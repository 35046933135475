import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { baseColors } from '../../../../../../../../theme';
import { OUTPUT_PANEL_BREAKPOINT } from '../../../../../../screens/OutputPanel/constants';

export const classNames = mergeStyleSets({
  row: {
    flexGrow: 1,
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '8px',
    marginLeft: '8px !important',
  },
  rowInPanel: {
    margin: 0,
  },
  dropdown: {
    width: '100%',
    minWidth: '90px',
  },
  laneIcon: {
    transform: 'rotate(90deg)',
  },
  label: {
    whiteSpace: 'nowrap',
    [`@media(max-width: ${OUTPUT_PANEL_BREAKPOINT}px)`]: {
      minWidth: '75px',
    },
  },
  fieldset: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    selectors: {
      '& > div': {
        marginBottom: 0,
      },
      '&>label': {
        marginRight: 8,
      },
      '& .icon-btn-wrapper>button': {
        color: baseColors.offBlack,
        marginLeft: 4,
      },
      '& .icon-btn-wrapper>button.is-disabled': {
        background: 'none',
      },
    },
  },
});

export const mainWrapperStyles: React.CSSProperties = {
  width: '100%',
};
