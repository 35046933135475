import { omit } from 'lodash';
import { createEntity, fetchTypeSections } from '../api';
import { ENTITY_CHILDREN_KEY } from './constants';
import {
  CustomConditionRelatedProperty,
  IEntityForm,
  IEntityFormSection,
  IEntityProperty,
  IGenericEntity,
  ValidationRequestPayload,
  ValidationRequestResponse,
  ValidationStatus,
} from './types';

export const generateSections = (entityName: string) => [
  {
    key: 'form-wrap',
    name: 'form-wrap',
    href: null,
    iconName: '',
    subSections: [
      {
        key: 'Edit',
        title: null,
        name: entityName,
        href: '/',
        iconName: 'Edit',
      },
    ],
  },
];

export const getPropertiesToWatch = (
  section: IEntityFormSection,
  form: IEntityForm,
  entity: IGenericEntity
): [string[], { [key: string]: unknown }] => {
  const keys: string[] = [];
  const defaultValues = {};
  section?.properties?.forEach((property) => {
    const interaction = property?.interactions?.find(
      (int) => int.conditionRelatedProperty
    );
    const key = interaction?.conditionRelatedProperty;
    const prop = section?.properties?.find(
      (relatedProp) => relatedProp?.name === key
    );
    if (key && !keys.includes(key)) {
      keys.push(key);
      defaultValues[key] =
        form?.data?.[key] || entity?.[key] || prop?.interaction?.defaultValue;
    }
  });

  return [keys, defaultValues];
};

export const filterOutReadOnlyProperties = (
  pages: IEntityFormSection[],
  data: IEntityForm['data']
): IEntityForm['data'] =>
  Object.keys(data)
    .filter(
      (key) =>
        (key === ENTITY_CHILDREN_KEY ||
          pages.some((page) =>
            page.properties.find(
              (prop) => prop.name === key && !prop.isReadOnly
            )
          )) &&
        data[key] !== null
    )
    .reduce((obj, key) => ({ ...obj, [key]: data[key] }), {});

export const separateChildrenEntities = (
  src: IEntityForm['data']
): {
  data: Omit<IEntityForm['data'], typeof ENTITY_CHILDREN_KEY>;
  children: IEntityForm['data'][typeof ENTITY_CHILDREN_KEY];
} => ({
  children: src?.[ENTITY_CHILDREN_KEY],
  data: omit(src, ENTITY_CHILDREN_KEY),
});

export const separateDataPropertiesAndFile = (data: { [key: string]: any }) => {
  const key = Object.keys(data).find(
    (k) => 'File' in window && data[k] instanceof File
  );

  // if file was found, we want to strip "file updated" entry
  // which is used only in the Fronted to mark this info to the user
  // However this prop is not accepted by the backend
  return {
    data: omit(data, [key, key ? 'wasFileUpdated' : undefined]),
    file: key ? (data[key] as File) : undefined,
    key,
  };
};

export const validateField = async ({
  validationTypeId,
  subjectTypeId,
  subjectId,
  SubjectParentId,
  value,
}: ValidationRequestPayload & { validationTypeId: string }) => {
  try {
    const { data } = await createEntity<
      ValidationRequestPayload,
      ValidationRequestResponse
    >(validationTypeId)({
      subjectTypeId,
      subjectId,
      SubjectParentId,
      value,
    });

    if (data?.validationStatus === ValidationStatus.Valid) {
      return true;
    }

    return data?.validationStatus;
  } catch (e) {
    console.error(e);
    return false;
  }
};

interface GetActualPropertyInteraction {
  property: IEntityProperty;
  watched: { [x: string]: any };
  currentData: { [x: string]: any };
  parentEntity?: IGenericEntity;
  currentEntity: IGenericEntity;
}

export const getActualPropertyInteraction = ({
  property,
  watched,
  currentData,
  parentEntity,
  currentEntity,
}: GetActualPropertyInteraction) => {
  if (!property) {
    return null;
  }
  let interaction = property?.interaction;
  const { name, isReadOnly } = property;

  if (property?.interactions?.length) {
    property.interactions.some((int) => {
      const { conditionMatching, conditionRelatedProperty } = int;
      const watchedValue = watched?.[conditionRelatedProperty];
      const storedValue = currentData?.[conditionRelatedProperty];
      const entityValue = currentEntity?.[conditionRelatedProperty];

      const currentValue =
        // if it's self-referencing and read only, we only use the value
        // stored in the entity
        name === conditionRelatedProperty && isReadOnly
          ? entityValue
          : watchedValue ?? storedValue ?? entityValue;

      if (
        // We want to apply referenceTypeFilterValue
        // when parent is not writable (for this specific referenceTypeFilterValue)
        (conditionRelatedProperty ===
          CustomConditionRelatedProperty['parent/IsWritable'] &&
          !parentEntity?.isWritable) ||
        (conditionRelatedProperty !==
          CustomConditionRelatedProperty['parent/IsWritable'] &&
          // If the current value equals the conditionMatching value, we use
          // this interaction
          currentValue === conditionMatching) ||
        // If there is a conditionRelatedProperty, but not a condition matching AND the current value
        // is nullish, we use this interaction.
        (!conditionMatching &&
          !!conditionRelatedProperty &&
          (currentValue === null || currentValue === undefined))
      ) {
        interaction = int;
        return true;
      }
      return false;
    });
  }

  if (!interaction?.controlType) {
    return null;
  }

  return interaction;
};

interface HandleCalculationChange {
  setPreventSubmission: (b: boolean) => void;
  setShouldSkipFollowingSections: (b: boolean) => void;
  entityId: string;
  designId: string;
}

export const handleCalculationChange = async ({
  setPreventSubmission,
  setShouldSkipFollowingSections,
  entityId,
  designId,
}: HandleCalculationChange) => {
  if (entityId) {
    try {
      setPreventSubmission(true);
      const { data } = await fetchTypeSections(entityId)({
        designId,
      });
      setShouldSkipFollowingSections(!data.length);
    } catch (e) {
      console.error(e);
    } finally {
      setPreventSubmission(false);
    }
  }
};
