import { ITheme } from '@fluentui/react';

export const getStyles = (theme: ITheme) => ({
  link: {
    color: theme.palette.themePrimary,
  },
  avatar: {
    marginBottom: 5,
  },
  userEmail: {
    color: theme.palette.neutralSecondary,
  },
  userInfoWrapper: {
    padding: '4px 0 8px',
  },
  userPropWrapper: {
    marginBottom: '6px',
  },
  userPropKey: {
    fontWeight: 600,
  },
  userPropValue: {
    fontWeight: 300,
  },
});
