import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LargeThumbnailPicker from '../../../../../pageTemplates/LargeThumbnailPicker';
import {
  setCurrentPage,
  setSelectedSourceId as setSelectedSourceIdInCatalog,
} from '../../../Catalog/actions';

import { fetchAllDesigns, setSelectedDesignId } from '../../actions';
import {
  getAllDesigns,
  getSelectedDesignId,
  areDesignsLoading,
} from '../../selectors';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { addTopLevelEntityRoute } from '../../../../../utils/routes';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { routes } from '../../../../../constants/routes';
import { setMostRecentPath } from '../../../../../shared/section-route/actions';
import { setSelectedSourceId as setSelectedSourceIdInIngestion } from '../../../Ingestion/actions';
import { getSearchQuery } from '../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../Search/utils';
import { usePrevious } from '../../../../../utils/usePrevious';
import { ISingleLargeThumbnail } from '../../../../../pageTemplates/LargeThumbnailPicker/LargeThumbnailPicker';
import { useQueuedImports } from './useQueuedImports';

const Designs = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { importsInProgressCount, isLoading: isLoadingQueuedImports } =
    useQueuedImports();

  // STATE
  const designs = useSelector(getAllDesigns);
  const selectedDesignId = useSelector(getSelectedDesignId);
  const isLoading = useSelector(areDesignsLoading);
  const searchQuery = useSelector(getSearchQuery);

  // DERIVED STATE
  const previousDesigns = usePrevious(designs, []);
  const filteredDesigns = useMemo(
    () =>
      designs.filter(
        (design) =>
          searchQuery === '' || matchesSearchQuery(searchQuery, design.name)
      ),
    [designs, searchQuery]
  );

  // CALLBACKS
  const selectionCleanup = React.useCallback(() => {
    dispatch(setCurrentPage(null));
    dispatch(setSelectedSourceIdInCatalog(null));
    dispatch(setSelectedSourceIdInIngestion(null));
    dispatch(
      setMostRecentPath({
        module: 'designer',
        section: 'ingestion',
        path: null,
      })
    );
  }, [dispatch]);

  const handleDesignClick = React.useCallback(
    (designId: string) => {
      dispatch(
        setSelectedDesignId(selectedDesignId === designId ? null : designId)
      );
      selectionCleanup();
    },
    [dispatch, selectedDesignId]
  );

  const handleDoubleClick = React.useCallback(
    (designId: string) => {
      dispatch(setSelectedDesignId(designId));
      selectionCleanup();
      history.push(routes.designer.ingestion.sources);
    },
    [dispatch]
  );

  const onStartClick = React.useCallback(() => {
    const path = addTopLevelEntityRoute(TYPE_IDS.Design);
    const goBackPage = routes.designer.contentLibrary.designs;
    history.push(path, {
      goBackPage,
      typeName: 'Design',
    });
  }, [history]);

  // EFFECTS
  React.useEffect(() => {
    dispatch(fetchAllDesigns());
  }, [dispatch]);

  React.useEffect(() => {
    // design was added
    if (designs.length - previousDesigns.length === 1) {
      const newDesign = designs[designs.length - 1];

      dispatch(setSelectedDesignId(newDesign.id));
    }
  }, [designs]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: designs.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick,
  });

  const itemsToRender: ISingleLargeThumbnail[] = React.useMemo(() => {
    const output = filteredDesigns
      .map((design) => ({
        iconName: design?.icon,
        id: design.id,
        name: design.name,
        itemTestId: `design-tile-${design.id}`,
      }))
      .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name));

    if (importsInProgressCount) {
      const syncingDesigns = Array(importsInProgressCount)
        .fill(0)
        .map((_, id) => ({
          id: String(id),
          name: t('designImportExport:importInProgress'),
          isSyncing: true,
          iconName: '',
          itemTestId: `design-tile-${id}`,
        }));

      output.unshift(...syncingDesigns);
    }

    return output;
  }, [filteredDesigns, importsInProgressCount]);

  // RENDER
  if (!isLoading && tutorialTile) return tutorialTile;

  return (
    <LargeThumbnailPicker
      items={itemsToRender}
      selectedItemId={selectedDesignId}
      handleClick={handleDesignClick}
      handleDoubleClick={handleDoubleClick}
      isLoading={isLoading || isLoadingQueuedImports}
      emptyMessage={t('designer:noDesignsMessage')}
    />
  );
};

export default Designs;
