import React, { FunctionComponent } from 'react';
import { IContextualMenuItem, Stack } from '@fluentui/react';

import DataSourceIcon from '../DataSourceIcon';
import ButtonMenu from '../ui/ButtonMenu';
import { wrapStyles } from './styles';
import { numberToHexColor } from '../ui/ColorPicker/utils';
import { TooltipHost } from '../ui';

export interface IDataSource {
  color: number | string;
  name: string;
  id: string;
}

interface DataSourceDropdownProps {
  dataSources: IDataSource[];
  activeDataSourceId: string;
  onChangeDataSource(dataSourceId: string): void;
  isDisabled?: boolean;
}

const renderSourceIcon = (color: number | string) => (
  <DataSourceIcon
    color={typeof color === 'number' ? numberToHexColor(color) : color}
  />
);

const onRenderCustomIcon = ({ data: { color } }: IContextualMenuItem) =>
  renderSourceIcon(color);

const DataSourceDropdown: FunctionComponent<DataSourceDropdownProps> = ({
  dataSources,
  activeDataSourceId,
  onChangeDataSource,
  isDisabled,
}) => {
  const items: IContextualMenuItem[] = dataSources.map((dataSource) => ({
    key: dataSource.id,
    text: dataSource.name,
    data: dataSource,
    onRenderCustomIcon,
  }));

  if (!onChangeDataSource) {
    const current = dataSources.find(
      (source) => source.id === activeDataSourceId
    );
    return (
      <Stack styles={wrapStyles}>
        <TooltipHost content={current?.name}>
          {renderSourceIcon(current?.color)}
        </TooltipHost>
      </Stack>
    );
  }

  return (
    <Stack styles={wrapStyles}>
      <ButtonMenu
        disabled={isDisabled}
        shouldDismissOnChange
        items={items}
        activeItemKey={activeDataSourceId}
        onChangeItem={onChangeDataSource}
        defaultIconProps={{
          iconName: 'EykoNoDataSource',
        }}
        data-testid="data-source-dropdown-btn"
      />
    </Stack>
  );
};

export default DataSourceDropdown;
