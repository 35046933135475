import { mergeStyleSets } from 'office-ui-fabric-react';

export const classNames = mergeStyleSets({
  groupTitle: {
    padding: '0 8px',
    fontWeight: 600,
  },
  groupWrapper: {
    marginTop: '8px',
  },
});
