import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from '../../../../constants/routes';

import Designs from '../../ContentLibrary/screens/Designs';

import Tags from '../../Metadata/screens/Tags';
import CatalogDefinition from '../../Metadata/screens/CatalogDefinition';
import IngestionIndexPage from '../../Ingestion/screens/IndexPage';
import CatalogIndexPage from '../../Catalog/screens/IndexPage';
import {
  SectionSwitch,
  SectionRoute,
} from '../../../../shared/section-route/SectionSwitch';
import { DESIGNER_MODULE_NAME } from '../../constants';

const DESIGNER_METADATA_SECTION_NAME = 'metadata';

const METADATA_SECTION_ROUTES: SectionRoute[] = [
  [routes.designer.metadata.tags, Tags],
  [routes.designer.metadata.catalogDefinition, CatalogDefinition],
];

const IndexPage = () => (
  <Switch>
    <Route path={routes.designer.contentLibrary.designs} component={Designs} />
    <Route path={routes.designer.metadata.index}>
      <SectionSwitch
        defaultPath={routes.designer.metadata.tags}
        module={DESIGNER_MODULE_NAME}
        routes={METADATA_SECTION_ROUTES}
        section={DESIGNER_METADATA_SECTION_NAME}
      />
    </Route>
    <Route path={routes.designer.catalog.index} component={CatalogIndexPage} />
    <Route
      path={routes.designer.ingestion.index}
      component={IngestionIndexPage}
    />
    <Redirect to={routes.designer.contentLibrary.designs} />
  </Switch>
);

export default IndexPage;
