import { TypeIds } from "."
import { AggregationType } from "./AggregationType";
import { Availability } from "./Availability";
import { DataSourceEntity } from "./DataSourceEntity";
import { DataType } from "./DataType";
import { FormatType } from "./FormatType";
import { ProcessStatus } from "./ProcessStatus";

/** A data source entity. */
export interface DataSourceEntityField {
  /** The filter type id. */
  $filterTypeId: string | null; // TODO: make a tagged type?

  /** The parent type type id. */
  $parentTypeId: TypeIds.DataSourceEntity;

  /** The type id. */
  $typeId: TypeIds.DataSourceEntityField;

  aggregationType: AggregationType;

  /** The element availability. */
  availability: Availability;

  dataType: DataType;

  /** The field description. */
  description: string | null;

  /** The filter id. */
  filterId: string | null; // TODO: make a tagged type? (entity $uuid) - not sure this is an actual entity

  formatType: FormatType;

  /** The entity field id. */
  id: string; // TODO: make a tagged type (entity $uuid)

  ingestedAggregationType: AggregationType;

  ingestedDataType: DataType;

  /** The ingested length. */
  ingestedLength: null | null;

  /** The ingested precision. */
  ingestedPrecision: number | null;

  /** The ingested scale. */
  ingestedScale: number | null;

  /** Ingestion is pending. */
  ingestionStatus: ProcessStatus;

  /** The length. */
  length: number | null;

  /** The field name. */
  name: string | null;

  // NOTE: shouldn't this be available only when expanding? there is nothing that would distinguish this behaviour
  // in the Swagger docs, so we should get that info somehow and also make a special `Expanded` generic type
  parent?: DataSourceEntity;

  /** The parent id. */
  parentId: string; // TODO: make a tagged type (entity $uuid)

  /** The precision. */
  precision: number | null;

  /** The scale. */
  scale: number | null;

  sourceDataType: DataType;

  /** The source id. */
  sourceId: string | null; // TODO: make a tagged type? ($uuid ?)

  /** The source name. */
  sourceName: string | null;

  tags: string[] | null;
}

export const DataSourceEntityFieldKeys: { [key in keyof DataSourceEntityField]: key } = {
  $filterTypeId: '$filterTypeId',
  $parentTypeId: '$parentTypeId',
  $typeId: '$typeId',
  aggregationType: 'aggregationType',
  availability: 'availability',
  dataType: 'dataType',
  description: 'description',
  filterId: 'filterId',
  formatType: 'formatType',
  id: 'id',
  ingestedAggregationType: 'ingestedAggregationType',
  ingestedDataType: 'ingestedDataType',
  ingestedLength: 'ingestedLength',
  ingestedPrecision: 'ingestedPrecision',
  ingestedScale: 'ingestedScale',
  ingestionStatus: 'ingestionStatus',
  length: 'length',
  name: 'name',
  parent: 'parent',
  parentId: 'parentId',
  precision: 'precision',
  scale: 'scale',
  sourceDataType: 'sourceDataType',
  sourceId: 'sourceId',
  sourceName: 'sourceName',
  tags: 'tags',
};
