import { AxiosResponse } from 'axios';
import { ApiV4ResponseWrapper } from '../../../../modules/Designer/types';
import { TypePropertySection } from '../../../model/schemas/TypePropertySection';
import { translateApiName } from '../../../../config/i18n/utils';
import { i18nNameKey, i18nValidValuesKey } from '../constants';
import { TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP } from '../../../constants';
import { translatePropertyName, translateValidValues } from './utils';

export const mutablyParseInteractionResponse = (
  response: AxiosResponse<ApiV4ResponseWrapper<TypePropertySection[]>>
) => {
  response.data.data = response.data?.data?.map((typePropertySection) => ({
    ...typePropertySection,
    [i18nNameKey]: translateApiName(
      typePropertySection.parentId,
      typePropertySection.name
    ),
    properties: typePropertySection.properties.map((typeProperty) => ({
      ...typeProperty,
      [i18nNameKey]: translatePropertyName({
        typeProperty,
        typePropertySection,
      }),
      interaction: {
        ...typeProperty.interaction,
        [i18nValidValuesKey]: translateValidValues({
          typeProperty,
          typePropertySection,
        }),
      },
      interactions: typeProperty.interactions.map((interaction) => ({
        ...interaction,
        [i18nValidValuesKey]: interaction.validValues?.map((validValue) =>
          translateApiName(
            TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[
              interaction.conditionMatching
            ],
            String(validValue)
          )
        ),
      })),
    })),
  }));

  return response;
};
