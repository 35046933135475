import moment from 'moment';
import {
  ComparisonTypes,
  FilterTypes,
  IStreamFilter,
} from '../../types/IStreamFilter';

export const addOrRemoveSelectedFilter = (
  selectedFilters: IStreamFilter[],
  newSelectedFilter: IStreamFilter
) => {
  const streamExists = selectedFilters.some(
    (s) => s.id === newSelectedFilter.id
  );

  if (streamExists) {
    return selectedFilters.filter((s) => s.id !== newSelectedFilter.id);
  }

  return [...selectedFilters, newSelectedFilter];
};

export const removeSelectedStreamFilters = (
  streamFilters: IStreamFilter[],
  selectedStreamFilters: IStreamFilter[]
) =>
  streamFilters.filter(
    (filter) =>
      !selectedStreamFilters.some(
        (selectedFilters) => selectedFilters.id === filter.id
      )
  );

export const editFilter = (
  streamFilters: IStreamFilter[],
  editedFilter: IStreamFilter
) =>
  streamFilters.map((filter) => {
    if (filter.id === editedFilter.id) {
      return editedFilter;
    }

    return filter;
  });

export const removeStreamFilterById = (
  id: string,
  streamFilters: IStreamFilter[]
) => streamFilters.filter((filter) => filter.id !== id);

const getTypeFromComparison = (comparison: ComparisonTypes): FilterTypes => {
  if (
    [ComparisonTypes.Between, ComparisonTypes.NotBetween].includes(comparison)
  ) {
    return FilterTypes.Range;
  }

  if ([ComparisonTypes.In, ComparisonTypes.NotIn].includes(comparison)) {
    return FilterTypes.Values;
  }

  return FilterTypes.Comparison;
};

const compileComparison = (comparison: ComparisonTypes): ComparisonTypes => {
  if (
    [
      ComparisonTypes.IsNotNull,
      ComparisonTypes.IsNotBlank,
      ComparisonTypes.NotBetween,
      ComparisonTypes.NotIn,
    ].includes(comparison)
  ) {
    return ComparisonTypes.NotEqual;
  }

  if (
    [
      ComparisonTypes.IsNull,
      ComparisonTypes.IsBlank,
      ComparisonTypes.In,
      ComparisonTypes.Between,
    ].includes(comparison)
  ) {
    return ComparisonTypes.Equal;
  }

  return comparison;
};

const formatCompareValue = (value, comparison?: ComparisonTypes) => {
  if (
    [ComparisonTypes.IsNotBlank, ComparisonTypes.IsBlank].includes(comparison)
  ) {
    return '';
  }

  if (
    [ComparisonTypes.IsNotNull, ComparisonTypes.IsNull].includes(comparison)
  ) {
    return null;
  }

  if (!value) {
    return undefined;
  }

  if (value instanceof Date) {
    const offset = value.getTimezoneOffset() / 60;

    return moment.utc(value).subtract(offset, 'hours').format();
  }

  return value;
};

/** @deprecated */
export const compileStreamFiltersToRequest = (values: IStreamFilter) => ({
  type: getTypeFromComparison(values.comparison),
  comparison: compileComparison(values.comparison),
  values: values?.values?.map((v) => formatCompareValue(v, values.comparison)),
  compareValue: formatCompareValue(values.compareValue, values.comparison),
  lowerBoundValue: formatCompareValue(values.lowerBoundValue),
  upperBoundValue: formatCompareValue(values.upperBoundValue),
});
