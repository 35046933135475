import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import {
  addNestedEntityRoute,
  deleteEntityRoute,
  editEntityRoute,
} from '../../../../../utils/routes';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { getGroupById } from '../../selectors';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { routes } from '../../../../../constants/routes';
import { configureSmartGroupsJobIntent } from '../../actions';
import { GroupType } from '../../../../../api/model/schemas/GroupType';
import { HeaderBarButton } from '../../../../../components/ui';

const GroupsHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedDesignId = useSelector(getSelectedDesignId);
  const { selectedItem: selectedGroupId, clear } = useItemSelections();
  const selectedGroup = useSelector(getGroupById(selectedGroupId));
  const goBackPage = routes.designer.catalog.groups;

  const successCallback = (entityId: string) => {
    if (entityId) {
      dispatch(
        configureSmartGroupsJobIntent({
          entityId,
          onQueued: () => {
            history.push(goBackPage);
          },
        })
      );
    } else {
      history.push(goBackPage);
    }
  };

  const handleEdit = () => {
    const path = editEntityRoute(TYPE_IDS.Group, selectedGroupId);
    history.push(path, {
      assetsTypeId: TYPE_IDS.Group,
      initialValues: {},
    });
  };

  const handleAdd = () => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.Group,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesignId,
    });

    history.push(path, {
      typeName: 'Group',
      successCallback,
    });
  };

  const handleRefresh = React.useCallback(() => {
    dispatch(
      configureSmartGroupsJobIntent({
        entityId: selectedGroup.id,
        onQueued: () => null,
      })
    );
  }, [selectedGroup]);

  const handleDelete = React.useCallback(() => {
    const path = deleteEntityRoute(TYPE_IDS.Group, selectedGroupId);

    const goBackPage = routes.designer.catalog.groups;

    history.push(path, {
      goBackPage,
      entityName: selectedGroup?.name,
      successCallback: clear,
      deleteDescription: (
        <Trans
          i18nKey="wizard:deleteNestedEntity"
          values={{ name: selectedGroup?.name, asset: t('entityTypes:Group') }}
        />
      ),
    });
  }, [selectedGroupId]);

  return (
    <>
      <ItemActionsHeader
        isItemSelected={!!selectedGroupId}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
      />
      <HeaderBarButton
        tooltip={<Trans t={t} i18nKey="actionBar:general:refresh" />}
        onClick={handleRefresh}
        iconProps={{ iconName: 'Refresh' }}
        disabled={selectedGroup?.type !== GroupType.Smart}
        shouldHideOnDisabled
      />
    </>
  );
};

export default GroupsHeader;
