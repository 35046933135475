import { Label } from '@fluentui/react';
import React from 'react';
import ColorPicker from '../../../../../components/ui/ColorPicker';

import { IControlledField } from '../../types';

export const ColorPickerField: React.FC<IControlledField> = ({
  controller,
  isDisabled,
  label,
}) => {
  const { onChange, value } = controller;

  return (
    <>
      <Label>{label}</Label>
      <ColorPicker
        {...{
          isDisabled,
        }}
        color={((value ?? 0) as number).toString(16).padStart(6, '0')}
        onChange={(color) => onChange(parseInt(color, 16))}
      />
    </>
  );
};

export default ColorPickerField;
