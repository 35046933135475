import { IStreamFilter } from './IStreamFilter';
import { IColumn, IColumnAggregationMethods } from './IColumn';
import { ObjectTypes } from '../modules/App/types';
import { ParentDrilling, SingleItem } from '../modules/GroupTable/types';

export interface ITotal {
  type: IColumnAggregationMethods,
  rows: any[][],
}

export interface IDatasetOptions {
  showAllValues: boolean;
  dataOnDemand: boolean;
  freezePanes: boolean;
  rowParentDrilling: ParentDrilling,
  columnParentDrilling: ParentDrilling,
}

export type IDatasetContinuation = {
  pageSize: number,
  token: string,
};

export type ILastMappedGroupTableDesign = {
  columns: SingleItem[],
  rows: SingleItem[],
  values: SingleItem[],
};

export interface LastSuccessfulMapping {
  columns: IColumn[];
  continuation?: IDatasetContinuation
  groupTableDesign?: ILastMappedGroupTableDesign;
  streamFilters?:IStreamFilter[]
  timestamp?: string;
}

export interface IDataset {
  type: ObjectTypes;
  id: string;
  streamId: string;
  columns: IColumn[];
  totals: ITotal[];
  filterColumns: IColumn[];
  streamFilters?: IStreamFilter[];
  rows: any[][];
  address: string;
  tableId: string;
  totalRowCount: number;
  continuation?: IDatasetContinuation
  options: IDatasetOptions
  lastSuccessfulMapping: LastSuccessfulMapping
}

export const TEMP_DATASET = 'dataset_temp';
