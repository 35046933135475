import { Dropdown, Label, ResponsiveMode, Stack } from '@fluentui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButtonProps } from '../../../../components/IconButton';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { SelectDropdown } from '../../../../components/ui';
import WizardPage, { WizardType } from '../../../../pageTemplates/WizardPage';
import PickEntity from '../../components/StreamToDatabase/PickEntity';
import {
  Strategy,
  useStreamToDatabase,
} from '../../components/StreamToDatabase/useStreamToDatabase';
import { getDropdownOptions } from '../../components/StreamToFile/utils';
import { useStreamToFile } from '../../components/StreamToFile/useStreamToFile';
import { getDestinationsOptions } from '../utils';
import { Destination, DestinationOption } from '../types';
import { StreamOutputContent } from '../../../Builder/components/StreamOutput/StreamOutputContent';
import { getSelectedStream } from '../../selectors';
import excelStreamerSelectors from '../../../Streams/selectors';
import { useStreamOutput } from '../../../Builder/components/StreamOutput/useStreamOutput';
import { isOfficeEnabled as getIsOfficeEnabled } from '../../../../config/buildType';
import { routes } from '../../../../constants/routes';
import { DESTINATION_CONNECTOR_ID } from '../constants';

interface State {
  destination: Destination;
  isLoading: boolean;
}

const ExportWizard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{
    strategy: Strategy;
    designId: string;
  }>();

  const isOfficeEnabled = getIsOfficeEnabled();

  const selectedStreamIsolatedBuild = useSelector(getSelectedStream);
  const selectedStreamIdInExcelBuild = useSelector(
    excelStreamerSelectors.getCurrentStreamId
  );

  const streamId = isOfficeEnabled
    ? selectedStreamIdInExcelBuild
    : selectedStreamIsolatedBuild?.id;

  const strategy = location?.state?.strategy;
  const designId = location?.state?.designId;

  const [{ destination }, setState] = useState<State>({
    destination: undefined,
    isLoading: false,
  });

  const destinations = useMemo(() => getDestinationsOptions(t), [t]);
  const fileOptions = useMemo(() => getDropdownOptions(t), [t]);
  const { isLoading: isLoadingStreamOutputData, streamOutputData } =
    useStreamOutput({ streamId });

  const isEykoStreaming = [
    Destination.Tableau,
    Destination['Microsoft Power BI'],
    Destination['Eyko Streaming Service'],
  ].includes(destination);

  const {
    selectedEntity,
    setSelectedEntity,
    handleApprove: onApproveDatabase,
    isLoading: isLoadingDatabase,
  } = useStreamToDatabase({
    strategy,
    endCallback: () => history.go(-1),
  });

  const {
    selectedItem,
    setSelectedItem,
    handleApprove: onApproveFile,
    isLoading: isLoadingFile,
  } = useStreamToFile({
    strategy,
    onQueued: () => history.go(-1),
  });

  // CALLBACKS
  const handleConfirm = useCallback(
    () =>
      ({
        [Destination.Database]: onApproveDatabase,
        [Destination.Anaplan]: onApproveDatabase,
        [Destination.Salesforce]: onApproveDatabase,
        [Destination.NextWorld]: onApproveDatabase,
        [Destination.File]: onApproveFile,
      }[destination]()),
    [destination, selectedEntity, selectedItem]
  );

  const handleCancel = useCallback(() => history.go(-1), [history]);

  const handleDestinationChange = React.useCallback(
    (option: DestinationOption) => {
      setState((state) => ({ ...state, destination: option.value }));
      setSelectedEntity(null);
    },
    []
  );

  const IsConfirmDisabled = useMemo(
    () =>
      !destination ||
      isEykoStreaming ||
      {
        [Destination.Database]: !selectedEntity,
        [Destination.Anaplan]: !selectedEntity,
        [Destination.Salesforce]: !selectedEntity,
        [Destination.NextWorld]: !selectedEntity,
        [Destination.File]: !selectedItem,
      }[destination],
    [destination, isEykoStreaming, selectedEntity, selectedItem]
  );

  const buttonsConfig: IconButtonProps[] = [
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.accept" />,
      onClick: handleConfirm,
      disabled: IsConfirmDisabled,
      iconProps: { iconName: 'CheckMark' },
      shouldHideOnDisabled: true,
      'data-testid': 'confirm-export-button',
    },
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.cancelAction" />,
      onClick: handleCancel,
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'cancel-export-button',
    },
  ];

  // RENDER
  if (!strategy) {
    // because application "remembers" last page in a module,
    // when you switch from Designer to Builder and again to Designer
    // App will try to reopen export Wizard, however, stratgy won't be present in the location state
    // Which is required dby the component
    // All other wizards have removed sidebar icons totally to avoid this problem
    // but here we have big probability to know what was the previous page
    const path = isOfficeEnabled
      ? routes.streams.index
      : routes.streamer.dataMode.selectAssets;

    return <Redirect to={path} />;
  }

  return (
    <WizardPage
      wizardType={WizardType.Export}
      headerBarButtonsConfig={buttonsConfig}
      pageTitle=" "
      entityName={t('exportWizard:Export')}
    >
      {isLoadingDatabase || isLoadingFile ? (
        <ItemsListShimmer />
      ) : (
        <>
          <Stack horizontal>
            <Label required>{t('streamToDatabase:destination')}</Label>
          </Stack>
          <Stack>
            <SelectDropdown
              maxMenuHeight={600}
              options={destinations}
              placeholder={t('filters:Select')}
              closeMenuOnSelect
              onChange={handleDestinationChange}
              className="export-wizard-destination-picker"
            />
          </Stack>

          {[
            Destination.Salesforce,
            Destination.Database,
            Destination.Anaplan,
            Destination.NextWorld,
          ].includes(destination) && (
            <Stack>
              <Label>{t('streamToDatabase:destination')}</Label>
              <PickEntity
                designId={designId}
                setSelectedEntity={setSelectedEntity}
                selectedEntity={selectedEntity}
                connectorTypeId={DESTINATION_CONNECTOR_ID[destination]}
              />
            </Stack>
          )}

          {destination === Destination.File && (
            <Stack>
              <Label>{t('streamToFile:fileType')}</Label>
              <Dropdown
                options={fileOptions}
                onChange={(_, item) => {
                  setSelectedItem(item);
                }}
                selectedKey={selectedItem?.key}
                responsiveMode={ResponsiveMode.large}
                placeholder={t('streamToFile:selectFileType')}
              />
            </Stack>
          )}

          {isEykoStreaming && (
            <Stack tokens={{ padding: '8px 0 0' }}>
              <StreamOutputContent
                isLoading={isLoadingStreamOutputData}
                streamOutputData={streamOutputData}
              />
            </Stack>
          )}
        </>
      )}
    </WizardPage>
  );
};

export default ExportWizard;
