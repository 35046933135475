import React, { FunctionComponent, ReactNode } from 'react';
import { Stack, Text, Spinner, CommandButton, Icon } from '@fluentui/react';
import { useId } from '@fluentui/react-utilities';
import { Trans } from 'react-i18next';
import useInformationDialog, {
  InfoParams,
} from '../../hooks/use-information-dialog';

import {
  containerStyles,
  slotStyles,
  fieldNameStyles,
  textStyles,
  spinnerStyles,
  deleteConfirmationStyles,
  buttonStyles,
  sureTextStyles,
  availabilityTextStyles,
  availabilityIconStyles,
  warningWrapper,
} from './styles';
import { getSearchMatch } from '../../modules/Search/utils';
import SearchMatch from '../SearchMatch';
import { TooltipHost } from '../ui';
import { Availability } from '../../api/model/schemas/Availability';

export interface EntityFieldProps {
  slot1?: ReactNode;
  slot2?: ReactNode;

  name?: string;
  children?: ReactNode;

  isSelectable?: boolean;

  isSelected?: boolean;
  isOrange?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;

  isSyncing?: boolean;
  syncingTooltipContent?: string | JSX.Element;

  onClick?(event: React.MouseEvent<HTMLDivElement>): void;
  onTextClick?(): void;

  info?: InfoParams;

  searchQuery?: string;
  showDeleteConfirmation?: boolean;
  onDeleteConfirm?(): void;
  onDeleteCancel?(): void;
  availability?: Availability;
  'data-testid'?: string;

  rightSideItems?: React.ReactNode;
  rightSideItemsClassName?: string;
}

const EntityField: FunctionComponent<EntityFieldProps> = ({
  children,
  slot1,
  slot2,
  name,
  isSelectable,
  isSelected,
  isOrange,
  isDisabled,
  isSyncing,
  isHidden,
  syncingTooltipContent,
  onClick,
  onTextClick,
  info,
  searchQuery,
  showDeleteConfirmation,
  onDeleteConfirm,
  onDeleteCancel,
  availability,
  'data-testid': dataTestId,
  rightSideItems,
  rightSideItemsClassName,
}) => {
  // HOOKS
  const componentId = useId(`${EntityField.name}-`);
  const { infoDialog, infoDialogToggleButton, isInfoOpen } =
    useInformationDialog({
      info: info && {
        ...info,
        title: info.title || name,
        assetAvailability: availability,
      },
      target: `#${componentId}`,
      isSelected,
    });

  const printName = () => {
    if (typeof searchQuery === 'string' && searchQuery !== '') {
      const match = getSearchMatch(name, searchQuery);

      if (match) {
        return <SearchMatch match={match} />;
      }
    }

    return name;
  };
  // PARTS
  const deleteConfirmation = () => (
    <Stack
      horizontal
      verticalAlign="center"
      styles={deleteConfirmationStyles}
      tokens={{ childrenGap: 2 }}
    >
      <Text
        styles={sureTextStyles}
        data-testid="entity-field-delete-confirmation-text"
      >
        <Trans i18nKey="designer:removeSourceConfirmation" />
      </Text>

      <CommandButton
        data-testid="confirm-delete-entity-field"
        styles={buttonStyles}
        iconProps={{
          iconName: 'Accept',
        }}
        onClick={(event) => {
          event.stopPropagation();
          onDeleteConfirm();
        }}
      />
      <CommandButton
        data-testid="cancel-delete-entity-field"
        styles={buttonStyles}
        iconProps={{
          iconName: 'Cancel',
        }}
        onClick={(event) => {
          event.stopPropagation();
          onDeleteCancel();
        }}
      />
    </Stack>
  );

  const rightSlot = () => {
    // override default behavior
    if (rightSideItems) {
      return (
        <Stack className={rightSideItemsClassName}>{rightSideItems}</Stack>
      );
    }

    if (isSyncing) {
      return (
        <TooltipHost content={syncingTooltipContent}>
          <Spinner styles={spinnerStyles} />
        </TooltipHost>
      );
    }

    if (showDeleteConfirmation) {
      return deleteConfirmation();
    }

    return (
      <div style={{ position: 'relative' }}>
        <span style={warningWrapper}>
          <Icon
            iconName="Warning"
            style={availabilityIconStyles(availability)}
          />
        </span>
        {info?.data && infoDialogToggleButton}
      </div>
    );
  };

  // RENDER
  return (
    <>
      <Stack
        horizontal
        verticalAlign="center"
        styles={containerStyles({
          isSelected,
          isOrange,
          isInfoOpen,
          isSelectable: isSelectable && !isDisabled,
          isDisabled: isDisabled || isHidden,
        })}
        onClick={isDisabled ? undefined : onClick}
        id={componentId}
        data-testid={dataTestId}
        data-is-selected={isSelected}
        data-is-syncing={isSyncing}
      >
        {slot1 && <Stack styles={slotStyles}>{slot1}</Stack>}

        {slot2 && <Stack styles={slotStyles}>{slot2}</Stack>}

        <Stack
          onClick={() => {
            if (onTextClick && !isDisabled) {
              onTextClick();
            }
          }}
          styles={fieldNameStyles}
        >
          {name && (
            <Text as="p" styles={textStyles}>
              <span style={availabilityTextStyles(availability)}>
                {printName()}
              </span>
            </Text>
          )}

          {children}
        </Stack>

        {rightSlot()}
      </Stack>
      {infoDialog}
    </>
  );
};

export default EntityField;
