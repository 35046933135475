import * as api from '../api';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';

import { IDesign, ApiV4ResponseWrapper } from '../types';

export const fetchDesigns = api.fetchEntities<
ApiV4ResponseWrapper<IDesign[]>
>(TYPE_IDS.Design);

export const createDesign = api.createEntity<IDesign>(TYPE_IDS.Design);
export const deleteDesign = api.deleteEntity<IDesign>(TYPE_IDS.Design);
