import { ApplicationState } from '../../store/types';
import { StreamFiltersState, initialState } from './reducer';

export const getStreamFiltersState = (
  state: ApplicationState,
): StreamFiltersState => state.streamFilters || initialState;

export const getStreamFiltersList = (
  state: ApplicationState,
) => getStreamFiltersState(state).streamFilters;

export const getSelectedStreamFiltersList = (
  state: ApplicationState,
) => getStreamFiltersState(state).selectedStreamFilters;

export const getStreamFiltersFromValues = (
  state: ApplicationState,
) => getStreamFiltersList(state).filter((filter) => filter.generatedFromValue);

export const getHasDatasetFilters = (
  state: ApplicationState,
) => getStreamFiltersState(state).streamFilters.length > 0;
