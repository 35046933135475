/* eslint-disable max-len */
import { createSelector } from 'reselect';
import { ApplicationState } from '../../store/types';
import { DRILLING_DIRECTIONS } from './constants';
import streamSelectors from '../Streams/selectors';

import { DrillingDirection, SectionType } from './types';
import { generateColumnUuid } from '../ColumnsSequencing/utils';

export const getGroupTableState = (state: ApplicationState) => state.groupTable;

export const getGroupTable = (datasetId: string) => (state: ApplicationState) => getGroupTableState(state)[datasetId];

export const getShouldReload = (datasetId: string) => (state:ApplicationState) => getGroupTableState(state)[datasetId]?.shouldReload;

export const getSectionByType = (key: SectionType, datasetId: string) => (state: ApplicationState) => getGroupTableState(state)[datasetId]?.[key] || [];

const getCurrentGroupTableState = (state:ApplicationState) => {
  const { currentDatasetId } = state.streams;

  if (!currentDatasetId) return null;

  return getGroupTable(currentDatasetId)(state);
};

export const getIsCurrentGroupTableHasValueSelected = createSelector(
  getCurrentGroupTableState,
  ((groupTableState) => groupTableState?.values?.length > 0),
);

export const getDesignSectionItemsColumns = (datasetId: string) => (state:ApplicationState) => {
  const groupTableState = getGroupTable(datasetId)(state);
  const dataset = streamSelectors.getDatasetById(datasetId)(state);

  return [
    ...groupTableState.columns,
    ...groupTableState.rows,
    ...groupTableState.values,
  ].map((item) => dataset.columns.find((col) => generateColumnUuid(col) === item.columnUuid));
};

export const getTotalBySectionType = (sectionType : SectionType, datasetId : string) => (state: ApplicationState) => getGroupTableState(state)[datasetId]?.totals[sectionType];

export const getIsRefreshButtonEnabled = (datasetId: string) => (state: ApplicationState) => {
  const groupTable = getGroupTable(datasetId)(state);

  if (!groupTable) return false;

  return groupTable?.rows?.length >= 1 && groupTable?.values?.length >= 1;
};

export const getMaxDrillingLevel = (datasetId: string, drillingDirection: DrillingDirection) => (state: ApplicationState) => {
  const groupTable = getGroupTable(datasetId)(state);

  if (!groupTable) {
    return null;
  }

  switch (drillingDirection) {
    case DRILLING_DIRECTIONS.rowDrilling: {
      const rowsWithoutTotals = groupTable.rows;

      return rowsWithoutTotals.length;
    }
    case DRILLING_DIRECTIONS.columnDrilling: {
      const columnsWithoutTotals = groupTable.columns;

      return columnsWithoutTotals.length;
    }
    default: {
      return null;
    }
  }
};
