import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { HeaderBarButton } from '../../../../../components/ui';
import { routes } from '../../../../../constants/routes';
import { setSelectedSourceId, triggerIngestionJob } from '../../actions';
import { getSelectedSourceId, getSelectedSource } from '../../selectors';

const IngestDataButton = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  // STATE
  const hasSingleDataSourceSelected = !!useSelector(getSelectedSourceId);
  const selectedDataSource = useSelector(getSelectedSource);
  const isAtSourcesScreen = !!matchPath(routes.designer.ingestion.sources, pathname);

  // CALLBACKS
  const successCallback = React.useCallback(() => {
    // this trick enables "default selection" to properly work
    // on sources screen
    history.push(isAtSourcesScreen ? routes.designer.ingestion.index : routes.designer.ingestion.sources);
    dispatch(setSelectedSourceId(null));
  }, [isAtSourcesScreen, dispatch]);

  const handleClick = React.useCallback(() => {
    dispatch(triggerIngestionJob({
      successCallback,
    }));
  }, [dispatch, successCallback]);

  // DERIVED STATE
  // TO DO - disbale this button when they are unresolved link issues
  // after the API will provide them
  const isDisabled = !hasSingleDataSourceSelected || !selectedDataSource?.canConnect;

  // RENDER
  return (
    <HeaderBarButton
      data-testid="ingest-datasource-btn"
      disabled={isDisabled}
      iconProps={{ iconName: 'Upload' }}
      onClick={handleClick}
      shouldHideOnDisabled
      tooltip={(
        <Trans t={t} i18nKey="actionBar:designer.ingestion.ingest" />
      )}
    />
  );
};

export default IngestDataButton;
