import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IFoldableListItem } from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';
import EntityField from '../../../../../components/EntityField';

import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../../App/types';

import { IGroup } from '../../../types';

import { getGroups, getIsUpdating, getDataSources } from '../../selectors';
import { fetchGroups, updateEntity, fetchDataSources } from '../../actions';
import useEditableItems from '../../../../../hooks/useEditableItems';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import { EmptyMessage, GroupCollapse } from '../../../../../components/ui';
import { getSelectedItemsIds } from '../../../../App/selectors';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { getSearchQuery } from '../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../Search/utils';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { addNestedEntityRoute } from '../../../../../utils/routes';
import { ConfigureStatus } from '../../../../../api/model/schemas/ConfigureStatus';

export const Groups: FunctionComponent = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // STATE
  const selectedDesignId = useSelector(getSelectedDesignId);

  const { records: groups, isLoading: isGroupsLoading } =
    useSelector(getGroups);
  const isUpdating = useSelector(getIsUpdating);
  const { records: dataSources, isLoading: isDataSourcesLoading } =
    useSelector(getDataSources);

  const selectedEntitiesIds = useSelector(getSelectedItemsIds);
  const searchQuery = useSelector(getSearchQuery);

  // DERIVED STATE
  const { properties } = useGetParsedTypeProperties({
    designId: selectedDesignId,
    typeId: TYPE_IDS.Group,
  });

  const filteredRecords = useMemo(
    () =>
      groups.filter(
        (record) =>
          searchQuery === '' || matchesSearchQuery(searchQuery, record.name)
      ),
    [groups, searchQuery]
  );

  const groupsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds,
  });

  // TODO: talk to Stephan about adding missing fields to both the main response
  // as well as the list of shown attributes: group source (Hub, Field, etc.), group type (Logic, Values)
  const schema = getSchemaFromTypeProperties(properties, TYPE_IDS.Group);

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:catalog:groups:cta:noDataIngested':
        dataSources.length === 0,
    }),
    [dataSources.length]
  );

  // CALLBACKS
  const onEditEndCallback = React.useCallback(
    (hasChanged: boolean) => {
      if (hasChanged) {
        dispatch(fetchGroups(selectedDesignId));
      }
    },
    [dispatch, selectedDesignId]
  );

  const onEntityUpdated = React.useCallback(() => {
    dispatch(fetchGroups(selectedDesignId));
  }, [dispatch, selectedDesignId]);

  const { isEditing, onEditEnd, onEditStart } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  const handleRename = React.useCallback(
    async (dataSourceId: string, newName: string) => {
      await dispatch(
        updateEntity({
          entity: {
            id: dataSourceId,
            name: newName,
          },
          typeId: TYPE_IDS.Group,
        })
      );

      await dispatch(fetchGroups(selectedDesignId));
    },
    [dispatch, fetchGroups, selectedDesignId]
  );

  // NOTE: based on GroupsHeader.tsx
  const handleAdd = React.useCallback(() => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.Group,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesignId,
    });

    history.push(path, {
      typeName: 'Group',
    });
  }, [history, selectedDesignId]);

  // EFFECTS
  useEffect(() => {
    if (selectedDesignId && dataSources.length === 0)
      dispatch(fetchDataSources(selectedDesignId));
  }, [dispatch, selectedDesignId, dataSources.length]);

  useEffect(() => {
    if (selectedDesignId) dispatch(fetchGroups(selectedDesignId));
  }, [dispatch, selectedDesignId]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: groups.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick: handleAdd,
    startButtonStates: tutorialTileRequirements,
  });
  const { handleItemClickedOnSingleSelection, selectedItem } =
    useItemSelections();

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: groupsWhichIncludesSelections,
      selectedItemId: selectedItem,
    });

  const renderGroup = (field: IGroup) =>
    field ? (
      <EntityField
        isDisabled={field.configureStatus === ConfigureStatus.Configuring}
        isSyncing={field.configureStatus === ConfigureStatus.Configuring}
        key={field.id}
        isSelectable
        isSelected={field.id === selectedItem}
        info={{
          data: field,
          title: field.name,
          schema,
          enableInlineEdit: true,
          onEntityUpdated,
        }}
        onClick={() => handleItemClickedOnSingleSelection(field.id)}
        slot1={<EntityFieldSlotSpacer />}
      >
        <EditableFoldableListItem
          text={field.name}
          onEdit={(newVal) => handleRename(field.id, newVal)}
          isSelected={field.id === selectedItem}
          onEditStart={() => {
            const isSelected = field.id === selectedItem;
            // for consistency mark source as selected when editing
            if (!isSelected) {
              handleItemClickedOnSingleSelection(field.id);
            }
            onEditStart();
          }}
          onEditEnd={onEditEnd}
          transparentBackground
          disableEditing={
            isEditing || field.configureStatus === ConfigureStatus.Configuring
          }
          searchQuery={searchQuery}
          availability={field.availability}
          testId={`catalog-group-${field.name}`}
        />
      </EntityField>
    ) : null;

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = groups.find((r) => r.id === item.key);

    return renderGroup(entry);
  };

  // RENDER
  if (isGroupsLoading || isUpdating || isDataSourcesLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isGroupsLoading && groups.length === 0) {
    return (
      <EmptyMessage
        message={t('wizard:noEntityDefined', {
          entityType: t('entityTypes:Group', { count: 0 }),
        })}
      />
    );
  }

  if (currentSort === DefinedSortTypes.Name) {
    const sortedFields = [...groupsWhichIncludesSelections].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return <>{sortedFields.map((field) => renderGroup(field))}</>;
  }

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onClick={onClickHeaderHandler}
        >
          {group.items.map((item) => onRenderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default Groups;
