import * as effects from 'redux-saga/effects';
import { setStreamFilters } from '../actions';
import selectors from '../../Streams/selectors';
import { actionCreators } from '../../Streams/actions';

function* updateStreamFilters() {
  const currentDataset = selectors.getCurrentDataset(yield effects.select());

  yield effects.put({
    type: setStreamFilters.type,
    payload: currentDataset?.streamFilters || [],
  });
}

export default function* rootSaga() {
  yield effects.takeLatest(
    actionCreators.checkCurrentSelectionFinish.type,
    updateStreamFilters,
  );
}
