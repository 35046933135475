import moment from 'moment';
import * as effects from 'redux-saga/effects';
import * as excelActions from '../../../actions/excel-actions';

import * as StreamActions from '../actions';
import streamSelectors from '../selectors';

// This saga handles playing stream into the TABLE object,
// for group table, checkout group table module sagas

export function* handleRewind() {
  const currentDataset = streamSelectors.getCurrentDataset(
    yield effects.select(),
  );

  yield effects.put(StreamActions.setRewinding(true));

  try {
    yield effects.call(excelActions.updateDataset, {
      datasetId: currentDataset.id,
      forceRender: true,
    });

    const {
      columns, continuation, id, streamFilters,
    } = streamSelectors.getCurrentDataset(yield effects.select());

    yield effects.put({
      type: 'EDIT_DATASET',
      id,
      properties: {
        lastSuccessfulMapping: {
          columns,
          continuation,
          timestamp: moment().format('h:mm A M/D/YY'),
          streamFilters,
        },
      },
    });
  } catch (error) {
    console.log('handle rewind error', error);
  } finally {
    yield effects.put(StreamActions.setRewinding(false));
  }
}

export function* handlePlayIntent() {
  const hasColumnsQueuedForDeletion = streamSelectors.getHasColumnsQueuedForDeletion(yield effects.select());
  const hasColumnsQueuedForMapping = streamSelectors.getHasColumnsQueuedForMapping(yield effects.select());
  const hasAnyColumnChangedAggregationCount = streamSelectors.getIfCountOfColumnAggregationsChanged(
    yield effects.select(),
  );
  const hasContinuationToken = streamSelectors.getIfCurrentDatasetHasContinuation(yield effects.select());

  const hasCurrentDatasetFiltersChanged = streamSelectors.getHasCurrentDatasetFiltersChanged(yield effects.select());

  const hasColumnsQueuedForSequencing = streamSelectors.getHasColumnsQueuedForSequencing(yield effects.select());
  const hasColumnsQueuedForSortModeChange = streamSelectors.getHasColumnsQueuedForSortModeChange(
    yield effects.select(),
  );
  const hasColumnsQueuedForSortPositionChange = streamSelectors.getHasColumnsQueuedForSortPositionChange(
    yield effects.select(),
  );

  // this is equal to the "refresh call"
  if (
    !hasContinuationToken
    || hasColumnsQueuedForMapping
    || hasColumnsQueuedForDeletion
    || hasAnyColumnChangedAggregationCount
    || hasCurrentDatasetFiltersChanged
    || hasColumnsQueuedForSequencing
    || hasColumnsQueuedForSortModeChange
    || hasColumnsQueuedForSortPositionChange
  ) {
    yield effects.call(handleRewind);
    return;
  }

  const currentDataset = streamSelectors.getCurrentDataset(
    yield effects.select(),
  );

  yield effects.put(StreamActions.setAutoLoadAll(true));
  yield effects.call(excelActions.mapContinuation, currentDataset.id);
}

export function* handlePauseIntent() {
  yield effects.put(StreamActions.setAutoLoadAll(false));
}

export function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(
      StreamActions.playStreamIntent.type,
      handlePlayIntent,
    ),
    yield effects.takeLatest(
      StreamActions.pauseStreamPlayingIntent.type,
      handlePauseIntent,
    ),
    yield effects.takeLatest(
      StreamActions.rewindStreamIntent.type,
      handleRewind,
    ),
  ]);
}

export default rootSaga;
