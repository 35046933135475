import { ITypeSectionProperty } from '../../types';
import i18n from '../../../../config/i18n';
import { SortControlOption } from './useConfig';

export const entitySchemaPropertiesToSortConfig = (
  input: ITypeSectionProperty[],
  sortTypesToSkip?: string[],
): SortControlOption[] => input
  .filter((item) => item.isSortable)
  .filter((item) => (sortTypesToSkip ? !sortTypesToSkip.includes(item.name.toLowerCase()) : true))
  .map((item) => ({
    // API entries are PascalCase -> we need camelCase
    key: item.name.charAt(0).toLowerCase() + item.name.slice(1),
    name: i18n.t(`sortControls:${item.name}`),
    icon: item.icon,
    iconType: item.iconType,
  }));
