import React from 'react';
import { Props, TutorialTile } from '../components/TutorialTile';
import { SerializedTutorialTile } from '../model';
import { storageFactory } from '../../../modules/Storage';

const TUTORIAL_TILES_STORAGE_KEY = 'tutorial';
const tutorialTilesStorage = storageFactory(TUTORIAL_TILES_STORAGE_KEY);

/** maps from a key used to find a translation for the copy to be displayed
 * to a boolean value deciding whether or not that message should be displayed
 */
interface StartButtonStates {
  [key: string]: boolean;
}

interface UseTutorialTileArgs extends Omit<Props, 'onStartClick'> {
  isDisplayForced?: boolean;
  key?: string;
  name?: string;
  onStartClick?: Props['onStartClick'];
  /** if any of the start states are active,then the start button should be disabled */
  startButtonStates?: StartButtonStates;
}
export const useTutorialTile = ({
  isDisplayForced,
  name,
  onStartClick,
  startButtonStates,
  ...props
}: UseTutorialTileArgs) => {
  // DEPS
  const tutorialTileSerializer = React.useMemo(
    () => name ? tutorialTilesStorage<SerializedTutorialTile>(name) : undefined,
    [name],
  );
  const serializedState: SerializedTutorialTile | undefined = React.useMemo(
    () => tutorialTileSerializer?.load(),
    [tutorialTileSerializer],
  );
  const { isViewed = false } = serializedState ?? {};

  // STATE
  const [isHidden, setIsHidden] = React.useState(false);

  // DERIVED STATE
  /** get the translation key of the first unmet requirement for the start button to be active */
  const unsatisfiedStartRequirement = React.useMemo(
    () => startButtonStates && Object.entries(startButtonStates).find(
      ([_translationKey, isActive]) => isActive,
    )?.[0],
    [startButtonStates],
  );

  const isDisplayed = !isHidden && (
    isDisplayForced
    || Boolean(unsatisfiedStartRequirement)
    || !isViewed
  );

  // CALLBACKS
  const markAsViewed = React.useCallback(
    () => {
      tutorialTileSerializer?.save({
        ...serializedState,
        isViewed: true,
      });
    },
    // NOTE: ignoring `tutorialTileState` change because it depends only on `tutorialTileSerializer`
    [tutorialTileSerializer],
  );

  const hanleStartClick = React.useCallback(
    () => {
      markAsViewed();
      setIsHidden(true);
      // NOTE: if display is not forced it means the tile is displayed only because it has not been viewed yet
      // and we can show what's hidden underneath
      if (isDisplayForced) onStartClick?.();
    },
    [isDisplayForced, markAsViewed, onStartClick],
  );

  // RESULT
  return isDisplayed && (
    <TutorialTile
      {...props}
      {...{ unsatisfiedStartRequirement }}
      onStartClick={hanleStartClick}
    />
  );
};
