import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from '../../../../constants/routes';

import FavoritesList from '../FavoritesList';
import RecentStreams from '../RecentStreams';

const Favorites = () => (
  <Switch>
    <Route
      exact
      path={routes.favorites.sharedWithMe.index}
      component={FavoritesList}
    />
    <Route
      exact
      path={routes.favorites.recentStreams.index}
      component={RecentStreams}
    />
    <Redirect to={routes.favorites.sharedWithMe.index} />
  </Switch>
);

export default Favorites;
