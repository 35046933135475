import { ILabelStyles, ITextFieldStyles } from '@fluentui/react';
import {
  IDropdownStyleProps,
  IDropdownStyles,
  ITextFieldStyleProps,
} from 'office-ui-fabric-react';
import { baseColors } from '../../../../../theme';

export const textFieldStyles =
  (invalid?: boolean) =>
  ({ focused }: ITextFieldStyleProps): Partial<ITextFieldStyles> => ({
    fieldGroup: {
      borderWidth: '1px !important',
      borderColor: invalid
        ? 'red'
        : focused
        ? baseColors.midOrange
        : baseColors.inputBorder,
      selectors: {
        '&:after': {
          display: 'none',
        },
      },
    },
    field: {},
  });

export const dropdownStyles =
  (isValid: boolean = true) =>
  ({ isOpen }: IDropdownStyleProps): Partial<IDropdownStyles> => ({
    dropdown: {
      selectors: {
        '&:after': {
          display: 'none',
        },
      },
    },
    dropdownItems: {
      maxHeight: 200,
    },
    title: {
      border: `1px solid ${
        !isValid
          ? 'red'
          : isOpen
          ? baseColors.midOrange
          : baseColors.inputBorder
      } !important`,
      selectors: {
        '&:hover': {
          borderColor: baseColors.midOrange,
        },
      },
    },
  });

export const labelStyles: Partial<ILabelStyles> = {
  root: {
    selectors: {
      '&:after': {
        paddingRight: 2,
      },
    },
  },
};
