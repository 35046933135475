import {
  IContextualMenuItem,
} from '@fluentui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ButtonMenu from '../../../../../../components/ui/ButtonMenu';
import { IColumnSortType } from '../../../../../../types/IColumn';
import { changeSortModeOfColumn } from '../../../../actions';
import { IColumnWithUuid } from '../../types';
import { COLUMN_SORT_MODE } from './constants';
import streamSelectors from '../../../../../Streams/selectors';
import { checkIfColumnSortModeChanged } from '../../../../../Streams/utils';

interface Props {
  column: IColumnWithUuid;
  onClick?(): void;
}

const ColumnSortSettings: React.FC<Props> = ({ column, onClick }) => {
  // HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // STATE
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  // DERIVED STATE
  const menuItems: IContextualMenuItem[] = React.useMemo(
    () => Object.values(COLUMN_SORT_MODE).map(
      (sortMode) => ({
        key: sortMode.value,
        text: t(sortMode.labelI18nKey),
        iconProps: {
          iconName: sortMode.icon,
        },
        canCheck: true,
      }),
    ),
    [t],
  );

  const isOrange = React.useMemo(() => checkIfColumnSortModeChanged({
    column,
    currentColumns: currentDataset?.columns,
    lastSuccessfullyMappedColumns: currentDataset?.lastSuccessfulMapping?.columns || [],
  }), [currentDataset?.lastSuccessfulMapping?.columns, column, currentDataset?.columns]);

  // CALLBACKS
  const handleItemClick = (key: string) => {
    dispatch(changeSortModeOfColumn(column, key as IColumnSortType));
  };

  return (
    <ButtonMenu
      onChangeItem={handleItemClick}
      activeItemKey={column?.sortConfig?.sortMode || null}
      items={menuItems}
      onMenuOpened={onClick}
      isOrange={isOrange}
    />
  );
};

export default ColumnSortSettings;
