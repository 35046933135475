import handleRemovedDatasets from './handleRemovedDatasets';

const listenWorksheetDeleted = () => {
  let eventResult;

  const handler = async () => {
    await handleRemovedDatasets();
  };

  const addWorksheetDeletedListener = () => Excel.run(async (ctx) => {
    try {
      const sheets = ctx.workbook.worksheets;
      eventResult = sheets.onDeleted.add(handler);
      ctx.sync();
    } catch (e) {
      console.error(e);
    }
  });

  const removeWorksheetDeletedListener = () => Excel.run(eventResult.context, async (ctx) => {
    try {
      eventResult.remove(handler);
      await ctx.sync();
      eventResult = null;
    } catch (e) {
      console.error(e);
    }
  });

  return {
    addWorksheetDeletedListener,
    removeWorksheetDeletedListener,
  };
};

export default listenWorksheetDeleted;
