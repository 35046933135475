import {
  mergeStyleSets, ITheme, ITooltipStyles, IContextualMenuItemStyleProps, IContextualMenuItemStyles, IContextualMenuStyles,
} from '@fluentui/react';
import { baseColors } from '../../../../theme';

export const classNames = mergeStyleSets({
  btnWrap: {
    position: 'relative',
    height: '100%',
  },
});

export const tooltipStyles = (theme:ITheme):Partial<ITooltipStyles> => ({
  root: {
    selectors: {
      '.ms-Callout-beak': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-beakCurtain': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-main': {
        backgroundColor: theme.palette.black,
      },
    },
  },
  content: {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
  },
});

export const itemStyles = (
  { theme } : IContextualMenuItemStyleProps,
) : Partial<IContextualMenuItemStyles> => ({
  root: {
    height: 24,
    lineHeight: 24,
    selectors: {
      ':hover': {
        backgroundColor: baseColors.hover,
      },
    },
    margin: 0,
  },
  icon: {
    fontSize: '12px!important',
    width: '16px!important',
    color: theme.palette.neutralPrimary,
    visibility: 'visible!important',
  },
  label: {
    fontSize: 13,
  },
});

export const droplistStyles: Partial<IContextualMenuStyles> = {
  root: {
    backgroundColor: 'white',
    fontSize: '12px',
    selectors: {
      '.ms-ContextualMenu-link': {
        height: '28px',
        lineHeight: '28px',
      },
      '.ms-ContextualMenu-itemText': {
        fontSize: '12px',
      },
      '.ms-ContextualMenu-icon': {
        lineHeight: '16px',
        fontSize: '16px !important',
        position: 'relative',
        top: '2px',
      },
      '.ms-ContextualMenu-list': {
        border: `1px solid ${baseColors.outlineGray}`,
      },
    },
  },
};
