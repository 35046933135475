import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import LargeThumbnailPicker from '../../../../pageTemplates/LargeThumbnailPicker';
import { ISingleLargeThumbnail } from '../../../../pageTemplates/LargeThumbnailPicker/LargeThumbnailPicker';
import { getSearchQuery } from '../../../Search/selectors';
import { matchesSearchQuery } from '../../../Search/utils';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { addNestedEntityRoute } from '../../../../utils/routes';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { routes } from '../../../../constants/routes';
import {
  getAllDesigns,
  areDesignsLoading,
} from '../../../Designer/ContentLibrary/selectors';
import { fetchAllDesigns } from '../../../Designer/ContentLibrary/actions';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { usePrevious } from '../../../../utils/usePrevious';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../App/types';
import { streamToImageThumbnail } from './uitls';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { classNames } from './styles';

const Streams = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // STATE
  const designs = useSelector(getAllDesigns);
  const streams = useSelector(selectors.getAllStreams);
  const selectedStreamId = useSelector(selectors.getSelectedBuilderStreamId);
  const isBuilderLoading = useSelector(selectors.getIsBuilderStateLoading);
  const isDesignListLoading = useSelector(areDesignsLoading);
  const searchQuery = useSelector(getSearchQuery);

  // DERIVED STATE

  const previousStreams = usePrevious(streams, []);
  const normalizedStreams = React.useMemo(
    () => normalizeById(streams),
    [streams]
  );
  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:main:cta:noDesignsAvailable': designs.length === 0,
    }),
    [designs.length]
  );

  const filteredStreams = useMemo(
    () =>
      streams.filter(
        (stream) =>
          searchQuery === '' || matchesSearchQuery(searchQuery, stream.name)
      ),
    [streams, searchQuery]
  );

  const { mappedGroups, currentSort } = useSortedFoldableGroups({
    input: filteredStreams,
  });

  const itemsToRender: ISingleLargeThumbnail[] = useMemo(
    () =>
      filteredStreams
        .map(streamToImageThumbnail)
        .sort((streamA, streamB) =>
          streamA.name.trim().localeCompare(streamB.name.trim())
        ),
    [filteredStreams, searchQuery]
  );

  // CALLBACKS
  const handleDesignClick = React.useCallback(
    (streamId: string) => {
      dispatch(
        actions.setSelectedStreamId(
          selectedStreamId === streamId ? null : streamId
        )
      );
    },
    [selectedStreamId]
  );
  const handleDesignDoubleClick = React.useCallback(
    (streamId: string) => {
      dispatch(actions.setSelectedStreamId(streamId));
      history.push(routes.builder.assets.sources);
    },
    [selectedStreamId]
  );

  const onStartClick = React.useCallback(() => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.Stream,
      parentSchemaId: TYPE_IDS.Design,
      // The Design Entity ID is submitted through the wizard, it is unknown beforeahed
      // and react router requires the path parameter to be defined
      parentEntityId: 'unknown',
    });
    const goBackPage = routes.builder.streams.index;
    history.push(path, {
      goBackPage,
      typeName: t('streams:Stream'),
    });
  }, [history]);

  // EFFECTS
  React.useEffect(() => {
    if (designs.length === 0) dispatch(fetchAllDesigns());
  }, [designs.length, dispatch]);

  React.useEffect(() => {
    // stream was added
    if (streams.length - previousStreams.length === 1) {
      const newStream = streams[streams.length - 1];

      dispatch(actions.setSelectedStreamId(newStream.id));
    }
  }, [streams]);

  React.useEffect(() => {
    dispatch(actions.fetchStreams());
  }, [dispatch]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: streams.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (isDesignListLoading) return <ItemsListShimmer />;

  if (tutorialTile && !isBuilderLoading) return tutorialTile;

  if (currentSort !== DefinedSortTypes.Name) {
    return (
      <>
        {mappedGroups.map((group) => {
          const { name, key } = group;
          const relatedStreams = group.items.map(
            (item) => normalizedStreams[item.key]
          );
          const items = relatedStreams
            .map(streamToImageThumbnail)
            .sort((streamA, streamB) =>
              streamA.name.trim().localeCompare(streamB.name.trim())
            );
          return (
            <div
              // I noticed a small lag when operating on larger lists when key was solely based on the "key" prop of group.
              // React tried to reuse the old structure. Incorporating current sort tells React to drop it if current sort changes
              key={`${key}${currentSort}`}
              className={classNames.groupWrapper}
            >
              <p className={classNames.groupTitle}>{name}</p>
              <LargeThumbnailPicker
                isLoading={isBuilderLoading}
                items={items}
                selectedItemId={selectedStreamId}
                handleClick={handleDesignClick}
                handleDoubleClick={handleDesignDoubleClick}
                emptyMessage={t('builder:noStreamsMessage')}
              />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <LargeThumbnailPicker
      isLoading={isBuilderLoading}
      items={itemsToRender}
      selectedItemId={selectedStreamId}
      handleClick={handleDesignClick}
      handleDoubleClick={handleDesignDoubleClick}
      emptyMessage={t('builder:noStreamsMessage')}
    />
  );
};

export default Streams;
