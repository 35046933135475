import React from 'react';

export interface FilePickerContextValue {
  selectedItemIndex: number | null
}

const defaultValue: FilePickerContextValue = {
  selectedItemIndex: null
}

export const FilePickerContext = React.createContext(defaultValue)
