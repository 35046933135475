import i18n from "../../../../taskpane/config/i18n";
import { Action, dialogClosed, DIALOG_CLOSED_ACTION_TYPE } from "../actions";
import { OFFICE_DIALOG_ERROR_CODES } from "../constants";
import { createErrorEvent, DialogErrorEvent, DialogErrorHandlerCreator, DialogMessageHandlerCreator, PromiseHandlers } from "./events";

// OPEN DIALOG

export interface BaseOpenDialogArgs {
  // NOTE: not all options are supported in the isolated version
  options?: Office.DialogOptions;
  url: string;
}

export interface SharedOpenDialogArgs extends BaseOpenDialogArgs, PromiseHandlers {
  createErrorHandler: DialogErrorHandlerCreator;
  createMessageHandler: DialogMessageHandlerCreator;
}

// OPEN DIALOG – OFFICE

export const openOfficeDialog = ({
  createErrorHandler,
  createMessageHandler,
  options,
  reject,
  url,
}: SharedOpenDialogArgs): void => {
  try {
    Office.context.ui.displayDialogAsync(
      url,
      // NOTE: we have to spread because... `.displayDialogAsync` mutates the options object in a way that makes it
      // not reusable with consecutive calls if a callback is provided...
      { ...options },

      dialogResult => {
        if (dialogResult.status === Office.AsyncResultStatus.Failed) {
          reject(dialogResult.error);
          return;
        }

        const dialog = dialogResult.value;

        // handle errors
        dialog.addEventHandler(
          Office.EventType.DialogEventReceived,
          createErrorHandler(dialog),
        );

        // handle messages
        const handleAction = createMessageHandler(dialog);
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          event => {
            if (!('message' in event)) {
              reject(new Error('dialog:error:unsupportedEvent'));
              return;
            }

            if (event.origin !== window.location.origin) {
              reject(new Error(i18n.t('dialog:error:originMismatch')));
              return;
            }

            try {
              const action = JSON.parse(event.message);
              handleAction({ action });
            } catch (error) {
              reject(error);
            }
          },
        );
      },
    );
  } catch (error) {
    reject(error);
  }
};

// OPEN DIALOG – ISOLATED

export const DIALOGS_EXPECTED_TO_BE_OPEN = new Set<Window>();

export const openIsolatedDialog = ({
  createErrorHandler,
  createMessageHandler,
  options,
  reject,
  url,
}: SharedOpenDialogArgs): void => {
  let dialogClosedMonitoringInterval;

  try {
    if (options?.promptBeforeOpen && !confirm(i18n.t('dialog:prompt'))) return;

    const windowFeatures = options && [
      options.height && `height=${options.height}`,
      options.width && `width=${options.height}`,
    ].filter(Boolean).join(',');

    const dialog = window.open(
      url,
      undefined,
      windowFeatures
    );
    DIALOGS_EXPECTED_TO_BE_OPEN.add(dialog);

    // handle events
    const handleError = createErrorHandler(dialog);
    const handleAction = createMessageHandler(dialog);
    // NOTE: actually the events that come in might be of many different forms (react devtools, webpack, etc.)
    // so need to watch out for those
    // TODO: we need a better way to recognized our own messages, a potential solution could be to have
    // error messages have a `type` property as well and force all our events (both error and messages)
    // to have an `@eyko/` prefix in their `type` property
    // actions/messages would potentially have an additional `payload` prop and the errors would have `error`
    // OR it might be possible to clean up using:
    // [channel messaging API](https://developer.mozilla.org/en-US/docs/Web/API/Channel_Messaging_API/Using_channel_messaging)
    // that would simplify this message handler quite a bit
    const onMessageReceived = (event: MessageEvent<Action<string, unknown> | DialogErrorEvent>) => {
      // the only messages supported from our own window is the special case of the `DialogClosedAction`
      // the rest goes through the normal flow
      if (event.source === window && 'type' in event.data && event.data.type === DIALOG_CLOSED_ACTION_TYPE) {
        // handle the special action type and clean up the listener if the window has ended it's life as expected
        window.removeEventListener('message', onMessageReceived);
      }

      // ignore messages from other windows unless it's the dialog we just opened or it's an error
      if (dialog !== event.source && !('error' in event.data)) return;

      // make sure we're still communicating within the same origin
      if (event.origin !== window.location.origin) {
        reject(new Error(i18n.t('dialog:error:originMismatch')));
        return;
      }

      // handle errors
      if ('error' in event.data) {
        handleError(event.data);
        return;
      }

      // handle messages
      const action = event.data;

      // just for additional safety so that we don't try to handle some 3rd party actions (webpack, react-devtools)
      // that make completely no sense for us (as there is only one channel for ALL messages within a window :S)
      if ('type' in action) handleAction({ action });
    };
    window.addEventListener('message', onMessageReceived);

    // CLEANUP when the dialog closes
    const monitorDialogClosed = () => {
      if (!dialog.closed) return;

      // if the dialog closed but is still on the list that means the closing wasn't triggered
      // by the code (as it would've removed the expectancy) and we should report an error
      if (DIALOGS_EXPECTED_TO_BE_OPEN.has(dialog)) {
        window.postMessage(
          createErrorEvent({ error: OFFICE_DIALOG_ERROR_CODES.DialogClosed }),
          window.location.origin,
        );
        // error reported, we can drop the reference
        DIALOGS_EXPECTED_TO_BE_OPEN.delete(dialog);
      }

      // we should always inform the host that the dialog closed so all the related processes can be cleaned up
      window.postMessage(
        dialogClosed(),
        window.location.origin,
      );

      clearInterval(dialogClosedMonitoringInterval);
    };
    dialogClosedMonitoringInterval = setInterval(monitorDialogClosed, 500);
  } catch (error) {
    clearInterval(dialogClosedMonitoringInterval);
    reject(error);
  }
};
