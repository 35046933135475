import { createReducer } from '@reduxjs/toolkit';
import { IDesign } from '../types';

import {
  fetchAllDesigns, setSelectedDesignId, designSuccessfullyDeleted,
} from './actions';

export interface ContentLibraryState {
  designs: IDesign[],
  selectedDesignId: string | null
  isLoading: boolean,

}

export const initialState:ContentLibraryState = {
  designs: [],
  selectedDesignId: null,
  isLoading: false,
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(setSelectedDesignId, (state, { payload }) => ({
    ...state,
    selectedDesignId: payload,
  }))
  .addCase(designSuccessfullyDeleted, (state) => ({
    ...state,
    selectedDesignId: null,
  }))
  .addCase(fetchAllDesigns.pending, (state) => ({
    ...state,
    isLoading: true,
  }))
  .addCase(fetchAllDesigns.rejected, (state) => ({
    ...state,
    isLoading: false,
  }))
  .addCase(fetchAllDesigns.fulfilled, (state, action) => ({
    ...state,
    isLoading: false,
    designs: action.payload || [],
  })));

export default reducer;
