import React from 'react';
import { useSelector } from 'react-redux';

import { IColumn } from '../types/IColumn';
import { getIsCurrentGroupTableHasValueSelected, getSectionByType } from '../modules/GroupTable/selectors';
import { ObjectTypes } from '../modules/App/types';
import { getActiveObjectType } from '../modules/App/selectors';
import streamSelectors from '../modules/Streams/selectors';
import { SECTION_TYPES } from '../modules/GroupTable/constants';
import { generateColumnUuid } from '../modules/ColumnsSequencing/utils';
import { getAllAssociatedDatasetColumns, isColumnNumeric } from '../modules/Streams/components/StreamerColumn/utils';

interface Params {
  column: IColumn;
}

export default ({ column }: Params) => {
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const activeObjectType = useSelector(getActiveObjectType);
  const isAtLeastOneGroupTableValueSelected = useSelector(
    getIsCurrentGroupTableHasValueSelected,
  );
  const groupTableValuesSection = useSelector(getSectionByType(
    SECTION_TYPES.values,
    currentDataset?.id,
  ));

  const isGroupTable = currentDataset
    ? currentDataset.type === ObjectTypes.GROUP_TABLE
    : activeObjectType === ObjectTypes.GROUP_TABLE;

  const isTheOnlyValueAssociatedColumnInGroupTable = React.useMemo(() => {
    if (!currentDataset?.columns) return false;

    const allAggregationsRelatedToClickedColumn = getAllAssociatedDatasetColumns({
      column,
      dataset: currentDataset,
    });

    const associatedAggregationInValuesCount = allAggregationsRelatedToClickedColumn.reduce(
      (accumulator, columnWithAggregation) => {
        const isInValues = groupTableValuesSection.some(
          (item) => item.columnUuid === generateColumnUuid(columnWithAggregation),
        );

        return accumulator + (isInValues ? 1 : 0);
      },
      0,
    );

    return associatedAggregationInValuesCount === groupTableValuesSection.length;
  }, [column, currentDataset?.id, groupTableValuesSection]);

  return (isGroupTable
  && !isColumnNumeric(column)
  && !isAtLeastOneGroupTableValueSelected) || (isGroupTable && isTheOnlyValueAssociatedColumnInGroupTable);
};
