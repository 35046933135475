import React from 'react';
import { baseColors } from '../../../../../theme';

export const wrapperStyles: React.CSSProperties = {
  backgroundColor: baseColors.orange,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '32px',
  width: '32px',
};
