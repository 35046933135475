import React from 'react';
import { Text } from '@fluentui/react';
import { styles, textWrapperStyles } from './styles';

type Props ={
  match: RegExpMatchArray
  testid?: string
  isBold?: boolean
};

const SearchMatch: React.FC<Props> = ({ match, isBold, testid }:Props) => (
  <Text style={textWrapperStyles(isBold)}>
    {match[1]}
    <span data-testid={testid} style={styles.matched}>
      {match[2]}
    </span>
    {match[3]}
  </Text>
);

export default SearchMatch;
