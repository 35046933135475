import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Layout';

export const TUTORIAL_TILE_KEY = 'streamer-layout';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:streamer:layout:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:streamer:layout:items:organizeYourData:description',
      icon: 'SynergiesLayout',
      titleI18nKey: 'tutorialTiles:streamer:layout:items:organizeYourData:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:streamer:layout:items:sort:description',
      icon: 'Ascending',
      titleI18nKey: 'tutorialTiles:streamer:layout:items:sort:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:streamer:layout:items:sequence:description',
      icon: 'Move',
      titleI18nKey: 'tutorialTiles:streamer:layout:items:sequence:title',
    },
  ],
};
