import { omit } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAssets } from '../../../actions';
import {
  getPreviouslyFetchedAssets,
  getSelectedAssets,
} from '../../../selectors';
import { StreamerAsset } from '../../../types';
import ReorderDragAndDrop from '../ReorderDragAndDrop/ReorderDragAndDrop';
import { StreamerAssetWithUiid } from '../ReorderDragAndDrop/types';
import { generateReorderUiid } from '../ReorderDragAndDrop/utils';

export const AssetsSequencing = () => {
  // DEPS
  const dragDropId = 'columns-reorder';
  const dispatch = useDispatch();

  // STATE
  const selectedAssets = useSelector(getSelectedAssets);
  const previouslyFetchedAssets = useSelector(getPreviouslyFetchedAssets);

  // DERIVED STATE
  const items: StreamerAssetWithUiid[] = React.useMemo(
    () =>
      selectedAssets.map((asset) => ({
        ...asset,
        uuid: generateReorderUiid(asset),
      })),
    [selectedAssets]
  );

  const initialColumnOrder = React.useMemo(
    () =>
      previouslyFetchedAssets.reduce<
        Record<ReturnType<typeof generateReorderUiid>, number>
      >(
        (accumulator, current, idx) => ({
          ...accumulator,
          [generateReorderUiid(current)]: idx,
        }),
        {}
      ),
    [previouslyFetchedAssets]
  );

  // CALLBACKS
  const checkIfOrderChanged = React.useCallback(
    (item, index) =>
      previouslyFetchedAssets.length > 0
        ? initialColumnOrder[item.uuid] !== index
        : false,
    []
  );

  const handleUpdate = React.useCallback(
    (sequencedAssets: StreamerAssetWithUiid[]) => {
      const streamerAssets: StreamerAsset[] = sequencedAssets.map((a) =>
        omit(a, 'uuid')
      );
      dispatch(setSelectedAssets(streamerAssets));
    },
    [selectedAssets]
  );

  return (
    <ReorderDragAndDrop
      {...{ checkIfOrderChanged, dragDropId, items }}
      onUpdate={handleUpdate}
    />
  );
};
