import React from 'react';
import {
  Stack,
  Shimmer,
  ShimmerElementType,
} from '@fluentui/react';

const ItemsListShimmer = () => {
  const singleStreamHeight = 32;

  const shimmerMockingStreamItem = [
    { type: ShimmerElementType.line, height: singleStreamHeight },
  ];

  return (
    <Stack tokens={{ childrenGap: 10 }} style={{ padding: '4px' }}>
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
      <Shimmer shimmerElements={shimmerMockingStreamItem} />
    </Stack>
  );
};

export default ItemsListShimmer;
