import React from 'react';
import {
  IButtonProps,
  IconButton as Button,
  IButtonStyles,
  ITheme,
  getTheme,
  TooltipDelay,
  Text,
  DirectionalHint,
} from '@fluentui/react';
import Icon from '@mdi/react';
import { TooltipHost } from '../ui';
import { counterBadgeStyles, stackStyles, tooltipStyles } from './styles';
import { AuthContext } from '../../modules/Auth-v2/context';

interface IButtonPropsOverrides {
  styles?: ((theme: ITheme, active?: boolean) => IButtonStyles) | IButtonStyles;
}

export interface IconButtonProps
  extends Omit<IButtonProps, keyof IButtonPropsOverrides>,
    IButtonPropsOverrides {
  active?: boolean;
  isVisible?: boolean;
  count?: number | string;
  disabled?: boolean;
  shouldDisableForAnonymous?: boolean;
  shouldHideOnDisabled?: boolean;
  directionalHint?: DirectionalHint;
  tooltip?: any;
  'data-testid'?: string;
  form?: string;
  mdiIcon?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  active = false,
  isVisible = true,
  count,
  disabled,
  shouldDisableForAnonymous,
  shouldHideOnDisabled,
  styles,
  tooltip,
  directionalHint,
  mdiIcon,
  ...props
}) => {
  const { isSignedIn: isLoggedIn } = React.useContext(AuthContext);
  const theme = getTheme();
  const shouldDisplayCounter = Boolean(
    (count && count > 0) || typeof count === 'string'
  );

  if (!isVisible) {
    return null;
  }

  // we are not showing buttons that are not clickabble at all
  if (shouldDisableForAnonymous && !isLoggedIn) return null;

  const computedStyles =
    typeof styles === 'function' ? styles(theme, active) : styles;

  if (shouldHideOnDisabled && disabled) return null;

  return (
    <TooltipHost
      hostClassName={stackStyles.root}
      content={tooltip}
      delay={TooltipDelay.long}
      directionalHint={directionalHint}
      tooltipProps={{
        styles: tooltipStyles(theme),
      }}
    >
      <Button
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        disabled={disabled}
        styles={computedStyles}
      >
        {mdiIcon && <Icon className="mdiIcon" path={mdiIcon} />}
        {shouldDisplayCounter && (
          <Text
            className={counterBadgeStyles(theme).root}
            data-testid="notifications-count"
          >
            {count}
          </Text>
        )}
      </Button>
    </TooltipHost>
  );
};

export default IconButton;
