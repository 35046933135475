import { DataType } from '../../../../../api/model/schemas/DataType';
import i18n from '../../../../../config/i18n';
import { ProcessStatus } from '../../../../../api/model/schemas/ProcessStatus';

export const NEXT_INGESTION_STATE_MAP = {
  [ProcessStatus.NotProcessed]: ProcessStatus.PendingProcess,
  [ProcessStatus.PendingProcess]: ProcessStatus.NotProcessed,
  [ProcessStatus.PendingUnprocess]: ProcessStatus.Processed,
  [ProcessStatus.Processed]: ProcessStatus.PendingUnprocess,
} as const;

// TO DO make this dynamic after below will be finished
// https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/5757
export const DATA_TYPE_CHANGES_OPTIONS: Record<DataType, DataType[]> = {
  [DataType.Boolean]: [
    DataType.NumericDecimal,
    DataType.Text,
    DataType.NumericInteger,
  ],
  [DataType.DateTime]: [DataType.Text],
  [DataType.Geography]: [DataType.Text],
  [DataType.Json]: [DataType.Json],
  [DataType.NumericDecimal]: [DataType.Text],
  [DataType.NumericInteger]: [
    DataType.DateTime,
    DataType.NumericDecimal,
    DataType.Text,
  ],
  [DataType.Text]: [
    DataType.Boolean,
    DataType.DateTime,
    DataType.NumericDecimal,
    DataType.Json,
    DataType.NumericInteger,
  ],
  [DataType.Unknown]: [],
};

// add i18n
export const allDataTypeOptionsInIngestion = () => [
  {
    name: i18n.t('streams:Boolean'),
    key: DataType.Boolean as string,
    iconProps: {
      iconName: 'EykoBoolean',
    },
  },
  {
    name: i18n.t('streams:Date and Time'),
    key: DataType.DateTime as string,
    iconProps: {
      iconName: 'DateTime',
    },
  },

  {
    name: i18n.t('streams:Decimal Number'),
    key: DataType.NumericDecimal as string,
    iconProps: {
      iconName: 'Decimals',
    },
  },
  {
    name: i18n.t('streams:Geography'),
    key: DataType.Geography as string,
    iconProps: {
      iconName: 'POI',
    },
  },
  {
    name: i18n.t('streams:JSON Document'),
    key: DataType.Json as string,
    iconProps: {
      iconName: 'EykoJsonType',
    },
  },
  {
    name: i18n.t('streams:Text'),
    key: DataType.Text as string,
    iconProps: {
      iconName: 'TextField',
    },
  },
  {
    name: i18n.t('streams:Whole Number'),
    key: DataType.NumericInteger as string,
    iconProps: {
      iconName: 'NumberField',
    },
  },
];
