import { IButtonStyles } from '@fluentui/react';
import { ISpinnerStyles, IStackStyles } from 'office-ui-fabric-react';
import { infoButtonClassname } from '../../../hooks/use-information-dialog/styles';
import { baseColors } from '../../../theme';
import { renameButtonClassName } from '../../EditableFoldableListItem/styles';

interface ButtonContainerStylesArgs {
  isSticky: boolean;
  isInfoOpen: boolean;
  isSelected: boolean;
  hasLeftSlotSpacer: boolean;
  isOpen: boolean;
}
export const buttonContainerStyles = ({
  isSticky,
  isInfoOpen,
  isSelected,
  hasLeftSlotSpacer,
  isOpen,
}: ButtonContainerStylesArgs): IStackStyles => {
  const stickyStyles = isOpen
    ? {
        position: 'sticky' as const,
        top: -1,
        left: 0,
        zIndex: 1,
      }
    : {};

  return {
    root: {
      paddingLeft: hasLeftSlotSpacer ? '28px' : 0,
      backgroundColor: isSelected
        ? baseColors.selected
        : isSticky
        ? baseColors.white
        : 'transparent',
      paddingRight: '2px',
      alignItems: 'center',
      height: '28px',
      minHeight: '28px',
      ...(isSticky ? stickyStyles : {}),
      selectors: {
        [`.${infoButtonClassname}`]: {
          opacity: isInfoOpen ? 1 : 0,
        },
        '&:hover': {
          backgroundColor: isSelected
            ? baseColors.selectedWithHover
            : baseColors.hover,
        },
        [`&:hover .${infoButtonClassname}`]: {
          opacity: 1,
        },
        [`.${renameButtonClassName}`]: {
          opacity: 0,
        },
        [`&:hover .${renameButtonClassName}`]: {
          opacity: 1,
        },
      },
    },
  };
};

interface ArrowBtnStylesArgs {
  isSelected: boolean;
  isSticky: boolean;
}
export const arrowBtnStyles = ({
  isSelected,
  isSticky,
}: ArrowBtnStylesArgs): IButtonStyles => ({
  root: {
    // height: '100%',
    padding: 0,
    width: 24,
    minWidth: 24,
    height: 24,
    borderRadius: 4,
    margin: '0 2px',
    color: baseColors.iconColor,
    backgroundColor: isSticky ? baseColors.white : 'transparent',
  },
  rootHovered: {
    color: 'inherit',
    backgroundColor: isSelected
      ? baseColors.hover
      : baseColors.selectedWithHover,
  },
  rootPressed: {
    color: 'inherit',
    backgroundColor: isSelected
      ? baseColors.selectedWithHover
      : baseColors.selected,
  },
  icon: {
    fontSize: '16px',
    color: baseColors.iconColor,
    backgroundColor: 'transparent',
    // transform: isOpen ? 'rotateZ(-180deg)' : '',
    transition: 'transform .2s ease-in-out',
  },
});

const computeTextColor = ({
  isDisabled,
  isOrange,
}: {
  isDisabled: boolean;
  isOrange: boolean;
}) => {
  if (isDisabled) {
    return `${baseColors.iconColor}80`;
  }

  return isOrange ? baseColors.orange : baseColors.textColor;
};

interface GetNameBtnStylesArgs {
  isOrange: boolean;
  isDisabled: boolean;
}
export const getNameBtnStyles = ({
  isOrange,
  isDisabled,
}: GetNameBtnStylesArgs): IButtonStyles => ({
  root: {
    flexGrow: 1,
    fontSize: '12px',
    paddingLeft: 0,
    margin: 0,
    backgroundColor: 'transparent',
    color: computeTextColor({
      isDisabled,
      isOrange,
    }),
    position: 'relative',
    maxWidth: 'calc(100% - 32px)',
  },
  rootHovered: {
    backgroundColor: 'transparent',
    color: computeTextColor({
      isDisabled,
      isOrange,
    }),
  },
  get rootPressed() {
    return this.rootHovered;
  },
  flexContainer: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    selectors: {
      span: {
        fontWeight: 600,
      },
    },
  },
  label: {
    fontWeight: 600,
  },
});

export const textStyles: React.CSSProperties = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100%',
};
export const selectionStyles: React.CSSProperties = {
  fontWeight: 300,
  fontSize: '9px',
  marginLeft: '2px',
};

export const spinnerStyles: ISpinnerStyles = {
  root: {
    width: '32px',
    height: '100%',
  },
  circle: {
    width: '16px',
    height: '16px',
  },
};
