import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAssets } from '../../../actions';
import {
  getPreviouslyFetchedAssets,
  getSelectedAssets,
} from '../../../selectors';
import { StreamerAsset } from '../../../types';
import ReorderDragAndDrop from '../ReorderDragAndDrop/ReorderDragAndDrop';
import { StreamerAssetWithUiid } from '../ReorderDragAndDrop/types';
import {
  generateReorderUiid,
  generateSortOrder,
} from '../ReorderDragAndDrop/utils';

export const AssetsPrecedence = () => {
  // DEPS
  const dragDropId = 'columns-sort-order';
  const dispatch = useDispatch();

  // STATE
  const selectedAssets = useSelector(getSelectedAssets);
  const previouslyFetchedAssets = useSelector(getPreviouslyFetchedAssets);

  // DERIVED STATE
  const items: StreamerAssetWithUiid[] = React.useMemo(
    () =>
      selectedAssets
        .map((asset) => ({
          ...asset,
          uuid: generateReorderUiid(asset),
        }))
        .sort((a, b) => a?.sortPosition - b?.sortPosition),
    [selectedAssets]
  );

  const initialColumnPrecedence = React.useMemo(
    () =>
      previouslyFetchedAssets.reduce<
        Record<ReturnType<typeof generateReorderUiid>, number>
      >(
        (accumulator, current, idx) => ({
          ...accumulator,
          [generateReorderUiid(current)]:
            current?.sortPosition ??
            generateSortOrder({
              assetIndex: idx,
              assetLength: previouslyFetchedAssets.length,
            }),
        }),
        {}
      ),
    [previouslyFetchedAssets]
  );

  // CALLBACKS
  const checkIfOrderChanged = React.useCallback(
    (item) =>
      previouslyFetchedAssets.length > 0 && item?.sortPosition
        ? initialColumnPrecedence[item.uuid] !== item.sortPosition
        : false,
    []
  );

  const handleUpdate = React.useCallback(
    (sequencedAssets: StreamerAssetWithUiid[]) => {
      const streamerAssets: StreamerAsset[] = selectedAssets.map((a) => ({
        ...a,
        sortPosition: generateSortOrder({
          assetIndex: sequencedAssets.findIndex(
            (sequencedAsset) => sequencedAsset.uuid === generateReorderUiid(a)
          ),
          assetLength: sequencedAssets.length,
        }),
      }));

      dispatch(setSelectedAssets(streamerAssets));
    },
    [selectedAssets]
  );

  return (
    <ReorderDragAndDrop
      {...{ checkIfOrderChanged, dragDropId, items }}
      onUpdate={handleUpdate}
    />
  );
};
