import React from 'react';
import { IContextualMenuItemProps, Icon } from '@fluentui/react';
import MdiIcon from '@mdi/react';
import * as mdiJs from '@mdi/js';
import { camelCase, flow, upperFirst } from 'lodash';
import { itemIconStyles, checkIconStyles } from './styles';
import DataSourceIcon from '../../DataSourceIcon';
import { numberToHexColor } from '../ColorPicker/utils';
import { IconType } from '../../../modules/Designer/types';
import { baseColors } from '../../../theme';

export const onRenderSourceIcon = ({
  item: {
    data: { color },
    checked,
  },
}: IContextualMenuItemProps) => (
  <>
    <Icon iconName={checked ? 'Accept' : null} styles={checkIconStyles} />

    <DataSourceIcon color={numberToHexColor(color)} />
  </>
);

const renderFluentIcon = (iconName: string) => (
  <Icon iconName={iconName} styles={itemIconStyles} />
);
const renderSvgIcon = (iconName: string) => (
  <div
    /* eslint-disable-next-line react/no-danger */
    dangerouslySetInnerHTML={{ __html: iconName }}
    style={{ display: 'flex' }}
  />
);
/**  we receive icon as "shape-outline" from BE, but we need mdiShapeOutline */
export const materialIconNameToMdi = (iconName: string) =>
  `mdi${flow(camelCase, upperFirst)(iconName)}`;

export const renderMaterialIcon = (iconName: string) => (
  <MdiIcon
    path={mdiJs[materialIconNameToMdi(iconName)]}
    style={{ fontSize: 16, width: 16, color: baseColors.iconColor }}
  />
);

const renderIconByType: Record<IconType, (iconName: string) => JSX.Element> = {
  [IconType.Fabric]: renderFluentIcon,
  [IconType.MaterialDesign]: renderMaterialIcon,
  [IconType.SVG]: renderSvgIcon,
  [IconType.None]: renderSvgIcon,
};

export const onRenderIcon = ({ item }: IContextualMenuItemProps) => {
  const iconName = item?.iconProps?.iconName;
  const iconType = item?.data?.iconType as IconType;

  const renderFunction = renderIconByType[iconType];

  return (
    <>
      <Icon
        iconName={item.checked ? 'Accept' : null}
        styles={checkIconStyles}
      />
      {iconName && renderFunction(iconName)}
    </>
  );
};
