import isEqual from 'lodash/isEqual';

import { IColumn } from '../../types/IColumn';
import { IStreamFilter } from '../../types/IStreamFilter';
import { compileStreamFiltersToRequest } from '../StreamFilters/utils';
import { ILastMappedGroupTableDesign } from '../../types/IDataset';
import { generateColumnUuid } from '../ColumnsSequencing/utils';
import { IGroupTable } from '../GroupTable/types';
import { SECTION_TYPES } from '../GroupTable/constants';
import { IRequestDataElement, IRequestReturnElement } from './api';

interface QueuedComparisonParams {
  column: IColumn;
  currentColumns: IColumn[];
  lastSuccessfullyMappedColumns: IColumn[];
  shouldCheckBySpecificAggregation?: boolean
}

const areColumnsEqualForMapping = (colA: IColumn, colB: IColumn) => colA.id === colB.id
  && colA.parentEntityName === colB.parentEntityName
  && colA?.isHidden === colB?.isHidden
  && colA.formatType === colB.formatType;

export const areColumnsEqual = (colA: IColumn, colB: IColumn) => colA.id === colB.id;

export const isRequestColEqualCol = (
  reqColA: IRequestDataElement,
  colB: IColumn,
) => reqColA.streamElementId === colB.id
  && reqColA.aggregation === colB.aggregation;

const compareUuids = (colA: IColumn, colB: IColumn) => generateColumnUuid(colA) === generateColumnUuid(colB);

export const checkIfColumnIsQueuedForMapping = ({
  column: checkedColumn,
  currentColumns,
  lastSuccessfullyMappedColumns,
  shouldCheckBySpecificAggregation,
}: QueuedComparisonParams) => {
  const columnComparisonFunction = shouldCheckBySpecificAggregation ? compareUuids : areColumnsEqualForMapping;

  const indexInCurrentColumns = currentColumns.findIndex((c) => columnComparisonFunction(checkedColumn, c));
  const indexInLastSuccessfullyMappedColumns = lastSuccessfullyMappedColumns.findIndex(
    (c) => columnComparisonFunction(checkedColumn, c),
  );

  const isInCurrentColumns = indexInCurrentColumns !== -1;
  const isInLastSuccessfullyMappedColumns = indexInLastSuccessfullyMappedColumns !== -1;

  return (
    (!isInLastSuccessfullyMappedColumns && isInCurrentColumns)
  );
};
export const checkIfColumnWasSequenced = ({
  column: checkedColumn,
  currentColumns,
  lastSuccessfullyMappedColumns,
  shouldCheckBySpecificAggregation,
}: QueuedComparisonParams) => {
  const columnComparisonFunction = shouldCheckBySpecificAggregation ? compareUuids : areColumnsEqualForMapping;

  const indexInCurrentColumns = currentColumns.findIndex((c) => columnComparisonFunction(checkedColumn, c));
  const indexInLastSuccessfullyMappedColumns = lastSuccessfullyMappedColumns.findIndex(
    (c) => columnComparisonFunction(checkedColumn, c),
  );

  const isInCurrentColumns = indexInCurrentColumns !== -1;
  const isInLastSuccessfullyMappedColumns = indexInLastSuccessfullyMappedColumns !== -1;

  const wasColumnSequenced = isInCurrentColumns
  && isInLastSuccessfullyMappedColumns
  && indexInCurrentColumns !== indexInLastSuccessfullyMappedColumns;

  return wasColumnSequenced;
};
export const checkIfColumnSortModeChanged = ({
  column: checkedColumn,
  currentColumns,
  lastSuccessfullyMappedColumns,
  shouldCheckBySpecificAggregation,
}: QueuedComparisonParams) => {
  const columnComparisonFunction = shouldCheckBySpecificAggregation ? compareUuids : areColumnsEqualForMapping;

  const currentColumn = currentColumns.find((c) => columnComparisonFunction(checkedColumn, c));
  const columnInPreviousMapping = lastSuccessfullyMappedColumns.find(
    (c) => columnComparisonFunction(checkedColumn, c),
  );

  if (currentColumn?.sortConfig && columnInPreviousMapping?.sortConfig) {
    return currentColumn.sortConfig.sortMode !== columnInPreviousMapping.sortConfig.sortMode;
  }

  if (
    (!currentColumn?.sortConfig || currentColumn?.sortConfig?.sortMode === null)
    && (!columnInPreviousMapping?.sortConfig || columnInPreviousMapping?.sortConfig?.sortMode === null)
  ) {
    return false;
  }

  return true;
};

export const checkIfColumnSortPositionChanged = ({
  column: checkedColumn,
  currentColumns,
  lastSuccessfullyMappedColumns,
  shouldCheckBySpecificAggregation,
}: QueuedComparisonParams) => {
  const columnComparisonFunction = shouldCheckBySpecificAggregation ? compareUuids : areColumnsEqualForMapping;

  const currentColumn = currentColumns.find((c) => columnComparisonFunction(checkedColumn, c));
  const columnInPreviousMapping = lastSuccessfullyMappedColumns.find(
    (c) => columnComparisonFunction(checkedColumn, c),
  );

  if (currentColumn?.sortConfig?.sortPosition && columnInPreviousMapping?.sortConfig?.sortPosition) {
    return currentColumn.sortConfig.sortPosition !== columnInPreviousMapping.sortConfig.sortPosition;
  }

  if (
    (!currentColumn?.sortConfig || !currentColumn?.sortConfig?.sortPosition)
    && (!columnInPreviousMapping?.sortConfig || !columnInPreviousMapping?.sortConfig?.sortPosition)
  ) {
    return false;
  }

  return true;
};

export const checkIfColumnIsQueuedForDeletion = ({
  column: checkedColumn,
  currentColumns,
  lastSuccessfullyMappedColumns,
}: QueuedComparisonParams) => {
  const isInCurrentColumns = currentColumns.some(
    (c) => areColumnsEqualForMapping(checkedColumn, c),
  );
  const isInLastSuccessfullyMappedColumns = lastSuccessfullyMappedColumns.some(
    (c) => areColumnsEqualForMapping(checkedColumn, c),
  );

  return isInLastSuccessfullyMappedColumns && !isInCurrentColumns;
};

export const checkIfCountOfColumnAggregationsChanged = ({
  column: checkedColumn,
  currentColumns,
  lastSuccessfullyMappedColumns,
}: QueuedComparisonParams) => {
  try {
    const currentlySelectedAggregations = currentColumns
      .filter((c) => areColumnsEqualForMapping(checkedColumn, c))
      .map((c) => c.aggregation);
    const previouslySelectedAggregations = lastSuccessfullyMappedColumns
      .filter((c) => areColumnsEqualForMapping(checkedColumn, c))
      .map((c) => c.aggregation);

    return !isEqual(
      currentlySelectedAggregations,
      previouslySelectedAggregations,
    );
  } catch (_) {
    // DO nothing - fail silently
    // avoid crashing when changing dataset to empty
  }

  return false;
};

interface GroupTableSectionColumnPositionComarisionParams {
  checkedColumn: IColumn;
  currentDatasetColumns?: IColumn[];
  currentGroupTableConfig?: IGroupTable;
  lastMappedGroupTableConfig?: ILastMappedGroupTableDesign;
}
export const groupTableConfigToColumnUuidsBySectionType = (
  config?: IGroupTable | ILastMappedGroupTableDesign,
) => Object.keys(SECTION_TYPES).reduce((acc, currentValue) => {
  if (!config) {
    return {
      ...acc,
    };
  }

  const sectionToUuidsBySection = config[currentValue].reduce(
    (obj, item, index) => Object.assign(
      obj, { [item.columnUuid]: { sectionType: currentValue, index } },
    ),
    {},
  );

  return {
    ...acc,
    ...sectionToUuidsBySection,
  };
}, {});

export const checkIfColumnChangedSectionInGroupTableNotIncludingAggregationType = ({
  checkedColumn,
  currentDatasetColumns,
  currentGroupTableConfig,
  lastMappedGroupTableConfig,
}: GroupTableSectionColumnPositionComarisionParams): boolean => {
  const allColumnAggregationUuids = currentDatasetColumns
    ?.filter((c) => areColumnsEqualForMapping(checkedColumn, c))
    .map((c) => c.aggregation)
    .map((aggregation) => generateColumnUuid({
      ...checkedColumn,
      aggregation,
    }));

  if (!lastMappedGroupTableConfig) return false;

  const currentConfigToColumnsUuidsBySectionType = groupTableConfigToColumnUuidsBySectionType(
    currentGroupTableConfig,
  );
  const previousConfigToColumnsUuidsBySectionType = groupTableConfigToColumnUuidsBySectionType(
    lastMappedGroupTableConfig,
  );

  return allColumnAggregationUuids?.some(
    (columnUuid) => !isEqual(
      currentConfigToColumnsUuidsBySectionType[columnUuid],
      previousConfigToColumnsUuidsBySectionType[columnUuid],
    ),
  ) ?? false;
};

export const generateDefaultSortOrder = ({
  columnsLength,
  indexOfColumn,
}: {
  columnsLength :number,
  indexOfColumn: number
}) => (columnsLength - indexOfColumn) * -1;

export const checkIfSpecyficColumnAggregationChangedSectionInGroupTable = ({
  checkedColumn,
  currentGroupTableConfig,
  lastMappedGroupTableConfig,
}: GroupTableSectionColumnPositionComarisionParams) => {
  const columnUuid = generateColumnUuid(checkedColumn);
  const currentConfigToColumnsUuidsBySectionType = groupTableConfigToColumnUuidsBySectionType(
    currentGroupTableConfig,
  );
  const previousConfigToColumnsUuidsBySectionType = groupTableConfigToColumnUuidsBySectionType(
    lastMappedGroupTableConfig,
  );

  return !isEqual(currentConfigToColumnsUuidsBySectionType[columnUuid], previousConfigToColumnsUuidsBySectionType[columnUuid]);
};

const filtersToRequestFilters = (filters: IStreamFilter[], requestData: IRequestDataElement[]) => {
  if (!filters || filters.length === 0) return undefined;

  return {
    type: 'Group',
    groupFilterType: 'And',
    groupFilters: filters.map((f) => ({
      type: 'Field',
      fieldId: requestData.find((c) => isRequestColEqualCol(c, f.column))?.id,
      fieldFilters: [compileStreamFiltersToRequest(f)],
    })),
  };
};

const generatePseudoUuid = (arrIndex:number) => {
  const BASE_PSEUDO_UUID = '00000000-0000-0000-0000-000000000000';

  return `${BASE_PSEUDO_UUID.slice(0, BASE_PSEUDO_UUID.length - String(arrIndex).length)}${arrIndex}`;
};

export const colToRequestCol = (
  col: IColumn,
  index: number,
): IRequestDataElement => ({
  // we need an unique uuid-like id of a requested column,
  // this id cannot change while we are requesting continuation based on a token
  id: col?.ref || generatePseudoUuid(index + 1),
  aggregation: col.aggregation,
  streamElementId: col.id,
  streamElementTypeId: col.streamElementTypeId,
  filters: col.filters || [],
  sortMode: col?.sortConfig?.sortMode || null,
  sortPosition: col?.sortConfig?.sortPosition || null,
});

export const columnsToRequestElements = (
  columns: IColumn[],
  filters: IStreamFilter[] = [],
  columnFilters: IColumn[] = [],
): {
  data: IRequestDataElement[];
  result: IRequestReturnElement[];
  filter: any;
} => {
  const data = [...columns, ...columnFilters].map(colToRequestCol);

  filters.forEach((filter, index) => {
    const columnExists = data.some((d) => isRequestColEqualCol(d, filter.column));

    if (!columnExists) {
      // we need to have unique col names if we are requesting filters
      data.push(colToRequestCol(filter.column, data.length + index));
    }
  });

  const result: IRequestReturnElement[] = data
    // Data holds the set of data that the query should work with. It allows you to set filters etc.
    // Columns is what should be visible to the user and is a subset of Data
    // when we iterate over filters, we are adding extra columns to the data, which might be not visible to the user
    .slice(0, columns.length)
    .map((col, index, arr) => ({
      id: col.id,
      // we assign sortMode only if it has value
      // API sort order goes from low to high values
      // so if we are explicitly setting sortMode,
      // we want that column to be the first column in sort position
      ...(col.sortMode && {
        sortMode: col.sortMode,
        sortPosition: col.sortPosition ?? generateDefaultSortOrder({
          columnsLength: arr.length,
          indexOfColumn: index,
        }),
      }),
    }));

  const filter = filtersToRequestFilters(filters, data);

  return { data, result, filter };
};
