import { Label } from '@fluentui/react';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import DatePicker from '../../../../../components/ui/DatePicker';
import { SCHEMA_FORMAT_TYPES } from '../../constants';

import { IControlledField } from '../../types';

export const DatePickerField: React.FC<IControlledField> = ({
  controller,
  entityProperty,
  isDisabled,
  label,
  interaction,
}) => {
  const { onChange, value } = controller;
  const { isReadOnly } = entityProperty;
  const { formatType } = interaction;

  const shouldUseTime = ![
    SCHEMA_FORMAT_TYPES.LongDate,
    SCHEMA_FORMAT_TYPES.ShortDate,
  ].includes(formatType);

  // The goal of this conversion is to ensure that the component displays the date "as is", ignoring the time zone.
  // The server stores the date in UTC, while the component always converts it to the local date – this might lead to scenarios
  // where the server will perform calculations based on one date (in UTC) but the user will visually see a different date (in local format)
  // causing confusion. By removing the `Z` from the date string (e.g. "2021-11-23T21:24:55Z") we make the component show
  // what date value that is used by the server for the calculations.

  const parsedDate = useMemo(
    () => value && new Date(value.slice(0, -1)),
    [value]
  );

  const handleChange = useCallback(
    (date: Date) => onChange(moment(date).utc(true).format()),
    [onChange]
  );

  return (
    <>
      <Label>{label}</Label>
      <DatePicker
        // API stores data as string
        date={parsedDate}
        disabled={isDisabled || isReadOnly}
        onChange={handleChange}
        showTimePicker={shouldUseTime}
      />
    </>
  );
};

export default DatePickerField;
