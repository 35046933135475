import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import { UserRoles } from '../../../../types/IUser';
import { processStreamIntent, setSelectedStreamId } from '../../../Builder/actions';
import { getSelectedStream } from '../../../Builder/selectors';
import { createStreamShare, fetchStreamShare, removeStreamShare } from '../../api';
import ShareWizard from '../../components/ShareWizard/ShareWizard';

const BuilderStreamShare = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleCancel = React.useCallback(() => {
    history.push(routes.builder.streams.index);
  }, []);

  const selectedStream = useSelector(getSelectedStream);

  const shareStream = async (userId, access) => {
    await createStreamShare(selectedStream?.id)({
      access,
      userId,
    });

    // We must process a stream after sharing to make all of the assets available to the new users
    dispatch(processStreamIntent({
      streamId: selectedStream.id,
      historyGoBack: () => {
        console.log('callback?');
        handleCancel();
      }, // handled by the stream wizard
    }));
    dispatch(setSelectedStreamId(null));
  };

  return (
    <ShareWizard
      createShareObject={shareStream}
      entityId={selectedStream?.id}
      entityName={selectedStream?.name}
      fetchShareObjects={fetchStreamShare}
      successCallback={() => null}
      handleCancel={handleCancel}
      parentEntityId={selectedStream?.id}
      unShareObject={removeStreamShare}
      visibleUsersRoles={[UserRoles.Admin, UserRoles.Designer, UserRoles.Builder]}
    />
  );
};

export default BuilderStreamShare;
