import { mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../theme';

const leftSlotWidth = 32;
const rightSlotWidth = 28;
const totalHeight = 28;

export const classNames = mergeStyleSets({
  disabledWrapper: {
    selectors: {
      '&:hover': {
        background: `${baseColors.white} !important`,
      },
    },
  },
  wrapper: {
    display: 'flex',
    height: totalHeight,
    alignItems: 'center',
    position: 'relative',
  },
  wrapperWhenLeftSlotComponent: {
    display: 'flex',
    alignItems: 'center',
  },
  contentWhenLeftSlotComponent: {
    width: `calc(100% - ${leftSlotWidth}px)`,
    height: totalHeight,
    display: 'flex',
    alignItems: 'center',
  },
  wrapperSelected: {
    background: baseColors.selected,

  },
  wrapperHidden: {
    // 50% opacity
    color: `${baseColors.iconColor}80`,
  },
  wrapperSelectedHoverable: {
    cursor: 'pointer',
    selectors: {
      '&:hover': {
        background: `${baseColors.selectedWithHover} !important`,
      },
    },
  },
  wrapperGhosting: {
    background: baseColors.hover,
    opacity: 0.3,
  },
  hoverable: {
    selectors: {
      '&:hover': {
        background: baseColors.hover,
        cursor: 'pointer',
      },
    },
  },
  leftSlot: {
    width: leftSlotWidth,
    minWidth: leftSlotWidth,
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightSlot: {
    height: '100%',
    width: rightSlotWidth,
  },
  text: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: `calc(100% - ${leftSlotWidth}px)`,
    maxWidth: `calc(100% - ${leftSlotWidth}px)`,
    margin: '0 2px',
  },
  textQueued: {
    color: baseColors.orange,
  },
  textWhenRightSlotComponent: {
    width: `calc(100% - ${leftSlotWidth + rightSlotWidth}px)`,
    maxWidth: `calc(100% - ${leftSlotWidth + rightSlotWidth}px)`,
  },
  textWhenLeftSlotComponent: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    maxWidth: '100%',
    margin: '0 2px',
  },
  textWhenLeftAndRightSlotComponent: {
    width: `calc(100% - ${rightSlotWidth}px)`,
    maxWidth: `calc(100% - ${rightSlotWidth}px)`,
  },
  icon: {
    width: '16px',
    lineHeight: '16px',
    fontSize: '16px',
    position: 'relative',
    top: '1px',
  },
});
