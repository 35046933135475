import {
  PublicClientApplication,
  PopupRequest,
  AuthError,
  AuthenticationResult,
  EndSessionPopupRequest,
} from '@azure/msal-browser';

import {
  createMsalInstance,
  createAuthDialogEventHandler,
  createSignInDialogMessageHandler,
  createSignOutDialogMessageHandler,
} from './utils';
import { authConfigSerializer, authStateSerializer } from './authStorage';
import { IAuthConfiguration } from '../../types/IAuthConfiguration';
import { instanceUrlSerializer } from '../Settings/components/connection/utils';
import fetchConfiguration from '../../api/Auth';
import {
  whitelistedErrorCodes,
  AUTH_DIALOG_URL,
  AUTH_DIALOG_OPTIONS,
} from './constants';
import { dispatchToDialogFactory } from '../../../auth/actions';
import { clearUserData } from '../User/actions';
import { cleanUserAuthState } from '../../api/interceptors/error';

let authState: AuthenticationResult | undefined;
let authConfig: IAuthConfiguration | undefined;
let msalInstance: PublicClientApplication | undefined;

// initialize the msal lib using the persisted configuration if one has been fetched in a previous session
// NOTE: for some reason this part is sometimes executed before `authConfigSerializer` is defined – circular deps?
authConfig = authConfigSerializer?.load();
if (authConfig) {
  msalInstance = createMsalInstance(authConfig);
}

const fetchAndPersistConfiguration = async (instanceUrl: string) => {
  authConfig = await fetchConfiguration(instanceUrl);
  authConfigSerializer.save(authConfig);

  msalInstance = createMsalInstance(authConfig);
};

export const authConnect = async (
  instanceUrl: string
): Promise<AuthenticationResult> => {
  const isUsingNativeExcel =
    Office.context.platform !== Office.PlatformType.OfficeOnline;

  instanceUrlSerializer.save(instanceUrl);

  await fetchAndPersistConfiguration(instanceUrl);

  return new Promise(async (resolve, reject) => {
    // NOTE: we have to keep using native dialogs in the native Excel client
    // because otherwise a weird error appears, see: https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/11269
    if (isUsingNativeExcel) {
      try {
        Office.context.ui.displayDialogAsync(
          AUTH_DIALOG_URL,
          // NOTE: we have to spread because... `.displayDialogAsync` mutates the options object in a way that makes it
          // not reusable with consecutive calls if a callback is provided...
          { ...AUTH_DIALOG_OPTIONS },
          (dialogResult) => {
            if (dialogResult.status === Office.AsyncResultStatus.Failed) {
              reject(dialogResult.error);
            } else {
              const loginDialog = dialogResult.value;
              const dispatchToDialog = dispatchToDialogFactory(loginDialog);

              loginDialog.addEventHandler(
                Office.EventType.DialogMessageReceived,
                createSignInDialogMessageHandler({
                  dispatchToDialog,
                  reject: (reason?: unknown) => {
                    loginDialog.close();
                    reject(reason);
                  },
                  resolve: (authResult: AuthenticationResult) => {
                    authState = authResult;
                    resolve(authResult);
                    loginDialog.close();
                  },
                })
              );

              loginDialog.addEventHandler(
                Office.EventType.DialogEventReceived,
                createAuthDialogEventHandler({
                  dispatchToDialog,
                  reject,
                  resolve,
                })
              );
            }
          }
        );
      } catch (error) {
        console.error(error);
        reject(error);
      }
    } else {
      if (!authConfig || !msalInstance)
        return reject('Missing auth config or msal instance');

      const loginRequest = {
        scopes: [authConfig.scope],
        prompt: 'select_account',
      };

      try {
        const authResult = await msalInstance.loginPopup(loginRequest);
        authState = authResult;
        resolve(authResult);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }
  });
};

export const authDisconnect = async () => {
  if (!msalInstance || !authState)
    throw new Error('Missing auth state or msal instance');

  return new Promise<void>(async (resolve, reject) => {
    const handleLogout = () => {
      authStateSerializer.remove();
      authState = undefined;
      // NOTE: since our user's state is tightly coupled with the authentication, we're hardoding the cleaning here
      window.sharedState.store.dispatch(clearUserData());
      resolve();
    };

    const isUsingNativeExcel =
      Office.context.platform !== Office.PlatformType.OfficeOnline;

    // NOTE: we have to keep using native dialogs in the native Excel client
    // because otherwise a weird error appears, see: https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/11269
    if (isUsingNativeExcel) {
      try {
        Office.context.ui.displayDialogAsync(
          AUTH_DIALOG_URL,
          // NOTE: we have to spread because... `.displayDialogAsync` mutates the options object in a way that makes it
          // not reusable with consecutive calls if a callback is provided...
          { ...AUTH_DIALOG_OPTIONS },
          (dialogResult) => {
            if (dialogResult.status === Office.AsyncResultStatus.Failed) {
              reject(dialogResult.error);
            } else {
              const logoutDialog = dialogResult.value;
              const dispatchToDialog = dispatchToDialogFactory(logoutDialog);

              logoutDialog.addEventHandler(
                Office.EventType.DialogMessageReceived,
                createSignOutDialogMessageHandler({
                  dispatchToDialog,
                  reject: (reason?: unknown) => {
                    logoutDialog.close();
                    reject(reason);
                  },
                  resolve: () => {
                    handleLogout();
                    logoutDialog.close();
                  },
                })
              );

              logoutDialog.addEventHandler(
                Office.EventType.DialogEventReceived,
                createAuthDialogEventHandler({
                  customLogoutCheck: acquireTokenSilent,
                  dispatchToDialog,
                  reject,
                  resolve,
                })
              );
            }
          }
        );
      } catch (error) {
        console.error(error);
        reject(error);
      }
    } else {
      const logoutRequest: EndSessionPopupRequest = {
        account: authState.account,
      };

      try {
        await msalInstance.logoutPopup(logoutRequest);
        handleLogout();
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }
  });
};

type AccessToken = string;
export const acquireTokenSilent = async (): Promise<AccessToken | null> => {
  authConfig = authConfigSerializer.load();
  authState = authStateSerializer.load();

  if (!msalInstance || !authConfig || !authState) {
    console.warn(
      'silent authentication failure: msal instance, auth config or state missing'
    );
    return null;
  }

  const request: PopupRequest = {
    account: authState.account ?? undefined,
    scopes: [authConfig.scope],
  };

  try {
    // NOTE: keep in mind that `msal.acquireTokenSilent()` reuses a cached token if it's not expired
    // and refreshes it (fetches a new one) only if it has
    const authResult = await msalInstance.acquireTokenSilent(request);
    authStateSerializer.save(authResult);

    return authResult.accessToken;
  } catch (error) {
    console.warn(error);

    // NOTE: since our user's state is tightly coupled with the authentication, we're hardcoding the cleaning here
    cleanUserAuthState(false);
    authState = undefined;

    if (error instanceof AuthError) {
      if (whitelistedErrorCodes.includes(error.errorCode)) return null;

      throw error;
    }
  }

  return null;
};
