import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Icon } from '@fluentui/react';
import {
  setSelectedEmptyLinkId,
} from '../../actions';
import {
  getLinksWithoutLinkFields, getSelectedEmptyLinkId,
} from '../../selectors';

import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';

import EntityField from '../../../../../components/EntityField';
import { InfoParams } from '../../../../../hooks/use-information-dialog';
import { SchemaEntry } from '../../../../../components/DataSchemaMapper/model';
import DataSourceCalloutTitle from '../../components/DataSourceCalloutTitle';
import { IDesignSource } from '../../../types';
import { baseColors } from '../../../../../theme';
import { translateApiName } from '../../../../../config/i18n/utils';
import { numberToHexColor } from '../../../../../components/ui/ColorPicker/utils';

const linkInfoSchema: SchemaEntry[] = [
  {
    key: 'source',
    name: translateApiName(TYPE_IDS.DataSource, 'type'),
  },
  {
    key: 'type',
    translationPrefix: TYPE_IDS.Link,
  },
  {
    key: 'flow',
    translationPrefix: TYPE_IDS.Link,
  },
  {
    key: 'records',
    translationPrefix: TYPE_IDS.Link,
  },
  {
    key: 'id',
    name: 'id',
  },
];

interface Props {
  disableOnclick?: boolean
  selectedLinkIds?: string[]
  sourceData: IDesignSource
}

export const EmptyLinks = ({
  disableOnclick,
  selectedLinkIds,
  sourceData,
}:Props) => {
  // DEPS
  const { t } = useTranslation();

  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const linksWithoutLinkFields = useSelector(getLinksWithoutLinkFields);
  const selectedEmptyLinkId = useSelector(getSelectedEmptyLinkId);

  // EFFECTS
  React.useEffect(() => () => {dispatch(setSelectedEmptyLinkId(null))}, []);

  return (
    <div>
      {linksWithoutLinkFields.map((link, index) => {
        const name = t('modeBar:ingestion.emptyLink', { number: index + 1 });

        const info:InfoParams = {
          data: {
            flow: link.flow,
            type: link.type,
            records: link.records,
            source: sourceData.name,
            id: link.id,
          },
          schema: linkInfoSchema,
          title: <DataSourceCalloutTitle {...{ name }} color={numberToHexColor(sourceData.color)} />,
        };

        const isSelected = selectedEmptyLinkId === link.id;
        const handleOnClick = disableOnclick ? null : () => dispatch(setSelectedEmptyLinkId(isSelected ? null : link.id));

        return (
          <EntityField
            info={info}
            slot1={<Icon iconName="Warning" style={{ color: baseColors.red }} />}
            name={name}
            key={link.id}
            isSelectable
            isSelected={selectedLinkIds ? selectedLinkIds.includes(link.id) : isSelected}
            onClick={handleOnClick}
          />
        );
      })}
    </div>
  );
};
