import { getAndSetDefault, updateWith } from '../../../../../../shared/utils/map';
import { DataSourceEntity } from '../../../../../api/model/schemas/DataSourceEntity';
import { DataSourceEntityField } from '../../../../../api/model/schemas/DataSourceEntityField';
import { LinkFields } from '../../../../../api/model/schemas/LinkFields';
import { matchesSearchQuery } from '../../../../Search/utils';
import { DesignerIngestionState } from "../../types";

interface GetLinkFieldsForEntitiesArgs {
  fromId: string;
  linkedEntityFields: DesignerIngestionState['linkedEntityFields'];
  linksFields: DesignerIngestionState['linksFields'];
  searchQuery: string;
  toId: string;
}
export const getLinkFieldsForEntities = ({
  fromId,
  linkedEntityFields,
  linksFields,
  searchQuery,
  toId,
}: GetLinkFieldsForEntitiesArgs) => {
  Object.values(linksFields).filter(
    (linkFields) => {
      const fromEntityField = linkedEntityFields[linkFields.fromId];
      const toEntityField = linkedEntityFields[linkFields.toId];

      const isMatching =
        fromEntityField.parentId === fromId &&
        toEntityField.parentId === toId;

      const fullName = `${fromEntityField.name} ${toEntityField.name}`;

      const matchesSearch = matchesSearchQuery(searchQuery, fullName);

      return isMatching && matchesSearch;
    }
  );
};

type EntitiesToLinkFields = Map<DataSourceEntity['id'], LinkFields['id'][]>;
export type LinkFieldsGroupedByEntities = Map<DataSourceEntity['id'], EntitiesToLinkFields>;

type PrepareLinkListArgs = Pick<DesignerIngestionState,
  | 'linksFields'
  | 'linkedEntityFields'
>;
export const groupLinkFieldsByEntities = ({
  linkedEntityFields,
  linksFields: linksFieldsRecord,
}: PrepareLinkListArgs): LinkFieldsGroupedByEntities => {
  const result: LinkFieldsGroupedByEntities = new Map();

  Object.values(linksFieldsRecord).forEach(linkFields => {
    const fromField: DataSourceEntityField = linkedEntityFields[linkFields.fromId];
    const toField: DataSourceEntityField = linkedEntityFields[linkFields.toId];

    const fromEntityId: DataSourceEntity['id'] = fromField.parentId;
    const toEntityId: DataSourceEntity['id'] = toField.parentId;

    const entitiesToLinkFieldIds: EntitiesToLinkFields = getAndSetDefault(result, fromEntityId, new Map());

    updateWith(
      entitiesToLinkFieldIds,
      toEntityId,
      // NOTE: if an array for the given key already exists, the `?.push(...)` will work and return the new length
      // at which point the ternary (thus the setter) returns the updated array's reference
      // otherwise the chain operator will short-circuit with `undefined` and we initialize a new array with one element
      linkFieldsIds => linkFieldsIds?.push(linkFields.id) ? linkFieldsIds : [linkFields.id],
    );
  });

  return result;
}

type PerEntityEntry<Value = unknown> = readonly [entityId: string, value: Value];
type PerToEntityEntry = PerEntityEntry<LinkFields['id'][]>;
type PerFromEntityEntry = PerEntityEntry<PerToEntityEntry[]>;
export type SortedGroupedLinkFields = PerFromEntityEntry[];

interface SortGroupedLinkFieldsArgs extends Pick<DesignerIngestionState,
  | 'linkedEntities'
  | 'linkedEntityFields'
  | 'linksFields'
> {
  groupedLinkFields: LinkFieldsGroupedByEntities;
}
export const sortGroupedLinkFields = ({
  groupedLinkFields,
  linkedEntities,
  linkedEntityFields,
  linksFields,
}: SortGroupedLinkFieldsArgs): SortedGroupedLinkFields => {
  const sortEntityEntries  = ([entityIdA]: PerEntityEntry, [entityIdB]: PerEntityEntry) => {
    const entityA: DataSourceEntity = linkedEntities[entityIdA];
    const entityB: DataSourceEntity = linkedEntities[entityIdB];

    return entityA.name.localeCompare(entityB.name);
  };

  const sortLinkFieldsIds = (linkFieldIdA: LinkFields['id'], linkFieldIdB: LinkFields['id']) => {
    const linkFieldsA: LinkFields = linksFields[linkFieldIdA];
    const linkFieldsB: LinkFields = linksFields[linkFieldIdB];

    const fromEntityFieldA: DataSourceEntityField = linkedEntityFields[linkFieldsA.fromId];
    const fromEntityFieldB: DataSourceEntityField = linkedEntityFields[linkFieldsB.fromId];
    const toEntityFieldA: DataSourceEntityField = linkedEntityFields[linkFieldsA.toId];
    const toEntityFieldB: DataSourceEntityField = linkedEntityFields[linkFieldsB.toId];

    // start by comparing the "from" fields
    return fromEntityFieldA.name.localeCompare(fromEntityFieldB.name)
      // if comparing "from" fields returns `0` that means they are identical and we should sort by the target
      || toEntityFieldA.name.localeCompare(toEntityFieldB.name);
  };

  const sortedFromEntries: PerEntityEntry<EntitiesToLinkFields>[] = Array
    .from(groupedLinkFields.entries())
    .sort(sortEntityEntries);

  // NOTE: `.map()` doesn't properly infer the tuple type
  const withAllLevelsSorted = sortedFromEntries.map(([fromEntityId, targetEntityLinkFields]) => {
    const sortedToEntries: PerToEntityEntry[] = Array
      .from(targetEntityLinkFields.entries())
      .sort(sortEntityEntries);

    const withLinkFieldsSorted = sortedToEntries.map(([toEntityId, linkFieldsIds]) => {
      const sortedLinkFieldsIds: LinkFields['id'][] = linkFieldsIds.sort(sortLinkFieldsIds);

      return [toEntityId, sortedLinkFieldsIds] as const;
    });

    return [fromEntityId, withLinkFieldsSorted] as const;
  });

  return withAllLevelsSorted;
};
