import { StreamerFilter } from '../types';

export const streamerFilterFormDefault: StreamerFilter = {
  comparison: null,
  values: [],
  lowerBoundValue: '',
  upperBoundValue: '',
  id: null,
  aggregation: null,
  item: null,
  compareValue: '',
  type: null,
};
