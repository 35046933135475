import { CSSProperties } from 'react';
import {
  mergeStyleSets, IButtonStyles, ISpinnerStyles,
} from '@fluentui/react';

import {
  baseColors, BORDER_RADIUS_IN_PX,
} from '../../../theme';

export const styles = mergeStyleSets({
  callout: {
    borderRadius: BORDER_RADIUS_IN_PX,
  },
});

export const calloutStyles = {
  calloutMain: {
    overflow: 'visible' as const,
  },
};

export const titleBar: CSSProperties = {
  borderRadius: `${BORDER_RADIUS_IN_PX} ${BORDER_RADIUS_IN_PX} 0 0`,
  backgroundColor: baseColors.lightGrey,
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${baseColors.outlineGray}`,
  height: '32px',
  boxSizing: 'border-box',
};

export const titleTextStyles: React.CSSProperties = {
  flex: 1,
  fontWeight: 600,
  fontSize: '12px',
  margin: 0,
  overflow: 'auto',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const contentStyles:React.CSSProperties = {
  borderRadius: BORDER_RADIUS_IN_PX,
  padding: '8px',
};

const CLOSE_SIZE = 24;

export const buttonStyles: IButtonStyles = {
  root: {
    marginLeft: 8,
    width: CLOSE_SIZE,
    height: CLOSE_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    zIndex: 1,
  },
  rootHovered: {
    backgroundColor: baseColors.selected,
    color: baseColors.midOrange,
    selectors: {
      '.ms-Icon': {
        color: baseColors.midOrange,
      },
    },
  },
  icon: {
    fontSize: 16,
    color: baseColors.iconColor,
  },
};

export const spinnerStyles: ISpinnerStyles = {
  root: {
    width: '32px',
    height: '100%',
  },
  circle: {
    width: '16px',
    height: '16px',
  },
};
