import React from 'react';
import { Stack } from '@fluentui/react';
import { valueStyles } from './styles';

interface Props {
  children: React.ReactNode,
  onClick(): void;
  isSelected: boolean;
  [key: string]: any;
}

const GroupValue: React.FC<Props> = ({
  children,
  onClick,
  isSelected,
  ...rest
}) => (
  <Stack
    horizontal
    styles={valueStyles(isSelected)}
    onClick={onClick}
    wrap
    {...rest}
  >
    { children }
  </Stack>
);

export default GroupValue;
