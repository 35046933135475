import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { acquireTokenSilent } from '../../modules/Auth-v2';

export const authTokenInterceptor = async (request: AxiosRequestConfig) => {
  const accessToken = await acquireTokenSilent();

  if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;

  return request;
};

export const applyAuthTokenInterceptor = (
  instance: AxiosInstance
): AxiosInstance => {
  instance.interceptors.request.use(authTokenInterceptor);

  return instance;
};
