import React from 'react';
import { registerIcons } from 'office-ui-fabric-react/lib/Styling';

// Aggregations
import SynergiesClicked from '../assets/icons/Custom - Clicked.svg';
import SynergiesNumber from '../assets/icons/Custom - Number.svg';
import SynergiesSum from '../assets/icons/Custom - Sum.svg';
import SynergiesAverage from '../assets/icons/Custom - Average.svg';
import SynergiesCount from '../assets/icons/Custom - Count.svg';
import SynergiesMinimum from '../assets/icons/Custom - Minimum.svg';
import SynergiesMaximum from '../assets/icons/Custom - Maximum.svg';
import SynergiesDoNotSummarize from '../assets/icons/Custom - Do Not Summarize.svg';
import SynergiesDistinctCountNoBlanks from '../assets/icons/Custom - Distinct Count (No Blanks).svg';
import SynergiesDistinctCountWithBlanks from '../assets/icons/Custom - Distinct Count (With Blanks).svg';
import SynergiesCalc from '../assets/icons/Custom - Calc.svg';

import SynergiesStreamer from '../assets/icons/Custom - Streamer.svg';
import SynergiesBuilder from '../assets/icons/Custom - Builder.svg';
import SynergiesDesigner from '../assets/icons/Custom - Designer.svg';
import SynergiesAdmin from '../assets/icons/Custom - Admin.svg';
// Header
import SynergiesAutoLoad from '../assets/icons/Custom - AutoLoad.svg';
import SynergiesLoadNextPage from '../assets/icons/Custom - LoadNextPage.svg';
import SynergiesCloseSearch from '../assets/icons/SynergiesCloseSearch.svg';

import SynergiesLayout from '../assets/icons/Custom - Layout.svg';
import SynergiesGroupTable from '../assets/icons/SynergiesGroupTable.svg';
import SynergiesTable from '../assets/icons/SynergiesTable.svg';
import SortAscending from '../assets/icons/SortAscending.svg';
import SortCustom from '../assets/icons/SynergiesSortCustom.svg';
import BulkUpload from '../assets/icons/SynergiesBulkUpload.svg';
import SynergiesColumnsLayout from '../assets/icons/SynergiesColumnsLayout.svg';
import SynergiesRowsLayout from '../assets/icons/SynergiesRowsLayout.svg';
import SynergiesValuesLayout from '../assets/icons/SynergiesValuesLayout.svg';
import SynergiesFilterCriteria from '../assets/icons/SynergiesFilterCriteria.svg';
import SynergiesFilterCriteriaFilled from '../assets/icons/SynergiesFilterCriteriaFilled.svg';

// Streams
import StreamsAdventureWorks from '../assets/icons/streams/adventure-works.svg';
import StreamsEiko from '../assets/icons/streams/eiko.svg';
import StreamsIsosceles from '../assets/icons/streams/isosceles.svg';
import StreamsBigDataChurn from '../assets/icons/streams/big-data-churn.svg';
import StreamsCovidAgeAnalysis from '../assets/icons/streams/covid-age-analysis.svg';
import StreamsCovidHospitals from '../assets/icons/streams/covid-hospitals.svg';
import StreamsCovidMedicalMask from '../assets/icons/streams/covid-medical-mask.svg';
import StreamsCovidVaccinations from '../assets/icons/streams/covid-vaccinations.svg';

import SynergiesCustomSelected from '../assets/icons/Custom - Selected.svg';
import SynergiesCustomUnselected from '../assets/icons/Custom - Unselected.svg';
import SynergiesCustomName from '../assets/icons/Custom - Name.svg';
import SynergiesCustomRole from '../assets/icons/Custom - Role.svg';
// Designer
import ContentLibrary from '../assets/icons/designer/ContentLibrary.svg';
import CatalogDefinition from '../assets/icons/designer/CatalogDefinition.svg';
import EykoData from '../assets/icons/designer/EykoData.svg';
import EykoHubs from '../assets/icons/designer/EykoHubs.svg';
import EykoStreams from '../assets/icons/designer/EykoStreams.svg';
import EykoCatalog from '../assets/icons/designer/EykoCatalog.svg';
import MetadataConfiguration from '../assets/icons/designer/MetadataConfiguration.svg';
import EykoNoDataSource from '../assets/icons/designer/SynergiesNoDatasource.svg';
import SmartCatalogMagicWand from '../assets/icons/SmartCatalogMagicWand.svg';
import EykoOutput from '../assets/icons/EykoOutput.svg';
import EykoLiteralValue from '../assets/icons/designer/EykoLiteralValue.svg';
import EykoSmartFlow from '../assets/icons/designer/EykoSmartFlow.svg';

// Builder
import StreamOutput from '../assets/icons/builder/StreamOutput.svg';

// Ingestion Types
import EykoBoolean from '../assets/icons/designer/EykoBoolean.svg';
import EykoJsonType from '../assets/icons/designer/EykoJsonType.svg';
import EykoTransform from '../assets/icons/designer/EykoTransform.svg';

// Write to DB
import EykoStreamToDb from '../assets/icons/EykoStreamToDb.svg';
// Streamer Filters

import Contains from '../assets/icons/streamer/filters/Contains.svg';
import Between from '../assets/icons/streamer/filters/Between.svg';
import EndsWith from '../assets/icons/streamer/filters/EndsWith.svg';
import Equal from '../assets/icons/streamer/filters/Equal.svg';
import GreaterOrEqualThan from '../assets/icons/streamer/filters/GreaterOrEqualThan.svg';
import GreaterThan from '../assets/icons/streamer/filters/GreaterThan.svg';
import IsBlank from '../assets/icons/streamer/filters/IsBlank.svg';
import IsNotBlank from '../assets/icons/streamer/filters/IsNotBlank.svg';
import IsNotNull from '../assets/icons/streamer/filters/IsNotNull.svg';
import IsNull from '../assets/icons/streamer/filters/IsNull.svg';
import LessOrEqualThan from '../assets/icons/streamer/filters/LessOrEqualThan.svg';
import LessThan from '../assets/icons/streamer/filters/LessThan.svg';
import NotContains from '../assets/icons/streamer/filters/NotContains.svg';
import NotEqual from '../assets/icons/streamer/filters/NotEqual.svg';
import StartsWith from '../assets/icons/streamer/filters/StartsWith.svg';

// Streamer
import OpenAllCards from '../assets/icons/streamer/OpenAllCards.svg';
import CloseAllCards from '../assets/icons/streamer/CloseAllCards.svg';

export default () =>
  registerIcons({
    icons: {
      SynergiesClicked: <SynergiesClicked />,
      SynergiesNumber: <SynergiesNumber />,
      SynergiesSum: <SynergiesSum />,
      SynergiesCalc: <SynergiesCalc />,
      SynergiesAverage: <SynergiesAverage />,
      SynergiesCount: <SynergiesCount />,
      SynergiesMinimum: <SynergiesMinimum />,
      SynergiesMaximum: <SynergiesMaximum />,
      SynergiesDoNotSummarize: <SynergiesDoNotSummarize />,
      SynergiesDistinctCountNoBlanks: <SynergiesDistinctCountNoBlanks />,
      SynergiesDistinctCountWithBlanks: <SynergiesDistinctCountWithBlanks />,
      SynergiesBuilder: <SynergiesBuilder />,
      SynergiesStreamer: <SynergiesStreamer />,
      SynergiesDesigner: <SynergiesDesigner />,
      SynergiesAdmin: <SynergiesAdmin />,
      SynergiesAutoLoad: <SynergiesAutoLoad />,
      SynergiesLoadNextPage: <SynergiesLoadNextPage />,
      SynergiesCloseSearch: <SynergiesCloseSearch />,
      SynergiesLayout: <SynergiesLayout />,
      SynergiesGroupTable: <SynergiesGroupTable />,
      SynergiesTable: <SynergiesTable />,
      SynergiesColumnsLayout: <SynergiesColumnsLayout />,
      SynergiesRowsLayout: <SynergiesRowsLayout />,
      SynergiesValuesLayout: <SynergiesValuesLayout />,
      SynergiesSortAscending: <SortAscending />,
      SynergiesSortCustom: <SortCustom />,
      SynergiesBulkUpload: <BulkUpload />,
      SynergiesFilterCriteria: <SynergiesFilterCriteria />,
      SynergiesFilterCriteriaFilled: <SynergiesFilterCriteriaFilled />,

      // streams
      StreamsAdventureWorks: <StreamsAdventureWorks />,
      StreamsEiko: <StreamsEiko />,
      StreamsIsosceles: <StreamsIsosceles />,
      StreamsBigDataChurn: <StreamsBigDataChurn />,
      StreamsCovidAgeAnalysis: <StreamsCovidAgeAnalysis />,
      StreamsCovidHospitals: <StreamsCovidHospitals />,
      StreamsCovidMedicalMask: <StreamsCovidMedicalMask />,
      StreamsCovidVaccinations: <StreamsCovidVaccinations />,

      // Control View
      SynergiesCustomSelected: <SynergiesCustomSelected />,
      SynergiesCustomUnselected: <SynergiesCustomUnselected />,
      SynergiesCustomName: <SynergiesCustomName />,
      SynergiesCustomRole: <SynergiesCustomRole />,

      // designer
      ContentLibrary: <ContentLibrary />,
      CatalogDefinition: <CatalogDefinition />,
      EykoData: <EykoData />,
      EykoHubs: <EykoHubs />,
      EykoStreams: <EykoStreams />,
      EykoCatalog: <EykoCatalog />,
      MetadataConfiguration: <MetadataConfiguration />,
      EykoNoDataSource: <EykoNoDataSource />,
      SmartCatalogMagicWand: <SmartCatalogMagicWand />,
      EykoLiteralValue: <EykoLiteralValue />,
      EykoSmartFlow: <EykoSmartFlow />,

      // Ingestion types
      EykoBoolean: <EykoBoolean />,
      EykoJsonType: <EykoJsonType />,
      EykoTransform: <EykoTransform />,

      // Builder
      StreamOutput: <StreamOutput />,

      // New Streamer
      EykoOutput: <EykoOutput />,
      EykoStreamToDb: <EykoStreamToDb />,
      OpenAllCards: <OpenAllCards />,
      CloseAllCards: <CloseAllCards />,

      // Streamer Filters
      EykoContains: <Contains />,
      EykoBetween: <Between />,
      EykoEndsWith: <EndsWith />,
      EykoEqual: <Equal />,
      EykoGreaterOrEqualThan: <GreaterOrEqualThan />,
      EykoGreaterThan: <GreaterThan />,
      EykoIsBlank: <IsBlank />,
      EykoIsNotBlank: <IsNotBlank />,
      EykoIsNotNull: <IsNotNull />,
      EykoIsNull: <IsNull />,
      EykoLessOrEqualThan: <LessOrEqualThan />,
      EykoLessThan: <LessThan />,
      EykoNotContains: <NotContains />,
      EykoNotEqual: <NotEqual />,
      EykoStartsWith: <StartsWith />,
    },
  });
