import moment from 'moment';

import i18n from '../../../../config/i18n';

export function dateToFromNowDaily(myDate: number) {
  const fromNow = moment(myDate).fromNow();

  return moment(myDate).calendar(null, {
    lastWeek: `[${i18n.t('dateFormatting:last')}] dddd hh:mm A`,
    lastDay: `[${i18n.t('dateFormatting:yesterday')}] hh:mm A`,
    sameDay: `[${i18n.t('dateFormatting:today')}] hh:mm A`,
    // when the date is further away, use from-now functionality
    sameElse() {
      return `[${fromNow}]`;
    },
  });
}
