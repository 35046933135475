import React from 'react';
import { baseColors } from '../../theme';

export const styles: Record<string, React.CSSProperties> = {
  matched: {
    backgroundColor: baseColors.grayOverlay,
  },
};

export const textWrapperStyles = (isBold: boolean): React.CSSProperties => ({
  fontSize: '12px',
  fontWeight: isBold ? 600 : 'normal',
});
