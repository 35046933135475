import { AxiosResponse, AxiosInstance } from 'axios';
import { entityUuidRegexStr } from '../../../shared/constants/entityUuidRegex';
import { ApiV4ResponseWrapper } from '../../modules/Designer/types';
import { TypeIds } from '../model/schemas';
import { isMatchingApiCall } from './i18n/utils';

// NOTE: this is a very badly prepared regexp that depends on the odata params to exist in a very specific order
const apiDataSourceChildEntitiesPathRegex = new RegExp(
  `/v4/Entities/${entityUuidRegexStr}/Children\\\?\\\$orderby=name\\\&typeId=${TypeIds.DataSourceEntity}`
);
const linksDiscoveryJobsPathRegex = new RegExp(
  `\\\&\\\$top=1\\\&typeId=${TypeIds.GenerateLinksJob}`
);

const blacklist: [method: string, url: RegExp][] = [
  ['GET', apiDataSourceChildEntitiesPathRegex],
  ['GET', linksDiscoveryJobsPathRegex],
];

export const multiPageResponseInterceptor =
  (instance: AxiosInstance) =>
  async (firstPageResponse: AxiosResponse<ApiV4ResponseWrapper<any>>) => {
    // NOTE: unfortunatelly there doesn't seem to be a way to disable an interceptor on a per-request basis
    // so a "global blacklist" is used which might be problematic if the same URL is used in both situations where
    // infinite scroll should be supported as well as where there is a need for "fetch-all" solution
    if (
      blacklist.some((blacklistItem) =>
        isMatchingApiCall(...blacklistItem, firstPageResponse)
      )
    ) {
      return firstPageResponse;
    }

    let multiPageResponse: AxiosResponse<ApiV4ResponseWrapper<any>> = null;

    if (firstPageResponse.data?.nextPage) {
      let nextPageUrl = firstPageResponse.data.nextPage;
      multiPageResponse = { ...firstPageResponse };
      delete multiPageResponse.data.nextPage;

      while (nextPageUrl) {
        const {
          data: newPageResponse,
        }: AxiosResponse<ApiV4ResponseWrapper<any>> = await instance.get(
          nextPageUrl
        );

        multiPageResponse = {
          ...multiPageResponse,
          data: {
            ...multiPageResponse.data,
            data: [...multiPageResponse.data.data, ...newPageResponse.data],
          },
        };

        nextPageUrl = newPageResponse.nextPage;
      }
    }

    return multiPageResponse || firstPageResponse;
  };

export const applyMultiPageResponseInterceptor = (
  instance: AxiosInstance
): AxiosInstance => {
  instance.interceptors.response.use(multiPageResponseInterceptor(instance));

  return instance;
};
