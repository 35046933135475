import { AxiosResponse } from 'axios';
import { QueryOptions } from 'odata-query';
import * as paths from '../../constants/apiPaths';
import getClient from '../../api/getClient';
import {
  createNestedEntity,
  fetchEntities, fetchEntity, fetchNestedEntities, patchEntity,
} from '../Designer/api';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { INotification, NotificationResponse } from '../../types/INotification';
import { ApiV4ResponseWrapper } from '../Designer/types';
import { IUser } from '../../types/IUser';

export const getConfiguration = async () => {
  const client = await getClient();
  const { data } = await client({
    method: 'GET',
    url: paths.configurationPath,
  });

  return data;
};

export const fetchUserNotifications = async (
  userId: string,
  params?: Partial<QueryOptions<unknown>>,
) => fetchNestedEntities<INotification>({
  typeId: TYPE_IDS.Notification,
})(userId)(params);

export const fetchNextPageNotifications = async (
  url: string,
): Promise<AxiosResponse<ApiV4ResponseWrapper<INotification>>> => {
  const client = await getClient();

  return client({
    method: 'GET',
    url,
  });
};

export const dismissNotification = async (
  notificationId: string,
) => patchEntity<INotification>(TYPE_IDS.Notification)({
  id: notificationId,
  response: NotificationResponse.Dismiss,
});

export const dismissAllNotifications = async (
  userId: string,
) => createNestedEntity({
  typeId: TYPE_IDS.DismissNotificationsRequest,
  parentEntityId: userId,
})({});

export const fetchAllUsers = () => fetchEntities<ApiV4ResponseWrapper<IUser[]>>(TYPE_IDS.User)();
export const fetchSingleUser = (userId: string) => fetchEntity<ApiV4ResponseWrapper<IUser>>(TYPE_IDS.User, userId);
