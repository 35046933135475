import { createAsyncThunk } from '@reduxjs/toolkit';
import { IClientPreference } from '../../types/IClientPreferences';
import * as api from './api';

export const getClientPreferences = createAsyncThunk(
  'GET_CLIENT_PREFERENCES',
  async () => [],
);

export const createClientPreferences = createAsyncThunk(
  'CREATE_CLIENT_PREFERENCES',
  async (preference: IClientPreference) => {
    const { data } = await api.createClientPreference(preference);

    return data;
  },
);

export const editClientPreferences = createAsyncThunk(
  'EDIT_CLIENT_PREFERENCES',
  async (preference: IClientPreference) => {
    const { id, ...rest } = preference;
    const { data } = await api.updateClientPreference(id, rest);

    return data;
  },
);
