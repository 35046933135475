/* eslint-disable no-prototype-builtins */
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import EntityField from '../../../../components/EntityField';
import useGetParsedTypeProperties from '../../../../hooks/useGetParsedTypeProperties';
import {
  fetchStreamFields,
  fetchProcessedDataSources,
  fetchStreamCalculations,
  fetchStreamGroupsAssets,
  fetchStreamHubs,
  fetchStreamMachineLearning,
} from '../../actions';
import {
  getStreamFields,
  getDataSources,
  getSelectedStream,
  getCalculations,
  getGroups,
  getHubs,
  getMachineLearning,
} from '../../selectors';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import DataSourceIcon from '../../../../components/DataSourceIcon';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import {
  dataTypeIcons,
  tutorialTileConfig,
  TUTORIAL_TILE_KEY,
  sourceSchemaEntry,
} from './constants';
import { getSearchQuery } from '../../../Search/selectors';
import { matchesSearchQuery } from '../../../Search/utils';
import { getSchemaFromTypeProperties } from '../../../../utils/getSchemaFromTypeProperties';
import { getSelectedDesignId } from '../../../Designer/ContentLibrary/selectors';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';

import {
  isCalculation,
  isGroup,
  isHub,
  isStreamField,
  isStreamMachineLearning,
} from './utils';
import { StreamEntity } from '../../types';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../App/types';
import { GroupCollapse } from '../../../../components/ui';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { IFoldableListItem } from '../../../../components/ui/FoldableList/types/IFoldableListGroup';
import { numberToHexColor } from '../../../../components/ui/ColorPicker/utils';
import DataSourceCalloutTitle from '../../../Designer/Ingestion/components/DataSourceCalloutTitle';
import { ancestorSourceNameKey } from '../../../../constants/infoBoxes';
import { StreamCalculationKeys } from '../../../../api/model/schemas/StreamCalculation';
import { StreamFieldKeys } from '../../../../api/model/schemas/StreamField';
import { SchemaEntry } from '../../../../components/DataSchemaMapper/model';
import { StreamReferenceProcessStatus } from '../../../../api/model/schemas/StreamReferenceProcessStatus';

const AssetsData: FunctionComponent = () => {
  // DEPS
  const { id: streamId, parentId: selectedDesignId } =
    useSelector(getSelectedStream);

  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    records: streamFields,
    isLoading: isLoadingStreamFields,
    entityFields,
    entities,
  } = useSelector(getStreamFields);
  const { records: dataSources, isLoading: isLoadingSources } =
    useSelector(getDataSources);
  const { streamCalculations, isLoading: isLoadingCalculations } =
    useSelector(getCalculations);
  const { streamGroupAssets, isLoading: isLoadingGroupAssets } =
    useSelector(getGroups);
  const {
    streamMachineLearning,
    isLoadingStreamML: isLoadingMachineLearningAssets,
  } = useSelector(getMachineLearning);
  const { streamHubs, isLoading: isLoadingHubs } = useSelector(getHubs);
  const searchQuery = useSelector(getSearchQuery);
  const designId = useSelector(getSelectedDesignId);

  const { properties: streamFieldProperties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.StreamField,
  });
  const { properties: streamCalculationProperties } =
    useGetParsedTypeProperties({
      designId,
      typeId: TYPE_IDS.StreamCalculation,
    });

  // DERIVED STATE
  const isLoading =
    isLoadingMachineLearningAssets ||
    isLoadingStreamFields ||
    isLoadingSources ||
    isLoadingCalculations ||
    isLoadingGroupAssets ||
    isLoadingHubs;

  const filteredRecords = useMemo(
    () =>
      [
        ...streamFields.map((field) => ({
          ...field,
          streamAssetType: t('entityTypes:DataSourceField'),
        })),
        ...streamCalculations.map((field) => ({
          ...field,
          streamAssetType: t('entityTypes:Calculation'),
        })),
        ...streamGroupAssets.map((field) => ({
          ...field,
          streamAssetType: t('entityTypes:Group'),
        })),
        ...streamHubs.map((field) => ({
          ...field,
          streamAssetType: t('entityTypes:Hub'),
        })),
        ...streamMachineLearning.map((field) => ({
          ...field,
          streamAssetType: t('entityTypes:MachineLearning'),
        })),
      ]
        .filter(
          (record) =>
            searchQuery === '' || matchesSearchQuery(searchQuery, record.name)
        )
        .sort((recordA, recordB) => recordA.name.localeCompare(recordB.name)),
    [streamFields, searchQuery, streamCalculations]
  );
  const normalizedRecords = normalizeById(filteredRecords);

  const { currentSort, mappedGroups, onClickHeaderHandler } =
    useSortedFoldableGroups({ input: filteredRecords });

  const getSchema = React.useCallback(
    (field: StreamEntity) => {
      const [resultSchema, insertSourceAtKey] = isCalculation(field)
        ? [
            getSchemaFromTypeProperties(
              streamCalculationProperties,
              TYPE_IDS.StreamCalculation
            ),
            StreamCalculationKeys.description,
          ]
        : [
            getSchemaFromTypeProperties(
              streamFieldProperties,
              TYPE_IDS.StreamField
            ),
            StreamFieldKeys.description,
          ];

      const insertSourceAt = resultSchema.findIndex(
        (entry) => entry.key === insertSourceAtKey
      );
      const sourceSchemaEntryTranslated: SchemaEntry = {
        key: sourceSchemaEntry.key,
        name: t(sourceSchemaEntry.nameI18nKey),
      };
      resultSchema.splice(insertSourceAt + 1, 0, sourceSchemaEntryTranslated);

      return resultSchema;
    },
    [streamCalculationProperties, streamFieldProperties]
  );

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:catalog:stream:cta:noAssetsSelected':
        filteredRecords.length === 0,
    }),
    [filteredRecords.length]
  );

  // EFFECTS
  useEffect(() => {
    dispatch(
      fetchStreamFields({
        streamId,
        deepFetch: true,
      })
    );
    dispatch(fetchProcessedDataSources(selectedDesignId));
    dispatch(fetchStreamCalculations({ streamId }));
    dispatch(fetchStreamGroupsAssets({ streamId }));
    dispatch(fetchStreamHubs({ streamId }));
    dispatch(fetchStreamMachineLearning({ streamId }));
  }, [streamId]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const renderFieldIcon = (field: StreamEntity) => {
    let icon = 'EykoData';

    if (isCalculation(field)) {
      icon = 'Calculator';
    }
    if (isGroup(field)) {
      icon = 'RowsGroup';
    }

    if (isHub(field)) {
      icon = 'EykoHubs';
    }
    if (isStreamMachineLearning(field)) {
      icon = 'Robot';
    }

    if (isStreamField(field)) {
      icon = dataTypeIcons[field.dataType] || 'EykoData';
    }

    return (
      <Icon
        iconName={icon}
        styles={{
          root: {
            color: 'black',
            height: 16,
            width: 16,
          },
        }}
      />
    );
  };

  const getDataSource = (field: StreamEntity) => {
    if ((field as any)?.calculationId) {
      return null;
    }

    const entityField = entityFields.find(
      (f) => f.id === (field as any)?.sourceFieldId
    );
    const entity = entities.find((e) => e.id === entityField?.parentId);
    const dataSource = dataSources.find((d) => d.id === entity?.parentId);

    return dataSource;
  };

  const renderField = (f: StreamEntity) => {
    const dataSource = getDataSource(f);
    const dataSourceColor = dataSource && numberToHexColor(dataSource?.color);

    return (
      <EntityField
        key={f.id}
        name={f?.processedName || f.name}
        info={{
          data: {
            ...f,
            [ancestorSourceNameKey]: dataSource?.name,
          },
          schema: getSchema(f),
          title: dataSourceColor ? (
            <DataSourceCalloutTitle name={f.name} color={dataSourceColor} />
          ) : (
            f.name
          ),
        }}
        slot1={
          dataSourceColor ? (
            <DataSourceIcon color={dataSourceColor} />
          ) : (
            <EntityFieldSlotSpacer />
          )
        }
        slot2={renderFieldIcon(f)}
        searchQuery={searchQuery}
        availability={f.availability}
        isOrange={f.processStatus !== StreamReferenceProcessStatus.Processed}
      />
    );
  };

  const renderItem = (item: IFoldableListItem) => {
    const field = normalizedRecords[item.key];

    return field ? renderField(field) : null;
  };

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (currentSort === DefinedSortTypes.Name) {
    return <div>{filteredRecords.map((f) => renderField(f))}</div>;
  }

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onClick={onClickHeaderHandler}
        >
          {group.items.map((item) => renderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default AssetsData;
