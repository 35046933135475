import { CancelToken } from 'axios';
import { omit, uniqBy } from 'lodash';
import i18n from '../../../../../config/i18n';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import {
  createNestedEntity,
  fetchEntities,
  fetchEntity,
  fetchNestedEntities,
} from '../../../api';
import * as api from '../../../Catalog/api';
import {
  ApiV4ResponseWrapper,
  DataSourceConfigureStatus,
  EntityIngestionStatus,
  IDesignSourceEntity,
  IDesignSourceEntityField,
  IType,
} from '../../../types';
import { IGenericEntity, SchemaIconType } from '../../types';
import { IEntity } from '../LinkPicker/types';
import { translateApiName } from '../../../../../config/i18n/utils';
import { IEntityOption } from './types';
import {
  i18nNameKey,
  i18nCategoryKey,
} from '../../../../../api/interceptors/i18n/constants';
import { FeatureStatus } from '../../../../../api/model/schemas/FeatureStatus';
import { Hub } from '../../../../../api/model/schemas/Hub';
import { DataSourceEntityField } from '../../../../../api/model/schemas/DataSourceEntityField';
import { DataSourceEntity } from '../../../../../api/model/schemas/DataSourceEntity';
import { DataType } from '../../../../../api/model/schemas/DataType';

export const disableInvalidAssetOptionsByDataType = (
  assetOptions: IEntityOption[],
  parentDataType: DataType,
  parentTypeId?: string
): IEntityOption[] => {
  if (!parentDataType) {
    return assetOptions;
  }
  return assetOptions.map((option) => {
    const dataType = option.data.ingestedDataType || option.data.dataType;
    if (parentTypeId === TYPE_IDS.Calculation) {
      if (
        parentDataType === DataType.NumericDecimal &&
        ![DataType.NumericDecimal, DataType.NumericInteger].includes(dataType)
      ) {
        return { ...option, isDisabled: true };
      }
      return option;
    }

    if (
      parentDataType === DataType.NumericDecimal &&
      [DataType.NumericDecimal, DataType.NumericInteger].includes(dataType)
    ) {
      return option;
    }

    if (dataType === parentDataType) {
      return option;
    }

    return { ...option, isDisabled: true };
  });
};

export const fetchTypes = (typesIds: string[]) =>
  Promise.all(
    typesIds.map(async (id) => {
      /**
       * Client-side patch for literal values type, which atm does not have
       * type metadata from the server
       */
      if (id === TYPE_IDS.LiteralValue)
        return {
          id: TYPE_IDS.LiteralValue,
          name: 'Literal value',
          iconType: SchemaIconType.FabricMdl2,
          icon: 'EykoLiteralValue',
        };
      const { data } = await fetchEntity<IType>(TYPE_IDS.Type, id)();
      return data;
    })
  );

export const fetchSourceEntities = async (
  sourceId: string,
  entityId?: string,
  tryToLoadEntityFields = false,
  selectedFieldsOnly = false
) => {
  if (tryToLoadEntityFields) {
    const {
      data: { data: entities },
    } = await api.fetchEntities(sourceId)({
      filter: `configureStatus eq '${DataSourceConfigureStatus.Configured}'${
        entityId ? ` and id eq ${entityId}` : ''
      }`,
    });

    await Promise.all(
      entities
        .filter((entity) => !entity.fieldsLoaded)
        .map((entity) =>
          createNestedEntity({
            parentEntityId: entity.id,
            typeId: TYPE_IDS.LoadEntityFieldsRequest,
          })({
            loadOperation: 'Load',
            operation: 'NoChange',
          })
        )
    );

    const filter = `parent/parentId eq ${sourceId}${
      entityId ? ` and parentId eq ${entityId}` : ''
    }${
      selectedFieldsOnly
        ? ` and (ingestionStatus eq '${EntityIngestionStatus.Processed}' or ingestionStatus eq '${EntityIngestionStatus.PendingProcess}')`
        : ''
    }`;

    const {
      data: { data: fields },
    } = await fetchEntities<ApiV4ResponseWrapper<IDesignSourceEntityField[]>>(
      TYPE_IDS.SourceEntityField
    )({
      filter,
    });

    return { entities, fields };
  }

  type ExpandedEntityFields = IDesignSourceEntityField & {
    parent: IDesignSourceEntity;
  };
  const filter = `parent/parentId eq ${sourceId}${
    entityId ? ` and parentId eq ${entityId}` : ''
  }${
    selectedFieldsOnly
      ? ` and (ingestionStatus eq '${EntityIngestionStatus.Processed}' or ingestionStatus eq '${EntityIngestionStatus.PendingProcess}')`
      : ''
  }`;
  const {
    data: { data: expandedEntityFields },
  } = await fetchEntities<ApiV4ResponseWrapper<ExpandedEntityFields[]>>(
    TYPE_IDS.SourceEntityField
  )({
    filter,
    expand: 'parent',
  });

  const entities = uniqBy(
    expandedEntityFields.map((field) => field.parent),
    'id'
  );

  const fields: IDesignSourceEntityField[] = expandedEntityFields.map((f) =>
    omit(f, 'parent')
  );

  return { entities, fields };
};

const getSortedEntities = <T extends IGenericEntity>(entities: T[]) =>
  [...entities].sort((a, b) => a?.name?.localeCompare(b?.name));

export const getAllowedEntityOptions = (
  options: IEntityOption[] = [],
  allowedDataTypes: DataType[]
) =>
  allowedDataTypes
    ? options.map((option) => ({
        ...option,
        isDisabled: !allowedDataTypes.includes(
          option?.data?.ingestedDataType || option?.data?.dataType
        ),
      }))
    : options;

export const disableByReferenceType = (
  options: IEntityOption[],
  referenceTypeFilterProperty: string,
  referenceTypeFilterValue: string
): IEntityOption[] =>
  options.map((option) => ({
    ...option,
    isDisabled:
      option.isDisabled ||
      (option?.data?.[referenceTypeFilterProperty] !== undefined &&
        option?.data?.[referenceTypeFilterProperty] !==
          referenceTypeFilterValue),
  }));

interface SourceEntitiesToOptions {
  entities: IEntity[];
  dataType?: DataType;
  parentTypeId?: string;
  allowedDataTypes?: DataType[];
  referenceTypeFilterProperty?: string;
  referenceTypeFilterValue?: string;
}

export const sourceEntitiesToOptions = ({
  entities,
  dataType,
  parentTypeId,
  allowedDataTypes,
  referenceTypeFilterProperty,
  referenceTypeFilterValue,
}: SourceEntitiesToOptions): IEntityOption[] =>
  entities
    .flatMap((entity) => {
      const assetOptions: IEntityOption[] = entity.fields.map((field) => ({
        value: field.id,
        label: field.name,
        subLabel: entity.name,
        data: {
          parent: entity.name,
          dataType: field?.dataType,
          ingestedDataType: field?.ingestedDataType,
          ...(referenceTypeFilterProperty
            ? {
                [referenceTypeFilterProperty]:
                  field?.[referenceTypeFilterProperty],
              }
            : {}),
        },
      }));

      let options: IEntityOption[] = [];

      if (allowedDataTypes || parentTypeId === TYPE_IDS.Calculation) {
        options = getAllowedEntityOptions(assetOptions, allowedDataTypes);
      } else {
        options = disableInvalidAssetOptionsByDataType(
          assetOptions,
          dataType,
          parentTypeId
        );
      }

      if (referenceTypeFilterProperty) {
        return disableByReferenceType(
          options,
          referenceTypeFilterProperty,
          referenceTypeFilterValue
        );
      }

      return options;
    })
    .sort((a, b) => a.label.localeCompare(b.label));

interface EntitiesTopOptions {
  entities: IGenericEntity[];
  dataType?: DataType;
  parentTypeId?: string;
  allowedDataTypes?: DataType[];
  referenceTypeFilterProperty?: string;
  referenceTypeFilterValue?: string;
}

export const entitiesToOptions = ({
  entities,
  dataType,
  parentTypeId,
  allowedDataTypes,
  referenceTypeFilterProperty,
  referenceTypeFilterValue,
}: EntitiesTopOptions): IEntityOption[] => {
  const untagged = i18n.t('sortControls:untagged');
  const sortedEntities = getSortedEntities(entities);

  const tags = sortedEntities.reduce((arr: string[], entity) => {
    if (entity?.tags?.length) {
      return [...arr, ...entity.tags.filter((tag) => !arr.includes(tag))];
    }
    return arr.includes(untagged) ? arr : [...arr, untagged];
  }, []);
  // Enforce untagged to be last in the list
  if (tags.includes(untagged)) {
    tags.push(tags.splice(tags.indexOf(untagged), 1)[0]);
  }

  return tags
    .flatMap((tag) => {
      const assetOptions = sortedEntities
        .filter((entity) =>
          tag === untagged ? !entity?.tags?.length : entity?.tags?.includes(tag)
        )
        .map((entity) => ({
          value: entity.id,
          label: entity.name,
          subLabel: tag,
          isDisabled: entity?.status === FeatureStatus.ComingSoon,
          data: {
            parent: tag,
            dataType: entity?.dataType,
            ingestedDataType: entity?.ingestedDataType,
            ...(referenceTypeFilterProperty
              ? {
                  [referenceTypeFilterProperty]:
                    entity?.[referenceTypeFilterProperty],
                }
              : {}),
          },
        }));

      let options: IEntityOption[] = [];

      if (allowedDataTypes || parentTypeId === TYPE_IDS.Calculation) {
        options = getAllowedEntityOptions(assetOptions, allowedDataTypes);
      } else {
        options = disableInvalidAssetOptionsByDataType(
          assetOptions,
          dataType,
          parentTypeId
        );
      }

      if (referenceTypeFilterProperty) {
        return disableByReferenceType(
          options,
          referenceTypeFilterProperty,
          referenceTypeFilterValue
        );
      }

      return options;
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const categorizedEntitiesToOptions = (
  entities: IGenericEntity[] = []
) => {
  const sortedEntities = getSortedEntities(entities);

  type CategoryDescriptor = { category: string; [i18nCategoryKey]: string };
  const categories = sortedEntities.reduce<CategoryDescriptor[]>(
    (accumulator, { category, [i18nCategoryKey]: i18nCategory }) =>
      !accumulator.find(
        ({ category: storedCategory }) => storedCategory === category
      )
        ? [...accumulator, { category, i18nCategory }]
        : accumulator,
    []
  );

  return categories.flatMap(({ category, [i18nCategoryKey]: i18nCategory }) => [
    {
      label: i18nCategory ?? category,
      options: sortedEntities
        .filter((entity) => entity.category === category)
        .map((entity) => ({
          value: entity.id,
          label:
            entity[i18nNameKey] ??
            (entity.isLocked
              ? translateApiName(entity.$typeId, entity.name)
              : entity.name),
          isDisabled: entity?.status === FeatureStatus.ComingSoon,
          data: { ...entity, parent: category },
        })),
    },
  ]);
};

export const getLabel = (selectedId: string, options: IEntityOption[]) =>
  options.find((option) => option.value === selectedId)?.data?.parent;

type FetchRecordCountParams = {
  typeId: string;
  entityId: string;
  cancelToken: CancelToken;
};

export const fetchHubRecordCount = async ({
  entityId,
  typeId,
  cancelToken,
}: FetchRecordCountParams) => {
  const { data } = await fetchEntity<Hub>(typeId, entityId)(
    undefined,
    cancelToken
  );

  return data.records;
};

export const fetchGroupAssetCount = async ({
  entityId,
  typeId,
  cancelToken,
}: FetchRecordCountParams) => {
  const { data: groupAsset } = await fetchEntity<DataSourceEntityField>(
    typeId,
    entityId
  )(undefined, cancelToken);
  const { data } = await fetchNestedEntities<ApiV4ResponseWrapper<any>>({
    typeId: TYPE_IDS.GroupValue,
  })(groupAsset.parentId)(
    {
      count: true,
    },
    cancelToken
  );

  return data.count;
};

export const fetchSourceEntityFieldRecordCount = async ({
  entityId,
  typeId,
  cancelToken,
}: FetchRecordCountParams) => {
  const { data: field } = await fetchEntity<DataSourceEntityField>(
    typeId,
    entityId
  )(undefined, cancelToken);
  const { data: sourceEntity } = await fetchEntity<DataSourceEntity>(
    field.$parentTypeId,
    field.parentId
  )(undefined, cancelToken);

  return sourceEntity.rowCount;
};

export const FETCH_RECORDS_BY_TYPE_ID = {
  [TYPE_IDS.Hub]: fetchHubRecordCount,
  [TYPE_IDS.SourceEntityField]: fetchSourceEntityFieldRecordCount,
  [TYPE_IDS.Group]: fetchGroupAssetCount,
};

interface GenerateTypedEntititesTaskId {
  typeId: string;
  sourceId?: string;
}

export const generateTypedEntititesTaskId = ({
  typeId,
  sourceId,
}: GenerateTypedEntititesTaskId) =>
  `typeId=${typeId}${sourceId ? `&sourceId=${sourceId}` : ''}`;
