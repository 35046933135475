/* eslint-disable default-case */
import React from 'react';
import { useSelector } from 'react-redux';
import { enforceSchema } from '../../shared/types/utils';
import { SelectionType } from '../components/ui/ViewControlButton';

import { getSelectionType } from '../modules/App/selectors';

interface HasId {
  id: string;
  [key: string]: any;
}

interface Params<T> {
  entities: T[];
  selectedEntitiesIds: string[];
}

type TransformationFunc<T> = (input: T[], selectedEntitiesId: string[]) => T[];
type TransformationMap = Record<SelectionType, TransformationFunc<any>>;

const transformationMap = enforceSchema<TransformationMap>()({
  [SelectionType.all]: <T extends HasId>(input: T[]): T[] => input,
  [SelectionType.selected]: <T extends HasId>(
    input: T[],
    selectedEntities
  ): T[] => input.filter((item) => selectedEntities.includes(item.id)),
  [SelectionType.unselected]: <T extends HasId>(
    input: T[],
    selectedEntities
  ): T[] => input.filter((item) => !selectedEntities.includes(item.id)),
});

export default function useEntitiesSelections<T extends HasId>({
  entities,
  selectedEntitiesIds,
}: Params<T>) {
  const selectionType = useSelector(getSelectionType);

  // NOTE: some views might introduce an unsupported value in the store so we need a default
  const transformation =
    transformationMap[selectionType] ?? transformationMap[SelectionType.all];

  const result = React.useMemo(() => {
    if (!entities) return [];
    if (!selectedEntitiesIds) return entities;

    return transformation(entities, selectedEntitiesIds);
  }, [transformation, selectedEntitiesIds, entities]);

  return result;
}
