import React from 'react';
import { authConnect, authDisconnect } from '.';
import i18n from '../../config/i18n';
import { authStateSerializer } from './authStorage';

interface AuthContextState {
  authError?: string;
  isSignedIn: boolean;
  isSignInPending: boolean;
  signIn: (instanceUrl: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const defaultValue: AuthContextState = {
  isSignedIn: false,
  isSignInPending: false,
  signIn: async () => {},
  signOut: async () => {},
};

export const AuthContext = React.createContext(defaultValue);

interface AuthProviderProps {
  children?: React.ReactNode;
  isSignedIn?: boolean;
}

export const AuthProvider = ({
  children,
  isSignedIn: isSignedInInitial = false,
}: AuthProviderProps) => {
  // STATE
  const [authError, setAuthError] = React.useState<string>();
  const [isSignedIn, setIsSignedIn] = React.useState(isSignedInInitial);
  const [isSignInPending, setIsSignInPending] = React.useState(false);

  // CALLBACKS
  const handleError = React.useCallback((error: unknown) => {
    if (typeof error === 'string') {
      setAuthError(error);
    } else {
      setAuthError(i18n.t('auth:error.unknown'));
      console.error(error);
    }
  }, []);

  const signIn = React.useCallback(async (instanceUrl: string) => {
    setAuthError(undefined);
    setIsSignInPending(true);

    try {
      const authResult = await authConnect(instanceUrl);
      if (authResult) {
        authStateSerializer.save(authResult);
        setIsSignedIn(true);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSignInPending(false);
    }
  }, []);

  const signOut = React.useCallback(async () => {
    setAuthError(undefined);

    try {
      await authDisconnect();
      authStateSerializer.remove();
      setIsSignedIn(false);
      // TODO: we need to implement a better, more reusable "auth gating" approach to limit access
      // to specific parts of the app (streamer, builder, designer) that will automatically
      // pick up on the changes in the auth context
    } catch (error) {
      handleError(error);
    }
  }, []);

  // RESULT
  const authContextValue = React.useMemo<AuthContextState>(
    () => ({
      authError,
      isSignInPending,
      isSignedIn,
      signIn,
      signOut,
    }),
    [authError, isSignedIn, isSignInPending]
  );

  // RENDER
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
