import React from 'react';
import { Trans } from 'react-i18next';
import { DataSourceProcessStatus } from '../../../../../api/model/schemas/DataSourceProcessStatus';
import { DataSourceConfigureStatus, IDesignSource } from '../../../types';

export const isSourceSyncing = (source: IDesignSource) =>
  source.configureStatus === DataSourceConfigureStatus.Configuring ||
  source.processStatus === DataSourceProcessStatus.Processing;

export const getSyncingText = (source: IDesignSource) => {
  if (source.processStatus === DataSourceProcessStatus.Processing) {
    return <Trans i18nKey="other:processing.ingestingDataSource" />;
  }

  if (source.configureStatus === DataSourceConfigureStatus.Configuring) {
    return <Trans i18nKey="other:processing.configuringDataSource" />;
  }

  return null;
};
