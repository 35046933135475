import { createSelector } from 'reselect';
import { ApplicationState } from '../../../store/types';
import { IDesignTag } from '../types';
import { DesignerMetadataState } from './reducer';

export const getMetadataState = (
  state: ApplicationState,
): DesignerMetadataState => state.designerMetadata;

export const getAllTags = (state: ApplicationState):IDesignTag[] => getMetadataState(state).tags;
export const getIsMetadataStateLoading = (state: ApplicationState) => getMetadataState(state).isLoading;

export const getTagById = (id: string) => (state: ApplicationState):IDesignTag | null => {
  const tags = getAllTags(state);

  if (!tags || !id) return null;

  return tags.find((tag) => tag.id === id);
};

export const getIsNewTagInputOpen = (state: ApplicationState):boolean => getMetadataState(state).isNewTagInputOpen;
export const getCatalogDefinition = (state: ApplicationState) => getMetadataState(state).catalogDefinition;

export const getSortedCatalogDefinition = createSelector(
  getCatalogDefinition,
  (catalogEntries) => {
    const sortedEntries = catalogEntries.slice().sort((entryA, entryB) => entryA.name.localeCompare(entryB.name));

    return sortedEntries.map((entry) => ({
      ...entry,
      properties: entry.properties.slice().sort((propA, propB) => propA.name.localeCompare(propB.name)),
    }));
  },
);

export const getCatalogEntrySelection = (state: ApplicationState) => getMetadataState(state).catalogEntrySelection;
export const getCatalogPropertySelection = (state: ApplicationState) => getMetadataState(state).catalogPropertySelection;
export const getCatalogPropertyById = (propertyId: string) => createSelector(
  getCatalogDefinition,
  catalogEntries => {
    const properties = catalogEntries.map(entry => entry.properties).flat();
    return properties.find(property => property.id === propertyId);
  },
);