import { mergeStyleSets } from 'office-ui-fabric-react';
import { ENTITY_FIELD_SLOT_WIDTH } from '../../../../constants/design';

export const classNames = mergeStyleSets({
  spinner: {
    marginLeft: '5px!important',
  },
  dataSourceColor: {
    height: '32px',
    width: ENTITY_FIELD_SLOT_WIDTH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: '12px',
  },
  calloutTarget: {
    position: 'absolute',
    left: 0,
    bottom: '-5px',
    width: '1px',
    height: '1px',
    background: 'transparent',
  },
  noEntitiesText: {
    marginTop: '10px',
    display: 'block',
  },
  btnWrapper: { position: 'relative', display: 'inline' },
});
