import { IIconProps } from '@fluentui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { HeaderBarButton } from '../../../../components/ui';
import { routes } from '../../../../constants/routes';
import { getLinksFields } from '../../../Designer/Ingestion/selectors';
import { performLinksAnalysis } from '../../actions';
import {
  getLinksAnalysisState,
  getSelectedBuilderStreamId,
} from '../../selectors';
import { BuilderProcessStreamButton } from '../BuilderProcessStreamButton/BuilderProcessStreamButton';

const MAGIC_WAND_ICON: IIconProps = {
  iconName: 'SmartCatalogMagicWand',
};

export const LinksHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedStreamId = useSelector(getSelectedBuilderStreamId);
  const linksFields = useSelector(getLinksFields);
  const { isLoading } = useSelector(getLinksAnalysisState);

  const handleWandClick = async () => {
    dispatch(performLinksAnalysis(selectedStreamId));
    history.push(routes.builder.assets.linksWizard);
  };

  return (
    <>
      <HeaderBarButton
        iconProps={MAGIC_WAND_ICON}
        onClick={handleWandClick}
        disabled={Object.keys(linksFields).length === 0 || isLoading}
        shouldHideOnDisabled
      />
      <BuilderProcessStreamButton />
    </>
  );
};
