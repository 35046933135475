import {
  ITextStyles,
  IButtonStyles,
  mergeStyles,
  ISpinnerStyles,
  IIconStyles,
  ITheme,
} from '@fluentui/react';
import React from 'react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../theme';

export const containerStyles = mergeStyles({
  paddingLeft: '16px',
  paddingRight: '16px',
});

export const getCardStyles = {
  root: {
    padding: '8px',
    marginLeft: 2,
    marginBottom: 8,
    marginRight: 2,
    minWidth: 'auto',
    position: 'relative' as const,
    background: 'white',
    border: `1px solid ${baseColors.inputBorder}`,
    borderRadius: BORDER_RADIUS_IN_PX,
  },
};

export const messageWrapperStyles = (theme: ITheme): ITextStyles => ({
  root: {
    display: 'inline',
    fontSize: '12px',
    selectors: {
      strong: {
        color: theme.palette.themePrimary,
      },
    },
  },
});

export const titleStyles: ITextStyles = {
  root: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
};

export const descriptionStyles: ITextStyles = {
  root: {
    fontSize: '12px',
  },
};

export const dismissButtonStyles = (theme: ITheme): IButtonStyles => ({
  root: {
    color: theme.palette.themePrimary,
    height: '19px',
    padding: 0,
    margin: 0,
  },
  label: {
    margin: 0,
    fontSize: 12,
    padding: 0,
  },
});

export const dateStyles: ITextStyles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '12px',
    color: baseColors.iconColor,
    textAlign: 'right',
    marginTop: '3px !important',
    selectors: {
      i: {
        marginRight: '6px',
      },
    },
  },
};

export const getCancelBtnStyles: IButtonStyles = {
  root: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    marginTop: '0 !important',
    height: 'auto',
    padding: '0',
    selectors: {
      i: {
        fontSize: '16px',
        margin: 0,
        color: `${baseColors.iconColor} !important`,
      },
      ':hover .ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
  rootHovered: {
    color: baseColors.iconColor,
  },
};

export const cardSectionStyles = {
  root: {
    paddingRight: '12px',
    flex: 1,
  },
};

export const spinnerStyles: ISpinnerStyles = {
  root: {
    margin: '20px',
  },
};

export const getIconStyles: IIconStyles = {
  root: {
    color: baseColors.iconColor,
  },
};

export const detailsStyles: ITextStyles = {
  root: {
    background: baseColors.lightGrey,
    border: `1px solid ${baseColors.outlineGray}`,
    borderRadius: BORDER_RADIUS_IN_PX,
    padding: '8px',
    fontSize: '12px',
    wordBreak: 'break-word',
  },
};

export const showMoreStyles: React.CSSProperties = {
  marginLeft: '3px',
};

export const messageStyles: React.CSSProperties = {
  display: 'inline',
};
