import {
  mergeStyleSets,
} from '@fluentui/react';

export const classNames = mergeStyleSets({
  container: {
    flex: '1 1 auto',
    overflowY: 'auto',
    maxHeight: '100%',
  },
});
