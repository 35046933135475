import { DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-utilities';
import React from 'react';
import { Trans } from 'react-i18next';
import { HeaderBarButton, InformationDialog } from '../../../../components/ui';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { ADDIN_PANE_OFFSET } from '../../../../hooks/use-information-dialog/constants';
import { fetchNestedEntities } from '../../../Designer/api';
import { ApiV4ResponseWrapper } from '../../../Designer/types';
import { StreamOutput as IStreamOutput } from '../../../../api/model/schemas/StreamOutput';
import { StreamOutputContent } from './StreamOutputContent';

interface Props {
  streamId: string;
  isStreamProcessed: boolean;
}

export const StreamOutput = ({ streamId, isStreamProcessed }: Props) => {
  // STATE
  const [isInfoOpen, setIsInfoOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [streamOutputData, setData] = React.useState<IStreamOutput>(null);

  // HOOKS
  const componentId = useId('stream-output');

  // CALLBACKS
  const fetchStreamOutput = async () => {
    setLoading(true);
    const {
      data: { data: streamOutputs },
    } = await fetchNestedEntities<ApiV4ResponseWrapper<IStreamOutput[]>>({
      typeId: TYPE_IDS.StreamOutput,
    })(streamId)();

    // right now we only support 1 output
    setData(streamOutputs[0]);
    setLoading(false);
  };

  const onClickInfo = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsInfoOpen((isOpen) => {
        const nextValue = !isOpen;

        if (nextValue && !streamOutputData) {
          fetchStreamOutput();
        }

        return nextValue;
      });
    },
    [streamOutputData, streamId]
  );

  const closeDialog = React.useCallback(() => {
    setIsInfoOpen(false);
  }, []);

  // EFFECTS
  React.useEffect(() => {
    if (streamOutputData) {
      setData(null);
    }
  }, [streamId]);

  // RENDER
  if (!streamId) return null;

  return (
    <>
      <HeaderBarButton
        id={componentId}
        iconProps={{ iconName: 'StreamOutput' }}
        onClick={onClickInfo}
        disabled={!isStreamProcessed}
        shouldHideOnDisabled
        tooltip={<Trans i18nKey="streamOutput:eyko Streaming Service" />}
      />
      <InformationDialog
        target={`#${componentId}`}
        onDismiss={closeDialog}
        onClose={closeDialog}
        calloutWidth={document.body.clientWidth - ADDIN_PANE_OFFSET}
        directionalHint={DirectionalHint.bottomRightEdge}
        isCalloutVisible={isInfoOpen}
        title={<Trans i18nKey="streamOutput:eyko Streaming Service" />}
      >
        <StreamOutputContent
          isLoading={isLoading}
          streamOutputData={streamOutputData}
        />
      </InformationDialog>
    </>
  );
};
