import { Icon, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React from 'react';
import { buttonIconStyles, buttonSpinnerStyles } from '../styles';

interface Props {
  isError: boolean;
  isCopyingFinished: boolean;
  isWritingToClipboard: boolean;
}

export const ShareIndicator = ({
  isCopyingFinished,
  isError,
  isWritingToClipboard,
}: Props) => (
  // RENDER
  <>
    {isWritingToClipboard && (
      <Spinner size={SpinnerSize.xSmall} styles={buttonSpinnerStyles} />
    )}
    {isCopyingFinished && (
      <Icon
        iconName={isError ? 'Error' : 'Completed'}
        styles={buttonIconStyles}
      />
    )}
  </>
);
