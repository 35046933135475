import React from 'react';

interface UseAllowFormSubmission {
  shouldAllowSubmission: boolean;
  setPreventSubmission: (b: boolean) => void;
  shouldSkipFollowingSections: boolean;
  setShouldSkipFollowingSections: (b: boolean) => void;
}

const Context = React.createContext<UseAllowFormSubmission>(undefined);

export const useFormSubmission = () => React.useContext(Context);

// eslint-disable-next-line react/display-name
export const FormSubmissionProvider = ({
  isValid,
  preventFormSubmission,
  setPreventSubmission,
  shouldSkipFollowingSections,
  setShouldSkipFollowingSections,
  children,
}) => (
  // Some cases of the entity form require custom validation
  // which is not practical to handle by react-hook-form validation system,
  // so we use this to handle those cases.
  <Context.Provider
    value={{
      shouldAllowSubmission: isValid && !preventFormSubmission,
      setPreventSubmission,
      shouldSkipFollowingSections,
      setShouldSkipFollowingSections,
    }}
  >
    {children}
  </Context.Provider>
);
