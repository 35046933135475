import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Text, getTheme, mergeStyleSets, Stack,
} from '@fluentui/react';
import { Route, Switch } from 'react-router-dom';
import { selectors } from '../../Streams';
import { getStyles } from './styles';

import { ObjectTypes } from '../../../App/types';
import { getActiveObjectType } from '../../../App/selectors';
import ActiveObjectIcon from './ActiveObjectIcon';
import SortControl from '../../../Designer/components/SortControl';
import { routes } from '../../../../constants/routes';
import SectionTitle from '../../../../components/SectionTitle';

interface Props {
  title: string
}

const StreamerHeader: React.FC<Props> = ({
  title,
}) => {
    // HOOKS
    const theme = getTheme();

    // STATE
    const currentDataset = useSelector(selectors.getCurrentDataset);
    const { totalPages, pagesFetched } = useSelector(selectors.getFetchingProgress);
    const activeObjectType = useSelector(getActiveObjectType);

    // DERIVED STATE
  const rowDifference = currentDataset && currentDataset.rows
    ? currentDataset.totalRowCount - currentDataset.rows.length
    : 0;

  const classNames = useMemo(() => {
    const styles = getStyles(theme, rowDifference);
    return mergeStyleSets(styles);
  }, [rowDifference]);

  const fetchingPages = totalPages > 0;
  const fetchedPercent = Math.round((pagesFetched / totalPages) * 100);
  const objectType = currentDataset?.type || activeObjectType;
  
  // PARTS
  const renderCurrentStatus = () => {
    if (objectType === ObjectTypes.GROUP_TABLE) {
      return fetchingPages && `${fetchedPercent}%`;
    }

    return (
      <>
        <span className={classNames.rows}>
          {currentDataset?.rows?.length || '-'}
        </span>
        /
        {currentDataset?.totalRowCount || '-'}
      </>
    );
  };

  const streamingInfo = () => (
    <>
      <Text className={classNames.status}>
        {renderCurrentStatus()}
      </Text>
      <ActiveObjectIcon />
    </>
  );

  return (
    <Switch>
      <Route path={routes.groupTable.design}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ position: 'relative' }}
        >
          <SectionTitle title={title} />
          {streamingInfo()}
        </Stack>
      </Route>
      <Route>
        <SortControl title={title}>
          {streamingInfo()}
        </SortControl>
      </Route>
    </Switch>

  );
};

export default StreamerHeader;
