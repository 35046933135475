import debounce from 'lodash/debounce';

// based on https://github.com/lodash/lodash/issues/4815#issuecomment-805433955
// with the addition of `.cancel()`
/**
 * Debounces an async function in a way that can be awaited.
 * By default debounce doesn't return a promise for async functions
 * @param func async function to debounce
 * @param wait same function as lodash.debounce's wait parameter.
 *             Call this function at most this often.
 * @returns a promise which will be resolved/ rejected only if the function is executed,
 *          with the result of the underlying call.
 */
export interface AsyncDebounceResult<
  F extends (...args: any[]) => Promise<any>
> {
  (...args: Parameters<F>): ReturnType<F>;
  cancel(): void;
}

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number
): AsyncDebounceResult<F> {
  const debounced = debounce((resolve, reject, args: Parameters<F>) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);

  const result = (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    }) as ReturnType<F>;

  result.cancel = () => debounced.cancel();

  return result;
}
