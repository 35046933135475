// TODO: move into i18n/types
import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../../modules/Designer/types";
import { Hub } from "../../../../model/schemas/Hub";
import { TypeIds } from "../../../../model/schemas";
import { isResponseTypeIdMatching } from "../../utils";
import { i18nDataTypeKey, i18nFormatTypeKey, i18nTypeKey } from "../../constants";
import { translateApiName } from "../../../../../config/i18n/utils";
import { TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP } from "../../../../constants";

export const isHubsResponse = (
  response: AxiosResponse<ApiV4ResponseWrapper<unknown>>
): response is AxiosResponse<ApiV4ResponseWrapper<Hub[]>> =>
  isResponseTypeIdMatching(response, TypeIds.Hub);

export const mutablyParseHubs = (response: AxiosResponse<ApiV4ResponseWrapper<Hub[]>>) => {
  response.data.data = response.data.data.map(hub => {
    return {
      ...hub,
      [i18nDataTypeKey]: translateApiName(hub.$typeId, hub.dataType),
      [i18nFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[hub.dataType] ?? hub.$typeId,
        String(hub.formatType),
      ),
      [i18nTypeKey]: translateApiName(hub.$typeId, hub.type),
    };
  });

  return response;
}
