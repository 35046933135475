import * as effects from 'redux-saga/effects';
import { cancelCurrentOperation, incrementPagesFetched, setFetchingProgress } from '../actions';
import { selectors } from '../Streams';

export function* updateFetchingProgress() {
  yield effects.takeLatest(incrementPagesFetched.type, function* updater() {
    const { pagesFetched, totalPages } = selectors.getFetchingProgress(yield effects.select());
    if (totalPages && pagesFetched >= totalPages) {
      yield effects.put(setFetchingProgress({
        totalPages: 0,
        pagesFetched: 0,
      }));
    }
  });
}

export function* cancelFetchingProgress() {
  yield effects.takeEvery(cancelCurrentOperation.type, function* updater() {
    yield effects.put(setFetchingProgress({
      totalPages: 0,
      pagesFetched: 0,
    }));
  });
}
