import { createReducer } from '@reduxjs/toolkit';

import { AppState, DefinedSortTypes } from './types';
import * as actions from './actions';
import { SelectionType } from '../../components/ui/ViewControlButton';
import { setSelectedDesignId } from '../Designer/ContentLibrary/actions';

export const initialState: AppState = {
  activeObjectType: null,
  isOtherUserSyncing: false,
  selectedItemIds: [],
  sortControlType: DefinedSortTypes.Name,
  selectionType: SelectionType.all,
  isEditingItem: false,
  sortControlHistory: {},
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(setSelectedDesignId, (state) => ({
    ...state,
    selectedItemIds: [],
  }))
  .addCase(actions.setSelectedItemId, (state, { payload }) => ({
    ...state,
    selectedItemIds: [payload],
  }))
  .addCase(actions.setEditingItem, (state, { payload }) => ({
    ...state,
    isEditingItem: payload,
  }))
  .addCase(actions.setSortType, (state, {
    payload: {
      path,
      sortType,
    },
  }) => ({
    ...state,
    sortControlType: sortType,
    sortControlHistory: {
      ...state.sortControlHistory,
      [path]: sortType,
    },
  }))
  .addCase(actions.setSelectionType, (state, { payload }) => ({
    ...state,
    selectionType: payload,
  }))
  .addCase(actions.setMultipleSelectedItemIds, (state, { payload }) => ({
    ...state,
    selectedItemIds: [...payload],
  }))
  .addCase(actions.appendSelectedItemId, (state, { payload }) => ({
    ...state,
    selectedItemIds: [...state.selectedItemIds, payload],
  }))
  .addCase(actions.clearSelection, (state) => ({
    ...state,
    selectedItemIds: [],
  }))
  .addCase(actions.removeSelectedItemId, (state, { payload }) => ({
    ...state,
    selectedItemIds: state.selectedItemIds.filter((item) => item !== payload),
  }))
  .addCase(actions.setActiveObjectType, (state, { payload }) => ({
    ...state,
    activeObjectType: payload,
  }))
  .addCase(actions.setIsOtherUserSyncing, (state, { payload }) => ({
    ...state,
    isOtherUserSyncing: payload,
  })));

export default reducer;
