import { useId } from '@fluentui/react-utilities';
import {
  DirectionalHint,
  Stack,
  Text,
  Spinner,
  DefaultButton,
} from 'office-ui-fabric-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  HeaderBarButton,
  InformationDialog,
} from '../../../../../components/ui';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { createNestedEntity } from '../../../api';
import { getSelectedDesign } from '../../selectors';

export const ExportDesign = () => {
  // DEPS
  const { t } = useTranslation();

  // STATE
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const selectedDesign = useSelector(getSelectedDesign);
  const [isSuccess, setSuccess] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [hasError, setError] = React.useState(false);

  // HOOKS
  const componentId = useId('design-export');

  // CALLBACKS
  const closeDialog = React.useCallback(() => {
    setDialogOpen(false);
  }, []);

  const createSnapshot = React.useCallback(async () => {
    if (!selectedDesign) return;

    setLoading(true);
    try {
      await createNestedEntity({
        parentEntityId: selectedDesign.id,
        typeId: TYPE_IDS.CreateDesignSnapshotJob,
      })({ ExportName: `${new Date().toISOString()}` });

      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [selectedDesign]);

  return (
    <>
      <HeaderBarButton
        id={componentId}
        iconProps={{ iconName: 'Export' }}
        onClick={() => setDialogOpen(true)}
        disabled={!selectedDesign?.id}
        shouldHideOnDisabled
        tooltip={<Trans i18nKey="exportWizard:Export" />}
      />
      <InformationDialog
        target={`#${componentId}`}
        onDismiss={closeDialog}
        onClose={closeDialog}
        calloutWidth={300}
        directionalHint={DirectionalHint.bottomRightEdge}
        isCalloutVisible={isDialogOpen}
        title={<Trans i18nKey="exportWizard:Export" />}
      >
        <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
          style={{ marginBottom: '8px' }}
        >
          <DefaultButton
            onClick={createSnapshot}
            iconProps={{ iconName: 'Processing' }}
          >
            {t('designImportExport:startExport')}
          </DefaultButton>
        </Stack>

        {isLoading && <Spinner style={{ marginTop: '8px' }} />}

        {hasError && <Text>{t('designImportExport:errorMsg')}</Text>}

        {isSuccess && <Text>{t('designImportExport:exportQueued')}</Text>}
      </InformationDialog>
    </>
  );
};
