import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { fetchDesigns } from './api';

export const fetchAllDesigns = createAsyncThunk(
  'DESIGNER/CONTENT_LIBRARY/FETCH_DESIGNS',
  async () => {
    const { data: responseData } = await fetchDesigns();

    return responseData.data;
  },
);

export const setSelectedDesignId = createAction<string | null, 'DESIGNER/CONTENT_LIBRARY/SELECT_DESIGN'>('DESIGNER/CONTENT_LIBRARY/SELECT_DESIGN');
export const designSuccessfullyDeleted = createAction('DESIGNER/DESIGN_SUCCESSFULLY_DELETED');
