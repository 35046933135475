// TODO: remove this module altogether in favour of tutorial tiles
import React, { useState, useEffect } from 'react';
import { IButtonProps } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TeachingBubble } from '../../../../components/ui';
import { btnStyles } from './styles';
import { getClientPreferencesListAsObject } from '../../../ClientPreferences/selectors';
import { ToggleValues, UserPreferenceIds } from '../../../../types/IClientPreferences';
import { isOfficeEnabled as isOfficeEnabledUtil } from '../../../../config/buildType';

interface Props {
  bubbles: any;
  afterLastAction?(): void;
}

const TeachingBubbles: React.FC<Props> = ({ bubbles, afterLastAction }) => {
  // HOOKS
  const { t } = useTranslation();

  // STATE
  const [visible, setVisible] = useState(false);
  const [currentBubbleIndex, setCurrentBubbleIndex] = useState(0);
  const clientPreferences = useSelector(getClientPreferencesListAsObject);

  // DERIVED STATE
  const onboardingValue = clientPreferences[UserPreferenceIds.ONBOARDING];
  const isOfficeEnabled = isOfficeEnabledUtil();

  const currentBubble = bubbles[currentBubbleIndex];

  const isLast = currentBubbleIndex === bubbles.length - 1;

  const isDismissed = currentBubbleIndex < 0;
  const isBubbleSetFinished = currentBubbleIndex > bubbles.length - 1;
  const isOnboardingOff = onboardingValue === ToggleValues.Off;

  // CALLBACKS
  const handleClick = async () => {
    setCurrentBubbleIndex(currentBubbleIndex + 1);

    if (isLast && afterLastAction) {
      afterLastAction();
    }
  };

  const handleDismiss = () => {
    setCurrentBubbleIndex(-1);
  };

  // EFFECTS

  // Timeout here is necessary as animations where making it render in weird place
  // Also I think it is nice that it appears a bit later
  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), 1000);

    return () => clearTimeout(timeout);
  }, []);

  // PARTS
  const primaryButtonProps: IButtonProps = ({
    children: isLast ? t('teachingBubbles:gotIt') : t('onboarding:next'),
    onClick: handleClick,
    styles: btnStyles(),
  });

  // RENDER

  // To make all tests work we disable bubbles for cypress build, to be handled in the future
  if (isDismissed || isBubbleSetFinished || !visible || isOnboardingOff || !isOfficeEnabled) {
    return null;
  }

  // TEMPORARY HIDE THEM - there is no way to control it (no user preferences in API v4)
  return null;

  return (
    <TeachingBubble
      target={currentBubble.target}
      primaryButtonProps={primaryButtonProps}
      onDismiss={handleDismiss}
      hasCloseButton
      headline={currentBubble.headline}
      footerContent={`${currentBubbleIndex + 1} of ${bubbles.length}`}
    >
      { currentBubble.children }
    </TeachingBubble>

  );
};

export default TeachingBubbles;
