import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IFoldableListGroup,
  IFoldableListItem,
} from '../../../../components/ui/FoldableList/types/IFoldableListGroup';
import EntityField from '../../../../components/EntityField';

import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import useGetParsedTypeProperties from '../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../App/types';

import { getGroups, getSelectedStream } from '../../selectors';
import {
  assetClicked,
  AssetClickedIntent,
  assetsDataGroupClicked,
  fetchGroupsAssets,
  fetchStreamGroupsAssets,
} from '../../actions';
import { GroupCollapse } from '../../../../components/ui';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { getSchemaFromTypeProperties } from '../../../../utils/getSchemaFromTypeProperties';
import { getSelectedDesignId } from '../../../Designer/ContentLibrary/selectors';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { StreamAssetType, UIEnhancedGroupAsset } from '../../types';
import {
  normalizeById,
  normalizeByKey,
} from '../../../../utils/normalizeEntities';
import { useSearchFilteredRecords } from '../../../../hooks/useSearchFilteredRecords';
import { StreamReferenceProcessStatus } from '../../../../api/model/schemas/StreamReferenceProcessStatus';

const Groups: FunctionComponent = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const selectedStream = useSelector(getSelectedStream);
  const designId = useSelector(getSelectedDesignId);

  const { records, isLoading, streamGroupAssets, recordIdsBeingUpdated } =
    useSelector(getGroups);

  // DERIVED STATE
  const { properties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.Group,
  });

  const selectedGroupsIds = React.useMemo(
    () => streamGroupAssets.map((f) => f.groupId),
    [streamGroupAssets]
  );

  const { filteredRecords, searchQuery } = useSearchFilteredRecords(records);

  const groupsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds: selectedGroupsIds,
  });

  // TODO: talk to Stephan about adding missing fields to both the main response
  // as well as the list of shown attributes: group source (Hub, Field, etc.), group type (Logic, Values)
  const schema = getSchemaFromTypeProperties(properties, TYPE_IDS.Group);

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:catalog:groups:cta:noAssetsAvailable':
        records.length === 0,
    }),
    [records.length]
  );

  const normalizedGroupAssets = normalizeById(records);
  const normalizedStreamGroupAssetByGroupAssetId = normalizeByKey(
    streamGroupAssets,
    'groupAssetId'
  );

  // CALLBACKS
  const onClickItemHandler = (entity: UIEnhancedGroupAsset) => {
    dispatch(
      assetClicked({
        entity,
        actionType: streamGroupAssets.some(
          (streamGroupAsset) => streamGroupAsset.groupId === entity.id
        )
          ? AssetClickedIntent.REMOVE
          : AssetClickedIntent.ADD,
        assetType: StreamAssetType.Group,
      })
    );
  };

  const handleGroupClick = (group: IFoldableListGroup) => {
    const relatedFields = group.items.reduce<UIEnhancedGroupAsset[]>(
      (accumulator, item) => [...accumulator, normalizedGroupAssets[item.key]],
      []
    );

    dispatch(
      assetsDataGroupClicked({
        entities: relatedFields,
        selectedEntitiesIds: selectedGroupsIds,
        assetType: StreamAssetType.Group,
      })
    );
  };

  // EFFECTS
  useEffect(() => {
    // all groups in the design (which is a parent of a stream)
    dispatch(fetchGroupsAssets(selectedStream.parentId));
    dispatch(fetchStreamGroupsAssets({ streamId: selectedStream.id }));
  }, [dispatch]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({ input: groupsWhichIncludesSelections });

  const renderSource = (groupAsset: UIEnhancedGroupAsset) => {
    if (!groupAsset) return null;

    const isGroupSelected = selectedGroupsIds.includes(groupAsset.id);
    const isQueued =
      isGroupSelected &&
      normalizedStreamGroupAssetByGroupAssetId[groupAsset.id]?.processStatus ===
        StreamReferenceProcessStatus.NotProcessed;

    return (
      <EntityField
        key={groupAsset.id}
        isSelectable
        isOrange={isQueued}
        isSelected={isGroupSelected}
        isSyncing={recordIdsBeingUpdated.includes(groupAsset.id)}
        info={{
          data: groupAsset,
          schema,
        }}
        slot1={<EntityFieldSlotSpacer />}
        onClick={() => onClickItemHandler(groupAsset)}
        name={groupAsset.name}
        searchQuery={searchQuery}
        availability={groupAsset.availability}
      />
    );
  };

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (currentSort === DefinedSortTypes.Name) {
    return (
      <>
        {groupsWhichIncludesSelections
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((field) => renderSource(field))}
      </>
    );
  }

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = records.find((r) => r.id === item.key);

    return renderSource(entry);
  };

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onIconClick={onClickHeaderHandler}
          onTextClick={() => handleGroupClick(group)}
          isSyncing={group.items.some((item) =>
            recordIdsBeingUpdated.includes(item.key)
          )}
          isSelected={group.items.every((item) =>
            selectedGroupsIds.includes(item.key)
          )}
          selections={{
            current: selectedGroupsIds.filter((id) =>
              group.items.map((item) => item.key).includes(id)
            ).length,
            total: group.items.length,
          }}
        >
          {group.items.map((item) => onRenderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default Groups;
