import { Label, Stack, Toggle } from '@fluentui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import IconButton from '../../../../../../../../components/IconButton';
import SectionTitle from '../../../../../../../../components/SectionTitle';
import ViewControlButton from '../../../../../../../../components/ui/ViewControlButton';
import { IconType } from '../../../../../../../Designer/types';
import { getSelectedAndFetchedRawAssets } from '../../../../../../selectors';
import { StreamAssetRaw } from '../../../../../../types';
import {
  setClosedColumns,
  setClosedSwimlanes,
  setColumnsAssetId,
  setOutputViewType,
  setSwimlanesAssetId,
  toggleTableTotals,
} from '../../../../actions';
import {
  getAreTableTotalsEnabled,
  getColumns,
  getColumnsAssetId,
  getOutputViewType,
  getSwimlanes,
  getSwimlanesAssetId,
} from '../../../../selectors';
import { OutputViewType } from '../../../../types';
import { ViewGroupType } from '../../model';
import { classNames, mainWrapperStyles } from './styles';
import SelectDropdownVirtual from '../../../../../../../../components/ui/SelectDropdown/SelectDropdownVirtual';

const I18N_PREFIX = 'modules:streamer:sections:data:modes:boards:header';

const assetsToOptions = (assets: StreamAssetRaw[]) =>
  assets.map((asset) => ({ value: asset.id, label: asset.name }));

interface Props {
  title: string;
  isPanelView?: boolean;
}

const viewControlOptions = [
  {
    key: OutputViewType.kanban,
    name: 'Kanban',
    icon: 'BacklogBoard',
    iconType: IconType.Fabric,
  },
  {
    key: OutputViewType.table,
    name: 'Table',
    icon: 'Table',
    iconType: IconType.Fabric,
  },
];

const OutputHeader: React.FC<Props> = ({ title, isPanelView }) => {
  // HOOKS
  const { t } = useTranslation();

  // STATE
  const assets = useSelector(getSelectedAndFetchedRawAssets);
  const dispatch = useDispatch();
  const swimlanesId = useSelector(getSwimlanesAssetId);
  const columnsId = useSelector(getColumnsAssetId);
  const columns = useSelector(getColumns);
  const swimlanes = useSelector(getSwimlanes);
  const viewType = useSelector(getOutputViewType);
  const areTableTotalsEnabled = useSelector(getAreTableTotalsEnabled);

  const [shouldOpenGroup, setShouldOpenGroup] = useState({
    [ViewGroupType.swimlane]: false,
    [ViewGroupType.column]: false,
  });

  // DERIVED STATE
  const assetOptions = useMemo(() => assetsToOptions(assets), [assets]);
  const swimlaneOptions = useMemo(
    () => [
      { value: undefined, label: t(`${I18N_PREFIX}.None`) },
      ...assetOptions.filter((option) => option.value !== columnsId),
    ],
    [assetOptions, columnsId, t]
  );

  const currentSwimlaneOption = useMemo(
    () => swimlaneOptions.find((o) => o.value === swimlanesId),
    [swimlaneOptions, swimlanesId]
  );

  const columnOptions = useMemo(
    () => [
      { value: undefined, label: t(`${I18N_PREFIX}.None`) },
      ...assetOptions.filter((option) => option.value !== swimlanesId),
    ],
    [assetOptions, swimlanesId, t]
  );

  const currentColumnOption = useMemo(
    () => columnOptions.find((o) => o.value === swimlanesId),
    [columnOptions, swimlanesId]
  );

  // CALLBACKS
  const toggleGroup = useCallback(
    (group: ViewGroupType) => {
      const action = {
        [ViewGroupType.swimlane]: setClosedSwimlanes,
        [ViewGroupType.column]: setClosedColumns,
      };
      const keys = {
        [ViewGroupType.swimlane]: Object.keys(swimlanes),
        [ViewGroupType.column]: Object.keys(columns),
      };

      setShouldOpenGroup((current) => ({
        ...current,
        [group]: !current[group],
      }));

      return shouldOpenGroup[group]
        ? dispatch(action[group]([]))
        : dispatch(action[group](keys[group]));
    },
    [columns, swimlanes, shouldOpenGroup]
  );

  const handleViewChange = useCallback((view: OutputViewType) => {
    dispatch(setOutputViewType(view));
  }, []);

  // RENDER
  return (
    <Stack horizontal horizontalAlign="space-between" style={mainWrapperStyles}>
      <SectionTitle title={title} fullWidth={false}>
        <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
          className={cn(classNames.row, {
            [classNames.rowInPanel]: isPanelView,
          })}
        >
          <div className={classNames.fieldset}>
            <Label className={classNames.label}>
              {t(`${I18N_PREFIX}.Swimlanes`)}
            </Label>
            <SelectDropdownVirtual
              className={classNames.dropdown}
              placeholder={t(`${I18N_PREFIX}.None`)}
              value={currentSwimlaneOption}
              onChange={(option) => dispatch(setSwimlanesAssetId(option.value))}
              options={swimlaneOptions}
            />
            <IconButton
              disabled={!swimlanesId}
              className={classNames.laneIcon}
              onClick={() => toggleGroup(ViewGroupType.swimlane)}
              iconProps={{
                iconName: shouldOpenGroup[ViewGroupType.swimlane]
                  ? 'TripleColumn'
                  : 'DoubleColumn',
              }}
            />
          </div>
          {viewType === OutputViewType.kanban && (
            <div className={classNames.fieldset}>
              <Label className={classNames.label}>
                {t(`${I18N_PREFIX}.Columns`)}
              </Label>
              <SelectDropdownVirtual
                className={classNames.dropdown}
                placeholder={t(`${I18N_PREFIX}.None`)}
                value={currentColumnOption}
                onChange={(option) => dispatch(setColumnsAssetId(option.value))}
                options={columnOptions}
              />
              <IconButton
                disabled={!columnsId}
                onClick={() => toggleGroup(ViewGroupType.column)}
                iconProps={{
                  iconName: shouldOpenGroup[ViewGroupType.column]
                    ? 'TripleColumn'
                    : 'DoubleColumn',
                }}
              />
            </div>
          )}
          {viewType === OutputViewType.table && (
            <div className={classNames.fieldset}>
              <Label className={classNames.label}>
                {t(`${I18N_PREFIX}.Totals`)}
              </Label>
              <Toggle
                checked={areTableTotalsEnabled}
                onChange={() => dispatch(toggleTableTotals())}
              />
            </div>
          )}
        </Stack>
        <ViewControlButton
          options={viewControlOptions}
          activeItem={viewType}
          onChangeItem={(key) => handleViewChange(key as OutputViewType)}
        />
      </SectionTitle>
    </Stack>
  );
};

export default OutputHeader;
