import i18n from "../../../../taskpane/config/i18n";
import { enforceSchema } from "../../../types/utils";
import { Action, dispatchToDialogFactory, PostActionMessage } from "../actions";
import { OFFICE_DIALOG_ERROR_CODES } from "../constants";

// DIALOG EVENT HANDLERS

type PromiseHandler<Result = unknown> = (value: Result) => void;

export interface PromiseHandlers<Result = unknown, Error = unknown> {
  resolve: PromiseHandler<Result>;
  reject: PromiseHandler<Error>;
}

export type Dialog = Office.Dialog | Window;

interface DialogEventHandlerFactoryArgs<Result = unknown, Error = unknown>
  extends PromiseHandlers<Result, Error>
{
  dialog: Office.Dialog | Window;
  dispatchToDialog: PostActionMessage;
}

type DialogEventHandlerFactory<DialogEventHandler> = (args: DialogEventHandlerFactoryArgs) => DialogEventHandler;

interface InjectBaseEventHandlerBehaviourArgs<DialogEventHandler> extends PromiseHandlers {
  dialog: DialogEventHandlerFactoryArgs['dialog'];
  eventHandlerFactory: DialogEventHandlerFactory<DialogEventHandler>;
}

export const injectBaseEventHandlerBehviour = <DialogEventHandler>({
  dialog,
  eventHandlerFactory,
  ...args
}: InjectBaseEventHandlerBehaviourArgs<DialogEventHandler>): DialogEventHandler => eventHandlerFactory({
  dialog,
  dispatchToDialog: dispatchToDialogFactory(dialog),
  ...args
});

export type DialogEventHandlerCreator<DialogEventHandler> = (dialog: Dialog) => DialogEventHandler;

// DIALOG EVENT HANDLERS – ERRORS

export interface DialogErrorEvent {
  error: any;
}

export const createErrorEvent = enforceSchema<DialogErrorEvent>();

export type DialogErrorHandler = (args: DialogErrorEvent) => void;
export type DialogErrorHandlerFactory = DialogEventHandlerFactory<DialogErrorHandler>;
export type DialogErrorHandlerCreator = DialogEventHandlerCreator<DialogErrorHandler>;

export const defaultDialogErrorHandlerFactory: DialogEventHandlerFactory<DialogErrorHandler> = ({
  reject,
}) =>
  ({ error: errorCode }) => {
    switch (errorCode) {
      case OFFICE_DIALOG_ERROR_CODES.DialogClosed: reject(new Error(i18n.t('dialog:error:dialogClosed'))); break;
      case OFFICE_DIALOG_ERROR_CODES.DialogExists: reject(new Error(i18n.t('dialog:error:dialogAlreadyOpen'))); break;
      default: reject(new Error(i18n.t('dialog:error:unknown'))); break;
    }
  }

// DIALOG EVENT HANDLERS – MESSAGES

export type DialogMessageHandler<A extends Action = Action> = (args: { action: A }) => void;
export type DialogMessageHandlerFactory<A extends Action = Action>
  = DialogEventHandlerFactory<DialogMessageHandler<A>>;
export type DialogMessageHandlerCreator = DialogEventHandlerCreator<DialogMessageHandler>;
