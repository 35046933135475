import React from 'react';
import { Icon } from '@fluentui/react';

import { useTranslation } from 'react-i18next';
import { TutorialTile as ITutorialTile } from '../model';
import { tutorialTileStyles, tutorialTileItemStyles, tutorialTileImageStyles } from '../styles';

export interface Props extends ITutorialTile {
  onStartClick: () => void;
  unsatisfiedStartRequirement?: string;
}

export const TutorialTile = ({
  image,
  items,
  onStartClick,
  showMeUrl,
  unsatisfiedStartRequirement,
  titleI18nKey,
}: Props): JSX.Element => {
  // HOOKS
  const { t } = useTranslation();

  // PARTS
  const listItems = React.useMemo(
    () => items.map((item, idx) => (
      // NOTE: when generating the list based on static config the index is enough as a key (it won't change)
      <li className={tutorialTileItemStyles.root} key={item.key ?? idx}>
        <Icon className={tutorialTileItemStyles.icon} iconName={item.icon} />
        <h4 className={tutorialTileItemStyles.title}>{t(item.titleI18nKey)}</h4>
        <p className={tutorialTileItemStyles.description}>{t(item.descriptionI18nKey)}</p>
      </li>
    )),
    [items, t],
  );

  const hero = typeof image === 'string'
    ? <div className={tutorialTileStyles.image} style={tutorialTileImageStyles(image)} />
    : image;

  // RENDER
  return (
    <section className={tutorialTileStyles.root}>
      <div className={tutorialTileStyles.content}>
        <h3 className={tutorialTileStyles.title}>{t(titleI18nKey)}</h3>
        {/* <img className={tutorialTileStyles.image} src={image} /> */}
        {/* NOTE: using a div because `img` would not be resized properly by flexbox */}
        {hero}
        <ul className={tutorialTileStyles.list}>
          {listItems}
        </ul>
      </div>
      <footer className={tutorialTileStyles.footer}>
        {showMeUrl && <a href={showMeUrl} target="_blank" className={tutorialTileStyles.link}>{t('tutorialTiles:all:cta:showMeHow')}</a>}
        <button
          className={tutorialTileStyles.button}
          disabled={Boolean(unsatisfiedStartRequirement)}
          onClick={onStartClick}
          data-testid="tutorial-tile-start-btn"
        >
          {t(unsatisfiedStartRequirement ?? 'tutorialTiles:all:cta:start')}
        </button>
      </footer>
    </section>
  );
};
