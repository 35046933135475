import { TransProps } from 'react-i18next';
import i18n from '../../config/i18n';

export const generateI18nConfig = (secondsElapsed: number | null): TransProps<string> => {
  if (!secondsElapsed) {
    return {
      i18nKey: 'wizard:processingInfoNoTime',
    };
  }

  if (secondsElapsed < 60) {
    return {
      i18nKey: 'wizard:processingInfoSecondsOnly',
      values: {
        secondsNumber: secondsElapsed,
        seconds: i18n.t('wizard:Second', { count: secondsElapsed }),
      },
    };
  }

  const minutes = Math.floor(secondsElapsed / 60);
  const seconds = secondsElapsed - (minutes * 60);

  return {
    i18nKey: 'wizard:processingInfoMinutesAndSeconds',
    values: {
      minutesNumber: minutes,
      minutes: i18n.t('wizard:Minute', { count: minutes }),
      secondsNumber: seconds,
      seconds: i18n.t('wizard:Second', { count: seconds }),
    },
  };
};
