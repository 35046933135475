import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Data';

export const TUTORIAL_TILE_KEY = 'builder-catalog-data';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:builder:catalog:data:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:builder:catalog:data:items:selectYourData:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:builder:catalog:data:items:selectYourData:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010410681-Builder-Data-Stream-what-you-need',
};
