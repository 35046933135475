import { Middleware } from 'redux';

import { persist as persistDataSets } from './DataSets';
import { handleUiBlockWhileCoauthoring } from './blockingUi';

const officePersistor:Middleware = (store) => (next) => (action) => {
  const result = next(action);

  handleUiBlockWhileCoauthoring(action);
  persistDataSets(action, store);
  return result;
};

export default officePersistor;
