import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../../shared/tutorial-tiles/components/animations/Data';
import { ACTION_PREFIX as DESIGNER_INGESTION_ACTION_PREFIX } from "../../constants";

export const ACTION_PREFIX = `${DESIGNER_INGESTION_ACTION_PREFIX}/Data` as const;

export const TUTORIAL_TILE_KEY = 'designer-ingest-data';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:ingest:data:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:data:items:selectYourData:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:designer:ingest:data:items:selectYourData:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:data:items:changeTypes:description',
      icon: 'TextField',
      titleI18nKey: 'tutorialTiles:designer:ingest:data:items:changeTypes:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:data:items:aggregateYourData:description',
      icon: 'SynergiesSum',
      titleI18nKey: 'tutorialTiles:designer:ingest:data:items:aggregateYourData:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010204082-Data-Take-what-you-need',
};

export const INFINITE_SCROLL_TARGET_ID = `${TUTORIAL_TILE_KEY}-scrollable-target`;
