import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { DatePicker } from '@fluentui/react';
import { TextField } from '../../../../../../components/ui';
import Error from '../../Error';
import { ComparisonTypes } from '../../../../../../types/IStreamFilter';
import ValueInput from '../../../ValueInput';
import { IColumn, IColumnDataType } from '../../../../../../types/IColumn';
import { TYPE_IDS } from '../../../../../../constants/apiV4TypeIds';

interface Props {
  comparison: ComparisonTypes;
  column: IColumn;
  compareValue: any;
}

const ComparisonValue: React.FC<Props> = ({
  comparison,
  column,
  compareValue,
}) => {
  const { t } = useTranslation('filters');

  const { control, errors, setValue } = useFormContext();
  const shouldRenderValuePicker: boolean =
    column?.streamElementTypeId === TYPE_IDS.StreamField ||
    column?.streamElementTypeId === TYPE_IDS.StreamGroupAsset;

  useEffect(() => {
    if ([IColumnDataType.DateTime].includes(column?.dataType)) {
      if (!compareValue || !(compareValue instanceof Date)) {
        setValue('compareValue', new Date());
      }
    }
  }, [column, comparison]);

  if (
    [
      ComparisonTypes.IsNull,
      ComparisonTypes.IsNotNull,
      ComparisonTypes.Between,
      ComparisonTypes.NotBetween,
      ComparisonTypes.In,
      ComparisonTypes.NotIn,
      ComparisonTypes.IsBlank,
      ComparisonTypes.IsNotBlank,
    ].includes(comparison) ||
    !comparison
  ) {
    return null;
  }

  if ([IColumnDataType.DateTime].includes(column.dataType)) {
    return (
      <>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label={t('Value')}
              isMonthPickerVisible={false}
              onSelectDate={(date) => onChange(date)}
              value={value}
            />
          )}
          name="compareValue"
          control={control}
          rules={{ required: true }}
          defaultValue={new Date()}
        />
        <ErrorMessage
          render={({ message }) => <Error>{message}</Error>}
          errors={errors}
          name="compareValue"
          message={t('required')}
        />
      </>
    );
  }

  if (
    [IColumnDataType.NumericInteger, IColumnDataType.NumericDecimal].includes(
      column.dataType
    )
  ) {
    return (
      <>
        <Controller
          as={NumberFormat}
          label={t('Value')}
          name="compareValue"
          control={control}
          rules={{ required: true }}
          customInput={TextField}
          defaultValue=""
        />
        <ErrorMessage
          render={({ message }) => <Error>{message}</Error>}
          errors={errors}
          name="compareValue"
          message={t('required')}
        />
      </>
    );
  }

  if ([ComparisonTypes.Equal, ComparisonTypes.NotEqual].includes(comparison)) {
    return (
      <>
        <Controller
          render={({ onChange, value }) =>
            shouldRenderValuePicker ? (
              <ValueInput
                onChange={(values) => {
                  onChange(values);
                }}
                isMulti={false}
                column={column}
                defaultValue={[value]}
                label={t('Value')}
              />
            ) : (
              <TextField
                label={t('Value')}
                onChange={(_, val) => onChange(val)}
                value={value}
                data-testid="filter-equal-to-text-field"
              />
            )
          }
          label={t('Value')}
          name="compareValue"
          control={control}
          rules={{ required: true }}
        />
        <ErrorMessage
          render={({ message }) => <Error>{message}</Error>}
          errors={errors}
          name="compareValue"
          message={t('required')}
        />
      </>
    );
  }

  return (
    <>
      <Controller
        as={TextField}
        label={t('Value')}
        name="compareValue"
        control={control}
        rules={{ required: true }}
      />
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="compareValue"
        message={t('required')}
      />
    </>
  );
};

export default ComparisonValue;
