import axios, { AxiosError, AxiosResponse } from 'axios';

import { ServerError } from './model';

import { IAuthConfiguration } from '../types/IAuthConfiguration';
import { configurationPath } from '../constants/apiPaths';

const fetchConfiguration = async (instanceUrl: string): Promise<IAuthConfiguration> => {
  try {
    const { data }:AxiosResponse<IAuthConfiguration> = await axios.get(`${instanceUrl}${configurationPath}`);
    return data;
  } catch (err) {
    const axiosError = err as AxiosError<ServerError>;

    throw new Error(axiosError.response.data.title);
  }
};
export default fetchConfiguration;
