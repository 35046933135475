import * as effects from 'redux-saga/effects';
import selectors from '../selectors';
import * as StreamActions from '../actions';
import { fetchStreamAvailableColumns } from './fetchStreamAvailableColumns';
import { normalizeById } from '../../../utils/normalizeEntities';

export function* setCurrentStreamId(currentStreamId: string | null) {
  yield effects.put({ type: 'SET_CURRENT_STREAM_ID', currentStreamId });

  if (!currentStreamId) return;

  const currentStream = selectors.getCurrentStream(yield effects.select());

  if (!currentStream) return;

  // make sure when changing stream user won't see "old stream" columns
  // while waiting for response
  yield effects.put({ type: 'SET_AVAILABLE_COLUMNS', availableColumns: [] });

  const availableColumns = yield effects.call(
    fetchStreamAvailableColumns,
    currentStream.id
  );

  const currentDataset = selectors.getCurrentDataset(yield effects.select());

  if (currentDataset) {
    // because after stream processing column params might have been changed (like format type)
    // we need to update all existing column associations to make sure
    // that older tables will be marked as queued
    const streamsColumnsById = normalizeById(availableColumns);
    const updatedColumns = currentDataset.columns.map((column) => ({
      ...streamsColumnsById[column.id],
      aggregation: column.aggregation,
      sortConfig: {
        ...column.sortConfig,
      },
      isHidden: column?.isHidden,
    }));
    yield effects.put({
      type: 'EDIT_DATASET',
      id: currentDataset.id,
      properties: {
        columns: updatedColumns,
      },
    });
  }

  yield effects.put({ type: 'SET_AVAILABLE_COLUMNS', availableColumns });
  yield effects.put({ type: 'SET_CURRENT_SUBCOMMAND', command: null });
}

function* setCurrentStreamIdWrapper({
  payload: streamId,
}: ReturnType<typeof StreamActions.setCurrentStreamIdIntent>) {
  yield effects.call(setCurrentStreamId, streamId);
}

export default function* rootSaga() {
  yield effects.takeLatest(
    StreamActions.setCurrentStreamIdIntent.type,
    setCurrentStreamIdWrapper
  );
}
