import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';

import i18n from '../config/i18n';
import { IFoldableListGroup } from '../components/ui/FoldableList/types/IFoldableListGroup';
import { DefinedSortTypes } from '../modules/App/types';
import { translateApiName } from '../config/i18n/utils';
import { TYPE_IDS } from '../constants/apiV4TypeIds';

interface HasIdAndName {
  name: string;
  id: string;
}

export function itemsToAZLettersFoldableList<T extends HasIdAndName>(
  input: T[],
  activeItemId?: string,
  openGroupId?: string
): IFoldableListGroup[] {
  const groupTitlesWithDuplicates = input
    ?.map((item) => item?.name?.slice(0, 1)?.toUpperCase())
    ?.sort((a, b) => a?.localeCompare(b));

  const groupTitlesNoDuplicates = uniq(groupTitlesWithDuplicates);

  const groupedEntries = groupTitlesNoDuplicates.reduce<IFoldableListGroup[]>(
    (accumulator, current) => {
      const obj: IFoldableListGroup = {
        key: current,
        name: current,
        isOpen: current === openGroupId,
        items: input
          .filter(
            (item) =>
              item.name.startsWith(current) ||
              item.name.startsWith(current.toLowerCase())
          )
          .map((item) => ({
            key: item.id,
            name: item.name,
            isActive: item.id === activeItemId,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      };

      const shouldBeOpen = obj.items.some((item) => item.key === activeItemId);

      if (shouldBeOpen) {
        obj.isOpen = true;
      }

      accumulator.push(obj);

      return accumulator;
    },
    []
  );

  return groupedEntries;
}

const tryToTranslateGroupName = (sortKey: string, rawName: string) => {
  if (sortKey === DefinedSortTypes.Tags) {
    return rawName;
  }

  if (sortKey === DefinedSortTypes.Type) {
    // NOTE: we can do this translation ONLY because for now this sort key is used only with data sources
    // due to the default return of this function (which is a desirable behaviour, so it should probably stay)
    // it's impossible to perform the translation after the `createItemsGroupedByProp` fires
    return translateApiName(TYPE_IDS.DataSource, rawName);
  }

  if (sortKey === DefinedSortTypes.DataType) {
    return translateApiName(TYPE_IDS.SourceEntityField, rawName);
  }

  if (i18n.exists(`streams:${rawName}`)) {
    return i18n.t(`streams:${rawName}`);
  }

  if (i18n.exists(`sortControls:${rawName}`)) {
    return i18n.t(`sortControls:${rawName}`);
  }

  if (
    sortKey === DefinedSortTypes.Entity ||
    sortKey === DefinedSortTypes.Tags
  ) {
    return rawName;
  }

  if (!rawName) {
    return i18n.t('sortControls:notDefined');
  }

  return startCase(rawName);
};

type ItemPropWithTranslation = { name: string; nameI18n?: string };

export function createItemsGroupedByProp(sortKey: string) {
  return function itemsGroupedByProp<T extends HasIdAndName>(
    input: T[],
    activeItemId?: string,
    openGroupId?: string
  ): IFoldableListGroup[] {
    const groupTitlesWithDuplicates = input
      .reduce<ItemPropWithTranslation[]>((accumulator, currentItem) => {
        const currentItemProperties: ItemPropWithTranslation[] = Array.isArray(
          currentItem[sortKey]
        )
          ? currentItem[sortKey].map((prop) => ({ name: prop }))
          : [
              {
                name: currentItem[sortKey],
                nameI18n: currentItem[`i18n${upperFirst(sortKey)}`],
              },
            ];

        const newAccumulator = [...accumulator, ...currentItemProperties];
        return newAccumulator;
      }, [])
      ?.sort(({ name: a }, { name: b }) => a?.localeCompare(b));

    const groupTitlesNoDuplicates = uniqBy(
      groupTitlesWithDuplicates,
      (prop) => prop.name
    );

    const groupedEntries = groupTitlesNoDuplicates.reduce<IFoldableListGroup[]>(
      (accumulator, { name: groupTitle, nameI18n: groupTitleI18n }) => {
        const obj: IFoldableListGroup = {
          key: groupTitle,
          name: groupTitleI18n ?? tryToTranslateGroupName(sortKey, groupTitle),
          isOpen: groupTitle === openGroupId,
          items: input
            .filter((item) =>
              Array.isArray(item[sortKey])
                ? item[sortKey].includes(groupTitle)
                : item[sortKey] === groupTitle
            )
            .map((item) => ({
              key: item.id,
              name: item.name,
              isActive: item.id === activeItemId,
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        };

        const shouldBeOpen = obj.items.some(
          (item) => item.key === activeItemId
        );

        if (shouldBeOpen) {
          obj.isOpen = true;
        }

        accumulator.push(obj);

        return accumulator;
      },
      []
    );

    if (sortKey === DefinedSortTypes.Tags) {
      const untaggedKey = i18n.t('sortControls:untagged');

      const untagged: IFoldableListGroup = {
        key: untaggedKey,
        name: untaggedKey,
        isOpen: openGroupId === untaggedKey,
        items: input
          .filter((item) => (item as any)?.tags?.length === 0)
          .map((item) => ({
            key: item.id,
            name: item.name,
            isActive: item.id === activeItemId,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      };

      if (untagged.items.length > 0) {
        groupedEntries.push(untagged);
      }
    }
    const sortedGroupedEntries = groupedEntries.sort((groupA, groupB) =>
      groupA.name.localeCompare(groupB.name)
    );

    return sortedGroupedEntries;
  };
}
