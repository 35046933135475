import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'office-ui-fabric-react';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import {
  getSingleSelectedSourceId,
  getAreFieldsUpdating,
  createGetSourceData,
  getAllSources,
} from '../../selectors';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import {
  INFINITE_SCROLL_TARGET_ID,
  tutorialTileConfig,
  TUTORIAL_TILE_KEY,
} from './constants';
import { DataSourceEntity } from '../../components/DataSourceEntity/DataSourceEntity';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import { getSelectedDesign } from '../../../ContentLibrary/selectors';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { prepareEntityFieldPropertiesSchema, prepareEntitySchema } from './utils';
import { classNames } from './styles';
import { dataModeLoaded, infiniteScrollEnd, searchPerformed } from './actions';
import {
  getEntitiesNextPageUrl,
  getEntityListSorted,
  getFieldsNextPageUrl,
  getIsLoading,
} from './selectors';
import { getSearchQuery } from '../../../../Search/selectors';
import { usePrevious } from '../../../../../utils/usePrevious';

const InfiniteLoader = <Spinner />;

const Data = () => {
  // DEPS
  const getSourceData = React.useMemo(createGetSourceData, []);

  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const selectedDesign = useSelector(getSelectedDesign);
  const sources = useSelector(getAllSources);
  const selectedDataSourceId = useSelector(getSingleSelectedSourceId);
  const sourceData = useSelector((state) =>
    getSourceData(state, selectedDataSourceId)
  );

  const entities = useSelector(getEntityListSorted);
  const entitiesNextPageUrl = useSelector(getEntitiesNextPageUrl);
  const fieldsNextPageUrl = useSelector(getFieldsNextPageUrl);
  const isDataModeLoading = useSelector(getIsLoading);
  const searchQuery = useSelector(getSearchQuery);
  const areFieldsUpdating = useSelector(getAreFieldsUpdating);

  const {
    isLoading: areEntityFieldPropertiesLoading,
    properties: entityFieldProperties,
  } = useGetParsedTypeProperties({
    designId: selectedDesign.id,
    typeId: TYPE_IDS.SourceEntityField,
  });

  const { properties: entityProperties } = useGetParsedTypeProperties({
    designId: selectedDesign.id,
    typeId: TYPE_IDS.SourceEntity,
  });


  // DERIVED STATE
  const entityFieldPropertiesSchema = React.useMemo(
    () => prepareEntityFieldPropertiesSchema({ properties: entityFieldProperties }),
    [entityFieldProperties]
  );

  const entityPropertiesSchema = React.useMemo(
    () => prepareEntitySchema({ properties: entityProperties }),
    [entityProperties]
  );


  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:ingest:data:cta:noSourcesAvailable':
        sources.length === 0,
      'tutorialTiles:designer:ingest:data:cta:noSourcesSelected':
        !selectedDataSourceId,
    }),
    [selectedDataSourceId, sources.length]
  );

  const previousSearchQuery = usePrevious(searchQuery);

  const isLoading =
    areEntityFieldPropertiesLoading ||
    isDataModeLoading ||
    // NOTE: this is a workaround for the fact the `searchQuery` is set in a different component and the only thing
    // we can do to trigger the API call here is to react to the state change with a `useEffect` – that however
    // causes the API call to be queued after the render finishes, and in the same render the new search query
    // is handled by the children, like groups and list items handling open state and highlights which unnecessarily
    // hinders performance and noticably delays the API call when thousands of items are present in the list
    previousSearchQuery !== searchQuery;

  // CALLBACK
  const loadMore = React.useCallback(() => {
    // only the entities url is required, when fetching without a query the fields are not loaded
    if (!entitiesNextPageUrl) return;
    dispatch(
      infiniteScrollEnd({
        entitiesNextPageUrl,
        fieldsNextPageUrl,
      })
    );
  }, [dispatch, entitiesNextPageUrl, fieldsNextPageUrl]);

  // EFFECTS
  const onMount = React.useCallback(() => {
    if (!selectedDataSourceId || searchQuery) return;
    dispatch(dataModeLoaded({ dataSourceId: selectedDataSourceId }));
  }, [dispatch, searchQuery, selectedDataSourceId]);
  React.useEffect(onMount, [onMount]);
  // NOTE: unfortunatelly because the search-box is handled elswhere and the exact same logic is used across multiple
  // screens there is currently no other way to react to the changes with a callback other than through a `useEffect`
  // although the `searchQuery` change is debounced so we should be semi-fine
  const onSearch = React.useCallback(() => {
    if (!searchQuery) {
      onMount();
      return;
    }

    dispatch(
      searchPerformed({
        dataSourceId: selectedDataSourceId,
        searchQuery,
      })
    );
  }, [onMount, searchQuery, selectedDataSourceId]);
  React.useEffect(onSearch, [onSearch]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (isLoading && !areFieldsUpdating) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  return (
    <section id={INFINITE_SCROLL_TARGET_ID} className={classNames.sectionWrap}>
      <InfiniteScroll
        dataLength={entities.length}
        hasMore={Boolean(entitiesNextPageUrl)}
        loader={InfiniteLoader}
        next={loadMore}
        scrollableTarget={INFINITE_SCROLL_TARGET_ID}
        scrollThreshold="200px"
      >
        {entities.map((entity) => (
          <DataSourceEntity
            {...{
              searchQuery,
              sourceData,
            }}
            // type assersion required because the old interface became outdated and it feels risky to modify it
            entity={entity as any}
            entityFieldInfoSchema={entityFieldPropertiesSchema}
            entityInfoSchema={entityPropertiesSchema}
            key={entity.id}
          />
        ))}
      </InfiniteScroll>
    </section>
  );
};

export default Data;
