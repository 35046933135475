import { mergeStyleSets } from '@fluentui/react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../theme';

export const classNames = mergeStyleSets({
  btnWrap: {
    background: 'none',
    padding: 0,
    height: '40px',
    width: '40px',
    border: `1px solid ${baseColors.inputBorder}`,
    borderRadius: BORDER_RADIUS_IN_PX,
    cursor: 'pointer',
    position: 'relative',
    selectors: {
      '&:hover': {
        backgroundColor: baseColors.hover,
      },
    },
  },
  btnSelected: {
    border: `1px solid ${baseColors.midOrange}`,
    backgroundColor: baseColors.midOrange,
    cursor: 'auto',
  },
  btnSelectedHoverable: {
    cursor: 'pointer',
    selectors: {
      '&:hover': {
        backgroundColor: baseColors.selectedWithHover,
      },
    },
  },
  icon: {
    color: baseColors.iconColor,
    fontSize: '26px',
    height: '26px',
    width: '26px',
    lineHeight: '1',
  },
});
