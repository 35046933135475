import { TutorialTile } from '../../../../shared/tutorial-tiles/model';

import image from '../../../../shared/tutorial-tiles/assets/calcs.png';
import { SchemaEntry } from '../../../../components/DataSchemaMapper/model';
import { ancestorSourceNameKey } from '../../../../constants/infoBoxes';
import { enforceSchema } from '../../../../../shared/types/utils';
import { DataType } from '../../../../api/model/schemas/DataType';

export const dataTypeIcons = {
  [DataType.Boolean]: 'EykoBoolean',
  [DataType.DateTime]: 'DateTime',
  [DataType.NumericDecimal]: 'Decimals',
  [DataType.Geography]: 'POI',
  [DataType.Json]: 'EykoJsonType',
  [DataType.Text]: 'TextField',
  [DataType.NumericInteger]: 'NumberField',
};

export const TUTORIAL_TILE_KEY = 'builder-catalog-stream';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:builder:catalog:stream:title',
  image,
  items: [
    {
      descriptionI18nKey:
        'tutorialTiles:builder:catalog:stream:items:reviewYourStream:description',
      icon: 'Filter',
      titleI18nKey:
        'tutorialTiles:builder:catalog:stream:items:reviewYourStream:title',
    },
    {
      descriptionI18nKey:
        'tutorialTiles:builder:catalog:stream:items:organizeYourStream:description',
      icon: 'CheckListText',
      titleI18nKey:
        'tutorialTiles:builder:catalog:stream:items:organizeYourStream:title',
    },
  ],
  showMeUrl:
    'https://eyko.zendesk.com/hc/en-us/articles/1500010411181-Builder-Stream-Reduce-the-clutter',
};

export const sourceSchemaEntry = enforceSchema<Partial<SchemaEntry>>()({
  key: ancestorSourceNameKey,
  nameI18nKey: 'entityTypes:Source',
} as const);
