import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../modules/Designer/types";
import { DataSourceEntityField } from "../../../model/schemas/DataSourceEntityField";
import { TypeIds } from "../../../model/schemas";
import { isResponseTypeIdMatching } from "../utils";
import { i18nDataTypeKey, i18nSourceDataTypeKey, i18nFormatTypeKey } from "../constants";
import { translateApiName } from "../../../../config/i18n/utils";
import { TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP } from "../../../constants";

export const isDataSourceEntityFieldResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<DataSourceEntityField> =>
  isResponseTypeIdMatching(response, TypeIds.DataSourceEntityField)
    && !Array.isArray((response.data as any)?.data)

export const isDataSourceEntityFieldsResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<ApiV4ResponseWrapper<DataSourceEntityField[]>> =>
  isResponseTypeIdMatching(response, TypeIds.DataSourceEntityField)
    && Array.isArray((response.data as any)?.data)

export const parseDataSourceEntityField = (dataSourceEntityField: DataSourceEntityField) => {
  return {
    ...dataSourceEntityField,
    [i18nDataTypeKey]: translateApiName(dataSourceEntityField.$typeId, dataSourceEntityField.dataType),
    [i18nSourceDataTypeKey]: translateApiName(dataSourceEntityField.$typeId, dataSourceEntityField.sourceDataType),
    [i18nFormatTypeKey]: translateApiName(
      TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[dataSourceEntityField.dataType] ?? dataSourceEntityField.$typeId,
      String(dataSourceEntityField.formatType),
    ),
  };
}
