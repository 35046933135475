import React from 'react';
import { FeatureStatus } from '../../../api/model/schemas/FeatureStatus';
import { translateApiName } from '../../../config/i18n/utils';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { baseColors } from '../../../theme';

const SUPPORTED_TYPES: string[] = [TYPE_IDS.ConnectorConfigurationType];

const FeatureStatusBgColor:Record<FeatureStatus, string> = {
  [FeatureStatus.ComingSoon]: baseColors.midOrange,
  [FeatureStatus.Stable]: 'transparent',
  
  // those colors do not exist in base colors, nor elsewhere in the app
  [FeatureStatus.Preview]: '#00ACE8',
  [FeatureStatus.New]: '#11CECE',
} as const
interface Props {
  status?: FeatureStatus;
  typeId: string;
}

export const OptionStatus = ({ status, typeId }: Props) => {
  if (
    status === FeatureStatus.Stable
    || !SUPPORTED_TYPES.includes(typeId)
  ) { return null; }

  return (
    <span
      style={{
        backgroundColor: FeatureStatusBgColor[status],
        padding: '2px 3px',
        borderRadius: '10px',
        textTransform: 'uppercase',
        marginLeft: '4px',
        fontSize: '8px',
        position: 'relative',
        bottom: '2px',
        color: baseColors.textColor,
      }}
    >
      {translateApiName(typeId, status)}
    </span>
  );
};
