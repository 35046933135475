import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Sources';

export const TUTORIAL_TILE_KEY = 'builder-catalog-sources';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:builder:catalog:sources:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:builder:catalog:sources:items:selectYourSources:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:builder:catalog:sources:items:selectYourSources:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010378982-Builder-Sources-Access-Your-Data',
};
