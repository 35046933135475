import React from 'react';
import { API_TYPE_IDS } from '../../../../../../../e2e/config/API_TYPE_IDS';
import { MachineLearningAssetValuesRequest } from '../../../../../api/model/schemas/MachineLearningAssetValuesRequest';
import { createNestedEntity } from '../../../api';

type Params = {
  machineLearningId: string;
  searchQuery: string | undefined;
};

export const useMLAssetAssetValueRequest = ({
  machineLearningId,
  searchQuery,
}: Params) => {
  const [isLoading, setLoading] = React.useState(false);
  const [continuationToken, setToken] = React.useState(null);
  const [result, setResult] =
    React.useState<MachineLearningAssetValuesRequest['result']>();

  const fetchFirstPage = async () => {
    setLoading(true);

    try {
      const { data } =
        await createNestedEntity<MachineLearningAssetValuesRequest>({
          parentEntityId: machineLearningId,
          typeId: API_TYPE_IDS.MachineLearningAssetValuesRequest,
        })({
          filterValue: searchQuery,
        });

      setResult(data.result);
      setToken(data?.continuationToken);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchNextPage = React.useCallback(async () => {
    try {
      const { data } =
        await createNestedEntity<MachineLearningAssetValuesRequest>({
          parentEntityId: machineLearningId,
          typeId: API_TYPE_IDS.MachineLearningAssetValuesRequest,
        })({
          filterValue: searchQuery,
          continuationToken,
        });
      setResult((currentResult) => ({
        ...currentResult,
        rows: [...currentResult.rows, ...data.result.rows],
      }));
      setToken(data?.continuationToken);
    } catch (error) {
      console.error(error);
    }
  }, [continuationToken]);

  React.useEffect(() => {
    if (machineLearningId) {
      fetchFirstPage();
    }
  }, [machineLearningId, searchQuery]);

  return { result, isLoading, hasMore: !!continuationToken, fetchNextPage };
};
