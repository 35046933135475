export const i18nCalculationTypeIdKey = 'i18nCalculationTypeId';
export const i18nCategoryKey = 'i18nCategory';
export const i18nDataTypeKey = 'i18nDataType';
export const i18nDefaultAggregationKey = 'i18nDefaultAggregation';
export const i18nFormatTypeKey = 'i18nFormatType';
export const i18nNameKey = 'i18nName';
export const i18nProcessedDataTypeKey = 'i18nProcessedDataType';
export const i18nProcessedFormatTypeKey = 'i18nProcessedFormatType';
export const i18nRoleKey = 'i18nRole';
export const i18nSourceDataTypeKey = 'i18nSourceDataType';
export const i18nTypeKey = 'i18nType';
export const i18nValidValuesKey = 'i18nValidValues';
