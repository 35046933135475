// NOTE: we're using immer, so we can reassign
/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { OutputViewType, StreamerOutputState } from './types';

import * as actions from './actions';
import {
  initialDataFetchFailed,
  playIntent,
  selectStreamId,
} from '../../actions';

const initialState: StreamerOutputState = {
  isFetching: false,
  rowCount: null,
  rows: null,
  totalRowCount: null,
  columnsAssetId: undefined,
  swimlanesAssetId: undefined,
  closedSwimlanes: [],
  closedColumns: [],
  view: OutputViewType.table,
  selectedCardKeys: [],
  isSharePreviewOpen: false,
  forceOpenAllCards: false,
  enableTableTotals: true,
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(
      actions.dataReceived,
      (state, { payload: { rowCount, totalRowCount, rows } }) => ({
        ...state,
        isFetching: false,
        rowCount,
        totalRowCount,
        rows,
      })
    )
    .addCase(
      actions.continuationReceived,
      (state, { payload: { rowCount, rows, totalRowCount } }) => ({
        ...state,
        isFetching: false,
        rows: [...state.rows, ...rows],
        totalRowCount,
        rowCount,
      })
    )
    .addCase(initialDataFetchFailed, (state) => ({
      ...state,
      isFetching: false,
    }))
    .addCase(playIntent, (state) => ({
      ...state,
      isFetching: true,
      rowCount: null,
      totalRowCount: null,
    }))
    .addCase(selectStreamId, () => ({
      ...initialState,
    }))
    .addCase(actions.setColumnsAssetId, (state, { payload }) => ({
      ...state,
      columnsAssetId: payload,
    }))
    .addCase(actions.setSwimlanesAssetId, (state, { payload }) => ({
      ...state,
      swimlanesAssetId: payload,
    }))
    .addCase(actions.setClosedSwimlanes, (state, { payload }) => ({
      ...state,
      closedSwimlanes: payload,
    }))
    .addCase(actions.setClosedColumns, (state, { payload }) => ({
      ...state,
      closedColumns: payload,
    }))
    .addCase(actions.setOutputViewType, (state, { payload }) => ({
      ...state,
      view: payload,
    }))
    .addCase(actions.toggleForceOpenAllCards, (state) => ({
      ...state,
      forceOpenAllCards: !state.forceOpenAllCards,
    }))

    .addCase(actions.toggleCardSelection, (state, { payload: cardKey }) => {
      if (state.selectedCardKeys.includes(cardKey)) {
        state.selectedCardKeys = state.selectedCardKeys.filter(
          (key) => key !== cardKey
        );
      } else {
        // NOTE: for now we want to support only a single-item selection, this should be changed when multi-select
        // should be turned back on
        // state.selectedCardKeys.push(cardKey);
        state.selectedCardKeys = [cardKey];
      }
    })
    .addCase(actions.toggleSharePreview, (state, { payload: isOpen }) => {
      state.isSharePreviewOpen = isOpen;
    })
    .addCase(actions.clearCardSelection, (state) => {
      state.isSharePreviewOpen = false;
      state.selectedCardKeys = initialState.selectedCardKeys;
    })
    .addCase(actions.fetchNextPage, (state) => ({
      ...state,
      isFetching: true,
    }))
    .addCase(actions.toggleTableTotals, (state) => ({
      ...state,
      enableTableTotals: !state.enableTableTotals,
    }))
);

export default reducer;
