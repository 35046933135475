import { createReducer } from '@reduxjs/toolkit';

import { IUser } from '../../types/IUser';

import * as actions from './actions';
import * as addError from './addError';
import { Error, UIEnhancedNotification } from './types';

export interface NotificationsState {
  errors: Error[];

  notifications: {
    isLoading: boolean;
    isLoadingMore: boolean;
    isDismissingAll: boolean;
    records: UIEnhancedNotification[];
    count: number;
  };

  unreadNotifications: {
    count?: number;
    isLoading: boolean;
  };

  usersData: {
    [id: string]: IUser;
  };
}

export const initialState: NotificationsState = {
  errors: [],

  notifications: {
    isLoading: false,
    isLoadingMore: false,
    isDismissingAll: false,
    records: [],
    count: 0,
  },

  unreadNotifications: {
    isLoading: false,
  },

  usersData: {},
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(addError.addError, (state, action) => ({
    ...state,
    errors: [action.payload, ...state.errors],
  }))
  .addCase(actions.removeErrorByDate, (state, { payload: { date } }) => ({
    ...state,
    errors: state.errors.filter((error) => error.date !== date),
  }))
  .addCase(actions.clearErrors, (state) => ({
    ...state,
    errors: [],
  }))
  .addCase(actions.dismissAllNotifications.pending, (state) => ({
    ...state,
    notifications: {
      ...state.notifications,
      isLoading: true,
    },
  }))
  .addCase(actions.dismissAllNotifications.rejected, (state) => ({
    ...state,
    notifications: {
      ...state.notifications,
      isLoading: false,
    },
  }))
  .addCase(actions.dismissAllNotifications.fulfilled, (state) => ({
    ...state,
    notifications: {
      ...state.notifications,
      isLoading: false,
      count: 0,
      records: [],
    },
    unreadNotifications: {
      ...state.unreadNotifications,
      count: 0,
    },
  }))
  .addCase(actions.removeNotificationFromRecords, (state, { payload: notificationId }) => ({
    ...state,
    notifications: {
      ...state.notifications,
      records: state.notifications.records.filter((notification) => notification.id !== notificationId),
      count: state.notifications.count - 1,
    },
    unreadNotifications: {
      ...state.unreadNotifications,
      count: state.unreadNotifications?.count - 1,
    },
  }))
  .addCase(actions.appendNotification, (state, {
    payload: {
      notification,
      updatedUserData,
    },
  }) => ({
    ...state,
    notifications: {
      ...state.notifications,
      count: state.notifications.count + 1,
      records: [notification, ...state.notifications.records],
    },
    unreadNotifications: {
      ...state.unreadNotifications,
      // this is temporary - because API does not provide filtering
      // we will be only displaying unread notification
      // In addition paging of the notifications is broken, so we are fetching all of them at one
      // https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6080
      // https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6087
      count: (state.unreadNotifications.count || 0) + 1,
    },
    usersData: updatedUserData ? { ...updatedUserData } : state.usersData,
  }))
  .addCase(actions.setNotifications, (state, {
    payload: {
      records, count, usersById,
    },
  }) => ({
    ...state,
    notifications: {
      ...state.notifications,
      isLoading: false,
      records,
      count,
    },
    unreadNotifications: {
      ...state.unreadNotifications,
      // this is temporary - because API does not provide filtering
      // we will be only displaying unread notification
      // In addition paging of the notifications is broken, so we are fetching all of them at one
      // https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6080
      // https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6087
      count,
    },
    usersData: {
      ...usersById,
    },
  }))
  .addCase(actions.fetchNotificationsIntent, (state) => ({
    ...state,
    notifications: {
      ...state.notifications,
      isLoading: true,
    },
  })));

export default reducer;
