import React from 'react';
import { useSelector } from 'react-redux';
import GroupCollapse from '../../../../components/ui/GroupCollapse/GroupCollapse';
import { IFoldableListGroup } from '../../../../components/ui/FoldableList/types/IFoldableListGroup';

import * as StreamsStore from '../../Streams';
import { areColumnsEqual } from '../../utils';
import useActions from '../../../../utils/useActions';
import * as excelActions from '../../../../actions/excel-actions';
import { InfoParams } from '../../../../hooks/use-information-dialog';

interface Props {
  group: IFoldableListGroup;
  onIconClick: (key: string) => void;
  info?: InfoParams;
  searchQuery?: string;
}

const DataModeGroupCollapse: React.FC<Props> = ({
  group,
  children,
  onIconClick,
  info,
  searchQuery,
}) => {
  const availableColumns = useSelector(
    StreamsStore.selectors.getAvailableColumns
  );
  const syncing = useSelector(StreamsStore.selectors.getIsSyncing);
  const streamColumnLoading = useSelector(
    StreamsStore.selectors.getStreamColumnLoading
  );
  const currentDataset = useSelector(StreamsStore.selectors.getCurrentDataset);
  const { removeAllColumnsBelogningToEntityFromDataSet } = useActions(
    StreamsStore.actionCreators
  );

  const columnIdsInGroup = group.items.map((item) => item.key);
  const allColumnsInEntity = availableColumns
    .filter((column) => columnIdsInGroup.includes(column.id))
    .sort((colA, colB) => colA.name.localeCompare(colB.name))
    .map((col) => ({
      ...col,
      // make sure when clicking on entity, default aggregation is respected
      aggregation: col.defaultAggregation,
    }));

  const columnsMappedInEntity = allColumnsInEntity.filter((available) =>
    currentDataset?.columns.some((column) => areColumnsEqual(column, available))
  );

  const isFullyMapped =
    columnsMappedInEntity.length === allColumnsInEntity.length;

  const handleTitleClick = async () => {
    if (isFullyMapped && currentDataset) {
      const columnsThatWillLeftAfterEnityRemovalFromDataset =
        currentDataset.columns.filter((column) =>
          columnIdsInGroup.includes(column.id)
        );

      if (columnsThatWillLeftAfterEnityRemovalFromDataset.length === 0) return;
      removeAllColumnsBelogningToEntityFromDataSet(
        group.name,
        currentDataset.id
      );
      await excelActions.updateDataset({ datasetId: currentDataset.id });

      return;
    }

    if (isFullyMapped || syncing || streamColumnLoading) {
      return;
    }

    if (currentDataset) {
      /**
       * Do not include columns that were already mapped
       */
      const { columns: oldColumns } = currentDataset;
      const newColumns = allColumnsInEntity.filter(
        (column) => !oldColumns.some((col) => areColumnsEqual(col, column))
      );
      excelActions.updateDataset({
        datasetId: currentDataset?.id,
        columns: newColumns,
      });
    } else {
      excelActions.updateDataset({
        datasetId: currentDataset?.id,
        columns: allColumnsInEntity,
      });
    }
  };

  return (
    <GroupCollapse
      groupId={group.key}
      groupName={group.name}
      onIconClick={onIconClick}
      isOpen={group.isOpen || searchQuery !== ''}
      onTextClick={handleTitleClick}
      isSelected={isFullyMapped}
      info={info}
      searchQuery={searchQuery}
      isRenderingOnQueryMismatch
      selections={{
        current: columnsMappedInEntity?.length,
        total: group.items.length,
      }}
    >
      {children}
    </GroupCollapse>
  );
};

export default DataModeGroupCollapse;
