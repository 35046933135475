import React from 'react';

import { useSelector } from 'react-redux';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import EntityField from '../../../../../components/EntityField';
import { IDesignSource } from '../../../types';
import { InfoParams } from '../../../../../hooks/use-information-dialog';
import { SchemaEntry } from '../../../../../components/DataSchemaMapper/model';
import DataSourceCalloutTitle from '../DataSourceCalloutTitle';
import { LinkFields as LinkFieldsSchema } from '../../../../../api/model/schemas/LinkFields';
import {
  getLinkedEntities,
  getLinkedEntityFields,
  getAllLinks,
} from '../../selectors';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import i18n from '../../../../../config/i18n';
import {
  translateApiName,
  i18nApiPrefix,
} from '../../../../../config/i18n/utils';
import { numberToHexColor } from '../../../../../components/ui/ColorPicker/utils';
import { Link } from '../../../../../api/model/schemas/Link';
import { EntityFieldProps } from '../../../../../components/EntityField/EntityField';

const i18nModePrefix = 'modules:designer:sections:ingest:modes:links';
const getModeI18nKey = (key: string) => `${i18nModePrefix}:${key}`;

const linkCustomSchemaKeys: string[] = [
  'fromField',
  'fromEntity',
  'toField',
  'toEntity',
];

const linkSchemaKeys: (keyof Link)[] = [
  'type',
  'analyzedType',
  'flow',
  'analyzedFlow',
  'records',
];

const linkInfoSchema: SchemaEntry[] = [
  {
    key: 'source',
    name: translateApiName(TYPE_IDS.DataSource, 'type'),
    nameI18nKey: `${i18nApiPrefix}:${TYPE_IDS.DataSource}:type`,
  },
  ...linkCustomSchemaKeys.map<SchemaEntry>((key) => {
    const i18nKey = getModeI18nKey(key);

    return {
      key,
      name: i18n.t(i18nKey),
      nameI18nKey: i18nKey,
    };
  }),
  ...linkSchemaKeys.map<SchemaEntry>((key) => ({
    key,
    translationPrefix: TYPE_IDS.Link,
  })),
];

type ExtendedEntityFieldProps = Pick<
  EntityFieldProps,
  'children' | 'rightSideItems' | 'rightSideItemsClassName'
>;

export interface Props extends ExtendedEntityFieldProps {
  isQueued?: boolean;
  isSelected?: boolean;
  isSyncing?: boolean;
  isDisabled?: boolean;
  linkFields: LinkFieldsSchema;
  onClick: () => void;
  searchQuery?: string;
  // NOTE: this is not perfect but rewriting the entire `EntityField` right now is out of the question right now
  slot2?: React.ReactNode;
  sourceData: IDesignSource;
}

export const LinkFields = ({
  isQueued,
  isSelected,
  isSyncing,
  linkFields,
  onClick,
  rightSideItems,
  rightSideItemsClassName,
  searchQuery,
  slot2,
  sourceData,
  isDisabled,
}: Props) => {
  // STATE
  const linkedEntities = useSelector(getLinkedEntities);
  const linkedEntityFields = useSelector(getLinkedEntityFields);
  const link = useSelector(getAllLinks)[linkFields.parentId];

  // DERIVED STATE
  const fromEntityField = linkedEntityFields[linkFields.fromId];
  const toEntityField = linkedEntityFields[linkFields.toId];
  const fromEntity = linkedEntities[fromEntityField.parentId];
  const toEntity = linkedEntities[toEntityField.parentId];
  const name = `${fromEntityField.name} = ${toEntityField.name}` as const;

  const info = React.useMemo<InfoParams>(
    () => ({
      data: {
        flow: link.flow,
        fromEntity: fromEntity.name,
        fromField: fromEntityField.name,
        toEntity: toEntity.name,
        toField: toEntityField.name,
        type: link.type,
        records: link.records,
        source: sourceData?.name,
        analyzedFlow: link.analyzedFlow,
        analyzedType: link.analyzedType,
      },
      schema: linkInfoSchema,
      title: (
        <DataSourceCalloutTitle
          {...{ name }}
          color={numberToHexColor(sourceData.color)}
        />
      ),
    }),
    [fromEntity, fromEntityField, link, toEntity, toEntityField, sourceData]
  );

  // RENDER
  return (
    <EntityField
      {...{
        info,
        name,
        rightSideItems,
        rightSideItemsClassName,
        slot2,
      }}
      isSelectable
      isDisabled={isDisabled}
      isSelected={isSelected}
      isOrange={isQueued}
      onClick={onClick}
      slot1={<EntityFieldSlotSpacer />}
      isSyncing={isSyncing}
      searchQuery={searchQuery}
      availability={link.availability}
      data-testid={`link-field-${name}`}
    />
  );
};

export default LinkFields;
