import { createAction } from '@reduxjs/toolkit';
import { StreamerFilter } from '../types';

export const addFilter = createAction<StreamerFilter, 'STREAMER2_FILTERS/ADD'>(
  'STREAMER2_FILTERS/ADD'
);
export const editFilter = createAction<
  StreamerFilter,
  'STREAMER2_FILTERS/EDIT'
>('STREAMER2_FILTERS/EDIT');
export const deleteFilter = createAction<
  StreamerFilter,
  'STREAMER2_FILTERS/DELETE'
>('STREAMER2_FILTERS/DELETE');

export const setFilters = createAction<
  StreamerFilter[],
  'STREAMER2_FILTERS/SET'
>('STREAMER2_FILTERS/SET');
