import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { routes } from '../../../../../constants/routes';
import useItemSelections from '../../../../../hooks/useItemSelections';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import {
  addNestedEntityRoute,
  deleteEntityRoute,
  editEntityRoute,
} from '../../../../../utils/routes';
import {
  configureSourceEntityIntent,
  fetchDataSourceTransforms,
  setConfiguringDataSource,
} from '../../actions';
import { getDataSourceTransforms, getSelectedSource } from '../../selectors';

const TransformsHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedSource = useSelector(getSelectedSource);
  const { selectedItem: selectedId, clear } = useItemSelections();
  const { t } = useTranslation();
  const goBackPage = routes.designer.ingestion.transforms;
  const { records: transforms } = useSelector(getDataSourceTransforms);

  const currentTransform = useMemo(
    () => transforms.find((trans) => trans?.id === selectedId),
    [transforms, selectedId]
  );

  const successCallback = (entityId: string) => {
    // When dealing when stream outputs we don't need to start a configuring task,
    // so this callback will start without an entityId.
    if (!entityId) {
      history.push(goBackPage);
    } else {
      dispatch(
        configureSourceEntityIntent({
          entityId,
          onStart: () => dispatch(setConfiguringDataSource(true)),
          onQueued: () => {
            history.push(goBackPage);
            dispatch(setConfiguringDataSource(false));
          },
          onEnd: () => {
            dispatch(fetchDataSourceTransforms(selectedSource?.id));
          },
        })
      );
    }
  };

  const handleAdd = () => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.SourceEntity,
      parentSchemaId: TYPE_IDS.DataSource,
      parentEntityId: selectedSource.id,
    });

    history.push(path, {
      typeName: 'Transform',
      successCallback,
    });
  };

  const handleEdit = () => {
    const path = editEntityRoute(TYPE_IDS.SourceEntity, selectedId);
    history.push(path, {
      successCallback,
    });
  };

  const handleDelete = React.useCallback(() => {
    const path = deleteEntityRoute(TYPE_IDS.SourceEntity, selectedId);

    history.push(path, {
      goBackPage,
      entityName: currentTransform?.name,
      successCallback: clear,
      deleteDescription: (
        <Trans
          i18nKey="wizard:deleteNestedEntity"
          values={{
            name: currentTransform?.name,
            asset: t('entityTypes:DataSourceEntity'),
          }}
        />
      ),
    });
  }, [selectedId]);

  return (
    <ItemActionsHeader
      isItemSelected={!!selectedId}
      {...{ handleAdd, handleEdit, handleDelete }}
    />
  );
};

export default TransformsHeader;
