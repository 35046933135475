export enum FormatType {
  Currency = 'Currency',
  LongDate = 'LongDate',
  LongDateTime = 'LongDateTime',
  LongTime = 'LongTime',
  NoMarkup = 'NoMarkup',
  Number = 'Number',
  Percentage = 'Percentage',
  Response = 'Response',
  ShortDate = 'ShortDate',
  ShortDateTime = 'ShortDateTime',
  ShortTime = 'ShortTime',
  Standard = 'Standard',
  State = 'State',
  Uri = 'Uri',
}
