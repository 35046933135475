import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, Route, useHistory, useLocation } from 'react-router-dom';
import { IIconProps } from 'office-ui-fabric-react';
import { HeaderBarButton } from '../../../../components/ui';
import { routes } from '../../../../constants/routes';
import { playIntent } from '../../actions';
import {
  getIsFetchingStreamerOutput,
  getSelectedCardKeys,
} from '../../strategies/isolated/selectors';
import {
  getSelectedAssetsCount,
  getSelectedStreamDesignId,
} from '../../selectors';
import { StreamingStrategies } from '../../types';
import FiltersHeader from '../../Filters/components/StreamerFiltersHeader';
import { Strategy } from '../StreamToDatabase/useStreamToDatabase';
import { toggleSharePreview } from '../../strategies/isolated/actions';
import CardHeaderControls from '../../strategies/isolated/components/CardHeaderControls/CardHeaderControls';
import ExportButton from '../../ExportWizard/components/ExportButton';
import ImportButton from '../../ImportWizard/components/ImportButton/ImportButton';

const BACK_ICON_PROPS: IIconProps = {
  iconName: 'Back',
};

const PLAY_ICON_PROPS: IIconProps = {
  iconName: 'Play',
};

const SHARE_ICON_PROPS: IIconProps = {
  iconName: 'Share',
};

export const Header = () => {
  // HOOKS
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  // STATE
  const selectedAssetsCount = useSelector(getSelectedAssetsCount);
  const isRetrievingOutputData = useSelector(getIsFetchingStreamerOutput);
  const selectedStreamDesignId = useSelector(getSelectedStreamDesignId);
  const selectedCardKeys = useSelector(getSelectedCardKeys);

  // DERIVED STATE
  const noAssetsSelected = selectedAssetsCount === 0;
  const isInStreamSelection = matchPath(
    pathname,
    routes.streamer.streamSelecting.index
  );

  // CALLBACKS
  const handlePlayClick = React.useCallback(() => {
    dispatch(
      playIntent({
        streamingStrategy: StreamingStrategies.ISOLATED,
        metadata: {
          history,
        },
      })
    );
  }, [dispatch]);

  const handleGoBack = React.useCallback(() => {
    history.push(routes.streamer.streamSelecting.pickStream);
  }, [history]);

  const handleShareClick = React.useCallback(() => {
    if (selectedCardKeys.length > 0) dispatch(toggleSharePreview(true));
  }, [dispatch, selectedCardKeys.length]);

  return (
    <div>
      <HeaderBarButton
        iconProps={BACK_ICON_PROPS}
        onClick={handleGoBack}
        disabled={!!isInStreamSelection}
        shouldHideOnDisabled
      />
      <Route path={routes.streamer.dataMode.filters.index}>
        <FiltersHeader />
      </Route>
      <HeaderBarButton
        iconProps={PLAY_ICON_PROPS}
        onClick={handlePlayClick}
        disabled={noAssetsSelected || isRetrievingOutputData}
        shouldHideOnDisabled
        data-testid="play-resume-btn"
      />
      <CardHeaderControls />
      {/* FIXME [KM]: I don't think a button for "isolated" streamer should be present in what
        seems to be a header for a generic streamer (used in all placed)
      */}
      <ImportButton
        strategy={Strategy.ISOLATED}
        designId={selectedStreamDesignId}
      />
      <ExportButton
        strategy={Strategy.ISOLATED}
        designId={selectedStreamDesignId}
      />

      <HeaderBarButton
        disabled={selectedCardKeys.length < 1}
        iconProps={SHARE_ICON_PROPS}
        onClick={handleShareClick}
        shouldHideOnDisabled
      />
    </div>
  );
};
