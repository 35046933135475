import {
  ITheme, ITooltipStyles, IButtonStyles, mergeStyleSets,
} from '@fluentui/react';

import { baseColors } from '../../theme';

export const tooltipStyles = (theme:ITheme):Partial<ITooltipStyles> => ({
  root: {
    selectors: {
      '.ms-Callout-beak': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-beakCurtain': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-main': {
        backgroundColor: theme.palette.black,
      },
    },
  },
  content: {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
  },
});

export const btnStyles = (theme: ITheme, isActive = false): IButtonStyles => ({
  root: {
    width: 28,
    height: 28,
  },
  flexContainer: {
    position: 'relative',
  },
  icon: {
    color: isActive ? theme.palette.themePrimary : theme.palette.neutralPrimary,
  },
  rootDisabled: {
    backgroundColor: 'transparent',
  },
});

export const counterBadgeStyles = (theme: ITheme) => mergeStyleSets({
  root: [
    'sidebar-item-badge',
    {
      color: theme.palette.white,
      backgroundColor: baseColors.red,
      borderRadius: '50%',
      fontSize: '10px',
      width: '12px',
      height: '12px',
      padding: 1,
      position: 'absolute',
      top: '-3px',
      right: '-3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  ],
});

export const stackStyles = mergeStyleSets({
  root: [
    'icon-btn-wrapper',
    {
      position: 'relative',
    },
  ],
});
