import { baseColors } from '../../../../theme';

export const containerStyles = {
  height: '100%',
  display: 'flex' as const,
  flex: 1,
  flexDirection: 'column' as const,
};

export const scrollableAreaStyles = {
  flexGrow: 1,
  height: 'calc(100% - 25px)',
  maxHeight: 'calc(100% - 25px)',
  overflowY: 'auto' as const,
};

export const switchButtonStyles = (isUnselected) => ({
  position: 'sticky' as const,
  bottom: 0,
  left: 0,
  width: '100%',
  minHeight: '24px',
  backgroundColor: baseColors.lightGrey,
  borderTop: `1px solid ${baseColors.outlineGray}`,
  color: baseColors.textColor,
  display: 'flex' as const,
  alignItems: 'center' as const,
  justifyContent: isUnselected ? 'flex-start' : 'flex-end',
});

export const textStyles = {
  margin: 0,
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',
};

export const arrowStyles = ({
  root: {
    zIndex: 1,
    height: '24px',
    color: baseColors.textColor,
    selectors: {
      '&:hover': {
        backgroundColor: baseColors.midGrey,
        color: baseColors.textColor,
      },
    },
  },
  icon: {
    lineHeight: '16px',
    height: '16px',
    fontSize: '16px',
    width: '16px',
  },
});
