import { writeable } from '../../../../../../../shared/types/utils';

export const TRANSLATION_PREFIX = 'wizard:inputs:oauthControl';

export const OAUTH_DIALOG_FILE = 'oauth.html';

export const BASE_DIALOG_OPTIONS: Office.DialogOptions = {
  promptBeforeOpen: false,
  width: 800,
  height: 600,
};

export enum FieldNames {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  SCOPES = 'scopes',
}

export const WATCHED_FIELD_NAMES = writeable([
  FieldNames.ACCESS_TOKEN,
  FieldNames.REFRESH_TOKEN,
  FieldNames.SCOPES,
] as const);

export const XERO_HOST_FIELD_NAME = 'host';

export const XERO_WATCHED_FIELD_NAMES = writeable([
  FieldNames.ACCESS_TOKEN,
  XERO_HOST_FIELD_NAME,
] as const);
