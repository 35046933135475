import React, { useMemo } from 'react';
import {
  Stack, mergeStyleSets, getTheme, Icon,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Toggle } from '../../../ui';

import {
  getStyles, footerStackStyles, toggleStyles,
} from './styles';
import { selectors, actionCreators } from '../../../../modules/Streams/Streams';
import { updateDataset } from '../../../../actions/excel-actions/excel-actions';
import { ColumnsReorder, ColumnsSortOrder } from '../../../../modules/ColumnsSequencing/screens/ColumnsReorder';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import useSortControl from '../../../../hooks/useSortControl';
import { DefinedSortTypes } from '../../../../modules/App/types';

const Design : React.FC = () => {
  // HOOKS
  const { t } = useTranslation('streams');
  const dispatch = useDispatch();
  const theme = getTheme();

  // STATE
  const currentDataset = useSelector(selectors.getCurrentDataset);
  const { currentSort } = useSortControl();

  // DERIVED STATE
  const classNames = useMemo(() => {
    const styles = getStyles(theme);
    return mergeStyleSets(styles);
  }, []);

  const hasTotals = currentDataset?.totals?.length !== 0;

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:streamer:layout:cta:noDataSelected': !currentDataset?.columns?.length,
    }),
    [currentDataset?.columns?.length],
  );

  // CALLBACKS
  const { editDataset } = bindActionCreators(actionCreators, dispatch);
  const onChangeTotal = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean,
  ) => {
    const totals = checked
      ? [{ rows: [[]], type: null }]
      : [];

    editDataset(currentDataset.id, {
      ...currentDataset,
      totals,
    });

    updateDataset({ datasetId: currentDataset.id });
  };

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (tutorialTile) return tutorialTile;

  return (
    <Stack className={classNames.container} horizontal grow={1}>
      <div className={classNames.sequenceWrapper}>
        {currentSort === DefinedSortTypes.Sequence && (
          <ColumnsReorder />
        )}
        {currentSort === DefinedSortTypes.Precedence && (
          <ColumnsSortOrder />
        )}
      </div>
      <Stack styles={footerStackStyles}>
        <Icon
          iconName="SynergiesColumnsLayout"
          className={classNames.icon}
        />
        <div className={classNames.toggleStack}>
          <Toggle
            onChange={onChangeTotal}
            styles={toggleStyles}
            label={t('Total')}
            inlineLabel
            defaultChecked={hasTotals}
          />
        </div>
      </Stack>
    </Stack>
  );
};

export default Design;
