import React from 'react';
import { ITextFieldStyles, TextField, ITextFieldProps } from '@fluentui/react';
import merge from 'lodash/merge';

const baseStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    selectors: {
      '&::after': {
        content: '',
        display: 'none',
      },
    },
  },
  field: {
    fontSize: '12px',
  },
  subComponentStyles: {
    label: {
      root: {
        fontSize: '12px',
      },
    },
  },
};

const StyledTextInput: React.FC<ITextFieldProps> = ({ styles, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextField {...rest} styles={merge({}, baseStyles, styles)} />
);

export default StyledTextInput;

export const FilterFormTextInput: React.FC<ITextFieldProps> = ({ styles, ...rest }) => (
  <StyledTextInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    styles={merge({}, baseStyles, {
      field: {
        fontSize: '14px',
      },
    })}
  />
);
