import * as effects from 'redux-saga/effects';
import * as actions from '../actions';
import { getStreamFiltersList } from '../selectors';
import selectors from '../../Streams/selectors';

function* updateDataset() {
  const streamFilters = getStreamFiltersList(yield effects.select());

  const currentDataset = selectors.getCurrentDataset(yield effects.select());

  if (!currentDataset) {
    return;
  }

  yield effects.put({
    type: 'EDIT_DATASET',
    id: currentDataset.id,
    properties: {
      streamFilters,
    },
  });
}

export default function* rootSaga() {
  yield effects.takeLatest([
    actions.addStreamFilter.type,
    actions.setStreamFilters.type,
    actions.editStreamFilter.type,
    actions.removeStreamFilter.type,
    actions.removeSelectedFilters.type,
  ], updateDataset);
}
