import axios, { AxiosResponse } from 'axios';
import * as effects from 'redux-saga/effects';
import { IColumn } from '../../../types/IColumn';
import { IDatasetContinuation } from '../../../types/IDataset';
import selectors from '../selectors';
import { getStreamFiltersList } from '../../StreamFilters/selectors';
import { columnsToRequestElements } from '../utils';
import { IInquiryData } from '../../../types/IInquiryData';
import { IStream } from '../../../types/IStream';
import { IStreamFilter } from '../../../types/IStreamFilter';
import { createStreamDataRequestTask, InquiriesDataParams } from '../api';

/**
 *
 * @param items Items to be included and returned by the ad-hoc inquiry
 * @param continuation
 * @param extraItems Items to be included but not returned by the ad-hoc inquiry (useful for
 * custom filters and cross-entities requests)
 */

export function* fetchColumnData(
  items: IColumn[],
  continuation?: IDatasetContinuation,
  extraItems: IColumn[] = [],
) {
  const source = yield effects.call(axios.CancelToken.source);
  try {
    const currentStream:IStream = yield effects.select(selectors.getCurrentStream);
    const streamFilters:IStreamFilter[] = yield effects.select(getStreamFiltersList);

    const { data, result, filter } :
    ReturnType<typeof columnsToRequestElements> = yield effects.call(
      columnsToRequestElements, items, streamFilters, extraItems,
    );

    const params:InquiriesDataParams = {
      data,
      columns: result,
      ...(continuation ? { continuation } : {}),
      filter,
    };
    const response : AxiosResponse<IInquiryData> = yield effects.call(
      createStreamDataRequestTask(currentStream.id), params,
    );
    return response.data;
  } finally {
    if (yield effects.cancelled()) {
      source.cancel();
    }
  }
}
