import * as effects from 'redux-saga/effects';

import * as api from '../api';
import { deleteTopLevelEntity } from '../actions';
import { updateEntity, createEntity } from '../Form/actions';

export function* handleDeleteTopLevelEntity({
  payload: {
    entityId,
    schemaId,
    callback,
  },
}:ReturnType<typeof deleteTopLevelEntity>) {
  yield effects.put({ type: 'SET_SYNCING', syncing: true });
  try {
    yield effects.call(api.deleteEntity(schemaId), entityId);
    yield effects.call(callback);
  } catch (error) {
    console.log('error', error);
  } finally {
    yield effects.put({ type: 'SET_SYNCING', syncing: false });
  }
}

export function* handleUpdateTopLevelEntity({
  payload: {
    schemaId,
    entity,
    callback,
  },
}:ReturnType<typeof updateEntity>) {
  yield effects.put({ type: 'SET_SYNCING', syncing: true });
  try {
    yield effects.call(api.patchEntity(schemaId), entity);
    yield effects.call(callback);
  } catch (error) {
    console.log('error', error);
  } finally {
    yield effects.put({ type: 'SET_SYNCING', syncing: false });
  }
}

export function* handleCreateTopLevelEntity({
  payload: {
    schemaId,
    data,
    callback,
  },
}:ReturnType<typeof createEntity>) {
  yield effects.put({ type: 'SET_SYNCING', syncing: true });
  try {
    yield effects.call(api.createEntity(schemaId), data);
    yield effects.call(callback);
  } catch (error) {
    console.log('error', error);
  } finally {
    yield effects.put({ type: 'SET_SYNCING', syncing: false });
  }
}

export function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(deleteTopLevelEntity, handleDeleteTopLevelEntity),
    yield effects.takeLatest(updateEntity, handleUpdateTopLevelEntity),
    yield effects.takeLatest(createEntity, handleCreateTopLevelEntity),
  ]);
}

export default rootSaga;
