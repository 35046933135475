import React, { FunctionComponent } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

import { classNames } from './styles';

export interface DatePickerProps {
  date: Date;
  disabled?: boolean;
  is24Hours?: boolean;
  onChange(date: Date): void;
  showTimePicker?: boolean;
}

const isValidDate = (dateObject: Date) =>
  new Date(dateObject).toString() !== 'Invalid Date';

const DatePicker: FunctionComponent<DatePickerProps> = (props) => {
  const {
    date,
    disabled,
    is24Hours = false,
    onChange,
    showTimePicker = false,
  } = props;

  const timeFormat = is24Hours ? 'h:mm aa' : 'HH:mm';
  const dateFormat = showTimePicker ? `dd/MM/yyyy ${timeFormat}` : 'dd/MM/yyyy';
  const placeholderText = showTimePicker
    ? `DD/MM/YYYY ${timeFormat}`
    : 'DD/MM/YYYY';
  const selected = isValidDate(date) || date === undefined ? date : new Date();

  return (
    <ReactDatePicker
      {...{ dateFormat, disabled, onChange, placeholderText, selected }}
      className={classNames.inputWrapper}
      showTimeInput={showTimePicker}
    />
  );
};

export default DatePicker;
