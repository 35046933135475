import { Label } from '@fluentui/react';
import React from 'react';

import IconPicker from '../../../../../components/formControls/IconPicker';
import { IControlledField } from '../../types';

const IconGallery : React.FC<IControlledField> = ({
  controller,
  isDisabled,
  label,
}) => {
  const { onChange, value } = controller;
  return (
    <>
      <Label>{label}</Label>
      <IconPicker
        {...{ isDisabled }}
        initialValue={value}
        onValueChange={(val) => onChange(val)}
      />
    </>
  );
};

export default IconGallery;
