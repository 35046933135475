export enum NotificationSubjectState {
  InProgress = 'InProgress',
  Complete = 'Complete',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Start = 'Start',
}

export enum NotificationResponse {
  /**
   * Unread notification
   */
  None = 'None',
  /**
   * Notification marked as "read"
   */
  Dismiss = 'Dismiss',
}

export enum NotificationActivity {
  Configure = 'Configure',
  Delete = 'Delete',
  Export = 'Export',
  Import = 'Import',
  Initializing = 'Initializing',
  Interacting = 'Interacting',
  LinkDiscovery = 'LinkDiscovery',
  None = 'None',
  Process = 'Process',
  Revoke = 'Revoke',
  Share = 'Share',
}

interface Details {
  method: string;
  path: string;
  exception: string;
  message: string;
  snapshotId?: string;
}

export interface INotification {
  $typeId: string;
  id: string;
  activity: NotificationActivity;
  state: NotificationSubjectState;
  timeStamp: string;
  showPopup: boolean;
  $senderTypeId: string;
  senderId: string;
  subjectId?: string;
  subjectName?: string;
  response: NotificationResponse;
  details?: Details;
  parentId: string;
  // @deprecated
  $parentTypeId: string;
  // @deprecated API changed - but old notification might contain them
  $subjectTypeId: string;
  subjectTypeId: string;
}
