import * as effects from 'redux-saga/effects';
import * as reduxSaga from 'redux-saga';

import { AxiosResponse } from 'axios';
import * as builderActions from '../actions';
import * as builderApi from '../api';
import * as builderSelectors from '../selectors';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { IDesignerFilter } from '../../../types/IDesignerFilter';
import { fetchEntity } from '../../Designer/api';
import { deleteTopLevelEntity } from '../../Designer/actions';
import { ComparisonTypes, FilterTypes } from '../../../types/IStreamFilter';
import { IStreamField } from '../../Designer/types';
import { toggleValue } from '../../../utils/toggleValue';

export function* handleCreateStreamFieldFilter({
  payload: { relatedStreamField, filter: filterPayload },
}: ReturnType<typeof builderActions.createStreamFieldFilterIntent>) {
  try {
    const { data } = yield effects.call(builderApi.createStreamFieldFilter, {
      filter: filterPayload,
      streamFieldId: relatedStreamField.id,
    });

    const newFilter: IDesignerFilter = {
      ...data,
      item: {
        ...relatedStreamField,
      },
    };

    const { records: currentFilters } = builderSelectors.getStreamFilters(
      yield effects.select()
    );
    yield effects.put(
      builderActions.fetchAllStreamFieldsFilters.fulfilled(
        [...currentFilters, newFilter],
        '',
        ''
      )
    );
  } finally {
    const { isLoading: areFiltersLoading } = builderSelectors.getStreamFilters(
      yield effects.select()
    );

    if (areFiltersLoading) {
      yield effects.put(builderActions.setIsLoadingFilters(false));
    }
  }
}
export function* handleEditStreamFieldFilter({
  payload: { relatedStreamField, filter: filterPayload },
}: ReturnType<typeof builderActions.editStreamFieldFilterIntent>) {
  try {
    yield effects.call(builderApi.editStreamFieldFilter, filterPayload);

    const { data: response }: AxiosResponse<IDesignerFilter> =
      yield effects.call(
        fetchEntity(TYPE_IDS.StreamFieldFilter, filterPayload.id)
      );

    const updatedFilter: IDesignerFilter = {
      ...response,
      item: {
        ...relatedStreamField,
        id: relatedStreamField.id,
        name: relatedStreamField.name,
        dataType: relatedStreamField.dataType as any,
      },
    };

    const { records: currentFilters } = builderSelectors.getStreamFilters(
      yield effects.select()
    );

    const updatedFilters = [
      ...currentFilters.filter((filter) => filter.id !== filterPayload.id),
      updatedFilter,
    ];
    yield effects.put(
      builderActions.fetchAllStreamFieldsFilters.fulfilled(
        updatedFilters,
        '',
        ''
      )
    );
  } finally {
    const { isLoading: areFiltersLoading } = builderSelectors.getStreamFilters(
      yield effects.select()
    );

    if (areFiltersLoading) {
      yield effects.put(builderActions.setIsLoadingFilters(false));
    }
  }
}
type FilterUpdate = { forkHandle: reduxSaga.Task; valuesToUpdate: string[] };
interface Params {
  newValues: string[];
  relatedFilter: Partial<IDesignerFilter>;
  relatedStreamField: Partial<IStreamField>;
  filterQueueReference: Record<'string', FilterUpdate>;
}

export function* updateFilterByValue({
  relatedStreamField,
  newValues,
  relatedFilter,
  filterQueueReference,
}: Params) {
  try {
    // waiting for similar action to arrive in watcher
    yield effects.delay(500);

    const { records: currentFilters } = builderSelectors.getStreamFilters(
      yield effects.select()
    );

    if (relatedFilter && newValues.length > 0) {
      // PATCH filter
      const updatedFilter: Partial<IDesignerFilter> = {
        id: relatedFilter.id,
        values: newValues,
      };

      yield effects.call(builderApi.editStreamFieldFilter, updatedFilter);
      yield effects.put(
        builderActions.valueFilterUpdatedSuccessfully({ updatedFilter })
      );
    } else if (relatedFilter && newValues.length === 0) {
      // DELETE filter
      yield effects.put(
        deleteTopLevelEntity({
          callback: () => null,
          entityId: relatedFilter.id,
          schemaId: TYPE_IDS.StreamFieldFilter,
        })
      );

      const newFilters = currentFilters.filter(
        (f) => f.id !== relatedFilter.id
      );

      yield effects.put(
        builderActions.fetchAllStreamFieldsFilters.fulfilled(
          newFilters,
          null,
          null
        )
      );
    } else {
      // CREATE filter
      const { data: createdFilter } = yield effects.call(
        builderApi.createStreamFieldFilter,
        {
          filter: {
            type: FilterTypes.Values,
            comparison: ComparisonTypes.Equal,
            values: newValues,
          },
          streamFieldId: relatedStreamField.id,
        }
      );

      const newFilters = [
        ...currentFilters,
        {
          ...createdFilter,
          item: relatedStreamField,
        },
      ];

      yield effects.put(
        builderActions.fetchAllStreamFieldsFilters.fulfilled(
          newFilters,
          null,
          null
        )
      );

      // eslint-disable-next-line no-param-reassign
      delete filterQueueReference[relatedStreamField.id];
    }
  } catch (error) {
    console.error('updateFilterByValue error', error);
  }
}

export function* valueClickedWatcher() {
  const filterUpdatesQueue: Record<IStreamField['id'], FilterUpdate> = {};
  // keep listening for actions
  while (true) {
    const action: ReturnType<typeof builderActions.filterByValueItemClicked> =
      yield effects.take(builderActions.filterByValueItemClicked.type);
    const { relatedFilter, value, relatedStreamField } = action.payload;
    const queuedFilterUpdate = filterUpdatesQueue[relatedStreamField.id];
    // if a queued task exists we should cancel it and respawn it with updated payload
    if (queuedFilterUpdate) yield effects.cancel(queuedFilterUpdate.forkHandle);
    // prepare the data and spawn the delayed update task
    const valuesToUpdate = toggleValue(
      queuedFilterUpdate?.valuesToUpdate ?? [],
      value
    );
    const forkHandle: reduxSaga.Task = yield effects.fork(updateFilterByValue, {
      filterQueueReference: filterUpdatesQueue,
      newValues: valuesToUpdate,
      relatedFilter,
      relatedStreamField,
    });

    // store info about the spawned task
    filterUpdatesQueue[relatedStreamField.id] = { forkHandle, valuesToUpdate };
  }
}

export function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(
      builderActions.createStreamFieldFilterIntent.type,
      handleCreateStreamFieldFilter
    ),
    yield effects.takeLatest(
      builderActions.editStreamFieldFilterIntent.type,
      handleEditStreamFieldFilter
    ),
    valueClickedWatcher(),
  ]);
}

export default rootSaga;
