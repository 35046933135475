import { IColumnDataType } from './IColumn';

export enum FilterTypes {
  Values = 'Values',
  Comparison = 'Comparison',
  Range = 'Range',
}

export enum ComparisonTypes {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  Contains = 'Contains',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull',
  IsBlank = 'IsBlank',
  IsNotBlank = 'IsNotBlank',
  Between = 'Between',
  In = 'In',
  NotBetween = 'NotBetween',
  NotIn = 'NotIn',
  DoesNotContain = 'DoesNotContain',
  DoesNotStartWith = 'DoesNotStartWith',
  DoesNotEndWith = 'DoesNotEndWith',
}

const NonTextSubset = [
  ComparisonTypes.Equal,
  ComparisonTypes.NotEqual,
  ComparisonTypes.LessThan,
  ComparisonTypes.LessThanOrEqual,
  ComparisonTypes.GreaterThan,
  ComparisonTypes.GreaterThanOrEqual,
  ComparisonTypes.IsNull,
  ComparisonTypes.IsNotNull,
  ComparisonTypes.Between,
  ComparisonTypes.In,
  ComparisonTypes.NotBetween,
  ComparisonTypes.NotIn,
];

export const ComparisonPerDataType = (
  dataType: IColumnDataType
): ComparisonTypes[] =>
  ({
    [IColumnDataType.Boolean]: [
      ComparisonTypes.Equal,
      ComparisonTypes.NotEqual,
      ComparisonTypes.IsNull,
      ComparisonTypes.IsNotNull,
    ],
    [IColumnDataType.Text]: [
      ComparisonTypes.Equal,
      ComparisonTypes.NotEqual,
      ComparisonTypes.Contains,
      ComparisonTypes.StartsWith,
      ComparisonTypes.EndsWith,
      ComparisonTypes.IsNull,
      ComparisonTypes.IsNotNull,
      ComparisonTypes.IsBlank,
      ComparisonTypes.IsNotBlank,
      ComparisonTypes.In,
      ComparisonTypes.NotBetween,
      ComparisonTypes.NotIn,
      ComparisonTypes.DoesNotContain,
      ComparisonTypes.DoesNotEndWith,
      ComparisonTypes.DoesNotStartWith,
    ],
    [IColumnDataType.DateTime]: NonTextSubset,
    [IColumnDataType.NumericDecimal]: NonTextSubset,
    [IColumnDataType.NumericInteger]: NonTextSubset,
  }[dataType] || Object.keys(ComparisonTypes));

export interface IStreamFilter {
  id?: string;
  type?: FilterTypes;
  values?: string[];
  comparison?: ComparisonTypes;
  compareValue?: string;
  lowerBoundValue?: string;
  upperBoundValue?: string;
  dataSetId?: string;
  column?: any;
  generatedFromValue?: boolean;
  aggregation?: string | null;
}
