import React, { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getDataSources } from '../../../Catalog/selectors';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { getAllSources } from '../../../Ingestion/selectors';
import { IDesignSourceEntity, IDesignSourceEntityField } from '../../../types';
import { fetchTypedEntities } from '../../actions';
import {
  getEntityById,
  getEntityFormByTypeId,
  getIsTypedEntitiesFetchTaskRunning,
  getTypedEntitiesByTypeId,
} from '../../selectors';
import { IControlledField } from '../../types';
import LinkField from '../LinkPicker/LinkField';
import { generateEntitiesWithFields } from '../LinkPicker/utils';
import { useSourceFromEntity } from '../SelectOneOfType/useSourceFromEntity';

const FieldPicker: React.FC<IControlledField> = ({
  controller,
  entityProperty,
  interaction,
  isDisabled,
  isEditing,
  currentForm,
  label,
}) => {
  const dispatch = useDispatch();
  const designId = useSelector(getSelectedDesignId);
  const { value, onChange } = controller;
  const { records: catalogSources } = useSelector(getDataSources);
  const ingestionSources = useSelector(getAllSources);

  const { isReadOnly, isEditableAfterCreation } = entityProperty;
  const {
    isRequired,
    referenceTypeFilterValueProperty,
    referenceTypeFilterProperty,
    allowedDataTypes,
  } = interaction;
  const isTransformation = currentForm.parentTypeId === TYPE_IDS.SourceEntity;
  const entity = useSelector(getEntityById(value));
  const { sourceId, setSourceId } = useSourceFromEntity(entity?.parentId);
  const entityForm = useSelector(getEntityFormByTypeId(TYPE_IDS.SourceEntity));
  const currentSources = isTransformation ? ingestionSources : catalogSources;
  const currentSourceId =
    (isTransformation && !isEditing ? entityForm?.parentEntityId : sourceId) ||
    currentSources?.[0]?.id;

  const sourceEntities = useSelector(
    getTypedEntitiesByTypeId(TYPE_IDS.SourceEntity)
  );
  const sourceFields = useSelector(
    getTypedEntitiesByTypeId(TYPE_IDS.SourceEntityField)
  );

  const isLoading = useSelector(
    getIsTypedEntitiesFetchTaskRunning(
      TYPE_IDS.SourceEntityField,
      currentSourceId
    )
  );

  const entities = useMemo(
    () =>
      generateEntitiesWithFields(
        sourceEntities.filter(
          ({ parentId }) => parentId === currentSourceId
        ) as IDesignSourceEntity[],
        sourceFields as IDesignSourceEntityField[],
        !isTransformation
      ),
    [sourceEntities, sourceFields, isTransformation, currentSourceId]
  );

  // On transforms wizard, the entityId is given by the referenceTypeFilterValueProperty
  // of the interaction

  const filterRefProp = useWatch({
    name: referenceTypeFilterValueProperty || '',
  }) as string;

  useEffect(() => {
    if (
      (referenceTypeFilterValueProperty && !filterRefProp) ||
      !currentSourceId
    ) {
      return;
    }
    dispatch(
      fetchTypedEntities({
        typeId: TYPE_IDS.SourceEntityField,
        designId,
        sourceId: currentSourceId,
        entityId: referenceTypeFilterProperty === 'parentId' && filterRefProp,
        selectedFieldsOnly: true,
      })
    );
  }, [currentSourceId, filterRefProp, referenceTypeFilterValueProperty]);

  return (
    <LinkField
      data-testid="field-picker-select"
      fieldId={value}
      onFieldChange={onChange}
      onSourceChange={!isTransformation && setSourceId}
      sourceId={currentSourceId}
      sources={currentSources}
      entities={entities}
      isDisabled={
        isDisabled ||
        isReadOnly ||
        (isEditing && !isEditableAfterCreation) ||
        isLoading
      }
      isRequired={isRequired}
      isLoading={isLoading}
      label={label}
      allowedDataTypes={allowedDataTypes}
    />
  );
};

export default FieldPicker;
