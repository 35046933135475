import { StreamAssetRaw } from '../../../../types';
import { KEYS_SHOWN_ON_MOUNT } from './components/OutputCard/constants';

interface GroupByAssetValue {
  assetId: string;
  data: { [key: string]: any }[];
  assets: StreamAssetRaw[];
}

export const groupByAssetValue = ({
  assetId,
  data,
  assets,
}: GroupByAssetValue) => {
  const index = assets.findIndex((asset) => asset.id === assetId);
  const key = Object.keys(data?.[0] || {})[index];

  return data.reduce(
    (acc, cur) => ({
      ...acc,
      [cur[key]]: [...(acc[cur[key]] || []), cur],
    }),
    {}
  );
};

export const getOutputRowHeight = (
  row: { [key: string]: any }[],
  isOpened: boolean
) => {
  if (!row?.length) return 0;

  const nKeys = Object.keys(row[0] || {}).length;

  // We have to account for the __CARD_KEY item on each entry
  // that's why whe have + 1 and - 1 in the calculations

  if (nKeys > KEYS_SHOWN_ON_MOUNT + 1) {
    return isOpened
      ? 213 + 20 * Math.max(nKeys - KEYS_SHOWN_ON_MOUNT - 1, 0)
      : 213;
  }
  return 141 + 20 * Math.max(nKeys - 3, 0);
};
