/* eslint-disable prefer-arrow-callback */
import 'react-app-polyfill/ie11';

import { Provider as StoreProvider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from '@uifabric/icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './modules/App/root';

import configureStore, { sagaMiddleware } from './store';
import { ISharedState } from './sharedState';
import { isOfficeEnabled as isOfficeEnabledUtil } from './config/buildType';
import { settingsSync } from './store/persistence';
import { createSessionId } from './modules/App/sessionId';
import customIconsInitializer from './utils/customIconsInitializer';

import 'mutationobserver-shim';
import './config/i18n';

import './theme';
import 'office-ui-fabric-react/dist/css/fabric.min.css';
import ErrorBoundary from './modules/App/components/ErrorBoundary';
import rootSaga from './sagas/rootSaga';
import { acquireTokenSilent } from './modules/Auth-v2';
import { AuthProvider } from './modules/Auth-v2/context';
import { initializeSentry } from './config/sentry';

initializeIcons();
customIconsInitializer();
initializeSentry();

let isOfficeInitialized = false;

const title = 'eyko';
window.sharedState = {
  store: null,
  runSaga: sagaMiddleware.run,
  datasets: [],
  currentDatasetId: null,
  currentSheet: null,
  setDatasets(this: ISharedState, datasets) {
    this.datasets =
      typeof datasets === 'function'
        ? datasets(this.datasets.map((dataset) => ({ ...dataset })))
        : datasets;
  },
};

const storeInitializer = () => {
  let hasInitialized = false;
  let initializedStore = null;

  return async () => {
    if (!hasInitialized && !window.sharedState.store) {
      hasInitialized = true;
      initializedStore = await configureStore();
      window.sharedState.store = initializedStore;
      sagaMiddleware.run(rootSaga);
    }

    return initializedStore;
  };
};

const getStore = storeInitializer();

// NOTE: a separate wrapper to handle auth errors is required in order not to prevent the app from rendering
const authSilent = async (): Promise<boolean> => {
  try {
    const accessToken = await acquireTokenSilent();
    if (accessToken) return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};
const isOfficeEnabled = isOfficeEnabledUtil();

const render = async (Component) => {
  const isSignedIn = await authSilent();
  const store = await getStore();

  ReactDOM.render(
    <ErrorBoundary>
      <AppContainer>
        <AuthProvider {...{ isSignedIn }}>
          <StoreProvider store={store}>
            <Component {...{ isOfficeInitialized, title }} />
          </StoreProvider>
        </AuthProvider>
      </AppContainer>
    </ErrorBoundary>,
    document.getElementById('container')
  );
};

if (isOfficeEnabled) {
  Office.initialize = () => {
    isOfficeInitialized = true;
    // Office API does not expose any user information
    // So in order to make persistence sync work, we need to assign
    // session id of each user
    createSessionId();
    settingsSync();
    // enable auto-open addin after document is saved
    Office.context.document.settings.set(
      'Office.AutoShowTaskpaneWithDocument',
      true
    );
    Office.context.document.settings.saveAsync(() => {
      render(App);
    });
  };
}

// Because office.js cannot initialize outside of the Office environment
// we simply render the `App` component as soon as we can
if (!isOfficeEnabled) {
  render(App);
}

if ((module as any).hot) {
  (module as any).hot.accept('./modules/App/root', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./modules/App/root').default;
    render(NextApp);
  });
}
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('./service-worker.js')
      .then((registration) => {
        console.log('SW registered: ', registration);
      })
      .catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}
