import { IButtonStyles, IStackStyles, ITextFieldStyles } from '@fluentui/react';
import { baseColors, palette } from '../../../../../theme';

export const containerStyles: IStackStyles = {
  root: {
    padding: 8,
    borderBottom: `1px solid ${baseColors.outlineGray}`,
    flexDirection: 'row',
    alignItems: 'center',
  },
};

export const inputWrapperStyles: IStackStyles = {
  root: {
    position: 'relative',
    flex: 1,
  },
};

export const closeButtonStyles: IButtonStyles = {
  root: {
    flex: '0 0 32px',
    minWidth: 32,
    marginLeft: 8,
    height: 32,
    border: 0,
  },
};

export const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    border: `1px solid ${baseColors.outlineGray}`,
    borderRadius: 2,
    paddingLeft: 32,
    height: 32,
    selectors: {
      '&::after': {
        border: `1px solid ${baseColors.midOrange}`,
      },
      '&:hover,&:hover::after': {
        borderColor: baseColors.orange,
      },
    },
  },
  icon: {
    left: 12,
  },
  field: {
    paddingLeft: 4,
  },
};

export const clearButtonStyles: (_: boolean) => IButtonStyles = isEmpty => ({
  root: {
    minWidth: 24,
    height: 24,
    position: 'absolute',
    right: 4,
    top: 4,
    display: isEmpty ? 'none' : 'block',
    padding: 0,
  },
  rootHovered: {
    backgroundColor: palette.neutralLighter,
  },
  icon: {
    color: palette.neutralSecondary,
    fontSize: 12,
  },
  iconHovered: {
    color: palette.neutralPrimary,
  },
});
