import { Slider } from 'office-ui-fabric-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useReferenceTypeProperty } from '../../hooks/useReferenceTypeProperty';
import { IControlledField } from '../../types';

const RangeSlider: React.FC<IControlledField> = ({
  label,
  controller,
  inputName,
  interaction,
}) => {
  // HOOKS
  const { name, onChange, ref, value } = controller;
  const { setValue } = useFormContext();

  // EFFECTS
  useReferenceTypeProperty(interaction, (val) => {
    setValue(inputName, val);
  });

  // RENDER
  return (
    <Slider
      {...{ label, ref, value, onChange }}
      min={0}
      max={100}
      step={1}
      defaultValue={value}
      data-testid={`text-input-${name}`}
    />
  );
};

export default RangeSlider;
