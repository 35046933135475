import { getTheme, ITeachingBubbleStyles, IButtonStyles } from '@fluentui/react';
import { baseColors } from '../../../../theme';

export const teachingStyles = (): Partial<ITeachingBubbleStyles> => {
  const theme = getTheme();

  return {
    root: {
      backgroundColor: `${theme.palette.neutralPrimary} !important`,
      selectors: {
        strong: {
          color: theme.palette.themePrimary,
        },
      },
    },
    closeButton: {
      selectors: {
        '&:hover': {
          backgroundColor: theme.palette.neutralPrimary,
        },
      },
    },
    headline: {
      fontSize: '12px',
    },
    subText: {
      fontSize: '12px',
    },
    footer: {
      fontSize: '12px',
    },
    primaryButton: {
      color: theme.palette.neutralPrimary,
      fontSize: '12px',
      selectors: {
        '&:hover': {
          backgroundColor: baseColors.white,
        },
        '&:active': {
          backgroundColor: baseColors.white,
        },
        '&:focus': {
          backgroundColor: baseColors.white,
        },
      },
    },
    subComponentStyles: {
      callout: {
        root: {
          width: '80%',
          color: baseColors.white,
          selectors: {
            '.ms-Callout-main': {
              backgroundColor: `${theme.palette.neutralPrimary} !important`,
            },
            '.ms-Callout-beak': {
              backgroundColor: `${theme.palette.neutralPrimary} !important`,
            },
          },
        },
      },
    },
  };
};

export const btnStyles = (): IButtonStyles => {
  const theme = getTheme();

  return {
    root: {
      color: theme.palette.neutralPrimary,
      height: '24px',
      selectors: {
        '&:hover': {
          backgroundColor: baseColors.white,
        },
        '&:active': {
          backgroundColor: baseColors.white,
        },
        '&:focus': {
          backgroundColor: baseColors.white,
        },
      },
    },
    label: {
      fontSize: '12px !important',
    },
  };
};
