import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { matchPath, useLocation, useHistory } from 'react-router-dom';

import { getSelectedStreamFiltersList } from '../../../../../../StreamFilters/selectors';
import { removeSelectedFilters } from '../../../../../../StreamFilters/actions';
import { routes } from '../../../../../../../constants/routes';
import { HeaderBarButton } from '../../../../../../../components/ui';

const DeleteButton : React.FC = () => {
  const { t } = useTranslation('headers');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const selectedFilters = useSelector(getSelectedStreamFiltersList);

  const isDisabled = !selectedFilters || !selectedFilters.length;

  const isCreateRoute = !!matchPath(pathname, {
    path: [routes.streams.filters.create],
    exact: false,
  });

  const handleClick = () => {
    if (isCreateRoute) {
      history.push(routes.streams.filters.index);
    } else {
      dispatch(removeSelectedFilters());
    }
  };

  return (
    <HeaderBarButton
      tooltip={(
        <Trans
          t={t}
          i18nKey="actionBar:filters.delete"
        />
      )}
      onClick={handleClick}
      iconProps={{ iconName: 'Delete' }}
      data-testid="delete-filter-top-menu"
      disabled={isDisabled}
      shouldHideOnDisabled
    />
  );
};

export default DeleteButton;
