import * as effects from 'redux-saga/effects';

import checkCurrentSelection from '../../../actions/excel-actions/checkCurrentSelection';
import * as StreamsActions from '../actions';
import { setDrillableSelection } from '../../GroupTable/actions';
import { setCheckingCurrentSelection } from '../actions';
import { setCurrentStreamId } from './setCurrentStreamId';
import selectors from '../selectors';
import clearUserFlow from './clearUserFlow';
import { checkIsDrillableSelection } from './checkDrillableSelection';
import { IDataset } from '../../../types/IDataset';
import { ObjectTypes } from '../../App/types';

export function* checkWorkbookSelection() {
  const {
    datasets,
    syncing,
  } = selectors.getStreamsState(
    yield effects.select(),
  );

  // --- If app is currently syncing, wait until it finishes

  if (syncing) {
    yield effects.take((action) => (
      action.type === 'SET_SYNCING'
      && action.syncing === false
    ));
  }

  // ---

  yield effects.put(setCheckingCurrentSelection(true));

  const {
    currentDataset,
    address,
  } : {
    currentDataset: IDataset,
    address: string,
  } = yield effects.call(checkCurrentSelection, datasets);

  yield effects.put({
    type: 'SET_CURRENT_DATASET_ID',
    id: currentDataset?.id || null,
  });

  yield effects.call(setCurrentStreamId, currentDataset?.streamId || null);

  // If the current selection belongs to a dataset, check if it is a drillable selection.
  if (currentDataset && currentDataset.type === ObjectTypes.GROUP_TABLE) {
    yield effects.call(checkIsDrillableSelection, address, currentDataset);
  } else {
    yield effects.put(setDrillableSelection(null));
    // handle resetting TEMP_DATASET
    yield effects.call(clearUserFlow);
  }

  yield effects.put(setCheckingCurrentSelection(false));
  yield effects.put(StreamsActions.actionCreators.checkCurrentSelectionFinish());
}

export default function* rootSaga() {
  yield effects.takeLatest(
    StreamsActions.checkWorkbookSelection.type,
    checkWorkbookSelection,
  );
}
