import React from 'react';

export const useSetSafeTimeout = () => {
  // REFS
  const timeoutRefs = React.useRef<Set<NodeJS.Timeout>>(new Set());

  // CALLBACKS
  const setSafeTimeout = React.useCallback(
    <TArgs extends any[]>(
      callback: (...args: TArgs) => void,
      ms?: number,
      ...args: TArgs
    ): NodeJS.Timeout => {
      const handle = setTimeout(() => {
        callback(...args);
        timeoutRefs.current?.delete(handle);
      }, ms);

      timeoutRefs.current?.add(handle);

      return handle;
    },
    []
  );

  // EFFECTS
  const onCleanup = React.useCallback(() => {
    timeoutRefs.current?.forEach((handle) => clearTimeout(handle));
  }, []);
  React.useEffect(() => onCleanup, [onCleanup]);

  // RESULT
  return setSafeTimeout;
};
