import { createReducer } from '@reduxjs/toolkit';
import { clone, get, setWith, unionWith } from 'lodash';
import * as formActions from './actions';
import { generateTypedEntititesTaskId } from './components/SelectOneOfType/utils';
import { ENTITY_CHILDREN_KEY } from './constants';
import { IEntityForm, IGenericEntity, IGenericEntityAsset } from './types';

export interface EntityFormState {
  forms: IEntityForm[];
  currentPageIndex: number;
  previousPageIndex: number;
  // Entities that make up the main entity.
  entities: {
    records: (IGenericEntity | IGenericEntityAsset)[];
    isLoading: boolean;
  };
  // Entities used by fields within the wizard.
  typedEntities: {
    records: { [key: string]: IGenericEntity[] };
    runningTasks: string[];
  };
  isFetchingEntities: boolean;
  isFetchingSections: boolean;
  isSyncing: boolean;
}

export const initialState: EntityFormState = {
  forms: [],
  entities: {
    records: [],
    isLoading: false,
  },
  typedEntities: {
    records: {},
    runningTasks: [],
  },
  currentPageIndex: 0,
  previousPageIndex: 0,
  isFetchingEntities: false,
  isFetchingSections: false,
  isSyncing: false,
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(formActions.setEntityFormSyncing, (state, { payload }) => ({
      ...state,
      isSyncing: payload,
    }))
    .addCase(formActions.addEntityForm, (state, { payload }) => ({
      ...state,
      forms: [...state.forms, payload],
    }))
    .addCase(
      formActions.removeEntityForm,
      (state, { payload: { typeId } }) => ({
        ...state,
        forms: state.forms.filter((form) => form.typeId !== typeId),
      })
    )
    .addCase(formActions.setCurrentPageIndex, (state, action) => ({
      ...state,
      currentPageIndex: action.payload,
      previousPageIndex: state.currentPageIndex,
    }))
    .addCase(formActions.fetchEntities.pending, (state) => ({
      ...state,
      entities: {
        ...state.entities,
        records: [],
        isLoading: true,
      },
    }))
    .addCase(formActions.fetchEntities.fulfilled, (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        records: action.payload || [],
        isLoading: false,
      },
    }))
    .addCase(formActions.fetchEntityFormSections.pending, (state) => ({
      ...state,
      isFetchingSections: true,
    }))
    .addCase(
      formActions.fetchEntityFormSections.fulfilled,
      (state, { payload: { typeId, sections } }) => ({
        ...state,
        isFetchingSections: false,
        forms: state.forms.map((form) =>
          form.typeId === typeId ? { ...form, sections } : form
        ),
      })
    )
    .addCase(
      formActions.setEntityFormData,
      (state, { payload: { typeId, data } }) => ({
        ...state,
        forms: state.forms.map((form) =>
          form.typeId === typeId ? { ...form, data } : form
        ),
      })
    )
    .addCase(
      formActions.setFormParentEntityId,
      (state, { payload: { typeId, parentEntityId } }) => ({
        ...state,
        forms: state.forms.map((form) =>
          form.typeId === typeId ? { ...form, parentEntityId } : form
        ),
      })
    )
    .addCase(
      formActions.setFormEntityId,
      (state, { payload: { typeId, entityId } }) => ({
        ...state,
        forms: state.forms.map((form) =>
          form.typeId === typeId ? { ...form, entityId } : form
        ),
      })
    )
    .addCase(formActions.restartForm, () => initialState)
    .addCase(formActions.setFetchingEntities, (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        isLoading: action.payload,
      },
    }))
    .addCase(
      formActions.addRunningTaskTypedEntities,
      ({ typedEntities: { runningTasks } }, { payload }) => {
        const id = generateTypedEntititesTaskId(payload);
        // eslint-disable-next-line no-param-reassign
        runningTasks.push(id);
      }
    )
    .addCase(
      formActions.removeRunningTaskTypedEntities,
      ({ typedEntities: { runningTasks } }, { payload }) => {
        const id = generateTypedEntititesTaskId(payload);
        // eslint-disable-next-line no-param-reassign
        runningTasks.splice(runningTasks.indexOf(id), 1);
      }
    )
    .addCase(formActions.addEntities, (state, action) => ({
      ...state,
      entities: {
        isLoading: false,
        records: unionWith(
          action.payload,
          state.entities.records,
          (a, b) => a.id === b.id
        ),
      },
    }))
    .addCase(formActions.addTypedEntities, (state, { payload }) => ({
      ...state,
      typedEntities: {
        ...state.typedEntities,
        records: {
          ...state.typedEntities.records,
          [payload.typeId]: unionWith(
            payload.entities,
            state.typedEntities.records[payload.typeId],
            (a, b) => a.id === b.id
          ),
        },
      },
    }))
    .addCase(
      formActions.setEntityFormChildren,
      (state, { payload: { children, typeId, formTypeId } }) => {
        const formIndex = state.forms.findIndex(
          (form) => form.typeId === formTypeId
        );
        return setWith(
          clone(state),
          `forms[${formIndex}].data.${ENTITY_CHILDREN_KEY}.${typeId}`,
          children,
          clone
        );
      }
    )
    .addCase(
      formActions.addEntityFormChildren,
      (state, { payload: { children, typeId, formTypeId } }) => {
        const formIndex = state.forms.findIndex(
          (form) => form.typeId === formTypeId
        );
        const path = `forms[${formIndex}].data.${ENTITY_CHILDREN_KEY}.${typeId}`;
        const currentChildren = get(state, path) || [];
        return setWith(
          clone(state),
          path,
          [...currentChildren, ...children],
          clone
        );
      }
    )
);

export default reducer;
