import React, { ReactElement, Ref } from 'react';
import ReactSelect, {
  GroupTypeBase,
  NamedProps,
  OptionTypeBase,
} from 'react-select';
import { useTranslation } from 'react-i18next';
import {
  getCustomTheme,
  customStyles,
  IndicatorSeparator,
  DropdownIndicator,
  ControlComponent,
  LoadingIndicator,
  Option,
  SingleValue,
} from './customization';
import './styles.css';

export interface CustomProps {
  label?: string;
}

const SelectDropdown = <
  OptionType extends OptionTypeBase = { label: string; value: string },
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
  props: NamedProps<OptionType, IsMulti, GroupType> & CustomProps,
  ref: Ref<any>
) => {
  // HOOKS
  const { t } = useTranslation();

  // RENDER
  return (
    <ReactSelect
      ref={ref}
      components={{
        Control: ControlComponent,
        IndicatorSeparator,
        DropdownIndicator,
        LoadingIndicator,
        Option,
        SingleValue,
      }}
      theme={getCustomTheme}
      styles={customStyles}
      menuPlacement="auto"
      placeholder={t('filters:Select')}
      maxMenuHeight={200}
      {...props}
    />
  );
};

/**
 * Fix for react-select inability to infer OptionType when passing grouped options
 */

type ActualOptionType<T extends OptionTypeBase> =
  T extends GroupTypeBase<OptionTypeBase> ? T['options'][number] : T;

// Cast Dropdown as forward-ref

export default React.forwardRef(SelectDropdown) as <
  OptionType extends OptionTypeBase = { label: string; value: string },
  IsMulti extends boolean = false
>(
  props: NamedProps<ActualOptionType<OptionType>, IsMulti> &
    CustomProps & { ref?: Ref<any> }
) => ReactElement;
