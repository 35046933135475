import { getTheme } from '@fluentui/react';
import { baseColors } from '../../../theme';

export const valueStyles = (isSelected) => {
  const theme = getTheme();

  return {
    root: {
      color: theme.palette.neutralPrimary,
      backgroundColor: isSelected ? theme.palette.neutralLight : baseColors.white,
      marginLeft: '32px',
      fontSize: '12px',
      lineHeight: '28px',
      selectors: {
        '&:hover': {
          backgroundColor: baseColors.hover,
        },
      },
    },
  };
};
