import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupCollapse } from '../../../../../components/ui';
import { DataSourceEntity } from '../../../../../api/model/schemas/DataSourceEntity';

export const calculateGroupId = (
  fromId: DataSourceEntity['id'],
  toId: DataSourceEntity['id']
) => `${fromId}--${toId}` as const;

export interface Props {
  children: React.ReactNode;
  from: DataSourceEntity;
  openGroupId: string | null;
  setOpenGroupId: React.Dispatch<React.SetStateAction<string | null>>;
  to: DataSourceEntity;
  isSelected?: boolean;
  isOrange?: boolean;
  isSyncing?: boolean;
  searchQuery?: string;
  groupName?: string;
}

export const LinkGroup = ({
  children,
  from,
  isOrange,
  isSelected,
  isSyncing,
  groupName,
  openGroupId,
  searchQuery,
  setOpenGroupId,
  to,
}: Props) => {
  // HOOKS
  const { t } = useTranslation();

  // DERIVED STATE
  const groupId = calculateGroupId(from.id, to.id);
  const isOpen = openGroupId === groupId || (searchQuery && searchQuery !== '');

  // CALLBACKS
  const onClick = React.useCallback(() => {
    setOpenGroupId(isOpen ? null : groupId);
  }, [groupId, isOpen, setOpenGroupId]);

  // RENDER
  return (
    <GroupCollapse
      {...{
        groupId,
        isOpen,
        onClick,
        isSelected,
        isOrange,
        isSyncing,
        searchQuery,
      }}
      key={groupId}
      // https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/8232
      // This task introduces name on Link entity, I am leaving this fallback mechanism just in case
      groupName={groupName ?? t('modules:designer.sections.ingest.modes.links.groupName', {
        fromName: from.name,
        toName: to.name,
      })}
      data-testid={`link-group-collapse-from-${from.name}-to-${to.name}`}
    >
      {children}
    </GroupCollapse>
  );
};
