import { Configuration, ILoggerCallback, LogLevel } from '@azure/msal-browser';
import { ClientAuthErrorMessage } from '@azure/msal-common';

import getAddinLoginUrl from '../../utils/getAddinLoginUrl';
import { DeepPartial } from '../../../shared/types';

export const REDIRECT_URI = getAddinLoginUrl();

export const AUTH_LOCAL_STORAGE_KEY = 'auth';
export const AUTH_CONFIG_LOCAL_STORAGE_KEY = 'config';
export const AUTH_STATE_LOCAL_STORAGE_KEY = 'state';

export const msalConfigBase: DeepPartial<Configuration> = {
  auth: {
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: `${REDIRECT_URI}?logout`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

// NOTE: add a verbose logger if `MSAL_AUTH_DEBUG` env is set
if (process.env.MSAL_AUTH_DEBUG) {
  const loggerCallback: ILoggerCallback = (_logLevel, message, _containsPii) => {
    console.debug(message);
  };

  msalConfigBase.system = {
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Verbose,
    },
  };
}

export const whitelistedErrorCodes: string[] = [
  ClientAuthErrorMessage.noTokensFoundError.code,
];

export const AUTH_DIALOG_URL = getAddinLoginUrl();

export const AUTH_DIALOG_OPTIONS: Office.DialogOptions = Object.freeze({
  promptBeforeOpen: false,
});
