import React from 'react';
import { useSelector } from 'react-redux';

import streamSelectors from '../../../Streams/selectors';
import { actionCreators as streamActions } from '../../../Streams/actions';

import useActions from '../../../../utils/useActions';
import { IColumnWithUuid } from './types';
import { generateColumnUuid } from '../../utils';
import { generateDefaultSortOrder } from '../../../Streams/utils';
import ReorderDragAndDrop from './components/ReorderDragAndDrop/ReorderDragAndDrop';

const ColumnsSortOrder: React.FC = () => {
  // DEPS
  const dragDropId = 'column-sort-order';

  // STATE
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  // HOOKS
  const { setColumnsOfDataset } = useActions(streamActions);

  // DERIVED STATE
  const columns = currentDataset?.columns || [];

  const initialColumnsSortOrder = React.useMemo(
    () =>
      currentDataset?.lastSuccessfulMapping?.columns?.reduce<
        Record<string, number>
      >(
        (prev, cur, idx) => ({
          ...prev,
          [generateColumnUuid(cur)]:
            cur?.sortConfig?.sortPosition ||
            generateDefaultSortOrder({
              columnsLength:
                currentDataset?.lastSuccessfulMapping?.columns?.length,
              indexOfColumn: idx,
            }),
        }),
        {}
      ),
    [currentDataset?.lastSuccessfulMapping?.columns]
  );

  const items = React.useMemo(
    () =>
      columns
        .map((column) => ({
          ...column,
          uuid: generateColumnUuid(column),
        }))
        .sort(
          (colA, colB) =>
            colA.sortConfig?.sortPosition - colB.sortConfig?.sortPosition
        ),
    [columns]
  );

  // CALLBACKS
  const checkIfOrderChanged = React.useCallback(
    (item: IColumnWithUuid, currentItemIndex: number) => {
      if (item?.sortConfig?.sortPosition) {
        return (
          initialColumnsSortOrder[item.uuid] !== item?.sortConfig?.sortPosition
        );
      }
      return (
        initialColumnsSortOrder[item.uuid] !==
        generateDefaultSortOrder({
          columnsLength: currentDataset?.columns?.length,
          indexOfColumn: currentItemIndex,
        })
      );
    },
    [initialColumnsSortOrder, currentDataset?.columns]
  );
  const updateDatasetColumns = React.useCallback(
    (newColumns: IColumnWithUuid[]) => {
      if (!currentDataset) return;

      const columnsWithUpdatedSortPosition = currentDataset.columns.map(
        (col) => ({
          ...col,
          sortConfig: {
            ...col?.sortConfig,
            sortPosition: generateDefaultSortOrder({
              columnsLength: newColumns.length,
              indexOfColumn: newColumns.findIndex(
                (colWithUpdatedSortPosition) =>
                  colWithUpdatedSortPosition.id === col.id
              ),
            }),
          },
        })
      );

      setColumnsOfDataset(currentDataset.id, columnsWithUpdatedSortPosition);
    },
    [currentDataset?.columns]
  );

  // RENDER
  return (
    <ReorderDragAndDrop
      {...{
        checkIfOrderChanged,
        dragDropId,
        updateDatasetColumns,
        items,
      }}
    />
  );
};

export default ColumnsSortOrder;
