import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataSourceEntity } from '../../../../api/model/schemas/DataSourceEntity';
import { writeToDatabase } from '../../actions';
import { actionCreators } from '../../../Streams/actions';

export enum Strategy {
  ISOLATED = 'ISOLATED_STREAMER',
  OLD_STREAMER = 'OLD_STREAMER',
}

const ACTIONS: Record<Strategy, (entity: DataSourceEntity) => any> = {
  [Strategy.ISOLATED]: writeToDatabase,
  [Strategy.OLD_STREAMER]: actionCreators.writeToDbOldStreamer,
};

interface UseStreamToDatabase {
  strategy: Strategy;
  endCallback: () => void;
}

export const useStreamToDatabase = ({
  strategy,
  endCallback,
}: UseStreamToDatabase) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntity, setSelectedEntity] =
    React.useState<DataSourceEntity | null>(null);
  const dispatch = useDispatch();

  const handleApprove = async () => {
    setIsLoading(true);
    const actionToDispatch = ACTIONS[strategy];
    try {
      await dispatch(actionToDispatch(selectedEntity));
    } finally {
      setIsLoading(false);
      endCallback();
    }
  };

  return {
    selectedEntity,
    setSelectedEntity,
    handleApprove,
    isLoading,
  };
};
