import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import InformationDialog from '../../../../components/ui/InformationDialog';
import { addStreamFilter, editStreamFilter } from '../../actions';
import { IStreamFilter } from '../../../../types/IStreamFilter';
import FilterRule from '../FilterRule';
import { routes } from '../../../../constants/routes';
import { getSelectedStreamFiltersList } from '../../selectors';

import RangeInputSection from './components/RangeInputSection';
import ColumnSelect from './components/ColumnSelect';
import ComparisonSelect from './components/ComparisonSelect';
import ComparisonValue from './components/ComparisonValue';
import ValuesSelect from './components/ValuesSelect';

const formDefault : IStreamFilter = {
  comparison: null,
  values: [],
  lowerBoundValue: '',
  upperBoundValue: '',
  id: null,
  column: null,
  aggregation: null,
};

interface Props {
  openGroup(id: string): void;
}

const FilterForm: React.FC<Props> = ({ openGroup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formMethods = useForm<IStreamFilter>({
    defaultValues: formDefault,
    mode: 'onChange',
  });
  const {
    handleSubmit, watch, reset, formState, register,
  } = formMethods;

  const selectedFilters = useSelector(getSelectedStreamFiltersList);
  const { pathname } = useLocation();
  const history = useHistory();

  const isEditing = pathname === routes.streams.filters.edit;

  useEffect(() => {
    if (isEditing) {
      reset(selectedFilters[0]);
    }
  }, [pathname]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      history.push(routes.streams.filters.index);
    }
  }, [formState]);

  const watchedValues = watch([
    'comparison', 'column', 'compareValue', 'lowerBoundValue', 'upperBoundValue', 'values', 'aggregation',
  ]);

  const { comparison, column, compareValue } = watchedValues;

  const goBackToFilters = () => {
    history.push(routes.streams.filters.index);
  };

  const onSubmit = async (values: IStreamFilter) => {
    if (values.id) {
      dispatch(editStreamFilter({
        ...selectedFilters[0],
        ...values,
        column: {
          ...column,
          aggregation: values.aggregation,
        },
      }));
    } else {
      const id = uuidv4();

      dispatch(addStreamFilter({
        ...values,
        column: {
          ...column,
          aggregation: values.aggregation,
        },
        id,
      }));

      openGroup(id);
    }

    goBackToFilters();
  };

  const handleDismiss = () => {
    handleSubmit(onSubmit)();
  };

  const isEditScreen = pathname === routes.streams.filters.edit;

  const calloutTarget = !isEditScreen ? 'newFilter' : `filter-${[selectedFilters[0].id]}`;

  const target = `[data-filterid="${calloutTarget}"]`;
  // @ts-ignore
  const calloutWidth = document.querySelector('[data-testid="search-results-box"]')?.offsetWidth - 32;

  return (
    <div>
      { !isEditScreen && (
        <FilterRule
          filter={watchedValues}
          onClick={() => {}}
          isSelected
          isOpen
          onOpenGroup={() => {}}
        />
      )}
      <InformationDialog
        target={target}
        onDismiss={handleDismiss}
        onClose={goBackToFilters}
        onApprove={formState.isValid ? handleDismiss : null}
        calloutWidth={calloutWidth}
        isCalloutVisible
        labelId="newFilter"
        descriptionId="newFilterDescription"
        title={t('filters:Filter')}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} data-testid="new-filter-form">
            <ColumnSelect isEditing={isEditing} />

            <ComparisonSelect column={column} />

            <ComparisonValue comparison={comparison} column={column} compareValue={compareValue} />

            <RangeInputSection comparison={comparison} column={column} />

            <ValuesSelect comparison={comparison} column={column} />

            <input type="hidden" name="id" ref={register} />
          </form>
        </FormProvider>
      </InformationDialog>
    </div>
  );
};

export default FilterForm;
