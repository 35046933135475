import React from 'react';
import {
  IconButton,
  getTheme,
  Text,
} from '@fluentui/react';
import { buttonStyles, outerStyles, textStyles } from './styles';

interface Props {
  isActive?: boolean;
  iconName: string;
  onClick():void;
  isDisabled?: boolean;
  description: string;
}

const RoleButton: React.FC<Props> = ({
  isActive = false, iconName, onClick, isDisabled = false, description,
}) => {
  const theme = getTheme();
  const btnStyles = buttonStyles(theme, isActive);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={outerStyles(isActive)}>
        <IconButton
          styles={btnStyles}
          iconProps={{ iconName }}
          onClick={onClick}
          disabled={isDisabled}
        />
      </div>
      <Text styles={textStyles(isActive)}>
        { description }
      </Text>
    </div>
  );
};
export default RoleButton;
