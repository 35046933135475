import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AGGREGATION_METHODS, getAggregations, NUMERIC_AGGREGATION_METHODS } from '../../../../../constants/aggregations';
import { IColumn, IColumnAggregationMethods } from '../../../../../types/IColumn';
import { aggregationsToMenuItems, getAlreadyUsedAggregations, getInvalidAggregationMethods } from '../../../../../utils/itemsAggregation';
import { editMatrixItemAggregationIntent } from '../../../actions';
import { SectionType } from '../../../types';
import streamSelectors from '../../../../Streams/selectors';
import { SECTION_TYPES } from '../../../constants';
import ButtonMenu from '../../../../../components/ui/ButtonMenu';

interface Props {
  syncedItem: IColumn
  isItemQueued: boolean
  sectionType: SectionType
}

const SingleItemAggregationButton = ({ syncedItem, sectionType }: Props) => {
  const AGGREGATIONS = getAggregations();
  const dispatch = useDispatch();
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  const isRowsOrColumns = sectionType === SECTION_TYPES.rows || sectionType === SECTION_TYPES.columns;
  const additionalInvalidMethodsInValuesSection = React.useMemo(() => {
    // if item was already aggregated then in values we cannot apply another aggregation
    if (!syncedItem.isAggregatingValuesAllowed) return NUMERIC_AGGREGATION_METHODS;

    // disallow do not summarize
    return [AGGREGATION_METHODS.null];
  }, [syncedItem]);

  const invalidAggregations = isRowsOrColumns ? NUMERIC_AGGREGATION_METHODS : getInvalidAggregationMethods(syncedItem, additionalInvalidMethodsInValuesSection);
  const alreadyUsedAggregations = getAlreadyUsedAggregations(currentDataset.columns, syncedItem);

  const aggregationsToOmit = [
    ...invalidAggregations,
    ...alreadyUsedAggregations]
    // we have to show current aggregation on the list
    // to display "checkmark"
    .filter((agg) => agg !== syncedItem.aggregation);

  const menuItems = aggregationsToMenuItems({
    aggregationsToOmit,
    getIsChecked: (currentAgg) => currentAgg === syncedItem.aggregation,
    isAggregatingValuesAllowed: syncedItem.isAggregatingValuesAllowed,
  });

  const handleClick = (key: string) => {
    // you cannot deselect aggregation in Matrix table layout
    // only change
    if (key === syncedItem.aggregation) return;

    dispatch(editMatrixItemAggregationIntent({
      column: syncedItem,
      aggregation: key as IColumnAggregationMethods,
      sectionType,
    }));
  };

  return (
    <ButtonMenu
      onChangeItem={handleClick}
      activeItemKey={syncedItem.aggregation}
      items={menuItems}
      defaultIconProps={{
        iconName: AGGREGATIONS[syncedItem.aggregation].icon,
      }}
    />
  );
};

export default SingleItemAggregationButton;
