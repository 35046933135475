import { AssetProcessStatus } from '../../../../api/model/AssetProcessStatus';
import { ISingleLargeThumbnail } from '../../../../pageTemplates/LargeThumbnailPicker/LargeThumbnailPicker';
import { IStream } from '../../../Designer/types';

const isStreamUnprocessed = (stream: IStream) =>
  stream?.processStatus === AssetProcessStatus.NotProcessed;

const isStreamProcessing = (stream: IStream) =>
  stream?.processStatus === AssetProcessStatus.Processing;

export const streamToImageThumbnail = (
  stream: IStream | null
): ISingleLargeThumbnail => ({
  iconName: stream?.icon ? stream.icon : 'Database',
  id: stream?.id,
  name: stream?.name,
  hasRedBorder: isStreamUnprocessed(stream),
  isSyncing: isStreamProcessing(stream),
  itemTestId: `stream-tile-${stream?.name}`,
});
