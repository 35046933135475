import { Stack } from '@fluentui/react';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDataSources,
  getSelectedSourceId,
} from '../../../Catalog/selectors';
import { fetchIngestedOrPendingDataSourceFields } from '../../../Ingestion/actions';
import {
  getDataSourceEntities,
  getDataSourceFields,
} from '../../../Ingestion/selectors';
import LinkField from './LinkField';
import { LinkFields } from './types';
import {
  filterSelectedEntity,
  generateEntitiesWithFields,
  getCurrentEntity,
  getDataType,
  getValidEntitiesAndFields,
} from './utils';

interface Props {
  linkFields: LinkFields;
  onLinkFieldsChange: (linkFields: LinkFields) => void;
  excludedFields: {
    from: string[];
    to: string[];
  };
}
const LinkPicker: React.FC<Props> = ({
  linkFields,
  onLinkFieldsChange,
  excludedFields = { from: [], to: [] },
}) => {
  const dispatch = useDispatch();
  // links were moved to catalog, so that's why we use this selector
  const { records: sources } = useSelector(getDataSources);
  const sourceId = useSelector(getSelectedSourceId);
  // we are reusing ingestion store to fetch fields
  const srcEntities = useSelector(getDataSourceEntities);
  const srcFields = useSelector(getDataSourceFields);

  const { fromId, toId } = linkFields;

  useEffect(() => {
    if (!srcEntities?.length || !srcFields?.length) {
      dispatch(fetchIngestedOrPendingDataSourceFields(sourceId));
    }
  }, [sourceId, srcEntities, srcFields]);

  const entities = useMemo(
    () => generateEntitiesWithFields(srcEntities, srcFields),
    [srcEntities, srcFields]
  );

  const enforcedEntities = useMemo(
    () => ({
      from: getCurrentEntity(entities, excludedFields?.from?.[0])?.id,
      to: getCurrentEntity(entities, excludedFields?.to?.[0])?.id,
    }),
    [excludedFields, entities]
  );

  const fromEntities = useMemo(
    () =>
      getValidEntitiesAndFields(
        entities,
        enforcedEntities.from,
        excludedFields.from
      ),
    [enforcedEntities.from, excludedFields.from, entities]
  );

  const toEntities = useMemo(
    () =>
      getValidEntitiesAndFields(
        filterSelectedEntity(entities, fromId),
        enforcedEntities.to,
        excludedFields.to
      ),
    [enforcedEntities.to, excludedFields.to, entities, fromId]
  );

  const dataType = useMemo(
    () => getDataType(fromId, srcFields),
    [fromId, srcFields]
  );

  return (
    <Stack>
      <LinkField
        data-testid="link-picker-select-from"
        sourceId={sourceId}
        entities={fromEntities}
        sources={sources}
        fieldId={fromId}
        isRequired
        onFieldChange={(id) => {
          onLinkFieldsChange({ ...linkFields, fromId: id, toId: null });
        }}
      />
      {linkFields.fromId && (
        <LinkField
          dataType={dataType}
          data-testid="link-picker-select-to"
          sourceId={sourceId}
          entities={toEntities}
          sources={sources}
          fieldId={toId}
          isRequired
          onFieldChange={(id) => {
            onLinkFieldsChange({ ...linkFields, toId: id });
          }}
        />
      )}
    </Stack>
  );
};

export default React.memo(LinkPicker, isEqual);
