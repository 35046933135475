import { AnyAction } from 'redux';

import { getSessionId } from '../../modules/App/sessionId';
import * as persistenceActions from './actions';
import { SYNC_IN_PROGRESS_KEY } from './constants';

const setBlockingSessionId = (sessionId: string | null) => {
  if (!window.Office) return;

  Office.context.document.settings.set(SYNC_IN_PROGRESS_KEY, sessionId);
  Office.context.document.settings.saveAsync({ overwriteIfStale: true }, (asyncResult) => {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      // TO DO - handle
      // eslint-disable-next-line no-console
      console.error(`Settings save failed. Error: ${asyncResult.error.message}`);
    }
  });
};

export const handleUiBlockWhileCoauthoring = (action: AnyAction) => {
  if (action.type === persistenceActions.blockUiOfOtherUsers().type) {
    setBlockingSessionId(getSessionId());
  }

  if (action.type === persistenceActions.syncPersistedData().type) {
    setBlockingSessionId(null);
  }
};
