import React, { useState } from 'react';
import { IconButton as Button } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  IGenericFormField,
  Json,
} from '../../types';
import Row from './MultiEnpointSelectorRow';
import MultiSelectorCallout from './MultiEnpointSelectorCallout';
import { getClassNames } from './styles';
import { ENTITY_CHILDREN_KEY } from '../../constants';
import useMultiEntityForm from '../ManageMultiple/hooks/useMultiEntityForm';
import { generateUniqueName } from './utils';
import useManageMultiple from '../ManageMultiple/hooks/useManageMultiple';

export interface IEndpoint extends Json {
  name: string,
  endpoint: string,
  requestMethod: string,
  payload?: string,
}

const defaultValues : IEndpoint = {
  name: 'New',
  endpoint: 'data',
  requestMethod: 'Get',
};

const MultiSelector : React.FC<IGenericFormField> = (
  {
    interaction, currentEntity, currentForm,
  },
) => {
  const { t } = useTranslation();
  const typeId = interaction.referenceType;
  const typeKey = `${ENTITY_CHILDREN_KEY}.${typeId}`;
  const { schema } = useMultiEntityForm(
    typeId,
    currentEntity?.id,
  );
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState<string>(null);
  const [lastAddedId, setLastAddedId] = useState<string>(null);
  const {
    items, remove, append, edit,
  } = useManageMultiple<IEndpoint>({
    typeId,
    currentEntityForm: currentForm,
    interaction,
    defaultValues,
  });

  const selectedField = items.find((item) => item.id === selectedFieldId);

  const handleAdd = async () => {
    const allNames = items?.map((currentfield) => currentfield.data?.name || '') || [];

    const newName = generateUniqueName('New', allNames);
    const data = { name: newName, endpoint: 'data', requestMethod: 'Get' };
    const id = await append(data);
    setSelectedFieldId(id);
    setLastAddedId(id);
  };

  const classNames = getClassNames(null);

  const handleDismissCallout = () => {
    setSelectedFieldId(null);
    setIsCalloutVisible(false);
  };

  const handleRemove = async (id: string) => {
    await remove(id);
    setSelectedFieldId(null);
  };

  const handleOpenCallout = (id: string) => {
    setSelectedFieldId(id);
    setIsCalloutVisible(true);
  };

  return (
    <>
      <div className={classNames.grid}>
        <div className={classNames.row}>
          <div className={classNames.headers}>{t('wizard:Endpoint')}</div>
          <div className={classNames.headers}>{t('wizard:Parameters')}</div>
          <div />
        </div>
        {
        items.map((field, index) => (
          <Row
            {...{
              currentForm,
              schema,
              typeId,
            }}
            setSelectedItemId={setSelectedFieldId}
            canBeDeleted={index > 0}
            initialEditing={lastAddedId === field?.id}
            id={field?.id}
            remove={handleRemove}
            items={items}
            parentEntityId={currentEntity?.id}
            key={field?.id}
            isSelected={field.id === selectedFieldId}
            hasOpenCallout={field.id === selectedFieldId && isCalloutVisible}
            handleClick={() => setSelectedFieldId(field.id)}
            handleEditItem={edit}
            openCallout={() => handleOpenCallout(field?.id)}

          />
        ))
      }
      </div>
      <Button
        iconProps={{ iconName: 'Add' }}
        onClick={handleAdd}
        className={classNames.btn}
      />

      <MultiSelectorCallout
        {...{
          schema,
          typeKey,
        }}
        item={selectedField}
        handleEditItem={edit}
        calloutProps={{
          target: `[data-field-id="${selectedFieldId}"]`,
          onDismiss: handleDismissCallout,
          onClose: handleDismissCallout,
          isCalloutVisible,
          calloutWidth: 265,
        }}
      />
    </>
  );
};

export default MultiSelector;
