import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IColumn, IColumnAggregationMethods } from '../../../../../../../types/IColumn';
import { aggregationsToMenuItems, getAlreadyUsedAggregationsFiltered, getInvalidAggregationMethods } from '../../../../../../../utils/itemsAggregation';
import { editTableItemAggregation } from '../../../../../actions';
import streamSelectors from '../../../../../../Streams/selectors';
import ButtonMenu from '../../../../../../../components/ui/ButtonMenu';

interface Props {
  syncedItem: IColumn
  onClick?(): void;
}

const SingleColumnAggregationButton = ({ syncedItem, onClick }: Props) => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  // DERIVED STATE
  const invalidAggregationsByItemType = getInvalidAggregationMethods(syncedItem);
  const alreadyUsedAggregations = getAlreadyUsedAggregationsFiltered(
    currentDataset.columns, syncedItem,
  );

  const aggregationsToOmit = [...invalidAggregationsByItemType, ...alreadyUsedAggregations];

  const menuItems = aggregationsToMenuItems({
    aggregationsToOmit,
    getIsChecked: (currentAgg) => currentAgg === syncedItem.aggregation,
    isAggregatingValuesAllowed: syncedItem.isAggregatingValuesAllowed,
  });

  const isOrange = React.useMemo(() => {
    const lastSuccessfullyMappedColumns = currentDataset?.lastSuccessfulMapping?.columns || [];
    const allColumnAggregationsFromPreviousMapping = lastSuccessfullyMappedColumns
      .filter((col) => col.id === syncedItem.id)
      .map((col) => col.aggregation);

    return !allColumnAggregationsFromPreviousMapping.some((aggregation) => aggregation === syncedItem.aggregation);
  }, [syncedItem, currentDataset?.lastSuccessfulMapping?.columns]);

  // CALLBACKS
  const handleClick = (item: string) => {
    dispatch(editTableItemAggregation({
      column: syncedItem,
      aggregation: item as IColumnAggregationMethods,
    }));
  };

  // RENDER
  return (
    <ButtonMenu
      onChangeItem={handleClick}
      activeItemKey={syncedItem.aggregation}
      items={menuItems}
      onMenuOpened={onClick}
      isOrange={isOrange}
    />
  );
};

export default SingleColumnAggregationButton;
