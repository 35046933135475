import { IStackStyles, mergeStyleSets } from '@fluentui/react';

export const viewControlContainerStyles: IStackStyles = {
  root: {
    position: 'relative',
    flex: 1,
    justifyContent: 'flex-end',
  },
};

export const classNames = mergeStyleSets({
  sortControlWrapper: {
    top: '-4px',
  },
});
