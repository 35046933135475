import React from 'react';
import { useSelector } from 'react-redux';
import { getSearchQuery } from '../modules/Search/selectors';
import { matchesSearchQuery } from '../modules/Search/utils';

interface HasIdAndName {
  name: string;
  id: string;
}

export function useSearchFilteredRecords<T extends HasIdAndName>(records: T[]) {
  const searchQuery = useSelector(getSearchQuery);

  const filteredRecords = React.useMemo(
    () => (searchQuery === ''
      ? records
      : records.filter((record) => matchesSearchQuery(searchQuery, record.name))),
    [records, searchQuery],
  );

  return {
    filteredRecords,
    searchQuery,
  };
}
