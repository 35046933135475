import React from 'react';

import { normalizeById } from '../../../../utils/normalizeEntities';
import { IDesignSourceEntity } from '../../../Designer/types';
import { fetchEntities } from '../../api';

export const useFetchEntities = (dataSourceId: string) => {
  const [normalizedEntities, setEntities] = React.useState<
    Record<string, IDesignSourceEntity>
  >({});
  const [isLoading, setLoading] = React.useState(false);

  const onDataSourceIdChange = React.useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await fetchEntities(dataSourceId)();

      const a = normalizeById(data);
      setEntities(a);
    } finally {
      setLoading(false);
    }
  }, [dataSourceId]);

  React.useEffect(() => {
    onDataSourceIdChange();
  }, [onDataSourceIdChange]);

  return { isLoading, normalizedEntities };
};
