import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MachineLearning } from '../../../../api/model/schemas/MachineLearning';
import { StreamReferenceProcessStatus } from '../../../../api/model/schemas/StreamReferenceProcessStatus';
import EntityField from '../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { GroupCollapse } from '../../../../components/ui';
import {
  IFoldableListGroup,
  IFoldableListItem,
} from '../../../../components/ui/FoldableList/types/IFoldableListGroup';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';
import { useSearchFilteredRecords } from '../../../../hooks/useSearchFilteredRecords';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { DefinedSortTypes } from '../../../App/types';
import {
  assetClicked,
  AssetClickedIntent,
  assetsDataGroupClicked,
  fetchMachineLearning,
  fetchStreamMachineLearning,
} from '../../actions';
import { getMachineLearning, getSelectedStream } from '../../selectors';
import { StreamAssetType } from '../../types';

export const AssetsMachineLearning = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const {
    isLoadingML,
    isLoadingStreamML,
    streamMachineLearning,
    records,
    recordIdsBeingUpdated,
  } = useSelector(getMachineLearning);
  const selectedStream = useSelector(getSelectedStream);

  // DERIVED STATE
  const normalizedRecords = React.useMemo(
    () => normalizeById(records),
    [records]
  );
  const { searchQuery, filteredRecords } = useSearchFilteredRecords(records);

  const selectedMLIds = React.useMemo(
    () => streamMachineLearning.map((f) => f.machineLearningId),
    [streamMachineLearning]
  );

  const sourceFieldsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds: selectedMLIds,
  });

  // EFFECTS
  React.useEffect(() => {
    dispatch(fetchStreamMachineLearning({ streamId: selectedStream.id }));
    dispatch(fetchMachineLearning({ designId: selectedStream.parentId }));
  }, []);

  // CALLBACKS
  const onClickItemHandler = React.useCallback(
    (entity: MachineLearning) => {
      dispatch(
        assetClicked({
          entity,
          actionType: streamMachineLearning.some(
            (streamHub) => streamHub.machineLearningId === entity.id
          )
            ? AssetClickedIntent.REMOVE
            : AssetClickedIntent.ADD,
          assetType: StreamAssetType.MachineLearning,
        })
      );
    },
    [dispatch, streamMachineLearning]
  );

  const handleGroupClick = React.useCallback(
    (group: IFoldableListGroup) => {
      const relatedFields = group.items.reduce<MachineLearning[]>(
        (accumulator, item) => [...accumulator, normalizedRecords[item.key]],
        []
      );

      dispatch(
        assetsDataGroupClicked({
          entities: relatedFields,
          selectedEntitiesIds: selectedMLIds,
          assetType: StreamAssetType.MachineLearning,
        })
      );
    },
    [normalizedRecords, selectedMLIds, dispatch]
  );

  // PARTS
  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({ input: sourceFieldsWhichIncludesSelections });

  const renderMLAsset = (entity: MachineLearning) => {
    if (!entity) return null;

    const relatedStreamML = streamMachineLearning.find(
      // TO DO - verify if this is the right prop
      (streamML) => streamML.machineLearningId === entity.id
    );
    const isQueued =
      relatedStreamML?.processStatus ===
      StreamReferenceProcessStatus.NotProcessed;

    return (
      <EntityField
        key={entity.id}
        isSelectable
        isOrange={isQueued}
        isSelected={!!relatedStreamML}
        slot1={<EntityFieldSlotSpacer />}
        onClick={() => onClickItemHandler(entity)}
        name={entity.name}
        isSyncing={recordIdsBeingUpdated.includes(entity.id)}
        searchQuery={searchQuery}
        availability={entity.availability}
      />
    );
  };

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = normalizedRecords[item.key];

    return renderMLAsset(entry);
  };

  if (isLoadingML || isLoadingStreamML) {
    return <ItemsListShimmer />;
  }

  if (currentSort === DefinedSortTypes.Name) {
    return (
      <>
        {sourceFieldsWhichIncludesSelections
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((field) => renderMLAsset(field))}
      </>
    );
  }

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onIconClick={onClickHeaderHandler}
          onTextClick={() => handleGroupClick(group)}
          isSyncing={group.items.some((item) =>
            recordIdsBeingUpdated.includes(item.key)
          )}
          isSelected={group.items.every((item) =>
            selectedMLIds.includes(item.key)
          )}
          selections={{
            current: selectedMLIds.filter((id) =>
              group.items.map((item) => item.key).includes(id)
            ).length,
            total: group.items.length,
          }}
        >
          {group.items.map((item) => onRenderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};
