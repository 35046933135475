import { AxiosResponse, CancelToken } from 'axios';
import { QueryOptions } from 'odata-query';

import getClient from '../../api/getClient';
import {
  entitiesPath,
  nestedEntitiesPath,
  singleTopLevelEntityPath,
  singleNestedEntityPath,
  childEntityPath,
  typeSectionPath,
  AttachEntityFilePath,
  attachEntityFilePath,
  typesPath,
} from '../../constants/apiPaths';
import {
  SingleNestedEntityPathParams,
  ApiV4PostResponse,
  SingleNestedEntityCreatePathParams,
  ApiV4ResponseWrapper,
  ITypeSectionWithProperties,
} from './types';

export const fetchEntities =
  <T>(schemaId: string) =>
  async (
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<T>> => {
    const client = await getClient();
    return client.get(entitiesPath(schemaId, oDataParams), { cancelToken });
  };

export const fetchEntity =
  <T>(typeId: string, entityId: string) =>
  async (
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<T>> => {
    const client = await getClient();
    return client.get(singleTopLevelEntityPath(typeId, entityId, oDataParams), {
      cancelToken,
    });
  };

export const createEntity =
  <T, R = T>(schemaId: string) =>
  async (
    entity: T,
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<ApiV4PostResponse & R>> => {
    const client = await getClient();
    return client.post(entitiesPath(schemaId, oDataParams), entity, {
      cancelToken,
    });
  };

export const deleteEntity =
  <T>(schemaId: string) =>
  async (
    entityId: string,
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<ApiV4PostResponse & T>> => {
    const client = await getClient();
    return client.delete(
      singleTopLevelEntityPath(schemaId, entityId, oDataParams),
      { cancelToken }
    );
  };

type NestedEntitesSchemas = {
  typeId: string;
};

export const fetchNestedEntities =
  <T>({ typeId }: NestedEntitesSchemas) =>
  (parentEntityId: string) =>
  async (
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<T>> => {
    const client = await getClient();
    return client.get(
      nestedEntitiesPath({
        typeId,
        parentEntityId,
        oDataParams,
      }),
      { cancelToken }
    );
  };

export const deleteNestedEntity =
  <T>(entityParams: SingleNestedEntityPathParams) =>
  async (
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<ApiV4PostResponse & T>> => {
    const client = await getClient();
    const path = singleNestedEntityPath({ ...entityParams, oDataParams });
    return client.delete(path, { cancelToken });
  };

export const patchEntity =
  <T extends { id: string }>(schemaId: string) =>
  async (
    { id, ...entity }: Partial<T>,
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<ApiV4PostResponse & T>> => {
    const client = await getClient();
    return client.patch(
      singleTopLevelEntityPath(schemaId, id, oDataParams),
      entity,
      { cancelToken }
    );
  };

export const createNestedEntity =
  <T>(entityParams: SingleNestedEntityCreatePathParams) =>
  async (
    entity: Partial<T>,
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<ApiV4PostResponse & T>> => {
    const client = await getClient();
    const path = childEntityPath({ ...entityParams, oDataParams });
    return client.post(path, entity, { cancelToken });
  };

// TODO: fix interface
interface FetchTypeSectionsArgs {
  cancelToken?: CancelToken;
  designId?: string;
  oDataParams?: Partial<QueryOptions<unknown>>;
}
export const fetchTypeSections =
  (typeId: string) =>
  async ({
    cancelToken,
    designId,
    oDataParams,
  }: FetchTypeSectionsArgs = {}): Promise<
    ApiV4ResponseWrapper<ITypeSectionWithProperties[]>
  > => {
    const client = await getClient();
    const { data } = await client.get<
      ApiV4ResponseWrapper<ITypeSectionWithProperties[]>
    >(typeSectionPath({ typeId, params: oDataParams, designId }), {
      cancelToken,
    });
    return data;
  };

export interface AttachEntityFile {
  file: File;
  key?: string;
  onUploadProgress?: (event: ProgressEvent) => void;
}

export const attachEntityFile =
  ({ entityId, typeId }: AttachEntityFilePath) =>
  async ({ file, key = 'data', onUploadProgress }: AttachEntityFile) => {
    const client = await getClient();
    const data = new FormData();
    data.append(key, file);

    const config = {
      onUploadProgress,
    };

    return client.patch(
      attachEntityFilePath({ entityId, typeId }),
      data,
      config
    );
  };

export const fetchTypes =
  <T>({ typeId, designId }) =>
  async (
    oDataParams?: Partial<QueryOptions<unknown>>,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse<T>> => {
    const client = await getClient();
    return client.get(typesPath({ typeId, designId, params: oDataParams }), {
      cancelToken,
    });
  };
