import React, { useMemo } from 'react';
import { FontIcon, Stack } from '@fluentui/react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import OutputCard from '../OutputCard';
import { classNames as outputClassNames } from '../../styles';
import { StreamAssetRaw, StreamerFilter } from '../../../../../../types';
import { Stream } from '../../../../../../../../api/model/schemas/Stream';
import { classNames } from './styles';
import FormattedValue from '../FormattedValue';
import {
  getOutputViewType,
  CARD_KEY_PROP,
  getColumnsAssetId,
  getSelectedCardKeys,
} from '../../../../selectors';
import { groupByAssetValue } from '../../utils';
import Column from '../Column';
import { OutputViewType } from '../../../../types';
import SwimlanesTable from '../OutputTable/SwimlanesTable';
import { ExtendedProps as ExtendedCardProps } from '../OutputCard/OutputCard';
import { ValueType } from '../FormattedValue/FormattedValue';

interface Props extends ExtendedCardProps {
  assetId: string;
  assets: StreamAssetRaw[];
  closedColumns: any[];
  columnsHeaders: string[];
  data: { [key: string]: any }[];
  filters: StreamerFilter[];
  isOpen: boolean;
  label: string;
  onColumnHeaderClick: (key: any) => void;
  onHeaderClick: (key: any) => void;
  stream: Stream;
  toggleIsCardOpen: (key: number) => void;
  openedCards: boolean[];
}

const Swimlane: React.FC<Props> = ({
  assetId,
  assets,
  closedColumns,
  columnsHeaders,
  data,
  filters,
  isOpen,
  label,
  onColumnHeaderClick,
  onHeaderClick,
  selectionPreviewsRef,
  stream,
  toggleCard,
  toggleIsCardOpen,
  openedCards,
}) => {
  // STATE
  const columnsId = useSelector(getColumnsAssetId);
  const viewType = useSelector(getOutputViewType);
  const selectedCardKeys = useSelector(getSelectedCardKeys);

  // DERIVED STATE
  const asset = useMemo(
    () => assets.find((current) => current.id === assetId),
    [assetId]
  );
  const columns = useMemo(
    () =>
      groupByAssetValue({
        assetId: columnsId,
        data,
        assets,
      }),
    [assets, data, columnsId]
  );

  if (viewType === OutputViewType.table) {
    return (
      <Stack>
        <Stack
          horizontal
          verticalAlign="center"
          className={classNames.header}
          onClick={() => onHeaderClick(label)}
        >
          <FontIcon iconName={isOpen ? 'ChevronUp' : 'ChevronDown'} />
          <span>
            <FormattedValue
              asset={asset}
              value={label}
              valueType={ValueType.SwimlaneValue}
            />
          </span>
        </Stack>
        <Stack
          horizontal
          className={cn(classNames.columns, classNames.container)}
        >
          {isOpen && <SwimlanesTable {...{ assets, filters, data }} />}
        </Stack>
      </Stack>
    );
  }

  if (columnsId) {
    return (
      <Stack>
        <Stack
          horizontal
          verticalAlign="center"
          className={classNames.header}
          onClick={() => onHeaderClick(label)}
        >
          <FontIcon iconName={isOpen ? 'ChevronUp' : 'ChevronDown'} />
          <span>
            <FormattedValue
              asset={asset}
              value={label}
              valueType={ValueType.SwimlaneValue}
            />
          </span>
        </Stack>
        <Stack horizontal className={classNames.columns}>
          {isOpen &&
            columnsHeaders.map((key) => (
              <Column
                {...{
                  assets,
                  filters,
                  selectionPreviewsRef,
                  toggleCard,
                  openedCards,
                  toggleIsCardOpen,
                }}
                assetId={columnsId}
                key={key}
                label={key}
                data={columns[key]}
                stream={stream}
                isOpen={!new Set(closedColumns).has(key)}
                onHeaderClick={() => onColumnHeaderClick(key)}
              />
            ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack
        horizontal
        verticalAlign="center"
        className={classNames.header}
        onClick={() => onHeaderClick(label)}
      >
        <FontIcon iconName={isOpen ? 'ChevronUp' : 'ChevronDown'} />
        <span>
          <FormattedValue
            asset={asset}
            value={label}
            valueType={ValueType.SwimlaneValue}
          />
        </span>
      </Stack>
      {isOpen && (
        <div className={outputClassNames.background}>
          <div className={outputClassNames.grid}>
            {data.map((entry) => (
              <OutputCard
                {...{
                  assets,
                  entry,
                  filters,
                  selectionPreviewsRef,
                  toggleCard,
                }}
                isCardSelected={selectedCardKeys.includes(entry[CARD_KEY_PROP])}
                key={entry[CARD_KEY_PROP]}
                parentTitle={stream.name}
                toggleIsLocalOpen={() => toggleIsCardOpen(entry[CARD_KEY_PROP])}
                isLocalOpen={openedCards[entry[CARD_KEY_PROP]]}
              />
            ))}
          </div>
        </div>
      )}
    </Stack>
  );
};
export default Swimlane;
