import React, { useEffect, useMemo } from 'react';
import { Stack } from '@fluentui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { Route, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { IDesignerFilter } from '../../../../../../../types/IDesignerFilter';
import FiltersForm, {
  formFilterDefault,
} from '../../../../../../../components/FiltersForm';
import { routes } from '../../../../../../../constants/routes';
import {
  getAllIngestionFilters,
  getDataSourceFields,
  getIsLoadingDataSourceFields,
  getIsLoadingFilters,
  getSingleSelectedSourceId,
  getAllSources,
} from '../../../../selectors';
import { GroupCollapse } from '../../../../../../../components/ui';
import useCollapseGroups from '../../../../../../../hooks/useCollapseGroups';
import FilterCondition from '../../../../../../../components/FilterCondition';
import ItemsListShimmer from '../../../../../../../components/ItemsListShimmer';
import {
  fetchAllFiltersByLogic,
  createEntityFieldIngestionFilterIntent,
  editEntityFieldIngestionFilterIntent,
  fetchIngestedOrPendingDataSourceFields,
} from '../../../../actions';
import { deleteEntityRoute } from '../../../../../../../utils/routes';
import { formatValues } from '../../../../../../../utils/formatFilterValues';
import { compileStreamFiltersToRequest } from '../../../../../../StreamFilters/utils';
import { TYPE_IDS } from '../../../../../../../constants/apiV4TypeIds';
import {
  parseIngestionFiltersToRenderableGroups,
  loadItemOptions,
} from './utils';
import { ENTITY_FIELD_SLOT_WIDTH_IN_PX } from '../../../../../../../constants/design';
// import IngestionFilterForm from '../IngestionFilterForm';
import { adjustComparisonType } from '../../../../../../../utils/adjustFilterComparisonType';
import { useTutorialTile } from '../../../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { getSearchQuery } from '../../../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../../../Search/utils';

const newFilterFormTargetId = 'filter-form-target';

const Logic = () => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const formMethods = useForm<IDesignerFilter>({
    defaultValues: formFilterDefault,
    mode: 'onChange',
  });

  // STATE
  const ingestionFilters = useSelector(getAllIngestionFilters);

  const sources = useSelector(getAllSources);
  const selectedDataSourceId = useSelector(getSingleSelectedSourceId);
  const isLoading = useSelector(getIsLoadingFilters);

  const sourceFields = useSelector(getDataSourceFields);
  const isLoadingFields = useSelector(getIsLoadingDataSourceFields);
  const searchQuery = useSelector(getSearchQuery);

  const [selectedFilterForEdit, setSelectedFilterForEdit] =
    React.useState<IDesignerFilter>(null);

  const filteredFilters = useMemo(
    () =>
      ingestionFilters.filter((f) =>
        matchesSearchQuery(searchQuery, f?.item?.name)
      ),
    [ingestionFilters, searchQuery]
  );

  // DERIVED STATE
  const groupToRender =
    parseIngestionFiltersToRenderableGroups(filteredFilters);
  const isEditing = selectedFilterForEdit !== null;
  const calloutTarget = isEditing
    ? `filter-${[selectedFilterForEdit.id]}`
    : newFilterFormTargetId;
  // @ts-ignore
  const calloutWidth = document.querySelector(`#${calloutTarget}`)?.offsetWidth;

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:ingest:filters:cta:noSourcesAvailable':
        sources.length === 0,
      'tutorialTiles:designer:ingest:filters:cta:noSourcesSelected':
        !selectedDataSourceId,
    }),
    [selectedDataSourceId, sources.length]
  );

  // CALLBACKS
  const { isGroupOpen: isEntityOpen, handleOpenGroup: handleOpenEntity } =
    useCollapseGroups({
      scrollOnOpen: false,
      initiallyOpenFirstGroupId:
        groupToRender.length > 0 ? groupToRender[0].entityName : null,
    });

  const handleCloseForm = () => {
    formMethods.reset(formFilterDefault);
    setSelectedFilterForEdit(null);
    history.push(routes.designer.ingestion.filters.logic.index);
  };

  const handleSubmit = async (values) => {
    const { item, ...rest } = values;

    if (isEditing) {
      dispatch(
        editEntityFieldIngestionFilterIntent({
          fieldId: item.id,
          editedFilter: {
            ...compileStreamFiltersToRequest(rest),
            id: selectedFilterForEdit.id,
          },
        })
      );
    } else {
      dispatch(
        createEntityFieldIngestionFilterIntent({
          fieldId: item.id,
          newFilterPayload: {
            fieldId: item.id,
            filter: {
              ...compileStreamFiltersToRequest(rest),
              id: undefined,
            },
          },
        })
      );
    }

    history.push(routes.designer.ingestion.filters.logic.index);
  };

  const handleDeleteClick = (filter) => {
    history.push(
      deleteEntityRoute(TYPE_IDS.SourceEntityFieldFilter, filter.id),
      {
        goBackPage: routes.designer.ingestion.filters.logic.index,
        deleteDescription: (
          <Trans
            i18nKey="wizard:deleteNestedEntity"
            values={{
              name: `${filter.item?.name} ${t(
                `filters:${filter.comparison}`
              )} ${formatValues(filter)}`,
              asset: t('entityTypes:Filter'),
            }}
          />
        ),
      }
    );
  };

  // We are never asking for values, always typing them
  // This is a "cheat" to not change underlying component, which has quite complex logic
  const loadValuesOptions = async (): Promise<Array<string>> =>
    new Promise((resolve) => resolve([]));

  const handleLoadItemOptions = React.useCallback(
    (inputValue: string) =>
      Promise.resolve(
        loadItemOptions(sourceFields, ingestionFilters)(inputValue)
      ),
    [sourceFields]
  );

  const onStartClick = React.useCallback(() => {
    history.push(routes.designer.ingestion.filters.logic.add);
  }, [history]);

  // EFFECTS
  // we want to refetch filters on every mount,
  // to ensure we get fresh list after deletion
  // which happens under different route
  useEffect(() => {
    if (selectedDataSourceId) {
      dispatch(fetchIngestedOrPendingDataSourceFields(selectedDataSourceId));
      dispatch(fetchAllFiltersByLogic(selectedDataSourceId));
    }
  }, []);

  useEffect(() => {
    if (selectedFilterForEdit) {
      formMethods.reset(adjustComparisonType(selectedFilterForEdit));
      history.push(routes.designer.ingestion.filters.logic.edit);
    }
  }, [selectedFilterForEdit]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: ingestionFilters.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (isLoading || isLoadingFields) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  return (
    <FormProvider {...formMethods}>
      <Stack>
        <Route path={routes.designer.ingestion.filters.logic.add}>
          {/* we need this component to get nice padding
          for the newly created filter from */}
          <div
            id={newFilterFormTargetId}
            style={{
              width: 'calc(100% - 20px)',
              height: '10px',
              margin: 'auto',
            }}
          />
        </Route>
        {groupToRender.map((group) => (
          <GroupCollapse
            key={`entity_${group.entityName}`}
            groupName={group.entityName}
            groupId={group.entityName}
            onClick={(id) => handleOpenEntity(id)}
            isOpen={isEntityOpen(group.entityName)}
            isSelected={false}
          >
            {group.filters.map((filter) => (
              <FilterCondition
                key={filter.id}
                filter={filter}
                onEdit={() => setSelectedFilterForEdit(filter)}
                onDelete={handleDeleteClick}
                spacing={ENTITY_FIELD_SLOT_WIDTH_IN_PX}
                id={`filter-${filter.id}`}
                isEditing={selectedFilterForEdit?.id === filter.id}
                isSelected={selectedFilterForEdit?.id === filter.id}
                searchQuery={searchQuery}
              />
            ))}
          </GroupCollapse>
        ))}

        <Route
          path={[
            routes.designer.ingestion.filters.logic.add,
            routes.designer.ingestion.filters.logic.edit,
          ]}
        >
          <FiltersForm
            hideAggregationPicker
            acceptsTypedValues
            title={t('filters:Filter')}
            loadValuesOptions={loadValuesOptions}
            loadItemOptions={handleLoadItemOptions}
            onClose={handleCloseForm}
            onSubmit={handleSubmit}
            target={`#${calloutTarget}`}
            calloutWidth={calloutWidth}
          />
        </Route>
      </Stack>
    </FormProvider>
  );
};

export default Logic;
