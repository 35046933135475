import { mergeStyleSets } from 'office-ui-fabric-react';
import React from 'react';

const classNames = mergeStyleSets({
  iframeWrapper: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    selectors: {
      iframe: {
        width: '100%',
        height: '100%',
        border: 0,
      },
    },
  },
  container: {
    width: '100%',
    height: 'calc(100% - 20px)',
  },
});

interface Props {
  title: string;
  url: string;
}

export const IframeContent: React.FC<Props> = ({ title, url }) => (
  <div className={classNames.container}>
    <div className={classNames.iframeWrapper}>
      <iframe title={title} src={url} />
    </div>
  </div>
);
