import { Stack } from 'office-ui-fabric-react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';
import { chain } from 'lodash';

import FiltersForm from '../../../../components/FiltersForm';
import { OptionGroup } from '../../../../components/FiltersForm/components/ItemSelect';
import { routes } from '../../../../constants/routes';
import { getAvailableAssets, getSelectedStreamId } from '../../selectors';
import {
  parseAssetsToFilterFormItems,
  shouldEnforceTypedValues,
} from './utils';
import { StreamAssetRaw, StreamerFilter } from '../../types';
import { streamerFilterFormDefault } from '../constants';
import { getAllStreamerFilters } from '../selectors';
import { addFilter, deleteFilter, editFilter } from '../actions';
import useCollapseGroups from '../../../../hooks/useCollapseGroups';
import { AggregationType } from '../../../../api/model/schemas/AggregationType';
import { fetchSingleAssetData, rawAssetToStreamerAsset } from '../../utils';
import { DataType } from '../../../../api/model/schemas/DataType';
import { ColumnFilterType } from '../../../../api/model/schemas/ColumnFilterType';
import { ComparisonType } from '../../../../api/model/schemas/ComparisonType';
import { FilterGroup } from './FilterGroup';
import { fetchStreamAssets } from '../../actions';

const newFilterFormTargetId = 'filter-form-target';

export const StreamerFilters = () => {
  // HOOKS
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formMethods = useForm<StreamerFilter>({
    defaultValues: streamerFilterFormDefault,
    mode: 'onChange',
  });

  // STATE
  const { records: assets } = useSelector(getAvailableAssets);
  const filters = useSelector(getAllStreamerFilters);
  const selectedStreamId = useSelector(getSelectedStreamId);
  const [selectedFilterForEdit, setSelectedFilterForEdit] =
    React.useState<StreamerFilter>(null);

  // DERIVED STATE
  const groupedFilters = React.useMemo(
    () =>
      chain(filters)
        .groupBy('item.processedEntityName')
        .map((value, key) => ({ label: key, filters: value }))
        .value(),
    [filters]
  );

  const { isGroupOpen, handleOpenGroup } = useCollapseGroups({
    isMultiOpen: true,
    initiallyOpenFirstGroupId: groupedFilters[0]?.label,
  });

  const isEditing = selectedFilterForEdit !== null;
  // we want to specify where we are gonna display the callout of the filter form
  // in edit case, we want to display it just below edited filter, so there is no risk of
  // overlapping your edited item
  const calloutTarget = isEditing
    ? `filter-${[selectedFilterForEdit?.id]}`
    : newFilterFormTargetId;
  // @ts-ignore reason: ts tells us that offsetWidth does not exist on Element (which is not true)
  const calloutWidth = document.querySelector(`#${calloutTarget}`)?.offsetWidth;

  // EFFECTS

  const onMount = React.useCallback(() => {
    if (filters.length === 0) {
      history.push(routes.streamer.dataMode.filters.add);
    }

    if (assets.length === 0) {
      dispatch(fetchStreamAssets(selectedStreamId));
    }
  }, [filters, assets, selectedStreamId]);
  React.useEffect(onMount, [onMount]);

  // CALLBACKS
  const handleCloseForm = React.useCallback(() => {
    formMethods.reset(streamerFilterFormDefault);
    setSelectedFilterForEdit(null);
    history.push(routes.streamer.dataMode.filters.index);
  }, [history, formMethods.reset]);

  const handleSubmit = React.useCallback(
    (filter: any) => {
      const action = isEditing ? editFilter : addFilter;
      const { id, ...remainingFilter } = filter as StreamerFilter;

      dispatch(
        action({
          ...remainingFilter,
          id: isEditing ? selectedFilterForEdit?.id : uuid4(),
          aggregation: remainingFilter?.aggregation ?? AggregationType.None,
        })
      );

      handleCloseForm();
    },
    [dispatch, handleCloseForm, isEditing, selectedFilterForEdit]
  );

  const loadItemOptions = React.useCallback(
    async (inputVal: string): Promise<Array<OptionGroup>> =>
      Promise.resolve(parseAssetsToFilterFormItems(assets, t, inputVal)),
    [assets]
  );

  const handleDeleteFilter = React.useCallback((filter) => {
    dispatch(deleteFilter(filter));
  }, []);

  const handleEdit = React.useCallback(
    (filter) => {
      setSelectedFilterForEdit(filter);
      formMethods.reset(filter);
      history.push(routes.streamer.dataMode.filters.edit);
    },
    [history]
  );

  const loadValuesOptions = React.useCallback(
    async (asset: StreamAssetRaw, inputVal: string): Promise<Array<string>> => {
      if (!asset) {
        return [];
      }

      const { rows } = await fetchSingleAssetData({
        asset: rawAssetToStreamerAsset(asset),
        selectedStreamId,
        filter:
          // API returns 500 if we try to filter out on "boolean values"
          inputVal && asset.dataType !== DataType.Boolean
            ? {
                id: uuid4(),
                type: ColumnFilterType.Comparison,
                comparison: ComparisonType.Contains,
                compareValue: inputVal,
                item: asset,
                aggregation: AggregationType.None,
              }
            : null,
      });

      const filteredValues =
        rows
          ?.flat(2)
          .filter((val) => ['string', 'number', 'boolean'].includes(typeof val))
          .map((val) => String(val)) ?? [];

      return filteredValues;
    },
    []
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...formMethods}>
      <Stack>
        <Route path={routes.streamer.dataMode.filters.add}>
          {/* we need this component to get nice padding
        for the newly created filter from */}
          <div
            id={newFilterFormTargetId}
            style={{
              width: 'calc(100% - 20px)',
              height: '10px',
              margin: 'auto',
            }}
          />
        </Route>
        {groupedFilters.map((group) => (
          <FilterGroup
            {...{
              group,
              handleEdit,
              isGroupOpen,
              handleDeleteFilter,
              handleOpenGroup,
              selectedFilterForEdit,
            }}
            key={group.label}
          />
        ))}
        <Route
          path={[
            routes.streamer.dataMode.filters.add,
            routes.streamer.dataMode.filters.edit,
          ]}
        >
          <FiltersForm
            {...{ loadValuesOptions, loadItemOptions, calloutWidth }}
            title={t('filters:Filter')}
            onClose={handleCloseForm}
            onSubmit={handleSubmit}
            target={`#${calloutTarget}`}
            acceptsTypedValues={shouldEnforceTypedValues}
          />
        </Route>
      </Stack>
    </FormProvider>
  );
};
