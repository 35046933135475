import * as effects from 'redux-saga/effects';

import * as streamerActions from '../actions';
import * as selectors from '../selectors';
import * as filterSelectors from '../Filters/selectors';
import { selectedAssetsToDataRequestParams } from './utils';
import { CallableStrategyParams } from '../types';
import { fetchStreamDataRequest } from '../utils';
import { STREAMING_STRATEGIES } from '../streamingStrategies';
import { handleSelectedAssetsChange } from '../strategies/isolated/sagas';

export function* handlePlay({
  payload: { streamingStrategy: strategy, metadata },
}: ReturnType<typeof streamerActions.playIntent>) {
  const selectedAssets = selectors.getSelectedAssets(yield effects.select());
  const selectedStreamId = selectors.getSelectedStreamId(
    yield effects.select()
  );
  const filters = filterSelectors.getAllStreamerFilters(yield effects.select());

  const dataRequestParams = selectedAssetsToDataRequestParams(
    selectedAssets,
    filters
  );

  try {
    const firstPageResponse: CallableStrategyParams = yield effects.call(
      fetchStreamDataRequest,
      {
        dataRequestParams,
        selectedStreamId,
      }
    );

    yield effects.put(streamerActions.storeSelectedItems());
    yield effects.call(STREAMING_STRATEGIES[strategy], {
      ...firstPageResponse,
      metadata,
    });
  } catch (error) {
    yield effects.put(streamerActions.initialDataFetchFailed());
    console.error('request failed', error);
  }
}

export function* rootSaga() {
  yield effects.all([
    effects.takeLatest(streamerActions.playIntent.type, handlePlay),
    effects.takeEvery(streamerActions.assetClicked, handleSelectedAssetsChange),
  ]);
}

export default rootSaga;
