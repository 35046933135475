import { IStackStyles, IStyle, mergeStyleSets } from '@fluentui/react';

export const notificationClassname = 'notification-el';

interface Styles {
  noRecordsMessage: IStyle;
  gridWrapper: IStyle;
}

export const classNames = mergeStyleSets<Styles>({
  noRecordsMessage: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridWrapper: {
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr) )',
  },
  [`.${notificationClassname}-enter`]: {
    opacity: 0,
  },
  [`.${notificationClassname}-enter-active`]: {
    opacity: 1,
    transition: 'opacity 200ms',
  },
  [`.${notificationClassname}-exit`]: {
    opacity: 1,
  },
  [`.${notificationClassname}-exit-active`]: {
    opacity: 0,
    transition: 'opacity 200ms',
  },
});

export const containerStyles: IStackStyles = {
  root: {
    selectors: {
      '.infinite-scroll-component': {
        overflow: 'hidden !important' as 'hidden',
      },
    },
  },
};
