import { IButtonStyles, mergeStyleSets } from 'office-ui-fabric-react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../../../../../theme';

const truncate = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const classNames = mergeStyleSets({
  wrapper: {
    padding: '4px',
  },
  card: {
    padding: '8px',
    minWidth: 'auto',
    position: 'relative' as const,
    background: baseColors.white,
    border: `1px solid ${baseColors.inputBorder}`,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: baseColors.hover,
    },
    '&:focus': {
      borderColor: baseColors.midOrange,
      outline: `1px solid ${baseColors.midOrange} !important`,
    },
  },
  selectedCard: {
    backgroundColor: baseColors.selected,
    '&:hover': {
      backgroundColor: baseColors.selectedWithHover,
    },
  },
  previewCard: {
    cursor: 'auto',
    minWidth: '350px',
    '&:hover': {
      backgroundColor: `${baseColors.white}`,
    },
    '& + &': {
      marginLeft: 8,
    },
  },
  hiddenPreview: {
    display: 'none',
  },
  cardSpinner: {
    marginLeft: 'auto',
  },
  key: {
    fontSize: 12,
    lineHeight: '1.5',
    color: baseColors.textColor,
  },
  value: {
    color: baseColors.midOrange,
    fontSize: 12,
    lineHeight: '1.5',
    ...truncate,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  title: {
    ...truncate,
    fontSize: 18,
    marginTop: -2,
  },
  subtitle: {
    ...truncate,
    color: baseColors.outlineGray,
    fontSize: 12,
    marginTop: -3,
  },
  icon: {
    width: 32,
    height: 32,
    backgroundColor: baseColors.midOrange,
    color: 'white',
    borderRadius: BORDER_RADIUS_IN_PX,
    marginRight: 8,
    padding: 8,
    flexShrink: 0,
  },
  titleRow: {
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    padding: '12px 8px 15px',
  },
  emphasized: {
    selectors: {
      '&>:first-of-type': {
        fontWeight: 600,
        fontSize: 16,
        ...truncate,
      },
      '&>:last-of-type': {
        color: baseColors.midOrange,
        fontSize: 24,
        marginTop: -3,
        marginBottom: 3,
        ...truncate,
      },
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    selectors: {
      '&>img': {
        marginLeft: 'auto',
      },
    },
  },
  filterIcon: {
    color: baseColors.outlineGray,
    fontSize: 16,
    marginRight: 8,
  },
  filterComparisonIcon: {
    color: baseColors.outlineGray,
    width: '12px',
    height: '12px',
    minWidth: '12px',
    margin: '0 2px',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    selectors: {
      '&>div': {
        overflow: 'hidden',
      },
      '& p': {
        color: baseColors.outlineGray,
        fontSize: 10,
        display: 'flex',
        whiteSpace: 'nowrap',
      },
      '& p>span:': {
        display: 'inline-block',
        ...truncate,
      },
      '& p>span:first-of-type': {
        maxWidth: 70,
      },
      '& p>span:last-of-type': {
        color: baseColors.midOrange,
      },
      '& p>i': {
        margin: '0 4px',
        flexShrink: 0,
      },
    },
  },
  poweredByEyko: {
    marginLeft: 'auto',
  },
  keyValueWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2px',
    // to have proper ellipsis color
    color: baseColors.midOrange,
    ...truncate,
  },
  keyValueSeparator: {
    margin: '0 2px',
  },
  cardKeyAggregationIcon: {
    width: '12px',
    height: '12px',
    marginRight: '4px',
  },
  cardTitleAggregationIcon: {
    width: '15px',
    height: '15px',
    marginRight: '4px',
  },
});

export const showMoreStyles: IButtonStyles = {
  root: {
    fontSize: 12,
    borderColor: baseColors.midOrange,
    color: baseColors.midOrange,
    padding: '0 5px',
    borderRadius: 4,
    backgroundColor: 'transparent',
    fontWeight: 400,
  },
  rootHovered: {
    borderColor: baseColors.midOrange,
    color: baseColors.white,
    backgroundColor: baseColors.midOrange,
  },
  rootPressed: {
    borderColor: baseColors.orange,
    color: baseColors.white,
    backgroundColor: baseColors.orange,
  },
  label: {
    fontWeight: 400,
  },
};
