import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import EntityField from '../../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import FoldableList from '../../../../../components/ui/FoldableList';
import useEditableItems from '../../../../../hooks/useEditableItems';
import useItemSelections from '../../../../../hooks/useItemSelections';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../../App/types';
import { getSearchQuery } from '../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../Search/utils';
import { setNewTagInputOpen, editTagIntent } from '../../actions';
import { getAllTags, getIsMetadataStateLoading } from '../../selectors';

const TagsList = () => {
  // STATE
  const isLoading = useSelector(getIsMetadataStateLoading);
  const searchQuery = useSelector(getSearchQuery);
  const tags = useSelector(getAllTags);

  // DERIVED STATE
  const filteredTags = useMemo(
    () => tags.filter((tag) => searchQuery === '' || matchesSearchQuery(searchQuery, tag.name)),
    [tags, searchQuery],
  );

  // HOOKS
  const dispatch = useDispatch();

  const {
    selectedItem,
    setSingle,
    clear,
    handleItemClickedOnSingleSelection,
  } = useItemSelections();

  const {
    mappedGroups,
    onClickHeaderHandler,
    currentSort,
  } = useSortedFoldableGroups({
    input: filteredTags,
    selectedItemId: selectedItem,
  });

  const {
    isEditing,
    onEditEnd,
    onEditStart,
  } = useEditableItems({
    shouldCleanupOnUmount: true,
  });

  // CALLBACKS
  const hideInputField = () => dispatch(setNewTagInputOpen(false));
  const handleEdit = (tagId: string, newTagName:string) => {
    dispatch(editTagIntent({
      tagId,
      tagName: newTagName,
    }));
  };

  const handleClickNonGroupedItem = (tagId:string) => {
    if (selectedItem === tagId) {
      clear();
    } else {
      setSingle(tagId);
      hideInputField();
    }
  };

  // EFFECTS
  // cleanup - clear selection when closing this scrteen
  React.useEffect(() => () => {clear()}, []);

  // PARTS
  const renderTag = (id: string, name: string) => (
    <EntityField
      slot1={<EntityFieldSlotSpacer />}
      key={id}
      onClick={() => {
        if (isEditing) return;

        if (currentSort === DefinedSortTypes.Name) {
          handleClickNonGroupedItem(id);
        } else {
          handleItemClickedOnSingleSelection(id);
        }
      }}
      isSelected={selectedItem === id}
      isSelectable
    >
      <EditableFoldableListItem
        testId={`${name}-tag-item`}
        onEdit={(newValue) => handleEdit(id, newValue)}
        text={name}
        disableEditing={isEditing}
        onEditStart={onEditStart}
        onEditEnd={onEditEnd}
        searchQuery={searchQuery}
        transparentBackground
      />
    </EntityField>
  );

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  return (
    <>
      {currentSort === DefinedSortTypes.Letter && (
      <FoldableList
        groups={mappedGroups}
        onClickHeader={(key) => {
          if (isEditing) return;
          onClickHeaderHandler(key);
        }}
        onRenderItem={(item) => renderTag(item.key, item.name)}
      />
      )}
      { currentSort === DefinedSortTypes.Name && (
      <>
        {
          filteredTags
            .slice()
            .sort((tag1, tag2) => tag1.name.localeCompare(tag2.name))
            .map(({ id, name }) => renderTag(id, name))
        }
      </>
      )}
    </>
  );
};

export default TagsList;
