import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Stack, getTheme, DirectionalHint } from '@fluentui/react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { btnStyles } from './styles';

import IconButton from '../../../../components/IconButton';
import { ObjectTypes } from '../../types';
import { getModules } from '../Content/constants';
import streamSelectors from '../../../Streams/selectors';
import { getActiveObjectType } from '../../selectors';
import useIsOnboarding from '../../../../hooks/useIsOnboarding';
import EykoSidebarLogo from './EykoSidebarLogo';
import { getUserRole } from '../../../User/selectors';
import { routes } from '../../../../constants/routes';
import NotificationsSidebarButton from '../../../Notifications/components/NotificationsSidebarButton';

const Sidebar = () => {
  const { t } = useTranslation();
  const [currentModuleRoutes, setCurrentModuleRoutes] = useState<{}>({});
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const activeObjectType = useSelector(getActiveObjectType);
  const userRole = useSelector(getUserRole);
  const theme = getTheme();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { isOnboarding } = useIsOnboarding();

  const isGroupTable = useMemo(
    () =>
      currentDataset
        ? currentDataset.type === ObjectTypes.GROUP_TABLE
        : activeObjectType === ObjectTypes.GROUP_TABLE,
    [currentDataset]
  );

  const modules = useMemo(
    () =>
      getModules({
        isGroupTable,
        t,
      }),
    [isGroupTable]
  );

  const isActivePath = useCallback(
    (path: string) =>
      !!matchPath(pathname, {
        path,
        exact: false,
      }),
    [pathname]
  );

  const isInWizard = useMemo(
    () =>
      !!matchPath(pathname, [
        routes.add.topLevelEntity,
        routes.add.nestedEntity,
        routes.edit.entity,
        routes.delete.topLevelEntity,
        routes.share.index,
        routes.process.index,
        routes.builder.assets.linksWizard,
      ]),
    [pathname]
  );

  const mappedModules = useMemo(
    () =>
      modules.map((m) => ({
        ...m,
        isActive:
          isActivePath(m.path) || m?.activeRootPaths?.some(isActivePath),
      })),
    [modules, pathname]
  );

  useEffect(() => {
    const activeModule = mappedModules.find((m) => m.isActive)?.key;

    setCurrentModuleRoutes({
      ...currentModuleRoutes,
      [activeModule]: pathname,
    });
  }, [pathname]);

  const handleClick = (option: any) => {
    if (currentModuleRoutes[option.key]) {
      push(currentModuleRoutes[option.key]);
    } else if (option.path) {
      push(option.path);
    }
  };

  if (isOnboarding) {
    return null;
  }

  return (
    <Stack style={{ backgroundColor: theme.palette.themeLighterAlt }}>
      <EykoSidebarLogo />
      {mappedModules
        .filter((option) => {
          if (!option.visibleForRoles) return true;

          return option.visibleForRoles.includes(userRole);
        })
        .filter((option) => {
          if (isInWizard)
            return (
              option.isActive ||
              option?.Component?.name === NotificationsSidebarButton.name
            );

          return true;
        })
        .map((option) => {
          if (option.Component) {
            return (
              <option.Component
                key={option.key}
                isActive={option.isActive}
                onClick={() => handleClick(option)}
                disabled={option.isActive}
                isInWizard={isInWizard}
              />
            );
          }

          return (
            <IconButton
              key={option.key}
              tooltip={option.title}
              iconProps={{ iconName: option.icon }}
              disabled={option.isActive}
              active={option.isActive}
              styles={btnStyles}
              onClick={() => handleClick(option)}
              directionalHint={DirectionalHint.rightCenter}
              data-testid={`sidebar-button-${option.key}`}
            />
          );
        })}
    </Stack>
  );
};

export default Sidebar;
