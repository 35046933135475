import { ITextFieldStyles, mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../../theme';

export const classNames = mergeStyleSets({
  row: {
    margin: '0 -8px',
    padding: '4px 8px',
    gridTemplateColumns: '1fr 1fr 0.3fr',
    gridTemplateRows: '1fr',
    display: 'grid',
    alignItems: 'center',
  },
  item: {
    selectors: {
      button: {
        visibility: 'hidden',
      },
      '& .ms-TextField': {
        marginRight: 5,
      },
      ':hover': {
        backgroundColor: baseColors.hover,
        selectors: {
          button: {
            visibility: 'visible',
          },
        },
      },
      '&>div:last-of-type': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
  btn: {
    color: baseColors.iconColor,
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  add: {
    marginTop: 8,
  },
  headers: {
    fontWeight: 600,
  },
});

export const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    borderRadius: 2,
    height: 24,
  },
};
