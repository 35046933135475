import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import LanguagePicker from '../../components/LanguagePicker';
import Connection from '../../components/connection/Connection';
// import PageSize from '../../components/PageSize';
// import OnboardingSwitch from '../../components/OnboardingSwitch';
// import TooltipSwitch from '../../components/TooltipSwitch';
// import { sectionTitleStyles } from './styles';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';

const Preferences: React.FC = () => {
  // HOOKS
  // const { t } = useTranslation('settings');

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
  });

  // RENDER
  if (tutorialTile) return tutorialTile;

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Connection />
      <LanguagePicker />
      {/* API does not support user preferences yet */}
      {/* <PageSize />
      <div style={{ marginTop: 5 }}>
        <h3 style={sectionTitleStyles()}>
          {t('messaging')}
        </h3>
        <OnboardingSwitch />
        <TooltipSwitch />
      </div> */}
    </Stack>
  );
};

export default Preferences;
