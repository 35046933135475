import { AxiosResponse } from 'axios';
import * as effects from 'redux-saga/effects';
import { setSelectedItemId } from '../../App/actions';
import { getSelectedDesignId } from '../ContentLibrary/selectors';
import { IDesignTag } from '../types';

import * as actions from './actions';
import * as api from './api';

export function* handleAddTag({ payload: tagName }:ReturnType<typeof actions.createTagIntent>) {
  const selectedDesignId = getSelectedDesignId(yield effects.select());

  yield effects.put(actions.setAreTagsLoading(true));
  try {
    const { data: createdTag }:AxiosResponse<IDesignTag> = yield effects.call(api.createTag, {
      designId: selectedDesignId,
      tagData: {
        name: tagName,
      },
    });

    // select created tag
    yield effects.put(setSelectedItemId(createdTag.id));

    yield effects.put(actions.fetchDesignTags(selectedDesignId) as any);

    yield effects.take([actions.fetchDesignTags.fulfilled, actions.fetchDesignTags.rejected]);
  } catch (error) {
    console.log('error', error);
  } finally {
    yield effects.put(actions.setAreTagsLoading(false));
  }
}
export function* handleEditTag({
  payload: {
    tagId,
    tagName,
  },
}:ReturnType<typeof actions.editTagIntent>) {
  yield effects.put(actions.setNewTagInputOpen(false));

  const selectedDesignId = getSelectedDesignId(yield effects.select());

  yield effects.put(actions.setAreTagsLoading(true));
  try {
    yield effects.call(api.editTag, { id: tagId, name: tagName });

    yield effects.put(actions.fetchDesignTags(selectedDesignId) as any);

    yield effects.take([actions.fetchDesignTags.fulfilled, actions.fetchDesignTags.rejected]);
  } catch (error) {
    console.log('error', error);
  } finally {
    yield effects.put(actions.setAreTagsLoading(false));
  }
}

export function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(actions.createTagIntent.type, handleAddTag),
    yield effects.takeLatest(actions.editTagIntent.type, handleEditTag),
  ]);
}

export default rootSaga;
