import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../../shared/tutorial-tiles/components/animations/Links';
import { LinkStatus } from "../../../../../api/model/schemas/LinkStatus";
import { enforceSchema } from "../../../../../../shared/types/utils";

export const TUTORIAL_TILE_KEY = 'designer-ingest-links';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:ingest:links:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:links:items:combineData:description',
      icon: 'Link',
      titleI18nKey: 'tutorialTiles:designer:ingest:links:items:combineData:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:links:items:noHassle:description',
      icon: 'Robot',
      titleI18nKey: 'tutorialTiles:designer:ingest:links:items:noHassle:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:links:items:youAreInCharge:description',
      icon: 'Equalizer',
      titleI18nKey: 'tutorialTiles:designer:ingest:links:items:youAreInCharge:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010229921-Links-Join-the-dots',
};

export enum LinksViewControlFilter {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Retained = 'Retained',
}

const ACCEPTED_STATUSES: LinkStatus[] = [
  LinkStatus.Accepted,
];

const REJECTED_STATUSES: LinkStatus[] = [
  LinkStatus.Rejected,
];

const RETAINED_STATUSES: LinkStatus[] = [
  LinkStatus.Accepted,
  LinkStatus.Proposal,
];

export const FILTER_TO_STATUS = enforceSchema<Record<LinksViewControlFilter, LinkStatus[]>>()({
  [LinksViewControlFilter.Accepted]: ACCEPTED_STATUSES,
  [LinksViewControlFilter.Rejected]: REJECTED_STATUSES,
  [LinksViewControlFilter.Retained]: RETAINED_STATUSES,
});
