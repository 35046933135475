import { mergeStyleSets } from '@fluentui/react';

export const classNames = mergeStyleSets({
  noErrorsMessage: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridWrapper: {
    padding: '8px',
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr) )',
  },
});
