import { IStackStyles, getTheme, mergeStyleSets } from '@fluentui/react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../theme';

const theme = getTheme();

export const headerStyles: IStackStyles = {
  root: {
    position: 'relative',
    flexShrink: '0!important',
    height: '32px',
    backgroundColor: theme.palette.themeLighter,
  },
};

type Params = {
  isShareWizard: boolean;
};

export const getClassnames = ({ isShareWizard }: Params) =>
  mergeStyleSets({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      position: 'relative',
      flex: 1,
      overflow: 'hidden',
      maxHeight: '100%',
      overflowY: 'auto',
    },
    mainWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '100%',
    },
    textWrapper: {
      maxWidth: '200px',
    },
    textStyles: {
      display: 'block',
      margin: 0,
      padding: 0,
    },
    headerStyles: {
      fontSize: '16px',
      fontWeight: 600,
      marginBottom: '10px',
    },
    descriptionStyles: {
      fontSize: '12px',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 !important',
      flexBasis: 1,
      overflowX: 'hidden',
      overflowY: 'auto',
      borderLeft: `1px solid ${baseColors.inputBorder}`,
      borderRight: `1px solid ${baseColors.inputBorder}`,
      borderBottom: `1px solid ${baseColors.inputBorder}`,
      padding: isShareWizard ? 0 : 8,
      position: 'relative',
      borderBottomRightRadius: BORDER_RADIUS_IN_PX,
      borderBottomLeftRadius: BORDER_RADIUS_IN_PX,
    },
    sectionTitleWrapper: {
      minHeight: '36px',
      position: 'relative',
    },
    icon: {
      height: 16,
      width: 16,
    },
    commandBarStyles: {
      position: 'relative',
    },
    wizardPageDetails: {
      position: 'absolute',
      right: 8,
      top: 0,
      height: '30px',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      textAlign: 'right',
    },
    wizardPageTitle: {
      fontSize: '8px',
      color: baseColors.textColor,
    },
    pageNumber: {
      fontSize: '12px',
      color: baseColors.midOrange,
    },
    rightSlotIconWrapper: {
      marginRight: '10px',
    },
    rightSlotTextWrapper: {
      textAlign: 'right',
    },
    lastUpdatedText: {
      fontSize: '8px',
      color: baseColors.inputBorder,
    },
    lastUpdatedTextSingle: {
      margin: 0,
    },
    spinner: {
      position: 'relative',
      top: '-6px',
      right: '6px',
    },
  });

export const stackStyles: IStackStyles = {
  root: {
    padding: '8px',
    maxHeight: 'calc(100% - 32px)',
    flex: 1,
  },
};

export const shimmerStackStyles: IStackStyles = {
  root: {
    position: 'absolute',
    zIndex: 999999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'white',
    padding: '8px',
  },
};
