import { IContextualMenuItem } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import ButtonMenu from '../../../../../components/ui/ButtonMenu';
import { SchemaIconType } from '../../types';
import { fetchTypes } from './utils';
import { translateApiName } from '../../../../../config/i18n/utils';
import { renderMaterialIcon } from '../../../../../components/ui/ViewControlButton/utils';

interface Props {
  typesIds: string[];
  selectedTypeId: string;
  onChange: (id: string) => void;
  isDisabled?: boolean;
}

const onRenderSvg = ({ data: { icon } }) => <SVG src={icon} />;
const onRenderMaterial = ({ data: { icon } }) => renderMaterialIcon(icon);

const generateIcon = (iconType: SchemaIconType, icon: string) => {
  switch (iconType) {
    case SchemaIconType.InlineSvg:
    case SchemaIconType.Uri:
      return {
        onRenderCustomIcon: onRenderSvg,
      };
    case SchemaIconType.MaterialDesign:
      return {
        onRenderCustomIcon: onRenderMaterial,
      };
    case SchemaIconType.FabricMdl2:
      return {
        iconProps: { iconName: icon },
      };
    default:
      return {};
  }
};

const TypePicker: React.FC<Props> = ({
  typesIds,
  selectedTypeId,
  onChange,
  isDisabled,
}) => {
  // STATE
  const [items, setItems] = useState<IContextualMenuItem[]>([]);

  useEffect(() => {
    (async () => {
      const types = await fetchTypes(typesIds);
      setItems(
        types.map(({ name, iconType, icon, id }) => ({
          key: id,
          text: translateApiName(id, name),
          data: { icon },
          ...generateIcon(iconType, icon),
        }))
      );
    })();
  }, [typesIds]);

  return (
    <ButtonMenu
      disabled={isDisabled}
      data-testid="type-picker"
      shouldDismissOnChange
      items={items}
      onChangeItem={onChange}
      activeItemKey={selectedTypeId}
    />
  );
};

export default TypePicker;
