import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getSelectedStreamFiltersList } from '../../../../../../StreamFilters/selectors';
import { routes } from '../../../../../../../constants/routes';
import { HeaderBarButton } from '../../../../../../../components/ui';

const EditButton : React.FC = () => {
  const { t } = useTranslation('headers');
  const history = useHistory();
  const { pathname } = useLocation();
  const selectedFilters = useSelector(getSelectedStreamFiltersList);

  const isEditRoute = !!matchPath(pathname, {
    path: [routes.streams.filters.edit],
    exact: false,
  });

  const isDisabled = !selectedFilters
    || !selectedFilters.length
    || selectedFilters.length > 1
    || isEditRoute;

  const handleRefreshClick = async () => {
    history.push(routes.streams.filters.edit);
  };

  return (
    <HeaderBarButton
      tooltip={(
        <Trans
          t={t}
          i18nKey="actionBar:filters.edit"
        />
      )}
      onClick={handleRefreshClick}
      iconProps={{ iconName: 'Edit' }}
      data-testid="edit-filter-top-menu"
      disabled={isDisabled}
      shouldHideOnDisabled
    />
  );
};

export default EditButton;
