import React from 'react';
import { useSelector } from 'react-redux';

import { matchPath, useLocation } from 'react-router-dom';
import { selectors } from '../../../../Streams/Streams';
import { getActiveObjectType } from '../../../selectors';
import { ObjectTypes } from '../../../types';
import GroupTableHeaderBar from '../../../../GroupTable/components/GroupTableHeaderBar';
import PlayGroupTableButton from './PlayGroupTableButton';
import PlayButton from './PlayBtn2';
import RewindButton from './RewindButton';
import PauseButton from './PauseButton';
import { routes } from '../../../../../constants/routes';
import LinkedCopyButton from './LinkedCopyButton';
import StreamProcessButton from '../../../../Streams/components/StreamProcessButton';
import { Strategy } from '../../../../Streamer/components/StreamToDatabase/useStreamToDatabase';
import ExportButton from '../../../../Streamer/ExportWizard/components/ExportButton';

const StreamsHeader: React.FC = () => {
  const activeObjectType = useSelector(getActiveObjectType);
  const currentDataset = useSelector(selectors.getCurrentDataset);
  const isCurrentDatasetStreamProcessing = useSelector(
    selectors.getIsCurrentDatasetStreamProcessing
  );
  const currentStream = useSelector(selectors.getCurrentStream);
  const { pathname } = useLocation();

  const isCreateOrEditFiltersRoute = !!matchPath(pathname, {
    path: [routes.streams.filters.create, routes.streams.filters.edit],
    exact: false,
  });

  const shouldDisplayGroupTable = currentDataset
    ? currentDataset.type === ObjectTypes.GROUP_TABLE
    : activeObjectType === ObjectTypes.GROUP_TABLE;

  if (isCreateOrEditFiltersRoute || isCurrentDatasetStreamProcessing) {
    return null;
  }

  // TO DO - transfer to switch for all objects later on (table, pivot table etc)
  if (shouldDisplayGroupTable) {
    return (
      <>
        <PlayGroupTableButton />
        <GroupTableHeaderBar />
        <StreamProcessButton />
      </>
    );
  }

  return (
    <>
      <RewindButton />
      <PlayButton />
      <PauseButton />
      <LinkedCopyButton />
      <StreamProcessButton />
      <ExportButton
        strategy={Strategy.OLD_STREAMER}
        designId={currentStream?.parentId}
      />
    </>
  );
};

export default StreamsHeader;
