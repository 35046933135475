import { OptionTypeBase } from 'react-select';
import { FeatureStatus } from '../../../api/model/schemas/FeatureStatus';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';

export enum Destination {
  Database = 'Database',
  File = 'File',
  Anaplan = 'Anaplan',
  FastClose = 'FastClose',
  NextWorld = 'NextWorld',
  Salesforce = 'Salesforce',
  Bizview = 'Bizview',
  Tableau = 'Tableau',
  'Workday Adaptive Planning' = 'Workday Adaptive Planning',
  'Eyko Streaming Service' = 'Eyko Streaming Service',
  'Microsoft Power BI' = 'Microsoft Power BI',
}

export interface DestinationOption extends OptionTypeBase {
  label: string;
  value: Destination;
  isDisabled?: boolean;
  data: {
    status: FeatureStatus;
    $typeId: typeof TYPE_IDS.ConnectorConfigurationType;
  };
}
