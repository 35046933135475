import { isOfficeEnabled } from "../../../taskpane/config/buildType";

// ACTIONS

export type Action<Type extends string = string, Payload = undefined> = Payload extends undefined
  ? {
    type: Type;
  } : {
    type: Type;
    payload: Payload;
  };

export const createDialogAction = <Type extends string, Payload = undefined>(action: Action<Type, Payload>) => action;

// ACTIONS – DIALOG INITIALIZE

export enum DIALOG_INITIALIZE_ACTION_TYPE {
  SUCCESS = '@eyko/DIALOG/INITIALIZE-SUCCESS',
  FAILURE = '@eyko/DIALOG/INITIALIZE-FAILURE',
}

export const dialogInitializeSuccess = () => createDialogAction({
  type: DIALOG_INITIALIZE_ACTION_TYPE.SUCCESS,
});
export type DialogInitializeSuccessAction = ReturnType<typeof dialogInitializeSuccess>;

export const dialogInitializeFailure = (errorMessage: string) => createDialogAction({
  type: DIALOG_INITIALIZE_ACTION_TYPE.FAILURE,
  payload: errorMessage,
});
export type DialogInitializeFailureAction = ReturnType<typeof dialogInitializeFailure>;

export type DialogInitializeResultAction =
  | DialogInitializeSuccessAction
  | DialogInitializeFailureAction
  ;

export const DIALOG_CLOSED_ACTION_TYPE = '@eyko/DIALOG/CLOSED';

export const dialogClosed = () => createDialogAction({
  type: DIALOG_CLOSED_ACTION_TYPE,
});
export type DialogClosedAction = ReturnType<typeof dialogClosed>;

// MESSAGING

// MESSAGING – SEND

export type PostActionMessage = <Type extends string, Payload = undefined>(action: Action<Type, Payload>) => void;

const dispatchToWindowFactory = (target: Window): PostActionMessage =>
  // NOTE: no serialization is needed because the data is serialized using the structured clone algorithm
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#syntax
  action => target.postMessage(action, window.location.origin);

// MESSAGING – SEND – TO HOST

const dispatchToHostIsolated = window.opener
  ? dispatchToWindowFactory(window.opener)
  : () => {
    throw new Error (
      `${dispatchToHostIsolated.name} method should not be used outside of a spawned dialog context, 'window.opener' is missing: ${window.opener}`
    );
  };

const dispatchToHostOffice: PostActionMessage =
  action => Office.context.ui.messageParent(JSON.stringify(action));

export const dispatchToHost: PostActionMessage = isOfficeEnabled()
  ? dispatchToHostOffice
  : dispatchToHostIsolated
  ;

// MESSAGEING – SEND – TO DIALOG

const dispatchToOfficeDialogFactory = (dialog: Office.Dialog): PostActionMessage =>
  action => dialog.messageChild(JSON.stringify(action));

// NOTE: the following type does not represent the domain accurately but it seems like the best choice when dealing
// with "conditional compilation" – we're assuming here that `isOfficeEnabled()` usage will remain consistent
type DispatchToDialogFactory = (target: Office.Dialog | Window) => PostActionMessage
export const dispatchToDialogFactory: DispatchToDialogFactory = isOfficeEnabled()
  ? dispatchToOfficeDialogFactory
  : dispatchToWindowFactory
  ;
