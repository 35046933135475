import React from 'react';
import {
  Toggle, IToggleProps, IToggleStyles, IToggleStyleProps,
} from '@fluentui/react';

import merge from 'lodash/merge';

import { baseColors } from '../../../theme';

const toggleBaseStyles = (styles?: Partial<IToggleStyles>) => ({
  checked, disabled, theme,
}: IToggleStyleProps): Partial<IToggleStyles> => {
  const baseStyles = {
    pill: {
      borderColor: checked ? baseColors.midOrange : baseColors.offBlack,
      backgroundColor: checked ? baseColors.midOrange : baseColors.white,
      selectors: {
        '&:hover': {
          backgroundColor: checked ? theme.palette.themePrimary : baseColors.white,
          borderColor: checked ? baseColors.orange : baseColors.iconColor,
        },
      },
    },
    thumb: {
      backgroundColor: checked ? baseColors.white : baseColors.offBlack,
    },
    text: {
      fontSize: '12px',
    },
    label: {
      fontSize: '12px',
      fontWeight: 400,
    },
  };

  let stateStyles = {};

  if (disabled) {
    stateStyles = {
      pill: {
        borderColor: baseColors.darkGrey,
        backgroundColor: checked ? baseColors.darkGrey : baseColors.white,
        selectors: {
          '&:hover': {
            backgroundColor: checked ? baseColors.darkGrey : baseColors.white,
            borderColor: baseColors.darkGrey,
          },
        },
      },
      thumb: {
        backgroundColor: checked ? baseColors.white : baseColors.darkGrey,
      },
    };
  }

  return merge({}, baseStyles, stateStyles, styles);
};

// ensure that Toggle styles are mergable if passed
type Props = Omit<IToggleProps, 'styles'> & {
  styles?: Partial<IToggleStyles>;
};

const StyledToggle = ({ styles, ...restProps }: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Toggle {...restProps} styles={toggleBaseStyles(styles)} />
);

export default StyledToggle;
