import { AxiosResponse } from 'axios';
import qs from 'qs';

import { ApiV4ResponseWrapper } from '../../../modules/Designer/types';
import { TypeIds } from '../../model/schemas';

const searchIndicator = '?';
const hashIndicator = '#';

const getSearchFromURL = (url: string) => {
  const searchStartsAt = url.indexOf(searchIndicator) + 1;
  const searchEndsAt = url.indexOf(hashIndicator);

  return searchStartsAt > 0
    ? url.substring(searchStartsAt, searchEndsAt > searchStartsAt ? searchEndsAt : url.length)
    : undefined;
};

export const isResponseTypeIdMatching = (response: AxiosResponse<unknown>, typeId: TypeIds) => {
  const searchParams = qs.parse(getSearchFromURL(response.config.url));

  return 'typeId' in searchParams && searchParams.typeId === typeId;
};

export const isMatchingApiCall = (method: string, urlRegex: RegExp, response: AxiosResponse<unknown>) => {
  if (!response?.config) {
    return false;
  }
  return response.config.method.toLocaleLowerCase() === method.toLowerCase() && urlRegex.test(response.config.url);
};

export const mutablyParseResponseDataArray = <In, Out extends In>(
  response: AxiosResponse<ApiV4ResponseWrapper<In[]>>,
  parser: (element: In) => Out,
) => {
  response.data.data = response.data?.data?.map(parser);

  return response;
};

export const mutablyParseResponseData = <In, Out extends In>(
  response: AxiosResponse<In>,
  parser: (element: In) => Out,
) => {
  response.data = parser(response.data);
  return response;
};
