import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AssetProcessStatus } from '../../../../api/model/AssetProcessStatus';
import { Stream } from '../../../../api/model/schemas/Stream';
import { routes } from '../../../../constants/routes';
import { useSearchFilteredRecords } from '../../../../hooks/useSearchFilteredRecords';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import LargeThumbnailPicker from '../../../../pageTemplates/LargeThumbnailPicker';
import { ISingleLargeThumbnail } from '../../../../pageTemplates/LargeThumbnailPicker/LargeThumbnailPicker';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { DefinedSortTypes } from '../../../App/types';
import { fetchStreamsAction, selectStreamId } from '../../actions';
import { getSortedStreamsRecords, getStreams } from '../../selectors';
import { classNames } from './styles';

const streamToThumbnail = (stream: Stream): ISingleLargeThumbnail => ({
  iconName: stream.icon ? stream.icon : 'Database',
  id: stream.id,
  name: stream.name,
  isSyncing: stream.processStatus === AssetProcessStatus.Processing,
  itemTestId: `stream-tile-${stream.name}`,
});

export const SelectStream = () => {
  // HOOKS
  const dispatch = useDispatch();
  const { isLoading, selectedId } = useSelector(getStreams);
  const sortedStreams = useSelector(getSortedStreamsRecords);
  const history = useHistory();
  const { t } = useTranslation();

  // DERIVED STATE
  const { filteredRecords } = useSearchFilteredRecords(sortedStreams);

  const itemsToRender: ISingleLargeThumbnail[] =
    filteredRecords.map(streamToThumbnail);

  const normalizedStreams = normalizeById(sortedStreams);

  const { mappedGroups, currentSort } = useSortedFoldableGroups({
    input: filteredRecords ?? [],
  });

  // CALLBACKS
  const handleClick = React.useCallback(
    (id: string) => {
      dispatch(selectStreamId(id));
      history.push(routes.streamer.dataMode.selectAssets);
    },
    [dispatch]
  );

  const getInitialSelectedStream = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const streamId = urlSearchParams.get('open_stream');
    if (streamId) {
      dispatch(selectStreamId(streamId));
      history.push(routes.streamer.dataMode.selectAssets);

      // for unknown reason URLSearchParams.delete() does not work with v4
      window.history.pushState({}, null, window.location.href.split('?')[0]);
    }
  };

  // EFFECTS
  React.useEffect(() => {
    dispatch(fetchStreamsAction());
  }, []);

  React.useEffect(() => {
    getInitialSelectedStream();
  }, []);

  // RENDER
  if (currentSort !== DefinedSortTypes.Name) {
    return (
      <>
        {mappedGroups.map((group) => {
          const { name, key } = group;
          const relatedStreams = group.items.map(
            (item) => normalizedStreams[item.key]
          );

          const items = relatedStreams
            .map(streamToThumbnail)
            .sort((streamA, streamB) =>
              streamA.name.trim().localeCompare(streamB.name.trim())
            );
          return (
            <div
              // I noticed a small lag when operating on larger lists when key was solely based on the "key" prop of group.
              // React tried to reuse the old structure. Incorporating current sort tells React to drop it if current sort changes
              key={`${key}${currentSort}`}
              className={classNames.groupWrapper}
            >
              <p className={classNames.groupTitle}>{name}</p>
              <LargeThumbnailPicker
                items={items}
                handleClick={handleClick}
                emptyMessage={t('builder:noStreamsMessage')}
                selectedItemId={selectedId}
              />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <LargeThumbnailPicker
      items={itemsToRender}
      handleClick={handleClick}
      emptyMessage={t('builder:noStreamsMessage')}
      selectedItemId={selectedId}
      isLoading={isLoading}
    />
  );
};
