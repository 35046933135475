import { Stack, Shimmer, ShimmerElementType } from '@fluentui/react';
import React from 'react';

const FormShimmer = () => (
  <Stack>
    <Stack tokens={{ childrenGap: 10 }} style={{ padding: '4px' }}>
      <Shimmer width="30%" />
      <Shimmer />
      <Shimmer width="20%" />
      <Shimmer />
      <Shimmer width="50%" />
      <Shimmer />
      <Shimmer width="30%" />
      <Shimmer shimmerElements={[{
        type: ShimmerElementType.line, height: 120,
      }]}
      />
      <Shimmer width="30%" />
      <Shimmer />
      <Shimmer width="20%" />
      <Shimmer />
      <Shimmer width="50%" />
      <Shimmer />
    </Stack>
  </Stack>
);

export default FormShimmer;
