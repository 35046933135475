import { useState, useEffect } from 'react';

interface Params {
  isMultiOpen?: boolean,
  scrollOnOpen?: boolean,
  initiallyOpenFirstGroupId?: string
}

export default (
  { initiallyOpenFirstGroupId, isMultiOpen, scrollOnOpen }: Params = {
    initiallyOpenFirstGroupId: null,
    scrollOnOpen: true,
    isMultiOpen: false,
  },
) => {
  // STATE
  const [openGroups, setOpenGroups] = useState(initiallyOpenFirstGroupId ? [initiallyOpenFirstGroupId] : []);

  // EFFECTS
  useEffect(() => {
    if (initiallyOpenFirstGroupId) {
      setOpenGroups([initiallyOpenFirstGroupId]);
    }
  }, [initiallyOpenFirstGroupId]);

  // CALLBACKS
  const isGroupOpen = (id: string) => openGroups.includes(id);

  const handleOpenGroup = (id: string) => {
    if (isGroupOpen(id)) {
      setOpenGroups(openGroups.filter((openGroup) => openGroup !== id));
    } else if (isMultiOpen) {
      setOpenGroups([...openGroups, id]);
    } else {
      setOpenGroups([id]);
    }

    // that's a trick to schedule it after re-render
    if (scrollOnOpen) {
      window.setTimeout(() => {
        const el = document.querySelector(`[data-groupuid="${id}"]`);

        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  };

  const isAnyGroupOpen = openGroups.length > 0;

  return {
    openGroups,
    isGroupOpen,
    handleOpenGroup,
    isAnyGroupOpen,
  };
};
