import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { SelectionType } from '../components/ui/ViewControlButton';

import * as appActions from '../modules/App/actions';
import { getSelectionType, getSortControlHistory, getSortType } from '../modules/App/selectors';

export default () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const setSortTypeAction = React.useCallback(
    (sortType: string) => dispatch(appActions.setSortType({ sortType, path: pathname })),
    [dispatch, pathname],
  );
  const setSelectionTypeAction = React.useCallback(
    (selectionType: SelectionType) => dispatch(appActions.setSelectionType(selectionType)),
    [dispatch],
  );
  const currentSort = useSelector(getSortType);
  const selectionType = useSelector(getSelectionType);
  const sortHistory = useSelector(getSortControlHistory);

  return {
    setSortType: setSortTypeAction,
    setSelectionType: setSelectionTypeAction,
    currentSort,
    selectionType,
    sortHistory,
  };
};
