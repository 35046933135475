import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import streamSelectors from '../modules/Streams/selectors';
import { isInStreamer } from '../utils/routes';

export const useGetLastUpdatedStreamingTime = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const syncing = useSelector(streamSelectors.getIsSyncing);
  const checkingCurrentSelection = useSelector(
    streamSelectors.getIsCheckingCurrentSelection
  );

  if (syncing || checkingCurrentSelection || !isInStreamer(pathname)) {
    return null;
  }

  const date = currentDataset?.lastSuccessfulMapping?.timestamp;

  return date ? t('wizard:lastUpdatedTime', { time: date }) : null;
};
