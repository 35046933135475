/* eslint-disable class-methods-use-this */
import {
  Event, EventProcessor, Hub, Integration,
} from '@sentry/types';
import { PATHNAME_STORAGE_KEY } from '../../hooks/useStoringPathnameInLocalstorage';

/** This function adds duration since Sentry was initialized till the time event was sent */
export class PathnameFromLocalStorage implements Integration {
  /**
   * @inheritDoc
   */
  public static id: string = 'PathnameFromLocalStorage';

  /**
   * @inheritDoc
   */
  public name: string = PathnameFromLocalStorage.id;

  /**
   * @inheritDoc
   */
  public setupOnce(addGlobalEventProcessor: (callback: EventProcessor) => void, getCurrentHub: () => Hub): void {
    addGlobalEventProcessor((event) => {
      const self = getCurrentHub().getIntegration(PathnameFromLocalStorage);
      if (self) {
        return self.process(event);
      }
      return event;
    });
  }

  /**
   * @inheritDoc
   */
  public process(event: Event): Event {
    return {
      ...event,
      extra: {
        ...event.extra,
        'router:path': window.localStorage.getItem(PATHNAME_STORAGE_KEY),
      },
    };
  }
}
