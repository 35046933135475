import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderBarButton } from '../../../../../components/ui';

import { routes } from '../../../../../constants/routes';
import {
  setCurrentStreamIdIntent,
} from '../../../../Streams/actions';

const UserFlowControls = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    history.push(routes.favorites.sharedWithMe.index);
    dispatch(setCurrentStreamIdIntent(null));
  };

  return (

    <HeaderBarButton
      tooltip={(
        <Trans t={t} i18nKey="actionBar:general.back" />
      )}
      onClick={handleClick}
      iconProps={{ iconName: 'Back' }}
      data-testid="go-back-user-flow-button"
    />
  );
};

export default UserFlowControls;
