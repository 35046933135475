import * as effects from 'redux-saga/effects';
import { linksSagas } from '../screens/Links/sagas';

import dataSagas from './data';
import filterSagas from './filters';

export function* rootSaga() {
  yield effects.all([
    dataSagas(),
    filterSagas(),
    linksSagas(),
  ]);
}

export default rootSaga;
