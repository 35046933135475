type HasId = {
  id: string;
  [key: string]: any;
};

export const normalizeById = <T extends HasId>(
  fields: T[],
): Record<string, T> => fields.reduce(
    (accumulator, field) => ({
      ...accumulator,
      [field.id]: field,
    }),
    {},
  );

export const normalizeByKey = <T extends HasId>(
  fields: T[],
  key: string,
): Record<string, T> => fields.reduce(
    (accumulator, field) => ({
      ...accumulator,
      [field[key]]: field,
    }),
    {},
  );
