import React from 'react';
import {
  CommandBarButton,
  Icon,
  IconButton,
  IIconProps,
  Spinner,
  Stack,
} from '@fluentui/react';
import { useId } from '@fluentui/react-utilities';
import {
  arrowBtnStyles,
  getNameBtnStyles,
  buttonContainerStyles,
  textStyles,
  spinnerStyles,
  selectionStyles,
} from './styles';
import useInformationDialog, {
  InfoParams,
} from '../../../hooks/use-information-dialog';
import { getSearchMatch } from '../../../modules/Search/utils';
import SearchMatch from '../../SearchMatch';
import { Availability } from '../../../api/model/schemas/Availability';
import {
  availabilityIconStyles,
  warningWrapper,
} from '../../EntityField/styles';

const getIconState = ({
  hasMore,
  isOpen,
}: Pick<BaseProps, 'hasMore' | 'isOpen'>) => {
  if (hasMore) return 'More';
  if (isOpen) return 'ChevronUp';
  return 'ChevronDown';
};

interface Selections {
  current: number;
  total: number;
}

interface BaseProps {
  children: React.ReactNode;
  groupId: string;
  groupName: React.ReactNode;
  /**
   * defines whether or not there might be more items still hidden within the group
   * TODO: combine it with `isOpen` and redefine as `openState`, `collapseState` or sth similar
   * for now it is a separate flag as the component is reused across many places and refactoring it feels
   * out of the scope of the changes introduced at the time of writing this comment
   * */
  hasMore?: boolean;
  info?: InfoParams;
  isDisabled?: boolean;
  isOpen: boolean;
  isOrange?: boolean;
  isSelected?: boolean;
  isSticky?: boolean;
  isSyncing?: boolean;
  searchQuery?: string;
  isRenderingOnQueryMismatch?: boolean;
  hasLeftSlotSpacer?: boolean;
  'data-testid'?: string;
  selections?: Selections;
  availability?: Availability;
}

interface OnClickHandlerProps extends BaseProps {
  onClick(id: string): void;
}
interface DifferentHandlerProps extends BaseProps {
  onIconClick(id: string): void;
  onTextClick(id: string): void;
}

type Props = OnClickHandlerProps | DifferentHandlerProps;

const GroupCollapse: React.FC<Props> = (props) => {
  // DEPS
  const {
    availability,
    children,
    groupId,
    groupName,
    info,
    hasMore = false,
    isOpen,
    isOrange,
    isSelected,
    isSticky,
    isSyncing,
    isRenderingOnQueryMismatch,
    searchQuery,
    hasLeftSlotSpacer,
    'data-testid': dataTestId,
    isDisabled,
    selections,
  } = props;

  // HOOKS
  const componentId = useId(`${GroupCollapse.name}-`);
  const { infoDialog, infoDialogToggleButton, isInfoOpen } =
    useInformationDialog({
      info: info && {
        ...info,
        title: info?.title || groupName,
      },
      target: `#${componentId}`,
      isSelected,
    });

  // DERIVED STATE
  const iconProps = React.useMemo<IIconProps>(
    () => ({
      iconName: getIconState({
        hasMore,
        isOpen,
      }),
    }),
    [hasMore, isOpen]
  );

  // CALLBACKS
  const handleIconClick = React.useMemo(
    () =>
      isDisabled
        ? undefined
        : () => {
            if ('onIconClick' in props) props.onIconClick(groupId);
            else props.onClick(groupId);
          },
    [
      groupId,
      isDisabled,
      (props as OnClickHandlerProps).onClick,
      (props as DifferentHandlerProps).onIconClick,
    ]
  );
  const handleTextClick = React.useMemo(
    () =>
      isDisabled
        ? undefined
        : () => {
            if ('onTextClick' in props) props.onTextClick(groupId);
            else props.onClick(groupId);
          },
    [
      groupId,
      isDisabled,
      (props as OnClickHandlerProps).onClick,
      (props as DifferentHandlerProps).onTextClick,
    ]
  );

  // PARTS
  const groupNameNode = React.useMemo(() => {
    if (typeof groupName !== 'string') return groupName;

    const match = searchQuery && getSearchMatch(groupName, searchQuery);
    const groupNameWithSearchQuery = match ? (
      <SearchMatch {...{ match }} />
    ) : (
      groupName
    );

    return (
      <CommandBarButton
        data-testid="group-collapse-text-btn"
        onClick={handleTextClick}
        styles={getNameBtnStyles({ isOrange, isDisabled })}
      >
        <span style={textStyles}>{groupNameWithSearchQuery}</span>
        {selections?.current > 0 && (
          <span style={selectionStyles}>
            ({selections.current}/{selections.total})
          </span>
        )}
      </CommandBarButton>
    );
  }, [groupName, handleTextClick, isOrange, searchQuery]);

  // RENDER
  if (
    !isRenderingOnQueryMismatch &&
    typeof groupName === 'string' &&
    searchQuery !== '' &&
    !getSearchMatch(groupName, searchQuery)
  ) {
    return null;
  }

  return (
    <>
      <Stack
        data-groupuid={groupId}
        data-is-orange={isOrange}
        data-is-selected={isSelected}
        data-testid={dataTestId}
        horizontal
        id={componentId}
        styles={buttonContainerStyles({
          isSticky,
          isInfoOpen,
          isSelected: isSelected || isInfoOpen,
          hasLeftSlotSpacer,
          isOpen,
        })}
        verticalAlign="stretch"
      >
        <IconButton
          {...{
            iconProps,
          }}
          data-testid="group-collapse-arrow-icon-btn"
          onClick={handleIconClick}
          styles={arrowBtnStyles({ isSelected, isSticky })}
        />
        {groupNameNode}
        {isSyncing && <Spinner styles={spinnerStyles} />}
        {!isSyncing && info?.data && (
          <div style={{ position: 'relative' }}>
            <span style={warningWrapper}>
              <Icon
                iconName="Warning"
                style={availabilityIconStyles(availability)}
              />
            </span>
            {info?.data && infoDialogToggleButton}
          </div>
        )}
      </Stack>
      {infoDialog}
      {isOpen && children}
    </>
  );
};

export default GroupCollapse;
