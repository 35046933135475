import { DataSourceEntityFieldKeys } from '../../../../../api/model/schemas/DataSourceEntityField';
import { SchemaEntry } from '../../../../../components/DataSchemaMapper/model';
import { IFoldableListGroup } from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';
import i18n from '../../../../../config/i18n';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { ancestorSourceNameKey } from '../../../../../constants/infoBoxes';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import {
  EntityIngestionStatus,
  ITypeSectionProperty,
  UIEnhancedDesignSourceEntityField,
} from '../../../types';
import { isFieldSelected } from '../../components/DataSourceEntity/utils';

type NormalizedFields = { [key: string]: UIEnhancedDesignSourceEntityField };
export const normalizeFieldsById = (
  fields: UIEnhancedDesignSourceEntityField[]
): NormalizedFields =>
  fields.reduce<NormalizedFields>(
    (accumulator, field) => ({
      ...accumulator,
      [field.id]: field,
    }),
    {}
  );
export const areAllFieldsInGroupSelected = (
  group: IFoldableListGroup,
  normalizedFields: NormalizedFields
) => {
  const relatedFields = group.items.reduce<UIEnhancedDesignSourceEntityField[]>(
    (accumulator, item) => [...accumulator, normalizedFields[item.key]],
    []
  );

  return relatedFields.every((field) =>
    isFieldSelected(field?.ingestionStatus)
  );
};

export const isGroupOrange = (
  group: IFoldableListGroup,
  normalizedFields: NormalizedFields
) => {
  const isGroupSelected = areAllFieldsInGroupSelected(group, normalizedFields);
  const relatedFields = group.items.reduce<UIEnhancedDesignSourceEntityField[]>(
    (accumulator, item) => [...accumulator, normalizedFields[item.key]],
    []
  );

  return relatedFields.every((field) =>
    isGroupSelected
      ? field?.ingestionStatus === EntityIngestionStatus.PendingProcess
      : field?.ingestionStatus === EntityIngestionStatus.PendingUnprocess
  );
};

interface PrepareEntityFieldPropertiesSchemaArgs {
  properties: ITypeSectionProperty[];
}
export const prepareEntityFieldPropertiesSchema = ({
  properties: entityFieldProperties,
}: PrepareEntityFieldPropertiesSchemaArgs): SchemaEntry[] => {
  const schema = getSchemaFromTypeProperties(
    entityFieldProperties,
    TYPE_IDS.SourceEntityField
  );
  // TODO: contact Stephan about adding some of the missing attributes into the info box
  // NOTE: source of the entity probably has to be added on the FE side
  // since it is not a direct property of an entity

  const insertSourceAt = schema.findIndex(
    (entry) => entry.key === DataSourceEntityFieldKeys.sourceName
  );
  schema.splice(insertSourceAt + 1, 0, {
    key: ancestorSourceNameKey,
    name: i18n.t('entityTypes:Source'),
  });

  const insertTypeAt = schema.findIndex(
    (entry) => entry.key === DataSourceEntityFieldKeys.sourceDataType
  );
  schema.splice(insertTypeAt + 1, 0, {
    key: DataSourceEntityFieldKeys.dataType,
    translationPrefix: TYPE_IDS.SourceEntityField,
  });
  schema.splice(insertTypeAt + 2, 0, {
    key: DataSourceEntityFieldKeys.formatType,
    translationPrefix: TYPE_IDS.SourceEntityField,
  });

  return schema;
};

export const prepareEntitySchema = ({
  properties: entityProperties,
}: PrepareEntityFieldPropertiesSchemaArgs): SchemaEntry[] => {
  const entityPropertiesSchema = getSchemaFromTypeProperties(
    entityProperties,
    TYPE_IDS.SourceEntity
  );

  const schema: SchemaEntry[] = [
    ...entityPropertiesSchema,
    {
      key: 'status',
      translationPrefix: TYPE_IDS.SourceEntity,
    },
    {
      key: 'statusDetails',
      translationPrefix: TYPE_IDS.SourceEntity,
    },
  ];

  return schema;
};
