import { isEqual, merge, cloneDeep } from 'lodash';
import {
  escapeToExcelValidData,
  getDataType, getFormat, groupByType, groupMatrixByFirstColumn, nestedSerializeObjectKeys, restoreEscapedData,
} from '../../actions/excel-actions/utils';
import { COMPARISON_TYPES, FILTER_TYPES } from '../../components/main/streams/FiltersList/constants';
import { AGGREGATION_METHODS, IAggregationMethod } from '../../constants/aggregations';
import { IColumn } from '../../types/IColumn';
import { IDataset } from '../../types/IDataset';
import { IInquiryData } from '../../types/IInquiryData';
import getDatasetColumnByColumnUuid from '../../utils/getDatasetColumnByColumnUuid';
import { generateColumnUuid } from '../ColumnsSequencing/utils';
import { DRILLING_DIRECTIONS, PARENT_DRILLING, SECTION_TYPES } from './constants';

import {
  DrillingDirection, IGroupedRow, IGroupTable, SingleItem,
} from './types';

export const getColumnsFromDataset = (items: SingleItem[], dataset: IDataset):IColumn[] => items.map(({ columnUuid, id: ref }) => {
  const datasetColumn = dataset.columns.find((currentDatasetColumn) => columnUuid === generateColumnUuid(currentDatasetColumn));

  return {
    ...datasetColumn,
    ref,
    aggregation: datasetColumn.aggregation,
  };
});

export const getIsColumnLayout = (table: IGroupTable) => table.columnDrilling > 0 && table.columns.length > 0;

export const getTableHeaderLength = (table: IGroupTable) => (getIsColumnLayout(table) ? 2 : 1);

/**
 * Finds an specific key inside a nested object.
 * @param obj Source object
 * @param parents Keys of the parent objects
 */

const findNestedKey = (obj, parents: string[] = []) => {
  let result = obj;
  for (let i = 0; i < parents.length; i += 1) {
    result = result[parents[i]];
  }
  return result;
};

/**
 * Returns the column and row titles from a Grouped Data, in the shape of a nested object.
 * @param src Group table to get titles from
 * @param rowKeys Row keys for the current drilling action
 * @param columnKeys Column keys for the current drilling action
 */

export const getGroupTableTitles = (
  src: IGroupedRow,
  rowKeys: string[] = [],
  columnKeys: string[] = [],
) => {
  let rowTitles = {};
  let columnTitles = {};

  const getTitles = (
    data: IGroupedRow,
    rowParents = [],
    columnParents = [],
  ) => {
    Object.keys(data).sort().forEach((key) => {
      switch (key) {
        case SECTION_TYPES.rows:
          Object.keys(data[key]).forEach((value) => {
            findNestedKey(rowTitles, rowParents)[value] = {};
            getTitles(data[key][value], [...rowParents, value], columnParents);
          });
          break;
        case SECTION_TYPES.columns:
          Object.keys(data[key]).forEach((value) => {
            // eslint-disable-next-line no-param-reassign
            if (!findNestedKey(columnTitles, columnParents)[value]) {
              findNestedKey(columnTitles, columnParents)[value] = {};
            }
            getTitles(data[key][value], rowParents, [...columnParents, value]);
          });
          break;
        default:
          break;
      }
    });
  };

  getTitles(src);

  rowKeys.forEach((key) => {
    rowTitles = rowTitles[key];
  });

  columnKeys.forEach((key) => {
    columnTitles = columnTitles[key];
  });

  return {
    columnTitles,
    rowTitles,
  };
};

/**
 * This lets you find the location in the worksheet
 * of a given key in the headers of a group table
 */

export const findHeaderKeyCellIndex = (
  table: IGroupTable,
  dataset: IDataset,
  drillingDirection: DrillingDirection,
  keys: string[],
) => {
  let serializedTitles: string[][] = [];
  const { columnTitles, rowTitles } = getGroupTableTitles(table.data);

  if (drillingDirection === DRILLING_DIRECTIONS.rowDrilling) {
    serializedTitles = nestedSerializeObjectKeys(
      rowTitles, dataset.options.rowParentDrilling === PARENT_DRILLING.after,
    );
  } else {
    serializedTitles = nestedSerializeObjectKeys(
      columnTitles, dataset.options.columnParentDrilling === PARENT_DRILLING.after,
    );
  }

  return serializedTitles.findIndex((el) => isEqual(el, keys));
};

/**
 * Assigns the aggregation methods to the Values array based on
 * aggregation inheritance
 */
export const getAggregatedValues = (
  values: IColumn[],
  columnAgg: IAggregationMethod,
  rowAgg: IAggregationMethod,
):IColumn[] => values.map((column) => ({
  ...column,
  aggregation: AGGREGATION_METHODS[columnAgg || null]
    || AGGREGATION_METHODS[rowAgg || null]
    || column.aggregation,
}));

export const getItemType = (index: number, query: IInquiryData, table:IGroupTable) => {
  if (!query.columns[index]) {
    return null;
  }

  const { id } = query.columns[index];
  if (table.columns.find((item) => item.id === id)) {
    return SECTION_TYPES.columns;
  }
  if (table.rows.find((item) => item.id === id)) {
    return SECTION_TYPES.rows;
  }
  return SECTION_TYPES.values;
};

export const groupInquiryData = (
  queries: IInquiryData[],
  groupTable: IGroupTable,
):IGroupedRow => {
  const grouped: IGroupedRow[] = queries.map((query) => {
    const type = getItemType(0, query, groupTable);
    if (type === SECTION_TYPES.values) {
      return { values: query?.rows?.[0] };
    }
    const dataType = getDataType(0, query);
    const group = { [type]: groupMatrixByFirstColumn(query.rows, dataType) };
    groupByType(group[type], 1, query, groupTable);
    return group;
  });

  return merge({}, ...grouped);
};

/**
 * Each time a group table is drilled down one level,
 * a filter has to be added, to match the inquiry result with the key of the item
 * which is being drilled.
 * @param items The items (a.k.a columns) involve in the drilling action
 * @param keys The keys that match each item that has been drilled.
 */

export const addDrillingFiltersToItems = (items: IColumn[], keys: string[]) => {
  if (!keys.length) {
    return items;
  }
  return items.map((item, index) => {
    if (keys[index] || keys[index] === '') {
      return {
        ...item,
        filters: [
          ...item.filters,
          {
            type: FILTER_TYPES.Comparison,
            compareValue: restoreEscapedData(keys[index], item.dataType),
            comparison: COMPARISON_TYPES.Equal,
          },
        ],
      };
    }
    return item;
  });
};

export const getValueAggregation = (
  row: IColumn,
  column: IColumn,
  value: IColumn,
) => column?.aggregation || row?.aggregation || value?.aggregation || null;

export const getValueFormat = (
  row: IColumn,
  column: IColumn,
  value: IColumn,
) => getFormat({
  ...value,
  aggregation: getValueAggregation(row, column, value),
});

export type TGetEscapedMatrixValues = {
  src: any[][],
  groupTable: IGroupTable,
  dataset: IDataset,
  keys?: {
    rows: string[][]
    columns: string[][]
  },
};

/**
 * Takes a matrix of values and returns the same matrix with the values ready to be
 * rendered in the worksheet (i.e. ISO dates (string) will be turned to Excel dates (number))
 * @param params.src A renderable values matrix.
 * @param params.groupTable The table used to generate the src values
 * @param params.keys If src is drilled data, why need the keys of the parent nodes.
 */

export const getEscapedMatrixValues = ({
  src, groupTable, dataset,
  keys = {
    rows: null,
    columns: null,
  },
}: TGetEscapedMatrixValues) => {
  const values = cloneDeep(src);
  const valuesCount = groupTable.values.length;
  const getColumn = (columnUuid: string) => getDatasetColumnByColumnUuid({
    columnUuid,
    dataset,
  });

  for (let rowIndex = 0; rowIndex < values.length; rowIndex += 1) {
    const rowDrilling = keys?.rows?.[rowIndex]?.length;
    let valueIndex = 0;
    for (let colIndex = 0; colIndex < values[0].length; colIndex += 1) {
      const columnDrilling = keys?.columns?.[
        Math.floor(colIndex / valuesCount)
      ]?.length;

      values[rowIndex][colIndex] = escapeToExcelValidData(
        {
          value: values[rowIndex][colIndex],
          dataType: getColumn(groupTable.values[valueIndex]?.columnUuid)?.dataType,
          aggregation: getValueAggregation(
            getColumn(groupTable.rows[rowDrilling - 1]?.columnUuid),
            getColumn(groupTable.columns[columnDrilling - 1]?.columnUuid),
            getColumn(groupTable.values[valueIndex]?.columnUuid),
          ),
          formatType: getColumn(groupTable.values[valueIndex]?.columnUuid)?.formatType,
        },
      );

      valueIndex += 1;
      if (valueIndex === valuesCount) {
        valueIndex = 0;
      }
    }
  }
  return values;
};

interface IGetRenderableTotal {
  src: IGroupedRow,
  table: IGroupTable,
  dataset: IDataset,
  isColumnsTotal?: boolean,
  keys?: string[]
}

/**
 * Returns the groupTable totals ready to be rendered in the worksheet table.
 * @param params.src Grouped inquiry data response
 * @param params.table Group table whose totals will be rendered
 * @param params.dataset Dataset whose totals will be rendered
 * @param params.isColumnsTotal If false, the data will be handled as row totals
 * @param params.keys keys for the current drilling action.
 */

export const getRenderableTotals = ({
  src,
  table,
  dataset,
  isColumnsTotal = false,
  keys = [],

} : IGetRenderableTotal) => {
  const values: any[][] = [];
  if (src?.values) {
    return getEscapedMatrixValues({
      src: [src.values],
      groupTable: table,
      dataset,
    });
  }

  const { columnTitles, rowTitles } = getGroupTableTitles(
    table.data,
    isColumnsTotal ? keys : [],
    !isColumnsTotal ? keys : [],
  );
  const { columnParentDrilling, rowParentDrilling } = dataset.options;

  const titles = isColumnsTotal ? rowTitles : columnTitles;

  const childrenFirst = isColumnsTotal
    ? rowParentDrilling === PARENT_DRILLING.after
    : columnParentDrilling === PARENT_DRILLING.after;

  const drillingKeys = nestedSerializeObjectKeys(titles, childrenFirst);

  const direction = isColumnsTotal
    ? SECTION_TYPES.rows
    : SECTION_TYPES.columns;

  drillingKeys.forEach((drill) => {
    let data = src;
    [...keys, ...drill].forEach((key) => {
      data = data?.[direction]?.[key];
    });
    if (isColumnsTotal) {
      values.push(data?.values);
    } else {
      values[0] = [...(values[0] || []), ...data?.values];
    }
  });

  if (!values.length && !isColumnsTotal) {
    // Generate a total filler the size of columns * values when the query returns no results
    const filler = Array(
      Math.max(1, table.columns.length)
      * Math.max(1, table.values.length),
    ).fill('');

    return [filler];
  }

  const escapedValues = getEscapedMatrixValues({
    src: values,
    groupTable: table,
    dataset,
    keys: {
      rows: isColumnsTotal && drillingKeys,
      columns: !isColumnsTotal && drillingKeys,
    },
  });
  return escapedValues;
};

interface IGetRenderableValues {
  table: IGroupTable,
  dataset: IDataset,
  rowKeys?: string[],
  columnKeys?: string[],
}

/**
 * Returns a 2d matrix with the group table values for the required drilling level
 * @param params.table Group table whose values will be rendered
 * @param params.dataset Dataset whose values will be rendered
 * @param params.rowKeys Row keys for the current drilling action
 * @param params.columnKeys Column keys for the current drilling action
 */

export const getRenderableValues = ({
  table,
  dataset,
  rowKeys = [],
  columnKeys = [],
}: IGetRenderableValues) => {
  const { columnParentDrilling, rowParentDrilling } = dataset.options;
  const childrenRowsFirst = rowParentDrilling === PARENT_DRILLING.after;
  const childrenColumnsFirst = columnParentDrilling === PARENT_DRILLING.after;
  const { columnTitles, rowTitles } = getGroupTableTitles(table.data, rowKeys, columnKeys);
  const values: any[][] = [];
  const valuesCount = table.values.length;
  const filler = Array(valuesCount).fill('');

  const keys = {
    rows: nestedSerializeObjectKeys(rowTitles, childrenRowsFirst),
    columns: nestedSerializeObjectKeys(columnTitles, childrenColumnsFirst),
  };

  keys.rows.forEach((row) => {
    let { data } = table;
    let currentRow = [];

    [...rowKeys, ...row].forEach((rKey) => {
      data = data?.rows?.[rKey];
    });

    if (!getIsColumnLayout(table) && data?.values) {
      currentRow = [...currentRow, ...data.values];
    }

    keys.columns.forEach((column) => {
      let current = data;
      [...columnKeys, ...column].forEach((cKey) => {
        current = current?.columns?.[cKey];
      });

      if (current?.values) {
        currentRow = [...currentRow, ...current.values];
      } else {
        currentRow = [...currentRow, ...filler];
      }
    });
    values.push(currentRow);
  });

  const escapedValues = getEscapedMatrixValues({
    src: values,
    groupTable: table,
    dataset,
    keys,
  });

  return escapedValues;
};
