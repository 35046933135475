import { mergeStyleSets } from 'office-ui-fabric-react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../../../theme';
import { CARD_MIN_WIDTH } from './components/OutputCard/constants';

export const classNames = mergeStyleSets({
  fs: {
    alignItems: 'flex-start',
  },
  grow: {
    flexGrow: 1,
  },
  column: {
    flexDirection: 'column',
  },
  wrapper: {
    width: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    overflow: 'auto',
    padding: 8,
    boxSizing: 'border-box',
    display: 'flex',
    selectors: {
      '& *': {
        boxSizing: 'border-box',
      },
    },
  },
  background: {
    backgroundColor: '#F5F8FA',
    border: `1px solid ${baseColors.outlineGray}`,
    borderRadius: BORDER_RADIUS_IN_PX,
    padding: 8,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${CARD_MIN_WIDTH}px, 1fr))`,
    alignItems: 'start',
    gridGap: '8px',
  },
  window: {
    selectors: {
      '&>div>div': {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(${CARD_MIN_WIDTH}px, 1fr))`,
        alignItems: 'start',
      },
    },
  },
});
