import { IStackStyles, ITextStyles } from '@fluentui/react';
import { baseColors } from '../../../theme';

export const foldableItemStyles = (isActive: boolean): IStackStyles => ({
  root: {
    padding: '5px 0 5px 32px',
    cursor: 'default',
    fontSize: 12,
    color: isActive && baseColors.orange,
    backgroundColor: isActive && baseColors.hover,
    selectors: {
      '&:hover': {
        backgroundColor: baseColors.hover,
      },
    },
  },
});

export const containerStyles: IStackStyles = {
  root: {
    selectors: {
      '.ms-Button--commandBar': {
        color: baseColors.textColor,
      },
    },
  },
};

export const itemTextStyles: ITextStyles = {
  root: {
    fontSize: 12,
  },
};
