import { Label, Stack } from '@fluentui/react';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { IControlledField } from '../../types';
import TagPickerBase from '../TagPicker';

const TagPicker : React.FC<IControlledField> = ({
  controller,
  isDisabled,
  label,
  initialValue,
}) => {
  // This is the design selected from the Designer content library screen
  const selectedDesignId = useSelector(getSelectedDesignId);
  // This is the design selected within the same entity form wizard section
  const currentDesignId = useWatch({ name: 'design', defaultValue: null });

  const { onChange } = controller;

  return (
    <Stack>
      <Label>{label}</Label>
      <TagPickerBase
        {...{
          isDisabled,
        }}
        designId={currentDesignId || selectedDesignId}
        initialValue={initialValue}
        onChange={onChange}
      />
    </Stack>
  );
};

export default TagPicker;
