import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from '../../../../constants/routes';

import { Chat } from '../Chat/Chat';
import { ErrorForm } from '../ErrorForm/ErrorForm';
import { IframeContent } from '../../components/IFrameContent/IFrameContent';

export const IndexPage = () => (
  <Switch>
    <Route path={routes.help.knowledgeBase}>
      <IframeContent
        title="hubspot knowledgebase"
        url="https://knowledge.eyko.io?hide_widget=true"
      />
    </Route>
    <Route path={routes.help.website}>
      <IframeContent title="eyko main webpage" url="https://eyko.io" />
    </Route>
    <Route path={routes.help.chat}>
      <Chat />
    </Route>
    <Route path={routes.help.errorForm}>
      <ErrorForm />
    </Route>
    <Redirect to={routes.help.chat} />
  </Switch>
);
