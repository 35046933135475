import React, { useEffect } from 'react';
import { MemoryRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isOfficeEnabled as isOfficeEnabledUtil } from '../../../config/buildType';
import { routes } from '../../../constants/routes';

import Progress from '../../../components/Progress';
import Content from '../components/Content';
import { fetchUserData } from '../../User/fetchUserData';
import { getClientPreferences } from '../../ClientPreferences/actions';
import { UserPreferenceIds } from '../../../types/IClientPreferences';
import i18n from '../../../config/i18n';
import { getIsUserDataAvailable } from '../../User/selectors';
import { getClientPreferencesListAsObject } from '../../ClientPreferences/selectors';
import NotificaionsSetup from '../../Notifications/components/NotificationsSetup/NotificationsSetup';
import { AuthContext } from '../../Auth-v2/context';
import { HistoryService } from '../services/memoryHistory';

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

const App: React.FC<AppProps> = ({ title, isOfficeInitialized }: AppProps) => {
  // HOOKS
  const { t } = useTranslation('connection');
  const { isSignedIn: isLoggedIn } = React.useContext(AuthContext);
  const dispatch = useDispatch();

  // STATE
  const isUserDataAvailable = useSelector(getIsUserDataAvailable);
  const clientPreferences = useSelector(getClientPreferencesListAsObject);
  const isOfficeEnabled = isOfficeEnabledUtil();

  // DERIVED STATE
  const streamingMoudulePath = isOfficeEnabled
    ? routes.streamingFlow.index
    : routes.streamer.index;
  const initialEntry = isLoggedIn
    ? streamingMoudulePath
    : routes.onboarding.index;

  // EFFECTS
  useEffect(() => {
    if (isLoggedIn && !isUserDataAvailable) {
      dispatch(fetchUserData());
      dispatch(getClientPreferences());
    }
  }, [dispatch, isLoggedIn, isUserDataAvailable, isOfficeEnabled]);

  useEffect(() => {
    const languagePreference =
      clientPreferences && clientPreferences[UserPreferenceIds.LANGUAGE];
    if (languagePreference) {
      i18n.changeLanguage(languagePreference);
    }
  }, [clientPreferences]);

  // RENDER

  // this is only to trigger critical error fro cypress testing
  if (!isOfficeEnabled && window.location.pathname.includes('crash')) {
    throw new Error('fatal error triggered');
  }

  if (isOfficeEnabled && !isOfficeInitialized) {
    return (
      <Progress
        title={title}
        logo="assets/logo.png"
        message={t('Please sideload your addin to see app body')}
      />
    );
  }

  return (
    <MemoryRouter initialEntries={[initialEntry]}>
      <HistoryService />
      {isLoggedIn && <NotificaionsSetup />}
      <Content />
    </MemoryRouter>
  );
};

export default App;
