import React, { useEffect, useMemo } from 'react';
import { Stack, IStackStyles } from '@fluentui/react';
import { matchPath, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { routes } from '../../../../constants/routes';
import * as excelActions from '../../../../actions/excel-actions';

import HeaderMenu from '../HeaderMenu';
import Sidebar from '../Sidebar';
import Settings from '../../../Settings/screens/Index';
import Designer from '../../../Designer/screens/IndexPage';
import Builder from '../../../Builder/screens/IndexPage';
import Notifications from '../../../Notifications/screens/Index';
import { IndexPage as HelpContent } from '../../../Help/screens/IndexPage/IndexPage';
import GroupTableDesign from '../../../GroupTable/screens/GroupTableDesign';
import Onboarding from '../../../Onboarding/screens/IndexPage';
import BlockedUiOverlay from '../BlockedUiOverlay';
import Container from '../../../../components/Container';
import UserFlow from '../../screens/UserFlow';
import streamSelectors from '../../../Streams/selectors';
import { getModules } from './constants';
import { getActiveObjectType } from '../../selectors';
import { ObjectTypes } from '../../types';
import listenWorkbookSelectionChange from '../../../../actions/excel-actions/listenWorkbookSelectionChange';
import { checkWorkbookSelection } from '../../../Streams/actions';
import TopLevelEntityDeletePage from '../../../Designer/screens/TopLevelEntityDeletePage';
import EntityForm from '../../../Designer/Form/screens/EntityForm';
import SharingSection from '../../../Share/screens/IndexPage';
import ProcessSection from '../../../Process/screens/IndexPage';
import { useGoogleAnalytics } from '../../../../hooks/useGoogleAnalytics';
import { isOfficeEnabled as isOfficeEnabledUtil } from '../../../../config/buildType';
import { useStoringPathnameInLocalStorage } from '../../../../hooks/useStoringPathnameInLocalstorage';
import { IndexPage as Streamer } from '../../../Streamer/screens/IndexPage/IndexPage';
import { AssetLinksWizard } from '../../../Builder/screens/AssetsLinksWizard/AssetLinksWizard';
import { OutputPanel } from '../../../Streamer/screens/OutputPanel/OutputPanel';
import { classNames } from './styles';
import ExportWizard from '../../../Streamer/ExportWizard/screens';
import { ImportWizard } from '../../../Streamer/ImportWizard/ImportWizard';
import MachineLearningPreview from '../../../Designer/Catalog/screens/MachineLearning/MachineLearningPreview';

const wrapStyle: IStackStyles = {
  root: {
    position: 'relative',
    flexGrow: 1,
    overflow: 'hidden',
    maxHeight: '100%',
    overflowY: 'auto',
  },
};

const Content: React.FC = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useGoogleAnalytics();
  useStoringPathnameInLocalStorage();

  // STATE
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const activeObjectType = useSelector(getActiveObjectType);
  const { pathname } = useLocation();

  // DERIVED STATE
  const isOfficeEnabled = isOfficeEnabledUtil();
  const isInNewStreamer = matchPath(pathname, routes.streamer.index);
  const isGroupTable = useMemo(
    () =>
      currentDataset
        ? currentDataset.type === ObjectTypes.GROUP_TABLE
        : activeObjectType === ObjectTypes.GROUP_TABLE,
    [currentDataset, activeObjectType]
  );

  const modules = useMemo(
    () =>
      getModules({
        isGroupTable,
        t,
      }),
    [isGroupTable, t]
  );

  const module =
    modules.find(
      (m) =>
        !!matchPath(pathname, {
          path: m.path,
        })
    ) || modules[0];

  // EFFECTS
  useEffect(() => {
    if (!isOfficeEnabled) return undefined;

    excelActions.addTableEventListeners();
    excelActions.handleRemovedDatasets();
    const { addWorksheetDeletedListener, removeWorksheetDeletedListener } =
      excelActions.listenWorksheetDeleted();
    const { add, cleanup } = listenWorkbookSelectionChange(() => {
      dispatch(checkWorkbookSelection());
    });
    add();
    addWorksheetDeletedListener();

    return () => {
      cleanup();
      removeWorksheetDeletedListener();
    };
  }, [isOfficeEnabled]);

  // RENDER
  return (
    <Stack horizontal styles={wrapStyle}>
      <Sidebar />
      <Stack styles={wrapStyle}>
        <Switch>
          <Route path={routes.delete.topLevelEntity}>
            <TopLevelEntityDeletePage />
          </Route>
          <Route
            path={[
              routes.add.topLevelEntity,
              routes.add.nestedEntity,
              routes.edit.entity,
            ]}
            component={EntityForm}
          />
          <Route path={routes.process.index}>
            <ProcessSection />
          </Route>
          <Route path={routes.share.index}>
            <SharingSection />
          </Route>
          <Route path={routes.builder.assets.linksWizard}>
            <AssetLinksWizard />
          </Route>
          <Route path={routes.streamer.export.index} component={ExportWizard} />
          <Route path={routes.streamer.import.index} component={ImportWizard} />
          <Route
            path={routes.designer.catalog.machineLearningPreview}
            component={MachineLearningPreview}
          />
          <Route>
            <HeaderMenu />
            <Stack
              horizontal
              style={{ flexGrow: 1 }}
              className={cn({
                [classNames.contentWrapInStreamer]: isInNewStreamer,
              })}
            >
              <Switch>
                <Route path={routes.onboarding.index} component={Onboarding} />
                <Route>
                  <Container sections={module.sections}>
                    <Route
                      path={[
                        routes.streams.index,
                        routes.visuals.index,
                        routes.streams.design.index,
                        routes.groupTable.design,
                        routes.favorites.index,
                        routes.designer.index,
                        // during auth pending
                        routes.settings.preferences.index,
                      ]}
                    >
                      <BlockedUiOverlay />
                    </Route>
                    <Route
                      path={[
                        routes.streamingFlow.index,
                        routes.favorites.index,
                        routes.streams.index,
                        routes.visuals.index,
                      ]}
                      component={UserFlow}
                    />
                    <Route path={routes.settings.index} component={Settings} />
                    <Route path={routes.help.index} component={HelpContent} />
                    <Route
                      path={routes.notifications.index}
                      component={Notifications}
                    />
                    <Route
                      path={routes.groupTable.design}
                      component={GroupTableDesign}
                    />
                    <Route path={routes.designer.index}>
                      <Designer />
                    </Route>
                    <Route path={routes.builder.index}>
                      <Builder />
                    </Route>
                    <Route path={routes.streamer.index}>
                      <Streamer />
                    </Route>
                  </Container>
                </Route>
              </Switch>
              <OutputPanel />
            </Stack>
          </Route>
        </Switch>
      </Stack>
    </Stack>
  );
};

export default Content;
