
import middleware from './middleware';
import settingsSyncReal from './settingsSync';

import { getPersisted, persist } from './DataSets';

const dataController = {
  getPersisted,
  persist,
};

const officeSettingsMiddleware = middleware;
const settingsSync = settingsSyncReal;

export {
  officeSettingsMiddleware,
  settingsSync,
  dataController,
};
