import { IButtonStyles, ITheme, mergeStyles } from '@fluentui/react';
import { baseColors } from '../../../../theme';
import { columnAggregationBtnWrapperClassName, menuOpenClassName } from './ColumnAggregationButton/ColumnAggregationButton';

export const getStyles = (theme: ITheme, streamming?: boolean, syncing?: boolean, isColumnQueued?: boolean, isColumnDisabled?: boolean) => ({
  streaming: {
    backgroundColor: theme.palette.themePrimary,
    color: '#FFFFFF!important',
  },
  listItem: {
    padding: 0,
    fontSize: 12,
    border: 'none',
    selectors: {
      '& .ms-Spinner': {
        marginLeft: 'auto',
      },
      ':hover i': {
        visibility: 'visible',
      },
      '& *': {
        cursor: syncing ? 'progress!important' : '',
      },
    },
  },
  aggregation: {
    width: 32,
    minHeight: 24,
    display: 'flex',
    selectors: {
    },
  },
  match: {
    display: 'inline-block',
    backgroundColor: baseColors.grayOverlay,
  },
  name: {
    padding: '6px 0',
    paddingLeft: 2,
    paddingRight: 0,
    selectors: {
      '*': {
        fontWeight: '400!important',
        color: isColumnDisabled ? `${baseColors.iconColor}80` : isColumnQueued ? theme.palette.themePrimary : baseColors.iconColor,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      span: {
        maxWidth: 'calc(100% - 14px)',
      },
    },
  },
  btnWrap: {
    position: 'relative',
    height: '100%',
  },
  filter: {
    marginLeft: 8,
    color: streamming ? baseColors.white : theme.palette.themePrimary,
    verticalAlign: 'sub',
    cursor: 'help',
  },
  filterRule: {
    marginBottom: 5,
  },
  columnDetails: {
    padding: '8px 30px 8px 8px',
    minWidth: '233px',
    fontSize: '12px',
  },
  columnDetailsSingleColumn: {
    marginBottom: 9,
    display: 'flex',
    selectors: {
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  singleColumnTitle: {
    color: baseColors.iconColor,
    fontWeight: 600,
    display: 'inline-block',
    width: '35%',
  },
  singleColumnDescription: {
    width: '65%',
  },
});

export const aggregationBtn = (isColumnSelected: boolean, isColumnQueued: boolean, nonGreyBg: boolean = false) => (isDroplistOpen: boolean):IButtonStyles => (
  {
    root: {
      boxSizing: 'border-box',
      height: '100%',
      width: 32,
      backgroundColor: (isColumnSelected && !nonGreyBg) ? baseColors.midGrey : 'transparent',
      borderRadius: 0,
      margin: 0,
      selectors: {
        ':focus i': {
          visibility: 'visible',
        },
        ':active': {
          height: '100%',
        },
      },
    },
    rootHovered: {
      color: 'inherit',
      backgroundColor: isColumnSelected ? baseColors.selectedWithHover : baseColors.hover,
    },
    rootPressed: {
      color: 'inherit',
      backgroundColor: isColumnSelected ? baseColors.hover : baseColors.selectedWithHover,
    },
    icon: {
      fontSize: 16,
      width: 16,
      height: 16,
      color: isColumnQueued ? baseColors.orange : baseColors.iconColor,
      backgroundColor: 'transparent',
      visibility: (isColumnSelected || isDroplistOpen) ? 'visible' : 'hidden',
    },
  }
);

export const aggregationDroplistBtn : IButtonStyles = {
  root: {
    position: 'absolute',
    borderRadius: 0,
    color: 'inherit',
    right: 0,
    bottom: 0,
    height: 'auto',
    width: 'auto',
    padding: 2,
    margin: 0,
    pointerEvents: 'none',
  },
  rootHovered: {
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  rootPressed: {
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  icon: {
    fontSize: 8,
    color: 'inherit',
    backgroundColor: 'transparent',
    visibility: 'hidden',
    margin: 0,
    height: 'auto',
    lineHeight: '8px',
  },
};

export const getContainerClassname = (isColumnSelected: boolean) => mergeStyles({
  selectors: {
    [`.${columnAggregationBtnWrapperClassName}`]: {
      visibility: isColumnSelected ? 'visible' : 'hidden',
    },
    [`.${columnAggregationBtnWrapperClassName}.${menuOpenClassName}`]: {
      visibility: 'visible',
    },
    [`&:hover .${columnAggregationBtnWrapperClassName}`]: {
      visibility: 'visible',
    },
  },
});
