import {
  IStackStyles, IPersonaStyles,
} from '@fluentui/react';

export const stackStyles: IStackStyles = {
  root: {
    position: 'relative',
    flexShrink: '0!important',
    height: '32px',
  },
};

export const avatarStyles : Partial<IPersonaStyles> = {
  root: {
    margin: '3px 5px 3px 0px',
    cursor: 'pointer',
  },
};
