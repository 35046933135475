import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fetchTypeSections } from '../api';
import { ITypeSectionProperty } from '../types';

import * as api from './api';
import { CatalogEntry } from './types';

export const fetchDesignTags = createAsyncThunk(
  'DESIGNER/METADATA/FETCH_DESIGN_TAGS',
  async (designId:string) => {
    const { data: responseData } = await api.fetchTags(designId)();

    return responseData.data;
  },
);
export const fetchCatalogDefinition = createAsyncThunk(
  'DESIGNER/METADATA/FETCH_CATALOG_DEFINITION',
  async (designId: string) => {
    const catalogDefinition:CatalogEntry[] = [];
    const { data: responseData } = await api.fetchTypes();

    await Promise.all(responseData.data.filter(type => type.isExtensible).map(async (type) => {
      const response = await fetchTypeSections(type.id)({ designId });

      const allProperties = response.data
        .reduce<ITypeSectionProperty[]>((acc, currentSection) => {
        const currentSectionProperties = currentSection.properties;
        acc.push(...currentSectionProperties);

        return acc;
      }, []);

      catalogDefinition.push({
        ...type,
        properties: allProperties,
      });
    }));

    return catalogDefinition;
  },
);

export const setNewTagInputOpen = createAction<boolean, 'DESIGNER/METADATA/SET_IS_CREATING_TAG'>('DESIGNER/METADATA/SET_IS_CREATING_TAG');
export const setAreTagsLoading = createAction<boolean, 'DESIGNER/METADATA/SET_ARE_TAGS_LOADING'>('DESIGNER/METADATA/SET_ARE_TAGS_LOADING');
export const createTagIntent = createAction<string, 'DESIGNER/METADATA/CREATE_TAG_INTENT'>('DESIGNER/METADATA/CREATE_TAG_INTENT');
export const editTagIntent = createAction<{tagId: string, tagName: string}, 'DESIGNER/METADATA/EDIT_TAG_INTENT'>('DESIGNER/METADATA/EDIT_TAG_INTENT');

export const toggleCatalogEntrySelection = createAction<string, 'DESIGNER/METADATA/TOGGLE_CATALOG_ENTRY_SELECTION'>('DESIGNER/METADATA/TOGGLE_CATALOG_ENTRY_SELECTION');
export const toggleCatalogPropertySelection = createAction<string, 'DESIGNER/METADATA/TOGGLE_CATALOG_PROPERTY_SELECTION'>('DESIGNER/METADATA/TOGGLE_CATALOG_PROPERTY_SELECTION');