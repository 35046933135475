import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from '../../../../../constants/routes';
import Logic from './components/Logic';
import Values from './components/Values';

const Filters = () => (
  <Switch>
    <Route path={routes.designer.ingestion.filters.values} component={Values} />
    <Route path={routes.designer.ingestion.filters.logic.index} component={Logic} />
    <Redirect to={routes.designer.ingestion.filters.logic.index} />
  </Switch>
);

export default Filters;
