import { entityUuidRegexStr } from '../../../../../shared/constants/entityUuidRegex';
import { CalculationTypes } from '../../../model/schemas';

export const apiInteractionPathRegex = new RegExp(
  `/v4/Types/(${entityUuidRegexStr})/Interaction`
);

export const WeekCalculationTypeId = '4a1257ed-085c-4dd9-ac3e-37e50ac13dae';
export const WeekCalculationTypePropertyId =
  '6f32d7ab-dd3f-44f7-9252-6a17403d75e4';

export const WeekdayCalculationTypeId = 'f2487b26-cdcd-4abc-b5e6-a4eb68ddb7d8';
export const WeekdayCalculationTypePropertyId =
  'bb5dc158-6be3-4da1-95c8-cb9972662913';

export const supportedCalculationTypes = Object.values(CalculationTypes);
