import { settingsStorage } from '../../utils';
import { INSTANCE_URL_LOCAL_STORAGE_KEY } from './constants';

export const instanceUrlSerializer = settingsStorage<string>(
  INSTANCE_URL_LOCAL_STORAGE_KEY
);

export const getEnvironmentUrl = () => {
  // start by checking if a user has already made a choice in one of their previous sessions
  const persistedInstanceUrl = instanceUrlSerializer.load();

  if (persistedInstanceUrl) return persistedInstanceUrl;

  const { origin } = window.location;

  // origins are based on the manifest files
  switch (origin) {
    case 'https://devexceladdin.azureedge.net':
      return 'https://sadiradra.azurewebsites.net';
    case 'https://prodexceladdin.azureedge.net':
      return 'https://enif-api.azurewebsites.net';
    default:
      return (
        process.env.DEFAULT_ENV_CONNECTION ||
        'https://sadiradra.azurewebsites.net'
      );
  }
};
