import { createSelector } from 'reselect';
import { ApplicationState } from '../../../store/types';
import { IDesignSource } from '../types';

export const getCatalogState = (state: ApplicationState) =>
  state.designerCatalog;

export const getDataSources = (state: ApplicationState) =>
  getCatalogState(state).dataSources;

export const getCalculations = (state: ApplicationState) =>
  getCatalogState(state).calculations;

export const getMLAssets = (state: ApplicationState) =>
  getCatalogState(state).machineLearning;

export const getCalculationById = (calcId) => (state: ApplicationState) =>
  getCalculations(state).records.find((record) => record.id === calcId);
export const getMLAssetById = (id: string) => (state: ApplicationState) =>
  getMLAssets(state).records.find((record) => record.id === id);

export const getHubs = (state: ApplicationState) => getCatalogState(state).hubs;

export const getHubById = (hubId) => (state: ApplicationState) =>
  getHubs(state).records.find((record) => record.id === hubId);

export const getGroups = (state: ApplicationState) =>
  getCatalogState(state).groups;

export const getGroupById = (groupId) => (state: ApplicationState) =>
  getGroups(state).records.find((record) => record.id === groupId);

export const getEntityFields = (state: ApplicationState) =>
  getCatalogState(state).entityFields;

export const getSelectedSourceId = (state: ApplicationState) =>
  getCatalogState(state).selectedSourceId;

export const getSelectedEntityId = (state: ApplicationState) =>
  getCatalogState(state).selectedEntityId;

export const getCurrentSelectionType = (state: ApplicationState) =>
  getCatalogState(state).currentSelectionType;

export const getCurrentFilter = (state: ApplicationState) =>
  getCatalogState(state).currentFilter;

export const getIsUpdating = (state: ApplicationState) =>
  getCatalogState(state).isUpdating;

export const getCurrentPage = (state: ApplicationState) =>
  getCatalogState(state).currentPage;

export const getSelectedSource = createSelector(
  getDataSources,
  getSelectedSourceId,
  ({ records }, sourceId) => records?.find((s) => s.id === sourceId)
);

export const getSelectedSourceName = createSelector(
  getSelectedSource,
  (source) => source?.name
);

export const createGetSourceData = () =>
  createSelector(
    getDataSources,
    (_, id: IDesignSource['id']) => id,
    ({ records: sources }, id) => sources.find((source) => source.id === id)
  );
