import { ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import {
  DATA_TYPE_TO_EXCEL_FORMAT,
  FORMAT_TYPE_TO_EXCEL_FORMAT,
  IDrillingState,
  DRILLING_STATE,
  PAGE_SIZE,
  excelEscapedNull,
  tableHeadersStylesFormat,
} from './constants';
import { defaultPageSize } from '../../constants/pageSizes';
import {
  IColumn,
  IColumnAggregationMethods,
  IColumnDataType,
  IColumnFormatType,
} from '../../types/IColumn';
import { UserPreferenceIds } from '../../types/IClientPreferences';
import {
  AGGREGATION_METHODS,
  COLUMN_DATA_TYPES,
  getAggregations,
  IAggregationMethod,
} from '../../constants/aggregations';
import { IDataset, TEMP_DATASET } from '../../types/IDataset';
import { MappedThunkActions } from '../../utils/useActions';
import {
  areColumnsEqual,
  getNonRepeatingColumns,
} from '../../modules/Streams/components/StreamerColumn/utils';
import { actionCreators } from '../../modules/Streams/actions';
import { addError } from '../../modules/Notifications/addError';
import { ObjectTypes } from '../../modules/App/types';
import { IInquiryData } from '../../types/IInquiryData';
import {
  IGetGroupTableValuesFormat,
  IGroupedColumn,
  IGroupedRow,
  IGroupTable,
} from '../../modules/GroupTable/types';
import {
  getColumnsFromDataset,
  getEscapedMatrixValues,
  getIsColumnLayout,
} from '../../modules/GroupTable/utils';
import { formatDateToExcelDate, formatExcelDateToDate } from '../../utils/date';
import { PARENT_DRILLING } from '../../modules/GroupTable/constants';
import { generateColumnUuid } from '../../modules/ColumnsSequencing/utils';
import getDatasetColumnByColumnUuid from '../../utils/getDatasetColumnByColumnUuid';
import { getInvalidAggregationMethods } from '../../utils/itemsAggregation';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { baseColors } from '../../theme';

export const reportError = (e: Error) => {
  window.sharedState.store.dispatch(
    addError({
      date: +new Date(),
      title: e.name,
      status: null,
      stackTrace: e.stack,
      detail: e.message,
    })
  );
};

const getDerivedDefaultFormatType = (dataType: IColumnDataType) => {
  const { DateTime, NumericDecimal, NumericInteger } = IColumnDataType;
  const { ShortDateTime, Standard } = FORMAT_TYPE_TO_EXCEL_FORMAT;
  switch (dataType) {
    case DateTime:
      return ShortDateTime;
    case NumericDecimal:
      return DATA_TYPE_TO_EXCEL_FORMAT.NumericDecimal;
    case NumericInteger:
      return DATA_TYPE_TO_EXCEL_FORMAT.NumericInteger;
    default:
      return Standard;
  }
};

export const getFormat = (column: IColumn) => {
  if (
    [
      AGGREGATION_METHODS.Count,
      AGGREGATION_METHODS.CountDistinctExcludingNull,
      AGGREGATION_METHODS.CountDistinctIncludingNull,
    ].includes(column.aggregation)
  ) {
    return DATA_TYPE_TO_EXCEL_FORMAT.NumericInteger;
  }

  const dataTypeFormat = DATA_TYPE_TO_EXCEL_FORMAT[column.dataType];
  const formatTypeFormat = FORMAT_TYPE_TO_EXCEL_FORMAT[column.formatType];

  if (column.formatType === IColumnFormatType.Standard) {
    return getDerivedDefaultFormatType(column.dataType);
  }

  if (
    column.dataType === IColumnDataType.NumericInteger &&
    column.formatType === IColumnFormatType.Number
  ) {
    // this is Number Format type without commas
    return '0';
  }

  if (
    column.dataType === IColumnDataType.NumericDecimal &&
    column.formatType === IColumnFormatType.Number
  ) {
    // this is Number Format type without commas for decimals
    return '0.00';
  }

  if (formatTypeFormat) {
    return formatTypeFormat;
  }
  return dataTypeFormat || null;
};

export const mapMatrix = <T>(
  src: T[][],
  callback: (el?: T, rowIndex?: number, columnIndex?: number) => T
): T[][] => {
  const matrix = src.map((r) => [...r]);
  for (let r = 0; r < matrix.length; r += 1) {
    for (let c = 0; c < matrix[r].length; c += 1) {
      matrix[r][c] = callback(matrix[r][c], r, c);
    }
  }
  return matrix;
};

export const buildGetColumnFunction = (
  datasetId: string,
  columnIndex: number,
  rowIndex: number
) =>
  `=SYNERGIES.GETDATAFROMCOLUMN("${datasetId}", ${columnIndex}, ${rowIndex})`;

export const buildGetTotalFunction = (
  datasetId: string,
  columnIndex: number,
  totalIndex: number
) =>
  `=SYNERGIES.GETTOTALFROMCOLUMN("${datasetId}", ${columnIndex}, ${totalIndex})`;

export const regxGetColumnFunction =
  /=SYNERGIES\.GET(?:TOTAL|DATA)FROMCOLUMN\("(dataset_.*?)", (.*?), (.*?)\)/;
// TODO -- Separate parents "one";"two";"three"
export const regxGroupTableRow =
  /=SYNERGIES\.GROUPTABLEROW\("(dataset_.*?)", (TRUE|FALSE), \{(.*?)\}\)/;

export const getDatasetStreammingColumns = (
  dataset: IDataset,
  formulas: Excel.Range['formulas']
) => {
  const columns: IColumn[] = [dataset.columns[0]];

  for (let col = 1; col < formulas[0].length; col += 1) {
    for (let row = 0; row < formulas.length; row += 1) {
      const match = formulas[row][col].match(regxGetColumnFunction);
      if (match) {
        const column = dataset.columns[match[2]];
        if (column) {
          columns.push(column);
          break;
        }
      }
    }
  }
  return columns;
};

export const escapeExcelSpecialCharacters = (input: any) => {
  // There are special characters in excel that are shortcuts
  // @ - is a shortcut for function, but a valid Entity name
  if (typeof input !== 'string') return input;

  let output = input;

  if (output.startsWith('@')) {
    output = `'${output}`;
  }

  return output;
};

export const unescapeExcelSpecialCharacters = (input: string) => {
  if (input.startsWith("'@")) {
    return input.substring(1);
  }
  return input;
};

export const generateColumnHeader = (column: IColumn) => {
  const title = escapeExcelSpecialCharacters(column.name);
  if (column.aggregation) {
    return `${getAggregations()[column.aggregation].label}\n${title}`;
  }
  return title;
};

export const removeColumnsAggregation = (columns: IColumn[]) =>
  columns.map((col) => ({ ...col, aggregation: AGGREGATION_METHODS.null }));
export const getAggregatedValues = (
  values: IColumn[],
  columnAgg: IAggregationMethod,
  rowAgg: IAggregationMethod
): IColumn[] =>
  values.map((column) => ({
    ...column,
    aggregation:
      AGGREGATION_METHODS[columnAgg || null] ||
      AGGREGATION_METHODS[rowAgg || null] ||
      column.aggregation,
  }));

export const bindThunkActionCreators = <T extends ActionCreatorsMapObject>(
  actions: T
) =>
  bindActionCreators<T, MappedThunkActions<T>>(
    actions,
    window.sharedState.store.dispatch
  );

/**
 * Adds custom filters to the columns to be mapped
 * @param column
 */

export const applyCustomFiltersToColumn = (column: IColumn) => {
  const { getState } = window.sharedState.store;
  const { setCustomFilters } = bindThunkActionCreators(actionCreators);
  const { customFilters } = getState().streams;
  const filters =
    customFilters.find((customFilter) =>
      areColumnsEqual(customFilter.column, column)
    )?.filters || [];
  if (filters.length) {
    setCustomFilters(
      customFilters.filter(
        (customFilter) => !areColumnsEqual(customFilter.column, column)
      )
    );
    return { ...column, filters: [...column.filters, ...filters] };
  }
  return column;
};

/**
 * Applies active filters to the current dataset
 * @param columns
 * @param dataset
 */

export const applyColumnFilters = (columns: IColumn[], dataset: IDataset) => {
  const { getState } = window.sharedState.store;
  const { editDataset, setCustomFilters } =
    bindThunkActionCreators(actionCreators);
  const { customFilters, currentStreamId } = getState().streams;

  let filterColumns: IColumn[] = [];
  const columnsWithFilters = columns.map((column) =>
    applyCustomFiltersToColumn(column)
  );

  if (dataset.columns.length) {
    const columnsToAdd = columnsWithFilters.map(
      (col) =>
        dataset.filterColumns.find((filterColumn) =>
          areColumnsEqual(col, filterColumn)
        ) || col
    );
    filterColumns = dataset.filterColumns.filter(
      (fCol) => !columnsWithFilters.some((col) => areColumnsEqual(col, fCol))
    );

    return editDataset(dataset.id, {
      columns: [...dataset.columns, ...columnsToAdd],
      filterColumns,
    });
  }

  filterColumns = customFilters
    .filter((customFilter) => customFilter.streamId === currentStreamId)
    .reduce<IColumn[]>((arr, curr) => {
      arr.push({
        ...curr.column,
        filters: curr.filters,
      });
      return arr;
    }, []);

  setCustomFilters(
    customFilters.filter(
      (customFilter) => customFilter.streamId !== currentStreamId
    )
  );

  return editDataset(dataset.id, {
    columns: columnsWithFilters,
    filterColumns,
  });
};

/**
 * Provides a new dataset with the passed columns, or adds them
 * to the currently active dataset.
 */

export const provideDataset = ({
  datasetId,
  type,
}: {
  datasetId: string | null;
  type?: ObjectTypes;
}) => {
  const { getState } = window.sharedState.store;
  const { editDataset, addDataset, setCurrentDatasetId } =
    bindThunkActionCreators(actionCreators);
  const {
    streams: { datasets, currentStreamId, defaultDatasetOptions },
    app: { activeObjectType },
    clientPreferences: { records: preferences },
  } = getState();

  const pageSizePreference = preferences.find(
    (preference) => preference.id === UserPreferenceIds.PAGE_SIZE
  );

  const pageSize = Number(pageSizePreference?.value) || defaultPageSize;

  const { dataOnDemand } = defaultDatasetOptions;

  let dataset: IDataset;
  const currentType = type || activeObjectType;

  if (datasetId) {
    dataset = editDataset(datasetId, {
      continuation: { pageSize, token: null },
    });
  } else {
    dataset = addDataset({
      type: currentType,
      id: dataOnDemand ? `dataset_${datasets.length}` : TEMP_DATASET,
      streamId: currentStreamId,
      columns: [],
      filterColumns: [],
      rows: null,
      address: null,
      tableId: null,
      continuation: { pageSize, token: null },
      lastSuccessfulMapping: {
        columns: [],
      },
      totalRowCount: null,
      totals:
        currentType === ObjectTypes.TABLE ? [{ type: null, rows: [[]] }] : [],
      options: defaultDatasetOptions,
    });
    if (!dataOnDemand) {
      setCurrentDatasetId(TEMP_DATASET);
    }
  }

  return dataset;
};

export const fetchDatasetData = async (dataset: IDataset) => {
  const { fetchColumnData, editDataset } =
    bindThunkActionCreators(actionCreators);

  try {
    const data = await fetchColumnData(
      dataset.columns,
      dataset.continuation,
      dataset.filterColumns
    );

    return editDataset(dataset.id, {
      rows: data.rows,
      totalRowCount: data.totalRowCount,
      continuation: data.continuation,
    });
  } catch (e) {
    console.error(e);
    reportError(e);
    throw new Error(e.message);
  }
};

const getType = (index: number, query: IInquiryData, table: IGroupTable) => {
  if (!query.columns[index]) {
    return null;
  }

  const { id } = query.columns[index];
  if (table.columns.find((item) => item.id === id)) {
    return 'columns';
  }
  if (table.rows.find((item) => item.id === id)) {
    return 'rows';
  }
  return 'values';
};

export const getDataType = (index: number, query: IInquiryData) =>
  query?.columns?.[index]?.dataType;

type EscapeToExcelValidDataParams = {
  value: any;
  dataType: IColumnDataType;
  aggregation: IColumnAggregationMethods | null;
  formatType: IColumnFormatType;
};
export const escapeToExcelValidData = ({
  aggregation,
  dataType,
  formatType,
  value,
}: EscapeToExcelValidDataParams) => {
  // To avoid conflict between 'null' and null values in the group table
  // we render null as '__null' in the worksheet

  if (value === null) return excelEscapedNull;
  if (
    [COLUMN_DATA_TYPES.DateTime].includes(dataType) &&
    [
      AGGREGATION_METHODS.null,
      AGGREGATION_METHODS.Minimum,
      AGGREGATION_METHODS.Maximum,
    ].includes(aggregation)
  ) {
    return formatDateToExcelDate(value);
  }

  if (
    COLUMN_DATA_TYPES.Text === dataType &&
    formatType === IColumnFormatType.Uri
  ) {
    return `=HYPERLINK("${value}")`;
  }

  if (COLUMN_DATA_TYPES.Text === dataType) {
    return escapeExcelSpecialCharacters(value);
  }

  return value;
};

export const restoreEscapedData = (value: any, dataType: IColumnDataType) => {
  if (value === excelEscapedNull) {
    return null;
  }

  if ([COLUMN_DATA_TYPES.DateTime].includes(dataType)) {
    return formatExcelDateToDate(value);
  }

  if (COLUMN_DATA_TYPES.Text === dataType) {
    return unescapeExcelSpecialCharacters(value);
  }

  return value;
};

export const groupMatrixByFirstColumn = (
  matrix: any[][],
  dataType: IColumnDataType
) =>
  matrix.reduce((obj, curr) => {
    const key = escapeToExcelValidData({
      value: curr[0],
      dataType,
      aggregation: null,
      formatType: null,
    });
    return {
      ...obj,
      [key]: [...(obj[key] || []), curr.slice(1)],
    };
  }, {});

export const groupByType = (
  obj: any,
  index: number,
  query: IInquiryData,
  table: IGroupTable
) => {
  const type = getType(index, query, table);
  const dataType = getDataType(index, query);
  if (!type) {
    Object.keys(obj).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = {};
    });
    return;
  }

  Object.keys(obj).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    obj[key] = {
      ...(!Array.isArray(obj[key]) ? obj[key] : {}),
      [type]:
        type === 'values'
          ? obj[key][0]
          : groupMatrixByFirstColumn(obj[key], dataType),
    };
  });

  if (type === 'values') {
    return;
  }

  Object.keys(obj).forEach((key) => {
    groupByType(obj[key][type], index + 1, query, table);
  });
};

export const serializeObjectKeys = (input: any, childrenFirst = false) => {
  const output: string[] = [];

  const getKeysFromObject = (currentObject: any, parentIndex = 0) => {
    Object.keys(currentObject || {}).forEach((key) => {
      output.splice(output.length - (childrenFirst ? parentIndex : 0), 0, key);
      if (Object.keys(currentObject?.[key] || {}).length) {
        getKeysFromObject(currentObject[key], parentIndex + 1);
      }
    });

    return output;
  };

  return getKeysFromObject(input);
};

export const nestedSerializeObjectKeys = (
  input: any,
  childrenFirst = false
) => {
  const output: string[][] = [];

  const getKeysFromObject = (obj: any, parents: string[] = []) => {
    Object.keys(obj || {}).forEach((key) => {
      output.splice(output.length - (childrenFirst ? parents.length : 0), 0, [
        ...parents,
        key,
      ]);
      if (Object.keys(obj?.[key] || {}).length) {
        getKeysFromObject(obj[key], [...parents, key]);
      }
    });
    return output;
  };

  return getKeysFromObject(input);
};

export const fetchTotalsIntersection = async (dataset: IDataset) => {
  const { fetchColumnData } = bindThunkActionCreators(actionCreators);
  const { getState } = window.sharedState.store;
  const table = getState().groupTable[dataset.id];

  if (!table.totals.rows || !table.totals.columns || !getIsColumnLayout(table))
    return [];

  const values = getColumnsFromDataset(table.values, dataset);
  const tableRows = getColumnsFromDataset(table.rows, dataset);
  const tableColumns = getColumnsFromDataset(table.columns, dataset);

  const tableItems = [...tableRows, ...tableColumns, ...values];

  const data = await fetchColumnData(values, null, [
    ...dataset.filterColumns,
    ...getNonRepeatingColumns(tableItems, values),
  ]);

  return getEscapedMatrixValues({
    src: data.rows,
    groupTable: table,
    dataset,
  })[0];
};

export const fetchAllColumnDataPages = async (
  columns: IColumn[],
  filterColumns: IColumn[] = []
) => {
  const { fetchColumnData } = bindThunkActionCreators(actionCreators);

  let data: IInquiryData = {
    columnCount: 0,
    rowCount: 0,
    totalRowCount: 0,
    columns: [],
    rows: [],
    continuation: { pageSize: PAGE_SIZE, token: null },
  };

  const recursiveFetch = async () => {
    const { continuation, rows, ...res } = await fetchColumnData(
      columns,
      data.continuation,
      filterColumns
    );
    data = { ...res, rows: [...data.rows, ...rows], continuation };
    if (continuation) {
      await recursiveFetch();
    }
  };
  await recursiveFetch();
  return data;
};

export const removeGroupTableKeys = (keys: string[], tableKeys: string[][]) =>
  tableKeys.filter(
    (currentKeys) => !isEqual(currentKeys.slice(0, keys.length), keys)
  );

export const generateRowFunction = (
  key: string,
  parents: string[],
  datasetId: string,
  state: IDrillingState = DRILLING_STATE.FALSE
) =>
  `=SYNERGIES.GROUPTABLEROW("${datasetId}", ${state}, {${[...parents, key]
    .map((title) => `"${title}"`)
    .join(';')}})`;

export const rowsTitlesToFunctions = (
  rowTitles,
  defaultParents = [],
  datasetId: string
) => {
  const functions = [];
  // eslint-disable-next-line no-shadow
  const serializeTitles = (titles, parents) => {
    Object.keys(titles).forEach((key) => {
      functions.push(generateRowFunction(key, parents, datasetId));
      serializeTitles(titles[key], [...parents, key]);
    });
  };
  serializeTitles(rowTitles, defaultParents);
  return functions;
};

export const findNestedProperty = (obj, parents: string[] = []) => {
  let result = obj;
  for (let i = 0; i < parents.length; i++) {
    result = result[parents[i]];
  }
  return result;
};

export const handleGroupTableData = (
  groupTable: IGroupTable,
  dataset: IDataset,
  rowKeys: string[] = [],
  columnKeys: string[] = []
) => {
  const rowsTitles = {};
  const columnTitles = {};
  const values = [];
  const columnsChildrenFirst =
    !rowKeys.length &&
    dataset.options.columnParentDrilling === PARENT_DRILLING.after;
  const rowsChildrenFirst =
    !columnKeys.length &&
    dataset.options.rowParentDrilling === PARENT_DRILLING.after;

  const groupTableData = (
    data: IGroupedRow | IGroupedColumn,
    rowParents = [],
    columnParents = []
  ) => {
    Object.keys(data)
      .sort()
      .forEach((key) => {
        switch (key) {
          case 'rows':
            Object.keys(data[key]).forEach((value) => {
              findNestedProperty(rowsTitles, rowParents)[value] = {};
              groupTableData(
                data[key][value],
                [...rowParents, value],
                columnParents
              );
            });
            break;
          case 'columns':
            Object.keys(data[key]).forEach((value) => {
              // eslint-disable-next-line no-param-reassign
              if (!findNestedProperty(columnTitles, columnParents)[value]) {
                findNestedProperty(columnTitles, columnParents)[value] = {};
              }
              groupTableData(data[key][value], rowParents, [
                ...columnParents,
                value,
              ]);
            });
            break;
          default:
            break;
        }
      });
  };

  let rowIndex = -1;

  const getValuesByColumn = (
    columns: IGroupedColumn['columns'],
    colKeys,
    columnKeysIndex = 0
  ) => {
    Object.keys(colKeys).forEach((col) => {
      if (columnKeys[columnKeysIndex] && columnKeys[columnKeysIndex] !== col) {
        return;
      }
      // TODO: depending on the dataset's Parent drilling, getValuesByColumn should be
      // executed before or after. We need to find a better way of writing this.
      if (columnsChildrenFirst && columns[col]?.columns) {
        getValuesByColumn(
          columns[col].columns,
          colKeys[col],
          columnKeysIndex + 1
        );
      }
      const colValues = columns[col]?.values;
      if (!columnKeys[columnKeysIndex]) {
        const filler = Array(
          groupTable.values.length *
            (serializeObjectKeys(colKeys[col]).length + 1)
        ).fill('');
        values[rowIndex] = [
          ...(values[rowIndex] || []),
          ...(colValues || filler),
        ];
      }
      if (!columnsChildrenFirst && columns[col]?.columns) {
        getValuesByColumn(
          columns[col].columns,
          colKeys[col],
          columnKeysIndex + 1
        );
      }
    });
  };

  const getValuesByRow = (rows: IGroupedRow['rows']) => {
    Object.keys(rows).forEach((row) => {
      if (rowsChildrenFirst && rows[row].rows) {
        getValuesByRow(rows[row].rows);
      }
      rowIndex += 1;
      values[rowIndex] = undefined;
      if (rows[row].values) {
        values[rowIndex] = rows[row].values;
      } else if (rows[row].columns) {
        getValuesByColumn(rows[row].columns, columnTitles);
      }
      if (!rowsChildrenFirst && rows[row].rows) {
        getValuesByRow(rows[row].rows);
      }
    });
  };

  groupTableData(groupTable.data);
  let valuesData = groupTable.data;

  rowKeys.forEach((key) => {
    valuesData = valuesData.rows[key];
  });

  getValuesByRow(valuesData.rows);

  return {
    rowsTitles,
    columnTitles,
    values,
  };
};

/**
 * Ignore columns that cannot aggregate with total type of aggregation
 */

export const getColumnsWithTotalTypeAggregations = (
  type: IColumnAggregationMethods,
  columns: IColumn[]
) => {
  if (type) {
    return columns.map((col) => {
      const { method } = getAggregations()[type];
      if (getInvalidAggregationMethods(col).includes(method)) {
        return { ...col, aggregation: null };
      }
      return { ...col, aggregation: method };
    });
  }
  return columns;
};

/**
 * Get only not-repeated columns with aggregations.
 */

export const getUniqueColumns = (columns: IColumn[]) =>
  columns.reduce<IColumn[]>((arr, column) => {
    if (!arr.some((col) => areColumnsEqual(col, column))) {
      return [...arr, column];
    }
    return arr;
  }, []);

const isColumnTotalable = (column: IColumn) =>
  !!column.aggregation ||
  (column.streamElementTypeId === TYPE_IDS.StreamCalculation &&
    column.dataType !== IColumnDataType.Text &&
    column?.isAggregated);

export const getTotalableColumns = (columns: IColumn[]) =>
  getUniqueColumns(columns).filter(isColumnTotalable);

export const fetchDatasetTotals = async (dataset: IDataset) => {
  const { fetchColumnData, editDataset } =
    bindThunkActionCreators(actionCreators);

  const totals = await Promise.all(
    dataset.totals.map(async (total) => {
      const columns = getColumnsWithTotalTypeAggregations(
        total.type,
        dataset.columns
      );

      const totalColumns = getTotalableColumns(columns);

      const data = totalColumns.length
        ? await fetchColumnData(totalColumns, null, [
            ...getNonRepeatingColumns(dataset.columns, totalColumns),
            ...dataset.filterColumns,
          ])
        : null;

      const rows = [
        columns.map((col, i) => {
          /**
           * If is the first column, and it has no aggregation, map label
           */
          if (!isColumnTotalable(col)) {
            if (i === 0) {
              return !total.type
                ? 'Total'
                : `Total ${getAggregations()[total.type].label}`;
            }
            return '-';
          }
          const index = totalColumns.findIndex((column) =>
            areColumnsEqual(col, column)
          );
          return data?.rows?.[0]?.[index] || '-';
        }),
      ];

      return { ...total, rows };
    })
  );

  return editDataset(dataset.id, {
    totals,
  });
};

export const applyCustomAggregations = (dataset: IDataset) => {
  const { getState } = window.sharedState.store;
  const { editDataset, setCustomAggregations } =
    bindThunkActionCreators(actionCreators);
  const { customAggregations } = getState().streams;

  const mutableAggregations = [...customAggregations];

  const columns = dataset.columns
    .reverse()
    .map((col) => {
      const customAgg = mutableAggregations.find((customAggregation) =>
        areColumnsEqual(customAggregation.column, col)
      );
      if (customAgg) {
        mutableAggregations.splice(
          mutableAggregations.findIndex(
            (ca) => areColumnsEqual(ca.column, col),
            1
          )
        );
        return { ...col, aggregation: customAgg.aggregation };
      }
      return col;
    })
    .reverse();

  setCustomAggregations(mutableAggregations);

  return editDataset(dataset.id, {
    columns,
  });
};

export const formatTotalsRows = (range: Excel.Range, dataset: IDataset) => {
  range.set({
    format: {
      ...tableHeadersStylesFormat,
    },
    numberFormat: [
      dataset.columns.map((column) =>
        isColumnTotalable(column) ? getFormat(column) : null
      ),
    ],
  });
};

export const getTotalColumnHorizontalAlignment = (
  column: IColumn
): Excel.HorizontalAlignment => {
  // every calculation is a numeric
  if (column.aggregation) {
    return Excel.HorizontalAlignment.right;
  }

  switch (column.dataType) {
    case IColumnDataType.DateTime:
    case IColumnDataType.NumericDecimal:
    case IColumnDataType.NumericInteger:
      return Excel.HorizontalAlignment.right;
    default:
      return undefined;
  }
};

export const formatTotalsByColumn = (
  range: Excel.Range,
  columns: IColumn[]
) => {
  columns.forEach((col, i) => {
    const colRange = range.getColumn(i);
    colRange.format.horizontalAlignment =
      getTotalColumnHorizontalAlignment(col);
  });
};

// convert A to 1, Z to 26, AA to 27
export const columnLettersToNumber = (letters: string) => {
  let n = 0;
  for (let p = 0; p < letters.length; p++) {
    n = letters[p].charCodeAt(null) - 64 + n * 26;
  }
  return n;
};

// convert 1 to A, 26 to Z, 27 to AA
export const columnNumberToLetters = (number: number) => {
  let temp: number;
  let letters = '';
  while (number > 0) {
    temp = (number - 1) % 26;
    letters = String.fromCharCode(temp + 65) + letters;
    number = (number - temp - 1) / 26;
  }
  return letters;
};

export const getGroupTableValuesFormat = ({
  table,
  dataset,
  withColumnTotal = false,
  columnDrilling,
  rowDrilling,
}: IGetGroupTableValuesFormat) => {
  const { columnTitles } = handleGroupTableData(table, dataset);
  const columnCount = serializeObjectKeys(columnTitles, true)?.length || 0;
  const columnTotal = table.totals.columns && withColumnTotal ? 1 : 0;
  // const colAggregation = AGGREGATION_METHODS[table.columns[columnDrilling]?.aggregationType || null];
  const colAggregation =
    AGGREGATION_METHODS[
      getDatasetColumnByColumnUuid({
        dataset,
        columnUuid: table.columns[columnDrilling]?.columnUuid,
      })?.aggregation || null
    ];
  const rowAggregation =
    AGGREGATION_METHODS[
      getDatasetColumnByColumnUuid({
        dataset,
        columnUuid: table.rows[rowDrilling]?.columnUuid,
      })?.aggregation || null
    ];
  // const rowAggregation = AGGREGATION_METHODS[table.rows[rowDrilling]?.aggregationType || null];

  return Array(Math.max(1, columnCount + columnTotal))
    .fill(
      table.values
        .map((valueAssociatedItem) =>
          dataset.columns.find(
            (c) => generateColumnUuid(c) === valueAssociatedItem.columnUuid
          )
        )
        .map((associatedColumn) =>
          getFormat({
            ...associatedColumn,
            aggregation:
              colAggregation ||
              rowAggregation ||
              AGGREGATION_METHODS[associatedColumn.aggregation],
          })
        )
    )
    .flat();
};

/**
 * Returns a colum-row pair for the first cell of a given address
 * @param address Range address
 */

export const getCellColumnRowPair = (address: string) =>
  address.match(/([A-Z]+)(\d+)/)?.slice(1) || [null, null];

// e,g 'Sheet2!A1:D9' -> A
// e,g 'Sheet6!AC1:ZZ9' -> AC
export const getFirstColumnLetterFromAddress = (address: string) => {
  const withoutNumbers = address.replace(/[0-9]/g, '');
  const firstAndLastColumnLetters = withoutNumbers.split('!')[1];

  return firstAndLastColumnLetters.split(':')[0];
};

export const generateExcelRangeLinkMatrix = (address: string): string[][] => {
  const getSheetNameRegx = /(.*)(?:\!)/;
  const getColumnRowPairRegx = /([A-Z]+)(\d+)/g;

  const sheetName = address.match(getSheetNameRegx)[1];
  const output: string[][] = [];
  let matches = [];
  // eslint-disable-next-line no-const-assign
  // eslint-disable-next-line no-cond-assign
  while ((matches = getColumnRowPairRegx.exec(address))) {
    output.push(matches);
  }
  const columnRowPairs = output.map((match) => match.slice(1));

  const matrix: string[][] = [];
  for (let row = +columnRowPairs[0][1]; row <= +columnRowPairs[1][1]; row++) {
    const matrixRow = [];
    for (
      let col = columnLettersToNumber(columnRowPairs[0][0]);
      col <= columnLettersToNumber(columnRowPairs[1][0]);
      col++
    ) {
      matrixRow.push(`=${sheetName}!${columnNumberToLetters(col)}${row}`);
    }
    matrix.push(matrixRow);
  }

  return matrix;
};

type GenericFunction = (...args: any) => any;

export const generateMock =
  <T extends GenericFunction>(fn: T) =>
  () =>
    console.log(`mock of ${fn.name} called`) as ReturnType<T>;

/**
 * Returns factor used to calc table column width based on
 * the text content lenght
 * @param length String lenght
 */
export const getWidthFactor = (length: number) => {
  switch (true) {
    case length >= 30:
      return 6;
    case length >= 10:
      return 7;
    default:
      return 2;
  }
};

export const getEscapedDatasetTotals = (dataset: IDataset) =>
  dataset.totals
    .flatMap((total) => total.rows)
    .map((row) =>
      row.map((value, index) =>
        escapeToExcelValidData({
          value,
          dataType: dataset.columns[index].dataType,
          aggregation: dataset.columns[index].aggregation,
          formatType: dataset.columns[index].formatType,
        })
      )
    );


export function pickTextColorBasedOnBgColorSimple(bgColor: string) {
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
    baseColors.textColor : baseColors.white;
}