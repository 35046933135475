import { Label, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataType } from '../../../../../api/model/schemas/DataType';
import DataSourceDropdown, {
  IDataSource,
} from '../../../../../components/DataSourceDropdown';
import EntityFieldDropdown from '../../../../../components/EntityFieldDropdown';
import { classNames } from '../SelectOneOfType/styles';
import { IEntity } from './types';
import { getCurrentEntity } from './utils';

interface Props {
  label?: string;
  fieldId: string;
  sourceId: string;
  onSourceChange?: (id: string) => void;
  onFieldChange: (id: string) => void;
  sources: IDataSource[];
  entities: IEntity[];
  isDisabled?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  dataType?: DataType;
  'data-testid': string;
  allowedDataTypes?: DataType[];
}

const LinkField: React.FC<Props> = ({
  fieldId,
  sourceId,
  onFieldChange,
  onSourceChange,
  sources,
  entities,
  isDisabled,
  isRequired,
  isLoading,
  dataType,
  'data-testid': testId,
  label,
  allowedDataTypes,
}) => {
  const { t } = useTranslation();
  const currentEntity = useMemo(
    () => getCurrentEntity(entities, fieldId),
    [entities, fieldId]
  );

  const handleSourceChange = useMemo(
    () =>
      onSourceChange &&
      ((id: string) => {
        onSourceChange(id);
        onFieldChange(null);
      }),
    [onFieldChange, onSourceChange]
  );

  return (
    <Stack
      data-testid={testId}
      data-is-disabled={isDisabled || !entities.length}
    >
      <Label required={isRequired}>
        {label || currentEntity?.name || t('filters:Select')}
      </Label>
      <Stack horizontal>
        <DataSourceDropdown
          activeDataSourceId={sourceId}
          onChangeDataSource={handleSourceChange}
          dataSources={sources}
          isDisabled={isDisabled}
        />
        <EntityFieldDropdown
          onChange={onFieldChange}
          activeFieldId={fieldId}
          entities={entities}
          isDisabled={isDisabled || !entities.length}
          dataType={dataType}
          allowedDataTypes={allowedDataTypes}
          // eslint-disable-next-line react/jsx-props-no-spreading
        />
        {isLoading && (
          <Spinner className={classNames.spinner} size={SpinnerSize.small} />
        )}
      </Stack>
      {currentEntity?.rowCount && (
        <Text variant="tiny">
          {`${currentEntity.rowCount} ${t(
            'modules:designer:sections:ingest:modes:links:records'
          )}`}
        </Text>
      )}
    </Stack>
  );
};

export default LinkField;
