import { CommandButton, Spinner } from '@fluentui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataSourceIcon from '../../../../components/DataSourceIcon';
import { numberToHexColor } from '../../../../components/ui/ColorPicker/utils';
import { assetsDataGroupClicked } from '../../actions';
import { createGetSourceData } from '../../selectors';
import { StreamAssetType } from '../../types';
import { useFetchEntities } from './useFetchEntities';
import { classNames } from './styles';

import { SingleIslandResultProps } from './types';

export const SingleIslandResult = ({
  singleSolutionIsland,
  setClosedResults,
  areResultsClosed,
  normalizedStreamLinksByLinkId,
  isLastResultOpen,
  dataSourceId,
}: SingleIslandResultProps) => {
  // DEPS
  const getSourceData = React.useMemo(createGetSourceData, []);
  const dispatch = useDispatch();
  const history = useHistory();

  // HOOKS
  const { isLoading: isLoadingEntities, normalizedEntities } =
    useFetchEntities(dataSourceId);

  // STATE
  const sourceData = useSelector((state) => getSourceData(state, dataSourceId));

  // CALLBACKS
  const handleClose = React.useCallback(() => {
    setClosedResults((prev) => [...prev, dataSourceId]);
    if (isLastResultOpen) {
      history.go(-1);
    }
  }, [dataSourceId, isLastResultOpen]);

  const handleAcceptSolution = () => {
    const entities = singleSolutionIsland.relatedFields
      // skip links already included in Stream (as StreamLinks)
      .filter((linkField) => !normalizedStreamLinksByLinkId[linkField.parentId])
      // StreamLink requires Link id, not LinkFields id
      .map((linkFields) => ({
        id: linkFields.parentId,
      }));

    dispatch(
      assetsDataGroupClicked({
        entities,
        // enforce ADD
        selectedEntitiesIds: [],
        assetType: StreamAssetType.Link,
      })
    );

    handleClose();
  };

  // RENDER
  if (areResultsClosed) return null;

  return (
    <div className={classNames.wrapper}>
      {isLoadingEntities ? (
        <Spinner />
      ) : (
        <>
          <div className={classNames.boxHeader}>
            <div className={classNames.sourceWrapper}>
              <DataSourceIcon color={numberToHexColor(sourceData?.color)} />
              <p className={classNames.sourceName}>{sourceData?.name}</p>
            </div>
            <div>
              <CommandButton
                iconProps={{ iconName: 'Accept' }}
                onClick={handleAcceptSolution}
                className={classNames.controlButton}
              />
              <CommandButton
                iconProps={{ iconName: 'Cancel' }}
                onClick={handleClose}
                className={classNames.controlButton}
              />
            </div>
          </div>

          {singleSolutionIsland.relatedFields.map((linkField) => (
            <div key={linkField.id} className={classNames.linkWrapper}>
              <p className={classNames.linkEntities}>
                {`${normalizedEntities[linkField.from.parentId]?.name} and ${
                  normalizedEntities[linkField.to.parentId]?.name
                }`}
              </p>
              <p
                className={classNames.linkFields}
              >{`${linkField.from.name} = ${linkField.to.name}`}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
