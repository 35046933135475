import axios from 'axios';

import { compose } from 'redux';
import { getEnvironmentUrl } from '../modules/Settings/components/connection/utils';
import { applyMultiPageResponseInterceptor } from './interceptors/multiPage';
import { applyI18nInterceptor } from './interceptors/i18n';
import { applyAuthTokenInterceptor } from './interceptors/authToken';
import { applyCountActiveRequests } from './interceptors/requestsCount';
import { applyErrorInterceptor } from './interceptors/error';

export const getClient = () => {
  const instance = axios.create({
    // NOTE: this kinda prevents us from instantiating the client once – as long as we use a `baseURL` we need to make
    // sure it's in sync with the environment the user decided to connect with (which might change in runtime)
    baseURL: getEnvironmentUrl(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return compose(
    applyCountActiveRequests,
    applyI18nInterceptor,
    applyAuthTokenInterceptor,
    applyMultiPageResponseInterceptor,
    applyErrorInterceptor
  )(instance);
};

export default getClient;
