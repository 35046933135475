import React from 'react';
import FilterCondition from '../../../../components/FilterCondition';
import { GroupCollapse } from '../../../../components/ui';
import { ENTITY_FIELD_SLOT_WIDTH_IN_PX } from '../../../../constants/design';
import { StreamerFilter } from '../../types';
import { streamerFilterToDesignerFilter } from './utils';

interface Group {
  label: string;
  filters: StreamerFilter[];
}

interface Props {
  group: Group;
  handleDeleteFilter(filter: any): void;
  handleEdit(filter: any): void;
  handleOpenGroup: (id: string) => void;
  isGroupOpen: (id: string) => boolean;
  selectedFilterForEdit: StreamerFilter | null;
}

export const FilterGroup = ({
  group,
  handleDeleteFilter,
  handleEdit,
  handleOpenGroup,
  isGroupOpen,
  selectedFilterForEdit,
}: Props) => (
  <GroupCollapse
    key={`entity_${group.label}`}
    groupName={group.label}
    groupId={group.label}
    onClick={handleOpenGroup}
    isOpen={isGroupOpen(group.label)}
  >
    {group.filters.map((filter) => (
      <FilterCondition
        key={filter.id}
        filter={streamerFilterToDesignerFilter(filter)}
        onEdit={handleEdit}
        onDelete={handleDeleteFilter}
        spacing={ENTITY_FIELD_SLOT_WIDTH_IN_PX}
        id={`filter-${filter.id}`}
        isEditing={selectedFilterForEdit?.id === filter.id}
        isSelected={selectedFilterForEdit?.id === filter.id}
      />
    ))}
  </GroupCollapse>
);
