import { createSelector } from 'reselect';
import { ApplicationState } from '../../../store/types';
import { StreamerLimitedComparisons } from '../types';

const getStreamerFiltersState = (state: ApplicationState) =>
  state.streamer.filters;

export const getAllStreamerFilters = (state: ApplicationState) =>
  getStreamerFiltersState(state).records;

export const createGetAssetValueFilter = (assetId: string) =>
  createSelector([getAllStreamerFilters], (allFilters) =>
    allFilters.find(
      (f) =>
        f.item?.id === assetId &&
        f?.comparison === StreamerLimitedComparisons.In
    )
  );
