export enum PropertyDataType {
  Any = 'Any',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  Embedded = 'Embedded',
  File = 'File',
  Guid = 'Guid',
  Json = 'Json',
  None = 'None',
  NumericDecimal = 'NumericDecimal',
  NumericInteger = 'NumericInteger',
  Object = 'Object',
  SecuredText = 'SecuredText',
  Text = 'Text',
}