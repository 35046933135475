import React from 'react';
import { useSelector } from 'react-redux';
import { getSortType } from '../../../App/selectors';
import { DefinedSortTypes } from '../../../App/types';
import { ByValues } from '../components/ByValues';
import { StreamerFilters } from '../components/StreamerFilters';

export const Index = () => {
  const sortType = useSelector(getSortType);

  if (sortType === DefinedSortTypes.Logic) {
    return <StreamerFilters />;
  }

  if (sortType === DefinedSortTypes.Value) {
    return <ByValues />;
  }

  return null;
};
