import { mergeStyleSets } from '@fluentui/react';

export const classNames = mergeStyleSets({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'relative',
    flexGrow: 1,
    overflow: 'hidden',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
});
