import React from 'react';
import { GroupCollapse } from '../ui';
import useCollapseGroups from '../../hooks/useCollapseGroups';
import GroupValue from '../ui/GroupValue';

interface Field {
  id: string;
  name: string;
  values: Array<any>;
}

interface Entity {
  id: string;
  name: string;
  fields: Array<Field>;
}

interface Props {
  entities: Array<Entity>;
  formatValue?(value: any): string;
  isEntitySelected(entity: Entity): boolean;
  isFieldSelected(field: Field, entity: Entity): boolean;
  isValueSelected(value: any, field: Field, entity: Entity): boolean;
  onValueSelect(value: any, field: Field, entity: Entity): void;
}

const marginWrapper = {
  marginLeft: '32px',
};

const DoubleFoldableList: React.FC<Props> = ({
  entities,
  formatValue,
  isEntitySelected,
  isFieldSelected,
  isValueSelected,
  onValueSelect,
}) => {
  const {
    isGroupOpen: isEntityOpen,
    handleOpenGroup: handleOpenEntity,
  } = useCollapseGroups();
  const {
    isGroupOpen: isFieldOpen,
    handleOpenGroup: handleOpenField,
  } = useCollapseGroups();

  const handleFormatValue = (value) => {
    if (formatValue) {
      return formatValue(value);
    }

    return value;
  };

  if (!entities || !entities.length) {
    return null;
  }

  return (
    <>
      { entities.map((entity) => (
        <GroupCollapse
          key={`entity_${entity.id}`}
          groupName={entity.name}
          groupId={entity.id}
          onClick={(id) => handleOpenEntity(id)}
          isOpen={isEntityOpen(entity.id)}
          isSelected={isEntitySelected(entity)}
        >
          <div style={marginWrapper}>
            { entity.fields.map((field) => (
              <GroupCollapse
                key={`${entity.id}_field_${field.id}`}
                groupName={field.name}
                groupId={field.id}
                onClick={(id) => handleOpenField(id)}
                isOpen={isFieldOpen(field.id)}
                isSelected={isFieldSelected(field, entity)}
              >
                { field.values.map((value) => (
                  <GroupValue
                    onClick={() => onValueSelect(value, field, entity)}
                    isSelected={isValueSelected(value, field, entity)}
                  >
                    { handleFormatValue(value) }
                  </GroupValue>
                ))}
              </GroupCollapse>
            ))}
          </div>
        </GroupCollapse>
      ))}
    </>
  );
};

export default DoubleFoldableList;
