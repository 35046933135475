import { createSelector } from 'reselect';

import { ApplicationState } from '../../../store/types';
import { ContentLibraryState } from './reducer';

export const getContentLibraryState = (
  state: ApplicationState,
): ContentLibraryState => state.designerContentLibrary;

export const getAllDesigns = (
  state: ApplicationState,
) => getContentLibraryState(state).designs;

export const areDesignsLoading = (
  state:ApplicationState,
) => getContentLibraryState(state).isLoading;

export const getSelectedDesignId = (
  state: ApplicationState,
) => getContentLibraryState(state).selectedDesignId;

export const isDesignSelected = (
  state: ApplicationState,
) => !!getSelectedDesignId(state);

export const getDesignById = (
  id: string,
) => (state: ApplicationState) => getAllDesigns(state).find((design) => design.id === id);

export const getSelectedDesign = createSelector(
  getAllDesigns,
  getSelectedDesignId,
  (allDesigns, selectedDesignId) => allDesigns.find((design) => design.id === selectedDesignId),
);
