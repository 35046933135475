import { mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../../../../../theme';

export const getClassNames = (isOpen: boolean) =>
  mergeStyleSets({
    container: {
      width: isOpen ? 278 : 32,
      minHeight: 278,
      marginRight: 8,
      flexShrink: 0,
    },
    bg: {
      flexGrow: 1,
      overflow: 'auto',
    },
    header: {
      position: 'relative',
      cursor: 'pointer',
      marginBottom: 8,
      selectors: {
        '&:hover': {
          color: baseColors.lightBlack,
        },
        '&>i': {
          fontSize: 16,
          marginRight: 8,
          transform: 'rotate(-90deg)',
        },
        '&>span': {
          fontWeight: 600,
          textTransform: 'uppercase',
          flexGrow: 1,
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...(!isOpen
            ? {
                transform: 'rotate(90deg)',
                position: 'absolute',
                transformOrigin: '0px 16px',
                top: '20px',
              }
            : {}),
        },
      },
    },
  });
