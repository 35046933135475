import { AxiosInstance, AxiosResponse } from 'axios';

import { ApiV4ResponseWrapper } from '../../../modules/Designer/types';
import { isMatchingApiCall } from './utils';
import { apiChildrenPathRegex } from './children/constants';
import { mutablyParseChildrenResponse } from './children';
import { apiInteractionPathRegex } from './interaction/constants';
import { mutablyParseInteractionResponse } from './interaction';
import { apiEntityPathRegex, apiEntitiesPathRegex } from './entities/constants';
import {
  mutablyParseEntityResponse,
  mutablyParseEntitiesResponse,
} from './entities';

export const i18nInterceptor = (
  response: AxiosResponse<ApiV4ResponseWrapper<unknown>>
) => {
  if (isMatchingApiCall('GET', apiInteractionPathRegex, response))
    return mutablyParseInteractionResponse(response as any);
  if (isMatchingApiCall('GET', apiChildrenPathRegex, response))
    return mutablyParseChildrenResponse(response);
  if (isMatchingApiCall('GET', apiEntityPathRegex, response))
    return mutablyParseEntityResponse(response);
  if (isMatchingApiCall('GET', apiEntitiesPathRegex, response))
    return mutablyParseEntitiesResponse(response);

  return response;
};

export const applyI18nInterceptor = (
  instance: AxiosInstance
): AxiosInstance => {
  instance.interceptors.response.use(i18nInterceptor);

  return instance;
};
