export enum DataType {
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  Geography = 'Geography',
  Json = 'Json',
  NumericDecimal = 'NumericDecimal',
  NumericInteger = 'NumericInteger',
  Text = 'Text',
  Unknown = 'Unknown',
}
