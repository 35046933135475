/* eslint-disable no-empty */
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as tagsApi from '../../../Metadata/api';

import { CreatableDropdown } from '../../../../../components/ui';

export interface Props {
  designId: string,
  initialValue?: string[],
  isDisabled?: boolean,
  onChange(value: string[]): void,
}

const TagPicker = ({
  designId,
  initialValue,
  isDisabled = false,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = React.useState(false);
  const [isCreating, setCreating] = React.useState(false);
  const [allTags, setAllTags] = React.useState([]);
  const [tags, setTags] = React.useState(initialValue || []);

  React.useEffect(() => {
    const getAllTags = async () => {
      try {
        setLoading(true);
        // Reseting all tags seems to be required to refesh the options
        // in the react-select when refetching.
        setAllTags([]);
        const { data: responseData } = await tagsApi.fetchTags(designId)();

        const tagNames = responseData.data.map((tag) => tag.name);
        const sortedTags = [...tagNames].sort((a, b) => a?.localeCompare(b));

        setAllTags(sortedTags);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (!isCreating) {
      if (designId) {
        getAllTags();
      } else {
        setAllTags([]);
      }
    }
  }, [isCreating, designId]);

  const handleChange = (newValue: string[]) => {
    // NOTE: clearing out the tags from the picker sets its value to `null` which is not picked up by the generic
    // logic for building entity form requests thus no change is requested in the PATCH – setting an empty array
    // will ensure it is sent to the BE and should always properly clear tags if the picker is empty
    const nonNullableValue = newValue ?? []
    onChange(nonNullableValue);
    setTags(nonNullableValue);
  };

  const handleCreate = async (newTagName) => {
    try {
      setCreating(true);
      if (designId) {
        await tagsApi.createTag({ designId, tagData: { name: newTagName } });
      }
      onChange([...tags, newTagName]);
      setTags([...tags, newTagName]);
    } catch (error) {
    } finally {
      setCreating(false);
    }
  };

  // internally react-select library hold options as array of objects
  // {key: string, label:string}
  // when creating new object list of values is appended with such an object
  // however for our purposes we are really good with flat string[] list
  const getOptionValue = (option) => option?.label || option;
  const getOptionLabel = (option) => option?.label || option;

  return (
    <CreatableDropdown
      isDisabled={isDisabled || isCreating || (isLoading && allTags.length === 0)}
      isMulti
      onChange={handleChange}
      value={tags}
      options={allTags}
      label={null}
      menuPlacement="auto"
      defaultOptions
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      noOptionsMessage={() => t('wizard:noEntityDefined', { entityType: t('entityTypes:Tag', { count: 0 }) })}
      onCreateOption={handleCreate}
      formatCreateLabel={(tagName) => t('modeBar:metadata.newTagInForm', { tagName })}
    />
  );
};

export default TagPicker;
