import { GroupAsset } from '../../api/model/schemas/GroupAsset';
// import { LinkFields } from '../../api/model/schemas/LinkFields';
import { StreamCalculation } from '../../api/model/schemas/StreamCalculation';
import { StreamGroupAsset } from '../../api/model/schemas/StreamGroupAsset';
import { StreamHub } from '../../api/model/schemas/StreamHub';
import { StreamMachineLearning } from '../../api/model/schemas/StreamMachineLearning';
import { IStream, IStreamField } from '../Designer/types';

export enum StreamAssetType {
  Field = 'Field',
  Calculation = 'Calculation',
  Group = 'Group',
  Link = 'Link',
  Hub = 'Hub',
  MachineLearning = 'MachineLearning',
}
export interface UIEnhancedGroupAsset extends GroupAsset {
  parentGroupName: string;
}

export type EntitySelectableForStream = {
  id: string;
  [key: string]: any;
};
export type StreamEntity =
  | StreamMachineLearning
  | IStreamField
  | StreamCalculation
  | StreamGroupAsset
  | StreamHub;

export interface UIEnhancedStream extends IStream {
  parentDesignName: string;
}
