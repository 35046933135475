import { TextField } from '@fluentui/react';
import React, { useCallback, useMemo } from 'react';
import { IControlledField, IEntityProperty } from '../../types';

const MultiSelectBuilder: React.FC<IControlledField> = ({
  controller,
  label,
  isDisabled,
  entityProperty,
  interaction,
}) => {
  const {
    isReadOnly,
  }: IEntityProperty = entityProperty;
  const {
    isRequired,
  } = interaction;

  const { value, onChange } = controller;
  const content = useMemo(() => (value || []).join('\n'), [value]);
  const handleChange = useCallback((e) => (
    onChange(e.target.value.split('\n'))
  ), []);

  return (
    <TextField
      value={content}
      label={label}
      disabled={isDisabled || isReadOnly}
      required={isRequired}
      multiline
      onChange={handleChange}
    />
  );
};

export default MultiSelectBuilder;
