import React, { useEffect } from 'react';
import {
  Switch, Route, useHistory, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { routes } from '../../../../constants/routes';

import ErrorsView from '../ErrorsView';
import NotificationsScreen from '../NotificationsScreen';
import { getErrors, getNotifications } from '../../selectors';

const NotificationsIndex: React.FC = () => {
  const history = useHistory();
  const { records } = useSelector(getNotifications);
  const errors = useSelector(getErrors);

  useEffect(() => {
    if (errors.length > 0 && records.length === 0) {
      history.push(routes.notifications.errors.index);
    }
  }, []);

  return (
    <Switch>
      <Route path={routes.notifications.errors.index} component={ErrorsView} />
      <Route path={routes.notifications.records.index} component={NotificationsScreen} />
      <Redirect to={routes.notifications.records.index} />
    </Switch>
  );
};

export default NotificationsIndex;
