import { AxiosResponse } from "axios";
import { TypeIds } from "../../../model/schemas";
import { isResponseTypeIdMatching } from "../utils";
import { i18nNameKey, i18nCategoryKey } from "../constants";
import { translateApiName } from "../../../../config/i18n/utils";
import { ConnectorConfigurationType } from "../../../model/schemas/ConnectorConfigurationType";
import { ApiV4ResponseWrapper } from "../../../../modules/Designer/types";

export const isConnectorConfigurationTypeResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<ConnectorConfigurationType> =>
  isResponseTypeIdMatching(response, TypeIds.ConnectorConfigurationType)
    && !Array.isArray((response.data as any)?.data)

export const isConnectorConfigurationTypesResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<ApiV4ResponseWrapper<ConnectorConfigurationType[]>> =>
  isResponseTypeIdMatching(response, TypeIds.ConnectorConfigurationType)
    && Array.isArray((response.data as any)?.data)

export const parseConnectorConfigurationType = (connectorConfigurationType: ConnectorConfigurationType) => {
  return {
    ...connectorConfigurationType,
    // id of the connector configuration type is the type id used as a context for translations
    // e.g. id of "49f5de7c-7ba8-4647-9c23-4b90e2cbdaf1" is the type id for `OnPremisesSqlServerConfiguration`
    [i18nNameKey]: translateApiName(connectorConfigurationType.id, connectorConfigurationType.name),
    // translations for the data source categories are exported in the context of `DataSource` type id
    [i18nCategoryKey]: translateApiName(TypeIds.DataSource, connectorConfigurationType.category),
  };
}
