import * as effects from 'redux-saga/effects';

import { TEMP_DATASET } from '../../../types/IDataset';
import * as StreamsActions from '../actions';
import selectors from '../selectors';
import { setActiveObjectType } from '../../App/actions';
import { initialGroupTable } from '../../GroupTable/reducer';
import { clearSearchBar } from '../../Search/actions';
import { setTable } from '../../GroupTable/actions';

export default function* clearUserFlow() {
  const datasets = selectors.getDatasets(yield effects.select());

  const filteredDatasets = datasets.filter((dataset) => dataset.id !== TEMP_DATASET);

  yield effects.put(StreamsActions.setDatasets(filteredDatasets));
  yield effects.put(setActiveObjectType(null));
  yield effects.put(clearSearchBar());

  yield effects.put(setTable({
    datasetId: TEMP_DATASET,
    table: initialGroupTable,
  }));
}
