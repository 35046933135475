import getClient from '../../../api/getClient';
import { typeInteractionPropertiesPath } from '../../../constants/apiPaths';
import { createNestedEntity, fetchNestedEntities } from '../api';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import {
  IDesignSource,
  ApiV4ResponseWrapper,
  IDesignSourceEntity,
  IDesignSourceEntityField,
  ICalculation,
  IHub,
  IGroup,
  ApiV4PostResponse,
} from '../types';
import { MachineLearning } from '../../../api/model/schemas/MachineLearning';
import { ProcessMachineLearningType } from '../../../api/model/schemas/ProcessMachineLearningType';

export const fetchDataSources = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSource[]>
>({
  typeId: TYPE_IDS.DataSource,
});

export const fetchEntities = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSourceEntity[]>
>({
  typeId: TYPE_IDS.SourceEntity,
});

export const fetchEntityFields = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSourceEntityField[]>
>({
  typeId: TYPE_IDS.SourceEntityField,
});

export const fetchCalculations = fetchNestedEntities<
  ApiV4ResponseWrapper<ICalculation[]>
>({
  typeId: TYPE_IDS.Calculation,
});

export const fetchMachineLearningAssets = fetchNestedEntities<
  ApiV4ResponseWrapper<MachineLearning[]>
>({
  typeId: TYPE_IDS.MachineLearning,
});

export const fetchHubs = fetchNestedEntities<ApiV4ResponseWrapper<IHub[]>>({
  typeId: TYPE_IDS.Hub,
});

export const fetchGroups = fetchNestedEntities<ApiV4ResponseWrapper<IGroup[]>>({
  typeId: TYPE_IDS.Group,
});

interface CreateCustomPropertyArgs {
  typeId: string;
  designId: string;
}
export const createCustomProperty =
  <T>({ typeId, designId }: CreateCustomPropertyArgs) =>
  async (property: T): Promise<ApiV4ResponseWrapper<ApiV4PostResponse & T>> => {
    const client = await getClient();
    return client.post(
      typeInteractionPropertiesPath({ typeId, designId }),
      property
    );
  };

export const configureSmartGroup = (groupId: string) =>
  createNestedEntity({
    typeId: TYPE_IDS.GenerateGroupValuesJob,
    parentEntityId: groupId,
  })({});

export const configureMachineLearning = (
  mlAssetId: string,
  type: ProcessMachineLearningType
) =>
  createNestedEntity({
    typeId: TYPE_IDS.ConfigureMachineLearningJob,
    parentEntityId: mlAssetId,
  })({
    type,
  });
