import { generateColumnUuid } from '../modules/ColumnsSequencing/utils';
import { IColumn } from '../types/IColumn';
import { IDataset } from '../types/IDataset';

interface Params {
  columnUuid: string
  dataset: IDataset
}

export default ({
  columnUuid,
  dataset,
}:Params): IColumn | null => {
  if (!dataset.columns) return null;

  const column = dataset.columns.find((col) => generateColumnUuid(col) === columnUuid);

  return column || null;
};
