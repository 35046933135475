// ported from https://docs.microsoft.com/en-gb/office/dev/add-ins/reference/javascript-api-for-office-error-codes
export enum OFFICE_DIALOG_ERROR_CODES {
  /**
   * One of the following:
   *   - No page exists at the URL that was passed to `displayDialogAsync`.
   *   - The page that was passed to `displayDialogAsync` loaded, but the dialog box was directed to a page that it cannot find or load, or it has been directed to a URL with invalid syntax. Thrown within the dialog and triggers a `DialogEventReceived` event in the host page.
   */
  NotFound = 12002,
  /**
   * The dialog box was directed to a URL with the HTTP protocol. HTTPS is required. Thrown within the dialog and triggers a `DialogEventReceived` event in the host page.
   */
  UnsecureRedirect = 12003,
  /**
   * The domain of the URL passed to `displayDialogAsync` is not trusted. The domain must be the same domain as the host page (including protocol and port number). Thrown by call of `displayDialogAsync`.
   */
  UntrustedOrigin = 12004,
  /**
   * The URL passed to `displayDialogAsync` uses the HTTP protocol. HTTPS is required. Thrown by call of `displayDialogAsync`. (In some versions of Office, the error message returned with 12005 is the same one returned for 12004.)
   */
  UnsecureUrl = 12005,
  /**
   * The dialog box was closed, usually because the user chooses the X button. Thrown within the dialog and triggers a `DialogEventReceived` event in the host page.
   */
  DialogClosed = 12006,
  /**
   * A dialog box is already opened from this host window. A host window, such as a task pane, can only have one dialog box open at a time. Thrown by call of `displayDialogAsync`.
   */
  DialogExists = 12007,
  /**
   * The user chose to ignore the dialog box. This error can occur in online versions of Office, where users may choose not to allow an add-in to present a dialog. Thrown by call of `displayDialogAsync`.
   */
  DialogIgnored = 12009,
};
