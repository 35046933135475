import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getActiveObjectType } from '../modules/App/selectors';
import { ObjectTypes } from '../modules/App/types';
import { getGroupTable } from '../modules/GroupTable/selectors';
import streamSelectors from '../modules/Streams/selectors';
import {
  checkIfColumnChangedSectionInGroupTableNotIncludingAggregationType,
  checkIfColumnIsQueuedForMapping,
  checkIfCountOfColumnAggregationsChanged,
  checkIfSpecyficColumnAggregationChangedSectionInGroupTable,
} from '../modules/Streams/utils';
import { IColumn } from '../types/IColumn';

interface Params {
  column: IColumn;
  shouldCheckBySpecificAggregation?: boolean;
  ignoreAggregationCount?: boolean
}

export default ({ column, shouldCheckBySpecificAggregation, ignoreAggregationCount }: Params) => {
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const activeObjectType = useSelector(getActiveObjectType);
  const groupTableConfig = useSelector(getGroupTable(currentDataset?.id));

  const isColumnQueued = useMemo(() => {
    if (shouldCheckBySpecificAggregation) return false;

    const isGroupTable = currentDataset
      ? currentDataset.type === ObjectTypes.GROUP_TABLE
      : activeObjectType === ObjectTypes.GROUP_TABLE;

    const hasCountOfColumnAggregationChanged = checkIfCountOfColumnAggregationsChanged(
      {
        column,
        currentColumns: currentDataset?.columns || [],
        lastSuccessfullyMappedColumns:
          currentDataset?.lastSuccessfulMapping?.columns || [],
      },
    );

    const isColumnQueuedForMapping = checkIfColumnIsQueuedForMapping({
      column,
      currentColumns: currentDataset?.columns || [],
      lastSuccessfullyMappedColumns:
        currentDataset?.lastSuccessfulMapping?.columns || [],
    });

    const hasAnyOfColumnAggregationChangedSectionInGroupTableDesign = isGroupTable
      && checkIfColumnChangedSectionInGroupTableNotIncludingAggregationType({
        checkedColumn: column,
        currentDatasetColumns: currentDataset?.columns || [],
        currentGroupTableConfig: groupTableConfig,
        lastMappedGroupTableConfig:
          currentDataset?.lastSuccessfulMapping?.groupTableDesign,
      });

    return (
      (hasCountOfColumnAggregationChanged && !ignoreAggregationCount)
      || isColumnQueuedForMapping
      || hasAnyOfColumnAggregationChangedSectionInGroupTableDesign
    );
  }, [
    column,
    currentDataset?.columns,
    currentDataset?.lastSuccessfulMapping,
    activeObjectType,
    groupTableConfig,
  ]);

  const isSpecificColumnAggregationQueued = useMemo(() => {
    if (!shouldCheckBySpecificAggregation) return false;

    const isGroupTable = currentDataset
      ? currentDataset.type === ObjectTypes.GROUP_TABLE
      : activeObjectType === ObjectTypes.GROUP_TABLE;

    if (isGroupTable) {
      return checkIfSpecyficColumnAggregationChangedSectionInGroupTable({
        checkedColumn: column,
        currentGroupTableConfig: groupTableConfig,
        lastMappedGroupTableConfig: currentDataset?.lastSuccessfulMapping?.groupTableDesign,
      });
    }

    return checkIfColumnIsQueuedForMapping({
      column,
      currentColumns: currentDataset?.columns,
      lastSuccessfullyMappedColumns: currentDataset?.lastSuccessfulMapping?.columns,
      shouldCheckBySpecificAggregation: true,
    });
  }, [column, groupTableConfig, currentDataset?.lastSuccessfulMapping?.groupTableDesign]);

  return isColumnQueued || isSpecificColumnAggregationQueued;
};
