import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComparisonTypes,
  IStreamFilter,
} from '../../../../types/IStreamFilter';
import { renderFilterValue } from './utils';
import { valueStyles } from './styles';
import { GroupCollapse, GroupValue } from '../../../../components/ui';

interface Props {
  filter: IStreamFilter;
  onClick(filter: IStreamFilter): void;
  isSelected: boolean;
  isOpen: boolean;
  onOpenGroup(columnId: string): void;
}

const FilterRule: React.FC<Props> = ({
  filter,
  onClick,
  isSelected,
  isOpen,
  onOpenGroup,
}) => {
  const { t } = useTranslation('filters');

  const renderMultipleValues = () =>
    (filter.values || []).map((value, i) => (
      <React.Fragment key={value}>
        <span style={valueStyles()}>
          {renderFilterValue(value, filter.comparison, filter.column)}
        </span>
        {i < filter.values.length - 1 && ', '}
      </React.Fragment>
    ));

  const formatFilterValue = () => {
    if (!filter || !filter.comparison) {
      return t('condition');
    }

    switch (filter.comparison) {
      case ComparisonTypes.In:
        return (
          <>
            {`${t('Equal')} `}
            {renderMultipleValues()}
          </>
        );
      case ComparisonTypes.NotIn:
        return (
          <>
            {`${t('NotEqual')} `}
            {renderMultipleValues()}
          </>
        );
      case ComparisonTypes.Between:
      case ComparisonTypes.NotBetween:
        return (
          <>
            {filter.comparison === ComparisonTypes.Between
              ? `${t('Between')} `
              : `${t('NotBetween')} `}
            {filter.lowerBoundValue && (
              <span style={valueStyles()}>
                {renderFilterValue(
                  filter.lowerBoundValue,
                  filter.comparison,
                  filter.column
                )}
              </span>
            )}
            {filter.lowerBoundValue &&
              filter.upperBoundValue &&
              ` ${t('and')} `}
            {filter.upperBoundValue && (
              <span style={valueStyles()}>
                {renderFilterValue(
                  filter.upperBoundValue,
                  filter.comparison,
                  filter.column
                )}
              </span>
            )}
          </>
        );
      default:
        return (
          <>
            {`${t(filter.comparison)} `}
            <span style={valueStyles()}>
              {renderFilterValue(
                filter.compareValue,
                filter.comparison,
                filter.column
              )}
            </span>
          </>
        );
    }
  };

  const handleClick = () => {
    onClick(filter);
  };

  const id = filter && filter.id ? `filter-${filter.id}` : 'newFilter';

  return (
    <GroupCollapse
      groupName={filter?.column?.name || 'Select item...'}
      isOpen={isOpen}
      onClick={onOpenGroup}
      groupId={filter?.id}
      isSelected={isSelected}
    >
      <GroupValue
        onClick={handleClick}
        isSelected={isSelected}
        data-filterid={id}
      >
        {formatFilterValue()}
      </GroupValue>
    </GroupCollapse>
  );
};

export default FilterRule;
