import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import ItemActionsHeader from '../../../../pageTemplates/ItemActionsHeader';

const FiltersHeader = () => {
  // HOOKS
  const history = useHistory();

  // CALLBACKS
  const handleAdd = React.useCallback(
    () => history.push(routes.streamer.dataMode.filters.add),
    [history]
  );

  return <ItemActionsHeader isItemSelected={false} handleAdd={handleAdd} />;
};

export default FiltersHeader;
