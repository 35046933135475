import { CommandButton, IIconProps, Spinner, Stack } from "office-ui-fabric-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from 'classnames';
import { useCancelToken } from "../../../../../../../shared/hooks/useCancelToken";
import { TypeIds } from "../../../../../../api/model/schemas";
import { Link } from "../../../../../../api/model/schemas/Link";
import { LinkStatus } from "../../../../../../api/model/schemas/LinkStatus";
import { fetchEntity, patchEntity } from "../../../../api";
import { linkUpdated, setSelectedLinkFieldsId } from "../../../actions";
import LinkFields from "../../../components/LinkFields";
import { Props as LinkFieldsProps } from "../../../components/LinkFields/LinkFields";
import { getSelectedLinkFieldsId } from "../../../selectors";
import { classNames, linkFieldsStyles } from "./styles";

const buttonIconBase: IIconProps = {
  className: linkFieldsStyles[classNames.actionButtonIcon],
}

const acceptButtonIcon: IIconProps = {
  ...buttonIconBase,
  iconName: 'Accept',
};

const rejectButtonIcon: IIconProps = {
  ...buttonIconBase,
  iconName: 'Cancel',
};

type ExtendedLinkFieldsProps = Pick<LinkFieldsProps,
  | 'isQueued'
  | 'linkFields'
  | 'searchQuery'
  | 'sourceData'
>;

interface Props extends ExtendedLinkFieldsProps {
  link: Link;
};

export const IngestionLinkFields = ({
  isQueued,
  link,
  linkFields,
  searchQuery,
  sourceData,
}: Props): JSX.Element => {
  // HOOKS
  const dispatch = useDispatch();
  const cancelToken = useCancelToken();

  // STATE
  const selectedLinkFieldsId = useSelector(getSelectedLinkFieldsId);
  const [isLoading, setIsLoading] = React.useState(false);

  // DERIVED STATE
  const isSelected = selectedLinkFieldsId === linkFields.id;

  // CALLBACKS
  const handleClick = React.useCallback(
    () => {
      dispatch(
        setSelectedLinkFieldsId(isSelected ? null : linkFields.id)
      );
    },
    [dispatch, isSelected, linkFields.id],
  );

  const updateLinkStatus = React.useCallback(
    async (linkData: Pick<Link, 'status'>) => {
      try {
        setIsLoading(true);

        await patchEntity<Link>(TypeIds.Link)({
          id: link.id,
          status: linkData.status,
        }, undefined, cancelToken);

        const { data: updatedLink } = await fetchEntity<Link>(TypeIds.Link, link.id)(undefined, cancelToken);

        dispatch(linkUpdated(updatedLink));
      } catch(error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [cancelToken, dispatch, link],
  );

  const onAccept = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    event => {
      updateLinkStatus({ status: LinkStatus.Accepted });
      event.stopPropagation();
    },
    [updateLinkStatus],
  );
  onAccept;

  const onReject = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    event => {
      updateLinkStatus({ status: LinkStatus.Rejected });
      event.stopPropagation();
    },
    [updateLinkStatus],
  );

  // PARTS
  const spinner = isLoading && <Spinner />;
  const actionButtons = link.status === LinkStatus.Proposal && (
    <>
      <CommandButton
        className={linkFieldsStyles[classNames.actionButton]}
        iconProps={acceptButtonIcon}
        onClick={onAccept}
      />
      <CommandButton
        className={linkFieldsStyles[classNames.actionButton]}
        iconProps={rejectButtonIcon}
        onClick={onReject}
      />
    </>
  );

  // RENDER
  return (
    <Stack
      className={cn(
        linkFieldsStyles[classNames.linkFields],
        {
          [linkFieldsStyles[classNames.linkFieldsSelected]]: isSelected,
        },
      )}
    >
      <LinkFields
        {...{
          isQueued,
          isSelected,
          linkFields,
          searchQuery,
          sourceData,
        }}
        onClick={handleClick}
        rightSideItems={spinner || actionButtons}
        rightSideItemsClassName={linkFieldsStyles[classNames.actionButtonList]}
      />
    </Stack>
  );
};
