import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IFoldableListGroup,
  IFoldableListItem,
} from '../../../../components/ui/FoldableList/types/IFoldableListGroup';
import EntityField from '../../../../components/EntityField';

import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import useGetParsedTypeProperties from '../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../App/types';

import { ICalculation } from '../../../Designer/types';

import {
  getCalculations,
  getSelectedBuilderStreamId,
  getStreamFields,
  getSelectedStream,
} from '../../selectors';
import {
  assetClicked,
  AssetClickedIntent,
  assetsDataGroupClicked,
  fetchCalculations,
  fetchStreamCalculations,
} from '../../actions';
import { GroupCollapse } from '../../../../components/ui';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { getSchemaFromTypeProperties } from '../../../../utils/getSchemaFromTypeProperties';
import { getSelectedDesignId } from '../../../Designer/ContentLibrary/selectors';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { StreamAssetType } from '../../types';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { useSearchFilteredRecords } from '../../../../hooks/useSearchFilteredRecords';
import { CalculationKeys } from '../../../../api/model/schemas/Calculation';
import { StreamReferenceProcessStatus } from '../../../../api/model/schemas/StreamReferenceProcessStatus';

const Calculations: FunctionComponent = () => {
  // HOOKS
  const dispatch = useDispatch();

  const selectedStreamId = useSelector(getSelectedBuilderStreamId);
  const selectedStream = useSelector(getSelectedStream);
  const designId = useSelector(getSelectedDesignId);

  const { records, isLoading, streamCalculations, recordIdsBeingUpdated } =
    useSelector(getCalculations);
  const { records: streamFields } = useSelector(getStreamFields);

  const { properties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.Calculation,
  });

  const selectedCalulations = streamFields
    .filter((f) => f.$sourceFieldTypeId === TYPE_IDS.Calculation)
    .map((f) => f.sourceFieldId);

  const { filteredRecords, searchQuery } = useSearchFilteredRecords(records);

  const sourceFieldsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds: selectedCalulations,
  });

  const normalizedCalculations = normalizeById(records);
  const selectedICalculationIds = streamCalculations.map(
    (cal) => cal.calculationId
  );

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({ input: sourceFieldsWhichIncludesSelections });

  // DERIVED STATE
  const schema = React.useMemo(
    () => [
      ...getSchemaFromTypeProperties(properties, TYPE_IDS.Calculation),
      // TODO: contact Stephan about making some additional attributes shown in info boxes on the API side
      {
        key: CalculationKeys.calculationTypeId,
        translationPrefix: TYPE_IDS.Calculation,
      },
      {
        key: CalculationKeys.dataType,
        translationPrefix: TYPE_IDS.Calculation,
      },
      {
        key: CalculationKeys.formatType,
        translationPrefix: TYPE_IDS.Calculation,
      },
    ],
    [properties]
  );

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:catalog:calcs:cta:noAssetsAvailable':
        records.length === 0,
    }),
    [records.length]
  );

  // EFFECTS
  useEffect(() => {
    // all groups in the design (which is a parent of a stream)
    dispatch(fetchCalculations(selectedStream?.parentId));
    dispatch(fetchStreamCalculations({ streamId: selectedStreamId }));
  }, []);

  // CALLBACKS
  const onClickItemHandler = (calculation: ICalculation) => {
    dispatch(
      assetClicked({
        entity: calculation,
        actionType: streamCalculations.some(
          (streamCalculation) =>
            streamCalculation.calculationId === calculation.id
        )
          ? AssetClickedIntent.REMOVE
          : AssetClickedIntent.ADD,
        assetType: StreamAssetType.Calculation,
      })
    );
  };

  const handleGroupClick = (group: IFoldableListGroup) => {
    const relatedFields = group.items.reduce<ICalculation[]>(
      (accumulator, item) => [...accumulator, normalizedCalculations[item.key]],
      []
    );

    dispatch(
      assetsDataGroupClicked({
        entities: relatedFields,
        selectedEntitiesIds: selectedICalculationIds,
        assetType: StreamAssetType.Calculation,
      })
    );
  };

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const renderCalculation = (calculation: ICalculation) => {
    if (!calculation) return null;

    const relatedStreamCalculation = streamCalculations.find(
      (streamCalculation) =>
        streamCalculation?.calculationId === calculation?.id
    );
    const isQueued =
      relatedStreamCalculation?.processStatus ===
      StreamReferenceProcessStatus.NotProcessed;

    return (
      <EntityField
        key={calculation.id}
        isSelectable
        isSelected={!!relatedStreamCalculation}
        info={{
          data: calculation,
          schema,
        }}
        slot1={<EntityFieldSlotSpacer />}
        isOrange={isQueued}
        onClick={() => onClickItemHandler(calculation)}
        name={calculation.name}
        isSyncing={recordIdsBeingUpdated.includes(calculation.id)}
        searchQuery={searchQuery}
        availability={calculation.availability}
      />
    );
  };

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (currentSort === DefinedSortTypes.Name) {
    return (
      <>
        {sourceFieldsWhichIncludesSelections
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((field) => renderCalculation(field))}
      </>
    );
  }

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = records.find((r) => r.id === item.key);

    return renderCalculation(entry);
  };

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onIconClick={onClickHeaderHandler}
          onTextClick={() => handleGroupClick(group)}
          isSelected={group.items.every((item) =>
            selectedICalculationIds.includes(item.key)
          )}
          isSyncing={group.items.some((item) =>
            recordIdsBeingUpdated.includes(item.key)
          )}
          selections={{
            current: selectedICalculationIds.filter((id) =>
              group.items.map((item) => item.key).includes(id)
            ).length,
            total: group.items.length,
          }}
        >
          {group.items.map((item) => onRenderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default Calculations;
