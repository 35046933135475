import React from 'react';
import { useSelector } from 'react-redux';
import useActions from '../utils/useActions';

import * as appActions from '../modules/App/actions';
import { getSelectedItemsIds } from '../modules/App/selectors';

export default () => {
  const {
    appendSelectedItemId,
    clearSelection,
    setSelectedItemId,
    setMultipleSelectedItemIds,
    removeSelectedItemId,
  } = useActions(appActions);

  const selectedItems = useSelector(getSelectedItemsIds);

  const handleItemClickedOnSingleSelection = (clickedId: string) => {
    const currentlySelected = selectedItems[0];
    const isAlreadySelected = currentlySelected === clickedId;

    if (isAlreadySelected) {
      removeSelectedItemId(clickedId);
    } else {
      setSelectedItemId(clickedId);
    }
  };

  // EFFECTS

  // we need to clear selection on dismount
  // because this piece of state is shared
  // across multiple pages
  React.useEffect(() => () => {clearSelection()}, []);

  return {
    append: appendSelectedItemId,
    clear: clearSelection,
    setSingle: setSelectedItemId,
    setMultiple: setMultipleSelectedItemIds,
    remove: removeSelectedItemId,
    selectedItems,
    selectedItem: selectedItems[0],
    handleItemClickedOnSingleSelection,
    isItemSelected: selectedItems.length > 0,
  };
};
