import React, { useMemo } from 'react';
import {
  Stack, mergeStyleSets, Icon,
} from '@fluentui/react';

import { SectionType } from '../../types';

import ToggleStack from './components/ToggleStack';

import {
  getStyles,
  footerStackStyles,
  scrollAreaStyles,
  rightStackStyles,
} from './styles';

interface Props {
  sectionType: SectionType;
  handleChangeTotal: (checked: boolean) => void;
  hasTotals: boolean;
  children?: React.ReactNode;
}

export const SectionIcon: Record<SectionType, string> = {
  columns: 'SynergiesColumnsLayout',
  rows: 'SynergiesRowsLayout',
  values: 'SynergiesValuesLayout',
};

const ItemsContainer: React.FC<Props> = ({
  children,
  sectionType,
  handleChangeTotal,
  hasTotals,
}) => {
  const classNames = useMemo(() => {
    const styles = getStyles();
    return mergeStyleSets(styles);
  }, []);

  return (
    <Stack className={classNames.singleContainer} horizontal disableShrink>
      <Stack styles={rightStackStyles}>
        <Stack styles={scrollAreaStyles}>
          {children}
        </Stack>
        <Stack styles={footerStackStyles}>
          <Icon
            iconName={SectionIcon[sectionType]}
            className={classNames.icon}
          />
          <ToggleStack
            sectionType={sectionType}
            handleChangeTotal={handleChangeTotal}
            hasTotals={hasTotals}
            toggleStackClassName={classNames.toggleStack}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ItemsContainer;
