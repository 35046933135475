import { Stack } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import InlineEditField from '../../../ui/InlineEditField';

import { containerStyles } from './styles';

interface TextEditProps {
  value: string;
  onChange(newValue: string): void;
}

const TextEdit: FunctionComponent<TextEditProps> = ({
  value, onChange,
}) => (
  <Stack styles={containerStyles}>
    <InlineEditField
      value={value}
      onChange={(newValue) => onChange(newValue)}
    />
  </Stack>
);

export default TextEdit;
