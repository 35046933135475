import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { History } from 'history';
import { AssetProcessStatus } from '../../api/model/AssetProcessStatus';
import * as api from './api';
import {
  GroupAssetClickedActionType,
  StreamDataRequestParams,
  StreamerAsset,
  StreamingStrategies,
} from './types';
import { ApplicationState } from '../../store/types';

import * as selectors from './selectors';
import * as filterSelectors from './Filters/selectors';
import { selectedAssetsToDataRequestParams } from './sagas/utils';
import { DataSourceEntity } from '../../api/model/schemas/DataSourceEntity';
import { createNestedEntity, patchEntity } from '../Designer/api';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { configureSourceEntity } from '../Designer/Ingestion/api';
import { createTypedAction } from '../../../shared/utils/actions';
import { StreamFileFormat } from '../../api/model/schemas/StreamFileFormat';

const ACTION_PREFIX = '@eyko/Streamer_v2';

export const fetchStreamsAction = createAsyncThunk(
  `${ACTION_PREFIX}/FETCH_STREAMS`,
  async () => {
    const {
      data: { data: streams },
    } = await api.fetchStreams();
    return streams;
  }
);
export const fetchStreamAssets = createAsyncThunk(
  `${ACTION_PREFIX}/FETCH_STREAM_ASSETS`,
  async (streamId: string) => {
    const filter = `processStatus eq '${AssetProcessStatus.Processed}'`;
    const [
      {
        data: { data: streamFields },
      },
      {
        data: { data: calcs },
      },
      {
        data: { data: groups },
      },
      {
        data: { data: hubs },
      },
      {
        data: { data: machineLearning },
      },
      {
        data: { data: lookups },
      },
    ] = await Promise.all([
      api.fetchStreamFields(streamId)({ filter }),
      api.fetchStreamCalculations(streamId)({ filter }),
      api.fetchStreamGroupsAssets(streamId)({ filter }),
      api.fetchStreamHubs(streamId)({ filter }),
      api.fetchStreamMachineLearning(streamId)({ filter }),
      api.fetchStreamLookups(streamId)({ filter }),
    ]);

    return [
      ...streamFields,
      ...calcs,
      ...groups,
      ...hubs,
      ...machineLearning,
      ...lookups,
    ];
  }
);

export const selectStreamId = createTypedAction<string>()(
  `${ACTION_PREFIX}/SELECT_STREAM`
);

export const assetClicked = createTypedAction<StreamerAsset>()(
  `${ACTION_PREFIX}/ASSET_CLICKED`
);

export const assetAggregationClicked = createTypedAction<StreamerAsset>()(
  `${ACTION_PREFIX}/ASSET_AGGREGATION_CLICKED`
);

export const setSelectedAssets = createTypedAction<StreamerAsset[]>()(
  `${ACTION_PREFIX}/SET_SELECTED_ASSETS`
);

export type GroupAssetClickedParams = {
  assets: StreamerAsset[];
  actionType: GroupAssetClickedActionType;
};

export const groupAssetsClicked = createTypedAction<GroupAssetClickedParams>()(
  `${ACTION_PREFIX}/SELECT_GROUP_OF_ASSETS`
);

export const storeSelectedItems = createAction(
  `${ACTION_PREFIX}/STORE_SELECTED_ITEMS`
);
export const playIntent = createTypedAction<{
  streamingStrategy: StreamingStrategies;
  metadata: {
    history: History<any>;
  };
}>()(`${ACTION_PREFIX}/PLAY_INTENT`);

// because we want to reuse this action for changing
// sort order, sort mode & aggregation
// that is the simplest way to compare old object
export const updateStreamerAsset = createTypedAction<{
  currentAsset: StreamerAsset;
  updatedAsset: StreamerAsset;
}>()(`${ACTION_PREFIX}/UPDATE_ASSET`);

export const writeToDatabase = createAsyncThunk(
  `${ACTION_PREFIX}/WRITE_TO_DB`,
  async (entity: DataSourceEntity, { getState }) => {
    const state = getState() as any as ApplicationState;

    const selectedAssets = selectors.getSelectedAssets(state);
    const filters = filterSelectors.getAllStreamerFilters(state);
    const selectedStreamId = selectors.getSelectedStreamId(state);

    const dataRequestParams = selectedAssetsToDataRequestParams(
      selectedAssets,
      filters
    );

    await patchEntity<any>(TYPE_IDS.ConfigureDataSourceEntity)({
      id: entity.configurationId,
      data: dataRequestParams,
      streamId: selectedStreamId,
    });

    await configureSourceEntity(entity.id);
  }
);

type Params = {
  format: StreamFileFormat;
  data: StreamDataRequestParams | any;
  selectedStreamId: string;
};
export const performSendToFile = createAsyncThunk(
  `${ACTION_PREFIX}/SEND_TO_FILE`,
  async ({ data, format, selectedStreamId }: Params) => {
    await createNestedEntity<any>({
      parentEntityId: selectedStreamId,
      typeId: TYPE_IDS.SendStreamDataJob,
    })({
      format,
      data,
    });
  }
);

export const sendToFileIntent = createAsyncThunk(
  `${ACTION_PREFIX}/SEND_TO_FILE_INTENT`,
  async (format: StreamFileFormat, { getState, dispatch }) => {
    const state = getState() as any as ApplicationState;

    const selectedAssets = selectors.getSelectedAssets(state);
    const filters = filterSelectors.getAllStreamerFilters(state);
    const selectedStreamId = selectors.getSelectedStreamId(state);

    const dataRequestParams = selectedAssetsToDataRequestParams(
      selectedAssets,
      filters
    );

    dispatch(
      performSendToFile({
        data: dataRequestParams,
        format,
        selectedStreamId,
      })
    );
  }
);

export const sendToFileModalClosed = createAction(
  `${ACTION_PREFIX}/SEND_TO_FILE_MODAL_OPENED`
);

export const initialDataFetchFailed = createAction(
  `${ACTION_PREFIX}/OUTPUT_DATA_RECEIVED_FAILURE`
);
