import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EntityField from '../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import FoldableList from '../../../../components/ui/FoldableList';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import WizardPage, { WizardType } from '../../../../pageTemplates/WizardPage';
import { IUser, UserRoles } from '../../../../types/IUser';
import { DefinedSortTypes } from '../../../App/types';
import { getIsProcessingStream } from '../../../Builder/selectors';
import { FetchShareFunction } from './types';
import { useGetEntityShareObjects } from './useGetEntityShareObjects';

import { useGetUsers } from './useGetUsers';
import { usePickingUsers } from './usePickingUsers';
import { useSharedPolicyUpdate } from './useSharedPolicyUpdate';
import { getHeaderBarButtonConfig } from './utils';

interface Props {
  successCallback(): void
  handleCancel(): void
  entityName: string;
  entityId: string;
  parentEntityId: string
  fetchShareObjects: FetchShareFunction
  createShareObject: (userId: any, access: any) => Promise<void>
  unShareObject: (id: string) => Promise<any>
  visibleUsersRoles: UserRoles[]
}

const ShareWizard = ({
  successCallback,
  handleCancel,
  entityName,
  createShareObject,
  unShareObject,
  fetchShareObjects,
  parentEntityId,
  visibleUsersRoles,
}: Props) => {
  // HOOKS
  const { t } = useTranslation();

  const { isLoading: isLoadingUsers, users } = useGetUsers(visibleUsersRoles);
  const { handleUserClick, pickedUserIds } = usePickingUsers();
  const { isLoading: isLoadingShareObjects, shareEntities } = useGetEntityShareObjects({
    fetchFunction: fetchShareObjects,
    parentEntityId,
  });
  const {
    handleUpdate,
    isUpdating,
  } = useSharedPolicyUpdate({
    createShareObject,
    pickedUserIds,
    shareEntities,
    unShareObject,
  });
  const isProcessingStream = useSelector(getIsProcessingStream);

  // DERIVED STATE
  const isLoading = isUpdating || isLoadingUsers || isLoadingShareObjects || isProcessingStream;

  const userIdsWithAccess = React.useMemo(() => shareEntities.map((entity) => entity.userId), [shareEntities]);
  const hasPickedAnyUsers = pickedUserIds.length > 0;

  const selectedUserIds = React.useMemo(() => {
    const usersWithRemoved = userIdsWithAccess.filter((userId) => !pickedUserIds.includes(userId));
    const pickedWithRemoved = pickedUserIds.filter((userId) => !userIdsWithAccess.includes(userId));

    return [...usersWithRemoved, ...pickedWithRemoved];
  }, [userIdsWithAccess, pickedUserIds]);

  const usersWhichIncludesSelection = useEntitiesSelections({
    entities: users,
    selectedEntitiesIds: selectedUserIds,
  });

  const {
    mappedGroups,
    currentSort,
    onClickHeaderHandler,
  } = useSortedFoldableGroups({
    input: usersWhichIncludesSelection,
  });

  // CALLBACKS
  const handleAccept = async () => {
    await handleUpdate();
    successCallback();
  };

  // PARTS
  const renderEntity = (user:IUser) => (
    <EntityField
      slot1={<EntityFieldSlotSpacer />}
      key={user.id}
      name={user.name}
      isSelectable
      isSelected={selectedUserIds.includes(user.id)}
      onClick={() => handleUserClick(user)}
      isOrange={pickedUserIds.includes(user.id)}
    />
  );

  // RENDER
  return (
    <WizardPage
      wizardType={WizardType.Share}
      pageTitle={t('shareModule:Share')}
      headerBarButtonsConfig={getHeaderBarButtonConfig({
        handleCancel,
        handleAccept,
        hasPickedAnyUsers,
      })}
      entityName={entityName}
      isSyncing={isLoading}
    >
      {currentSort !== DefinedSortTypes.Name ? (
        <FoldableList
          groups={mappedGroups}
          onClickHeader={onClickHeaderHandler}
          onRenderItem={(item) => {
            const relatedUser = users.find((user) => user.id === item.key);

            return relatedUser ? renderEntity(relatedUser) : null;
          }}
        />
      ) : (
        <>
          {usersWhichIncludesSelection
            .slice()
            .sort((user1, user2) => user1.name.localeCompare(user2.name))
            .map(renderEntity)}
        </>
      )}

    </WizardPage>
  );
};

export default ShareWizard;
