import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import ItemActionsHeader from '../../../../pageTemplates/ItemActionsHeader';
import { addNestedEntityRoute, deleteEntityRoute, editEntityRoute } from '../../../../utils/routes';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import * as selectors from '../../selectors';
import { setSelectedStreamId } from '../../actions';
import { AssetProcessStatus } from '../../../../api/model/AssetProcessStatus';
import { StreamOutput } from '../StreamOutput/StreamOutput';
import { BuilderProcessStreamButton } from '../BuilderProcessStreamButton/BuilderProcessStreamButton';

const StreamsHeader: React.FC = () => {
  // STATE
  const selectedStreamId = useSelector(selectors.getSelectedBuilderStreamId);
  const selectedStream = useSelector(selectors.getSelectedStream);

  // HOOKS
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // DERIVED STAT
  const isSharingEnabled = selectedStream?.processStatus === AssetProcessStatus.Processed;

  // CALLBACKS
  const handleEdit = () => {
    const path = editEntityRoute(TYPE_IDS.Stream, selectedStream.id);
    const goBackPage = routes.builder.streams.index;
    history.push(path, {
      goBackPage,
    });
  };

  const handleAdd = () => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.Stream,
      parentSchemaId: TYPE_IDS.Design,
      // The Design Entity ID is submitted through the wizard, it is unknown beforeahed
      // and react router requires the path parameter to be defined
      parentEntityId: 'unknown',
    });
    const goBackPage = routes.builder.streams.index;
    history.push(path, {
      goBackPage,
      typeName: t('streams:Stream'),
    });
  };

  const handleShare = () => {
    history.push(routes.share.stream);
  };

  const handleDelete = React.useCallback(
    () => {
      const path = deleteEntityRoute(TYPE_IDS.Stream, selectedStream.id);

      const goBackPage = routes.builder.streams.index;

      history.push(path, {
        goBackPage,
        entityName: selectedStream.name,
        successCallback: () => dispatch(setSelectedStreamId(null)),
        deleteDescription: (
          <Trans
            i18nKey="wizard:deleteNestedEntity"
            values={{ name: selectedStream.name, asset: t('entityTypes:Stream') }}
          />
        ),
      });
    },
    [selectedStream],
  );

  // RENDER
  return (
    <>
      <ItemActionsHeader
        isItemSelected={!!selectedStreamId}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleShare={isSharingEnabled ? handleShare : null}
      />
      <BuilderProcessStreamButton />
      <StreamOutput
        streamId={selectedStreamId}
        isStreamProcessed={selectedStream?.processStatus === AssetProcessStatus.Processed}
      />
    </>
  );
};

export default StreamsHeader;
