import { createReducer } from '@reduxjs/toolkit';
import { IUser, UserRoles } from '../../types/IUser';

import { changeUserRole, clearUserData } from './actions';
import { fetchUserData } from './fetchUserData';

export interface UserState {
  isLoading: boolean;
  data?: IUser;
  selectedUserRole?: UserRoles;
}

export const initialState: UserState = {
  isLoading: false,
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchUserData.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(fetchUserData.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(fetchUserData.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
      selectedUserRole: action.payload.role,
    }))
    .addCase(changeUserRole, (state, action) => ({
      ...state,
      selectedUserRole: action.payload,
    }))
    .addCase(clearUserData, (state) => ({
      ...state,
      data: undefined,
      selectedUserRole: undefined,
    }))
);

export default reducer;
