import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getPreviouslyStoredLanguage } from '../../modules/Settings/utils';
import { LANGUAGE_CODES } from '../../constants/languages';

import enUS from './en.json';
import enGB from './en_GB.json';
import es from './es.json';
import de from './de.json';

const resources = {
  [LANGUAGE_CODES.enUS]: enUS,
  [LANGUAGE_CODES.enGB]: enGB,
  [LANGUAGE_CODES.de]: de,
  [LANGUAGE_CODES.es]: es,
};

const officeDisplayLanguage = window?.Office?.context?.displayLanguage;

const previouslySelectedLanguage = getPreviouslyStoredLanguage();
const initialLang =
  previouslySelectedLanguage || officeDisplayLanguage || LANGUAGE_CODES.enUS;

// NOTE: shouldn't we export the result of `.init` and await for it's resolution (Promise) when using it?
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // Language will be lowercased EN --> en while leaving full locales like en-US
    cleanCode: true,
    resources,
    lng: initialLang,
    fallbackLng: LANGUAGE_CODES.enUS,
    // enable this while fixing translations issues
    // debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
