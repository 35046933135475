import {
  mergeStyleSets, IContextualMenuStyles, IButtonStyles, ITooltipHostStyles, IContextualMenuItemStyleProps, IContextualMenuItemStyles,
} from '@fluentui/react';
import React from 'react';
import { baseColors, ICON_SIZE } from '../../../theme';
import { SlotPosition } from './types';

export const classNames = mergeStyleSets({
  btnWrap: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const droplistStyles: Partial<IContextualMenuStyles> = {
  root: {
    backgroundColor: 'white',
    fontSize: '12px',
    selectors: {
      '.ms-ContextualMenu-link': {
        height: '28px',
        lineHeight: '28px',
      },
      '.ms-ContextualMenu-itemText': {
        fontSize: '12px',
      },
      '.ms-ContextualMenu-icon': {
        lineHeight: '16px',
        fontSize: '16px !important',
        position: 'relative',
        top: '2px',
      },
      '.ms-ContextualMenu-list': {
        border: `1px solid ${baseColors.outlineGray}`,
      },
    },
  },
};

export const smallDroplistButtonStyles = (isDroplistOpen:boolean): IButtonStyles => ({
  root: {
    position: 'absolute',
    borderRadius: 0,
    color: 'inherit',
    right: 2,
    bottom: -3,
    height: 'auto',
    width: 'auto',
    padding: 2,
    margin: 0,
    pointerEvents: 'none',
  },
  rootHovered: {
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  rootPressed: {
    color: 'inherit',
    backgroundColor: 'transparent',
  },
  icon: {
    fontSize: 6,
    color: baseColors.iconColor,
    backgroundColor: 'transparent',
    visibility: isDroplistOpen ? 'visible' : 'hidden',
    margin: 0,
    height: 'auto',
    lineHeight: '8px',
  },
});

const getWidth = (position: SlotPosition) => {
  if (position === SlotPosition.left) {
    return 32;
  }

  return 24;
};

const getVisibility = (isItemSelected: boolean, shouldDisplayIconOnlyOnHover:boolean) => {
  if (!shouldDisplayIconOnlyOnHover) return 'visible';

  return isItemSelected ? 'visible' : 'hidden';
};

type ButtonStylesParams = {
  slotPosition: SlotPosition,
  isItemQueued: boolean
  isItemSelected:boolean
  shouldDisplayIconOnlyOnHover: boolean,
  isDroplistVisible: boolean
};

export const mainDroplistButtonStyles = ({
  isItemQueued,
  slotPosition,
  isItemSelected,
  shouldDisplayIconOnlyOnHover,
  isDroplistVisible,
}: ButtonStylesParams):IButtonStyles => ({
  root: {
    width: 24,
    minWidth: 24,
    height: 24,
    background: (isDroplistVisible && isItemSelected) ? baseColors.white : 'transparent',
    borderRadius: 4,
    margin: 0,
    visibility: 'visible',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootHovered: {
    color: 'inherit',
    backgroundColor: isItemSelected ? baseColors.hover : baseColors.selectedWithHover,
    visibility: 'visible',
  },
  rootPressed: {
    color: 'inherit',
    backgroundColor: isItemSelected ? baseColors.selectedWithHover : baseColors.hover,
    visibility: 'visible',
  },
  icon: {
    fontSize: 16,
    width: 16,
    height: 16,
    color: isItemQueued ? baseColors.orange : baseColors.iconColor,
    backgroundColor: 'transparent',
    display: 'flex',
    visibility: getVisibility(isItemSelected, shouldDisplayIconOnlyOnHover),
  },
  flexContainer: {
    width: getWidth(slotPosition),
  },
});

interface WrapperButtonStylesParams {
  isItemSelected: boolean,
  isDroplistVisible: boolean,
}

export const wrapperStyles = ({ isItemSelected, isDroplistVisible }: WrapperButtonStylesParams):IButtonStyles => ({
  root: {
    background: 'transparent',
    margin: 'auto',
    padding: 0,
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    selectors: {
      ':focus i': {
        visibility: 'visible',
      },
      ':hover i': {
        visibility: 'visible',
      },
    },
    width: '100%',
    height: '100%',
  },
  rootPressed: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: 28,
    borderRadius: 0,
    backgroundColor: isItemSelected ? baseColors.selectedWithHover : baseColors.hover,
  },
  icon: {
    backgroundColor: 'transparent',
    visibility: isDroplistVisible ? 'visible' : 'hidden',
    fontSize: '16px',
    color: `${baseColors.iconColor} !important`,
  },
  iconHovered: {
    color: baseColors.iconColor,
  },
});

export const tooltipHostStyles:ITooltipHostStyles = {
  root: {
    width: '100%',
    height: '100%',
  },
};

export const iconTooltipHostStyles:ITooltipHostStyles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const iconStyle = (isQueued: boolean):React.CSSProperties => ({
  width: ICON_SIZE,
  height: ICON_SIZE,
  color: isQueued ? baseColors.orange : baseColors.iconColor,
});

export const itemStyles = (
  { theme } : IContextualMenuItemStyleProps,
) : Partial<IContextualMenuItemStyles> => ({
  root: {
    height: 24,
    lineHeight: 24,
    selectors: {
      ':hover': {
        backgroundColor: baseColors.lightGrey,
      },
    },
    margin: 0,
  },
  icon: {
    fontSize: '12px!important',
    width: '16px!important',
    color: theme.palette.neutralPrimary,
    visibility: 'visible!important',
  },
  label: {
    fontSize: 13,
  },
});
