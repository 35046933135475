import { Spinner, Text } from 'office-ui-fabric-react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { DataRequestContinuation } from '../../../../api/model/schemas/DataRequestContinuation';
import EntityField from '../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import { GroupCollapse } from '../../../../components/ui';
import { toggleValue } from '../../../../utils/toggleValue';
import { getSelectedStreamId } from '../../selectors';
import {
  StreamAssetRaw,
  StreamerFilter,
  StreamerLimitedComparisons,
} from '../../types';
import { fetchSingleAssetData, rawAssetToStreamerAsset } from '../../utils';
import { createGetAssetValueFilter } from '../selectors';
import { classNames } from './styles';
import { streamerFilterFormDefault } from '../constants';
import { addFilter, deleteFilter, editFilter } from '../actions';
import { AggregationType } from '../../../../api/model/schemas/AggregationType';
import { getSearchQuery } from '../../../Search/selectors';

interface Props {
  asset: StreamAssetRaw;
}

const PAGE_SIZE = 250;

const generateWrapperId = (assetId: string) => `scrollable-group-${assetId}`;

export const ByValuesGroupCollapse = ({ asset }: Props) => {
  // HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // REDUX STATE
  const selectedStreamId = useSelector(getSelectedStreamId);
  const searchQuery = useSelector(getSearchQuery);
  const relatedFilter = useSelector(createGetAssetValueFilter(asset.id));

  // STATE
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState([]);
  const [totalRowCount, setTotalRowCount] = React.useState(null);
  const [dataReqContinuation, setDataReqContinuation] =
    React.useState<DataRequestContinuation>({
      pageSize: PAGE_SIZE,
    });

  // DERIVED STATE
  const wrapperId = generateWrapperId(asset.id);

  // CALLBACKS
  const fetchValues = React.useCallback(
    async (fetchMore = false) => {
      if (!fetchMore) {
        setValues([]);
      }

      try {
        setLoading(true);
        const {
          rows,
          continuation,
          totalRowCount: rowCount,
        } = await fetchSingleAssetData({
          asset: rawAssetToStreamerAsset(asset),
          selectedStreamId,
          continuation: dataReqContinuation,
        });

        const updatedValues = rows.flat(2).map(String);

        setValues((currentValues) => [...currentValues, ...updatedValues]);
        setDataReqContinuation(continuation);
        setTotalRowCount(rowCount);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [dataReqContinuation]
  );

  const handleClick = React.useCallback(async () => {
    if (isOpen) {
      setOpen(false);
      return;
    }
    setOpen(true);

    if (values.length > 0) return;

    await fetchValues();
  }, [values, isOpen]);

  const handleValueClick = React.useCallback(
    (newVal: string) => {
      if (!relatedFilter) {
        const newFilter: StreamerFilter = {
          ...streamerFilterFormDefault,
          id: uuid4(),
          values: [newVal],
          item: asset,
          comparison: StreamerLimitedComparisons.In,
          aggregation: AggregationType.None,
        };

        dispatch(addFilter(newFilter));
      }

      const updatedValues = toggleValue(relatedFilter?.values ?? [], newVal);

      if (updatedValues.length === 0) {
        dispatch(deleteFilter(relatedFilter));
      } else {
        dispatch(
          editFilter({
            ...relatedFilter,
            values: updatedValues,
          })
        );
      }
    },
    [relatedFilter]
  );

  // RENDER
  return (
    <div className={classNames.singleValueGroupWrapper}>
      <GroupCollapse
        isOpen={isOpen}
        onClick={handleClick}
        groupName={asset.name}
        groupId={asset.id}
        isSticky={isOpen}
        isSyncing={isLoading}
        isSelected={!!relatedFilter}
        searchQuery={searchQuery}
      >
        {!isLoading && values.length === 0 && (
          <Text className={classNames.noValuesMsg}>
            {t('filters:noValuesAvailable')}
          </Text>
        )}
        <div id={wrapperId} className={classNames.scrollableAreaStyles}>
          <InfiniteScroll
            loader={<Spinner />}
            hasMore={values.length < totalRowCount}
            scrollableTarget={wrapperId}
            next={() => fetchValues(true)}
            dataLength={values.length}
            style={{
              overflow: 'hidden',
            }}
          >
            {values.map((v) => (
              <EntityField
                slot1={<EntityFieldSlotSpacer />}
                name={v}
                key={v}
                isSelectable
                onClick={() => handleValueClick(v)}
                isSelected={relatedFilter?.values?.some(
                  (currentVal) => currentVal === v
                )}
              />
            ))}
          </InfiniteScroll>
        </div>
      </GroupCollapse>
    </div>
  );
};
