import React from 'react';
import GA4React from 'ga-4-react';

import { useLocation } from 'react-router-dom';
import { getIsLocalDevBuild } from '../config/buildType';

const GA_ID_STAGING = 'G-DT67981YNL';

export const useGoogleAnalytics = () => {
  // STATE
  const hasInitialized = React.useRef(null);
  const queueArray = React.useRef([]);
  const ga4react = new GA4React(GA_ID_STAGING);

  // HOOKS
  const { pathname } = useLocation();

  // DERIVED STATE
  // TO DO - support more environments
  const isLocal = getIsLocalDevBuild();

  // CALLBACKS
  const initializeGA = async () => {
    try {
      await ga4react.initialize();
      hasInitialized.current = true;
    } catch (e) {
      console.error('Addblock is activated');
    }
  };

  // EFFECTS
  React.useEffect(() => {
    if (!isLocal) {
      initializeGA();
    }
  }, []);

  React.useEffect(() => {
    if (isLocal) return;

    if (!hasInitialized.current) {
      queueArray.current.push(pathname);
    } else {
      while (queueArray.current.length > 0) {
        ga4react.pageview(queueArray.current.shift());
      }

      ga4react.pageview(pathname);
    }
  }, [pathname]);
};
