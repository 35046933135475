import { loadTheme } from '@fluentui/react';

/**
 * BEWARE - orange might not be truly orange.
 * We are doing 15min rebranding®
 * Because the days of the v4 are close to end,
 * and we want it to appear more as the v5
 */
const LIGHT_MODE_COLOR_PALETTE = {
  orange: '#E86013',
  midOrange: '#057284',
  lightOrange: '#F3F8F9',
  outlineGray: '#C6C6C6',
  offBlack: '#323130',
  lightBlack: '#595959',
  darkGrey: '#E6F1F3',
  midGrey: '#F3F8F9',
  lightGrey: '#F3F8F9',
  white: '#ffffff',
  red: '#DB0B0B',
} as const;

export const baseColors = {
  orange: LIGHT_MODE_COLOR_PALETTE.orange,
  midOrange: LIGHT_MODE_COLOR_PALETTE.midOrange,
  lightOrange: LIGHT_MODE_COLOR_PALETTE.lightOrange,
  outlineGray: LIGHT_MODE_COLOR_PALETTE.outlineGray,
  offBlack: LIGHT_MODE_COLOR_PALETTE.offBlack,
  lightBlack: LIGHT_MODE_COLOR_PALETTE.lightBlack,
  darkGrey: LIGHT_MODE_COLOR_PALETTE.darkGrey,
  midGrey: LIGHT_MODE_COLOR_PALETTE.midGrey,
  lightGrey: LIGHT_MODE_COLOR_PALETTE.lightGrey,
  white: LIGHT_MODE_COLOR_PALETTE.white,
  red: LIGHT_MODE_COLOR_PALETTE.red,

  // semantic names
  hover: LIGHT_MODE_COLOR_PALETTE.lightGrey,
  selected: LIGHT_MODE_COLOR_PALETTE.midGrey,
  selectedWithHover: LIGHT_MODE_COLOR_PALETTE.darkGrey,
  iconColor: LIGHT_MODE_COLOR_PALETTE.offBlack,
  textColor: LIGHT_MODE_COLOR_PALETTE.offBlack,
  // 10% opacity
  grayOverlay: `${LIGHT_MODE_COLOR_PALETTE.offBlack}1a`,
  inputBorder: LIGHT_MODE_COLOR_PALETTE.outlineGray,
  errorRed: LIGHT_MODE_COLOR_PALETTE.red,
} as const;

export const ICON_SIZE = 16;
export const BORDER_RADIUS_IN_PX = '4px';

export const palette = {
  themePrimary: baseColors.orange,
  themeLighterAlt: baseColors.lightOrange,
  themeLighter: baseColors.midOrange,
  themeLight: '#a8d5bc',
  themeTertiary: '#62ab83',
  themeSecondary: '#318456',
  themeDarkAlt: '#1e673f',
  themeDark: '#E86013',
  themeDarker: '#134027',
  neutralLighterAlt: '#f8f8f8',
  neutralLighter: '#f4f4f4',
  neutralLight: '#eaeaea',
  neutralQuaternaryAlt: '#dadada',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c8c8',
  neutralTertiary: '#a19f9d',
  neutralSecondary: '#605e5c',
  neutralPrimaryAlt: '#3b3a39',
  neutralPrimary: '#323130',
  neutralDark: '#201f1e',
  black: '#000000',
  white: '#ffffff',
};

loadTheme({
  palette,
  semanticColors: {
    inputBorder: baseColors.inputBorder,
    inputBorderHovered: baseColors.inputBorder,
    smallInputBorder: baseColors.inputBorder,
  },
});
