import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataSourceEntity } from '../../../../api/model/schemas/DataSourceEntity';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { EmptyMessage, GroupCollapse } from '../../../../components/ui';

import { fetchDesignLinks } from '../../../Designer/Ingestion/actions';
import LinkFields from '../../../Designer/Ingestion/components/LinkFields';
import {
  calculateGroupId,
  LinkGroup,
} from '../../../Designer/Ingestion/components/LinkGroup/LinkGroup';
import {
  getLinkedEntities,
  getLinkedEntityFields,
  getLinksFields,
  getIsLoadingLinks,
  getIsLoadingDataSourceFields,
  getLinksWithoutLinkFields,
  getAllLinks,
} from '../../../Designer/Ingestion/selectors';
import {
  assetClicked,
  AssetClickedIntent,
  fetchStreamLinks,
} from '../../actions';
import {
  createGetSourceData,
  getActiveDataSourceIds,
  getSelectedStream,
  getStreamLinks,
} from '../../selectors';
import { StreamAssetType } from '../../types';
import { LinkFields as LinkFieldsInterface } from '../../../../api/model/schemas/LinkFields';
import { EntityProcessStatus } from '../../../Designer/types';
import { EmptyLinks } from '../../../Designer/Ingestion/screens/Links/EmptyLinks';
import { getSearchQuery } from '../../../Search/selectors';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { LinkType } from '../../../../api/model/schemas/LinkType';
import { LinkStatus } from '../../../../api/model/schemas/LinkStatus';

// This is a copy of Designer/Catalog/Links
// which was adapted for requirements of builder asset selection
// We are waiting for the Backend to "magiaclly" insert links into the stream
// But before that we have to allow the user to select something
const Links = () => {
  // DEPS
  const getSourceData = React.useMemo(createGetSourceData, []);
  const emptyLinksId = 'empty_links';

  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // STATE

  // BUILDER-STATE
  const sourceId = useSelector(getActiveDataSourceIds);
  const sourceData = useSelector((state) => getSourceData(state, sourceId));
  const selectedStream = useSelector(getSelectedStream);
  const {
    streamLinks,
    isLoading: isLoadingStreamLinks,
    recordIdsBeingUpdated,
  } = useSelector(getStreamLinks);

  // DESIGNER STATE (a short cut - becasue this feature is really temporary)
  const allLinks = useSelector(getAllLinks);
  const linkedEntities = useSelector(getLinkedEntities);
  const linkedEntityFields = useSelector(getLinkedEntityFields);
  const linksFields = useSelector(getLinksFields);
  const isLoadingLinks = useSelector(getIsLoadingLinks);
  const isLoadingFields = useSelector(getIsLoadingDataSourceFields);
  const linksWithoutLinkFields = useSelector(getLinksWithoutLinkFields);
  const searchQuery = useSelector(getSearchQuery);

  const [openGroupId, setOpenGroupId] = React.useState<string>(null);

  // DERIVED STATE
  const linksFieldsCount = Object.keys(linksFields).length;
  const isLoading = isLoadingLinks || isLoadingFields || isLoadingStreamLinks;
  const selectedLinkIds = streamLinks.map((link) => link.linkId);
  const hasEmptyLinks = linksWithoutLinkFields.length > 0;

  // iterates over all linked fields and extracts the entities the fields relate to
  // then generated a list of groups in the form of `from entity -> to entity`
  // only with unique pairs
  type FromToEntityPair = [DataSourceEntity, DataSourceEntity];
  const entityBasedGroups: FromToEntityPair[] = React.useMemo(() => {
    const fromToEntityMap = new Map<DataSourceEntity, Set<DataSourceEntity>>();

    Object.values(linksFields).forEach((linkFields) => {
      const fromField = linkedEntityFields[linkFields.fromId];
      const fromEntity = linkedEntities[fromField.parentId];
      const toField = linkedEntityFields[linkFields.toId];
      const toEntity = linkedEntities[toField.parentId];

      const isAccepted =
        allLinks[linkFields.parentId]?.status === LinkStatus.Accepted;

      if (!isAccepted) return;

      if (fromToEntityMap.has(fromEntity))
        fromToEntityMap.get(fromEntity).add(toEntity);
      else fromToEntityMap.set(fromEntity, new Set([toEntity]));
    });

    const flattenedGroups = Array.from(fromToEntityMap).reduce(
      (accumulator, [from, toEntities]) => {
        toEntities.forEach((to) => accumulator.push([from, to]));
        return accumulator;
      },
      [] as FromToEntityPair[]
    );

    return flattenedGroups;
  }, [
    linkedEntities,
    linkedEntityFields,
    linksFields,
    streamLinks,
    recordIdsBeingUpdated,
    sourceId,
  ]);

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:catalog:links:cta:noLinksAvailable':
        linksFieldsCount === 0,
    }),
    [linksFieldsCount]
  );

  // EFFECTS
  const fetchLinks = React.useCallback(() => {
    if (sourceId)
      dispatch(
        fetchDesignLinks({
          dataSourceId: sourceId,
          fetchLinksRequestODataQuery: `status eq '${LinkStatus.Accepted}'`,
        })
      );
  }, [dispatch, sourceId]);
  React.useEffect(fetchLinks, [fetchLinks]);

  React.useEffect(() => {
    if (selectedStream?.id) {
      dispatch(fetchStreamLinks({ streamId: selectedStream?.id }));
    }
  }, [selectedStream?.id]);

  // CALLBACKS

  const onClickItemHandler = (linkFields: LinkFieldsInterface) => {
    dispatch(
      assetClicked({
        entity: { id: linkFields.parentId },
        actionType: streamLinks.some(
          (streamLink) => streamLink.linkId === linkFields.parentId
        )
          ? AssetClickedIntent.REMOVE
          : AssetClickedIntent.ADD,
        assetType: StreamAssetType.Link,
      })
    );
  };

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const groups = React.useMemo(
    () =>
      entityBasedGroups
        .sort((pairA, pairB) => pairA[0].name.localeCompare(pairB[0].name))
        .map(([from, to]) => {
          const filteredLinkFields = Object.values(linksFields).filter(
            (linkFields) => {
              const fromEntityField = linkedEntityFields[linkFields.fromId];
              const toEntityField = linkedEntityFields[linkFields.toId];

              const isMatching =
                fromEntityField.parentId === from.id &&
                toEntityField.parentId === to.id;

              return isMatching;
            }
          );

          const isParentSelected = filteredLinkFields.some((linkFields) =>
            streamLinks.find(
              (streamLink) => streamLink.linkId === linkFields.parentId
            )
          );

          // In the recent change, links are given name
          // filteredLinkFields have the same parent
          const groupName = filteredLinkFields[0]?.parent?.name

          const linkFieldComponents = filteredLinkFields.map((linkFields) => {
            const relatedStreamLink = streamLinks.find(
              (streamLink) => streamLink.linkId === linkFields.parentId
            );

            const isQueued =
              relatedStreamLink &&
              relatedStreamLink.processStatus ===
                EntityProcessStatus.NotProcessed;

            const relatedDesignLink = allLinks[linkFields.parentId];

            const isDisabled =
              relatedDesignLink &&
              // enable disabling previously selected smart links
              !relatedStreamLink &&
              [relatedDesignLink.analyzedType, relatedDesignLink.type].includes(
                LinkType.ManyToMany
              );

            if (!sourceData?.name) return null;

            return (
              <LinkFields
                {...{
                  linkFields,
                  sourceData,
                  searchQuery,
                  isQueued,
                  isDisabled,
                }}
                key={linkFields.id}
                onClick={() => onClickItemHandler(linkFields)}
                isSelected={!!relatedStreamLink}
                isSyncing={recordIdsBeingUpdated.includes(linkFields.parentId)}
              />
            );
          });

          return (
            <LinkGroup
              {...{
                from,
                openGroupId,
                setOpenGroupId,
                to,
                searchQuery,
                groupName: groupName
              }}
              key={calculateGroupId(from.id, to.id)}
              isSelected={isParentSelected}
              isSyncing={filteredLinkFields.some((linkFields) =>
                recordIdsBeingUpdated.includes(linkFields.parentId)
              )}
            >
              {linkFieldComponents}
            </LinkGroup>
          );
        }),
    [
      entityBasedGroups,
      linkedEntityFields,
      linksFields,
      openGroupId,
      sourceData,
      searchQuery,
    ]
  );

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isLoading && linksFieldsCount === 0) {
    return (
      <EmptyMessage
        message={t('wizard:noEntityDefined', {
          entityType: t('entityTypes:Link', { count: 0 }),
        })}
      />
    );
  }

  return (
    <section>
      {hasEmptyLinks && (
        <GroupCollapse
          isOpen={openGroupId === emptyLinksId}
          groupId={emptyLinksId}
          groupName={t('modeBar:ingestion.linksWithoutLinkFields')}
          onClick={() => setOpenGroupId(emptyLinksId)}
        >
          <EmptyLinks
            disableOnclick
            selectedLinkIds={selectedLinkIds}
            sourceData={sourceData}
          />
        </GroupCollapse>
      )}
      {groups}
    </section>
  );
};

export default Links;
