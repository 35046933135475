import axios from "axios";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FETCH_RECORDS_BY_TYPE_ID } from "./utils";
import {classNames} from './styles'

interface Props {
  entityId: string;
  typeId: string;
}

export const RecordCount = ({ entityId, typeId }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = React.useState(false);
  const [recordCount, setRecordCount] = React.useState(null);

  React.useEffect(() => {
    const source = axios.CancelToken.source()

    const fetchData = async () => {

      const fetchRecordFunction = FETCH_RECORDS_BY_TYPE_ID[typeId]

      if (!fetchRecordFunction) return

      setLoading(true);

      try {
        const records = await fetchRecordFunction({
          entityId,
          typeId,
          cancelToken: source.token
        })
        setRecordCount(records);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        } 
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      source.cancel()
    }
  }, [entityId, typeId]);

  if (isLoading)
    return (
      <Spinner
        className={classNames.recordSpinner}
        size={SpinnerSize.xSmall}
      />
    );

  return (
    <p
      className={classNames.recordCount}
    >
      {recordCount === null || recordCount === undefined
        ? t("wizard:unknownRecords")
        : `${recordCount} ${t("wizard:Record", { count: recordCount })}`}
    </p>
  );
};
