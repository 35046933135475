import React from 'react';
import html2canvas from 'html2canvas';
import { Dialog, IDialogContentProps, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  classNames,
  dialogStyles,
  dialogContentStyles,
  dialogPreviewListStyles,
  shareSolutionColorVar,
} from './styles';
import {
  I18N_PREFIX,
  MODAL_PROPS,
  SHARE_SOLUTIONS,
  SHARE_SOLUTIONS_ORDER,
} from './constants';
import { toggleSharePreview } from '../../../../actions';
import { classNames as outputClassNames } from '../../styles';
import {
  CARD_KEY_PROP,
  getCardsNormalizedOutput,
  getSelectedCardKeys,
} from '../../../../selectors';
import OutputCard from '../OutputCard';
import {
  getFetchedAssetsAsStreamEntities,
  getSelectedStream,
} from '../../../../../../selectors';
import { getAllStreamerFilters } from '../../../../../../Filters/selectors';
import { OnShareCallback, ShareLinkDataType, ShareSolution } from './model';
import { NormalProps as NormalCardProps } from '../OutputCard/OutputCard';
import { useSetSafeTimeout } from '../../../../../../../../../shared/hooks/useSetSafeTimeout';
import { ShareSmartObjectButton } from './components/ShareSmartObjectButton';
import { ShareLinkButton } from './components/ShareLinkButton';
import { ShareImageButton } from './components/ShareImageButton';

type ExtendedCardProps = Pick<NormalCardProps, 'selectionPreviewsRef'>;

type Props = ExtendedCardProps;

export const SharePreview = ({ selectionPreviewsRef }: Props) => {
  // DEPS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const setSafeTimeout = useSetSafeTimeout();

  // STATE
  const [writingToClipboard, setWritingToClipboard] =
    React.useState<ShareLinkDataType>();
  const [copyingFinished, setCopyingFinished] =
    React.useState<ShareLinkDataType>();
  const [componentError, setComponentError] = React.useState<unknown>();
  const [previewDiv, setPreviewDiv] = React.useState<HTMLDivElement>();
  const [canvas, setCanvas] = React.useState<HTMLCanvasElement>();
  const selectedCardKeys = useSelector(getSelectedCardKeys);
  const assets = useSelector(getFetchedAssetsAsStreamEntities);
  const filters = useSelector(getAllStreamerFilters);
  const entries = useSelector(getCardsNormalizedOutput);
  const stream = useSelector(getSelectedStream);
  const [selectedSolution, setSelectedSolution] = React.useState<ShareSolution>(
    ShareSolution.Eyko
  );

  // DERIVED STATE
  const isGeneratingPreview = !canvas;
  const dialogContentProps = React.useMemo<IDialogContentProps>(
    () => ({
      title: t(`${I18N_PREFIX}.title`),
      styles: dialogContentStyles,
    }),
    [t]
  );

  const selectedEntries = React.useMemo(
    () =>
      entries.filter((entry) =>
        selectedCardKeys.includes(entry[CARD_KEY_PROP])
      ),
    [entries, selectedCardKeys]
  );

  const selectedSolutionConfig = SHARE_SOLUTIONS[selectedSolution];
  const selectedSolutionStyle = React.useMemo<React.CSSProperties>(
    () =>
      ({
        [shareSolutionColorVar]: selectedSolutionConfig.color,
      } as React.CSSProperties),
    [selectedSolutionConfig.color]
  );

  // CALLBACKS
  const onClose = React.useCallback(() => {
    dispatch(toggleSharePreview(false));
  }, [dispatch]);

  const onShare = React.useCallback(
    async (
      shareButtonType: ShareLinkDataType,
      shareCallback: OnShareCallback
    ) => {
      const selectionPreviews = selectionPreviewsRef.current;

      if (!selectionPreviews) {
        setComponentError(t(`${I18N_PREFIX}.error`));
        return;
      }

      setComponentError(undefined);
      setCopyingFinished(undefined);
      setWritingToClipboard(shareButtonType);

      try {
        await shareCallback(selectionPreviews);
      } catch (error) {
        setComponentError(t(`${I18N_PREFIX}.error`));
      }

      setWritingToClipboard(undefined);
      setCopyingFinished(shareButtonType);
    },
    [t]
  );

  const onImageShare = React.useCallback(() => {
    setComponentError(undefined);
    setCopyingFinished(undefined);
    setWritingToClipboard(ShareLinkDataType.Image);

    canvas.toBlob(async (imageBlob) => {
      try {
        const data = [
          new ClipboardItem({
            // @ts-ignore – TS types require the value of the dictionary to be a promise
            // while using `Promise.resolve()` to circumvent that causes runtime errors
            [ShareLinkDataType.Image]: new Blob([imageBlob], {
              type: ShareLinkDataType.Image,
            }),
          }),
        ];

        await navigator.clipboard.write(data);
      } catch (error) {
        setComponentError(t(`${I18N_PREFIX}.error`));
      }

      setWritingToClipboard(undefined);
      setCopyingFinished(ShareLinkDataType.Image);
    });
  }, [canvas]);

  // EFFECTS
  const onPreviewChange = React.useCallback(() => {
    if (!previewDiv) return;

    // let the UI update before blocking it with `html2canvas`
    setSafeTimeout(async () => {
      try {
        // NOTE: apart from generating per-card previews inside `OutputCard` that are used for DnD, the one here
        // generates a preview for the list of all selected cards as a single image because it's impossible
        // with the current state of clipboard API to place multiple, separate images in the clipboard
        setCanvas(await html2canvas(previewDiv));
      } catch (error) {
        setComponentError(t(`${I18N_PREFIX}.error`));
        console.error(error);
      }
    }, 0);
  }, [previewDiv, setSafeTimeout]);
  React.useEffect(() => {
    onPreviewChange();
  }, [onPreviewChange]);

  // PARTS
  const previewList = selectedEntries.map((entry) => (
    <OutputCard
      {...{
        assets,
        entry,
        filters,
      }}
      isPreview
      key={entry[CARD_KEY_PROP]}
      onRef={() => {}}
      parentTitle={stream.name}
    />
  ));

  const SelectedSolutionLogo = selectedSolutionConfig.logo;

  const shareSolutions = SHARE_SOLUTIONS_ORDER.map((solution) => {
    const solutionConfig = SHARE_SOLUTIONS[solution];
    const SolutionLogo = solutionConfig.logo;

    return (
      <button
        className={cn(classNames.shareSolutionButton, {
          [classNames.shareSolutionButtonSelected]:
            solution === selectedSolution,
        })}
        key={solution}
        onClick={() => setSelectedSolution(solution)}
        style={{ borderColor: solutionConfig.color }}
        type="button"
      >
        <SolutionLogo />
      </button>
    );
  });

  // RENDER
  return (
    <Dialog
      {...{
        dialogContentProps,
      }}
      hidden={false}
      modalProps={MODAL_PROPS}
      onDismiss={onClose}
      styles={dialogStyles}
    >
      <Stack
        className={outputClassNames.background}
        horizontal
        styles={dialogPreviewListStyles}
      >
        <div className={classNames.sharePreview} ref={setPreviewDiv}>
          {previewList}
        </div>
        {isGeneratingPreview && (
          <div className={classNames.overlay}>
            <span>{componentError || t(`${I18N_PREFIX}.generating`)}</span>
          </div>
        )}
      </Stack>
      <Stack className={classNames.shareInfo}>
        <Stack horizontal>
          <SelectedSolutionLogo className={classNames.shareInfoLogo} />
          <Stack>
            <h3 className={classNames.shareInfoTitle}>
              {t(`${I18N_PREFIX}.options.${selectedSolution}.title`)}
            </h3>
            <p className={classNames.shareInfoDescription}>
              {t(`${I18N_PREFIX}.options.${selectedSolution}.description`)}
            </p>
          </Stack>
        </Stack>
        <Stack
          style={selectedSolutionStyle}
          className={classNames.shareInfoButtons}
          horizontal
        >
          {selectedSolutionConfig.options.includes(ShareLinkDataType.Rich) && (
            <ShareSmartObjectButton
              {...{
                canvas,
                copyingFinished,
                onShare,
                selectedCardKeys,
                writingToClipboard,
              }}
              isError={Boolean(componentError)}
            />
          )}
          {selectedSolutionConfig.options.includes(ShareLinkDataType.Plain) && (
            <ShareLinkButton
              {...{
                copyingFinished,
                onShare,
                selectedCardKeys,
                writingToClipboard,
              }}
              isError={Boolean(componentError)}
            />
          )}
          {selectedSolutionConfig.options.includes(ShareLinkDataType.Image) && (
            <ShareImageButton
              {...{
                copyingFinished,
                onImageShare,
                writingToClipboard,
              }}
              isError={Boolean(componentError)}
            />
          )}
        </Stack>
      </Stack>
      <Stack className={classNames.shareSolutions} horizontal>
        {shareSolutions}
      </Stack>
    </Dialog>
  );
};
