import { AxiosResponse } from 'axios';
import * as effects from 'redux-saga/effects';
import { StreamCalculation } from '../../../api/model/schemas/StreamCalculation';
import { StreamGroupAsset } from '../../../api/model/schemas/StreamGroupAsset';
import { StreamHub } from '../../../api/model/schemas/StreamHub';
import { StreamLookup } from '../../../api/model/schemas/StreamLookup';
import { StreamMachineLearning } from '../../../api/model/schemas/StreamMachineLearning';
import {
  fetchStreamCalculations,
  fetchStreamFields,
  fetchStreamGroupsAssets,
  fetchStreamHubs,
  fetchStreamMachineLearning,
  fetchStreamLookups,
} from '../../Builder/api';
import { ApiV4ResponseWrapper, IStreamField } from '../../Designer/types';
import { setIsFetchingAvailableColumns } from '../actions';
import {
  parseCalculationsToStreamerColumns,
  parseFieldsToStreamerColumns,
  parseGroupAssetsToStreamerColumn,
  parseHubsToStreamerColumns,
  parseLookupToStreamerColumn,
  parseMLAssetTOStreamerColumn,
} from './utils';

export function* fetchStreamAvailableColumns(streamId: string) {
  yield effects.put(setIsFetchingAvailableColumns(true));

  const {
    data: { data: streamFields },
  }: AxiosResponse<ApiV4ResponseWrapper<IStreamField[]>> = yield effects.call(
    fetchStreamFields(streamId),
    {
      expand: 'sourceField($expand=parent($expand=parent))',
    }
  );

  const {
    data: { data: streamsCalculations },
  }: AxiosResponse<ApiV4ResponseWrapper<StreamCalculation[]>> =
    yield effects.call(fetchStreamCalculations(streamId));

  const {
    data: { data: streamHubs },
  }: AxiosResponse<ApiV4ResponseWrapper<StreamHub[]>> = yield effects.call(
    fetchStreamHubs(streamId)
  );

  const {
    data: { data: streamGroupAssets },
  }: AxiosResponse<ApiV4ResponseWrapper<StreamGroupAsset[]>> =
    yield effects.call(fetchStreamGroupsAssets(streamId));
  const {
    data: { data: machineLearningAssets },
  }: AxiosResponse<ApiV4ResponseWrapper<StreamMachineLearning[]>> =
    yield effects.call(fetchStreamMachineLearning(streamId));
  const {
    data: { data: streamLookups },
  }: AxiosResponse<ApiV4ResponseWrapper<StreamLookup[]>> = yield effects.call(
    fetchStreamLookups(streamId)
  );

  const availableColumns = [
    ...parseFieldsToStreamerColumns(streamFields),
    ...parseCalculationsToStreamerColumns(streamsCalculations),
    ...parseHubsToStreamerColumns(streamHubs),
    ...parseGroupAssetsToStreamerColumn(streamGroupAssets),
    ...parseMLAssetTOStreamerColumn(machineLearningAssets),
    ...parseLookupToStreamerColumn(streamLookups),
  ];

  return availableColumns;
}
