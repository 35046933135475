import {
  CommandButton, DefaultButton, Stack, TextField,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createTagIntent, setNewTagInputOpen } from '../../actions';
import { getIsNewTagInputOpen } from '../../selectors';

import {
  containerStyles,
  closeButtonStyles,
  textFieldStyles,
  clearButtonStyles,
  inputWrapperStyles,
} from './styles';

const NewTagInput = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const isNewTagInputOpen = useSelector(getIsNewTagInputOpen);

  const onChangeHandler = (_: any, newValue: string) => {
    setValue(newValue);
  };

  const onClearHandler = () => {
    setValue('');
  };

  const onKeyDownHandler: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event,
  ) => {
    if (event.key === 'Enter') {
      dispatch(createTagIntent(value));
      setValue('');
    }
  };

  const onCloseHandler = () => dispatch(setNewTagInputOpen(false));

  React.useEffect(() => () => {
    dispatch(setNewTagInputOpen(false));
  }, []);

  if (!isNewTagInputOpen) {
    return null;
  }

  return (
    <Stack styles={containerStyles}>
      <Stack styles={inputWrapperStyles}>
        <TextField
          autoFocus
          placeholder={t('modeBar:metadata.newTagPlaceholder')}
          value={value}
          styles={textFieldStyles}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          data-testid="new-tag-input"
          iconProps={{
            iconName: 'Add',
          }}
        />

        <CommandButton
          iconProps={{
            iconName: 'Cancel',
          }}
          onClick={onClearHandler}
          styles={clearButtonStyles(!value)}
        />
      </Stack>

      <DefaultButton
        iconProps={{
          iconName: 'Leave',
        }}
        onClick={onCloseHandler}
        styles={closeButtonStyles}
      />
    </Stack>
  );
};

export default NewTagInput;
