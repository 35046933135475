import { v4 as uuid4 } from 'uuid';
import { omit } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AggregationType } from '../../../api/model/schemas/AggregationType';
import { OrderByDirection } from '../../../api/model/schemas/OrderByDirection';
import { StreamDataRequest } from '../../../api/model/schemas/StreamDataRequest';
import { fetchEntity } from '../../Designer/api';
import { setSelectedAssets } from '../actions';
import { setFilters } from '../Filters/actions';
import { getAvailableAssets } from '../selectors';
import { StreamAssetRaw, StreamerAsset, StreamerFilter } from '../types';
import { normalizeById } from '../../../utils/normalizeEntities';
import { StreamDataRequestElement } from '../../../api/model/schemas/StreamDataRequestElement';
import { adjustComparisonType } from '../Filters/components/utils';

interface Params {
  configurationId: string;
  configurationTypeId: string;
}

type ConfigurationResponse = {
  data: StreamDataRequest;
};

export const useLoadConfiguration = ({
  configurationId,
  configurationTypeId,
}: Params) => {
  // HOOKS
  const history = useHistory();
  const dispatch = useDispatch();

  // STATE
  const [storedStreamDataRequest, setStreamDataRequest] =
    React.useState<Partial<StreamDataRequest>>(undefined);
  const [isLoading, setLoading] = React.useState(false);
  const { records: rawAssets } = useSelector(getAvailableAssets);

  // DERIVED STATE
  const normalizedStreamDataRequestData = React.useMemo(
    () =>
      storedStreamDataRequest?.data
        ? normalizeById<StreamDataRequestElement>(storedStreamDataRequest?.data)
        : {},
    [storedStreamDataRequest?.data]
  );

  const normalizedRawAssets = React.useMemo(
    () => (rawAssets ? normalizeById<StreamAssetRaw>(rawAssets) : {}),
    [rawAssets]
  );

  // CALLBACKS
  const fetchConfig = React.useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchEntity<ConfigurationResponse>(
        configurationTypeId,
        configurationId
      )();

      setStreamDataRequest(data.data);
    } finally {
      setLoading(false);
    }
  }, [configurationTypeId, configurationId]);

  const setLastLoadedConfigurationAsSelection = () => {
    const assets: StreamerAsset[] = storedStreamDataRequest?.data.map(
      (entry) => ({
        ...omit(entry, 'id'),
        aggregation: entry?.aggregation ?? AggregationType.None,
        sortMode: entry?.sortMode ?? OrderByDirection.Ascending,
      })
    );

    const getRawAssetByFieldId = (fieldId: string) => {
      const relatedAsset = normalizedStreamDataRequestData[fieldId];
      return normalizedRawAssets[relatedAsset.streamElementId];
    };

    const filters: StreamerFilter[] =
      storedStreamDataRequest?.filter?.groupFilters?.map((groupFilter) => {
        const filter = groupFilter?.fieldFilters[0];

        const streamerFilter: StreamerFilter = {
          aggregation: AggregationType.None,
          id: uuid4(),
          compareValue: filter?.compareValue,
          comparison: adjustComparisonType(filter as unknown as StreamerFilter)
            ?.comparison,
          item: getRawAssetByFieldId(groupFilter.fieldId),
          lowerBoundValue: filter?.lowerBoundValue,
          type: filter?.type,
          upperBoundValue: filter?.upperBoundValue,
          values: filter?.values,
        };

        return streamerFilter;
      });

    dispatch(setSelectedAssets(assets));
    if (filters) {
      dispatch(setFilters(filters));
    }
    history.go(-1);
  };

  React.useEffect(() => {
    if (!configurationId || !configurationTypeId) return;
    fetchConfig();
  }, [configurationId, configurationTypeId]);

  return {
    loadConfigurationAsSelection: setLastLoadedConfigurationAsSelection,
    isLoading,
    canLoadSelection: storedStreamDataRequest?.data?.length > 0,
    selectedAssetsCount: storedStreamDataRequest?.data?.length,
    selectedFiltersCount: storedStreamDataRequest?.filter?.groupFilters?.length,
  };
};
