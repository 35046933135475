import { IDesignerFilter } from '../types/IDesignerFilter';
import { ComparisonTypes, FilterTypes } from '../types/IStreamFilter';

export const adjustComparisonType = (
  editedFilter: IDesignerFilter
): IDesignerFilter => {
  if (editedFilter?.lowerBoundValue && editedFilter?.upperBoundValue) {
    return {
      ...editedFilter,
      comparison:
        editedFilter?.comparison === ComparisonTypes.Equal
          ? ComparisonTypes.Between
          : ComparisonTypes.NotBetween,
    };
  }

  if (Array.isArray(editedFilter?.values)) {
    return {
      ...editedFilter,
      comparison:
        editedFilter?.comparison === ComparisonTypes.Equal
          ? ComparisonTypes.In
          : ComparisonTypes.NotIn,
    };
  }

  if (
    editedFilter?.comparison === ComparisonTypes.NotEqual &&
    editedFilter?.type === FilterTypes.Comparison &&
    editedFilter?.compareValue === ''
  ) {
    return {
      ...editedFilter,
      comparison: ComparisonTypes.IsNotBlank,
    };
  }

  if (
    editedFilter?.comparison === ComparisonTypes.Equal &&
    editedFilter?.type === FilterTypes.Comparison &&
    editedFilter?.compareValue === ''
  ) {
    return {
      ...editedFilter,
      comparison: ComparisonTypes.IsBlank,
    };
  }

  if (
    editedFilter?.comparison === ComparisonTypes.NotEqual &&
    editedFilter?.type === FilterTypes.Comparison &&
    editedFilter?.compareValue === undefined
  ) {
    return {
      ...editedFilter,
      comparison: ComparisonTypes.IsNotNull,
    };
  }

  if (
    editedFilter?.comparison === ComparisonTypes.Equal &&
    editedFilter?.type === FilterTypes.Comparison &&
    editedFilter?.compareValue === undefined
  ) {
    return {
      ...editedFilter,
      comparison: ComparisonTypes.IsNull,
    };
  }

  return editedFilter;
};
