import * as persistenceActions from '../../store/persistence/actions';

import * as realExcelActions from './excel-actions';
import handleRemovedDatasets from './handleRemovedDatasets';
import listenWorksheetDeleted from './listenWorksheetDeleted';

// Because right now we cannot distinguish between EDIT_DATASET
// actions, we need a way to find when we should sync datasets between users.
// those excel-actions listed below will trigger sync + repaint changed datasets
// in co-authoring users
const handler = {
  get(target, prop) {
    const actionsThatRequireSync = [
      'mapDataFromSelectedRange',
      'loadDataContinuation',
      'removeTable',
      'generatePivotTable',
      'onDeleteTableColumn',
      'renderDataset',
      'updateDataset',
      'mapContinuation',
      'toggleExcelEvents',
    ];
    const property = target[prop];
    let proxiedFunc = null;

    if (actionsThatRequireSync.includes(prop)) {
      proxiedFunc = async (...args) => {
        window.sharedState.store.dispatch(persistenceActions.blockUiOfOtherUsers());

        const result = await property(...args);

        window.sharedState.store.dispatch(persistenceActions.syncPersistedData());
        return result;
      };
    }

    return proxiedFunc || property;
  },
};

// IE 11 Fix
let proxiedExcelActions = realExcelActions;

if (window.Proxy) {
  proxiedExcelActions = new window.Proxy(realExcelActions, handler);
}

const actions = proxiedExcelActions;

const {
  debug,
  removeTable,
  generatePivotTable,
  renderDataset,
  clearStoredSettings,
  mapContinuation,
  renderDatasetAfterSync,
  addTableEventListeners,
  toggleExcelEvents,
} = actions as typeof realExcelActions;

// We need update dataset to interact with during cypress tests
// because it handles some of the redux changes inside
const { updateDataset } = proxiedExcelActions;

export {
  debug,
  removeTable,
  generatePivotTable,
  renderDataset,
  clearStoredSettings,
  mapContinuation,
  renderDatasetAfterSync,
  updateDataset,
  addTableEventListeners,
  handleRemovedDatasets,
  listenWorksheetDeleted,
  toggleExcelEvents,
};
