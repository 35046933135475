import { Spinner, Stack } from '@fluentui/react';
import React, { FunctionComponent, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SectionTitle from '../../../../components/SectionTitle';
import ViewControlButton from '../../../../components/ui/ViewControlButton';
import useSortControl from '../../../../hooks/useSortControl';
import { getSelectedDesignId } from '../../ContentLibrary/selectors';

import { fetchTypeSections } from '../../api';
import { IDesignSource, ITypeSectionProperty } from '../../types';
import useConfig, { SortControlOption } from './useConfig';
import { entitySchemaPropertiesToSortConfig } from './utils';
import { viewControlContainerStyles, classNames } from './styles';
import { useObservable } from '../../../../utils/observable';
import { activeRequestsCount } from '../../../../api/interceptors/requestsCount';
import { ViewControlButtonProps } from '../../../../components/ui/ViewControlButton/ViewControlButton';

type ExtendedViewControlProps = Pick<ViewControlButtonProps,
  | 'selectionItemsOverride'
>;

interface SortControlProps extends ExtendedViewControlProps {
  children?: ReactNode;
  showDataSourceOptions?: boolean;
  dataSources?: IDesignSource[];
  onChangeSource?(key: string): void;
  selectedDataSourceId?: string;
  title?: string;
}

const SortControl: FunctionComponent<SortControlProps> = ({
  children,
  showDataSourceOptions,
  dataSources,
  onChangeSource,
  selectedDataSourceId,
  title,
  selectionItemsOverride,
}) => {
  // DEPS
  const {
    defaultSort,
    staticSortOptions,
    typeIdForFetchingDynamicSortConfig,
    sectionTitle,
    showSelectionOptions,
  } = useConfig();

  const {
    currentSort,
    setSortType,
    selectionType,
    setSelectionType,
    sortHistory,
  } = useSortControl();

  const { pathname } = useLocation();
  // STATE
  const designId = useSelector(getSelectedDesignId);
  const [sortOptions, setSortOptions] =
    React.useState<SortControlOption[]>(staticSortOptions);
  const [isLoading, setLoading] = React.useState(false);
  const [requestCount] = useObservable(activeRequestsCount);

  // DERIVED STATE
  const isSyncing = isLoading || requestCount > 0;
  const hasSortConfigDefined = staticSortOptions.length > 0;

  // EFFECTS
  React.useEffect(() => {
    setSortOptions(staticSortOptions);
  }, [pathname]);

  React.useEffect(() => {
    if (sortHistory[pathname]) {
      setSortType(sortHistory[pathname]);
      return;
    }

    if (defaultSort) {
      setSortType(defaultSort);
    }
  }, [pathname, defaultSort]);

  React.useEffect(() => {
    const addSortOptions = async () => {
      try {
        setLoading(true);
        const response = await fetchTypeSections(
          typeIdForFetchingDynamicSortConfig
        )({ designId });

        const allProperties = response.data.reduce<ITypeSectionProperty[]>(
          (acc, currentSection) => {
            const currentSectionProperties = currentSection.properties;
            acc.push(...currentSectionProperties);

            return acc;
          },
          []
        );

        const sortTypesToSkip = staticSortOptions.map((option) => option.key);
        const dynamicSortOptions = entitySchemaPropertiesToSortConfig(
          allProperties,
          sortTypesToSkip
        );
        const newSortOptions = [
          ...staticSortOptions,
          ...dynamicSortOptions,
        ].sort((optA, optB) => optA.name.localeCompare(optB.name));
        setSortOptions(newSortOptions);
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };

    if (typeIdForFetchingDynamicSortConfig) {
      addSortOptions();
    }
  }, [designId, typeIdForFetchingDynamicSortConfig]);

  return (
    <Stack horizontal horizontalAlign="space-between">
      <SectionTitle title={title || sectionTitle} />

      <Stack
        id="sort-control-stack"
        horizontal
        styles={viewControlContainerStyles}
      >
        {children}
        {isSyncing && <Spinner style={{ width: '32px', height: '32px' }} />}
        {!isSyncing && hasSortConfigDefined && (
          <ViewControlButton
            {...{
              dataSources,
              onChangeSource,
              showDataSourceOptions,
              showSelectionOptions,
              selectedDataSourceId,
              selectionItemsOverride,
            }}
            activeItem={currentSort}
            onChangeItem={setSortType}
            options={sortOptions}
            activeSelection={selectionType}
            onChangeSelection={setSelectionType}
            className={classNames.sortControlWrapper}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default SortControl;
