import React from "react";
import { InvitationStatus } from "../../../../api/model/schemas/InvitationStatus";
import { IUser, UserRoles } from "../../../../types/IUser";
import { isUserPermitted } from "../../../../utils/isUserPermitted";
import * as api from "../../api";

export const useGetUsers = (visibleUsersRoles: UserRoles[]) => {
  const [isLoading, setLoading] = React.useState(false);
  const [hasError, setError] = React.useState(false);
  const [users, setUsers] = React.useState<IUser[]>([]);

  React.useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const resp = await api.fetchUsers();

        const permittedUsers = resp.data.data
          .filter(
            (user) =>
              user.invitationStatus === InvitationStatus.Accepted ||
              // temporary fix for in-house staging users
              // who do not have any invitation status (because they are never sent)
              // this should be fixed on the BE later on, but we want to unblock the business
              !user?.invitationStatus
          )
          .filter((user) => isUserPermitted(user.role, visibleUsersRoles));

        setUsers(permittedUsers);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { isLoading, users, hasError };
};
