import { mergeStyleSets } from '@fluentui/react';
import { baseColors, palette } from '../../../theme';

export const classNames = mergeStyleSets({
  sublabel: {
    color: palette.neutralTertiary,
    fontSize: '0.75em',
    fontWeight: 300,
    selectors: {
      '&.disabled': {
        color: baseColors.outlineGray,
      },
    },
  },
});
