import { matchPath, generatePath } from 'react-router-dom';
import { TYPE_IDS } from '../constants/apiV4TypeIds';
import { routes } from '../constants/routes';

export const deleteEntityRoute = (schemaId: string, entityId: string) =>
  generatePath(routes.delete.topLevelEntity, {
    schemaId,
    entityId,
  });

export const addTopLevelEntityRoute = (schemaId: string) =>
  generatePath(routes.add.topLevelEntity, { schemaId });
export const addNestedEntityRoute = (params: {
  schemaId: string;
  parentSchemaId: string;
  parentEntityId: string;
}) => generatePath(routes.add.nestedEntity, params);
export const editEntityRoute = (schemaId: string, entityId?: string) =>
  generatePath(routes.edit.entity, { schemaId, entityId });
export const machineLearningPreviewRoute = (assetId: string) =>
  generatePath(routes.designer.catalog.machineLearningPreview, { assetId });

export const isInDesigner = (pathname: string) =>
  matchPath(pathname, [routes.designer.index]);
export const isInStreamer = (pathname: string) =>
  matchPath(pathname, [
    routes.streams.index,
    routes.visuals.index,
    routes.process.streamFromStreamer,
    routes.favorites.index,
    routes.groupTable.design,
    routes.streamer.dataMode.output,
    routes.streamer.export.index,
  ]);

export const isInIsolatedStreamer = (pathname: string) =>
  matchPath(pathname, [
    routes.streamer.dataMode.filters.index,
    routes.streamer.dataMode.layout,
    routes.streamer.dataMode.index,
    routes.streamer.dataMode.selectAssets,
    routes.streamer.dataMode.output,
  ]);
export const isInFavoritesStreamer = (pathname: string) =>
  matchPath(pathname, [routes.favorites.index]);
export const isInDeleteWizard = (pathname: string) =>
  matchPath(pathname, [routes.delete.index]);
export const isInEditWizard = (pathname: string) =>
  matchPath(pathname, [routes.edit.entity]);
export const isInAddWizard = (pathname: string) =>
  matchPath(pathname, [routes.add.index]);
export const isInBuilder = (pathname: string) =>
  matchPath(pathname, [routes.builder.index]);
export const isInSettings = (pathname: string) =>
  matchPath(pathname, [routes.settings.index]);
export const isInNotifications = (pathname: string) =>
  matchPath(pathname, [routes.notifications.index]);
export const isInHelp = (pathname: string) =>
  matchPath(pathname, [routes.help.index]);
export const isInShareWizard = (pathname: string) =>
  matchPath(pathname, [routes.share.index]);
export const isInDesignShare = (pathname: string) =>
  matchPath(pathname, [routes.share.design]);
export const isInStreamShare = (pathname: string) =>
  matchPath(pathname, [routes.share.stream]);
export const isStreamProcessBuilder = (pathname: string) =>
  matchPath(pathname, [routes.process.streamFromBuilder]);
export const isStreamProcessStreamer = (pathname: string) =>
  matchPath(pathname, [routes.process.streamFromStreamer]);
export const isCreatingButNotDesignOrStream = (pathname: string) => {
  const match = matchPath<{ schemaId: string }>(pathname, [
    routes.add.nestedEntity,
    routes.add.topLevelEntity,
  ]);

  if (!match) return false;
  if (match.params.schemaId === TYPE_IDS.Design) return false;
  if (match.params.schemaId === TYPE_IDS.Stream) return false;

  return true;
};

export const isInAssetLinksWizard = (pathname: string) =>
  matchPath(pathname, [routes.builder.assets.linksWizard]);

export const isInDesignerLinks = (pathname: string) =>
  matchPath(pathname, [routes.designer.catalog.links]);
