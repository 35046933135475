import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { ModuleName, SectionName } from './model';
import { getMostRecentPaths } from './selectors';
import { SectionRoute } from './SectionRoute';

export type SectionRoute = [
  /* path:  */ string,
  /* component:  */ React.ComponentType
];

export interface Props {
  defaultPath?: string;
  module: ModuleName;
  routes: SectionRoute[];
  section: SectionName;
  ignoredRoutes?: string[];
}

export const SectionSwitch = ({
  defaultPath,
  module,
  routes,
  section,
  ignoredRoutes,
}: Props) => {
  // STATE
  const mostRecentPath = useSelector(getMostRecentPaths)[module]?.[section];

  // DERIVED STATE
  const redirectTo = mostRecentPath
    ? ignoredRoutes?.includes(mostRecentPath)
      ? defaultPath
      : mostRecentPath
    : defaultPath;

  // RENDER
  return (
    <Switch>
      {routes.map(([path, component]) => (
        <SectionRoute key={path} {...{ component, module, path, section }} />
      ))}
      {defaultPath && <Redirect to={redirectTo} />}
    </Switch>
  );
};
