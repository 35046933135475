import {
  IButtonStyles,
  ICommandBarStyles,
  IIconStyles,
  mergeStyles,
  getTheme,
} from '@fluentui/react';

import { baseColors, BORDER_RADIUS_IN_PX } from '../../theme';

const theme = getTheme();

export const commandBarStyles: ICommandBarStyles = {
  root: {
    backgroundColor: baseColors.white,
    borderBottomLeftRadius: BORDER_RADIUS_IN_PX,
    paddingLeft: 0,
    height: '32px',
    selectors: {
      '.ms-OverflowSet-item:first-child .ms-Button': {
        borderLeft: `1px solid ${baseColors.outlineGray}`,
        borderBottomLeftRadius: BORDER_RADIUS_IN_PX,
      },
      '.ms-OverflowSet-item:last-child .ms-Button': {
        borderRight: `1px solid ${baseColors.outlineGray}`,
        borderBottomRightRadius: BORDER_RADIUS_IN_PX,
      },
    },
  },
};

export const buttonItemStyles: IButtonStyles = {
  root: {
    margin: 0,
    borderTop: `1px solid ${baseColors.outlineGray}`,
    marginTop: '-1px',
    height: '100%',
    background: baseColors.lightGrey,
    color: theme.palette.neutralPrimary,
    minWidth: '32px',
    width: '32px',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${baseColors.outlineGray}`,
  },
  rootHovered: {
    background: baseColors.midGrey,
    selectors: {
      '.ms-Button-icon': {
        color: theme.palette.black,
      },
    },
  },
};

export const activeItemClassName = mergeStyles({
  background: baseColors.white,
  borderTop: 0,
  borderLeft: `1px solid ${baseColors.outlineGray}`,
  borderColor: baseColors.outlineGray,
  borderRight: `1px solid ${baseColors.outlineGray}`,
  cursor: 'default !important',
  selectors: {
    '&:hover': {
      background: baseColors.white,
    },
    '.ms-Button-icon': {
      color: baseColors.iconColor,
    },
  },
});

export const iconItemStyles: IIconStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    height: 16,
    width: 16,
  },
};
