import { all } from 'redux-saga/effects';

import streamsSagas from '../modules/Streams/sagas';
import filterSagas from '../modules/StreamFilters/sagas';
import notificationSagas from '../modules/Notifications/sagas';
import groupTableSagas from '../modules/GroupTable/sagas';
import designerSagas from '../modules/Designer/sagas';
import builderSagas from '../modules/Builder/sagas';
import streamerSagas from '../modules/Streamer/sagas';

export default function* rootSaga() {
  yield all([
    streamsSagas(),
    filterSagas(),
    notificationSagas(),
    groupTableSagas(),
    designerSagas(),
    builderSagas(),
    streamerSagas(),
  ]);
}
