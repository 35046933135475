import React, { FunctionComponent } from 'react';
import { Stack } from 'office-ui-fabric-react';
import { useLocation, Switch, Route, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { ProgressIndicator } from '@fluentui/react';
import streamSelectors from '../../modules/Streams/selectors';
import { HideSectionCondition } from '../../modules/App/components/Content/constants';
import { getActiveObjectType } from '../../modules/App/selectors';
import { checkShouldRemove } from './utils';
import { routes } from '../../constants/routes';

import SectionTitle from '../SectionTitle';
import ContextualMenu from '../ContextualMenu';
import FooterNav from '../FooterNav';

import SearchBar from '../../modules/Search/components/SearchBar';
import StreamerHeader from '../../modules/Streams/components/StreamerHeader';

import {
  stackStyles,
  containerClassname,
  streamsContainerClassname,
  sectionTitleWrapperClassname,
  designerContainerClassName,
  progressIndicatorStyles,
} from './styles';
import { getSelectedDesignId } from '../../modules/Designer/ContentLibrary/selectors';

import SortControl from '../../modules/Designer/components/SortControl';
import {
  getSelectedBuilderStreamId,
  getSelectedDataSourceIds,
  getStreamFieldsSourceFieldsCount,
} from '../../modules/Builder/selectors';
import { getSelectedSourceId } from '../../modules/Designer/Catalog/selectors';
import DataSortControl from '../../modules/Builder/components/DataSortControl';
import CatalogSourceTitle from '../../modules/Designer/Catalog/components/CatalogSourceTitle';
import { SourcesTitle } from '../../modules/Builder/components/SourcesTitle/SourcesTitle';
import { HeaderMessage } from '../HeaderMessage/HeaderMessage';
import OutputHeader from '../../modules/Streamer/strategies/isolated/screens/OutputPreview/components/OutputHeader';
import { ALL_SORT_CONFIG_PATHS } from '../../modules/Designer/components/SortControl/useConfig';
import { LinksSortControl } from '../../modules/Designer/Ingestion/screens/Links/components/SortControl';

interface SubSection {
  key: string;
  iconName?: string;
  href?: string;
  title?: string;
  name?: string;
  hideConditions?: HideSectionCondition[];
  component?: FunctionComponent;
}

interface Section {
  key: string;
  iconName?: string;
  href?: string;
  name: string;
  title?: string;
  hideConditions?: HideSectionCondition[];
  subSections?: SubSection[];
  disabled?: boolean;
  component?: FunctionComponent;
}

interface ContainerProps {
  sections: Section[];
  children: React.ReactNode;
}

const isActiveSection = (section: Section | SubSection, pathname: string) =>
  !!matchPath(pathname, {
    path: section.href,
    exact: false,
  });

const Container: FunctionComponent<ContainerProps> = ({
  children,
  sections = [],
}) => {
  const { pathname } = useLocation();
  const isSyncing = useSelector(streamSelectors.getIsSyncing);

  const { currentStreamId } = useSelector(streamSelectors.getStreamsState);
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const recentStreams = useSelector(streamSelectors.getRecentStreams);
  const activeObjectType = useSelector(getActiveObjectType);
  const selectedDesignId = useSelector(getSelectedDesignId);
  const selectedBuilderStreamId = useSelector(getSelectedBuilderStreamId);
  const selectedCatalogSourceId = useSelector(getSelectedSourceId);
  const selectedBuilderDataSourceIds = useSelector(getSelectedDataSourceIds);
  const selectedStreamSourceFieldsCount = useSelector(
    getStreamFieldsSourceFieldsCount
  );

  const hasSubSections = sections.some((s) => !!s.subSections);

  const activeSection =
    sections.find((s) => isActiveSection(s, pathname)) || sections[0];

  const subSections = activeSection?.subSections || [];

  const headerItems = hasSubSections ? subSections : sections;

  const filteredHeaderItems = (headerItems as Section[])
    .filter((s) => {
      if (!s.hideConditions) return true;

      const shouldRemove = s.hideConditions.some((condition) =>
        checkShouldRemove({
          condition,
          isSyncing,
          currentDataset,
          currentStreamId,
          activeObjectType,
          recentStreams,
          selectedDesignId,
          selectedBuilderStreamId,
          selectedCatalogSourceId,
          selectedBuilderDataSourceIds,
          selectedStreamSourceFieldsCount,
        })
      );

      return !shouldRemove;
    })
    .map((s) => ({
      ...s,
      onRender: s.component ? () => <s.component /> : undefined,
    }));

  const footerItems = sections
    .filter((s) => {
      if (!s.hideConditions) return true;

      const shouldRemove = s.hideConditions.some((condition) =>
        checkShouldRemove({
          condition,
          isSyncing,
          currentDataset,
          currentStreamId,
          activeObjectType,
          recentStreams,
          selectedDesignId,
          selectedBuilderStreamId,
        })
      );

      return !shouldRemove;
    })
    .map((s) => ({
      key: s.key,
      iconName: s.iconName,
      href: s.href,
      isActive: isActiveSection(s, pathname),
      name: s.name,
    }));

  const activeSubSection =
    subSections.find((s) => s.href === pathname) || subSections[0];

  return (
    <Stack styles={stackStyles}>
      {/* This is temporary solution, because we will move towards route matching,
          and this can be extracted from this component */}
      <div className={sectionTitleWrapperClassname}>
        <Switch>
          <Route path={routes.designer.catalog.links}>
            <LinksSortControl />
          </Route>
          <Route
            path={[routes.builder.assets.data, routes.builder.assets.links]}
          >
            <DataSortControl />
          </Route>
          <Route path={routes.builder.assets.sources}>
            <SourcesTitle />
          </Route>
          <Route path={routes.designer.catalog.data}>
            <CatalogSourceTitle />
          </Route>
          <Route path={[routes.streams.index, routes.groupTable.design]}>
            <StreamerHeader
              title={
                activeSubSection ? activeSubSection.name : activeSection.name
              }
            />
          </Route>
          <Route path={routes.streamer.dataMode.output}>
            <OutputHeader
              title={
                activeSubSection ? activeSubSection.name : activeSection.name
              }
            />
          </Route>
          {/* Works as a Catch all for all "default" sort configs */}
          <Route path={ALL_SORT_CONFIG_PATHS}>
            <SortControl />
          </Route>
          <Route>
            <SectionTitle
              title={
                activeSubSection ? activeSubSection.name : activeSection.name
              }
            />
          </Route>
        </Switch>
        <HeaderMessage />
      </div>

      <ContextualMenu items={filteredHeaderItems} />

      <SearchBar />

      <div
        className={cn(containerClassname, {
          [streamsContainerClassname]: activeSection.key === 'streams',
          [designerContainerClassName]: matchPath(pathname, {
            path: [routes.designer.index, routes.builder.index],
            exact: false,
          }),
        })}
        data-testid="container-box"
        id="container-box"
      >
        {isSyncing && <ProgressIndicator styles={progressIndicatorStyles} />}
        {children}
      </div>
      <Route path={[routes.designer.index, routes.builder.index]}>
        {hasSubSections && <FooterNav items={footerItems} />}
      </Route>
    </Stack>
  );
};

export default Container;
