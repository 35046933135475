import * as effects from 'redux-saga/effects';

import { routes } from '../../../../constants/routes';
import * as actions from './actions';
import * as streamerActions from '../../actions';
import { CallableStrategyParams } from '../../types';
import * as selectors from './selectors';
import { OUTPUT_PANEL_BREAKPOINT } from '../../screens/OutputPanel/constants';
import * as filtersActions from '../../Filters/actions';

export function* handleSelectedAssetsChange({
  payload: { streamElementId },
}: ReturnType<typeof streamerActions.assetClicked>) {
  const swimlanesId = yield effects.select(selectors.getSwimlanesAssetId);
  const columnsId = yield effects.select(selectors.getColumnsAssetId);
  if (streamElementId === swimlanesId) {
    yield effects.put(actions.setSwimlanesAssetId(null));
  }
  if (streamElementId === columnsId) {
    yield effects.put(actions.setColumnsAssetId(null));
  }
}

export function* isolatedStreamingStrategy({
  getNextPage,
  rowCount,
  rows,
  totalRowCount,
  metadata: { history },
}: CallableStrategyParams) {
  yield effects.put(
    actions.dataReceived({
      rowCount,
      rows,
      totalRowCount,
    })
  );

  if (window.innerWidth <= OUTPUT_PANEL_BREAKPOINT) {
    yield effects.call(history.push, routes.streamer.dataMode.output);
  }

  let currentGetNextPage = getNextPage;
  while (true) {
    const { fetchNextPage } = yield effects.race({
      fetchNextPage: effects.take(actions.fetchNextPage),
      selectionChanged: effects.take([
        streamerActions.assetClicked,
        streamerActions.selectStreamId,
        streamerActions.groupAssetsClicked,
        streamerActions.assetAggregationClicked,
        streamerActions.setSelectedAssets,
        filtersActions.addFilter,
        filtersActions.deleteFilter,
        filtersActions.editFilter,
        streamerActions.updateStreamerAsset,
      ]),
    });

    if (!fetchNextPage) {
      break;
    }

    const continuationResponse: CallableStrategyParams = yield effects.call(
      currentGetNextPage
    );

    currentGetNextPage = continuationResponse.getNextPage;

    yield effects.put(
      actions.continuationReceived({
        rowCount: continuationResponse.rowCount,
        rows: continuationResponse.rows,
        totalRowCount: continuationResponse.totalRowCount,
      })
    );

    if (!continuationResponse?.getNextPage) {
      break;
    }
  }
}
