import { IColumnFormatType, IColumnDataType } from '../../types/IColumn';

export const DATA_TYPE_TO_EXCEL_FORMAT: Record<IColumnDataType, string> = {
  DateTime: 'dd/mm/yy h:mm',
  NumericInteger: '#,##0;-#,##0',
  Text: null,
  NumericDecimal: '#,##0.00;-#,##0.00',
  Boolean: 'General',
  Geography: 'General',
  JSON: 'General',
  Unknown: 'General',
};

export const FORMAT_TYPE_TO_EXCEL_FORMAT : Record<IColumnFormatType, string> = {
  LongDate: 'dddd, mmmm dd, yyyy',
  LongDateTime: 'dddd, mmmm dd, yyyy hh:mm AM/PM',
  ShortDateTime: 'm/d/yy hh:mm',
  Number: DATA_TYPE_TO_EXCEL_FORMAT.NumericDecimal,
  Uri: null,
  Response: null,
  State: null,
  Percentage: '0.00%',
  ShortDate: 'm/d/yy',
  LongTime: 'hh:mm:ss AM/PM',
  ShortTime: 'hh:mm',
  Currency: '$ #,##0.00;[Red]-$ #,##0.00',
  Standard: 'General',
  NoMarkup: 'General',
};

export const colorsDrillingRows = [
  '#8ea9db',
  '#aabfe4',
  '#c7d4ed',
  '#e3eaf6',
  '#ffffff',
];

export const colorsDrillingColumns = [
  '#305496',
  '#8ea9db',
  '#aabfe4',
  '#c7d4ed',
  '#e3eaf6',
  '#ffffff',
];

export const COLUMN_HEADER_COLOR_CHANGE_THRESHOLD = 3;

export const topGroupTableColors = {
  fill: { color: '#305496' },
  font: { color: '#FFFFFF' },
};

export type IDrillingState = 'TRUE' | 'FALSE';

export const DRILLING_STATE : Record<IDrillingState, IDrillingState> = {
  TRUE: 'TRUE',
  FALSE: 'FALSE',
};

export const PAGE_SIZE : number = 500;

export const excelEscapedNull = '-';

export const tableHeadersStylesFormat = {
  fill: { color: '#4472c4' },
  font: { color: '#FFFFFF', bold: true },
};
