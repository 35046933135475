import { createAction } from '@reduxjs/toolkit';

// import { CancelTokenSource } from 'axios';
import { CancelToken } from 'axios';
import { IStreamFilter } from '../../types/IStreamFilter';
import {
  StreamsThunkAction,
  AggregationChangeIntentPayload,
  IFetchingProgress,
} from './types';

import { IColumn } from '../../types/IColumn';
import {
  IDataset,
  IDatasetOptions,
  LastSuccessfulMapping,
} from '../../types/IDataset';
import { IInquiryData } from '../../types/IInquiryData';
import { ICustomFilter } from '../../types/ICustomFilter';
import { ICustomAggregation } from '../../types/ICustomAggregation';

import selectors from './selectors';
import { IStream } from '../../types/IStream';
import { columnsToRequestElements } from './utils';
import { getStreamFiltersList } from '../StreamFilters/selectors';
import { createStreamDataRequestTask, InquiriesDataParams } from './api';
import { ProcessStreamIntentArgs } from '../Builder/actions';
import { DataSourceEntity } from '../../api/model/schemas/DataSourceEntity';
import { patchEntity } from '../Designer/api';
import { configureSourceEntity } from '../Designer/Ingestion/api';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { StreamFileFormat } from '../../api/model/schemas/StreamFileFormat';
import { performSendToFile } from '../Streamer/actions';

export const actionCreators = {
  setCurrentStreamColumn:
    (currentStreamColumn: IColumn): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_CURRENT_STREAM_COLUMN', currentStreamColumn });
    },
  // This is for fetching values, only with custom filters.
  fetchSpecificColumnData:
    (
      columns: IColumn[],
      continuation?: { pageSize: number; token: string },
      cancelationToken?: CancelToken
    ): StreamsThunkAction<Promise<IInquiryData>> =>
    async (_, getState) => {
      const state = getState();
      const currentStream = selectors.getCurrentStream(state);
      const { data, result } = columnsToRequestElements(columns, []);
      const params: InquiriesDataParams = {
        data,
        columns: result,
        ...(continuation ? { continuation } : {}),
      };

      const response = (await createStreamDataRequestTask(currentStream.id)(
        params,
        null,
        cancelationToken
      )) as any;

      return response.data;
    },
  // This imports filters from dataset automatically.
  fetchColumnData:
    (
      columns: IColumn[],
      continuation?: { pageSize: number; token: string },
      filterColumns: IColumn[] = [] // Treat this as extra filters?
    ): StreamsThunkAction<Promise<IInquiryData>> =>
    async (_, getState) => {
      const state = getState();
      const currentStream = selectors.getCurrentStream(state);
      const streamFilters = getStreamFiltersList(state);

      const { data, result, filter } = columnsToRequestElements(
        columns,
        streamFilters,
        filterColumns
      );
      const params: InquiriesDataParams = {
        data,
        columns: result,
        ...(continuation ? { continuation } : {}),
        filter,
      };

      const response = (await createStreamDataRequestTask(currentStream.id)(
        params
      )) as any;

      return response.data;
    },
  writeToDbOldStreamer:
    (entity: DataSourceEntity): StreamsThunkAction<any> =>
    async (_, getState) => {
      const state = getState();
      const currentStream = selectors.getCurrentStream(state);
      const currentDataset = selectors.getCurrentDataset(state);
      const streamFilters = getStreamFiltersList(state);

      const { data, result, filter } = columnsToRequestElements(
        currentDataset.columns,
        streamFilters,
        []
      );
      const params: InquiriesDataParams = {
        data,
        columns: result,
        filter,
      };

      await patchEntity<any>(TYPE_IDS.ConfigureDataSourceEntity)({
        id: entity.configurationId,
        data: params,
        streamId: currentStream.id,
      });

      await configureSourceEntity(entity.id);
    },
  sendToFileOldStreamer:
    (format: StreamFileFormat): StreamsThunkAction<any> =>
    async (dispatch, getState) => {
      const state = getState();
      const currentStream = selectors.getCurrentStream(state);
      const currentDataset = selectors.getCurrentDataset(state);
      const streamFilters = getStreamFiltersList(state);

      const { data, result, filter } = columnsToRequestElements(
        currentDataset.columns,
        streamFilters,
        []
      );
      const params: InquiriesDataParams = {
        data,
        columns: result,
        filter,
      };

      dispatch(
        performSendToFile({
          selectedStreamId: currentStream.id,
          data: params,
          format,
        })
      );
    },
  setPermittedDataBooks:
    (permittedDataBooks: string[]): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_PERMITTED_DATABOOKS', permittedDataBooks });
      dispatch({ type: 'SET_CURRENT_STREAM_ID', currentStreamId: null });
    },
  setPermittedStreamCategories:
    (permittedStreamCategories: string[]): StreamsThunkAction =>
    (dispatch) => {
      dispatch({
        type: 'SET_PERMITTED_STREAM_CATEGORIES',
        permittedStreamCategories,
      });
      dispatch({ type: 'SET_CURRENT_STREAM_ID', currentStreamId: null });
    },
  setPermittedStreams:
    (permittedStreams: string[]): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_PERMITTED_STREAMS', permittedStreams });
      dispatch({ type: 'SET_CURRENT_STREAM_ID', currentStreamId: null });
    },
  setStreamColumnLoading:
    (column: IColumn): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_STREAM_COLUMN_LOADING', column });
    },
  setSyncing:
    (syncing: boolean): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_SYNCING', syncing });
    },

  setAutoLoadAll:
    (payload: boolean): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_AUTO_LOAD_ALL', payload });
    },
  setCurrentDatasetId:
    (id: string): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_CURRENT_DATASET_ID', id });
    },
  editDataset:
    (id: string, properties: Partial<IDataset>): StreamsThunkAction<IDataset> =>
    (dispatch, getState) => {
      dispatch({ type: 'EDIT_DATASET', id, properties });
      return getState().streams.datasets.find((dataset) => dataset.id === id);
    },
  addDataset:
    (dataset: IDataset): StreamsThunkAction<IDataset> =>
    (dispatch) => {
      dispatch({ type: 'ADD_DATASET', dataset });
      return dataset;
    },
  removeDataset:
    (datasetId: string): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'REMOVE_DATASET', datasetId });
    },
  setAutoLoading:
    (autoLoading: boolean): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_AUTOLOADING', autoLoading });
    },
  setCustomFilters:
    (customFilters: ICustomFilter[]): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_CUSTOM_FILTERS', customFilters });
    },
  setCustomAggregations:
    (customAggregations: ICustomAggregation[]): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_CUSTOM_AGGREGATIONS', customAggregations });
    },
  setDefaultDatasetOptions:
    (options: IDatasetOptions): StreamsThunkAction =>
    (dispatch) => {
      dispatch({ type: 'SET_DEFAULT_DATASET_OPTIONS', options });
    },
  removeAllColumnsBelogningToEntityFromDataSet:
    (dataEntityName: string, datasetId: string): StreamsThunkAction =>
    (dispatch, getState) => {
      const datasetsToEdit = selectors.getDatasetById(datasetId)(getState());

      const filteredColumns = datasetsToEdit.columns.filter((c) => {
        if (c.parentEntityName === dataEntityName) return false;

        return true;
      });

      // prevent deselecting last column
      if (filteredColumns.length === 0) return;

      dispatch({
        type: 'EDIT_DATASET',
        id: datasetId,
        properties: { columns: filteredColumns },
      });
    },
  setColumnsOfDataset:
    (datasetId: string, columns: IColumn[]): StreamsThunkAction =>
    (dispatch, getState) => {
      const { lastSuccessfulMapping } = selectors.getDatasetById(datasetId)(
        getState()
      );

      const newProperties: Partial<IDataset> = {
        columns,
      };

      if (
        lastSuccessfulMapping?.continuation?.token &&
        JSON.stringify(columns) ===
          JSON.stringify(lastSuccessfulMapping.columns)
      ) {
        newProperties.continuation = lastSuccessfulMapping.continuation;
      }

      dispatch({
        type: 'EDIT_DATASET',
        id: datasetId,
        properties: newProperties,
      });
    },
  setDatasetsStreamFilters:
    (datasetId: string, streamFilters: IStreamFilter[]): StreamsThunkAction =>
    (dispatch, getState) => {
      const currentDataset = selectors.getDatasetById(datasetId)(getState());
      const newColumns = currentDataset.columns.map((datasetColumn) => ({
        ...datasetColumn,
        filters: streamFilters.filter(
          (selectedFilter) => selectedFilter.column?.id === datasetColumn.id
        ),
      }));

      dispatch({
        type: 'EDIT_DATASET',
        id: datasetId,
        properties: {
          streamFilters,
          columns: newColumns,
        },
      });
    },
  // Quick fix for infinite loop issue
  checkCurrentSelectionFinish: createAction('CHECK_CURRENT_SELECTION_FINISH'),
};

export const columnAggregationChangeIntent = createAction<
  AggregationChangeIntentPayload,
  'streamer/COLUMN_AGGREGATION_CHANGE_INTENT'
>('streamer/COLUMN_AGGREGATION_CHANGE_INTENT');
export const itemClicked = createAction<IColumn, 'streamer/ITEM_CLICKED'>(
  'streamer/ITEM_CLICKED'
);
export const groupTableItemClicked = createAction<
  IColumn,
  'streamer/GROUP_TABLE_ITEM_CLICKED'
>('streamer/GROUP_TABLE_ITEM_CLICKED');

export const fetchAllStreams = createAction('streamer/FETCH_STRAMS');

export const setStreamsPending = createAction('SET_STREAMS_PENDING');
export const setStreamsError = createAction<string>('SET_STREAMS_ERROR');
export const setStreamsSuccess = createAction<IStream[]>('SET_STREAMS_SUCCESS');
export const checkWorkbookSelection = createAction('CHECK_WORKBOOK_SELECTION');
export const setCheckingCurrentSelection = createAction<
  boolean,
  'SET_CHECKING_CURRENT_SELECTION'
>('SET_CHECKING_CURRENT_SELECTION');
export const setCurrentStreamIdIntent = createAction<
  string,
  'streamer/SET_CURRENT_STREAM_ID_INTENT'
>('streamer/SET_CURRENT_STREAM_ID_INTENT');

// PLAY / RETRIVE

export const playStreamIntent = createAction('streamer/PLAY_INTENT');
export const pauseStreamPlayingIntent = createAction(
  'streamer/PAUSE_STREAM_PLAYING_INTENT'
);
export const rewindStreamIntent = createAction('streamer/REWIND_STREAM_INTENT');
export const setLastSuccessfulMapping = createAction<
  LastSuccessfulMapping,
  'streamer/SET_LAST_SUCCESSFUL_MAPPING'
>('streamer/SET_LAST_SUCCESSFUL_MAPPING');

export const setAutoLoadAll = createAction<boolean, 'SET_AUTO_LOAD_ALL'>(
  'SET_AUTO_LOAD_ALL'
);
export const setRewinding = createAction<boolean, 'streamer/SET_REWINDING'>(
  'streamer/SET_REWINDING'
);

export const setDatasets = createAction<IDataset[], 'streamer/SET_DATASETS'>(
  'streamer/SET_DATASETS'
);
export const cancelCurrentOperation = createAction(
  'streamer/CANCEL_CURRENT_OPERATION'
);

export const setFetchingProgress = createAction<
  IFetchingProgress,
  'streamer/SET_FETCHING_PROGRESS'
>('streamer/SET_FETCHING_PROGRESS');
export const incrementTotalPages = createAction<
  number,
  'streamer/INCREMENT_TOTAL_PAGES'
>('streamer/INCREMENT_TOTAL_PAGES');
export const incrementPagesFetched = createAction<
  number,
  'streamer/INCREMENT_PAGES_FETCHED'
>('streamer/INCREMENT_PAGES_FETCHED');
export const toggleFiltersView = createAction('streamer/TOGGLE_FILTERS_VIEW');
export const setSyncing = createAction<boolean, 'streamer/SET_SYNCING'>(
  'streamer/SET_SYNCING'
);
export const setIsFetchingAvailableColumns = createAction<
  boolean,
  'streamer/SET_IS_FETCHING_AVAILABLE_COLUMNS'
>('streamer/SET_IS_FETCHING_AVAILABLE_COLUMNS');

export const streamerProcessingIntent = createAction<
  ProcessStreamIntentArgs,
  'streamer/PROCESSING_INTENT'
>('streamer/PROCESSING_INTENT');
