import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import { renderDatasetAfterSync } from '../../actions/excel-actions';

import { DATA_SETS_STORAGE_KEY, SYNC_IN_PROGRESS_KEY } from './constants';
import { setIsOtherUserSyncing } from '../../modules/App/actions';
import { getSessionId } from '../../modules/App/sessionId';
import { getIsOtherUserSyncing } from '../../modules/App/selectors';
import { DataPersistedInWorkbook } from './types';
import { syncGroupTable } from '../../modules/GroupTable/actions';

import { IDataset } from '../../types/IDataset';
import { ObjectTypes } from '../../modules/App/types';
import { checkWorkbookSelection, setDatasets } from '../../modules/Streams/actions';

function synchronizeDatasets() {
  Office.context.document.settings.refreshAsync(() => {
    const receivedPersistedData = Office.context.document.settings.get(
      DATA_SETS_STORAGE_KEY,
    ) || {} as DataPersistedInWorkbook;
    const sessionThatBlocksUi = Office.context.document.settings.get(
      SYNC_IN_PROGRESS_KEY,
    );

    const currentSessionId = getSessionId();
    const isSelfTrigger = receivedPersistedData.lastSavedBySessionId === currentSessionId
      || sessionThatBlocksUi === currentSessionId;

    if (isSelfTrigger) return;

    // fix error on initial render
    if (!window.sharedState.store) return;

    // we only need to repaint datasets that are FREE form, to get the updated info
    const currentDatasets = window.sharedState.store.getState().streams?.datasets;

    window.sharedState.store.dispatch(setDatasets(receivedPersistedData.datasets || []));

    if (receivedPersistedData.groupTableState) {
      window.sharedState.store.dispatch(syncGroupTable(receivedPersistedData.groupTableState));
    }
    // leaving this one for better debugging
    console.log('[DATASET SYNC] - setting datasets to', receivedPersistedData);

    // update taskpane if we have new data from other tab
    window.sharedState.store.dispatch(checkWorkbookSelection());

    // we need to repaint datasets that uses free type (function) approach
    const differenceBetweenReceivedAndCurrent = differenceWith(
      receivedPersistedData.datasets,
      currentDatasets ?? [],
      isEqual,
    ) as IDataset[];

    const diffWhichNeedsRepaint = differenceBetweenReceivedAndCurrent.filter(
      (dataset) => dataset.type === ObjectTypes.FREE_FORM,
    );

    diffWhichNeedsRepaint.forEach((dataset) => {
      renderDatasetAfterSync(dataset);
    });
  });
}

let timeoutId: ReturnType<typeof setTimeout> | undefined;

function handleBlockedUI() {
  const currentSessionId = getSessionId();
  Office.context.document.settings.refreshAsync(() => {
    const sessionThatBlocksUi = Office.context.document.settings.get(
      SYNC_IN_PROGRESS_KEY,
    );

    const isOtherUserSyncing = !!(
      sessionThatBlocksUi && sessionThatBlocksUi !== currentSessionId
    );

    // fix error on initial render
    if (!window.sharedState.store) return;

    const currentSessionIsSyncingResult = getIsOtherUserSyncing(window.sharedState.store.getState());

    if (currentSessionIsSyncingResult !== isOtherUserSyncing) {
      // to minimize rerenders, dispatch only when it differs
      window.sharedState.store.dispatch(
        setIsOtherUserSyncing(isOtherUserSyncing),
      );
    }

    if (isOtherUserSyncing) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // In case that co-authoring user that
        // blocked the UI looses connection/exits excel
        // we are forcefully removing the block
        const isOtherUserStillSyncing = getIsOtherUserSyncing(
          window.sharedState.store.getState(),
        );
        if (isOtherUserStillSyncing) {
          window.sharedState.store.dispatch(setIsOtherUserSyncing(false));
        }
        // 25 seconds
      }, 1000 * 25);
    }
  });
}

function syncDataSets() {
  Office.context.document.settings.addHandlerAsync(
    Office.EventType.SettingsChanged,
    () => {
      handleBlockedUI();
      synchronizeDatasets();
    },
  );
}

export default syncDataSets;
