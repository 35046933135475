import React from 'react';

export const snapshotWrapper: React.CSSProperties = {
  padding: '4px',
  border: '1px solid #eee',
  borderRadius: '4px',
  marginBottom: '8px',
};

export const snapshotTitle: React.CSSProperties = {
  fontWeight: 600,
  lineBreak: 'anywhere',
};
export const snapshotId: React.CSSProperties = {
  lineBreak: 'anywhere',
};
