import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { routes } from '../../../../constants/routes';
import { DefinedSortTypes } from '../../../App/types';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { IconType } from '../../types';
import useSortControl from '../../../../hooks/useSortControl';

export type SortControlOption = {
  key: string;
  name: string;
  icon: string;
  iconType: IconType;
};

type NonEmptyArray<T> = [T, ...T[]];

interface SingleSortConfig {
  sectionTitle: any;
  defaultSort: DefinedSortTypes;
  typeIdForFetchingDynamicSortConfig: string | null;
  showSelectionOptions: boolean;
  staticSortOptions: NonEmptyArray<SortControlOption>;
}
interface SortConfigs {
  [x: string]: SingleSortConfig;
}

export const getSortConfigs = (t: any, currentSort: string): SortConfigs => ({
  [routes.streamer.dataMode.selectAssets]: {
    sectionTitle: t('modeBar:ingestion.sources'),
    defaultSort: DefinedSortTypes.StreamerEntity,
    typeIdForFetchingDynamicSortConfig: null,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.StreamerEntity,
        name: t('sortControls:entity'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
    ],
    showSelectionOptions: true,
  },
  [routes.designer.ingestion.sources]: {
    sectionTitle: t('modeBar:ingestion.sources'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.DataSource,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
    showSelectionOptions: true,
  },
  [routes.designer.ingestion.transforms]: {
    sectionTitle: t('modeBar:ingestion.transforms'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: null,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
    showSelectionOptions: true,
  },
  [routes.designer.metadata.tags]: {
    sectionTitle: t('modeBar:metadata.tags'),
    defaultSort: DefinedSortTypes.Letter,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Tag,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Letter,
        name: t('sortControls:letter'),
        icon: 'sort-ascending',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name,
        name: t('sortControls:name'),
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.ingestion.data]: {
    sectionTitle: t('modeBar:ingestion.data'),
    defaultSort: DefinedSortTypes.Entity,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Entity,
        name: t('sortControls:entity'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.metadata.catalogDefinition]: {
    sectionTitle: t('modeBar:metadata.configuration'),
    defaultSort: DefinedSortTypes.Catalog,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Catalog,
        name: t('sortControls:catalog'),
        icon: 'CatalogDefinition',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Data,
        name: t('sortControls:data'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.catalog.sources]: {
    sectionTitle: t('modeBar:catalog.catalog'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.DataSource,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.catalog.data]: {
    sectionTitle: t('modeBar:catalog.data'),
    defaultSort: DefinedSortTypes.Entity,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.SourceEntityField,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Entity,
        name: t('sortControls:entity'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name,
        name: t('sortControls:name'),
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.catalog.calculations]: {
    sectionTitle: t('modeBar:catalog.calculations'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Calculation,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.catalog.hubs]: {
    sectionTitle: t('modeBar:catalog.hubs'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Hub,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.ingestion.filters.logic.index]: {
    sectionTitle: t('modeBar:ingestion.filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.designer.catalog.groups]: {
    sectionTitle: t('modeBar:catalog.groups'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Group,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.streams.index]: {
    sectionTitle: t('modeBar:builder.streams'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Stream,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Design as string,
        name: t('sortControls:design') as string,
        icon: 'Brush',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Status as string,
        name: t('sortControls:status') as string,
        icon: 'Completed',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.builder.assets.sources]: {
    sectionTitle: t('modeBar:builder.sources'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.DataSource,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Type as string,
        name: t('sortControls:type') as string,
        icon: 'shape-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.data]: {
    sectionTitle: t('modeBar:builder.data'),
    defaultSort: DefinedSortTypes.Entity,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.SourceEntityField,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Entity,
        name: t('sortControls:entity'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name,
        name: t('sortControls:name'),
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.links]: {
    sectionTitle: t('modeBar:builder.data'),
    defaultSort: DefinedSortTypes.Entity,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Entity,
        name: t('sortControls:entity'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.EmptyLinks,
        name: t('sortControls:emptyLinks'),
        icon: 'FieldEmpty',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.designer.catalog.links]: {
    sectionTitle: t('modeBar:ingestion.links'),
    defaultSort: DefinedSortTypes.Entity,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Entity,
        name: t('sortControls:entity'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.EmptyLinks,
        name: t('sortControls:emptyLinks'),
        icon: 'FieldEmpty',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.builder.assets.calculations]: {
    sectionTitle: t('modeBar:builder.calculations'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Calculation,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.hubs]: {
    sectionTitle: t('modeBar:builder.hubs'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Hub,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.groups]: {
    sectionTitle: t('modeBar:builder.groups'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Group,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.preview]: {
    sectionTitle: t('modeBar:builder.preview'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name,
        name: t('sortControls:name'),
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.StreamAssetType as string,
        name: t('sortControls:type') as string,
        icon: 'shape-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.calculations]: {
    sectionTitle: t('modeBar:builder.calculations'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Calculation,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.hubs]: {
    sectionTitle: t('modeBar:builder.hubs'),
    defaultSort: DefinedSortTypes.Tags,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Hub,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.streams.list.index]: {
    sectionTitle: t('modeBar:streams.data'),
    defaultSort: DefinedSortTypes.Entity,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name,
        name: t('sortControls:name'),
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Tags,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Entity,
        name: t('sortControls:entity'),
        icon: 'table',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.favorites.sharedWithMe.index]: {
    sectionTitle: t('streamsSetup:favorites'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Stream,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.favorites.recentStreams.index]: {
    sectionTitle: t('streamsSetup:recents'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Stream,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.share.design]: {
    sectionTitle: '',
    defaultSort: DefinedSortTypes.Role,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name,
        name: t('sortControls:name'),
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Role,
        name: t('sortControls:role'),
        icon: 'account-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.share.stream]: {
    sectionTitle: '',
    defaultSort: DefinedSortTypes.Role,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name,
        name: t('sortControls:name'),
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Role,
        name: t('sortControls:role'),
        icon: 'account-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.streams.design.index]: {
    sectionTitle: t('streams:subSections.Layout'),
    defaultSort: DefinedSortTypes.Sequence,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Sequence,
        name: t('sortControls:sequence'),
        icon: 'Move',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Precedence,
        name: t('sortControls:precedence'),
        icon: 'SwitcherStartEnd',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.streamer.dataMode.layout]: {
    sectionTitle: t('streams:subSections.Layout'),
    defaultSort: DefinedSortTypes.Sequence,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Sequence,
        name: t('sortControls:sequence'),
        icon: 'Move',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Precedence,
        name: t('sortControls:precedence'),
        icon: 'SwitcherStartEnd',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.streams.filters.index]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.streams.filters.create]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.builder.assets.filters.index]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Value,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: currentSort === DefinedSortTypes.Value,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.builder.assets.filters.add]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.builder.assets.filters.edit]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.streamer.dataMode.filters.index]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: currentSort === DefinedSortTypes.Value,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.streamer.dataMode.filters.add]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.streamer.dataMode.filters.edit]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.streams.values.index]: {
    sectionTitle: t('streams:subSections.Filters'),
    defaultSort: DefinedSortTypes.Logic,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: true,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Logic,
        name: t('sortControls:logic'),
        icon: 'FlowChart',
        iconType: IconType.Fabric,
      },
      {
        key: DefinedSortTypes.Value,
        name: t('sortControls:value'),
        icon: 'BulletedListText',
        iconType: IconType.Fabric,
      },
    ],
  },
  [routes.designer.contentLibrary.designs]: {
    sectionTitle: t('modeBar:contentLibrary.designs'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: null,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.streamer.streamSelecting.pickStream]: {
    sectionTitle: t('streamsSetup:favorites'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.Stream,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.designer.catalog.machineLearning]: {
    sectionTitle: t('modeBar:catalog.machineLearning'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.MachineLearning,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
  [routes.builder.assets.machineLearning]: {
    sectionTitle: t('modeBar:catalog.machineLearning'),
    defaultSort: DefinedSortTypes.Name,
    typeIdForFetchingDynamicSortConfig: TYPE_IDS.MachineLearning,
    showSelectionOptions: false,
    staticSortOptions: [
      {
        key: DefinedSortTypes.Tags as string,
        name: t('sortControls:tags') as string,
        icon: 'tag-outline',
        iconType: IconType.MaterialDesign,
      },
      {
        key: DefinedSortTypes.Name as string,
        name: t('sortControls:name') as string,
        icon: 'square-edit-outline',
        iconType: IconType.MaterialDesign,
      },
    ],
  },
});

export default (): SingleSortConfig => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { currentSort } = useSortControl();
  const SORT_CONFIGS = getSortConfigs(t, currentSort);

  const config = SORT_CONFIGS[pathname];

  if (!config) {
    if (pathname !== routes.streams.index) {
      // /streams main path is a wrapper for "user flow" - so it sends out not related warnings
      console.log(
        '%cConfig error! See below:',
        'color:red;font-size: 40px;font-weight:600'
      );
      console.warn('affected path', pathname);
      console.warn(
        'You are trying to use sort control, but the config is not defined. \n Please add entry at src/taskpane/modules/Designer/components/SortControl/useConfig'
      );
    }
    return {
      defaultSort: null,
      sectionTitle: null,
      staticSortOptions: [] as any, // just to avoid error
      typeIdForFetchingDynamicSortConfig: null,
      showSelectionOptions: false,
    };
  }

  return config;
};

export const ALL_SORT_CONFIG_PATHS = Array.from(
  new Set(Object.keys(getSortConfigs(() => null, null)))
);
