import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useSortControl from '../../../../hooks/useSortControl';
import { DefinedSortTypes } from '../../../App/types';
import { LogicFilters } from './Logic';
import { ByValueFilters } from './ByValues';
import { getSelectedBuilderStreamId, getStreamFields, getStreamFilters } from '../../selectors';
import useSelectedSources from '../../hooks/useSelectedSources';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { fetchAllStreamFieldsFilters, fetchStreamFields } from '../../actions';

const AssetsFilters = () => {
  const { currentSort } = useSortControl();

  // HOOKS - REDUX
  const dispatch = useDispatch();
  const selectedStreamId = useSelector(getSelectedBuilderStreamId);
  const { records: filters, isLoading } = useSelector(getStreamFilters);

  const {
    records: streamFields,
    entityFields,
    entities,
    isLoading: isLoadingFields,
  } = useSelector(getStreamFields);

  const {
    selectedSourceIds: selectedSourceIdsFromStream,
  } = useSelectedSources(selectedStreamId);

  // DERIVED STATE
  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:catalog:filters:cta:noAssetsAvailable': selectedSourceIdsFromStream.length === 0,
    }),
    [selectedSourceIdsFromStream.length],
  );

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: filters.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  // HOOKS
  React.useEffect(() => {
    if (selectedStreamId) {
      dispatch(fetchAllStreamFieldsFilters(selectedStreamId));
    }
  }, [selectedStreamId]);

  React.useEffect(() => {
    dispatch(fetchStreamFields({ streamId: selectedStreamId, deepFetch: true }));
  }, [selectedStreamId]);

  // RENDER
  if (isLoading || isLoadingFields) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (currentSort === DefinedSortTypes.Logic) {
    return (
      <LogicFilters
        {...{
          entities, entityFields, streamFields, filters,
        }}
      />
    );
  }

  if (currentSort === DefinedSortTypes.Value) {
    return (
      <ByValueFilters
        {...{
          entities, entityFields, streamFields, filters,
        }}
      />
    );
  }
  return null;
};

export default AssetsFilters;
