import qs from 'query-string';
import { OAUTH_DIALOG_FILE, BASE_DIALOG_OPTIONS, TRANSLATION_PREFIX } from "./constants";
import i18n from "../../../../../../config/i18n";
import { MessageFromDialogAction, oauthStart, OAUTH_END_ACTION_TYPE } from "../../../../../../../oauth/actions";
import { openDialog } from "../../../../../../../shared/utils/dialog";
import { DialogErrorHandlerFactory, DialogMessageHandlerFactory } from "../../../../../../../shared/utils/dialog/utils/events";
import { OFFICE_DIALOG_ERROR_CODES } from "../../../../../../../shared/utils/dialog/constants";
import { DIALOG_INITIALIZE_ACTION_TYPE } from "../../../../../../../shared/utils/dialog/actions";
import { fetchEntities } from "../../../../api";
import { OAuthConnetorConfiguration } from "./models";
import { TypeIds } from "../../../../../../api/model/schemas";
import { createOAuthAccessToken, createOAuthConnectionUrl } from "../../../../../../api/client/system";
import { ApiV4ResponseWrapper } from '../../../../types';

interface FetchOAuthConfigurationArgs {
  oauthConfigurationTypeId: string;
}
export const fetchOAuthConfiguration = async ({
  oauthConfigurationTypeId,
}: FetchOAuthConfigurationArgs): Promise<OAuthConnetorConfiguration> => {
  const { data: responseData } = await fetchEntities<ApiV4ResponseWrapper<OAuthConnetorConfiguration[]>>(TypeIds.ConnectorConfigurationType)({
    filter: {
      oAuthProperties: { ne: null },
    },
  });

  const { data: oauthConfigurations } = responseData;
  const configuration = oauthConfigurations.find(connector => connector.id === oauthConfigurationTypeId);

  if (!configuration) throw new Error(i18n.t(`${TRANSLATION_PREFIX}:errors.configurationMissing`));

  return configuration;
};

interface ConnectOAuthArgs {
  oauthConfigurationTypeId: string;
  selectedScopeIds: string[];
}
interface ConnectOAuthResult {
  accessToken: string;
  refreshToken: string;
}
export const connectOAuth = async ({
  oauthConfigurationTypeId,
  selectedScopeIds,
}: ConnectOAuthArgs): Promise<ConnectOAuthResult | null> => {
  const { data: responseData } = await fetchEntities<ApiV4ResponseWrapper<OAuthConnetorConfiguration[]>>(TypeIds.ConnectorConfigurationType)({
    filter: {
      oAuthProperties: { ne: null },
    },
  });

  const { data: oauthConfigurations } = responseData;
  const oauthConfiguration = oauthConfigurations.find(connector => connector.id === oauthConfigurationTypeId);

  const { data: oauthURL } = await createOAuthConnectionUrl({
    connectorId: oauthConfiguration.id,
    selectedScopeIds,
  });

  if (!oauthURL) {
    throw new Error(i18n.t(`${TRANSLATION_PREFIX}:errors.configurationMissing`));
  }

  const authorizationCode = await openOAuthDialog({
    authorizationURL: qs.stringifyUrl({
      url: oauthURL,
      query: {
        state: getOAuthDialogURL(),
      },
    }),
  });

  const { data: { accessToken, refreshToken } } = await createOAuthAccessToken({
    authorizationCode,
    connectorId: oauthConfigurationTypeId,
  });

  return { accessToken, refreshToken };
};

export const getOAuthDialogURL = () => {
  const pathname = window.location.pathname;
  const path = pathname.substring(0, pathname.lastIndexOf('/') + 1);

  return `${window.location.origin}${path}${OAUTH_DIALOG_FILE}`;
};

const dialogErrorHandlerFactory: DialogErrorHandlerFactory = ({
  reject,
}) =>
  ({ error }) => {
    switch (error) {
      case OFFICE_DIALOG_ERROR_CODES.DialogClosed:
        reject(i18n.t('oauth:error:dialogClosed'));
        break;
      case OFFICE_DIALOG_ERROR_CODES.DialogExists:
        reject(i18n.t('oauth:error:dialogAlreadyOpen'));
        break;
      default:
        reject(i18n.t('oauth:error:unknown'));
        break;
    }
  }

interface OpenOAuthDialogArgs {
  authorizationURL: string;
}

const dialogMessageHandlerFactory = ({
  authorizationURL,
}: OpenOAuthDialogArgs): DialogMessageHandlerFactory<MessageFromDialogAction> => ({
  dispatchToDialog,
  reject,
  resolve,
}) =>
  ({ action }) => {
    switch (action.type) {
      case DIALOG_INITIALIZE_ACTION_TYPE.SUCCESS:
        dispatchToDialog(oauthStart(authorizationURL));
        break;

      case OAUTH_END_ACTION_TYPE:
        resolve(action.payload);
        break;

      case DIALOG_INITIALIZE_ACTION_TYPE.FAILURE:
        console.error(action.payload);
        reject(action.payload);
        break;
    }
  };

export const openOAuthDialog = async ({
  authorizationURL,
}: OpenOAuthDialogArgs): Promise<string> => openDialog({
  options: BASE_DIALOG_OPTIONS,
  errorHandlerFactory: dialogErrorHandlerFactory,
  messageHandlerFactory: dialogMessageHandlerFactory({ authorizationURL }),
  url: getOAuthDialogURL(),
});
