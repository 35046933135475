import { IButtonStyles, ITheme } from '@fluentui/react';
import { baseColors } from '../../../../theme';

export const btnStyles = (_theme: ITheme, isActive = false, isVisible = true): IButtonStyles => ({
  root: {
    width: 32,
    height: 32,
    padding: 4,
    backgroundColor: isActive ? baseColors.white : 'transparent',
    display: isVisible ? 'inline' : 'none',
  },
  rootDisabled: {
    backgroundColor: isActive ? baseColors.white : 'auto',
  },
  icon: {
    color: baseColors.iconColor,
    width: 16,
    height: 16,
  },
  iconDisabled: {
    color: isActive ? baseColors.iconColor : 'initial',
  },
});
