import React, { useEffect, useMemo, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useActions from '../../../../utils/useActions';
import { actionCreators } from '../../actions';
import GroupValue from '../../../../components/ui/GroupValue';
import { IColumn, IColumnDataType } from '../../../../types/IColumn';
import { ComparisonTypes, FilterTypes, IStreamFilter } from '../../../../types/IStreamFilter';
import { getSearchState } from '../../../Search/selectors';
import { getSearchMatch } from '../../../Search/components/SearchBar/utils';
import SearchMatch from '../../../../components/SearchMatch';
import { IInquiryDataContinuation } from '../../../../types/IInquiryData';

const compileValues = (inquiryData) => {
  if (inquiryData) {
    const { rows } = inquiryData;

    return rows.map((row) => row[0]);
  }

  return [];
};

const WAIT_INTERVAL = 500;
const SCROLLABLE_TARGET = 'values-scroll';

interface Props {
  column: IColumn;
  onSelect(column: IColumn, value: string): void;
  filterForColumn?: IStreamFilter,
}

const ColumnValueList: React.FC<Props> = ({ column, onSelect, filterForColumn }) => {
  const { t } = useTranslation('filters');
  const [columnValues, setColumnValues] = useState<unknown[]>([]);
  const [continuation, setContinuation] = useState<IInquiryDataContinuation>();
  const [totalCount, setTotalCount] = useState(10000);
  const [searchedValue, setSearchedValue] = useState('');
  const {
    fetchSpecificColumnData,
  } = useActions(actionCreators);
  const {
    searchQuery,
  } = useSelector(getSearchState);

  const filters = useMemo(() => {
    if (searchQuery?.length && column.dataType === IColumnDataType.Text) {
      return [{
        type: FilterTypes.Comparison,
        comparison: ComparisonTypes.Contains,
        compareValue: searchQuery,
      }];
    }

    return [];
  }, [searchQuery]);

  const fetchColumnValues = async (
    fetchMore = false,
    cancelationToken: CancelTokenSource | null = null,
  ) => {
    if (column) {
      if (!fetchMore) {
        setColumnValues([]);
      }

      const data = await fetchSpecificColumnData(
        [{
          ...column,
          aggregation: undefined,
          filters,
        }],
        (fetchMore ? continuation : undefined),
        cancelationToken?.token,
      );

      const compiledValues = compileValues(data);

      const newValues = [
        ...(fetchMore ? columnValues : []),
        ...compiledValues,
      ];

      setColumnValues([
        ...newValues,
      ]);

      setTotalCount(data.totalRowCount);

      if (data.continuation) {
        setContinuation(data.continuation);
      }
      setSearchedValue(searchQuery);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    let timer: any;

    if (searchQuery === '') {
      fetchColumnValues(false, source);
    } else {
      timer = setTimeout(() => fetchColumnValues(false, source), WAIT_INTERVAL);
    }

    return () => {
      clearTimeout(timer);
      source.cancel();
    };
  }, [searchQuery]);

  const getColumnValue = (columnValue: string) => (
    columnValue === '' ? t('emptyValue') : columnValue
  );

  return (
    <InfiniteScroll
      dataLength={columnValues.length}
      next={() => fetchColumnValues(true)}
      hasMore={columnValues.length < totalCount}
      loader={<Spinner />}
      scrollableTarget={SCROLLABLE_TARGET}
    >
      { columnValues.map((columnValue) => {
        const stringifiedValue = String(columnValue);
        const matchedName = columnValue
          ? getSearchMatch(stringifiedValue, searchedValue)
          : null;

        return (
          <GroupValue
            key={stringifiedValue}
            onClick={() => onSelect(column, stringifiedValue)}
            isSelected={Boolean(filterForColumn?.values?.includes(stringifiedValue))}
          >
            {!matchedName ? getColumnValue(stringifiedValue) : <SearchMatch match={matchedName} />}
          </GroupValue>
        );
      })}

    </InfiniteScroll>
  );
};

export default ColumnValueList;
