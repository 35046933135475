import * as effects from 'redux-saga/effects';
import {
  entityFormSubmission,
  setEntityFormSyncing,
  setFormEntityId,
  setFormParentEntityId,
} from './actions';
import * as api from '../api';
import {
  getAllEntitiesByTypeId,
  getAllForms,
  getAllFormsSectionsSorted,
  getEntityFormByTypeId,
} from './selectors';
import {
  IEntityForm,
  IEntityFormChildren,
  IEntityFormSection,
  IGenericEntity,
} from './types';
import {
  filterOutReadOnlyProperties,
  separateChildrenEntities,
  separateDataPropertiesAndFile,
} from './utils';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { createCustomProperty } from '../Catalog/api';
import { getSelectedDesignId } from '../ContentLibrary/selectors';
import { typedEntitiesFetchWatcher } from './components/SelectOneOfType/sagas';
import { GroupType } from '../../../api/model/schemas/GroupType';

export function* updateFormsAfterEntityCreation(
  res: IGenericEntity,
  forms: IEntityForm[]
) {
  for (let i = 0; i < forms.length; i += 1) {
    if (forms[i].typeId === res.$typeId) {
      yield effects.put(
        setFormEntityId({
          typeId: res.$typeId,
          entityId: res.id,
        })
      );
    }

    if (forms[i].parentTypeId === res.$typeId) {
      yield effects.put(
        setFormParentEntityId({
          typeId: forms[i].typeId,
          parentEntityId: res.id,
        })
      );
    }
  }
}

function* processGroupChildren({ children, entities, parentEntityId }) {
  const allChildEntities = entities.flat();
  const operations = [];

  // handle `GroupValue` children
  const groupValueChildren = children[TYPE_IDS.GroupValue];
  const groupValueEntities = allChildEntities.filter(
    (entity) =>
      entity.$typeId === TYPE_IDS.GroupValue &&
      // Only include group-values that are direct children of the current group
      entity.parentId === parentEntityId
  );

  groupValueChildren?.forEach((child) => {
    const relatedGroupValueEntities = groupValueEntities.find(
      (groupValue) => groupValue.id === child.id
    );
    if (!relatedGroupValueEntities) {
      // no group value with the same id has previously existed so we need to create (POST) one
      operations.push([
        api.createNestedEntity({
          parentEntityId,
          typeId: TYPE_IDS.GroupValue,
        }),
        child.data,
      ]);
    } else {
      // the group value exists so we need to update it with the new changes
      operations.push([
        api.patchEntity(TYPE_IDS.GroupValue),
        {
          id: child.id,
          ...child.data,
        },
      ]);
    }
  });

  groupValueEntities?.forEach((groupValue) => {
    // if an existing group value is no longer present in the child list, we need to DELETE it
    if (!groupValueChildren.find((child) => child.id === groupValue.id)) {
      operations.push([api.deleteEntity(groupValue.$typeId), groupValue.id]);
    }
  });

  yield effects.all(
    operations.map(([apiCallback, data]) => effects.call(apiCallback, data))
  );
}

interface ProcessChildEntity {
  child: IEntityFormChildren;
  parentEntityId: string;
  typeId: string;
}

function* processChildEntity({
  child,
  parentEntityId,
  typeId,
}: ProcessChildEntity) {
  const { data, file } = separateDataPropertiesAndFile(child.data);
  const res = yield effects.call(
    api.createNestedEntity({
      parentEntityId,
      typeId,
    }),
    data
  );

  if (file && res) {
    yield effects.call(
      api.attachEntityFile({
        entityId: res.data.id,
        typeId,
      }),
      { file }
    );
  }

  return res;
}

export function* handleEntityFormSubmission({
  payload: { callback },
}: ReturnType<typeof entityFormSubmission>) {
  const forms: IEntityForm[] = yield effects.select(getAllForms);

  const pages: IEntityFormSection[] = yield effects.select(
    getAllFormsSectionsSorted
  );

  // In case of creating/editing data source we neeed to run configure JOB as a last step
  // so we need to obtain newly created dataSource Id and pass it to another saga
  // The same saga is used for create/edit in Wizard Forms, that's why in that case
  // we also are trying to reuse logic
  // to summarize the flow
  // CREATE/EDIT DataSource -> obtain ID -> pass id to the configure Job saga (in the ingestion sagas)

  // We need to run a configure JOB for transformations as well, so we reuse the existing
  // pattern to dispatch the action after the entity is created succesfully
  let mainEntityId = null;

  // moved this past Links early return to avoid blocking UI error
  yield effects.put(setEntityFormSyncing(true));

  try {
    let res: IGenericEntity;
    for (let i = 0; i < forms.length; i += 1) {
      if (forms[i].embeddedTo) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const {
        typeId: schemaId,
        entityId,
        parentTypeId: parentSchemaId,
        ...form
      }: // We need to get a fresh updated version of the form, because as we'll see further
      // they can be updated in previous iterations (with a parentEntityId or entityId).
      IEntityForm = yield effects.select(
        getEntityFormByTypeId(forms[i].typeId)
      );

      let { parentEntityId } = form;

      const { data, children } = separateChildrenEntities(
        filterOutReadOnlyProperties(pages, form.data)
      );

      // When we are creating a new Stream, we select the Design ID in the wizard form
      // so we need to get it from the form data to properly create the Stream as child of said Design

      if (schemaId === TYPE_IDS.Stream) {
        parentEntityId = data.design as string;
        delete data.design;
      }

      if (forms[0].typeId === TYPE_IDS.DataSource && i === 1) {
        mainEntityId = parentEntityId;
      }

      if (
        [
          TYPE_IDS.ColumnsToRowsTransformation,
          TYPE_IDS.RowsToColumnsTransformation,
          TYPE_IDS.CloneEntityTransformation,
        ].includes(schemaId as any)
      ) {
        mainEntityId = parentEntityId;
      }
      if (entityId) {
        res = yield effects.call(api.patchEntity(schemaId), {
          id: entityId,
          ...data,
        });

        const childrenTypes = Object.keys(children || {});

        const entities: IGenericEntity[] = yield effects.all(
          childrenTypes.flatMap((type) =>
            effects.select(getAllEntitiesByTypeId(type))
          )
        );

        // FIXME: this causes issues in the group wizard and breaks the functionality entirely
        // For the sake of simplicity, we delete the existing child entities of the given
        // type, then we create the ones submitted trough the form.

        // NOTE [KM]: since this behaviour breaks groups I am handling that specific scenario differently, as I'm not
        // sure at this point in time how much of the app is affected by this issue and whether PATCHing the children
        // everywhere would break other wizard scenarios
        if (schemaId === TYPE_IDS.Group) {
          yield effects.call(processGroupChildren, {
            children,
            entities,
            parentEntityId: entityId,
          });
        } else {
          yield effects.all(
            entities
              .flat()
              .map((entity) =>
                effects.call(api.deleteEntity(entity.$typeId), entity.id)
              )
          );

          yield effects.all(
            childrenTypes.flatMap((key) =>
              children[key].map((child) =>
                effects.call(processChildEntity, {
                  parentEntityId: entityId,
                  typeId: key,
                  child,
                })
              )
            )
          );
        }
      } else {
        if (parentEntityId && parentSchemaId) {
          if (schemaId === TYPE_IDS.SchemaProperty) {
            // when creating custom properties in the metadata wizard we need to use a different endpoint
            // so the custom property shows up only under a specific Design
            // but only for the "creation" process (no `entityId` present), editing goes through the normal flow
            const designId = yield effects.select(getSelectedDesignId);
            res = yield effects.call(
              createCustomProperty({ designId, typeId: parentEntityId }),
              data
            );
          } else {
            res = yield effects.call(
              api.createNestedEntity({
                parentEntityId,
                typeId: schemaId,
              }),
              data
            );

            // Handle SmartGroups configuration callback
            if (
              schemaId === TYPE_IDS.Group &&
              res &&
              res.data.type === GroupType.Smart
            ) {
              mainEntityId = res.data.id;
            }

            // Handle Machine Learning configuration callback
            if (schemaId === TYPE_IDS.MachineLearning && res) {
              mainEntityId = res.data.id;
            }
          }
        } else if (!parentSchemaId) {
          res = yield effects.call(api.createEntity(schemaId), data);
        } else {
          return;
        }

        if (res) {
          yield effects.call(updateFormsAfterEntityCreation, res.data, forms);
          const childrenTypes = Object.keys(children || {});
          if (childrenTypes.length) {
            // eslint-disable-next-line no-loop-func
            yield effects.all(
              childrenTypes.flatMap((key) =>
                children[key].map((child) =>
                  effects.call(processChildEntity, {
                    parentEntityId: res.data.id,
                    typeId: key,
                    child,
                  })
                )
              )
            );
          }
        }
      }
    }

    if (!res) {
      return;
    }

    yield effects.call(callback, mainEntityId);
  } catch (error) {
    console.error(error);
  } finally {
    yield effects.put(setEntityFormSyncing(false));
  }
}

export function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(entityFormSubmission, handleEntityFormSubmission),
    typedEntitiesFetchWatcher(),
  ]);
}

export default rootSaga;
