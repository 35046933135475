import moment from 'moment';
import { ColumnFilterType } from '../../../api/model/schemas/ColumnFilterType';
import { ComparisonType } from '../../../api/model/schemas/ComparisonType';
import { FieldFilter } from '../../../api/model/schemas/FieldFilter';
import {
  StreamerFilter,
  StreamerFilterComparisonTypes,
  StreamerLimitedComparisons,
} from '../types';

// ---- helper methods
const isRangeFilter = (
  x: StreamerFilterComparisonTypes
): x is
  | StreamerLimitedComparisons.NotBetween
  | StreamerLimitedComparisons.Between =>
  (
    [
      StreamerLimitedComparisons.NotBetween,
      StreamerLimitedComparisons.Between,
    ] as StreamerFilterComparisonTypes[]
  ).includes(x);

const isValuesFilter = (
  x: StreamerFilterComparisonTypes
): x is StreamerLimitedComparisons.In | StreamerLimitedComparisons.NotIn =>
  (
    [
      StreamerLimitedComparisons.In,
      StreamerLimitedComparisons.NotIn,
    ] as StreamerFilterComparisonTypes[]
  ).includes(x);

const isNotEqualComparisonType = (
  x: StreamerFilterComparisonTypes
): x is
  | StreamerLimitedComparisons.NotBetween
  | StreamerLimitedComparisons.NotIn
  | StreamerLimitedComparisons.IsNotNull
  | StreamerLimitedComparisons.IsNotBlank =>
  (
    [
      StreamerLimitedComparisons.IsNotNull,
      StreamerLimitedComparisons.IsNotBlank,
      StreamerLimitedComparisons.NotIn,
      StreamerLimitedComparisons.NotBetween,
    ] as StreamerFilterComparisonTypes[]
  ).includes(x);

const isEqualComparisonType = (
  x: StreamerFilterComparisonTypes
): x is
  | StreamerLimitedComparisons.IsNull
  | StreamerLimitedComparisons.IsBlank
  | StreamerLimitedComparisons.In
  | StreamerLimitedComparisons.Between =>
  (
    [
      StreamerLimitedComparisons.IsNull,
      StreamerLimitedComparisons.IsBlank,
      StreamerLimitedComparisons.In,
      StreamerLimitedComparisons.Between,
    ] as StreamerFilterComparisonTypes[]
  ).includes(x);
// ----- helper methods end
const getColumnFilterType = (
  comparison: StreamerFilterComparisonTypes
): ColumnFilterType => {
  if (isRangeFilter(comparison)) {
    return ColumnFilterType.Range;
  }

  if (isValuesFilter(comparison)) {
    return ColumnFilterType.Values;
  }

  return ColumnFilterType.Comparison;
};

const compileComparison = (
  comparison: StreamerFilterComparisonTypes
): ComparisonType => {
  if (isNotEqualComparisonType(comparison)) {
    return ComparisonType.NotEqual;
  }
  if (isEqualComparisonType(comparison)) {
    return ComparisonType.Equal;
  }

  return comparison;
};

const formatCompareValue = (
  value,
  comparison?: StreamerFilterComparisonTypes
) => {
  if (
    [
      StreamerLimitedComparisons.IsNotBlank,
      StreamerLimitedComparisons.IsBlank,
    ].includes(comparison as StreamerLimitedComparisons)
  ) {
    return '';
  }

  if (
    [
      StreamerLimitedComparisons.IsNotNull,
      StreamerLimitedComparisons.IsNull,
    ].includes(comparison as StreamerLimitedComparisons)
  ) {
    return null;
  }

  if (!value) {
    return undefined;
  }

  if (value instanceof Date) {
    const offset = value.getTimezoneOffset() / 60;

    return moment.utc(value).subtract(offset, 'hours').format();
  }

  return value;
};

export const compileStreamerFilterToRequest = (
  values: StreamerFilter
): FieldFilter => ({
  type: getColumnFilterType(values.comparison),
  comparison: compileComparison(values.comparison),
  values: values?.values?.map((v) => formatCompareValue(v, values.comparison)),
  compareValue: formatCompareValue(values.compareValue, values.comparison),
  lowerBoundValue: formatCompareValue(values.lowerBoundValue),
  upperBoundValue: formatCompareValue(values.upperBoundValue),
});
