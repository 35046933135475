import buildQuery, { QueryOptions } from 'odata-query';
import {
  SingleNestedEntityPathParams,
  SingleNestedEntityCreatePathParams,
} from '../modules/Designer/types';

export const configurationPath = '/_configuration';

export const currentUserPreferences = '/v3/Users/Me/ClientPreferences';
export const updateUserPreferences = (id: string) =>
  `/v3/Users/Me/ClientPreferences(${id})` as const;

// v4 API
export const systemOAuthConnectorsPath = '/v4/System/OAuthConnectors';
export const systemOauthConnectorPath = (connectorId: string) =>
  `/v4/System/OAuth/${connectorId}` as const;
export const systemOAuthConnectorAccessPath = (connectorId: string) =>
  `${systemOauthConnectorPath(connectorId)}/Access` as const;

export const systemOAuthXeroTenantsPath = '/v4/System/XeroTenants';

export const systemConnectorConfigurationPropertyEntriesPath =
  '/v4/System/ConnectorConfigurationPropertyEntries';

export const buildEykoOdataQuery = (odata: Partial<QueryOptions<unknown>>) => {
  let parsedOData = buildQuery(odata);

  // you have to explicitly ask for count to include it in the response
  if ('count' in odata && odata.count === true) {
    return parsedOData;
  }

  // to speed up queries, we are always enforcing count to false
  parsedOData = `${parsedOData}&$count=false`;

  return parsedOData;
};

export const entitiesPath = (
  typeId: string,
  params?: Partial<QueryOptions<unknown>>
) =>
  params
    ? (`/v4/Entities${buildEykoOdataQuery(params)}&typeId=${typeId}` as const)
    : (`/v4/Entities?typeId=${typeId}` as const);

export const singleTopLevelEntityPath = (
  typeId: string,
  entityId: string,
  params?: Partial<QueryOptions<unknown>>
) =>
  params
    ? (`/v4/Entities/${entityId}${buildEykoOdataQuery(
        params
      )}&typeId=${typeId}` as const)
    : (`/v4/Entities/${entityId}?typeId=${typeId}` as const);

type NestedEntitiesPathParams = {
  oDataParams?: Partial<QueryOptions<unknown>>;
  parentEntityId: string;
  typeId: string;
};

type ODataParams = {
  oDataParams?: Partial<QueryOptions<unknown>>;
};

export const nestedEntitiesPath = ({
  oDataParams,
  typeId,
  parentEntityId,
}: NestedEntitiesPathParams) =>
  oDataParams
    ? (`/v4/Entities/${parentEntityId}/Children${buildEykoOdataQuery(
        oDataParams
      )}&typeId=${typeId}` as const)
    : (`/v4/Entities/${parentEntityId}/Children?typeId=${typeId}` as const);

export const singleNestedEntityPath = ({
  oDataParams,
  typeId,
  entityId,
  parentEntityId,
}: SingleNestedEntityPathParams & ODataParams) =>
  oDataParams
    ? (`/v4/Entities/${parentEntityId}/Children/${entityId}${buildEykoOdataQuery(
        oDataParams
      )}}&typeId=${typeId}` as const)
    : (`/v4/Entities/${parentEntityId}/Children/${entityId}?typeId=${typeId}` as const);

export const childEntityPath = ({
  typeId,
  parentEntityId,
  oDataParams,
}: SingleNestedEntityCreatePathParams & ODataParams) =>
  oDataParams
    ? (`/v4/Entities/${parentEntityId}/Children/${buildEykoOdataQuery(
        oDataParams
      )}&typeId=${typeId}` as const)
    : (`/v4/Entities/${parentEntityId}/Children/?typeId=${typeId}` as const);

interface TypeSectionPathArgs {
  typeId: string;
  params?: Partial<QueryOptions<unknown>>;
  designId?: string;
}
export const typeSectionPath = ({
  typeId,
  params,
  designId,
}: TypeSectionPathArgs) =>
  `/v4/Types/${typeId}/Interaction${
    params ? `${buildEykoOdataQuery(params)}&` : '?'
  }${designId ? `designId=${designId}` : ''}` as const;

interface TypeInteractionPropertiesPath {
  typeId: string;
  designId: string;
}
export const typeInteractionPropertiesPath = ({
  typeId,
  designId,
}: TypeInteractionPropertiesPath) =>
  `/v4/Types/${typeId}/Interaction/Properties?designId=${designId}` as const;

export interface AttachEntityFilePath {
  entityId: string;
  typeId: string;
}
export const attachEntityFilePath = ({
  entityId,
  typeId,
}: AttachEntityFilePath) =>
  `/v4/Entities/${entityId}/Data?typeId=${typeId}` as const;

export interface TypesPath {
  typeId: string;
  designId: string;
  params?: Partial<QueryOptions<unknown>>;
}
export const typesPath = ({ typeId, designId, params }) =>
  params
    ? (`/v4/Types${buildEykoOdataQuery(
        params
      )}&typeId=${typeId}&designId=${designId}` as const)
    : (`/v4/Types?typeId=${typeId}&designId=${designId}` as const);
