import { AnyAction, MiddlewareAPI, Dispatch } from 'redux';

import {
  DATA_SETS_STORAGE_KEY,
  DATA_TESTS_PERSIST_TRIGGER_ACTIONS,
} from './constants';
import { ApplicationState } from '../types';
import { DataPersistedInWorkbook } from './types';
import { getSessionId } from '../../modules/App/sessionId';
import { isOfficeEnabled } from '../../config/buildType';

export const getPersisted = () => new Promise((resolve) => {
  if (!isOfficeEnabled()) {
    resolve(undefined);
    return;
  }

  Office.context.document.settings.refreshAsync(() => {
    const storedDataSets = Office.context.document.settings.get(
      DATA_SETS_STORAGE_KEY,
    ) as DataPersistedInWorkbook;
    resolve(storedDataSets);
  });
});

export const persist = (
  action: AnyAction,
  store: MiddlewareAPI<Dispatch<AnyAction>, any>,
  forceUpdate?: boolean,
) => {
  if (!isOfficeEnabled()) return;

  if (DATA_TESTS_PERSIST_TRIGGER_ACTIONS.includes(action.type)) {
    const state = store.getState() as ApplicationState;
    const { datasets } = state.streams ?? {};

    if (!datasets?.length && !forceUpdate) return;

    const objectToStore: DataPersistedInWorkbook = {
      datasets: datasets ?? [],
      lastSavedBySessionId: getSessionId(),
      groupTableState: state.groupTable,
    };

    Office.context.document.settings.set(DATA_SETS_STORAGE_KEY, objectToStore);
    Office.context.document.settings.saveAsync(
      { overwriteIfStale: true },
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          // TO DO - handle
          // eslint-disable-next-line no-console
          console.error(
            `Settings save failed. Error: ${asyncResult.error.message}`,
          );
        }
      },
    );
  }
};
