import { IContextualMenuItem, ContextualMenuItemType } from '@fluentui/react';

import { SelectionType } from './types/SelectionType';

export interface SelectionItem extends IContextualMenuItem {
  isDefault?: boolean;
  nameI18nKey?: string;
}

export const selectionItems: SelectionItem[] = [
  {
    key: 'divider_1',
    itemType: ContextualMenuItemType.Divider,
  },
  {
    key: SelectionType.all,
    nameI18nKey: 'viewControl:all',
  },
  {
    key: SelectionType.selected,
    nameI18nKey: 'viewControl:selected',
  },
  {
    key: SelectionType.unselected,
    nameI18nKey: 'viewControl:unselected',
  },
];
