import React, {
  FunctionComponent,
  useRef,
  useLayoutEffect,
  useState,
} from 'react';
import { Stack, Text, IconButton } from '@fluentui/react';

import { useTranslation } from 'react-i18next';
import TooltipHost from '../../../ui/TooltipHost';

import {
  // percentTextStyles,
  fileElementStyles,
  nameStyles,
  btnStyles,
  percentStackStyles,
  classNames,
} from './styles';

interface FileElementProps {
  name: string;
  onClickDelete?(): void;
  onClickSettings?(): void;
  index: number;
  isSelected: boolean;
  wasUpdated?: boolean;
}

const FileElement: FunctionComponent<FileElementProps> = ({
  name,
  wasUpdated,
  onClickDelete,
  onClickSettings,
  index,
  isSelected,
}) => {
  const { t } = useTranslation();
  const [isTextCollapsed, setIsTextCollapsed] = useState(false);
  const nameRef = useRef<HTMLSpanElement>();

  useLayoutEffect(() => {
    const { offsetWidth, scrollWidth } =
      nameRef?.current?.parentElement?.parentElement;

    if (offsetWidth < scrollWidth) {
      setIsTextCollapsed(true);
    }
  }, [nameRef]);

  return (
    <Stack
      horizontal
      horizontalAlign="end"
      styles={fileElementStyles(isSelected)}
      verticalAlign="center"
      tabIndex={0}
    >
      <Text styles={nameStyles}>
        <TooltipHost content={isTextCollapsed ? name : null}>
          <span ref={nameRef}>{name}</span>
        </TooltipHost>
      </Text>
      {wasUpdated && (
        <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
          styles={percentStackStyles}
        >
          <Text className={classNames.updateText}>{t('upload:updated')}</Text>
        </Stack>
      )}
      {onClickSettings && (
        <IconButton
          onClick={onClickSettings}
          data-file-index={index}
          styles={btnStyles(isSelected)}
          iconProps={{
            iconName: 'Settings',
          }}
        />
      )}
      <IconButton
        onClick={onClickDelete}
        styles={btnStyles(false)}
        iconProps={{
          iconName: 'Delete',
        }}
      />
    </Stack>
  );
};

export default FileElement;
