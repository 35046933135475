import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { HeaderBarButton } from '../../../../components/ui';
import { routes } from '../../../../constants/routes';
import { clearErrors, dismissAllNotifications } from '../../actions';
import { getErrors, getNotificationsCount } from '../../selectors';

export const NotificationsHeader = () => {
  // HOOKS
  const dispatch = useDispatch();
  // STATE
  const notificationCount = useSelector(getNotificationsCount);
  const errors = useSelector(getErrors);

  // DERIVED STATE
  const shouldRenderDismissAllErrors = errors.length > 0;
  const isDismissAllNotificationsVisible = React.useMemo(
    () => notificationCount && notificationCount > 0,
    [notificationCount],
  );

  // CALLBACKS
  const handleDismissAllNotifications = React.useCallback(() => {
    dispatch(dismissAllNotifications());
  }, [dispatch]);

  const handleClearErrors = React.useCallback(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  // RENDER
  return (
    <>
      <Route path={routes.notifications.records.index}>
        <HeaderBarButton
          tooltip={<Trans i18nKey="notifications:dismissAll" />}
          disabled={!isDismissAllNotificationsVisible}
          shouldHideOnDisabled
          onClick={handleDismissAllNotifications}
          iconProps={{ iconName: 'Delete' }}
        />
      </Route>
      <Route path={routes.notifications.errors.index}>
        <HeaderBarButton
          tooltip={<Trans i18nKey="notifications:dismissAll" />}
          disabled={!shouldRenderDismissAllErrors}
          shouldHideOnDisabled
          onClick={handleClearErrors}
          iconProps={{ iconName: 'Delete' }}
        />
      </Route>
    </>
  );
};
