import React from 'react';
import { TextField as TextFieldBase } from '@fluentui/react';
import upperFirst from 'lodash/upperFirst';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SCHEMA_FORMAT_TYPES } from '../../constants';
import {
  IControlledField,
  IEntityProperty,
  SchemaDataType,
  SchemaControlType,
} from '../../types';

import { textFieldStyles } from './styles';
import { useReferenceTypeProperty } from '../../hooks/useReferenceTypeProperty';
import { translateApiName } from '../../../../../config/i18n/utils';
import { CalculationTypes } from '../../../../../api/model/schemas';
import { DaxValidate } from './DaxValidate/DaxValidate';

export const getTypeFromIteration = ({
  interaction: { formatType },
  dataType,
}: IEntityProperty) => {
  switch (formatType) {
    case SCHEMA_FORMAT_TYPES.Uri:
      return 'url';
    default:
      break;
  }

  switch (dataType) {
    case SchemaDataType.SecuredText:
      return 'password';
    case SchemaDataType.NumericInteger:
    case SchemaDataType.NumericDecimal:
      return 'number';
    default:
      return 'text';
  }
};

export const transform = {
  input: (value) => (isNaN(value) || value === 0 ? '' : value?.toString()),
  outputInt: (e) => {
    const output = parseInt(e.target.value, 10);
    return isNaN(output) ? 0 : output;
  },
  outputDecimal: (e) => {
    const output = parseFloat(e.target.value);
    return isNaN(output) ? 0 : output;
  },
};

const TextField: React.FC<IControlledField> = ({
  controller,
  state,
  currentEntity,
  currentForm,
  entityProperty,
  interaction,
  isDisabled,
  label,
  translationPrefix,
  inputName,
}) => {
  // HOOKS
  const { t } = useTranslation();

  // DERIVED STATE
  const { isReadOnly }: IEntityProperty = entityProperty;

  const { isRequired, controlType } = interaction;

  const { name, onChange, ref, value } = controller;
  const { setValue } = useFormContext();
  const { invalid } = state;
  const type = getTypeFromIteration({ ...entityProperty, interaction });
  const isDaxCalculation =
    currentForm.typeId === CalculationTypes.DaxCalculation;
  const isDaxMeasure = currentForm.typeId === CalculationTypes.DaxMeasure;

  const i18nValue = currentEntity?.[`i18n${upperFirst(entityProperty.name)}`];
  const translatedValue =
    i18nValue ??
    (translationPrefix
      ? t(`${translationPrefix}:${value}`)
      : translateApiName(currentForm?.typeId, value));
  const parsedValue =
    type === 'number'
      ? transform.input(value)
      : isDisabled || isReadOnly
      ? translatedValue
      : value;

  // CALLBACKS

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (entityProperty.dataType === SchemaDataType.NumericInteger) {
      return onChange(transform.outputInt(e));
    }
    if (entityProperty.dataType === SchemaDataType.NumericDecimal) {
      return onChange(transform.outputDecimal(e));
    }
    return onChange(e);
  };

  // EFFECTS
  useReferenceTypeProperty(interaction, (val) => {
    setValue(inputName, val);
  });

  // RENDER
  return (
    <>
      <TextFieldBase
        data-testid={`text-input-${name}`}
        componentRef={ref}
        label={label}
        readOnly={isReadOnly}
        disabled={isDisabled || isReadOnly}
        required={isRequired}
        type={type}
        styles={textFieldStyles(invalid)}
        multiline={controlType === SchemaControlType.MultiLineEdit}
        onChange={handleChange}
        value={parsedValue}
        canRevealPassword={type === 'password'}
      />
      {(isDaxCalculation || isDaxMeasure) && (
        <DaxValidate
          textValue={parsedValue}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default TextField;
