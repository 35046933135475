import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sourceEntitiesToOptions } from '../../modules/Designer/Form/components/SelectOneOfType/utils';
import { IEntity } from '../../modules/Designer/Form/components/LinkPicker/types';
import { SelectDropdownVirtualList } from '../ui';
import { IEntityOption } from '../../modules/Designer/Form/components/SelectOneOfType/types';
import { entityDropdownStyles } from '../../modules/Designer/Form/components/SelectOneOfType/styles';
import { DataType } from '../../api/model/schemas/DataType';

export interface EntityFieldDropdownProps {
  isDisabled: boolean;
  entities: IEntity[];
  activeFieldId: string;
  onChange(fieldId: string): void;
  dataType?: DataType;
  allowedDataTypes: DataType[];
}

const EntityFieldDropdown: FunctionComponent<EntityFieldDropdownProps> = ({
  entities,
  activeFieldId,
  onChange,
  isDisabled,
  dataType,
  allowedDataTypes,
}) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => sourceEntitiesToOptions({ entities, dataType, allowedDataTypes }),
    [entities]
  );

  const currentOption = useMemo(
    () => options.find((o) => o.value === activeFieldId),
    [options, activeFieldId]
  );

  return (
    <SelectDropdownVirtualList
      options={options}
      isDisabled={!options.length || isDisabled}
      placeholder={t('filters:Select')}
      onChange={(option: IEntityOption) => onChange(option.value)}
      value={currentOption}
      styles={entityDropdownStyles}
      // fixing https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6943
      menuPlacement="bottom"
    />
  );
};

export default EntityFieldDropdown;
