import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchDesignTags, setNewTagInputOpen } from '../../actions';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { getIsMetadataStateLoading, getAllTags } from '../../selectors';

import NewTagInput from '../../components/NewTagInput/NewTagInput';
import TagsList from '../../components/TagsList';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';

const Tags = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const selectedDesignId = useSelector(getSelectedDesignId);
  const tags = useSelector(getAllTags);
  const isLoading = useSelector(getIsMetadataStateLoading);

  // CALLBACKS
  const handleAdd = React.useCallback(
    () => {
      dispatch(setNewTagInputOpen(true));
    },
    [dispatch],
  );


  // EFFECTS
  React.useEffect(() => {
    if (selectedDesignId) {
      dispatch(fetchDesignTags(selectedDesignId));
    }
  }, [dispatch, selectedDesignId]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: tags.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick: handleAdd,
  });

  if (!isLoading && tutorialTile) return tutorialTile;

  return (
    <>
      <NewTagInput />
      <TagsList />
    </>
  );
};

export default Tags;
