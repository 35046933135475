import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { fetchEntity, fetchNestedEntities } from '../api';
import { ApiV4ResponseWrapper } from '../types';
import { fetchEntityForm } from './api';
import { FetchTypedEntitiesPayload } from './components/SelectOneOfType/types';
import { getFormChildrenByTypeId } from './selectors';
import {
  IEntityForm,
  IEntityFormChildren,
  IGenericEntity,
  IGenericEntityAsset,
} from './types';

interface FetchEntityFormSectionsArgs {
  typeId: string;
  designId?: string;
}

export const fetchEntityFormSections = createAsyncThunk(
  'DESIGNER/ENTITY_FORM/FETCH_ENTITY_FORM_SECTIONS',
  async ({ typeId, designId }: FetchEntityFormSectionsArgs) => {
    const { data: responseData } = await fetchEntityForm({ typeId, designId });
    let sections = responseData.data;
    if (typeId === TYPE_IDS.Calculation) {
      sections = responseData.data.filter(
        (section) => section.name !== 'Condition'
      );
    }
    return {
      typeId,
      sections,
    };
  }
);

type FetchEntityParams = {
  typeId: string;
  entityId: string;
  assetsTypeId?: string;
};

export const fetchEntities = createAsyncThunk(
  'DESIGNER/ENTITY_FORM/FETCH_ENTITIES',
  async ({ typeId, entityId, assetsTypeId }: FetchEntityParams) => {
    const regx = /\$(.+)TypeId/;
    const entities: IGenericEntity[] = [];

    const recursiveEntityFetch = async (
      currentTypeId: string,
      currentEntityId: string,
      parentEntityId?: string
    ) => {
      if (currentTypeId && !currentEntityId && parentEntityId) {
        const { data } = await fetchNestedEntities<
          ApiV4ResponseWrapper<IGenericEntityAsset[]>
        >({ typeId: currentTypeId })(parentEntityId)();
        entities.push(...data?.data);
      }

      if (!currentTypeId || !currentEntityId) {
        return undefined;
      }

      try {
        const { data } = await fetchEntity<IGenericEntity>(
          currentTypeId,
          currentEntityId
        )(
          currentTypeId === TYPE_IDS.ColumnsToRowsTransformation
            ? {
                expand: 'TransformationFields',
              }
            : undefined
        );

        // in case of 404/500 error data might be undefined, and this will cause whole addin to crash
        if (data) {
          entities.push(data);
        }

        /**
         * Child entities have their identifiers in the form of $xxxTypeId and xxxId
         * so we find those using regex.
         * One entity can have many child entities, and these can have many children as well,
         * We fetch all of them.
         */
        await Promise.all(
          Object.keys(data)
            /**
             * We need to ignore $parentTypeId or we would end up in an infinite fetching loop
             */
            .filter((key) => regx.test(key) && key !== '$parentTypeId')
            /**
             * LiteralValues are stored as 'entities' even when they are not. There is nothing to fetch, so
             * we filter them out.
             */
            .filter(
              (childTypeIdKey) => data[childTypeIdKey] !== TYPE_IDS.LiteralValue
            )
            .map((childTypeIdKey) => {
              const childEntityIdKey = `${childTypeIdKey.match(regx)[1]}Id`;

              /**
               * When dealing with assets, the children entities do not end with Id
               */
              const assetEntityKey = `${childTypeIdKey.match(regx)[1]}`;

              return recursiveEntityFetch(
                data[childTypeIdKey],
                data[assetEntityKey] || data[childEntityIdKey],
                currentEntityId
              );
            })
        );
      } catch (error) {
        // for debugging - just in case
        console.log('one of the nested entities failed to load', {
          error,
          currentTypeId,
          currentEntityId,
          parentEntityId,
        });
      }
    };

    await recursiveEntityFetch(typeId, entityId);

    /**
     * Some assets (hubs sources for example) are not described in the parent entity
     * so we have to fetch them using their typeId
     */
    if (assetsTypeId) {
      const { data } = await fetchNestedEntities<
        ApiV4ResponseWrapper<IGenericEntityAsset[]>
      >({ typeId: assetsTypeId })(entityId)();
      entities.push(...data?.data);

      // The entity LinkFields doesn't use the asset schema, so we can finish
      // after fetching it.
      if (assetsTypeId === TYPE_IDS.LinkFields) {
        return entities;
      }

      const children = await Promise.all(
        data?.data.map(async (asset) => {
          const res = await fetchEntity<IGenericEntity>(
            asset.$assetTypeId,
            asset.assetId
          )();
          return res.data;
        })
      );

      entities.push(...children);
    }

    return entities;
  }
);

export const setCurrentPageIndex = createAction<
  number,
  'DESIGNER/ENTITY_FORM/SET_CURRENT_PAGE_INDEX'
>('DESIGNER/ENTITY_FORM/SET_CURRENT_PAGE_INDEX');

export const setEntityFormData = createAction<
  {
    typeId: string;
    data: IEntityForm['data'];
  },
  'DESIGNER/ENTITY_FORM/SET_ENTITY_FORM_DATA'
>('DESIGNER/ENTITY_FORM/SET_ENTITY_FORM_DATA');

export const addEntityForm = createAction<
  IEntityForm,
  'DESIGNER/ENTITY_FORM/ADD_ENTITY_FORM'
>('DESIGNER/ENTITY_FORM/ADD_ENTITY_FORM');
export const removeEntityForm = createAction<
  {
    typeId: string;
  },
  'DESIGNER/ENTITY_FORM/REMOVE_ENTITY_FORM'
>('DESIGNER/ENTITY_FORM/REMOVE_ENTITY_FORM');

export const setFormEntityId = createAction<
  {
    typeId: string;
    entityId: string;
  },
  'DESIGNER/ENTITY_FORM/SET_FORM_ENTITY_ID'
>('DESIGNER/ENTITY_FORM/SET_FORM_ENTITY_ID');

export const setFormParentEntityId = createAction<
  {
    typeId: string;
    parentEntityId: string;
  },
  'DESIGNER/ENTITY_FORM/SET_FORM_PARENT_ENTITY_ID'
>('DESIGNER/ENTITY_FORM/SET_FORM_PARENT_ENTITY_ID');

export const updateEntity = createAction<
  {
    schemaId: string;
    entity: { id: string; [key: string]: any };
    callback: () => void;
  },
  'DESIGNER/ENTITY_FORM/UPDATE_ENTITY'
>('DESIGNER/ENTITY_FORM/UPDATE_ENTITY');

export const createEntity = createAction<
  {
    schemaId: string;
    data: IGenericEntity;
    callback: () => void;
  },
  'DESIGNER/ENTITY_FORM/CREATE_ENTITY'
>('DESIGNER/ENTITY_FORM/CREATE_ENTITY');

export const createChildEntity = createAction<
  {
    schemaId: string;
    parentEntityId: string;
    parentSchemaId: string;
    data: IGenericEntity;
    callback: () => void;
  },
  'DESIGNER/ENTITY_FORM/CREATE_CHILD_ENTITY'
>('DESIGNER/ENTITY_FORM/CREATE_CHILD_ENTITY');

export const restartForm = createAction('DESIGNER/ENTITY_FORM/RESTART_FORM');

export const entityFormSubmission = createAction<
  {
    callback: (createdEntityId: string) => void;
  },
  'DESIGNER/ENTITY_FORM/FORM_SUBMISSION'
>('DESIGNER/ENTITY_FORM/FORM_SUBMISSION');

type SetEntityChildren = {
  formTypeId: string;
  typeId: string;
  children: IEntityFormChildren[];
};
export const setEntityFormChildren = createAction<
  SetEntityChildren,
  'DESIGNER/ENTITY_FORM/SET_CHILDREN'
>('DESIGNER/ENTITY_FORM/SET_CHILDREN');
export const addEntityFormChildren = createAction<
  SetEntityChildren,
  'DESIGNER/ENTITY_FORM/ADD_CHILDREN'
>('DESIGNER/ENTITY_FORM/ADD_CHILDREN');

export const setFetchingEntities = createAction<
  boolean,
  'DESIGNER/ENTITY_FORM/SET_FETCHING_ENTITIES'
>('DESIGNER/ENTITY_FORM/SET_FETCHING_ENTITIES');

export const addRunningTaskTypedEntities = createAction<{
  typeId: string;
  sourceId?: string;
}>('DESIGNER/ENTITY_FORM/ADD_RUNNING_TASK_TYPED_ENTITIES');

export const removeRunningTaskTypedEntities = createAction<{
  typeId: string;
  sourceId?: string;
}>('DESIGNER/ENTITY_FORM/REMOVE_RUNNING_TASK_TYPED_ENTITIES');

export const addEntities = createAction<
  IGenericEntity[],
  'DESIGNER/ENTITY_FORM/ADD_ENTITIES'
>('DESIGNER/ENTITY_FORM/ADD_ENTITIES');

export const addTypedEntities = createAction<
  {
    typeId: string;
    entities: IGenericEntity[];
  },
  'DESIGNER/ENTITY_FORM/ADD_TYPED_ENTITIES'
>('DESIGNER/ENTITY_FORM/ADD_TYPED_ENTITIES');
export const fetchTypedEntities = createAction<
  FetchTypedEntitiesPayload,
  'DESIGNER/ENTITY_FORM/FETCH_TYPED_ENTITIES'
>('DESIGNER/ENTITY_FORM/FETCH_TYPED_ENTITIES');
export const setEntityFormSyncing = createAction<
  boolean,
  'DESIGNER/ENTITY_FORM/SET_SYNCING'
>('DESIGNER/ENTITY_FORM/SET_SYNCING');

type AddFileParams = {
  formTypeId: string;
  childrenTypeId: string;
  interactionTypeId: string;
  files: File[];
};
export const addFileIntent = createAsyncThunk(
  'DESIGNER/ENTITY_FORM/ADD_FILE_INTENT',
  (
    { childrenTypeId, formTypeId, files, interactionTypeId }: AddFileParams,
    { getState, dispatch }
  ) => {
    const state = getState() as any;
    const items = getFormChildrenByTypeId(formTypeId, childrenTypeId)(state);

    const currentFiles = items.map((item) => item?.data);

    files.forEach((file) => {
      const index = currentFiles.findIndex((f) => f.name === file.name);

      if (index !== -1) {
        const idToEdit = items[index].id;

        const data = {
          file,
          name: file.name,
          wasFileUpdated: true,
        };

        const children = items.map((item) =>
          item.id === idToEdit
            ? {
                ...item,
                data: { ...(item.data || {}), ...(data || {}) },
              }
            : item
        );

        dispatch(
          setEntityFormChildren({
            formTypeId,
            typeId: interactionTypeId,
            children,
          })
        );
      } else {
        const id = uuidv4();
        dispatch(
          addEntityFormChildren({
            formTypeId,
            typeId: interactionTypeId,
            children: [{ id, data: { file, name: file.name } }],
          })
        );
      }
    });
  }
);
