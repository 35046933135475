import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import {
  addNestedEntityRoute,
  editEntityRoute,
  deleteEntityRoute,
} from '../../../../../utils/routes';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import {
  getCatalogEntrySelection,
  getCatalogPropertySelection,
  getCatalogPropertyById,
} from '../../selectors';
import { toggleCatalogPropertySelection } from '../../actions';
import { routes } from '../../../../../constants/routes';

export const CatalogHeader = () => {
  // HOOKS
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const catalogEntrySelectionId = useSelector(getCatalogEntrySelection);
  const catalogPropertySelectionId = useSelector(getCatalogPropertySelection);
  const propertySelector = React.useMemo(
    () => getCatalogPropertyById(catalogPropertySelectionId),
    [catalogPropertySelectionId]
  );
  const catalogPropertySelection = useSelector(propertySelector);

  // CALLBACKS
  const handleAdd = React.useCallback(
    catalogEntrySelectionId
      ? () => {
          const path = addNestedEntityRoute({
            parentEntityId: catalogEntrySelectionId,
            parentSchemaId: TYPE_IDS.Type,
            schemaId: TYPE_IDS.SchemaProperty,
          });

          history.push(path, {
            typeName: t('catalog:entityField'),
          });
        }
      : undefined,
    [catalogEntrySelectionId, history.push]
  );

  const handleEdit = React.useCallback(
    catalogPropertySelectionId
      ? () => {
          const path = editEntityRoute(
            TYPE_IDS.SchemaProperty,
            catalogPropertySelectionId
          );
          history.push(path, {});
        }
      : undefined,
    [catalogPropertySelectionId, history.push]
  );

  const handleDelete = React.useCallback(
    catalogPropertySelection?.isLocked === false
      ? () => {
          const path = deleteEntityRoute(
            TYPE_IDS.SchemaProperty,
            catalogPropertySelection.id
          );

          const goBackPage = routes.designer.metadata.catalogDefinition;

          history.push(path, {
            goBackPage,
            entityName: catalogPropertySelection.name,
            deleteDescription: (
              <Trans
                i18nKey="wizard:deleteNestedEntity"
                values={{
                  name: catalogPropertySelection.name,
                  asset: t('entityTypes:SchemaProperty'),
                }}
              />
            ),
          });

          dispatch(toggleCatalogPropertySelection(catalogPropertySelection.id));
        }
      : undefined,
    [catalogPropertySelection, dispatch]
  );

  // RENDER
  return (
    <ItemActionsHeader
      {...{ handleAdd, handleDelete, handleEdit }}
      isItemSelected={!!catalogPropertySelectionId}
    />
  );
};
