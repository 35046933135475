import {
  IStackStyles,
  mergeStyleSets,
} from '@fluentui/react';

export const classNames = mergeStyleSets({
  container: {
    flex: '1 1 auto',
    overflowY: 'auto',
    maxHeight: '100%',
  },
});

export const emptyMessageStyles: IStackStyles = {
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
};
