import { IStyle, mergeStyleSets } from '@fluentui/merge-styles';

import { enforceSchema } from '../../../shared/types/utils';
import { baseColors } from '../../theme';
import { CSSProperties } from 'react';

type StyleSet = { [key: string]: IStyle };

export const tutorialTileStyles = mergeStyleSets(enforceSchema<StyleSet>()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    maxHeight: '100%',
    margin: '0 16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
  },
  title: {
    flexShrink: 0,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 16,
  },
  image: {
    flexShrink: 1,
    width: '100%',
    height: '100%',
    maxHeight: 160, // NOTE: nasty hack to make the image resize but only until a certain point
    marginBottom: 32,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  list: {
    flexShrink: 0,
    listStyle: 'none',
    padding: 0,
    margin: 0,
    overflowY: 'auto',
  },
  footer: {
    flexShrink: 0,
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    display: 'flex',
    marginTop: 'auto',
    marginBottom: 16,
    paddingTop: 8,
  },
  link: {
    color: baseColors.midOrange,
    selectors: {
      '&:hover': {
        color: baseColors.orange,
      },
    },
  },
  button: {
    marginLeft: 'auto',
    padding: '6px 16px',
    lineHeight: 16,
    border: 0,
    borderRadius: 4,
    backgroundColor: baseColors.offBlack,
    color: baseColors.white,
    fontSize: 12,
    boxShadow: '0px 3.6px 7.2px rgba(50, 49, 48, 0.132), 0px 0.8px 3.2px rgba(50, 49, 48, 0.108)',
    cursor: 'pointer',
    selectors: {
      '&:hover': {
        backgroundColor: baseColors.lightBlack,
      },
      '&:disabled': {
        backgroundColor: baseColors.midGrey,
        color: baseColors.offBlack,
        cursor: 'not-allowed',
      },
    },
  },
}));

export const tutorialTileImageStyles = (url: string): CSSProperties => ({
  backgroundImage: `url(${url})`,
});

export const tutorialTileItemStyles = mergeStyleSets(enforceSchema<StyleSet>()({
  root: {
    display: 'grid',
    gridTemplateColumns: '32px auto',
    gridTemplateRows: 'auto auto',
  },
  icon: {
    gridColumn: '1 / span 1',
    gridRow: '1 / span 2',
    justifySelf: 'center',
    width: 16,
    fontSize: 16,
  },
  title: {
    gridColumn: '2 / span 1',
    gridRow: '1 / span 1',
    margin: 0,
    marginBottom: 4,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 16,
  },
  description: {
    gridColumn: '2 / span 1',
    gridRow: '2 / span 1',
    margin: 0,
    marginBottom: 16,
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 16,
  },
}));
