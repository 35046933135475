// TODO: move into i18n/types
import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../../modules/Designer/types";
import { isResponseTypeIdMatching } from "../../utils";
import { TypeIds } from "../../../../model/schemas";
import { translateApiName } from "../../../../../config/i18n/utils";
import { i18nDataTypeKey, i18nFormatTypeKey, i18nProcessedDataTypeKey, i18nProcessedFormatTypeKey } from "../../constants";
import { TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP } from "../../../../constants";
import { StreamGroupAsset } from "../../../../model/schemas/StreamGroupAsset";

export const isStreamGroupAssetResponse = (
  response: AxiosResponse<ApiV4ResponseWrapper<unknown>>
): response is AxiosResponse<ApiV4ResponseWrapper<StreamGroupAsset[]>> =>
  isResponseTypeIdMatching(response, TypeIds.StreamGroupAsset);

export const mutablyParseStreamGroupAssets = (response: AxiosResponse<ApiV4ResponseWrapper<StreamGroupAsset[]>>) => {
  response.data.data = response.data.data.map(group => {
    return {
      ...group,
      [i18nDataTypeKey]: translateApiName(group.$typeId, group.dataType),
      [i18nFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[group.dataType] ?? group.$typeId,
        String(group.formatType),
      ),
      [i18nProcessedDataTypeKey]: translateApiName(group.$typeId, group.processedDataType),
      [i18nProcessedFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[group.processedDataType] ?? group.$typeId,
        String(group.processedFormatType),
      ),
    };
  });

  return response;
}
