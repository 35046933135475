export enum Environment {
  Local = 'Local',
  Staging = 'Staging',
  Production = 'Production',
  DynamicStaging = 'DynamicStaging',
  Unknown = 'Unknown'
}

export const getEnvironment = ():Environment => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return Environment.Local;
  }

  if (window.location.host === 'qaaddin-eyko.azurewebsites.net') {
    return Environment.DynamicStaging;
  }

  if (window.location.host === 'prodexceladdin.azureedge.net') {
    return Environment.Production;
  }

  if (window.location.host === 'devexceladdin.azureedge.net') {
    return Environment.Staging;
  }

  return Environment.Local;
};
