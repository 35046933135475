import React from 'react';
import { Text, Stack, getTheme } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import { routes } from '../../../../constants/routes';
import * as styles from './styles';
import { PrimaryButton } from '../../../../components/ui';
import EykoLogo from './logo.svg';
import { AuthContext } from '../../../Auth-v2/context';
import { getEnvironmentUrl } from '../../../Settings/components/connection/utils';
import { isOfficeEnabled } from '../../../../config/buildType';

const Welcome: React.FC = () => {
  // HOOKS
  const { t } = useTranslation('welcome');
  const { isSignedIn, isSignInPending, signIn } = React.useContext(AuthContext);

  // DERIVED STATE
  const theme = getTheme();

  // CALLBACKS
  const handleConnect = React.useCallback(() => {
    const environmentUrl = getEnvironmentUrl();

    // to support quick stream from v4
    const urlSearchParams = new URLSearchParams(window.location.search);
    const envUrlFromParams = urlSearchParams.get('env_url');

    signIn(envUrlFromParams ?? environmentUrl);
  }, [signIn]);

  // RENDER
  if (isSignedIn)
    return (
      <Redirect
        to={
          isOfficeEnabled() ? routes.streamingFlow.index : routes.streamer.index
        }
      />
    );

  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      style={{ flex: '1 1 auto' }}
    >
      <EykoLogo style={{ marginBottom: 115 }} />
      <Text variant="mediumPlus" styles={styles.titleStyles(theme)}>
        {t('title')}
      </Text>
      <Text as="p" variant="small" styles={styles.textStyles(theme)}>
        {t('subtitle_1')}
        <br />
        {t('subtitle_2')}
      </Text>
      <PrimaryButton
        data-testid="welcome-page-btn"
        disabled={isSignInPending}
        onClick={handleConnect}
      >
        {t('connect')}
      </PrimaryButton>
      <Link
        data-testid="skip-onboarding-btn"
        to={routes.settings.preferences.index}
        style={styles.linkStyles(theme)}
      >
        {t('settings')}
      </Link>
    </Stack>
  );
};

export default Welcome;
