import {
  ISearchBoxStyleProps,
  ISearchBoxStyles,
  mergeStyleSets,
} from '@fluentui/react';
import { baseColors } from '../../../theme';

export const classNames = mergeStyleSets({
  listItemWrap: {
    margin: '2px',
  },
  sectionWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '100%',
    flex: '1 1 200px',
    width: '100%',
    margin: '0',
    padding: '0',
    listStyleType: 'none',
    selectors: {
      '&>div': {
        width: '100%',
      },
    },
  },
  infiniteScroll: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 40px)',
    gridGap: '5px',
    justifyItems: 'center',
    position: 'relative',
  },
  mainWrap: {
    height: '100%',
  },
});

export const searchBoxStyles = ({
  theme,
}: ISearchBoxStyleProps): ISearchBoxStyles => ({
  root: {
    borderColor: baseColors.midOrange,
    borderRadius: 2,
    margin: '0 5px 8px !important',
    flexWrap: 'nowrap',
    // flex: 1,
    selectors: {
      '&:after': {
        display: 'none',
      },
      '.ms-SearchBox-iconContainer': {
        display: 'flex',
      },
      '&:active': {
        borderColor: theme.palette.themePrimary,
      },
      '&:hover': {
        borderColor: theme.palette.themePrimary,
      },
    },
  },
  field: {},
  icon: {
    color: baseColors.iconColor,
    opacity: '1 !important',
  },
  iconContainer: {
    width: '32px !important',
  },
  clearButton: {
    alignItems: 'center',
    justifyContent: 'center',
    selectors: {
      '.ms-Button': {
        borderRadius: '4 !important',
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
  },
});
