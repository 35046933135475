import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Streams';

export const TUTORIAL_TILE_KEY = 'builder-main';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:builder:main:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:builder:main:items:createStream:description',
      icon: 'Add',
      titleI18nKey: 'tutorialTiles:builder:main:items:createStream:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:builder:main:items:pickWhatYouNeed:description',
      icon: 'EykoData',
      titleI18nKey: 'tutorialTiles:builder:main:items:pickWhatYouNeed:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:builder:main:items:processAndShare:description',
      icon: 'Share',
      titleI18nKey: 'tutorialTiles:builder:main:items:processAndShare:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010407981-Streams-Welcome-to-Builder',
};
