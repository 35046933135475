import { IButtonStyles, IIconStyles, IStackTokens, IStyle, mergeStyleSets } from "@fluentui/react";
import { enforceSchema } from "../../../../../../../shared/types/utils";
import { baseColors } from "../../../../../../theme";

export const CONNECT_BUTTON_STYLES: IButtonStyles = {
  root: {
    whiteSpace: 'pre',
  },
};

export const BUTTON_ICON_STYLES: IIconStyles = {
  root: {
    marginLeft: 4,
  },
};

export const STACK_TOKENS: IStackTokens = {
  childrenGap: 8,
};

type StyleSet = { [key: string]: IStyle };

export const oauthControlStyles = mergeStyleSets(enforceSchema<StyleSet>()({
  scopeHelpLink: {
    alignSelf: 'center',
    marginLeft: 'auto !important', // overriding styles in fluent...
    color: baseColors.midOrange,
    selectors: {
      '&:hover': {
        color: baseColors.orange,
      },
    },
  },
}));
