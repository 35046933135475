import { useId } from '@fluentui/react-utilities';
import {
  DirectionalHint,
  Stack,
  Text,
  Spinner,
  DefaultButton,
  TextField,
} from 'office-ui-fabric-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  HeaderBarButton,
  InformationDialog,
} from '../../../../../components/ui';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { createEntity } from '../../../api';

export const ImportDesign = () => {
  // DEPS
  const { t } = useTranslation();

  // STATE
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [snapshotId, setSnapshotId] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [hasError, setError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);

  // HOOKS
  const componentId = useId('design-import');

  // CALLBACKS
  const closeDialog = React.useCallback(() => {
    setDialogOpen(false);
  }, []);

  const loadSnapshot = React.useCallback(async () => {
    if (!snapshotId) return;

    setLoading(true);
    try {
      await createEntity(TYPE_IDS.ImportDesignJob)({
        SnapshotId: snapshotId,
      });

      setSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [snapshotId]);

  return (
    <>
      <HeaderBarButton
        id={componentId}
        iconProps={{ iconName: 'Import' }}
        onClick={() => setDialogOpen(true)}
        shouldHideOnDisabled
        tooltip={<Trans i18nKey="importStreamerWizard:Import" />}
      />
      <InformationDialog
        target={`#${componentId}`}
        onDismiss={closeDialog}
        onClose={closeDialog}
        calloutWidth={300}
        directionalHint={DirectionalHint.bottomRightEdge}
        isCalloutVisible={isDialogOpen}
        title={<Trans i18nKey="importStreamerWizard:Import" />}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          style={{ marginBottom: '8px' }}
        >
          <TextField
            label={t('designImportExport:snapshotid')}
            value={snapshotId}
            onChange={(_, val) => {
              setSnapshotId(val);
            }}
          />
          <DefaultButton
            onClick={loadSnapshot}
            iconProps={{ iconName: 'Processing' }}
            disabled={isLoading || isSuccess}
          >
            {t('importStreamerWizard:Import')}
          </DefaultButton>
        </Stack>

        {isLoading && <Spinner />}

        {hasError && <Text>{t('designImportExport:errorMsg')}</Text>}

        {isSuccess && <Text>{t('designImportExport:importQueued')}</Text>}
      </InformationDialog>
    </>
  );
};
