import React, { FunctionComponent } from 'react';
import { Stack } from '@fluentui/react';

import Dropzone from './components/Dropzone';
import FileList from './components/FileList';

import { FileTypes } from './types/FileType';
import { IDelimitedFile } from '../../modules/Designer/Form/components/FilePicker/types';

interface FileUploadProps {
  fileType: FileTypes;
  // when editing we are not receiving files themselves, just the metadata
  files: IDelimitedFile[];
  multiple?: boolean;
  onAdd: (files: File[]) => void;
  onRemove: (index: number) => void;
  onSelect(index: number): void;
}

const FileUpload: FunctionComponent<FileUploadProps> = ({
  fileType,
  multiple,
  files,
  onAdd,
  onRemove,
  onSelect,
}) => (
  <Stack>
    <Dropzone onAddFiles={onAdd} fileType={fileType} multiple={multiple} />

    <FileList files={files} onDeleteFile={onRemove} onSelectFile={onSelect} />
  </Stack>
);
export default FileUpload;
