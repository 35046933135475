import {
  FontIcon,
  getTheme,
  mergeStyles,
  Spinner,
  TooltipDelay,
} from '@fluentui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import {
  components,
  GroupTypeBase,
  MenuListComponentProps,
  OptionTypeBase,
  SingleValueProps,
} from 'react-select';
import VirtualList from 'react-tiny-virtual-list';
import { ItemSizeGetter } from 'react-tiny-virtual-list/types/SizeAndPositionManager';
import cn from 'classnames';
import { baseColors } from '../../../theme';
import TooltipHost from '../TooltipHost';
import { OptionStatus } from './OptionStatus';
import { classNames } from './styles';

export const customStyles = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    boxShadow: 'none',
    borderColor: isFocused ? baseColors.midOrange : baseColors.outlineGray,
    minHeight: 32,
  }),
  option: (styles, { isSelected, isDisabled, data }) => ({
    ...styles,
    padding: data?.subLabel ? '5px 12px' : styles.padding,
    backgroundColor: isSelected && !isDisabled ? baseColors.midGrey : 'inherit',
    color: isDisabled ? baseColors.outlineGray : baseColors.textColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    ':hover': {
      color: isDisabled ? baseColors.outlineGray : baseColors.textColor,
      backgroundColor: isSelected
        ? baseColors.hover
        : isDisabled
        ? 'inherit'
        : baseColors.selectedWithHover,
    },
    [`& .${classNames.sublabel}`]: {
      display: 'block',
    },
  }),
  groupHeading: (base) => ({
    ...base,
    fontWeight: 600,
    color: baseColors.midOrange,
  }),
  group: (base) => ({
    ...base,
    borderBottom: `1px solid ${baseColors.outlineGray}`,
    '&:last-child': {
      borderBottom: 0,
    },
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0px 2px 13px rgba(0, 0, 0,.08), 0px 2px 1px rgba(0, 0, 0,.05)',
    zIndex: 12,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    fontSize: 12,
    padding: '5px 8px',
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: 12,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '1px 8px 2px',
  }),
  clearIndicator: (base) => ({
    ...base,
    fontSize: 12,
    padding: '5px 8px',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: baseColors.textColor,
    ':hover': {
      backgroundColor: baseColors.darkGrey,
      cursor: 'pointer',
    },
  }),
};

export const getCustomTheme = (theme) => {
  const fluentTheme = getTheme();

  return {
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 24,
    },
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary75: fluentTheme.palette.themePrimary,
      primary50: fluentTheme.palette.themeLighter,
      primary25: fluentTheme.palette.themeLighterAlt,
      primary: fluentTheme.palette.themePrimary,
      neutral20: fluentTheme.semanticColors.inputBorder,
    },
  };
};

export const LoadingIndicator = () => <Spinner />;

export const labelStyle = {
  fontSize: '12px',
  padding: '0 0 5px',
  fontWeight: 600,
  margin: 0,
};

export const ControlComponent = (props) => {
  const {
    selectProps: { label },
  } = props;

  return (
    <div>
      {label && <p style={labelStyle}>{label}</p>}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Control {...props} />
    </div>
  );
};

export const IndicatorSeparator = () => null;

export const iconClass = mergeStyles({
  color: baseColors.iconColor,
  fontSize: 16,
});

export const DropdownIndicator = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <FontIcon iconName="ChevronDown" className={iconClass} />
  </components.DropdownIndicator>
);

export const Option = ({ children, ...props }) => {
  // removing those event listeners improves performance
  // we do already have a tooltip, which handles user interactions
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return (
    <TooltipHost content={children} delay={TooltipDelay.long}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...(newProps as any)}>
        {children}
        <OptionStatus
          status={props?.data?.data?.status}
          typeId={props?.data?.data?.$typeId}
        />
        {props?.data?.subLabel && (
          <span
            className={cn([
              classNames.sublabel,
              { disabled: props?.data?.isDisabled },
            ])}
          >
            {props?.data?.subLabel}
          </span>
        )}
      </components.Option>
    </TooltipHost>
  );
};

const DefaultItemHeight = 40;
const WithSubLabelHeight = 43;
export const MenuListVirtualized: React.ComponentType<
  MenuListComponentProps<any, boolean, GroupTypeBase<OptionTypeBase>>
> = (props) => {
  const { options, children, maxHeight, getValue } = props;

  const renderItem = (props) => {
    if (Array.isArray(children)) {
      return (
        <li style={props.style} key={props.index}>
          {children[props.index]}
        </li>
      );
    }
    return (
      <li key={props.index} className="react-virtualized-menu-placeholder">
        <Trans i18nKey="filters:noEntries" />
      </li>
    );
  };

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * DefaultItemHeight;
  const childrenOptions = React.Children.toArray(children);
  const wrapperHeight =
    maxHeight < childrenOptions.length * DefaultItemHeight
      ? maxHeight
      : childrenOptions.length * DefaultItemHeight;

  const getItemSize: ItemSizeGetter = (index) =>
    children[index]?.props?.data?.subLabel
      ? WithSubLabelHeight
      : DefaultItemHeight;

  return (
    <span className="react-virtualized-list-wrapper">
      <VirtualList
        width="100%"
        height={wrapperHeight + 6}
        scrollOffset={initialOffset}
        itemCount={childrenOptions.length}
        itemSize={getItemSize}
        renderItem={renderItem}
      />
    </span>
  );
};

export const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
  <components.SingleValue {...props}>
    {children}
    {props?.data?.subLabel && (
      <span className={classNames.sublabel}> ({props?.data?.subLabel})</span>
    )}
  </components.SingleValue>
);

export const styledDropdownComponents = {
  Control: ControlComponent,
  IndicatorSeparator,
  DropdownIndicator,
  LoadingIndicator,
  Option,
};
