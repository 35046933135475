import * as effects from 'redux-saga/effects';
import { PARENT_DRILLING, DRILLING_DIRECTIONS, SECTION_TYPES } from '../../GroupTable/constants';
import { DrillingDirection, SectionType } from '../../GroupTable/types';
import { getGroupTableTitles, getTableHeaderLength } from '../../GroupTable/utils';
import { IDataset } from '../../../types/IDataset';
import {
  columnLettersToNumber, getCellColumnRowPair, nestedSerializeObjectKeys,
} from '../../../actions/excel-actions/utils';
import { getGroupTable, getMaxDrillingLevel } from '../../GroupTable/selectors';
import { setDrillableSelection } from '../../GroupTable/actions';
import { getTableAddress } from '../../../actions/excel-actions/getTableAddress';

/**
 * Drillable selections are the column and row headers in a group table,
 * in other words, its clickable areas. This function lets you know if the
 * passed range from a dataset belongs to a drillable selection.
 * @param rangeAddress Address of the range to be checked.
 * @param worksheetId The worksheetId of the range to be checked.
 */

export function* checkIsDrillableSelection(
  rangeAddress: string,
  dataset: IDataset,
) {
  const groupTable = getGroupTable(dataset.id)(yield effects.select());
  const [eventColumn, eventRow] = getCellColumnRowPair(rangeAddress);

  let rowsKeys : string[][];
  let columnKeys: string[][];
  let drillingDirection: DrillingDirection = null;
  let sectionType: SectionType;
  const valuesLength = groupTable.values.length;
  const tableHeaderSize = getTableHeaderLength(groupTable);

  const address = yield effects.call(getTableAddress, dataset.tableId);

  const [addressColumn, addressRow] = getCellColumnRowPair(address);

  const { columnTitles, rowTitles } = getGroupTableTitles(groupTable.data);

  if (addressColumn === eventColumn
  && +eventRow >= +addressRow + tableHeaderSize) {
    rowsKeys = nestedSerializeObjectKeys(rowTitles, dataset.options.rowParentDrilling === PARENT_DRILLING.after);
    // - 1 because excel row indexes start at 1
    if (+eventRow <= rowsKeys.length + (+addressRow - 1) + tableHeaderSize) {
      drillingDirection = DRILLING_DIRECTIONS.rowDrilling;
      sectionType = SECTION_TYPES.rows;
    }
  } else if (+eventRow === +addressRow
    && columnLettersToNumber(eventColumn) > columnLettersToNumber(addressColumn)
  ) {
    columnKeys = nestedSerializeObjectKeys(columnTitles, dataset.options.columnParentDrilling === PARENT_DRILLING.after);
    if (columnLettersToNumber(eventColumn) <= (Math.max(1, columnKeys.length) * valuesLength) + columnLettersToNumber(addressColumn)) {
      drillingDirection = DRILLING_DIRECTIONS.columnDrilling;
      sectionType = SECTION_TYPES.columns;
    }
  }

  if (!drillingDirection || !groupTable[sectionType]?.length) {
    yield effects.put(setDrillableSelection(null));
    return;
  }

  let keys : string[] = [];
  let index: number;
  let childrenTitles: any;
  const maxDrillingLevel = getMaxDrillingLevel(dataset.id, drillingDirection)(yield effects.select());

  if (drillingDirection === DRILLING_DIRECTIONS.rowDrilling) {
    index = +eventRow - (+addressRow + tableHeaderSize);
    keys = rowsKeys[index];
    childrenTitles = keys?.reduce((titles, key) => titles?.[key] || {}, rowTitles);
  }

  if (drillingDirection === DRILLING_DIRECTIONS.columnDrilling) {
    index = Math.floor((columnLettersToNumber(eventColumn) - columnLettersToNumber(addressColumn) - 1) / valuesLength);
    keys = columnKeys[index];
    childrenTitles = keys?.reduce((titles, key) => titles?.[key] || {}, columnTitles);
  }

  const isDrilledDown = !!Object.keys(childrenTitles || {}).length;

  const canDrillDown = keys?.length < maxDrillingLevel && !isDrilledDown;
  const canDrillUp = keys?.length > 1 || isDrilledDown;

  yield effects.put(setDrillableSelection({
    keys, index, isDrilledDown, dataset, drillingDirection, canDrillUp, canDrillDown,
  }));
}
