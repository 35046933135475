import { DrillingDirection, ParentDrilling, SectionType } from './types';

export const SECTION_TYPES: Record<SectionType, SectionType> = {
  rows: 'rows',
  columns: 'columns',
  values: 'values',
};

export const DRILLING_DIRECTIONS: Record<DrillingDirection, DrillingDirection> =
  {
    columnDrilling: 'columnDrilling',
    rowDrilling: 'rowDrilling',
  };

export const PARENT_DRILLING: Record<ParentDrilling, ParentDrilling> = {
  after: 'after',
  before: 'before',
};

export const ItemTypes = {
  GROUP_TABLE_ITEM: 'GROUP_TABLE_ITEM',
};

export const MAX_CELLS_TO_RENDER_AT_ONCE = 5000;

export const MIN_DRILLING_LEVELS: Record<DrillingDirection, number> = {
  columnDrilling: 0,
  rowDrilling: 1,
};

export const MIN_COLUMN_WIDTH = 80;
export const MAX_COLUMN_WIDTH = 300;
