import { routes } from '../../constants/routes';
import { constant } from '../../../shared/types/utils';

export const ROUTES_WITH_SEARCH: readonly string[] = constant<string[]>()([
  routes.streams.list.index,
  routes.streams.filters.index,
  routes.streams.values.index,
  routes.streams.values.unselected,
  routes.favorites.index,
  routes.favorites.sharedWithMe.index,
  routes.favorites.recentStreams.index,
  routes.streams.design.index,

  /// builder
  routes.builder.streams.index,
  routes.builder.assets.sources,
  routes.builder.assets.data,
  routes.builder.assets.preview,
  routes.builder.assets.calculations,
  routes.builder.assets.groups,
  routes.builder.assets.hubs,
  routes.builder.assets.filters.index,
  routes.builder.assets.links,

  // designer
  routes.designer.ingestion.sources,
  routes.designer.ingestion.data,
  routes.designer.ingestion.filters.logic.index,
  routes.designer.catalog.links,
  routes.designer.catalog.sources,
  routes.designer.catalog.data,
  routes.designer.catalog.groups,
  routes.designer.catalog.hubs,
  routes.designer.catalog.calculations,
  routes.designer.metadata.tags,
  routes.designer.metadata.catalogDefinition,
  routes.designer.contentLibrary.designs,
  routes.designer.catalog.machineLearning,

  // NEW STREAMER
  routes.streamer.streamSelecting.pickStream,
  routes.streamer.dataMode.selectAssets,
  routes.streamer.dataMode.filters.index,
]);
