import { chain } from 'lodash';
import React from 'react';
import { DataSource } from '../../../../api/model/schemas/DataSource';
import { DataSourceEntity } from '../../../../api/model/schemas/DataSourceEntity';
import { DataSourceEntityType } from '../../../../api/model/schemas/DataSourceEntityType';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { fetchEntities } from '../../../Designer/api';
import { ApiV4ResponseWrapper } from '../../../Designer/types';

export type GroupOption = {
  label: string;
  options: DropdownOption[];
};

export type DropdownOption = {
  value: string;
  label: string;
  data: DataSourceEntity;
};

type ExpandedEntity = DataSourceEntity & {
  parent: DataSource;
};

interface GenericConnectorConfiguration {
  [key: string]: any;
  parentId: string;
}

type PickEntityParams = {
  designId: string;
  selectedEntityId: string;
  connectorTypeId: string;
};

const usePickEntity = ({
  designId,
  selectedEntityId,
  connectorTypeId,
}: PickEntityParams) => {
  const [expandedEntities, setExpandedEntities] = React.useState<
    ExpandedEntity[]
  >([]);
  const [isFetchingEntities, setFetchingEntities] =
    React.useState<boolean>(false);

  const fetchExpandedEntities = React.useCallback(async () => {
    setFetchingEntities(true);
    setExpandedEntities([]);

    try {
      const {
        data: { data: connectors },
      } = await fetchEntities<
        ApiV4ResponseWrapper<GenericConnectorConfiguration[]>
      >(connectorTypeId)({
        filter: `parent/parentId eq ${designId}`,
      });

      const sourceIds = connectors.map((c) => c.parentId);

      if (sourceIds.length > 0) {
        const {
          data: { data: entities },
        } = await fetchEntities<ApiV4ResponseWrapper<ExpandedEntity[]>>(
          TYPE_IDS.SourceEntity
        )({
          filter: `parent/parentId eq ${designId} and type eq '${
            DataSourceEntityType.StreamOutput
          }' and parentId in (${sourceIds.join(',')})`,
          expand: 'parent',
        });

        setExpandedEntities(entities);
      }
    } finally {
      setFetchingEntities(false);
    }
  }, [setFetchingEntities, connectorTypeId]);

  // EFFECTS

  React.useEffect(() => {
    if (designId) {
      fetchExpandedEntities();
    }
  }, [designId, connectorTypeId]);

  // DERIVED STATE
  const dropdownOptions: GroupOption[] = React.useMemo(
    () =>
      chain(expandedEntities)
        .groupBy('parent.name')
        .map((entities, parentName) => ({
          label: parentName,
          options: entities.map((entity) => ({
            label: entity.name,
            value: entity.id,
            data: entity,
          })),
        }))
        .value(),
    [expandedEntities, connectorTypeId]
  );

  const dataSetColor = React.useMemo(
    () =>
      expandedEntities.find((e) => e.id === selectedEntityId)?.parent?.color,
    [selectedEntityId]
  );

  return {
    isFetchingEntities,
    dropdownOptions,
    dataSetColor,
  };
};

export default usePickEntity;
