import React from 'react';
import Icon from '@mdi/react';
import * as mdiJs from '@mdi/js';
import { Spinner } from 'office-ui-fabric-react';
import cn from 'classnames';
import { TooltipHost } from '../ui';

import { classNames, spinnerStyles, tooltipStyles } from './styles';
import { MAX_LABEL_TITLE_LENGTH } from './constants';

interface Props {
  handleClick: () => void;
  handleDoubleClick?: () => void;
  isSelected?: boolean;
  isSyncing?: boolean;
  hasError?: boolean;
  label: string;
  id: string;
  iconName: string;
  testId?: string;
}

const LargeImageThumbnail: React.FC<Props> = ({
  handleClick,
  hasError = false,
  handleDoubleClick,
  iconName,
  // id,
  isSelected,
  isSyncing,
  label,
  testId,
}) => (
  <div
    role="presentation"
    className={cn(classNames.optionWrapper, {
      [classNames.optionWrapperChecked]: isSelected,
      [classNames.optionWrapperHasError]: hasError,
      [classNames.optionWrapperSyncing]: isSyncing,
    })}
    data-testid={testId}
    onClick={isSyncing ? undefined : handleClick}
    onDoubleClick={isSyncing ? undefined : handleDoubleClick}
    data-is-selected={isSelected}
    data-is-syncing={isSyncing}
  >
    <TooltipHost
      // we want to show tooltip only for longer labels
      content={label.length >= MAX_LABEL_TITLE_LENGTH ? label : undefined}
      calloutProps={{ gapSpace: 0, setInitialFocus: false }}
      styles={tooltipStyles}
    >
      <div
        className={cn(classNames.iconWrapper, {
          [classNames.contentSyncing]: isSyncing,
        })}
      >
        <Icon
          path={
            Object.keys(mdiJs).includes(iconName)
              ? mdiJs[iconName]
              : mdiJs.mdiDatabaseOutline
          }
          className={cn(classNames.icon, {
            [classNames.contentError]: hasError,
          })}
        />
        <p
          className={cn(classNames.label, {
            [classNames.contentError]: hasError,
          })}
        >
          {label}
        </p>
      </div>
      {isSyncing && <Spinner styles={spinnerStyles} />}
    </TooltipHost>
  </div>
);

export default LargeImageThumbnail;
