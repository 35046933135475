import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useEffect } from 'react';
import { useFormContext, Validate } from 'react-hook-form';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { IGenericFormField } from '../types';
import { validateField } from '../utils';

const useWizardFieldValidation = ({
  currentForm,
  entityProperty,
  inputName,
  currentEntity,
}: IGenericFormField) => {
  const { watch, trigger } = useFormContext();
  const design = watch('design');

  // When in the stream wizard, the parentId is dynamic, which is the
  // designId selected within the form.

  const isStream = currentForm.typeId === TYPE_IDS.Stream;
  const SubjectParentId = isStream
    ? design
    : (currentEntity?.parentId || currentForm?.parentEntityId);

  const requestFieldValidation = AwesomeDebouncePromise(async (value) => validateField({
    validationTypeId: entityProperty.validationTypeId,
    subjectTypeId: currentForm?.typeId,
    subjectId: currentEntity?.id,
    SubjectParentId,
    value,
  }), 300);

  useEffect(() => {
    // react-form-hook errors object is empty on mounting
    // unless we manually trigger validation
    if (entityProperty?.validationTypeId) {
      trigger(inputName);
    }
  }, []);

  const validate: Validate | Record<string, Validate> = (
    // eslint-disable-next-line no-nested-ternary
    !entityProperty?.validationTypeId
      ? undefined
      : isStream && !SubjectParentId
        ? undefined
        : requestFieldValidation
  );

  return validate;
};

export default useWizardFieldValidation;
