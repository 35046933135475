import { Label, Spinner, Stack } from 'office-ui-fabric-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { DataSourceEntity } from '../../../api/model/schemas/DataSourceEntity';
import { IconButtonProps } from '../../../components/IconButton';
import { SelectDropdown } from '../../../components/ui';
import i18n from '../../../config/i18n';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import WizardPage, { WizardType } from '../../../pageTemplates/WizardPage';

import PickEntity from '../components/StreamToDatabase/PickEntity';
import { Destination } from '../ExportWizard/types';
import { textStyles } from './styles';
import { useLoadConfiguration } from './useLoadConfiguration';

interface Option {
  value: string;
  label: string;
}

const destinations: Option[] = [
  {
    label: i18n.t(`exportWizard:${Destination.Anaplan}`),
    value: TYPE_IDS.AnaplanConfiguration,
  },
  {
    label: i18n.t(`exportWizard:${Destination.Database}`),
    value: TYPE_IDS.AzureSqlServerConfiguration,
  },
];

export const ImportWizard = () => {
  const { t } = useTranslation();
  const location = useLocation<{
    designId: string;
  }>();
  const history = useHistory();

  const designId = location?.state?.designId;

  const [selectedEntity, setSelectedEntity] =
    React.useState<DataSourceEntity | null>(null);
  const [destination, setDestination] = React.useState<Option | undefined>(
    undefined
  );

  const {
    loadConfigurationAsSelection,
    canLoadSelection,
    selectedAssetsCount,
    selectedFiltersCount,
    isLoading,
  } = useLoadConfiguration({
    configurationId: selectedEntity?.configurationId,
    configurationTypeId: selectedEntity?.$configurationTypeId,
  });

  const foundMessageKey =
    selectedAssetsCount && selectedFiltersCount
      ? 'importStreamerWizard:foundAssetsWithFilters'
      : 'importStreamerWizard:foundAssets';

  // // CALLBACKS
  const handleCancel = React.useCallback(() => history.go(-1), [history]);
  const handleDestinationChange = React.useCallback((option) => {
    setDestination(option);
    setSelectedEntity(null);
  }, []);

  // PARTS
  const buttonsConfig: IconButtonProps[] = [
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.accept" />,
      onClick: loadConfigurationAsSelection,
      disabled: !canLoadSelection,
      iconProps: { iconName: 'CheckMark' },
      shouldHideOnDisabled: true,
      'data-testid': 'confirm-export-button',
    },
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.cancelAction" />,
      onClick: handleCancel,
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'cancel-export-button',
    },
  ];

  // PARTS
  const statusTexts = () => {
    if (!selectedEntity) return null;

    if (isLoading) return <Spinner style={textStyles} />;

    return selectedAssetsCount ? (
      <p style={textStyles}>
        {t(foundMessageKey, { selectedAssetsCount, selectedFiltersCount })}
      </p>
    ) : (
      <p style={textStyles}>{t('importStreamerWizard:notFound')}</p>
    );
  };

  // RENDER
  return (
    <WizardPage
      wizardType={WizardType.Import}
      headerBarButtonsConfig={buttonsConfig}
      pageTitle={t('importStreamerWizard:pageTitle')}
      entityName={t('importStreamerWizard:Import')}
    >
      <div>
        <Stack>
          <Label>{t('importStreamerWizard:Destination')}</Label>
          <SelectDropdown
            maxMenuHeight={600}
            options={destinations}
            placeholder={t('filters:Select')}
            closeMenuOnSelect
            onChange={handleDestinationChange}
            value={destination}
          />
        </Stack>
        {destination?.value && (
          <Stack>
            <Label>{t('importStreamerWizard:Entity')}</Label>
            <PickEntity
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              designId={designId}
              connectorTypeId={destination.value}
            />
          </Stack>
        )}
        {statusTexts()}
      </div>
    </WizardPage>
  );
};
