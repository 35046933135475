import { useEffect, useState } from 'react';
import { StreamOutput } from '../../../../api/model/schemas/StreamOutput';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { fetchNestedEntities } from '../../../Designer/api';
import { ApiV4ResponseWrapper } from '../../../Designer/types';

interface UseStreamOutput {
  streamId: string;
}

export const useStreamOutput = ({ streamId }: UseStreamOutput) => {
  const [isLoading, setLoading] = useState(false);
  const [streamOutputData, setData] = useState<StreamOutput>(null);

  const fetchStreamOutput = async () => {
    setLoading(true);
    const {
      data: { data: streamOutputs },
    } = await fetchNestedEntities<ApiV4ResponseWrapper<StreamOutput[]>>({
      typeId: TYPE_IDS.StreamOutput,
    })(streamId)();

    // right now we only support 1 output
    setData(streamOutputs[0]);
    setLoading(false);
  };

  useEffect(() => {
    fetchStreamOutput();
  }, [streamId]);

  return {
    streamOutputData,
    isLoading,
  };
};
