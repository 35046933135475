import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';
import {
  toggleSearchBar, clearSearchBar, setSearchQuery, setIsSearchBarOpen,
} from '../../actions';
import { ROUTES_WITH_SEARCH } from '../../constants';

import { getSearchState } from '../../selectors';
import { HeaderBarButton } from '../../../../components/ui';

interface SearchState {
  isOpen: boolean;
  searchQuery: string;
}

const SearchIconButton:React.FC = () => {
  const [searchState, setSearchState] = useState<{[key: string]: SearchState}>({});
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isSearchBarOpen, searchQuery } = useSelector(getSearchState);

  useEffect(() => {
    const newState = { ...searchState };

    Object.keys(newState).map((key) => {
      if (newState[key].searchQuery === '') {
        newState[key].isOpen = isSearchBarOpen;
      }

      return null;
    });

    setSearchState({
      ...newState,
      [pathname]: {
        isOpen: isSearchBarOpen,
        searchQuery,
      },
    });
  }, [pathname, isSearchBarOpen, searchQuery]);

  useEffect(() => {
    const st = searchState[pathname];

    if (st) {
      dispatch(setIsSearchBarOpen(st.isOpen));
    }

    dispatch(setSearchQuery(st?.searchQuery || ''));
  }, [pathname]);

  useEffect(() => {
    if (isSearchBarOpen && !ROUTES_WITH_SEARCH.includes(pathname)) {
      dispatch(clearSearchBar());
    }
  }, [pathname]);

  return (
    <Route path={ROUTES_WITH_SEARCH as string[]} exact>
      { !isSearchBarOpen && (
        <HeaderBarButton
          data-testid="toggle-searchbox-icon"
          tooltip={<Trans t={t} i18nKey="actionBar:general.search" />}
          iconProps={{
            iconName: 'Search',
          }}
          onClick={() => dispatch(toggleSearchBar())}
        />
      ) }
    </Route>
  );
};

export default SearchIconButton;
