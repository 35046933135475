import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EntityField from '../../../../components/EntityField';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';
import { useSearchFilteredRecords } from '../../../../hooks/useSearchFilteredRecords';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { DefinedSortTypes } from '../../../App/types';
import { assetClicked, fetchStreamAssets } from '../../actions';
import {
  createGetIsAssetQueued,
  createGetIsAssetSelected,
  getAvailableAssets,
  getSelectedAssetsIds,
  getSelectedStreamId,
} from '../../selectors';
import { StreamAssetRaw } from '../../types';
import { rawAssetToStreamerAsset } from '../../utils';
import { SelectAssetsGroupCollapse } from './SelectAssetsGroupCollapse';
import { SelectStreamerAggregation } from './SelectStreamerAggregation';

export const SelectAssets = () => {
  const dispatch = useDispatch();

  // STATE
  const selectedStreamId = useSelector(getSelectedStreamId);
  const { isLoading, records: assets } = useSelector(getAvailableAssets);
  const getIsAssetSelected = useSelector(createGetIsAssetSelected);
  const selectedEntitiesIds = useSelector(getSelectedAssetsIds);
  const getIsAssetQueued = useSelector(createGetIsAssetQueued);

  // DERIVED STATE

  const normalizedAssets = normalizeById(assets);
  const { filteredRecords, searchQuery } = useSearchFilteredRecords(assets);

  const columnsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds,
  });

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: columnsWhichIncludesSelections,
    });

  // EFFECTS
  React.useEffect(() => {
    if (selectedStreamId) {
      dispatch(fetchStreamAssets(selectedStreamId));
    }
  }, [selectedStreamId]);

  // PARTS

  const renderAsset = (asset: StreamAssetRaw) => (
    <EntityField
      slot1={<SelectStreamerAggregation asset={asset} />}
      key={asset.id}
      name={asset.name}
      onClick={() => dispatch(assetClicked(rawAssetToStreamerAsset(asset)))}
      isSelectable
      isSelected={getIsAssetSelected(asset)}
      isOrange={getIsAssetQueued(asset)}
      searchQuery={searchQuery}
      data-testid={`column-${asset.name.toLowerCase()}${
        getIsAssetSelected(asset) ? '-selected' : ''
      }`}
    />
  );

  if (isLoading) return <ItemsListShimmer />;

  if (currentSort !== DefinedSortTypes.Name) {
    return (
      <>
        {mappedGroups.map((group) => (
          <SelectAssetsGroupCollapse
            {...{ normalizedAssets, group, searchQuery }}
            key={group.key}
            onIconClick={onClickHeaderHandler}
          >
            {group.items.map((item) => {
              const relatedItem = normalizedAssets[item.key];

              return relatedItem ? renderAsset(relatedItem) : null;
            })}
          </SelectAssetsGroupCollapse>
        ))}
      </>
    );
  }

  return (
    <section>
      {columnsWhichIncludesSelections
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(renderAsset)}
    </section>
  );
};
