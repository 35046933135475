import { Filter } from '../../../api/model/schemas/Filter';
import { FilterType } from '../../../api/model/schemas/FilterType';
import { GroupingFilterType } from '../../../api/model/schemas/GroupingFilterType';
import { StreamDataRequestElement } from '../../../api/model/schemas/StreamDataRequestElement';
import { compileStreamerFilterToRequest } from '../Filters/utils';
import { generateSortOrder } from '../Layout/components/ReorderDragAndDrop/utils';
import {
  StreamerAsset,
  StreamDataRequestParams,
  StreamerFilter,
} from '../types';

const generatePseudoUuid = (arrIndex: number) => {
  const BASE_PSEUDO_UUID = '00000000-0000-0000-0000-000000000000';

  return `${BASE_PSEUDO_UUID.slice(
    0,
    BASE_PSEUDO_UUID.length - String(arrIndex).length
  )}${arrIndex}`;
};

const isRequestElementMatchingFilter = (
  data: StreamDataRequestElement,
  filter: StreamerFilter
) =>
  data.streamElementId === filter?.item?.id &&
  data.aggregation === filter.aggregation;

const streamerFiltersToRequestFilter = (
  filters: StreamerFilter[],
  requestData: StreamDataRequestParams['data']
): Filter => {
  if (!filters || filters.length === 0) return undefined;

  return {
    type: FilterType.Group,
    groupFilterType: GroupingFilterType.And,
    groupFilters: filters.map((filter) => ({
      groupFilterType: GroupingFilterType.And,
      type: FilterType.Field,
      fieldId: requestData.find((dataElement) =>
        isRequestElementMatchingFilter(dataElement, filter)
      )?.id,
      fieldFilters: [compileStreamerFilterToRequest(filter)],
    })),
  };
};

export const selectedAssetsToDataRequestParams = (
  assets: StreamerAsset[],
  filters: StreamerFilter[]
): StreamDataRequestParams => {
  const data: StreamDataRequestParams['data'] = assets.map((a, index) => ({
    // we need an unique uuid-like id of a requested column,
    // this id cannot change while we are requesting continuation based on a token
    id: generatePseudoUuid(index + 1),
    streamElementId: a.streamElementId,
    streamElementTypeId: a.streamElementTypeId,
    aggregation: a.aggregation,
    sortMode: a.sortMode,
    sortPosition: a?.sortPosition,
  }));

  const columns: StreamDataRequestParams['columns'] = data.map(
    (item, itemIndex, items) => ({
      id: item.id,
      sortMode: item.sortMode,
      sortPosition:
        item.sortPosition ??
        generateSortOrder({
          assetIndex: itemIndex,
          assetLength: items.length,
        }),
    })
  );

  filters.forEach((filter, index) => {
    const columnExists = data.some((dataElement) =>
      isRequestElementMatchingFilter(dataElement, filter)
    );
    const item = filter?.item;

    if (!columnExists && item) {
      // filter might be added on an asset that will not be included in the final results
      data.push({
        id: generatePseudoUuid(data.length + index + 1),
        streamElementId: item.id,
        streamElementTypeId: item.$typeId,
        aggregation: filter?.aggregation,
      });
    }
  });

  const filter = streamerFiltersToRequestFilter(filters, data);

  return {
    // Data holds the set of data that the query should work with. It allows you to set filters etc.
    // Columns is what should be visible to the user and is a subset of Data
    // when we iterate over filters, we are adding extra columns to the data, which might be not visible to the user
    columns,
    data,
    filter,
  };
};
