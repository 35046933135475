import { AxiosResponse } from "axios";
import { translateApiName } from "../../../../config/i18n/utils";
import { ApiV4ResponseWrapper } from "../../../../modules/Designer/types";
import { TypeIds } from "../../../model/schemas";
import { TransformConfigurationType } from "../../../model/schemas/TransformConfigurationType";
import { i18nNameKey } from "../constants";
import { isResponseTypeIdMatching } from "../utils";

export const isTransformConfigurationTypesResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<ApiV4ResponseWrapper<TransformConfigurationType[]>> =>
  isResponseTypeIdMatching(response, TypeIds.TransformConfigurationType)
    && Array.isArray((response.data as any)?.data)

export const parseTransformConfigurationType = (entity: TransformConfigurationType) => {
  return {
    ...entity,
    // id of the transform configuration type is actually the type id used as the context for translating the name
    [i18nNameKey]: translateApiName(entity.id, entity.name),
  };
};
