import {
  ITooltipStyles, ITheme,
} from '@fluentui/react';

export const tooltipStyles = (theme:ITheme):Partial<ITooltipStyles> => ({
  root: {
    selectors: {
      '.ms-Callout-beak': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-beakCurtain': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-main': {
        backgroundColor: theme.palette.black,
      },
    },
  },
  content: {
    backgroundColor: theme.palette.black,
    color: theme.palette.themePrimary,
    fontWeight: 'bold',
    selectors: {
      b: {
        color: theme.palette.themePrimary,
      },
      // FIXME: this just nasty :D and using html tags inside translations doesn't seem like a good idea to me :thinking:
      strong: {
        color: theme.palette.themePrimary,
      },
    },
  },
});
