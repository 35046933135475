import { IButtonStyles } from '@fluentui/react';
import { baseColors } from '../../../theme';

export const buttonClassName = 'color-picker-button-class';

export const buttonStyles = (selectedColor: string): IButtonStyles => ({
  root: {
    width: 24,
    minWidth: 24,
    height: 24,
    padding: 0,
    border: `1px solid ${baseColors.inputBorder}`,

    selectors: {
      span: {
        backgroundColor: `#${selectedColor}`,
        border: `3px solid ${baseColors.white}`,
        boxSizing: 'border-box',
      },
    },
  },
});
