import React, { FunctionComponent } from 'react';
import { colorIconStyles } from './styles';

interface DataSourceIconProps {
  color: string;
  size?: number;
}

const DataSourceIcon: FunctionComponent<DataSourceIconProps> = ({
  color, size = 16,
}) => (
  <div style={colorIconStyles(color, size)} />
);

export default DataSourceIcon;
