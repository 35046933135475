import { AggregationType } from '../../../../../api/model/schemas/AggregationType';
import { DataSourceEntityField } from '../../../../../api/model/schemas/DataSourceEntityField';
import { DataType } from '../../../../../api/model/schemas/DataType';
import { ProcessStatus } from '../../../../../api/model/schemas/ProcessStatus';
import {
  DATA_TYPE_CHANGES_OPTIONS,
  allDataTypeOptionsInIngestion,
  NEXT_INGESTION_STATE_MAP,
} from './constants';

export const isFieldOrange = (ingestionStatus: ProcessStatus) =>
  ingestionStatus === ProcessStatus.PendingProcess ||
  ingestionStatus === ProcessStatus.PendingUnprocess;
export const isFieldSelected = (ingestionStatus) =>
  ingestionStatus === ProcessStatus.Processed ||
  ingestionStatus === ProcessStatus.PendingProcess;

export const getAvailableDataTypes = (dataType: DataType) => {
  const availableDataTypes = [dataType, ...DATA_TYPE_CHANGES_OPTIONS[dataType]];

  return allDataTypeOptionsInIngestion()
    .filter((entry) => availableDataTypes.includes(entry.key as DataType))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getIsDataTypeOrange = (field: DataSourceEntityField) => {
  if (field.ingestionStatus === ProcessStatus.Processed) {
    return field.dataType !== field.ingestedDataType;
  }
  if (
    field.ingestionStatus === ProcessStatus.PendingProcess ||
    field.ingestionStatus === ProcessStatus.NotProcessed
  ) {
    return field.dataType !== field.sourceDataType;
  }
  return false;
};

export const isAggregationOrange = (field: DataSourceEntityField) => {
  if (field.ingestionStatus === ProcessStatus.PendingProcess) {
    return field.aggregationType !== AggregationType.None;
  }

  return field.aggregationType !== field.ingestedAggregationType;
};

export const getNextIngestionState = (currentState: ProcessStatus) => {
  return NEXT_INGESTION_STATE_MAP[currentState] ?? currentState;
};
