import { useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { fetchStreamFields } from '../actions';
import { getStreamFields } from '../selectors';

const useSelectedSources = (streamId: string) => {
  const dispatch = useDispatch();

  const {
    records: streamFields,
    entityFields,
    entities,
    isLoading,
  } = useSelector(getStreamFields);

  const fetchCallback = useCallback(
    () => dispatch(fetchStreamFields({
      streamId,
      deepFetch: true,
    })),
    [],
  );

  useEffect(() => {
    fetchCallback();
  }, []);

  const streamFieldSourceFieldIds = useMemo(
    () => streamFields
      .filter((f) => f.$sourceFieldTypeId === TYPE_IDS.SourceEntityField)
      .map((f) => f.sourceFieldId),
    [streamFields],
  );

  const entityFieldIds = useMemo(
    () => entityFields
      .filter((f) => streamFieldSourceFieldIds.includes(f.id))
      .map((f) => f.parentId),
    [streamFieldSourceFieldIds, entityFields],
  );

  const selectedSourceIds = useMemo(
    () => uniq(entities
      .filter((f) => entityFieldIds.includes(f.id))
      .map((f) => f.parentId)),
    [entityFieldIds, entities],
  );

  const getStreamFieldsBySource = (sourceId: string) => {
    const sourceEntityIds = entities
      .filter((e) => e.parentId === sourceId).map((e) => e.id);
    const sourceEntityFieldIds = entityFields
      .filter((f) => sourceEntityIds.includes(f.parentId)).map((e) => e.id);
    const sourceStreamFieldIds = streamFields
      .filter((f) => sourceEntityFieldIds.includes(f.sourceFieldId))
      .map((e) => e.id);

    return sourceStreamFieldIds;
  };

  return {
    selectedSourceIds,
    isLoading,
    getStreamFieldsBySource,
    fetchAgain: fetchCallback,
  };
};

export default useSelectedSources;
