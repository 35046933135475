import {
  IButtonStyles,
  ICommandBarStyles,
  IIconStyles,
  mergeStyles,
  getTheme,
  ITooltipStyles,
  IStyle,
} from '@fluentui/react';

import { baseColors, BORDER_RADIUS_IN_PX } from '../../theme';

const theme = getTheme();

export const commandBarStyles: ICommandBarStyles = {
  root: {
    border: `1px solid ${baseColors.outlineGray}`,
    backgroundColor: baseColors.lightGrey,
    paddingLeft: 0,
    height: '32px',
    borderTopLeftRadius: BORDER_RADIUS_IN_PX,
    borderTopRightRadius: BORDER_RADIUS_IN_PX,
    selectors: {
      '.ms-OverflowSet-item:first-child .ms-Button': {
        borderLeft: 0,
      },
      '.ms-Button:active .ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
};

export const buttonItemStyles: IButtonStyles = {
  root: {
    margin: 0,
    background: 'transparent',
    borderLeft: '1px solid transparent',
    borderRight: '1px solid transparent',
    color: theme.palette.themeLighterAlt,
    minWidth: '32px',
    width: '32px',
    boxSizing: 'border-box',
    height: '100%',
    cursor: 'pointer',
  },
  rootHovered: {
    background: baseColors.midGrey,
    selectors: {
      '.ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
};

export const activeItemStyles: IStyle = {
  background: theme.palette.themeLighterAlt,
  borderLeft: `1px solid ${baseColors.outlineGray}`,
  borderRight: `1px solid ${baseColors.outlineGray}`,
  cursor: 'default !important',
  selectors: {
    ':hover': {
      background: theme.palette.themeLighterAlt,
    },
  },
};

export const activeItemClassName = mergeStyles(activeItemStyles);

export const iconItemStyles: IIconStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    height: 16,
    width: 16,
  },
};

export const tooltipStyles: Partial<ITooltipStyles> = {
  root: {
    selectors: {
      '.ms-Callout-beak': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-beakCurtain': {
        backgroundColor: theme.palette.black,
      },
      '.ms-Callout-main': {
        backgroundColor: theme.palette.black,
      },
    },
  },
  content: {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
  },
};
