import { Stack } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import EntityField from '../../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { EmptyMessage, GroupCollapse } from '../../../../../components/ui';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import { getSortType } from '../../../../App/selectors';
import { DefinedSortTypes } from '../../../../App/types';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { fetchCatalogDefinition, toggleCatalogEntrySelection, toggleCatalogPropertySelection } from '../../actions';
import {
  getSortedCatalogDefinition,
  getIsMetadataStateLoading,
  getCatalogEntrySelection,
  getCatalogPropertySelection,
} from '../../selectors';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { getSearchState } from '../../../../Search/selectors';
import { translateApiName } from '../../../../../config/i18n/utils';
import useEditableItems from '../../../../../hooks/useEditableItems';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import { updateEntity } from '../../../Catalog/actions';

const CatalogDefinition = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // STATE
  const designId = useSelector(getSelectedDesignId);
  const catalogEntries = useSelector(getSortedCatalogDefinition);
  const isMetadataStateLoading = useSelector(getIsMetadataStateLoading);
  const currentSort = useSelector(getSortType);
  const catalogEntrySelection = useSelector(getCatalogEntrySelection);
  const catalogPropertySelection = useSelector(getCatalogPropertySelection);
  const { isSearchBarOpen, searchQuery } = useSelector(getSearchState);

  // DERIVED STATE
  const {
    isLoading: arePropertiesLoading,
    properties: infoProperties,
  } = useGetParsedTypeProperties({ designId, typeId: TYPE_IDS.SchemaProperty });

  const schema = getSchemaFromTypeProperties(infoProperties, TYPE_IDS.SchemaProperty);

  const isLoading = isMetadataStateLoading || arePropertiesLoading;

  const searchRegex = React.useMemo(
    () => searchQuery !== '' && new RegExp(searchQuery, 'i'),
    [searchQuery],
  );

  // CALLBACKS
  const onSelectEntity = React.useCallback(
    (entityId: string) => {
      dispatch(toggleCatalogEntrySelection(entityId));
    },
    [dispatch],
  );

  const onSelectProperty = React.useCallback(
    (propertyId: string) => {
      dispatch(toggleCatalogPropertySelection(propertyId));
    },
    [dispatch],
  );

  const handleRename = React.useCallback(
    (entityId: string, newName:string) => {
      dispatch(updateEntity({
        entity: {
          id: entityId,
          name: newName,
        },
        typeId: TYPE_IDS.SchemaProperty,
      }));
    },
    [dispatch],
  );

  const onEditEndCallback = (hasChanged: boolean) => {
    if (hasChanged) {
      dispatch(fetchCatalogDefinition(designId))
    }
  }

  const {
    isEditing,
    onEditEnd,
    onEditStart,
  } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  // EFFECTS
  React.useEffect(() => {
    dispatch(fetchCatalogDefinition(designId));
  }, [dispatch, designId]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
  });

  const groupedEntries = React.useMemo(
    () => (currentSort === DefinedSortTypes.Data
      ? catalogEntries.map((entry) => ({
        ...entry,
        properties: entry.properties.filter((property) => !property.isLocked),
      })).filter((entry) => entry.properties.length)
      : catalogEntries),
    [catalogEntries, currentSort],
  );

  const filteredEntries = React.useMemo(
    () => ((isSearchBarOpen && searchRegex)
      ? groupedEntries.map((entry) => ({
        ...entry,
        properties: entry.properties.filter((property) => searchRegex.test(property.name)),
      })).filter((entry) => entry.properties.length || searchRegex.test(entry.name))
      : groupedEntries),
    [isSearchBarOpen, searchRegex, groupedEntries],
  );

  const translatedAndSortedEntries = React.useMemo(() => filteredEntries
    .map((catalogEntry) => ({
      ...catalogEntry,
      name: catalogEntry.isLocked ? translateApiName(catalogEntry.id, catalogEntry.name) : catalogEntry.name,
      properties: catalogEntry.properties
        .map((property) => ({
          ...property,
          name: property.isLocked ? translateApiName(catalogEntry.id, property.name) : property.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    }))
    .sort((a, b) => a.name.localeCompare(b.name)), [filteredEntries, searchRegex]);

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isLoading && catalogEntries.length === 0) {
    return <EmptyMessage message={t('wizard:noEntityDefined', { entityType: t('entityTypes:Tag', { count: 0 }) })} />;
  }

  return (
    <Stack>
      {translatedAndSortedEntries.map((catalogEntry) => (
        <GroupCollapse
          groupId={catalogEntry.id}
          groupName={catalogEntry.name}
          onClick={() => onSelectEntity(catalogEntry.id)}
          isOpen={catalogEntry.id === catalogEntrySelection || (searchQuery !== '' && isSearchBarOpen)}
          key={catalogEntry.id}
          isSelected={catalogEntry.id === catalogEntrySelection}
          searchQuery={searchQuery}
          isRenderingOnQueryMismatch
        >
          {catalogEntry.properties.map((property) => (
            <EntityField
              info={{
                data: property,
                // FIXME: the mapping is a bit of a dirty workaround, the `DataSchemaMapper` and `EntityField` should
                // probably work more through composition and have less magic when it comes to prefixing translations
                schema: schema.map((schemaEntry) => ({
                  ...schemaEntry,
                  translationPrefix: TYPE_IDS.SchemaProperty,
                  // FIXME: nasty workaround, should become redundant when translations are handled by an axios interceptor
                  valueTranslationPrefix: schemaEntry.key === 'name' ? catalogEntry.id : property.$typeId,
                })),
              }}
              isOrange={!property.isLocked}
              isSelected={property.id === catalogPropertySelection}
              key={property.name}
              // if property is custom we want to enable inline edit
              name={property.isLocked && property.name}
              onClick={() => onSelectProperty(property.id)}
              searchQuery={isSearchBarOpen ? searchQuery : undefined}
              slot1={<EntityFieldSlotSpacer />}
            >
              {!property.isLocked && (
                <EditableFoldableListItem
                  text={property.name}
                  isSelected={property.id === catalogPropertySelection}
                  onEditEnd={onEditEnd}
                  transparentBackground
                  disableEditing={isEditing}
                  searchQuery={searchQuery}
                  onEditStart={onEditStart}
                  onEdit={(newVal) => handleRename(property.id, newVal)}
                />
              )}
            </EntityField>
          ))}
        </GroupCollapse>
      ))}
    </Stack>
  );
};

export default CatalogDefinition;
