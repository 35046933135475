import React from 'react';

import FatalError from '../../screens/FatalError';

interface Props {
  hasError: boolean;
  errorDetails?: string;
}

class ErrorBoundary extends React.Component<{}, Props> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.log('getDerivedStateFromError', JSON.stringify({
      name: error.name, stack: error.stack, message: error.message,
    }));
    return {
      hasError: true,
      errorDetails: JSON.stringify({
        name: error.name, stack: error.stack, message: error.message,
      }),
    };
  }

  // TO DO - include sentry/rollbar call here
  // componentDidCatch(error: Error, errorInfo) {
  // }

  render() {
    const { hasError, errorDetails } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return <FatalError errorDetails={errorDetails} />;
    }

    return children;
  }
}

export default ErrorBoundary;
