import { IDesignerFilter } from '../../../../../types/IDesignerFilter';
import { Json } from '../../types';

export enum SortModes {
  ascending = 'ascending',
  descending = 'descending',
  custom = 'custom',
}

export enum DisplayModes {
  logic = 'logic',
  values = 'values',
}

export interface Group extends Json, Partial<IDesignerFilter> {
  name: string;
}
