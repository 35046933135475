import { TypeIds } from '.';
import { DataType } from './DataType';
import { Availability } from './Availability';
import { AggregationType } from './AggregationType';
import { StreamReferenceProcessStatus } from './StreamReferenceProcessStatus';
import { FormatType } from './FormatType';

/** A stream field proxy. */
export interface StreamField {
  /** The type id. */
  readonly $typeId: TypeIds.StreamField; // string($uuid)

  /** The entity id. */
  readonly id: string; // ($uuid)

  /** The field name. */
  readonly name: string | null;

  /** The field name. */
  readonly description: string | null;

  dataType: DataType;

  formatType: FormatType;

  /** The element availability. */
  availability: Availability;

  /** The source field type id. */
  readonly $sourceFieldTypeId: TypeIds.DataSourceEntityField; // string($uuid)

  /** The source field id. */
  sourceFieldId: string; // ($uuid)

  /** The parent type id. */
  readonly $parentTypeId: TypeIds.Stream; // string($uuid)

  /** The parent id. */
  readonly parentId: string; // ($uuid)

  defaultAggregation: AggregationType;

  tags: string[] | null;

  /** Ingestion is pending. */
  processStatus: StreamReferenceProcessStatus;

  /** The time stamp of last successful processing. */
  readonly processTimeStamp: string | null; // ($date-time)

  /** The processed entity name. */
  readonly processedEntityName: string | null;

  /** The name when last successful processing. */
  readonly processedName: string | null;

  processedDataType: DataType;

  processedFormatType: FormatType;

  processedIsAggregated: boolean;
}

export const StreamFieldKeys: { [key in keyof StreamField]: key } = {
  $parentTypeId: '$parentTypeId',
  $sourceFieldTypeId: '$sourceFieldTypeId',
  $typeId: '$typeId',
  availability: 'availability',
  dataType: 'dataType',
  defaultAggregation: 'defaultAggregation',
  description: 'description',
  formatType: 'formatType',
  id: 'id',
  name: 'name',
  parentId: 'parentId',
  processedDataType: 'processedDataType',
  processedEntityName: 'processedEntityName',
  processedFormatType: 'processedFormatType',
  processedName: 'processedName',
  processStatus: 'processStatus',
  processTimeStamp: 'processTimeStamp',
  sourceFieldId: 'sourceFieldId',
  tags: 'tags',
  processedIsAggregated: 'processedIsAggregated',
};
