import { Stack, Shimmer } from '@fluentui/react';
import React from 'react';

const AssetShimmer = () => (
  <Stack tokens={{ childrenGap: 10 }} style={{ padding: '4px' }}>
    <Shimmer width="30%" />
    <Shimmer width="100%" />
  </Stack>
);

export default AssetShimmer;
