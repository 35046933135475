import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DirectionalHint } from '@fluentui/react';
import { Trans } from 'react-i18next';
import IconButton from '../../../../components/IconButton';
import { routes } from '../../../../constants/routes';
import { buttonStyles, iconItemStyles } from './styles';
import { useIsOutputInPanelBreakpointActive } from '../../screens/OutputPanel/useIsOutputInPanelBreakpointActive';

export const OutputModeBarButton = () => {
  const history = useHistory();
  const shouldHide = useIsOutputInPanelBreakpointActive();

  const isActive = !!useRouteMatch(routes.streamer.dataMode.output);

  const handleClick = () => history.push(routes.streamer.dataMode.output);

  if (shouldHide) return null;

  return (
    <IconButton
      iconProps={{
        iconName: 'EykoOutput',
        styles: iconItemStyles,
      }}
      tooltip={
        <strong>
          <Trans i18nKey="modeBar:streams.output" />
        </strong>
      }
      directionalHint={DirectionalHint.topCenter}
      onClick={handleClick}
      styles={buttonStyles(isActive)}
    />
  );
};
