import * as effects from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { QueryOptions } from 'odata-query';
import { getCurrentUserData } from '../User/selectors';
import { fetchUserData } from '../User/fetchUserData';

import * as NotificationActions from './actions';
import * as NotificationsApi from './api';
import {
  INotification,
  NotificationActivity,
  NotificationResponse,
  NotificationSubjectState,
} from '../../types/INotification';
import { ApiV4ResponseWrapper } from '../Designer/types';
import { IUser } from '../../types/IUser';
import { UIEnhancedNotification } from './types';
import { getNotifications, getUsersData } from './selectors';
import { getSelectedDesignId } from '../Designer/ContentLibrary/selectors';
import { fetchDesignDataSources } from '../Designer/Ingestion/actions';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { fetchProcessedDataSources, fetchStreams } from '../Builder/actions';
import { fetchAllStreams } from '../Streams/actions';
import { getSelectedStream } from '../Builder/selectors';
import {
  fetchGroups,
  fetchMachineLearningAssets,
} from '../Designer/Catalog/actions';
import { getSelectedStreamId } from '../Streamer/selectors';
import { selectStreamId } from '../Streamer/actions';
import { fetchAllDesigns } from '../Designer/ContentLibrary/actions';

const prohibitedActivities = [
  NotificationActivity.Interacting,
  NotificationActivity.LinkDiscovery,
];

const processFinishStates = [
  NotificationSubjectState.Failed,
  NotificationSubjectState.Complete,
];

export function* notificationWatcher(notification: INotification) {
  // TO DO trigger those "refetch" actions only when at the relevant page
  // probably some kind of "history observer" is needed
  const selectedDesignIdInDesigner = getSelectedDesignId(
    yield effects.select()
  );

  // we want to react to every change of sources, because now auto-ingesting sources can be created
  if (
    notification.subjectTypeId === TYPE_IDS.DataSource &&
    processFinishStates.includes(notification.state)
  ) {
    if (selectedDesignIdInDesigner) {
      //  thunk action - that's why casting to any
      yield effects.put(
        fetchDesignDataSources(selectedDesignIdInDesigner) as any
      );
    }
    const selectedStreamInBuilder = getSelectedStream(yield effects.select());
    if (selectedStreamInBuilder) {
      //  thunk action - that's why casting to any
      yield effects.put(
        fetchProcessedDataSources(selectedStreamInBuilder.parentId) as any
      );
    }
  }
  if (
    notification.subjectTypeId === TYPE_IDS.Stream &&
    processFinishStates.includes(notification.state)
  ) {
    // quick and dirty - we can add constrains later when trigger those notifications
    yield effects.put(fetchStreams() as any);
    yield effects.put(fetchAllStreams());

    const selectedStreamIdInIsolatedStreamer = getSelectedStreamId(
      yield effects.select()
    );

    if (selectedStreamIdInIsolatedStreamer === notification.subjectId) {
      // we are selecting the same stream "again" to remove all of the selections and output
      // because they might be irrelevant
      yield effects.put(selectStreamId(selectedStreamIdInIsolatedStreamer));
    }
  }
  if (
    notification.subjectTypeId === TYPE_IDS.Group &&
    processFinishStates.includes(notification.state)
  ) {
    yield effects.put(fetchGroups(selectedDesignIdInDesigner) as any);
  }

  if (
    notification.subjectTypeId === TYPE_IDS.DesignSnapshot &&
    notification.activity === NotificationActivity.Import &&
    processFinishStates.includes(notification.state)
  ) {
    yield effects.put(fetchAllDesigns() as any);
  }

  if (
    notification.subjectTypeId === TYPE_IDS.MachineLearning &&
    processFinishStates.includes(notification.state)
  ) {
    yield effects.put(
      fetchMachineLearningAssets(selectedDesignIdInDesigner) as any
    );
  }
}

export function* handleBroadcastNotificationReceived({
  payload: notification,
}: ReturnType<typeof NotificationActions.appendNotificationIntent>) {
  const currentNotifications = getNotifications(yield effects.select());

  // broadcast & send-user channels may receive exactly the same notifications
  const isNotificationAlreadyStored = currentNotifications.records.some(
    (storedNotification) => storedNotification.id === notification.id
  );
  if (isNotificationAlreadyStored) {
    return;
  }

  yield effects.call(notificationWatcher, notification);
}

export function* handleNotificationAppend({
  payload: notification,
}: ReturnType<typeof NotificationActions.appendNotificationIntent>) {
  const currentNotifications = getNotifications(yield effects.select());

  // broadcast & send-user channels may receive exactly the same notifications
  const isNotificationAlreadyStored = currentNotifications.records.some(
    (storedNotification) => storedNotification.id === notification.id
  );
  if (isNotificationAlreadyStored) {
    return;
  }

  if (prohibitedActivities.includes(notification.activity)) {
    return;
  }

  let hasUpdatedUsers = false;
  let usersData = getUsersData(yield effects.select);
  const relatedUser = usersData[notification.senderId];

  if (!relatedUser) {
    const { data: missingUser }: AxiosResponse<IUser> = yield effects.call(
      NotificationsApi.fetchSingleUser(notification.senderId)
    );

    usersData = {
      ...usersData,
      [missingUser.id]: missingUser,
    };
    hasUpdatedUsers = true;
  }

  const enhancedNotification = {
    ...notification,
    relatedUserName: usersData[notification.senderId]?.name,
  };

  yield effects.put(
    NotificationActions.appendNotification({
      notification: enhancedNotification,
      updatedUserData: hasUpdatedUsers ? usersData : null,
    })
  );

  yield effects.call(notificationWatcher, notification);
}

export function* fetchNotifications() {
  let currentUser = getCurrentUserData(yield effects.select());

  if (!currentUser) {
    yield effects.take(fetchUserData.fulfilled);
    currentUser = getCurrentUserData(yield effects.select());
  }

  const usersResponse: AxiosResponse<ApiV4ResponseWrapper<IUser[]>> =
    yield effects.call(NotificationsApi.fetchAllUsers);
  const users: IUser[] = usersResponse.data.data;

  const usersById = users.reduce<Record<string, IUser>>(
    (accumulator, user) => ({
      ...accumulator,
      [user.id]: user,
    }),
    {}
  );

  const prohibitedActivitiesFilter = prohibitedActivities
    .map((activity) => `and activity ne '${activity}'`)
    .join(' ');

  const oDataFilter: Partial<QueryOptions<unknown>> = {
    filter: `response ne '${NotificationResponse.Dismiss}' ${prohibitedActivitiesFilter}`,
  };

  try {
    const {
      data: firstPageResponse,
    }: AxiosResponse<ApiV4ResponseWrapper<INotification[]>> =
      yield effects.call(
        NotificationsApi.fetchUserNotifications,
        currentUser.id,
        oDataFilter
      );

    const allNotifications: INotification[] = [...firstPageResponse.data];

    const filteredNotifications: UIEnhancedNotification[] = allNotifications
      .map((notification) => ({
        ...notification,
        relatedUserName: usersById[notification.senderId]?.name,
      }))
      .sort((notificationA, notificationB) => {
        const dateA = new Date(notificationA.timeStamp);
        const dateB = new Date(notificationB.timeStamp);

        return dateB.getTime() - dateA.getTime();
      });

    yield effects.put(
      NotificationActions.setNotifications({
        usersById,
        count: filteredNotifications.length,
        records: filteredNotifications,
      })
    );
  } catch (error) {
    console.error('notification initialization error', error);
  }
}

export function* dismissSingleNotification({
  payload: notificationId,
}: ReturnType<typeof NotificationActions.dismissNotification>) {
  yield effects.put(
    NotificationActions.removeNotificationFromRecords(notificationId)
  );
  yield effects.call(NotificationsApi.dismissNotification, notificationId);
}

export default function* rootSaga() {
  yield effects.all([
    effects.takeEvery(
      NotificationActions.appendNotificationIntent.type,
      handleNotificationAppend
    ),
    effects.takeEvery(
      NotificationActions.broadcastNotificationReceived.type,
      handleBroadcastNotificationReceived
    ),
    effects.takeLatest(
      NotificationActions.fetchNotificationsIntent.type,
      fetchNotifications
    ),
    effects.takeLatest(
      NotificationActions.dismissNotification.type,
      dismissSingleNotification
    ),
  ]);
}
