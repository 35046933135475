export enum WizardType {
  Delete = 'Delete',
  Share = 'Share',
  Add = 'Add',
  Edit = 'Edit',
  Process = 'Process',
  Export = 'Export',
  Import = 'Import',
  Preview = 'Preview',
}
