import i18n from '../../../../taskpane/config/i18n';
import { Action, dialogInitializeFailure, dialogInitializeSuccess, dispatchToHost } from '../actions';
import { DialogMessageHandler } from './events';

// INITIALIZE DIALOG

/**
 * @function
 * @returns {boolean} `isDone`: a boolean value that indicates whether the initialization process should end with the custom
 *                    initializer – all that had to be done was done
 *
 *                    Possible values are:
 *                      * `true` – initialization process ends with the custom initializer
 *                      * `false` – the default initialization process should be triggered, i.e. establishing
 *                        communications with the host window
 * */
export type CustomDialogInitializer = () => boolean;

export interface InitializeDialogArgs {
  customInitializer?: CustomDialogInitializer;
  onMessageFromParent: DialogMessageHandler;
}

// INITIALIZE DIALOG – OFFICE

const prepareErrorMessage = error =>
  `code: ${error.errorCode ?? error.code}; name: ${error.name}; message: ${error.errorMessage ?? error.message}; stack: ${error.stack}`;

const onRegisterOfficeMessageHandlerComplete = (asyncResult: Office.AsyncResult<void>) => {
  if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
    const errorMessage: string = prepareErrorMessage(asyncResult.error);

    dispatchToHost(dialogInitializeFailure(errorMessage));
  } else {
    dispatchToHost(dialogInitializeSuccess());
  }
};

const onOfficeMessageFromParentFactory = (onMessageFromParent: DialogMessageHandler) =>
  (event: Office.DialogParentMessageReceivedEventArgs): void => {
    const action = JSON.parse(event.message) as Action;

    onMessageFromParent({ action });
  };

export const initializeOfficeDialog = ({
  customInitializer,
  onMessageFromParent
}: InitializeDialogArgs) => {
  Office.initialize = () => {
    // skip if a custom initialization behaviour was defined and reports being done with the entire initialization process
    if (customInitializer?.()) return;

    Office.context.ui.addHandlerAsync(
      Office.EventType.DialogParentMessageReceived,
      onOfficeMessageFromParentFactory(onMessageFromParent),
      // NOTE: `as any` assertion is required because there is no overload type def for `addHandlerAsync`
      // that accepts a callback on the 3rd position (even tho it is possible)
      onRegisterOfficeMessageHandlerComplete as any,
    );
  };
};

// INITIALIZE DIALOG – ISOLATED

export const initializeIsolatedDialog = ({
  customInitializer,
  onMessageFromParent,
}: InitializeDialogArgs) => {
  // skip if a custom initialization behaviour was defined and reports being done with the entire initialization process
  if (customInitializer?.()) return;

  // handle events
  const onMessageReceived = (event: MessageEvent<Action<string, unknown>>) => {
      // ignore messages from other windows unless it's the dialog we just opened
      if (event.source !== window.opener) return;

      // make sure we're still communicating within the same origin
      if (event.origin !== window.location.origin) {
        throw new Error(i18n.t('dialog:error:originMismatch'));
      }

      // handle messages
      const action = event.data;
      onMessageFromParent({ action });
  }

  window.addEventListener('message', onMessageReceived);

  // report back to the opener that we're done with initializing the dialog
  dispatchToHost(dialogInitializeSuccess());
};
