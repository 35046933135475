import React from 'react';

type Callback<T> = (newValue: T, oldValue: T) => void;

type SetDeleteResult<T> = ReturnType<Set<T>['delete']>;

type CleanUp<T> = () => SetDeleteResult<T>;

export type UnwrappedObservable<T> = T extends Observable<infer R> ? R : never;

export interface Observable<T> {
  get(): T;
  set(newValue: T): void;
  reset(): void;
  size(): number;
  subscribe(callback: Callback<T>): CleanUp<T>;
  unsubscribe(callback: Callback<T>): SetDeleteResult<T>;
}

export const observable = <T>(value: T): Observable<T> => {
  const subscriptions = new Set<Callback<T>>();

  let state = value;

  const size: Observable<T>['size'] = () => subscriptions.size;

  const get: Observable<T>['get'] = () => state;

  const set: Observable<T>['set'] = (newValue: T) => {
    // NOTE: this is sth like `onBeforeChange`, since we update the value after the callbacks are triggered
    subscriptions.forEach((callback) => callback(newValue, state));
    state = newValue;
  };

  const reset: Observable<T>['reset'] = () => set(value);

  const subscribe: Observable<T>['subscribe'] = (callback) => {
    subscriptions.add(callback);
    return () => subscriptions.delete(callback);
  };

  const unsubscribe: Observable<T>['unsubscribe'] = (callback) =>
    subscriptions.delete(callback);

  return {
    get,
    set,
    reset,
    size,
    subscribe,
    unsubscribe,
  };
};

export const useObservable = <T>(
  observableObj: Observable<T>
): [T, (state: T) => void] => {
  // prepare React component state we'll use to update the component
  // when the associated observable's state updates
  const [state, setState] = React.useState(observableObj.get());

  React.useEffect(() => {
    // in case `observable` dependency changes and we want to sync the states
    // (can't reinitialize `useState`) – should not do anything on first call
    // as we're setting the same value as the one we initialized with
    setState(observableObj.get());

    // `.subscribe()` returns a clean up function that removes the callback
    const cleanUp = observableObj.subscribe((newState) => {
      setState(newState);
    });

    return () => {
      cleanUp();
    };
  }, [observableObj]);

  return [state, observableObj.set];
};
