/* eslint-disable i18next/no-literal-string */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Text, getTheme, mergeStyleSets } from '@fluentui/react';
import { getStyles } from './styles';
import { getCurrentUserData, getIsLoading, getUserRole } from '../../selectors';
import { changeUserRole } from '../../actions';
import { fetchUserData } from '../../fetchUserData';
import RoleButton from '../../components/RoleButton';
import { UserRoles } from '../../../../types/IUser';
import { isUserPermitted } from '../../../../utils/isUserPermitted';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import {
  ORDER_OF_APPEARANCE,
  tutorialTileConfig,
  TUTORIAL_TILE_KEY,
} from './constants';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';

const UserProfile = () => {
  // DEPS
  const theme = getTheme();

  // HOOKS
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();

  // STATE
  const userData = useSelector(getCurrentUserData);
  const userRole = useSelector(getUserRole);
  const isUserDataLoading = useSelector(getIsLoading);

  // DERIVED STATE
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
  });

  const systemUserRole = userData?.role;

  const selectedUserRole = userRole || systemUserRole;

  const classNames = useMemo(() => {
    const styles = getStyles(theme);
    return mergeStyleSets(styles);
  }, []);

  // CALLBACKS
  const handleRoleChange = (role: UserRoles) => {
    dispatch(changeUserRole(role));
  };

  // EFFECTS

  React.useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  // RENDER

  if (isUserDataLoading) return <ItemsListShimmer />;
  if (tutorialTile) return tutorialTile;

  return (
    <>
      <Stack className={classNames.userInfoWrapper}>
        <Text variant="large">{userData?.name}</Text>
        <Text variant="xSmall" className={classNames.userEmail}>
          {userData?.role}
        </Text>
      </Stack>
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <RoleButton
          isActive={selectedUserRole === UserRoles.Streamer}
          iconName="SynergiesStreamer"
          onClick={() => handleRoleChange(UserRoles.Streamer)}
          description={t('roles.Streamer')}
        />
        <RoleButton
          isActive={selectedUserRole === UserRoles.Builder}
          iconName="SynergiesBuilder"
          onClick={() => handleRoleChange(UserRoles.Builder)}
          isDisabled={
            !isUserPermitted(systemUserRole, [
              UserRoles.Admin,
              UserRoles.Designer,
              UserRoles.Builder,
            ])
          }
          description={t('roles.Builder')}
        />
        <RoleButton
          isActive={selectedUserRole === UserRoles.Designer}
          iconName="SynergiesDesigner"
          onClick={() => handleRoleChange(UserRoles.Designer)}
          isDisabled={
            !isUserPermitted(systemUserRole, [
              UserRoles.Admin,
              UserRoles.Designer,
            ])
          }
          description={t('roles.Designer')}
        />
        <RoleButton
          isActive={selectedUserRole === UserRoles.Admin}
          iconName="SynergiesAdmin"
          onClick={() => handleRoleChange(UserRoles.Admin)}
          isDisabled={!isUserPermitted(systemUserRole, [UserRoles.Admin])}
          description={t('roles.Admin')}
        />
      </Stack>
      {ORDER_OF_APPEARANCE.map(
        (key) =>
          userData?.[key] && (
            <Stack {...{ key }} className={classNames.userPropWrapper}>
              <Text variant="small" className={classNames.userPropKey}>
                {t(key)}
              </Text>
              <Text variant="medium" className={classNames.userPropValue}>
                {userData[key]}
              </Text>
            </Stack>
          )
      )}
    </>
  );
};

export default UserProfile;
