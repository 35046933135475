import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../../shared/tutorial-tiles/components/animations/Calcs';

export const TUTORIAL_TILE_KEY = 'designer-catalog-calcs';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:catalog:calcs:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:calcs:items:createCalculation:description',
      icon: 'Add',
      titleI18nKey: 'tutorialTiles:designer:catalog:calcs:items:createCalculation:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:calcs:items:pickFunction:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:designer:catalog:calcs:items:pickFunction:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:calcs:items:addConditions:description',
      icon: 'FlowChart',
      titleI18nKey: 'tutorialTiles:designer:catalog:calcs:items:addConditions:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010337682-Designer-Calculations-Making-your-data-count',
};
