import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import EntityField from '../../../../components/EntityField';
import DataSourceIcon from '../../../../components/DataSourceIcon';
import { IFoldableListItem } from '../../../../components/ui/FoldableList/types/IFoldableListGroup';

import { DefinedSortTypes } from '../../../App/types';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import useGetParsedTypeProperties from '../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';

import {
  getDataSources,
  getSelectedDataSourceIds,
  getSelectedStream,
} from '../../selectors';

import {
  fetchProcessedDataSources,
  setSelectedDataSourceIds,
} from '../../actions';

import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { IDesignSource } from '../../../Designer/types';
import { EmptyMessage, GroupCollapse } from '../../../../components/ui';
import { getSearchQuery } from '../../../Search/selectors';
import { matchesSearchQuery } from '../../../Search/utils';
import { getSchemaFromTypeProperties } from '../../../../utils/getSchemaFromTypeProperties';
import { getSelectedDesignId } from '../../../Designer/ContentLibrary/selectors';
import useSelectedSources from '../../hooks/useSelectedSources';
import { deleteEntity } from '../../../Designer/api';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import DataSourceCalloutTitle from '../../../Designer/Ingestion/components/DataSourceCalloutTitle';
import { numberToHexColor } from '../../../../components/ui/ColorPicker/utils';
import { DataSourceProcessStatus } from '../../../../api/model/schemas/DataSourceProcessStatus';

const Sources: FunctionComponent = () => {
  // HOOKS - REDUX
  const dispatch = useDispatch();
  const selectedStream = useSelector(getSelectedStream);
  const selectedDataSourceIds = useSelector(getSelectedDataSourceIds);
  const { records, isLoading } = useSelector(getDataSources);
  const searchQuery = useSelector(getSearchQuery);
  const designId = useSelector(getSelectedDesignId);
  const [showDelete, setShowDelete] = useState('');
  const [isDeleting, setIsDeleting] = useState('');

  // HOOKS
  const { t } = useTranslation();
  const {
    isLoading: isStreamFieldsLoading,
    selectedSourceIds: selectedSourceIdsFromStream,
    getStreamFieldsBySource,
    fetchAgain,
  } = useSelectedSources(selectedStream.id);

  const filteredRecords = useMemo(
    () =>
      records
        .filter((source) =>
          [
            DataSourceProcessStatus.Processed,
            DataSourceProcessStatus.Processing,
          ].includes(source.processStatus)
        )
        .filter(
          (source) =>
            searchQuery === '' || matchesSearchQuery(searchQuery, source.name)
        ),
    [records, searchQuery]
  );

  const sources = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds: selectedDataSourceIds,
  });

  const { properties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.DataSource,
  });

  const { mappedGroups, onClickHeaderHandler, currentSort } =
    useSortedFoldableGroups({ input: sources });

  // DERIVED STATE
  const schema = getSchemaFromTypeProperties(properties, TYPE_IDS.DataSource);
  const combinedSelections = uniq([
    ...selectedDataSourceIds,
    ...selectedSourceIdsFromStream,
  ]);

  // CALLBACKS
  const onClickHandler = (id: string) => {
    if (isDeleting !== '') {
      return;
    }

    if (showDelete === id) {
      return;
    }

    if (selectedSourceIdsFromStream.includes(id)) {
      setShowDelete(id);
      return;
    }

    const newDataSources = selectedDataSourceIds.includes(id)
      ? selectedDataSourceIds.filter((s) => s !== id)
      : [...selectedDataSourceIds, id];

    dispatch(setSelectedDataSourceIds(newDataSources));
  };

  const onDeleteConfirm = async (id: string) => {
    setIsDeleting(id);
    setShowDelete('');
    const sourceStreamFields = getStreamFieldsBySource(id);

    await Promise.all(
      sourceStreamFields.map(async (fieldId) => {
        await deleteEntity(TYPE_IDS.StreamField)(fieldId);
      })
    );

    const newDataSources = selectedDataSourceIds.filter((s) => s !== id);

    dispatch(setSelectedDataSourceIds(newDataSources));

    setIsDeleting('');
    fetchAgain();
  };

  const onDeleteCancel = () => {
    setShowDelete('');
  };

  // EFFECTS
  useEffect(() => {
    if (selectedStream)
      dispatch(fetchProcessedDataSources(selectedStream.parentId));
  }, [dispatch, selectedStream]);

  useEffect(() => {
    // We can assume that "starting and unselected screen"
    // will contain not be sorted by names
    // because it's not a default sort
    // we want for an user to start with something selected
    if (!mappedGroups || sources.length !== 1) return;

    if (selectedDataSourceIds.length === 0) {
      const pickedSourceId = mappedGroups[0]?.items[0]?.key;
      if (!pickedSourceId) return;
      dispatch(setSelectedDataSourceIds([pickedSourceId]));
    }
  }, [mappedGroups]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced:
      selectedDataSourceIds.length === 0 &&
      selectedSourceIdsFromStream.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
  });

  const renderSource = (source: IDesignSource) => (
    <EntityField
      key={source.id}
      isSelectable
      isSelected={
        selectedDataSourceIds.includes(source.id) ||
        selectedSourceIdsFromStream.includes(source.id)
      }
      info={{
        data: source,
        schema,
        title: (
          <DataSourceCalloutTitle
            name={source.name}
            color={numberToHexColor(source.color)}
          />
        ),
      }}
      onClick={() => onClickHandler(source.id)}
      slot1={<DataSourceIcon color={numberToHexColor(source.color)} />}
      name={source.name}
      searchQuery={searchQuery}
      showDeleteConfirmation={showDelete === source.id}
      onDeleteConfirm={() => onDeleteConfirm(source.id)}
      onDeleteCancel={() => onDeleteCancel()}
      isSyncing={
        isDeleting === source.id ||
        source.processStatus === DataSourceProcessStatus.Processing
      }
      isDisabled={source.processStatus === DataSourceProcessStatus.Processing}
      data-testid={`builder-source-${source.name}`}
    />
  );

  const onRenderGroupItem = (item: IFoldableListItem) => {
    const source = records.find((s) => s.id === item.key);

    if (source) {
      return renderSource(source);
    }

    return null;
  };

  // RENDER
  if (isLoading || isStreamFieldsLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isLoading && sources.length === 0) {
    return (
      <EmptyMessage
        message={t('wizard:noEntityDefined', {
          entityType: t('entityTypes:Source', { count: 0 }),
        })}
      />
    );
  }

  if (currentSort === DefinedSortTypes.Name) {
    return (
      <>
        {sources
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((field) => renderSource(field))}
      </>
    );
  }
  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onClick={onClickHeaderHandler}
          isSelected={group.items.every((item) =>
            combinedSelections.includes(item.key)
          )}
          selections={{
            total: group.items.length,
            current: combinedSelections.filter((id) =>
              group.items.map((item) => item.key).includes(id)
            ).length,
          }}
        >
          {group.items.map(onRenderGroupItem)}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default Sources;
