import * as effects from 'redux-saga/effects';
import { merge } from 'lodash';
import * as selectors from '../selectors';
import {
  addDrillingFiltersToItems,
  getAggregatedValues, getColumnsFromDataset, getIsColumnLayout, groupInquiryData,
} from '../utils';
import { setTableData } from '../actions';
import { fetchAllColumnDataPages } from '../../Streams/sagas/fetchAllColumnDataPages';
import { IDataset } from '../../../types/IDataset';
import { getNonRepeatingColumns, removeColumnsAggregation } from '../../Streams/components/StreamerColumn/utils';
import { IInquiryData } from '../../../types/IInquiryData';
import { IGroupedRow } from '../types';

interface IFetchGroupTableData {
  dataset: IDataset;
  rowKeys?: string[];
  columnKeys?: string[];
  isRowsTotal?: boolean;
  isColumnsTotal?: boolean;
  shouldUpdateDataset?: boolean;
}

/**
 * Fetches and groups the data necessary to populate a group table.
 * @param params.dataset The dataset that will be rendered as group table.
 * @param params.rowKeys The keys that will be use to filter the inquiries by Row drilling level.
 * @param params.columnKeys The keys that will be use to filter the inquiries
 * by Column drilling level.
 * @param params.isRowsTotal If true, table rows won't be included in the inquiry result
 * @param params.isColumnsTotal If true, table columns won't be included in the inquiry result
 */

function* fetchGroupTableData({
  dataset,
  rowKeys = [],
  columnKeys = [],
  isColumnsTotal = false,
  isRowsTotal = false,
  shouldUpdateDataset = true,
}: IFetchGroupTableData) {
  // STEP 1: Get groupTable
  const groupTable = selectors.getGroupTable(dataset.id)(yield effects.select());

  // STEP 2: Get drilling levels for current operation

  const rowDrilling = rowKeys.length ? rowKeys.length + 1 : groupTable.rowDrilling;
  const columnDrilling = columnKeys.length ? columnKeys.length + 1 : Math.min(
    groupTable.columnDrilling, groupTable.columns.length,
  );

  // STEP 3: Get columns from dataset

  const tableRows = getColumnsFromDataset(groupTable.rows, dataset);
  const tableColumns = getColumnsFromDataset(groupTable.columns, dataset);
  const tableValues = getColumnsFromDataset(groupTable.values, dataset);

  // STEP 4: Aggregations on each Row and Column won't be send in the queries
  // (instead, they will be inherited by the Values) so we need a copy of those without
  // aggregations.

  const aggregatedRows = removeColumnsAggregation(tableRows);
  const aggregatedColumns = removeColumnsAggregation(tableColumns);

  // STEP 5: Filter Rows and Columns by their drilling level

  const filteredRows = addDrillingFiltersToItems(aggregatedRows, rowKeys);
  const filterdColumns = addDrillingFiltersToItems(aggregatedColumns, columnKeys);

  // STEP 6: Build an inquiry for each different drilling level

  const queries = [];

  const rowIterator = rowKeys.length ? rowDrilling : 0;
  const columnIterator = columnKeys.length ? columnDrilling : 0;

  for (let r = rowDrilling; r >= rowIterator; r -= 1) {
    const rows = tableRows.slice(0, r);
    if (!rows.length && !rowKeys.length) {
      // eslint-disable-next-line no-continue
      continue;
    }
    for (let c = columnDrilling; c >= columnIterator; c -= 1) {
      const columns = tableColumns.slice(0, c);
      // STEP 6.1: If the group table will render at least 1 Column Drilling level
      // we can skip the very first iteration, and save 1 query.
      if (!columns.length && getIsColumnLayout(groupTable)) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (tableRows.length) {
        // STEP 6.2: Set aggreations on Values based on inheritance from parent items.
        const aggregatedValues = getAggregatedValues(
          tableValues,
          !isColumnsTotal ? columns.slice(-1)[0]?.aggregation : null,
          !isRowsTotal ? rows.slice(-1)[0]?.aggregation : null,
        );

        const queryItems = [
          ...(isRowsTotal ? [] : filteredRows.slice(0, r)),
          ...(isColumnsTotal ? [] : filterdColumns.slice(0, c)),
          ...aggregatedValues,
        ];

        queries.push(
          yield effects.fork(
            fetchAllColumnDataPages,
            queryItems,
            // STEP 6.3: We include all other columns from the dataset in the query
            // to cover cross entitity joins.
            getNonRepeatingColumns(dataset.columns, queryItems),
          ),
        );
      }
    }
  }

  const results: IInquiryData[] = yield effects.join(queries);

  // TEMPORAL STEP: Check if any cancelled inquiries

  if (results.some((query : IInquiryData & {cancelled: boolean}) => query.cancelled)) return false;
  if (!shouldUpdateDataset) return results;

  // STEP 7: Group fetched data

  const data : IGroupedRow = yield effects.call(groupInquiryData, results, groupTable);

  // TEMPORAL STEP: Update group table.

  yield effects.put(setTableData({
    datasetId: dataset.id,
    data: merge({},
      rowKeys.length || columnKeys.length ? groupTable.data : {},
      data),
  }));

  return data;
}

export default fetchGroupTableData;
