import { getTheme } from '@fluentui/react';

export const valueStyles = () => {
  const theme = getTheme();

  return {
    color: theme.palette.themePrimary,
    marginLeft: '2px',
    marginRight: '2px',
  };
};
