/* eslint-disable no-nested-ternary */
import { TextField } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'use-onclickoutside';
import IconButton from '../../../../../components/IconButton';
import { stringArrayToOptions } from '../../../../../components/ui/SelectDropdown/utils';
import { TypedValuesInput } from '../../../../../components/ValuesInput';
import { COLUMN_DATA_TYPES } from '../../../../../constants/aggregations';
import { IDesignerFilter } from '../../../../../types/IDesignerFilter';
import {
  ComparisonTypes,
  FilterTypes,
} from '../../../../../types/IStreamFilter';
import { adjustComparisonType } from '../../../../../utils/adjustFilterComparisonType';
import { formatValues } from '../../../../../utils/formatFilterValues';
import { UseManageMultipleEditItem } from '../ManageMultiple/hooks/useManageMultiple';
import { GroupPickerCreatableDropdown } from './CreatableDropdown';
import {
  getClassNames,
  inRowNameStyles,
  typedValuePickerStyles,
  valuePickerStyles,
} from './styles';
import { DisplayModes, Group } from './types';

const dataType = COLUMN_DATA_TYPES.Text;

export interface Props {
  id: string;
  name: string;
  areAllNamesUnique: boolean;
  mode: DisplayModes;
  onRemove: (id: string) => void;
  handleEditItem: UseManageMultipleEditItem<Group>;
  options: string[];
  isSelected: boolean;
  setSelectedItemId: (id: string) => void;
  index: number;
  shouldDnd: boolean;
  filter: Partial<IDesignerFilter>;
  shouldRenderValuePicker: boolean;
  currentSelectedItem: Group;
}

const GroupPickerRow: React.FC<Props> = ({
  id,
  mode,
  name,
  filter = {},
  options,
  onRemove,
  handleEditItem,
  isSelected,
  setSelectedItemId,
  index,
  shouldDnd,
  shouldRenderValuePicker,
  currentSelectedItem,
  areAllNamesUnique,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const removeBtnRef = useRef(null);
  const nameRef = useRef(null);
  const classNames = getClassNames(isSelected);

  const { comparison: realComparisonType } = useMemo(
    () => adjustComparisonType(filter as IDesignerFilter),
    [filter]
  );

  const formatedValues = useMemo(
    () =>
      formatValues({
        ...filter,
        comparison: realComparisonType,
        item: {
          id: null,
          name: null,
          dataType,
        },
      }),
    [filter, dataType]
  );

  const dropdownOptions = useMemo(
    () => stringArrayToOptions(options),
    [options]
  );

  const dropdownValue =
    (filter.values && stringArrayToOptions(filter.values)) ||
    (filter.compareValue && stringArrayToOptions([filter.compareValue])) ||
    [];
  const typedInputValues =
    filter.values || (filter.compareValue && [filter.compareValue]) || [];

  // EFFECTS

  useOnClickOutside(ref, () => {
    if (isSelected && mode === DisplayModes.values) {
      if (name || dropdownValue?.length) {
        if (name && areAllNamesUnique) {
          setSelectedItemId(null);
        }
      } else {
        setSelectedItemId(null);
        onRemove(id);
      }
    }
  });

  // CALLBACKS

  const handleRowClick = (e: any) => {
    if (
      !isSelected &&
      !removeBtnRef.current?.contains(e.target) &&
      (!currentSelectedItem || !!currentSelectedItem?.name)
    ) {
      setSelectedItemId(id);
    }
  };

  const handleRemove = async () => {
    onRemove(id);
    setSelectedItemId(null);
  };

  const handleValuesChange = useCallback(
    async (values: string[]) => {
      const hasMany = values?.length > 1;
      const hasNone = values?.length === 0;
      const hasOne = values?.length === 1;
      const newFilter: Partial<IDesignerFilter> = {
        values: hasMany ? values : undefined,
        compareValue: hasOne ? values[0] : hasNone ? '' : undefined,
        comparison: ComparisonTypes.Equal,
        type: hasMany ? FilterTypes.Values : FilterTypes.Comparison,
      };
      await handleEditItem(id, newFilter);
    },
    [filter, id]
  );

  const handleDropdownChange = useCallback(
    async (
      src: {
        value: string;
      }[]
    ) => {
      const values = src?.map(({ value }) => value);
      await handleValuesChange(values);
    },
    [handleValuesChange]
  );

  const handleNameChange = useCallback(
    (value: string) => handleEditItem(id, { name: value }),
    [handleEditItem]
  );

  // PARTS

  const groupName = (
    <div>
      {isSelected && mode === DisplayModes.values ? (
        <TextField
          data-testid="group-name-field"
          onChange={(_, val) => handleNameChange(val)}
          defaultValue={name}
          componentRef={nameRef}
          styles={inRowNameStyles}
        />
      ) : (
        name || ''
      )}
    </div>
  );

  const valuesInLogicMode = (
    <>
      {t(`filters:${realComparisonType}`)}{' '}
      {(filter?.compareValue ||
        filter?.values?.length ||
        (filter?.lowerBoundValue && filter?.upperBoundValue)) && (
        <span className={classNames.span}>{formatedValues}</span>
      )}
    </>
  );

  const valuesInValuesMode = !isSelected ? (
    formatedValues
  ) : shouldRenderValuePicker ? (
    <GroupPickerCreatableDropdown
      closeMenuOnSelect={false}
      isMulti
      value={dropdownValue}
      onChange={handleDropdownChange}
      options={dropdownOptions}
      styles={valuePickerStyles}
      menuPlacement="bottom"
    />
  ) : (
    <TypedValuesInput
      isMulti
      onChange={handleValuesChange}
      defaultValue={typedInputValues}
      styles={typedValuePickerStyles}
    />
  );

  // RENDER

  useEffect(() => {
    if (isSelected && mode === DisplayModes.values) {
      setTimeout(() => nameRef?.current?.focus(), 50);
    }
  }, [isSelected]);

  return (
    <div ref={ref} onClick={handleRowClick}>
      <Draggable draggableId={id} index={index} isDragDisabled={!shouldDnd}>
        {(provided: DraggableProvided) => (
          <div
            className={`${classNames.row} ${classNames.item}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {groupName}
            <div
              className={classNames.values}
              data-group-id={id}
              data-testid={`group-values-picker-${index}`}
            >
              {mode === DisplayModes.logic
                ? valuesInLogicMode
                : valuesInValuesMode}
            </div>
            <div ref={removeBtnRef}>
              <IconButton
                iconProps={{ iconName: 'Delete' }}
                onMouseDown={handleRemove}
                className={classNames.rowButton}
              />
            </div>
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default GroupPickerRow;
