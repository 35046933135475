import { Trans } from 'react-i18next';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import i18n from '../../../../config/i18n';
import NotificationsSidebarButton from '../../../Notifications/components/NotificationsSidebarButton';
import { UserRoles } from '../../../../types/IUser';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { Environment, getEnvironment } from '../../../../utils/getEnvironment';
import { isOfficeEnabled } from '../../../../config/buildType';
import { OutputModeBarButton } from '../../../Streamer/components/OutputModeBarButton/OutputModeBarButton';

const isDesignerBuilderBackdoorApplied = () => {
  const url = new URL(window.location.href);

  return url.searchParams.has('backdoor');
};

export enum Modules {
  Streamer = 'Streamer',
  Streamer2 = 'IsolatedStreamer',
  Builder = 'Builder',
  Designer = 'Designer',
  Admin = 'Admin',
  Notifications = 'Notifications',
  Settings = 'Settings',
  Help = 'Help',
}

export enum HideSectionCondition {
  whenSyncing = 'whenSyncing',
  whenStreamIsNotSelected = 'whenStreamIsNotSelected',
  whenDatasetIsSelected = 'whenDatasetIsSelected',
  whenTableHasNoColumnsSelected = 'whenTableHasNoColumnsSelected',
  whenTableWasNotPlayed = 'wwhenTableWasNotPlayed',
  whenGroupTableHasNoColumnsSelected = 'whenGroupTableHasNoColumnsSelected',
  forGroupTable = 'forGroupTable',
  whenNoRecentStreams = 'whenNoRecentStreams',
  whenNoDesignIsSelected = 'whenNoDesignIsSelected',
  whenNoBuilderStreamIsSelected = 'whenNoBuilderStreamIsSelected',
  whenNoCatalogSourceIsSelected = 'whenNoCatalogSourceIsSelected',
  whenNoBuilderDataSourceIsSelected = 'whenNoBuilderDataSourceIsSelected',
}

const settingsSections = (t) => [
  {
    key: 'profile',
    title: <Trans t={t} i18nKey="modeBar:settings:profileTooltip" />,
    name: <Trans t={t} i18nKey="modeBar:settings:profile" />,
    href: routes.settings.profile.index,
    iconName: 'Contact',
  },
  {
    key: 'Preferences',
    title: <Trans t={t} i18nKey="modeBar:settings:preferencesTooltip" />,
    name: <Trans t={t} i18nKey="modeBar:settings:preferences" />,
    href: routes.settings.preferences.index,
    iconName: 'PlayerSettings',
  },
];

const notificationSections = (t) => [
  {
    key: 'Notifications',
    title: <Trans t={t} i18nKey="modeBar:notifications.activityFeedTooltip" />,
    name: <Trans t={t} i18nKey="modeBar:notifications.activityFeed" />,
    href: routes.notifications.records.index,
    iconName: 'ActivityFeed',
  },
  {
    key: 'Errors',
    title: <Trans t={t} i18nKey="modeBar:notifications:errorsTooltip" />,
    name: <Trans t={t} i18nKey="modeBar:notifications:errors" />,
    href: routes.notifications.errors.index,
    iconName: 'Error',
  },
];

const helpSections = (t) => [
  {
    key: 'Chat',
    title: (
      <strong>
        <Trans t={t} i18nKey="modeBar:help.chat" />
      </strong>
    ),
    name: <Trans t={t} i18nKey="modeBar:help.chat" />,
    href: routes.help.chat,
    iconName: 'Chat',
  },
  {
    key: 'Help',
    title: (
      <strong>
        <Trans t={t} i18nKey="modeBar:help.knowledgeBase" />
      </strong>
    ),
    name: <Trans t={t} i18nKey="modeBar:help.knowledgeBase" />,
    href: routes.help.knowledgeBase,
    iconName: 'KnowledgeArticle',
  },
  {
    key: 'Videos',
    title: (
      <strong>
        <Trans t={t} i18nKey="modeBar:help.eykoVideos" />
      </strong>
    ),
    name: <Trans t={t} i18nKey="modeBar:help.eykoVideos" />,
    href: 'https://video.eyko.io/',
    iconName: 'Video',
  },
  {
    key: 'Website',
    title: (
      <strong>
        <Trans t={t} i18nKey="modeBar:help.website" />
      </strong>
    ),
    name: <Trans t={t} i18nKey="modeBar:help.website" />,
    href: routes.help.website,
    iconName: 'Website',
  },
  {
    key: 'Errors_Form',
    title: (
      <strong>
        <Trans t={t} i18nKey="modeBar:help.support" />
      </strong>
    ),
    name: <Trans t={t} i18nKey="modeBar:help.support" />,
    href: routes.help.errorForm,
    iconName: 'Ticket',
  },
];

const builderSections = (t) => [
  {
    key: 'stream list',
    name: <strong>{t('sectionBar:builder.streams')}</strong>,
    href: routes.builder.streams.index,
    iconName: 'ContentLibrary',
    subSections: [
      {
        key: 'Designs',
        title: <Trans t={t} i18nKey="modeBar:builder.streamsTooltip" />,
        name: t('modeBar:builder.streams'),
        href: routes.builder.streams.index,
        iconName: 'EykoStreams',
      },
    ],
  },
  {
    key: 'stream catalog',
    name: <strong>{t('sectionBar:builder.catalog')}</strong>,
    href: routes.builder.assets.index,
    iconName: 'EykoCatalog',
    hideConditions: [HideSectionCondition.whenNoBuilderStreamIsSelected],
    subSections: [
      {
        key: 'sources',
        title: <Trans i18nKey="modeBar:builder.dataTooltip" />,
        name: t('modeBar:builder.sources'),
        href: routes.builder.assets.sources,
        iconName: 'Database',
      },
      {
        key: 'data',
        title: <Trans i18nKey="modeBar:builder.dataTooltip" />,
        name: t('modeBar:builder.data'),
        href: routes.builder.assets.data,
        iconName: 'EykoData',
      },
      {
        key: 'links',
        title: <Trans i18nKey="modeBar:ingestion.linksTooltip" />,
        name: t('modeBar:ingestion.links'),
        href: routes.builder.assets.links,
        iconName: 'Link',
      },
      {
        key: 'calculations',
        title: <Trans i18nKey="modeBar:builder.calculationsTooltip" />,
        name: t('modeBar:builder.calculations'),
        href: routes.builder.assets.calculations,
        iconName: 'Calculator',
      },
      {
        key: 'hubs',
        title: <Trans i18nKey="modeBar:builder.hubsTooltip" />,
        name: t('modeBar:builder.hubs'),
        href: routes.builder.assets.hubs,
        iconName: 'EykoHubs',
      },
      {
        key: 'groupsTooltip',
        title: <Trans i18nKey="modeBar:builder.groupsTooltip" />,
        name: t('modeBar:builder.groups'),
        href: routes.builder.assets.groups,
        iconName: 'RowsGroup',
      },
      {
        key: 'machine_learning',
        title: <strong>{t('modeBar:catalog.machineLearning')}</strong>,
        name: t('modeBar:catalog.machineLearning'),
        href: routes.builder.assets.machineLearning,
        iconName: 'Robot',
      },
      {
        key: 'preview',
        title: <Trans i18nKey="modeBar:builder.previewTooltip" />,
        name: t('modeBar:builder.preview'),
        href: routes.builder.assets.preview,
        iconName: 'Streaming',
      },
      {
        key: 'filters',
        title: <Trans i18nKey="modeBar:builder.filtersTooltip" />,
        name: t('modeBar:builder.filters'),
        href: routes.builder.assets.filters.index,
        iconName: 'Filter',
      },
    ],
  },
];

const designsSections = (t) => [
  {
    key: 'content library',
    name: <strong>{t('sectionBar:designer.designs')}</strong>,
    href: routes.designer.contentLibrary.designs,
    iconName: 'ContentLibrary',
    subSections: [
      {
        key: 'Designs',
        title: <Trans t={t} i18nKey="modeBar:contentLibrary.designsTooltip" />,
        name: t('modeBar:contentLibrary.designs'),
        href: routes.designer.contentLibrary.designs,
        iconName: 'Brush',
      },
    ],
  },
  {
    key: 'ingestion',
    name: <strong>{t('sectionBar:designer.ingestion')}</strong>,
    href: routes.designer.ingestion.index,
    iconName: 'CloudUpload',
    hideConditions: [HideSectionCondition.whenNoDesignIsSelected],
    subSections: [
      {
        key: 'sources',
        title: <Trans i18nKey="modeBar:ingestion.sourcesTooltip" />,
        name: t('modeBar:ingestion.sources'),
        href: routes.designer.ingestion.sources,
        iconName: 'Database',
      },
      {
        key: 'data',
        title: <Trans i18nKey="modeBar:ingestion.dataTooltip" />,
        name: t('modeBar:ingestion.data'),
        href: routes.designer.ingestion.data,
        iconName: 'EykoData',
      },
      {
        key: 'transforms',
        title: (
          <strong>
            <Trans i18nKey="modeBar:ingestion.transforms" />
          </strong>
        ),
        name: t('modeBar:ingestion.transforms'),
        href: routes.designer.ingestion.transforms,
        iconName: 'EykoTransform',
      },
      {
        key: 'filters',
        title: <Trans i18nKey="modeBar:ingestion.filtersTooltip" />,
        name: t('modeBar:ingestion.filters'),
        href: routes.designer.ingestion.filters.index,
        iconName: 'Filter',
      },
    ],
  },
  {
    key: 'catalog',
    name: <strong>{t('sectionBar:designer.catalog')}</strong>,
    href: routes.designer.catalog.index,
    iconName: 'EykoCatalog',
    hideConditions: [HideSectionCondition.whenNoDesignIsSelected],
    subSections: [
      {
        key: 'sources',
        title: <Trans i18nKey="modeBar:catalog.sourcesTooltip" />,
        name: t('modeBar:catalog.sources'),
        href: routes.designer.catalog.sources,
        iconName: 'Database',
      },
      {
        key: 'data',
        title: <Trans i18nKey="modeBar:catalog.dataTooltip" />,
        name: t('modeBar:catalog.data'),
        href: routes.designer.catalog.data,
        iconName: 'EykoData',
      },
      {
        key: 'links',
        title: <Trans i18nKey="modeBar:ingestion.linksTooltip" />,
        name: t('modeBar:ingestion.links'),
        href: routes.designer.catalog.links,
        iconName: 'Link',
      },
      {
        key: 'calculations',
        title: <Trans i18nKey="modeBar:catalog.calculationsTooltip" />,
        name: t('modeBar:catalog.calculations'),
        href: routes.designer.catalog.calculations,
        iconName: 'Calculator',
      },
      {
        key: 'hubs',
        title: <Trans i18nKey="modeBar:catalog.hubsTooltip" />,
        name: t('modeBar:catalog.hubs'),
        href: routes.designer.catalog.hubs,
        iconName: 'EykoHubs',
      },
      {
        key: 'groupsTooltip',
        title: <Trans i18nKey="modeBar:catalog.groupsTooltip" />,
        name: t('modeBar:catalog.groups'),
        href: routes.designer.catalog.groups,
        iconName: 'RowsGroup',
      },
      {
        key: 'machineLearning',
        title: <strong>{t('modeBar:catalog.machineLearning')}</strong>,
        name: t('modeBar:catalog.machineLearning'),
        href: routes.designer.catalog.machineLearning,
        iconName: 'Robot',
      },
    ],
  },
  {
    key: 'metadata',
    name: <strong>{t('sectionBar:designer.setup')}</strong>,
    href: routes.designer.metadata.index,
    iconName: 'WindowEdit',
    hideConditions: [HideSectionCondition.whenNoDesignIsSelected],
    subSections: [
      {
        key: 'Tags',
        title: <Trans i18nKey="modeBar:metadata.tagsTooltip" />,
        name: t('modeBar:metadata.tags'),
        href: routes.designer.metadata.tags,
        iconName: 'Tag',
      },
      ...(getEnvironment() !== Environment.Production
        ? [
            {
              key: 'Configuration',
              title: (
                <Trans t={t} i18nKey="modeBar:metadata.configurationTooltip" />
              ),
              name: t('modeBar:metadata.configuration'),
              href: routes.designer.metadata.catalogDefinition,
              iconName: 'MetadataConfiguration',
            },
          ]
        : []),
    ],
  },
];

const officeStreamerSections = (t, isGroupTable) => [
  {
    key: 'streams',
    name: i18n.t('sections:streams.name'),
    href: routes.streams.index,
    iconName: 'Streaming',
    hideConditions: [HideSectionCondition.whenStreamIsNotSelected],
    subSections: [
      {
        key: 'items',
        href: routes.streams.list.index,
        iconName: 'EykoData',
        title: <Trans t={t} i18nKey="modeBar:streams.dataTooltip" />,
        name: <Trans t={t} i18nKey="modeBar:streams.data" />,
      },
      {
        key: 'layout',
        title: (
          <strong>
            <Trans t={t} i18nKey="modeBar:streams.layout" />
          </strong>
        ),
        name: <Trans t={t} i18nKey="modeBar:streams.layout" />,
        href: isGroupTable
          ? routes.groupTable.design
          : routes.streams.design.index,
        iconName: 'SynergiesLayout',
      },
      {
        key: 'filter_values',
        title: <Trans t={t} i18nKey="modeBar:streams.filtersTooltip" />,
        name: <Trans t={t} i18nKey="modeBar:streams.filters" />,
        iconName: 'Filter',
        href: routes.streams.filters.index,
      },
      {
        key: 'table_legend',
        title: (
          <strong>
            <Trans i18nKey="modeBar:streams.tableLegend" />
          </strong>
        ),
        name: <Trans i18nKey="modeBar:streams.tableLegend" />,
        iconName: 'Info',
        href: routes.streams.tableLegend.index,
        hideConditions: [HideSectionCondition.whenTableWasNotPlayed],
      },
    ],
  },
  {
    key: 'visuals',
    name: i18n.t('sections:visuals.name'),
    href: routes.visuals.tables.index,
    iconName: 'VisualsFolder',
    hideConditions: [
      HideSectionCondition.whenSyncing,
      HideSectionCondition.whenStreamIsNotSelected,
    ],
    subSections: [
      {
        key: 'objects',
        title: <Trans t={t} i18nKey="streamsSetup:objectsTooltip" />,
        name: <Trans t={t} i18nKey="streamsSetup:objects" />,
        href: routes.visuals.tables.index,
        iconName: 'WebComponents',
      },
    ],
  },
  {
    key: 'favorites',
    name: <Trans t={t} i18nKey="streamsSetup:favorites" />,
    href: routes.favorites.index,
    iconName: 'FavoriteStar',
    hideConditions: [
      HideSectionCondition.whenSyncing,
      HideSectionCondition.whenDatasetIsSelected,
    ],
    subSections: [
      {
        key: 'recent-streams',
        title: <Trans t={t} i18nKey="streamsSetup:recentsTooltip" />,
        name: <Trans t={t} i18nKey="streamsSetup:recents" />,
        href: routes.favorites.recentStreams.index,
        iconName: 'History',
        hideConditions: [HideSectionCondition.whenNoRecentStreams],
      },
      {
        key: 'favorites',
        title: <Trans t={t} i18nKey="streamsSetup:favoritesTooltip" />,
        name: <Trans t={t} i18nKey="streamsSetup:favorites" />,
        href: routes.favorites.sharedWithMe.index,
        iconName: 'FavoriteStar',
      },
    ],
  },
];

const isolatedStreamerSections = (t) => [
  {
    key: 'streamSelecting',
    title: <Trans t={t} i18nKey="streamsSetup:favoritesTooltip" />,
    name: <Trans t={t} i18nKey="streamsSetup:favorites" />,
    href: routes.streamer.streamSelecting.index,
    iconName: 'FavoriteStar',
    subSections: [
      {
        key: 'pick_stream',
        title: <Trans t={t} i18nKey="streamsSetup:favoritesTooltip" />,
        name: <Trans t={t} i18nKey="streamsSetup:favorites" />,
        href: routes.streamer.streamSelecting.pickStream,
        iconName: 'FavoriteStar',
      },
    ],
  },
  {
    key: 'data_mode',
    title: <Trans t={t} i18nKey="streamsSetup:favoritesTooltip" />,
    name: <Trans t={t} i18nKey="streamsSetup:favorites" />,
    href: routes.streamer.dataMode.index,
    iconName: 'Database',
    subSections: [
      {
        key: 'select_assets',
        title: <Trans t={t} i18nKey="modeBar:streams.dataTooltip" />,
        name: <Trans t={t} i18nKey="modeBar:streams.data" />,
        href: routes.streamer.dataMode.selectAssets,
        iconName: 'EykoData',
      },
      {
        key: 'layout',
        href: routes.streamer.dataMode.layout,
        title: (
          <strong>
            <Trans t={t} i18nKey="modeBar:streams.layout" />
          </strong>
        ),
        name: <Trans t={t} i18nKey="modeBar:streams.layout" />,
        iconName: 'SynergiesLayout',
      },
      {
        key: 'filters',
        title: <Trans i18nKey="modeBar:builder.filtersTooltip" />,
        name: t('modeBar:builder.filters'),
        href: routes.streamer.dataMode.filters.index,
        iconName: 'Filter',
      },
      {
        key: 'output',
        title: (
          <strong>
            <Trans t={t} i18nKey="modeBar:streams.output" />
          </strong>
        ),
        name: <Trans t={t} i18nKey="modeBar:streams.output" />,
        href: routes.streamer.dataMode.output,
        iconName: 'EykoOutput',
        component: OutputModeBarButton,
      },
    ],
  },
];

interface ModuleParameters {
  isGroupTable: boolean;
  t: any;
}

export const getModules = ({ isGroupTable, t }: ModuleParameters): Array<any> =>
  [
    isOfficeEnabled()
      ? {
          key: Modules.Streamer,
          visibleForRoles: [
            UserRoles.Streamer,
            UserRoles.Builder,
            UserRoles.Designer,
            UserRoles.Admin,
          ],
          title: <Trans t={t} i18nKey="leftPanel:StreamerTooltip" />,
          icon: 'Streaming',
          path: routes.streamingFlow.index,
          activeRootPaths: [
            routes.visuals.index,
            routes.favorites.index,
            routes.streams.index,
            routes.process.streamFromStreamer,
          ],
          sections: officeStreamerSections(t, isGroupTable),
        }
      : {
          key: Modules.Streamer2,
          visibleForRoles: [
            UserRoles.Streamer,
            UserRoles.Builder,
            UserRoles.Designer,
            UserRoles.Admin,
          ],
          title: <Trans t={t} i18nKey="leftPanel:StreamerTooltip" />,
          icon: 'Streaming',
          path: routes.streamer.index,
          sections: isolatedStreamerSections(t),
        },
    isDesignerBuilderBackdoorApplied()
      ? {
          key: Modules.Builder,
          title: <Trans t={t} i18nKey="leftPanel:BuilderTooltip" />,
          icon: 'SynergiesBuilder',
          path: routes.builder.index,
          activeRootPaths: [
            routes.process.streamFromBuilder,
            routes.share.stream,
            generatePath(routes.add.nestedEntitySchema, {
              schemaId: TYPE_IDS.Stream,
            }),
            generatePath(routes.edit.entitySchema, {
              schemaId: TYPE_IDS.Stream,
            }),
            generatePath(routes.delete.topLevelEntitySchema, {
              schemaId: TYPE_IDS.Stream,
            }),
          ],
          sections: builderSections(t),
          visibleForRoles: [
            UserRoles.Builder,
            UserRoles.Designer,
            UserRoles.Admin,
          ],
        }
      : null,
    isDesignerBuilderBackdoorApplied()
      ? {
          key: Modules.Designer,
          title: <Trans t={t} i18nKey="leftPanel:DesignerTooltip" />,
          icon: 'SynergiesDesigner',
          path: routes.designer.index,
          activeRootPaths: [
            routes.designer.index,
            routes.share.design,
            [
              generatePath(routes.add.topLevelEntity, {
                schemaId: TYPE_IDS.Design,
              }),
              generatePath(routes.add.nestedEntitySchema, {
                schemaId: TYPE_IDS.Calculation,
              }),
              generatePath(routes.add.nestedEntitySchema, {
                schemaId: TYPE_IDS.Group,
              }),
              generatePath(routes.add.nestedEntitySchema, {
                schemaId: TYPE_IDS.Hub,
              }),
              generatePath(routes.add.nestedEntitySchema, {
                schemaId: TYPE_IDS.DataSource,
              }),
              generatePath(routes.add.nestedEntitySchema, {
                schemaId: TYPE_IDS.Link,
              }),
            ],
            [
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.Design,
              }),
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.Calculation,
              }),
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.Group,
              }),
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.Hub,
              }),
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.DataSource,
              }),
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.Link,
              }),
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.SourceEntity,
              }),
              generatePath(routes.edit.entitySchema, {
                schemaId: TYPE_IDS.SourceEntityField,
              }),
            ],
            [
              generatePath(routes.delete.topLevelEntitySchema, {
                schemaId: TYPE_IDS.Design,
              }),
              generatePath(routes.delete.topLevelEntitySchema, {
                schemaId: TYPE_IDS.Calculation,
              }),
              generatePath(routes.delete.topLevelEntitySchema, {
                schemaId: TYPE_IDS.Group,
              }),
              generatePath(routes.delete.topLevelEntitySchema, {
                schemaId: TYPE_IDS.Hub,
              }),
              generatePath(routes.delete.topLevelEntitySchema, {
                schemaId: TYPE_IDS.DataSource,
              }),
              generatePath(routes.delete.topLevelEntitySchema, {
                schemaId: TYPE_IDS.Link,
              }),
              generatePath(routes.delete.topLevelEntitySchema, {
                schemaId: TYPE_IDS.Tag,
              }),
            ],
          ],
          visibleForRoles: [UserRoles.Designer, UserRoles.Admin],
          sections: designsSections(t),
        }
      : null,
    {
      key: Modules.Notifications,
      title: <Trans t={t} i18nKey="leftPanel:NotificationsTooltip" />,
      name: <Trans t={t} i18nKey="modeBar:notifications.activityFeed" />,
      Component: NotificationsSidebarButton,
      sections: notificationSections(t),
      path: routes.notifications.index,
    },
    {
      key: Modules.Settings,
      title: <Trans t={t} i18nKey="leftPanel:SettingsTooltip" />,
      icon: 'Settings',
      path: routes.settings.index,
      sections: settingsSections(t),
    },
    {
      key: Modules.Help,
      title: <Trans t={t} i18nKey="leftPanel:HelpTooltip" />,
      icon: 'Help',
      path: routes.help.index,
      sections: helpSections(t),
    },
  ].filter((section) => section?.key);
