import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Streams';

export const TUTORIAL_TILE_KEY = 'streamer-main';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:streamer:main:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:streamer:main:items:pickStream:description',
      icon: 'EykoStreams',
      titleI18nKey: 'tutorialTiles:streamer:main:items:pickStream:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:streamer:main:items:selectObject:description',
      icon: 'WebComponents',
      titleI18nKey: 'tutorialTiles:streamer:main:items:selectObject:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:streamer:main:items:streamYourData:description',
      icon: 'Play',
      titleI18nKey: 'tutorialTiles:streamer:main:items:streamYourData:title',
    },
  ],
};
