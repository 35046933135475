import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { routes } from '../../../../../../../constants/routes';
import { getSelectedStreamFiltersList } from '../../../../../../StreamFilters/selectors';
import { HeaderBarButton } from '../../../../../../../components/ui';

const AddButton : React.FC = () => {
  const { t } = useTranslation('headers');
  const history = useHistory();
  const { pathname } = useLocation();
  const selectedFilters = useSelector(getSelectedStreamFiltersList);

  const isCreateOrEdit = !!matchPath(pathname, {
    path: [routes.streams.filters.create, routes.streams.filters.edit],
    exact: false,
  });

  const isDisabled = isCreateOrEdit || selectedFilters.length > 0;

  const handleClick = async () => {
    history.push(routes.streams.filters.create);
  };

  return (
    <HeaderBarButton
      tooltip={(
        <Trans
          t={t}
          i18nKey="actionBar:filters.add"
        />
      )}
      onClick={handleClick}
      iconProps={{ iconName: 'Add' }}
      data-testid="add-filter-top-menu"
      disabled={isDisabled}
      shouldHideOnDisabled
    />
  );
};

export default AddButton;
