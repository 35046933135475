import { all } from 'redux-saga/effects';

import topLevelEntitiesSaga from './topLevelEntities';
import nestedEntities from './nestedEntities';
import metadataSagas from '../Metadata/sagas';
import entityFormSagas from '../Form/sagas';
import ingestionSagas from '../Ingestion/sagas';
import catalogSagas from '../Catalog/sagas';

export function* rootSaga() {
  yield all([
    topLevelEntitiesSaga(),
    nestedEntities(),
    metadataSagas(),
    entityFormSagas(),
    ingestionSagas(),
    catalogSagas(),
  ]);
}

export default rootSaga;
