import { IContextualMenuItem } from '@fluentui/react';
import { AggregationType } from '../api/model/schemas/AggregationType';
import { DataType } from '../api/model/schemas/DataType';
import {
  AGGREGATION_METHODS,
  COLUMN_DATA_TYPES,
  getAggregations,
} from '../constants/aggregations';
import { IColumn, IColumnAggregationMethods } from '../types/IColumn';

export const getInvalidAggregationMethods = (
  column: IColumn,
  additionalInvalidMethods?: IColumnAggregationMethods[]
) => {
  let invalid: IColumnAggregationMethods[] = [
    ...(additionalInvalidMethods ?? []),
  ];
  if (
    ![
      COLUMN_DATA_TYPES.NumericDecimal,
      COLUMN_DATA_TYPES.NumericInteger,
    ].includes(column.dataType)
  ) {
    invalid = [
      ...invalid,
      AGGREGATION_METHODS.Average,
      AGGREGATION_METHODS.Sum,
    ];
  }

  if (
    ![
      COLUMN_DATA_TYPES.NumericDecimal,
      COLUMN_DATA_TYPES.NumericInteger,
      COLUMN_DATA_TYPES.DateTime,
    ].includes(column.dataType)
  ) {
    invalid = [
      ...invalid,
      AGGREGATION_METHODS.Maximum,
      AGGREGATION_METHODS.Minimum,
    ];
  }

  return invalid;
};

type Params = {
  column: IColumn;
  hasAtLeastOneValueSelected: boolean;
  isGroupTable: boolean;
};

export const getInvalidAggregationMethodsForGroupTable = ({
  column,
  hasAtLeastOneValueSelected,
  isGroupTable,
}: Params): (IColumnAggregationMethods | null)[] => {
  const invalid: ReturnType<typeof getInvalidAggregationMethodsForGroupTable> =
    getInvalidAggregationMethods(column);

  if (!hasAtLeastOneValueSelected && isGroupTable) {
    invalid.push(null);
  }

  return invalid;
};

export type AggregationsToMenuItemsParams = {
  aggregationsToOmit: IColumnAggregationMethods[];
  getIsChecked: (currentAggregaion: IColumnAggregationMethods) => boolean;
  getIsDisabled?: (currentAggregaion: IColumnAggregationMethods) => boolean;
  isAggregatingValuesAllowed: boolean;
};

export const aggregationsToMenuItems = ({
  aggregationsToOmit,
  getIsChecked,
  getIsDisabled,
  isAggregatingValuesAllowed,
}: AggregationsToMenuItemsParams): IContextualMenuItem[] => {
  const AGGREGATIONS = getAggregations();

  const mapAggregations = (agg: IColumnAggregationMethods) => ({
    key: agg,
    text: AGGREGATIONS[agg].label,
    iconProps: {
      iconName: AGGREGATIONS[agg].icon,
    },
    canCheck: true,
    checked: getIsChecked(agg),
    disabled: getIsDisabled ? getIsDisabled(agg) : false,
  });

  if (!isAggregatingValuesAllowed) {
    return [AGGREGATION_METHODS.null].map(mapAggregations);
  }

  const menuItems: IContextualMenuItem[] = Object.values(AGGREGATION_METHODS)
    .sort((aggNameA, aggNameB) =>
      AGGREGATIONS[aggNameA].label.localeCompare(AGGREGATIONS[aggNameB].label)
    )
    .map(mapAggregations)
    .filter((item) => !aggregationsToOmit.includes(item.key));

  return menuItems;
};

export const getAlreadyUsedAggregations = (columns: IColumn[], syncedColumn) =>
  columns.filter((c) => c.id === syncedColumn.id).map((c) => c.aggregation);

export const getAlreadyUsedAggregationsFiltered = (
  columns: IColumn[],
  syncedColumn: IColumn
) =>
  columns
    .filter(
      (c) =>
        c.id === syncedColumn.id && c.aggregation !== syncedColumn.aggregation
    )
    .map((c) => c.aggregation);

// v4
export const getInvalidAggregationMethodsApiV4 = (dataType: DataType) => {
  let invalid: AggregationType[] = [];
  if (![DataType.NumericDecimal, DataType.NumericInteger].includes(dataType)) {
    invalid = [...invalid, AggregationType.Average, AggregationType.Sum];
  }

  if (
    ![
      DataType.NumericDecimal,
      DataType.NumericInteger,
      DataType.DateTime,
    ].includes(dataType)
  ) {
    invalid = [...invalid, AggregationType.Maximum, AggregationType.Minimum];
  }

  return invalid;
};

export const getAggregationsForAlreadyAggregatedItems = () =>
  Object.values({ ...AggregationType }).filter(
    (val) => val !== AggregationType.None
  );
