import React from 'react';
import { PrimaryButton, IButtonProps, IButtonStyles } from '@fluentui/react';
import merge from 'lodash/merge';

import { baseColors } from '../../../theme';

const baseStyles: IButtonStyles = {
  root: {
    background: baseColors.iconColor,
    border: `1px solid ${baseColors.iconColor}`,
    color: baseColors.white,
  },
  rootHovered: {
    border: `1px solid ${baseColors.iconColor}`,
    background: baseColors.iconColor,
    color: baseColors.white,
  },
};

const StyledPrimaryButton = ({
  styles,
  children,
  ...restProps
}: IButtonProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <PrimaryButton {...restProps} styles={merge(styles, baseStyles)}>
    {children}
  </PrimaryButton>
);

export default StyledPrimaryButton;
