import { ItemActionsHeaderProps, ItemActionButton } from './types';

export const getConfig = ({
  handleAdd,
  isItemSelected,
  handleCopy,
  handleDelete,
  handleEdit,
  handleShare,
}:ItemActionsHeaderProps):ItemActionButton[] => ([
  {
    handleClick: handleAdd,
    iconName: 'Add',
    testId: 'actionbar-add-btn',
    tooltipKey: 'actionBar:general.add',
    // always show add btn
    disabled: false,
  },
  {
    handleClick: handleEdit,
    iconName: 'Edit',
    testId: 'actionbar-edit-btn',
    tooltipKey: 'actionBar:general.edit',
    disabled: !isItemSelected,
  },
  {
    handleClick: handleCopy,
    iconName: 'Copy',
    testId: 'actionbar-copy-btn',
    tooltipKey: 'actionBar:general.copy',
    disabled: !isItemSelected,
  },
  {
    handleClick: handleDelete,
    iconName: 'Delete',
    testId: 'actionbar-delete-btn',
    tooltipKey: 'actionBar:general.delete',
    disabled: !isItemSelected,
  },
  {
    handleClick: handleShare,
    iconName: 'Share',
    testId: 'actionbar-share-btn',
    tooltipKey: 'actionBar:general.share',
    disabled: !isItemSelected,
  },
].filter((singleConfig): singleConfig is ItemActionButton => Boolean(singleConfig.handleClick)));
