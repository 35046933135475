import React from 'react';

import { Stack } from '@fluentui/react';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { shimmerStackStyles } from '../../../../pageTemplates/WizardPage/styles';
import { useGetSyncingText } from '../../../../hooks/useGetSyncingText';

const BlockedUiOverlay: React.FC = () => {
  const { shouldShowOverlayWithText } = useGetSyncingText();

  if (!shouldShowOverlayWithText) return null;

  return (
    <Stack styles={shimmerStackStyles} data-testid="blocked-ui-overlay">
      <ItemsListShimmer />
    </Stack>
  );
};

export default BlockedUiOverlay;
