import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import EntityField from '../../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { GroupCollapse } from '../../../../../components/ui';
import { IFoldableListItem } from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import useEditableItems from '../../../../../hooks/useEditableItems';
import useEntitiesSelections from '../../../../../hooks/useEntitiesSelections';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { useSearchFilteredRecords } from '../../../../../hooks/useSearchFilteredRecords';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { getSelectedItemsIds } from '../../../../App/selectors';
import { DefinedSortTypes } from '../../../../App/types';
import { updateEntity } from '../../../Catalog/actions';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { DataSourceConfigureStatus, IDesignSourceEntity } from '../../../types';
import { fetchDataSourceTransforms } from '../../actions';
import { getDataSourceTransforms, getSelectedSource } from '../../selectors';

const Transforms: React.FC = () => {
  // STATE
  const selectedDesignId = useSelector(getSelectedDesignId);
  const selectedSource = useSelector(getSelectedSource);
  const { records: transforms, isLoading } = useSelector(
    getDataSourceTransforms
  );
  const dispatch = useDispatch();

  const selectedEntitiesIds = useSelector(getSelectedItemsIds);

  // DERIVED STATE

  const { filteredRecords, searchQuery } = useSearchFilteredRecords(transforms);

  const transformsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds,
  });

  // HOOKS

  const { properties } = useGetParsedTypeProperties({
    designId: selectedDesignId,
    typeId: TYPE_IDS.SourceEntity,
  });

  const schema = useMemo(
    () => [...getSchemaFromTypeProperties(properties, TYPE_IDS.SourceEntity)],
    [properties]
  );

  const { handleItemClickedOnSingleSelection, selectedItem } =
    useItemSelections();

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: transformsWhichIncludesSelections,
      selectedItemId: selectedItem,
    });

  const onEditEndCallback = useCallback((hasChanged: boolean) => {
    if (hasChanged) dispatch(fetchDataSourceTransforms(selectedSource.id));
  }, []);

  const { isEditing, onEditEnd, onEditStart } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  // EFFECTS
  React.useEffect(() => {
    if (selectedSource?.id) {
      dispatch(fetchDataSourceTransforms(selectedSource.id));
    }
  }, [selectedSource]);

  // CALLBACKS

  const handleEntityRename = useCallback(
    (id: string, name: string) =>
      dispatch(
        updateEntity({
          typeId: TYPE_IDS.SourceEntity,
          entity: { id, name },
        })
      ),
    []
  );

  const renderTransform = (entity: IDesignSourceEntity) => {
    const isConfiguring =
      entity.configureStatus === DataSourceConfigureStatus.Configuring;

    return (
      <EntityField
        key={entity.id}
        slot1={<EntityFieldSlotSpacer />}
        isSyncing={isConfiguring}
        isDisabled={isConfiguring}
        isSelected={entity.id === selectedItem}
        info={{
          data: entity,
          schema,
          title: entity.name,
          enableInlineEdit: true,
        }}
        data-testid={`ingestion-transform-${entity.name}`}
      >
        <EditableFoldableListItem
          text={entity.name}
          onClick={() =>
            !isConfiguring && handleItemClickedOnSingleSelection(entity.id)
          }
          onEdit={(val) => handleEntityRename(entity.id, val)}
          onEditStart={() => {
            handleItemClickedOnSingleSelection(entity.id);
            onEditStart();
          }}
          onEditEnd={onEditEnd}
          searchQuery={searchQuery}
          transparentBackground
          disableEditing={isEditing || isConfiguring}
        />
      </EntityField>
    );
  };

  const renderItem = (item: IFoldableListItem) => {
    const entry = transforms.find((r) => r.id === item.key);
    return entry ? renderTransform(entry) : null;
  };

  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (currentSort === DefinedSortTypes.Name) {
    [...transformsWhichIncludesSelections].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    return (
      <section>{filteredRecords.map((item) => renderTransform(item))}</section>
    );
  }

  return (
    <section>
      {mappedGroups.map((group) => (
        <GroupCollapse
          onClick={onClickHeaderHandler}
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen || searchQuery !== ''}
          groupName={group.name}
        >
          {group.items.map((item) => renderItem(item))}
        </GroupCollapse>
      ))}
    </section>
  );
};

export default Transforms;
