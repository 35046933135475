import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { activeRequestsCount } from '../api/interceptors/requestsCount';
import { getIsOtherUserSyncing } from '../modules/App/selectors';
import { getIsProcessingStream } from '../modules/Builder/selectors';
import {
  getIsConfiguringDataSource,
  getIsIngestingDataSource,
  getIsSmartAnalysisLinksInProgress,
} from '../modules/Designer/Ingestion/selectors';
import { getIsFetchingStreamerOutput } from '../modules/Streamer/strategies/isolated/selectors';
import streamSelectors from '../modules/Streams/selectors';
import { useObservable } from '../utils/observable';
import { isInBuilder, isInDesignerLinks, isInStreamer } from '../utils/routes';

export const useGetSyncingText = () => {
  const { t } = useTranslation('notifications');
  const isOtherUserBlockingUI = useSelector(getIsOtherUserSyncing);
  const isSyncing = useSelector(streamSelectors.getIsSyncing);
  const checkingCurrentSelection = useSelector(
    streamSelectors.getIsCheckingCurrentSelection
  );
  const areStreamsPending = useSelector(streamSelectors.getAreStreamsPending);
  const isConfiguringDataSource = useSelector(getIsConfiguringDataSource);
  const isIngestingDataSource = useSelector(getIsIngestingDataSource);
  const isProcessingStreamInBuilder = useSelector(getIsProcessingStream);
  const isCurrentDatasetStreamProcessing = useSelector(
    streamSelectors.getIsCurrentDatasetStreamProcessing
  );
  const isRetrievingOutputData = useSelector(getIsFetchingStreamerOutput);
  const isSmartAnalysisLinksInProgress = useSelector(
    getIsSmartAnalysisLinksInProgress
  );
  const [requestCount] = useObservable(activeRequestsCount);
  const { pathname } = useLocation();

  const isVisible = requestCount > 0;

  const shouldShowOverlayWithText =
    isOtherUserBlockingUI ||
    isSyncing ||
    checkingCurrentSelection ||
    areStreamsPending ||
    isConfiguringDataSource ||
    isIngestingDataSource ||
    isProcessingStreamInBuilder ||
    isCurrentDatasetStreamProcessing ||
    isRetrievingOutputData ||
    isSmartAnalysisLinksInProgress;

  const getTextMessage = () => {
    if (isSmartAnalysisLinksInProgress && isInDesignerLinks(pathname)) {
      return 'linksDiscovery';
    }

    /// Requests below this if will be shown only if there are pending requests
    // there are cases where we want to show async process, so we have 0 pending requests
    if (!isVisible) return null;

    if (isOtherUserBlockingUI) {
      return 'uiBlocked';
    }

    if (isProcessingStreamInBuilder && isInBuilder(pathname)) {
      return 'processingStream';
    }

    if (isConfiguringDataSource) {
      return 'configuring';
    }

    if (isIngestingDataSource) {
      return 'ingestingDataSource';
    }

    if (isCurrentDatasetStreamProcessing && isInStreamer(pathname)) {
      return 'datasetStreamIsProcessing';
    }

    if (
      (isSyncing || checkingCurrentSelection || areStreamsPending) &&
      isInStreamer(pathname)
    ) {
      return 'retrievingData';
    }

    return 'retrievingData';
  };

  const textMessage = getTextMessage();

  return {
    syncingText: textMessage ? t(textMessage) : null,
    shouldShowOverlayWithText,
  };
};
