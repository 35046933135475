import * as effects from 'redux-saga/effects';

import * as api from '../api';
import { deleteNestedEntity } from '../actions';
import { clearSelection } from '../../App/actions';
import { createChildEntity } from '../Form/actions';

export function* handleDeleteTopLevelEntity({
  payload,
}:ReturnType<typeof deleteNestedEntity>) {
  yield effects.put({ type: 'SET_SYNCING', syncing: true });
  try {
    yield effects.call(api.deleteNestedEntity(payload));
    yield effects.put(clearSelection());
  } catch (error) {
    console.log('error', error);
  } finally {
    yield effects.put({ type: 'SET_SYNCING', syncing: false });
  }
}

export function* handleCreateChildEntity({
  payload: {
    schemaId,
    parentEntityId,
    data,
    callback,
  },
}:ReturnType<typeof createChildEntity>) {
  yield effects.put({ type: 'SET_SYNCING', syncing: true });
  try {
    yield effects.call(api.createNestedEntity({
      parentEntityId,
      typeId: schemaId,
    }), data);
    yield effects.call(callback);
  } catch (error) {
    console.log('error', error);
  } finally {
    yield effects.put({ type: 'SET_SYNCING', syncing: false });
  }
}

export function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(deleteNestedEntity, handleDeleteTopLevelEntity),
  ]);
}

export default rootSaga;
