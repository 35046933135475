import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import EntityField from '../../../../components/EntityField';
import GroupCollapse from '../../../../components/ui/GroupCollapse';
import {
  IFoldableListGroup,
  IFoldableListItem,
} from '../../../../components/ui/FoldableList/types/IFoldableListGroup';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';

import useGetParsedTypeProperties from '../../../../hooks/useGetParsedTypeProperties';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';

import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';

import { DefinedSortTypes } from '../../../App/types';
import {
  EntityProcessStatus,
  IDesignSourceEntityField,
  UIEnhancedDesignSourceEntityField,
} from '../../../Designer/types';

import {
  getEntityFields,
  getSelectedBuilderStreamId,
  getStreamFields,
  getIsUpdating,
  getActiveDataSource,
} from '../../selectors';

import {
  fetchDataSourceFields,
  fetchStreamFields,
  assetClicked,
  AssetClickedIntent,
  assetsDataGroupClicked,
} from '../../actions';
import { EmptyMessage } from '../../../../components/ui';
import { getSearchQuery } from '../../../Search/selectors';
import { matchesSearchQuery } from '../../../Search/utils';
import { getSchemaFromTypeProperties } from '../../../../utils/getSchemaFromTypeProperties';
import {
  normalizeById,
  normalizeByKey,
} from '../../../../utils/normalizeEntities';
import { getSelectedDesignId } from '../../../Designer/ContentLibrary/selectors';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { StreamAssetType } from '../../types';
import DataSourceCalloutTitle from '../../../Designer/Ingestion/components/DataSourceCalloutTitle';
import { ancestorSourceNameKey } from '../../../../constants/infoBoxes';
import { DataSourceEntityFieldKeys } from '../../../../api/model/schemas/DataSourceEntityField';
import { DataSourceEntityKeys } from '../../../../api/model/schemas/DataSourceEntity';

const Data: FunctionComponent = () => {
  // HOOKS - REDUX
  const dispatch = useDispatch();
  const selectedStreamId = useSelector(getSelectedBuilderStreamId);

  const activeDataSource = useSelector(getActiveDataSource);
  const designId = useSelector(getSelectedDesignId);

  const { fields, isLoading, entities } = useSelector(getEntityFields);
  const {
    records: streamFields,
    recordIdsBeingUpdated,
    isLoading: streamFieldsLoading,
  } = useSelector(getStreamFields);

  const isUpdating = useSelector(getIsUpdating);

  const searchQuery = useSelector(getSearchQuery);

  // HOOKS
  const { t } = useTranslation();
  const { properties: entityFieldProperties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.SourceEntityField,
  });

  const { properties: entityProperties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.SourceEntity,
  });

  // DERIVED STATE
  const filteredFields = useMemo(
    () =>
      fields.filter(
        (field) =>
          searchQuery === '' || matchesSearchQuery(searchQuery, field.name)
      ),
    [fields, searchQuery]
  );

  const normalizedSourceFields = normalizeById(fields);
  const normalizedStreamFieldsBySourceFieldId = normalizeByKey(
    streamFields,
    'sourceFieldId'
  );

  const selectedFieldsIds = React.useMemo(
    () => streamFields.map((f) => f.sourceFieldId),
    [streamFields]
  );

  const sourceFieldsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredFields,
    selectedEntitiesIds: selectedFieldsIds,
  });

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({ input: sourceFieldsWhichIncludesSelections });

  const entityFieldSchema = useMemo(() => {
    const schema = getSchemaFromTypeProperties(
      entityFieldProperties,
      TYPE_IDS.SourceEntityField
    );
    // TODO: contact Stephan about adding some of the missing attributes into the info box
    // NOTE: source of the entity probably has to be added on the FE side
    // since it is not a direct property of an entity

    const insertSourceAt = schema.findIndex(
      (entry) => entry.key === DataSourceEntityFieldKeys.sourceName
    );
    schema.splice(insertSourceAt + 1, 0, {
      key: ancestorSourceNameKey,
      name: t('entityTypes:Source'),
    });

    const insertTypeAt = schema.findIndex(
      (entry) => entry.key === DataSourceEntityFieldKeys.sourceDataType
    );
    schema.splice(insertTypeAt + 1, 0, {
      key: DataSourceEntityFieldKeys.dataType,
      translationPrefix: TYPE_IDS.SourceEntityField,
    });
    schema.splice(insertTypeAt + 2, 0, {
      key: DataSourceEntityFieldKeys.formatType,
      translationPrefix: TYPE_IDS.SourceEntityField,
    });

    return schema;
  }, [entityFieldProperties, t]);
  const entitySchema = useMemo(() => {
    // TODO: contact Stephan about adding the `sourceName` to the list of props displayed in the info box
    const schema = getSchemaFromTypeProperties(
      entityProperties,
      TYPE_IDS.SourceEntity
    );
    const insertIdx = schema.findIndex(
      (entry) => entry.key === DataSourceEntityKeys.name
    );
    schema.splice(insertIdx + 1, 0, {
      key: DataSourceEntityKeys.sourceName,
      translationPrefix: TYPE_IDS.SourceEntity,
    });
    // NOTE: source of the entity probably has to be added on the FE side
    // since it is not a direct property of an entity
    schema.splice(insertIdx + 2, 0, {
      key: ancestorSourceNameKey,
      name: t('entityTypes:Source'),
    });
    return schema;
  }, [entityProperties, t]);

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:catalog:data:cta:noSourcesSelected':
        !activeDataSource?.id,
    }),
    [activeDataSource?.id]
  );

  // CALLBACKS
  const onClickFieldHandler = React.useCallback(
    (field: IDesignSourceEntityField) => {
      dispatch(
        assetClicked({
          entity: field,
          actionType: selectedFieldsIds.includes(field.id)
            ? AssetClickedIntent.REMOVE
            : AssetClickedIntent.ADD,
          assetType: StreamAssetType.Field,
        })
      );
    },
    [dispatch, selectedFieldsIds]
  );

  const handleGroupClick = (group: IFoldableListGroup) => {
    const relatedFields = group.items.reduce<
      UIEnhancedDesignSourceEntityField[]
    >(
      (accumulator, item) => [...accumulator, normalizedSourceFields[item.key]],
      []
    );

    dispatch(
      assetsDataGroupClicked({
        entities: relatedFields,
        selectedEntitiesIds: selectedFieldsIds,
        assetType: StreamAssetType.Field,
      })
    );
  };

  // EFFECTS
  useEffect(() => {
    dispatch(fetchStreamFields({ streamId: selectedStreamId }));
    if (activeDataSource?.id) {
      dispatch(fetchDataSourceFields(activeDataSource?.id));
    }
  }, [activeDataSource?.id, selectedStreamId]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const renderField = (field: IDesignSourceEntityField) => {
    if (!field) return null;

    const isFieldSelected = selectedFieldsIds.includes(field.id);
    const isQueued =
      isFieldSelected &&
      normalizedStreamFieldsBySourceFieldId[field.id]?.processStatus ===
        EntityProcessStatus.NotProcessed;

    return (
      <EntityField
        key={field.id}
        info={{
          data: {
            ...field,
            [ancestorSourceNameKey]: activeDataSource.name,
          },
          schema: entityFieldSchema,
          title: (
            <DataSourceCalloutTitle
              name={field.name}
              color={`#${activeDataSource.color.toString(16)}`}
            />
          ),
        }}
        isSelected={isFieldSelected}
        isSelectable
        onClick={() => onClickFieldHandler(field)}
        slot1={<EntityFieldSlotSpacer />}
        isOrange={isQueued}
        name={field.name}
        searchQuery={searchQuery}
        isSyncing={recordIdsBeingUpdated.includes(field.id)}
        availability={field.availability}
        data-testid={`builder-assets-field-${field.name}`}
      />
    );
  };

  // RENDER
  if (isLoading || isUpdating || streamFieldsLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isLoading && fields.length === 0) {
    return <EmptyMessage message={t('builder:noIngestedFields')} />;
  }

  if (currentSort === DefinedSortTypes.Name) {
    return (
      <>
        {sourceFieldsWhichIncludesSelections
          .slice()
          .sort((fieldA, fieldB) => fieldA.name.localeCompare(fieldB.name))
          .map((field) => renderField(field))}
      </>
    );
  }

  const renderItem = (item: IFoldableListItem) => {
    const field = normalizedSourceFields[item.key];

    return field ? renderField(field) : null;
  };

  const getEntityByName = (name: string) =>
    entities.find((e) => e.name === name) || {};

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          isSyncing={group.items.some((item) =>
            recordIdsBeingUpdated.includes(item.key)
          )}
          isSelected={group.items.every((item) =>
            selectedFieldsIds.includes(item.key)
          )}
          selections={{
            current: selectedFieldsIds.filter((id) =>
              group.items.map((item) => item.key).includes(id)
            ).length,
            total: group.items.length,
          }}
          groupName={group.name}
          onIconClick={onClickHeaderHandler}
          onTextClick={() => handleGroupClick(group)}
          info={{
            schema: entitySchema,
            data: {
              ...getEntityByName(group.key),
              [ancestorSourceNameKey]: activeDataSource.name,
            },
            title: (
              <DataSourceCalloutTitle
                name={group.name}
                color={`#${activeDataSource.color.toString(16)}`}
              />
            ),
          }}
        >
          {group.items.map((item) => renderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default Data;
