/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CalloutBase, { Props as BaseProps } from '../../../../../components/ui/InformationDialog';
import { IEntityFormSection } from '../../types';
import Property from '../Property';
import { translateApiName } from '../../../../../config/i18n/utils';

interface Props extends Omit<BaseProps, 'onApprove' | 'onDismiss'> {
  schema: IEntityFormSection,
  defaultValues: any,
  onSubmit: (data: any) => void,
}

const Callout: React.FC<Props> = ({
  schema, defaultValues, onSubmit,
  ...props
}) => {
  const typeId = schema?.parentId;
  const submitRef = useRef(null);
  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const watched = watch(undefined, defaultValues);
  const onApprove = React.useMemo(
    // NOTE: `CalloutBase` will not display the submit button if no `onApprove` method is passed in
    // so the button will only be shown for dirty forms
    () => methods.formState.isDirty
      ? () => submitRef.current?.click()
      : undefined,
    [methods.formState.isDirty],
  )

  return (
    <CalloutBase
      {...props}
      {...{ onApprove }}
      // eslint-disable-next-line react/destructuring-assignment
      onDismiss={props.onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="callout_form">
          {schema?.properties
              .filter((prop) => prop.name !== 'name')
              .map((property) => (
                <Property
                  name={property.name}
                  label={translateApiName(typeId, property.name)}
                  watched={watched}
                  key={property.id}
                  property={property}
                  currentForm={{
                    typeId: schema.id,
                    data: defaultValues,
                    sections: [],
                  }}
                  currentEntity={null}
                  translationPrefix={`api:types:${typeId}`}
                />
              ))}
          <input style={{ display: 'none' }} ref={submitRef} type="submit" form="callout_form" />
        </form>
      </FormProvider>
    </CalloutBase>
  );
};

export default Callout;
