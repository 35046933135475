import { DataModeState } from './types';
import * as dataModeActions from './screens/Data/actions';
import { DataSourceEntity } from '../../../api/model/schemas/DataSourceEntity';

interface SetDataModeEntitiesAndFieldsArgs
  extends Partial<dataModeActions.FetchEntitiesAndFieldsResult> {
  dataModeState: DataModeState;
}
// NOTE: this method mutates the state because it's supposed to work with Immer proxies
export const setDataModeEntitiesAndFields = ({
  dataModeState,
  entities: newEntities,
  entitiesNextPageUrl,
  fields: newFields,
  fieldsNextPageUrl,
}: SetDataModeEntitiesAndFieldsArgs) => {
  try {
    const { entities, fieldsPerEntity } = dataModeState;

    // for any new entities
    newEntities?.forEach((entity) => {
      entities[entity.id] = entity;
    });

    // for any new fields
    newFields?.forEach(({ parent: parentEntity, ...field }) => {
      // ensure there is a dictionary to save the field to...
      if (!fieldsPerEntity[field.parentId])
        fieldsPerEntity[field.parentId] = {};
      // ...and save the field but without the parent, which will be stored elswhere
      fieldsPerEntity[field.parentId][field.id] = field;

      // in case there is an extension to the parent entity present
      if (parentEntity) {
        // make sure the entity object we're about to update exists
        entities[parentEntity.id] =
          entities[parentEntity.id] ?? ({} as DataSourceEntity);
        // when using the power of immer to update the entity props, the reference to the entity itself will change
        // only if any of the property values actually change – that's why we iterate over the props to re-evaluate them
        Object.keys(parentEntity).forEach((key) => {
          entities[parentEntity.id][key] = parentEntity[key];
        });
      }
    });

    // update the next page urls if applicable
    Object.assign<DataModeState, Partial<DataModeState>>(dataModeState, {
      entitiesNextPageUrl,
      fieldsNextPageUrl,
    });
  } catch (error) {
    console.error(error);
  }
};
