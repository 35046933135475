import { mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../../../../../theme';

const keys = {
  cell: 'cell',
  wrapper: 'wrapper',
  table: 'table',
  filterIcon: 'filterIcon',
  alignRight: 'alignRight',
  tableHeaderAggIcon: 'tableHeaderAggIcon',
  shimmerWrap: 'shimmerWrap',
  headerGrid: 'headerGrid',
  footerGrid: 'footerGrid',
  bodyGrid: 'bodyGrid',
};

export const classNames = mergeStyleSets({
  wrapper: [
    keys.wrapper,
    {
      overflow: 'hidden!important',
      boxSizing: 'border-box',
    },
  ],
  table: [
    keys.table,
    {
      borderSpacing: 0,
      minWidth: '100%',
      border: `1px solid ${baseColors.outlineGray}`,
      borderRadius: 4,
      selectors: {
        '& th': {
          textAlign: 'left',
          borderBottom: `1px solid ${baseColors.outlineGray}`,
        },
        '& tfoot td': {
          borderTop: `1px solid ${baseColors.outlineGray}`,
        },
        '& th, & tfoot td': {
          color: baseColors.midOrange,
          fontWeight: 'normal',
          padding: '0px 8px',
          height: 28,
          backgroundColor: '#F5F8FA',
          whiteSpace: 'nowrap',
        },
        '& td': {
          padding: '4px 8px',
          whiteSpace: 'nowrap',
        },
        '& tbody tr:hover': {
          backgroundColor: baseColors.hover,
        },
        '& th:not(:last-child)': {
          borderRight: `1px solid ${baseColors.outlineGray}`,
        },
        '& td:not(:last-child)': {
          borderRight: `1px solid ${baseColors.outlineGray}`,
        },
      },
    },
  ],
  filterIcon: [
    keys.filterIcon,
    {
      color: baseColors.outlineGray,
      marginRight: 4,
      fontSize: 18,
      verticalAlign: 'middle',
    },
  ],
  alignRight: [
    keys.alignRight,
    {
      textAlign: 'right',
    },
  ],
  tableHeaderAggIcon: [
    keys.tableHeaderAggIcon,
    {
      width: '12px',
      height: '12px',
      marginRight: '4px',
    },
  ],
  shimmerWrap: [
    keys.shimmerWrap,
    {
      width: '100%',
    },
  ],
  cell: [
    keys.cell,
    {
      verticalAlign: 'middle',
      padding: '4px 8px',
    },
  ],
  headerGrid: [
    keys.headerGrid,
    {
      overflow: 'hidden!important',
      selectors: {
        [`& .${keys.cell}`]: {
          color: baseColors.midOrange,
          fontWeight: 'normal',
          padding: '0px 8px',
          height: 28,
          backgroundColor: '#F5F8FA',
          whiteSpace: 'nowrap',
          border: `1px solid ${baseColors.outlineGray}`,
          display: 'flex',
          alignItems: 'center',
        },
        [`& .${keys.cell}:first-child`]: {
          borderRadius: '4px 0 0 0',
        },
        [`& .${keys.cell}:last-child`]: {
          borderRadius: '0 4px 0 0',
        },
        [`& .${keys.cell}:not(:first-child)`]: {
          borderLeft: 'none',
        },
      },
    },
  ],
  footerGrid: [
    keys.footerGrid,
    {
      overflow: 'hidden!important',
      selectors: {
        [`& .${keys.cell}`]: {
          color: baseColors.midOrange,
          fontWeight: 'normal',
          padding: '0px 8px',
          height: 28,
          backgroundColor: '#F5F8FA',
          whiteSpace: 'nowrap',
          border: `1px solid ${baseColors.outlineGray}`,
          display: 'flex',
          alignItems: 'center',
          [`& .${keys.cell}:first-child`]: {
            borderRadius: '0 0 0 4px',
          },
          [`& .${keys.cell}:last-child`]: {
            borderRadius: '0 0 4px 0',
          },
          [`& .${keys.cell}:not(:first-child)`]: {
            borderLeft: 'none',
          },
          [`& .${keys.cell}>div`]: {
            width: '100%',
          },
        },
      },
    },
  ],
  bodyGrid: [
    keys.bodyGrid,
    {
      selectors: {
        [`& .${keys.cell}`]: {
          borderRight: `1px solid ${baseColors.outlineGray}`,
        },
        [`& .${keys.cell}.first`]: {
          borderLeft: `1px solid ${baseColors.outlineGray}`,
        },
        [`& .${keys.cell} > div`]: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        [`& .${keys.cell}.hover`]: {
          backgroundColor: baseColors.hover,
        },
        [`& .${keys.cell}.lastRow`]: {
          borderBottom: `1px solid ${baseColors.outlineGray}`,
        },
        [`& .${keys.cell}.lastRow.lastCell`]: {
          borderBottomRightRadius: 4,
        },
        [`& .${keys.cell}.lastRow.first`]: {
          borderBottomLeftRadius: 4,
        },
      },
    },
  ],
});
