import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import { BuilderProcessStreamButton } from '../BuilderProcessStreamButton/BuilderProcessStreamButton';

import FiltersHeader from '../FiltersHeader/FiltersHeader';
import { LinksHeader } from '../LinksHeader/LinksHeader';
import StreamsHeader from '../StreamsHeader';

const BuilderHeader: React.FC = () => (
  <>
    <Switch>
      <Route path={routes.builder.streams.index} component={StreamsHeader} />
      <Route path={routes.builder.assets.links} component={LinksHeader} />
      <Route path={routes.builder.assets.filters.index}>
        <FiltersHeader />
        {/* placed separately to not break button order in the StreamsHeader */}
        <BuilderProcessStreamButton />
      </Route>
      <Route>
        {/* placed separately to not break button order in the StreamsHeader */}
        <BuilderProcessStreamButton />
      </Route>
    </Switch>
  </>
);

export default BuilderHeader;
