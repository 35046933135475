import { differenceWith, isEqual } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { SelectionType } from '../../../../components/ui/ViewControlButton';
import { getSelectionType } from '../../../App/selectors';
import { getSearchQuery } from '../../../Search/selectors';
import { matchesSearchQuery } from '../../../Search/utils';
import { getAvailableAssets, getSelectedAssets } from '../../selectors';
import { StreamAssetRaw } from '../../types';
import { ByValuesGroupCollapse } from './ByValuesGroupCollapse';

export const ByValues = () => {
  // STATE
  const selectionType = useSelector(getSelectionType);
  const { records: allRawAssets } = useSelector(getAvailableAssets);
  const selectedAssets = useSelector(getSelectedAssets);
  const searchQuery = useSelector(getSearchQuery);

  // DERIVED STATE
  const selectedColumnsWithNoAggregation = React.useMemo(
    () =>
      allRawAssets.filter((asset) =>
        selectedAssets.some(
          (selectedAsset) => selectedAsset.streamElementId === asset.id
        )
      ),
    [allRawAssets, selectedAssets]
  );
  const unselectedColumns = React.useMemo(
    () =>
      differenceWith<StreamAssetRaw, StreamAssetRaw>(
        allRawAssets,
        selectedColumnsWithNoAggregation,
        isEqual
      ),
    [allRawAssets, selectedColumnsWithNoAggregation]
  );

  const subsetOfAssets = React.useMemo(() => {
    let result: StreamAssetRaw[] = [];
    switch (selectionType) {
      case SelectionType.selected:
        result = selectedColumnsWithNoAggregation;
        break;
      case SelectionType.unselected:
        result = unselectedColumns;
        break;
      default:
      case SelectionType.all:
        result = [...selectedColumnsWithNoAggregation, ...unselectedColumns];
    }

    return [...result]
      .filter((asset) => matchesSearchQuery(searchQuery, asset.name))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [
    selectionType,
    selectedColumnsWithNoAggregation,
    unselectedColumns,
    searchQuery,
  ]);

  // RENDER
  return (
    <div>
      {subsetOfAssets.map((asset) => (
        <ByValuesGroupCollapse asset={asset} key={asset.id} />
      ))}
    </div>
  );
};
