/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  IControlledField,
  IEntityProperty,
  IGenericFormField,
  SchemaControlType,
  SchemaDataType,
} from '../types';
import SelectField from './fields/SelectField';
import TextField from './fields/TextField';
import DatePicker from './fields/DatePicker';
import YesNo from './fields/YesNo';
import AsyncSelectOne from './fields/AsyncSelectOne';
import TagPicker from './fields/TagPicker';
import IconPicker from './fields/IconGallery';
import MultiEndpointSelector from './MultiEndpointSelector';
import ChildEntityForm from './ChildEntityForm';
import ColorPicker from './fields/ColorPicker';
import HiddenField from './fields/HiddenField';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { DataSourceLinkFormKeys } from '../../Ingestion/types';
import Records from './fields/Records';
import SelectOneOfType from './fields/SelectOneOfType';
import FieldPicker from './fields/FieldPicker';
import EntityPicker from './fields/EntityPicker';
import AssetPicker from './AssetPicker';
import GroupPicker from './GroupPicker';
import FilePicker from './FilePicker';
import MultiSelectBuilder from './fields/MultiSelectBuilder';
import { errorMsgStyles } from '../styles';
import useWizardFieldValidation from '../hooks/useWizardFieldValidation';
import { OAuthControl } from './fields/oauth/components/OAuthControl';
import { XeroTenantDropdown } from './fields/oauth/components/XeroTenantDropdown';
import KeyValuePicker from './KeyValuePicker';
import RangeSlider from './fields/RangeSlider';
import MultiLinkPicker from './MultiLinkPicker';
import { SelectOneConnectorConfigurationPropertyEntry } from './fields/oauth/components/SelectOneConnectorConfigurationPropertyEntry';

const generateField = ({
  interaction,
  dataType,
  name,
}: IEntityProperty): React.FC<IControlledField> => {
  const controlType = interaction?.controlType;
  switch (controlType) {
    case SchemaControlType.None:
      return HiddenField;
    case SchemaControlType.SingleLineEdit:
    case SchemaControlType.MultiLineEdit:
      if (name === DataSourceLinkFormKeys.records) {
        return Records;
      }
      return TextField;
    case SchemaControlType.SelectOne:
      if (dataType === SchemaDataType.Boolean) {
        return YesNo;
      }
      if ([SchemaDataType.Date, SchemaDataType.DateTime].includes(dataType)) {
        return DatePicker;
      }
      if (interaction?.referenceType) {
        if (interaction?.referenceType === TYPE_IDS.SourceEntityField) {
          return FieldPicker;
        }
        if (interaction?.referenceType === TYPE_IDS.SourceEntity) {
          return EntityPicker;
        }
        return AsyncSelectOne;
      }
      return SelectField;
    case SchemaControlType.SelectMultiple:
      if (name === 'tags') {
        return TagPicker;
      }
      if (interaction?.referenceType) {
        return AsyncSelectOne;
      }
      return SelectField;

    case SchemaControlType.IconPicker:
      return IconPicker;
    case SchemaControlType.ColorPicker:
      return ColorPicker;
    case SchemaControlType.SelectOneOfType:
      return SelectOneOfType;
    case SchemaControlType.ManageMultiple:
      if (dataType !== SchemaDataType.Object) {
        return MultiSelectBuilder;
      }
      break;
    case SchemaControlType.XeroTenant:
      return XeroTenantDropdown;
    case SchemaControlType.SelectOneConnectorConfigurationPropertyEntry:
      return SelectOneConnectorConfigurationPropertyEntry;
    case SchemaControlType.Slider:
      return RangeSlider;
    default:
      break;
  }

  switch (dataType) {
    case SchemaDataType.Boolean:
      return YesNo;
    case SchemaDataType.NumericDecimal:
    case SchemaDataType.NumericInteger:
      return TextField;
    default:
      return null;
  }
};

const generateFieldSet = ({
  interaction,
  dataType,
}: IEntityProperty): React.FC<IGenericFormField> => {
  const controlType = interaction?.controlType;
  switch (controlType) {
    case SchemaControlType.ManageMultiple: {
      const { referenceType } = interaction;
      if (referenceType === TYPE_IDS.LinkFields) {
        return MultiLinkPicker;
      }
      if (
        referenceType === TYPE_IDS.Group ||
        referenceType === TYPE_IDS.HubSource
      ) {
        return AssetPicker;
      }
      if (
        (
          [
            TYPE_IDS.AzureDelimitedTextFile,
            TYPE_IDS.AzureExcelFileConfiguration,
          ] as string[]
        ).includes(referenceType)
      ) {
        return FilePicker;
      }
      if (referenceType === TYPE_IDS.Endpoint) {
        return MultiEndpointSelector;
      }
      if (dataType === SchemaDataType.KeyValue) {
        return KeyValuePicker;
      }
      return null;
    }
    case SchemaControlType.TypeControl:
    case SchemaControlType.CalculationFilters:
      return ChildEntityForm;
    case SchemaControlType.LinkProcess:
      // Temporally remove due business logic inconsistencies
      // return LinkProcess;
      return null;
    case SchemaControlType.HubProcess:
      // Temporally remove due business logic inconsistencies
      // return HubProcess;
      return null;
    case SchemaControlType.GroupValues:
      return GroupPicker;
    case SchemaControlType.OAuthControl:
      return OAuthControl;
    default:
      return null;
  }
};

const Field: React.FC<IGenericFormField> = (props) => {
  const { entityProperty, interaction, inputName, initialValue } = props;
  const FieldSet = generateFieldSet({ ...entityProperty, interaction });
  const Component = generateField({ ...entityProperty, interaction });
  const { errors } = useFormContext();
  const { t } = useTranslation();

  const validate = useWizardFieldValidation(props);

  if (FieldSet) {
    return <FieldSet {...props} />;
  }

  const errorMsg =
    errors?.[inputName]?.type === 'validate' && errors?.[inputName]?.message;

  if (Component) {
    return (
      <>
        <Controller
          name={inputName}
          defaultValue={initialValue}
          rules={{
            required: interaction?.isRequired,
            // eslint-disable-next-line no-nested-ternary
            validate,
          }}
          render={(controller, state) => (
            <Component {...props} {...{ controller, state }} />
          )}
        />
        {
          // TODO: Customize error msg based on entity
          errorMsg ? (
            <span style={errorMsgStyles}>{t(`wizard:${errorMsg}`)}</span>
          ) : null
        }
      </>
    );
  }

  return null;
};

export default Field;
