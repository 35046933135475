import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import Error from '../../Error';
import { ComparisonTypes } from '../../../../types/IStreamFilter';
import { TypedValuesInput, LoadedValuesInput } from '../../../ValuesInput';

interface Props {
  comparison: ComparisonTypes;
  loadOptions(inputValue: string): Promise<Array<string>>;
  acceptsTypedValues: boolean;
}

const ValuesSelect: React.FC<Props> = ({
  comparison,
  loadOptions,
  acceptsTypedValues,
}) => {
  const { t } = useTranslation('filters');

  const { control, errors } = useFormContext();

  if (![ComparisonTypes.In, ComparisonTypes.NotIn].includes(comparison)) {
    return null;
  }

  const ValuesInput = acceptsTypedValues ? TypedValuesInput : LoadedValuesInput;

  return (
    <>
      <Controller
        render={({ onChange, value }) => (
          <ValuesInput
            onChange={(values) => {
              onChange(values);
            }}
            isMulti
            loadOptions={loadOptions}
            defaultValue={value}
            label={t('Values')}
          />
        )}
        label={t('Value')}
        name="values"
        control={control}
        rules={{
          required: true,
          validate: (v) => v.length > 0,
        }}
      />
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="values"
        message={t('required')}
      />
    </>
  );
};

export default ValuesSelect;
