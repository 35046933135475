import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Links';

export const TUTORIAL_TILE_KEY = 'builder-catalog-links';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:builder:catalog:links:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:builder:catalog:links:items:selectLinks:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:builder:catalog:links:items:selectLinks:title',
    }
  ],
  showMeUrl: 'https://eyko.zendesk.com/knowledge/articles/4403607427607/en-us?brand_id=360005226094',
};
