import {
  IButtonStyles, ICalloutContentStyles, IContextualMenuStyles, IIconStyles,
} from '@fluentui/react';
import { baseColors } from '../../../theme';

export const buttonStyles = (isOrange?: boolean): IButtonStyles => ({
  root: {
    padding: 0,
    position: 'relative',
    width: 24,
    minWidth: 24,
    height: 24,
    color: `${isOrange ? baseColors.midOrange : baseColors.iconColor} !important`,
    backgroundColor: 'transparent',
    borderRadius: 4,
    selectors: {
      '.ms-Button-menuIcon': {
        display: 'none',
      },
      '.ms-Button-icon': {
        position: 'relative',
        fontSize: 18,
        color: `${isOrange ? baseColors.midOrange : baseColors.iconColor} !important`,
        selectors: {
          svg: {
            width: 16,
          },
        },
      },
      '&:active .ms-Button-icon': {
        color: `${isOrange ? baseColors.midOrange : baseColors.iconColor} !important`,
      },
      '&.ms-Button.is-expanded': {
        boxShadow: '0px 2px 4px rgb(0 0 0 / 11%)',
      },
    },
  },
  rootFocused: {
    selectors: {
      '.ms-Button-icon': {
        color: `${isOrange ? baseColors.midOrange : baseColors.iconColor} !important`,
      },
    },
  },
  rootHovered: {
    backgroundColor: baseColors.selected,
    selectors: {
      '.ms-Button-icon': {
        color: `${isOrange ? baseColors.midOrange : baseColors.iconColor} !important`,
      },
    },
  },
  rootExpanded: {
    backgroundColor: baseColors.white,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'rgb(0 0 0 / 13%) 0px 6.4px 14.4px 0px, rgb(0 0 0 / 11%) 0px 1.2px 3.6px 0px',
    selectors: {
      '.ms-Button-icon': {
        color: `${isOrange ? baseColors.midOrange : baseColors.iconColor} !important`,
      },
    },
  },
});

export const calloutStyles: Partial<ICalloutContentStyles> = {
  root: {
    borderRadius: '4px',
    border: 0,
    borderTopLeftRadius: 0,
    backgroundColor: baseColors.white,
  },
  calloutMain: {
    borderTopLeftRadius: 0,
    borderRadius: '4px',
    backgroundColor: 'transparent',
  },
};

export const contextualMenuStyles: Partial<IContextualMenuStyles> = {
  root: {
    backgroundColor: 'transparent',
    fontSize: 12,
    minWidth: 116,
    selectors: {
      '.ms-ContextualMenu-divider': {
        backgroundColor: baseColors.inputBorder,
      },
      '.ms-ContextualMenu-link': {
        lineHeight: 28,
      },
    },
  },
  subComponentStyles: {
    menuItem: {
      root: {
        fontSize: '12px !important',
        height: 28,
      },
      checkmarkIcon: {
        marginLeft: 6,
        marginRight: 6,
        display: 'block',
        fontSize: 14,
      },
    },
    callout: {
    },
  },
};

export const statusIconStyles: IIconStyles = {
  root: {
    position: 'absolute',
    fontSize: 8,
    top: 4,
    right: 4,
    zIndex: 12,
  },
};

export const itemIconStyles: IIconStyles = {
  root: {
    fontSize: 16,
    display: 'flex',
    width: 16,
    selectors: {
      svg: {
        width: 16,
      },
    },
  },
};
