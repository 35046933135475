import { bindThunkActionCreators } from './utils';
import { actionCreators } from '../../modules/Streams/Streams';
import { syncPersistedData as syncPersistedDataActionCreator } from '../../store/persistence/actions';
import { handleError } from './handleError';
import { persist as persistData } from '../../store/persistence/DataSets';

// It may happen that user deletes a table, this mechanism will purge
// the persisted data on every addin start
const handleRemovedDatasets = async () => Excel.run(async (ctx) => {
  const { getState } = window.sharedState.store;
  const { removeDataset } = bindThunkActionCreators(actionCreators);
  const allDatasets = getState().streams.datasets;

  try {
    const tableIds = [];
    const tables = ctx.workbook.tables.load('items');
    await ctx.sync();
    tables.items.forEach((table) => {
      tableIds.push(table.id);
    });

    let syncRequired = false;

    allDatasets.forEach((dataset) => {
      if (!tableIds.includes(dataset.tableId)) {
        syncRequired = true;
        removeDataset(dataset.id);
      }
    });

    if (syncRequired) {
      // we are calling function directly, to force update,
      // to handle case when user deletes all datasets (to sync empty array)
      persistData(syncPersistedDataActionCreator(), window.sharedState.store, true);
    }
  } catch (e) {
    handleError(e);
  }
});

export default handleRemovedDatasets;
