import React, {
  useRef, useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
  TooltipHost,
  TooltipDelay,
  IconButton,
  getTheme,
  ContextualMenu,
  IContextualMenuItem,
  IButtonStyles,
  DirectionalHint,
} from '@fluentui/react';

import {
  classNames,
  tooltipStyles,
  itemStyles,
  droplistStyles,
} from './styles';
import { AGGREGATION_METHODS, getAggregations, IAggregationMethod } from '../../constants/aggregations';
import { IColumnAggregationMethods } from '../../types/IColumn';
import streamSelectors from '../../modules/Streams/selectors';

export interface IAggregationMenuItem extends IContextualMenuItem {
  data: IColumnAggregationMethods
}

interface Props {
  aggregationIconName?: string;
  tooltipLabel: string;
  onDroplistItemClick: (
    ev?:
    | React.MouseEvent<HTMLElement, MouseEvent>
    | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) => any;
  aggregationBtnStyles?: IButtonStyles | ((isDroplistVisible:boolean) => IButtonStyles),
  aggregationDroplistBtnStyles?: IButtonStyles,
  omit?: IAggregationMethod[]
  currentAggregations?: IAggregationMethod[]
  isColumnAggregation?: boolean
  tooltipDirectionalHint?: DirectionalHint
  isGroupTableAggregation?: boolean;
  isDisabled?: boolean;
  checkIsLastInGroupTableValues?: (aggregation: IColumnAggregationMethods) => boolean
}

const GroupTableAggregationButton: React.FC<Props> = ({
  aggregationBtnStyles,
  aggregationDroplistBtnStyles,
  aggregationIconName,
  onDroplistItemClick,
  tooltipLabel,
  omit = [],
  isColumnAggregation,
  currentAggregations,
  tooltipDirectionalHint,
  isDisabled,
  checkIsLastInGroupTableValues,
}:Props) => {
  const [isDroplistVisible, setDroplistVisible] = useState(false);
  const theme = getTheme();
  const iconBtnRef = useRef(null);
  const toggleDroplist = () => setDroplistVisible(!isDroplistVisible);
  const aggregations = getAggregations();
  const currentDatasetId = useSelector(streamSelectors.getCurrentDatasetId);
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  useEffect(() => {
    if (!currentDatasetId) {
      setDroplistVisible(false);
    }
  }, [currentDatasetId]);

  let menuItems: IContextualMenuItem[] = Object.values(AGGREGATION_METHODS).map(
    (agg) => ({
      key: agg,
      text: aggregations[agg].label,
      data: aggregations[agg].method,
      itemProps: {
        styles: itemStyles,
      },
      iconProps: {
        iconName: aggregations[agg].icon,
      },
      canCheck: isColumnAggregation,
    }),
  );

  if (isColumnAggregation && currentAggregations) {
    menuItems = menuItems.map((item) => ({
      ...item,
      checked: currentAggregations.includes(item.data),
      disabled: checkIsLastInGroupTableValues && checkIsLastInGroupTableValues(item.data),
    }));
  }

  const onItemClick = (
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem,
  ) => {
    ev?.preventDefault();
    if (currentDataset?.options?.dataOnDemand) {
      setDroplistVisible(false);
    }
    onDroplistItemClick(ev, item);
    return false;
  };

  const items = menuItems
    .filter((item) => !omit.includes(item.data))
    .sort((itemA, itemB) => itemA?.text.localeCompare(itemB?.text));

  return (
    <>
      <TooltipHost
        content={tooltipLabel}
        delay={TooltipDelay.long}
        tooltipProps={{
          styles: tooltipStyles(theme),
        }}
        directionalHint={tooltipDirectionalHint || DirectionalHint.topCenter}
      >
        <div ref={iconBtnRef} className={classNames.btnWrap} data-testid="aggregation-btn">
          <IconButton
            styles={typeof aggregationBtnStyles === 'function' ? aggregationBtnStyles(isDroplistVisible) : aggregationBtnStyles}
            data-is-focusable
            iconProps={{ iconName: aggregationIconName || 'MapLayers' }}
            onClick={toggleDroplist}
            disabled={isDisabled}
          />
          <IconButton
            data-testid="aggregation-button-reveal-droplist"
            styles={aggregationDroplistBtnStyles}
            iconProps={{ iconName: 'CaretSolid16' }}
            onClick={toggleDroplist}
          />
        </div>
      </TooltipHost>
      <ContextualMenu
        items={items}
        hidden={!isDroplistVisible}
        onDismiss={() => setDroplistVisible(false)}
        onItemClick={onItemClick}
        target={iconBtnRef}
        styles={droplistStyles}
        isBeakVisible={false}
      />
    </>
  );
};

export default GroupTableAggregationButton;
