import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { fetchEntities } from '../../../Catalog/api';
import { getDataSources } from '../../../Catalog/selectors';
import { getAllSources } from '../../../Ingestion/selectors';
import { IDesignSourceEntity } from '../../../types';
import { getEntityById, getEntityFormByTypeId } from '../../selectors';
import { IControlledField } from '../../types';
import PickerBase from '../EntityPicker';
import { useSourceFromEntity } from '../SelectOneOfType/useSourceFromEntity';

const EntityPicker: React.FC<IControlledField> = ({
  controller,
  entityProperty,
  interaction,
  isDisabled,
  currentForm,
  isEditing,
  label,
}) => {
  const { value, onChange } = controller;
  const { records: catalogSources } = useSelector(getDataSources);
  const ingestionSources = useSelector(getAllSources);
  const [entities, setEntities] = useState<IDesignSourceEntity[]>([]);
  const entity = useSelector(getEntityById(value));
  const isTransformation = currentForm.parentTypeId === TYPE_IDS.SourceEntity;
  const { sourceId, setSourceId } = useSourceFromEntity(entity?.id);
  const { isReadOnly, isEditableAfterCreation } = entityProperty;
  const { isRequired, allowedDataTypes } = interaction;
  const entityForm = useSelector(getEntityFormByTypeId(TYPE_IDS.SourceEntity));

  const currentSourceId =
    isTransformation && !isEditing ? entityForm?.parentEntityId : sourceId;
  const currentSources = isTransformation ? ingestionSources : catalogSources;

  useEffect(() => {
    (async () => {
      setEntities([]);
      try {
        if (currentSourceId) {
          const {
            data: { data },
          } = await fetchEntities(currentSourceId)();
          const sortedEntities = [...data].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setEntities(sortedEntities);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [currentSourceId]);

  return (
    <PickerBase
      onEntityChange={onChange}
      onSourceChange={!isTransformation && setSourceId}
      sourceId={currentSourceId}
      sources={currentSources}
      entities={entities}
      entityId={value}
      label={label}
      isDisabled={
        isDisabled || isReadOnly || (isEditing && !isEditableAfterCreation)
      }
      isRequired={isRequired}
      isTransformation={isTransformation}
      allowedDataTypes={allowedDataTypes}
    />
  );
};

export default EntityPicker;
