import React, { useMemo } from 'react';
import { Stack, IconButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IDesignerFilter } from '../../types/IDesignerFilter';
import * as styles from './styles';
import {
  formatValues,
  getRealComparisonType,
} from '../../utils/formatFilterValues';
import SearchMatch from '../SearchMatch';
import { getSearchMatch } from '../../modules/Search/utils';

interface Props {
  filter: IDesignerFilter;
  onEdit(filter: IDesignerFilter): void;
  onDelete(filter: IDesignerFilter): void;
  isEditing?: boolean;
  isSelected?: boolean;
  spacing?: string;
  id?: string;
  searchQuery?: string;
}

const FilterCondition: React.FC<Props> = ({
  filter,
  onEdit,
  onDelete,
  isEditing = false,
  isSelected,
  spacing,
  id,
  searchQuery,
}) => {
  // HOOKS
  const { t } = useTranslation('filters');

  // DERIVED STATE
  const realComparisonType = useMemo(
    () => getRealComparisonType(filter),
    [filter]
  );

  // CALLBACKS
  const handleEdit = () => {
    onEdit(filter);
  };

  const handleDelete = () => {
    onDelete(filter);
  };
  if (!filter) {
    return null;
  }

  const renderSearchMatch = () => {
    const searchMatch = getSearchMatch(filter?.item?.name, searchQuery);

    return <SearchMatch match={searchMatch} />;
  };

  // RENDER
  return (
    <>
      <Stack
        styles={styles.containerStyles(isSelected, spacing)}
        horizontal
        verticalAlign="center"
      >
        <span style={styles.primaryTextStyle}>
          {searchQuery ? renderSearchMatch() : filter.item?.name}
        </span>
        <span style={styles.spanStyle}>{t(realComparisonType)}</span>
        <span style={styles.valueTextStyle}>
          {formatValues({ ...filter, comparison: realComparisonType })}
        </span>
        <div style={styles.btnWrapStyles}>
          <div style={styles.btnContainerStyles}>
            <IconButton
              styles={styles.iconButtonStyles(isEditing, isSelected)}
              iconProps={{ iconName: 'Filter' }}
              onClick={handleEdit}
            />
          </div>
          <div style={styles.btnContainerStyles}>
            <IconButton
              styles={styles.iconButtonStyles(false, isSelected)}
              iconProps={{ iconName: 'Delete' }}
              onClick={handleDelete}
            />
          </div>
        </div>
      </Stack>
      <div id={id} style={styles.filterFormTarget} />
    </>
  );
};

export default FilterCondition;
