import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IFoldableListItem } from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';
import EntityField from '../../../../../components/EntityField';

import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../../App/types';

import { IHub } from '../../../types';

import { getHubs, getIsUpdating, getDataSources } from '../../selectors';
import { fetchHubs, updateEntity, fetchDataSources } from '../../actions';
import useEditableItems from '../../../../../hooks/useEditableItems';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import { EmptyMessage, GroupCollapse } from '../../../../../components/ui';
import { getSelectedItemsIds } from '../../../../App/selectors';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { getSearchQuery } from '../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../Search/utils';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { HubKeys } from '../../../../../api/model/schemas/Hub';
import { addNestedEntityRoute } from '../../../../../utils/routes';

const Hubs: FunctionComponent = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // STATE
  const selectedDesignId = useSelector(getSelectedDesignId);

  const { records: hubs, isLoading: isHubsLoading } = useSelector(getHubs);
  const isUpdating = useSelector(getIsUpdating);
  const { records: dataSources, isLoading: isDataSourcesLoading } = useSelector(getDataSources);

  const selectedEntitiesIds = useSelector(getSelectedItemsIds);
  const searchQuery = useSelector(getSearchQuery);

  // DERIVED STATE
  const {
    properties,
  } = useGetParsedTypeProperties({ designId: selectedDesignId, typeId: TYPE_IDS.Hub });

  const schema = useMemo(
    () => [
      ...getSchemaFromTypeProperties(properties, TYPE_IDS.Hub),
      // TODO: contact Stephan about making some additional attributes shown in info boxes on the API side
      { key: HubKeys.dataType, translationPrefix: TYPE_IDS.Hub },
      { key: HubKeys.formatType, translationPrefix: TYPE_IDS.Hub },
      { key: HubKeys.type, translationPrefix: TYPE_IDS.Hub },
    ],
    [properties],
  );

  const filteredRecords = useMemo(
    () => hubs
      .filter((record) => searchQuery === '' || matchesSearchQuery(searchQuery, record.name)),
    [hubs, searchQuery],
  );

  const hubsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds,
  });

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:catalog:hubs:cta:noDataIngested': dataSources.length === 0,
    }),
    [dataSources.length],
  );

  // CALLBACKS
  const onEditEndCallback = React.useCallback(
    (hasChanged: boolean) => {
      if (hasChanged) {
        dispatch(fetchHubs(selectedDesignId));
      }
    },
    [dispatch, selectedDesignId],
  );

  const onEntityUpdated = React.useCallback(
    () => {
      dispatch(fetchHubs(selectedDesignId));
    },
    [dispatch, selectedDesignId],
  );

  const {
    isEditing,
    onEditEnd,
    onEditStart,
  } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  const handleRename = React.useCallback(
    async (dataSourceId: string, newName:string) => {
      await dispatch(updateEntity({
        entity: {
          id: dataSourceId,
          name: newName,
        },
        typeId: TYPE_IDS.Hub,
      }));

      await dispatch(fetchHubs(selectedDesignId));
    },
    [dispatch, fetchHubs, selectedDesignId],
  );

  // NOTE: based on HubsHeader.tsx
  const handleAdd = React.useCallback(
    () => {
      const path = addNestedEntityRoute({
        schemaId: TYPE_IDS.Hub,
        parentSchemaId: TYPE_IDS.Design,
        parentEntityId: selectedDesignId,
      });

      history.push(path, {
        typeName: 'Hub',
      });
    },
    [history, selectedDesignId],
  );

  // EFFECTS
  useEffect(() => {
    if (selectedDesignId && dataSources.length === 0) dispatch(fetchDataSources(selectedDesignId));
  }, [dispatch, selectedDesignId, dataSources.length]);

  useEffect(() => {
    if (selectedDesignId) dispatch(fetchHubs(selectedDesignId));
  }, [dispatch, selectedDesignId]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: hubs.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick: handleAdd,
    startButtonStates: tutorialTileRequirements,
  });

  const {
    handleItemClickedOnSingleSelection,
    selectedItem,
  } = useItemSelections();

  const {
    mappedGroups,
    currentSort,
    onClickHeaderHandler,
  } = useSortedFoldableGroups({
    input: hubsWhichIncludesSelections,
    selectedItemId: selectedItem,
  });

  const renderHub = (field: IHub) => (field ? (
    <EntityField
      key={field.id}
      isSelectable
      isSelected={field.id === selectedItem}
      info={{
        data: field,
        schema,
        title: field.name,
        enableInlineEdit: true,
        onEntityUpdated,
      }}
      onClick={() => handleItemClickedOnSingleSelection(field.id)}
      slot1={(
        <EntityFieldSlotSpacer />
      )}
      data-testid={`catalog-hub-${field.name}`}
    >
      <EditableFoldableListItem
        text={field.name}
        onEdit={(newVal) => handleRename(field.id, newVal)}
        isSelected={field.id === selectedItem}
        onEditStart={() => {
          const isSelected = field.id === selectedItem;
          // for consistency mark source as selected when editing
          if (!isSelected) {
            handleItemClickedOnSingleSelection(field.id);
          }
          onEditStart();
        }}
        onEditEnd={onEditEnd}
        transparentBackground
        disableEditing={isEditing}
        searchQuery={searchQuery}
        availability={field.availability}
      />
    </EntityField>
  ) : null);

  // RENDER
  if (isHubsLoading || isUpdating || isDataSourcesLoading) {
    return (
      <ItemsListShimmer />
    );
  }

  if (tutorialTile) return tutorialTile;

  if (!isHubsLoading && hubs.length === 0) {
    return <EmptyMessage message={t('wizard:noEntityDefined', { entityType: t('entityTypes:Hub', { count: 0 }) })} />;
  }

  if (currentSort === DefinedSortTypes.Name) {
    const sortedFields = [...hubsWhichIncludesSelections].sort(
      (a, b) => (a.name.localeCompare(b.name)),
    );

    return (
      <>
        {
          sortedFields.map(
            (field) => renderHub(field),
          )
        }
      </>
    );
  }

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = hubs.find((r) => r.id === item.key);

    return renderHub(entry);
  };

  return (
    <div>
      { mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onClick={onClickHeaderHandler}
        >
          { group.items.map((item) => (onRenderItem(item))) }
        </GroupCollapse>
      )) }
    </div>
  );
};

export default Hubs;
