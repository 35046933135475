import React, { useEffect } from 'react';
import { Stack } from '@fluentui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { IDesignerFilter } from '../../../../../../../types/IDesignerFilter';
import { formFilterDefault } from '../../../../../../../components/FiltersForm';
import { getAllSources, getAllEntitiesWithValues, getIsLoadingFilters } from '../../../../selectors';
import ItemsListShimmer from '../../../../../../../components/ItemsListShimmer';
// import { fetchAllFiltersByLogic } from '../../../../actions';
import DoubleFoldableList from '../../../../../../../components/DoubleFoldableList';

const Values = () => {
  const formMethods = useForm<IDesignerFilter>({
    defaultValues: formFilterDefault,
    mode: 'onChange',
  });
  const sources = useSelector(getAllSources);
  const entitiesWithValues = useSelector(getAllEntitiesWithValues);
  const isLoading = useSelector(getIsLoadingFilters);
  // const dispatch = useDispatch();

  useEffect(() => {
    if (sources) {
      // dispatch(fetchAllFiltersByLogic());
    }
  }, [sources]);

  if (isLoading) {
    return <ItemsListShimmer />;
  }

  return (
    <FormProvider {...formMethods}>
      <Stack>
        <DoubleFoldableList
          entities={entitiesWithValues}
          isEntitySelected={() => false}
          isFieldSelected={() => false}
          isValueSelected={() => false}
          onValueSelect={() => {}}
        />
      </Stack>
    </FormProvider>
  );
};

export default Values;
