import { IColumn } from '../../types/IColumn';
import { IColumnWithUuid } from './screens/ColumnsReorder/types';

// format type might change when stream will be reprocessed
export const generateColumnUuid = (column: IColumn) => `${column.id}_${column.aggregation}_${column.formatType}`;

export const searchRegex = (searchQuery: string): RegExp => new RegExp(searchQuery, 'i');

export const filterColumns = (columns: IColumnWithUuid[], searchQuery: string) => columns
  .filter((column) => column.name.search(searchRegex(searchQuery)) !== -1);
