import React from 'react';
import { Stack } from '@fluentui/react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  moveItem, reorder,
} from '../../actions';
import { SECTION_TYPES } from '../../constants';
import { MoveItemPayload } from '../../types';

import SingleSection from '../../components/SingleSection';

import { classNames } from './styles';
import { selectors } from '../../../Streams/Streams';
import { columnsToOptions } from './utils';
import { ObjectTypes } from '../../../App/types';
import { routes } from '../../../../constants/routes';
import { getActiveObjectType } from '../../../App/selectors';

const GroupTableDesign: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleMove = (a: MoveItemPayload, b: MoveItemPayload) => dispatch(moveItem({ source: a, destination: b }));
  const handleReorder = (a: MoveItemPayload, b: MoveItemPayload) => dispatch(reorder(a, b));

  const currentDataset = useSelector(selectors.getCurrentDataset);
  const currentStreamId = useSelector(selectors.getCurrentStreamId);
  const isSyncing = useSelector(selectors.getIsSyncing);
  const activeObjectType = useSelector(getActiveObjectType);

  const options = columnsToOptions(currentDataset?.columns || []);

  const isGroupTable = React.useMemo(
    () => (currentDataset
      ? currentDataset.type === ObjectTypes.GROUP_TABLE
      : activeObjectType === ObjectTypes.GROUP_TABLE),
    [currentDataset, activeObjectType],
  );

  const handleDragEnd = (result: DropResult) => {
    const source = result.source as MoveItemPayload;
    const destination = result.destination as MoveItemPayload;

    if (source.droppableId === destination.droppableId) {
      handleReorder(source, destination);
    } else {
      handleMove(source, destination);
    }
  };

  React.useEffect(() => {
    if (isSyncing) return;

    if (!isGroupTable || !currentStreamId) {
      history.push(routes.streams.index);
    }
  }, [currentDataset, isSyncing, currentStreamId]);

  return (
    <Stack
      className={classNames.wrapper}
      grow={1}
      tokens={{ childrenGap: 10, padding: 8 }}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <SingleSection sectionType={SECTION_TYPES.rows} dropdownOptions={options} />
        <SingleSection sectionType={SECTION_TYPES.columns} dropdownOptions={options} />
        <SingleSection sectionType={SECTION_TYPES.values} dropdownOptions={options} />
      </DragDropContext>
    </Stack>
  );
};
export default GroupTableDesign;
