import { IColumnSortTypes } from '../../../../../../types/IColumn';
import { enforceSchema } from '../../../../../../../shared/types/utils';

interface ColumnSortMode<Value extends IColumnSortTypes> {
  labelI18nKey: string;
  value: Value;
  icon: string;
}

type ColumnSortModes = { [key in IColumnSortTypes]: ColumnSortMode<key> }

export const COLUMN_SORT_MODE = enforceSchema<ColumnSortModes>()({
  [IColumnSortTypes.Ascending]: {
    labelI18nKey: 'streams:columnsSort.ascending',
    value: IColumnSortTypes.Ascending,
    icon: 'SynergiesSortAscending',
  },
  [IColumnSortTypes.Descending]: {
    labelI18nKey: 'streams:columnsSort.descending',
    value: IColumnSortTypes.Descending,
    icon: 'SortLines',
  },
  // hidden until https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6596
  // is finished
  // null: {
  //   labelI18nKey: 'streams:columnsSort.original',
  //   value: null,
  //   icon: 'SynergiesSortCustom',
  // },
} as const);
