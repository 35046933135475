import { createAction } from '@reduxjs/toolkit';

import { AppThunkAction } from '../../store/types';

import * as types from './types';
import { getSectionByType } from './selectors';
import { IColumn } from '../../types/IColumn';
import { SECTION_TYPES } from './constants';

export const addItem = createAction<
types.AddPayload,
'groupTable/ADD_ITEM'
>('groupTable/ADD_ITEM');

export const updateItem = createAction<
types.UpdatePayload,
'groupTable/UPDATE_ITEM'
>('groupTable/UPDATE_ITEM');

export const setItems = createAction<
types.SetItemsPayload,
'groupTable/SET_ITEMS'
>('groupTable/SET_ITEMS');

export const removeItem = createAction<
types.RemovePayload,
'groupTable/REMOVE_ITEM'
>('groupTable/REMOVE_ITEM');

export const setTable = createAction<
types.SetTablePayload,
'groupTable/SET_TABLE'
>('groupTable/SET_TABLE');

export const initChangeDrillingLevel = createAction<
types.SetDrillingLevel,
'groupTable/INIT_CHANGE_DRILLING_LEVEL'
>('groupTable/INIT_CHANGE_DRILLING_LEVEL');

export const setDrillingLevel = createAction<
types.SetDrillingLevel,
'groupTable/SET_DRILLING_LEVEL'
>('groupTable/SET_DRILLING_LEVEL');

export const setTableData = createAction<
types.SetTableData,
'groupTable/SET_TABLE_DATA'
>('groupTable/SET_TABLE_DATA');

export const setTableRowKeys = createAction<
types.SetTableRowKeys,
'groupTable/SET_TABLE_ROW_KEYS'
>('groupTable/SET_TABLE_ROW_KEYS');

export const setTableColumnKeys = createAction<
types.SetTableColumnKeys,
'groupTable/SET_TABLE_COLUMN_KEYS'
>('groupTable/SET_TABLE_COLUMN_KEYS');

export const setShouldReload = createAction<
types.SetShouldReload,
'groupTable/SET_SHOULD_RELOAD'
>('groupTable/SET_SHOULD_RELOAD');

export const groupTablePlayIntent = createAction('groupTable/PLAY_INTENT');

export const addGroupTableItemIntent = createAction<IColumn, 'groupTable/HANDLE_ADD_ITEM_INTENT'>('groupTable/HANDLE_ADD_ITEM_INTENT');

export const syncGroupTable = createAction<types.GroupTableState, 'groupTable/SYNC_GROUP_TABLE'>('groupTable/SYNC_GROUP_TABLE');

export const setDrillableSelection = createAction<types.IDrillableSelection, 'groupTable/SET_DRILLABLE_SELECTION'>('groupTable/SET_DRILLABLE_SELECTION');

export const toggleGroupTableTotal = createAction<types.ToggleGroupTableTotal, 'groupTable/TOGGLE_GROUP_TABLE_TOTAL'>('groupTable/TOGGLE_GROUP_TABLE_TOTAL');

export const editMatrixItemAggregationIntent = createAction<types.MatrixItemAggregationEditIntentPayload, 'groupTable/COLUMN_AGGREGATION_CHANGE_INTENT'>('groupTable/COLUMN_AGGREGATION_CHANGE_INTENT');

export const postDragAndDropAggregationChange = createAction<types.PostDragAndDropAggregationChangePayload, 'groupTable/POST_DRAG_AND_DROP_AGG_UPDATE'>('groupTable/POST_DRAG_AND_DROP_AGG_UPDATE');

export const moveItem = createAction<types.MoveItemActionPayload, 'groupTable/MOVE_ITEM'>('groupTable/MOVE_ITEM');

type KnownAction =
  | ReturnType<typeof addItem>
  | ReturnType<typeof updateItem>
  | ReturnType<typeof addGroupTableItemIntent>
  | ReturnType<typeof postDragAndDropAggregationChange>
  | ReturnType<typeof setItems>
  | ReturnType<typeof removeItem>;

export const reorder = (
  source: types.MoveItemPayload,
  destination: types.MoveItemPayload,
): AppThunkAction<KnownAction> => (dispatch, getState) => {
  const datasetId = getState().streams.currentDatasetId;
  const result = Array.from(getSectionByType(source.droppableId, datasetId)(getState()));

  const [removed] = result.splice(source.index, 1);
  result.splice(destination.index, 0, removed);

  dispatch(setItems({
    sectionType: source.droppableId,
    items: result,
    datasetId,
  }));
};

export const updateItemAndTryToAssociateAggregation = (
  payload: types.UpdatePayload,
): AppThunkAction<KnownAction> => (dispatch) => {
  if (payload.sectionType !== SECTION_TYPES.values) {
    dispatch(updateItem(payload));
    return;
  }

  dispatch(updateItem({
    ...payload,
    item: {
      ...payload.item,
    },
  }));
};

export const removeItemById = (
  itemId: string,
): AppThunkAction<KnownAction> => (dispatch, getState) => {
  const state = getState();
  const datasetId = state.streams.currentDatasetId;

  const sectionTypes = Object.values(SECTION_TYPES);

  sectionTypes.forEach((sectionType) => {
    const items = state.groupTable[datasetId][sectionType];
    const item = items.find((i) => i.columnUuid === itemId);

    if (!item) {
      return;
    }

    dispatch(removeItem({
      datasetId,
      sectionType,
      item,
    }));
  });
};
