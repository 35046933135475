import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../../shared/tutorial-tiles/components/animations/Hubs';

export const TUTORIAL_TILE_KEY = 'designer-catalog-hubs';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:catalog:hubs:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:hubs:items:createHub:description',
      icon: 'Add',
      titleI18nKey: 'tutorialTiles:designer:catalog:hubs:items:createHub:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:hubs:items:pickYourSources:description',
      icon: 'Database',
      titleI18nKey: 'tutorialTiles:designer:catalog:hubs:items:pickYourSources:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:hubs:items:chooseHowToBlend:description',
      icon: 'VennDiagram',
      titleI18nKey: 'tutorialTiles:designer:catalog:hubs:items:chooseHowToBlend:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010368101-Designer-Hubs-Create-the-perfect-blend',
};
