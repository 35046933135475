import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import FoldableList from '../../../../../components/ui/FoldableList';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../../App/types';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';

import {
  fetchDesignDataSources,
  setLoadingSources,
  setSelectedSourceId,
  configureDataSourceIntent,
} from '../../actions';
import {
  getAllSourcesSortedAscending,
  getIsLoadingSources,
  getSelectedSourceId,
} from '../../selectors';
import { EmptyMessage } from '../../../../../components/ui';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import DataSourceIcon from '../../../../../components/DataSourceIcon';
import EntityField from '../../../../../components/EntityField';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import useEditableItems from '../../../../../hooks/useEditableItems';
import { updateEntity } from '../../../Form/actions';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import DataSourceCalloutTitle from '../../components/DataSourceCalloutTitle';
import useEntitiesSelections from '../../../../../hooks/useEntitiesSelections';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { getSyncingText, isSourceSyncing } from './utils';
import { getSearchQuery } from '../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../Search/utils';
import { DataSourceConfigureStatus } from '../../../types';
import { numberToHexColor } from '../../../../../components/ui/ColorPicker/utils';
import { addNestedEntityRoute } from '../../../../../utils/routes';
import { DataSourceProcessStatus } from '../../../../../api/model/schemas/DataSourceProcessStatus';

const DataSources = () => {
  // DEPS
  const dispatch = useDispatch();
  const onEditEndCallback = React.useCallback(
    (hasChanged: boolean) => {
      if (hasChanged) {
        dispatch(setLoadingSources(true));
      }
    },
    [dispatch]
  );

  // HOOKS
  const { t } = useTranslation();
  const history = useHistory();

  // STATE
  const sortedDataSources = useSelector(getAllSourcesSortedAscending);
  const selectedDesignId = useSelector(getSelectedDesignId);
  const areSourcesLoading = useSelector(getIsLoadingSources);
  const selectedSourceId = useSelector(getSelectedSourceId);

  const searchQuery = useSelector(getSearchQuery);

  const filteredDataSources = useMemo(
    () =>
      sortedDataSources.filter((s) => matchesSearchQuery(searchQuery, s.name)),
    [sortedDataSources, searchQuery]
  );

  // DERIVED STATE
  const { isLoading: arePropertiesLoading, properties } =
    useGetParsedTypeProperties({
      designId: selectedDesignId,
      typeId: TYPE_IDS.DataSource,
    });

  const selectedEntitiesIds = React.useMemo(
    () => [selectedSourceId],
    [selectedSourceId]
  );

  const sourcesWhichIncludesSelections = useEntitiesSelections({
    entities: filteredDataSources,
    selectedEntitiesIds,
  });

  const schema = getSchemaFromTypeProperties(properties, TYPE_IDS.DataSource);

  const isLoading = areSourcesLoading || arePropertiesLoading;

  // CALLBACKS
  const handleRename = React.useCallback(
    (dataSourceId: string, newName: string) => {
      dispatch(
        updateEntity({
          callback: () => dispatch(fetchDesignDataSources(selectedDesignId)),
          entity: {
            id: dataSourceId,
            name: newName,
          },
          schemaId: TYPE_IDS.DataSource,
        })
      );
    },
    [dispatch, selectedDesignId]
  );

  const checkIsSourceSelected = React.useCallback(
    (sourceId) => selectedSourceId === sourceId,
    [selectedSourceId]
  );

  const handleItemClick = React.useCallback(
    (sourceId: string) => {
      dispatch(
        setSelectedSourceId(selectedSourceId === sourceId ? null : sourceId)
      );
    },
    [dispatch, selectedSourceId]
  );

  const { isEditing, onEditEnd, onEditStart } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: sourcesWhichIncludesSelections,
    });

  // NOTE: based on SourcesHeader add button logic
  const addSuccessCallback = React.useCallback(
    (newEntityId: string) => {
      dispatch(
        configureDataSourceIntent({
          historyGoBack: () => history.go(-1),
          dataSourceId: newEntityId,
          reloadFields: false,
        })
      );
    },
    [dispatch, history]
  );

  // NOTE: based on SourcesHeader add button logic
  const onStartClick = React.useCallback(() => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.DataSource,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesignId,
    });

    history.push(path, {
      typeName: 'Source',
      successCallback: addSuccessCallback,
    });
  }, [addSuccessCallback, history, selectedDesignId]);

  // EFFECTS
  React.useEffect(() => {
    dispatch(fetchDesignDataSources(selectedDesignId));
  }, []);

  React.useEffect(() => {
    // we want for an user to start with something selected
    if (selectedSourceId || areSourcesLoading) return;

    const firstSourceThatIsNotConfiguring = sortedDataSources.find(
      (source) =>
        source.configureStatus === DataSourceConfigureStatus.Configured &&
        source.processStatus !== DataSourceProcessStatus.Processing
    );
    if (!firstSourceThatIsNotConfiguring) return;

    dispatch(setSelectedSourceId(firstSourceThatIsNotConfiguring.id));
  }, [sortedDataSources, areSourcesLoading]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: sortedDataSources.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick,
  });

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isLoading && filteredDataSources.length === 0) {
    return (
      <EmptyMessage
        message={t('wizard:noEntityDefined', {
          entityType: t('entityTypes:Source', { count: 0 }),
        })}
      />
    );
  }

  return (
    <>
      {currentSort !== DefinedSortTypes.Name ? (
        <FoldableList
          groups={mappedGroups}
          expandAll={searchQuery && searchQuery !== ''}
          onClickHeader={onClickHeaderHandler}
          onRenderItem={(item) => {
            const relatedSource = sortedDataSources.find(
              (source) => source.id === item.key
            );
            if (!relatedSource) return null;

            const isCurrentSourceSyncing = isSourceSyncing(relatedSource);

            const sourceColor = numberToHexColor(relatedSource.color);
            const info = {
              data: relatedSource,
              schema,
              title: (
                <DataSourceCalloutTitle name={item.name} color={sourceColor} />
              ),
            };

            return (
              <EntityField
                {...{ info }}
                isSelected={checkIsSourceSelected(item.key)}
                key={item.key}
                onClick={() => {
                  if (isEditing || isCurrentSourceSyncing) return;
                  handleItemClick(item.key);
                }}
                slot1={<DataSourceIcon color={sourceColor} />}
                isSyncing={isCurrentSourceSyncing}
                isDisabled={isCurrentSourceSyncing}
                isSelectable={!isCurrentSourceSyncing}
                syncingTooltipContent={getSyncingText(relatedSource)}
                data-testid={`ingestion-source-${item.name}`}
              >
                <EditableFoldableListItem
                  text={item.name}
                  onEdit={(newVal) => handleRename(item.key, newVal)}
                  isSelected={checkIsSourceSelected(item.key)}
                  searchQuery={searchQuery}
                  onEditStart={() => {
                    const isSelected = checkIsSourceSelected(item.key);
                    // for consistency mark source as selected when editing
                    if (!isSelected) {
                      handleItemClick(item.key);
                    }
                    onEditStart();
                  }}
                  onEditEnd={onEditEnd}
                  transparentBackground
                  disableEditing={isEditing || isCurrentSourceSyncing}
                />
              </EntityField>
            );
          }}
        />
      ) : (
        <>
          {sourcesWhichIncludesSelections.map((dataSource) => {
            const isCurrentSourceSyncing = isSourceSyncing(dataSource);
            return (
              <EntityField
                info={{
                  data: dataSource,
                  schema,
                  title: (
                    <DataSourceCalloutTitle
                      name={dataSource.name}
                      color={numberToHexColor(dataSource.color)}
                    />
                  ),
                }}
                isSelected={checkIsSourceSelected(dataSource.id)}
                key={dataSource.id}
                onClick={() => {
                  if (isEditing || isCurrentSourceSyncing) return;
                  handleItemClick(dataSource.id);
                }}
                slot1={
                  <DataSourceIcon color={numberToHexColor(dataSource.color)} />
                }
                isSyncing={isCurrentSourceSyncing}
                isDisabled={isSourceSyncing(dataSource)}
                isSelectable={!isCurrentSourceSyncing}
                data-testid={`ingestion-source-${dataSource.name}`}
              >
                <EditableFoldableListItem
                  text={dataSource.name}
                  searchQuery={searchQuery}
                  onEdit={(newVal) => handleRename(dataSource.id, newVal)}
                  isSelected={checkIsSourceSelected(dataSource.id)}
                  onEditStart={() => {
                    const isSelected = checkIsSourceSelected(dataSource.id);
                    // for consistency mark source as selected when editing
                    if (!isSelected) {
                      handleItemClick(dataSource.id);
                    }
                    onEditStart();
                  }}
                  onEditEnd={onEditEnd}
                  transparentBackground
                  disableEditing={isEditing || isCurrentSourceSyncing}
                />
              </EntityField>
            );
          })}
        </>
      )}
    </>
  );
};

export default DataSources;
