import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { getTheme, CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { tooltipStyles } from '../ui/TooltipHost/styles';

import {
  commandBarStyles,
  buttonItemStyles,
  iconItemStyles,
  activeItemClassName,
} from './styles';

interface ContextualMenuItem {
  key: string;
  name: string;
  href: string;
  iconName: string;
  isActive?: boolean;
}

interface ContextualMenuProps {
  items: ContextualMenuItem[];
}

const FooterNav: FunctionComponent<ContextualMenuProps> = ({
  items,
}) => {
  const { push } = useHistory();
  const theme = getTheme();

  const onClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    item?: ICommandBarItemProps,
  ) => {
    event.preventDefault();

    if (item?.href) {
      push(item.href);
    }
  };

  const processItems: ICommandBarItemProps[] = items.map((i) => ({
    key: i.key,
    href: i.href,
    name: i.name,
    iconOnly: true,
    iconProps: {
      iconName: i.iconName,
      styles: iconItemStyles,
    },
    onClick,
    buttonStyles: buttonItemStyles,
    className: `${i.isActive ? activeItemClassName : ''}`,
    disabled: i.isActive,
    tooltipHostProps: {
      styles: tooltipStyles(theme),
      tooltipProps: {
        styles: tooltipStyles(theme),
      },
      content: i.name,
    },
    'data-testid': `footer-${i.key}`,
  }));

  return (
    <CommandBar
      styles={commandBarStyles}
      items={processItems}
    />
  );
};

export default FooterNav;
