import React from 'react';
import { Route, RouteProps } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setMostRecentPath, SetMostRecentPathPayload } from './actions';

interface RouteWithRememberPathProps extends SetMostRecentPathPayload {
  component: RouteProps['component'];
}
const RouteWithRememberPath = ({
  component,
  module,
  path,
  section,
}: RouteWithRememberPathProps) => {
  // HOOKS
  const dispatch = useDispatch();

  // EFFECTS
  React.useEffect(
    () => {
      dispatch(setMostRecentPath({ module, path, section }))
    },
    [dispatch],
  );

  // RENDER
  return (
    <Route {...{ component, path }} />
  );
};

export const SectionRoute = (props: RouteWithRememberPathProps) => (
  <Route path={props.path}>
    <RouteWithRememberPath key={props.path} {...props} />
  </Route>
);
