/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import React from 'react';

import './styles.css';

export const Chat: React.FC = () => {
  React.useEffect(() => {
    (window as any).HubSpotConversations.widget.load({ widgetOpen: true });

    return () => (window as any).HubSpotConversations.widget.remove();
  }, []);

  return (
    <>
      <div id="hubspot-wrapper" />;
    </>
  );
};
