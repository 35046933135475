import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TypeIds } from '../../../../../api/model/schemas';
import { Link } from '../../../../../api/model/schemas/Link';
import { LinkStatus } from '../../../../../api/model/schemas/LinkStatus';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { routes } from '../../../../../constants/routes';
import useSortControl from '../../../../../hooks/useSortControl';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import {
  addNestedEntityRoute,
  deleteEntityRoute,
  editEntityRoute,
} from '../../../../../utils/routes';
import { DefinedSortTypes } from '../../../../App/types';
import { patchEntity } from '../../../api';
import { getSelectedSourceId } from '../../../Catalog/selectors';
import { setSelectedEmptyLinkId, setSelectedLinkFieldsId } from '../../actions';
import {
  getLinkedEntityFields,
  getSelectedEmptyLinkId,
  getSelectedLinkFields,
} from '../../selectors';
import { SmartLinksButtons } from './buttons/SmartLinksButtons';

const LinksHeader = () => {
  // HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentSort } = useSortControl();

  // STATE
  const selectedSourceId = useSelector(getSelectedSourceId);
  const selectedLinkFields = useSelector(getSelectedLinkFields);
  const selectedEmptyLinkId = useSelector(getSelectedEmptyLinkId);
  const linkedEntityFields = useSelector(getLinkedEntityFields);

  // DERIVED STATE
  const fromEntityField = linkedEntityFields[selectedLinkFields?.fromId];
  const toEntityField = linkedEntityFields[selectedLinkFields?.toId];
  const linkFieldName = `${fromEntityField?.name} = ${toEntityField?.name}`;

  const isDeletingEmptyLink = currentSort === DefinedSortTypes.EmptyLinks;
  const isItemSelected = isDeletingEmptyLink
    ? !!selectedEmptyLinkId
    : !!selectedLinkFields;

  const selectedLinkId = isDeletingEmptyLink
    ? selectedEmptyLinkId
    : selectedLinkFields?.parentId;

  // CALLBACKS
  const handleEdit = React.useCallback(
    () => {
      const path = editEntityRoute(TYPE_IDS.Link, selectedLinkId);
      history.push(path, {
        assetsTypeId: TYPE_IDS.LinkFields,
        initialValues: {},
        async successCallback() {
          try {
            await patchEntity<Link>(TypeIds.Link)({
              id: selectedLinkId,
              status: LinkStatus.Accepted,
            });

            history.push(routes.designer.catalog.links);
          } catch(error) {
            console.error(error);
          }
        },
      });
    },
    [history, selectedLinkId],
  );

  const handleAdd = React.useCallback(
    () => {
      const path = addNestedEntityRoute({
        parentEntityId: selectedSourceId,
        schemaId: TYPE_IDS.Link,
        parentSchemaId: TYPE_IDS.DataSource,
      });

      history.push(path, {
        typeName: 'Link',
        goBackPage: routes.designer.catalog.links,
      });
    },
    [history, selectedSourceId],
  );

  const handleDelete = React.useCallback(
    () => {
      const idToDelete = isDeletingEmptyLink
        ? selectedEmptyLinkId
        : selectedLinkFields?.parentId;
      const nameToDelete = isDeletingEmptyLink
        ? `Empty Link ${selectedEmptyLinkId}`
        : linkFieldName;

      const path = deleteEntityRoute(TYPE_IDS.Link, idToDelete);

      const goBackPage = routes.designer.catalog.links;

      const successAction = isDeletingEmptyLink
        ? setSelectedEmptyLinkId(null)
        : setSelectedLinkFieldsId(null);

      history.push(path, {
        goBackPage,
        entityName: nameToDelete,
        successCallback: () => dispatch(successAction),
        deleteDescription: (
          <Trans
            i18nKey="wizard:deleteNestedEntity"
            values={{ name: nameToDelete, asset: t('entityTypes:Link') }}
          />
        ),
      });
    },
    [isDeletingEmptyLink, linkFieldName, selectedEmptyLinkId, selectedLinkFields],
  );

  // RENDER
  return (
    <>
      <SmartLinksButtons />
      <ItemActionsHeader
        isItemSelected={isItemSelected}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
    </>
  );
};

export default LinksHeader;
