import { createDialogAction, DialogInitializeResultAction } from "../shared/utils/dialog/actions";

// # ACTIONS

// OAUTH START
export const OAUTH_START_ACTION_TYPE = 'OAUTH/START';

export const oauthStart = (authorizationURL: string) => createDialogAction({
  type: OAUTH_START_ACTION_TYPE,
  payload: authorizationURL,
});
export type OAuthStartAction = ReturnType<typeof oauthStart>;

// OAUTH END
export const OAUTH_END_ACTION_TYPE = 'OAUTH/END';

export const oauthEnd = (accessToken: string) => createDialogAction({
  type: OAUTH_END_ACTION_TYPE,
  payload: accessToken,
});
export type OAuthEndAction = ReturnType<typeof oauthEnd>;

// # ACTION GROUPS

export type MessageFromHostAction =
  | OAuthStartAction
  ;

export type MessageFromDialogAction =
  | DialogInitializeResultAction
  | OAuthEndAction
  ;
