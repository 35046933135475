import { ITheme, ITextStyles } from '@fluentui/react';

export const linkStyles = (theme: ITheme) => ({
  textDecoration: 'none',
  color: theme.palette.themePrimary,
  marginTop: '8px',
});

export const titleStyles = (theme: ITheme): ITextStyles => ({
  root: {
    textAlign: 'center',
    margin: '0 0 12px',
    color: theme.palette.neutralPrimary,
  },
});

export const textStyles = (theme: ITheme): ITextStyles => ({
  root: {
    textAlign: 'center',
    margin: '0 0 24px',
    color: theme.palette.neutralPrimary,
    fontWeight: 300,
  },
});
