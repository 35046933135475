import { useEffect, useState } from 'react';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { IGenericEntity } from '../../types';
import * as api from '../../../api';

/**
 * This hook is use on fields of the entity wizard-form, when editing, where
 * the data source Id will be infered based on a source entity id.
 * Because these inputs are dropdowns, we need to return the setSourceId in case the user
 * decides to change it.
 */

export const useSourceFromEntity = (entityId: string, defaultSourceId?: string) => {
  const [sourceId, setSourceId] = useState<string>(defaultSourceId);

  useEffect(() => {
    (async () => {
      if (entityId) {
        try {
          const { data } = await api.fetchEntity<IGenericEntity>(
            TYPE_IDS.SourceEntity, entityId,
          )();
          setSourceId(data.parentId);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [entityId]);

  return { sourceId, setSourceId };
};
