import { IDataset } from '../../types/IDataset';
import { isOfficeEnabled } from '../../config/buildType';

/**
 * Checks if the selected range is part of an existing dataset, based on
 * its tableId.
 */

const checkCurrentSelection = async (
  datasets: IDataset[],
) => {
  let currentDataset : IDataset = null;
  let address: string;

  // TODO: this should be ran only for Excel, not all Office integrations
  if (isOfficeEnabled()) await Excel.run(async (ctx) => {
    try {
      // STEP 1. Load all tables that are part of the selected range.
      const range = ctx.workbook.getSelectedRange().load('address');
      const tables = range.getTables(false).load('items');
      await ctx.sync();
      address = range.address;

      if (tables.items) {
        for (let t = 0; t < tables.items.length; t++) {
          for (let d = 0; d < datasets.length; d++) {
            // STEP 2. Find if there is a dataset with the same tableId than
            // the current table
            if (datasets[d].tableId === tables.items[t].id) {
              currentDataset = datasets[d];
              return;
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  });

  return {
    currentDataset,
    address,
  };
};

export default checkCurrentSelection;
