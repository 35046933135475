import React from 'react';
import {
  IDropdownOption, ResponsiveMode, Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { SELECTED_LANGUAGE_LOCALSTORAGE_KEY } from '../../constants';
import i18n from '../../../../config/i18n';
import { LANGUAGE_CODES } from '../../../../constants/languages';

import Dropdown from '../../../../components/ui/Dropdown';
import { dropdownStyles } from '../../../Designer/Form/components/fields/styles';

const LanguagePicker: React.FC = () => {
  const { t } = useTranslation('languagePicker');

  const storedLang = window.localStorage.getItem(SELECTED_LANGUAGE_LOCALSTORAGE_KEY);

  const selectedLanguage = storedLang || i18n.language;

  const languages: IDropdownOption[] = [
    { key: LANGUAGE_CODES.enUS, text: t('en-us') },
    { key: LANGUAGE_CODES.es, text: t('es') },

    // Temporally removed.
    /* { key: LANGUAGE_CODES.enGB, text: t('en-uk') }, */
    /* { key: LANGUAGE_CODES.de, text: t('de') }, */
  ];
  const handleChange = async (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    const newLang = item.key as string;
    window.localStorage.setItem(SELECTED_LANGUAGE_LOCALSTORAGE_KEY, newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <Stack style={{ width: '100%' }} tokens={{ childrenGap: 20 }}>
      <Dropdown
        label={t('label')}
        selectedKey={selectedLanguage}
        onChange={handleChange}
        options={languages}
        responsiveMode={ResponsiveMode.large}
        styles={dropdownStyles()}
      />
    </Stack>
  );
};

export default LanguagePicker;
