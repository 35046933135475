import axios from 'axios';
import * as effects from 'redux-saga/effects';
import { TypeIds } from '../../../../api/model/schemas';
import { DataSourceEntity } from '../../../../api/model/schemas/DataSourceEntity';
import { DataSourceEntityField } from '../../../../api/model/schemas/DataSourceEntityField';
import {
  NotificationActivity,
  NotificationSubjectState,
} from '../../../../types/INotification';
import { appendNotificationIntent } from '../../../Notifications/actions';
import { fetchEntities } from '../../api';
import { ApiV4ResponseWrapper } from '../../types';
import * as ingestionActions from '../actions';
import {
  configureDataSource,
  processIngestionJob,
  configureSourceEntity,
} from '../api';
import {
  searchFailed,
  searchFinished,
  searchPerformed,
} from '../screens/Data/actions';
import { getSelectedSourceId } from '../selectors';

// DOCS link
// https://teams.microsoft.com/l/entity/com.microsoft.teamspace.tab.wiki/tab::6c9ecf0f-7ae3-400a-89c5-7580e81a6564?context=%7B%22subEntityId%22%3A%22%7B%5C%22pageId%5C%22%3A2%2C%5C%22sectionId%5C%22%3A10%2C%5C%22origin%5C%22%3A2%7D%22%2C%22channelId%22%3A%2219%3Aaf9ccf5b397e408da7de9cd7e02fcec4%40thread.tacv2%22%7D&tenantId=e403d4d1-1e37-46e4-ad49-2ad0113af75e

function* runIngestion({
  payload: { successCallback },
}: ReturnType<typeof ingestionActions.triggerIngestionJob>) {
  const selectedDataSourceId = getSelectedSourceId(yield effects.select());

  try {
    yield effects.put(ingestionActions.setIsIngestingDataSource(true));
    yield effects.call(processIngestionJob, selectedDataSourceId);

    while (true) {
      const {
        payload: notification,
      }: ReturnType<typeof appendNotificationIntent> = yield effects.take(
        appendNotificationIntent
      );

      if (
        notification.subjectId === selectedDataSourceId &&
        notification.activity === NotificationActivity.Process &&
        [
          NotificationSubjectState.Start,
          NotificationSubjectState.InProgress,
          NotificationSubjectState.Failed,
        ].includes(notification.state)
      ) {
        break;
      }
      yield effects.put(ingestionActions.setSelectedSourceId(null));
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield effects.put(ingestionActions.setIsIngestingDataSource(false));
    yield effects.call(successCallback);
  }
}
function* handleConfigureDataSource({
  payload: { historyGoBack, dataSourceId, reloadFields },
}: ReturnType<typeof ingestionActions.configureDataSourceIntent>) {
  try {
    yield effects.put(ingestionActions.setConfiguringDataSource(true));

    yield effects.call(configureDataSource, dataSourceId, reloadFields);

    while (true) {
      const {
        payload: notification,
      }: ReturnType<typeof appendNotificationIntent> = yield effects.take(
        appendNotificationIntent
      );

      if (
        notification.subjectId === dataSourceId &&
        notification.activity === NotificationActivity.Configure &&
        [
          NotificationSubjectState.Start,
          NotificationSubjectState.InProgress,
          NotificationSubjectState.Failed,
        ].includes(notification.state)
      ) {
        break;
      }
    }

    yield effects.put(ingestionActions.setSelectedSourceId(null));
  } catch (error) {
    console.log('err', error);
  } finally {
    yield effects.put(ingestionActions.setConfiguringDataSource(false));
    yield effects.call(historyGoBack);
  }
}

function* handleConfigureDataSourceEntity({
  payload: { entityId, onQueued, onEnd, onStart },
}: ReturnType<typeof ingestionActions.configureSourceEntityIntent>) {
  try {
    yield effects.call(onStart);
    yield effects.call(configureSourceEntity, entityId);
    while (true) {
      const {
        payload: notification,
      }: ReturnType<typeof appendNotificationIntent> = yield effects.take(
        appendNotificationIntent
      );

      if (
        notification.subjectId === entityId &&
        notification.activity === NotificationActivity.Configure
      ) {
        if (
          [
            NotificationSubjectState.Start,
            NotificationSubjectState.InProgress,
          ].includes(notification.state)
        ) {
          yield effects.call(onQueued);
        } else {
          break;
        }
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield effects.call(onEnd);
  }
}

function* handleSearchPerformed({
  payload: { dataSourceId, searchQuery },
}: ReturnType<typeof searchPerformed>) {
  const source = axios.CancelToken.source();

  try {
    const [searchEntitiesResponse, searchFieldsResponse] = yield effects.all([
      effects.call(
        fetchEntities<ApiV4ResponseWrapper<DataSourceEntity[]>>(
          TypeIds.DataSourceEntity
        ),
        {
          orderBy: 'name',
          filter: `parentId eq ${dataSourceId} and contains(name,'${searchQuery}')`,
        },
        source.token
      ),
      effects.call(
        fetchEntities<ApiV4ResponseWrapper<DataSourceEntityField[]>>(
          TypeIds.DataSourceEntityField
        ),
        {
          orderBy: 'name',
          filter: `parent/parentId eq ${dataSourceId} and contains(name, '${searchQuery}')`,
          expand: 'parent',
        },
        source.token
      ),
    ]);

    const {
      data: { data: entities, nextPage: entitiesNextPageUrl },
    } = searchEntitiesResponse;
    const {
      data: { data: fields, nextPage: fieldsNextPageUrl },
    } = searchFieldsResponse;

    yield effects.put(
      searchFinished({
        entities,
        entitiesNextPageUrl,
        fields,
        fieldsNextPageUrl,
      })
    );
  } catch (error) {
    yield effects.put(searchFailed());
  } finally {
    source.cancel();
  }
}

export default function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(
      ingestionActions.triggerIngestionJob.type,
      runIngestion
    ),
    yield effects.takeLatest(
      ingestionActions.configureDataSourceIntent.type,
      handleConfigureDataSource
    ),
    yield effects.takeEvery(
      ingestionActions.configureSourceEntityIntent.type,
      handleConfigureDataSourceEntity
    ),
    yield effects.takeLatest(searchPerformed.type, handleSearchPerformed),
  ]);
}
