import { all } from 'redux-saga/effects';

import filterSaga from './filters';
import changeDatasetIdSaga from './changeDatasetId';

export function* rootSaga() {
  yield all([
    filterSaga(),
    changeDatasetIdSaga(),
  ]);
}

export default rootSaga;
