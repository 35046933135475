export const TYPE_IDS = {
  AnaplanConfiguration: '72aace5e-2c05-4a04-b501-ec805f8e9b72',
  AzureDelimitedTextFile: '5385e9be-18dc-4c21-8feb-133b298e925f',
  AzureExcelFileConfiguration: 'bae0b6e5-9757-4a9e-a9ba-2d0440660a75',
  AzureRestConfigPagination: '152b949e-8b0d-4f4a-9940-bfd3a17911fe',
  AzureSqlServerConfiguration: '3f21c0f2-1551-46ef-8303-93ed7e421edc',
  CalculateEntityDisplayNamesRequest: 'bc6ad257-18fa-4c94-baf4-c0f5f2a16058',
  Calculation: 'bfb8d835-a301-454d-895e-98dfcd1ffb70',
  CalculationType: 'b23447c6-1867-41ae-8f73-a8a8b29f0a49',
  CloneEntityTransformation: '7a89b11e-7d5f-40af-8d70-efb92b940a9b',
  ColumnsToRowsTransformation: '950abe70-1e7c-4bae-98d1-f449b96bbbb9',
  ConfigureDataSourceEntity: '30b3616b-1cd6-4af8-8bc9-d48992e726d0',
  ConfigureDataSourceJob: '0FB391B5-4C50-431E-A789-9C6F1C9DA25B',
  ConfigureSourceEntityJob: '771588f4-bfbe-4c33-b6b2-747d8f1f366f',
  ConnectorConfigurationType: '4ecf2659-275a-418a-b26f-3212e91ab186',
  CustomPropertyValidValues: '803092d1-8105-4844-b644-68b40ee1ad92',
  DataSource: 'eb1d2cc4-a7db-4bf7-8d0d-f080b0702d7d',
  Design: '54b96780-dda5-4947-aeed-1a6232c39395',
  DesignShare: '247b9ace-4107-4d98-8089-be90decd8275',
  CreateDesignSnapshotJob: '01f5ecca-d20a-4185-988c-c91b18f041b8',
  DesignSnapshot: '053fc04f-4e2b-4c89-98d3-cba552cefcf7',
  ImportDesignJob: 'ba1009c8-a487-4bb5-8f3c-78c2fc506905',
  DismissNotificationsRequest: '123084ea-0f3d-4276-802c-36839add2eff',
  Endpoint: '0c3da73b-cf63-468e-aa67-e28c5188ce82',
  Filter: 'ce550110-cd40-4ac1-8972-5b4d84ff6feb',
  FindCoveringLinksRequest: 'c6a587be-1253-4c5b-b91c-d676f8ab80fd',
  GenerateGroupValuesJob: '688c9f08-c6bd-40e0-a779-887123ecb977',
  GenerateLinksJob: '0d2b56c9-dcb4-4a7f-86be-b958eaa0c193',
  Group: 'acce81ab-5368-4101-b10a-ee73be4b19fb',
  GroupValue: 'df731e7d-2c6c-4c7a-a05b-d9ff3b359fb3',
  GroupValuesRequest: 'd89a9e71-fa4d-47e4-acdc-efdf0e6f474a',
  Hub: 'e11d9494-e920-4e8f-b890-e055bdf311b9',
  HubAnalysisRequest: '233ba72e-7b97-4007-9dbf-b8574f78d319',
  HubSource: '4e7a56f4-7dbf-4997-88cd-cb7ffb3dd2ff',
  HubValuesRequest: 'ce7702fb-aa90-4583-b3be-a872b85cce03',
  Link: '0207203f-b5d4-431b-b663-d59081cf6bc9',
  LinkAnalysisRequest: '89b031d1-5c68-454d-aaed-f98e5bc56edb',
  LinkFields: '9b7df4d5-48a9-4b12-8e30-70fc9f13d4ff',
  LinkSection: '5523d1cc-0272-4b0f-918e-8159d881b32f',
  LiteralValue: '9ef87d6c-3abc-4c84-92d8-ffe15c88fa6f',
  LoadEntityFieldsRequest: 'd8a8ecd4-16bc-48ec-80b1-f927934364c4',
  MachineLearning: '29e712e0-15fd-4ca6-bed9-367903c0ea10',
  Notification: '22ed3908-b833-49b1-b15d-627544f89a0f',
  ProcessDataSourceJob: '81680429-45C3-420B-9DC7-140B7ED3F3E4',
  ProcessStreamJob: 'e0146917-9851-4755-81db-f1d63751ee6d',
  RowsToColumnsTransformation: '4285b9e7-9ba4-4e92-849e-b8cad6dcf294',
  SchemaProperty: '1bcf1a10-d290-49aa-a55d-3e1f19347729',
  Lookup: '827714d9-39d6-4b35-a0cb-29d2f8f97c6d',
  SendStreamDataJob: '12810a88-6cda-47bd-8859-86392596f294',
  SourceEntity: 'dddc12aa-bc3c-4468-8a20-d98824fa5df8',
  SourceEntityConfig: 'e7a34cda-aa2c-4d56-9147-5d6ada88ae43',
  SourceEntityField: '21db92d1-c009-4906-9e78-c648d76e0158',
  SourceEntityFieldFilter: 'c247cd7a-9ce1-4a2e-929b-ccd7d895b5dc',
  SourceEntityFieldValuesRequest: 'd40a22fa-829c-40d7-9660-a45d492fc9ab',
  SourceEntityTypes: 'b08ff19c-89b1-4277-9dcf-6168672903e4',
  Stream: '64ccbed4-90ae-42a5-b071-d4c6dd3e5dd0',
  StreamCalculation: '7bcb3ae8-f95b-498c-9ae8-19274debb8a7',
  StreamDataRequest: '7c86fce6-2b42-4dc8-ab40-31cf11bdc966',
  StreamField: 'cb00cb56-a051-4590-89b3-6e188d5f034c',
  StreamFieldFilter: '6712dd0c-0681-4798-9a6f-85ed87473c60',
  StreamGroupAsset: '8358520b-364d-4e8a-b5e3-0663c5ecd04e',
  StreamHub: '99dd7c63-a283-45e1-b571-236622cc8516',
  StreamLink: '38cb2b31-33cc-4c40-8374-b38451f4219e',
  StreamLookup: '109c4509-fdb0-4824-9437-2f4b20fca609',
  StreamOutput: '1dfb2af9-b55c-4eb0-a2ca-80ecd1379f07',
  StreamShare: '1e871b0b-5533-4313-a887-0a8fac61e26d',
  StreamMachineLearning: '3ed24c1b-051e-4916-80e2-0cf7a74a816b',
  Tag: '19bb2e4b-52bd-4ded-aadd-e8ad016c6d59',
  TransformFieldsIdsProperty: '25f05d88-8fec-46b5-bed8-f68d5dc5c75e',
  Type: '2f85fb2b-e5c2-4a1a-8981-1805a22af1eb',
  TypePropertySection: '4d0fae61-6ff4-4538-86fc-8f99e91718f9',
  User: '3d236c98-6a5c-46bf-ab48-a6530fabb2a7',
  XeroConfiguration: 'e2d478d2-04b2-49fd-928d-048de1883200',
  ConfigureMachineLearningJob: '32b294b5-3499-4cd6-97bb-2b851bc9cd44',
  MachineLearningAssetValuesRequest: '37e85e8d-00bd-4a17-a4c6-0a8acdfdc700',
  SmartFlowsRequest: 'd78b6ac5-12d9-4960-b8b5-aab53047f83c',
  NextWorldConfiguration: '0cb96d4b-6c58-476c-9891-d6a50cf7f36d',
  SalesforceConfiguration: '7653b80d-e59e-41f2-842b-dd33001a67d0',
} as const;

export const TYPE_IDS_TO_NAMES = Object.keys(TYPE_IDS).reduce(
  (accumulator, key) => ({
    ...accumulator,
    [TYPE_IDS[key]]: key,
  }),
  {}
);
