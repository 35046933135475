import { TutorialTile } from '../../../../shared/tutorial-tiles/model';

import TutorialAnimation from './TutorialAnimation';
import { IUser } from '../../../../types/IUser';

export const TUTORIAL_TILE_KEY = 'settings-profile';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:settings:profile:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey:
        'tutorialTiles:settings:profile:items:updateYourProfile:description',
      icon: 'PlayerSettings',
      titleI18nKey:
        'tutorialTiles:settings:profile:items:updateYourProfile:title',
    },
    {
      descriptionI18nKey:
        'tutorialTiles:settings:profile:items:chooseYourRoles:description',
      icon: 'Info',
      titleI18nKey:
        'tutorialTiles:settings:profile:items:chooseYourRoles:title',
    },
  ],
};

export const ORDER_OF_APPEARANCE: Array<Partial<keyof IUser>> = [
  'name',
  'email',
  'firstName',
  'lastName',
  'streetAddress',
  'city',
  'country',
  'postalCode',
  'state',
  'jobTitle',
  'company',
  'department',
  'mobilePhone',
  'businessPhone',
];
