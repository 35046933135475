import { IStackStyles, mergeStyleSets } from '@fluentui/react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../theme';
import { OUTPUT_PANEL_BREAKPOINT_MEDIA_QUERY } from './constants';

export const classNames = mergeStyleSets({
  container: {
    display: 'none',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 'calc(100vh - 32px)',
    width: '100%',
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
    overflowY: 'auto',
    [`@media${OUTPUT_PANEL_BREAKPOINT_MEDIA_QUERY}`]: {
      display: 'flex',
    },
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  textWrapper: {
    maxWidth: '200px',
  },
  textStyles: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerStyles: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '10px',
  },
  descriptionStyles: {
    fontSize: '12px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 !important',
    flexBasis: 1,
    overflow: 'hidden',
    borderLeft: `1px solid ${baseColors.inputBorder}`,
    borderRight: `1px solid ${baseColors.inputBorder}`,
    borderBottom: `1px solid ${baseColors.inputBorder}`,
    position: 'relative',
    borderBottomRightRadius: BORDER_RADIUS_IN_PX,
    borderBottomLeftRadius: BORDER_RADIUS_IN_PX,
  },
  sectionTitleWrapper: {
    minHeight: '36px',
    position: 'relative',
    marginTop: '8px',
    marginBottom: '-1px',
  },
  icon: {
    height: 16,
    width: 16,
  },
  commandBarStyles: {
    position: 'relative',
    marginTop: '1px !important',
  },
  wizardPageDetails: {
    position: 'absolute',
    right: 8,
    top: 0,
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'right',
  },
  wizardPageTitle: {
    fontSize: '8px',
    color: baseColors.textColor,
  },
  pageNumber: {
    fontSize: '12px',
    color: baseColors.midOrange,
  },
  rightSlotIconWrapper: {
    marginRight: '10px',
  },
  rightSlotTextWrapper: {
    textAlign: 'right',
  },
  lastUpdatedText: {
    fontSize: '8px',
    color: baseColors.inputBorder,
  },
  lastUpdatedTextSingle: {
    margin: 0,
  },
  spinner: {
    position: 'relative',
    top: '-6px',
    right: '6px',
  },
});

export const stackStyles: IStackStyles = {
  root: {
    padding: '0 8px 8px',
    maxHeight: '100%',
    flex: 1,
  },
};
