import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HeaderBarButton } from '../../../../../../components/ui';
import { routes } from '../../../../../../constants/routes';

const AddButton : React.FC = () => {
  // HOOKS
  const { t } = useTranslation();
  const history = useHistory();

  // CALLBACKS
  const handleClick = React.useCallback(() => {
    history.push(routes.designer.ingestion.filters.logic.add);
  }, [history]);

  return (
    <>
      <HeaderBarButton
        tooltip={(
          <Trans
            t={t}
            i18nKey="actionBar:filters.add"
          />
      )}
        onClick={handleClick}
        iconProps={{ iconName: 'Add' }}
        data-testid="add-filter-top-menu"
      />
    </>
  );
};

export default AddButton;
