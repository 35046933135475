import React from 'react';
import { useSelector } from 'react-redux';

import { IFoldableListGroup } from '../components/ui/FoldableList/types/IFoldableListGroup';

import { getSortType } from '../modules/App/selectors';
import { DefinedSortTypes } from '../modules/App/types';
import { getSearchQuery } from '../modules/Search/selectors';
import {
  itemsToAZLettersFoldableList,
  createItemsGroupedByProp,
} from '../utils/foldableList';
import { usePrevious } from '../utils/usePrevious';

const getMappingFunction = (sortType: string) => {
  // more functions will be added
  switch (sortType) {
    case DefinedSortTypes.Letter:
      return itemsToAZLettersFoldableList;
    default:
      return createItemsGroupedByProp(sortType);
  }
};
interface HasIdAndName {
  name: string;
  id: string;
}

interface Params<T extends HasIdAndName> {
  input: T[];
  selectedItemId?: string;
  onClickHeaderCallback?: (clickedKey: string) => void;
  onClickItemCallback?: (clickedItemKey: string) => void;
}

export default function <T extends HasIdAndName>({
  input,
  onClickHeaderCallback,
  selectedItemId,
}: Params<T>) {
  // HOOKS
  const currentSort = useSelector(getSortType);
  const searchQuery = useSelector(getSearchQuery);

  // STATE
  const [mappedGroups, setMappedGroups] = React.useState<IFoldableListGroup[]>(
    []
  );
  const [openGroupId, setOpenGroupId] = React.useState(null);
  const previousInput = usePrevious(input, []);

  // DERIVED STATE
  const hasSearchQuery = searchQuery !== '';
  const filteredGroups = React.useMemo(
    () =>
      hasSearchQuery
        ? mappedGroups.map((group) => ({ ...group, isOpen: true }))
        : mappedGroups,
    [mappedGroups, hasSearchQuery]
  );
  // CALLBACKS
  const onClickHeaderHandler = React.useCallback(
    (key: string, passedMappedGroups?: IFoldableListGroup[]) => {
      if (hasSearchQuery) return;

      // after initial mapping first group might be open, and openGroupId does not follow along
      // TO DO - rethink this whole hook, because it's getting scary
      const isAlreadyOpen =
        openGroupId === key ||
        mappedGroups.find((group) => group.key === key)?.isOpen;

      setOpenGroupId(isAlreadyOpen ? null : key);
      setMappedGroups([
        ...(passedMappedGroups || mappedGroups).map((group) => ({
          ...group,
          isOpen: isAlreadyOpen ? false : group.key === key,
        })),
      ]);
      if (onClickHeaderCallback) {
        onClickHeaderCallback(key);
      }
    },
    [openGroupId, mappedGroups, hasSearchQuery]
  );

  // EFFECTS
  React.useEffect(() => {
    const mappingFunction = getMappingFunction(currentSort);

    if (mappingFunction) {
      setMappedGroups(mappingFunction(input, selectedItemId, openGroupId));
    }
  }, [currentSort, input, selectedItemId]);

  // handle opening group of an asset that was just added
  React.useEffect(() => {
    if (input.length > previousInput.length && previousInput.length !== 0) {
      const added = input.filter(
        ({ id }) => !previousInput.some(({ id: id2 }) => id === id2)
      )[0];
      const mappingFunction = getMappingFunction(currentSort);
      // we meed to map all the entries, and close open groups
      // to open the relevant one
      const locallyMapped = mappingFunction(input, added.id, null);

      const relevantGroup = locallyMapped.find((group) =>
        group.items.find((item) => item.key === added.id)
      );

      // trigger open relevant group only if it's closed
      // but skip it if there is only 1 group - because it was already open
      if (relevantGroup.key !== openGroupId && locallyMapped.length > 1) {
        onClickHeaderHandler(relevantGroup.key, locallyMapped);
      }
    }
  }, [input, previousInput, onClickHeaderHandler]);

  // RESULT
  return {
    mappedGroups: filteredGroups,
    onClickHeaderHandler,
    currentSort,
  };
}
