import React, { FunctionComponent, useState } from 'react';
import {
  ColorPicker as ColorPickerBase,
  CommandBarButton,
  Callout,
} from '@fluentui/react';

import { buttonStyles, buttonClassName } from './styles';

export interface ColorPickerProps {
  color: string;
  isDisabled?: boolean;
  onChange(color: string): void;
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({
  color,
  isDisabled = false,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <CommandBarButton
        disabled={isDisabled}
        onClick={() => setIsOpen(!isOpen)}
        styles={buttonStyles(color)}
        className={buttonClassName}
      />

      { isOpen && (
        <Callout
          target={`.${buttonClassName}`}
          onDismiss={() => setIsOpen(false)}
        >
          <ColorPickerBase
            alphaType="none"
            color={`#${color}`}
            onChange={(_ev, newColor) => onChange(newColor.hex)}
          />
        </Callout>
      ) }
    </>
  );
};

export default ColorPicker;
