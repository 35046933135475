import React from 'react';
import EntityField from '../../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import { IDesignSource } from '../../../types';
import { getNextIngestionState, isFieldOrange, isFieldSelected } from './utils';
import DataTypeDropdown from './DataTypeDropdown';
import EntityFieldAggregationDropdown from './EntityFieldAggregationDropdown';
import DataSourceCalloutTitle from '../DataSourceCalloutTitle';
import { SchemaEntry } from '../../../../../components/DataSchemaMapper/model';
import { numberToHexColor } from '../../../../../components/ui/ColorPicker/utils';
import { ancestorSourceNameKey } from '../../../../../constants/infoBoxes';
import { DataSourceEntityField } from '../../../../../api/model/schemas/DataSourceEntityField';
import { EntityContext } from './EntityContext';
import { ProcessStatus } from '../../../../../api/model/schemas/ProcessStatus';
import { Availability } from '../../../../../api/model/schemas/Availability';

interface Props {
  field: DataSourceEntityField;
  infoSchema: Array<SchemaEntry>;
  source: IDesignSource;
  searchQuery?: string;
}

const DataSourceEntityField = ({
  field,
  infoSchema,
  source,
  searchQuery,
}: Props) => {
  // HOOKS
  const { patchEntityField } = React.useContext(EntityContext);

  // STATE
  const [isUpdating, setUpdating] = React.useState(false);

  // DERIVED STATE
  const { ingestionStatus, name, availability } = field;

  const isOrange = isFieldOrange(ingestionStatus);
  const isSelected = isFieldSelected(ingestionStatus);

  const renderedAvailability:Availability = ingestionStatus === ProcessStatus.NotProcessed  ? Availability.Available : availability

  const shouldRenderSlots = isSelected;

  // CALLBACKS
  const handleClick = React.useCallback(async () => {
    const nextIngestionStatus = getNextIngestionState(field.ingestionStatus);

    setUpdating(true);
    await patchEntityField({
      fieldId: field.id,
      updatedValues: {
        ingestionStatus: nextIngestionStatus,
      },
    });
    setUpdating(false);
  }, [field, patchEntityField]);

  // RENDER
  return (
    <EntityField
      {...{
        isOrange,
        isSelected,
        name,
        searchQuery,
      }}
      availability={renderedAvailability}
      info={{
        data: {
          ...field,
          [ancestorSourceNameKey]: source.name,
        },
        schema: infoSchema,
        title: (
          <DataSourceCalloutTitle
            {...{ name }}
            color={numberToHexColor(source.color)}
          />
        ),
      }}
      isSelectable
      onClick={handleClick}
      isSyncing={isUpdating}
      slot1={
        shouldRenderSlots ? (
          <EntityFieldAggregationDropdown {...{ field, setUpdating }} />
        ) : (
          <EntityFieldSlotSpacer />
        )
      }
      slot2={
        shouldRenderSlots ? (
          <DataTypeDropdown {...{ field, setUpdating }} />
        ) : (
          <EntityFieldSlotSpacer />
        )
      }
      data-testid={`ingestion-field-${field.name}`}
    />
  );
};

export default DataSourceEntityField;
