import { Stack, Text } from '@fluentui/react';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { CommandBar, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import moment from 'moment';
import { IconButtonProps } from '../../components/IconButton';
import { HeaderBarButton } from '../../components/ui';

import { headerStyles, getClassnames, stackStyles } from './styles';
import SectionTitle from '../../components/SectionTitle';
import { commandBarStyles } from '../../components/ContextualMenu/styles';
import { WizardType } from './types';
import { generateCommandBarConfig } from './utils';
import SortControl from '../../modules/Designer/components/SortControl';
import { WizardPageShimmer } from './WizardPageShimmer';
import { HeaderMessage } from '../../components/HeaderMessage/HeaderMessage';
import { useObservable } from '../../utils/observable';
import { activeRequestsCount } from '../../api/interceptors/requestsCount';

interface CommonProps {
  entityName?: string;
  headerBarButtonsConfig: IconButtonProps[];
  pageTitle: string;
  iconOverride?: string;
  isSyncing?: boolean;
}

type WizardTypeProps = {
  wizardType: WizardType;
  lastEditedWhen?: Date;
  lastEditedBy?: string;
};

type Props = CommonProps & WizardTypeProps;

const WizardPage: React.FC<Props> = ({
  children,
  headerBarButtonsConfig,
  iconOverride,
  lastEditedBy,
  lastEditedWhen,
  pageTitle,
  wizardType,
  entityName,
  isSyncing,
}) => {
  // HOOKS
  const { t } = useTranslation();
  const [requestCount] = useObservable(activeRequestsCount);

  // DERIVED STATA
  const isFetchingDataFromApi = requestCount > 0;

  const isShareWizard = wizardType === WizardType.Share;
  const localIconOverride = isSyncing ? 'Sync' : iconOverride;

  const classNames = getClassnames({
    isShareWizard,
  });

  // PARTS
  const renderModeBar = () => {
    if (isFetchingDataFromApi || isSyncing)
      return (
        <>
          <SectionTitle title={entityName} />
          <Spinner size={SpinnerSize.small} className={classNames.spinner} />
        </>
      );

    return (
      <>
        <SectionTitle title={entityName} />
        {isShareWizard ? (
          <SortControl />
        ) : (
          <>
            {wizardType === WizardType.Edit && lastEditedWhen && (
              <div className={classNames.rightSlotTextWrapper}>
                <Text className={classNames.lastUpdatedText}>
                  <p className={classNames.lastUpdatedTextSingle}>
                    {t('wizard:lastUpdatedTime', {
                      time: moment(lastEditedWhen).format('h:mm A M/D/YY'),
                    })}
                  </p>
                  <p className={classNames.lastUpdatedTextSingle}>
                    {t('wizard:lastUpdatedBy', { userName: lastEditedBy })}
                  </p>
                </Text>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  // RENDER
  return (
    <div className={classNames.container}>
      <Stack
        disableShrink
        styles={headerStyles}
        horizontal
        horizontalAlign="start"
        verticalAlign="center"
      >
        {headerBarButtonsConfig.map((btnProps) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <HeaderBarButton {...btnProps} key={btnProps.iconProps?.iconName} />
        ))}
      </Stack>
      <Stack styles={stackStyles}>
        <Stack
          className={classNames.sectionTitleWrapper}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          {renderModeBar()}
          <HeaderMessage />
        </Stack>
        <div className={classNames.commandBarStyles}>
          <CommandBar
            styles={commandBarStyles}
            items={generateCommandBarConfig(wizardType, localIconOverride)}
          />
          <div className={classNames.wizardPageDetails}>
            <Text className={classNames.wizardPageTitle}>{pageTitle}</Text>
          </div>
        </div>
        <div
          className={classNames.contentWrapper}
          data-testid="container-box"
          id="container-box"
        >
          {isSyncing && <WizardPageShimmer />}
          {children}
        </div>
      </Stack>
    </div>
  );
};

export default WizardPage;
