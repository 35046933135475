import React from 'react';

import { ITypeSectionProperty } from '../modules/Designer/types';
import getParsedTypeProperties from '../utils/getParsedTypeProperties';

interface Params {
  designId: string;
  typeId: string;
}

export default ({ designId, typeId }:Params) => {
  const [isLoading, setLoading] = React.useState(false);
  const [properties, setProperties] = React.useState<ITypeSectionProperty[]>([]);

  React.useEffect(() => {
    const getProperties = async () => {
      try {
        setLoading(true);
        const newProperties = await getParsedTypeProperties({ designId, typeId });
        setProperties(newProperties);
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    };
    if (typeId) {
      getProperties();
    }
  }, [typeId]);

  return {
    isLoading,
    properties,
  };
};
