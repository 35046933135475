import { omit } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AggregationType } from '../../../../../api/model/schemas/AggregationType';
import ButtonMenu from '../../../../../components/ui/ButtonMenu';
import { aggregationButtonMenuConfig } from '../../../../../constants/aggregations';
import {
  getAggregationsForAlreadyAggregatedItems,
  getInvalidAggregationMethodsApiV4,
} from '../../../../../utils/itemsAggregation';
import { updateStreamerAsset } from '../../../actions';
import {
  createGetStreamerAssetAsRawAsset,
  createGetWasAssetAggregationChanged,
} from '../../../selectors';
import { StreamerAsset } from '../../../types';
import { StreamerAssetWithUiid } from './types';

interface Props {
  asset: StreamerAssetWithUiid;
}

const DraggableAssetAggregationDropdown = ({ asset }: Props) => {
  // HOOKS
  const dispatch = useDispatch();

  // DERIVED STATE
  const rawAsset = useSelector(createGetStreamerAssetAsRawAsset(asset));
  const isOrange = useSelector(createGetWasAssetAggregationChanged(asset));

  const invalidAggregations = React.useMemo(() => {
    if (rawAsset.isAggregated) {
      return getAggregationsForAlreadyAggregatedItems();
    }

    return getInvalidAggregationMethodsApiV4(rawAsset.dataType);
  }, [asset]);

  const items = React.useMemo(
    () =>
      aggregationButtonMenuConfig()
        .filter((menuItem) => !invalidAggregations.includes(menuItem.key))
        .map((item) => ({
          ...item,
          checked: item.key === asset.aggregation,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [invalidAggregations]
  );

  // CALLBACKS
  const handleChange = React.useCallback(
    (newAggregation: AggregationType) => {
      if (newAggregation === asset.aggregation) return;

      const currentAsset: StreamerAsset = omit(asset, 'uuid');

      dispatch(
        updateStreamerAsset({
          currentAsset,
          updatedAsset: {
            ...currentAsset,
            aggregation: newAggregation,
          },
        })
      );
    },
    [asset]
  );

  // RENDER
  return (
    <ButtonMenu
      {...{ items, isOrange }}
      onChangeItem={handleChange}
      activeItemKey={asset.aggregation}
    />
  );
};

export default DraggableAssetAggregationDropdown;
