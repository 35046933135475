import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from './TutorialAnimation';

export const TUTORIAL_TILE_KEY = 'designer-catalog-data';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:catalog:data:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:data:items:addNamesAndDescriptions:description',
      icon: 'EykoData',
      titleI18nKey: 'tutorialTiles:designer:catalog:data:items:addNamesAndDescriptions:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:data:items:tagYourData:description',
      icon: 'Tag',
      titleI18nKey: 'tutorialTiles:designer:catalog:data:items:tagYourData:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:data:items:customData:description',
      icon: 'WindowEdit',
      titleI18nKey: 'tutorialTiles:designer:catalog:data:items:customData:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010230081-Designer-Data-Enrich-your-data',
};
