import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { selectors } from '../../../../Streams/Streams';
import { rewindStreamIntent } from '../../../../Streams/actions';
import { getIsOtherUserSyncing } from '../../../selectors';

import TeachingBubbles from '../../../../Onboarding/components/TeachingBubbles';
import { finalTeachingBubbles } from '../../../../Onboarding/constants';
import {
  createClientPreferences,
  editClientPreferences,
  getClientPreferences,
} from '../../../../ClientPreferences/actions';
import { getClientPreferencesListAsObject } from '../../../../ClientPreferences/selectors';
import { ToggleValues, UserPreferenceIds } from '../../../../../types/IClientPreferences';
import { HeaderBarButton } from '../../../../../components/ui';

const RewindButton : React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientPreferences = useSelector(getClientPreferencesListAsObject);
  const onboardingValue = clientPreferences[UserPreferenceIds.ONBOARDING];

  const isOtherUserBlockingUI = useSelector(getIsOtherUserSyncing);
  const isSyncing = useSelector(selectors.getIsSyncing);
  const isRewinding = useSelector(selectors.getIsRewinding);

  const shouldDisplayRewindButton = useSelector(selectors.getIfTableRewindButtonShouldBeVisible);

  const isDisabled = !shouldDisplayRewindButton
    || isOtherUserBlockingUI
    || isSyncing
    || isRewinding;

  const handleRefreshClick = async () => {
    dispatch(rewindStreamIntent());
  };

  const handleLastBubble = async () => {
    const newPreference = {
      id: UserPreferenceIds.ONBOARDING,
      value: ToggleValues.Off,
    };

    if (!onboardingValue) {
      await dispatch(createClientPreferences(newPreference));
    } else {
      await dispatch(editClientPreferences(newPreference));
    }

    await dispatch(getClientPreferences());
  };

  if (!shouldDisplayRewindButton) {
    return null;
  }

  return (
    <>
      <TeachingBubbles bubbles={finalTeachingBubbles} afterLastAction={handleLastBubble} />
      <HeaderBarButton
        tooltip={(
          <Trans t={t} i18nKey="actionBar:general.rewind" />
        )}
        onClick={handleRefreshClick}
        iconProps={{ iconName: 'Previous' }}
        data-testid="refresh-top-menu"
        disabled={isDisabled}
        shouldHideOnDisabled
      />
    </>
  );
};

export default RewindButton;
