import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupCollapse } from '../../../../components/ui';
import { IFoldableListGroup } from '../../../../components/ui/FoldableList/types/IFoldableListGroup';
import { createGetIsAssetQueued, getSelectedAssetsIds } from '../../selectors';
import { GroupAssetClickedActionType, StreamAssetRaw } from '../../types';
import { rawAssetToStreamerAsset } from '../../utils';
import { groupAssetsClicked } from '../../actions';

interface Props {
  group: IFoldableListGroup;
  onIconClick: (key: string) => void;
  normalizedAssets: Record<string, StreamAssetRaw>;
  searchQuery?: string;
}

export const SelectAssetsGroupCollapse: React.FC<Props> = ({
  children,
  group,
  onIconClick,
  normalizedAssets,
  // TO DO - support search
  // searchQuery,
}) => {
  const dispatch = useDispatch();

  // STATE
  const selectedAssetIds = useSelector(getSelectedAssetsIds);
  const getIsAssetQueued = useSelector(createGetIsAssetQueued);

  // DERIVED STATE
  const isFullyMapped = React.useMemo(() => {
    const groupItemIds = group.items.map((item) => item.key);

    return groupItemIds.every((id) => selectedAssetIds.includes(id));
  }, [selectedAssetIds]);

  const currentSelectionsCount = React.useMemo(
    () =>
      selectedAssetIds.filter((id) =>
        group.items.map((item) => item.key).includes(id)
      ).length,
    [selectedAssetIds, group?.items]
  );

  const isOrange = React.useMemo(
    () =>
      group.items.some((item) => {
        const relatedAsset = normalizedAssets[item.key];

        return relatedAsset ? getIsAssetQueued(relatedAsset) : false;
      }),
    [group.items, normalizedAssets]
  );

  // CALLBACKS
  const onTextClick = React.useCallback(() => {
    const assets = group.items
      .map((item) => normalizedAssets[item.key])
      .map(rawAssetToStreamerAsset);

    dispatch(
      groupAssetsClicked({
        actionType: isFullyMapped
          ? GroupAssetClickedActionType.Deselect
          : GroupAssetClickedActionType.Select,
        assets,
      })
    );
  }, [group.items, normalizedAssets, isFullyMapped]);

  return (
    <GroupCollapse
      {...{ onTextClick, onIconClick }}
      groupId={group.key}
      groupName={group.name}
      isOpen={group.isOpen}
      isSelected={isFullyMapped}
      isRenderingOnQueryMismatch
      isOrange={isOrange}
      isSticky
      selections={{
        total: group.items.length,
        current: currentSelectionsCount,
      }}
    >
      {children}
    </GroupCollapse>
  );
};
