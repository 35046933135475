import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IFoldableListItem } from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';
import EntityField from '../../../../../components/EntityField';

import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../../App/types';

import { ICalculation } from '../../../types';

import {
  getCalculations,
  getIsUpdating,
  getDataSources,
} from '../../selectors';
import {
  fetchCalculations,
  updateEntity,
  fetchDataSources,
} from '../../actions';
import useEditableItems from '../../../../../hooks/useEditableItems';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import { EmptyMessage, GroupCollapse } from '../../../../../components/ui';
import { getSelectedItemsIds } from '../../../../App/selectors';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { getSearchQuery } from '../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../Search/utils';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { CalculationKeys } from '../../../../../api/model/schemas/Calculation';
import { addNestedEntityRoute } from '../../../../../utils/routes';

const Calculations: FunctionComponent = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  // STATE
  const selectedDesignId = useSelector(getSelectedDesignId);

  const { records, isLoading: isCalculationsLoading } =
    useSelector(getCalculations);
  const isUpdating = useSelector(getIsUpdating);
  const designId = useSelector(getSelectedDesignId);

  const selectedEntitiesIds = useSelector(getSelectedItemsIds);
  const searchQuery = useSelector(getSearchQuery);

  const { records: dataSources, isLoading: isDataSourcesLoading } =
    useSelector(getDataSources);

  // DERIVED STATE
  const { properties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.Calculation,
  });

  const schema = useMemo(
    () => [
      ...getSchemaFromTypeProperties(properties, TYPE_IDS.Calculation),
      // TODO: contact Stephan about making some additional attributes shown in info boxes on the API side
      {
        key: CalculationKeys.calculationTypeId,
        translationPrefix: TYPE_IDS.Calculation,
      },
      {
        key: CalculationKeys.dataType,
        translationPrefix: TYPE_IDS.Calculation,
      },
      {
        key: CalculationKeys.formatType,
        translationPrefix: TYPE_IDS.Calculation,
      },
    ],
    [properties]
  );

  const filteredRecords = useMemo(
    () =>
      records.filter(
        (record) =>
          searchQuery === '' || matchesSearchQuery(searchQuery, record.name)
      ),
    [records, searchQuery]
  );

  const sourceFieldsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds,
  });

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:catalog:calcs:cta:noDataIngested':
        dataSources.length === 0,
    }),
    [dataSources.length]
  );

  // CALLBACKS
  const onEditEndCallback = React.useCallback(
    (hasChanged: boolean) => {
      if (hasChanged) {
        dispatch(fetchCalculations(selectedDesignId));
      }
    },
    [dispatch, fetchCalculations, selectedDesignId]
  );

  const onEntityUpdated = React.useCallback(() => {
    dispatch(fetchCalculations(selectedDesignId));
  }, [dispatch, fetchCalculations, selectedDesignId]);

  const { isEditing, onEditEnd, onEditStart } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  const handleRename = React.useCallback(
    async (dataSourceId: string, newName: string) => {
      await dispatch(
        updateEntity({
          entity: {
            id: dataSourceId,
            name: newName,
          },
          typeId: TYPE_IDS.Calculation,
        })
      );

      await dispatch(fetchCalculations(selectedDesignId));
    },
    [dispatch, fetchCalculations, selectedDesignId]
  );

  // NOTE: based on CalcsHeader.tsx
  const handleAdd = React.useCallback(() => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.Calculation,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesignId,
    });

    history.push(path, {
      typeName: 'Calculation',
    });
  }, [history, selectedDesignId]);

  // EFFECTS
  useEffect(() => {
    if (records.length === 0 || dataSources.length === 0)
      dispatch(fetchDataSources(selectedDesignId));
  }, [dispatch, dataSources.length, records.length, selectedDesignId]);

  useEffect(() => {
    if (selectedDesignId) dispatch(fetchCalculations(selectedDesignId));
  }, [dispatch, selectedDesignId]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: records.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    onStartClick: handleAdd,
    startButtonStates: tutorialTileRequirements,
  });

  const { handleItemClickedOnSingleSelection, selectedItem } =
    useItemSelections();

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: sourceFieldsWhichIncludesSelections,
      selectedItemId: selectedItem,
    });

  const renderCalculation = (calculation: ICalculation) =>
    calculation ? (
      <EntityField
        key={calculation.id}
        isSelectable
        isSelected={calculation.id === selectedItem}
        info={{
          data: calculation,
          schema,
          title: calculation.name,
          enableInlineEdit: true,
          onEntityUpdated,
        }}
        onClick={() => handleItemClickedOnSingleSelection(calculation.id)}
        data-testid={`catalog-calculation-${calculation.name}`}
        slot1={<EntityFieldSlotSpacer />}
      >
        <EditableFoldableListItem
          text={calculation.name}
          onEdit={(newVal) => handleRename(calculation.id, newVal)}
          isSelected={calculation.id === selectedItem}
          onEditStart={() => {
            const isSelected = calculation.id === selectedItem;
            // for consistency mark source as selected when editing
            if (!isSelected) {
              handleItemClickedOnSingleSelection(calculation.id);
            }
            onEditStart();
          }}
          onEditEnd={onEditEnd}
          transparentBackground
          disableEditing={isEditing}
          searchQuery={searchQuery}
          availability={calculation.availability}
        />
      </EntityField>
    ) : null;

  // RENDER
  if (isCalculationsLoading || isUpdating || isDataSourcesLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isCalculationsLoading && records.length === 0) {
    return (
      <EmptyMessage
        message={t('wizard:noEntityDefined', {
          entityType: t('entityTypes:Calculation', { count: 0 }),
        })}
      />
    );
  }

  if (currentSort === DefinedSortTypes.Name) {
    const sortedFields = [...sourceFieldsWhichIncludesSelections].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return <>{sortedFields.map((field) => renderCalculation(field))}</>;
  }

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = records.find((r) => r.id === item.key);

    return renderCalculation(entry);
  };

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onClick={onClickHeaderHandler}
        >
          {group.items.map((item) => onRenderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default Calculations;
