import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
  getCustomTheme,
  customStyles,
  styledDropdownComponents,
} from './customization';

const AsyncCreatableDropdown: React.FC<Record<string, unknown>> = (props) => (
  <AsyncCreatableSelect
    components={styledDropdownComponents}
    theme={getCustomTheme}
    styles={customStyles}
    menuPlacement="auto"
    maxMenuHeight={200}
    {...props}
  />
);

export default AsyncCreatableDropdown;
