import { escapeRegExp } from 'lodash';

export const generateUniqueName = (value:string, current: string[]) => {
  if (!current.includes(value)) {
    return value;
  }

  const regx = new RegExp(`^${escapeRegExp(value)}(?:\\((\\d+)\\))?$`);

  const arr = current.reduce((acc, s) => {
    const m = s.match(regx);
    if (m) {
      return [...acc, +m?.[1] || 0];
    }
    return acc;
  }, []);

  if (!arr.length) {
    return value;
  }

  arr.sort();

  let n = arr[0];

  for (let i = 1; i < arr.length; i += 1) {
    if (n + 1 < arr[i]) {
      break;
    }
    n = arr[i];
  }

  return `${value}(${n + 1})`;
};
