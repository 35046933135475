import { IIconProps } from 'office-ui-fabric-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCancelToken } from '../../../../../../../shared/hooks/useCancelToken';
import { TypeIds } from '../../../../../../api/model/schemas';
import { Link } from '../../../../../../api/model/schemas/Link';
import { LinkStatus } from '../../../../../../api/model/schemas/LinkStatus';
import { HeaderBarButton } from '../../../../../../components/ui';
import { patchEntity } from '../../../../api';
import { getSelectedSourceId } from '../../../../Catalog/selectors';
import { fetchDesignLinks, linksDiscoveryIntent } from '../../../actions';
import {
  getAllLinks,
  getIsSmartAnalysisLinksInProgress,
} from '../../../selectors';

const MAGIC_WAND_ICON: IIconProps = {
  iconName: 'SmartCatalogMagicWand',
};

const REVERT_ICON: IIconProps = {
  iconName: 'Undo',
};

export const SmartLinksButtons = () => {
  // HOOKS
  const cancelToken = useCancelToken();
  const dispatch = useDispatch();

  // STATE
  const selectedDataSourceId = useSelector(getSelectedSourceId);
  const links = useSelector(getAllLinks);
  const isSmartAnalysisLinksInProgress = useSelector(
    getIsSmartAnalysisLinksInProgress
  );

  // DERIVED STATE
  const proposedLinks = React.useMemo(
    () =>
      Object.values(links).filter(
        (link) => link.status === LinkStatus.Proposal
      ),
    [links]
  );

  // CALLBACKS
  const handleWandClick = React.useCallback(async () => {
    dispatch(linksDiscoveryIntent({ dataSourceId: selectedDataSourceId }));
  }, [dispatch]);

  const updateLinkStatus = React.useCallback(
    async (link: Link) => {
      try {
        await patchEntity<Link>(TypeIds.Link)(
          {
            id: link.id,
            status: LinkStatus.Rejected,
          },
          undefined,
          cancelToken
        );
      } catch (error) {
        console.error(error);
      }
    },
    [cancelToken]
  );

  const handleRevertClick = React.useCallback(async () => {
    await Promise.all(proposedLinks.map((link) => updateLinkStatus(link)));

    dispatch(fetchDesignLinks({ dataSourceId: selectedDataSourceId }));
  }, [dispatch, proposedLinks, selectedDataSourceId, updateLinkStatus]);

  // RENDER
  return (
    <>
      <HeaderBarButton
        iconProps={MAGIC_WAND_ICON}
        onClick={handleWandClick}
        disabled={!selectedDataSourceId || isSmartAnalysisLinksInProgress}
        shouldHideOnDisabled
      />
      <HeaderBarButton
        iconProps={REVERT_ICON}
        onClick={handleRevertClick}
        disabled={proposedLinks.length === 0 || isSmartAnalysisLinksInProgress}
        shouldHideOnDisabled
      />
    </>
  );
};
