import { IconButton, Stack } from '@fluentui/react';
import React, { useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ENTITY_CHILDREN_KEY } from '../../constants';
import { getAllEntitiesByTypeId } from '../../selectors';
import { IGenericFormField } from '../../types';
import useMultiEntityForm from '../ManageMultiple/hooks/useMultiEntityForm';
import Property from '../Property';
import AssetShimmer from './AssetShimmer';
import { classes, btnStyles } from './styles';
import { translateApiName } from '../../../../../config/i18n/utils';

const AssetPicker: React.FC<IGenericFormField> = ({
  interaction,
  currentForm,
  isEditing,
  currentEntity,
}) => {
  const typeId = interaction.referenceType;
  const { schema } = useMultiEntityForm(interaction.referenceType);
  const { fields, append, remove } = useFieldArray({
    name: `${ENTITY_CHILDREN_KEY}.${typeId}`,
  });
  // When editing, only render entities that are direct children of the current entity
  const entities = useSelector(
    getAllEntitiesByTypeId(typeId, currentEntity?.id)
  );

  //  react-hook-form requires to pass a default value when appending a field,
  //  but in our case, defaults are took care internally by Property component,
  //  so we just pass an empty object and let Property fill it.

  const onAdd = useCallback(() => append({}), [append]);

  // Add all the existing hub sources

  useEffect(() => {
    const entityCount = entities?.length;
    if (entityCount) {
      for (let i = fields.length; i < entityCount; i += 1) {
        onAdd();
      }
    }
  }, []);

  // Ensure the minimum amount of assets while creating

  useEffect(() => {
    const min = interaction?.minimum;

    if (min > 0 && !isEditing) {
      for (let i = fields.length; i < min; i += 1) {
        onAdd();
      }
    }
  }, [fields.length, onAdd]);

  return (
    <>
      {fields.map((item, i) => {
        const key = `${ENTITY_CHILDREN_KEY}.${typeId}[${i}]`;
        return (
          <Stack
            className={classes.wrap}
            horizontal
            key={item.id}
            verticalAlign="center"
          >
            <Stack className={classes.asset}>
              {schema?.properties?.map((property) => (
                <Property
                  key={`${item.id}-${property.id}`}
                  label={translateApiName(schema?.parentId, property?.name)}
                  property={{
                    ...property,
                    interaction: {
                      ...property?.interaction,
                      isRequired: true,
                    },
                  }}
                  name={`${key}.data.${property?.name}`}
                  currentForm={currentForm}
                  currentEntity={entities[i]}
                />
              )) || <AssetShimmer />}
            </Stack>
            {i >= interaction?.minimum && (
              <IconButton
                iconProps={{ iconName: 'Delete' }}
                onClick={() => remove(i)}
                styles={btnStyles}
              />
            )}
          </Stack>
        );
      })}

      {(!interaction?.maximum || fields.length < interaction?.maximum) && (
        <IconButton
          className={classes.add}
          iconProps={{ iconName: 'Add' }}
          onClick={() => onAdd()}
          styles={btnStyles}
        />
      )}
    </>
  );
};

export default AssetPicker;
