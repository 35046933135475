import React from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import { Stack } from '@fluentui/react';

import { useSelector } from 'react-redux';
import { routes } from '../../../../constants/routes';

import FiltersPage from '../FiltersPage';
import Design from '../../../../components/main/streams/Design';
import DataMode from '../DataMode';
import streamSelectors from '../../selectors';

import { containerClassName, wrapperStyles, wrapperStackTokens } from '../../../../components/main/streams/styles';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { TableLegend } from '../../components/TableLegend/TableLegend';

const Streams: React.FC = () => {
  const isFetchingStreamColumns = useSelector(streamSelectors.getIsFetchingAvailableColumns);

  if (isFetchingStreamColumns) {
    return (
      <ItemsListShimmer />
    );
  }

  return (
    <Stack
      tokens={wrapperStackTokens}
      styles={wrapperStyles}
    >
      <div
        id="scroll-box"
        data-testid="search-results-box"
        data-is-scrollable="true"
        className={containerClassName}
      >
        <Switch>
          <Route
            path={[
              routes.streams.values.index,
              routes.streams.filters.index,
            ]}
            component={FiltersPage}
          />
          <Route path={routes.streams.design.index} component={Design} />
          <Route path={routes.streams.list.index} component={DataMode} />
          <Route path={routes.streams.tableLegend.index} component={TableLegend} />
          <Redirect to={routes.streams.list.index} />
        </Switch>
      </div>
    </Stack>
  );
};

export default Streams;
