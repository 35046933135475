import React from 'react';
import { useSelector } from 'react-redux';
import { JobStatus } from '../../../../../api/model/schemas/JobStatus';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { fetchEntities } from '../../../api';
import { ApiV4ResponseWrapper } from '../../../types';
import { areDesignsLoading } from '../../selectors';

export const useQueuedImports = () => {
  const isFetchingDesigns = useSelector(areDesignsLoading);

  const [importsInProgressCount, setCount] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);

  const fetchImportsCount = async () => {
    setLoading(true);
    try {
      const response = await fetchEntities<ApiV4ResponseWrapper<{}>>(
        TYPE_IDS.ImportDesignJob
      )({
        filter: `status eq '${JobStatus.InProgress}' or status eq '${JobStatus.Queued}'`,
        count: true,
      });

      setCount(response.data?.count);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isFetchingDesigns) {
      fetchImportsCount();
    }
  }, [isFetchingDesigns]);

  return {
    importsInProgressCount,
    isLoading,
  };
};
