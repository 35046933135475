import { combineReducers } from 'redux';

import * as Streams from '../modules/Streams/Streams';
import notificationsReducer from '../modules/Notifications/reducer';
import appReducer from '../modules/App/reducer';
import groupTableReducer from '../modules/GroupTable/reducer';
import userReducer from '../modules/User/reducer';
import searchReducer from '../modules/Search/reducer';
import clientPreferences from '../modules/ClientPreferences/reducer';
import columnsSequencing from '../modules/ColumnsSequencing/reducer';
import streamFilters from '../modules/StreamFilters/reducer';
import designerContentLibrary from '../modules/Designer/ContentLibrary/reducer';
import designerEntityForm from '../modules/Designer/Form/reducer';
import designerCatalog from '../modules/Designer/Catalog/reducer';
import designerIngestion from '../modules/Designer/Ingestion/reducer';
import designerMetadata from '../modules/Designer/Metadata/reducer';
import builder from '../modules/Builder/reducer';
import sectionRouteHistory from '../shared/section-route/reducer';
import streamerReducer from '../modules/Streamer/reducer';
import streamerOutput from '../modules/Streamer/strategies/isolated/reducer';

const reducers = {
  app: appReducer,
  groupTable: groupTableReducer,
  notifications: notificationsReducer,
  streams: Streams.reducer,
  streamer: streamerReducer,
  user: userReducer,
  search: searchReducer,
  clientPreferences,
  columnsSequencing,
  streamFilters,
  designerCatalog,
  designerContentLibrary,
  designerIngestion,
  designerEntityForm,
  designerMetadata,
  builder,
  sectionRouteHistory,
  streamerOutput,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
