import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_PREFIX } from '../constants';
import { ShareLinkDataType } from '../model';
import { classNames } from '../styles';
import { ShareIndicator } from './ShareIndicator';

interface Props {
  copyingFinished?: ShareLinkDataType;
  isError: boolean;
  onImageShare(): void;
  writingToClipboard?: ShareLinkDataType;
}

export const ShareImageButton = ({
  copyingFinished,
  isError,
  onImageShare,
  writingToClipboard,
}: Props) => {
  // DEPS
  const { t } = useTranslation();

  // DERIVED STATE
  const isCopyingFinished = copyingFinished === ShareLinkDataType.Image;
  const isWritingToClipboard = writingToClipboard === ShareLinkDataType.Image;

  // RENDER
  return (
    <button
      className={classNames.shareButton}
      onClick={onImageShare}
      type="button"
    >
      <ShareIndicator
        {...{ isCopyingFinished, isError, isWritingToClipboard }}
      />
      {t(`${I18N_PREFIX}.buttons.image`)}
    </button>
  );
};
