import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import {
  getCustomTheme,
  customStyles,
  styledDropdownComponents,
} from './customization';

type Props = Partial<React.ComponentProps<typeof CreatableSelect>>;

// eslint-disable-next-line react/display-name
const CreatableDropdown = React.forwardRef<Props['ref'], Props>((props, ref) => {
  // HOOKS
  const { t } = useTranslation();

  // RENDER
  return (
    <CreatableSelect
      ref={ref}
      components={styledDropdownComponents}
      theme={getCustomTheme}
      styles={customStyles}
      menuPlacement="auto"
      placeholder={t('filters:Select')}
      maxMenuHeight={200}
      {...props}
    />
  );
});

export default CreatableDropdown;
