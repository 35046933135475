import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../../shared/tutorial-tiles/components/animations/Sources';

export const TUTORIAL_TILE_KEY = 'designer-catalog-sources';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:catalog:sources:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:sources:items:selectSources:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:designer:catalog:sources:items:selectSources:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:sources:items:catalogYourData:description',
      icon: 'EykoData',
      titleI18nKey: 'tutorialTiles:designer:catalog:sources:items:catalogYourData:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:catalog:sources:items:youAreInCharge:description',
      icon: 'Calculator',
      titleI18nKey: 'tutorialTiles:designer:catalog:sources:items:youAreInCharge:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010204402-Designer-Assets-Managing-your-data-assets',
};
