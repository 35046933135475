import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { IStream } from '../../../../types/IStream';
import { setCurrentStreamIdIntent } from '../../../Streams/actions';
import { routes } from '../../../../constants/routes';
import LargeThumbnailPicker from '../../../../pageTemplates/LargeThumbnailPicker';
import { ISingleLargeThumbnail } from '../../../../pageTemplates/LargeThumbnailPicker/LargeThumbnailPicker';
import { AssetProcessStatus } from '../../../../api/model/AssetProcessStatus';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../App/types';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { classNames } from './styles';
import { useSearchFilteredRecords } from '../../../../hooks/useSearchFilteredRecords';
import { setTable } from '../../../App/actions';

interface Props {
  streams: IStream[];
}

const streamToThumbnail = (stream: IStream): ISingleLargeThumbnail => ({
  iconName: stream.icon ? stream.icon : 'Database',
  id: stream.id,
  name: stream.name,
  isSyncing: stream.processStatus === AssetProcessStatus.Processing,
  itemTestId: `stream-tile-${stream.name}`,
});

const StreamsList: React.FC<Props> = ({ streams }) => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { filteredRecords } = useSearchFilteredRecords(streams);

  // DERIVED STATE
  const normalizedStreams = React.useMemo(
    () => (streams ? normalizeById(streams) : {}),
    [streams]
  );

  const { mappedGroups, currentSort } = useSortedFoldableGroups({
    input: filteredRecords ?? [],
  });

  const itemsToRender: ISingleLargeThumbnail[] =
    filteredRecords?.map(streamToThumbnail) ?? [];

  // CALLBACKS
  const handleStreamSelected = React.useCallback(
    (streamKey: string) => {
      dispatch(setCurrentStreamIdIntent(streamKey));
      // temporarily we are only allowing to work with table in excel
      // reason below
      // https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/7512/
      dispatch(setTable());
      history.push(routes.streams.list.index);
    },
    [dispatch, history]
  );

  // RENDER
  if (currentSort !== DefinedSortTypes.Name) {
    return (
      <>
        {mappedGroups.map((group) => {
          const { name, key } = group;
          const relatedStreams = group.items.map(
            (item) => normalizedStreams[item.key]
          );

          const items = relatedStreams
            .map(streamToThumbnail)
            .sort((streamA, streamB) =>
              streamA.name.trim().localeCompare(streamB.name.trim())
            );
          return (
            <div
              // I noticed a small lag when operating on larger lists when key was solely based on the "key" prop of group.
              // React tried to reuse the old structure. Incorporating current sort tells React to drop it if current sort changes
              key={`${key}${currentSort}`}
              className={classNames.groupWrapper}
            >
              <p className={classNames.groupTitle}>{name}</p>
              <LargeThumbnailPicker
                items={items}
                handleClick={handleStreamSelected}
                emptyMessage={t('builder:noStreamsMessage')}
                selectedItemId={null}
              />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <LargeThumbnailPicker
      items={itemsToRender}
      handleClick={handleStreamSelected}
      emptyMessage={t('builder:noStreamsMessage')}
      selectedItemId={null}
    />
  );
};

export default StreamsList;
