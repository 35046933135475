import moment from 'moment';
import {
  actionCreators,
  setCheckingCurrentSelection,
  setDatasets,
  checkWorkbookSelection,
  setCurrentStreamIdIntent,
} from '../../modules/Streams/actions';
import { IColumn } from '../../types/IColumn';
import { IDataset, TEMP_DATASET } from '../../types/IDataset';
import {
  mapMatrix,
  buildGetColumnFunction,
  generateColumnHeader,
  bindThunkActionCreators,
  provideDataset,
  fetchDatasetData,
  reportError,
  applyCustomAggregations,
  fetchDatasetTotals,
  formatTotalsRows,
  columnLettersToNumber,
  applyColumnFilters,
  getFormat,
  getFirstColumnLetterFromAddress,
  escapeToExcelValidData,
  getWidthFactor,
  formatTotalsByColumn,
  getEscapedDatasetTotals,
  pickTextColorBasedOnBgColorSimple,
} from './utils';
import { getSessionId } from '../../modules/App/sessionId';
import { ObjectTypes } from '../../modules/App/types';
import { DATA_SETS_STORAGE_KEY } from '../../store/persistence/constants';
import { setTable } from '../../modules/GroupTable/actions';
import { handleError } from './handleError';
import { renderDatasetAsMatrix } from '../../modules/GroupTable/sagas/renderDatasetAsMatrix';
import { getAggregations } from '../../constants/aggregations';
import {
  MAX_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
} from '../../modules/GroupTable/constants';
import { tableHeadersStylesFormat } from './constants';

// Enabling the extendedErrorLogging has a huge impact on performance,
// so it should be used for debugging only.
/* OfficeExtension.config.extendedErrorLogging = true; */

export const debug = () =>
  Excel.run(async () => {
    try {
      console.log('AppState:', window.sharedState.store.getState());
      console.log(
        'Stored Datasets in settings',
        Office.context.document.settings.get(DATA_SETS_STORAGE_KEY)
      );
      console.log(
        'Office.AutoShowTaskpaneWithDocument',
        Office.context.document.settings.get(
          'Office.AutoShowTaskpaneWithDocument'
        )
      );
      console.log('session ID', getSessionId());
    } catch (e) {
      console.error(e);
    }
  });

export const removeTable = () => {
  const { getState } = window.sharedState.store;
  const { currentDatasetId } = getState().streams;
  const { setSyncing, editDataset } = bindThunkActionCreators(actionCreators);
  return Excel.run(async (ctx) => {
    try {
      setSyncing(true);
      const dataset = getState().streams.datasets.find(
        (currentDataset) => currentDataset.id === currentDatasetId
      );
      if (!dataset || !dataset.tableId) {
        return;
      }
      const table = ctx.workbook.worksheets
        .getActiveWorksheet()
        .tables.getItem(dataset.tableId);
      const range = table.getRange();
      table.convertToRange();
      range.style = 'Normal';
      range.getRow(0).delete('Up');
      range.load('address');
      await ctx.sync();

      editDataset(currentDatasetId, {
        tableId: null,
        address: range.address,
      });

      range.select();
    } catch (e) {
      setSyncing(false);
      console.error(e);
    }
  });
};

export const generatePivotTable = () =>
  Excel.run(async (context) => {
    try {
      // Get seleted range
      const selected = context.workbook.worksheets
        .getActiveWorksheet()
        .getUsedRange();

      // Pull header values
      const headContent = selected.getRow(0).load('formulas');
      await context.sync();
      const headValues = headContent.formulas[0];
      const functionRegex = /=SYNERGIES/;
      const hasFunctionInHeader = headValues.every((f) =>
        functionRegex.test(f)
      );
      const headNames = hasFunctionInHeader
        ? headValues.map((f) => f.match(/[\w-]+/g)[4])
        : null;

      // Create new worksheet
      const worksheet = context.workbook.worksheets.add();
      await context.sync();
      worksheet.activate();
      await context.sync();

      // Create PivoTable on the new worksheet, with the selected range
      worksheet.load('pivotTables');
      await context.sync();
      const count = worksheet.pivotTables.getCount();
      await context.sync();
      const name = `Synergies Pivot Table 00${count.value + 1}`;
      const pivotTable = worksheet.pivotTables.add(name, selected, 'A2');
      await context.sync();

      // Add rows to PivotTable
      pivotTable.load('hierarchies');
      await context.sync();
      pivotTable.hierarchies.items.forEach((_item, index) => {
        const item = _item;
        if (headNames) {
          item.name = headNames[index];
        }

        if (index === 0) {
          pivotTable.rowHierarchies.add(item);
        } else {
          pivotTable.dataHierarchies.add(item);
        }
      });
    } catch (e) {
      console.error(e);
    }
  });

export const mapContinuation = async (datasetId: string) => {
  const { getState } = window.sharedState.store;
  const { fetchColumnData, setSyncing, editDataset, setAutoLoading } =
    bindThunkActionCreators(actionCreators);
  const { autoLoadAll, datasets } = getState().streams;

  let dataset = datasets.find((d) => d.id === datasetId);

  setSyncing(true);
  if (autoLoadAll) {
    setAutoLoading(true);
  }

  try {
    const data = await fetchColumnData(dataset.columns, dataset.continuation);
    return await Excel.run({ delayForCellEdit: true }, async (ctx) => {
      try {
        /* ctx.application.suspendScreenUpdatingUntilNextSync(); */
        const table = ctx.workbook.tables.getItem(dataset.tableId);

        const continuationRows = data.rows;

        /**
         * Handle Date type values + excel special characters
         */

        continuationRows.forEach((row, rowIndex) => {
          row.forEach((value, columnIndex) => {
            continuationRows[rowIndex][columnIndex] = escapeToExcelValidData({
              value,
              dataType: dataset.columns[columnIndex].dataType,
              aggregation: dataset.columns[columnIndex].aggregation,
              formatType: dataset.columns[columnIndex].formatType,
            });
          });
        });

        /**
         * Map data into table
         */

        table.rows.add(null, continuationRows);
        table.sort.reapply();

        dataset = editDataset(dataset.id, {
          rows: [...dataset.rows, ...continuationRows],
          continuation: data.continuation,
        });

        if (data.continuation && getState().streams.autoLoadAll) {
          mapContinuation(dataset.id);
        } else {
          setSyncing(false);
          setAutoLoading(false);
        }
      } catch (e) {
        reportError(e);
        handleError(e);
      }
    });
  } catch (e) {
    reportError(e);
    handleError(e);
    return null;
  }
};

export const onSelectTable = async (
  event: Excel.TableSelectionChangedEventArgs
) => {
  const { getState } = window.sharedState.store;
  const { setCurrentDatasetId } = bindThunkActionCreators(actionCreators);
  const { datasets } = getState().streams;
  try {
    if (getState().streams.autoLoading) {
      return null;
    }

    if (event.isInsideTable) {
      const dataset = datasets.find(
        (currentDataset) => currentDataset.tableId === event.tableId
      );
      if (dataset) {
        setCurrentDatasetId(dataset.id);
        window.sharedState.store.dispatch(
          setCurrentStreamIdIntent(dataset.streamId)
        );
      }
    }
  } catch (e) {
    handleError(e);
    reportError(e);
  }
};

export const onDeleteTableColumn = async (
  event: Excel.TableChangedEventArgs
) => {
  const { getState } = window.sharedState.store;
  const { datasets } = getState().streams;
  const { editDataset } = bindThunkActionCreators(actionCreators);
  try {
    if (event.changeType === Excel.DataChangeType.columnDeleted) {
      const dataset = datasets.find(
        (currentDataset) => currentDataset.tableId === event.tableId
      );
      if (dataset) {
        // This needs to be re-factor to avoid using dataset.address
        const regx = /([A-Z]*)\d:([A-Z]*)\d/;
        const tableMatch = dataset.address.match(regx);
        let pointer = columnLettersToNumber(tableMatch[1]);
        const column = columnLettersToNumber(event.address.match(regx)[1]);
        const delta =
          columnLettersToNumber(event.address.match(regx)[2]) - column;

        let index = 0;

        while (pointer !== column) {
          index += 1;
          pointer += 1;
        }

        editDataset(dataset.id, {
          columns: dataset.columns.filter(
            (_, i) => !(i >= index && i <= index + delta)
          ),
        });

        await renderDataset(dataset.id);
      }
    }
  } catch (e) {
    handleError(e);
    reportError(e);
  }
};

export const addTableEventListeners = async () =>
  Excel.run(async (ctx) => {
    try {
      const tables = ctx.workbook.tables.load('items');
      await ctx.sync();
      tables.items.forEach((table) => {
        table.onChanged.add(onDeleteTableColumn);
      });
    } catch (e) {
      handleError(e);
    }
  });

const formatTableHeaders = (range: Excel.Range, columns: IColumn[]) => {
  const STANDARD_HEIGHT = 17;
  const headerRange = range.getRow(0);

  const uniqueIdsColumns = new Set(columns.map((c) => c.id));
  const areMultipleAggregationsOfTheSameColumnPresent =
    uniqueIdsColumns.size < columns.length;

  columns.forEach((col, i) => {
    const column = headerRange.getColumn(i);
    column.format.horizontalAlignment = Excel.HorizontalAlignment.center;
    const agg = col?.aggregation
      ? getAggregations()[col.aggregation].label
      : '';
    const length = Math.max(agg.length, col.name.length);
    const width = length * getWidthFactor(length);
    const columnWidth = Math.max(
      MIN_COLUMN_WIDTH,
      Math.min(width, MAX_COLUMN_WIDTH)
    );
    column.format.set({
      columnWidth,
      rowHeight:
        STANDARD_HEIGHT *
        (areMultipleAggregationsOfTheSameColumnPresent ? 2 : 1),
      verticalAlignment: Excel.VerticalAlignment.bottom,
      ...tableHeadersStylesFormat,
    });
    if (col?.dataSourceColor) {
      column.format.fill.color = col.dataSourceColor
      column.format.font.color = pickTextColorBasedOnBgColorSimple(col.dataSourceColor)
    }
    column.untrack();
  });
  headerRange.untrack();
};

const forceTotalsFormatting = async (dataset: IDataset, tableId: string) =>
  Excel.run(async (ctx) => {
    try {
      const totals = getEscapedDatasetTotals(dataset);

      const table = ctx.workbook.worksheets
        .getActiveWorksheet()
        .tables.getItem(tableId);
      table.showTotals = true;
      const totalRange = table.getTotalRowRange();
      totalRange.set({
        values: totals,
      });
      formatTotalsRows(totalRange, dataset);
      formatTotalsByColumn(totalRange, dataset.columns);
      await ctx.sync();
    } catch (error) {
      console.log('forced totals Formatting error', error);
    }
  });

const mapDatasetAsTable = async (dataset: IDataset) => {
  const { dispatch } = window.sharedState.store;
  const { editDataset } = bindThunkActionCreators(actionCreators);
  return Excel.run({ delayForCellEdit: true }, async (ctx) => {
    try {
      ctx.application.suspendScreenUpdatingUntilNextSync();
      /**
       * Get table headers
       */
      const headers = dataset.columns.map(generateColumnHeader);

      /**
       * Get table range
       */

      let range: Excel.Range;
      const rowCount = dataset.rows.length + 1;
      const colCount = dataset.columns.length;

      let tableToUpdate = null;

      if (dataset.tableId) {
        tableToUpdate = ctx.workbook.worksheets
          .getActiveWorksheet()
          .tables.getItem(dataset.tableId);
        await ctx.sync();
      }

      if (tableToUpdate) {
        /**
         * If dataset exists, delete mapped table and data.
         */

        const tableRange = tableToUpdate.getRange().load('columnCount');
        tableRange.clear(Excel.ClearApplyTo.formats);

        await ctx.sync();
        const colDiff = colCount - tableRange.columnCount;

        if (colDiff > 0) {
          const lastCol = tableRange.getLastColumn().getOffsetRange(0, 1);
          for (let c = 0; c < colDiff; c++) {
            lastCol.getEntireColumn().insert(Excel.InsertShiftDirection.right);
          }
        }
        range = tableRange
          .getAbsoluteResizedRange(rowCount, colCount)
          .load('address');
        tableToUpdate.delete();

        const verticalTableCount = range
          .getEntireColumn()
          .getTables()
          .getCount();
        await ctx.sync();

        if (verticalTableCount.value) {
          const worksheet = ctx.workbook.worksheets.add();
          worksheet.activate();
          range = worksheet
            .getRange()
            .getAbsoluteResizedRange(rowCount, colCount)
            .load('address');
        }
      } else {
        const firstColumn = ctx.workbook
          .getSelectedRange()
          .getAbsoluteResizedRange(rowCount, 1);
        const verticalTableCount = firstColumn
          .getEntireColumn()
          .getTables()
          .getCount();
        await ctx.sync();
        if (verticalTableCount.value) {
          const worksheet = ctx.workbook.worksheets.add();
          worksheet.activate();
          range = worksheet
            .getRange()
            .getAbsoluteResizedRange(rowCount, colCount)
            .load('address');
        } else {
          for (let c = 0; c < colCount; c++) {
            firstColumn
              .getEntireColumn()
              .insert(Excel.InsertShiftDirection.right);
          }
          range = firstColumn
            .getAbsoluteResizedRange(rowCount, colCount)
            .getOffsetRange(0, -colCount)
            .load('address');
        }
      }

      /**
       * Take out columns that are only used as filters
       */
      const rows = dataset.rows.map((row) =>
        row.filter((_, i) => i < colCount)
      );
      /**
       * Handle Date type values + excel special characters
       */

      rows.forEach((row, index) => {
        row.forEach((value, col) => {
          rows[index][col] = escapeToExcelValidData({
            value,
            dataType: dataset.columns[col].dataType,
            aggregation: dataset.columns[col].aggregation,
            formatType: dataset.columns[col].formatType,
          });
        });
      });

      /**
       * Map data into the worksheet
       */
      range.set({ values: [headers, ...rows] });

      /**
       * Text formatting
       */

      for (let c = 0; c < dataset.columns.length; c++) {
        range.getColumn(c).numberFormat = [[getFormat(dataset.columns[c])]];
      }

      /**
       * Set columns size;
       */

      formatTableHeaders(range, dataset.columns);

      /**
       * Turn mapped data into a table
       */

      const table = ctx.workbook.worksheets
        .getActiveWorksheet()
        .tables.add(range, true);
      table.load('id');
      table.onChanged.add(onDeleteTableColumn);

      /**
       * Format totals rows
       */

      if (dataset.totals.length) {
        const totals = getEscapedDatasetTotals(dataset);
        table.showTotals = true;
        const totalRange = table.getTotalRowRange();
        totalRange.set({
          values: totals,
        });
        formatTotalsRows(totalRange, dataset);
        formatTotalsByColumn(totalRange, dataset.columns);
      }

      /**
       * handle hiding columns
       */

      const hiddenColumnsIndexes = [];
      for (let i = 0; i < dataset.columns.length; i++) {
        if (dataset.columns[i].isHidden) {
          hiddenColumnsIndexes.push(i);
        }
      }

      let firstColumnIndex;

      if (dataset.address) {
        firstColumnIndex = columnLettersToNumber(
          getFirstColumnLetterFromAddress(dataset.address)
        );
      } else {
        await ctx.sync();
        firstColumnIndex = columnLettersToNumber(
          getFirstColumnLetterFromAddress(range.address)
        );
      }

      // we are subtracting 1, because for this case column A => 0, B => 1, AA => 27
      firstColumnIndex -= 1;

      hiddenColumnsIndexes.forEach(async (indexToHide) => {
        const hiddenRange = ctx.workbook.worksheets
          .getActiveWorksheet()
          .getRangeByIndexes(0, firstColumnIndex + indexToHide, 1, 1);

        hiddenRange.columnHidden = true;
      });
      await ctx.sync();

      /**
       * Update dataset with server data, address and table id
       */

      editDataset(dataset.id, {
        address: range.address,
        tableId: table.id,
        lastSuccessfulMapping: {
          timestamp: moment().format('h:mm A M/D/YY'),
          columns: dataset.columns,
          continuation: dataset.continuation,
          streamFilters: dataset?.streamFilters,
        },
      });

      // TODO : this will be moved to sagas
      dispatch(checkWorkbookSelection());

      return table.id;
    } catch (e) {
      handleError(e);
      reportError(e);
    }

    return null;
  });
};

const mapDatasetAsFunctions = async (dataset: IDataset) => {
  const { editDataset } = bindThunkActionCreators(actionCreators);
  return Excel.run({ delayForCellEdit: true }, async (ctx) => {
    try {
      ctx.application.suspendScreenUpdatingUntilNextSync();
      let range: Excel.Range;

      if (dataset.address) {
        ctx.workbook.worksheets
          .getActiveWorksheet()
          .getRange(dataset.address)
          .set({
            values: '' as any,
            formulas: '' as any,
          });
        range = ctx.workbook.worksheets
          .getActiveWorksheet()
          .getRange(dataset.address)
          .getAbsoluteResizedRange(
            dataset.rows.length + dataset.totals.length,
            dataset.columns.length
          )
          .load('address');
      } else {
        range = ctx.workbook
          .getSelectedRange()
          .getAbsoluteResizedRange(
            dataset.rows.length + dataset.totals.length,
            dataset.columns.length
          )
          .load('address');
      }

      const formulas = mapMatrix(dataset.rows, (_, row, col) =>
        buildGetColumnFunction(dataset.id, col, row)
      );
      range.set({ formulas });
      await ctx.sync();

      editDataset(dataset.id, {
        address: range.address,
      });

      window.sharedState.store.dispatch(setCheckingCurrentSelection());
    } catch (e) {
      handleError(e);
      reportError(e);
    }
  });
};

export const renderDataset = async (datasetId: string) => {
  const { getState, dispatch } = window.sharedState.store;
  const { setSyncing, setAutoLoading, editDataset, setCurrentDatasetId } =
    bindThunkActionCreators(actionCreators);
  const { autoLoadAll, datasets } = getState().streams;
  let dataset = datasets.find((d) => d.id === datasetId);

  if (!dataset) {
    return;
  }

  setSyncing(true);
  if (autoLoadAll) {
    setAutoLoading(true);
  }

  if (dataset.id === TEMP_DATASET) {
    const table = getState().groupTable[TEMP_DATASET];
    const id = `dataset_${datasets.length}`;
    dispatch(setTable({ datasetId: id, table }));
    editDataset(dataset.id, { id });
    setCurrentDatasetId(id);
    dataset = { ...dataset, id };
  }

  try {
    if (dataset.type !== ObjectTypes.GROUP_TABLE) {
      dataset = await fetchDatasetData(dataset);
      dataset = await fetchDatasetTotals(dataset);
    }

    if (dataset.type === ObjectTypes.TABLE) {
      const tableId = await mapDatasetAsTable(dataset);

      if (dataset.totals.length && tableId) {
        // because of the excel bug we have to call the same method again
        // to respect total row formatting
        // https://github.com/OfficeDev/office-js/issues/1970
        await forceTotalsFormatting(dataset, tableId);
      }
    } else if (dataset.type === ObjectTypes.FREE_FORM) {
      await mapDatasetAsFunctions(dataset);
    } else if (dataset.type === ObjectTypes.GROUP_TABLE) {
      await await window.sharedState
        .runSaga(renderDatasetAsMatrix, dataset)
        .toPromise();
    }
    /**
     * If auto load active, load more data
     */
    if (dataset.continuation && autoLoadAll) {
      mapContinuation(dataset.id);
    } else {
      setSyncing(false);
    }
  } catch (e) {
    handleError(e);
    reportError(e);
  }
};

export const updateDataset = async ({
  datasetId,
  columns = [],
  datasetType,
  forceRender,
}: {
  datasetId: string | null;
  columns?: IColumn[];
  datasetType?: ObjectTypes;
  forceRender?: boolean;
}) => {
  let dataset: IDataset;
  // move me to saga plaase :)
  dataset = provideDataset({ datasetId, type: datasetType });
  dataset = applyColumnFilters(columns, dataset);
  dataset = applyCustomAggregations(dataset);

  if (forceRender || dataset.options.dataOnDemand) {
    await renderDataset(dataset.id);
  }
};

export const renderDatasetAfterSync = (dataset: IDataset) => {
  // We need that proxy, not to trigger a loop of syncing dataset between users
  updateDataset({ datasetId: dataset.id });
};

export const clearStoredSettings = () => {
  Office.context.document.settings.set(DATA_SETS_STORAGE_KEY, {
    datasets: [],
    lastSavedBySessionId: null,
  });
  Office.context.document.settings.saveAsync((asyncResult) => {
    console.log(
      `Cleared out stored datasets with status: ${asyncResult.status}`
    );
    window.sharedState.store.dispatch(setDatasets([]));
    console.log('datasets should be []');
  });
};

export const toggleExcelEvents = (enabled: boolean) =>
  Excel.run(async (ctx) => {
    ctx.runtime.enableEvents = enabled;
  });

/*

range.numberFormat = [['⍰ @']]
range.numberFormat = [['☑ @']]
range.numberFormat = [['⚐ @']]
range.numberFormat = [['⌾ @']]
*/
