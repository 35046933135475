import React from 'react';
import { useDispatch } from 'react-redux';
import { setEntityFormData } from '../../../../actions';
import { IGenericFormField } from '../../../../types';
import { FieldNames } from '../constants';
import { Option } from '../models';
import { ScopeDropdown } from './ScopeDropdown';

export interface Props {
  currentForm?: IGenericFormField['currentForm'];
  helpUri?: string;
  isDisabled?: boolean;
  scopeOptions: Option[];
}

export const OAuthScopeSelect = ({
  currentForm,
  helpUri,
  isDisabled = false,
  scopeOptions,
}: Props) => {
  // DEPS
  const dispatch = useDispatch();

  // CALLBACKS
  const onChange = React.useCallback(
    (value) => {
      dispatch(
        setEntityFormData({
          typeId: currentForm.typeId,
          data: {
            [FieldNames.SCOPES]: value,
          },
        })
      );
    },
    [currentForm.typeId, dispatch]
  );

  // RENDER
  return (
    <ScopeDropdown
      {...{
        helpUri,
        onChange,
        scopeOptions,
      }}
      isDisabled={isDisabled}
      value={currentForm.data[FieldNames.SCOPES] as string[]}
    />
  );
};
