import React from 'react';
import { useFormContext } from 'react-hook-form';
import axios, { AxiosResponse, CancelToken } from 'axios';
import { ISpinnerStyles, Label, Spinner, Stack } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { IControlledField } from '../../../../types';
import { fetchConnectorConfigurationPropertyEntries } from '../../../../../../../api/client/system';
import { asyncDebounce } from '../../../../../../../../shared/utils/async-debounce';
import { SelectDropdown } from '../../../../../../../components/ui';
import { Option } from '../models';
import { baseColors } from '../../../../../../../theme';

const I18N_PREFIX =
  'entityForm:fields:SelectOneConnectorConfigurationPropertyEntry';

const SPINNER_STYLE: ISpinnerStyles = {
  root: {
    // [KM]: why does fluent generate the classes in such a bad way that even an orthodox software engineer like me
    // stoops so low as to use `!important`...
    marginLeft: '8px !important',
  },
};

interface PropertyEntry {
  id: string;
  name: string;
}

const fetchPropertyEntriesDebounced = asyncDebounce(
  fetchConnectorConfigurationPropertyEntries,
  500
);

export const SelectOneConnectorConfigurationPropertyEntry: React.FC<IControlledField> =
  ({
    controller: { name, onChange, value },
    currentForm: { sections },
    entityProperty,
    label,
  }) => {
    // DEPS
    const { t } = useTranslation();
    const { getValues, watch } = useFormContext();
    const formSection = React.useMemo(
      () =>
        sections.find((section) =>
          section.properties.find(
            (property) => property.id === entityProperty.id
          )
        ),
      [sections, entityProperty]
    );
    /**
     * BE wants all the properties from the same section as the property associated with this field
     * and they will extract the data that they need to continue with the process
     * */
    const sectionPropNames = React.useMemo(
      () =>
        // filter out the property itself from the list to avoid a circular dependency that would cause a re-fetch
        // of the configuration property entries each time the value of the field itself changes
        formSection.properties
          .filter((property) => property.id !== entityProperty.id)
          .map((property) => property.name),
      [formSection, entityProperty]
    );

    // STATE
    /** key-value pairs of OTHER properties in the section are used as the configuration data required by the BE */
    const configuration = watch(sectionPropNames);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState<unknown>();
    const [propertyEntries, setPropertyEntries] = React.useState<
      PropertyEntry[]
    >([]);

    // DERIVED STATE
    /** the name of the property we are sending to the custom endpoint */
    const propertyName = entityProperty.name;
    /** the type id of the configuration the user is currently setting */
    const typeId = formSection.parentId;
    /**
     * list of OTHER property values in the same section listed alphabetically by the property name
     * required to work around the issue of creating a proper dep list for the effect that fetches the items
     * for this component to display – passing the entire `configuration` object will not work because
     * its reference changes on every render which might cause an infinite fetch loop
     * we're sorting the list alphabetically by keys to ensure changing any value or the amount of values
     * re-triggers the effect (although the deps array changing its length might trigger React warnings/errors)
     */
    const configurationValues = Object.entries(configuration)
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .map(([, configValue]) => configValue);
    const options: Option[] = React.useMemo(
      () =>
        propertyEntries.map((entry) => ({
          label: entry.name,
          value: entry.id,
        })),
      [propertyEntries]
    );
    const selectedOption: Option = React.useMemo(
      () => options.find((option) => option.value === value),
      [options, value]
    );

    // CALLBACKS
    const onOptionChange = React.useCallback(
      (option: Option) => onChange(option.value),
      [onChange]
    );

    // EFFECTS
    // fetch the configuration property entries when the data provided by the user is updated...
    const loadPropertyEntries = React.useCallback(
      async (cancelToken: CancelToken) => {
        try {
          setError(undefined);
          setIsLoading(true);
          // ... but debounce it since the effect triggers basically on every key press
          // as the component is re-rendered on every key press inside the field that
          // is set as a condition for this one in the config coming in from the API
          const result: AxiosResponse<PropertyEntry[]> =
            await fetchPropertyEntriesDebounced({
              cancelToken,
              configuration,
              propertyName,
              typeId,
            });
          // HACK: for some reason, when the request returns an error code (i.e. 502) Axios fails
          // and returns an error object as a normal result instead of throwing so we do that ourselves
          if (result instanceof Error) throw result;

          const propertyEntriesData = result.data;
          setPropertyEntries(propertyEntriesData);

          // set a default selection after updating the lsit if there was nothing selected already
          // or the previously selected value is no longer available
          // NOTE: using the `getValues` method to avoid placing the value on the deps list which would
          // reset this callback definition, and in turn reset the effect
          const currentValue = getValues(name);
          const shouldResetSelection =
            !currentValue ||
            !propertyEntriesData.find((entry) => entry.id === currentValue);
          if (shouldResetSelection) onChange(undefined);
        } catch (err) {
          setPropertyEntries([]);
          setError(err);
        } finally {
          setIsLoading(false);
        }
      },
      // NOTE: can't place `configuration` on the deps list because its reference changes on each render
      // but we can try to use a list of the values present in that hash
      [getValues, name, propertyName, typeId, ...configurationValues]
    );
    React.useEffect(() => {
      const source = axios.CancelToken.source();

      loadPropertyEntries(source.token);

      return () => {
        fetchPropertyEntriesDebounced.cancel();
        source.cancel();
      };
    }, [loadPropertyEntries]);

    // PARTS
    const spinner = isLoading && <Spinner styles={SPINNER_STYLE} />;
    const errorMessage = error && (
      <span style={{ color: baseColors.red }}>{t(`${I18N_PREFIX}.error`)}</span>
    );

    // RESULT
    return (
      <Stack>
        <Stack horizontal>
          <Label>{label}</Label>
          {spinner}
        </Stack>
        <SelectDropdown
          {...{ options }}
          isDisabled={isLoading}
          onChange={onOptionChange}
          value={selectedOption}
        />
        {errorMessage}
      </Stack>
    );
  };
