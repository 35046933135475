import React, { useCallback, useEffect, useRef } from 'react';
import { chunk, last } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { List, AutoSizer } from 'react-virtualized';
import {
  CARD_KEY_PROP,
  getAreCardsForceOpen,
  getCardsNormalizedOutput,
  getIsAllDataFetched,
  getIsFetchingStreamerOutput,
  getSelectedCardKeys,
} from '../../../../selectors';
import { CARD_MIN_WIDTH } from '../OutputCard/constants';
import OutputCardShimmer from '../OutputCard/OutputCardShimmer';
import OutputCard from '../OutputCard';
import { getAllStreamerFilters } from '../../../../../../Filters/selectors';
import {
  getFetchedAssetsAsStreamEntities,
  getSelectedStream,
} from '../../../../../../selectors';
import { fetchNextPage } from '../../../../actions';
import { getOutputRowHeight } from '../../utils';
import { ExtendedProps as ExtendedCardProps } from '../OutputCard/OutputCard';
import { classNames } from '../../styles';

const IS_PLACEHOLDER_KEY = '__isPlaceholder';

const CARD_PLACEHOLDER = {
  [CARD_KEY_PROP]: 'placeholder',
  [IS_PLACEHOLDER_KEY]: true,
};

type UICard = { [IS_PLACEHOLDER_KEY]?: boolean } & ReturnType<
  typeof getCardsNormalizedOutput
>[number];

interface Props extends ExtendedCardProps {
  openedCards: boolean[];
  toggleIsCardOpen: (key: number) => void;
  preview: JSX.Element;
}

const CardListOutput: React.FC<Props> = ({
  openedCards,
  selectionPreviewsRef,
  toggleCard,
  toggleIsCardOpen,
  preview,
}) => {
  // HOOKS
  const dispatch = useDispatch();
  const windowRef = useRef();

  // STATE
  const isRetrievingOutputData = useSelector(getIsFetchingStreamerOutput);
  const assets = useSelector(getFetchedAssetsAsStreamEntities);
  const filters = useSelector(getAllStreamerFilters);
  const data = useSelector(getCardsNormalizedOutput);
  const areCardsForceOpen = useSelector(getAreCardsForceOpen);
  const selectedCardKeys = useSelector(getSelectedCardKeys);
  const stream = useSelector(getSelectedStream);
  const isAllDataFetched = useSelector(getIsAllDataFetched);

  // CALLBACKS

  const getRows = useCallback(
    (width: number): UICard[][] => {
      if (!data?.length) {
        return [];
      }
      const size = Math.floor(width / CARD_MIN_WIDTH);
      const group = chunk(data, size);
      if (isRetrievingOutputData) {
        return [
          ...group.slice(0, -1),
          [...Array(size)].map((_, i) => last(group)[i] || CARD_PLACEHOLDER),
          Array(size).fill(CARD_PLACEHOLDER),
        ];
      }
      return group;
    },
    [data, isRetrievingOutputData]
  );

  const shouldExpandRow = useCallback(
    (row: any[]) => row.some((entry) => openedCards[entry[CARD_KEY_PROP]]),
    [openedCards]
  );

  const rowRenderer = useCallback(
    (rows: ReturnType<typeof getRows>, { style, index, key: rowKey }: any) => (
      <div key={rowKey} style={style}>
        {rows[index].map((entry) => {
          if (entry?.[IS_PLACEHOLDER_KEY]) {
            return <OutputCardShimmer />;
          }
          const cardKey = entry[CARD_KEY_PROP];
          return (
            <OutputCard
              {...{
                assets,
                entry,
                filters,
                selectionPreviewsRef,
                toggleCard,
              }}
              key={cardKey}
              isCardSelected={selectedCardKeys.includes(cardKey)}
              parentTitle={stream.name}
              toggleIsLocalOpen={() => toggleIsCardOpen(cardKey)}
              isLocalOpen={openedCards[cardKey]}
            />
          );
        })}
      </div>
    ),
    [filters, selectedCardKeys, openedCards]
  );

  // EFFECTS

  useEffect(() => {
    (windowRef?.current as any)?.recomputeRowHeights();
  }, [openedCards, areCardsForceOpen]);

  // RENDER

  return (
    <section className={cn([classNames.column, classNames.wrapper])}>
      <div className={cn([classNames.background, classNames.grow])}>
        {preview}
        <AutoSizer>
          {({ height, width }) => {
            const rows = getRows(width);
            return (
              <List
                ref={windowRef}
                rowRenderer={({ style, index, key }) =>
                  rowRenderer(rows, { style, index, key })
                }
                height={height}
                className={classNames.window}
                width={width}
                rowCount={rows.length}
                onRowsRendered={({ stopIndex }) => {
                  if (
                    stopIndex >= rows.length - 5 &&
                    !isRetrievingOutputData &&
                    !isAllDataFetched
                  ) {
                    dispatch(fetchNextPage());
                  }
                }}
                rowHeight={({ index }) =>
                  getOutputRowHeight(
                    rows[index],
                    areCardsForceOpen || shouldExpandRow(rows[index])
                  )
                }
                estimatedRowSize={getOutputRowHeight(
                  rows[0],
                  areCardsForceOpen
                )}
              />
            );
          }}
        </AutoSizer>
      </div>
    </section>
  );
};

export default CardListOutput;
