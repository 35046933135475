export const routes = {
  onboarding: {
    index: '/onboarding',
    streams: {
      index: '/onboarding/streams',
    },
  },
  delete: {
    index: '/delete',
    topLevelEntity: '/delete/entity/:schemaId/:entityId',
    topLevelEntitySchema: '/delete/entity/:schemaId',
  },
  add: {
    index: '/add',
    topLevelEntity: '/add/entity/:schemaId',
    nestedEntity: '/add/nestedEntity/:schemaId/:parentSchemaId/:parentEntityId',
    nestedEntitySchema: '/add/nestedEntity/:schemaId/',
  },
  edit: {
    entity: '/edit/entity/:schemaId/:entityId',
    entitySchema: '/edit/entity/:schemaId/',
  },
  process: {
    index: '/process',
    streamFromBuilder: '/process/stream_from_builder',
    streamFromStreamer: '/process/stream_from_streamer',
  },
  share: {
    index: '/share',
    stream: '/share/stream',
    design: '/share/design',
  },
  builder: {
    index: '/builder',
    streams: {
      index: '/builder/streams',
    },
    assets: {
      index: '/builder/assets',
      sources: '/builder/assets/sources',
      data: '/builder/assets/data',
      hubs: '/builder/assets/hubs',
      calculations: '/builder/assets/calculations',
      groups: '/builder/assets/groups',
      links: '/builder/assets/links',
      linksWizard: '/builder/assets/links/wizard',
      machineLearning: '/builder/assets/machine_learning',
      preview: '/builder/assets/preview',
      filters: {
        index: '/builder/assets/filters',
        add: '/builder/assets/filters/add',
        edit: '/builder/assets/filters/edit',
      },
    },
  },
  designer: {
    index: '/designer',
    contentLibrary: {
      designs: '/designer/contentLibrary/index',
    },
    metadata: {
      index: '/designer/metadata',
      tags: '/designer/metadata/tags',
      catalogDefinition: '/designer/metadata/catalog_definition',
    },
    ingestion: {
      index: '/designer/ingestion',
      sources: '/designer/ingestion/sources',
      data: '/designer/ingestion/data',
      transforms: '/designer/ingestion/transforms',
      filters: {
        index: '/designer/ingestion/filters',
        logic: {
          index: '/designer/ingestion/filters/logic',
          add: '/designer/ingestion/filters/logic/add',
          edit: '/designer/ingestion/filters/logic/edit',
        },
        values: '/designer/ingestion/filters/values',
      },
    },
    catalog: {
      index: '/designer/catalog',
      sources: '/designer/catalog/sources',
      data: '/designer/catalog/data',
      calculations: '/designer/catalog/calculations',
      hubs: '/designer/catalog/hubs',
      groups: '/designer/catalog/groups',
      links: '/designer/catalog/links',
      machineLearning: '/designer/catalog/machine_learning',
      machineLearningPreview:
        '/designer/catalog/machine_learning/preview/:assetId',
    },
  },
  streamer: {
    index: '/streamer',
    streamSelecting: {
      index: '/streamer/select_stream/',
      pickStream: '/streamer/select_stream/pick',
    },
    dataMode: {
      index: '/streamer/data_mode',
      selectAssets: '/streamer/data_mode/select_assets',
      output: '/streamer/data_mode/output',
      layout: '/streamer/data_mode/layout',
      filters: {
        index: '/streamer/data_mode/filters',
        add: '/streamer/data_mode/filters/add',
        edit: '/streamer/data_mode/filters/edit',
      },
    },
    export: {
      index: '/streamer/export',
    },
    import: {
      index: '/streamer/import',
    },
  },
  streams: {
    index: '/streams',
    list: {
      index: '/streams/list',
    },
    design: {
      index: '/streams/design',
    },
    options: {
      index: '/streams/options',
    },
    columns: {
      reorder: '/streams/columns/reorder',
    },
    filters: {
      index: '/streams/filters',
      create: '/streams/filters/create',
      edit: '/streams/filters/edit',
    },
    values: {
      index: '/streams/values',
      unselected: '/streams/values/unselected',
    },
    tableLegend: {
      index: '/streams/table-legend'
    }
  },
  streamingFlow: {
    index: '/streaming_flow',
  },
  settings: {
    index: '/settings',
    preferences: {
      index: '/settings/preferences',
    },
    branding: {
      index: '/settings/branding',
    },
    profile: {
      index: '/settings/profile',
    },
  },
  visuals: {
    index: '/visuals',
    tables: {
      index: '/visuals/tables',
    },
    charts: {
      index: '/visuals/charts',
    },
    maps: {
      index: '/visuals/maps',
    },
    shapes: {
      index: '/visuals/shapes',
    },
    controls: {
      index: '/visuals/controls',
    },
  },
  favorites: {
    index: '/favorites',
    sharedWithMe: {
      index: '/favorites/sharedWithMe',
    },
    recentStreams: {
      index: '/favorites/recentStreams',
    },
  },
  notifications: {
    index: '/notifications',
    records: {
      index: '/notifications/records',
    },
    errors: {
      index: '/notifications/errors',
    },
  },
  help: {
    index: '/help',
    knowledgeBase: '/help/knowledge',
    chat: '/help/chat',
    errorForm: '/help/report_error',
    website: '/help/website',
  },
  groupTable: {
    design: '/grouptable/design',
  },
} as const;
