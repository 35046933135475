import * as effects from 'redux-saga/effects';
import {
  NotificationActivity,
  NotificationSubjectState,
} from '../../../types/INotification';
import { processStream } from '../../Builder/api';
import { appendNotificationIntent } from '../../Notifications/actions';
import * as StreamActions from '../actions';

function* processStreamInStreamer({
  payload: { historyGoBack, streamId },
}: ReturnType<typeof StreamActions.streamerProcessingIntent>) {
  yield effects.put(StreamActions.setCurrentStreamIdIntent(null));
  yield effects.put(
    StreamActions.actionCreators.setCurrentDatasetId(null) as any
  );

  try {
    yield effects.call(processStream, {
      streamId,
      ingestBeforeProcessing: true,
    });

    while (true) {
      const {
        payload: notification,
      }: ReturnType<typeof appendNotificationIntent> = yield effects.take(
        appendNotificationIntent
      );

      if (
        notification.subjectId === streamId &&
        notification.activity === NotificationActivity.Process &&
        [
          NotificationSubjectState.Start,
          NotificationSubjectState.InProgress,
          NotificationSubjectState.Failed,
        ].includes(notification.state)
      ) {
        break;
      }
    }
  } catch (error) {
    console.log('err', error);
  } finally {
    yield effects.put(StreamActions.fetchAllStreams);
    yield effects.call(historyGoBack);
  }
}

export default function* rootSaga() {
  yield effects.takeLatest(
    StreamActions.streamerProcessingIntent.type,
    processStreamInStreamer
  );
}
