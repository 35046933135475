import {
  Icon,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataSourceEntity } from '../../../../api/model/schemas/DataSourceEntity';
import DataSourceIcon from '../../../../components/DataSourceIcon';
import { SelectDropdown } from '../../../../components/ui';
import { numberToHexColor } from '../../../../components/ui/ColorPicker/utils';
import { classNames } from './styles';
import usePickEntity, { DropdownOption } from './usePickEntity';

interface Props {
  designId: string;
  setSelectedEntity: React.Dispatch<React.SetStateAction<DataSourceEntity>>;
  selectedEntity: DataSourceEntity | null;
  connectorTypeId: string;
}

const PickEntity: React.FC<Props> = ({
  designId,
  setSelectedEntity,
  selectedEntity,
  connectorTypeId,
}) => {
  const { t } = useTranslation();
  const { dataSetColor, dropdownOptions, isFetchingEntities } = usePickEntity({
    designId,
    selectedEntityId: selectedEntity?.id,
    connectorTypeId,
  });

  const handleDropdownChange = React.useCallback(
    (option: DropdownOption) => setSelectedEntity(option.data),
    [connectorTypeId]
  );

  const selectDropdownValue = selectedEntity
    ? {
        data: selectedEntity,
        value: selectedEntity?.id,
        label: selectedEntity?.name,
      }
    : null;

  return (
    <div>
      <Stack horizontal>
        <div className={classNames.dataSourceColor}>
          {typeof dataSetColor === 'number' ? (
            <DataSourceIcon color={numberToHexColor(dataSetColor)} />
          ) : (
            <Icon iconName="EykoNoDataSource" />
          )}
        </div>
        <SelectDropdown
          options={dropdownOptions}
          isDisabled={!dropdownOptions.length || isFetchingEntities}
          onChange={handleDropdownChange}
          className="stream-to-db-pick-entity"
          value={selectDropdownValue}
        />
        {isFetchingEntities && (
          <Spinner
            className={classNames.spinner}
            size={SpinnerSize.small}
            data-testid="fetching-entities-spinner"
          />
        )}
      </Stack>
      {!isFetchingEntities && !dropdownOptions.length && (
        <Text
          as="p"
          className={classNames.noEntitiesText}
          data-testid="no-writable-entities"
        >
          {t('streamToDatabase:noEntities')}
        </Text>
      )}
    </div>
  );
};

export default PickEntity;
