/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import { Text } from '@fluentui/react';
import React, { FunctionComponent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';

import { FileTypes } from '../../types/FileType';

import { dropzoneStyles } from './styles';

interface DropzoneProps {
  onAddFiles(files: File[]): void;
  fileType: FileTypes;
  multiple?: boolean;
}

const ACCEPT_MIME_TYPES: Record<FileTypes, string[]> = {
  [FileTypes.Excel]: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  [FileTypes.Delimited]: [
    'text/csv',
    'text/tsv',
  ],
  [FileTypes.Image]: [
    'image/*',
    'image/svg+xml',
  ],
  [FileTypes.All]: null,
};

const Dropzone: FunctionComponent<DropzoneProps> = ({
  onAddFiles, fileType, multiple = false,
}) => {
  const accept = ACCEPT_MIME_TYPES[fileType];

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onAddFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  const { t } = useTranslation('upload');

  const fileCountLabel = t(`fileCount.${multiple ? 'files' : 'file'}`);
  const fileTypeLabel = t(`fileType.${fileType}`);

  return (
    <div {...getRootProps()} style={dropzoneStyles}>
      <input {...getInputProps()} />
      <Text>
        <Trans
          i18nKey={t('dropZone', {
            fileCount: fileCountLabel,
            fileType: fileTypeLabel,
          })}
        />
      </Text>
    </div>
  );
};

export default Dropzone;
