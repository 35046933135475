import { IButtonStyles, IIconStyles, IStyle } from '@fluentui/react';
import {
  buttonItemStyles,
  activeItemStyles,
} from '../../../../components/ContextualMenu/styles';
import { baseColors } from '../../../../theme';

const hoveredActiveStyles: IStyle = {
  ...(activeItemStyles as {}),
  opacity: 0.7,
  cursor: 'pointer !important',
};

export const buttonStyles = (isActive: boolean): IButtonStyles => ({
  root: {
    ...(buttonItemStyles.root as {}),
    ...(isActive ? (activeItemStyles as {}) : {}),
    borderRadius: 0,
    cursor: 'pointer !important',
  },
  rootHovered: {
    ...(isActive ? hoveredActiveStyles : (buttonItemStyles.rootHovered as {})),
  },
});

export const iconItemStyles: IIconStyles = {
  root: {
    color: baseColors.offBlack,
    height: 16,
    width: 16,
  },
};
