import { createSelector } from 'reselect';
import { ApplicationState } from '../../store/types';

import { NotificationsState, initialState } from './reducer';

export const getNotificationsState = (
  state: ApplicationState,
): NotificationsState => state.notifications || initialState;

export const getNotifications = (
  state: ApplicationState,
) => getNotificationsState(state).notifications;

export const getNotificationsCount = (
  state: ApplicationState,
) => getNotificationsState(state).unreadNotifications.count;

export const getErrorsCount = (
  state: ApplicationState,
) => getNotificationsState(state).errors.length;
export const getErrors = (state: ApplicationState) => getNotificationsState(state).errors;

export const getUsersData = (
  state: ApplicationState,
) => getNotificationsState(state).usersData;

export const getUserDataById = (userId: string) => createSelector(
  getUsersData,
  (userData) => userData[userId],
);

export const getLastNotification = (
  state: ApplicationState,
) => getNotificationsState(state).notifications.records[0];
