import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';

import { QueryOptions } from 'odata-query';
import {
  fetchEntity,
  createNestedEntity,
  deleteEntity,
  fetchEntities,
} from '../Designer/api';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import {
  IDesignSourceEntity,
  IDesignSourceEntityField,
  UIEnhancedDesignSourceEntityField,
  EntityIngestionStatus,
  IStreamField,
  ApiV4ResponseWrapper,
} from '../Designer/types';
import * as api from './api';
import { IDesignerFilter } from '../../types/IDesignerFilter';
import {
  EntitySelectableForStream,
  StreamAssetType,
  UIEnhancedGroupAsset,
  UIEnhancedStream,
} from './types';
import { fetchDesigns } from '../Designer/ContentLibrary/api';
import { AssetProcessStatus } from '../../api/model/AssetProcessStatus';
import { normalizeById } from '../../utils/normalizeEntities';
import { EntityWithParent } from '../../api/types';
import { FindCoveringLinksRequest } from '../../api/model/schemas/FindCoveringLinksRequest';

export const fetchStreams = createAsyncThunk(
  'BUILDER/FETCH_STREAMS_AND_DESIGNS',
  async () => {
    const {
      data: { data: streams },
    } = await api.fetchStreams();
    const {
      data: { data: designs },
    } = await fetchDesigns();

    const normalizedDesigns = normalizeById(designs);

    const designsIds = designs.map((design) => design.id);
    const streamsThatIHaveAccessToo: UIEnhancedStream[] = streams
      .filter((stream) => designsIds.includes(stream.parentId))
      .map((stream) => ({
        ...stream,
        parentDesignName: normalizedDesigns[stream.parentId]?.name,
      }));

    return { streams: streamsThatIHaveAccessToo, designs };
  }
);

export const fetchCalculations = createAsyncThunk(
  'BUILDER/FETCH_ALL_CALCULATIONS',
  async (designId: string) => {
    const { data: responseData } = await api.fetchCalculations(designId)();

    return responseData.data;
  }
);

export const fetchStreamCalculations = createAsyncThunk(
  'BUILDER/FETCH_SELECTED_STREAM_CALCULATIONS',
  async ({ streamId }: { streamId: string }) => {
    const { data: responseData } = await api.fetchStreamCalculations(
      streamId
    )();

    return responseData.data;
  }
);
export const fetchStreamHubs = createAsyncThunk(
  'BUILDER/FETCH_STREAM_HUBS',
  async ({ streamId }: { streamId: string }) => {
    const { data: responseData } = await api.fetchStreamHubs(streamId)();

    return responseData.data;
  }
);

export const fetchStreamLinks = createAsyncThunk(
  'BUILDER/FETCH_STREAM_LINKS',
  async ({ streamId }: { streamId: string }) => {
    const { data: responseData } = await api.fetchStreamLinks(streamId)();

    return responseData.data;
  }
);

export const fetchHubs = createAsyncThunk(
  'BUILDER/FETCH_HUBS',
  async (designId: string) => {
    const { data: responseData } = await api.fetchHubs(designId)();

    return responseData.data;
  }
);

export const fetchMachineLearning = createAsyncThunk(
  'BUILDER/FETCH_MACHINE_LEARNING',
  async ({ designId }: { designId: string }) => {
    const { data: responseData } = await api.fetchMachineLearning(designId)();

    return responseData.data;
  }
);

export const fetchStreamMachineLearning = createAsyncThunk(
  'BUILDER/FETCH_STREAM_MACHINE_LEARNING',
  async ({ streamId }: { streamId: string }) => {
    const { data: responseData } = await api.fetchStreamMachineLearning(
      streamId
    )();

    return responseData.data;
  }
);

export const fetchGroupsAssets = createAsyncThunk(
  'BUILDER/FETCH_GROUPS_ASSETS',
  async (designId: string) => {
    let output: UIEnhancedGroupAsset[] = [];
    const {
      data: { data: groups },
    } = await api.fetchGroups(designId)();

    output = groups.map((group) => ({
      ...group,
      // yes this is a shortcut
      // but days of v4 are soon to be over
      parentGroupName: group.name,
    })) as unknown as UIEnhancedGroupAsset[];

    return output;
  }
);

export const fetchStreamGroupsAssets = createAsyncThunk(
  'BUILDER/FETCH_SELECTED_STREAM_GROUPS',
  async ({ streamId }: { streamId: string }) => {
    const {
      data: { data: streamGroupAssets },
    } = await api.fetchStreamGroupsAssets(streamId)();

    return streamGroupAssets;
  }
);

export const setStreamFieldEntities = createAction<
  IDesignSourceEntity[],
  'BUILDER/SET_ENTITIES'
>('BUILDER/SET_ENTITIES');
export const setStreamFieldEntityFields = createAction<
  IDesignSourceEntityField[],
  'BUILDER/SET_ENTITY_FIELDS'
>('BUILDER/SET_ENTITY_FIELDS');

export const fetchStreamFields = createAsyncThunk(
  'BUILDER/FETCH_STREAM_FIELDS',
  async (
    { streamId, deepFetch = false }: { streamId: string; deepFetch?: boolean },
    { dispatch }
  ) => {
    if (!deepFetch) {
      const {
        data: { data: streamFields },
      } = await api.fetchStreamFields(streamId)();

      return streamFields;
    }

    type ExpandedStreamFieldsResponse = IStreamField & {
      sourceField: IDesignSourceEntityField & {
        parent: IDesignSourceEntity;
      };
    };

    const {
      data: { data: expandedStreamFields },
    } = await fetchEntities<
      ApiV4ResponseWrapper<ExpandedStreamFieldsResponse[]>
    >(TYPE_IDS.StreamField)({
      expand: 'sourceField($expand=Parent)',
      filter: `parentId eq ${streamId}`,
    });

    const streamFields = expandedStreamFields.map((field) =>
      omit(field, 'sourceField')
    );
    const entityFields: IDesignSourceEntityField[] = expandedStreamFields.map(
      (field) => ({
        ...omit(field.sourceField, 'parent'),
      })
    );

    const entities: IDesignSourceEntity[] = uniqBy(
      expandedStreamFields.map((field) => ({
        ...field.sourceField.parent,
      })),
      'id'
    );

    dispatch(setStreamFieldEntityFields(entityFields));
    dispatch(setStreamFieldEntities(entities));
    return streamFields;
  }
);

export const fetchSourceEntity = createAsyncThunk(
  'BUILDER/FETCH_SOURCE_ENTITY',
  async (fieldId: string) => {
    const { data: responseData } = await fetchEntity<IDesignSourceEntity>(
      TYPE_IDS.SourceEntity,
      fieldId
    )();

    return responseData;
  }
);

export const fetchProcessedDataSources = createAsyncThunk(
  'BUILDER/STREAM_FETCH_SOURCES',
  async (designId: string) => {
    const { data: responseData } = await api.fetchDataSources(designId)({
      filter: `ingestedEntityCount gt 0 and (processStatus eq '${AssetProcessStatus.Processed}' or processStatus eq '${AssetProcessStatus.Processing}')`,
    });

    return responseData.data;
  }
);

export const setSelectedStreamId = createAction<
  string | null,
  'BUILDER/SELECT_STREAM'
>('BUILDER/SELECT_STREAM');
export const setSelectedFieldId = createAction<
  string | null,
  'BUILDER/SELECT_FIELD_ID'
>('BUILDER/SELECT_FIELD_ID');
export const setSelectedDataSourceIds = createAction<
  string[],
  'BUILDER/SELECT_DATA_SOURCES_IDS'
>('BUILDER/SELECT_DATA_SOURCES_IDS');
export const setActiveDataSourceId = createAction<
  string,
  'BUILDER/SELECT_ACTIVE_SOURCE_ID'
>('BUILDER/SELECT_ACTIVE_SOURCE_ID');

export const setFieldEntities = createAction<
  IDesignSourceEntity[],
  'BUILDER/SET_FIELD_ENTITIES'
>('BUILDER/SET_FIELD_ENTITIES');

export const fetchDataSourceFields = createAsyncThunk(
  'BUILDER/FETCH_SOURCE_ENTITY_FIELDS',
  async (dataSourceId: string, { dispatch }) => {
    const oDataParams: Partial<QueryOptions<unknown>> = {
      filter: `parent/parentId eq ${dataSourceId} and ingestionStatus eq '${EntityIngestionStatus.Processed}'`,
      expand: 'parent',
    };
    const {
      data: { data: fieldsWithParent },
    } = await fetchEntities<
      ApiV4ResponseWrapper<
        EntityWithParent<IDesignSourceEntityField, IDesignSourceEntity>[]
      >
    >(TYPE_IDS.SourceEntityField)(oDataParams);

    const entities: IDesignSourceEntity[] = fieldsWithParent.map((field) => ({
      ...field.parent,
    }));

    dispatch(setFieldEntities(entities));

    const entityFields = fieldsWithParent.reduce<
      UIEnhancedDesignSourceEntityField[]
    >((accumulator, curr) => {
      const parentEntityName = curr.parent.name;

      const plainField: IDesignSourceEntityField = omit(curr, 'parent');

      const field: UIEnhancedDesignSourceEntityField = {
        ...plainField,
        parentEntityName,
      };

      return [...accumulator, field];
    }, []);

    return entityFields;
  }
);

export const removeStreamField = createAsyncThunk(
  'BUILDER/REMOVE_STREAM_FIELD',
  async (streamFieldId: string) =>
    deleteEntity(TYPE_IDS.StreamField)(streamFieldId)
);

export const createStreamField = createAsyncThunk(
  'BUILDER/CREATE_STREAM_FIELD',
  async ({ streamId, entity }: { streamId: string; entity: any }) =>
    createNestedEntity({
      typeId: TYPE_IDS.StreamField,
      parentEntityId: streamId,
    })(entity)
);

export const removeStreamCalculation = createAsyncThunk(
  'BUILDER/REMOVE_STREAM_CALCULATION',
  async (streamCalculationId: string) =>
    deleteEntity(TYPE_IDS.StreamCalculation)(streamCalculationId)
);

export const createStreamCalculation = createAsyncThunk(
  'BUILDER/CREATE_STREAM_CALCULATION',
  async ({ streamId, entity }: { streamId: string; entity: any }) =>
    createNestedEntity({
      typeId: TYPE_IDS.StreamCalculation,
      parentEntityId: streamId,
    })(entity)
);

export type ProcessStreamIntentArgs = {
  streamId: string;
  historyGoBack: () => void;
};
export const processStreamIntent = createAction<
  ProcessStreamIntentArgs,
  'BUILDER/PROCESS_STREAM_INTENT'
>('BUILDER/PROCESS_STREAM_INTENT');
export const setProcessingStream = createAction<
  boolean,
  'BUILDER/SET_PROCESSING_STREAM'
>('BUILDER/SET_PROCESSING_STREAM');

export enum AssetClickedIntent {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export const assetClicked = createAction<
  {
    entity: EntitySelectableForStream;
    actionType: AssetClickedIntent;
    assetType: StreamAssetType;
  },
  'BUILDER/ASSET_CLICKED'
>('BUILDER/ASSET_CLICKED');

export const assetsDataGroupClicked = createAction<
  {
    entities: EntitySelectableForStream[];
    selectedEntitiesIds: string[];
    assetType: StreamAssetType;
  },
  'BUILDER/DATA_GROUP_CLICKED'
>('BUILDER/DATA_GROUP_CLICKED');

export const setAssetIdsBeingUpdated = createAction<
  { assetIds: string[]; assetType: StreamAssetType },
  'BUILDER/SET_ASSETS_IDS_BEING_UPDATED'
>('BUILDER/SET_ASSETS_IDS_BEING_UPDATED');

export const fetchAllStreamFieldsFilters = createAsyncThunk(
  'BUILDER/FETCH_ALL_STREAM_FILTERS',
  async (streamId: string) => {
    type ExpandedStreamFilters = IDesignerFilter & {
      parent: IStreamField;
    };

    const {
      data: { data: expandedFilters },
    } = await fetchEntities<ApiV4ResponseWrapper<ExpandedStreamFilters[]>>(
      TYPE_IDS.StreamFieldFilter
    )({
      expand: 'parent',
      filter: `parent/parentId eq ${streamId}`,
    });

    const output: IDesignerFilter[] = expandedFilters.map((filter) => ({
      ...omit(filter, 'parent'),
      item: {
        ...filter.parent,
        dataType: filter.parent.dataType as any,
      },
    }));

    return output;
  }
);

export const createStreamFieldFilterIntent = createAction<
  {
    filter: Partial<IDesignerFilter>;
    relatedStreamField: Partial<IDesignSourceEntityField>;
  },
  'BUILDER/CREATE_STREAM_FIELD_FILTER'
>('BUILDER/CREATE_STREAM_FIELD_FILTER');
export const editStreamFieldFilterIntent = createAction<
  {
    filter: Partial<IDesignerFilter>;
    relatedStreamField: Partial<IDesignSourceEntityField>;
  },
  'BUILDER/EDIT_STREAM_FIELD_FILTER'
>('BUILDER/EDIT_STREAM_FIELD_FILTER');

export const setIsLoadingFilters = createAction<
  boolean,
  'BUILDER/SET_IS_LOADING_FILTERS'
>('BUILDER/SET_IS_LOADING_FILTERS');

export const filterByValueItemClicked = createAction<
  {
    value: string;
    relatedFilter?: Partial<IDesignerFilter>;
    relatedStreamField: Partial<IStreamField>;
  },
  'BUILDER/FILTER_BY_VALUE_ITEM_CLICKED'
>('BUILDER/FILTER_BY_VALUE_ITEM_CLICKED');

export const valueFilterUpdatedSuccessfully = createAction<
  { updatedFilter: Partial<IDesignerFilter> },
  'BUILDER/VALUE_FILTER_UPDATED'
>('BUILDER/VALUE_FILTER_UPDATED');

export const performLinksAnalysis = createAsyncThunk(
  'BUILDER/LINK_ANALISIS',
  async (streamId: string) => {
    const {
      data: { result },
    } = await createNestedEntity<FindCoveringLinksRequest>({
      parentEntityId: streamId,
      typeId: TYPE_IDS.FindCoveringLinksRequest,
    })({});

    return result.dataSourceLinks;
  }
);
