import React, { useRef } from 'react';
import { DirectionalHint, IconButton as Button } from '@fluentui/react';
import cn from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { IEntityFormChildren, IEntityFormSection } from '../../types';
import { getClassNames, textFieldStyles } from './styles';
import { generateUniqueName } from './utils';
import { TextField, TooltipHost } from '../../../../../components/ui';
import { IEndpoint } from './MultiEnpointSelector';
import { UseManageMultipleEditItem } from '../ManageMultiple/hooks/useManageMultiple';

export type Props = {
  id: string
  items: IEntityFormChildren<IEndpoint>[]
  schema: IEntityFormSection,
  remove: (id: string) => void,
  openCallout: () => void
  parentEntityId: string
  isSelected: boolean
  hasOpenCallout: boolean
  handleClick: () => void
  initialEditing: boolean
  handleEditItem: UseManageMultipleEditItem<IEndpoint>
  canBeDeleted?: boolean
  setSelectedItemId: (id:string) => void
};

const Row : React.FC<Props> = ({
  id,
  items,
  remove,
  openCallout,
  isSelected,
  hasOpenCallout,
  handleClick,
  handleEditItem,
  canBeDeleted = true,
  setSelectedItemId,
}) => {
  const rowRef = useRef(null);
  const textFieldRef = useRef(null);
  const classNames = getClassNames(hasOpenCallout, isSelected);
  const current = items.find((item) => item.id === id);

  const onChangeName = async (event: any) => {
    const str = event.target.value;
    try {
      const allNames = items?.map((currentfield) => currentfield.data?.name || '') || [];
      const index = items.findIndex((item) => item.id === id);
      allNames.splice(index);
      const name = generateUniqueName(str, allNames);
      await handleEditItem(id, { name });
    } catch (e) {
      console.error(e);
    }
  };

  useOnClickOutside(
    rowRef,
    () => {
      if (
        isSelected && !hasOpenCallout && current?.data?.name
      ) {
        textFieldRef.current.blur();
        setSelectedItemId(null);
      }
    },
  );

  return (
    <div
      className={`${classNames.row} item`}
      onClick={handleClick}
      ref={rowRef}
    >
      <div className={classNames.truncate}>
        {
          isSelected && !hasOpenCallout
            ? (
              <TextField
                componentRef={textFieldRef}
                defaultValue={current?.data?.name}
                onBlurCapture={onChangeName}
                styles={textFieldStyles}
              />
            )
            : (
                current?.data?.name
            )
        }
      </div>
      <div className={classNames.truncate}>
        <span className={classNames.span}>{current?.data?.requestMethod?.toUpperCase()}</span>
        <TooltipHost content={current?.data?.endpoint || null} directionalHint={DirectionalHint.topLeftEdge}>
          <span>
            {' '}
            {current?.data?.endpoint}
          </span>
        </TooltipHost>
      </div>
      <div>
        <Button
          className={cn(classNames.btn, { [classNames.btnActive]: hasOpenCallout })}
          iconProps={{ iconName: 'Code' }}
          onClick={(e) => {
            e.stopPropagation();
            openCallout();
          }}
          data-field-id={id}
        />
        {canBeDeleted && (
        <Button
          className={classNames.btn}
          iconProps={{ iconName: 'Delete' }}
          onClick={(e) => {
            e.stopPropagation();
            remove(id);
          }}
        />
        )}
      </div>
    </div>
  );
};
export default Row;
