import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import { addNestedEntityRoute, deleteEntityRoute, editEntityRoute } from '../../../../../utils/routes';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { routes } from '../../../../../constants/routes';
import { getHubById } from '../../selectors';

const HubsHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const selectedDesignId = useSelector(getSelectedDesignId);

  const { selectedItem: selectedHubId, clear } = useItemSelections();
  const selectedHub = useSelector(getHubById(selectedHubId));

  const handleEdit = () => {
    const path = editEntityRoute(
      TYPE_IDS.Hub, selectedHubId,
    );
    history.push(path, {
      assetsTypeId: TYPE_IDS.HubSource,
      initialValues: {
      },
    });
  };

  const handleAdd = () => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.Hub,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesignId,
    });

    history.push(path, {
      typeName: 'Hub',
    });
  };

  const handleDelete = React.useCallback(
    () => {
      const path = deleteEntityRoute(TYPE_IDS.Hub, selectedHubId);

      const goBackPage = routes.designer.catalog.hubs;

      history.push(path, {
        goBackPage,
        entityName: selectedHub?.name,
        successCallback: clear,
        deleteDescription: (
          <Trans
            i18nKey="wizard:deleteNestedEntity"
            values={{ name: selectedHub?.name, asset: t('entityTypes:Hub') }}
          />
        ),
      });
    },
    [selectedHubId],
  );

  return (
    <ItemActionsHeader
      isItemSelected={!!selectedHubId}
      handleEdit={handleEdit}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
    />
  );
};

export default HubsHeader;
