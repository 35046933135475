import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import { UserRoles } from '../../../../types/IUser';
import { getSelectedDesign } from '../../../Designer/ContentLibrary/selectors';
import { createDesignShare, removeDesignShare, fetchDesignShare } from '../../api';
import ShareWizard from '../../components/ShareWizard/ShareWizard';

const DesignSharePage = () => {
  const history = useHistory();
  const handleGoBack = React.useCallback(() => {
    history.push(routes.designer.contentLibrary.designs);
  }, []);

  const selectedDesign = useSelector(getSelectedDesign);

  const shareDesign = async (userId, access) => {
    await createDesignShare(selectedDesign?.id)({
      access,
      userId,
    });
  };

  return (
    <ShareWizard
      createShareObject={shareDesign}
      entityId={selectedDesign?.id}
      entityName={selectedDesign?.name}
      fetchShareObjects={fetchDesignShare}
      successCallback={handleGoBack}
      handleCancel={handleGoBack}
      parentEntityId={selectedDesign?.id}
      unShareObject={removeDesignShare}
      visibleUsersRoles={[UserRoles.Admin, UserRoles.Designer]}
    />
  );
};

export default DesignSharePage;
