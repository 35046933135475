/* eslint-disable react/display-name */
import { Shimmer } from '@fluentui/react';
import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { GridCellProps } from 'react-virtualized';
import { StreamAssetRaw } from '../../../../../../types';
import FormattedValue, { ValueType } from '../FormattedValue/FormattedValue';
import { IS_PLACEHOLDER_KEY } from './constants';
import { classNames } from './styles';
import { isNumberFormat } from './utils';

interface Props extends GridCellProps {
  assets: StreamAssetRaw[];
  hoveredRowIndex: number;
  setHoveredRowIndex: (i: number) => void;
  data: { [key: string]: any }[];
  headers: string[];
  areTableTotalsEnabled: boolean;
  // we can not reuse the `key` prop from `GridCellProps` because of:
  // https://reactjs.org/warnings/special-props.html
  uniqueKey: string;
}

const OutputTableCell = React.memo<Props>(
  ({
    columnIndex,
    rowIndex,
    style,
    assets,
    hoveredRowIndex,
    setHoveredRowIndex,
    data,
    headers,
    areTableTotalsEnabled,
    uniqueKey,
  }) => {
    const classes = useMemo(
      () =>
        cn([
          classNames.cell,
          { first: columnIndex === 0 },
          { hover: rowIndex === hoveredRowIndex },
          { [classNames.alignRight]: isNumberFormat(assets[columnIndex]) },
          { lastRow: rowIndex === data?.length - 1 && !areTableTotalsEnabled },
          { lastCell: columnIndex === assets?.length - 1 },
        ]),
      [hoveredRowIndex, assets, columnIndex, rowIndex]
    );

    const onMouseEnter = useCallback(
      () => setHoveredRowIndex(rowIndex),
      [rowIndex]
    );

    if (data[rowIndex]?.[IS_PLACEHOLDER_KEY]) {
      return (
        <div key={uniqueKey} style={style} className={classes}>
          <Shimmer />
        </div>
      );
    }
    return (
      <div
        key={uniqueKey}
        style={style}
        onMouseEnter={onMouseEnter}
        className={classes}
      >
        <div>
          <FormattedValue
            asset={assets[columnIndex]}
            value={data[rowIndex][headers[columnIndex]]}
            valueType={ValueType.VirtualizedTableValue}
          />
        </div>
      </div>
    );
  }
);

export default OutputTableCell;
