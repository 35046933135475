import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import WizardPage, { WizardType } from '../../../../pageTemplates/WizardPage';
import FormShimmer from '../components/FormShimmer';

interface Props {
  goBack: () => void;
}

export const EntityFormShimmer = ({ goBack }: Props) => {
  const { t } = useTranslation();

  return (
    <WizardPage
      entityName={t('wizard:LoadingTitle')}
      pageTitle={t('wizard:LoadingTitle')}
      headerBarButtonsConfig={[
        // go back should always be allowed
        {
          tooltip: <Trans t={t} i18nKey="actionBar:general.cancelAction" />,
          onClick: goBack,
          iconProps: { iconName: 'Cancel' },
          'data-testid': 'form-cancel-button',
          type: 'button',
        },
      ]}
      wizardType={WizardType.Add}
      iconOverride="Sync"
    >
      <FormShimmer />
    </WizardPage>
  );
};
