import React from 'react';
import {
  Stack,
  Shimmer,
  ShimmerElementType,
} from '@fluentui/react';

const StreamListShimmer = () => {
  const singleStreamHeight = 72;
  const gapWidth = 8;

  const shimmerMockingFavorites = [
    { type: ShimmerElementType.line, height: singleStreamHeight },
    { type: ShimmerElementType.gap, width: gapWidth },
    { type: ShimmerElementType.line, height: singleStreamHeight },
    { type: ShimmerElementType.gap, width: gapWidth },
    { type: ShimmerElementType.line, height: singleStreamHeight },
  ];

  return (
    <Stack tokens={{ childrenGap: 10 }} style={{ padding: '4px' }}>
      <Shimmer shimmerElements={shimmerMockingFavorites} />
      <Shimmer shimmerElements={shimmerMockingFavorites} />
      <Shimmer shimmerElements={shimmerMockingFavorites} />
    </Stack>
  );
};

export default StreamListShimmer;
