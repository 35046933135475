import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IStreamFilter } from '../../../../types/IStreamFilter';
import FilterRule from '../FilterRule';

import { getStreamFiltersList, getSelectedStreamFiltersList } from '../../selectors';
import { selectStreamFilter } from '../../actions';
import { EmptyMessage } from '../../../../components/ui';
import FilterForm from '../FilterForm';
import { routes } from '../../../../constants/routes';
import useCollapseGroups from '../../../../hooks/useCollapseGroups';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';

const FiltersList = () => {
  // HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation('filters');

  // STATE
  const filters = useSelector(getStreamFiltersList);
  const selectedFilters = useSelector(getSelectedStreamFiltersList);

  // DERIVED STATE
  const { isGroupOpen, handleOpenGroup } = useCollapseGroups({ isMultiOpen: true });

  // CALLBACKS
  const handleSelectStreamFilter = (streamFilter: IStreamFilter) => {
    dispatch(selectStreamFilter(streamFilter));
  };

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
  });

  // RENDER
  if (tutorialTile) return tutorialTile;

  return (
    <>
      <Route
        path={[routes.streams.filters.index]}
        exact
      >
        { !filters?.length && <EmptyMessage message={t('emptyFiltersList')} />}
      </Route>
      { filters.map((filter) => {
        const isSelected = selectedFilters.some((s) => s.id === filter.id);
        const isOpen = isGroupOpen(filter.id);

        return (
          <FilterRule
            filter={filter}
            onClick={handleSelectStreamFilter}
            key={`filter-item-${filter.id}`}
            isSelected={isSelected}
            onOpenGroup={handleOpenGroup}
            isOpen={isOpen}
          />
        );
      })}
      <Route
        path={[routes.streams.filters.edit, routes.streams.filters.create]}
      >
        <FilterForm openGroup={handleOpenGroup} />
      </Route>
    </>
  );
};

export default FiltersList;
