import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from '../../../../constants/routes';

import { SelectStream } from '../SelectStream/SelectStream';
import { SelectAssets } from '../SelectAssets/SelectAssets';
import { Output } from '../../strategies/isolated/screens/OutputPreview/Output';
import { Index as StreamerFilters } from '../../Filters/screens/Index';
import { Layout } from '../../Layout/screens/Layout';

export const IndexPage = () => (
  <Switch>
    <Route path={routes.streamer.streamSelecting.index}>
      <Route path={routes.streamer.streamSelecting.pickStream}>
        <SelectStream />
      </Route>
      <Redirect to={routes.streamer.streamSelecting.pickStream} />
    </Route>
    <Route path={routes.streamer.dataMode.index}>
      <Route path={routes.streamer.dataMode.selectAssets}>
        <SelectAssets />
      </Route>
      <Route path={routes.streamer.dataMode.layout}>
        <Layout />
      </Route>
      <Route path={routes.streamer.dataMode.filters.index}>
        <StreamerFilters />
      </Route>
      <Route path={routes.streamer.dataMode.output}>
        <Output />
      </Route>
      <Redirect to={routes.streamer.dataMode.selectAssets} />
    </Route>
    <Redirect to={routes.streamer.streamSelecting.index} />
  </Switch>
);
