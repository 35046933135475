import { initialState as streamsInitialState } from '../modules/Streams/Streams';
import { initialState as groupTableInitialState } from '../modules/GroupTable/reducer';
import { SETTINGS_STORAGE_KEY } from './constants';
import { dataController } from './persistence';
import { DataPersistedInWorkbook } from './persistence/types';
import { acquireTokenSilent } from '../modules/Auth-v2';

const loadInitialSettings = () => {
  const storedSettings = localStorage.getItem(SETTINGS_STORAGE_KEY);
  return storedSettings ? JSON.parse(storedSettings) : null;
};

const initialState = async (): Promise<object> => {
  const isLoggedIn = Boolean(await acquireTokenSilent());
  const initialSettings = isLoggedIn ? loadInitialSettings() : null;
  const persistedData = await dataController.getPersisted() as DataPersistedInWorkbook;

  return {
    streams: {
      ...streamsInitialState,
      settings: {
        ...streamsInitialState.settings,
        ...initialSettings,
      },
      datasets: persistedData?.datasets || [],
    },
    groupTable: persistedData?.groupTableState || groupTableInitialState,
  };
};

export default initialState;
