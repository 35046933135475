import { createAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { AppThunkAction } from '../../store/types';

import { getSelectedItemIds } from './selectors';
import streamSelectors from '../Streams/selectors';
import { actionCreators as streamsActionCreators } from '../Streams/actions';
import { KnownAction as StreamsKnownAction } from '../Streams/types';
import { generateColumnUuid } from './utils';
import { IColumn, IColumnSortType } from '../../types/IColumn';
import { IColumnWithUuid } from './screens/ColumnsReorder/types';
import { TableItemAggregationEditIntentPayload } from './types';

export const setSelectedItemsIds = createAction<
string[],
'columns_sequencing/SET_SELECTED_ITEM_IDS'
>('columns_sequencing/SET_SELECTED_ITEM_IDS');

type KnownAction = ReturnType<typeof setSelectedItemsIds>;

export const changeSortModeOfColumn = (
  column: IColumnWithUuid,
  sortMode: IColumnSortType,
): AppThunkAction<KnownAction | StreamsKnownAction> => (dispatch, getState) => {
  const currentDataset = streamSelectors.getCurrentDataset(getState());

  const newColumns = currentDataset.columns.map((c) => {
    if (generateColumnUuid(c) === column.uuid) {
      return {
        ...c,
        sortConfig: {
          ...c?.sortConfig,
          sortMode,
        },
      };
    }

    return c;
  });

  dispatch(
    streamsActionCreators.setColumnsOfDataset(currentDataset.id, newColumns),
  );
};

export const handleHidingAndRevealingColumns = (
  isRevealing: boolean,
): AppThunkAction<KnownAction | StreamsKnownAction> => (dispatch, getState) => {
  const selectedItemIds = getSelectedItemIds(getState());
  const currentDataset = streamSelectors.getCurrentDataset(getState());

  let newColumns: IColumn[];

  if (isRevealing) {
    newColumns = currentDataset.columns.map((column) => {
      if (selectedItemIds.includes(generateColumnUuid(column))) {
        return omit(column, 'isHidden');
      }
      return column;
    });
  } else {
    newColumns = currentDataset.columns.map((column) => {
      if (selectedItemIds.includes(generateColumnUuid(column))) {
        return {
          ...column,
          isHidden: true,
        };
      }
      return column;
    });
  }

  dispatch(
    streamsActionCreators.setColumnsOfDataset(currentDataset.id, newColumns),
  );
};

export const editTableItemAggregation = createAction<TableItemAggregationEditIntentPayload, 'streamer/editTableItemAggregation'>('streamer/editTableItemAggregation');

export const deleteColumnsOfDataSet = (
  colummIds: string[],
): AppThunkAction<KnownAction | StreamsKnownAction> => (dispatch, getState) => {
  const currentDataset = streamSelectors.getCurrentDataset((getState()));

  const newColumns = currentDataset.columns.filter(
    (c) => !colummIds.includes(generateColumnUuid(c)),
  );

  dispatch(streamsActionCreators.setColumnsOfDataset(currentDataset?.id, newColumns));
};
