import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { TEMP_DATASET } from '../../types/IDataset';
import { GroupTableState, IGroupTable } from './types';

export const initialGroupTable : IGroupTable = {
  columns: [],
  rows: [],
  values: [],
  columnDrilling: 1,
  rowDrilling: 1,
  data: {},
  worksheetId: null,
  rowKeys: [],
  columnKeys: [],
  totals: {
    rows: true,
    columns: false,
    values: false,
  },
  shouldReload: false,
};

export const initialState: GroupTableState = {
  [TEMP_DATASET]: initialGroupTable,
};
const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.updateItem, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...state[payload.datasetId],
      [payload.sectionType]: [
        ...state[payload.datasetId][payload.sectionType].map(
          (item) => (item.id === payload.item.id ? payload.item : item),
        ),
      ],
    },
  }))
  .addCase(actions.removeItem, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...state[payload.datasetId],
      [payload.sectionType]: [
        ...state[payload.datasetId][payload.sectionType].filter(
          (item) => (item.id !== payload.item.id),
        ),
      ],
    },
  }))
  .addCase(actions.addItem, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...initialGroupTable,
      ...state[payload.datasetId],
      [payload.sectionType]: [
        ...(state?.[payload.datasetId]?.[payload.sectionType] || []),
        payload.item,
      ],
    },
  }
  ))
  .addCase(actions.setItems, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...initialGroupTable,
      ...state[payload.datasetId],
      [payload.sectionType]: [...payload.items],
    },
  }
  ))
  .addCase(actions.setTable, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: payload.table,
  }
  ))
  .addCase(actions.setDrillingLevel, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: { ...state[payload.datasetId], [payload.direction]: payload.level },
  }
  ))
  .addCase(actions.setTableData, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: { ...state[payload.datasetId], data: payload.data },
  }))
  .addCase(actions.setTableRowKeys, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: { ...state[payload.datasetId], rowKeys: payload.keys },
  }))
  .addCase(actions.setTableColumnKeys, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: { ...state[payload.datasetId], columnKeys: payload.keys },
  }))
  .addCase(actions.toggleGroupTableTotal, (state, { payload }) => {
    const table = state[payload.datasetId];
    return {
      ...state,
      [payload.datasetId]: {
        ...table,
        totals: {
          ...table.totals,
          [payload.sectionType]: !table.totals[payload.sectionType],
        },
      },
    };
  })
  .addCase(actions.setShouldReload, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...state[payload.datasetId],
      shouldReload: payload.shouldReload,
    },
  }))
  .addCase(actions.syncGroupTable, (state, { payload }) => ({
    ...payload,
    [TEMP_DATASET]: state[TEMP_DATASET],
  })));

export default reducer;
