import {
  ICardStyles,
  ICardSectionStyles,
  ICardItemStyles,
} from '@uifabric/react-cards';
import {
  ITextStyles,
  IButtonStyles,
  mergeStyles,
  ITheme,
  ILinkStyles,
  IStackStyles,
} from '@fluentui/react';

import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../theme';

export const containerStyles = mergeStyles({
  paddingLeft: '16px',
  paddingRight: '16px',
});

export const cardStyles: ICardStyles = {
  root: {
    padding: '8px',
    position: 'relative',
    background: 'white',
    border: `1px solid ${baseColors.inputBorder}`,
    borderRadius: BORDER_RADIUS_IN_PX,
    width: '100%',
    marginBottom: '8px',
    minWidth: 'calc(100% - 8px)',
  },
};

export const titleStyles: ITextStyles = {
  root: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: '6px',
  },
};

export const descriptionStyles: ITextStyles = {
  root: {
    fontSize: '12px',
  },
};

export const dismissButtonStyles: IButtonStyles = {
  root: {
    height: '19px',
    padding: 0,
    fontSize: '12px',
  },
  label: {
    margin: 0,
  },
};

export const dateStyles: ITextStyles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '12px',
    color: baseColors.iconColor,
    textAlign: 'right',
    marginTop: '3px !important',
    selectors: {
      i: {
        marginRight: '6px',
      },
    },
  },
};

export const cancelButtonStyles: IButtonStyles = {
  root: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    marginTop: '0 !important',
    height: 'auto',
    padding: '0',
    selectors: {
      i: {
        fontSize: '16px',
        margin: 0,
        color: `${baseColors.iconColor} !important`,
      },
      ':hover .ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
  rootHovered: {
    color: baseColors.iconColor,
  },
};

export const cardSectionStyles: ICardSectionStyles = {
  root: {
    paddingRight: '12px',
    paddingLeft: '25px',
  },
};

export const dismissAllButtonStyles: IButtonStyles = {
  root: {
    color: 'green',
  },
  label: {
    margin: 0,
  },
};

export const cardItemStyles: ICardItemStyles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

export const errorDetailStyles = (theme: ITheme): ITextStyles => ({
  root: {
    overflowWrap: 'break-word',
    maxHeight: '180px',
    overflowY: 'auto',
    color: theme.palette.themePrimary,
    fontSize: '12px',
  },
});

export const copyButtonStyles: IButtonStyles = {
  root: {
    float: 'left',
  },
};

export const errorIconStyles = mergeStyles({
  color: baseColors.red,
});

export const expandLinkStyles: ILinkStyles = {
  root: {
    // constant width to avoid jumping between the lines
    marginLeft: '2px',
    width: '40px',
  },
};

export const actionButtonStackStyles: IStackStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
};
