import { ObjectTypes } from '../../modules/App/types';
import { getIsColumnLayout } from '../../modules/GroupTable/utils';
import { actionCreators, selectors } from '../../modules/Streams/Streams';
import { bindThunkActionCreators, generateExcelRangeLinkMatrix } from './utils';

const createLinkedCopy = () => {
  const { getState } = window.sharedState.store;
  const { setSyncing } = bindThunkActionCreators(actionCreators);
  const dataset = selectors.getCurrentDataset(getState());
  return Excel.run({ delayForCellEdit: true }, async (ctx) => {
    ctx.runtime.enableEvents = false;
    setSyncing(true);
    const sourceRange = ctx.workbook.worksheets.getActiveWorksheet().tables
      .getItem(dataset.tableId)
      .getRange().load(['address', 'numberFormat']);
    const worksheet = ctx.workbook.worksheets.add();
    worksheet.activate();

    await ctx.sync();

    const matrix = generateExcelRangeLinkMatrix(sourceRange.address);

    if (
      dataset.type === ObjectTypes.GROUP_TABLE
      && getIsColumnLayout(getState().groupTable[dataset.id])
    ) {
      matrix[1][0] = '';
    }

    const targetRange = worksheet.getRange().getAbsoluteResizedRange(matrix.length, matrix[0].length);

    targetRange.set({
      numberFormat: sourceRange.numberFormat,
      formulas: matrix,
    });

    targetRange.copyFrom(sourceRange.address, Excel.RangeCopyType.formats, false);

    targetRange.format.autofitColumns();

    await ctx.sync();

    setSyncing(false);
    ctx.runtime.enableEvents = true;
  });
};

export {
  createLinkedCopy,
};
