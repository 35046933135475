import * as effects from 'redux-saga/effects';
import { isColumnInDataset } from '../components/StreamerColumn/utils';

import * as excelActions from '../../../actions/excel-actions';

import { itemClicked } from '../actions';
import streamSelectors from '../selectors';
import { IColumn } from '../../../types/IColumn';

export const areArraysEqual = (
  x: any[], y: any[],
):boolean => JSON.stringify(x) === JSON.stringify(y);

export function* handleItemSelectionDeselection({
  payload,
}: ReturnType<typeof itemClicked>) {
  // when selecting column by clicking it on the Data view in streamer
  // we want to pick default aggregation first
  const column:IColumn = {
    ...payload,
    aggregation: payload.defaultAggregation,
  };

  const { syncing, streamColumnLoading } = streamSelectors.getStreamsState(yield effects.select());

  if (streamColumnLoading || syncing) {
    return;
  }
  const currentDataset = streamSelectors.getCurrentDataset(yield effects.select());

  const shouldRemoveColumn = !!currentDataset?.id && isColumnInDataset(column, currentDataset);

  try {
    if (shouldRemoveColumn) {
      yield effects.call(removeColumnWithAllAggregationsFromDataSet, column);
      yield effects.call(excelActions.updateDataset, { datasetId: currentDataset.id });
    } else {
      yield effects.call(excelActions.updateDataset, { datasetId: currentDataset?.id, columns: [{ ...column, aggregation: column.defaultAggregation }] });

      const updatedDataset = streamSelectors.getCurrentDataset(yield effects.select());

      // wrapped with yield to allow mocking via redux-saga-test-plan
      const areColumnsEqualToWhatWasBefore = yield effects.call(areArraysEqual, updatedDataset?.columns, updatedDataset?.lastSuccessfulMapping?.columns);

      if (areColumnsEqualToWhatWasBefore && updatedDataset?.lastSuccessfulMapping?.continuation?.token) {
        yield effects.put({ type: 'EDIT_DATASET', id: currentDataset.id, properties: { continuation: updatedDataset.lastSuccessfulMapping.continuation } });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* removeColumnWithAllAggregationsFromDataSet(column: IColumn) {
  const currentDataset = streamSelectors.getCurrentDataset(yield effects.select());

  const filteredColumns = currentDataset.columns.filter((c) => {
    if (c.id === column.id && c.dataEntityId === column.dataEntityId && c.parentEntityName === column.parentEntityName) return false;

    return true;
  });
  // prevent deselecting last column
  if (filteredColumns.length === 0) return;
  yield effects.put({ type: 'EDIT_DATASET', id: currentDataset.id, properties: { columns: filteredColumns } });
}

export function* rootSaga() {
  yield effects.all([
    effects.takeLatest(
      itemClicked.type,
      handleItemSelectionDeselection,
    ),
  ]);
}

export default rootSaga;
