import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { routes } from '../../../../../constants/routes';

import useItemSelections from '../../../../../hooks/useItemSelections';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import { deleteEntityRoute } from '../../../../../utils/routes';

import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getTagById, getIsNewTagInputOpen } from '../../selectors';
import { setNewTagInputOpen } from '../../actions';
import useEditableItems from '../../../../../hooks/useEditableItems';

const TagsHeader = () => {
  const dispatch = useDispatch();
  const {
    isItemSelected,
    selectedItem,
  } = useItemSelections();
  const selectedTag = useSelector(getTagById(selectedItem));
  const isNewTagInputOpen = useSelector(getIsNewTagInputOpen);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isEditing,
  } = useEditableItems({});

  const handleDelete = () => {
    const path = deleteEntityRoute(TYPE_IDS.Tag, selectedTag.id);
    const goBackPage = routes.designer.metadata.tags;

    history.push(path, {
      goBackPage,
      entityName: selectedTag.name,
      deleteDescription: <Trans i18nKey="wizard:deleteNestedEntity" values={{ name: selectedTag.name, asset: t('entityTypes:Tag') }} />,
    });
  };

  const handleAdd = () => {
    dispatch(setNewTagInputOpen(true));
  };

  return (
    !isEditing && (
    <ItemActionsHeader
      isItemSelected={isItemSelected}
      handleAdd={isNewTagInputOpen ? null : handleAdd}
      handleDelete={handleDelete}
    />
    ));
};

export default TagsHeader;
