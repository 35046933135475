import { mergeStyleSets } from '@fluentui/react';
import { customStyles } from '../../../../../components/ui/SelectDropdown/customization';

export const classNames = mergeStyleSets({
  spinner: {
    marginLeft: '5px!important',
  },
  recordSpinner: {
    marginTop: '4px',
    alignItems: 'flex-start',
    marginLeft: '8px',
  },
  recordCount: {
    fontSize: '10px',
    fontWeight: 400,
    marginTop: '4px',
  },
  wrap: {
    selectors: {
      '&>div:first-of-type, &>button': {
        height: 'auto',
        minWidth: 32,
      },
    },
  },
  literalInput: {
    marginLeft: '10px!important',
    flexGrow: 1,
  },
});

export const entityDropdownStyles = {
  ...customStyles,
  container: (base) => ({
    ...customStyles.container(base),
    marginLeft: '8px!important',
  }),
};
