import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewControlButton from '../../../../../components/ui/ButtonMenu';
import { SortModes } from './types';

const SortingModeButton : React.FC<{
  setSortingMode: (mode : SortModes) => void,
  currentSortMode: SortModes,
  disabled: boolean,
}> = ({
  setSortingMode,
  currentSortMode,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <ViewControlButton
      onChangeItem={setSortingMode}
      activeItemKey={currentSortMode}
      disabled={disabled}
      shouldDismissOnChange
      items={[
        {
          text: t('wizard:Ascending'),
          key: SortModes.ascending,
          iconProps: {
            iconName: 'SynergiesSortAscending',
          },
        },
        {
          text: t('wizard:Descending'),
          key: SortModes.descending,
          iconProps: {
            iconName: 'sortLines',
          },
        },
        {
          text: t('wizard:Custom'),
          key: SortModes.custom,
          iconProps: {
            iconName: 'SynergiesSortCustom',
          },
        },
      ]}
    />
  );
};

export default SortingModeButton;
