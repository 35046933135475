import React from 'react';

import { Stack, Text } from '@fluentui/react';
import { classNames, emptyMessageStyles } from './styles';

import LargeImageThumbnail from '../../components/LargeImageThumbnail';
import StreamListShimmer from '../../components/StreamListShimmer';

export interface ISingleLargeThumbnail {
  id: string;
  name: string;
  iconName: string;
  itemTestId?: string;
  hasRedBorder?: boolean;
  isSyncing?: boolean;
}

interface Props {
  items: ISingleLargeThumbnail[];
  handleClick: (itemId: string) => void;
  handleDoubleClick?: (itemId: string) => void;
  selectedItemId: string;
  searchQuery?: string;
  isLoading?: boolean;
  emptyMessage?: string;
}

const LargeThumbnailPicker: React.FC<Props> = ({
  isLoading,
  items,
  handleClick,
  handleDoubleClick,
  selectedItemId,
  emptyMessage,
}) => {
  const handleDblClick = React.useCallback(
    (itemId) => (handleDoubleClick ? handleDoubleClick(itemId) : null),
    [handleDoubleClick]
  );

  if (isLoading) {
    return (
      <div className={classNames.loadingWrapper}>
        <StreamListShimmer />
      </div>
    );
  }

  if (!isLoading && items.length === 0 && emptyMessage) {
    return (
      <Stack styles={emptyMessageStyles}>
        <Text>{emptyMessage}</Text>
      </Stack>
    );
  }

  return (
    <section className={classNames.container}>
      {items.map((item) => (
        <LargeImageThumbnail
          key={item.id}
          label={item.name}
          handleClick={() => handleClick(item.id)}
          handleDoubleClick={() => handleDblClick(item.id)}
          iconName={item.iconName}
          id={item.id}
          testId={item?.itemTestId}
          isSelected={item.id === selectedItemId}
          hasError={item?.hasRedBorder}
          isSyncing={item?.isSyncing}
        />
      ))}
    </section>
  );
};

export default LargeThumbnailPicker;
