import { isLocalhost } from './isLocalhost';

export default (): string => {
  let baseUrl: string;

  if (isLocalhost()) {
    // webpack dev server hosts on this port by default
    // using 'origin' can support both https (for excel) and http (for isolated cypress build)
    baseUrl = window.location.origin;
  } else if (window.location.host === 'qaaddin-eyko.azurewebsites.net') {
    // on dynamic staging we have to use STATIC login.html page
    // because CORS settings in azure does not allow wildcard in their settings
    // therefore that's the only page you can use for authentication there
    baseUrl = 'https://qaaddin-eyko.azurewebsites.net/exceladdin';
  } else if (window.location.host.endsWith('eyko.io')) {
    // this is isolated build under our eyko domain
    // it is served under preview.eyko.io (and potentially others subdomains)
    // in that case we are stripping /exceladdin path
    // I am aware that this is "the same case" as in isLocalHost but wanted it to be more explicit and add separate reasoning
    baseUrl = window.location.origin;
  } else {
    // because addin is served in an iframe
    // we are getting here our blob storage address
    baseUrl = `${window.location.origin}/exceladdin`;
  }

  return `${baseUrl}/login.html`;
};
