import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { TYPE_IDS_TO_NAMES } from '../constants/apiV4TypeIds';

import { getSelectedStream } from '../modules/Builder/selectors';
import { getSelectedDesign } from '../modules/Designer/ContentLibrary/selectors';
import { getEntityName } from '../modules/Designer/Form/selectors';
import streamSelectors from '../modules/Streams/selectors';
import * as streamerSelectors from '../modules/Streamer/selectors';
import * as routesUtils from '../utils/routes';

export const useGetSubtitleText = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const params = useParams<{ schemaId?: string }>();
  const streamUsedInStreamer = useSelector(streamSelectors.getCurrentStream);
  const streamUsedInBuilder = useSelector(getSelectedStream);
  const streamUsedInIsolated = useSelector(streamerSelectors.getSelectedStream);
  const selectedDesignInDesigner = useSelector(getSelectedDesign);
  const formWizardEntityName = useSelector(getEntityName);

  const getText = () => {
    if (
      routesUtils.isStreamProcessStreamer(pathname) ||
      routesUtils.isStreamProcessBuilder(pathname)
    ) {
      return t('wizard:ProcessStream');
    }

    if (routesUtils.isInAssetLinksWizard(pathname)) {
      return t('builderLinksAnalysis:wizardSubtitle');
    }

    if (routesUtils.isInBuilder(pathname)) {
      return streamUsedInBuilder?.name || t('leftPanel:Builder');
    }

    if (routesUtils.isInDesigner(pathname)) {
      return selectedDesignInDesigner?.name || t('leftPanel:Designer');
    }

    if (routesUtils.isInStreamer(pathname)) {
      return streamUsedInStreamer?.name || t('leftPanel:Streamer');
    }

    if (routesUtils.isInIsolatedStreamer(pathname)) {
      return streamUsedInIsolated?.name || t('leftPanel:Streamer');
    }

    if (routesUtils.isInHelp(pathname)) {
      return t('subtitle:Help');
    }

    if (routesUtils.isInSettings(pathname)) {
      return t('subtitle:Settings');
    }

    if (routesUtils.isInNotifications(pathname)) {
      return t('subtitle:Notifications');
    }

    if (routesUtils.isInShareWizard(pathname)) {
      return t('shareModule:Share');
    }

    if (routesUtils.isInEditWizard(pathname)) {
      const entityName = TYPE_IDS_TO_NAMES[params?.schemaId];
      return `${t('actionBar:general.editPlain')} ${t(
        `entityTypes:${entityName}`
      )}`;
    }

    // to make it more explicit,
    // we are not adding subtitle in the ADD wizard
    if (routesUtils.isInAddWizard(pathname)) {
      if (!formWizardEntityName) return null;

      const entityName = TYPE_IDS_TO_NAMES[params?.schemaId];
      return `${t('actionBar:general.addPlain')} ${t(
        `entityTypes:${entityName}`
      )}`;
    }

    if (routesUtils.isInDeleteWizard(pathname)) {
      const entityName = TYPE_IDS_TO_NAMES[params?.schemaId];
      // TODO: figure out how to handle translating the wizard tile for built-in entity fields vs. custom ones
      // I need info about whether or not the entity field being edited is locked but I'm not sure how to get it
      return `${t('actionBar:general.deletePlain')} ${t(
        `entityTypes:${entityName}`
      )}`;
    }

    console.warn('There is a page with missing subtitle', pathname);
    return null;
  };

  return getText();
};
