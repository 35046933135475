import { getTheme } from '@fluentui/react';

const theme = getTheme();

export const styles = {
  infoIcon: {
    color: theme.palette.themePrimary,
    cursor: 'help',
    fontSize: 12,
  },
};

export const wrapIconStyles = {
  display: 'flex',
  alignItems: 'center',
};
