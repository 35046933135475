import React, { useEffect, useRef } from 'react';
import { Dropdown, IDropdownOption, ResponsiveMode } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { isEqual } from 'lodash';
import { IControlledField, SchemaControlType } from '../../types';

import { dropdownStyles } from './styles';
import { translateApiName } from '../../../../../config/i18n/utils';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { DataType } from '../../../../../api/model/schemas/DataType';

const SelectField: React.FC<IControlledField> = ({
  controller,
  currentForm,
  entityProperty,
  interaction,
  isDisabled,
  label,
  translationPrefix,
  currentEntity,
  state,
  inputName,
}) => {
  // HOOKS
  const { t } = useTranslation();

  // DERIVED STATE
  const { isReadOnly } = entityProperty;
  const isMulti = interaction.controlType === SchemaControlType.SelectMultiple;

  // Customization to disable link config fields on Link wizard

  const { value, onChange, ref } = controller;
  const { setValue } = useFormContext();
  const { invalid } = state;
  const {
    validValues: intValidValues = [],
    isRequired,
    referenceProperty,
    defaultValue,
  } = interaction;
  const referenceValues = useWatch({
    name: referenceProperty || '',
    defaultValue: currentEntity?.[referenceProperty] || [],
  });
  // FIXME: type assertion until proper client-side types are defined
  const i18nValidValues = (interaction as any).i18nValidValues || [];

  const validValues = referenceProperty ? referenceValues : intValidValues;

  // PARTS
  const options = React.useMemo(() => {
    let src = validValues;

    // We want a FE filter of dataTypes in the Hub Wizard

    if (currentForm.typeId === TYPE_IDS.Hub && inputName === 'dataType') {
      src = validValues.filter(
        (val) =>
          ![DataType.Geography, DataType.Boolean, DataType.Json].includes(val)
      );
    }

    const values = src.map((val, idx) => ({
      key: val,
      text:
        i18nValidValues[idx] ??
        // eslint-disable-next-line no-nested-ternary
        (currentForm && entityProperty.isLocked
          ? translationPrefix
            ? t(`${translationPrefix}:${val}`)
            : translateApiName(currentForm?.typeId, val)
          : val),
    }));

    const sortedValues = [...values].sort((a, b) =>
      String(a.text).localeCompare(String(b.text), undefined, { numeric: true })
    );

    if (!isRequired && !interaction?.defaultValue) {
      // If the property is not required and doesnt have
      // a default value, we give the user an option
      // clear a selected value
      sortedValues.unshift({
        key: null,
        text: t('filters:Select'),
      });
    }

    return sortedValues;
  }, [currentForm, entityProperty, validValues]);

  const handleChange = (_, option: IDropdownOption) => {
    if (isMulti) {
      const current: string[] = value || [];
      onChange(
        option.selected
          ? [...current, option.key]
          : current.filter((str) => str !== option.key)
      );
    } else {
      onChange(option.key);
    }
  };

  const history = useRef({
    validValues,
    isMulti,
  });

  /**
   * This effect handles situations where the valid values or the isMulti nature
   * of the component changes upon user interaction with a different field. In which case
   * the selected-values need to be restarted.
   * for example. If component changes from Multi to Single select, the previously selected
   * values will no longer be valid.
   */

  useEffect(() => {
    if (
      !isEqual(validValues, history.current.validValues) ||
      !isEqual(isMulti, history.current.isMulti)
    ) {
      setValue(inputName, isMulti ? defaultValue || [] : defaultValue || null);
      history.current = {
        validValues,
        isMulti,
      };
    }
  }, [validValues, isMulti]);

  // RENDER
  return (
    <Dropdown
      data-testid={`dropdown-${label}`}
      componentRef={ref}
      {...{
        options,
        selectedKey: !isMulti ? value : undefined,
        selectedKeys: isMulti ? value : undefined,
      }}
      styles={dropdownStyles(!invalid)}
      // there is request from business
      // to disable dropdowns if they have only one option available
      disabled={isDisabled || isReadOnly || (value && options.length === 1)}
      label={label}
      onChange={handleChange}
      placeholder={t('filters:Select')}
      required={isRequired}
      responsiveMode={ResponsiveMode.large}
      selectedKey={value}
      multiSelect={isMulti}
    />
  );
};

export default SelectField;
