import { createSelector } from 'reselect';
import { OrderByDirection } from '../../api/model/schemas/OrderByDirection';
import { ApplicationState } from '../../store/types';
import { normalizeById } from '../../utils/normalizeEntities';
import { StreamAssetRaw, StreamerAsset } from './types';

export const getStreamerState = (state: ApplicationState) => state.streamer;

export const getStreams = (state: ApplicationState) =>
  getStreamerState(state).streams;

export const getSortedStreamsRecords = createSelector(
  [getStreams],
  ({ records }) => [...records].sort((a, b) => a.name.localeCompare(b.name))
);

export const getSelectedStreamId = (state: ApplicationState) =>
  getStreamerState(state).streams.selectedId;

export const getSelectedStream = createSelector(
  getStreams,
  getSelectedStreamId,
  ({ records }, selectedId) => records.find(({ id }) => id === selectedId)
);

export const getAvailableAssets = (state: ApplicationState) =>
  getStreamerState(state).availableAssets;

export const getAvailableAssetsRecords = (state: ApplicationState) =>
  getAvailableAssets(state).records;

export const getSelectedAssets = (state: ApplicationState) =>
  getStreamerState(state).selectedAssets;

export const createGetIsAssetSelected =
  (state: ApplicationState) => (rawAsset: StreamAssetRaw) =>
    getSelectedAssets(state).some(
      (asset) => asset.streamElementId === rawAsset.id
    );

export const createGetAssetAggregations =
  (state: ApplicationState) => (rawAsset: StreamAssetRaw) =>
    getSelectedAssets(state)
      .filter((asset) => asset.streamElementId === rawAsset.id)
      .map((asset) => asset.aggregation);

export const getSelectedAssetsIds = createSelector(
  getSelectedAssets,
  (selectedAssets) => selectedAssets.map((asset) => asset.streamElementId)
);

export const getSelectedAssetsCount = (state: ApplicationState) =>
  getSelectedAssets(state).length;

export const getHasAnythingSelected = (state: ApplicationState) =>
  getSelectedAssetsCount(state) > 0;

export const createGetIsAssetQueued =
  (state: ApplicationState) => (checkedAsset: StreamAssetRaw) => {
    const { previouslyFetchedAssets } = getStreamerState(state);
    const getIsSelected = createGetIsAssetSelected(state);

    const wasStreamed = previouslyFetchedAssets.some(
      (asset) => asset.streamElementId === checkedAsset.id
    );

    const isSelected = getIsSelected(checkedAsset);
    // Later here we can check if precedence / order was changed

    return (!isSelected && wasStreamed) || (isSelected && !wasStreamed);
  };

export const createGetIsAggregationButtonQueued =
  (state: ApplicationState) => (checkedAsset: StreamAssetRaw) => {
    const currentAggregations = createGetAssetAggregations(state)(checkedAsset);

    if (currentAggregations.length === 1) {
      return currentAggregations[0] !== checkedAsset?.defaultAggregation;
    }

    const { previouslyFetchedAssets } = getStreamerState(state);
    const previousAggregationsCount = previouslyFetchedAssets
      .filter((asset) => asset.streamElementId === checkedAsset.id)
      .map((asset) => asset.aggregation).length;

    return currentAggregations.length !== previousAggregationsCount;
  };

export const createGetStreamerAssetAsRawAsset = (
  streamerAsset: StreamerAsset
) =>
  createSelector([getAvailableAssetsRecords], (rawAssets) =>
    rawAssets.find((asset) => asset.id === streamerAsset.streamElementId)
  );

export const createGetStreamerRawAssetById = (id: string) =>
  createSelector([getAvailableAssetsRecords], (rawAssets) =>
    rawAssets.find((asset) => asset.id === id)
  );

export const getPreviouslyFetchedAssets = (state: ApplicationState) =>
  getStreamerState(state).previouslyFetchedAssets;

export const getSelectedAndFetchedRawAssets = createSelector(
  [getSelectedAssets, getAvailableAssetsRecords, getPreviouslyFetchedAssets],
  (selected, available, fetched) =>
    available.filter(
      ({ id }) =>
        selected.some(({ streamElementId }) => id === streamElementId) &&
        fetched.some(({ streamElementId }) => id === streamElementId)
    )
);

export const getFetchedAssetsAsStreamEntities = createSelector(
  [getPreviouslyFetchedAssets, getAvailableAssetsRecords],
  (selectedAssets, rawRecords) =>
    selectedAssets.map((asset) =>
      rawRecords.find((rawAsset) => rawAsset.id === asset.streamElementId)
    )
);

export const getSelectedStreamEntitiesNormalizedById = createSelector(
  [getFetchedAssetsAsStreamEntities],
  (selectedAssets) => normalizeById(selectedAssets)
);

export const getSelectedStreamDesignId = createSelector(
  [getSelectedStream],
  (stream) => stream?.parentId ?? null
);

export const createGetWasAssetSortModeChanged = (checkedAsset: StreamerAsset) =>
  createSelector([getPreviouslyFetchedAssets], (previouslyFetchedAssets) => {
    const relatedAsset = previouslyFetchedAssets.find(
      (asset) =>
        asset.streamElementId === checkedAsset.streamElementId &&
        asset.aggregation === checkedAsset.aggregation
    );

    if (!relatedAsset) {
      // we are checking if it's different than default
      return checkedAsset.sortMode !== OrderByDirection.Ascending;
    }

    return relatedAsset?.sortMode !== checkedAsset.sortMode;
  });

export const createGetWasAssetAggregationChanged = (
  checkedAsset: StreamerAsset
) =>
  createSelector([getPreviouslyFetchedAssets], (previouslyFetchedAssets) => {
    if (previouslyFetchedAssets.length === 0) return false;

    const wasAssetAggregationPresentBefore = previouslyFetchedAssets.some(
      (asset) =>
        asset.streamElementId === checkedAsset.streamElementId &&
        asset.aggregation === checkedAsset.aggregation
    );

    return !wasAssetAggregationPresentBefore;
  });

export const getSendToFileState = (state: ApplicationState) =>
  getStreamerState(state).sendToFile;
