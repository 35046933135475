/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { DataType } from '../../../../../../../../api/model/schemas/DataType';
import { StreamAssetRaw } from '../../../../../../types';
import { FormatType } from '../../../../../../../../api/model/schemas/FormatType';
import { TooltipHost } from '../../../../../../../../components/ui';
import { truncate } from './styles';
import { formatValue } from './utils';

export enum ValueType {
  CardTitle,
  CardMainValue,
  CardRegularValue,
  TableValue,
  SwimlaneValue,
  ColumnValue,
  VirtualizedTableValue,
}
interface Props {
  value: any;
  asset: StreamAssetRaw;
  valueType: ValueType;
  maxLabelTitleLength?: number;
  withoutTooltip?: boolean;
}

const MAX_LABEL_TITLE_LENGTH: Record<ValueType, number> = {
  [ValueType.CardRegularValue]: 30,
  [ValueType.CardTitle]: 27,
  [ValueType.CardMainValue]: 23,
  // never show tooltip in table - it adjust it's width to fit value
  [ValueType.TableValue]: Number.MAX_SAFE_INTEGER,
  // never show tooltip on swimlane - it should take full width
  [ValueType.SwimlaneValue]: Number.MAX_SAFE_INTEGER,
  [ValueType.ColumnValue]: 26,
  [ValueType.VirtualizedTableValue]: 0,
};

const FormattedValue = React.memo(
  ({ asset, value, valueType, maxLabelTitleLength, withoutTooltip }: Props) => {
    // DERIVED STATE
    const processedFormatType = asset?.processedFormatType;
    const isRawNumber = processedFormatType === FormatType.Number;
    const isCurrency = processedFormatType === FormatType.Currency;
    const isPercentage = processedFormatType === FormatType.Percentage;

    const formattedValue = React.useMemo(
      () => formatValue({ asset, value }),
      [asset, value]
    );

    const shouldUseNumberFormat =
      [DataType.NumericDecimal, DataType.NumericInteger].includes(
        asset?.processedDataType
      ) && !isRawNumber;
    // const shouldDisplayTooltip = String(formattedValue).length;

    const display = useMemo(
      () =>
        shouldUseNumberFormat ? (
          <NumberFormat
            value={formattedValue}
            displayType="text"
            prefix={isCurrency && '$'}
            suffix={isPercentage && '%'}
            thousandSeparator
          />
        ) : (
          formattedValue
        ),
      [shouldUseNumberFormat, formattedValue, isCurrency, isPercentage]
    );

    // RENDER
    if (value === undefined) return null;

    if (withoutTooltip) {
      return display;
    }

    return (
      <TooltipHost
        calloutProps={{ setInitialFocus: false }}
        // we want to show tooltip only for longer labels
        content={
          String(formattedValue).length >=
          (maxLabelTitleLength || MAX_LABEL_TITLE_LENGTH[valueType])
            ? display
            : undefined
        }
        style={truncate}
      >
        {display}
      </TooltipHost>
    );
  }
);

export default FormattedValue;
