import React from 'react';

export const containerStyles = {
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
};

export const textStyle:React.CSSProperties = {
  margin: '0 20px',
  textAlign: 'center',
};
