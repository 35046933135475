import { createReducer } from "@reduxjs/toolkit";
import { setMostRecentPath } from "./actions";
import { ModuleName, SectionName, Path } from "./model";

export interface SectionRouteHistoryState {
  mostRecentPaths: Record<ModuleName, Record<SectionName, Path>>;
}

export const initialState: SectionRouteHistoryState = {
  mostRecentPaths: {},
}

const reducer = createReducer(initialState, (builder) => builder
  .addCase(setMostRecentPath, (state, { payload }) => ({
    ...state,
    mostRecentPaths: {
      ...state.mostRecentPaths,
      [payload.module]: {
        ...state.mostRecentPaths[payload.module],
        [payload.section]: payload.path,
      }
    },
  }))
);

export default reducer;