import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from './TutorialAnimation';

export const TUTORIAL_TILE_KEY = 'designer-setup-configuration';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:setup:configuration:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:setup:configuration:items:catalogExpansion:description',
      icon: 'Add',
      titleI18nKey: 'tutorialTiles:designer:setup:configuration:items:catalogExpansion:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:setup:configuration:items:fieldDefinition:description',
      icon: 'Dropdown',
      titleI18nKey: 'tutorialTiles:designer:setup:configuration:items:fieldDefinition:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:setup:configuration:items:eykoStore:description',
      icon: 'Database',
      titleI18nKey: 'tutorialTiles:designer:setup:configuration:items:eykoStore:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010376362-Configuration-Expanding-eyko',
};
