import { AxiosResponse } from 'axios';
import * as effects from 'redux-saga/effects';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { IDesignerFilter } from '../../../../types/IDesignerFilter';
import { fetchEntity } from '../../api';

import * as ingestionActions from '../actions';
import { createSourceEntityFieldFilter, editSourceEntityFilter } from '../api';
import {
  getAllIngestionFilters, getDataSourceFieldById, getIsLoadingFilters,
} from '../selectors';

function* handleCreateIngestionFilter({
  payload: { fieldId, newFilterPayload },
}: ReturnType<typeof ingestionActions.createEntityFieldIngestionFilterIntent>) {
  const relatedSourceField = getDataSourceFieldById(fieldId)(yield effects.select());
  try {
    yield effects.put(ingestionActions.setIsLoadingFilters(true));

    const { data } = yield effects.call(createSourceEntityFieldFilter, newFilterPayload);

    const newFilter: IDesignerFilter = {
      ...data,
      item: {
        ...relatedSourceField,
        dataType: relatedSourceField.dataType as any, // temporary until doing full migration from api v3 => to v4
      },
    };

    const currentFilters = getAllIngestionFilters(yield effects.select());

    yield effects.put(ingestionActions.fetchAllFiltersByLogic.fulfilled([...currentFilters, newFilter], '', ''));
  } catch (error) {
    console.log('err', error);
  } finally {
    const isLoadingFilters = getIsLoadingFilters(yield effects.select());
    if (isLoadingFilters) {
      yield effects.put(ingestionActions.setIsLoadingFilters(false));
    }
  }
}

function* handleEditIngestionFilter({
  payload: { fieldId, editedFilter },
}: ReturnType<typeof ingestionActions.editEntityFieldIngestionFilterIntent>) {
  const relatedSourceField = getDataSourceFieldById(fieldId)(yield effects.select());
  try {
    yield effects.put(ingestionActions.setIsLoadingFilters(true));

    yield effects.call(editSourceEntityFilter, editedFilter);

    const { data: updatedFilter }:AxiosResponse<IDesignerFilter> = yield effects.call(fetchEntity(TYPE_IDS.SourceEntityFieldFilter, editedFilter.id));

    const newFilter: IDesignerFilter = {
      ...updatedFilter,
      item: {
        ...relatedSourceField,
        dataType: relatedSourceField.dataType as any, // temporary until doing full migration from api v3 => to v4
      },
    };

    const currentFilters = getAllIngestionFilters(yield effects.select());

    yield effects.put(ingestionActions.fetchAllFiltersByLogic.fulfilled([...currentFilters.filter((filter) => filter.id !== editedFilter.id), newFilter], '', ''));
    // const relatedDataSourceId = getSingleSelectedSourceId(yield effects.select());
    // yield effects.put(ingestionActions.fetchAllFiltersByLogic(relatedDataSourceId) as any);
  } catch (error) {
    console.log('err', error);
  } finally {
    const isLoadingFilters = getIsLoadingFilters(yield effects.select());
    if (isLoadingFilters) {
      yield effects.put(ingestionActions.setIsLoadingFilters(false));
    }
  }
}

export default function* rootSaga() {
  yield effects.takeLatest(
    ingestionActions.createEntityFieldIngestionFilterIntent.type,
    handleCreateIngestionFilter,
  );
  yield effects.takeLatest(
    ingestionActions.editEntityFieldIngestionFilterIntent.type,
    handleEditIngestionFilter,
  );
}
