import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleHidingAndRevealingColumns } from '../../actions';
import streamsSelectors from '../../../Streams/selectors';
import { getSelectedItemIds } from '../../selectors';
import { generateColumnUuid } from '../../utils';
import { HeaderBarButton } from '../../../../components/ui';

const MoveColumnsHeader = () => {
  const { t } = useTranslation('headers');
  const dispatch = useDispatch();

  const currentDataset = useSelector(streamsSelectors.getCurrentDataset);
  const selectedItemIds = useSelector(getSelectedItemIds);

  const hiddenColumns = currentDataset?.columns.filter(
    (column) => column.isHidden,
  ) || [];

  // we are checking if all selected items are hidden
  const isRevealingAction = selectedItemIds.length > 0
    && selectedItemIds.every((itemId) => hiddenColumns.some((column) => itemId === generateColumnUuid(column)));

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(handleHidingAndRevealingColumns(isRevealingAction));
  };

  if (selectedItemIds.length === 0) {
    return null;
  }

  return (
    <div data-testid="columns-sequencing-header">
      <HeaderBarButton
        data-testid="hide-unhide-columns-btn"
        tooltip={(
          <Trans t={t} i18nKey={`actionBar:layout.${isRevealingAction ? 'unhide' : 'hide'}`} />
        )}
        iconProps={{ iconName: isRevealingAction ? 'Hide3' : 'View' }}
        onClick={handleIconClick}
      />
    </div>
  );
};

export default MoveColumnsHeader;
