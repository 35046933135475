import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Stack, Text } from '@fluentui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Transition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { getNotifications } from '../../selectors';
import { fetchNotificationsIntent } from '../../actions';
import NotificationCard from '../../components/NotificationCard';

import * as styles from './styles';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';

const SCROLLABLE_TARGET = 'container-box';

const animationDuration = 300;

const defaultStyle = {
  transition: `opacity ${animationDuration}ms ease-in-out`,
  opacity: 0,
};

const showStyle = {
  opacity: 1,
};

const removeStyle = {
  opacity: 0,
};

const transitionStyles = {
  entering: { ...showStyle },
  entered: { ...showStyle },
  exiting: { ...removeStyle },
  exited: { ...removeStyle },
};

const NotificationsScreen: React.FC = () => {
  // HOOKS
  const { t } = useTranslation('notifications');
  const dispatch = useDispatch();

  // STATE
  const { records, isLoading } = useSelector(getNotifications);

  // Temporary solution until API will allow to order notifications by timestamp
  // We need it performance-wise
  const [page, setPage] = React.useState(1);
  const notificationsToRender = records.slice(0, 40 * page);

  // DERIVED STATE
  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:notifications:activityFeed:cta:noNotifications':
        records.length === 0,
    }),
    [records.length]
  );

  // CALLBACKS
  const onLoadMore = () => {
    setPage(page + 1);
  };

  // EFFECTS
  useEffect(() => {
    if (records.length === 0) {
      dispatch(fetchNotificationsIntent());
    }
  }, []);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (!isLoading && tutorialTile) return tutorialTile;

  if (records.length === 0 && !isLoading) {
    return (
      <div className={styles.classNames.noRecordsMessage}>
        <Text>{t('noRecordsMessage')}</Text>
      </div>
    );
  }

  if (isLoading) {
    return <ItemsListShimmer />;
  }

  return (
    <Stack styles={styles.containerStyles}>
      <Stack style={{ padding: '6px 4px' }}>
        <InfiniteScroll
          scrollableTarget={SCROLLABLE_TARGET}
          dataLength={notificationsToRender.length}
          hasMore={notificationsToRender.length < records.length}
          next={onLoadMore}
          loader={<Spinner />}
        >
          <TransitionGroup
            id="wrap123"
            className={styles.classNames.gridWrapper}
          >
            {notificationsToRender.map((n) => (
              <Transition key={n.id} timeout={animationDuration}>
                {(state) => (
                  <div
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    <NotificationCard notification={n} />
                  </div>
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </InfiniteScroll>
      </Stack>
    </Stack>
  );
};

export default NotificationsScreen;
