import React from 'react';
import { IUser } from '../../../../types/IUser';

export const usePickingUsers = () => {
  const [pickedUserIds, setPickedUserIds] = React.useState<string[]>([]);

  const handleUserClick = (user:IUser) => {
    if (pickedUserIds.includes(user.id)) {
      setPickedUserIds(pickedUserIds.filter((id) => id !== user.id));
    } else {
      setPickedUserIds([...pickedUserIds, user.id]);
    }
  };

  return { pickedUserIds, handleUserClick };
};
