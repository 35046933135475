import { mergeStyleSets } from 'office-ui-fabric-react';
import { baseColors } from '../../theme';

export const classNames = mergeStyleSets({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    margin: '0 20px',
  },
  header: {
    fontSize: '14px',
    fontWeight: 600,
  },
  message: {
    fontSize: '12px',
    selectors: {
      strong: {
        color: baseColors.midOrange,
      },
    },
  },
});
