import { createSelector } from 'reselect';
import { AggregationType } from '../../../../api/model/schemas/AggregationType';
import { ApplicationState } from '../../../../store/types';
import {
  getFetchedAssetsAsStreamEntities,
  getPreviouslyFetchedAssets,
  getSelectedStreamEntitiesNormalizedById,
} from '../../selectors';
import { groupByAssetValue } from './screens/OutputPreview/utils';

export const CARD_KEY_PROP = '__CARD_KEY';

const getOutputStreamerState = (state: ApplicationState) =>
  state.streamerOutput;

const getOutputRows = (state: ApplicationState) =>
  getOutputStreamerState(state).rows;

const getAggregationText = (aggregation: AggregationType) => {
  if (!aggregation) return '';

  return aggregation === AggregationType.None ? '' : aggregation;
};

export const getNormalizedOutput = createSelector(
  [
    getSelectedStreamEntitiesNormalizedById,
    getPreviouslyFetchedAssets,
    getOutputRows,
  ],
  (normalizedRawAssets, fetched, rows) => {
    if (!rows || rows.length === 0) return [];

    const rowCount = rows.length;

    const resolvedDuplicateNamesRawAssets: typeof normalizedRawAssets = fetched
      .map((asset) => {
        const currentAsset = normalizedRawAssets[asset.streamElementId];
        const currentAssetName = currentAsset?.name;
        const currentNameCount = fetched.filter(
          (a) =>
            normalizedRawAssets[a.streamElementId]?.name === currentAssetName
        ).length;

        return currentNameCount > 1
          ? {
              ...currentAsset,
              name: `${currentAsset.name} (${currentAsset.processedEntityName})`,
            }
          : currentAsset;
      })
      .reduce(
        (accumulator, currentAsset) => ({
          ...accumulator,
          [currentAsset.id]: currentAsset,
        }),
        {}
      );

    const parsedColumnNamesWithRowValues = fetched.map(
      (column, columnIndex) => ({
        header: `${getAggregationText(column.aggregation)} ${
          resolvedDuplicateNamesRawAssets[column.streamElementId].name
        }`,
        rows: rows.map((row) => row[columnIndex]).slice(0, rowCount),
      })
    );

    return [...Array(rowCount)].map((_, index) => ({
      ...parsedColumnNamesWithRowValues.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.header]: cur.rows[index],
        }),
        {}
      ),
    }));
  }
);

export const getCardsNormalizedOutput = createSelector(
  [getNormalizedOutput],
  (normalizedOutput) =>
    normalizedOutput.map((entry, index) => ({
      ...entry,
      [CARD_KEY_PROP]: index,
    }))
);

export const getIsFetchingStreamerOutput = (state: ApplicationState) =>
  getOutputStreamerState(state).isFetching;

export const getColumnsAssetId = (state: ApplicationState) =>
  getOutputStreamerState(state).columnsAssetId;
export const getSwimlanesAssetId = (state: ApplicationState) =>
  getOutputStreamerState(state).swimlanesAssetId;

export const getClosedColumns = (state: ApplicationState) =>
  getOutputStreamerState(state).closedColumns;
export const getClosedSwimlanes = (state: ApplicationState) =>
  getOutputStreamerState(state).closedSwimlanes;

export const getSwimlanes = createSelector(
  [
    getCardsNormalizedOutput,
    getFetchedAssetsAsStreamEntities,
    getSwimlanesAssetId,
  ],
  (data, assets, assetId) =>
    groupByAssetValue({
      assetId,
      data,
      assets,
    })
);

export const getColumns = createSelector(
  [
    getCardsNormalizedOutput,
    getFetchedAssetsAsStreamEntities,
    getColumnsAssetId,
  ],
  (data, assets, assetId) =>
    groupByAssetValue({
      assetId,
      data,
      assets,
    })
);

export const getOutputViewType = (state: ApplicationState) =>
  getOutputStreamerState(state).view;

export const getSelectedCardKeys = (state: ApplicationState) =>
  getOutputStreamerState(state).selectedCardKeys;

export const getIsSharePreviewOpen = (state: ApplicationState) =>
  getOutputStreamerState(state).isSharePreviewOpen;

export const getAreCardsForceOpen = (state: ApplicationState) =>
  getOutputStreamerState(state).forceOpenAllCards;

export const getHasAnyOutputData = (state: ApplicationState) =>
  getOutputStreamerState(state).rows &&
  getOutputStreamerState(state).rows.length > 0;

export const getHasFetchedData = (state: ApplicationState) =>
  getOutputStreamerState(state).rowCount &&
  getOutputStreamerState(state).totalRowCount;

export const getIsAllDataFetched = (state: ApplicationState) =>
  getOutputStreamerState(state)?.rows?.length >=
  getOutputStreamerState(state)?.totalRowCount;

export const getAreTableTotalsEnabled = (state: ApplicationState) =>
  getOutputStreamerState(state)?.enableTableTotals;
