import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { routes } from '../../../../constants/routes';

import Streams from '../Streams';
import AssetsHubs from '../AssetsHubs';
import AssetsCalculations from '../AssetsCalculations';
import AssetsData from '../AssetsData';
import AssetsFilters from '../AssetsFilters';
import AssetsGroups from '../AssetsGroups';
import Sources from '../Sources';
import Preview from '../Preview';
import Links from '../AssetsLinks/AssetsLinks';
import {
  SectionSwitch,
  SectionRoute,
} from '../../../../shared/section-route/SectionSwitch';
import { AssetsMachineLearning } from '../AssetsMachineLearning/AssetsMachineLearning';

const BUILDER_MODULE_NAME = 'builder';
const BUILDER_ASSETS_SECTION_NAME = 'assets';

const ASSET_SECTION_ROUTES: SectionRoute[] = [
  [routes.builder.assets.sources, Sources],
  [routes.builder.assets.hubs, AssetsHubs],
  [routes.builder.assets.calculations, AssetsCalculations],
  [routes.builder.assets.data, AssetsData],
  [routes.builder.assets.groups, AssetsGroups],
  [routes.builder.assets.preview, Preview],
  [routes.builder.assets.links, Links],
  [routes.builder.assets.machineLearning, AssetsMachineLearning],
];

const Index = () => (
  <Switch>
    <Route
      path={[
        routes.builder.assets.filters.index,
        routes.builder.assets.filters.add,
      ]}
      component={AssetsFilters}
    />
    <Route path={routes.builder.assets.index}>
      <SectionSwitch
        defaultPath={routes.builder.assets.sources}
        module={BUILDER_MODULE_NAME}
        routes={ASSET_SECTION_ROUTES}
        section={BUILDER_ASSETS_SECTION_NAME}
        ignoredRoutes={[routes.builder.assets.data]}
      />
    </Route>
    <Route path={routes.builder.streams.index} component={Streams} />
    <Redirect to={routes.builder.streams.index} />
  </Switch>
);

export default Index;
