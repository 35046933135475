/* eslint-disable no-empty */
import { uniqBy } from 'lodash';
import { OptionGroup } from '../../../../components/FiltersForm/components/ItemSelect';
import { IDesignerFilter } from '../../../../types/IDesignerFilter';
import {
  IDesignSourceEntity,
  IDesignSourceEntityField,
  IStreamField,
} from '../../../Designer/types';
import { matchesSearchQuery } from '../../../Search/utils';

type Params = {
  filters: IDesignerFilter[];
  entities: IDesignSourceEntity[];
  entityFields: IDesignSourceEntityField[];
};

type Output = {
  entityName: string;
  filters: IDesignerFilter[];
}[];

export const parseFiltersIntoRenderableGroups = (input: Params): Output => {
  const { entities, entityFields, filters } = input;
  let output: Output = [];

  if (entities.length === 0 || entityFields.length === 0) return output;

  const groupsWithDuplicates = filters.reduce<IDesignSourceEntity[]>(
    (acc, cur) => {
      const associatedStreamField = cur.item as unknown as IStreamField;

      const entityField = entityFields.find(
        (f) => f.id === associatedStreamField?.sourceFieldId
      );
      const entity = entities.find((e) => e.id === entityField?.parentId);

      return [...acc, entity];
    },
    []
  );

  // randomly this produces array of undefined - even though
  // input is defined. Tried investigating this but for now
  // doing quick try/cath fix
  const withoutDuplicates = uniqBy(groupsWithDuplicates, 'name');

  try {
    output = withoutDuplicates
      .sort((entityA, entityB) => entityA.name.localeCompare(entityB.name))
      .map((entity) => ({
        entityName: entity.name,
        filters: filters
          .filter((filter) => {
            const associatedStreamField =
              filter.item as unknown as IStreamField;
            const entityField = entityFields.find(
              (f) => f.id === associatedStreamField?.sourceFieldId
            );

            return entityField?.parentId === entity.id;
          })
          .sort((filterA, filterB) =>
            filterA.item.name.localeCompare(filterB.item.name)
          ),
      }));
  } catch (error) {}

  return output;
};

type GetItemOptionParams = {
  streamFields: IStreamField[];
  entities: IDesignSourceEntity[];
  entityFields: IDesignSourceEntityField[];
  textInput: string;
};

export const getItemOptions = ({
  entities,
  entityFields,
  streamFields,
  textInput,
}: GetItemOptionParams): OptionGroup[] => {
  let output: OptionGroup[] = [];

  if (entities.length === 0 || entityFields.length === 0) return output;

  const groupsWithDuplicates = streamFields.reduce<IDesignSourceEntity[]>(
    (acc, currentField) => {
      const entityField = entityFields.find(
        (f) => f.id === currentField?.sourceFieldId
      );
      const entity = entities.find((e) => e.id === entityField?.parentId);

      return [...acc, entity];
    },
    []
  );

  // randomly this produces array of undefined - even though
  // input is defined. Tried investigating this but for now
  // doing quick try/cath fix
  const withoutDuplicates = uniqBy(groupsWithDuplicates, 'name');

  try {
    output = withoutDuplicates
      .sort((entityA, entityB) => entityA.name.localeCompare(entityB.name))
      .map((entity) => ({
        label: entity.name,
        options: streamFields
          .filter((streamField) => {
            const entityField = entityFields.find(
              (f) => f.id === streamField?.sourceFieldId
            );

            if (textInput) {
              return (
                entityField?.parentId === entity.id &&
                matchesSearchQuery(textInput, streamField.name)
              );
            }

            return entityField?.parentId === entity.id;
          })
          .sort((fieldA, fieldB) => fieldA.name.localeCompare(fieldB.name)),
      }));
  } catch (error) {}

  return output;
};
