import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNestedEntities } from '../../../../api';
import { ApiV4ResponseWrapper } from '../../../../types';
import { IEntityFormSection, IGenericEntity } from '../../../types';
import * as api from '../../../api';
import { getSelectedDesignId } from '../../../../ContentLibrary/selectors';
import { addEntities, setEntityFormSyncing } from '../../../actions';
import { getAllEntitiesByTypeId } from '../../../selectors';

const useMultiEntityForm = <T extends IGenericEntity = IGenericEntity>(
  formSchemaId: string,
  entityId?: string
) => {
  const dispatch = useDispatch();
  const designId = useSelector(getSelectedDesignId);

  const [schema, setSchema] = useState<IEntityFormSection>(null);
  const entities = useSelector(getAllEntitiesByTypeId(formSchemaId));

  useEffect(() => {
    (async () => {
      try {
        dispatch(setEntityFormSyncing(true));

        const { data: res } = await api.fetchEntityForm({
          typeId: formSchemaId,
          designId,
        });
        setSchema(res.data[0]);
        if (entityId) {
          const { data: result } = await fetchNestedEntities<
            ApiV4ResponseWrapper<T[]>
          >({ typeId: formSchemaId })(entityId)();
          dispatch(addEntities(result.data));
        }
      } finally {
        dispatch(setEntityFormSyncing(false));
      }
    })();
  }, []);

  return { schema, entities };
};

export default useMultiEntityForm;
