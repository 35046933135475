import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from '../../../../constants/routes';

// import SearchIconButton from '../../../Search/components/SearchIconButton';
import DesignsHeader from '../../ContentLibrary/components/DesignsHeader/DesignsHeader';
import TagsHeader from '../../Metadata/components/TagsHeader/TagsHeader';
import SourcesHeader from '../../Ingestion/components/SourcesHeader';
import FilterLogicHeader from '../FiltersLogicHeader/FiltersLogicHeader';
import DataTopHeader from '../../Catalog/components/DataTopHeader';
import GroupsHeader from '../../Catalog/components/GroupsHeader';
import HubsHeader from '../../Catalog/components/HubsHeader';
import IngestDataButton from '../../Ingestion/components/IngestDataButton';
import LinksHeader from '../../Ingestion/components/LinksHeader';
import CalcsHeader from '../../Catalog/components/CalcsHeader';
import { CatalogHeader } from '../../Metadata/components/CatalogHeader/CatalogHeader';
import TransformsHeader from '../../Ingestion/components/TransformsHeader';
import { MLHeader } from '../../Catalog/components/MLHeader/MLHeader';

const DesignerHeader = () => (
  <>
    <Switch>
      <Route
        path={routes.designer.ingestion.filters.logic.index}
        component={FilterLogicHeader}
      />
      <Route
        path={routes.designer.contentLibrary.designs}
        component={DesignsHeader}
      />
      <Route path={routes.designer.metadata.tags} component={TagsHeader} />
      <Route
        path={routes.designer.metadata.catalogDefinition}
        component={CatalogHeader}
      />
      <Route
        path={routes.designer.ingestion.sources}
        component={SourcesHeader}
      />
      <Route
        path={routes.designer.ingestion.transforms}
        component={TransformsHeader}
      />
      <Route path={routes.designer.catalog.data} component={DataTopHeader} />
      <Route path={routes.designer.catalog.links} component={LinksHeader} />
      <Route path={routes.designer.catalog.groups} component={GroupsHeader} />
      <Route path={routes.designer.catalog.hubs} component={HubsHeader} />
      <Route
        path={routes.designer.catalog.calculations}
        component={CalcsHeader}
      />
      <Route
        path={routes.designer.catalog.machineLearning}
        component={MLHeader}
      />
    </Switch>
    <Route path={routes.designer.ingestion.index}>
      <IngestDataButton />
    </Route>
  </>
);

export default DesignerHeader;
