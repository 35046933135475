import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Groups';

export const TUTORIAL_TILE_KEY = 'builder-catalog-groups';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:builder:catalog:groups:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:builder:catalog:groups:items:selectGroups:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:builder:catalog:groups:items:selectGroups:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010411101-Builder-Groups-Data-integrity-and-intelligence',
};
