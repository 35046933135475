import { IButtonStyles, IStackStyles } from '@fluentui/react';
import { baseColors } from '../../../../theme';

export const copyButtonStyles: IButtonStyles = {
  root: {
    float: 'left',
    color: baseColors.iconColor,
  },
};

export const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
};
