import { some } from 'lodash';
import { Task } from 'redux-saga';
import { call, fork, put, race, take } from 'redux-saga/effects';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { fetchNestedEntities } from '../../../api';
import * as actions from '../../actions';
import { IGenericEntity } from '../../types';
import { FetchTypedEntitiesPayload } from './types';
import { fetchSourceEntities } from './utils';

export function* fetchTypedEntities({
  typeId,
  designId,
  sourceId,
  selectedFieldsOnly,
  entityId,
}: FetchTypedEntitiesPayload) {
  console.time('fetchTypedEntities');

  yield put(
    actions.addRunningTaskTypedEntities({
      typeId,
      sourceId,
    })
  );
  try {
    let records: IGenericEntity[] = [];
    if (typeId === TYPE_IDS.SourceEntityField) {
      const { entities, fields } = yield call(
        fetchSourceEntities,
        sourceId,
        entityId,
        false,
        selectedFieldsOnly
      );
      yield put(
        actions.addTypedEntities({
          entities,
          typeId: TYPE_IDS.SourceEntity,
        })
      );
      records = fields;
    } else if (typeId === TYPE_IDS.SourceEntity) {
      const {
        data: { data: entities },
      } = yield call(fetchNestedEntities({ typeId })(sourceId));
      records = entities;
    } else {
      const {
        data: { data: entities },
      } = yield call(fetchNestedEntities({ typeId })(designId));
      records = entities;
    }
    yield put(
      actions.addTypedEntities({
        entities: records,
        typeId,
      })
    );
  } catch (e) {
    console.error(e);
  } finally {
    yield put(
      actions.removeRunningTaskTypedEntities({
        typeId,
        sourceId,
      })
    );
    console.timeEnd('fetchTypedEntities');
  }
}

export function* typedEntitiesFetchWatcher() {
  let queued: {
    payload: FetchTypedEntitiesPayload;
    task: Task;
  }[] = [];
  while (true) {
    const { data, flush } = yield race({
      data: take(actions.fetchTypedEntities.type),
      flush: take(actions.restartForm.type),
    });

    if (flush) {
      queued.forEach((item) => item.task.cancel());
      queued = [];
    } else if (data) {
      const { payload } = data;
      if (
        !some(
          queued.map((item) => item.payload),
          payload
        )
      ) {
        const task = yield fork(fetchTypedEntities, payload);
        queued.push({ payload, task });
      }
    }
  }
}
