import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { filter as loFilter, includes } from 'lodash';
import { Toggle } from '@fluentui/react';
import IconButton from '../../../../../components/IconButton';
import { getClassNames } from './styles';
import { IDesignerFilter } from '../../../../../types/IDesignerFilter';
import {
  ComparisonTypes,
  FilterTypes,
} from '../../../../../types/IStreamFilter';
import Row from './GroupPickerRow';
import GroupPickerCallout from './GroupPickerCallout';
import { COLUMN_DATA_TYPES } from '../../../../../constants/aggregations';
import { DisplayModes, Group, SortModes } from './types';
import DisplayModeButton from './GroupPickerDisplayModeBtn';
import SortingModeButton from './GroupPickerSortingModeBtn';
import { IGenericFormField } from '../../types';
import useManageMultiple from '../ManageMultiple/hooks/useManageMultiple';
import { useGroupAssetValues } from './useGroupAssetValues';
import { compileStreamFiltersToRequest } from '../../../../StreamFilters/utils';
// import { ENTITY_CHILDREN_KEY } from '../../constants';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getTypedEntityById } from '../../selectors';
import {
  EntityIngestionStatus,
  IDesignSourceEntityField,
} from '../../../types';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { FilterFormTextInput } from '../../../../../components/ui';

export const GroupPicker: React.FC<IGenericFormField> = ({
  interaction,
  currentForm,
  isEditing,
}) => {
  // STATE
  const typeId = interaction.referenceType;
  const [mode, setMode] = useState<DisplayModes>(DisplayModes.values);
  const [sortingMode, setSortingMode] = useState<SortModes>(
    SortModes.ascending
  );
  const [useValuesOnce, setUseValuesOnce] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string>(null);
  const { t } = useTranslation();
  const classNames = getClassNames();


  const assetId = currentForm?.data?.assetId as string;
  const asset = useSelector(getTypedEntityById(assetId));
  const values = useGroupAssetValues(asset);

  const {
    items = [],
    append,
    remove,
    move,
    edit,
  } = useManageMultiple<Group>({
    typeId,
    currentEntityForm: currentForm,
    interaction,
  });

  const { setPreventSubmission } = useFormSubmission();

  // CALLBACKS

  const handleAdd = useCallback(async () => {
    const id = await append({
      name: '',
      compareValue: '',
      comparison: ComparisonTypes.Equal,
      type: FilterTypes.Comparison,
    });
    setSelectedItemId(id);
  }, [append]);

  const handleCloseCallout = useCallback(
    async (isValid: boolean) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (!isValid || !selectedItem?.data?.name) {
        remove(selectedItemId);
      }
      setSelectedItemId(null);
    },
    [selectedItemId]
  );

  const handleSubmitCallout = useCallback(
    async (res: IDesignerFilter & { name: string }) => {
      const { name } = res;
      const filter = compileStreamFiltersToRequest(res);
      await edit(selectedItemId, { name, ...filter });
      setSelectedItemId(null);
    },
    [edit, selectedItemId]
  );

  const handleEditItem = edit;

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      move(source.index, destination.index);
    },
    [move]
  );

  // DERIVED STATE

  const sortedItems = useMemo(() => {
    if (sortingMode === SortModes.custom) {
      return items;
    }

    const sorted = [...items].sort((a, b) => {
      // If no name, send to the bottom
      if (!a?.data?.name) return 1;

      const aName = a?.data?.name || '';
      const bName = b?.data.name || '';

      switch (sortingMode) {
        case SortModes.ascending:
          return aName?.localeCompare(bName);
        case SortModes.descending:
          return bName?.localeCompare(aName);
        default:
          return 1;
      }
    });

    return sorted;
  }, [items, sortingMode]);

  const selectedItem = items.find((item) => item?.id === selectedItemId);

  const calloutDefaultValues = useMemo(
    () => ({
      ...(selectedItem?.data || {}),
      aggregation: null,
      id: selectedItem?.id,
      item: {
        id: null,
        name: null,
        dataType: asset?.dataType || COLUMN_DATA_TYPES.Text,
      },
    }),
    [selectedItemId, asset]
  );

  const isLogical = items.some(
    (item) =>
      ![
        ComparisonTypes.In,
        ComparisonTypes.Equal,
        ComparisonTypes.IsNull,
        ComparisonTypes.IsBlank,
      ].includes(item?.data?.comparison)
  );

  const shouldRenderValuePicker: boolean =
    asset?.$typeId === TYPE_IDS.SourceEntityField
      ? (asset as IDesignSourceEntityField)?.ingestionStatus ===
        EntityIngestionStatus.Processed
      : ([TYPE_IDS.Group, TYPE_IDS.Hub] as string[]).includes(asset?.$typeId);

  const names = useMemo(() => items.map((item) => item?.data?.name), [items]);
  const areAllNamesUnique = useMemo(
    () =>
      !loFilter(names, (val, i, iteratee) => includes(iteratee, val, i + 1))
        .length,
    [names]
  );
  const areItemsValid =
    !selectedItemId || (selectedItem?.data?.name && areAllNamesUnique);

  const usedValues = useMemo(
    () =>
      items.flatMap((item) => {
        const arr = [...(item?.data?.values || [])];
        if (item?.data?.compareValue) {
          arr.push(item?.data?.compareValue);
        }
        return arr;
      }),
    [items]
  );

  const rowValuesOptions = useMemo(
    () =>
      useValuesOnce
        ? values.filter((val) => !usedValues.includes(val))
        : values,
    [values, useValuesOnce, usedValues]
  );

  // PARTS

  const header = (
    <div className={classNames.grid}>
      <div className={`${classNames.row} ${classNames.headerRow}`}>
        <div className={classNames.headers}>{t('entityTypes:Group')}</div>
        <div className={classNames.headers}>{t('filters:Values')}</div>
        <div>
          <IconButton
            iconProps={{
              iconName: 'SynergiesBulkUpload',
            }}
          />
          <DisplayModeButton
            disabled={!!selectedItemId}
            setMode={setMode}
            isLogical={isLogical}
            currentMode={mode}
          />
          <SortingModeButton
            disabled={!!selectedItemId}
            setSortingMode={setSortingMode}
            currentSortMode={sortingMode}
          />
        </div>
      </div>
    </div>
  );

  const dndList = (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable
        // eslint-disable-next-line i18next/no-literal-string
        droppableId="group-dnd"
      >
        {(provided) => (
          <div ref={provided.innerRef} className={classNames.grid}>
            {sortedItems.map((item) => {
              const index = items?.findIndex(
                (current) => current?.id === item?.id
              );
              return (
                <Row
                  {...{
                    index,
                    mode,
                    handleEditItem,
                    setSelectedItemId,
                    areAllNamesUnique,
                  }}
                  id={item?.id}
                  key={item?.id}
                  name={item?.data?.name}
                  onRemove={remove}
                  options={rowValuesOptions}
                  isSelected={selectedItemId === item?.id}
                  shouldDnd={
                    sortingMode === SortModes.custom && !selectedItemId
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  filter={item?.data}
                  shouldRenderValuePicker={shouldRenderValuePicker}
                  currentSelectedItem={selectedItem?.data}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  // EFFECTS

  useEffect(() => {
    if (isEditing && isLogical && mode !== DisplayModes.logic) {
      setMode(DisplayModes.logic);
    }
  }, [isLogical]);

  useEffect(() => {
    setPreventSubmission(!areItemsValid);
    return () => {
      setPreventSubmission(false);
    };
  }, [areItemsValid]);

  // RENDER
  return (
    <>
      {header}

      {dndList}

      {areItemsValid && (
        <IconButton
          iconProps={{ iconName: 'Add' }}
          onMouseDown={handleAdd}
          className={classNames.addButton}
          data-testid="add-group-row-btn"
        />
      )}
      {selectedItemId && mode === DisplayModes.logic && (
        <GroupPickerCallout
          defaultValues={calloutDefaultValues}
          title={t('wizard:Logic')}
          target={`[data-group-id="${selectedItemId}"]`}
          onClose={handleCloseCallout}
          onSubmit={handleSubmitCallout}
          calloutWidth={265}
          loadValuesOptions={(_, query) =>
            new Promise((resolve) =>
              resolve(
                values.filter(
                  (val) =>
                    val != query &&
                    (useValuesOnce ? !usedValues.includes(val) : true)
                )
              )
            )
          }
          acceptsTypedValues={!shouldRenderValuePicker}
        >
          <Controller
            name="name"
            rules={{
              required: true,
              validate: (val) =>
                val === selectedItem?.data?.name || !names.includes(val),
            }}
            defaultValue={selectedItem?.data?.name}
            render={({ onChange, value, ref }) => (
              <FilterFormTextInput
                required
                onChange={onChange}
                value={value}
                componentRef={ref}
                label={t('wizard:Group')}
                data-testid="group-name-field"
              />
            )}
          />
        </GroupPickerCallout>
      )}
      {mode === DisplayModes.values && (
        <Toggle
          label={t('wizard:useGroupValuesOnce')}
          checked={useValuesOnce}
          onChange={() => setUseValuesOnce((b) => !b)}
          inlineLabel
          className={classNames.useOnce}
        />
      )}
    </>
  );
};

export default GroupPicker;
