import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { setEntityFormSyncing } from '../../actions';
import { requestAssetValues } from '../../../Ingestion/api';
import {
  ApiV4PostResponse,
  EntityIngestionStatus,
  IAssetValuesRequestResponse,
  IDesignSourceEntityField,
} from '../../../types';
import { IGenericEntity } from '../../types';

export const useGroupAssetValues = (entity: IGenericEntity) => {
  console.log({ entity });

  const dispatch = useDispatch();
  const [values, setValues] = useState<any[]>([]);

  const fetchValues = async (continuationToken?: string) => {
    if (
      entity?.$typeId === TYPE_IDS.SourceEntityField &&
      (entity as IDesignSourceEntityField)?.ingestionStatus !==
        EntityIngestionStatus.Processed
    ) {
      return [];
    }

    let data: ApiV4PostResponse & IAssetValuesRequestResponse;

    switch (entity?.$typeId) {
      case TYPE_IDS.SourceEntityField:
        data = (
          await requestAssetValues(
            entity.id,
            TYPE_IDS.SourceEntityFieldValuesRequest,
            continuationToken
          )
        )?.data;
        break;
      case TYPE_IDS.Hub:
        data = (
          await requestAssetValues(
            entity.id,
            TYPE_IDS.HubValuesRequest,
            continuationToken
          )
        )?.data;
        break;
      case TYPE_IDS.Group:
        data = (
          await requestAssetValues(
            entity.id,
            TYPE_IDS.GroupValuesRequest,
            continuationToken
          )
        )?.data;
        break;

      default:
        return [];
    }

    const responseValues = data?.result?.rows.flat();
    const currentContinationToken = data?.continuationToken;

    console.log({
      responseValues,
      currentContinationToken,
      typeId: entity.$typeId,
    });

    if (responseValues) {
      // For reasons unknown atm, the server might send a empty object in the array
      // of values, so we'll need to filter those out.
      const filteredValues = responseValues
        ?.filter((val) => ['string', 'number', 'boolean'].includes(typeof val))
        ?.sort((a, b) => String(a).localeCompare(String(b)));

      let nextPageResponse = [];
      if (currentContinationToken) {
        nextPageResponse = await fetchValues(currentContinationToken);
      }

      return filteredValues.concat(nextPageResponse);
    }

    return [];
  };

  const getAllValues = async () => {
    try {
      dispatch(setEntityFormSyncing(true));
      const response = await fetchValues();
      setValues(response);
    } catch (e) {
      console.log('getAllValues error', e);
    } finally {
      dispatch(setEntityFormSyncing(false));
    }
  };

  useEffect(() => {
    getAllValues();
  }, []);

  return values;
};
