import { AxiosResponse } from "axios";
import { TypeIds } from "../../../model/schemas";
import { isResponseTypeIdMatching } from "../utils";
import { i18nRoleKey } from "../constants";
import { translateApiName } from "../../../../config/i18n/utils";
import { ApiV4ResponseWrapper } from "../../../../modules/Designer/types";
import { User } from "../../../model/schemas/User";

export const isUserResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<User> =>
  isResponseTypeIdMatching(response, TypeIds.User)
    && !Array.isArray((response.data as any)?.data)

export const isUsersResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<ApiV4ResponseWrapper<User[]>> =>
  isResponseTypeIdMatching(response, TypeIds.User)
    && Array.isArray((response.data as any)?.data)

export const parseUser = (user: User) => {
  return {
    ...user,
    [i18nRoleKey]: translateApiName(user.$typeId, user.role),
  };
}
