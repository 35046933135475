import React from 'react';
import { Stack, Text } from '@fluentui/react';

import {
  iconButtonStyles,
  contentEdit,
  editIconButtonStyles,
  textStyleAtRest,
  textStyleWhileEditing,
  containerStyles,
  renameButtonClassName,
} from './styles';
import IconButton from '../IconButton';
import { getSearchMatch } from '../../modules/Search/utils';
import SearchMatch from '../SearchMatch';
import { Availability } from '../../api/model/schemas/Availability';
import { availabilityTextStyles } from '../EntityField/styles';

interface Props {
  addLeftSlotPadding?: boolean;
  disableEditing?: boolean;
  isSelected?: boolean;
  onClick?(): void;
  onEdit(value: string): void;
  onEditEnd?(hasChanged?: boolean): void;
  onEditStart?(): void;
  text: string;
  testId?: string;
  transparentBackground?: boolean;
  searchQuery?: string;
  isGroupHeader?: boolean;
  availability?: Availability;
}

const EditableFoldableListItem: React.FC<Props> = ({
  availability,
  addLeftSlotPadding,
  disableEditing,
  isSelected,
  onClick,
  onEdit,
  onEditEnd,
  onEditStart,
  text,
  testId,
  transparentBackground,
  searchQuery,
  isGroupHeader,
}) => {
  const [isEditing, setEditing] = React.useState(false);
  const [internalValue, setInternalValue] = React.useState(text);
  const textBoxRef = React.useRef(null);

  // DERIVED STATE
  const hasChanged = internalValue !== text;
  const shouldLookLikeIsSelected = isSelected || isEditing;

  React.useEffect(() => {
    if (isEditing && textBoxRef?.current) {
      textBoxRef?.current?.focus();

      // set caret to the last character
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(textBoxRef?.current, 1);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }, [isEditing]);

  const cancelEdit = () => {
    setEditing(false);
    setInternalValue(text);
    if (onEditEnd) {
      onEditEnd(false);
    }
  };

  const acceptEdit = async () => {
    await onEdit(internalValue);
    setEditing(false);
    if (onEditEnd) {
      onEditEnd(true);
    }
  };

  const startEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onEditStart) {
      onEditStart();
    }
    setEditing(true);
    e.stopPropagation();
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      acceptEdit();
    }
  };

  const printText = () => {
    if (typeof searchQuery === 'string' && searchQuery !== '') {
      const match = getSearchMatch(text, searchQuery);

      if (match) {
        return <SearchMatch match={match} isBold={isGroupHeader} />;
      }
    }

    return internalValue || text;
  };

  return (
    <Stack
      styles={containerStyles(shouldLookLikeIsSelected, transparentBackground)}
      horizontal
      verticalAlign="center"
      onClick={isEditing ? null : onClick}
      data-testid={testId}
    >
      {addLeftSlotPadding && <div style={{ width: '32px', height: '100%' }} />}
      {isEditing ? (
        <>
          <div
            role="textbox"
            contentEditable
            className={contentEdit}
            onInput={(e) => setInternalValue(e.currentTarget.textContent)}
            ref={textBoxRef}
            onKeyDown={onKeyDownHandler}
            tabIndex={0}
          >
            <Text styles={textStyleWhileEditing}>{text}</Text>
          </div>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            {hasChanged && (
              <IconButton
                styles={iconButtonStyles(isEditing, shouldLookLikeIsSelected)}
                iconProps={{ iconName: 'CheckMark' }}
                onClick={acceptEdit}
                data-testid="approve-edit-button"
              />
            )}
            <IconButton
              styles={iconButtonStyles(isEditing, shouldLookLikeIsSelected)}
              iconProps={{ iconName: 'Cancel' }}
              onClick={cancelEdit}
              data-testid="cancel-edit-button"
            />
          </div>
        </>
      ) : (
        <>
          <Text styles={textStyleAtRest(isGroupHeader)}>
            <span style={availabilityTextStyles(availability)}>
              {printText()}
            </span>
          </Text>
          {!disableEditing && (
            <div style={{ marginLeft: 'auto' }}>
              <IconButton
                styles={editIconButtonStyles(isEditing, isSelected)}
                className={renameButtonClassName}
                iconProps={{ iconName: 'Rename' }}
                onClick={startEdit}
                data-testid={`${text}-edit-button`}
              />
            </div>
          )}
        </>
      )}
    </Stack>
  );
};

export default EditableFoldableListItem;
