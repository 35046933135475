import {
  TeachingBubble, ITeachingBubbleStyles, getTheme, ITeachingBubbleProps, IToggleStyles,
} from '@fluentui/react';
import React from 'react';
import merge from 'lodash/merge';
import { baseColors } from '../../../theme';

export const teachingStyles = (
  customStyles: Partial<ITeachingBubbleStyles> = {},
  footerContent: any,
): Partial<ITeachingBubbleStyles> => {
  const theme = getTheme();

  const base = {
    root: {
      backgroundColor: `${theme.palette.neutralPrimary} !important`,
      selectors: {
        strong: {
          color: theme.palette.themePrimary,
        },
        '.ms-TeachingBubble-closebutton:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.themePrimary,
        },
      },
    },
    bodyContent: {
      padding: '12px',
    },
    body: {
      marginBottom: footerContent ? '2rem' : 0,
    },
    closeButton: {
      margin: '4px 4px 0 0 ',
    },
    headline: {
      fontSize: '12px',
      fontWeight: '600 !important',
    },
    subText: {
      fontSize: '12px',
      selectors: {
        b: {
          color: theme.palette.themePrimary,
        },
      },
    },
    footer: {
      fontSize: '12px',
    },
    primaryButton: {
      color: theme.palette.neutralPrimary,
      fontSize: '12px',
      selectors: {
        '.ms-Button-label': {
          fontWeight: 600,
        },
        ':hover': {
          backgroundColor: baseColors.white,
        },
        ':active': {
          backgroundColor: baseColors.white,
        },
        ':focus': {
          backgroundColor: baseColors.white,
        },
      },
    },
    subComponentStyles: {
      callout: {
        root: {
          width: '80%',
          color: baseColors.white,
          selectors: {
            '.ms-Callout-main': {
              backgroundColor: `${theme.palette.neutralPrimary} !important`,
            },
            '.ms-Callout-beak': {
              backgroundColor: `${theme.palette.neutralPrimary} !important`,
            },
          },
        },
      },
    },
  };

  return merge({}, base, customStyles);
};

type Props = Omit<ITeachingBubbleProps, 'styles'> & {
  styles?: Partial<IToggleStyles>;
};

const StyledTeachingBubble: React.FC<Props> = ({ styles, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TeachingBubble {...rest} styles={teachingStyles(styles, rest.footerContent)} />
);

export default StyledTeachingBubble;
