import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { DRILLING_DIRECTIONS, MIN_DRILLING_LEVELS } from '../../constants';
import { getIsOtherUserSyncing } from '../../../App/selectors';
import { initialGroupTable } from '../../reducer';
import { selectors as streamSelectors } from '../../../Streams/Streams';
import { updateDataset } from '../../../../actions/excel-actions';

import { handleGroupTableColumnDrilldown, handleGroupTableRowDrilldown } from '../../../../actions/excel-actions/groupTable';
import { cancelCurrentOperation } from '../../../Streams/actions';
import { HeaderBarButton } from '../../../../components/ui';

const GroupTableHeaderBar: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('groupTable');
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const groupTable = useSelector(selectors.getGroupTable(currentDataset?.id)) || initialGroupTable;
  const isOtherUserBlockingUI = useSelector(getIsOtherUserSyncing);
  const isSyncing = useSelector(streamSelectors.getIsSyncing);
  const fetchingProgress = useSelector(streamSelectors.getFetchingProgress);
  const shouldReload = useSelector(selectors.getShouldReload(currentDataset?.id));

  const drillableSelection = useSelector(streamSelectors.getDrillableSelection);
  const isDrillableSelection = currentDataset && !isOtherUserBlockingUI && drillableSelection && !isSyncing && !shouldReload;
  const maxDrillingLevel = useSelector(selectors.getMaxDrillingLevel(currentDataset?.id, drillableSelection?.drillingDirection));
  const minDrillingLevel = MIN_DRILLING_LEVELS[drillableSelection?.drillingDirection];
  const currentDrillingLevel = groupTable?.[drillableSelection?.drillingDirection];

  const changeDrillingLevel = (amount: number) => {
    dispatch(actions.initChangeDrillingLevel({
      datasetId: currentDataset.id,
      // We asume drillableSelection will hold value since the buttons are not
      // visble if drillableSelection is null or undefined.
      direction: drillableSelection?.drillingDirection,
      level: Math.max(0, groupTable[drillableSelection?.drillingDirection] + amount),
    }));

    updateDataset({ datasetId: currentDataset.id, forceRender: true });
  };

  const handleSingleDrilling = (shouldDrillUp = false) => {
    // TODO: Most of this should be taken care by sagas.
    const excelAction = drillableSelection?.drillingDirection === DRILLING_DIRECTIONS.columnDrilling
      ? handleGroupTableColumnDrilldown
      : handleGroupTableRowDrilldown;
    excelAction(drillableSelection, shouldDrillUp);
  };

  const fetchingPages = fetchingProgress?.totalPages > 0;

  return (
    <>
      <HeaderBarButton
        tooltip={(
          <Trans t={t} i18nKey="actionBar:general.cancel" />
        )}
        onClick={() => dispatch(cancelCurrentOperation())}
        iconProps={{ iconName: 'Stop' }}
        disabled={!isSyncing && !fetchingPages}
        shouldHideOnDisabled
      />
      <HeaderBarButton
        tooltip={(
          <Trans t={t} i18nKey="actionBar:general.drillDown" />
        )}
        onClick={() => handleSingleDrilling()}
        iconProps={{ iconName: 'Down' }}
        disabled={!isDrillableSelection || !drillableSelection.canDrillDown}
        shouldHideOnDisabled
      />
      <HeaderBarButton
        tooltip={(
          <Trans t={t} i18nKey="actionBar:general.drillUp" />
        )}
        onClick={() => handleSingleDrilling(true)}
        iconProps={{ iconName: 'Up' }}
        disabled={!isDrillableSelection || !drillableSelection.canDrillUp}
        shouldHideOnDisabled
      />
      <HeaderBarButton
        tooltip={(
          <Trans t={t} i18nKey="actionBar:general.drillDownAll" />
        )}
        onClick={() => changeDrillingLevel(1)}
        iconProps={{ iconName: 'DistributeDown' }}
        disabled={!isDrillableSelection || (currentDrillingLevel >= maxDrillingLevel)}
        shouldHideOnDisabled
      />
      <HeaderBarButton
        tooltip={(
          <Trans t={t} i18nKey="actionBar:general.drillUpAll" />
        )}
        onClick={() => changeDrillingLevel(-1)}
        style={{ transform: 'rotate(180deg)' }}
        iconProps={{ iconName: 'DistributeDown' }}
        disabled={!isDrillableSelection || (currentDrillingLevel <= minDrillingLevel)}
        shouldHideOnDisabled
      />
    </>
  );
};

export default GroupTableHeaderBar;
