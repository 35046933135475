import React from 'react';
import uniqBy from 'lodash/uniqBy';

import GroupCollapse from '../../../../components/ui/GroupCollapse';
import useCollapseGroups from '../../../../hooks/useCollapseGroups';
import { ByValueGroupCollapse } from './ByValueGroupCollapse';
import {
  IDesignSourceEntity,
  IDesignSourceEntityField,
  IStreamField,
} from '../../../Designer/types';
import { IDesignerFilter } from '../../../../types/IDesignerFilter';
import { FilterTypes } from '../../../../types/IStreamFilter';
import { normalizeByKey } from '../../../../utils/normalizeEntities';

interface Props {
  streamFields: IStreamField[];
  entityFields: IDesignSourceEntityField[];
  entities: IDesignSourceEntity[];
  filters: IDesignerFilter[];
}

interface ValueGroup {
  groupName: string;
  id: string;
  fields: IDesignSourceEntityField[];
}

export const ByValueFilters: React.FC<Props> = ({
  entities,
  entityFields,
  streamFields,
  filters,
}) => {
  // STATE
  const {
    isGroupOpen: isValueGroupOpen,
    handleOpenGroup: handleValueGroupOpen,
  } = useCollapseGroups();

  // DERIVED STATE
  const relatedFields = React.useMemo(
    () =>
      entityFields.filter((field) =>
        streamFields.some(
          (streamField) => streamField.sourceFieldId === field.id
        )
      ),
    [entityFields]
  );
  const relatedEntities = React.useMemo(
    () =>
      uniqBy(
        entities.filter((entity) =>
          relatedFields.some((field) => field.parentId === entity.id)
        ),
        'id'
      ),
    [entities, relatedFields]
  );

  const valueFilters = React.useMemo(
    () => filters.filter((filter) => filter.type === FilterTypes.Values),
    [filters]
  );
  const filtersNormalizedBySourceFieldId = React.useMemo(
    () =>
      valueFilters.reduce<Record<string, IDesignerFilter>>(
        (accumulator, currentFilter) => ({
          ...accumulator,
          [currentFilter?.item?.sourceFieldId]: currentFilter,
        }),
        {}
      ),
    [valueFilters]
  );
  const streamFieldsNormalizedBySourceFieldId = React.useMemo(
    () => normalizeByKey(streamFields, 'sourceFieldId'),
    [streamFields]
  );

  const groups = React.useMemo(
    () =>
      relatedEntities
        .reduce<ValueGroup[]>((accumulator, current) => {
          accumulator.push({
            groupName: current.name,
            id: current.id,
            fields: relatedFields
              .filter((field) => field.parentId === current.id)
              .sort((a, b) => a.name.localeCompare(b.name)),
          });
          return accumulator;
        }, [])
        .sort((a, b) => a.groupName.localeCompare(b.groupName)),
    [relatedEntities, relatedFields]
  );

  const { isGroupOpen, handleOpenGroup } = useCollapseGroups();

  return (
    <section>
      {groups.map((group) => (
        <GroupCollapse
          isOpen={isGroupOpen(group.id)}
          key={group.id}
          groupName={group.groupName}
          groupId={group.id}
          onClick={() => handleOpenGroup(group.id)}
        >
          {group.fields.map((field) => (
            <ByValueGroupCollapse
              relatedSourceEntityField={field}
              key={field.id}
              isValueGroupOpen={isValueGroupOpen(field.id)}
              handleValueGroupOpen={handleValueGroupOpen}
              relatedStreamField={
                streamFieldsNormalizedBySourceFieldId[field.id]
              }
              relatedFilter={filtersNormalizedBySourceFieldId[field.id]}
            />
          ))}
        </GroupCollapse>
      ))}
    </section>
  );
};
