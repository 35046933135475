import React from 'react';
import AddButton from './components/AddButton/AddButton';

const FilterLogicHeader = () => (
  <>
    <AddButton />
  </>
);

export default FilterLogicHeader;
