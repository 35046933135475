export enum UserRoles {
  Streamer = 'Streamer',
  Builder = 'Builder',
  Designer = 'Designer',
  Admin = 'Administrator',
  NoRole = 'NoRole',
}

export enum UserStatus {
  Active = 'Active',
  Invited = 'Invited',
  Disabled = 'Disabled',
}

export enum InvitationStatus {
  PendingAcceptance = 'PendingAcceptance',
  Accepted = 'Accepted',
}

export interface IUser {
  $typeId: string;
  email: string;
  firstName: string;
  id: string;
  invitationStateChanged: string;
  isAdministrator: boolean;
  lastName: string;
  name: string;
  role: UserRoles;
  status: UserStatus;
  preferredLanguage?: string;
  invitationStatus: InvitationStatus;
  jobTitle?: string
  company?: string
  department?: string
  mobilePhone?: string
  businessPhone?: string
  streetAddress?: string
  city?: string
  postalCode?: string
  state?: string
  country?: string
}
