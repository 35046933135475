import { debounce } from 'lodash';
import { SearchBox } from 'office-ui-fabric-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { IconButtonProps } from '../../../../../components/IconButton';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { routes } from '../../../../../constants/routes';
import WizardPage, {
  WizardType,
} from '../../../../../pageTemplates/WizardPage';
import { getMLAssetById } from '../../selectors';
import { classNames, tableCell, tableHeaderCell } from './styles';
import { useMLAssetAssetValueRequest } from './useMLAssetValuesRequest';

interface PageParams {
  assetId: string;
}

export const scrollId = 'table-wrapper';

export const MachineLearningPreview = () => {
  // DEPS
  const { t } = useTranslation();
  const history = useHistory();

  // STATE
  const pageParams = useParams<PageParams>();
  const [localSearchQuery, setLocalSearchQuery] = React.useState<string>();

  const { isLoading, result, hasMore, fetchNextPage } =
    useMLAssetAssetValueRequest({
      machineLearningId: pageParams?.assetId,
      searchQuery: localSearchQuery,
    });

  // CALLBACKS
  const handleCancel = () => {
    history.push(routes.designer.catalog.machineLearning);
  };
  const handleSearchUpdate = (_ev, newValue: string) =>
    setLocalSearchQuery(newValue);
  const debouncedSearchUpdate = debounce(handleSearchUpdate, 500);
  const selectedMLAsset = useSelector(getMLAssetById(pageParams?.assetId));

  // PARTS
  const buttonsConfig: IconButtonProps[] = [
    // Right now preview is read only
    // {
    //   tooltip: <Trans t={t} i18nKey="actionBar:general.accept" />,
    //   onClick: handleConfirm,
    //   disabled: false,
    //   iconProps: { iconName: 'CheckMark' },
    //   shouldHideOnDisabled: true,
    //   'data-testid': 'confirm-export-button',
    // },
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.cancelAction" />,
      onClick: handleCancel,
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'cancel-export-button',
    },
  ];

  // RENDER
  return (
    <WizardPage
      wizardType={WizardType.Preview}
      headerBarButtonsConfig={buttonsConfig}
      pageTitle={selectedMLAsset.name}
      entityName={t('mlPreview:preview')}
    >
      <SearchBox
        autoFocus
        placeholder={t('mlPreview:searchForKey')}
        onClear={() => setLocalSearchQuery('')}
        onChange={debouncedSearchUpdate}
        data-testid="searchbox"
        value={localSearchQuery}
        spellCheck={false}
        iconProps={{
          iconName: 'Search',
        }}
        clearButtonProps={{
          iconProps: {
            iconName: 'Cancel',
          },
        }}
      />
      {isLoading ? (
        <ItemsListShimmer />
      ) : (
        <div className={classNames.table} id={scrollId}>
          <div className={classNames.headerRow}>
            {result?.columns?.map((column) => (
              <div className={classNames.cell} style={tableHeaderCell}>
                {column.name}
              </div>
            ))}
          </div>
          <div>
            <InfiniteScroll
              dataLength={result?.rows?.length ?? 0}
              hasMore={hasMore}
              loader={<ItemsListShimmer />}
              next={fetchNextPage}
              scrollableTarget={scrollId}
              scrollThreshold="200px"
            >
              {result?.rows?.map((values) => (
                <div key={values?.toString()} className={classNames.tableRow}>
                  {values?.map((val) => (
                    <div className={classNames.cell} style={tableCell}>
                      {val}
                    </div>
                  ))}
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </WizardPage>
  );
};

export default MachineLearningPreview;

