import * as effects from 'redux-saga/effects';
import { GenerateLinksJob } from '../../../../../api/model/schemas/GenerateLinksJob';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { routes } from '../../../../../constants/routes';
import {
  NotificationActivity,
  NotificationSubjectState,
} from '../../../../../types/INotification';
import { memoryHistoryService } from '../../../../App/services/memoryHistory';
import { appendNotificationIntent } from '../../../../Notifications/actions';
import { createNestedEntity } from '../../../api';
import { getSelectedSourceId } from '../../../Catalog/selectors';
import * as ingestionActions from '../../actions';

function* processLinkDiscoveryNotification({
  payload: notification,
}: ReturnType<typeof appendNotificationIntent>) {
  const isLinkDiscoveryComplete =
    notification.activity === NotificationActivity.LinkDiscovery &&
    [
      NotificationSubjectState.Complete,
      NotificationSubjectState.Failed,
      NotificationSubjectState.Cancelled,
    ].includes(notification.state);

  if (!isLinkDiscoveryComplete) return;
  yield effects.put(ingestionActions.setLinksDiscoveryInProgress(false));

  const history = memoryHistoryService.getHistory();

  // NOTE: refresh the links view only if it's currently opened
  if (history.location.pathname === routes.designer.catalog.links) {
    const state = yield effects.select();
    const selectedDataSourceId = getSelectedSourceId(state);
    // NOTE: asserting type because out of the box redux-saga typings don't work with thunk actions
    yield effects.put(
      ingestionActions.fetchDesignLinks({
        dataSourceId: selectedDataSourceId,
      }) as any
    );
  }
}

function* handleLinksDiscovery({
  payload: { dataSourceId },
}: ReturnType<typeof ingestionActions.linksDiscoveryIntent>) {
  try {
    yield effects.put(ingestionActions.setLinksDiscoveryInProgress(true));

    yield effects.call(
      createNestedEntity<GenerateLinksJob>({
        parentEntityId: dataSourceId,
        typeId: TYPE_IDS.GenerateLinksJob,
      }),
      {}
    );
  } catch (error) {
    console.error(error);
  }
}

export function* linksSagas() {
  yield effects.all([
    yield effects.takeLatest(
      ingestionActions.linksDiscoveryIntent.type,
      handleLinksDiscovery
    ),
    yield effects.takeLatest(
      appendNotificationIntent.type,
      processLinkDiscoveryNotification
    ),
  ]);
}
