import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { selectors } from '../../../../Streams/Streams';
import {
  playStreamIntent,
} from '../../../../Streams/actions';
import { getIsOtherUserSyncing } from '../../../selectors';

import TeachingBubbles from '../../../../Onboarding/components/TeachingBubbles';
import { playTeachingBubbles } from '../../../../Onboarding/constants';
import { HeaderBarButton } from '../../../../../components/ui';

const PlayPauseResumeButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isOtherUserBlockingUI = useSelector(getIsOtherUserSyncing);
  const isRewinding = useSelector(selectors.getIsRewinding);
  const playButtonShouldBeVisible = useSelector(
    selectors.getIfPlayButtonShouldBeVisible,
  );
  const isSyncing = useSelector(selectors.getIsSyncing);

  const {
    autoLoadAll: isPlaying,
  }: {
    autoLoadAll: boolean;
  } = useSelector(selectors.getStreamsState);

  const isDisabled = !playButtonShouldBeVisible
    || isOtherUserBlockingUI
    || isRewinding
    || isSyncing
    || isPlaying;

  const handleClick = () => {
    dispatch(playStreamIntent());
  };

  if (!playButtonShouldBeVisible) {
    return null;
  }

  return (
    <>
      <TeachingBubbles bubbles={playTeachingBubbles} />
      <HeaderBarButton
        tooltip={(
          <Trans t={t} i18nKey="actionBar:general.play" />
        )}
        onClick={handleClick}
        iconProps={{ iconName: 'Play' }}
        data-testid="play-resume-btn"
        // do not disable when new columns were selected
        disabled={isDisabled}
        shouldHideOnDisabled
      />
    </>
  );
};

export default PlayPauseResumeButton;
