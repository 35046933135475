export enum OutputViewType {
  'kanban' = 'kanban',
  'table' = 'table',
}

export interface StreamerOutputState {
  rowCount: number;
  rows: any[][];
  totalRowCount: number;
  isFetching: boolean;
  columnsAssetId: string;
  swimlanesAssetId: string;
  closedSwimlanes: any[];
  closedColumns: any[];
  view: OutputViewType;
  selectedCardKeys: number[];
  isSharePreviewOpen: boolean;
  forceOpenAllCards: boolean;
  enableTableTotals: boolean;
}
