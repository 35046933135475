import { isolatedStreamingStrategy } from './strategies/isolated/sagas';
import { CallableStrategy, StreamingStrategies } from './types';

export const STREAMING_STRATEGIES: Record<
  StreamingStrategies,
  CallableStrategy
> = {
  [StreamingStrategies.ISOLATED]: isolatedStreamingStrategy,
  [StreamingStrategies.EXCEL_TABLE]: () => null,
};
