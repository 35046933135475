import { mergeStyleSets } from '@fluentui/react';
import { OUTPUT_PANEL_BREAKPOINT_MEDIA_QUERY } from '../../../Streamer/screens/OutputPanel/constants';

export const classNames = mergeStyleSets({
  contentWrapInStreamer: {
    [`@media${OUTPUT_PANEL_BREAKPOINT_MEDIA_QUERY}`]: {
      display: 'grid',
      gridTemplateColumns: '390px 1fr',
    },
  },
});
