export enum AggregationType {
  Average = 'Average',
  Count = 'Count',
  CountDistinctExcludingNull = 'CountDistinctExcludingNull',
  CountDistinctIncludingNull = 'CountDistinctIncludingNull',
  Maximum = 'Maximum',
  Minimum = 'Minimum',
  None = 'None',
  Sum = 'Sum',
}
