import React from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Text } from '@fluentui/react';

import { deleteTopLevelEntity } from '../../actions';

import DeleteConfirmation from '../../../../pageTemplates/DeleteConfirmation';
import { routes } from '../../../../constants/routes';
import { classNames } from './styles';
import useItemSelections from '../../../../hooks/useItemSelections';

export interface EntityDeleteLocationState{
  goBackPage: string;
  entityName: string;
  deleteDescription: string;
  successCallback?: () => void
}

interface PageParams {
  schemaId: string;
  entityId: string
}

const TopLevelEntityDeletePage = () => {
  const { t } = useTranslation('designer');
  const { clear } = useItemSelections();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<EntityDeleteLocationState>();
  const pageParams = useParams<PageParams>();
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!location?.state?.goBackPage) {
      history.push(routes.designer.contentLibrary.designs);
    }
  }, []);

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteTopLevelEntity({
      ...pageParams,
      callback: () => {
        clear();
        if (location?.state?.successCallback) {
          location.state.successCallback();
        }
        history.push(location?.state?.goBackPage);
      },
    }));
  };

  return (
    <DeleteConfirmation
      entityName={location?.state?.entityName}
      deleteDescription={location?.state?.deleteDescription}
      goBackPage={location?.state?.goBackPage}
      handleDelete={handleDelete}
      isSyncing={isLoading}
    >
      <div className={classNames.textWrapper}>
        <Text
          className={cn(classNames.textStyles, classNames.headerStyles)}
          as="h3"
        >
          {t('deleteHeader')}
        </Text>
        <Text
          className={cn(classNames.textStyles, classNames.descriptionStyles)}
          as="p"
        >
          {location?.state?.deleteDescription}
        </Text>
      </div>
    </DeleteConfirmation>
  );
};

export default TopLevelEntityDeletePage;
