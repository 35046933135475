import * as effects from 'redux-saga/effects';
import * as actions from '../actions';
import * as filtersActions from '../../StreamFilters/actions';

export function* handleShouldReload() {
  yield effects.takeLatest(
    [
      actions.setItems.type,
      actions.addItem.type,
      actions.toggleGroupTableTotal.type,
      filtersActions.addStreamFilter.type,
      filtersActions.editStreamFilter.type,
    ],
    function* handler({ payload }: ReturnType<typeof actions.setItems>) {
      yield effects.put(actions.setShouldReload({
        datasetId: payload.datasetId,
        shouldReload: true,
      }));
    },
  );
}
