import { ITextFieldStyles, mergeStyleSets } from '@fluentui/react';
import { customStyles } from '../../../../../components/ui/SelectDropdown/customization';
import { baseColors } from '../../../../../theme';

export const getClassNames = (isSelected?: boolean) =>
  mergeStyleSets({
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      alignItems: 'center',
      padding: 5,
      selectors: {
        '&>div': {
          minWidth: 0,
        },
      },
    },
    headers: {
      fontWeight: 600,
    },
    span: {
      color: '#EE884E',
    },
    row: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      margin: '0 -15px',
      padding: '1px 5px 2px 10px',
      selectors: {
        '&>div:first-child': {
          width: '85px',
          flex: '0 0 85px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '@media(min-width: 450px)': {
            flex: '0 0 50%',
          },
        },
        '&>div:nth-child(2)': {
          flex: '1 1 auto',
          padding: '0 5px',
        },
        '&>div:nth-child(3)': {
          flex: '0 1 auto',
        },
      },
    },
    headerRow: {
      marginBottom: -12,
      marginTop: -12,
      backgroundColor: 'transparent!important',
      selectors: {
        '& button': {
          width: 32,
          height: 32,
        },
        '& button:hover': {
          backgroundColor: baseColors.hover,
        },
        '& button i': {
          fontSize: '16px!important',
        },
      },
    },
    rowButton: {
      width: 24,
      height: 25,
      color: baseColors.iconColor,
      selectors: {
        '&:hover': {
          backgroundColor: baseColors.white,
        },
      },
    },
    addButton: {
      color: baseColors.iconColor,
    },
    values: {
      textOverflow: 'ellipsis',
      overflow: isSelected ? 'initial' : 'hidden',
      whiteSpace: isSelected ? 'initial' : 'nowrap',
    },
    item: {
      backgroundColor: isSelected ? '#EDEBE9' : 'initial',
      selectors: {
        button: {
          visibility: isSelected ? 'visible' : 'hidden',
        },
        ':hover': {
          backgroundColor: isSelected ? '#EDEBE9' : '#F3F2F1',
          selectors: {
            button: {
              visibility: 'visible',
            },
          },
        },
      },
    },
    useOnce: {
      marginTop: 'auto',
      justifyContent: 'flex-end',
    },
  });

export const inCalloutTagStyles = {
  ...customStyles,
  clearIndicator: () => ({
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0px 2px 13px rgba(0, 0, 0,.08), 0px 2px 1px rgba(0, 0, 0,.05)',
    zIndex: 12,
    width: 'auto',
    minWidth: '100%',
  }),
};

export const inRowTagStyles = {
  ...inCalloutTagStyles,
  control: (base, state) => ({
    ...customStyles.control(base, state),
    fontSize: 12,
    minHeight: 24,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px',
    minHeight: '22px',
  }),
  multiValue: (provided) => ({
    ...provided,
    maxWidth: 65,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: 2,
  }),
  input: (provided) => ({
    ...provided,
    margin: '0 2px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0px 2px 13px rgba(0, 0, 0,.08), 0px 2px 1px rgba(0, 0, 0,.05)',
    zIndex: 12,
    width: 'auto',
    minWidth: '100%',
  }),
};

export const inRowNameStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    borderWidth: '1px !important',
    height: 24,
    selectors: {
      '&:after': {
        display: 'none',
      },
    },
  },
  field: {
    fontSize: 12,
  },
};

export const valuePickerStyles = {
  ...inRowTagStyles,
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px 0 0',
  }),
};

export const typedValuePickerStyles = {
  ...valuePickerStyles,
  indicatorsContainer: () => ({
    display: 'none',
  }),
};
