/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FilterForm, { Props as BaseProps } from '../../../../../components/FiltersForm';
import { adjustComparisonType } from '../../../../../utils/adjustFilterComparisonType';

const GroupPickerCallout: React.FC<BaseProps> = (props) => {
  const methods = useForm({
    defaultValues: adjustComparisonType(props.defaultValues),
    mode: 'onChange',
  });
  return (
    <FormProvider {...methods}>
      <FilterForm
        {...props}
      />
    </FormProvider>
  );
};

export default GroupPickerCallout;
