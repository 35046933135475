import { IDropdownOption } from '@fluentui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendToFileIntent } from '../../actions';
import { Strategy } from '../StreamToDatabase/useStreamToDatabase';
import { actionCreators as oldStreamerActionCreators } from '../../../Streams/actions';
import { StreamFileFormat } from '../../../../api/model/schemas/StreamFileFormat';
import { getSendToFileState } from '../../selectors';

const actionCreators = {
  [Strategy.ISOLATED]: sendToFileIntent,
  [Strategy.OLD_STREAMER]: oldStreamerActionCreators.sendToFileOldStreamer,
};

interface UseStreamToFile {
  strategy: Strategy;
  onQueued: () => void;
}

export const useStreamToFile = ({ strategy, onQueued }: UseStreamToFile) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>(null);
  const sendToFileState = useSelector(getSendToFileState);
  const { isLoading } = sendToFileState;

  const handleApprove = React.useCallback(async () => {
    const actionCreator = actionCreators[strategy];

    dispatch(actionCreator(selectedItem.key as StreamFileFormat));
    onQueued();
  }, [dispatch, selectedItem, strategy]);

  return {
    selectedItem,
    setSelectedItem,
    sendToFileState,
    handleApprove,
    isLoading,
  };
};
