import { createReducer } from '@reduxjs/toolkit';
import { IClientPreference } from '../../types/IClientPreferences';

import { getClientPreferences, createClientPreferences, editClientPreferences } from './actions';

export interface ClientPreferencesState {
  isLoading: boolean;
  isCreating: boolean;
  records: IClientPreference[];
}

export const initialState: ClientPreferencesState = {
  isLoading: false,
  isCreating: false,
  records: [],
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(getClientPreferences.pending, (state) => ({
    ...state,
    isLoading: true,
  }))
  .addCase(getClientPreferences.fulfilled, (state, action) => ({
    ...state,
    isLoading: false,
    records: action.payload,
  }))
  .addCase(getClientPreferences.rejected, (state) => ({
    ...state,
    isLoading: false,
  }))
  .addCase(createClientPreferences.pending, (state) => ({
    ...state,
    isCreating: true,
  }))
  .addCase(createClientPreferences.fulfilled, (state) => ({
    ...state,
    isCreating: false,
  }))
  .addCase(createClientPreferences.rejected, (state) => ({
    ...state,
    isCreating: false,
  }))
  .addCase(editClientPreferences.pending, (state) => ({
    ...state,
    isCreating: true,
  }))
  .addCase(editClientPreferences.fulfilled, (state) => ({
    ...state,
    isCreating: false,
  }))
  .addCase(editClientPreferences.rejected, (state) => ({
    ...state,
    isCreating: false,
  })));

export default reducer;
