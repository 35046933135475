import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataSourceProcessStatus } from '../../../../api/model/schemas/DataSourceProcessStatus';
import SortControl from '../../../Designer/components/SortControl';
import { setActiveDataSourceId } from '../../actions';
import useSelectedSources from '../../hooks/useSelectedSources';
import {
  getActiveDataSourceIds,
  getActiveDataSourceName,
  getDataSources,
  getSelectedDataSourceIds,
  getSelectedStream,
} from '../../selectors';

const DataSortControl: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { records: dataSources } = useSelector(getDataSources);
  const selectedStream = useSelector(getSelectedStream);
  const { selectedSourceIds } = useSelectedSources(selectedStream.id);
  const selectedSourcesIds = useSelector(getSelectedDataSourceIds);
  const activeDataSourceId = useSelector(getActiveDataSourceIds);
  const activeSourceName = useSelector(getActiveDataSourceName);

  const allSelectedSourceIds = useMemo(
    () => [...selectedSourceIds, ...selectedSourcesIds],
    [selectedSourceIds, selectedSourcesIds]
  );

  const filteredDataSources = useMemo(
    () =>
      dataSources
        .filter(
          (dataSource) =>
            dataSource.processStatus === DataSourceProcessStatus.Processed &&
            allSelectedSourceIds.includes(dataSource.id)
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    [dataSources, allSelectedSourceIds]
  );


  useEffect(() => {
    const filteredSourcesIds = filteredDataSources.map(
      (dataSource) => dataSource.id
    );

    if (!filteredSourcesIds.includes(activeDataSourceId)) {
      dispatch(setActiveDataSourceId(filteredDataSources?.[0]?.id));
    }
  }, [filteredDataSources]);

  const onChangeSource = (id: string) => {
    dispatch(setActiveDataSourceId(id));
  };

  return (
    <SortControl
      dataSources={filteredDataSources}
      onChangeSource={onChangeSource}
      selectedDataSourceId={activeDataSourceId}
      showDataSourceOptions
      title={activeSourceName}
    />
  );
};

export default DataSortControl;
