import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as appActions from '../modules/App/actions';
import { getIsEditingItem } from '../modules/App/selectors';

interface Parameters {
  shouldCleanupOnUmount?: boolean;
  onEditEndCallback?: (hasChanged?: boolean) => void;
}

export default ({ shouldCleanupOnUmount, onEditEndCallback }: Parameters) => {
  const dispatch = useDispatch();
  const setIsEditing = (value: boolean) =>
    dispatch(appActions.setEditingItem(value));

  const isEditing = useSelector(getIsEditingItem);

  const onEditStart = React.useCallback(() => setIsEditing(true), []);
  const onEditEnd = React.useCallback((hasChanged: boolean) => {
    setIsEditing(false);
    if (onEditEndCallback) {
      onEditEndCallback(hasChanged);
    }
  }, []);

  // always set editing to false when leaving page
  React.useEffect(
    () => () => {
      if (shouldCleanupOnUmount) {
        setIsEditing(false);
      }
    },
    []
  );

  return {
    isEditing,
    onEditStart,
    onEditEnd,
  };
};
