import React from 'react';
import { useSelector } from 'react-redux';
import SortControl from '../../../components/SortControl';
import { getSelectedSourceName } from '../../selectors';

const SourcesTitle = () => {
  const sourceName = useSelector(getSelectedSourceName);

  return (
    <SortControl title={sourceName} />
  );
};

export default SourcesTitle;
