import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { routes } from '../../../../constants/routes';

import Profile from '../../../User/screens/UserProfile';
import Preferences from '../Preferences';
import { classNames } from '../Preferences/styles';

const Settings = () => (
  <section className={classNames.section}>
    <Switch>
      <Route path={routes.settings.profile.index} component={Profile} />
      <Route path={routes.settings.preferences.index} component={Preferences} />
      <Route path={routes.settings.branding.index} component={() => <div />} />
      <Redirect to={routes.settings.profile.index} />
    </Switch>
  </section>

);

export default Settings;
