import {
  IButtonStyles, getTheme, mergeStyles, IStackStyles, IStackTokens,
} from '@fluentui/react';
import { baseColors } from '../../../theme';

const theme = getTheme();

export const containerClassName = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  flex: '1 1 auto',
  overflow: 'auto',
  backgroundColor: '#FFFFFF',
  selectors: {
    '&.syncing': {
      cursor: 'progress',
    },
  },
});

export const wrapperStyles: IStackStyles = {
  root: {
    overflow: 'hidden',
    flexGrow: 1,
    height: '100%',
  },
};

export const wrapperStackTokens: IStackTokens = {
  childrenGap: 5,
};

export const arrowBtnStyles = (isOpen: boolean): IButtonStyles => ({
  root: {
    height: '100%',
    padding: 0,
    width: 32,
    color: theme.palette.neutralPrimary,
    borderRadius: 0,
  },
  rootHovered: {
    color: 'inherit',
    backgroundColor: baseColors.hover,
  },
  rootPressed: {
    color: 'inherit',
    backgroundColor: baseColors.selected,
  },
  icon: {
    fontSize: '16px',
    color: baseColors.iconColor,
    backgroundColor: 'transparent',
    transform: isOpen ? 'rotateZ(-180deg)' : '',
    transition: 'transform .2s ease-in-out',
  },
});

type NameButtonStylesConfig = {
  disableHover?: boolean
  isColumnSelected?: boolean;
  isMapped?: boolean;
  isFullyMapped?: boolean;
  isDisabled?: boolean;
};

const hoverBgColor = (config?: NameButtonStylesConfig) => {
  if (config?.disableHover) {
    return 'transparent';
  }

  return (config?.isFullyMapped || config?.isColumnSelected)
    ? baseColors.selectedWithHover
    : baseColors.hover;
};

export const getNameBtnStyles = (config?: NameButtonStylesConfig):IButtonStyles => ({
  root: {
    flexGrow: 1,
    fontSize: '12px',
    paddingLeft: '2px',
    margin: 0,
    backgroundColor: config?.isColumnSelected || config?.isFullyMapped ? baseColors.midGrey : 'transparent',
    color: config?.isDisabled ? `${baseColors.iconColor}80` : config?.isMapped ? baseColors.orange : theme.palette.neutralPrimary,
    position: 'relative',
    maxWidth: 'calc(100% - 32px)',
  },
  rootHovered: {
    backgroundColor: hoverBgColor(config),
    color: config?.isMapped ? baseColors.orange : theme.palette.neutralPrimary,
    cursor: config?.disableHover ? 'auto' : 'pointer',
  },
  get rootDisabled() {
    return this.root;
  },
  get rootPressed() {
    return this.rootHovered;
  },
  flexContainer: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    selectors: {
      span: {
        fontWeight: 600,
      },
    },
  },
  label: {
    fontWeight: 600,
  },
});
