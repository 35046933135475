import { DRILLING_DIRECTIONS, PARENT_DRILLING } from '../../../modules/GroupTable/constants';
import { DrillingDirection, IGroupTable, ParentDrilling } from '../../../modules/GroupTable/types';
import { getGroupTableTitles, getTableHeaderLength } from '../../../modules/GroupTable/utils';
import { IDataset } from '../../../types/IDataset';
import {
  nestedSerializeObjectKeys, getCellColumnRowPair, columnNumberToLetters, columnLettersToNumber,
} from '../utils';

export interface IGetMatrixRangeAreas {
  direction: DrillingDirection,
  address: string,
  groupTable: IGroupTable,
  dataset: IDataset,
  gridSize: [number, number],
  worksheet: Excel.Worksheet,
}

export const generateMatrixRangeAreas = ({
  direction,
  address,
  groupTable,
  dataset,
  gridSize,
} : Omit<IGetMatrixRangeAreas, 'worksheet'>) => {
  let parentDrilling: ParentDrilling;
  let nodes: {};
  let factor: number;

  const { rowTitles, columnTitles } = getGroupTableTitles(groupTable.data);
  const [columnStart, rowStart] = getCellColumnRowPair(address);

  const columns = {
    first: columnLettersToNumber(columnStart),
    last: columnLettersToNumber(columnStart) + gridSize[1] - 1,
  };

  const rows = {
    first: +rowStart,
    last: +rowStart,
  };

  let type;

  if (direction === DRILLING_DIRECTIONS.rowDrilling) {
    parentDrilling = dataset.options.rowParentDrilling;
    nodes = rowTitles;
    factor = 1;

    const tableHeaderSize = getTableHeaderLength(groupTable);
    rows.first = +rowStart + tableHeaderSize;
    rows.last = rows.first;

    type = rows;
  }

  if (direction === DRILLING_DIRECTIONS.columnDrilling) {
    parentDrilling = dataset.options.columnParentDrilling;
    nodes = columnTitles;
    factor = Math.max(1, groupTable.values.length);

    columns.first += 1;
    columns.last = columns.first;

    type = columns;
  }

  const childrenFirst = parentDrilling === PARENT_DRILLING.after;
  const keys = nestedSerializeObjectKeys(nodes, childrenFirst);

  const rangeAreasAddresses: string[][] = [];

  for (let i = 0; i < keys.length; i += 1) {
    const level = keys[i].length - 1;
    if (keys[i].length === keys[i + 1]?.length) {
      type.last += factor;
    } else {
      const start = `${columnNumberToLetters(columns.first)}${rows.first}`;
      const end = `${columnNumberToLetters(columns.last + (factor - 1))}${rows.last}`;

      rangeAreasAddresses[level] = [
        ...(rangeAreasAddresses[level] || []),
        `${start}:${end}`,
      ];

      type.first = type.last + factor;
      type.last = type.first;
    }
  }

  return rangeAreasAddresses;
};

export const getMatrixRangeAreas = (params: IGetMatrixRangeAreas) => {
  const rangeAreasAddresses = generateMatrixRangeAreas(params);
  return rangeAreasAddresses.map((addresses) => params.worksheet.getRanges(addresses.join(',')));
};
