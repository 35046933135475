import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { selectors } from '../../../../Streams/Streams';
import { pauseStreamPlayingIntent } from '../../../../Streams/actions';
import { HeaderBarButton } from '../../../../../components/ui';

const PauseButton : React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isFullyMapped = useSelector(selectors.getIsDatasetFullyMapped);

  const { autoLoadAll: isPlaying } : {
    autoLoadAll: boolean,
  } = useSelector(selectors.getStreamsState);

  const handleClick = () => {
    dispatch(pauseStreamPlayingIntent());
  };

  React.useEffect(() => {
    if (isFullyMapped) {
      dispatch(pauseStreamPlayingIntent());
    }
  }, [isFullyMapped]);

  return (
    <HeaderBarButton
      tooltip={(
        <Trans t={t} i18nKey="actionBar:general.pause" />
      )}
      onClick={handleClick}
      iconProps={{ iconName: 'Pause' }}
      data-testid="play-resume-btn"
      disabled={!isPlaying}
      shouldHideOnDisabled
    />
  );
};

export default PauseButton;
