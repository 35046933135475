import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { IColumn, IColumnAggregationMethods } from '../../../../../types/IColumn';
import streamSelectors from '../../../selectors';
import {
  aggregationsToMenuItems,
  getAlreadyUsedAggregations,
  getInvalidAggregationMethods,
  getInvalidAggregationMethodsForGroupTable,
} from '../../../../../utils/itemsAggregation';
import { getAggregations } from '../../../../../constants/aggregations';
import { columnAggregationChangeIntent } from '../../../actions';
import {
  getIsCurrentGroupTableHasValueSelected,
  getSectionByType,
} from '../../../../GroupTable/selectors';
import { SECTION_TYPES } from '../../../../GroupTable/constants';
import { generateColumnUuid } from '../../../../ColumnsSequencing/utils';
import ButtonMenu from '../../../../../components/ui/ButtonMenu';
import { iconStyles } from './styles';
import { checkIfCountOfColumnAggregationsChanged } from '../../../utils';

interface Props {
  syncedItem: IColumn;
  isMatrixTable: boolean;
  isItemDisabled: boolean;
  isCurrentColumnSelected: boolean;
  onClickButton?(): void;
}

export const columnAggregationBtnWrapperClassName = 'column-aggregation-button';
export const menuOpenClassName = 'menu-open';

const ColumnAggregationButton = ({
  syncedItem,
  isMatrixTable,
  isCurrentColumnSelected,
  onClickButton,
}: Props) => {
  const dispatch = useDispatch();
  const [isMenuOpened, setMenuOpened] = React.useState(false);
  const AGGREGATIONS = getAggregations();
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);
  const groupTableValuesSection = useSelector(
    getSectionByType(SECTION_TYPES.values, currentDataset?.id),
  );
  const isAtLeastOneGroupTableValueSelected = useSelector(
    getIsCurrentGroupTableHasValueSelected,
  );

  const alreadyUsedAggregations = React.useMemo(() => getAlreadyUsedAggregations(
    currentDataset?.columns || [],
    syncedItem,
  ), [currentDataset?.columns, syncedItem]);

  const dataOnDemand = currentDataset?.options?.dataOnDemand;

  const checkIsLastInGroupTableValues = (
    aggregation: IColumnAggregationMethods,
  ) => {
    if (!isMatrixTable || groupTableValuesSection.length > 1) return false;

    const isInValues = groupTableValuesSection.some(
      (item) => item.columnUuid === generateColumnUuid({ ...syncedItem, aggregation }),
    );
    return groupTableValuesSection.length === 1 && isInValues;
  };

  const aggregationsToOmit = isMatrixTable
    ? getInvalidAggregationMethodsForGroupTable({
      column: syncedItem,
      hasAtLeastOneValueSelected: isAtLeastOneGroupTableValueSelected,
      isGroupTable: isMatrixTable,
    })
    : getInvalidAggregationMethods(syncedItem);

  const menuItems = aggregationsToMenuItems({
    aggregationsToOmit,
    getIsChecked: (currentAgg) => alreadyUsedAggregations.includes(currentAgg),
    getIsDisabled: checkIsLastInGroupTableValues,
    isAggregatingValuesAllowed: syncedItem.isAggregatingValuesAllowed,
  });

  const getActiveIconName = () => {
    let icon = 'MapLayers';

    if (alreadyUsedAggregations.length === 0) {
      icon = AGGREGATIONS[syncedItem.defaultAggregation].icon;
    }

    if (alreadyUsedAggregations.length === 1) {
      icon = AGGREGATIONS[alreadyUsedAggregations[0]].icon;
    }

    return icon;
  };

  const handleChangeItem = (key: string) => {
    dispatch(
      columnAggregationChangeIntent({
        column: syncedItem,
        aggregation: key as IColumnAggregationMethods,
        isGroupTable: isMatrixTable,
      }),
    );
  };

  const isButtonOrange = React.useMemo(() => {
    if (alreadyUsedAggregations.length === 1 && !currentDataset?.lastSuccessfulMapping?.columns) {
      return alreadyUsedAggregations[0] !== syncedItem.defaultAggregation;
    }

    return checkIfCountOfColumnAggregationsChanged({
      column: syncedItem,
      currentColumns: currentDataset?.columns,
      lastSuccessfullyMappedColumns: currentDataset?.lastSuccessfulMapping?.columns || [],
    });
  }, [alreadyUsedAggregations, currentDataset?.lastSuccessfulMapping?.columns, syncedItem]);

  const onMenuOpened = () => {
    setMenuOpened(true);

    // some business reasoning why we are doing this:
    // 1.Make it consistent with Selecting fields on Ingestion in Designer, where you select the field then change the aggregation.
    // 2.it avoids the white-on-white issue with the aggregation method button being in white and the row being in white.
    if (!isCurrentColumnSelected) {
      onClickButton();
    }
  };

  const onDismiss = () => {
    setMenuOpened(false);
  };

  return (
    <div
      className={cn(
        columnAggregationBtnWrapperClassName,
        { [menuOpenClassName]: isMenuOpened },
      )}
    >
      <ButtonMenu
        onChangeItem={handleChangeItem}
        defaultIconProps={{
          iconName: getActiveIconName(),
          styles: iconStyles,
        }}
        items={menuItems}
        isOrange={isButtonOrange}
        shouldDismissOnChange={dataOnDemand}
        onMenuOpened={onMenuOpened}
        onDismiss={onDismiss}
      />
    </div>
  );
};

export default ColumnAggregationButton;
