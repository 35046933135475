import {
  ContextualMenu,
  IContextualMenuItem,
  IconButton,
  Stack,
  IButtonStyles,
  DirectionalHint,
  TooltipDelay,
  Icon,
} from '@fluentui/react';
import React from 'react';

import TooltipHost from '../TooltipHost';

import {
  droplistStyles,
  mainDroplistButtonStyles,
  classNames,
  wrapperStyles,
  itemStyles,
  tooltipHostStyles,
  iconStyle,
  iconTooltipHostStyles,
} from './styles';
import { SlotPosition } from './types';

interface Props {
  handleClick: (
    ev:
    | React.MouseEvent<HTMLElement, MouseEvent>
    | React.KeyboardEvent<HTMLElement>,
    item: IContextualMenuItem
  ) => void;

  iconName: string;
  items: IContextualMenuItem[];
  valueInput?: React.ReactNode;
  buttonTestId?: string;
  wrapperStylesOverwrite?: IButtonStyles;
  isItemSelected?: boolean;
  isItemQueued?: boolean;
  tooltipLabel: string;
  tooltipDirectionalHint?: DirectionalHint;
  slotPosition: SlotPosition;
  mainDroplistButtonStylesOverride?: (...args: any[])=> IButtonStyles;
  keepDroplistOpenAfterClickHandler?: boolean
  shouldDisplayIconOnlyOnHover?: boolean
}

const DroplistWithButton: React.FC<Props> = ({
  handleClick,
  iconName,
  items,
  valueInput,
  buttonTestId,
  wrapperStylesOverwrite,
  isItemSelected,
  tooltipLabel,
  tooltipDirectionalHint,
  slotPosition,
  mainDroplistButtonStylesOverride,
  isItemQueued,
  keepDroplistOpenAfterClickHandler,
  shouldDisplayIconOnlyOnHover,
}) => {
  const iconBtnRef = React.useRef(null);
  const [isDroplistVisible, setDroplistVisible] = React.useState(false);

  const toggleDroplist = () => setDroplistVisible(!isDroplistVisible);

  const handleItemClick = (
    ev:
    | React.MouseEvent<HTMLElement, MouseEvent>
    | React.KeyboardEvent<HTMLElement>,
    item: IContextualMenuItem,
  ) => {
    // to not confuse drag & drop
    ev.preventDefault();
    handleClick(ev, item);
    if (!keepDroplistOpenAfterClickHandler) {
      setDroplistVisible(false);
    }
  };

  const styledMenuItems:IContextualMenuItem[] = items.map((item) => ({
    ...item,
    itemProps: {
      styles: itemStyles,
    },
  }));

  if (styledMenuItems.length === 0) {
    return (
      <TooltipHost
        content={tooltipLabel}
        delay={TooltipDelay.long}
        directionalHint={tooltipDirectionalHint || DirectionalHint.topCenter}
        styles={iconTooltipHostStyles}
        data-testid={buttonTestId}
      >
        <Icon iconName={iconName} style={iconStyle(isItemQueued)} />
      </TooltipHost>
    );
  }

  return (
    <Stack
      horizontal
      horizontalAlign="center"
      styles={
        wrapperStylesOverwrite
        || wrapperStyles({
          isItemSelected,
          isDroplistVisible,
        })
      }
    >
      <TooltipHost
        content={tooltipLabel}
        delay={TooltipDelay.long}
        directionalHint={tooltipDirectionalHint || DirectionalHint.topCenter}
        styles={tooltipHostStyles}
      >
        <div ref={iconBtnRef} className={classNames.btnWrap}>
          {valueInput || null}
          <IconButton
            styles={mainDroplistButtonStylesOverride ? mainDroplistButtonStylesOverride(isDroplistVisible) : mainDroplistButtonStyles({
              slotPosition, isItemQueued, isItemSelected, shouldDisplayIconOnlyOnHover, isDroplistVisible,
            })}
            data-is-focusable
            iconProps={{
              iconName,
            }}
            onClick={toggleDroplist}
            data-testid={buttonTestId}
          />
        </div>
      </TooltipHost>
      <ContextualMenu
        items={styledMenuItems}
        hidden={!isDroplistVisible}
        onDismiss={() => setDroplistVisible(false)}
        onItemClick={handleItemClick}
        target={iconBtnRef}
        styles={droplistStyles}
        isBeakVisible={false}
      />
    </Stack>
  );
};

export default DroplistWithButton;
