import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import { addNestedEntityRoute, deleteEntityRoute, editEntityRoute } from '../../../../../utils/routes';
import { getSelectedItemsIds } from '../../../../App/selectors';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { routes } from '../../../../../constants/routes';
import { getCalculationById } from '../../selectors';

const CalcsHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const selectedItems = useSelector(getSelectedItemsIds);
  const { selectedItem: selectedCalcId, clear } = useItemSelections();
  const selectedCalc = useSelector(getCalculationById(selectedCalcId));

  const selectedDesignID = useSelector(getSelectedDesignId);

  const handleEdit = () => {
    const path = editEntityRoute(
      TYPE_IDS.Calculation, selectedItems[0],
    );
    history.push(path, {
      initialValues: {
      },
    });
  };

  const handleAdd = () => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.Calculation,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesignID,
    });

    history.push(path, {
      typeName: 'Calculation',
    });
  };

  const handleDelete = React.useCallback(
    () => {
      const path = deleteEntityRoute(TYPE_IDS.Calculation, selectedCalcId);

      const goBackPage = routes.designer.catalog.calculations;

      history.push(path, {
        goBackPage,
        entityName: selectedCalc?.name,
        successCallback: clear,
        deleteDescription: (
          <Trans
            i18nKey="wizard:deleteNestedEntity"
            values={{ name: selectedCalc?.name, asset: t('entityTypes:Calculation') }}
          />
        ),
      });
    },
    [selectedCalcId],
  );

  return (
    <ItemActionsHeader
      isItemSelected={!!selectedItems?.[0]}
      handleEdit={handleEdit}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
    />
  );
};

export default CalcsHeader;
