import { SchemaFormatType } from './types/SchemaFormatType';

export const SCHEMA_FORMAT_TYPES: Record<SchemaFormatType, SchemaFormatType> = {
  Standard: 'Standard',
  Uri: 'Uri',
  Json: 'Json',
  LongTime: 'LongTime',
  ShortTime: 'ShortTime',
  LongDate: 'LongDate',
  ShortDate: 'ShortDate',
  LongDateTime: 'LongDateTime',
  ShortDateTime: 'ShortDateTime',
  Number: 'Number',
  Response: 'Response',
  State: 'State',
  Currency: 'Currency',
  Percentage: 'Percentage',
};

export const ENTITY_CHILDREN_KEY = '_children';
