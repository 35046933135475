import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import Error from '../../Error';
import {
  ComparisonPerDataType,
  ComparisonTypes,
} from '../../../../../../types/IStreamFilter';
import { IColumn, IColumnDataType } from '../../../../../../types/IColumn';
import { SelectDropdown } from '../../../../../../components/ui';
import { TYPE_IDS } from '../../../../../../constants/apiV4TypeIds';

interface Props {
  column: IColumn;
}

const ColumnSection: React.FC<Props> = ({ column }) => {
  const { t } = useTranslation('filters');
  const acceptOnlyTypedValues =
    column?.streamElementTypeId !== TYPE_IDS.StreamField &&
    column?.streamElementTypeId !== TYPE_IDS.StreamGroupAsset;

  const { control, errors } = useFormContext();

  const comparisonOptions = useMemo(() => {
    const comparisonTypes = ComparisonPerDataType(column?.dataType);

    return comparisonTypes
      .map((type) => ({
        value: type,
        label: t(type),
      }))
      .filter((comparison) => {
        if (
          // behavior of other data-types when typing values
          // is unexpected, so to avoid errors those will be hidden
          // this affects Hubs & Calcs - for which we cannot receive "values"
          acceptOnlyTypedValues &&
          ![
            IColumnDataType.Text,
            IColumnDataType.NumericDecimal,
            IColumnDataType.NumericInteger,
            IColumnDataType.DateTime,
          ].includes(column?.dataType)
        ) {
          return ![
            ComparisonTypes.In,
            ComparisonTypes.Equal,
            ComparisonTypes.NotEqual,
          ].includes(comparison.value);
        }
        return true;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [column, acceptOnlyTypedValues]);

  if (!column) {
    return null;
  }

  return (
    <>
      <Controller
        render={({ onChange, value }) => (
          <SelectDropdown
            onChange={(option) => {
              onChange(option.value);
            }}
            isMulti={false}
            // without this ternary condition
            // react-select does not render placeholder
            // but tries to render label for `null` value
            value={value ? { value, label: t(value) } : null}
            label={t('Comparison')}
            options={comparisonOptions}
            className="filter-comparison-select"
          />
        )}
        name="comparison"
        control={control}
        rules={{ required: true }}
      />
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="comparison"
        message={t('required')}
      />
    </>
  );
};

export default ColumnSection;
