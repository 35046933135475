import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { QueryOptions } from 'odata-query';
import omit from 'lodash/omit';
import { EntityWithParent } from '../../../api/types';
import { SelectionType } from '../../../components/ui/ViewControlButton';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { createNestedEntity, fetchEntities, patchEntity } from '../api';
import {
  ApiV4ResponseWrapper,
  EntityIngestionStatus,
  IDesignSourceEntity,
  IDesignSourceEntityField,
  UIEnhancedDesignSourceEntityField,
} from '../types';

import * as api from './api';
import { ProcessMachineLearningType } from '../../../api/model/schemas/ProcessMachineLearningType';
import { SmartFlowOperations } from './components/DataTopHeader/constants';

export const fetchDataSources = createAsyncThunk(
  'DESIGNER/CATALOG/FETCH_DESIGN_DATA_SOURCES',
  async (designId: string) => {
    const { data: responseData } = await api.fetchDataSources(designId)();

    return responseData.data;
  }
);

export const fetchCalculations = createAsyncThunk(
  'DESIGNER/CATALOG/FETCH_DESIGN_CALCULATIONS',
  async (designId: string) => {
    const { data: responseData } = await api.fetchCalculations(designId)();

    return responseData.data;
  }
);

export const fetchMachineLearningAssets = createAsyncThunk(
  'DESIGNER/CATALOG/FETCH_DESIGN_ML_ASSETS',
  async (designId: string) => {
    const { data: responseData } = await api.fetchMachineLearningAssets(
      designId
    )();

    return responseData.data;
  }
);

export const fetchHubs = createAsyncThunk(
  'DESIGNER/CATALOG/FETCH_DESIGN_HUBS',
  async (designId: string) => {
    const { data: responseData } = await api.fetchHubs(designId)();

    return responseData.data;
  }
);

export const fetchGroups = createAsyncThunk(
  'DESIGNER/CATALOG/FETCH_DESIGN_GROUPS',
  async (designId: string) => {
    const { data: responseData } = await api.fetchGroups(designId)();

    return responseData.data;
  }
);

export const setSelectedSourceId = createAction<
  string,
  'DESIGNER/CATALOG/SET_SELECTED_SOURCE_ID'
>('DESIGNER/CATALOG/SET_SELECTED_SOURCE_ID');

export const setCurrentSelectionType = createAction<
  SelectionType,
  'DESIGNER/CATALOG/SET_CURRENT_SELECTION_TYPE'
>('DESIGNER/CATALOG/SET_CURRENT_SELECTION_TYPE');

export const setCurrentFilter = createAction<
  string,
  'DESIGNER/CATALOG/SET_CURRENT_FILTER'
>('DESIGNER/CATALOG/SET_CURRENT_FILTER');

export const setSelectedEntityId = createAction<
  string,
  'DESIGNER/CATALOG/SET_CURRENT_SELECTED_ENTITY_ID'
>('DESIGNER/CATALOG/SET_CURRENT_SELECTED_ENTITY_ID');

export const setFieldEntities = createAction<
  IDesignSourceEntity[],
  'DESIGNER/CATALOG/SET_FIELD_ENTITIES'
>('DESIGNER/CATALOG/SET_FIELD_ENTITIES');

export const setCurrentPage = createAction<
  string,
  'DESIGNER/CATALOG/SET_CURRENT_PAGE'
>('DESIGNER/CATALOG/SET_CURRENT_PAGE');

export const fetchDataSourceFields = createAsyncThunk(
  'DESIGNER/CATALOG/FETCH_SOURCE_ENTITY_FIELDS',
  async (dataSourceId: string, { dispatch }) => {
    const oDataParams: Partial<QueryOptions<unknown>> = {
      filter: `parent/parentId eq ${dataSourceId} and (ingestionStatus eq '${EntityIngestionStatus.Processed}' or ingestionStatus eq '${EntityIngestionStatus.PendingProcess}')`,
      expand: 'parent',
    };
    const {
      data: { data: fieldsWithParent },
    } = await fetchEntities<
      ApiV4ResponseWrapper<
        EntityWithParent<IDesignSourceEntityField, IDesignSourceEntity>[]
      >
    >(TYPE_IDS.SourceEntityField)(oDataParams);

    const entities: IDesignSourceEntity[] = fieldsWithParent.map((field) => ({
      ...field.parent,
    }));

    dispatch(setFieldEntities(entities));

    const entityFields = fieldsWithParent.reduce<
      UIEnhancedDesignSourceEntityField[]
    >((accumulator, curr) => {
      const parentEntityName = curr.parent.name;

      const plainField: IDesignSourceEntityField = omit(curr, 'parent');

      const field: UIEnhancedDesignSourceEntityField = {
        ...plainField,
        parentEntityName,
      };

      return [...accumulator, field];
    }, []);

    return entityFields;
  }
);

type SmartCatalogOperation = 'On' | 'Reset';
type TriggerSmartCatalogParams = {
  dataSourceId: string;
  operation: SmartCatalogOperation;
};

export const triggerSmartCatalog = createAsyncThunk(
  'DESIGNER/CATALOG/TRIGGER_SMART_CATALOG',
  async (
    { dataSourceId, operation }: TriggerSmartCatalogParams,
    { dispatch }
  ) => {
    await createNestedEntity({
      parentEntityId: dataSourceId,
      typeId: TYPE_IDS.CalculateEntityDisplayNamesRequest,
    })({ operation });

    dispatch(fetchDataSourceFields(dataSourceId));

    return { dataSourceId, operation };
  }
);

export interface TriggerSmartCatalog {
  dataSourceId: string;
  operation: SmartFlowOperations;
}

export const triggerSmartFlow = createAsyncThunk(
  'DESIGNER/CATALOG/TRIGGER_SMART_FLOW',
  async ({ dataSourceId, operation }: TriggerSmartCatalog, { dispatch }) => {
    try {
      await createNestedEntity({
        parentEntityId: dataSourceId,
        typeId: TYPE_IDS.SmartFlowsRequest,
      })({
        operation,
      });
      dispatch(fetchDataSourceFields(dataSourceId));
    } catch (e) {
      console.error(e);
    }
    return { dataSourceId, operation };
  }
);

export const updateEntity = createAsyncThunk(
  'DESIGNER/CATALOG/UPDATE_ENTITY',
  async ({ typeId, entity }: { typeId: string; entity: any }) =>
    patchEntity(typeId)(entity)
);

export const configureSmartGroupsJobIntent = createAction<
  {
    entityId: string;
    onQueued: () => void;
  },
  'DESIGNER/CATALOG/CONFIGURE_SMART_GROUP'
>('DESIGNER/CATALOG/CONFIGURE_SMART_GROUP');

export const configureMachineLearningIntent = createAction<
  {
    entityId: string;
    onQueued: () => void;
    processType: ProcessMachineLearningType;
  },
  'DESIGNER/CATALOG/CONFIGURE_ML_ASSET'
>('DESIGNER/CATALOG/CONFIGURE_ML_ASSET');
