import * as effects from 'redux-saga/effects';
import { mapDatasetAsMatrix } from '../../../actions/excel-actions/matrix/mapAsMatrix';
import { fetchTotalsIntersection } from '../../../actions/excel-actions/utils';
import { IDataset } from '../../../types/IDataset';
import * as selectors from '../selectors';
import fetchGroupTableData from './fetchGroupTableData';
import { fetchGroupTableTotal } from './fetchGroupTableTotal';
import * as actions from '../actions';
import { checkWorkbookSelection, cancelCurrentOperation } from '../../Streams/actions';
import { getRenderableTotals } from '../utils';
import { GroupTableTotals, IGroupedRow, IGroupTable } from '../types';

export function* renderDatasetAsMatrix(
  dataset: IDataset,
) {
  const { id: datasetId } = dataset;

  // STEP 1: Fetch data required to map the table body

  const {
    data,
    crossTotals,
    ...totals
  } : {
    data: IGroupedRow,
    rowTotalsData: IGroupedRow,
    columnTotalsData: IGroupedRow,
    crossTotals: GroupTableTotals['crossTotals']
  } = yield effects.all({
    data: effects.call(fetchGroupTableData, { dataset }),
    rowTotalsData: effects.call(fetchGroupTableTotal, dataset),
    columnTotalsData: effects.call(fetchGroupTableTotal, dataset, true),
    crossTotals: effects.call(fetchTotalsIntersection, dataset),
  });

  // STEP 1.1: If fetching is cancelled, end operation;

  if ([data, totals.rowTotalsData, totals.columnTotalsData].some((res) => !res)) {
    return;
  }

  // STEP 2: Assign fetched data to the groupTable

  const groupTable: IGroupTable = {
    ...selectors.getGroupTable(datasetId)(yield effects.select()),
    data,
  };

  // STEP 3: Turn fetched totals data into renderable arrays

  const rowTotals = getRenderableTotals({
    src: totals.rowTotalsData,
    table: groupTable,
    dataset,
  });

  const columnTotals = getRenderableTotals({
    src: totals.columnTotalsData,
    table: groupTable,
    dataset,
    isColumnsTotal: true,
  });

  // STEP 4: Map the fetched data into the worksheet.

  const task = yield effects.fork(
    mapDatasetAsMatrix,
    groupTable,
    dataset,
    {
      rowTotals,
      columnTotals,
      crossTotals,
    },
  );

  // STEP 4.1: Handle cancelling mapping.

  const [cancelled, { tableId }] = yield effects.race([
    effects.take(cancelCurrentOperation.type),
    effects.join(task),
  ]);

  if (cancelled) {
    return;
  }

  // STEP 5: Update dataset with the new data

  yield effects.put(actions.setTable({
    datasetId,
    table: groupTable,
  }));

  yield effects.put({
    type: 'EDIT_DATASET',
    id: datasetId,
    properties: {
      tableId,
    },
  });

  // STEP 6: Since the dataset has been created/reloaded, we update that state

  yield effects.put(actions.setShouldReload({
    datasetId,
    shouldReload: false,
  }));

  // STEP 7: Check the current selected range in the workbook, to update add-in context
  if (tableId) {
    yield effects.put(checkWorkbookSelection());
  }
}
