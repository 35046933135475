import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asyncDebounce } from '../../../../../../shared/utils/async-debounce';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSearchQuery } from '../../../../Search/selectors';
import { createNestedEntity } from '../../../api';
import { IDesignSourceEntity } from '../../../types';
import { fetchFieldsForEntitySource } from '../../api';
import {
  entityFieldsLoaded,
  patchFieldsTriggered,
  PatchFieldsTriggeredPayload,
} from '../../screens/Data/actions';
import { makeGetFieldListSorted } from '../../screens/Data/selectors';

type Params = {
  entity: IDesignSourceEntity;
};

export enum Operation {
  NoChange = 'NoChange',
  Include = 'Include',
  Exclude = 'Exclude',
}

export const useFetchSourceFields = ({ entity }: Params) => {
  // DEPS
  const cancelSource = React.useRef(axios.CancelToken.source());
  const getFieldListSorted = React.useMemo(makeGetFieldListSorted, []);
  const getEntityFieldsSorted = React.useCallback(
    (state) => getFieldListSorted(state, entity.id),
    [entity.id, getFieldListSorted]
  );

  // HOOKS
  const dispatch = useDispatch();

  // STATE
  // NOTE: each component is responsible for handling it's own "fetching" state (when expanding/refreshing an entity)...
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  // NOTE: ...but the fields need to be stored in a shared state so both the search managed in the main view as well as
  // the per-entity refresh work together
  const entityFields = useSelector(getEntityFieldsSorted);
  const searchQuery = useSelector(getSearchQuery);

  // CALLBACKS
  interface LoadFieldsArgs {
    operation?: Operation;
    patchedFieldId?: string;
  }
  const loadFields = React.useCallback(
    async ({ operation, patchedFieldId }: LoadFieldsArgs = {}) => {
      setIsLoading(true);
      try {
        // we need to load the fields before fetching them, but the same request is also used to perform
        // a field's state transition (whether or not it should be ingested or not)
        if (!entity.fieldsLoaded || operation) {
          await createNestedEntity({
            parentEntityId: entity.id,
            typeId: TYPE_IDS.LoadEntityFieldsRequest,
          })(
            {
              loadOperation: 'Load',
              operation: operation || Operation.NoChange,
            },
            undefined,
            cancelSource.current.token
          );
        }

        const {
          data: { data: fields },
        } = await fetchFieldsForEntitySource(entity.id)(
          patchedFieldId
            ? {
                filter: `id eq ${patchedFieldId}`,
              }
            : undefined,
          cancelSource.current.token
        );

        dispatch(
          entityFieldsLoaded({
            // casting because `IDesignSourceEntityField` is out of date compared to `DataSourceEntityField`
            fields: fields as any,
          })
        );

        // NOTE: set the "fully loaded" state only if we fetched all the fields and not just the updated one
        if (!patchedFieldId) setIsLoaded(true);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, entity.fieldsLoaded, entity.id]
  );

  const reloadFields = React.useCallback(
    (patchedFieldId?: string) => {
      // NOTE: not sure how to cancel the debounced call if `reloadFields` gets redefined
      // also keep in mind this is triggered after the `PATCH` request finishes so it depends on the distance between
      // the time the consecutive requests end and not between the time that the user clicks the fields
      asyncDebounce(loadFields, 750)({ patchedFieldId });
    },
    [loadFields]
  );

  const patchEntityField = React.useCallback(
    async (args: PatchFieldsTriggeredPayload) => {
      await dispatch(patchFieldsTriggered(args));
      reloadFields(args.fieldId);
    },
    [dispatch, reloadFields]
  );

  // EFFECTS
  const onMount = React.useCallback(() => {
    // don't try to fetch fields on mount if they haven't been loaded yet or if we're displaying search results
    // as then those should be fetched only on demand
    if (!entity.fieldsLoaded || searchQuery) return undefined;
    loadFields();

    return () => {
      cancelSource.current.cancel();
    };
  }, [entity.fieldsLoaded, loadFields, searchQuery]);
  React.useEffect(onMount, [onMount]);

  // RESULT
  return {
    entityFields,
    isLoaded,
    isLoading,
    loadEntityFields: loadFields,
    patchEntityField,
  };
};
