import React from 'react';
import cn from 'classnames';

import Icon from '@mdi/react';
import * as mdiJs from '@mdi/js';

import { classNames } from './styles';
import './style.css';

interface Props {
  iconName: string;
  isDisabled?: boolean;
  isSelected: boolean;
  onClick?: () => void;
}

const index = ({
  iconName,
  isDisabled = false,
  isSelected,
  onClick,
}: Props) => (
  <button
    disabled={isDisabled}
    type="button"
    className={cn(classNames.btnWrap, {
      [classNames.btnSelected]: isSelected,
      [classNames.btnSelectedHoverable]:
        isSelected && typeof onClick === 'function',
    })}
    onClick={onClick ?? null}
    data-title={iconName}
  >
    <Icon
      path={
        Object.keys(mdiJs).includes(iconName)
          ? mdiJs[iconName]
          : mdiJs.mdiDatabaseOutline
      }
      className={classNames.icon}
    />
  </button>
);

export default index;
