import React from 'react';
import { IShare } from '../../types';
import { FetchShareFunction } from './types';

type Params = {
  fetchFunction: FetchShareFunction,
  parentEntityId: string
};

export const useGetEntityShareObjects = (
  {
    fetchFunction,
    parentEntityId,
  }:Params,
) => {
  const [isLoading, setLoading] = React.useState(false);
  const [hasError, setError] = React.useState(false);
  const [shareEntities, setShareEntities] = React.useState<IShare[]>([]);

  React.useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const resp = await fetchFunction(parentEntityId)();

        setShareEntities(resp.data.data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { isLoading, shareEntities, hasError };
};
