import { TypeProperty } from '../../../model/schemas/TypeProperty';
import { TypePropertySection } from '../../../model/schemas/TypePropertySection';
import { PropertyDataType } from '../../../model/schemas/PropertyDataType';
import { TYPE_IDS_TO_NAMES } from '../../../../constants/apiV4TypeIds';
import i18n from '../../../../config/i18n';
import {
  supportedCalculationTypes,
  WeekCalculationTypeId,
  WeekCalculationTypePropertyId,
  WeekdayCalculationTypeId,
  WeekdayCalculationTypePropertyId,
} from './constants';
import { translateApiName } from '../../../../config/i18n/utils';

interface TranslateValidValuesArgs {
  typeProperty: TypeProperty;
  typePropertySection: TypePropertySection;
}
export const translateValidValues = ({
  typeProperty,
  typePropertySection,
}: TranslateValidValuesArgs): string[] | undefined => {
  if (typeProperty.dataType === PropertyDataType.Guid) {
    return typeProperty.interaction?.validValues?.map((validValue) =>
      i18n.t(`entityTypes:${TYPE_IDS_TO_NAMES[String(validValue)]}`)
    );
  }

  if (typePropertySection.parentId === WeekdayCalculationTypeId) {
    return typeProperty.interaction?.validValues?.map((validValue) =>
      i18n.t(
        `api:manualTranslations:entities:${WeekdayCalculationTypeId}:typeProperties:${WeekdayCalculationTypePropertyId}:interaction:validValues:${validValue}`
      )
    );
  }

  if (typePropertySection.parentId === WeekCalculationTypeId) {
    return typeProperty.interaction?.validValues?.map((validValue) =>
      i18n.t(
        `api:manualTranslations:entities:${WeekCalculationTypeId}:typeProperties:${WeekCalculationTypePropertyId}:interaction:validValues:${validValue}`
      )
    );
  }

  return undefined;
};

interface TranslatePropertyNameArgs {
  typeProperty: TypeProperty;
  typePropertySection: TypePropertySection;
}
export const translatePropertyName = ({
  typeProperty,
  typePropertySection,
}: TranslatePropertyNameArgs) => {
  // for CalculationType interactions we translate the interaction names based on their guids
  // which are static for the built-in calculation types
  if (supportedCalculationTypes.includes(typePropertySection.parentId)) {
    return translateApiName(typePropertySection.parentId, typeProperty.name);
  }

  return translateApiName(typePropertySection.$parentTypeId, typeProperty.name);
};
