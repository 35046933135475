import axios from "axios";
import React from "react";

export const useCancelToken = () => {
  // REFS
  const sourceRef = React.useRef(axios.CancelToken.source());

  // EFFECTS
  const onDismount = React.useCallback(
    () => () => {
      sourceRef.current.cancel();
    },
    [],
  );
  React.useEffect(onDismount, [onDismount]);

  // RESULT
  return sourceRef.current.token;
};
