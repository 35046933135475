import * as React from 'react';
import { Text, Stack, ITextStyles, Icon, getTheme } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import PrimaryButton from '../../../../components/ui/PrimaryButton';
import { ErrorFormRaw } from '../../../Help/screens/ErrorForm/ErrorFormRaw';

const textStyles: ITextStyles = {
  root: {
    textAlign: 'center',
    margin: '0 0 20px',
    maxWidth: '80%',
  },
};

interface Props {
  errorDetails: string;
}

const FatalError: React.FC<Props> = ({ errorDetails }) => {
  const [isErrorFormVisible, setFormVisible] = React.useState(false);
  const { t } = useTranslation('fatalError');
  const theme = getTheme();

  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      style={{ flex: '1 1 auto', background: theme.palette.themeLighterAlt }}
    >
      <Icon
        iconName="error"
        style={{ fontSize: '50px', marginBottom: '20px' }}
      />
      <Text as="h1" variant="large" styles={textStyles}>
        {t('title')}
      </Text>
      <Text
        as="p"
        variant="medium"
        styles={textStyles}
        style={{ fontWeight: 300 }}
      >
        {t('subtitle')}
      </Text>
      <Stack
        tokens={{
          childrenGap: 25,
          padding: 10,
        }}
        horizontalAlign="center"
        verticalAlign="center"
      >
        <PrimaryButton
          onClick={() => {
            window.self.location.reload();
          }}
        >
          {t('reload')}
        </PrimaryButton>
        {!isErrorFormVisible && (
          <PrimaryButton
            onClick={() => {
              setFormVisible(true);
            }}
          >
            {t('report')}
          </PrimaryButton>
        )}
        {isErrorFormVisible && (
          <ErrorFormRaw prefilErrorDetails={errorDetails} />
        )}
      </Stack>
    </Stack>
  );
};

export default FatalError;
