import {
  IToggleStyles, IStackStyles,
} from '@fluentui/react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../theme';

export const getStyles = () => ({
  singleContainer: {
    border: `1px solid ${baseColors.outlineGray}`,
    height: 'calc(100% / 3)',
    flexShrink: '0!important',
    borderRadius: BORDER_RADIUS_IN_PX,
  },
  itemsContainer: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
  sidebar: {
    position: 'relative',
    borderRight: `1px solid ${baseColors.outlineGray}`,
  },
  labelWrap: {
    flexShrink: '0!important',
  },
  label: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    color: baseColors.outlineGray,
    textTransform: 'uppercase',
  },
  toggleStack: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    height: 16,
    width: 16,
    marginLeft: 3,
  },
});

export const toggleStyles: Partial<IToggleStyles> = {
  root: {
    margin: 0,
  },
  label: {
    order: 'initial',
    marginRight: 8,
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 'normal',
    paddingTop: 2,
    paddingBottom: 2,
  },
  pill: {
    marginRight: 8,
    height: 16,
    width: 32,
    paddingLeft: 2,
    paddingRight: 2,
  },
  thumb: {
    fontSize: 10,
    width: 10,
    height: 10,
  },
};

export const footerStackStyles: IStackStyles = {
  root: {
    borderTop: `1px solid ${baseColors.outlineGray}`,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '0 0 24px',
    minHeight: 24,
    backgroundColor: baseColors.hover,
    borderBottomLeftRadius: BORDER_RADIUS_IN_PX,
    borderBottomRightRadius: BORDER_RADIUS_IN_PX,
  },
};

export const scrollAreaStyles: IStackStyles = {
  root: {
    flex: 1,
    maxHeight: 'calc(100% - 16px)',
  },
};

export const rightStackStyles: IStackStyles = {
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
};
