/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Callout, { Props as BaseProps } from '../../../../../components/ui/InformationDialog';
import { IEntityFormChildren, IEntityFormSection } from '../../types';
import Property from '../Property';
import { translateApiName } from '../../../../../config/i18n/utils';
import { IEndpoint } from './MultiEnpointSelector';
import { UseManageMultipleEditItem } from '../ManageMultiple/hooks/useManageMultiple';

interface Props {
  calloutProps: BaseProps
  schema: IEntityFormSection
  item: IEntityFormChildren<IEndpoint>
  handleEditItem: UseManageMultipleEditItem<IEndpoint>
}

const MultiSelectorCallout: React.FC<Props> = ({
  calloutProps: { ...calloutProps },
  schema,
  item,
  handleEditItem,
}) => {
  const { t } = useTranslation();

  const typeId = schema?.parentId;
  const submitRef = useRef(null);
  const methods = useForm({
    mode: 'onChange',
  });
  const { handleSubmit, watch } = methods;

  const watched = watch(undefined, item?.data);

  const onSubmit = async (data : IEndpoint) => {
    await handleEditItem(item.id, data);
    calloutProps.onClose();
  };

  return (
    <Callout
      {...calloutProps}
      onApprove={() => submitRef.current.click()}
      title={t('wizard:Parameters')}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="callout_form">
          {schema?.properties
              .map((property) => (
                <Property
                  name={property.name}
                  label={translateApiName(typeId, property.name)}
                  watched={watched}
                  key={property.id}
                  property={property}
                  currentForm={{
                    typeId: schema.id,
                    data: item?.data,
                    sections: [],
                  }}
                  currentEntity={null}
                  translationPrefix={`api:types:${typeId}`}
                />
              ))}
          <input style={{ visibility: 'hidden' }} ref={submitRef} type="submit" form="callout_form" />
        </form>
      </FormProvider>
    </Callout>
  );
};

export default MultiSelectorCallout;
