import {
  applyMiddleware, createStore, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from 'redux-saga';
import initialState from './initialState';
import { officeSettingsMiddleware } from './persistence';
import { persistSettings } from './utils';
import rootReducer from './rootReducer';
import { sentryReduxEnhancer } from '../config/sentry';

export const sagaMiddleware = createSagaMiddleware();

export const configureBasicStore = (preloadedState = {}) => {
  const enhancer = compose(composeWithDevTools(applyMiddleware(
    thunk,
    officeSettingsMiddleware,
    sagaMiddleware,
  )), sentryReduxEnhancer);

  const store = createStore(
    rootReducer,
    preloadedState,
    enhancer,
  );

  return store;
};

export default async function configureStore() {
  const enhancer = composeWithDevTools(applyMiddleware(
    thunk,
    officeSettingsMiddleware,
    sagaMiddleware,
  ));

  const state = await initialState();

  const store = createStore(
    rootReducer,
    state,
    enhancer,
  );

  store.subscribe(() => {
    persistSettings(store.getState().streams.settings);
  });

  return store;
}
