import { IStackStyles, mergeStyleSets } from '@fluentui/react';

export const classNames = mergeStyleSets({
  container: {
    overflowY: 'auto',
    maxHeight: '98%',
    padding: 8,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    gridGap: '8px',
  },
  loadingWrapper: {
    overflowY: 'auto',
    maxHeight: '100%',
    padding: 4,
    flex: '1 1 auto',
  },
});

export const emptyMessageStyles: IStackStyles = {
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
};
