import { ITextFieldStyles, mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../../theme';

export const getClassNames = (hasOpenCallout?: boolean, isSelected?: boolean) => mergeStyleSets({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    padding: 5,
  },
  headers: {
    fontWeight: 600,
  },
  span: {
    color: '#EE884E',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    height: '28px',
    margin: '0 -14px',
    padding: '0px 10px',
    gridTemplateColumns: '1.3fr 1fr 1fr',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    display: 'grid',
    backgroundColor: isSelected ? baseColors.selected : 'transparent',
    selectors: {
      button: {
        visibility: hasOpenCallout ? 'visible' : 'hidden',
      },
      ':hover': {
        selectors: {
          button: {
            visibility: 'visible',
          },
          'button:hover': {
            backgroundColor: isSelected ? baseColors.hover : baseColors.selectedWithHover,
          },
        },
      },
      '&.item': {
        selectors: {
          ':hover': {
            backgroundColor: isSelected ? baseColors.selectedWithHover : baseColors.hover,
          },
          '&>div:last-of-type': {
            display: 'flex',
            justifyContent: 'flex-end',
          },
          '&>div:first-of-type': {
            paddingRight: 8,
          },
        },
      },
    },
  },
  btn: {
    color: baseColors.iconColor,
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnActive: {
    backgroundColor: baseColors.selectedWithHover,
  },
});

export const textFieldStyles : Partial<ITextFieldStyles> = {
  fieldGroup: {
    borderRadius: 2,
    height: 24,
  },
};
