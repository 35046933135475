import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import {
  IconButton,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { Error } from '../../types';

import TooltipHost from '../../../../components/ui/TooltipHost';

import * as styles from './styles';

const CopyErrorDetailsButton: React.FC<Error> = (props) => {
  const [hasCopied, setCopied] = useState(false);
  const { t } = useTranslation('errors');

  useEffect(() => {
    let id;

    if (hasCopied) {
      id = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }

    return () => id && clearTimeout(id);
  }, [hasCopied]);

  const handleCopy = () => {
    copy(JSON.stringify(props));
    setCopied(true);
  };

  return (
    <>
      <TooltipHost
        content={hasCopied ? t('copyDetailsSuccess') : t('copyToClipboard')}
      >

        <IconButton
          data-testid="copy-error-details"
          styles={styles.copyButtonStyles}
          iconProps={{ iconName: hasCopied ? 'DocumentApproval' : 'Copy' }}
          onClick={handleCopy}
        />
      </TooltipHost>
    </>
  );
};

export default CopyErrorDetailsButton;
