import React from 'react';
import { useFetchSourceFields } from './useFetchSourceFields';

export interface EntityContextValue {
  patchEntityField: ReturnType<typeof useFetchSourceFields>['patchEntityField'];
}
const defaultValue: EntityContextValue = {
  patchEntityField: () => null,
};

export const EntityContext = React.createContext<EntityContextValue>(
  defaultValue,
);
