import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../modules/Designer/types";
import { parseDataSourceEntityField, isDataSourceEntityFieldResponse } from "../types/DataSourceEntityField";
import { mutablyParseResponseData, mutablyParseResponseDataArray } from "../utils";
import { isTypePropertyResponse, parseTypeProperty } from "../types/TypeProperty";
import { isConnectorConfigurationTypesResponse, parseConnectorConfigurationType } from "../types/ConnectorConfigurationType";
import { isUsersResponse, parseUser } from "../types/User";
import { isTransformConfigurationTypesResponse, parseTransformConfigurationType } from "../types/TransformConfigurationType";

export const mutablyParseEntityResponse = (response: AxiosResponse<unknown>) => {
  if (isDataSourceEntityFieldResponse(response)) return mutablyParseResponseData(response, parseDataSourceEntityField);
  if (isTypePropertyResponse(response)) return mutablyParseResponseData(response, parseTypeProperty);

  return response;
}

export const mutablyParseEntitiesResponse = (response: AxiosResponse<ApiV4ResponseWrapper<unknown>>) => {
  if (isConnectorConfigurationTypesResponse(response))
    return mutablyParseResponseDataArray(response, parseConnectorConfigurationType);
  if (isTransformConfigurationTypesResponse(response))
    return mutablyParseResponseDataArray(response, parseTransformConfigurationType);
  if (isUsersResponse(response))
    return mutablyParseResponseDataArray(response, parseUser);

  return response;
}
