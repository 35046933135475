import React from 'react';

import { wrapperStyles } from './styles';
import EykoSVG from './Eyko.svg';

const EykoSidebarLogo = () => (
  <div style={wrapperStyles}>
    <EykoSVG />
  </div>
);

export default EykoSidebarLogo;
