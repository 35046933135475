import React, { FunctionComponent } from 'react';
import { Icon, Stack, Text } from '@fluentui/react';

import { IFoldableListItem } from './types/IFoldableListGroup';

import { foldableItemStyles, itemTextStyles } from './styles';

interface FoldableListItemProps extends IFoldableListItem {
  onClick?(): void;
}

const FoldableListItem: FunctionComponent<FoldableListItemProps> = ({
  name, isActive, iconName, onClick,
}) => (
  <Stack
    styles={foldableItemStyles(isActive)}
    horizontal
    tokens={{ childrenGap: 10 }}
    verticalAlign="center"
    onClick={onClick}
  >
    { iconName && <Icon iconName={iconName} /> }
    <Text styles={itemTextStyles}>{ name }</Text>
  </Stack>
);

export default FoldableListItem;
