import { AssetProcessStatus } from '../../api/model/AssetProcessStatus';
import { AggregationType } from '../../api/model/schemas/AggregationType';
import { Availability } from '../../api/model/schemas/Availability';
import { DataSource } from '../../api/model/schemas/DataSource';
import { DataSourceEntityStatus } from '../../api/model/schemas/DataSourceEntityStatus';
import { DataType } from '../../api/model/schemas/DataType';
import { Group } from '../../api/model/schemas/Group';
import { ProcessStatus } from '../../api/model/schemas/ProcessStatus';
import { HubType } from './Catalog/types';
import { SchemaIconType } from './Form/types';

export interface ApiV4ResponseWrapper<T> {
  data: T;
  count?: number;
  timeStamp: Date;
  duration: number;
  nextPage?: string;
}
export interface ApiV4PostResponse {
  $schema: string;
  [key: string]: any;
}
export interface IDesign {
  name: string;
  iconType: string;
  id: string;
  icon: string;
}

export interface IDesignTag {
  $schema: string;
  id: string;
  name: string;
  $schemaParent: string;
}

export interface UpdateFrequency {
  updateFrequency: string;
}

export interface IStream {
  name: string;
  parentId: string;
  iconType: string;
  icon: string;
  id: string;
  processStatus: AssetProcessStatus;
  tags: string[];
}

export enum DataSourceConfigureStatus {
  NotConfigured = 'NotConfigured',
  Configuring = 'Configuring',
  Configured = 'Configured',
}

export type IDesignSource = DataSource;

export enum DataSourceEntityType {
  Transformation = 'Transformation',
  Source = 'Source',
  StreamOutput = 'StreamOutput',
  Unknown = 'Unknown',
}

export interface IDesignSourceEntity {
  $typeId: string;
  description: string;
  id: string;
  sourceName?: string;
  ingestionStatus: ProcessStatus;
  isLocked: boolean;
  name: string;
  parentId: string;
  rowCount: number;
  tags: string[];
  fieldsLoaded: boolean;
  type: DataSourceEntityType;
  configureStatus: DataSourceConfigureStatus;
  status: DataSourceEntityStatus;
  statusDetails: string;
}

export enum EntityIngestionStatus {
  // Available entity
  NotProcessed = 'NotProcessed',

  // Set by UI to indicate it should be ingested
  PendingProcess = 'PendingProcess',

  // Set by UI to indicate it should be un-ingested
  PendingUnprocess = 'PendingUnprocess',

  // Set by system to indicate it has been ingested
  Processed = 'Processed',

  // Set by system to indicate the item was available, but has disappeared from the source.
  // Processing will delete it (and update all places where its referenced)
  PendingDelete = 'PendingDelete',
}

// https://dev.azure.com/synergiesio/OnSynergies/_git/Eyko?path=%2FModel%2FProcessStatus.cs&_a=contents&version=GBmain
export enum EntityProcessStatus {
  // The item is not processed.
  NotProcessed = 'NotProcessed',

  // Item will be processed<
  PendingProcess = 'PendingProcess',

  // Item will be un-processed
  PendingUnprocess = 'PendingUnprocess',

  // Item is processed
  Processed = 'Processed',

  // Item will be removed.
  PendingDelete = 'PendingDelete',
}

export interface IDesignSourceEntityField {
  $parentTypeId: string;
  $typeId: string;
  aggregationType: AggregationType;
  dataType: DataType;
  description: string;
  formatType: string;
  id: string;
  ingestedAggregationType: AggregationType;
  ingestedDataType: DataType;
  ingestionStatus: EntityIngestionStatus;
  name: string;
  parentId: string;
  sourceDataType: DataType;
  sourceId: string;
  sourceName: string;
  tags: string[];
  availability: Availability;
}

export interface IAssetValuesRequestResponse {
  continuationToken?: string;
  parentId: string;
  // entity that values are requested
  parent: any;
  sortOrder: string;
  pageSize: number;
  records: number;
  result: {
    columnCount: number;
    rowCount: number;
    columns: Column[];
    rows: any[][];
    metadata: {
      start: string;
      queryGeneration: number;
      queryExecution: number;
      queryReadResult: number;
      end: string;
    };
  };
  designId: string;
  startTime: string;
  endTime: string;
  status: string;
  lastChangedById: string;
  lastChangedWhen: string;
  id: string;
}

interface Column {
  id: string;
  dataType: string;
  formatType: string;
}

export interface IDesignSourceFieldValuesRequestResponse
  extends IAssetValuesRequestResponse {}

export interface UIEnhancedDesignSourceEntityField
  extends IDesignSourceEntityField {
  parentEntityName: string;
}

export interface SingleNestedEntityCreatePathParams {
  typeId: string;
  parentEntityId: string;
}

export interface SingleNestedEntityPathParams
  extends SingleNestedEntityCreatePathParams {
  entityId: string;
}

export interface IType {
  $typeId: string;
  $parentTypeId: string;
  iconType: SchemaIconType;
  icon: string;
  id: string;
  index: number;
  isLocked: boolean;
  isExtensible: boolean;
  name: string;
  parentId: string;
  parentSchemaId: string;
}
export interface ITypeSectionWithProperties {
  $schema: string;
  $schemaParent: string;
  id: string;
  index: number;
  isLocked: boolean;
  name: string;
  parentId: string;
  properties: ITypeSectionProperty[];
}

export enum IconType {
  Fabric = 'FabricMdl2',
  SVG = 'InlineSvg',
  None = 'None',
  MaterialDesign = 'MaterialDesign',
}

export interface ITypeSectionProperty {
  $parentTypeId: string;
  $typeId: string;
  canPreSelect: boolean;
  dataType: string;
  icon?: string;
  iconType: IconType;
  id: string;
  index: number;
  interaction?: ITypeSectionPropertyInteraction;
  isAvailableOnAdd: boolean;
  isEditableAfterCreation: boolean;
  isEnabled: boolean;
  isLocked: boolean;
  isNested: boolean;
  isReadOnly: boolean;
  isSearchable: boolean;
  isShownOnInformation: boolean;
  isSortable: boolean;
  isStreamerEditable: boolean;
  name: string;
}

export enum ITypeSectionPropertyControlType {
  SingleLineEdit = 'SingleLineEdit',
  MultiLineEdit = 'MultiLineEdit',
  SelectMultiple = 'SelectMultiple',
}

export interface ITypeSectionPropertyInteraction {
  controlType: ITypeSectionPropertyControlType;
  controlSize: string;
  isRequired: boolean;
  isLocked: boolean;
  defaultValue?: string;
  validValues?: string[];
  formatType: string;
  referenceType?: string;
}

export interface ICalculation {
  id: string;
  tags: string[];
  name: string;
  availability: Availability;
}

export interface IHub {
  id: string;
  name: string;
  tags: string[];
  type: HubType;
  availability: Availability;
}

export type IGroup = Group;

export interface IStreamField {
  id: string;
  name: string;
  description: string;
  dataType: DataType;
  formatType: string;
  defaultAggregation: AggregationType;
  aggregationType: AggregationType;
  $sourceFieldTypeId: string;
  sourceFieldId: string;
  $parentTypeId: string;
  parentId: string;
  tags: string[];
  processStatus: EntityProcessStatus;
  processedName: string;
  processedDataType: string;
  processedEntityName: string;
  processedFormatType: string;
  processedIsAggregated: boolean;
  availability: Availability;
  sourceField?: {
    parent: {
      parent: {
        color: number;
        name: string;
      };
    };
  };
}
export interface IDesignStream {
  $typeId: string;
  id: string;
  name: string;
  iconType: string;
  icon?: string;
  $lastChangedByTypeId: string;
  lastChangedById: string;
  lastChangedWhen: string;
  $parentTypeId: string;
  parentId: string;
  processStatus: AssetProcessStatus;
  processedName: string;
  lastSuccessfulProcessStartTime: string;
  lastSuccessfulProcessEndTime: string;
  tags: any[];
}
