import { Availability } from '../api/model/schemas/Availability';
import { IFilter } from './IFilter';

export enum IColumnFormatType {
  Standard = 'Standard',
  NoMarkup = 'NoMarkup',
  Uri = 'Uri',
  LongTime = 'LongTime',
  ShortTime = 'ShortTime',
  LongDate = 'LongDate',
  ShortDate = 'ShortDate',
  LongDateTime = 'LongDateTime',
  ShortDateTime = 'ShortDateTime',
  Number = 'Number',
  Response = 'Response',
  State = 'State',
  Currency = 'Currency',
  Percentage = 'Percentage',
}

export enum IColumnDataType {
  Unknown = 'Unknown',
  Text = 'Text',
  JSON = 'JSON',
  NumericDecimal = 'NumericDecimal',
  NumericInteger = 'NumericInteger',
  DateTime = 'DateTime',
  Geography = 'Geography',
  Boolean = 'Boolean',
}

export enum IColumnSortTypes {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export type IColumnSortType = IColumnSortTypes | null;

export type IColumnAggregationMethods =
  | 'Sum'
  | 'Count'
  | 'Average'
  | 'Minimum'
  | 'Maximum'
  | 'CountDistinctIncludingNull'
  | 'CountDistinctExcludingNull';

export interface IColumSortConfig {
  sortMode: IColumnSortType;
  sortPosition?: number;
}

export interface IColumn {
  id: string;
  name: string;
  formatType: IColumnFormatType;
  dataType: IColumnDataType;
  filters: IFilter[];
  aggregation: IColumnAggregationMethods;
  defaultAggregation: IColumnAggregationMethods | null;
  description: string;
  isHidden?: boolean;
  sortConfig?: IColumSortConfig;
  tags: string[];
  parentEntityName: string;
  ref?: string;
  streamElementTypeId: string;
  isAggregatingValuesAllowed: boolean;
  availability: Availability;
  isAggregated?: boolean;
  dataSourceColor?: string;
  dataSourceName?: string;
  /**
   * @description used for fetching group "values" in streamer/filters
   */
  groupAssetId?: string;
  /**
   * @deprecated old part of v3 API. WIll be removed soon
   */
  dataEntityId: string;

  /**
   * @deprecated old part of v3 API. WIll be removed soon
   */
  dataSetId: string;
  /**
   * @deprecated old part of v3 API. WIll be removed soon
   */
  dataSourceId: string;
}
