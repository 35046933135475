import * as effects from 'redux-saga/effects';

import * as excelActions from '../../../actions/excel-actions';
import { provideDataset } from '../../../actions/excel-actions/utils';
import {
  // areColumnsEqual,
  isAggregationMapped,
} from '../components/StreamerColumn/utils';
import { IColumn, IColumnAggregationMethods } from '../../../types/IColumn';
import { editTableItemAggregation } from '../../ColumnsSequencing/actions';
import { TableItemAggregationEditIntentPayload } from '../../ColumnsSequencing/types';
import { IDataset } from '../../../types/IDataset';
import { getActiveObjectType } from '../../App/selectors';
import { generateColumnUuid } from '../../ColumnsSequencing/utils';
import { editMatrixItemAggregationIntent, setItems } from '../../GroupTable/actions';
import { SECTION_TYPES } from '../../GroupTable/constants';
import { getSectionByType } from '../../GroupTable/selectors';
import { MatrixItemAggregationEditIntentPayload } from '../../GroupTable/types';

import { columnAggregationChangeIntent } from '../actions';
import streamsSelectors from '../selectors';
import { handleAddGroupTableItemOnColumnClick, handleRemoveGroupTableItemOnColumnClick } from './groupTableItemSelectionDeselection';

export function* removeAggregationColumn(
  columnWithoutAggregation: IColumn,
  datasetId: string,
  aggregation: IColumnAggregationMethods,
) {
  const datasetsToEdit = streamsSelectors.getDatasetById(datasetId)(
    yield effects.select(),
  );

  const columnToRemove = { ...columnWithoutAggregation, aggregation };

  const filteredColumns = datasetsToEdit.columns.filter((c) => {
    if (
      generateColumnUuid(c) === generateColumnUuid(columnToRemove)
    ) {
      return false;
    }

    return true;
  });

  // prevent deselecting last column
  if (filteredColumns.length === 0) return;

  yield effects.put({
    type: 'EDIT_DATASET',
    id: datasetId,
    properties: { columns: filteredColumns },
  });

  yield effects.call(excelActions.updateDataset, {
    datasetId: datasetsToEdit.id,
  });
}

export function* addAggregation(
  column: IColumn,
  datasetId: string,
  aggregation: IColumnAggregationMethods,
) {
  const datasetsToEdit = streamsSelectors.getDatasetById(datasetId)(
    yield effects.select(),
  );

  yield effects.put({
    type: 'EDIT_DATASET',
    id: datasetId,
    properties: {
      columns: [...datasetsToEdit.columns, { ...column, aggregation }],
    },
  });

  if (datasetsToEdit.options.dataOnDemand) {
    yield effects.call(excelActions.updateDataset, {
      datasetId,
    });
  }
}

export function* handleColumnAggregationChangeIntent({
  payload: { column: columnWithoutAggregation, aggregation, isGroupTable },
}: ReturnType<typeof columnAggregationChangeIntent>) {
  const columnWithAggregation = { ...columnWithoutAggregation, aggregation };

  let currentDataset = streamsSelectors.getCurrentDataset(
    yield effects.select(),
  );

  if (!currentDataset) {
    const activeObjectType = getActiveObjectType(yield effects.select());
    const createdDataset:IDataset = yield effects.call(provideDataset, {
      datasetId: null,
      type: activeObjectType,
    });

    yield effects.put({
      type: 'EDIT_DATASET',
      id: createdDataset.id,
      properties: {
        columns: [columnWithAggregation],
      },
    });

    if (createdDataset.options.dataOnDemand) {
      yield effects.call(excelActions.updateDataset, {
        datasetId: createdDataset.id,
      });
    }

    if (isGroupTable) {
      yield effects.call(handleAddGroupTableItemOnColumnClick, columnWithAggregation);
    }

    return;
  }

  currentDataset = yield effects.select(streamsSelectors.getCurrentDataset);

  if (isAggregationMapped(columnWithoutAggregation, currentDataset, aggregation)) {
    if (isGroupTable) {
      const groupTableValuesSection = getSectionByType(SECTION_TYPES.values, currentDataset.id)(yield effects.select());
      const isInValues = groupTableValuesSection.some((item) => item.columnUuid === generateColumnUuid(columnWithAggregation));

      // in case of group table we don't want do deselect last value
      if (groupTableValuesSection.length === 1 && isInValues) {
        return;
      }

      yield effects.call(handleRemoveGroupTableItemOnColumnClick, columnWithAggregation, false);
    }

    yield effects.call(
      removeAggregationColumn,
      columnWithoutAggregation,
      currentDataset.id,
      aggregation,
    );
  } else {
    yield effects.call(
      addAggregation,
      columnWithoutAggregation,
      currentDataset.id,
      aggregation,
    );
    if (isGroupTable) {
      yield effects.call(handleAddGroupTableItemOnColumnClick, columnWithAggregation);
    }
  }
}

type EditColumnPayload = MatrixItemAggregationEditIntentPayload & TableItemAggregationEditIntentPayload;
export function* editColumnAggregation({
  aggregation,
  column: oldColumn,
  sectionType,
}:EditColumnPayload) {
  const currentDataset = streamsSelectors.getCurrentDataset(
    yield effects.select(),
  );

  const columnWithNewAggregation:IColumn = {
    ...oldColumn,
    aggregation,
  };

  const updatedColumns = currentDataset.columns.map((currentColumn) => {
    if (generateColumnUuid(currentColumn) === generateColumnUuid(oldColumn)) {
      return columnWithNewAggregation;
    }

    return currentColumn;
  });

  yield effects.put({
    type: 'EDIT_DATASET',
    id: currentDataset.id,
    properties: {
    // we need to have both old and updated column before we change
    // the group table design column "link"
      columns: [...updatedColumns, oldColumn],
    },
  });

  if (sectionType) {
    const groupTableDesignSectionToAlter = getSectionByType(sectionType, currentDataset.id)(yield effects.select());

    const alteredSection = groupTableDesignSectionToAlter.map((currentItem) => {
      if (currentItem.columnUuid === generateColumnUuid(oldColumn)) {
        return {
          ...currentItem,
          columnUuid: generateColumnUuid(columnWithNewAggregation),
        };
      }

      return currentItem;
    });

    yield effects.put(setItems({
      sectionType,
      items: alteredSection,
      datasetId: currentDataset.id,
    }));
  }

  yield effects.call(
    removeAggregationColumn,
    oldColumn,
    currentDataset.id,
    oldColumn.aggregation,
  );
}

export function* editAggregationWrapper({
  payload,
}: ReturnType<typeof editTableItemAggregation>) {
  yield effects.call(editColumnAggregation, payload as EditColumnPayload);
}

export function* rootSaga() {
  yield effects.all([
    effects.takeLatest(
      columnAggregationChangeIntent.type,
      handleColumnAggregationChangeIntent,
    ),
    effects.takeLatest(
      [editTableItemAggregation.type, editMatrixItemAggregationIntent.type],
      editAggregationWrapper,
    ),
  ]);
}

export default rootSaga;
