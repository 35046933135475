import { AxiosInstance } from 'axios';
import { observable } from '../../utils/observable';

export const activeRequestsCount = observable(0);

export const applyCountActiveRequests = (
  instance: AxiosInstance
): AxiosInstance => {
  instance.interceptors.request.use((config) => {
    activeRequestsCount.set(activeRequestsCount.get() + 1);

    return config;
  });
  instance.interceptors.response.use(
    (resp) => {
      activeRequestsCount.set(activeRequestsCount.get() - 1);

      return resp;
    },
    (err) => {
      activeRequestsCount.set(activeRequestsCount.get() - 1);

      return err;
    }
  );

  return instance;
};
