import {
  IDialogContentStyles,
  IDialogStyles,
  IIconStyles,
  ISpinnerStyles,
  IStackStyles,
  mergeStyleSets,
} from 'office-ui-fabric-react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../../../../../theme';

export const shareSolutionColorVar = '--share-solution-color';

export const classNames = mergeStyleSets({
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, .75)',
    color: baseColors.white,
  },
  sharePreview: {
    display: 'flex',
  },
  shareInfo: {
    marginTop: 16,
    padding: 8,
    border: `1px solid ${baseColors.outlineGray}`,
    borderRadius: BORDER_RADIUS_IN_PX,
  },
  shareInfoLogo: {
    flexShrink: '0 !important',
    marginRight: 8,
  },
  shareInfoTitle: {
    margin: 0,
    marginBottom: 4,
    fontSize: 14,
    lineHeight: 32,
    fontWeight: 600,
  },
  shareInfoDescription: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 16,
  },
  shareInfoButtons: {
    marginTop: '12px !important',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  shareButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 80,
    padding: '0 12px',
    backgroundColor: 'transparent',
    border: `1px solid var(${shareSolutionColorVar})`,
    borderRadius: 2,
    color: `var(${shareSolutionColorVar})`,
    lineHeight: 32,
    fontWeight: 600,
    fontSize: 14,
    cursor: 'pointer',
    selectors: {
      '& + &': {
        marginLeft: '8px !important',
      },
    },
  },
  shareButtonExposed: {
    backgroundColor: `var(${shareSolutionColorVar}) !important`,
    color: `${baseColors.white} !important`,
  },
  shareSolutions: {
    justifyContent: 'center',
    marginTop: 20,
  },
  shareSolutionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  shareSolutionButtonSelected: {
    borderWidth: 1,
    borderStyle: 'solid',
  },
});

export const dialogStyles: Partial<IDialogStyles> = {
  main: {
    width: '570px !important',
  },
};

export const dialogContentStyles: Partial<IDialogContentStyles> = {
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export const dialogPreviewListStyles: IStackStyles = {
  root: {
    overflowX: 'auto',
  },
};

export const buttonSpinnerStyles: ISpinnerStyles = {
  root: {
    marginRight: 8,
  },
};

export const buttonIconStyles: IIconStyles = {
  root: {
    marginRight: 8,
  },
};
