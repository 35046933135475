import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import {
  IColumnAggregationMethods,
  IColumnSortType,
} from '../../types/IColumn';
import { IFilter } from '../../types/IFilter';
import { fetchEntities, createNestedEntity } from '../Designer/api';

import {
  ApiV4ResponseWrapper, IStream,
} from '../Designer/types';

export const fetchStreams = fetchEntities<ApiV4ResponseWrapper<IStream[]>>(
  TYPE_IDS.Stream,
);

export interface IRequestDataElement {
  id: string;
  streamElementId: string;
  streamElementTypeId: string;
  filters?: IFilter[];
  aggregation?: IColumnAggregationMethods;
  sortMode: IColumnSortType;
  sortPosition?: number;
}

export interface IRequestReturnElement {
  id: string;
  sortMode?: IColumnSortType;
  sortPosition?: number;
}

export interface InquiriesDataParams {
  data: IRequestDataElement[];
  columns: IRequestReturnElement[];
  continuation?: {
    pageSize: number;
    token: string;
  };
  filter?: any;
}

export const createStreamDataRequestTask = (
  streamId: string,
) => createNestedEntity<InquiriesDataParams>({
  parentEntityId: streamId,
  typeId: TYPE_IDS.StreamDataRequest,
});
