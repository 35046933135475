import { mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../theme';

export const classNames = mergeStyleSets({
  inputWrapper: {
    width: 'calc(100% - 10px)',
    border: `1px solid ${baseColors.inputBorder}`,
    padding: '4px',
  },
});
