import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEntityForm,
  fetchEntityFormSections,
  removeEntityForm,
  setCurrentPageIndex,
} from '../actions';
import {
  getCurrentSectionIndex,
  getEntityByTypeId,
  getEntityFormByControllerId,
  getPreviousPageIndex,
} from '../selectors';
import { IEntityForm, IGenericFormField, SchemaDataType } from '../types';
import FormShimmer from './FormShimmer';
import { getSelectedDesignId } from '../../ContentLibrary/selectors';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';

const getTypeFromProperty = (property: string, form: IEntityForm) =>
  form?.data?.[property] as string;

const ChildEntityForm: React.FC<IGenericFormField> = ({
  entityProperty,
  interaction,
  currentForm: parentForm,
}) => {
  const designId = useSelector(getSelectedDesignId);
  const dispatch = useDispatch();
  const currentIndex = useSelector(getCurrentSectionIndex);
  const previousIndex = useSelector(getPreviousPageIndex);
  const { referenceType, referenceTypeProperty } = interaction;
  const { isNested, name } = entityProperty;

  const typeId =
    referenceType || getTypeFromProperty(referenceTypeProperty, parentForm);
  const parentTypeId = parentForm?.typeId;
  const parentEntityId = parentForm?.entityId;

  const entityForm = useSelector(
    getEntityFormByControllerId(entityProperty.id)
  );
  const currentEntity = useSelector(getEntityByTypeId(typeId));

  const sections = entityForm?.sections;

  const isCurrentReference = entityForm?.typeId === typeId;

  // Azure REST pagination needs some customizations in the
  // way form pages flow

  const isRestPagination =
    entityProperty?.id === TYPE_IDS.AzureRestConfigPagination;

  useEffect(() => {
    let embeddedTo: string;
    let nestedKey: string;
    if (!isCurrentReference) {
      dispatch(removeEntityForm({ typeId: entityForm?.typeId }));
    }

    if (entityProperty.dataType === SchemaDataType.Embedded || isNested) {
      embeddedTo = parentTypeId;
    }

    if (isNested) {
      nestedKey = name;
    }

    if ((!entityForm || !isCurrentReference) && !!typeId) {
      /**
       * This adds a new entityForm element for the children entity.
       * each entityForm will create a new entity once the form is submited
       */
      dispatch(
        addEntityForm({
          typeId,
          entityId: currentEntity?.id,
          parentTypeId,
          parentEntityId,
          controllerId: entityProperty.id,
          parentIndex: currentIndex,
          sections: undefined,
          data: currentEntity || {},
          embeddedTo,
          nestedKey,
        })
      );
      dispatch(fetchEntityFormSections({ typeId, designId }));
    }
  }, [designId]);

  useEffect(() => {
    if (!!sections && isCurrentReference && !isRestPagination) {
      dispatch(
        setCurrentPageIndex(
          previousIndex > currentIndex ? currentIndex - 1 : currentIndex + 1
        )
      );
    }
  }, [sections]);

  if (!typeId || isRestPagination) {
    return null;
  }

  return <FormShimmer />;
};

export default ChildEntityForm;
