import React from 'react';

import { IButtonStyles } from '@fluentui/react';
import IconButton, { IconButtonProps } from '../../IconButton';
import { baseColors } from '../../../theme';

export const headerBarBtnStyles: IButtonStyles = {
  root: {
    width: 32,
    height: 32,
    color: baseColors.white,
    '& .mdiIcon': {
      width: 18,
    },
  },
  rootHovered: {
    borderRadius: 0,
  },
  icon: {
    color: baseColors.white,
    width: 16,
    height: 16,
    fontSize: 16,
  },
  iconHovered: {
    color: baseColors.iconColor,
  },
  rootDisabled: {
    backgroundColor: 'transparent',
  },
};

const HeaderBarButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    styles={headerBarBtnStyles}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default HeaderBarButton;
