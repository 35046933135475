import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { i18nNameKey } from '../../../../../api/interceptors/i18n/constants';
import { supportedCalculationTypes } from '../../../../../api/interceptors/i18n/interaction/constants';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getDataSources } from '../../../Catalog/selectors';
import { ENTITY_CHILDREN_KEY } from '../../constants';
import { getAllForms, getEntityById } from '../../selectors';
import { IControlledField } from '../../types';
import SelectOneOfTypeBase from '../SelectOneOfType';
import { useSourceFromEntity } from '../SelectOneOfType/useSourceFromEntity';

const SelectOneOfType: React.FC<IControlledField> = ({
  controller,
  entityProperty,
  interaction,
  isDisabled,
  currentForm,
  label: fieldLabel,
}) => {
  // DEPS
  const { watch } = useFormContext();
  const { value, onChange } = controller;
  const { isReadOnly } = entityProperty;
  const {
    validTypes = [],
    defaultType,
    isRequired,
    allowedDataTypes,
    referenceTypeFilterProperty,
    referenceTypeFilterValue,
  } = interaction;
  const watched = watch();

  // STATE
  const entity = useSelector(getEntityById(value));
  const { records: sources } = useSelector(getDataSources);
  const [typeId, setTypeId] = useState<string>(
    // if  there is no entity, but we do have a value, this means literal value was used
    entity?.$typeId ?? (value ? TYPE_IDS.LiteralValue : defaultType)
  );
  const forms = useSelector(getAllForms);

  // DERIVED STATE
  const isSourceField = entity?.$typeId === TYPE_IDS.SourceEntityField;
  const isEntity = typeId === TYPE_IDS.SourceEntity;
  const { sourceId, setSourceId } = useSourceFromEntity(
    isSourceField && entity?.parentId,
    (isEntity && entity?.parentId) || sources?.[0]?.id
  );

  const currentData: any = forms?.reduce(
    (acc, curr) => ({
      ...acc,
      ...curr.data,
    }),
    {}
  );

  // Here we get all the current selected assets,
  // to filter and avoid picking the same asset twice

  const selected = useMemo(() => {
    if (currentForm?.typeId === TYPE_IDS.Hub) {
      const assets =
        get(watched, `${ENTITY_CHILDREN_KEY}.${TYPE_IDS.HubSource}`) || [];
      return assets.map((asset) => asset?.data?.assetId);
    }
    return [];
  }, [watched]);

  const label = useMemo(
    // `currentForm.typeId` might store the guid of a specific CalculationType we want to handle differently
    () =>
      supportedCalculationTypes.includes(currentForm?.typeId)
        ? entityProperty[i18nNameKey]
        : fieldLabel,
    [currentForm.typeId, entityProperty[i18nNameKey]]
  );

  // RENDER
  return (
    <SelectOneOfTypeBase
      {...{
        sourceId,
        typeId,
        sources,
        allowedDataTypes,
        label,
        referenceTypeFilterProperty,
        referenceTypeFilterValue,
      }}
      typesIds={validTypes}
      entityId={value}
      onEntityChange={onChange}
      onSourceChange={setSourceId}
      onTypeChange={setTypeId}
      isDisabled={isDisabled || isReadOnly}
      isRequired={isRequired}
      startsLoading={!!entity?.parentId}
      dataType={currentData?.dataType}
      parentTypeId={currentForm.parentTypeId}
      excluded={selected}
      formTypeId={currentForm?.typeId}
      formEntityId={currentForm?.entityId}
    />
  );
};

export default SelectOneOfType;
