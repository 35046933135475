import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from './TutorialAnimation';

export const TUTORIAL_TILE_KEY = 'designer-setup-tags';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:setup:tags:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:setup:tags:items:createTag:description',
      icon: 'Add',
      titleI18nKey: 'tutorialTiles:designer:setup:tags:items:createTag:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:setup:tags:items:tagHarmony:description',
      icon: 'Tag',
      titleI18nKey: 'tutorialTiles:designer:setup:tags:items:tagHarmony:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:setup:tags:items:workingWithTags:description',
      icon: 'Shapes',
      titleI18nKey: 'tutorialTiles:designer:setup:tags:items:workingWithTags:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010376142-Tags-Organize-your-data',
};
