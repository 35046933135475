/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getErrors } from '../../selectors';
import { Error } from '../../types';

import { EmptyMessage } from '../../../../components/ui';
import ErrorCard from '../../components/ErrorCard';
import * as styles from './styles';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';

const ErrorsView: React.FC = () => {
  // HOOKS
  const { t } = useTranslation('errors');

  // STATE
  const errors = useSelector(getErrors) as Error[];

  // DERIVES STATE
  const hasErrors = errors.length > 0;

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:notifications:errors:cta:noErrors': !hasErrors,
    }),
    [hasErrors]
  );

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (tutorialTile) return tutorialTile;

  if (!hasErrors) return <EmptyMessage message={t('noErrorsMessage')} />;

  return (
    <section className={styles.classNames.gridWrapper}>
      {errors.map((errorProps) => (
        <ErrorCard key={errorProps.date + errorProps.title} {...errorProps} />
      ))}
    </section>
  );
};

export default ErrorsView;
