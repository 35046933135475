import { Text } from 'office-ui-fabric-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { ProcessStreamIntentArgs } from '../../modules/Builder/actions';
import { IStream } from '../../types/IStream';
import WizardPage, { WizardType } from '../WizardPage';
import { classNames } from './styles';
import { ApplicationState } from '../../store/types';
import { generateI18nConfig } from './util';

interface ProcessStreamLocationState {
  selectedStream: IStream | null;
  processStreamActionCreator: (ProcessStreamIntentArgs) => Action;
  deselectStreamAction?: () => void
}

interface Props {
  goBackPage: string;
  selectorForLoading: (state: ApplicationState) => boolean;
}

const ProcessStreamWizard = ({
  goBackPage,
  selectorForLoading,
}: Props) => {
  // HOOKS
  const { state } = useLocation<ProcessStreamLocationState>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isProcessingInProgress = useSelector(selectorForLoading);

  // DERIVED STATE
  const selectedStream = state?.selectedStream;
  const actionCreator = state?.processStreamActionCreator;
  const deselectStreamAction = state?.deselectStreamAction;

  let secondsElapsed: number | null = null;

  if (
    selectedStream?.lastSuccessfulProcessEndTime
    && selectedStream?.lastSuccessfulProcessStartTime
  ) {
    const start = moment(selectedStream.lastSuccessfulProcessStartTime);
    const end = moment(selectedStream.lastSuccessfulProcessEndTime);
    const duration = moment.duration(end.diff(start));
    secondsElapsed = Math.ceil(duration.asSeconds());
  }

  // CALLBACKS
  const handleProcess = () => {
    dispatch(
      actionCreator({
        historyGoBack: () => (goBackPage ? history.push(goBackPage) : history.go(-1)),
        streamId: selectedStream?.id,
      }),
    );

    if (deselectStreamAction) {
      deselectStreamAction();
    }
  };

  // EFFECTS
  React.useEffect(() => {
    if (!selectedStream) {
      history.push(goBackPage);
    }
  }, [selectedStream, goBackPage]);

  // PARTS
  const buttonsConfig = [
    {
      tooltip: <Trans i18nKey="actionBar:general.accept" />,
      onClick: handleProcess,
      iconProps: { iconName: 'CheckMark' },
      'data-testid': 'approve-processing-button',
    },
    {
      tooltip: <Trans i18nKey="actionBar:general.cancelAction" />,
      onClick: () => history.go(-1),
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'cancel-processing-button',
    },
  ];

  // RENDER
  return (
    <WizardPage
      wizardType={WizardType.Process}
      headerBarButtonsConfig={buttonsConfig}
      pageTitle={t('wizard:Process')}
      entityName={selectedStream?.name}
      isSyncing={isProcessingInProgress}
    >
      <section className={classNames.contentWrapper}>
        <Text as="h2" className={classNames.header}>
          {t('wizard:areYouReadyForProcessing')}
        </Text>
        <Text as="p" className={classNames.message}>
          <Trans
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...generateI18nConfig(secondsElapsed)}
          />
        </Text>
      </section>
    </WizardPage>
  );
};

export default ProcessStreamWizard;
