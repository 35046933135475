import { Action, Reducer } from 'redux';

import { IStream } from '../../types/IStream';
import { IColumn } from '../../types/IColumn';
import { IDataset, IDatasetOptions } from '../../types/IDataset';
import { ICustomFilter } from '../../types/ICustomFilter';
import { logoutSuccess } from '../../store/globalActions';

import { IFetchingProgress, KnownAction } from './types';
import { ICustomAggregation } from '../../types/ICustomAggregation';
import { PARENT_DRILLING } from '../GroupTable/constants';
import * as GroupTableActions from '../GroupTable/actions';
import * as StreamActions from './actions';
import { IDrillableSelection } from '../GroupTable/types';

export interface StreamsState {
  streams: {
    pending: boolean;
    error: string;
    data: IStream[];
  };
  datasets: IDataset[];
  currentDatasetId: string;
  availableColumns: IColumn[];
  isFetchingAvailableColumns: boolean;
  currentStreamId: string;
  currentStreamColumn: IColumn;
  syncing: boolean;
  checkingCurrentSelection: boolean;
  streamColumnLoading: IColumn;
  autoLoadAll: boolean;
  autoLoading: boolean;
  isRewinding: boolean;
  customFilters: ICustomFilter[];
  customAggregations: ICustomAggregation[];
  defaultDatasetOptions: IDatasetOptions;
  settings: {
    permittedDataBooks: string[];
    permittedStreamCategories: string[];
    permittedStreams: string[];
  };
  drillableSelection: IDrillableSelection,
  fetchingProgress: IFetchingProgress;
  isFiltersView: boolean;
  processingWizardInProgress: boolean;
}

export const initialState : StreamsState = {
  streams: {
    pending: false,
    error: null,
    data: null,
  },
  availableColumns: [],
  currentStreamId: null,
  currentStreamColumn: null,
  syncing: false,
  checkingCurrentSelection: false,
  streamColumnLoading: null,
  autoLoadAll: false,
  datasets: [],
  currentDatasetId: null,
  autoLoading: false,
  isRewinding: false,
  customFilters: [],
  customAggregations: [],
  defaultDatasetOptions: {
    showAllValues: true,
    dataOnDemand: false,
    freezePanes: true,
    columnParentDrilling: PARENT_DRILLING.after,
    rowParentDrilling: PARENT_DRILLING.before,
  },
  settings: {
    permittedDataBooks: null,
    permittedStreamCategories: null,
    permittedStreams: [],
  },
  drillableSelection: null,
  fetchingProgress: {
    totalPages: 0,
    pagesFetched: 0,
  },
  isFiltersView: false,
  processingWizardInProgress: false,
  isFetchingAvailableColumns: false,
};

export const reducer: Reducer<StreamsState> = (
  state: StreamsState | undefined,
  incomingAction: Action,
): StreamsState => {
  if (state === undefined) {
    return { ...initialState };
  }

  const action = incomingAction as KnownAction | typeof logoutSuccess;

  switch (action.type) {
    case 'SET_STREAMS_SUCCESS':
      return {
        ...state,
        processingWizardInProgress: false,
        streams: {
          pending: false,
          data: action.payload,
          error: null,
        },
        settings: {
          ...state.settings,
          permittedStreams: action.payload.map((s) => s.id),
        },
      };

    case 'SET_STREAMS_ERROR':
      return {
        ...state,
        streams: {
          ...state.streams,
          pending: false,
          error: action.payload,
        },
      };

    case 'SET_STREAMS_PENDING':
      return {
        ...state,
        streams: {
          ...state.streams,
          pending: true,
          error: null,
        },
      };

    case 'SET_CURRENT_STREAM_ID':
      return {
        ...state,
        currentStreamId: action.currentStreamId,
      };

    case 'SET_CURRENT_STREAM_COLUMN':
      return {
        ...state,
        currentStreamColumn: action.currentStreamColumn,
      };

    case 'SET_AVAILABLE_COLUMNS':
      return {
        ...state,
        availableColumns: action.availableColumns,
        isFetchingAvailableColumns: false,
      };

    case 'SET_PERMITTED_DATABOOKS':
      return {
        ...state,
        settings: {
          ...state.settings,
          permittedDataBooks: action.permittedDataBooks,
        },
      };

    case 'SET_PERMITTED_STREAM_CATEGORIES':
      return {
        ...state,
        settings: {
          ...state.settings,
          permittedStreamCategories: action.permittedStreamCategories,
        },
      };

    case 'SET_PERMITTED_STREAMS':
      return {
        ...state,
        settings: {
          ...state.settings,
          permittedStreams: action.permittedStreams,
        },
      };
    case 'SET_STREAM_COLUMN_LOADING':
      return {
        ...state,
        streamColumnLoading: action.column,
      };
    case 'SET_SYNCING':
      return {
        ...state,
        syncing: action.syncing,
      };
    case 'SET_AUTO_LOAD_ALL':
      return {
        ...state,
        autoLoadAll: action.payload,
      };
    case StreamActions.setDatasets.type:
      return {
        ...state,
        datasets: action.payload,
      };
    case 'SET_CURRENT_DATASET_ID':
      return {
        ...state,
        currentDatasetId: action.id,
      };
    case 'EDIT_DATASET':
      return {
        ...state,
        datasets: state.datasets.map(
          (dataset) => (dataset.id === action.id
            ? { ...dataset, ...action.properties }
            : dataset),
        ),
      };
    case 'ADD_DATASET':
      return {
        ...state,
        datasets: [...state.datasets, action.dataset],
      };
    case 'REMOVE_DATASET':
      return {
        ...state,
        datasets: [...state.datasets].filter((dataset) => dataset.id !== action.datasetId),
      };
    case 'SET_AUTOLOADING':
      return {
        ...state,
        autoLoading: action.autoLoading,
      };
    case 'SET_CUSTOM_FILTERS':
      return {
        ...state,
        customFilters: action.customFilters,
      };

    case 'SET_DEFAULT_DATASET_OPTIONS':
      return {
        ...state,
        defaultDatasetOptions: action.options,
      };

    case 'SET_CUSTOM_AGGREGATIONS':
      return {
        ...state,
        customAggregations: action.customAggregations,
      };
    case logoutSuccess().type:
      return {
        ...state,
        streams: {
          ...initialState.streams,
        },
      };
    case StreamActions.setCheckingCurrentSelection.type:
      return {
        ...state,
        checkingCurrentSelection: action.payload,
      };
    case StreamActions.setRewinding.type:
      return {
        ...state,
        isRewinding: action.payload,
      };

    case GroupTableActions.setDrillableSelection.type:
      return {
        ...state,
        drillableSelection: action.payload,
      };

    case StreamActions.setFetchingProgress.type:
      return {
        ...state,
        fetchingProgress: action.payload,
      };

    case StreamActions.incrementTotalPages.type:
      return {
        ...state,
        fetchingProgress: {
          ...state.fetchingProgress,
          totalPages: state.fetchingProgress.totalPages + action.payload,
        },
      };
    case StreamActions.incrementPagesFetched.type:
      return {
        ...state,
        fetchingProgress: {
          ...state.fetchingProgress,
          pagesFetched: state.fetchingProgress.pagesFetched + action.payload,
        },
      };
    case StreamActions.toggleFiltersView.type:
      return {
        ...state,
        isFiltersView: !state.isFiltersView,
      };
    case StreamActions.setSyncing.type:
      return {
        ...state,
        syncing: action.payload,
      };
    case StreamActions.streamerProcessingIntent.type:
      return {
        ...state,
        processingWizardInProgress: true,
      };
    case StreamActions.setIsFetchingAvailableColumns.type:
      return {
        ...state,
        isFetchingAvailableColumns: action.payload,
      };
    default:
      return state;
  }
};
