import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import EntityField from '../../../../../components/EntityField';
import GroupCollapse from '../../../../../components/ui/GroupCollapse';

import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';

import {
  getEntityFields,
  getSelectedEntityId,
  getIsUpdating,
  getDataSources,
  getSelectedSource,
} from '../../selectors';
import {
  fetchDataSourceFields,
  setSelectedEntityId,
  updateEntity,
  fetchDataSources,
} from '../../actions';
import { IDesignSourceEntityField } from '../../../types';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import { DefinedSortTypes } from '../../../../App/types';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import {
  IFoldableListGroup,
  IFoldableListItem,
} from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';
import useEntitiesSelections from '../../../../../hooks/useEntitiesSelections';
import useEditableItems from '../../../../../hooks/useEditableItems';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import { getSelectedItemsIds } from '../../../../App/selectors';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { matchesSearchQuery } from '../../../../Search/utils';
import { getSearchQuery } from '../../../../Search/selectors';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import useItemSelections from '../../../../../hooks/useItemSelections';
import DataSourceCalloutTitle from '../../../Ingestion/components/DataSourceCalloutTitle';
import { ancestorSourceNameKey } from '../../../../../constants/infoBoxes';
import { DataSourceEntityFieldKeys } from '../../../../../api/model/schemas/DataSourceEntityField';
import { DataSourceEntityKeys } from '../../../../../api/model/schemas/DataSourceEntity';

const CatalogData: FunctionComponent = () => {
  // STATE
  const selectedDesignId = useSelector(getSelectedDesignId);
  const selectedEntityId = useSelector(getSelectedEntityId);
  const selectedSource = useSelector(getSelectedSource);

  // HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onEditEndCallback = (hasChanged: boolean) => {
    if (hasChanged) {
      dispatch(fetchDataSourceFields(selectedSource.id));
    }
  };

  const { isEditing, onEditEnd, onEditStart } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  const {
    isEditing: isEditingEntity,
    onEditEnd: onEditEntityEnd,
    onEditStart: onEditEntityStart,
  } = useEditableItems({
    shouldCleanupOnUmount: true,
  });

  const {
    fields,
    isLoading: isFieldsLoading,
    entities,
  } = useSelector(getEntityFields);
  const isUpdating = useSelector(getIsUpdating);

  const getEntityFromName = useCallback(
    (name: string) => entities.find((e) => e.name === name),
    [entities]
  );

  const onEntityUpdated = () => {
    dispatch(fetchDataSourceFields(selectedSource.id));
  };

  const selectedEntitiesIds = useSelector(getSelectedItemsIds);
  const searchQuery = useSelector(getSearchQuery);

  const { records: dataSources, isLoading: isDataSourcesLoading } =
    useSelector(getDataSources);

  // DERIVED STATE
  const isLoading = isFieldsLoading || isDataSourcesLoading;

  const filteredRecords = useMemo(
    () =>
      fields.filter(
        (field) =>
          searchQuery === '' || matchesSearchQuery(searchQuery, field.name)
      ),
    [fields, searchQuery]
  );

  const sourceFieldsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds,
  });

  const { handleItemClickedOnSingleSelection, selectedItem } =
    useItemSelections();

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: sourceFieldsWhichIncludesSelections,
      selectedItemId: selectedItem,
    });

  const { properties: entityFieldProperties } = useGetParsedTypeProperties({
    designId: selectedDesignId,
    typeId: TYPE_IDS.SourceEntityField,
  });

  const { properties: entityProperties } = useGetParsedTypeProperties({
    designId: selectedDesignId,
    typeId: TYPE_IDS.SourceEntity,
  });

  const entitySchema = useMemo(() => {
    // TODO: contact Stephan about adding the `sourceName` to the list of props displayed in the info box
    const schema = getSchemaFromTypeProperties(
      entityProperties,
      TYPE_IDS.SourceEntity
    );
    const insertIdx = schema.findIndex(
      (entry) => entry.key === DataSourceEntityKeys.name
    );
    schema.splice(insertIdx + 1, 0, {
      key: DataSourceEntityKeys.sourceName,
      translationPrefix: TYPE_IDS.SourceEntity,
    });
    // NOTE: source of the entity probably has to be added on the FE side
    // since it is not a direct property of an entity
    schema.splice(insertIdx + 2, 0, {
      key: ancestorSourceNameKey,
      name: t('entityTypes:Source'),
    });
    return schema;
  }, [entityProperties, t]);
  const entityFieldSchema = useMemo(() => {
    const schema = getSchemaFromTypeProperties(
      entityFieldProperties,
      TYPE_IDS.SourceEntityField
    );
    // TODO: contact Stephan about adding some of the missing attributes into the info box
    // NOTE: source of the entity probably has to be added on the FE side
    // since it is not a direct property of an entity

    const insertSourceAt = schema.findIndex(
      (entry) => entry.key === DataSourceEntityFieldKeys.sourceName
    );
    schema.splice(insertSourceAt + 1, 0, {
      key: ancestorSourceNameKey,
      name: t('entityTypes:Source'),
    });

    const insertTypeAt = schema.findIndex(
      (entry) => entry.key === DataSourceEntityFieldKeys.sourceDataType
    );
    schema.splice(insertTypeAt + 1, 0, {
      key: DataSourceEntityFieldKeys.dataType,
      translationPrefix: TYPE_IDS.SourceEntityField,
    });
    schema.splice(insertTypeAt + 2, 0, {
      key: DataSourceEntityFieldKeys.formatType,
      translationPrefix: TYPE_IDS.SourceEntityField,
    });

    return schema;
  }, [entityFieldProperties, t]);

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:catalog:data:cta:noDataIngested':
        dataSources.length === 0,
      'tutorialTiles:designer:catalog:data:cta:noSourceSelected':
        !selectedSource?.id,
    }),
    [dataSources.length, selectedSource?.id]
  );

  // CALLBACKS
  const handleRename = React.useCallback(
    (dataSourceId: string, newName: string) =>
      dispatch(
        updateEntity({
          entity: {
            id: dataSourceId,
            name: newName,
          },
          typeId: TYPE_IDS.SourceEntityField,
        })
      ),
    [dispatch]
  );

  const handleRenameEntity = React.useCallback(
    (entityId: string, newName: string) =>
      dispatch(
        updateEntity({
          entity: {
            id: entityId,
            name: newName,
          },
          typeId: TYPE_IDS.SourceEntity,
        })
      ),
    [dispatch]
  );

  const onClickEntityHandler = React.useCallback(
    (entityId: string) => {
      if (currentSort === DefinedSortTypes.Entity) {
        if (isEditingEntity) {
          return;
        }

        if (selectedItem) {
          // deselect fields when selecting entity
          handleItemClickedOnSingleSelection(selectedItem);
        }

        const id = getEntityFromName(entityId)?.id;

        dispatch(
          setSelectedEntityId(
            selectedEntityId && selectedEntityId === id ? null : id
          )
        );
      }
    },
    [
      dispatch,
      getEntityFromName,
      setSelectedEntityId,
      selectedEntityId,
      currentSort,
      isEditingEntity,
      selectedItem,
    ]
  );

  const handleClickItem = React.useCallback(
    (itemId: string) => {
      if (isEditingEntity || isEditing) return;

      // deselect entity when picking up field
      if (selectedEntityId) {
        dispatch(setSelectedEntityId(null));
      }

      handleItemClickedOnSingleSelection(itemId);
    },
    [
      selectedEntityId,
      isEditingEntity,
      isEditing,
      handleItemClickedOnSingleSelection,
    ]
  );

  // EFFECTS
  useEffect(() => {
    if (dataSources.length === 0) dispatch(fetchDataSources(selectedDesignId));
  }, [dataSources.length, selectedDesignId]);

  useEffect(() => {
    if (selectedSource?.id) dispatch(fetchDataSourceFields(selectedSource.id));
  }, [dispatch, selectedSource?.id]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const renderField = (field: IDesignSourceEntityField) =>
    field ? (
      <EntityField
        key={field.id}
        info={{
          data: {
            ...field,
            [ancestorSourceNameKey]: selectedSource.name,
          },
          schema: entityFieldSchema,
          enableInlineEdit: true,
          title: (
            <DataSourceCalloutTitle
              name={field.name}
              color={`#${selectedSource.color.toString(16)}`}
            />
          ),
          onEntityUpdated,
        }}
        isSelected={selectedItem === field.id}
        onClick={() => handleClickItem(field.id)}
        slot1={<EntityFieldSlotSpacer />}
        data-testid={`catalog-data-field-${field.name}`}
      >
        <EditableFoldableListItem
          text={field.name}
          onEdit={(newVal) => handleRename(field.id, newVal)}
          isSelected={field.id === selectedItem}
          onEditStart={() => {
            const isSelected = field.id === selectedItem;
            // for consistency mark source as selected when editing
            if (!isSelected) {
              handleClickItem(field.id);
            }
            onEditStart();
          }}
          onEditEnd={onEditEnd}
          transparentBackground
          disableEditing={isEditing}
          searchQuery={searchQuery}
        />
      </EntityField>
    ) : null;

  // RENDER
  if (isLoading || isUpdating) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (currentSort === DefinedSortTypes.Name) {
    const sortedFields = [...fields].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return <>{sortedFields.map((field) => renderField(field))}</>;
  }

  const isSelectedEntity = (name: string) => {
    const groupName = getEntityFromName(name)?.id;

    return (
      selectedEntityId === groupName && currentSort === DefinedSortTypes.Entity
    );
  };

  const renderItem = (item: IFoldableListItem) => {
    const field = fields.find((f) => f.id === item.key);

    return renderField(field);
  };

  const renderGroupName = (group: IFoldableListGroup) => {
    if (currentSort === DefinedSortTypes.Entity) {
      return (
        <EditableFoldableListItem
          text={group.name}
          onClick={() => onClickEntityHandler(group.key)}
          onEdit={async (newVal) => {
            const id = getEntityFromName(group.key)?.id;

            if (id) {
              await handleRenameEntity(id, newVal);
              dispatch(fetchDataSourceFields(selectedSource.id));
            }
          }}
          isSelected={group.key === selectedEntityId}
          onEditStart={() => {
            onClickEntityHandler(group.key);
            onEditEntityStart();
          }}
          onEditEnd={onEditEntityEnd}
          transparentBackground
          isGroupHeader
          disableEditing={isEditingEntity}
          searchQuery={searchQuery}
        />
      );
    }

    return group.name;
  };

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen || searchQuery !== ''}
          groupName={renderGroupName(group)}
          onTextClick={() => onClickEntityHandler(group.key)}
          isSelected={isSelectedEntity(group.key)}
          onIconClick={onClickHeaderHandler}
          info={{
            schema: entitySchema,
            data: {
              ...getEntityFromName(group.key),
              [ancestorSourceNameKey]: selectedSource.name,
            },
            title: (
              <DataSourceCalloutTitle
                name={group.name}
                color={`#${selectedSource.color.toString(16)}`}
              />
            ),
            onEntityUpdated,
          }}
        >
          {group.items.map((item) => renderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default CatalogData;
