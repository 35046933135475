import { IconButton, IButtonStyles, TooltipHost } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

interface Props {
  styles?: IButtonStyles
  textToCopy: string
}

const CopyButton = ({ textToCopy, styles }: Props) => {
  // HOOKS
  const { t } = useTranslation('errors');
  // STATE
  const [hasCopied, setCopied] = React.useState(false);

  // DEPS
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  const ANIMATION_TIME = 1500;

  // CALLBACKS
  const handleCopy = () => {
    copy(textToCopy);
    setCopied(true);
    timeoutRef.current = setTimeout(
      () => setCopied(false),
      ANIMATION_TIME,
    );
  };

  // EFFECTS
  React.useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    [],
  );

  return (
    <>
      <TooltipHost
        content={hasCopied ? t('copyDetailsSuccess') : t('copyToClipboard')}
      >

        <IconButton
          styles={styles}
          iconProps={{ iconName: hasCopied ? 'DocumentApproval' : 'Copy' }}
          onClick={handleCopy}
        />
      </TooltipHost>
    </>
  );
};

export default CopyButton;
