import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from './TutorialAnimation';

export const TUTORIAL_TILE_KEY = 'notifications-errors';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:notifications:errors:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:notifications:errors:items:errors:description',
      icon: 'Error',
      titleI18nKey: 'tutorialTiles:notifications:errors:items:errors:title',
    },
  ]
};
