/* eslint-disable no-param-reassign */
import { getAggregations } from '../../../constants/aggregations';
import {
  DRILLING_DIRECTIONS, MAX_COLUMN_WIDTH, MIN_COLUMN_WIDTH, PARENT_DRILLING,
} from '../../../modules/GroupTable/constants';
import { IGroupTable } from '../../../modules/GroupTable/types';
import { getGroupTableTitles, getIsColumnLayout, getValueFormat } from '../../../modules/GroupTable/utils';
import { baseColors } from '../../../theme';
import { IColumn } from '../../../types/IColumn';
import { IDataset } from '../../../types/IDataset';
import getDatasetColumnByColumnUuid from '../../../utils/getDatasetColumnByColumnUuid';
import {
  colorsDrillingColumns,
  colorsDrillingRows,
  COLUMN_HEADER_COLOR_CHANGE_THRESHOLD,
  topGroupTableColors,
} from '../constants';
import {
  columnLettersToNumber, getCellColumnRowPair, getFormat, getWidthFactor, nestedSerializeObjectKeys,
} from '../utils';
import { getMatrixRangeAreas, IGetMatrixRangeAreas } from './getMatrixRangeAreas';

/**
 * This formats the matrix rendered rows using Range Areas based on the drilling levels
 */

export const formatMatrixRows = (
  params: Omit<IGetMatrixRangeAreas, 'direction'>,
) => {
  const rangeAreas = getMatrixRangeAreas({
    ...params, direction: DRILLING_DIRECTIONS.rowDrilling,
  });
  rangeAreas.forEach((areas, level) => {
    areas.set({
      format: {
        fill: { color: colorsDrillingRows[level] || baseColors.white },
        font: { color: baseColors.offBlack },
      },
    });
    areas.untrack();
  });
};

/**
 * This formats the matrix rendered columns Range Areas based on the drilling levels
 */

export const formatMatrixColumns = (
  params: Omit<IGetMatrixRangeAreas, 'direction'>,
) => {
  const rangeAreas = getMatrixRangeAreas({
    ...params, direction: DRILLING_DIRECTIONS.columnDrilling,
  });
  rangeAreas.forEach((areas, level) => {
    areas.set({
      format: {
        fill: { color: colorsDrillingColumns[level] || baseColors.white },
        // To improve readability on deep drilled levels,
        // we change the text color from white to black
        font: {
          color: level > COLUMN_HEADER_COLOR_CHANGE_THRESHOLD
            ? baseColors.offBlack
            : baseColors.white,
        },
      },
    });
    areas.untrack();
  });
};

/**
 * Gets the right "number format" for each element in the matrix grid (headers, values, and totals),
 * considering the section type hierarchy (Columns -> Rows ->  Values)
 *
 * @param groupTable
 * @param dataset
 */

export const getMatrixNumberFormat = (
  groupTable: IGroupTable,
  dataset: IDataset,
) => {
  const columnsChildrenFirst = dataset.options.columnParentDrilling === PARENT_DRILLING.after;
  const rowsChildrenFirst = dataset.options.rowParentDrilling === PARENT_DRILLING.after;
  const { rowTitles, columnTitles } = getGroupTableTitles(groupTable.data);
  const rowNodes = nestedSerializeObjectKeys(rowTitles, rowsChildrenFirst);
  const columnNodes = nestedSerializeObjectKeys(columnTitles, columnsChildrenFirst);
  const valuesCount = groupTable.values.length;
  const filler = Array(valuesCount - 1).fill(null);
  const isColumnLayout = getIsColumnLayout(groupTable);
  const getColumn = (columnUuid: string) => getDatasetColumnByColumnUuid({
    columnUuid,
    dataset,
  });

  const rows: string[][] = [];
  let columns: string[] = [];
  let values: string[][] = [];

  // STEP 1. Iterate over each and every value, and determine its number format

  for (let r = 0; r < rowNodes.length; r += 1) {
    const rowDrilling = rowNodes[r].length;
    const row = getColumn(groupTable.rows[rowDrilling - 1]?.columnUuid);
    // STEP 1.1 Since we're already iterating over every row and column headers,
    // get get their formats at the same time. Here we get them for rows.
    rows.push([getFormat(row)]);
    const valuesRow: string[] = [];

    for (let c = 0; c <= columnNodes.length; c += 1) {
      let column: IColumn = null;
      if (isColumnLayout) {
        if (c === columnNodes.length) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const columnDrilling = columnNodes[c].length;
        column = getColumn(groupTable.columns[columnDrilling - 1]?.columnUuid);
        // And here for columns
        if (r === 0) {
          columns = [
            ...columns,
            getFormat(column),
            ...filler,
          ];
        }
      }

      for (let v = 0; v < groupTable.values.length; v += 1) {
        const value = getColumn(groupTable.values[v]?.columnUuid);
        valuesRow.push(getValueFormat(row, column, value));
      }
    }

    values.push(valuesRow);
  }

  // STEP 2. If the current table has column totals, and is currently rendering any columns
  // we get the "number format" for each column total

  if (groupTable.totals.columns && isColumnLayout) {
    const totals = rowNodes.map((path) => {
      const rowDrilling = path.length;
      const row = getColumn(groupTable.rows[rowDrilling - 1]?.columnUuid);
      return groupTable.values.map(({ columnUuid }) => getValueFormat(
        row, null, getColumn(columnUuid),
      ));
    });

    values = values.map((value, i) => [...value, ...totals[i]]);
    columns = [
      ...columns,
      null,
      ...filler,
    ];
  }

  // STEP 3. If the current table has row totals, we get the "number format" for each row total

  if (groupTable.totals.rows) {
    const totals = columnNodes.map((path) => {
      const columnDrilling = path.length;
      const column = getColumn(groupTable.columns[columnDrilling - 1]?.columnUuid);
      return groupTable.values.map(({ columnUuid }) => getValueFormat(
        null, column, getColumn(columnUuid),
      ));
    });

    // STEP 3.1 If column and row totals are enabled, we get the cross totals "number format" too

    if (groupTable.totals.columns || !isColumnLayout) {
      totals.push(groupTable.values.map(({ columnUuid }) => getFormat(getColumn(columnUuid))));
    }
    values.push([...totals.flat()]);
    rows.push([null]);
  }

  // STEP 4. Generate and return a grid of "number formats" that matches the current dataset grid

  let grid: string[][] = isColumnLayout
    ? [
      [null, ...columns],
      [null, ...[...columns].fill(null)],
    ]
    : [
      [null, ...Array(valuesCount).fill(null)],
    ];

  grid = [
    ...grid,
    ...rows.map((row, i) => [...row, ...values[i]]),
  ];

  return grid;
};

export const freezePanes = (
  worksheet: Excel.Worksheet,
  address: string,
  isColumnLayout: boolean,
) => {
  const [addressColumn, addressRow] = getCellColumnRowPair(address);
  worksheet.freezePanes.freezeColumns(columnLettersToNumber(addressColumn));
  worksheet.freezePanes.freezeRows(
    isColumnLayout ? +addressRow + 1 : +addressRow,
  );
};

/**
 * Formats the value headers and column width based on the text lenght
 * @param groupTable The table that will be formatted
 * @param dataset The table's dataset
 * @param range The range of the rendered table
 * @param gridSize Size (rows x columns) of the rendered table
 */

export const formatValueHeaders = (
  groupTable: IGroupTable,
  dataset: IDataset,
  range: Excel.Range,
  gridSize:[number, number],
) => {
  const isColumnLayout = getIsColumnLayout(groupTable);
  const aggregations = getAggregations();
  const titlePairs = groupTable.values.map(({ columnUuid, text }) => [
    aggregations[
      getDatasetColumnByColumnUuid({ columnUuid, dataset }).aggregation
    ].label,
    text,
  ]);

  const headerRange = range.getRow(isColumnLayout ? 1 : 0);
  const colCount = gridSize[1];

  // We start at 1 to exclude the first column, which should not be formatted here.

  for (let colIndex = 1; colIndex < colCount; colIndex += 1) {
    // eslint-disable-next-line no-loop-func
    titlePairs.forEach((pair) => {
      const length = Math.max(pair[0].length, pair[1].length);
      const width = length * getWidthFactor(length);
      const columnWidth = Math.max(MIN_COLUMN_WIDTH, Math.min(width, MAX_COLUMN_WIDTH));
      const column = headerRange.getColumn(colIndex);
      column.format.set({
        columnWidth,
        rowHeight: 17,
        verticalAlignment: Excel.VerticalAlignment.bottom,
      });
      column.untrack();
    });
  }
  headerRange.untrack();
};

export type TFormatMatrix = Omit<IGetMatrixRangeAreas, 'direction'> & { range: Excel.Range };

/**
 * Formats a matrix that has been already rendered into the worksheet
 */

export const formatMatrix = (
  { range, ...params }: TFormatMatrix,
) => {
  const {
    groupTable, dataset, worksheet, address,
  } = params;
  const valuesCount = groupTable.values.length;

  // STEP 1. Adds the propper "number format" to every element in the grid

  const numberFormat = getMatrixNumberFormat(groupTable, dataset);
  range.set({ numberFormat });

  // STEP 2. Adds the formatting based on the drilling levels

  formatMatrixRows(params);

  // STEP 3. Adds white borders to every column in the group table, to each side,

  range.format.borders.getItem('InsideVertical').style = Excel.BorderLineStyle.continuous;
  range.format.borders.getItem('InsideVertical').color = baseColors.white;

  const isColumnLayout = getIsColumnLayout(groupTable);

  // STEP 4. Final styling to the table, based on the layout type (with and without columns)

  range.getRowsAbove(isColumnLayout ? -2 : -1)
    .format.horizontalAlignment = Excel.HorizontalAlignment.center;

  if (isColumnLayout) {
    range.getCell(0, 0).format.set(topGroupTableColors);
    formatMatrixColumns(params);
    if (groupTable.totals.columns) {
      range.getColumnsAfter(-valuesCount).getRow(0).format.set(topGroupTableColors);
    }
  } else {
    range.getRow(0).format.set(topGroupTableColors);
  }

  formatValueHeaders(groupTable, dataset, range, params.gridSize);

  if (groupTable.totals.rows) {
    range.getRowsBelow(-1).format.set(topGroupTableColors);
  }

  range.getColumn(0).format.autofitColumns();

  if (dataset.options.freezePanes) {
    freezePanes(worksheet, address, isColumnLayout);
  }
};
