import { IColumn } from '../../../../types/IColumn';
import { IDropdownColumn } from '../../components/SingleItem/SingleItem';

export const columnsToOptions = (columns : IColumn[]):IDropdownColumn[] => {
  const options = columns.reduce<IDropdownColumn[]>((arr, column) => {
    if (!arr.some((option) => option.key === column.id)) {
      return [...arr, {
        text: column.name,
        key: column.id,
        data: {
          column,
        },
      }];
    }
    return arr;
  }, []);

  // return [...options, { key: 'total', text: 'Total', data: { column: null} }];

  return options;
};
