import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import BuilderStreamShare from '../BuilderStreamShare';
import DesignShare from '../DesignShare'

const IndexPage = () => (
  <Switch>
    <Route path={routes.share.stream}>
      <BuilderStreamShare />
    </Route>
    <Route path={routes.share.design}>
      <DesignShare />
    </Route>
  </Switch>
);

export default IndexPage;
