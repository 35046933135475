import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { routes } from '../../../../constants/routes';

import Streams from '../../../Streams/screens/IndexPage';
import Favorites from '../../../Favorites/screens/Index';
import streamSelectors from '../../../Streams/selectors';
import { constant } from '../../../../../shared/types/utils';

const FAVORITES_PATHS: readonly string[] = constant<string[]>()([
  routes.favorites.recentStreams.index,
  routes.favorites.sharedWithMe.index,
]);

const UserFlow = () => {
  const history = useHistory();
  const location = useLocation();

  const currenetStreamId = useSelector(streamSelectors.getCurrentStreamId);
  const currentDatasetId = useSelector(streamSelectors.getCurrentDatasetId);
  const recentStreams = useSelector(streamSelectors.getRecentStreams);

  useEffect(() => {
    if (
      (currenetStreamId && !currentDatasetId) ||
      (!currenetStreamId && currentDatasetId)
    ) {
      return;
    }

    if (!currenetStreamId && !currentDatasetId) {
      const newPath =
        recentStreams?.length > 0
          ? routes.favorites.recentStreams.index
          : routes.favorites.sharedWithMe.index;

      if (newPath !== location.pathname) {
        history.push(newPath);
      }
    } else if (FAVORITES_PATHS.includes(location.pathname)) {
      history.push(routes.streams.list.index);
    }
  }, [currentDatasetId, currenetStreamId]);

  return (
    <Switch>
      <Route path={routes.favorites.index} component={Favorites} />
      <Route path={routes.streams.index} component={Streams} />
    </Switch>
  );
};

export default UserFlow;
