import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import InformationDialog from '../ui/InformationDialog';

import RangeInputSection from './components/RangeInputSection';
import ItemSelect, { OptionGroup, Option } from './components/ItemSelect';
import ComparisonSelect from './components/ComparisonSelect';
import ComparisonValue from './components/ComparisonValue';
import ValuesSelect from './components/ValuesSelect';
import { IDesignerFilter } from '../../types/IDesignerFilter';

export const formFilterDefault: IDesignerFilter = {
  comparison: null,
  values: [],
  lowerBoundValue: '',
  upperBoundValue: '',
  id: null,
  item: null,
  aggregation: null,
};

export interface FilterFormProps {
  onSubmit(values: IDesignerFilter): void;
  target: string;
  calloutWidth: number;
  defaultValues?: IDesignerFilter;
  onClose(isValid?: boolean): void;
  loadItemOptions?(
    inputValue?: string
  ): Promise<Array<OptionGroup>> | Promise<Array<Option>>;
  loadValuesOptions(item: any, inputValue: string): Promise<Array<string>>;
  title?: string;
  isCalloutVisible?: boolean;
  acceptsTypedValues?: boolean | ((item?: any) => boolean);
  itemOptionsDefaultValues?: Array<OptionGroup> | Array<Option>;
  hideAggregationPicker?: boolean;
}

const FilterForm: React.FC<FilterFormProps> = ({
  onSubmit,
  target,
  calloutWidth,
  defaultValues,
  onClose,
  loadItemOptions,
  loadValuesOptions,
  isCalloutVisible = true,
  title,
  acceptsTypedValues: acceptsTypedValuesProp,
  children,
  itemOptionsDefaultValues,
  hideAggregationPicker,
}) => {
  const { handleSubmit, watch, formState, register, trigger } =
    useFormContext();

  useEffect(() => {
    trigger();
  }, []);

  const watchedValues = watch();

  const { comparison, item, compareValue } = watchedValues;

  const handleSuccess = () => {
    handleSubmit(onSubmit)();
  };

  const handleLoadValueOptions = (valueInput: string) =>
    loadValuesOptions(item, valueInput);

  const isEditing = !!defaultValues?.id;
  const { isValid, isDirty } = formState;

  const acceptsTypedValues =
    typeof acceptsTypedValuesProp === 'function'
      ? acceptsTypedValuesProp(item)
      : acceptsTypedValuesProp;

  return (
    <InformationDialog
      target={target}
      // filter form has to be closed explicitly
      // to avoid unexpected behavior on touch screens
      onDismiss={() => null}
      onClose={() => onClose(isValid)}
      calloutWidth={calloutWidth}
      isCalloutVisible={isCalloutVisible}
      labelId="newFilter"
      onApprove={isValid && isDirty ? handleSuccess : null}
      descriptionId="newFilterDescription"
      title={title || 'Condition'}
    >
      <form onSubmit={handleSubmit(onSubmit)} data-testid="new-filter-form">
        {children}
        {loadItemOptions ? (
          <ItemSelect
            isEditing={isEditing}
            loadOptions={loadItemOptions}
            itemOptionsDefaultValues={itemOptionsDefaultValues}
            hideAggregationPicker={hideAggregationPicker}
          />
        ) : (
          <input type="hidden" name="item" ref={register} />
        )}

        <ComparisonSelect item={item} />

        <ComparisonValue
          {...{
            acceptsTypedValues,
            compareValue,
            comparison,
            item,
          }}
          loadOptions={handleLoadValueOptions}
        />

        <RangeInputSection comparison={comparison} item={item} />

        <ValuesSelect
          acceptsTypedValues={acceptsTypedValues}
          comparison={comparison}
          loadOptions={handleLoadValueOptions}
        />

        <input type="hidden" name="id" ref={register} />
      </form>
    </InformationDialog>
  );
};

export default FilterForm;
