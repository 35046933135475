export const FILTER_TYPES = {
  Values: 'Values',
  Comparison: 'Comparison',
  Range: 'Range',
};

export const COMPARISON_TYPES = {
  Equal: 'Equal',
  NotEqual: 'NotEqual',
  LessThan: 'LessThan',
  LessThanOrEqual: 'LessThanOrEqual',
  GreaterThan: 'GreaterThan',
  GreaterThanOrEqual: 'GreaterThanOrEqual',
  Contains: 'Contains',
  StartsWith: 'StartsWith',
  EndsWith: 'EndsWith',
  IsNull: 'IsNull',
  IsNotNull: 'IsNotNull',
};
