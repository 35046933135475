import React from 'react';
import { Text, Icon } from '@fluentui/react';
import cn from 'classnames';
import { classNames } from './styles';

import './styles.css';

interface Props {
  itemName: string;
  leftSlotIcon?: string;
  rightSlotComponent?: React.ReactNode;
  leftSlotComponent?: React.ReactNode;
  isSelected?: boolean;
  isGhosting?: boolean;
  isQueued?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  shouldShowRightSlotOnlyOnHover?: boolean;
}

const StreamItem = (props: Props & React.HTMLAttributes<HTMLOrSVGElement>) => {
  const {
    children,
    className: divClassNameOverride,
    isGhosting,
    isSelected,
    isHidden,
    isQueued,
    itemName,
    leftSlotIcon,
    rightSlotComponent,
    shouldShowRightSlotOnlyOnHover,
    leftSlotComponent,
    isDisabled,
    ...rest
  } = props;

  const isHoverable = typeof props?.onClick === 'function';

  return (
    <div
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
        // className={`${classNames.wrapper} ${className ?? ''}`}
      className={cn(
        classNames.wrapper,
        divClassNameOverride,
        'hidingWrapper',
        {
          [classNames.hoverable]: isHoverable && !isSelected,
          [classNames.wrapperGhosting]: isGhosting,
          [classNames.wrapperHidden]: isHidden,
          [classNames.wrapperSelected]: isSelected,
          [classNames.wrapperSelectedHoverable]: isSelected && isHoverable,
          [classNames.disabledWrapper]: isDisabled,
        },
      )}
    >
      <div className={classNames.leftSlot}>
        {leftSlotComponent}
        {leftSlotIcon && (
        <Icon className={cn(classNames.icon, { [classNames.textQueued]: isQueued })} iconName={leftSlotIcon} />
        )}
      </div>

      <Text
        as="p"
        className={cn(classNames.text, {
          [classNames.textWhenRightSlotComponent]: !!rightSlotComponent,
          [classNames.textQueued]: isQueued,
        })}
      >
        {itemName}
      </Text>

      {rightSlotComponent && (
      <div
        className={cn(classNames.rightSlot, {
          hide: shouldShowRightSlotOnlyOnHover,
        })}
      >
        {rightSlotComponent}
      </div>
      )}
    </div>
  );
};

export default StreamItem;
