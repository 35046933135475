import { createSelector } from 'reselect';
import { ApplicationState } from '../../../store/types';
import { isFieldSelected } from './components/DataSourceEntity/utils';
import { IDesignSource } from '../types';
import { DesignerIngestionState } from './types';
import { Link } from '../../../api/model/schemas/Link';

export const getIngestionState = (
  state: ApplicationState
): DesignerIngestionState => state.designerIngestion;

export const getAllSources = (state: ApplicationState) =>
  getIngestionState(state).sources;

export const getAllSourcesSortedAscending = createSelector(
  getAllSources,
  (sources) => {
    const sortedSources = [...sources].sort((dataSource1, dataSource2) =>
      dataSource1.name.localeCompare(dataSource2.name)
    );

    return sortedSources;
  }
);

export const getAllIngestionFilters = (state: ApplicationState) =>
  getIngestionState(state).ingestionFilters;

export const createGetSourceData = () =>
  createSelector(
    getAllSources,
    (_, id: IDesignSource['id']) => id,
    (sources, id) => sources.find((source) => source.id === id)
  );

export const getIsLoadingFilters = (state: ApplicationState) =>
  getIngestionState(state).isLoadingFilters;

export const getAllEntitiesWithValues = (state: ApplicationState) =>
  getIngestionState(state).entitiesWithValues;

export const getIsLoadingSources = (state: ApplicationState) =>
  getIngestionState(state).isLoadingSources;
export const getSelectedSourceId = (state: ApplicationState) =>
  getIngestionState(state).selectedSourceId;

export const getSingleSelectedSourceId = (state: ApplicationState) =>
  getSelectedSourceId(state);

export const getSelectedSource = (state: ApplicationState) => {
  const sources = getAllSources(state);
  const selectedId = getSelectedSourceId(state);

  return sources?.find((source) => source.id === selectedId);
};

export const getDataSourceEntities = (state: ApplicationState) =>
  getIngestionState(state).dataSourceEntities;

export const getDataSourceEntitiesNextPageUrl = (state: ApplicationState) =>
  getIngestionState(state).dataSourceEntitiesNextPageUrl;

export const getDataSourceFields = (state: ApplicationState) =>
  getIngestionState(state).dataSourceFields;

export const getSelectedDataSourceFields = createSelector(
  getDataSourceFields,
  (fields) =>
    fields
      .filter((field) => isFieldSelected(field.ingestionStatus))
      .map((field) => field.id)
);

export const getDataSourceFieldById =
  (id: string) => (state: ApplicationState) => {
    const sourceFields = getDataSourceFields(state);

    return sourceFields.find((field) => field.id === id);
  };

export const getIsLoadingDataSourceFields = (state: ApplicationState) =>
  getIngestionState(state).isLoadingDataSourceFields;
export const getAreFieldsUpdating = (state: ApplicationState) =>
  getIngestionState(state).areFieldsUpdating;
export const getAllLinks = (state: ApplicationState) =>
  getIngestionState(state).links;
export const getLinksFields = (state: ApplicationState) =>
  getIngestionState(state).linksFields;
export const getLinkedEntities = (state: ApplicationState) =>
  getIngestionState(state).linkedEntities;
export const getLinkedEntityFields = (state: ApplicationState) =>
  getIngestionState(state).linkedEntityFields;
export const getIsLoadingLinks = (state: ApplicationState) =>
  getIngestionState(state).isLoadingLinks;
export const getIsConfiguringDataSource = (state: ApplicationState) =>
  getIngestionState(state).isConfiguringDataSource;
export const getIsIngestingDataSource = (state: ApplicationState) =>
  getIngestionState(state).isIngestingDataSource;
export const getSelectedLinkFieldsId = (state: ApplicationState) =>
  getIngestionState(state).selectedLinkFieldsId;
export const getSelectedEmptyLinkId = (state: ApplicationState) =>
  getIngestionState(state).selectedEmptyLinkId;
export const getIsSmartAnalysisLinksInProgress = (state: ApplicationState) =>
  getIngestionState(state).isSmartAnalysisLinksInProgress;
export const getSelectedLinkFields = createSelector(
  [getIngestionState, getSelectedLinkFieldsId],
  ({ linksFields }, selectedLinkFieldsId) => {
    if (!selectedLinkFieldsId) return null;

    return linksFields[selectedLinkFieldsId];
  }
);
export const getLinksWithoutLinkFields = createSelector(
  getAllLinks,
  getLinksFields,
  (links, linksFields) => {
    const nonEmptyLinkIds = Object.values(linksFields).reduce(
      (ids, linkFields) => ids.add(linkFields.parentId),
      new Set<Link['id']>()
    );

    return Object.values(links).filter((link) => !nonEmptyLinkIds.has(link.id));
  }
);

export const getSelectedEntityCount = (state: ApplicationState) =>
  getIngestionState(state).selectedEntitiesCountInDataSource;

export const getDataSourceTransforms = (state: ApplicationState) =>
  getIngestionState(state).transforms;
