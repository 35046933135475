import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getSortType } from '../../../App/selectors';
import { DefinedSortTypes } from '../../../App/types';
import FiltersList from '../../../StreamFilters/components/FilterList';
import ValuesList from '../ValuesList';

const FiltersPage: FunctionComponent = () => {
  const sortType = useSelector(getSortType);

  if (sortType === DefinedSortTypes.Logic) {
    return <FiltersList />;
  }

  if (sortType === DefinedSortTypes.Value) {
    return <ValuesList />;
  }

  return null;
};

export default FiltersPage;
