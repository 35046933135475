import { IStackStyles } from '@fluentui/react';

export const wrapStyles: IStackStyles = {
  root: {
    width: 32,
    justifyContent: 'center',
    alignItems: 'center',
    selectors: {
      '&>button': {
        flexGrow: 1,
        alignSelf: 'stretch',
        width: 'auto',
      },
    },
  },
};
