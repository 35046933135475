import { getAggregations } from '../../../constants/aggregations';
import { PARENT_DRILLING } from '../../../modules/GroupTable/constants';
import { GroupTableTotals, IGroupTable } from '../../../modules/GroupTable/types';
import { getGroupTableTitles, getIsColumnLayout, getRenderableValues } from '../../../modules/GroupTable/utils';
import { IDataset } from '../../../types/IDataset';
import getDatasetColumnByColumnUuid from '../../../utils/getDatasetColumnByColumnUuid';
import { reportError, serializeObjectKeys } from '../utils';
import { formatMatrix } from './formatMatrix';

const gerateMatrixHeaders = (
  groupTable: IGroupTable,
  dataset: IDataset,
) => {
  const aggregations = getAggregations();

  const isColumnLayout = getIsColumnLayout(groupTable);
  const columnsChildrenFirst = dataset.options.columnParentDrilling === PARENT_DRILLING.after;
  const rowsChildrenFirst = dataset.options.rowParentDrilling === PARENT_DRILLING.after;
  const { rowTitles, columnTitles } = getGroupTableTitles(groupTable.data);

  let serializedColumnTitles = serializeObjectKeys(columnTitles, columnsChildrenFirst);
  serializedColumnTitles = serializedColumnTitles.length ? serializedColumnTitles : [''];

  // Add column totals header
  if (isColumnLayout && groupTable.totals.columns) {
    serializedColumnTitles.push('Total');
  }

  const valuesTitles = groupTable.values.map(({ text, columnUuid }) => `${aggregations[
    getDatasetColumnByColumnUuid({ columnUuid, dataset }).aggregation
  ].label}\n${text}`);
  const rowsHeader = groupTable.rows.map((r) => r.text).join(' | ');
  const columnHeaders = serializedColumnTitles.flatMap((title) => [title, ...Array(Math.max(0, valuesTitles.length - 1)).fill('')]);
  const valuesHeaders = serializedColumnTitles.flatMap(() => valuesTitles);

  const topHeaders = isColumnLayout ? [
    [rowsHeader, ...columnHeaders],
    ['', ...valuesHeaders],
  ] : [
    [rowsHeader, ...valuesHeaders],
  ];

  const leftHeaders = serializeObjectKeys(rowTitles, rowsChildrenFirst);

  return {
    topHeaders,
    leftHeaders,
  };
};

const generateMatrixBody = (
  groupTable: IGroupTable,
  dataset: IDataset,
  {
    rowTotals,
    columnTotals,
    crossTotals,
  }: GroupTableTotals,
) => {
  const {
    topHeaders,
    leftHeaders,
  } = gerateMatrixHeaders(groupTable, dataset);

  const values = getRenderableValues({
    table: groupTable,
    dataset,
  });
  const grid = [
    ...topHeaders,
    ...leftHeaders.map((row, i) => [
      row,
      ...values[i],
      ...columnTotals?.[i] || [],
    ]),
  ];

  if (groupTable.totals.rows) {
    grid.push(['Total', ...rowTotals[0], ...crossTotals]);
  }

  return grid;
};

export const mapDatasetAsMatrix = (
  groupTable: IGroupTable,
  dataset: IDataset,
  totals: GroupTableTotals,
) => {
  const grid = generateMatrixBody(groupTable, dataset, totals);

  return Excel.run({ delayForCellEdit: true }, async (ctx) => {
    try {
      let range: Excel.Range;
      let worksheet = ctx.workbook.worksheets.getActiveWorksheet();
      const gridSize: [number, number] = [grid.length, grid[0].length];

      if (dataset.tableId) {
        /**
       * If table exists, delete mapped table and data.
       */
        const table = worksheet.tables.getItem(dataset.tableId);
        range = table.getRange().getAbsoluteResizedRange(...gridSize).load('address');
        table.delete();
        range.clear();
      } else {
        /**
         * If table doesn't exist, create it in the current sheet (if empty), otherwise, create
         * it in a new sheet.
         */
        const worksheetRange = worksheet.getRange().getUsedRangeOrNullObject();
        await ctx.sync();
        if (!worksheetRange.isNullObject) {
          worksheet = ctx.workbook.worksheets.add();
          worksheet.activate();
          range = worksheet.getRange()
            .getAbsoluteResizedRange(...gridSize).load('address');
        } else {
          range = ctx.workbook.getSelectedRange()
            .getAbsoluteResizedRange(...gridSize).load('address');
        }
      }

      await ctx.sync();

      range.set({
        values: grid,
      });

      formatMatrix({
        range,
        address: range.address,
        groupTable,
        dataset,
        gridSize,
        worksheet,
      });

      const table = worksheet.tables.add(range, false);
      table.load('id');
      table.showHeaders = false;
      range.getRowsAbove(1).delete(Excel.DeleteShiftDirection.up);

      await ctx.sync();

      return {
        tableId: table.id,
      };
    } catch (e) {
      console.error(e);
      reportError(e);
      return {
        tableId: null,
      };
    }
  });
};
