import React from 'react';
import {
  MessageBar,
  MessageBarType,
  Stack,
  Spinner,
  Icon,
  Label,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { IGenericFormField } from '../../../../types';
import { PrimaryButton } from '../../../../../../../components/ui';
import {
  TRANSLATION_PREFIX,
  FieldNames,
  WATCHED_FIELD_NAMES,
} from '../constants';
import {
  BUTTON_ICON_STYLES,
  STACK_TOKENS,
  CONNECT_BUTTON_STYLES,
} from '../styles';
import { OAuthConnetorConfiguration, Option } from '../models';
import { connectOAuth, fetchOAuthConfiguration } from '../utils';
import { defaultSelectedScopeIds } from './ScopeDropdown';
import { OAuthScope } from '../../../../../../../api/model/schemas/OAuthScope';
import { OAuthScopeSelect } from './OAuthScopeSelect';

const compareScopes = (a: OAuthScope, b: OAuthScope) =>
  a.id.localeCompare(b.id);

const scopeToOption = ({ id }: OAuthScope): Option => ({
  label: id,
  value: id,
});

export const OAuthControl: React.FC<IGenericFormField> = ({
  currentForm,
  label,
}) => {
  // HOOKS
  const { t } = useTranslation();
  // TODO: after upgrading to react-hook-form@7+ we should be able to
  // clean this up a bit by removing the dummy `input type="hidden"`
  const { register, setValue, watch } = useFormContext();

  // STATE
  const [isLoading, setIsLoading] = React.useState(false);
  const [oauthConfiguration, setOAuthConfiguration] =
    React.useState<OAuthConnetorConfiguration>();
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const watchedFields = watch(WATCHED_FIELD_NAMES);
  const selectedScopeIds: string[] =
    (currentForm.data[FieldNames.SCOPES] as string[]) ??
    defaultSelectedScopeIds;

  // DERIVED STATE
  const isInitialized = Boolean(oauthConfiguration);
  const isPending = isLoading || isConnecting;
  const isDisabled = !isInitialized || isPending;
  const isValuePresent =
    watchedFields.accessToken || watchedFields.refreshToken;
  const oauthConfigurationTypeId = currentForm.typeId;
  // scopes
  const { helpUri, scopes = [] } = oauthConfiguration?.oAuthProperties ?? {};
  const optionalScopes = React.useMemo(
    () => scopes.filter((scope) => !scope.required),
    [scopes]
  );
  const scopeOptions: Option[] = React.useMemo(
    () => optionalScopes.sort(compareScopes).map(scopeToOption),
    [optionalScopes]
  );

  // CALLBACKS
  const handleError = React.useCallback((err: unknown) => {
    console.error(err);

    const message = typeof err === 'string' ? err : (err as any).message;

    setError(message ?? t(`${TRANSLATION_PREFIX}.errors.unknown`));
  }, []);

  const handleOauthConnect = React.useCallback(async () => {
    try {
      setIsConnecting(true);

      const connectionResult = await connectOAuth({
        oauthConfigurationTypeId,
        selectedScopeIds,
      });

      if (!connectionResult) return;

      const { accessToken, refreshToken } = connectionResult;

      setValue(FieldNames.ACCESS_TOKEN, accessToken);
      setValue(FieldNames.REFRESH_TOKEN, refreshToken);
    } catch (err) {
      handleError(err);
    } finally {
      setIsConnecting(false);
    }
  }, [handleError, oauthConfigurationTypeId, selectedScopeIds, t]);

  // EFFECTS
  const loadConfiguration = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const configuration = await fetchOAuthConfiguration({
        oauthConfigurationTypeId,
      });

      setOAuthConfiguration(configuration);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }, [handleError, oauthConfigurationTypeId]);
  React.useEffect(() => {
    loadConfiguration();
  }, [loadConfiguration]);

  // PARTS
  const spinner = isLoading && <Spinner />;

  const connectedButtonContent = (
    <>
      {t(`${TRANSLATION_PREFIX}.connected`)}
      <Icon iconName="CheckMark" styles={BUTTON_ICON_STYLES} />
    </>
  );
  const idleButtonContent = isValuePresent
    ? connectedButtonContent
    : t(`${TRANSLATION_PREFIX}.connect`);
  const workingButtonContent = (
    <>
      {t(`${TRANSLATION_PREFIX}.connecting`)}
      <Spinner styles={BUTTON_ICON_STYLES} />
    </>
  );
  const buttonContent = isConnecting ? workingButtonContent : idleButtonContent;

  const scopesSelect = scopeOptions.length > 0 && (
    <OAuthScopeSelect
      {...{
        currentForm,
        helpUri,
        scopeOptions,
      }}
      isDisabled={isDisabled || isValuePresent}
    />
  );

  // RENDER
  return (
    <Stack tokens={STACK_TOKENS}>
      <input type="hidden" name={FieldNames.ACCESS_TOKEN} ref={register} />
      <input type="hidden" name={FieldNames.REFRESH_TOKEN} ref={register} />
      {spinner}
      {scopesSelect}
      <Label>{label}</Label>
      <PrimaryButton
        disabled={isDisabled}
        onClick={handleOauthConnect}
        styles={CONNECT_BUTTON_STYLES}
      >
        {buttonContent}
      </PrimaryButton>
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          dismissButtonAriaLabel={t('close')}
        >
          {error}
        </MessageBar>
      )}
    </Stack>
  );
};
