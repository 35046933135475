import { IModalProps } from '@fluentui/react';
import { ShareLinkDataType, ShareSolution, ShareSolutionConfig } from './model';
import EykoSVG from './assets/logos/Eyko.svg';
import MicrosoftOutlook from './assets/logos/MicrosoftOutlook.svg';
import MicrosoftPowerPoint from './assets/logos/MicrosoftPowerPoint.svg';
import MicrosoftTeams from './assets/logos/MicrosoftTeams.svg';
import MicrosoftWord from './assets/logos/MicrosoftWord.svg';
import Slack from './assets/logos/Slack.svg';
import Zoom from './assets/logos/Zoom.svg';

export const I18N_PREFIX =
  'modules:streamer:sections:data:modes:boards:preview:share';

export const MODAL_PROPS: IModalProps = {
  styles: {
    main: {
      maxWidth: 'none !important',
    },
  },
};

export const SHARE_SOLUTIONS: Record<ShareSolution, ShareSolutionConfig> = {
  [ShareSolution.Eyko]: {
    color: '#EE884E',
    logo: EykoSVG,
    options: [
      ShareLinkDataType.Image,
      ShareLinkDataType.Plain,
      ShareLinkDataType.Rich,
    ],
  },
  [ShareSolution.MicrosoftOutlook]: {
    color: '#0072C6',
    logo: MicrosoftOutlook,
    options: [
      ShareLinkDataType.Image,
      ShareLinkDataType.Plain,
      ShareLinkDataType.Rich,
    ],
  },
  [ShareSolution.MicrosoftPowerPoint]: {
    color: '#D04423',
    logo: MicrosoftPowerPoint,
    options: [
      ShareLinkDataType.Image,
      ShareLinkDataType.Plain,
      ShareLinkDataType.Rich,
    ],
  },
  [ShareSolution.MicrosoftTeams]: {
    color: '#464EB8',
    logo: MicrosoftTeams,
    options: [
      ShareLinkDataType.Image,
      ShareLinkDataType.Plain,
      ShareLinkDataType.Rich,
    ],
  },
  [ShareSolution.MicrosoftWord]: {
    color: '#2A5699',
    logo: MicrosoftWord,
    options: [
      ShareLinkDataType.Image,
      ShareLinkDataType.Plain,
      ShareLinkDataType.Rich,
    ],
  },
  [ShareSolution.Slack]: {
    color: '#4A154B',
    logo: Slack,
    options: [ShareLinkDataType.Image, ShareLinkDataType.Plain],
  },
  [ShareSolution.Zoom]: {
    color: '#2D8CFF',
    logo: Zoom,
    options: [ShareLinkDataType.Plain],
  },
};

export const SHARE_SOLUTIONS_ORDER: ShareSolution[] = [
  ShareSolution.Eyko,
  ShareSolution.MicrosoftTeams,
  ShareSolution.Slack,
  ShareSolution.Zoom,
  ShareSolution.MicrosoftOutlook,
  ShareSolution.MicrosoftWord,
  ShareSolution.MicrosoftPowerPoint,
];
