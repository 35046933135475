import React, { FunctionComponent, useState } from 'react';
import {
  Stack,
  MessageBar,
  MessageBarType,
  Text,
  IDropdownOption,
  ResponsiveMode,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { getEnvironmentUrl } from './utils';
import { Dropdown, PrimaryButton } from '../../../../components/ui';
import { AuthContext } from '../../../Auth-v2/context';
import { fetchUserData } from '../../../User/fetchUserData';
import { getIsStaging } from '../../../../utils/isStaging';
import { dropdownStyles } from '../../../Designer/Form/components/fields/styles';
import { isLocalhost } from '../../../../utils/isLocalhost';

interface EnvironmentDropdownOption extends IDropdownOption {
  url: string;
}

interface Environment {
  key: string;
  text?: string;
  url: string;
}
const ENVIRONMENTS: Environment[] = [
  { key: 'Sadiradra', url: 'https://sadiradra.azurewebsites.net' },
  { key: 'Enif', url: 'https://enif-api.azurewebsites.net' },
  { key: 'Aquarius', url: 'https://seat-api.azurewebsites.net' },
  { key: 'Izar', url: 'https://izar-api.azurewebsites.net' },
  { key: 'Castor', url: 'https://castor-api.azurewebsites.net' },
];

if (getIsStaging() || isLocalhost()) {
  // TODO: this should probably not be hard-coded but based on env vars or other configuration
  ENVIRONMENTS.push({
    key: 'Local env',
    text: 'https://localhost:44377',
    url: 'https://localhost:44377',
  });
  ENVIRONMENTS.push({
    key: 'Local env 2',
    text: 'https://localhost:5000',
    url: 'https://localhost:5000',
  });
}

const getInitialItem = (
  environmentsWithI18n: readonly Required<Environment>[]
) => environmentsWithI18n.find((item) => item.url === getEnvironmentUrl());

const Connection: FunctionComponent = () => {
  // DEPS
  const { t } = useTranslation(['connection', 'headers']);

  const options: Required<Environment>[] = React.useMemo(
    () =>
      ENVIRONMENTS.map((option) => ({
        ...option,
        text: option.text ?? t(option.key),
      })),
    [t]
  );

  // HOOKS
  const { authError, isSignInPending, isSignedIn, signIn, signOut } =
    React.useContext(AuthContext);

  // STATE
  const [selectedItem, setSelectedItem] = useState<
    EnvironmentDropdownOption | undefined
  >(getInitialItem(options));

  // DERIVED STATE
  const statusTextKey = isSignedIn ? 'connected' : 'disconnected';

  // CALLBACKS
  const onChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      item: EnvironmentDropdownOption
    ): void => {
      setSelectedItem(item);
    },
    []
  );

  const handleConnect = React.useMemo(
    () =>
      selectedItem &&
      (() => {
        signIn(selectedItem.url);
      }),
    [selectedItem]
  );

  const handleDisconnect = React.useCallback(async () => {
    await signOut();
  }, [signOut]);

  // EFFECTS
  React.useEffect(() => {
    if (isSignedIn) {
      fetchUserData();
    }
  }, [isSignedIn]);

  // RENDER
  return (
    <>
      {authError && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          isMultiline={false}
          dismissButtonAriaLabel={t('close')}
        >
          {authError}
        </MessageBar>
      )}
      <Stack style={{ width: '100%' }} tokens={{ childrenGap: 16 }}>
        <Dropdown
          {...{ options }}
          disabled={isSignedIn || isSignInPending}
          label={t('environment')}
          placeholder={t('Select environment')}
          selectedKey={selectedItem ? selectedItem.key : undefined}
          onChange={onChange}
          responsiveMode={ResponsiveMode.large}
          data-testid="environment-dropdown"
          styles={dropdownStyles()}
        />

        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
        >
          <Text as="p" style={{ fontSize: '12px' }}>
            <strong style={{ fontWeight: 600 }}>{t('status')}</strong>
            {isSignedIn && (
              <input type="hidden" data-testid="user-logged-in-indicator" />
            )}
            <span style={{ marginLeft: '2px' }}>{t(statusTextKey)}</span>
          </Text>

          {isSignedIn ? (
            <PrimaryButton onClick={handleDisconnect} data-testid="logout-btn">
              {t('disconnect')}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={handleConnect}
              disabled={!selectedItem || isSignInPending}
              data-testid="connect-btn"
            >
              {t('connect')}
            </PrimaryButton>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Connection;
