import React, { useMemo } from 'react';
import { FontIcon, Stack } from '@fluentui/react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import OutputCard from '../OutputCard';
import { classNames as outputClassNames } from '../../styles';
import { StreamAssetRaw, StreamerFilter } from '../../../../../../types';
import { Stream } from '../../../../../../../../api/model/schemas/Stream';
import FormattedValue from '../FormattedValue';
import { getClassNames } from './styles';
import { CARD_KEY_PROP, getSelectedCardKeys } from '../../../../selectors';
import { ExtendedProps as ExtendedCardProps } from '../OutputCard/OutputCard';
import { ValueType } from '../FormattedValue/FormattedValue';

interface Props extends ExtendedCardProps {
  assetId: string;
  assets: StreamAssetRaw[];
  data: { [key: string]: any }[];
  filters: StreamerFilter[];
  isOpen: boolean;
  label: string;
  onHeaderClick: (key: any) => void;
  stream: Stream;
  toggleIsCardOpen: (key: number) => void;
  openedCards: boolean[];
}

const Column: React.FC<Props> = ({
  assetId,
  assets,
  data,
  filters,
  isOpen,
  label,
  onHeaderClick,
  selectionPreviewsRef,
  stream,
  toggleCard,
  toggleIsCardOpen,
  openedCards,
}) => {
  // STATE
  const selectedCardKeys = useSelector(getSelectedCardKeys);

  // DERIVED STATE
  const asset = assets.find((current) => current.id === assetId);
  const classNames = useMemo(() => getClassNames(isOpen), [isOpen]);

  // RENDER
  return (
    <Stack className={classNames.container}>
      <div className={cn([outputClassNames.background, classNames.bg])}>
        <Stack
          className={classNames.header}
          horizontal
          verticalAlign="center"
          onClick={() => onHeaderClick(label)}
        >
          <FontIcon iconName={isOpen ? 'ChevronUp' : 'ChevronDown'} />
          <span>
            <FormattedValue
              asset={asset}
              value={label}
              valueType={ValueType.ColumnValue}
            />
          </span>
        </Stack>
        {isOpen && data && (
          <Stack tokens={{ childrenGap: 8 }}>
            {data.map((entry) => (
              <OutputCard
                {...{
                  assets,
                  entry,
                  filters,
                  selectionPreviewsRef,
                  toggleCard,
                }}
                isCardSelected={selectedCardKeys.includes(entry[CARD_KEY_PROP])}
                key={entry[CARD_KEY_PROP]}
                parentTitle={stream.name}
                toggleIsLocalOpen={() => toggleIsCardOpen(entry[CARD_KEY_PROP])}
                isLocalOpen={openedCards[entry[CARD_KEY_PROP]]}
              />
            ))}
          </Stack>
        )}
      </div>
    </Stack>
  );
};

export default Column;
