import React from 'react';

export const styles: Record<string, React.CSSProperties> = {
  subheader: {
    fontWeight: 600,
  },
  outputPart: {
    overflowWrap: 'break-word',
  },
  topStack: { marginBottom: '10px' },
} as const;
