import { ITypeSectionProperty } from "../modules/Designer/types";
import { SchemaEntry, SchemaEntryBase } from "../components/DataSchemaMapper/model";

export const getSchemaFromTypeProperties = (properties: ITypeSectionProperty[], typeId: string) =>
  properties.filter(property => property.isShownOnInformation)
    .map<SchemaEntry>(property => {
      const entryBase: SchemaEntryBase = {
        interaction: property.interaction,
        key: property.name,
      };

      // NOTE: we do not set the `name` for system-defined properties so they get translated using our translation files
      return property.isLocked
        ? { ...entryBase, translationPrefix: typeId }
        : { ...entryBase, name: property.name }
        ;
    });