import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import { isDesignSelected, getSelectedDesign } from '../../selectors';
import { designSuccessfullyDeleted } from '../../actions';
import { routes } from '../../../../../constants/routes';

import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import {
  addTopLevelEntityRoute,
  deleteEntityRoute,
  editEntityRoute,
} from '../../../../../utils/routes';
import { ExportDesign } from './ExportDesign';
import { ImportDesign } from './ImportDesign';
import { DesignSnapshots } from './DesignSnapshots';

const DesignsHeader = () => {
  const { t } = useTranslation();
  const isSelected = useSelector(isDesignSelected);
  const selectedDesign = useSelector(getSelectedDesign);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = () => {
    const path = deleteEntityRoute(TYPE_IDS.Design, selectedDesign.id);
    const goBackPage = routes.designer.contentLibrary.designs;

    history.push(path, {
      goBackPage,
      entityName: selectedDesign.name,
      deleteDescription: t('designer:deleteDesign'),
      successCallback: () => {
        dispatch(designSuccessfullyDeleted());
      },
    });
  };

  const handleAdd = () => {
    const path = addTopLevelEntityRoute(TYPE_IDS.Design);
    const goBackPage = routes.designer.contentLibrary.designs;
    history.push(path, {
      goBackPage,
      typeName: 'Design',
    });
  };

  const handleEdit = () => {
    const path = editEntityRoute(TYPE_IDS.Design, selectedDesign.id);
    const goBackPage = routes.designer.contentLibrary.designs;
    history.push(path, {
      goBackPage,
    });
  };

  const handleShare = () => {
    history.push(routes.share.design);
  };

  return (
    <>
      <ItemActionsHeader
        isItemSelected={isSelected}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleShare={handleShare}
      />
      <ImportDesign />
      <ExportDesign />
      <DesignSnapshots />
    </>
  );
};

export default DesignsHeader;
