import { chain } from 'lodash';
import { TFunction } from 'react-i18next';
import moment from 'moment';
import { OptionGroup } from '../../../../components/FiltersForm/components/ItemSelect';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import {
  StreamAssetRaw,
  StreamerFilter,
  StreamerFilterComparisonTypes,
  StreamerLimitedComparisons,
} from '../../types';
import { DataType } from '../../../../api/model/schemas/DataType';
import i18n from '../../../../config/i18n';
import {
  IDesignerFilter,
  IFilterItem,
} from '../../../../types/IDesignerFilter';
import { matchesSearchQuery } from '../../../Search/utils';
import { ColumnFilterType } from '../../../../api/model/schemas/ColumnFilterType';
import { ComparisonType } from '../../../../api/model/schemas/ComparisonType';
import { ComparisonTypes, FilterTypes } from '../../../../types/IStreamFilter';

export const parseAssetsToFilterFormItems = (
  assets: StreamAssetRaw[],
  t: TFunction,
  inputVal: string
): OptionGroup[] => {
  const filteredAssets = assets.filter((asset) =>
    matchesSearchQuery(inputVal, asset.name)
  );

  const groupedSourceFields: OptionGroup[] = chain(filteredAssets)
    .filter((asset) => asset.$typeId === TYPE_IDS.StreamField)
    .groupBy('processedEntityName')
    .map((value, key) => ({ label: key, options: value }))
    .value();

  const calculations = filteredAssets
    .filter((f) => f.$typeId === TYPE_IDS.StreamCalculation)
    .sort((a, b) => a.name.localeCompare(b.name));
  const hubs = filteredAssets
    .filter((f) => f.$typeId === TYPE_IDS.StreamHub)
    .sort((a, b) => a.name.localeCompare(b.name));
  const groups = filteredAssets
    .filter((f) => f.$typeId === TYPE_IDS.StreamGroupAsset)
    .sort((a, b) => a.name.localeCompare(b.name));

  const output: OptionGroup[] = [
    ...groupedSourceFields,
    {
      label: t('entityTypes:Calculation_plural'),
      options: calculations,
    },
    {
      label: t('entityTypes:Hub_plural'),
      options: hubs,
    },
    {
      label: t('entityTypes:Group_plural'),
      options: groups,
    },
  ];

  return output;
};

export const renderFilterValue = (
  value: any,
  comparison: StreamerFilterComparisonTypes,
  itemDataType: DataType
) => {
  if (
    [
      StreamerLimitedComparisons.IsNotNull,
      StreamerLimitedComparisons.IsNull,
      StreamerLimitedComparisons.IsBlank,
      StreamerLimitedComparisons.IsNotBlank,
    ].includes(comparison as StreamerLimitedComparisons)
  ) {
    return null;
  }

  if (value === undefined) {
    return i18n.t('filters:emptyValues');
  }

  if (DataType.DateTime === itemDataType) {
    return moment(value).format('LL');
  }

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(parseFloat(value))) {
    return value;
  }

  if (value === null) {
    return 'null';
  }

  return value;
};

export const shouldEnforceTypedValues = (filterItem: any) =>
  filterItem?.isAggregated;

export const streamerFilterToDesignerFilter = (
  input: StreamerFilter
): IDesignerFilter => ({
  ...input,
  comparison: input?.comparison as unknown as ComparisonTypes,
  type: input?.type as unknown as FilterTypes,
  item: input?.item as unknown as IFilterItem,
});

export const adjustComparisonType = (
  editedFilter: StreamerFilter
): StreamerFilter => {
  if (editedFilter?.lowerBoundValue && editedFilter?.upperBoundValue) {
    return {
      ...editedFilter,
      comparison: editedFilter?.comparison === ComparisonType.Equal ? StreamerLimitedComparisons.Between : StreamerLimitedComparisons.NotBetween,
    };
  }

  if (
    Array.isArray(editedFilter?.values)
  ) {
    return {
      ...editedFilter,
      comparison: editedFilter?.comparison === ComparisonType.Equal ? StreamerLimitedComparisons.In : StreamerLimitedComparisons.NotIn,
    };
  }

  if (
    editedFilter?.comparison === ComparisonType.NotEqual &&
    editedFilter?.type === ColumnFilterType.Comparison &&
    editedFilter?.compareValue === ''
  ) {
    return {
      ...editedFilter,
      comparison: StreamerLimitedComparisons.IsNotBlank,
    };
  }

  if (
    editedFilter?.comparison === ComparisonType.Equal &&
    editedFilter?.type === ColumnFilterType.Comparison &&
    editedFilter?.compareValue === ''
  ) {
    return {
      ...editedFilter,
      comparison: StreamerLimitedComparisons.IsBlank,
    };
  }

  if (
    editedFilter?.comparison === ComparisonType.NotEqual &&
    editedFilter?.type === ColumnFilterType.Comparison &&
    editedFilter?.compareValue === undefined
  ) {
    return {
      ...editedFilter,
      comparison: StreamerLimitedComparisons.IsNotNull,
    };
  }

  if (
    editedFilter?.comparison === ComparisonType.Equal &&
    editedFilter?.type === ColumnFilterType.Comparison &&
    editedFilter?.compareValue === undefined
  ) {
    return {
      ...editedFilter,
      comparison: StreamerLimitedComparisons.IsNull,
    };
  }

  return editedFilter;
};
