import { OrderByDirection } from '../../../../../api/model/schemas/OrderByDirection';

const translationsKeys = {
  [OrderByDirection.Ascending]: 'streams:columnsSort.ascending',
  [OrderByDirection.Descending]: 'streams:columnsSort.descending',
};

const icons = {
  [OrderByDirection.Ascending]: 'SynergiesSortAscending',
  [OrderByDirection.Descending]: 'SortLines',
};

export const SORT_SETTINGS = {
  translationsKeys,
  icons,
} as const;
