import { mergeStyleSets, getTheme } from '@fluentui/react';

export const classNames = mergeStyleSets({
  wrapper: {
    margin: '0 8px',
  },
  section: {
    width: '100%', height: '100%', padding: '8px', boxSizing: 'border-box',
  },
});

export const sectionTitleStyles = () => {
  const theme = getTheme();

  return {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '0',
    marginBottom: '5px',
    color: theme.palette.neutralPrimary,
  };
};
