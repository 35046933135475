import { all } from 'redux-saga/effects';

import { changeDrillingLevel } from './changeDrillingLevel';
import { handleShouldReload } from './handleShouldReload';
import handlePlay from './handlePlay';
import moveGroupTableItem from './moveGroupTableItem';

export function* rootSaga() {
  yield all([
    changeDrillingLevel(),
    handleShouldReload(),
    handlePlay(),
    moveGroupTableItem(),
  ]);
}

export default rootSaga;
