import React from 'react';

import { errorStyle } from './styles';

interface Props {
  children: React.ReactNode,
}

const Error: React.FC<Props> = ({ children }) => (
  <p style={errorStyle}>
    { children }
  </p>
);

export default Error;
