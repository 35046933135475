import {
  IButtonStyles, ITextFieldStyles, ITextStyles, ITextFieldStyleProps,
} from '@fluentui/react';
import { baseColors } from '../../../theme';

export const textFieldStyles = ({ focused }: ITextFieldStyleProps): Partial<ITextFieldStyles> => ({
  fieldGroup: {
    height: '28px !important',
    borderWidth: '1px !important',
    borderColor: focused ? baseColors.midOrange : baseColors.inputBorder,
    selectors: {
      '&:after': {
        border: 0,
      },
    },
  },
  root: {
    height: '28px',
  },
});

export const cancelBtn:IButtonStyles = {
  root: {
    width: 28,
    height: 28,
  },
  rootHovered: {
    borderRadius: 0,
    background: 'transparent',
  },
  rootPressed: {
    borderRadius: 0,
    background: 'transparent',
  },
  icon: {
    fontSize: '16px',
    height: 16,
    width: 16,
    color: baseColors.iconColor,
    selectors: {
      '&:hover': {
        color: baseColors.midOrange,
      },
    },
  },
};

export const cancelBtnWrapper:React.CSSProperties = {
  position: 'absolute',
  top: '0',
  bottom: '0',
  right: '0',
  margin: '0',
};

export const textStyles: ITextStyles = {
  root: {

  },
};
