import React, {
  FormEvent,
  KeyboardEvent,
  FunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { Text, TextField, ITextField } from '@fluentui/react';
import useOnClickOutside from 'use-onclickoutside';

import {
  cancelBtn, cancelBtnWrapper, textFieldStyles, textStyles,
} from './styles';
import IconButton from '../../IconButton';

interface InlineEditFieldProps {
  value: string;
  onChange(newValue: string): void;
  initialEditing?: boolean
  placeholder?: string;
  onCancelEdit?: () => void;
  testid?: string
}

const InlineEditField: FunctionComponent<InlineEditFieldProps> = ({
  value, onChange, initialEditing, placeholder, onCancelEdit, testid,
}) => {
  const [internalValue, setInternalValue] = useState('');
  const [isEditing, setIsEditing] = useState(initialEditing || false);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const ref = useRef(null);
  let textFieldRef: ITextField = null;

  const changeValueCallback = useCallback(() => {
    if (internalValue) {
      onChange(internalValue);
      setIsEditing(false);
    }
  }, [internalValue]);

  const onChangeHandler = (
    _event: FormEvent,
    newValue: string,
  ) => setInternalValue(newValue);

  const onKeyDownHandler = (
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      changeValueCallback();
    }
  };

  const internalOnCancel = () => {
    setIsEditing(false);
    if (onCancelEdit) {
      onCancelEdit();
    }
  };

  const onDoubleClickHandler = () => setIsEditing(true);

  useOnClickOutside(
    ref,
    changeValueCallback,
  );

  useEffect(() => {
    if (isEditing) {
      textFieldRef?.focus();
    }
  }, [isEditing]);

  if (!isEditing) {
    return (
      <Text
        data-testid={testid}
        onDoubleClick={onDoubleClickHandler}
        styles={textStyles}
      >
        { internalValue }
      </Text>
    );
  }

  return (
    <div ref={ref} style={{ position: 'relative', flex: 1 }}>
      <TextField
        data-testid={testid}
        styles={textFieldStyles}
        value={internalValue}
        placeholder={placeholder}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        componentRef={(innerRef) => {
          textFieldRef = innerRef;
        }}
      />
      <div
        style={cancelBtnWrapper}
      >
        <IconButton
          onClick={internalOnCancel}
          iconProps={{ iconName: 'Cancel' }}
          styles={cancelBtn}
        />
      </div>
    </div>
  );
};

export default InlineEditField;
