import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../../../../constants/routes';

import CatalogSources from '../Sources';
import Calculations from '../Calculations';
import CatalogData from '../Data';
import Hubs from '../Hubs';
import Groups from '../Groups';

import { getCurrentPage } from '../../selectors';
import { setCurrentPage } from '../../actions';
import Links from '../../../Ingestion/screens/Links';
import { MachineLearning } from '../MachineLearning/MachineLearning';

const IndexPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const currentPage = useSelector(getCurrentPage);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (pathname === routes.designer.catalog.index && currentPage) {
      history.push(currentPage);
    } else {
      dispatch(setCurrentPage(pathname));
    }
  }, [pathname]);

  return (
    <Switch>
      <Route
        path={routes.designer.catalog.sources}
        component={CatalogSources}
      />
      <Route
        path={routes.designer.catalog.calculations}
        component={Calculations}
      />
      <Route path={routes.designer.catalog.links} component={Links} />
      <Route path={routes.designer.catalog.data} component={CatalogData} />
      <Route path={routes.designer.catalog.groups} component={Groups} />
      <Route path={routes.designer.catalog.hubs} component={Hubs} />
      <Route
        path={routes.designer.catalog.machineLearning}
        component={MachineLearning}
      />
      <Route path={routes.designer.catalog.index}>
        <Redirect to={routes.designer.catalog.sources} />
      </Route>
    </Switch>
  );
};

export default IndexPage;
