/**
 * react-select has an specific type requirement for the dropdown options,
 * this utilty fn helps enforcing that data structure when the options are just
 * an array of strings.
 */

import { OptionTypeBase } from 'react-select';

export const stringArrayToOptions = (options: string[]) => options?.map((option) => ({ label: option, value: option })) || [];

interface Option extends OptionTypeBase {
  value: string,
  label: string,
}

interface Foo<T extends Option = Option> {
  options: T[]
}

export const findCurrentOptionFromGroupedOptions = <T extends Foo>(value: string, options: T[]): T['options'][number] => options
  .flatMap((option) => option.options)
  .find((option) => option.value === value) || null;
