import { Spinner, Text } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getClient from '../../../../../../api/getClient';
import { PrimaryButton } from '../../../../../../components/ui';

import * as styles from './styles'

type Props = {
  textValue: string;
  onChange: (...event: any[]) => void;
};

type DaxFormatterError = {
  column: number;
  line: number;
  message: string;
};

type DaxFormatterResponse = {
  formatted: string;
  errors: DaxFormatterError[]
}

export const DaxValidate = ({ textValue, onChange }: Props) => {
  const {t} = useTranslation()

  const [hasValidated, setValidated] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<DaxFormatterError[]>([]);

  const handleClick = async () => {
    setErrors([]);

    try {
      setLoading(true);
      const client = await getClient();

      const resp = await client.post<DaxFormatterResponse>(
        'https://daxformatter.azurewebsites.net/api/daxtextformat',
        {
          dax: `EVALUATE ${textValue}`,
        }
      );

      const fullyFormattedText = resp.data.formatted;

      if (fullyFormattedText) {
        // for payload asd, we are getting response EVALUATE\r\nasd\r\n
        // we have to strip off EVALUATE
        const strippedEvaluate = fullyFormattedText.slice(10,-2);
        console.log({ fullyFormattedText, strippedEvaluate });
        onChange(strippedEvaluate);
        setValidated(true);
      }

      setErrors(resp.data.errors);
    } catch (error) {
      console.error('validation error', error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setValidated(false);
    setErrors([]);
  }, [textValue]);

  return (
    <>
      {isLoading && <Spinner style={styles.validText}/>}
      {hasValidated && errors.length === 0 && <Text style={styles.validText}>{t('daxValidation:validDax')}</Text>}
      {errors.map((error) => (
        <div
          style={styles.errorWrapper}
        >
          <Text style={styles.errorMsg}>{error.message}</Text>
        </div>
      ))}
      {!(hasValidated && errors.length === 0) &&<PrimaryButton style={styles.button} onClick={handleClick}>{t('daxValidation:validate')}</PrimaryButton>}
    </>
  );
};
