import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IFoldableListGroup,
  IFoldableListItem,
} from '../../../../components/ui/FoldableList/types/IFoldableListGroup';
import EntityField from '../../../../components/EntityField';

import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import useGetParsedTypeProperties from '../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../App/types';

import { IHub } from '../../../Designer/types';

import { getHubs, getSelectedStream } from '../../selectors';
import {
  assetClicked,
  AssetClickedIntent,
  assetsDataGroupClicked,
  fetchHubs,
  fetchStreamHubs,
} from '../../actions';
import { GroupCollapse } from '../../../../components/ui';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { getSchemaFromTypeProperties } from '../../../../utils/getSchemaFromTypeProperties';
import { getSelectedDesignId } from '../../../Designer/ContentLibrary/selectors';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import { StreamAssetType } from '../../types';
import { normalizeById } from '../../../../utils/normalizeEntities';
import { useSearchFilteredRecords } from '../../../../hooks/useSearchFilteredRecords';
import { HubKeys } from '../../../../api/model/schemas/Hub';
import { StreamReferenceProcessStatus } from '../../../../api/model/schemas/StreamReferenceProcessStatus';

const Hubs: FunctionComponent = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const selectedStream = useSelector(getSelectedStream);
  const designId = useSelector(getSelectedDesignId);

  const { records, isLoading, streamHubs, recordIdsBeingUpdated } =
    useSelector(getHubs);

  // DERIVED STATE
  const normalizedHubs = normalizeById(records);

  const { properties } = useGetParsedTypeProperties({
    designId,
    typeId: TYPE_IDS.Hub,
  });

  const selectedHubsIds = React.useMemo(
    () => streamHubs.map((f) => f.hubId),
    [streamHubs]
  );
  const { filteredRecords, searchQuery } = useSearchFilteredRecords(records);

  const sourceFieldsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds: selectedHubsIds,
  });

  const schema = React.useMemo(
    () => [
      ...getSchemaFromTypeProperties(properties, TYPE_IDS.Hub),
      // TODO: contact Stephan about making some additional attributes shown in info boxes on the API side
      { key: HubKeys.dataType, translationPrefix: TYPE_IDS.Hub },
      { key: HubKeys.formatType, translationPrefix: TYPE_IDS.Hub },
      { key: HubKeys.type, translationPrefix: TYPE_IDS.Hub },
    ],
    [properties]
  );

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:builder:catalog:hubs:cta:noAssetsAvailable':
        records.length === 0,
    }),
    [records.length]
  );

  // CALLBACKS
  const onClickItemHandler = React.useCallback(
    (entity: IHub) => {
      dispatch(
        assetClicked({
          entity,
          actionType: streamHubs.some(
            (streamHub) => streamHub.hubId === entity.id
          )
            ? AssetClickedIntent.REMOVE
            : AssetClickedIntent.ADD,
          assetType: StreamAssetType.Hub,
        })
      );
    },
    [dispatch, streamHubs]
  );

  const handleGroupClick = (group: IFoldableListGroup) => {
    const relatedFields = group.items.reduce<IHub[]>(
      (accumulator, item) => [...accumulator, normalizedHubs[item.key]],
      []
    );

    dispatch(
      assetsDataGroupClicked({
        entities: relatedFields,
        selectedEntitiesIds: selectedHubsIds,
        assetType: StreamAssetType.Hub,
      })
    );
  };

  // EFFECTS
  useEffect(() => {
    // all groups in the design (which is a parent of a stream)
    dispatch(fetchHubs(selectedStream.parentId));
    dispatch(fetchStreamHubs({ streamId: selectedStream.id }));
  }, [dispatch]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({ input: sourceFieldsWhichIncludesSelections });

  const renderSource = (entity: IHub) => {
    if (!entity) return null;

    const relatedStreamHub = streamHubs.find(
      (streamHub) => streamHub.hubId === entity.id
    );
    const isQueued =
      relatedStreamHub?.processStatus ===
      StreamReferenceProcessStatus.NotProcessed;

    return (
      <EntityField
        key={entity.id}
        isSelectable
        isOrange={isQueued}
        isSelected={!!relatedStreamHub}
        info={{
          data: entity,
          schema,
        }}
        slot1={<EntityFieldSlotSpacer />}
        onClick={() => onClickItemHandler(entity)}
        name={entity.name}
        isSyncing={recordIdsBeingUpdated.includes(entity.id)}
        searchQuery={searchQuery}
        availability={entity.availability}
      />
    );
  };

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (currentSort === DefinedSortTypes.Name) {
    return (
      <>
        {sourceFieldsWhichIncludesSelections
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((field) => renderSource(field))}
      </>
    );
  }

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = records.find((r) => r.id === item.key);

    return renderSource(entry);
  };

  return (
    <div>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onIconClick={onClickHeaderHandler}
          onTextClick={() => handleGroupClick(group)}
          isSyncing={group.items.some((item) =>
            recordIdsBeingUpdated.includes(item.key)
          )}
          isSelected={group.items.every((item) =>
            selectedHubsIds.includes(item.key)
          )}
          selections={{
            current: selectedHubsIds.filter((id) =>
              group.items.map((item) => item.key).includes(id)
            ).length,
            total: group.items.length,
          }}
        >
          {group.items.map((item) => onRenderItem(item))}
        </GroupCollapse>
      ))}
    </div>
  );
};

export default Hubs;
