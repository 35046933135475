import { ApplicationState } from '../../store/types';

export const getSearchState = (state: ApplicationState) => state.search;

export const getIsSearchBarOpen = (
  state: ApplicationState,
) => getSearchState(state).isSearchBarOpen;

export const getSearchQuery = (
  state: ApplicationState,
) => getSearchState(state).searchQuery;
