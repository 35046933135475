import React, { FunctionComponent } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react';
import { getTheme } from '@fluentui/react';

import { useSelector } from 'react-redux';
import {
  commandBarStyles,
  buttonItemStyles,
  iconItemStyles,
  activeItemClassName,
} from './styles';
import { tooltipStyles } from '../ui/TooltipHost/styles';
import { openInNewTab } from './utils';
import { getClientPreferencesListAsObject } from '../../modules/ClientPreferences/selectors';
import { ToggleValues, UserPreferenceIds } from '../../types/IClientPreferences';

interface ContextualMenuItem {
  title?: string;
  key: string;
  name: string;
  href?: string;
  iconName?: string;
  disabled?: boolean;
  isActive?: boolean;
  onClick?(): void;
  onRender?(): React.ReactNode;
}

interface ContextualMenuProps {
  items: ContextualMenuItem[];
}

const checkIsExternalUrl = (url:string) => url.startsWith('https://') || url.startsWith('http://');

const ContextualMenu: FunctionComponent<ContextualMenuProps> = ({
  items,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const theme = getTheme();

  const clientPreferences = useSelector(getClientPreferencesListAsObject);

  const tooltipValue = clientPreferences[UserPreferenceIds.TOOLTIPS];

  const onClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    item?: ICommandBarItemProps,
  ) => {
    event.preventDefault();

    if (!item.href) return;

    if (checkIsExternalUrl(item.href)) {
      openInNewTab(item.href);
    } else {
      push(item.href);
    }
  };

  const processItems: ICommandBarItemProps[] = items.map((i) => ({
    key: i.key,
    href: i.href,
    text: tooltipValue === ToggleValues.Off ? undefined : i.title,
    iconOnly: true,
    disabled: i.disabled,
    iconProps: {
      iconName: i.iconName,
      styles: iconItemStyles,
    },
    onClick,
    buttonStyles: buttonItemStyles,
    className: (
      (matchPath(pathname, { path: i.href, exact: false }) || i.isActive) && activeItemClassName
    ),
    tooltipHostProps: {
      styles: tooltipStyles(theme),
      tooltipProps: {
        styles: tooltipStyles(theme),
      },
      content: i.title,
    },
    onRender: i.onRender,
    'data-testid': `mode-bar-${i.key}`,
  }));

  return (
    <CommandBar
      styles={commandBarStyles}
      items={processItems}
    />
  );
};

export default ContextualMenu;
