import { mergeStyleSets } from '@uifabric/merge-styles';
import React from 'react';
import ItemsListShimmer from '../../../../components/ItemsListShimmer';
import { useScript, useScriptConditionally } from '../../../../hooks/useScript';

interface Props {
  prefillEmail?: string;
  prefilErrorDetails?: string;
}

const classNames = mergeStyleSets({
  container: {
    padding: '8px',
    margin: 'auto',
    maxWidth: '500px',
  },
});

export const ErrorFormRaw = ({ prefilErrorDetails, prefillEmail }: Props) => {
  const [hasLoadedJQuery, setLoadedJQuery] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);

  useScript({
    url: '//code.jquery.com/jquery-3.6.0.min.js',
    onLoad: () => setLoadedJQuery(true),
  });

  useScriptConditionally({
    url: '//js.hsforms.net/forms/v2.js',
    shouldLoad: hasLoadedJQuery,
    onLoad: () => {
      // https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
      // https://community.hubspot.com/t5/CMS-Development/Embedded-Form-Populating-form-fields/td-p/284953
      (window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '20500384',
        formId: '79ec0f7a-32d0-4175-a608-c58e6aed5e12',
        target: '#hubspot_error_form',
        onFormReady: ($form) => {
          setLoading(false);

          if (prefillEmail) {
            $form.find('input[name="email"]').val(prefillEmail).change();
          }
          if (prefilErrorDetails) {
            $form
              .find('input[name="TICKET.error_details"]')
              .val(prefilErrorDetails)
              .change();
          }
        },
      });
    },
  });

  return (
    <>
      {isLoading && <ItemsListShimmer />}
      <div className={classNames.container} id="hubspot_error_form" />
    </>
  );
};
