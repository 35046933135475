import { createSelector } from 'reselect';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { ApplicationState } from '../../store/types';
import { IDesignSource } from '../Designer/types';
import { BuilderState } from './reducer';

export const getBuilderState = (state: ApplicationState): BuilderState =>
  state.builder;

export const getAllStreams = (state: ApplicationState) =>
  getBuilderState(state).streams;

export const getStreamFields = (state: ApplicationState) =>
  getBuilderState(state).streamFields;

export const getStreamSourceFields = (state: ApplicationState) =>
  getBuilderState(state).streamFields?.entityFields;

export const getIsBuilderStateLoading = (state: ApplicationState) =>
  getBuilderState(state).isLoading;

export const getSelectedBuilderStreamId = (state: ApplicationState) =>
  getBuilderState(state).selectedStreamId;

export const getSelectedDataSourceIds = (state: ApplicationState) =>
  getBuilderState(state).selectedDataSourceIds;

export const getActiveDataSourceIds = (state: ApplicationState) =>
  getBuilderState(state).activeDataSourceId;

export const getDataSources = (state: ApplicationState) =>
  getBuilderState(state).dataSources;

export const createGetSourceData = () =>
  createSelector(
    getDataSources,
    (_, id: IDesignSource['id']) => id,
    ({ records: sources }, id) => sources.find((source) => source.id === id)
  );

export const getEntityFields = (state: ApplicationState) =>
  getBuilderState(state).entityFields;

export const getEntities = (state: ApplicationState) =>
  getBuilderState(state).entities;

export const getCalculations = (state: ApplicationState) =>
  getBuilderState(state).calculations;

export const getHubs = (state: ApplicationState) => getBuilderState(state).hubs;

export const getStreamFilters = (state: ApplicationState) =>
  getBuilderState(state).filters;

export const getStreamLinks = (state: ApplicationState) =>
  getBuilderState(state).links;

export const getGroups = (state: ApplicationState) =>
  getBuilderState(state).groups;

export const getMachineLearning = (state: ApplicationState) =>
  getBuilderState(state).machineLearning;

export const getDesigns = (state: ApplicationState) =>
  getBuilderState(state).designs;

export const getSelectedStream = (state: ApplicationState) =>
  getBuilderState(state).streams.find(
    (s) => s.id === state.builder.selectedStreamId
  );

export const getSelectedStreamDesignName = createSelector(
  [getSelectedStream, getDesigns],
  (stream, designs) => {
    if (!stream) return null;

    return designs.find((design) => design.id === stream.parentId).name;
  }
);

export const getActiveDataSource = createSelector(
  [getDataSources, getActiveDataSourceIds],
  ({ records }, activeSourceId) =>
    !activeSourceId
      ? null
      : records.find((dataSource) => dataSource.id === activeSourceId)
);

export const getActiveDataSourceName = createSelector(
  [getDataSources, getActiveDataSourceIds],
  ({ records }, activeSourceId) => {
    if (!activeSourceId) return null;

    return records.find((dataSource) => dataSource.id === activeSourceId)?.name;
  }
);

export const getIsUpdating = (state: ApplicationState) =>
  getBuilderState(state).isUpdating;

export const getIsProcessingStream = (state: ApplicationState) =>
  getBuilderState(state).isProcessingStream;

export const getStreamFieldsSourceFieldsCount = createSelector(
  [getStreamFields],
  ({ records }) =>
    records.filter((r) => r.$sourceFieldTypeId === TYPE_IDS.SourceEntityField)
      .length
);

export const getFilterValuesBeingUpdated = (state: ApplicationState) =>
  getStreamFilters(state).valuesBeingUpdated;

export const getLinksAnalysisState = (state: ApplicationState) =>
  getBuilderState(state).linksAnalysis;
