import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectors } from '../../../../Streams/Streams';
import { getIsOtherUserSyncing } from '../../../selectors';

import { getIsRefreshButtonEnabled } from '../../../../GroupTable/selectors';
import { groupTablePlayIntent } from '../../../../GroupTable/actions';
import { HeaderBarButton } from '../../../../../components/ui';

const PlayGroupTableButton : React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('headers');

  const currentDataset = useSelector(selectors.getCurrentDataset);
  const isOtherUserBlockingUI = useSelector(getIsOtherUserSyncing);
  const isGroupTableRefreshEnabled = useSelector(getIsRefreshButtonEnabled(currentDataset?.id));
  const isSyncing = useSelector(selectors.getIsSyncing);

  const isDisabled = !currentDataset
    || isOtherUserBlockingUI
    || !isGroupTableRefreshEnabled
    || (isSyncing);

  const handleRefreshClick = async () => {
    dispatch(groupTablePlayIntent());
  };

  return (
    <HeaderBarButton
      tooltip={t('Play')}
      onClick={handleRefreshClick}
      iconProps={{ iconName: 'Play' }}
      data-testid="group-table-play"
      disabled={isDisabled}
      shouldHideOnDisabled
    />
  );
};

export default PlayGroupTableButton;
