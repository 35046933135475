import React from 'react';
import { Controller } from 'react-hook-form';
import { DataType } from '../../../../api/model/schemas/DataType';
import { ENTITY_CHILDREN_KEY } from '../constants';
import { SchemaFormatType } from './SchemaFormatType';

export interface IEntityFormSection {
  $typeId: string;
  id: string;
  index: number;
  name: string;
  $parentTypeId: string;
  parentId: string;
  properties: IEntityProperty[];
}

export enum CustomConditionRelatedProperty {
  'parent/IsWritable' = 'parent/IsWritable',
}

export interface IEntityPropertyInteraction {
  conditionMatching: string;
  conditionRelatedProperty: CustomConditionRelatedProperty | string;
  controlSize: string;
  controlType: SchemaControlType;
  formatType: SchemaFormatType;
  isRequired: boolean;
  defaultValue: any;
  validValues: [];
  validTypes: string[];
  defaultType: string;
  allowAggregated: boolean;
  isLocked: boolean;
  increment: number;
  maximum: number;
  minimum: number;
  referenceType: string;
  referenceProperty: string;
  referenceTypeProperty: string;
  parentId: string;
  referenceTypeFilterProperty: string;
  referenceTypeFilterValueProperty: string;
  referenceTypeFilterValue: string;
  allowedDataTypes: DataType[];
}

export interface IEntityProperty {
  $typeId: string;
  id: string;
  iconType: string;
  interaction: IEntityPropertyInteraction;
  interactions: IEntityPropertyInteraction[];
  isEditableAfterCreation: boolean;
  isEnabled: boolean;
  isLocked: boolean;
  isNested: boolean;
  isReadOnly: boolean;
  isSearchable: boolean;
  isShownOnInformation: boolean;
  ipSortable: boolean;
  isStreamerEditable: boolean;
  name: string;
  $parentTypeId: string;
  parentId: string;
  isAvailableOnAdd: boolean;
  description: string;
  dataType: SchemaDataType;
  canPreSelect: boolean;
  validationTypeId?: string;
}

export interface IGenericEntity {
  [key: string]: any;
  $typeId: string;
  category?: string;
  id: string;
  isLocked?: boolean;
  name: string;
  parentId: string;
  $parentTypeId?: string;
  tags: string[];
  lastChangedById?: string;
  $lastChangedByTypeId?: string;
  lastChangedWhen?: string;
  status?: string;
  isWritable?: boolean;
}

export interface IGenericEntityAsset extends IGenericEntity {
  $assetTypeId: string;
  assetId: string;
}

export interface Json {
  [key: string]: unknown;
}

export interface IEntityForm<T extends Json = Json, C extends Json = Json> {
  typeId: string;
  entityId?: string;
  parentTypeId?: string;
  parentEntityId?: string;

  /** When the form is generated by a controller within the form */
  controllerId?: string;
  parentIndex?: number;

  /** When the form fields should be embeded in another form, instead of submitting it by itself */
  embeddedTo?: string /** typeId */;
  nestedKey?: string /** When the form fields go embeded as a nested object */;

  sections: IEntityFormSection[];
  data: T & {
    [ENTITY_CHILDREN_KEY]?: {
      [key: string]: IEntityFormChildren<C>[];
    };
  };
}

export interface IEntityFormChildren<T extends Json = Json> {
  id: string;
  data: T;
}

export interface IGenericFormField {
  currentEntity: IGenericEntity;
  currentForm: IEntityForm<Json>;
  entityProperty: IEntityProperty;
  inputName: string;
  interaction: IEntityPropertyInteraction;
  isDisabled?: boolean;
  isEditing: boolean;
  label: string;
  translationPrefix?: string;
  initialValue: any;
  watched?: { [x: string]: any };
}

export interface IControlledField extends IGenericFormField {
  controller: Parameters<React.ComponentProps<typeof Controller>['render']>[0];
  state: Parameters<React.ComponentProps<typeof Controller>['render']>[1];
}

export enum SchemaControlType {
  None = 'None',
  SingleLineEdit = 'SingleLineEdit',
  MultiLineEdit = 'MultiLineEdit',
  SelectOne = 'SelectOne',
  SelectMultiple = 'SelectMultiple',
  IconPicker = 'IconPicker',
  ColorPicker = 'ColorPicker',
  Spinner = 'Spinner',
  ManageMultiple = 'ManageMultiple',
  TypeControl = 'TypeControl',
  LinkProcess = 'LinkProcess',
  HubProcess = 'HubProcess',
  SelectOneOfType = 'SelectOneOfType',
  CalculationFilters = 'CalculationFilters',
  GroupValues = 'GroupValues',
  OAuthControl = 'OAuthControl',
  XeroTenant = 'XeroTenant',
  SelectOneConnectorConfigurationPropertyEntry = 'SelectOneConnectorConfigurationPropertyEntry',
  Slider = 'Slider',
}
export enum SchemaDataType {
  None = 'None',
  Text = 'Text',
  Uri = 'Uri',
  SecuredText = 'SecuredText',
  Json = 'Json',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  Time = 'Time',
  Date = 'Date',
  NumericInteger = 'NumericInteger',
  NumericDecimal = 'NumericDecimal',
  Geography = 'Geography',
  File = 'File',
  Guid = 'Guid',
  Object = 'Object',
  Embedded = 'Embedded',
  Array = 'Array',
  KeyValue = 'KeyValue',
}
export type SchemaControlSize = 'Small' | 'Medium' | 'Large' | 'Full';
export enum SchemaIconType {
  None = 'None',
  FabricMdl2 = 'FabricMdl2',
  Uri = 'Uri',
  InlineSvg = 'InlineSvg',
  MaterialDesign = 'MaterialDesign',
}

export enum ValidationStatus {
  Valid = 'Valid',
  AlreadyUsed = 'AlreadyUsed',
  Invalid = 'Invalid',
}

export interface ValidationRequestPayload {
  subjectTypeId: string;
  subjectId: string;
  SubjectParentId: string;
  value: unknown;
}

export interface ValidationRequestResponse {
  value: unknown;
  validationStatus: ValidationStatus;
}
