import React from 'react';
import { Stack, Text } from '@fluentui/react';
import DataSourceIcon from '../../../../../components/DataSourceIcon';
import { textStyles } from './styles';

interface Props {
  name: string,
  color: string
}

const DataSourceCalloutTitle = ({ color, name }: Props) => (
  <Stack horizontal>
    <DataSourceIcon color={color} />
    <Text style={textStyles}>{name}</Text>
  </Stack>
);

export default DataSourceCalloutTitle;
