import { v4 as uuidv4 } from 'uuid';

const SESSION_ID_STORAGE_PATH = 'synergies/session-uuid';

export const createSessionId = () => {
  const sessionId = uuidv4();

  window.sessionStorage.setItem(SESSION_ID_STORAGE_PATH, sessionId);
};

export const getSessionId = () => {
  const sessionId = window.sessionStorage.getItem(SESSION_ID_STORAGE_PATH);

  if (!sessionId) {
    createSessionId();
    return getSessionId();
  }

  return sessionId;
};
