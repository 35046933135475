import * as effects from 'redux-saga/effects';
import { IDataset } from '../../../types/IDataset';
import { IGroupedRow } from '../types';
import fetchGroupTableData from './fetchGroupTableData';
import * as selectors from '../selectors';
import { groupInquiryData } from '../utils';
import { IInquiryData } from '../../../types/IInquiryData';

export function* fetchGroupTableTotal(
  dataset: IDataset,
  isColumnsTotal: boolean = false,
  keys: string[] = [],
) {
  const table = selectors.getGroupTable(dataset.id)(yield effects.select());

  if (isColumnsTotal && !table.totals.columns) {
    return [];
  }

  if (!isColumnsTotal && !table.totals.rows) {
    return [];
  }

  const task = yield effects.fork(fetchGroupTableData, {
    dataset,
    rowKeys: isColumnsTotal ? keys : [],
    columnKeys: !isColumnsTotal ? keys : [],
    isColumnsTotal,
    isRowsTotal: !isColumnsTotal,
    shouldUpdateDataset: false,
  });

  const data: IInquiryData[] = yield effects.join(task);

  if (!data) {
    return false;
  }

  const src : IGroupedRow = yield effects.call(groupInquiryData, data, table);

  return src;
}
