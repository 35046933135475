/* eslint-disable default-case */
import {
  activeItemClassName,
  buttonItemStyles,
  iconItemStyles,
} from '../../components/ContextualMenu/styles';

import { WizardType } from './types';

const getIconName = (type: WizardType) => {
  switch (type) {
    case WizardType.Add:
      return 'Add';
    case WizardType.Edit:
      return 'Edit';
    case WizardType.Delete:
      return 'Delete';
    case WizardType.Share:
      return 'Share';
    case WizardType.Process:
      return 'Processing';
    case WizardType.Export:
      return 'Export';
    case WizardType.Import:
      return 'Import';
    case WizardType.Preview:
      return 'ReviewRequestSolid';
  }
};

export const generateCommandBarConfig = (
  type: WizardType,
  iconOverwrite?: string
) => [
  {
    key: iconOverwrite || getIconName(type),
    className: activeItemClassName,
    buttonStyles: buttonItemStyles,
    iconProps: {
      iconName: iconOverwrite || getIconName(type),
      styles: iconItemStyles,
    },
  },
];
