import { isOfficeEnabled } from "../../../taskpane/config/buildType";
import { defaultDialogErrorHandlerFactory, DialogErrorHandlerFactory, injectBaseEventHandlerBehviour, DialogErrorHandlerCreator, DialogMessageHandlerCreator, DialogMessageHandlerFactory } from "./utils/events";
import { InitializeDialogArgs, initializeIsolatedDialog, initializeOfficeDialog } from "./utils/initialize";
import { BaseOpenDialogArgs, DIALOGS_EXPECTED_TO_BE_OPEN, openIsolatedDialog, openOfficeDialog, SharedOpenDialogArgs } from "./utils/open";

// OPEN

interface OpenDialogArgs extends BaseOpenDialogArgs {
  errorHandlerFactory?: DialogErrorHandlerFactory;
  messageHandlerFactory: DialogMessageHandlerFactory;
}

export const openDialog = async <Result>({
  errorHandlerFactory = defaultDialogErrorHandlerFactory,
  messageHandlerFactory,
  options = {},
  url,
}: OpenDialogArgs): Promise<Result> =>
  new Promise(async (resolve, reject) => {
    const createErrorHandler: DialogErrorHandlerCreator = dialog => injectBaseEventHandlerBehviour({
      dialog,
      eventHandlerFactory: errorHandlerFactory,
      reject,
      resolve,
    });

    const safelyCloseDialog = dialog => {
      // NOTE: this is used by the isolated (custom) dialog solution, should have no effect on the `Office.Dialog`
      // we can cas to `Window` as passing in an `Office.Dialog` instance will not remove anything
      DIALOGS_EXPECTED_TO_BE_OPEN.delete(dialog as Window);
      dialog.close();
    };
    const createMessageHandler: DialogMessageHandlerCreator = dialog => injectBaseEventHandlerBehviour({
      dialog,
      eventHandlerFactory: messageHandlerFactory,
      reject(reason?: unknown) {
        safelyCloseDialog(dialog);
        reject(reason);
      },
      resolve(result: Result) {
        safelyCloseDialog(dialog);
        resolve(result);
      },
    });

    const openDialogArgs: SharedOpenDialogArgs = {
      createErrorHandler,
      createMessageHandler,
      reject,
      resolve,
      options,
      url,
    };

    if (isOfficeEnabled()) openOfficeDialog(openDialogArgs);
    else openIsolatedDialog(openDialogArgs);
  });

// INITIALIZE

export const initializeDialog = ({
  customInitializer,
  onMessageFromParent,
}: InitializeDialogArgs): void => {
  if (isOfficeEnabled()) {
    initializeOfficeDialog({
      customInitializer,
      onMessageFromParent,
    });
  } else {
    initializeIsolatedDialog({
      customInitializer,
      onMessageFromParent,
    });
  }
};
