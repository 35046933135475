import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllFormsSectionsSorted,
  getCurrentSectionIndex,
  getEntityById,
  getEntityByTypeId,
  getEntityFormByTypeId,
  getIsFetchingEntities,
  getIsFetchingSections,
  getPreviousPageIndex,
} from '../selectors';
import {
  IEntityFormSection,
  IGenericEntity,
  SchemaControlType,
} from '../types';
import { getActualPropertyInteraction, getPropertiesToWatch } from '../utils';
import FormShimmer from './FormShimmer';
import Property from './Property';
import { translateApiName } from '../../../../config/i18n/utils';
import { setCurrentPageIndex } from '../actions';

type FormPageProps = {
  currentSection: IEntityFormSection;
  isEditing: boolean;
  isDisabled?: boolean;
  handleFormSubmission: () => void;
};

const FormPage: React.FC<FormPageProps> = ({
  currentSection,
  isEditing,
  isDisabled = false,
  handleFormSubmission,
}) => {
  // HOOKS
  const { watch } = useFormContext();
  const dispatch = useDispatch();

  // STATE
  const currentForm = useSelector(
    getEntityFormByTypeId(currentSection?.parentId)
  );

  const sectionIndex = useSelector(getCurrentSectionIndex);
  const previousIndex = useSelector(getPreviousPageIndex);
  const pages = useSelector(getAllFormsSectionsSorted);

  const { nestedKey, embeddedTo } = currentForm || {};
  const entity = useSelector(
    getEntityByTypeId(embeddedTo || currentSection?.parentId)
  );
  const currentEntity: IGenericEntity = nestedKey
    ? entity?.[nestedKey]
    : entity;

  const isFetchingEntities = useSelector(getIsFetchingEntities);
  const isFetchingSections = useSelector(getIsFetchingSections);

  const parentEntity = useSelector(
    getEntityById(currentForm?.parentEntityId || currentEntity?.parentId)
  );

  // DERIVED STATE
  const propertiesToWatch = useMemo(
    () => getPropertiesToWatch(currentSection, currentForm, currentEntity),
    [currentSection, currentForm, entity]
  );

  const watched = watch(...propertiesToWatch);
  const isLastPage = sectionIndex + 1 >= pages?.length;

  const isEmptyFormPage = useMemo(
    () =>
      !isFetchingSections &&
      pages.length &&
      (!currentSection?.properties?.length ||
        currentSection?.properties
          .filter((prop) => prop?.isAvailableOnAdd || isEditing)
          .map((prop) =>
            getActualPropertyInteraction({
              property: prop,
              currentData: currentForm?.data,
              watched,
              parentEntity,
              currentEntity,
            })
          )
          .every((int) => !int || int.controlType === SchemaControlType.None)),
    [currentSection, isEditing, watched, parentEntity, isFetchingSections]
  );

  useEffect(() => {
    // If the current page wont render any valid user interactions
    // we move on to the next page
    if (isEmptyFormPage) {
      if (isLastPage && previousIndex <= sectionIndex) {
        handleFormSubmission();
      } else {
        dispatch(
          setCurrentPageIndex(
            previousIndex > sectionIndex ? sectionIndex - 1 : sectionIndex + 1
          )
        );
      }
    }
  }, [currentSection, parentEntity, isEmptyFormPage]);

  // RENDER
  if (
    isFetchingSections ||
    !currentSection?.properties ||
    isEmptyFormPage ||
    (isEditing && !currentEntity && isFetchingEntities)
  ) {
    return <FormShimmer />;
  }

  return (
    <>
      {currentSection.properties
        .filter((property) => {
          if (!isEditing && !property.isAvailableOnAdd) return false;

          return true;
        })
        .map((property) => (
          <Property
            {...{
              currentEntity,
              currentForm,
              isDisabled,
              property,
              watched,
            }}
            key={property.id}
            label={
              property.isLocked
                ? translateApiName(currentSection?.parentId, property.name)
                : property.name
            }
          />
        ))}
    </>
  );
};

export default FormPage;
