import axios, { AxiosInstance } from 'axios';
import { authStateSerializer } from '../../modules/Auth-v2/authStorage';
import { addError } from '../../modules/Notifications/addError';
import { clearUserData } from '../../modules/User/actions';
import { ServerError } from '../model';

export const cleanUserAuthState = (isReloadingWindow: boolean = true) => {
  authStateSerializer.remove();
  window.sharedState.store?.dispatch(clearUserData());
  if (isReloadingWindow) window.location.reload();
};

const onResponseError = (error) => {
  if (axios.isCancel(error)) throw error;

  if (error?.response?.status === 401) {
    cleanUserAuthState();
    return;
  }

  const errorData = error?.response?.data as ServerError | undefined;

  window.sharedState.store.dispatch(
    addError({
      stackTrace: JSON.stringify({
        traceId: errorData?.traceId,
      }),
      detail: JSON.stringify({
        errors: errorData?.errors,
        detail: errorData?.detail,
      }),
      status: error.response.status,
      title: errorData?.title,
      date: +new Date(),
    })
  );
};

export const applyErrorInterceptor = (
  instance: AxiosInstance
): AxiosInstance => {
  instance.interceptors.response.use((resp) => resp, onResponseError);

  return instance;
};
