export interface GeneratePlainLinkArgs {
  title: string;
}
export const generatePlainLink = ({ title }: GeneratePlainLinkArgs): string => {
  // TODO: the url is a dummy value generated for the needs of demo presentation
  // we are using # to solve redirect problem on staging - still this url just leads to the main page of the addin
  const url = encodeURI(`${window.location.href}#${title}`);

  return url;
};

export interface GenerateRichLinkArgs {
  canvas: HTMLCanvasElement;
  title: string;
}
export const generateRichLink = ({
  canvas,
  title,
}: GenerateRichLinkArgs): string => {
  const url = generatePlainLink({ title });
  const dataURL = canvas.toDataURL();
  const ratio = window.devicePixelRatio;
  const { height, width } = canvas;

  const anchor = `<a target="_blank" href="${url}">eyko.io – ${title}</a>`;
  const img = `<br/><img alt="eyko preview" width="${width / ratio}" height="${
    height / ratio
  }" src="${dataURL}" />`;

  return `${anchor}${img}`;
};
