import React from 'react';
import { AsyncSelectDropdown } from '../../../../components/ui';
import useActions from '../../../../utils/useActions';
import { actionCreators } from '../../../Streams/actions';
import { IColumn, IColumnDataType } from '../../../../types/IColumn';
import { ComparisonTypes, FilterTypes } from '../../../../types/IStreamFilter';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { fetchEntity, fetchNestedEntities } from '../../../Designer/api';
import { ApiV4ResponseWrapper } from '../../../Designer/types';
import { IGenericEntity } from '../../../Designer/Form/types';
import { GroupAsset } from '../../../../api/model/schemas/GroupAsset';
import { TypedValuesInput } from '../../../../components/ValuesInput';

interface Props {
  column: IColumn;
  isMulti: boolean;
  onChange(value: string[]): void;
  defaultValue: string[];
  label: string;
}

const ValueInput: React.FC<Props> = ({
  column,
  isMulti,
  onChange,
  defaultValue,
  label,
}) => {
  const shouldRenderValuePicker: boolean =
    column?.streamElementTypeId === TYPE_IDS.StreamField ||
    column?.streamElementTypeId === TYPE_IDS.StreamGroupAsset;

  const { fetchSpecificColumnData } = useActions(actionCreators);

  const promiseOptions = async (inputValue) => {
    if (!column) {
      return [];
    }

    switch (column.streamElementTypeId) {
      case TYPE_IDS.StreamField: {
        const continuation = { pageSize: 50, token: null };

        const data = await fetchSpecificColumnData(
          [
            {
              ...column,
              filters:
                // API returns 500 if we try to filter out on "boolean values"
                column.dataType !== IColumnDataType.Boolean
                  ? [
                      {
                        type: FilterTypes.Comparison,
                        comparison: ComparisonTypes.Contains,
                        compareValue: inputValue,
                      },
                    ]
                  : [],
            },
          ],
          continuation
        );

        // react-select accepts String and do not perform casting
        const valueOptions = data.rows.map((row) => String(row[0]));

        return valueOptions;
      }

      case TYPE_IDS.StreamGroupAsset: {
        let groupValues: string[] = [];

        try {
          const { data: groupAsset } = await fetchEntity<GroupAsset>(
            TYPE_IDS.Group,
            column?.groupAssetId
          )();

          const { data } = await fetchNestedEntities<
            ApiV4ResponseWrapper<IGenericEntity[]>
          >({ typeId: TYPE_IDS.GroupValue })(groupAsset.parentId)({
            filter: `substringof(name, '${inputValue}')`,
          });
          groupValues = data?.data
            ?.map((groupValue) => groupValue.name)
            ?.sort((a, b) => String(a).localeCompare(String(b)));
        } catch (error) {
          console.log('fetching group values error', error);
        }
        return groupValues;
      }

      default:
        return [];
    }
  };

  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  return (
    <div data-testid="filter-form-value-input">
      {shouldRenderValuePicker ? (
        <AsyncSelectDropdown
          isMulti={isMulti}
          cacheOptions
          loadOptions={promiseOptions}
          onChange={onChange}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          value={defaultValue}
          label={label}
          menuPlacement="auto"
          defaultOptions
        />
      ) : (
        <TypedValuesInput
          isMulti={isMulti}
          onChange={onChange}
          defaultValue={defaultValue}
          label={label}
          assetDataType={column.dataType}
        />
      )}
    </div>
  );
};

export default ValueInput;
