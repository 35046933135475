import React from 'react';

export const form: React.CSSProperties = {
  flex: '0 1 0',
  maxHeight: '100%',
};

export const errorMsgStyles: React.CSSProperties = {
  color: 'red',
  padding: '5px 0',
  display: 'block',
  fontSize: '.75rem',
};
