import { Label, Stack } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDropdown } from '../../../../../../../components/ui';
import { TRANSLATION_PREFIX } from '../constants';
import { Option } from '../models';
import { oauthControlStyles } from '../styles';

// NOTE: for referential consistency
export const defaultSelectedScopeIds = [];

interface Props {
  helpUri?: string;
  isDisabled: boolean;
  onChange: (selectedScopeIds: string[]) => void;
  scopeOptions: Option[];
  value: string[];
}

export const ScopeDropdown = ({
  helpUri,
  isDisabled,
  onChange,
  scopeOptions,
  value: selectedScopeIds = defaultSelectedScopeIds,
}: Props): React.ReactElement | null => {
  // HOOKS
  const { t } = useTranslation();

  // DERIVED STATE
  const selectedScopeOptions: Option[] = React.useMemo(
    () => scopeOptions.filter(scope => selectedScopeIds.includes(scope.value)),
    [scopeOptions, selectedScopeIds],
  );

  // CALLBACKS
  const onScopeSelect = React.useCallback(
    (selections: Option[]) => onChange(selections?.map(selection => selection.value) ?? defaultSelectedScopeIds),
    [onChange],
  );

  // PARTS
  const helpLink = helpUri && (
    <a href={helpUri} target="_blank" className={oauthControlStyles.scopeHelpLink}>
      {t(`${TRANSLATION_PREFIX}:scopes.readMore`)}
    </a>
  );

  // RENDER
  return (
    <>
      <Stack horizontal>
        <Label>{t(`${TRANSLATION_PREFIX}:scopes.label`)}</Label>
        {helpLink}
      </Stack>
      <SelectDropdown
        {...{ isDisabled }}
        isMulti
        onChange={onScopeSelect}
        options={scopeOptions}
        value={selectedScopeOptions}
      />
    </>
  );
};
