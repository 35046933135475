import React from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import {
  getCustomTheme,
  customStyles,
  styledDropdownComponents,
} from './customization';

const AsyncSelectDropdown: React.FC<Record<string, unknown>> = (props) => {
  // HOOKS
  const { t } = useTranslation();

  // RENDER
  return (
    <AsyncSelect
      components={styledDropdownComponents}
      theme={getCustomTheme}
      styles={customStyles}
      menuPlacement="auto"
      placeholder={t('filters:Select')}
      maxMenuHeight={200}
      {...props}
    />
  );
};

export default AsyncSelectDropdown;
