import React from 'react';
import { Text } from '@fluentui/react';
import { useSelector } from 'react-redux';

import streamSelectors from '../../../../../Streams/selectors';
import { classNames } from './styles';

interface Props {
  selectionCount: number;
  draggingTaskId?: string
}

const DraggingClone = ({ selectionCount, draggingTaskId }: Props) => {
  const column = useSelector(streamSelectors.getCurrentDatasetColumnByColumnUuid(draggingTaskId));

  return (
    <>
      <Text as="p" className={classNames.text}>
        {column?.name ?? ''}
      </Text>
      {selectionCount > 1 && (
      <div
        className={classNames.selectionCount}
      >
        {selectionCount}
      </div>
      )}
    </>
  );
};

export default DraggingClone;
