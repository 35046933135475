import React from 'react';
import { Text } from '@fluentui/react';
import { useSelector } from 'react-redux';

import { classNames } from './styles';
import { createGetStreamerRawAssetById } from '../../../selectors';
import { extractIdFromReorderUuid } from './utils';

interface Props {
  selectionCount: number;
  draggingTaskId: string | null;
}

const DraggingClone = ({ selectionCount, draggingTaskId }: Props) => {
  const asset = useSelector(
    createGetStreamerRawAssetById(extractIdFromReorderUuid(draggingTaskId))
  );

  return (
    <>
      <Text as="p" className={classNames.text}>
        {asset?.name ?? ''}
      </Text>
      {selectionCount > 1 && (
        <div className={classNames.selectionCount}>{selectionCount}</div>
      )}
    </>
  );
};

export default DraggingClone;
