import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

export interface SearchState {
  searchQuery: string;
  isSearchBarOpen: boolean;
}

export const initialState: SearchState = {
  searchQuery: '',
  isSearchBarOpen: false,
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.setSearchQuery, (state, { payload }) => ({
    ...state,
    searchQuery: payload,
  }))
  .addCase(actions.clearSearchBar, () => ({
    ...initialState,
  }))
  .addCase(actions.setIsSearchBarOpen, (state, { payload }) => ({
    ...state,
    isSearchBarOpen: payload,
  }))
  .addCase(actions.toggleSearchBar, (state) => ({
    ...state,
    isSearchBarOpen: !state.isSearchBarOpen,
  })));

export default reducer;
