import React from 'react';
import { useSelector } from 'react-redux';
import SortControl from '../../../Designer/components/SortControl';
import { getSelectedStreamDesignName } from '../../selectors';

export const SourcesTitle = () => {
  const designName = useSelector(getSelectedStreamDesignName);

  return (
    <SortControl title={designName} />
  );
};
