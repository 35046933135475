import React from 'react';
import { Text } from '@fluentui/react';
import { containerStyles, textStyle } from './styles';

interface Props {
  message: string;
}

const EmptyMessage: React.FC<Props> = ({ message }) => (
  <div style={containerStyles} data-testid="no-entries-message">
    <Text style={textStyle}>{ message }</Text>
  </div>
);

export default EmptyMessage;
