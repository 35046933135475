import React, { FunctionComponent } from 'react';
import {
  CommandBarButton,
  IContextualMenuItem,
  Icon,
  ContextualMenuItemType,
} from '@fluentui/react';
import MdiIcon from '@mdi/react';
import * as mdiJs from '@mdi/js';

import { useTranslation } from 'react-i18next';
import {
  buttonStyles,
  contextualMenuStyles,
  calloutStyles,
  statusIconStyles,
  activeSvgIcon,
  commandBarButtonIconStyles,
} from './styles';

import { SelectionType } from './types/SelectionType';
import { SelectionItem, selectionItems } from './constants';

import {
  onRenderIcon,
  onRenderSourceIcon,
  materialIconNameToMdi,
} from './utils';
import { IconType, IDesignSource } from '../../../modules/Designer/types';

interface Option {
  name: string;
  key: string;
  icon: string;
  iconType: IconType;
}

export interface ViewControlButtonProps {
  onChangeItem(key: string): void;
  activeItem: string;
  options: Option[];

  onChangeSelection?(key: SelectionType): void;
  activeSelection?: SelectionType;
  showSelectionOptions?: boolean;

  showDataSourceOptions?: boolean;
  dataSources?: IDesignSource[];
  onChangeSource?(key: string): void;
  selectedDataSourceId?: string;
  className?: string;

  selectionItemsOverride?: SelectionItem[];
}

const ViewControlButton: FunctionComponent<ViewControlButtonProps> = ({
  options,
  activeItem,
  onChangeItem,
  onChangeSelection,
  showSelectionOptions,
  activeSelection,
  showDataSourceOptions,
  dataSources,
  onChangeSource,
  selectedDataSourceId,
  className,
  selectionItemsOverride,
}) => {
  // HOOKS
  const { t } = useTranslation();

  // DERIVED STATE
  const activeOption = React.useMemo(
    () => options.find((f) => f.key === activeItem),
    [activeItem, options]
  );

  const defaultSelectionOverride = selectionItemsOverride?.find(
    (item) => item.isDefault
  )?.key;
  const activeSelectionOverride = selectionItemsOverride
    ? selectionItemsOverride.find((item) => item.key === activeSelection)
        ?.key ?? defaultSelectionOverride
    : activeSelection;

  const isDefaultSelection = defaultSelectionOverride
    ? activeSelectionOverride === defaultSelectionOverride
    : activeSelection === SelectionType.all;

  // CALLBACKS
  const onClickHandler = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>, item: IContextualMenuItem) => {
      onChangeItem(item.key);
      ev.preventDefault();
    },
    [onChangeItem]
  );

  const onClickSelectionHandler = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>, item: IContextualMenuItem) => {
      if (onChangeSelection) {
        onChangeSelection(item.key as SelectionType);
      }

      ev.preventDefault();
    },
    [onChangeSelection]
  );

  const onClickSourceHandler = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>, item: IContextualMenuItem) => {
      if (onChangeSource) {
        onChangeSource(item.key);
      }

      ev.preventDefault();
    },
    [onChangeSource]
  );

  // PARTS
  const menuItems: IContextualMenuItem[] = React.useMemo(
    () =>
      options.map((f) => ({
        key: f.key,
        text: f.name,
        data: {
          iconType: f.iconType,
        },
        iconProps: { iconName: f.icon },
        onRenderIcon,
        onClick: onClickHandler,
        checked: f.key === activeItem,
      })),
    [activeItem, onClickHandler, options]
  );

  const parsedSelectionItems = React.useMemo(
    () =>
      (selectionItemsOverride ?? selectionItems).map((i) => ({
        ...i,
        name: i.nameI18nKey ? t(i.nameI18nKey) : i.name,
        onRenderIcon,
        onClick: onClickSelectionHandler,
        checked: i.key === activeSelectionOverride,
      })),
    [activeSelectionOverride, onClickSelectionHandler, selectionItemsOverride]
  );

  const parsedDataSources = React.useMemo(
    (): IContextualMenuItem[] =>
      dataSources?.map((s) => ({
        key: s.id,
        name: s.name,
        onClick: onClickSourceHandler,
        checked: selectedDataSourceId === s.id,
        onRenderIcon: onRenderSourceIcon,
        data: s,
      })) ?? [],
    [dataSources, onClickSourceHandler, selectedDataSourceId]
  );

  const items = React.useMemo(
    () => [
      ...menuItems,
      ...(showSelectionOptions || selectionItemsOverride
        ? parsedSelectionItems
        : []),
      ...(showDataSourceOptions
        ? [
            {
              key: 'divider_1',
              itemType: ContextualMenuItemType.Divider,
            },
            ...parsedDataSources,
          ]
        : []),
    ],
    [
      menuItems,
      parsedDataSources,
      parsedSelectionItems,
      selectionItemsOverride,
      showDataSourceOptions,
      showSelectionOptions,
    ]
  );

  // RENDER
  return (
    <div
      data-testid="view-control-button"
      style={{ position: 'relative', width: '32px', height: '32px' }}
      className={className}
    >
      {activeOption?.iconType === IconType.SVG && (
        <div
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: activeOption.icon }}
          style={activeSvgIcon}
        />
      )}
      {activeOption?.iconType === IconType.MaterialDesign && (
        <MdiIcon
          path={mdiJs[materialIconNameToMdi(activeOption.icon)]}
          style={activeSvgIcon}
        />
      )}
      <CommandBarButton
        iconProps={{
          iconName: activeOption?.icon,
          style: commandBarButtonIconStyles,
        }}
        menuProps={{
          gapSpace: 2,
          beakWidth: 0,
          styles: contextualMenuStyles,
          items,
          calloutProps: {
            styles: calloutStyles,
          },
        }}
        styles={buttonStyles}
      >
        {activeSelectionOverride && !isDefaultSelection && (
          <Icon
            iconName={
              activeSelection === SelectionType.selected
                ? 'SynergiesCustomSelected'
                : 'SynergiesCustomUnselected'
            }
            styles={statusIconStyles}
          />
        )}
      </CommandBarButton>
    </div>
  );
};

export default ViewControlButton;
