import { createSelector } from "reselect";
import { DataSourceEntity } from "../../../../../api/model/schemas/DataSourceEntity";
import { DataSourceEntityField } from "../../../../../api/model/schemas/DataSourceEntityField";
import { getIngestionState } from "../../selectors";

const emptyObject = {};

export const getDataModeState = createSelector(
  getIngestionState,
  ingestionState => ingestionState.modes.data,
);

export const getEntities = createSelector(
  getDataModeState,
  dataModeState => dataModeState.entities,
);

export const getEntityList = createSelector(
  getEntities,
  entities => Object.values(entities),
);

export const getEntityListSorted = createSelector(
  getEntityList,
  entityList => entityList.sort((a, b) => a.name.localeCompare(b.name)),
);

export const getEntitiesNextPageUrl = createSelector(
  getDataModeState,
  dataModeState => dataModeState.entitiesNextPageUrl,
);

const extractEntityId = (_, entityId: DataSourceEntity['id']) => entityId;

export const makeGetFields = () => createSelector(
  getDataModeState,
  extractEntityId,
  (dataModeState, entityId) => dataModeState.fieldsPerEntity[entityId]
    ?? (emptyObject as Record<string, DataSourceEntityField>),
);

export const makeGetFieldList = () => {
  const getFields = makeGetFields();

  return createSelector(
    getFields,
    fields => Object.values(fields),
  );
}

export const makeGetFieldListSorted = () => {
  const getFieldList = makeGetFieldList();

  return createSelector(
    getFieldList,
    fieldList => fieldList.sort((a, b) => a.name.localeCompare(b.name)),
  );
};

export const getFieldsNextPageUrl = createSelector(
  getDataModeState,
  dataModeState => dataModeState.fieldsNextPageUrl,
);

export const getIsLoading = createSelector(
  getDataModeState,
  dataModeState => dataModeState.isLoading,
);
