import { TypeIds } from '.';
import { DataType } from './DataType';
import { FormatType } from './FormatType';
import { Availability } from './Availability';
import { StreamReferenceProcessStatus } from './StreamReferenceProcessStatus';

/** A stream calculation. */
export interface StreamCalculation {
  /** The type id. */
  readonly $typeId: TypeIds.StreamCalculation;// string($uuid)

  /** The entity id. */
  readonly id: string; // ($uuid)

  /** The field name. */
  readonly name: string | null;

  /** The field name. */
  readonly description: string | null;

  dataType: DataType;

  formatType: FormatType;

  /** Whether its an aggregating calculation. */
  readonly isAggregated: boolean;

  /** The element availability. */
  availability: Availability;

  /** The calculation type id. */
  readonly $calculationTypeId: TypeIds.Calculation; // string($uuid)

  /** The calculation id. */
  calculationId: string; // ($uuid)

  /** The parent type id. */
  readonly $parentTypeId: TypeIds.Stream; // string($uuid)

  /** The parent id. */
  readonly parentId: string; // ($uuid)

  tags: string[] | null;

  /** Ingestion is pending. */
  processStatus: StreamReferenceProcessStatus;

  /** The time stamp of last successful processing. */
  readonly processTimeStamp: string | null; // ($date-time)

  /** The processed entity name. */
  readonly processedEntityName: string | null;

  /** The name when last successful processing. */
  readonly processedName: string | null;

  processedDataType: DataType;

  processedFormatType: FormatType;

  /** The aggregation type when last successful processing. */
  readonly processedIsAggregated: boolean | null;
}

export const StreamCalculationKeys: { [key in keyof StreamCalculation]: key } = {
  $calculationTypeId: '$calculationTypeId',
  $parentTypeId: '$parentTypeId',
  $typeId: '$typeId',
  availability: 'availability',
  calculationId: 'calculationId',
  dataType: 'dataType',
  description: 'description',
  formatType: 'formatType',
  id: 'id',
  isAggregated: 'isAggregated',
  name: 'name',
  parentId: 'parentId',
  processedDataType: 'processedDataType',
  processedEntityName: 'processedEntityName',
  processedFormatType: 'processedFormatType',
  processedIsAggregated: 'processedIsAggregated',
  processedName: 'processedName',
  processStatus: 'processStatus',
  processTimeStamp: 'processTimeStamp',
  tags: 'tags',
}