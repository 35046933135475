import { AxiosResponse } from "axios";
import { TypeIds } from "../../../model/schemas";
import { isResponseTypeIdMatching } from "../utils";
import { i18nDataTypeKey, i18nNameKey } from "../constants";
import { translateApiName } from "../../../../config/i18n/utils";
import { TypeProperty } from "../../../model/schemas/TypeProperty";

export const isTypePropertyResponse = (
  response: AxiosResponse<unknown>
): response is AxiosResponse<TypeProperty> =>
  isResponseTypeIdMatching(response, TypeIds.TypeProperty)
    && !Array.isArray((response.data as any)?.data)

export const parseTypeProperty = (typeProperty: TypeProperty) => {
  return {
    ...typeProperty,
    [i18nNameKey]: typeProperty.isLocked
      ? translateApiName(typeProperty.$typeId, typeProperty.name)
      : undefined
    ,
    [i18nDataTypeKey]: translateApiName(typeProperty.$typeId, typeProperty.dataType),
  };
}
