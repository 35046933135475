import { DataType } from '../../../../../../../../api/model/schemas/DataType';
import { FormatType } from '../../../../../../../../api/model/schemas/FormatType';
import { AggregationType } from '../../../../../../../../api/model/schemas/AggregationType';
import {
  StreamerFilter,
  StreamAssetRaw,
  StreamerAsset,
} from '../../../../../../types';
import { formatValue } from '../FormattedValue/utils';
import { selectedAssetsToDataRequestParams } from '../../../../../../sagas/utils';
import { TYPE_IDS } from '../../../../../../../../constants/apiV4TypeIds';
import { fetchStreamDataRequest } from '../../../../../../utils';

export const isAssetInFilters = (
  asset: StreamAssetRaw,
  filters: StreamerFilter[]
) => filters.some((f) => f.item.name === asset.name);

export const isNumberFormat = (asset: StreamAssetRaw) => {
  const processedDataType = asset?.processedDataType;
  const processedFormatType = asset?.processedFormatType;
  const isRawNumber = processedFormatType === FormatType.Number;
  if (
    [DataType.NumericDecimal, DataType.NumericInteger].includes(
      processedDataType
    )
  ) {
    if (isRawNumber) {
      return false;
    }

    return true;
  }

  return false;
};

interface GenerateTotals {
  values: any[][];
  assets: StreamAssetRaw[];
  aggregations: AggregationType[];
}

export const isAggregatedNumberCalc = (
  asset: StreamAssetRaw,
  aggregation: AggregationType
) =>
  asset?.$typeId === TYPE_IDS.StreamCalculation &&
  asset?.isAggregated &&
  (!aggregation || aggregation === AggregationType.None) &&
  [DataType.NumericDecimal, DataType.NumericInteger].includes(
    asset.processedDataType
  );

export const sumValues = (...values: any[]) =>
  values.reduce((a, b) => (+a || 0) + (+b || 0), 0);

export const convertValuesToDates = (values: any[], dataType: DataType) =>
  values.map((val) =>
    dataType === DataType.DateTime ? new Date(val).getTime() || 0 : +val || 0
  );

export const generateTotals = ({
  values,
  assets,
  aggregations,
}: GenerateTotals) =>
  values[0]?.map((_, index) => {
    const column = values.map((row) => row[index]);

    if (isAggregatedNumberCalc(assets?.[index], aggregations?.[index])) {
      return sumValues(...column);
    }

    switch (aggregations?.[index]) {
      case AggregationType.Average: {
        return sumValues(...column) / column.length || 0;
      }
      case AggregationType.Count:
      case AggregationType.CountDistinctIncludingNull:
      case AggregationType.CountDistinctExcludingNull:
      case AggregationType.Sum:
        return sumValues(...column);
      case AggregationType.Minimum:
        return Math.min(
          ...convertValuesToDates(column, assets[index].processedDataType)
        );
      case AggregationType.Maximum:
        return Math.max(
          ...convertValuesToDates(column, assets[index].processedDataType)
        );
      default:
        return '';
    }
  }) ?? [];

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

interface GetTableColumnsSizes {
  data: { [key: string]: any }[];
  headers: string[];
  assets: StreamAssetRaw[];
  filters: StreamerFilter[];
}

export const getTableColumnsSizes = ({
  data,
  headers,
  assets,
  filters,
}: GetTableColumnsSizes) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const body = getComputedStyle(document.body);

  context.font = `${body.fontSize} ${body.fontFamily}`;

  const strings = data.slice(0, 250).map((entry) =>
    Object.values(entry).map((value, index) =>
      String(
        formatValue({
          asset: assets[index],
          value,
        })
      )
    )
  );

  return [headers, ...strings].reduce<number[]>(
    (arr, currRow, rowIndex) =>
      currRow.map((value, colIndex) => {
        const hasFilters = isAssetInFilters(assets[colIndex], filters);
        const isHeader = rowIndex === 0;
        const valSize =
          context.measureText(value).width + (hasFilters && isHeader ? 20 : 0);
        return arr[colIndex] > valSize ? arr[colIndex] : valSize;
      }),
    []
  );
};

const getIsTotalable = <
  T extends {
    streamElementTypeId: string;
    isAggregated: boolean;
    aggregation?: AggregationType;
  }
>(
  asset: T
) =>
  (asset.streamElementTypeId === TYPE_IDS.StreamCalculation &&
    asset?.isAggregated) ||
  (!!asset.aggregation && asset.aggregation !== AggregationType.None);

interface FetchTableTotals {
  selected: StreamerAsset[];
  assets: StreamAssetRaw[];
  filters: StreamerFilter[];
  streamId: string;
}

export const fetchTableTotals = async ({
  selected,
  filters,
  streamId,
  assets,
}: FetchTableTotals) => {
  const { columns, data, filter } = selectedAssetsToDataRequestParams(
    selected,
    filters
  );

  // We need to prevent getting totals from assets set as do not summarize
  // when the same asset has been added with any other aggregation.
  // So we filter out the non aggregated one.

  const unique = data.filter((asset, index) => {
    const src = [...data];
    src.splice(index, 1);

    return !(
      asset.aggregation === AggregationType.None &&
      src.some(
        ({ streamElementId }) => streamElementId === asset.streamElementId
      )
    );
  });

  // ----

  const totalable = unique.filter((asset) =>
    getIsTotalable({
      ...asset,
      isAggregated: assets.find(({ id }) => id === asset.streamElementId)
        ?.isAggregated,
    })
  );

  if (!totalable.length) return [];

  const res = await fetchStreamDataRequest({
    selectedStreamId: streamId,
    dataRequestParams: {
      columns: totalable.map((asset) =>
        columns.find((col) => col.id === asset.id)
      ),
      data,
      filter,
    },
  });

  return selected.map((asset) => {
    const index = totalable.findIndex(
      (col) =>
        col.streamElementId === asset.streamElementId &&
        col.aggregation === asset.aggregation
    );

    if (index === -1) {
      return undefined;
    }

    // We select the first row because, since we are fetching totals
    // there should be just one row of data
    return res?.rows?.[0]?.[index] || undefined;
  });
};
