// NOTE: those are type ids that are not listed among the schemas in the API, nor are they returned in most requests
// but they are used as "context" for some of the translations
export const TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP = {
  Boolean: '4ebaa5da-936c-40bc-83d5-8f049061888e',
  DateTime: 'eaf2416a-73b1-4520-96f6-652692649123',
  NumericDecimal: '21a7b1db-3b08-4e5a-b27b-e48d6d7c2814',
  NumericInteger: 'c8ab6b52-0a50-4169-ab43-7b8bf2b87919',
  Text: 'ff08135e-d586-4fa6-8d92-85f002ce6efe',
  Address: '62261b7e-4342-430f-86fa-972f86b81f6f',
};

// NOTE: those are type ids that are not listed among the schemas in the API but they are used as "context" for i18n
export const CALCULATION_TYPE_TYPE_ID_MAP = {
  '014b4768-8200-40c4-9450-718302e1757e': 'Average',
  '06b31a0f-66ce-4596-8303-66c90c5a5b82': 'Left',
  '0704606a-13f0-4c52-956e-c2a2c74780ce': 'Count',
  '16f64e8a-56e3-4bd0-9012-159d3d706942': 'Product',
  '3a5251c6-eeab-4f1a-8f38-08b0382e753b': 'Right',
  '896d771f-6cef-4e48-9799-f30964fcd1d3': 'Minimum',
  'acce81ab-5368-4101-b10a-ee73be4b19fb': 'Group',
  'ae0de43c-be5a-4dc6-9b1e-684fa9e90e6e': 'Sum',
  'cc78dc73-797e-42cc-b6fe-8add53853a7c': 'Maximum',
  'd87e09f3-7ce0-4548-b2ca-0aeac9cbdd6a': 'CountDistinctNoBlank',
  'ecc962ac-0e2c-4dfa-9b63-60c1f590921b': 'CountDistinct',
  'f7f211ca-9975-49c9-acfd-871c13007850': 'Mid',
  '29373565-5a41-4e98-926d-e557eefdbf5d': 'DaxCalculation',
  'ceb90308-1d8c-49a1-a34d-e350850e59d1': 'DaxMeasure',
  '938df252-d46b-4111-8c4f-57a51ba884bb': 'Now',
  'f898acbe-7b8f-4c4f-ad84-8ef8b4958b5d': 'Today',
};
