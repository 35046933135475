import { ITheme } from '@fluentui/react';

export const getStyles = (theme:ITheme, rowDifference: number) => ({
  datasetInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexShrink: '0!important',
    // minWidth: '150px',
    paddingRight: 8,
    boxSizing: 'border-box',
  },
  status: {
    color: theme.palette.themePrimary,
    marginBottom: 11,
    lineHeight: '11px',
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  filter: {
    color: theme.palette.themePrimary,
    marginLeft: '8px!important',
    cursor: 'help',
  },
  columnNameTooltip: {
    color: theme.palette.themePrimary,
    fontWeight: 700,
  },
  filterRule: {
    marginBottom: 5,
  },
  rows: {
    color: rowDifference > 0 ? theme.palette.neutralTertiary : 'inherit',
  },
});
