import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18N_PREFIX } from '../constants';
import { OnShareCallback, ShareLinkDataType } from '../model';
import { classNames } from '../styles';
import { generatePlainLink } from '../utils';
import { ShareIndicator } from './ShareIndicator';

interface Props {
  copyingFinished?: ShareLinkDataType;
  isError: boolean;
  onShare(
    shareButtonType: ShareLinkDataType,
    callback: OnShareCallback
  ): Promise<void>;
  selectedCardKeys: number[];
  writingToClipboard?: ShareLinkDataType;
}

export const ShareLinkButton = ({
  copyingFinished,
  isError,
  onShare,
  selectedCardKeys,
  writingToClipboard,
}: Props): JSX.Element => {
  // DEPS
  const { t } = useTranslation();

  // DERIVED STATE
  const isCopyingFinished = copyingFinished === ShareLinkDataType.Plain;
  const isWritingToClipboard = writingToClipboard === ShareLinkDataType.Plain;

  // CALLBACKS
  const onLinkShare = React.useCallback(() => {
    onShare(ShareLinkDataType.Plain, async (selectionPreviews) => {
      const plainLinks = selectedCardKeys
        .map((cardKey) => {
          const cardPreview = selectionPreviews[cardKey];

          if (!cardPreview) return undefined;

          const { parentTitle, title } = cardPreview;

          return generatePlainLink({
            title: `${parentTitle} – ${title}`,
          });
        })
        .join('\n');

      const data = [
        new ClipboardItem({
          // @ts-ignore – TS types require the value of the dictionary to be a promise
          // while using `Promise.resolve()` to circumvent that causes runtime errors
          [ShareLinkDataType.Plain]: new Blob([plainLinks], {
            type: ShareLinkDataType.Plain,
          }),
        }),
      ];

      await navigator.clipboard.write(data);
    });
  }, [onShare, selectedCardKeys]);

  // RENDER
  return (
    <button
      className={classNames.shareButton}
      onClick={onLinkShare}
      type="button"
    >
      <ShareIndicator
        {...{ isCopyingFinished, isError, isWritingToClipboard }}
      />
      {t(`${I18N_PREFIX}.buttons.link`)}
    </button>
  );
};
