import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderBarButton } from '../../../../components/ui';
import { routes } from '../../../../constants/routes';
import { streamerProcessingIntent } from '../../actions';
import streamSelectors from '../../selectors';

const StreamProcessButton = () => {
  const selectedStream = useSelector(streamSelectors.getCurrentStream);
  const history = useHistory();

  const handleClick = () => {
    history.push(routes.process.streamFromStreamer, {
      processStreamActionCreator: streamerProcessingIntent,
      selectedStream,
    });
  };

  return (
    <HeaderBarButton
      tooltip={<Trans i18nKey="wizard:ProcessTooltip" />}
      onClick={handleClick}
      iconProps={{ iconName: 'Processing' }}
    />

  );
};

export default StreamProcessButton;
