import React, { useMemo, useState } from 'react';

import { CommandButton, DirectionalHint } from '@fluentui/react';

import { isEqual, every, isEmpty } from 'lodash';
import DataSchemaMapper from '../../components/DataSchemaMapper';
import InformationDialog from '../../components/ui/InformationDialog';

import { infoButtonClassname, infoButtonStyles } from './styles';
import { ADDIN_PANE_OFFSET } from './constants';
import { SchemaEntry } from '../../components/DataSchemaMapper/model';
import { patchEntity } from '../../modules/Designer/api';
import { Availability } from '../../api/model/schemas/Availability';

export interface InfoParams {
  data: any;
  schema: SchemaEntry[];
  title?: React.ReactNode | string;
  assetAvailability?: Availability

  enableInlineEdit?: boolean,
  onEntityUpdated?(): void;
}

interface Params {
  info?: InfoParams;
  target: string;
  isSelected?: boolean;
}

export default ({
  info,
  target,
  isSelected,
}: Params) => {
  // STATE
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [data, setData] = useState({});

  const isDataChanged = useMemo(
    () => !isEmpty(data) && every(data, (value, key) => !isEqual(value, info?.data?.[key])),
    [info?.data, data],
  );

  // CALLBACKS
  const onClickInfo = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsInfoOpen((isOpen) => !isOpen);
    },
    [],
  );

  const onUpdateData = async () => {
    setIsUpdating(true);
    await patchEntity(info?.data?.$typeId)({
      id: info?.data?.id,
      ...data,
    });
    setIsUpdating(false);
    info?.onEntityUpdated();
  };

  const onChangeData = (attribute: string, value: any) => {
    setData({
      ...data,
      [attribute]: value,
    });
  };

  const closeDialog = React.useCallback(
    () => { setIsInfoOpen(false); },
    [],
  );

  // PARTS
  const infoDialogToggleButton = info && (
    <CommandButton
      data-testid="open-infobox-btn"
      styles={infoButtonStyles(isSelected)}
      className={infoButtonClassname}
      onClick={onClickInfo}
      iconProps={{
        iconName: 'Info',
      }}
    />
  );

  const infoDialog = info && (
    <InformationDialog
      {...{ target }}
      onApprove={isDataChanged && onUpdateData}
      onDismiss={closeDialog}
      onClose={closeDialog}
      calloutWidth={document.body.clientWidth - ADDIN_PANE_OFFSET}
      directionalHint={DirectionalHint.bottomRightEdge}
      isCalloutVisible={isInfoOpen}
      isLoading={isUpdating}
      title={info.title}
      assetAvailability={info.assetAvailability}
    >
      <DataSchemaMapper
        item={info.data}
        schema={info.schema}
        enableInlineEdit={info.enableInlineEdit}
        onChangeData={onChangeData}
      />
    </InformationDialog>
  );

  // RESULT
  return {
    infoDialog,
    infoDialogToggleButton,
    isInfoOpen,
    setIsInfoOpen,
  };
};
