import { ITextStyles } from '@fluentui/react';
import { CSSProperties } from 'react';
import { baseColors } from '../../../../theme';

export const dropzoneStyles: CSSProperties = {
  border: `1px dashed ${baseColors.orange}`,
  padding: '50px',
  textAlign: 'center',
  cursor: 'pointer',
};

export const textStyles: ITextStyles = {
  root: {

  },
};
