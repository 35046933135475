import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { IEntityForm, IEntityProperty, IGenericEntity } from '../types';
import Field from './Field';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { getActualPropertyInteraction } from '../utils';
import { getEntityById } from '../selectors';

const Property: React.FC<{
  currentEntity?: IGenericEntity;
  currentForm?: IEntityForm;
  isDisabled?: boolean;
  label: string;
  name?: string;
  property: IEntityProperty;
  translationPrefix?: string;
  watched?: { [x: string]: any };
}> = ({
  currentEntity,
  currentForm,
  isDisabled = false,
  label,
  name,
  property,
  translationPrefix,
  watched,
}) => {
  const { unregister } = useFormContext();
  const parentEntity = useSelector(
    getEntityById(currentForm?.parentEntityId || currentEntity?.parentId)
  );

  if (!property) {
    return null;
  }

  const isEditing = !!currentForm?.entityId;

  useEffect(() => {
    if (
      property?.isReadOnly ||
      (!property.isEditableAfterCreation && isEditing)
    ) {
      unregister(property.name);
    }
  }, []);

  const inputName = name || property.name;

  const interaction = getActualPropertyInteraction({
    property,
    watched,
    parentEntity,
    currentData: currentForm?.data,
    currentEntity,
  });

  if (!interaction) {
    return null;
  }

  // Stream entity holds the value of the design as parentId, but
  // the field is called design, here we consolidate that.

  const isStreamDesignField =
    property.name === 'design' && currentForm.typeId === TYPE_IDS.Stream;

  const formDataValue = get(currentForm?.data, inputName);

  let initialValue =
    formDataValue ||
    (isStreamDesignField
      ? currentEntity?.parentId
      : currentEntity?.[property.name]) ||
    interaction?.defaultValue;

  // When editing a ColumnToRows transformation, we need to get the selected ids
  // from an array of fields
  if (property.id === TYPE_IDS.TransformFieldsIdsProperty) {
    initialValue = (currentEntity?.transformationFields || []).map(
      ({ id }) => id
    );
  }

  return (
    <Field
      {...{
        currentEntity,
        currentForm,
        inputName,
        interaction,
        isDisabled,
        label,
        translationPrefix,
        isEditing,
        watched,
        initialValue,
      }}
      entityProperty={property}
    />
  );
};

export default Property;
