import React from 'react';
import { useSelector } from 'react-redux';
import { LinkFields } from '../../../../../../api/model/schemas/LinkFields';
import { LinkStatus } from '../../../../../../api/model/schemas/LinkStatus';
import { Props as LinkFieldsProps } from '../../../components/LinkFields';
import {
  Props as LinkGroupProps,
  LinkGroup,
} from '../../../components/LinkGroup/LinkGroup';
import { getAllLinks } from '../../../selectors';
import { DesignerIngestionState } from '../../../types';
import { IngestionLinkFields } from './IngestionLinkFields';

type LinkGroupPropsKeys =
  | 'from'
  | 'openGroupId'
  | 'searchQuery'
  | 'setOpenGroupId'
  | 'to';

type LinkFieldsKeys = 'sourceData';

interface Props
  extends Pick<LinkGroupProps, LinkGroupPropsKeys>,
    Pick<LinkFieldsProps, LinkFieldsKeys> {
  linkFieldsIds: LinkFields['id'][];
  linksFields: DesignerIngestionState['linksFields'];
}

export const FromToGroup = ({
  from,
  linkFieldsIds,
  linksFields,
  openGroupId,
  searchQuery,
  setOpenGroupId,
  sourceData,
  to,
}: Props): JSX.Element => {
  // STATE
  const links = useSelector(getAllLinks);

  // DERIVED STATE
  const linkFieldsList = React.useMemo(
    () => linkFieldsIds.map((id) => linksFields[id]),
    [linksFields]
  );

  // In the recent change, links are given name
  // whole group share the same parent
  const groupName = linkFieldsList[0]?.parent?.name

  const hasProposedLinks = React.useMemo(
    () =>
      linkFieldsList.some(
        (linkFields) =>
          links[linkFields.parentId].status === LinkStatus.Proposal
      ),
    [linkFieldsList, links]
  );

  // PARTS
  const linkFieldsComponents = linkFieldsList.map((linkFields) => {
    const link = links[linkFields.parentId];
    return (
      <IngestionLinkFields
        {...{
          link,
          linkFields,
          sourceData,
          searchQuery,
        }}
        isQueued={link.status === LinkStatus.Proposal}
        key={linkFields.id}
      />
    );
  });

  // RENDER
  return (
    <LinkGroup
      {...{
        from,
        groupName,
        openGroupId,
        searchQuery,
        setOpenGroupId,
        to,
      }}
      isOrange={hasProposedLinks}
    >
      {linkFieldsComponents}
    </LinkGroup>
  );
};
