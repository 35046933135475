import React from 'react';
import {
  IconButton, Callout, DirectionalHint, TooltipHost, ITooltipHostProps, Spinner,
} from '@fluentui/react';

import {
  styles as mergedStyles,
  calloutStyles,
  buttonStyles,
  titleBar,
  contentStyles,
  titleTextStyles,
  spinnerStyles,
} from './styles';
import { Availability } from '../../../api/model/schemas/Availability';
import { availabilityTextStyles } from '../../EntityField/styles';

export interface Props {
  isCalloutVisible: boolean;
  target: string;
  onDismiss(ev?: any): void;
  onClose(): void;
  onApprove?(): void;
  labelId?: string;
  descriptionId?: string;
  calloutWidth: number;
  title?: string | React.ReactNode;
  directionalHint?: DirectionalHint;
  isLoading?: boolean;
  assetAvailability?: Availability
}

const InformationDialog: React.FC<Props> = ({
  isCalloutVisible,
  children,
  target,
  onDismiss,
  onClose,
  onApprove,
  labelId,
  descriptionId,
  calloutWidth,
  title,
  directionalHint,
  isLoading,
  assetAvailability,
}) => {
  if (!isCalloutVisible) {
    return null;
  }

  return (
    <Callout
      className={mergedStyles.callout}
      ariaLabelledBy={labelId}
      ariaDescribedBy={descriptionId}
      role="alertdialog"
      gapSpace={0}
      target={target}
      onDismiss={onDismiss}
      setInitialFocus={false}
      isBeakVisible={false}
          // this is needed to prevent focusing on button after closing infobox
          // shouldRestoreFocus is obsolete according to docs
      onRestoreFocus={() => {}}
      styles={calloutStyles}
      calloutWidth={calloutWidth}
      directionalHint={directionalHint}
    >
      <div style={titleBar}>
        <h3 style={titleTextStyles}>
          <TooltipHost content={title as ITooltipHostProps['content']}>
            <span style={availabilityTextStyles(assetAvailability)}>
              { title }
            </span>
          </TooltipHost>
        </h3>
        {
          isLoading
            ? (
              <Spinner styles={spinnerStyles} />
            ) : (
              <>
                { onApprove && (
                  <IconButton styles={buttonStyles} onClick={onApprove} iconProps={{ iconName: 'CheckMark' }} data-testid="infobox-accept-btn" />
                )}
                <IconButton styles={buttonStyles} onClick={onClose} iconProps={{ iconName: 'Cancel' }} data-testid="infobox-cancel-btn" />
              </>
            )
        }
      </div>
      <div style={contentStyles}>
        {children}
      </div>
    </Callout>
  );
};

export default InformationDialog;
