import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { ColumnsSequencingState } from './types';

export const initialState: ColumnsSequencingState = {
  selectedItemsIds: [],
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.setSelectedItemsIds, (state, { payload }) => ({
    ...state,
    selectedItemsIds: payload,
  })));

export default reducer;
