import { QueryOptions } from 'odata-query';
import {
  createNestedEntity,
  fetchNestedEntities,
  patchEntity,
  fetchEntity,
} from '../api';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';

import {
  IDesignSource,
  ApiV4ResponseWrapper,
  IDesignSourceEntity,
  IDesignSourceEntityField,
  IDesignSourceFieldValuesRequestResponse,
} from '../types';
import { IDesignerFilter } from '../../../types/IDesignerFilter';
import { Link } from '../../../api/model/schemas/Link';
import { LinkAnalysisRequest, LinkAnalysisRequestFields } from './types';
import { DataSourceEntityField } from '../../../api/model/schemas/DataSourceEntityField';

export const fetchDataSources = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSource[]>
>({
  typeId: TYPE_IDS.DataSource,
});
export const fetchLinks = fetchNestedEntities<ApiV4ResponseWrapper<Link[]>>({
  typeId: TYPE_IDS.Link,
});

export const fetchEntitiesForSource = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSourceEntity[]>
>({
  typeId: TYPE_IDS.SourceEntity,
});
export const fetchSingleEntityForSource = (entityId: string) =>
  fetchEntity<ApiV4ResponseWrapper<IDesignSourceEntity>>(
    TYPE_IDS.SourceEntity,
    entityId
  );

export const fetchFieldsForEntitySource = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSourceEntityField[]>
>({
  typeId: TYPE_IDS.SourceEntityField,
});

export const requestSourceFieldValues = (
  fieldId: string,
  oDataParams?: Partial<QueryOptions<unknown>>
) =>
  createNestedEntity<IDesignSourceFieldValuesRequestResponse>({
    parentEntityId: fieldId,
    typeId: TYPE_IDS.SourceEntityFieldValuesRequest,
  })({}, oDataParams);

export const requestAssetValues = (
  parentEntityId: string,
  requestTypeId: string,
  continuationToken?: string,
  oDataParams?: Partial<QueryOptions<unknown>>
) =>
  createNestedEntity<IDesignSourceFieldValuesRequestResponse>({
    parentEntityId,
    typeId: requestTypeId,
  })({ continuationToken }, oDataParams);

export const patchDataSourceEntityField = patchEntity<DataSourceEntityField>(
  TYPE_IDS.SourceEntityField
);

export const createSourceEntityFieldFilter = ({ fieldId, filter }: any) =>
  createNestedEntity<IDesignerFilter>({
    typeId: TYPE_IDS.SourceEntityFieldFilter,
    parentEntityId: fieldId,
  })(filter);

export const editSourceEntityFilter = (
  updatedFilter: Partial<IDesignerFilter>
) =>
  patchEntity<IDesignerFilter>(TYPE_IDS.SourceEntityFieldFilter)(updatedFilter);

export const processIngestionJob = (dataSourceId: string) =>
  createNestedEntity<IDesignerFilter>({
    typeId: TYPE_IDS.ProcessDataSourceJob,
    parentEntityId: dataSourceId,
  })({});
export const configureDataSource = (
  dataSourceId: string,
  reloadFields = false
) =>
  createNestedEntity<any>({
    typeId: TYPE_IDS.ConfigureDataSourceJob,
    parentEntityId: dataSourceId,
  })({ reloadFields });

export const configureSourceEntity = (entityId: string) =>
  createNestedEntity({
    typeId: TYPE_IDS.ConfigureSourceEntityJob,
    parentEntityId: entityId,
  })({});

export const requestLinkAnalysis = (
  sourceId: string,
  fields: LinkAnalysisRequestFields
) =>
  createNestedEntity<LinkAnalysisRequest>({
    typeId: TYPE_IDS.LinkAnalysisRequest,
    parentEntityId: sourceId,
  })({ fields });
