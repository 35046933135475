import { DeepWriteable } from "."

/**
 * enforces a given interface `X` while preserving the type of `Y`
 * useful for preserving literal types but forcing their base type
 *
 * example:
 * ```ts
 * const good = { foo: 1, bar: 1 } as const
 * const bad = { bar: 1 } as const
 *
 * type Foo = { foo: number }
 *
 * const preserved = enforceSchema<Foo>()(good)
 * // typeof preserved == { foo: 1, bar: 1 }
 * const error = enforceSchema<Foo>()(bad)
 * // Property 'foo' is missing in type '{ bar: 1; }' but required in type 'Foo'
 * ```
 * */
export const enforceSchema = <X>() => <Y extends X>(x: Y) => x

/**
 * same as {@linkcode enforceSchema} but also freezes the object
 */
export const constant = <X>() => <Y extends X>(x: Y) => Object.freeze(x)

/**
 * similar to {@linkcode enforceSchema} but expects exactly the interface you pass to it
 * 
 * useful for enforcing an interface "in place", e.g.:
 * ```ts
 * // `defaultsDeep` has a very wide type (`any`)
 * declare defaultsDeep(object: any, ...sources: any[]): any
 * // but we can still say what we expect to pass to it
 * defaultsDeep(enforceInterface<{ foo: number }>({ foo: 123 }))
 * ```
 */
export const enforceInterface = <X>(x: X) => x

/**
 * cast the type of `x` to `DeepWriteable<typeof x>` without the need to use `typeof`
 *
 * example:
 * ```ts
 * const foo = writeable({ foo: 1 } as const)
 * // `typeof foo == { foo: 1 }` as opposed to `{ readonly foo: 1 }`
 * ```
 */
export const writeable = <T>(x: T) => x as DeepWriteable<T>;