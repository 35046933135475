import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import streamsSelectors from '../../../Streams/selectors';
import { filterStreams } from '../../utils';

import StreamsList from '../../components/StreamsList';
import { getSearchState } from '../../../Search/selectors';
import { fetchAllStreams } from '../../../Streams/actions';
import { classNames } from './styles';
import StreamListShimmer from '../../../../components/StreamListShimmer';
import { Redirect } from 'react-router-dom';
import { routes } from '../../../../constants/routes';

const RecentStreams = () => {
  const dispatch = useDispatch();
  const recentStreams = useSelector(streamsSelectors.getRecentStreams);
  const areStreamsPending = useSelector(streamsSelectors.getAreStreamsPending);
  const { searchQuery } = useSelector(getSearchState);

  const filteredStreams = filterStreams(recentStreams, searchQuery);

  React.useEffect(() => {
    dispatch(fetchAllStreams());
  }, []);


  if (areStreamsPending) {
    return (
      <div className={classNames.container}>
        <StreamListShimmer />
      </div>
    );
  }

  if (!areStreamsPending && recentStreams.length === 0) {
    return  <Redirect to={routes.favorites.sharedWithMe.index} />
  }

  return (
    <StreamsList streams={filteredStreams} />
  );
};

export default RecentStreams;
