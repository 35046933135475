import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Grid, AutoSizer, ScrollSync, GridCellProps } from 'react-virtualized';

import { FontIcon, Shimmer } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { sum } from 'lodash';
import { StreamAssetRaw, StreamerFilter } from '../../../../../../types';
import FormattedValue from '../FormattedValue';
import { classNames as outputClassNames } from '../../styles';
import { ValueType } from '../FormattedValue/FormattedValue';
import {
  CARD_KEY_PROP,
  getAreTableTotalsEnabled,
  getIsAllDataFetched,
  getIsFetchingStreamerOutput,
  getSwimlanesAssetId,
} from '../../../../selectors';
import { classNames } from './styles';
import {
  fetchTableTotals,
  getScrollbarWidth,
  getTableColumnsSizes,
  isAssetInFilters,
  isNumberFormat,
} from './utils';
import { fetchNextPage } from '../../../../actions';
import {
  getPreviouslyFetchedAssets,
  getSelectedStreamId,
} from '../../../../../../selectors';
import OutputTableCell from './OutputTableCell';
import { ENTRY_PLACEHOLDER, ROW_HEIGHT, CELL_X_PADDING } from './constants';

interface Props {
  assets: StreamAssetRaw[];
  filters: StreamerFilter[];
  data: { [key: string]: any }[];
}

const OutputTable: React.FC<Props> = ({ assets, filters, data: rawData }) => {
  const isRetrievingOutputData = useSelector(getIsFetchingStreamerOutput);
  const isAllDataFetched = useSelector(getIsAllDataFetched);
  const streamId = useSelector(getSelectedStreamId);
  const streamerAssets = useSelector(getPreviouslyFetchedAssets);
  const swimlanesId = useSelector(getSwimlanesAssetId);
  const [hoveredRow, setHoveredRow] = useState<number>();
  const areTableTotalsEnabled = useSelector(getAreTableTotalsEnabled);
  const [totals, setTotals] = useState({
    records: [],
    isLoading: false,
  });
  const dispatch = useDispatch();

  const data: { [key: string]: any }[] = useMemo(() => {
    const src = rawData.map(({ [CARD_KEY_PROP]: _key, ...entry }) => entry);

    // Add shimmer/placeholder rows
    if (isRetrievingOutputData) {
      return [...src, ...Array(4).fill(ENTRY_PLACEHOLDER)];
    }

    return src;
  }, [rawData, isRetrievingOutputData]);

  const headers = useMemo(() => Object.keys(data[0]), [data]);

  const scrollBarWidth = useMemo(() => getScrollbarWidth(), []);

  const columnWidths = useMemo(
    () =>
      getTableColumnsSizes({
        data,
        assets,
        headers,
        filters,
      }),
    [headers, assets, data]
  );

  const onSectionRendered = useCallback(
    ({ rowStopIndex }) => {
      if (swimlanesId) return;
      if (
        rowStopIndex >= data.length - 5 &&
        !isRetrievingOutputData &&
        !isAllDataFetched
      ) {
        dispatch(fetchNextPage());
      }
    },
    [data, isRetrievingOutputData, isAllDataFetched]
  );

  const headerCellRenderer = useCallback(
    ({ columnIndex, key, style }: GridCellProps) => (
      <div key={key} style={style} className={classNames.cell}>
        {isAssetInFilters(assets[columnIndex], filters) && (
          <FontIcon iconName="Filter" className={classNames.filterIcon} />
        )}
        {headers[columnIndex]}
      </div>
    ),
    [headers]
  );

  const footerCellRenderer = useCallback(
    ({ columnIndex, key, style }: GridCellProps) => (
      <div
        key={key}
        style={style}
        className={cn([
          classNames.cell,
          { [classNames.alignRight]: isNumberFormat(assets[columnIndex]) },
        ])}
      >
        {totals.isLoading ? (
          <Shimmer />
        ) : (
          <FormattedValue
            asset={assets[columnIndex]}
            value={totals.records[columnIndex]}
            valueType={ValueType.VirtualizedTableValue}
          />
        )}
      </div>
    ),
    [assets, filters, totals, areTableTotalsEnabled]
  );

  const bodyCellRenderer = useCallback(
    (props: GridCellProps) => (
      <OutputTableCell
        {...{
          assets,
          hoveredRowIndex: hoveredRow,
          setHoveredRowIndex: setHoveredRow,
          data,
          headers,
          areTableTotalsEnabled,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        uniqueKey={props.key}
      />
    ),
    [data, assets, headers, hoveredRow, areTableTotalsEnabled]
  );

  useEffect(() => {
    (async () => {
      if (swimlanesId || !areTableTotalsEnabled) return;
      setTotals({ isLoading: true, records: [] });
      const records = await fetchTableTotals({
        filters,
        streamId,
        selected: streamerAssets,
        assets,
      });
      setTotals({ isLoading: false, records });
    })();
    // We do not want to re-fetch totals when selection/filters/order change
    // but only when we receive freshly streamed data
  }, [data, areTableTotalsEnabled]);

  return (
    <section
      className={cn([
        outputClassNames.column,
        outputClassNames.wrapper,
        classNames.wrapper,
      ])}
    >
      <ScrollSync>
        {({ onScroll, scrollLeft }) => (
          <AutoSizer>
            {({ height, width }) => {
              const hasHorizontalScrollBar = sum(columnWidths) > width;
              const hasVerticalScrollBar = data.length * ROW_HEIGHT > height;
              return (
                <>
                  <Grid
                    className={classNames.headerGrid}
                    height={ROW_HEIGHT}
                    scrollLeft={scrollLeft}
                    rowCount={1}
                    rowHeight={ROW_HEIGHT}
                    columnCount={headers.length}
                    width={width - (hasVerticalScrollBar ? scrollBarWidth : 0)}
                    columnWidth={({ index }) =>
                      columnWidths[index] + CELL_X_PADDING
                    }
                    cellRenderer={headerCellRenderer}
                  />
                  <Grid
                    {...{
                      onScroll,
                      scrollLeft,
                      width,
                      onSectionRendered,
                    }}
                    className={classNames.bodyGrid}
                    // Use the smallest value
                    height={Math.min(
                      height - ROW_HEIGHT * (areTableTotalsEnabled ? 2 : 1), // viewport height - header and total rows
                      data.length * ROW_HEIGHT +
                        (hasHorizontalScrollBar ? scrollBarWidth : 0) // sum of row heights + scroll bar
                    )}
                    rowHeight={ROW_HEIGHT}
                    rowCount={data.length}
                    columnCount={headers.length}
                    columnWidth={({ index }) =>
                      columnWidths[index] + CELL_X_PADDING
                    }
                    cellRenderer={bodyCellRenderer}
                  />

                  {areTableTotalsEnabled && (
                    <Grid
                      className={classNames.footerGrid}
                      height={ROW_HEIGHT}
                      scrollLeft={scrollLeft}
                      rowCount={1}
                      rowHeight={ROW_HEIGHT}
                      columnCount={headers.length}
                      width={
                        width - (hasVerticalScrollBar ? scrollBarWidth : 0)
                      }
                      columnWidth={({ index }) =>
                        columnWidths[index] + CELL_X_PADDING
                      }
                      cellRenderer={footerCellRenderer}
                    />
                  )}
                </>
              );
            }}
          </AutoSizer>
        )}
      </ScrollSync>
    </section>
  );
};

export default OutputTable;
