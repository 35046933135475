import {
  ITextStyles,
  ITheme,
} from '@fluentui/react';

export const getTextStyles = (theme: ITheme):ITextStyles => ({
  root: {
    position: 'absolute',
    right: '5px',
    top: '0',
    bottom: '0',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.white,
  },
});
