import { CancelToken, AxiosResponse } from 'axios';
import getClient from '../../../api/getClient';
import { typeSectionPath } from '../../../constants/apiPaths';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { createNestedEntity } from '../api';
import { HubAnalysisRequest, HubType } from '../Catalog/types';
import { ApiV4ResponseWrapper } from '../types';
import { IEntityFormSection } from './types';

interface FetchEntityFormArgs {
  typeId: string;
  cancelToken?: CancelToken;
  designId?: string;
}
export const fetchEntityForm = async ({
  typeId,
  cancelToken,
  designId,
}: FetchEntityFormArgs): Promise<
  AxiosResponse<ApiV4ResponseWrapper<IEntityFormSection[]>>
> => {
  const client = await getClient();
  return client.get(typeSectionPath({ typeId, designId }), { cancelToken });
};

interface RequestHubAnalysis {
  designId: string;
  assetIds: string[];
  hubType: HubType;
}

export const requestHubAnalysis = ({
  designId,
  assetIds,
}: RequestHubAnalysis) =>
  createNestedEntity<HubAnalysisRequest>({
    typeId: TYPE_IDS.HubAnalysisRequest,
    parentEntityId: designId,
  })({ assetIds });
