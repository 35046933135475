/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import * as StreamsStore from '../../Streams';
import {
  itemClicked,
  groupTableItemClicked,
} from '../../actions';
import { getActiveObjectType } from '../../../App/selectors';

import ColumnAggregationButton from './ColumnAggregationButton';

import { IColumn } from '../../../../types/IColumn';
import { ObjectTypes } from '../../../App/types';
import { getSearchQuery } from '../../../Search/selectors';
import useIsColumnQueued from '../../../../hooks/useIsColumnQueued';

import useIsColumnDisabled from '../../../../hooks/useIsColumnDisabled';
import EntityField from '../../../../components/EntityField';
import { SchemaEntry } from '../../../../components/DataSchemaMapper/model';

import { getContainerClassname } from './styles';
import { TYPE_IDS_TO_NAMES } from '../../../../constants/apiV4TypeIds';

interface ColumnProps {
  column: IColumn;
  schema: SchemaEntry[];
}

const StreamerColumn: React.FC<ColumnProps> = ({
  column, schema,
}: ColumnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const searchQuery = useSelector(getSearchQuery);

  const currentDataset = useSelector(StreamsStore.selectors.getCurrentDataset);
  const activeObjectType = useSelector(getActiveObjectType);

  const isColumnSelected = currentDataset?.columns.some((c) => c.id === column.id)
    || false;

  const isColumnQueued = useIsColumnQueued({ column, ignoreAggregationCount: true });
  const isColumnDisabled = useIsColumnDisabled({ column });

  const isMatrixTable = currentDataset
    ? currentDataset.type === ObjectTypes.GROUP_TABLE
    : activeObjectType === ObjectTypes.GROUP_TABLE;

  const handleItemClick = () => dispatch(
    isMatrixTable ? groupTableItemClicked(column) : itemClicked(column),
  );

  return (
    <div
      data-testid={`column-${column.name.toLowerCase()}${
        isColumnSelected ? '-selected' : ''
      }`}
      className={getContainerClassname(isColumnSelected)}
    >
      <EntityField
        isSelectable={!isColumnDisabled}
        isSelected={isColumnSelected}
        isOrange={isColumnQueued}
        isDisabled={isColumnDisabled}
        onTextClick={isColumnDisabled ? null : handleItemClick}
        info={{
          schema,
          data: column,
          title: `${column.name} (${t(`entityTypes:${TYPE_IDS_TO_NAMES[column.streamElementTypeId]}`)})`,
        }}
        slot1={(
          <ColumnAggregationButton
            syncedItem={column}
            isCurrentColumnSelected={isColumnSelected}
            isMatrixTable={isMatrixTable}
            isItemDisabled={isColumnDisabled}
            onClickButton={handleItemClick}
          />
        )}
        searchQuery={searchQuery}
        availability={column.availability}
        name={column.name}
      />
    </div>
  );
};

export default StreamerColumn;
