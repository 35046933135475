import { AuthenticationResult } from '@azure/msal-browser';
import { storageFactory } from '../Storage';
import {
  AUTH_LOCAL_STORAGE_KEY,
  AUTH_STATE_LOCAL_STORAGE_KEY,
  AUTH_CONFIG_LOCAL_STORAGE_KEY,
} from './constants';
import { IAuthConfiguration } from '../../types/IAuthConfiguration';

const authStorage = storageFactory(AUTH_LOCAL_STORAGE_KEY);
export const authConfigSerializer = authStorage<IAuthConfiguration>(
  AUTH_CONFIG_LOCAL_STORAGE_KEY
);
export const authStateSerializer = authStorage<AuthenticationResult>(
  AUTH_STATE_LOCAL_STORAGE_KEY
);
