import moment from 'moment';
import {
    DirectionalHint, Shimmer, ShimmerElementType, Spinner, Stack, Text
} from 'office-ui-fabric-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useId } from '@fluentui/react-utilities';

import { DesignSnapshot } from '../../../../../api/model/schemas/DesignSnapshot';
import IconButton from '../../../../../components/IconButton';
import { CopyButton, HeaderBarButton, InformationDialog } from '../../../../../components/ui';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { deleteEntity, fetchEntities } from '../../../api';
import { ApiV4ResponseWrapper } from '../../../types';
import { getSelectedDesign } from '../../selectors';
import * as styles from './styles';

export const DesignSnapshots = () => {
  // DEPS
  const { t } = useTranslation();

  // STATE
  const selectedDesign = useSelector(getSelectedDesign);
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [snapshots, setSnapshots] = React.useState<DesignSnapshot[]>([]);
  const [isLoading, setLoading] = React.useState(false);
  const [snapshotsBeingDeleted, setSnapshotsBeingDeleted] = React.useState<
    string[]
  >([]);

  // HOOKS
  const componentId = useId('design-import');

  // CALLBACKS
  const closeDialog = React.useCallback(() => {
    setDialogOpen(false);
  }, []);

  const loadSnapshots = React.useCallback(async (markLoading = true) => {
    if (!selectedDesign) return;

    if (markLoading) {
      setLoading(true);
    }

    try {
      const {
        data: { data },
      } = await fetchEntities<ApiV4ResponseWrapper<DesignSnapshot[]>>(
        TYPE_IDS.DesignSnapshot
      )({
        filter: `designId eq ${selectedDesign.id}`,
      });

      setSnapshots(data);
    } finally {
      if (markLoading) {
        setLoading(false);
      }
    }
  }, [selectedDesign]);

  const deleteSnapshot = React.useCallback(
    async (snapshotId: string) => {
      setSnapshotsBeingDeleted((current) => [...current, snapshotId]);
      try {
          await deleteEntity(TYPE_IDS.DesignSnapshot)(snapshotId);
        await loadSnapshots(false);
      } finally {
        setSnapshotsBeingDeleted((current) =>
          current.filter((id) => id !== snapshotId)
        );
      }
    },
    [setSnapshotsBeingDeleted, loadSnapshots]
  );

  // EFFECTS
  React.useEffect(() => {
    if (isDialogOpen) {
      loadSnapshots();
    }
  }, [isDialogOpen]);

  React.useEffect(() => {
    setSnapshots([]);
  }, [selectedDesign?.id]);

  const renderSnapshot = (snap: DesignSnapshot) =>
    snapshotsBeingDeleted.includes(snap.id) ? (
      <Shimmer
        style={{ padding: '4px 0 8px' }}
        shimmerElements={[{ type: ShimmerElementType.line, height: 50 }]}
      />
    ) : (
      <Stack style={styles.snapshotWrapper}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Text style={styles.snapshotTitle}>
            {t('designImportExport:snapshotCreated', {
              date: moment(snap.name).format('h:mm A M/D/YY'),
            })}
          </Text>
          <Stack horizontal>
            <CopyButton textToCopy={snap.id} />
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              onClick={() => deleteSnapshot(snap.id)}
            />
          </Stack>
        </Stack>
        <Text style={styles.snapshotId}>{snap.id}</Text>
      </Stack>
    );

  return (
    <>
      <HeaderBarButton
        id={componentId}
        iconProps={{ iconName: 'SaveAll' }}
        onClick={() => setDialogOpen(true)}
        shouldHideOnDisabled
        disabled={!selectedDesign?.id}
        tooltip={<Trans i18nKey="designImportExport:designSnapshots" />}
      />
      <InformationDialog
        target={`#${componentId}`}
        onDismiss={closeDialog}
        onClose={closeDialog}
        calloutWidth={300}
        directionalHint={DirectionalHint.bottomRightEdge}
        isCalloutVisible={isDialogOpen}
        title={<Trans i18nKey="designImportExport:designSnapshots" />}
      >
        {isLoading && <Spinner />}
        {!isLoading && snapshots.length === 0 && (
          <Text>{t('designImportExport:noSnapshotsAvailable')}</Text>
        )}
        {snapshots.map(renderSnapshot)}
      </InformationDialog>
    </>
  );
};
