import uniq from 'lodash/uniq';
import { OptionGroup } from '../../../../../../../components/FiltersForm/components/ItemSelect';
import { IDesignerFilter } from '../../../../../../../types/IDesignerFilter';
import { searchRegex } from '../../../../../../Search/utils';
import { UIEnhancedDesignSourceEntityField } from '../../../../../types';

export const parseIngestionFiltersToRenderableGroups = (
  ingestionFilters: IDesignerFilter[]
) => {
  const groupsWithDuplicates = ingestionFilters.reduce((acc, cur) => {
    const associatedEntityField =
      cur.item as unknown as UIEnhancedDesignSourceEntityField;

    return [...acc, associatedEntityField.parentEntityName];
  }, []);

  const withoutDuplicates: string[] = uniq(groupsWithDuplicates);

  const groups = withoutDuplicates
    .sort((nameA, nameB) => nameA.localeCompare(nameB))
    .map((entityName) => ({
      entityName,
      filters: ingestionFilters
        .filter((filter) => filter.item.parentEntityName === entityName)
        .sort((filterA, filterB) =>
          filterA.item.name.localeCompare(filterB.item.name)
        ),
    }));

  return groups;
};

export const loadItemOptions =
  (
    sourceFields: UIEnhancedDesignSourceEntityField[],
    ingestionFilters: IDesignerFilter[]
  ) =>
  (textInput: string): OptionGroup[] => {
    const optionGroupsTitles = sourceFields.map(
      (field) => field.parentEntityName
    );

    const alreadyUsedFieldsIds = ingestionFilters.map(
      (filter) => filter?.item?.id
    );

    const optionGroupsTitlesNoDuplicates = uniq(optionGroupsTitles);

    const result = optionGroupsTitlesNoDuplicates.reduce<OptionGroup[]>(
      (accumulator, current) => {
        const obj: OptionGroup = {
          label: current,
          options: sourceFields
            .filter(
              (field) =>
                field.parentEntityName === current &&
                field.name.search(searchRegex(textInput)) !== -1
            )
            .map((field) => ({
              id: field.id,
              name: field.name,
              dataType: field.dataType,
              isDisabled: alreadyUsedFieldsIds.includes(field.id),
            }))
            .sort((fieldA, fieldB) => fieldA.name.localeCompare(fieldB.name)),
        };

        return [...accumulator, obj];
      },
      []
    );

    return result.slice().sort((a, b) => a.label.localeCompare(b.label));
  };
