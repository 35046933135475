import { IButtonStyles, mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../../theme';

export const classes = mergeStyleSets({
  add: {
    flexShrink: 0,
  },
  wrap: {
    padding: '2px 8px 8px',
    marginBottom: 8,
    borderRadius: 4,
    border: `1px solid ${baseColors.inputBorder}`,
    selectors: {
      '&>button': {
        marginLeft: '8px!important',
      },
    },
  },
  asset: {
    flexDirection: 'column',
    alignItems: 'stretch',
    minWidth: 1,
    flexGrow: 1,
    selectors: {
      '&>div:first-child': {
        flexGrow: 1,
      },
    },
  },
});

export const btnStyles: IButtonStyles = {
  root: {
    color: baseColors.offBlack,
  },
  rootPressed: {
    color: baseColors.offBlack,
  },
};
