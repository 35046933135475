import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import Error from '../../Error';
import { ComparisonPerDataType } from '../../../../types/IStreamFilter';
import { SelectDropdown } from '../../../ui';
import { IFilterItem } from '../../../../types/IDesignerFilter';

interface Props {
  item: IFilterItem;
}

const ColumnSection: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation('filters');

  const { control, errors } = useFormContext();

  const comparisonOptions = useMemo(() => {
    const comparisonTypes = ComparisonPerDataType(item?.dataType);

    return comparisonTypes
      .map((type) => ({
        value: type,
        label: t(type),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [item]);

  if (!item) {
    return null;
  }

  return (
    <div style={{ marginTop: '5px' }}>
      <Controller
        render={({ onChange, value }) => (
          <SelectDropdown
            onChange={(option) => {
              onChange(option.value);
            }}
            isMulti={false}
            value={value ? { value, label: t(value) } : null}
            label={t('Comparison')}
            options={comparisonOptions}
            className="filter-comparison-select"
          />
        )}
        name="comparison"
        control={control}
        rules={{ required: true }}
      />
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="comparison"
        message={t('required')}
      />
    </div>
  );
};

export default ColumnSection;
