import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../shared/tutorial-tiles/components/animations/Hubs';

export const TUTORIAL_TILE_KEY = 'builder-catalog-hubs';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:builder:catalog:hubs:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:builder:catalog:hubs:items:selectHubs:description',
      icon: 'SynergiesClicked',
      titleI18nKey: 'tutorialTiles:builder:catalog:hubs:items:selectHubs:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010410941-Builder-Hubs-Create-the-perfect-blend',
};
