import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewControlButton from '../../../../../components/ui/ButtonMenu';
import { DisplayModes } from './types';

const DisplayModeButton : React.FC<{
  currentMode: DisplayModes,
  setMode: (mode : DisplayModes) => void,
  isLogical: boolean,
  disabled: boolean,
}> = ({
  setMode,
  isLogical,
  currentMode,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <ViewControlButton
      onChangeItem={setMode}
      activeItemKey={currentMode}
      disabled={disabled}
      shouldDismissOnChange
      data-testid="group-picker-display-mode-btn"
      items={[
        {
          text: t('wizard:Logic'),
          key: DisplayModes.logic,
          iconProps: {
            iconName: 'flowChart',
          },
        },
        {
          text: t('wizard:Value'),
          key: DisplayModes.values,
          disabled: isLogical,
          iconProps: {
            iconName: 'collapseMenu',
          },
        },
      ]}
    />
  );
};

export default DisplayModeButton;
