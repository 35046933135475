import { IContextualMenuItem } from '@fluentui/react';
import { omit } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderByDirection } from '../../../../../api/model/schemas/OrderByDirection';
import ButtonMenu from '../../../../../components/ui/ButtonMenu';
import { updateStreamerAsset } from '../../../actions';
import { createGetWasAssetSortModeChanged } from '../../../selectors';
import { StreamerAsset } from '../../../types';
import { SORT_SETTINGS } from './constants';
import { StreamerAssetWithUiid } from './types';

interface Props {
  asset: StreamerAssetWithUiid;
}

export const DraggableAssetSortSettings = ({ asset }: Props) => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOrange = useSelector(createGetWasAssetSortModeChanged(asset));

  // DERIVED STATE
  const items: IContextualMenuItem[] = React.useMemo(
    () =>
      Object.values(OrderByDirection).map((value) => ({
        key: value,
        text: t(SORT_SETTINGS.translationsKeys[value]),
        canCheck: true,
        iconProps: {
          iconName: SORT_SETTINGS.icons[value],
        },
      })),
    [t]
  );

  // CALLBACKS
  const handleChangeItem = React.useCallback(
    (newValue: OrderByDirection) => {
      if (newValue === asset.sortMode) return;

      const currentAsset: StreamerAsset = omit(asset, 'uuid');
      dispatch(
        updateStreamerAsset({
          currentAsset,
          updatedAsset: {
            ...currentAsset,
            sortMode: newValue,
          },
        })
      );
    },
    [asset]
  );

  // RENDER
  return (
    <ButtonMenu
      {...{ items, isOrange }}
      onChangeItem={handleChangeItem}
      activeItemKey={asset.sortMode}
    />
  );
};
