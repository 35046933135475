import React, {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { fetchNestedEntities } from '../../../../modules/Designer/api';
import { IGenericEntity } from '../../../../modules/Designer/Form/types';
import { createTag } from '../../../../modules/Designer/Metadata/api';
import { ApiV4ResponseWrapper } from '../../../../modules/Designer/types';
import { CreatableDropdown, SelectDropdown } from '../../../ui';
import { SchemaEntry } from '../../model';

interface MultiSelectEditProps {
  schemaItem: SchemaEntry;
  selectedItems: string[];
  onChange(newItems: string[]): void;
  designId: string;
}

interface Option {
  value: string;
  label: string;
}

const MultiSelectEdit: FunctionComponent<MultiSelectEditProps> = ({
  schemaItem, selectedItems, onChange, designId,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option[]>([]);
  const [isCreating, setCreating] = useState<boolean>(false);

  const fetchOptions = useCallback(async () => {
    const response = await fetchNestedEntities<ApiV4ResponseWrapper<IGenericEntity>>({
      typeId: schemaItem.interaction.referenceType,
    })(designId)();

    const mappedOptions = response?.data?.data?.map(({ name }) => ({
      value: name,
      label: name,
    }));

    const selectedOptions = mappedOptions.filter(
      (o: Option) => selectedItems.includes(o.value),
    );

    setOptions(mappedOptions);
    setValue(selectedOptions);
  }, []);

  const onUpdateValue = React.useCallback((newValue: Option[]) => {
    const newOptions = newValue?.map((o) => o.value) || [];

    setValue(newValue);
    onChange(newOptions);
  }, []);

  const onCreateTag = React.useCallback(async (inputVal: string) => {
    setCreating(true);
    await createTag({ designId, tagData: { name: inputVal } });
    const newOption = { value: inputVal, label: inputVal };

    // allow option to be selected/deselected
    setOptions((prevOptions) => [...prevOptions, newOption]);

    // create automatically selects new entry
    const newValue = [...value, newOption];
    onUpdateValue(newValue);

    setCreating(false);
  }, [value, designId, onUpdateValue]);

  useEffect(() => {
    fetchOptions();
  }, []);

  // Only tags can be created from infobox
  if (schemaItem?.interaction?.referenceType === TYPE_IDS.Tag) {
    return (
      <CreatableDropdown
        isLoading={isCreating}
        isDisabled={isCreating}
        isMulti
        options={options}
        value={value}
        onChange={onUpdateValue}
        onCreateOption={onCreateTag}
      />
    );
  }

  return (
    <SelectDropdown
      isMulti
      options={options}
      value={value}
      onChange={onUpdateValue}
    />
  );
};

export default MultiSelectEdit;
