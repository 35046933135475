import { mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../../../../../theme';

export const classNames = mergeStyleSets({
  header: {
    margin: '2px -8px',
    padding: '5px 8px',
    color: baseColors.offBlack,
    cursor: 'pointer',
    selectors: {
      '&>i': {
        fontSize: 16,
        marginRight: 8,
      },
      '&>span': {
        fontWeight: 600,
      },
      '&:hover': {
        backgroundColor: baseColors.hover,
      },
    },
  },
  columns: {
    paddingBottom: 8,
  },
  container: {
    overflow: 'auto',
  },
});
