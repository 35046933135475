import { TypeIds } from "."
import { HubType } from "./HubType"
import { FormatType } from "./FormatType"
import { DataType } from "./DataType"
import { HubBlankFilterBehaviourType } from "./HubBlankFilterBehaviourType"
import { Availability } from "./Availability"

/** A hub. */
export interface Hub {
  /** The type id. */
  readonly $typeId: TypeIds.Hub; // string($uuid)

  /** The entity id. */
  readonly id: string; // ($uuid)

  /** The hub name. */
  name: string | null;

  /** The hub description. */
  description: string | null;

  /** The hub types. */
  type: HubType;

  formatType: FormatType;

  dataType: DataType;

  /** The blank filter behaviour. */
  blankFilterBehaviour: HubBlankFilterBehaviourType;

  /** The records in the hub. */
  readonly records: number | null; // integer($int32)

  /** The element availability. */
  availability: Availability;

  /** The user type id. */
  readonly $lastChangedByTypeId: string | null; // ($uuid)

  /** The user id who made the last changes. */
  readonly lastChangedById: string | null; // ($uuid)

  /** The timestamp of the last changes. */
  readonly lastChangedWhen: string | null; // ($date-time)

  /** The parent type id. */
  readonly $parentTypeId: TypeIds.Design; // string($uuid)

  /** The parent id. */
  readonly parentId: string; // ($uuid)

  tags: string[] | null;
}

export const HubKeys: { [key in keyof Hub]: key } = {
  $lastChangedByTypeId: '$lastChangedByTypeId',
  $parentTypeId: '$parentTypeId',
  $typeId: '$typeId',
  availability: 'availability',
  blankFilterBehaviour: 'blankFilterBehaviour',
  dataType: 'dataType',
  description: 'description',
  formatType: 'formatType',
  id: 'id',
  lastChangedById: 'lastChangedById',
  lastChangedWhen: 'lastChangedWhen',
  name: 'name',
  parentId: 'parentId',
  records: 'records',
  tags: 'tags',
  type: 'type',
};