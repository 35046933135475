/* eslint-disable no-prototype-builtins */

import { StreamEntity } from '../../types';

export const isCalculation = (entity: StreamEntity) =>
  entity.hasOwnProperty('calculationId');
export const isStreamField = (entity: StreamEntity) =>
  entity.hasOwnProperty('sourceFieldId');
export const isGroup = (entity: StreamEntity) =>
  entity.hasOwnProperty('groupAssetId');
export const isHub = (entity: StreamEntity) => entity.hasOwnProperty('hubId');
export const isStreamMachineLearning = (entity: StreamEntity) =>
  entity.hasOwnProperty('machineLearningId');
