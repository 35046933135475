import React, { FunctionComponent } from 'react';
import { Stack } from '@fluentui/react';

import GroupCollapse from '../GroupCollapse';
import FoldableListItem from './FoldableListItem';

import { IFoldableListGroup, IFoldableListItem } from './types/IFoldableListGroup';

import { containerStyles } from './styles';

interface FoldableListProps {
  groups: IFoldableListGroup[];
  onClickItem?(key: string): void;
  onClickHeader(key: string): void;
  onRenderItem?(item: IFoldableListItem): React.ReactNode;
  expandAll?: boolean;
}

const FoldableList: FunctionComponent<FoldableListProps> = ({
  groups, onClickItem, onClickHeader, onRenderItem, expandAll,
}) => (
  <Stack styles={containerStyles}>
    { groups.map((group) => (
      <GroupCollapse
        groupId={group.key}
        groupName={group.name}
        onClick={() => onClickHeader(group.key)}
        isOpen={expandAll || group.isOpen}
        key={group.key}
      >
        { onRenderItem ? group.items.map(onRenderItem) : group.items.map((item) => (
          <FoldableListItem
            key={item.key}
            name={item.name}
            iconName={item.iconName}
            isActive={item.isActive}
            onClick={() => onClickItem(item.key)}
          />
        )) }
      </GroupCollapse>
    )) }
  </Stack>
);

export default FoldableList;
