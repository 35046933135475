/* eslint-disable react/prop-types */
import React from 'react';
import { IDropdownOption } from '@fluentui/react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { useDispatch, useSelector } from 'react-redux';
import { SectionType, SingleItem as ISingleItem } from '../../types';
import { IColumn } from '../../../../types/IColumn';

import { isColumnSelected } from '../../../ColumnsSequencing/selectors';
import streamSelectors from '../../../Streams/selectors';
import useIsColumnQueued from '../../../../hooks/useIsColumnQueued';

import SingleItemAggregationButton from './SingleItemAggregationButton/SingleItemAggregationButton';
import { setSelectedItemsIds } from '../../../ColumnsSequencing/actions';
import useIsColumnDisabled from '../../../../hooks/useIsColumnDisabled';
import EntityField from '../../../../components/EntityField';

export interface IDropdownColumn extends IDropdownOption {
  data: {
    column: IColumn;
  };
}

interface Props {
  sectionType: SectionType;
  dropdownOptions: IDropdownOption[];
  currentItem: ISingleItem;
  index: number;
  updateItem: (newItem: ISingleItem, isUpdatingColumn: boolean) => void;
  removeItem: (item: ISingleItem) => void;
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const SingleItem: React.FC<Props> = ({
  currentItem,
  index,
  sectionType,
}) => {
  const dispatch = useDispatch();

  const syncedColumnInDataset = useSelector(
    streamSelectors.getCurrentDatasetColumnByColumnUuid(currentItem.columnUuid),
  );

  const isSelectedColumn = useSelector(isColumnSelected(currentItem.columnUuid));

  const isColumnQueued = useIsColumnQueued({
    column: syncedColumnInDataset,
    shouldCheckBySpecificAggregation: true,
  });

  const isColumnDisabled = useIsColumnDisabled({ column: syncedColumnInDataset });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) return;

    // react only on left mouse button click
    if (event.button !== primaryButton) return;

    if (isColumnDisabled) return;

    // we are using the event for selection
    event.preventDefault();

    // only one column selected on group table
    dispatch(setSelectedItemsIds([currentItem.columnUuid]));
  };

  return (
    <Draggable draggableId={currentItem.columnUuid} index={index}>
      {(provided: DraggableProvided) => (
        <div
          // className={classNames.total}
          ref={provided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
        >
          <EntityField
            data-testid={`single-item-${sectionType}-${index}`}
            name={syncedColumnInDataset.name}
            isOrange={isColumnQueued}
            isSelected={isSelectedColumn}
            isSelectable
            onClick={handleClick}
            slot1={
              (
                <SingleItemAggregationButton
                  syncedItem={syncedColumnInDataset}
                  isItemQueued={isColumnQueued}
                  sectionType={sectionType}
                />
              )
            }
            // Sorting Disabled for Now
            //
            // slot2={(
            //   <ColumnSortSettings
            //     isItemSelected={false}
            //     column={{
            //       ...syncedColumnInDataset,
            //       uuid: currentItem.columnUuid,
            //     }}
            //   />
            // )}
          />
        </div>
      )}
    </Draggable>
  );
};

export default SingleItem;
