import React, { FunctionComponent } from 'react';
import {
  CommandBarButton,
  IContextualMenuItem,
  IContextualMenuItemProps,
  Icon,
  IIconProps,
  DirectionalHint,
} from '@fluentui/react';

import {
  buttonStyles,
  contextualMenuStyles,
  calloutStyles,
  itemIconStyles,
} from './styles';

interface ButtonMenuProps {
  onChangeItem(key: string): void;
  activeItemKey?: string | null;
  items: IContextualMenuItem[];
  shouldDismissOnChange?: boolean;
  isOrange?: boolean;
  defaultIconProps?: IIconProps;
  overrideIcon?: IIconProps;
  onMenuOpened?(): void;
  onDismiss?(): void;
  disabled?: boolean;
  'data-testid'?: string;
}

const onRenderIcon = ({ item }: IContextualMenuItemProps) => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    {item?.iconProps?.iconName && (
      <Icon {...item?.iconProps} styles={itemIconStyles} />
    )}

    {item.onRenderCustomIcon && item.onRenderCustomIcon(item)}
  </>
);

const ButtonMenu: FunctionComponent<ButtonMenuProps> = ({
  items,
  activeItemKey,
  onChangeItem,
  isOrange,
  shouldDismissOnChange,
  defaultIconProps,
  onMenuOpened,
  onDismiss,
  disabled,
  'data-testid': testId,
  overrideIcon,
}) => {
  const activeItem = items.find((f) => f.key === activeItemKey);

  const onClickHandler = (
    ev: React.MouseEvent<HTMLElement>,
    item: IContextualMenuItem
  ) => {
    onChangeItem(item.key);

    if (!shouldDismissOnChange) {
      ev.preventDefault();
    }
  };

  const menuItems: IContextualMenuItem[] = items.map((item) => ({
    checked: item.key === activeItemKey,
    onClick: onClickHandler,
    onRenderIcon,
    // required for custom icon
    iconProps: item.onRenderCustomIcon && {},
    canCheck: true,
    ...item,
  }));

  const onRenderButtonIcon = () => activeItem.onRenderCustomIcon(activeItem);

  const activeItemHasCustomIcon =
    activeItem?.onRenderCustomIcon && !activeItem?.iconProps?.iconName;

  return (
    <CommandBarButton
      onRenderIcon={activeItemHasCustomIcon && onRenderButtonIcon}
      iconProps={overrideIcon || activeItem?.iconProps || defaultIconProps}
      disabled={disabled}
      menuProps={{
        styles: contextualMenuStyles,
        items: menuItems,
        calloutProps: {
          styles: calloutStyles,
        },
        isBeakVisible: false,
        directionalHint: DirectionalHint.bottomLeftEdge,
        onMenuOpened,
        onDismiss,
      }}
      styles={buttonStyles(isOrange)}
      data-testid={testId}
    />
  );
};

export default ButtonMenu;
