import { IEntityFormSection, IGenericEntity, Json } from '../../types';

export const getSchemaPropertiesFromEntity = <T extends Json>(
  entity: IGenericEntity,
  { properties }: IEntityFormSection
): T =>
  properties.reduce(
    (acc, prop) =>
      prop.isReadOnly ? acc : { ...acc, [prop.name]: entity[prop.name] },
    {}
  ) as T;
