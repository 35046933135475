import {
  Configuration as MsalConfiguration,
  PublicClientApplication,
} from '@azure/msal-browser';
import defaultsDeep from 'lodash/defaultsDeep';

import { msalConfigBase } from './constants';
import { IAuthConfiguration } from '../../types/IAuthConfiguration';
import { enforceInterface } from '../../../shared/types/utils';
import i18n from '../../config/i18n';
import {
  AUTH_LOGIN_ACTION_TYPE,
  DIALOG_INITIALIZE_ACTION_TYPE,
  authInitializeTrigger,
  AUTH_INITIALIZE_ACTION_TYPE,
  authLoginTrigger,
  LoginMessageFromDialogAction,
  LogoutMessageFromDialogAction,
  DispatchToDialog,
  authLogoutTrigger,
  AUTH_LOGOUT_ACTION_TYPE,
} from '../../../auth/actions';
import { authConfigSerializer, authStateSerializer } from './authStorage';
import { cleanUserAuthState } from '../../api/interceptors/error';

const prepareMsalConfig = (
  authConfig: IAuthConfiguration,
  configOverrides?: Partial<MsalConfiguration>
): MsalConfiguration =>
  defaultsDeep(
    enforceInterface<MsalConfiguration>({
      auth: {
        authority: `${authConfig.instance}${authConfig.tenantId}`,
        clientId: authConfig.clientId,
      },
    }),
    configOverrides,
    msalConfigBase
  );

export const createMsalInstance = (
  authConfig: IAuthConfiguration,
  configOverrides?: Partial<MsalConfiguration>
) => {
  const msalConfig = prepareMsalConfig(authConfig, configOverrides);
  return new PublicClientApplication(msalConfig);
};

type DialogEventHandler = Parameters<Office.Dialog['addEventHandler']>[1];

type PromiseParams = Parameters<ConstructorParameters<PromiseConstructor>[0]>;
type Resolve = PromiseParams[0];
type Reject = PromiseParams[0];

interface DialogEventHandlerCreatorArgs {
  customLogoutCheck?: () => Promise<string | null>;
  dispatchToDialog: DispatchToDialog;
  resolve: Resolve;
  reject: Reject;
}

export const createAuthDialogEventHandler =
  ({
    customLogoutCheck,
    reject,
  }: DialogEventHandlerCreatorArgs): DialogEventHandler =>
  async (event) => {
    if ('error' in event) {
      switch (event.error) {
        case 12006:
          // NOTE: this is a (hopefully) temporary workaround for the MS logout flow not redirecting back to our page
          // when the user closes the dialog manually we check if we can still get valid credentials
          if (customLogoutCheck && (await customLogoutCheck()) === null)
            return cleanUserAuthState();
          reject(i18n.t('auth:error:dialogClosed'));
          break;
        case 12007:
          reject(i18n.t('auth:error:dialogAlreadyOpen'));
          break;
        default:
          reject(i18n.t('auth:error:unknown'));
          break;
      }
    }
  };

type HandleDialogInitializeArgs = Pick<
  DialogEventHandlerCreatorArgs,
  'dispatchToDialog' | 'reject'
>;
const handleDialogInitialize = ({
  dispatchToDialog,
  reject,
}: HandleDialogInitializeArgs) => {
  const config = authConfigSerializer.load();

  if (!config) {
    reject('Auth configuration missing.');
    return;
  }

  dispatchToDialog(authInitializeTrigger(config));
};

export const createSignInDialogMessageHandler =
  ({
    dispatchToDialog,
    reject,
    resolve,
  }: DialogEventHandlerCreatorArgs): DialogEventHandler =>
  (event) => {
    if ('message' in event && typeof event.message === 'string') {
      try {
        const action = JSON.parse(
          event.message
        ) as LoginMessageFromDialogAction;

        switch (action.type) {
          case DIALOG_INITIALIZE_ACTION_TYPE.SUCCESS:
            handleDialogInitialize({ dispatchToDialog, reject });
            break;

          case AUTH_INITIALIZE_ACTION_TYPE.SUCCESS:
            dispatchToDialog(authLoginTrigger());
            break;

          case AUTH_LOGIN_ACTION_TYPE.SUCCESS:
            resolve(action.payload);
            break;

          case DIALOG_INITIALIZE_ACTION_TYPE.FAILURE:
          case AUTH_INITIALIZE_ACTION_TYPE.FAILURE:
          case AUTH_LOGIN_ACTION_TYPE.FAILURE:
            reject(action.payload);
            break;
        }
      } catch (error) {
        reject(error);
      }
    } else {
      console.warn('[sign in]: unsupported message type');
    }
  };

export const createSignOutDialogMessageHandler =
  ({
    dispatchToDialog,
    reject,
    resolve,
  }: DialogEventHandlerCreatorArgs): DialogEventHandler =>
  (event) => {
    if ('message' in event && typeof event.message === 'string') {
      try {
        const action = JSON.parse(
          event.message
        ) as LogoutMessageFromDialogAction;

        switch (action.type) {
          case DIALOG_INITIALIZE_ACTION_TYPE.SUCCESS:
            handleDialogInitialize({ dispatchToDialog, reject });
            break;

          case AUTH_INITIALIZE_ACTION_TYPE.SUCCESS:
            const authState = authStateSerializer.load();
            if (!authState)
              throw new Error('[auth logout] auth state missing!');
            dispatchToDialog(authLogoutTrigger(authState.account));
            break;

          case AUTH_LOGOUT_ACTION_TYPE.SUCCESS:
            // TODO: this should probably happen next to where we unset the `authState` constant
            authStateSerializer.remove();
            resolve(true);
            break;

          case DIALOG_INITIALIZE_ACTION_TYPE.FAILURE:
          case AUTH_INITIALIZE_ACTION_TYPE.FAILURE:
          case AUTH_LOGOUT_ACTION_TYPE.FAILURE:
            reject(action.payload);
            break;
        }
      } catch (error) {
        reject(error);
      }
    } else {
      console.warn('[sign out]: unsupported message type');
    }
  };
