import moment from 'moment';
import i18n from '../../../../config/i18n';
import { ComparisonTypes } from '../../../../types/IStreamFilter';
import { IColumn, IColumnDataType } from '../../../../types/IColumn';

export const renderFilterValue = (value, comparison, column: IColumn) => {
  if ([
    ComparisonTypes.IsNotNull,
    ComparisonTypes.IsNull,
    ComparisonTypes.IsBlank,
    ComparisonTypes.IsNotBlank,
  ].includes(comparison)) {
    return null;
  }

  if (value === undefined) {
    return i18n.t('filters:emptyValues');
  }

  if ([IColumnDataType.DateTime].includes(column.dataType)) {
    return (moment(value).format('LL'));
  }

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(parseFloat(value))) {
    return value;
  }

  if (value === null) {
    return 'null';
  }

  return value;
};
