import {
  IButtonStyles, ICalloutContentStyles, IContextualMenuStyles, IIconStyles,
} from '@fluentui/react';
import { merge } from 'lodash';
import React from 'react';
import { baseColors } from '../../../theme';

export const btnWithCaret: IButtonStyles = {
  root: {
    position: 'relative',
    selectors: {
      '.ms-Button-menuIcon': {
        display: 'none',
      },
    },
  },
};

export const buttonStyles: IButtonStyles = merge({
  root: {
    padding: 0,
    width: 32,
    minWidth: 32,
    height: 32,
    borderRadius: '3px',
    color: baseColors.iconColor,
    selectors: {

      '.ms-Button-icon': {
        zIndex: 10,
        position: 'relative',
        fontSize: 18,
        color: baseColors.iconColor,
      },
      '&:active .ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
  rootFocused: {
    selectors: {
      '.ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
  rootHovered: {
    backgroundColor: baseColors.hover,
    selectors: {
      '.ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
  rootExpanded: {
    selectors: {
      '.ms-Button-icon': {
        color: baseColors.iconColor,
      },
    },
  },
}, btnWithCaret);

export const calloutStyles: Partial<ICalloutContentStyles> = {
  root: {
    borderRadius: '4px',
    backgroundColor: baseColors.white,
    boxShadow: '0px 3.2px 7.2px rgba(50, 49, 48, 0.132), 0px 0.6px 1.8px rgba(50, 49, 48, 0.108)',
  },
  calloutMain: {
    backgroundColor: 'transparent',
  },
};

export const contextualMenuStyles: Partial<IContextualMenuStyles> = {
  root: {
    backgroundColor: 'transparent',
    fontSize: 12,
    minWidth: 116,
    selectors: {
      '.ms-ContextualMenu-divider': {
        backgroundColor: baseColors.inputBorder,
      },
      '.ms-ContextualMenu-link': {
        lineHeight: 28,
      },
    },
  },
  subComponentStyles: {
    menuItem: {
      root: {
        fontSize: '12px !important',
        height: 28,
      },
    },
    callout: {
    },
  },
};

export const checkIconStyles: IIconStyles = {
  root: {
    marginLeft: 6,
    marginRight: 6,
    display: 'block',
    width: 14,
  },
};

export const statusIconStyles: IIconStyles = {
  root: {
    position: 'absolute',
    fontSize: 8,
    top: 4,
    right: 4,
    zIndex: 12,
  },
};

export const itemIconStyles: IIconStyles = {
  root: {
    fontSize: 16,
    width: 16,
  },
};

export const activeSvgIcon: React.CSSProperties = {
  position: 'absolute', zIndex: 3, top: 0, right: 0, left: 0, bottom: 0, pointerEvents: 'none', margin: 'auto', width: '16px', height: '16px',
};
export const commandBarButtonIconStyles: React.CSSProperties = {
  height: 16, width: 16, fontSize: 16, color: baseColors.iconColor,
};
