import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderBarButton } from '../../../../components/ui';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { routes } from '../../../../constants/routes';
import { editEntityRoute } from '../../../../utils/routes';
import { getCurrentUserData } from '../../selectors';

export const UserHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const userData = useSelector(getCurrentUserData);

  const handleEdit = () => {
    const path = editEntityRoute(TYPE_IDS.User, userData?.id);

    const goBackPage = routes.settings.profile.index;
    history.push(path, {
      goBackPage,
    });
  };

  return (
    <HeaderBarButton
      onClick={handleEdit}
      tooltip={t('profile:editUser')}
      iconProps={{ iconName: 'Edit' }}
    />
  );
};
