import { mergeStyleSets } from 'office-ui-fabric-react';
import { baseColors } from '../../../../theme';

export const classNames = mergeStyleSets({
  wrapper: {
    borderRadius: '4px',
    border: `1px solid ${baseColors.inputBorder}`,
    padding: '8px',
    marginBottom: '8px',
  },
  linkWrapper: {
    padding: '2px',
  },
  linkEntities: {
    fontWeight: 600,
    fontSize: '12px',
    marginBottom: '2px',
  },
  linkFields: {
    fontWeight: 400,
    fontSize: '12px',
  },
  boxHeader: {
    margin: '-8px -8px 4px',
    padding: '4px',
    borderBottom: `1px solid ${baseColors.inputBorder}`,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: baseColors.hover,
    justifyContent: 'space-between',
  },
  sourceName: {
    marginLeft: '4px',
    fontSize: '12px',
    fontWeight: 600,
  },
  sourceWrapper: {
    display: 'flex',
  },
  controlButton: {
    height: '17px',
  },
  noSuggestionsWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  explanationWrapper: {
    padding: '8px 8px 0',
  },
  explanationText: {
    fontSize: '12px',
    marginBottom: '8px',
  },
});
