import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import { editEntityRoute } from '../../../../../utils/routes';
import { getSelectedEntityId } from '../../selectors';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedItemsIds } from '../../../../App/selectors';
import { SmartCatalogButton } from './SmartCatalogButton';
import { SmartFlowButton } from './SmartFlowButton';

const DataTopHeader = () => {
  const history = useHistory();
  const selectedFieldId = useSelector(getSelectedItemsIds)?.[0];
  const selectedEntityId = useSelector(getSelectedEntityId);

  const handleEdit = () => {
    const path = editEntityRoute(
      selectedFieldId ? TYPE_IDS.SourceEntityField : TYPE_IDS.SourceEntity,
      selectedFieldId || selectedEntityId
    );
    history.push(path, {
      typeName: selectedFieldId ? 'Field' : 'Entity',
      initialValues: {},
    });
  };

  return (
    <>
      <ItemActionsHeader
        isItemSelected={!!selectedFieldId || !!selectedEntityId}
        handleEdit={handleEdit}
      />
      <SmartCatalogButton />
      <SmartFlowButton />
    </>
  );
};

export default DataTopHeader;
