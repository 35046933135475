import React from 'react';
import merge from 'lodash/merge';
import {
  TooltipHost,
  ITooltipHostProps,
  getTheme,
  TooltipDelay,
} from '@fluentui/react';

import { useSelector } from 'react-redux';
import { tooltipStyles } from './styles';
import { getClientPreferencesListAsObject } from '../../../modules/ClientPreferences/selectors';
import { ToggleValues, UserPreferenceIds } from '../../../types/IClientPreferences';

const SynergiesTooltipHost: React.FC<ITooltipHostProps> = ({
  children,
  tooltipProps,
  ...rest
}) => {
  const theme = getTheme();

  const mergedTooltipProps = merge(
    {},
    {
      styles: tooltipStyles(theme),
    },
    tooltipProps,
  );

  const clientPreferences = useSelector(getClientPreferencesListAsObject);

  const tooltipValue = clientPreferences[UserPreferenceIds.TOOLTIPS];

  if (tooltipValue === ToggleValues.Off) {
    return <>{children}</>;
  }

  return (
    <TooltipHost
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      delay={TooltipDelay.long}
      tooltipProps={mergedTooltipProps}
    >
      {children}
    </TooltipHost>
  );
};

export default SynergiesTooltipHost;
