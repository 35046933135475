import { createReducer } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import { StreamCalculation } from '../../api/model/schemas/StreamCalculation';
import { StreamGroupAsset } from '../../api/model/schemas/StreamGroupAsset';
import { StreamLink } from '../../api/model/schemas/StreamLink';
import { StreamHub } from '../../api/model/schemas/StreamHub';
import { IDesignerFilter } from '../../types/IDesignerFilter';

import {
  ICalculation,
  IDesign,
  IDesignSource,
  IDesignSourceEntity,
  IDesignSourceEntityField,
  IHub,
  IStreamField,
  UIEnhancedDesignSourceEntityField,
} from '../Designer/types';

import * as actions from './actions';
import {
  StreamAssetType,
  UIEnhancedGroupAsset,
  UIEnhancedStream,
} from './types';
import { LinkCoverageResult } from '../../api/model/schemas/LinkCoverageResult';
import { toggleValue } from '../../utils/toggleValue';
import { MachineLearning } from '../../api/model/schemas/MachineLearning';
import { StreamMachineLearning } from '../../api/model/schemas/StreamMachineLearning';

export interface BuilderState {
  streams: UIEnhancedStream[];
  designs: IDesign[];
  selectedStreamId: string | null;
  isLoading: boolean;
  isProcessingStream: boolean;
  isUpdating: boolean;

  streamFields: {
    records: IStreamField[];
    entityFields: IDesignSourceEntityField[];
    entities: IDesignSourceEntity[];
    isLoading: boolean;
    recordIdsBeingUpdated: string[];
  };

  dataSources: {
    records: IDesignSource[];
    isLoading: boolean;
  };

  calculations: {
    records: ICalculation[];
    streamCalculations: StreamCalculation[];
    isLoading: boolean;
    recordIdsBeingUpdated: string[];
  };

  hubs: {
    records: IHub[];
    streamHubs: StreamHub[];
    recordIdsBeingUpdated: string[];
    isLoading: boolean;
  };

  groups: {
    records: UIEnhancedGroupAsset[];
    streamGroupAssets: StreamGroupAsset[];
    recordIdsBeingUpdated: string[];
    isLoading: boolean;
  };

  filters: {
    records: IDesignerFilter[];
    isLoading: boolean;
    valuesBeingUpdated: string[];
  };

  entityFields: {
    isLoading: boolean;
    fields: UIEnhancedDesignSourceEntityField[];
    entities: IDesignSourceEntity[];
    error?: boolean;
  };

  links: {
    streamLinks: StreamLink[];
    isLoading: boolean;
    recordIdsBeingUpdated: string[];
  };

  machineLearning: {
    records: MachineLearning[];
    streamMachineLearning: StreamMachineLearning[];
    isLoadingML: boolean;
    isLoadingStreamML: boolean;
    recordIdsBeingUpdated: string[];
  };

  linksAnalysis: {
    results: LinkCoverageResult['dataSourceLinks'] | null;
    isLoading: boolean;
  };

  entities: {
    records: IDesignSourceEntity[];
  };

  selectedDataSourceIds?: string[];
  activeDataSourceId?: string;
}

export const initialState: BuilderState = {
  streams: [],
  designs: [],
  streamFields: {
    records: [],
    entityFields: [],
    entities: [],
    isLoading: false,
    recordIdsBeingUpdated: [],
  },
  dataSources: {
    records: [],
    isLoading: false,
  },
  entityFields: {
    isLoading: false,
    fields: [],
    entities: [],
  },
  entities: {
    records: [],
  },
  calculations: {
    records: [],
    recordIdsBeingUpdated: [],
    streamCalculations: [],
    isLoading: false,
  },
  hubs: {
    records: [],
    isLoading: false,
    recordIdsBeingUpdated: [],
    streamHubs: [],
  },
  groups: {
    records: [],
    streamGroupAssets: [],
    recordIdsBeingUpdated: [],
    isLoading: false,
  },
  filters: {
    isLoading: false,
    records: [],
    valuesBeingUpdated: [],
  },
  links: {
    isLoading: false,
    streamLinks: [],
    recordIdsBeingUpdated: [],
  },
  linksAnalysis: {
    isLoading: false,
    results: [],
  },
  machineLearning: {
    isLoadingML: false,
    isLoadingStreamML: false,
    recordIdsBeingUpdated: [],
    records: [],
    streamMachineLearning: [],
  },
  selectedStreamId: null,
  isLoading: false,
  isUpdating: false,
  isProcessingStream: false,
  selectedDataSourceIds: [],
};

const reducer = createReducer(initialState, (builder) =>
  builder

    .addCase(actions.setSelectedStreamId, (state, { payload }) => ({
      ...state,
      selectedStreamId: payload,
    }))
    .addCase(actions.setSelectedFieldId, (state, { payload }) => ({
      ...state,
      selectedFieldId: payload,
    }))
    .addCase(actions.setProcessingStream, (state, { payload }) => ({
      ...state,
      isProcessingStream: payload,
    }))
    .addCase(actions.fetchStreams.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(actions.fetchStreams.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(actions.fetchStreams.fulfilled, (state, action) => ({
      ...state,
      isLoading: false,
      streams: action.payload.streams || [],
      designs: action.payload.designs || [],
    }))
    .addCase(actions.createStreamFieldFilterIntent, (state) => ({
      ...state,
      filters: {
        ...state.filters,
        isLoading: true,
      },
    }))
    .addCase(actions.editStreamFieldFilterIntent, (state) => ({
      ...state,
      filters: {
        ...state.filters,
        isLoading: true,
      },
    }))
    .addCase(actions.setIsLoadingFilters, (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        isLoading: payload,
      },
    }))
    .addCase(actions.fetchAllStreamFieldsFilters.pending, (state) => ({
      ...state,
      filters: {
        ...state.filters,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchAllStreamFieldsFilters.rejected, (state) => ({
      ...state,
      filters: {
        ...state.filters,
        isLoading: false,
      },
    }))
    .addCase(
      actions.fetchAllStreamFieldsFilters.fulfilled,
      (state, { payload }) => ({
        ...state,
        filters: {
          ...state.filters,
          isLoading: false,
          records: payload,
          valuesBeingUpdated: [],
        },
      })
    )
    .addCase(actions.fetchStreamFields.pending, (state) => ({
      ...state,
      streamFields: {
        ...state.streamFields,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamFields.rejected, (state) => ({
      ...state,
      streamFields: {
        ...state.streamFields,
        isLoading: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamFields.fulfilled, (state, action) => ({
      ...state,
      streamFields: {
        ...state.streamFields,
        isLoading: false,
        records: action.payload,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(
      actions.valueFilterUpdatedSuccessfully,
      (state, { payload: { updatedFilter } }) => ({
        ...state,
        filters: {
          ...state.filters,
          valuesBeingUpdated: [],
          records: state.filters.records.map((filter) => {
            if (filter.id === updatedFilter.id) {
              return {
                ...filter,
                values: updatedFilter.values,
              };
            }
            return filter;
          }),
        },
      })
    )
    .addCase(
      actions.filterByValueItemClicked,
      (state, { payload: { value } }) => ({
        ...state,
        filters: {
          ...state.filters,
          valuesBeingUpdated: [
            ...toggleValue(state.filters.valuesBeingUpdated, value),
          ],
        },
      })
    )
    .addCase(
      actions.assetClicked,
      (state, { payload: { entity, assetType } }) => ({
        ...state,
        streamFields: {
          ...state.streamFields,
          recordIdsBeingUpdated:
            assetType === StreamAssetType.Field
              ? uniq([...state.streamFields.recordIdsBeingUpdated, entity.id])
              : state.streamFields.recordIdsBeingUpdated,
        },
        calculations: {
          ...state.calculations,
          recordIdsBeingUpdated:
            assetType === StreamAssetType.Calculation
              ? uniq([...state.calculations.recordIdsBeingUpdated, entity.id])
              : state.calculations.recordIdsBeingUpdated,
        },
        groups: {
          ...state.groups,
          recordIdsBeingUpdated:
            assetType === StreamAssetType.Group
              ? uniq([...state.groups.recordIdsBeingUpdated, entity.id])
              : state.calculations.recordIdsBeingUpdated,
        },
        links: {
          ...state.links,
          recordIdsBeingUpdated:
            assetType === StreamAssetType.Link
              ? uniq([...state.links.recordIdsBeingUpdated, entity.id])
              : state.links.recordIdsBeingUpdated,
        },
        hubs: {
          ...state.hubs,
          recordIdsBeingUpdated:
            assetType === StreamAssetType.Hub
              ? uniq([...state.hubs.recordIdsBeingUpdated, entity.id])
              : state.hubs.recordIdsBeingUpdated,
        },
        machineLearning: {
          ...state.machineLearning,
          recordIdsBeingUpdated:
            assetType === StreamAssetType.MachineLearning
              ? uniq([
                  ...state.machineLearning.recordIdsBeingUpdated,
                  entity.id,
                ])
              : state.machineLearning.recordIdsBeingUpdated,
        },
      })
    )
    .addCase(
      actions.setAssetIdsBeingUpdated,
      (state, { payload: { assetIds, assetType } }) => {
        // eslint-disable-next-line default-case
        switch (assetType) {
          case StreamAssetType.Field:
            return {
              ...state,
              streamFields: {
                ...state.streamFields,
                recordIdsBeingUpdated: assetIds,
              },
            };
          case StreamAssetType.Group:
            return {
              ...state,
              groups: {
                ...state.groups,
                recordIdsBeingUpdated: assetIds,
              },
            };
          case StreamAssetType.Calculation:
            return {
              ...state,
              calculations: {
                ...state.calculations,
                recordIdsBeingUpdated: assetIds,
              },
            };
          case StreamAssetType.Link:
            return {
              ...state,
              links: {
                ...state.links,
                recordIdsBeingUpdated: assetIds,
              },
            };
          case StreamAssetType.Hub:
            return {
              ...state,
              hubs: {
                ...state.hubs,
                recordIdsBeingUpdated: assetIds,
              },
            };
          case StreamAssetType.MachineLearning:
            return {
              ...state,
              machineLearning: {
                ...state.machineLearning,
                recordIdsBeingUpdated: assetIds,
              },
            };
        }
      }
    )
    .addCase(actions.setSelectedDataSourceIds, (state, action) => ({
      ...state,
      selectedDataSourceIds: action.payload,
    }))
    .addCase(actions.setStreamFieldEntities, (state, action) => ({
      ...state,
      streamFields: {
        ...state.streamFields,
        entities: action.payload,
      },
    }))
    .addCase(actions.setStreamFieldEntityFields, (state, action) => ({
      ...state,
      streamFields: {
        ...state.streamFields,
        entityFields: action.payload,
      },
    }))
    .addCase(actions.fetchProcessedDataSources.pending, (state) => ({
      ...state,
      dataSources: {
        ...state.dataSources,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchProcessedDataSources.rejected, (state) => ({
      ...state,
      dataSources: {
        ...state.dataSources,
        isLoading: false,
      },
    }))
    .addCase(actions.fetchProcessedDataSources.fulfilled, (state, action) => ({
      ...state,
      dataSources: {
        isLoading: false,
        records: action.payload,
      },
    }))
    .addCase(actions.fetchSourceEntity.fulfilled, (state, action) => ({
      ...state,
      entities: {
        records: [...state.entities.records, action.payload],
      },
    }))
    .addCase(actions.fetchDataSourceFields.pending, (state) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        fields: [],
        entities: [],
        isLoading: true,
      },
    }))
    .addCase(actions.fetchDataSourceFields.rejected, (state) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        isLoading: false,
        error: true,
      },
    }))
    .addCase(actions.fetchDataSourceFields.fulfilled, (state, action) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        isLoading: false,
        fields: action.payload,
        error: false,
      },
    }))
    .addCase(actions.fetchMachineLearning.pending, (state) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoadingML: true,
      },
    }))
    .addCase(actions.fetchMachineLearning.rejected, (state) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoadingML: false,
      },
    }))
    .addCase(actions.fetchMachineLearning.fulfilled, (state, action) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoadingML: false,
        records: action.payload,
      },
    }))
    .addCase(actions.fetchStreamMachineLearning.pending, (state) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoadingStreamML: true,
      },
    }))
    .addCase(actions.fetchStreamMachineLearning.rejected, (state) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoadingStreamML: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamMachineLearning.fulfilled, (state, action) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoadingStreamML: false,
        streamMachineLearning: action.payload,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchCalculations.pending, (state) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchCalculations.rejected, (state) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: false,
      },
    }))
    .addCase(actions.fetchCalculations.fulfilled, (state, action) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: false,
        records: action.payload,
      },
    }))
    .addCase(actions.fetchStreamCalculations.pending, (state) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamCalculations.rejected, (state) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamCalculations.fulfilled, (state, action) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: false,
        streamCalculations: action.payload,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchGroupsAssets.pending, (state) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchGroupsAssets.rejected, (state) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: false,
      },
    }))
    .addCase(actions.fetchGroupsAssets.fulfilled, (state, action) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: false,
        records: action.payload,
      },
    }))
    .addCase(actions.fetchStreamGroupsAssets.pending, (state) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: true,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamGroupsAssets.rejected, (state) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamGroupsAssets.fulfilled, (state, action) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: false,
        streamGroupAssets: action.payload,
      },
    }))
    .addCase(actions.createStreamField.pending, (state) => ({
      ...state,
      isUpdating: true,
    }))
    .addCase(actions.createStreamField.fulfilled, (state) => ({
      ...state,
      isUpdating: false,
    }))
    .addCase(actions.removeStreamField.pending, (state) => ({
      ...state,
      isUpdating: true,
    }))
    .addCase(actions.removeStreamField.fulfilled, (state) => ({
      ...state,
      isUpdating: false,
    }))
    .addCase(actions.setFieldEntities, (state, action) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        entities: action.payload,
      },
    }))
    .addCase(actions.setActiveDataSourceId, (state, action) => ({
      ...state,
      activeDataSourceId: action.payload,
    }))
    .addCase(actions.fetchStreamLinks.pending, (state) => ({
      ...state,
      links: {
        ...state.links,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamLinks.rejected, (state) => ({
      ...state,
      links: {
        ...state.links,
        isLoading: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamLinks.fulfilled, (state, { payload }) => ({
      ...state,
      links: {
        ...state.links,
        streamLinks: payload,
        isLoading: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamHubs.pending, (state) => ({
      ...state,
      hubs: {
        ...state.hubs,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamHubs.rejected, (state) => ({
      ...state,
      hubs: {
        ...state.hubs,
        isLoading: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchStreamHubs.fulfilled, (state, { payload }) => ({
      ...state,
      hubs: {
        ...state.hubs,
        streamHubs: payload,
        isLoading: false,
        recordIdsBeingUpdated: [],
      },
    }))
    .addCase(actions.fetchHubs.pending, (state) => ({
      ...state,
      hubs: {
        ...state.hubs,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchHubs.rejected, (state) => ({
      ...state,
      hubs: {
        ...state.hubs,
        isLoading: false,
      },
    }))
    .addCase(actions.fetchHubs.fulfilled, (state, action) => ({
      ...state,
      hubs: {
        ...state.hubs,
        isLoading: false,
        records: action.payload,
      },
    }))
    .addCase(actions.performLinksAnalysis.pending, (state) => ({
      ...state,
      linksAnalysis: {
        ...state.linksAnalysis,
        isLoading: true,
        results: [],
      },
    }))
    .addCase(actions.performLinksAnalysis.rejected, (state) => ({
      ...state,
      linksAnalysis: {
        ...state.linksAnalysis,
        isLoading: false,
      },
    }))
    .addCase(actions.performLinksAnalysis.fulfilled, (state, { payload }) => ({
      ...state,
      linksAnalysis: {
        ...state.linksAnalysis,
        isLoading: false,
        results: payload,
      },
    }))
);

export default reducer;
