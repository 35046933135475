import { UserRoles } from '../types/IUser';

export const isUserPermitted = (
  userRole?: UserRoles, visibilityRoleSet: UserRoles[] = [],
): boolean => {
  if (!visibilityRoleSet || !visibilityRoleSet.length) {
    return true;
  }

  if (!userRole) return false;

  return visibilityRoleSet.includes(userRole);
};
