import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { merge } from 'lodash';
import { IconButtonProps } from '../../../../components/IconButton';
import WizardPage, { WizardType } from '../../../../pageTemplates/WizardPage';
import {
  addEntities,
  addEntityForm,
  entityFormSubmission,
  fetchEntities,
  fetchEntityFormSections,
  restartForm,
  setCurrentPageIndex,
  setEntityFormData,
  setEntityFormSyncing,
} from '../actions';
import FormPage from '../components/FormPage';
import {
  getCurrentFormSection,
  getCurrentSectionIndex,
  getAllFormsSectionsSorted,
  getEntityFormByTypeId,
  getLastEditorEntity,
  getIsFetchingEntities,
  getEntityName,
  getEntityById,
  getIsSyncingEntityForm,
} from '../selectors';
import * as styles from '../styles';
import { getSelectedDesignId } from '../../ContentLibrary/selectors';
import { translateApiName } from '../../../../config/i18n/utils';
import { EntityFormShimmer } from './EntityFormShimmer';
import {
  TYPE_IDS,
  TYPE_IDS_TO_NAMES,
} from '../../../../constants/apiV4TypeIds';
import { FormSubmissionProvider } from '../hooks/useFormSubmission';
import { getIsConfiguringDataSource } from '../../Ingestion/selectors';
import { fetchEntity } from '../../api';
import { IGenericEntity } from '../types';

export interface PageParams {
  schemaId: string;
  entityId?: string;
  parentSchemaId?: string;
  parentEntityId?: string;
}

export const ENTITY_FORM_ID = 'entity_form';

const EntityForm = () => {
  // HOOKS
  const dispatch = useDispatch();
  const { schemaId, entityId, parentSchemaId, parentEntityId } =
    useParams<PageParams>();
  const history = useHistory();
  const location = useLocation();
  const { i18n, t } = useTranslation('designer');
  const formMethods = useForm({
    mode: 'onChange',
  });

  // STATE
  const designId = useSelector(getSelectedDesignId);
  const pages = useSelector(getAllFormsSectionsSorted);
  const currentSection = useSelector(getCurrentFormSection);
  const sectionIndex = useSelector(getCurrentSectionIndex);
  const currentEntityForm = useSelector(
    getEntityFormByTypeId(currentSection?.parentId)
  );
  const mainEntity = useSelector(getEntityById(entityId));
  const lastEditor = useSelector(getLastEditorEntity(mainEntity?.$typeId));
  const isFetchingEntities = useSelector(getIsFetchingEntities);
  const formWizardEntityName = useSelector(getEntityName);
  const isSyncingForm = useSelector(getIsSyncingEntityForm);
  const isConfiguringDataSource = useSelector(getIsConfiguringDataSource);
  const [preventFormSubmission, setPreventSubmission] = useState(false);
  const [shouldSkipFollowingSections, setShouldSkipFollowingSections] =
    useState(false);

  const isSyncing = isSyncingForm || isConfiguringDataSource;

  // DERIVED STTE
  const isLastPage =
    sectionIndex + 1 === pages?.length || shouldSkipFollowingSections;
  const isFirstPage = sectionIndex === 0;
  const isTitleHidden = !currentSection?.properties || isFetchingEntities;

  const {
    goBackPage = null,
    initialValues = {},
    typeName = '',
    successCallback = null,
    assetsTypeId,
  } = location?.state || {};
  const goBack = () => (goBackPage ? history.push(goBackPage) : history.go(-1));

  const pageTitle = translateApiName(
    currentEntityForm?.typeId,
    currentSection?.name
  );
  const { handleSubmit, formState } = formMethods;
  const { isValid } = formState;

  // CALLBACKS

  const handleBack = () => {
    if (sectionIndex > 0) {
      dispatch(setCurrentPageIndex(sectionIndex - 1));
    } else {
      goBack();
    }
  };

  const handleFormSubmission = () =>
    dispatch(
      entityFormSubmission({
        callback: successCallback || goBack,
      })
    );

  const onSubmit = (data) => {
    dispatch(
      setEntityFormData({
        typeId: currentSection?.parentId,
        data: merge({}, currentEntityForm?.data, data),
      })
    );
    if (isLastPage) {
      handleFormSubmission();
    } else {
      dispatch(setCurrentPageIndex(sectionIndex + 1));
    }
  };

  // TO allow some potential debugging
  const onError = (errors, e) =>
    console.error('entity form validation error', { errors, e });
  // EFFECTS

  // Load parent entity

  useEffect(() => {
    // This is currently required only  when dealing with Datasource entities of type Stream Output
    if (schemaId !== TYPE_IDS.SourceEntity) return;
    (async () => {
      try {
        const parentTypeId = parentSchemaId || mainEntity?.$parentTypeId;
        const parentId = parentEntityId || mainEntity?.parentId;
        if (parentTypeId && parentId) {
          dispatch(setEntityFormSyncing(true));
          const { data } = await fetchEntity<IGenericEntity>(
            parentTypeId,
            parentId
          )();

          dispatch(addEntities([data]));
        }
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setEntityFormSyncing(false));
      }
    })();
  }, [mainEntity]);

  // Load Form Sections

  useEffect(() => {
    if (entityId) {
      dispatch(fetchEntities({ typeId: schemaId, entityId, assetsTypeId }));
    }
    dispatch(
      addEntityForm({
        typeId: schemaId,
        parentTypeId: parentSchemaId,
        entityId,
        parentEntityId,
        data: initialValues,
        sections: [],
      })
    );
    dispatch(fetchEntityFormSections({ typeId: schemaId, designId }));
    return () => {
      dispatch(restartForm());
    };
  }, [designId]);

  // PARTS
  const buttonsConfig: IconButtonProps[] = [
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.back" />,
      onClick: handleBack,
      iconProps: { iconName: 'Back' },
      'data-testid': 'form-back-button',
      type: 'button',
      shouldHideOnDisabled: true,
      disabled: isFirstPage,
    },
    {
      tooltip: (
        <Trans
          t={t}
          i18nKey={
            isLastPage ? 'actionBar:general.accept' : 'actionBar:general.next'
          }
        />
      ),
      iconProps: { iconName: isLastPage ? 'Accept' : 'Forward' },
      type: 'submit',
      form: ENTITY_FORM_ID,
      shouldHideOnDisabled: true,
      'data-testid': 'entity-form-submit-page-btn',
      disabled: !isValid || preventFormSubmission || isSyncing,
    },
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.cancelAction" />,
      onClick: goBack,
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'form-cancel-button',
      type: 'button',
    },
  ];

  const i18nEntityName = mainEntity?.i18nName ?? mainEntity?.name;
  const fallbackEntityTypeNameTranslationKey = `entityTypes:${
    TYPE_IDS_TO_NAMES[currentEntityForm?.typeId]
  }`;
  const fallbackEntityTypeName = i18n.exists(
    fallbackEntityTypeNameTranslationKey
  )
    ? t(fallbackEntityTypeNameTranslationKey)
    : typeName;
  const entityName =
    (formWizardEntityName as string) ||
    (entityId
      ? i18nEntityName
      : `${t('entityForm:add')} ${fallbackEntityTypeName}`);

  if (isFetchingEntities && isFirstPage)
    return <EntityFormShimmer goBack={goBack} />;

  // RENDER
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...formMethods}>
      <FormSubmissionProvider
        {...{
          isValid,
          preventFormSubmission,
          setPreventSubmission,
          shouldSkipFollowingSections,
          setShouldSkipFollowingSections,
        }}
      >
        <form
          id={ENTITY_FORM_ID}
          style={styles.form}
          onSubmit={handleSubmit(onSubmit, onError)}
        />
        <WizardPage
          {...{
            entityName,
            pageTitle,
          }}
          isSyncing={isSyncing}
          pageTitle={isTitleHidden ? '' : pageTitle}
          wizardType={entityId ? WizardType.Edit : WizardType.Add}
          headerBarButtonsConfig={buttonsConfig}
          lastEditedWhen={
            mainEntity &&
            mainEntity?.lastChangedWhen &&
            new Date(mainEntity?.lastChangedWhen)
          }
          lastEditedBy={lastEditor?.name}
        >
          <FormPage
            {...{ currentSection, handleFormSubmission }}
            isDisabled={mainEntity?.isLocked}
            isEditing={!!mainEntity}
          />
        </WizardPage>
      </FormSubmissionProvider>
    </FormProvider>
  );
};

export default EntityForm;
