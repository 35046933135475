import { AuthenticationResult, AccountInfo } from "@azure/msal-common";
import { IAuthConfiguration } from "../taskpane/types/IAuthConfiguration";

// # SHARED
export type Action<Type extends string, Payload = undefined> = Payload extends undefined
  ? {
    type: Type;
  } : {
    type: Type;
    payload: Payload;
  };

const createAction = <Type extends string, Payload = undefined>(action: Action<Type, Payload>) => action;

export const dispatchToHost = <Type extends string, Payload = undefined>(action: Action<Type, Payload>): void =>
  Office.context.ui.messageParent(JSON.stringify(action));

export const dispatchToDialogFactory = (dialog: Office.Dialog) =>
  <Type extends string, Payload = undefined>(action: Action<Type, Payload>): void =>
    dialog.messageChild(JSON.stringify(action));

export type DispatchToDialog = ReturnType<typeof dispatchToDialogFactory>;

// # ACTIONS

// DIALOG INITIALIZE
export enum DIALOG_INITIALIZE_ACTION_TYPE {
  SUCCESS = 'DIALOG/INITIALIZE-SUCCESS',
  FAILURE = 'DIALOG/INITIALIZE-FAILURE',
}

export const dialogInitializeSuccess = () => createAction({
  type: DIALOG_INITIALIZE_ACTION_TYPE.SUCCESS,
});
export type DialogInitializeSuccessAction = ReturnType<typeof dialogInitializeSuccess>;

export const dialogInitializeFailure = (errorMessage: string) => createAction({
  type: DIALOG_INITIALIZE_ACTION_TYPE.FAILURE,
  payload: errorMessage,
});
export type DialogInitializeFailureAction = ReturnType<typeof dialogInitializeFailure>;

export type DialogInitializeResultAction =
  | DialogInitializeSuccessAction
  | DialogInitializeFailureAction
  ;

// AUTH INITIALIZE
export enum AUTH_INITIALIZE_ACTION_TYPE {
  TRIGGER = 'AUTH/INITIALIZE-TRIGGER',
  SUCCESS = 'AUTH/INITIALIZE-SUCCESS',
  FAILURE = 'AUTH/INITIALIZE-FAILURE',
}

export const authInitializeTrigger = (config: IAuthConfiguration) => createAction({
  type: AUTH_INITIALIZE_ACTION_TYPE.TRIGGER,
  payload: config,
});
export type AuthInitializeTriggerAction = ReturnType<typeof authInitializeTrigger>;

export const authInitialzeSuccess = () => createAction({
  type: AUTH_INITIALIZE_ACTION_TYPE.SUCCESS,
});
export type AuthInitializeSuccessAction = ReturnType<typeof authInitialzeSuccess>;

export const authInitializeFailure = (errorMessage: string) => createAction({
  type: AUTH_INITIALIZE_ACTION_TYPE.FAILURE,
  payload: errorMessage,
});
export type AuthInitializeFailureAction = ReturnType<typeof authInitializeFailure>;

export type AuthInitializeResultAction =
  | AuthInitializeSuccessAction
  | AuthInitializeFailureAction
  ;

// AUTH LOGIN
export enum AUTH_LOGIN_ACTION_TYPE {
  TRIGGER = 'AUTH/LOGIN-TRIGGER',
  SUCCESS = 'AUTH/LOGIN-SUCCESS',
  FAILURE = 'AUTH/LOGIN-FAILURE',
};

export const authLoginTrigger = () => createAction({
  type: AUTH_LOGIN_ACTION_TYPE.TRIGGER,
});
export type AuthLoginTriggerAction = ReturnType<typeof authLoginTrigger>;

export const authLoginSuccess = (authResult: AuthenticationResult) => createAction({
  type: AUTH_LOGIN_ACTION_TYPE.SUCCESS,
  payload: authResult,
});
export type AuthLoginSuccessAction = ReturnType<typeof authLoginSuccess>;

export const authLoginFailure = (errorMessage: string) => createAction({
  type: AUTH_LOGIN_ACTION_TYPE.FAILURE,
  payload: errorMessage,
});
export type AuthLoginFailureAction = ReturnType<typeof authLoginFailure>;

export type AuthLoginResultAction =
  | AuthLoginSuccessAction
  | AuthLoginFailureAction
  ;

// AUTH LOGOUT
export enum AUTH_LOGOUT_ACTION_TYPE {
  TRIGGER = 'AUTH/LOGOUT-TRIGGER',
  SUCCESS = 'AUTH/LOGOUT-SUCCESS',
  FAILURE = 'AUTH/LOGOUT-FAILURE',
};

export const authLogoutTrigger = (accountInfo: AccountInfo) => createAction({
  type: AUTH_LOGOUT_ACTION_TYPE.TRIGGER,
  payload: accountInfo,
});
export type AuthLogoutTriggerAction = ReturnType<typeof authLogoutTrigger>;

export const authLogoutSuccess = () => createAction({
  type: AUTH_LOGOUT_ACTION_TYPE.SUCCESS,
});
export type AuthLogoutSuccessAction = ReturnType<typeof authLogoutSuccess>;

export const authLogoutFailure = (errorMessage: string) => createAction({
  type: AUTH_LOGOUT_ACTION_TYPE.FAILURE,
  payload: errorMessage,
});
export type AuthLogoutFailureAction = ReturnType<typeof authLogoutFailure>;

export type AuthLogoutResultAction =
  | AuthLogoutSuccessAction
  | AuthLogoutFailureAction
  ;

// # ACTION GROUPS

// HOST
export type MessageFromHostAction =
  | AuthInitializeTriggerAction
  | AuthLoginTriggerAction
  | AuthLogoutTriggerAction
  ;

// DIALOG
export type LoginMessageFromDialogAction =
  | DialogInitializeResultAction
  | AuthInitializeResultAction
  | AuthLoginResultAction
  ;

export type LogoutMessageFromDialogAction =
  | DialogInitializeResultAction
  | AuthInitializeResultAction
  | AuthLogoutResultAction
  ;
