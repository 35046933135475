/* eslint-disable */
import { Stack, Text } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePickerContext } from '../../../../modules/Designer/Form/components/FilePicker/FilePickerContext';
import { IDelimitedFile } from '../../../../modules/Designer/Form/components/FilePicker/types';

import FileElement from '../FileElement'; 

import { titleStyles } from './styles';

interface FileListProps {
  // when editing we are not receiving files themselves, just the metadata
  files: IDelimitedFile[],
  onDeleteFile(index: number): void;
  onSelectFile(index: number): void;
}

const FileList: FunctionComponent<FileListProps> = ({
  files, onDeleteFile, onSelectFile
}) => {
  const { t } = useTranslation('upload');
  const {selectedItemIndex} = React.useContext(FilePickerContext)


  const onClickDelete = (index: number) => {
    onDeleteFile(index);
  };

  return (
    <Stack>
      {!!files.length &&
       <Text styles={titleStyles}>{ t('status') }</Text>
      }

      <Stack>
        { files.map((file, index) => (
          <FileElement
            index={index}
            isSelected={selectedItemIndex === index}
            key={`${file.name}_${index}`}
            name={file.name}
            onClickDelete={() => onClickDelete(index)}
            onClickSettings={() => onSelectFile(index)}
            wasUpdated={file.wasFileUpdated}
          />
        )) }
      </Stack>
    </Stack>
  );
};

export default FileList;
