import { AggregationType } from '../../api/model/schemas/AggregationType';
import { DataRequestContinuation } from '../../api/model/schemas/DataRequestContinuation';
import { OrderByDirection } from '../../api/model/schemas/OrderByDirection';
import { createStreamDataRequestTask } from './api';
import { selectedAssetsToDataRequestParams } from './sagas/utils';
import {
  StreamerAsset,
  StreamAssetRaw,
  FetchDataParams,
  StreamDataRequestParams,
  FetchDataResponse,
  StreamerFilter,
} from './types';

export const rawAssetToStreamerAsset = (
  input: StreamAssetRaw
): StreamerAsset => ({
  aggregation: input?.defaultAggregation ?? AggregationType.None,
  streamElementId: input.id,
  streamElementTypeId: input.$typeId,
  sortMode: OrderByDirection.Ascending,
});

export const fetchStreamDataRequest = async ({
  dataRequestParams,
  selectedStreamId,
  continuationToken,
}: FetchDataParams): Promise<FetchDataResponse> => {
  const params: StreamDataRequestParams = continuationToken
    ? {
        ...dataRequestParams,
        continuation: {
          pageSize: dataRequestParams?.continuation?.pageSize,
          token: continuationToken,
        },
      }
    : dataRequestParams;

  const {
    data: { rows, rowCount, totalRowCount, continuation },
  } = await createStreamDataRequestTask(selectedStreamId, params);

  const getNextPage = !continuation?.token
    ? null
    : () =>
        fetchStreamDataRequest({
          dataRequestParams,
          selectedStreamId,
          continuationToken: continuation.token,
        });

  return {
    getNextPage,
    rowCount,
    totalRowCount,
    rows,
  };
};

type FetchSingleAssetData = {
  asset: StreamerAsset;
  continuation?: DataRequestContinuation;
  filter?: StreamerFilter;
  selectedStreamId: string;
};
export const fetchSingleAssetData = async ({
  asset,
  continuation,
  filter,
  selectedStreamId,
}: FetchSingleAssetData) => {
  const dataRequestParams = selectedAssetsToDataRequestParams(
    [asset],
    filter ? [filter] : []
  );

  const params: StreamDataRequestParams = continuation
    ? {
        ...dataRequestParams,
        continuation,
      }
    : dataRequestParams;

  const {
    data: { rows, rowCount, totalRowCount, continuation: receivedContinuation },
  } = await createStreamDataRequestTask(selectedStreamId, params);

  return { rows, rowCount, totalRowCount, continuation: receivedContinuation };
};
