import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getConfig } from './utils';
import { ItemActionsHeaderProps } from './types';
import { HeaderBarButton } from '../../components/ui';

const ItemActionsHeader:React.FC<ItemActionsHeaderProps> = (props) => {
  const { t } = useTranslation();
  const buttonConfig = getConfig(props);

  return (
    <>
      {buttonConfig.map(({
        disabled,
        handleClick,
        iconName,
        testId,
        tooltipKey,
      }) => (
        <HeaderBarButton
          key={iconName}
          tooltip={(
            <Trans t={t} i18nKey={tooltipKey} />
          )}
          onClick={handleClick}
          iconProps={{ iconName }}
          data-testid={testId}
          disabled={disabled}
          shouldHideOnDisabled
        />
      ))}
    </>
  );
};

export default ItemActionsHeader;
