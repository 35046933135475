import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import { TooltipHost } from '../../../../../../../../components/ui';
import {
  AGGREGATIONS_TO_ICONS,
  getAggregationsToTranslatedName,
} from '../../../../../../../../constants/aggregations';

interface Props {
  entryKey: string;
  iconClassName?: string;
  textClassName?: string;
}

const FormattedEntryKey = ({
  entryKey,
  iconClassName,
  textClassName,
}: Props) => {
  // DERIVED STATE
  // Internal aggregation key is added when parsing the data
  const aggregation = entryKey.split(' ')[0];
  const aggregationIcon = aggregation
    ? AGGREGATIONS_TO_ICONS[aggregation]
    : null;
  const translatedName = getAggregationsToTranslatedName()[aggregation] ?? null;
  const entryKeyWithoutAggregation = entryKey.split(' ').slice(1).join(' ');

  // RENDER
  return (
    <span>
      {aggregationIcon && (
        <TooltipHost
          calloutProps={{ setInitialFocus: false }}
          content={translatedName}
        >
          <Icon iconName={aggregationIcon} className={iconClassName} />
        </TooltipHost>
      )}
      <span className={textClassName}>
        {aggregationIcon ? entryKeyWithoutAggregation : entryKey}
      </span>
    </span>
  );
};

export default FormattedEntryKey;
