import { TypeIds } from '.';
import { ConfigureStatus } from './ConfigureStatus';
import { DataSource } from './DataSource';
import { DataSourceEntityType } from './DataSourceEntityType';
import { ProcessStatus } from './ProcessStatus';

/** A data source entity proxy. */
export interface DataSourceEntity {
  /** The type id. */
  $typeId: TypeIds.DataSourceEntity;

  /** The entity id. */
  id: string; // TODO: make a tagged type (entity $uuid)

  /** The entity name */
  name: string | null;

  /** The entity description. */
  description: string | null;

  /** Types of data source entities. */
  type: DataSourceEntityType;

  /** The parent type type id. */
  $parentTypeId: TypeIds.DataSource;

  /** The parent id. */
  parentId: string; // ($uuid)

  tags: (string | null)[];

  /** The source id. */
  sourceId: string | null;

  /** The source name. */
  sourceName: string | null;

  /** The entity row count when known. */
  rowCount: number | null;

  /** Whether the fields are loaded. */
  readonly fieldsLoaded: boolean;

  /** Ingestion is pending. */
  ingestionStatus: ProcessStatus;

  /** The time stamp of last successful ingestion. */
  ingestionTimeStamp: string | null; // TODO: make a tagged type ($date-time)

  // /** The ingested name. */
  // ingestedName: string | null;

  /** Number of ingested fields. */
  readonly ingestedFieldCount: number; // integer($int32)

  /** The transform type id. */
  readonly $transformTypeId: string | null; // ($uuid)

  /** The transform id. */
  readonly transformId: string | null; // ($uuid)

  // NOTE: shouldn't this be available only when expanding? there is nothing that would distinguish this behaviour
  // in the Swagger docs, so we should get that info somehow and also make a special `Expanded` generic type
  parent?: DataSource;

  /** The configure states. */
  configureStatus: ConfigureStatus;

  /** When the last successful configure started. */
  readonly lastSuccessfulConfigureStartTime: string | null; // ($date-time)

  /** When the last successful configure started. */
  readonly lastSuccessfulConfigureEndTime: string | null; // ($date-time)

  /** The configuration id. */
  readonly configurationId: string | null;
  /** The configuration  type id. */
  readonly $configurationTypeId: string | null;
}

export const DataSourceEntityKeys: { [key in keyof DataSourceEntity]: key } = {
  $typeId: '$typeId',
  id: 'id',
  name: 'name',
  description: 'description',
  type: 'type',
  $parentTypeId: '$parentTypeId',
  parentId: 'parentId',
  tags: 'tags',
  sourceId: 'sourceId',
  sourceName: 'sourceName',
  rowCount: 'rowCount',
  fieldsLoaded: 'fieldsLoaded',
  ingestionStatus: 'ingestionStatus',
  ingestionTimeStamp: 'ingestionTimeStamp',
  // ingestedName: 'ingestedName',
  ingestedFieldCount: 'ingestedFieldCount',
  $transformTypeId: '$transformTypeId',
  transformId: 'transformId',
  parent: 'parent',
  configureStatus: 'configureStatus',
  lastSuccessfulConfigureStartTime: 'lastSuccessfulConfigureStartTime',
  lastSuccessfulConfigureEndTime: 'lastSuccessfulConfigureEndTime',
  configurationId: 'configurationId',
  $configurationTypeId: '$configurationTypeId',
};
