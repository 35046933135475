import { IStackStyles, IButtonStyles } from '@fluentui/react';
import React from 'react';
import { baseColors } from '../../theme';

const compileBgColor = (isSelected, hover) => {
  if (isSelected && hover) {
    return baseColors.selectedWithHover;
  }

  if (isSelected) {
    return baseColors.selected;
  }

  if (hover) {
    return baseColors.hover;
  }

  return baseColors.white;
};

export const containerStyles = (isSelected, spacing): IStackStyles => ({
  root: {
    fontSize: '12px',
    lineHeight: 16,
    fontWeight: 400,
    height: '28px',
    paddingLeft: spacing || 0,
    backgroundColor: compileBgColor(isSelected, false),
    selectors: {
      '&:hover': {
        backgroundColor: compileBgColor(isSelected, true),
      },
      '&:hover button': {
        opacity: 1,
      },
    },
  },
});

export const spanStyle: React.CSSProperties = {
  marginRight: '3px',
  whiteSpace: 'nowrap' as const,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '30%',
  minWidth: '15px',
};

export const primaryTextStyle: React.CSSProperties = {
  ...spanStyle,
  minWidth: '15%',
  color: baseColors.orange,
};

export const valueTextStyle: React.CSSProperties = {
  ...spanStyle,
  maxWidth: 'auto',
  color: baseColors.orange,
};

const compileIconBgColor = (isEditing, hover) => {
  if (isEditing && hover) {
    return baseColors.hover;
  }

  if (isEditing) {
    return baseColors.white;
  }

  if (hover) {
    return baseColors.selected;
  }

  return 'transparent';
};

export const iconButtonStyles = (isEditing, isSelected): IButtonStyles => ({
  root: {
    height: '24px',
    width: '24px',
    color: baseColors.textColor,
    backgroundColor: compileIconBgColor(isEditing, false),
    opacity: isSelected ? 1 : 0,
    borderRadius: 4,
  },
  rootHovered: {
    color: baseColors.textColor,
    backgroundColor: compileIconBgColor(isEditing, true),
  },
});

export const btnContainerStyles: React.CSSProperties = {
  width: '28px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const btnWrapStyles: React.CSSProperties = {
  marginLeft: 'auto',
  minWidth: '56px',
  display: 'flex',
};
export const filterFormTarget: React.CSSProperties = {
  width: 'calc(100% - 20px)',
  margin: 'auto',
};
