import { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { fetchEntities } from '../../api';
import { ApiV4ResponseWrapper } from '../../types';
import { getCurrentFormSection } from '../selectors';
import { IEntityPropertyInteraction, IGenericEntity } from '../types';

/**
 * When the property interaction has a referenceTypeProperty and  a referenceProperty, it means
 * the value will be picked from a property of a entity of a given type described in
 * another property within the same Section. This hooks handles all that.
 * @param interaction
 * @param onChange
 */

export const useReferenceTypeProperty = (
  interaction: IEntityPropertyInteraction,
  onChange: (value: unknown) => void,
) => {
  const { referenceTypeProperty, referenceProperty } = interaction;
  const [entities, setEntities] = useState<IGenericEntity[]>([]);
  const section = useSelector(getCurrentFormSection);

  const typeId = section
    .properties
    ?.find((prop) => prop.name === referenceTypeProperty)
    ?.interaction?.referenceType;

  const reference = useWatch({
    name: referenceTypeProperty,
  });

  useEffect(() => {
    (async () => {
      try {
        if (referenceTypeProperty && typeId) {
          const { data: res } = await fetchEntities<
          ApiV4ResponseWrapper<IGenericEntity[]>
          >(typeId)();

          setEntities(res.data);
        }
      } catch (e) {
        console.error(e);
        setEntities([]);
      }
    })();
  }, []);

  useDeepCompareEffect(() => {
    if (referenceTypeProperty) {
      if (typeof reference === 'string') {
        const selected = entities.find((entity) => entity.id === reference);
        onChange(selected?.[referenceProperty] || 'Unknown');
      } else {
        onChange('Unknown');
      }
    }
  }, [entities, reference]);
};
