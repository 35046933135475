import { ContextualMenuItemType } from 'office-ui-fabric-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SelectionItem } from '../../../../../../components/ui/ViewControlButton/constants';
import { setSelectionType } from '../../../../../App/actions';
import { initialState } from '../../../../../App/reducer';
import SortControl from '../../../../components/SortControl';
import { LinksViewControlFilter } from '../constants';

const I18N_PREFIX = 'modules:designer:sections:ingest:modes:links';

export const defaultSelection: SelectionItem = {
  isDefault: true,
  key: LinksViewControlFilter.Retained,
  nameI18nKey: `${I18N_PREFIX}:viewControl.retained`,
};

export const selectionItems: SelectionItem[] = [
  {
    key: 'divider_1',
    itemType: ContextualMenuItemType.Divider,
  },
  {
    key: LinksViewControlFilter.Accepted,
    nameI18nKey: `${I18N_PREFIX}:viewControl.accepted`,
  },
  {
    key: LinksViewControlFilter.Rejected,
    nameI18nKey: `${I18N_PREFIX}:viewControl.rejected`,
  },
  defaultSelection,
];

export const LinksSortControl = (): JSX.Element => {
  // DEPS
  const dispatch = useDispatch();

  // EFFECTS
  // the links view uses custom selection types that are not supported by other views
  // so we need to reset it on unmount
  const resetSelectionType = React.useCallback(() => {
    dispatch(setSelectionType(initialState.selectionType));
  }, [dispatch]);
  React.useEffect(() => resetSelectionType, [resetSelectionType]);

  // RENDER
  return (
    <>
      <SortControl selectionItemsOverride={selectionItems} />
    </>
  );
};
