import { IIconProps } from 'office-ui-fabric-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AggregationType } from '../../../../api/model/schemas/AggregationType';
import ButtonMenu from '../../../../components/ui/ButtonMenu';
import {
  aggregationButtonMenuConfig,
  AGGREGATIONS_TO_ICONS,
} from '../../../../constants/aggregations';
import {
  getAggregationsForAlreadyAggregatedItems,
  getInvalidAggregationMethodsApiV4,
} from '../../../../utils/itemsAggregation';
import { assetAggregationClicked } from '../../actions';
import {
  createGetAssetAggregations,
  createGetIsAggregationButtonQueued,
} from '../../selectors';
import { StreamAssetRaw } from '../../types';
import { rawAssetToStreamerAsset } from '../../utils';

interface Props {
  asset: StreamAssetRaw;
}

export const SelectStreamerAggregation = ({ asset }: Props) => {
  // HOOKS
  const dispatch = useDispatch();

  const getAssetAggregations = useSelector(createGetAssetAggregations);
  const getIsButtonOrange = useSelector(createGetIsAggregationButtonQueued);

  // DERIVED STATE
  const streamerAsset = React.useMemo(
    () => rawAssetToStreamerAsset(asset),
    [asset]
  );

  const usedAggregations = getAssetAggregations(asset);
  const isOrange = getIsButtonOrange(asset);
  const invalidAggregations = React.useMemo(() => {
    if (asset.isAggregated) {
      return getAggregationsForAlreadyAggregatedItems();
    }

    return getInvalidAggregationMethodsApiV4(asset.dataType);
  }, [asset]);

  const items = React.useMemo(
    () =>
      aggregationButtonMenuConfig()
        .filter((menuItem) => !invalidAggregations.includes(menuItem.key))
        .map((item) => ({
          ...item,
          checked: usedAggregations.includes(item.key),
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [invalidAggregations, usedAggregations]
  );

  const icon = React.useMemo((): IIconProps => {
    let iconName = 'MapLayers';

    if (usedAggregations.length === 0) {
      iconName =
        AGGREGATIONS_TO_ICONS[asset?.defaultAggregation] ??
        'SynergiesDoNotSummarize';
    }

    if (usedAggregations.length === 1) {
      let aggregation = usedAggregations[0];

      // API can treat null aggregation as type None
      if (aggregation === null) {
        aggregation = AggregationType.None;
      }

      iconName = AGGREGATIONS_TO_ICONS[aggregation];
    }

    return {
      iconName,
    };
  }, [asset, usedAggregations]);

  // CALLBACKS
  const onChangeItem = React.useCallback(
    (key: AggregationType) => {
      dispatch(
        assetAggregationClicked({
          ...streamerAsset,
          aggregation: key,
        })
      );
    },
    [streamerAsset]
  );

  return (
    <ButtonMenu {...{ items, onChangeItem, isOrange }} overrideIcon={icon} />
  );
};
