import { createAction } from '@reduxjs/toolkit';
import { createTypedAction } from '../../../../../shared/utils/actions';
import { OutputViewType } from './types';

const ACTION_PREFIX = '@eyko/Streamer/Isolated';

export const dataReceived = createTypedAction<{
  rowCount: number;
  rows: any[][];
  totalRowCount: number;
}>()(`${ACTION_PREFIX}/OUTPUT_DATA_RECEIVED`);

export const fetchNextPage = createTypedAction()(
  `${ACTION_PREFIX}/FETCH_NEXT_OUTPUT_PAGE`
);

export const continuationReceived = createTypedAction<{
  rowCount: number;
  rows: any[][];
  totalRowCount: number;
}>()(`${ACTION_PREFIX}/OUTPUT_CONTINUATION_RECEIVED`);

export const setColumnsAssetId = createTypedAction<string>()(
  `${ACTION_PREFIX}/SET_COLUMNS_ASSET_ID`
);

export const setSwimlanesAssetId = createTypedAction<string>()(
  `${ACTION_PREFIX}/SET_SWIMLANES_ASSET_ID`
);

export const setClosedSwimlanes = createTypedAction<any[]>()(
  `${ACTION_PREFIX}/SET_CLOSED_SWIMLANES`
);

export const setClosedColumns = createTypedAction<any[]>()(
  `${ACTION_PREFIX}/SET_CLOSED_COLUMNS`
);

export const toggleForceOpenAllCards = createAction(
  `${ACTION_PREFIX}/TOGGLE_FORCE_OPEN_ALL_CARDS`
);

export const setOutputViewType = createTypedAction<OutputViewType>()(
  'STREAMER_ISOLATED_OUTPUT/SET_VIEW_TYPE'
);

export const toggleCardSelection = createTypedAction<number>()(
  `${ACTION_PREFIX}/TOGGLE_CARD_SELECTION`
);

export const toggleSharePreview = createTypedAction<boolean>()(
  `${ACTION_PREFIX}/TOGGLE_SHARE_PREVIEW`
);

export const clearCardSelection = createTypedAction()(
  `${ACTION_PREFIX}/CLEAR_CARD_SELECTION`
);

export const toggleTableTotals = createTypedAction()(
  `${ACTION_PREFIX}/TOGGLE_TABLE_TOTALS`
);
