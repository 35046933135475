/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Text,
  CommandButton,
  Icon,
  Link,
  Stack,
  getTheme,
} from '@fluentui/react';
import { Card, CardItem, CardSection } from '@uifabric/react-cards';

import { Error } from '../../types';
import { removeErrorByDate } from '../../actions';
import { dateToFromNowDaily } from './utils';

import CopyErrorDetailsButton from '../CopyErrorDetailsButton';

import * as styles from './styles';
import { routes } from '../../../../constants/routes';

type Props = Error;

const ErrorCard: React.FC<Props> = ({
  date,
  detail,
  stackTrace,
  status,
  title,
}) => {
  const { t } = useTranslation('errors');
  const theme = getTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isDetailExpanded, setDetailExpanded] = useState(false);
  const handleDismissError = () => dispatch(removeErrorByDate({ date }));

  return (
    <Card styles={styles.cardStyles}>
      <CardItem fill styles={styles.cardItemStyles}>
        <Icon iconName="Warning" className={styles.errorIconStyles} />
        <Text styles={styles.titleStyles}>
          {status
            ? t('notificationTitle', { statusCode: status })
            : t('errorCardTitle')}
        </Text>
        <CommandButton
          iconProps={{
            iconName: 'Cancel',
          }}
          styles={styles.cancelButtonStyles}
          onClick={handleDismissError}
          data-testid="dismiss-error"
        />
      </CardItem>

      <CardSection
        styles={styles.cardSectionStyles}
        tokens={{ childrenGap: 8 }}
      >
        <Text styles={styles.descriptionStyles}>
          <span style={{ marginRight: '2px', wordBreak: 'break-word' }}>
            {title}
          </span>
          <br />
          <span style={{ margin: '4px 0' }}>{detail}</span>
          <Link
            styles={styles.expandLinkStyles}
            onClick={() => setDetailExpanded(!isDetailExpanded)}
          >
            {t(isDetailExpanded ? 'showLess' : 'showMore')}
          </Link>
        </Text>

        {isDetailExpanded && (
          <Text styles={styles.errorDetailStyles(theme)}>{stackTrace}</Text>
        )}

        <Stack
          tokens={{ childrenGap: 8 }}
          horizontal
          styles={styles.actionButtonStackStyles}
        >
          <CopyErrorDetailsButton
            {...{
              date,
              detail,
              stackTrace,
              status,
              title,
            }}
          />
          <Link
            onClick={() => history.push(routes.help.errorForm)}
            styles={styles.dismissButtonStyles}
          >
            {t('report')}
          </Link>
        </Stack>

        <Text styles={styles.dateStyles}>
          <Icon iconName="DateTime" style={{ fontSize: '16px' }} />
          {dateToFromNowDaily(date)}
        </Text>
      </CardSection>
    </Card>
  );
};

export default ErrorCard;
