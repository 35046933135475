import moment from 'moment';
import { DataType } from '../../../../../../../../api/model/schemas/DataType';
import { FormatType } from '../../../../../../../../api/model/schemas/FormatType';
import { StreamAssetRaw } from '../../../../../../types';

const MIN_DECIMALS = 2;

export const formatValue = ({
  asset,
  value,
}: {
  asset: StreamAssetRaw;
  value: any;
}) => {
  const processedDataType = asset?.processedDataType;
  const processedFormatType = asset?.processedFormatType;
  const isCurrency = processedFormatType === FormatType.Currency;
  const isDecimal = processedDataType === DataType.NumericDecimal || isCurrency;
  const isPercentage = processedFormatType === FormatType.Percentage;
  const isRawNumber = processedFormatType === FormatType.Number;
  const isBoolean = processedDataType === DataType.Boolean;

  if (isBoolean) {
    return String(value);
  }

  if (
    [DataType.NumericDecimal, DataType.NumericInteger].includes(
      processedDataType
    )
  ) {
    if (isRawNumber) {
      return value;
    }

    const actualValue = isPercentage ? +value * 100 : value;
    return isDecimal ? (+actualValue).toFixed(MIN_DECIMALS) : actualValue;
  }

  if (processedDataType === DataType.DateTime) {
    const date = moment(value);
    switch (processedFormatType) {
      case FormatType.LongDate:
        return date.format('LL');
      case FormatType.LongDateTime:
        return date.format('LLL');
      case FormatType.ShortDate:
        return date.format('L');
      case FormatType.ShortDateTime:
        return date.format('L h:mm A');
      case FormatType.LongTime:
        return date.format('h:mm:ss A');
      case FormatType.ShortTime:
        return date.format('k:mm');
      default:
        break;
    }
    return moment(value).format('LLL');
  }

  if (!value) {
    return '-';
  }

  return value;
};
