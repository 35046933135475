import { merge } from 'lodash';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../../store/types';
import { IUser } from '../../../types/IUser';
import { generateTypedEntititesTaskId } from './components/SelectOneOfType/utils';
import { ENTITY_CHILDREN_KEY } from './constants';
import {
  IEntityForm,
  IEntityFormChildren,
  IEntityFormSection,
  Json,
} from './types';

export const getCurrentSectionIndex = ({
  designerEntityForm: { currentPageIndex },
}: ApplicationState) => currentPageIndex;

export const getPreviousPageIndex = ({
  designerEntityForm: { previousPageIndex },
}: ApplicationState) => previousPageIndex;

export const getAllForms = ({
  designerEntityForm: { forms },
}: ApplicationState) => forms;

export const getAllFormsSections = (state: ApplicationState) =>
  getAllForms(state).flatMap(({ sections }) => sections);

export const getAllFormsSectionsSorted = (state: ApplicationState) => {
  const forms = getAllForms(state);
  return forms.reduce(
    (sorted: IEntityFormSection[], { parentIndex, ...form }) => {
      sorted.splice(
        parentIndex ? parentIndex + 1 : 0,
        0,
        ...(form?.sections || [])
      );
      return sorted;
    },
    []
  );
};

export const getCurrentFormSection = (state: ApplicationState) =>
  getAllFormsSectionsSorted(state)[getCurrentSectionIndex(state)];

export const getEmbeddedDataByTypeId =
  (typeId: string) => (state: ApplicationState) =>
    getAllForms(state)
      .filter((form) => form.embeddedTo === typeId)
      .reduce(
        (acc: IEntityForm['data'], { nestedKey, data }) =>
          nestedKey
            ? merge({}, acc, { [nestedKey]: data })
            : merge({}, acc, data),
        {}
      );

export const getEntityFormByTypeId =
  <T extends Json, C extends Json>(typeId: string) =>
  (state: ApplicationState): IEntityForm<T, C> => {
    const form = getAllForms(state).find(
      (currentForm) => currentForm.typeId === typeId
    );
    if (!form) {
      return undefined;
    }
    const embedded = getEmbeddedDataByTypeId(typeId)(state);

    return {
      ...form,
      data: merge({}, form.data, embedded) as T,
    };
  };

export const getFormChildrenByTypeId =
  <T extends Json = Json>(formTypeId: string, childrenTypeId: string) =>
  (state: ApplicationState): IEntityFormChildren<T>[] =>
    getEntityFormByTypeId<Json, T>(formTypeId)(state)?.data?.[
      ENTITY_CHILDREN_KEY
    ]?.[childrenTypeId] || [];

export const getEntityFormByControllerId =
  (controllerId: string) =>
  (state: ApplicationState): IEntityForm => {
    const form = getAllForms(state).find(
      (currentForm) => currentForm.controllerId === controllerId
    );
    if (!form) {
      return undefined;
    }
    const embedded = getEmbeddedDataByTypeId(form.typeId)(state);

    return {
      ...form,
      data: merge({}, form.data, embedded),
    };
  };

export const getEntityByTypeId =
  (typeId: string) =>
  ({ designerEntityForm: { entities } }: ApplicationState) =>
    entities.records.find((entity) => entity.$typeId === typeId);

export const getEntityById =
  (id: string) =>
  ({ designerEntityForm: { entities } }: ApplicationState) =>
    entities.records.find((entity) => entity.id === id);

export const getAllEntitiesByTypeId =
  (typeId: string, parentEntityId?: String) =>
  ({ designerEntityForm: { entities } }: ApplicationState) =>
    entities.records.filter(
      (entity) =>
        entity.$typeId === typeId &&
        (parentEntityId ? entity.parentId === parentEntityId : true)
    );

export const getAllEntitiesByParentId =
  (parentId: string, typeId: string) =>
  ({ designerEntityForm: { entities } }: ApplicationState) =>
    entities.records.filter(
      (entity) => entity.$typeId === typeId && entity.parentId === parentId
    );

export const getEntitiesFromAssets =
  (typeId: string) =>
  ({ designerEntityForm: { entities } }: ApplicationState) =>
    entities.records.reduce((acc, curr) => {
      if (curr.$typeId === typeId) {
        acc.push(entities.records.find((entity) => entity.id === curr.assetId));
      }
      return acc;
    }, []);

export const getEntities = ({
  designerEntityForm: { entities },
}: ApplicationState) => entities.records;

export const getTypedEntitiesState = ({
  designerEntityForm: { typedEntities },
}: ApplicationState) => typedEntities.records;

export const getTypedEntitiesByTypeId =
  (typeId: string) =>
  ({ designerEntityForm: { typedEntities } }: ApplicationState) =>
    typedEntities.records[typeId] || [];

export const getTypedEntities = ({
  designerEntityForm: { typedEntities },
}: ApplicationState) => Object.values(typedEntities.records).flat();

export const getTypedEntityById =
  (entityId: string) => (state: ApplicationState) =>
    getTypedEntities(state).find((entity) => entity.id === entityId);

export const getIsSyncingEntityForm = ({
  designerEntityForm: { isSyncing },
}: ApplicationState) => isSyncing;

export const getIsFetchingEntities = ({
  designerEntityForm: { entities, typedEntities },
}: ApplicationState) => entities.isLoading || typedEntities.runningTasks.length;

export const getIsTypedEntitiesFetchTaskRunning =
  (typeId: string, sourceId: string) =>
  ({
    designerEntityForm: {
      typedEntities: { runningTasks },
    },
  }: ApplicationState) => {
    const id = generateTypedEntititesTaskId({ typeId, sourceId });
    return runningTasks.indexOf(id) > -1;
  };

export const getIsFetchingSections = ({
  designerEntityForm: { isFetchingSections },
}: ApplicationState) => isFetchingSections;

export const getLastEditorEntity =
  (parentTypeId: string) =>
  (state: ApplicationState): IUser => {
    const parentEntity = getEntityByTypeId(parentTypeId)(state);
    return getEntities(state).find(
      (entity) =>
        entity.$typeId === parentEntity?.$lastChangedByTypeId &&
        entity.id === parentEntity?.lastChangedById
    ) as unknown as IUser;
  };

export const getEntityName = createSelector([getAllForms], (forms) =>
  forms ? forms[0]?.data?.name : null
);
