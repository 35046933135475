import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { addEntityFormChildren, setEntityFormChildren } from '../../../actions';
import { IEntityForm, IEntityPropertyInteraction, Json } from '../../../types';
import { getFormChildrenByTypeId } from '../../../selectors';

import useMultiEntityForm from './useMultiEntityForm';
import { getSchemaPropertiesFromEntity } from '../utils';

export interface UseManageMultiple<T = Json> {
  typeId: string;
  currentEntityForm: IEntityForm;
  interaction: IEntityPropertyInteraction;
  forceMin?: boolean;
  defaultValues?: T;
}

export type UseManageMultipleEditItem<T> = (
  id: string,
  data: Partial<T>
) => void;

const useManageMultiple = <T extends Json = Json>({
  typeId,
  currentEntityForm,
  interaction,
  forceMin = true,
  defaultValues,
}: UseManageMultiple<T>) => {
  const { entityId } = currentEntityForm;
  const dispatch = useDispatch();
  const formTypeId = currentEntityForm.typeId;
  const hasInitialized = React.useRef(null);

  const { entities, schema } = useMultiEntityForm(
    interaction.referenceType,
    entityId
  );

  const items = useSelector(
    getFormChildrenByTypeId<T>(currentEntityForm.typeId, typeId)
  );

  const handleAdd = (data: T = defaultValues) => {
    const id = uuidv4();
    dispatch(
      addEntityFormChildren({
        formTypeId,
        typeId,
        children: [{ id, data }],
      })
    );
    return id;
  };

  const handleRemove = (id: string) => {
    dispatch(
      setEntityFormChildren({
        formTypeId,
        typeId,
        children: items.filter((item) => item.id !== id),
      })
    );
  };

  const handleMove = (from: number, to: number) => {
    const result = [...items];
    const [removed] = result.splice(from, 1);
    result.splice(to, 0, removed);

    dispatch(
      setEntityFormChildren({
        formTypeId,
        typeId,
        children: result,
      })
    );
  };
  const handleEdit: UseManageMultipleEditItem<T> = (id, data) => {
    const children = items.map((item) =>
      item.id === id
        ? {
            ...item,
            data: { ...(item.data || {}), ...(data || {}) },
          }
        : item
    );

    dispatch(
      setEntityFormChildren({
        formTypeId,
        typeId,
        children,
      })
    );
  };

  useEffect(() => {
    if (
      entities?.length &&
      !items.length &&
      schema &&
      // prevents re-adding all entries if you delete them while editing
      !hasInitialized.current
    ) {
      entities.forEach((entity) => {
        // We add a form child for each existing entity.
        dispatch(
          addEntityFormChildren({
            formTypeId,
            typeId,
            children: [
              {
                id: entity.id,
                data: getSchemaPropertiesFromEntity<T>(entity, schema),
              },
            ],
          })
        );
      });

      hasInitialized.current = true;
    }
  }, [entities, schema]);

  useEffect(() => {
    if (!entityId) {
      const min = interaction?.minimum;

      if (min > 0 && forceMin) {
        for (let i = items.length; i < min; i += 1) {
          handleAdd(defaultValues);
        }
      }
    }
  }, [items]);

  return {
    items,
    append: handleAdd,
    remove: handleRemove,
    move: handleMove,
    edit: handleEdit,
  };
};

export default useManageMultiple;
