import { AxiosResponse } from 'axios';
import getClient from '../../api/getClient';
import { currentUserPreferences, updateUserPreferences } from '../../constants/apiPaths';
import { IClientPreference } from '../../types/IClientPreferences';

export const fetchClientPreferences = async (): Promise<AxiosResponse<IClientPreference[]>> => {
  const client = await getClient();

  return client.get(currentUserPreferences);
};

export const createClientPreference = async (preference) => {
  const client = await getClient();

  return client.post(currentUserPreferences, preference);
};

export const updateClientPreference = async (id: string, preference) => {
  const client = await getClient();

  return client.patch(updateUserPreferences(id), preference);
};
