import {
  mergeStyleSets,
  ISpinnerStyles,
  IIconStyles,
  ITooltipHostStyles,
} from '@fluentui/react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../theme';

export const classNames = mergeStyleSets({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  optionWrapper: {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'auto',
    height: '70px',
    borderRadius: BORDER_RADIUS_IN_PX,
    backgroundColor: baseColors.white,
    border: `1px solid ${baseColors.inputBorder}`,
    selectors: {
      '&:hover': {
        background: baseColors.selected,
      },
    },
  },
  optionWrapperChecked: {
    backgroundColor: `${baseColors.selected}`,
    borderColor: baseColors.midOrange,
    '&:hover': {
      background: baseColors.selectedWithHover,
    },
  },
  optionWrapperHasError: {
    borderColor: baseColors.red,
  },
  optionWrapperSyncing: {
    cursor: 'not-allowed',
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  contentSyncing: {
    opacity: '30%',
  },
  contentError: {
    color: baseColors.red,
  },
  label: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
  },
});

export const spinnerStyles: ISpinnerStyles = {
  root: {
    zIndex: 3,
    position: 'absolute',
    top: '4px',
    right: '4px',
  },
  circle: {
    width: '16px',
    height: '16px',
  },
};

export const iconStyles = (hasError: boolean): IIconStyles => ({
  root: {
    fontSize: 32,
    height: 32,
    width: 32,
    color: hasError ? baseColors.errorRed : baseColors.iconColor,
  },
});

export const tooltipStyles: ITooltipHostStyles = {
  root: { display: 'block', maxWidth: '100%', width: '100%' },
};
