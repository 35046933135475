import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  DropResult,
  Droppable,
  DragStart,
} from 'react-beautiful-dnd';

import { multiSelect, multiDragAwareReorder } from './utils';

import DraggingClone from './DraggingClone';
import DraggableAsset from './DraggableAsset';
import { StreamerAssetWithUiid } from './types';

interface Props {
  checkIfOrderChanged: (
    item: StreamerAssetWithUiid,
    itemIndex?: number
  ) => boolean;
  items: StreamerAssetWithUiid[];
  onUpdate: (newColumns: StreamerAssetWithUiid[]) => void;
  dragDropId: string;
}

const ReorderDragAndDrop: React.FC<Props> = ({
  checkIfOrderChanged,
  dragDropId,
  items,
  onUpdate,
}) => {
  const [selectedItemsIds, setSelectedItemsIds] = React.useState<string[]>([]);
  const [draggingTaskId, setDraggingTaskId] = useState<string>(null);

  const unselectAll = () => {
    setSelectedItemsIds([]);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return;
      }

      if (event.key === 'Escape') {
        unselectAll();
      }
    };

    const handleClickOrTouchEnd = (event: MouseEvent | TouchEvent) => {
      if (event.defaultPrevented) {
        return;
      }

      unselectAll();
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('click', handleClickOrTouchEnd);
    window.addEventListener('touchend', handleClickOrTouchEnd);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClickOrTouchEnd);
      window.removeEventListener('touchend', handleClickOrTouchEnd);
    };
  }, []);

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    const { processedItems } = multiDragAwareReorder({
      items,
      selectedItemsIds,
      source,
      destination,
    });

    setDraggingTaskId(null);
    onUpdate(processedItems);
  };

  const handleDragStart = (result: DragStart) => {
    const id = result.draggableId;

    const isSelected = selectedItemsIds.some((itemId) => itemId === id);

    // if dragging an item that is not selected - unselect all items
    if (!isSelected) {
      unselectAll();
    }

    setDraggingTaskId(id);
  };

  const toggleSelection = (itemId: string) => {
    const wasSelected = selectedItemsIds.includes(itemId);

    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [itemId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedItemsIds.length > 1) {
        return [itemId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedItemsIds(newTaskIds);
  };
  const toggleSelectionInGroup = (itemId: string) => {
    const index = selectedItemsIds.indexOf(itemId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedItemsIds([...selectedItemsIds, itemId]);
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallowCopy = [...selectedItemsIds];
    shallowCopy.splice(index, 1);
    setSelectedItemsIds(shallowCopy);
  };

  const multiSelectTo = (itemId: string) => {
    const updated = multiSelect(items, selectedItemsIds, itemId);

    if (!updated) return;

    setSelectedItemsIds(updated);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Droppable
        droppableId={dragDropId}
        renderClone={(provided) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            {/* react-beautiful-dnd is doing constant rerenders of this component :C */}
            {React.useMemo(
              () => (
                <DraggingClone
                  selectionCount={selectedItemsIds.length}
                  draggingTaskId={draggingTaskId}
                />
              ),
              [draggingTaskId]
            )}
          </div>
        )}
      >
        {(provided: any) => (
          <div ref={provided.innerRef} data-testid="columns-reorder-wrapper">
            {items.map((item, index) => {
              const isSelected = selectedItemsIds.includes(item.uuid);
              const isGhosting =
                isSelected && !!draggingTaskId && draggingTaskId !== item.uuid;

              return (
                <DraggableAsset
                  key={item.uuid}
                  asset={item}
                  index={index}
                  multiSelectTo={multiSelectTo}
                  toggleSelectionInGroup={toggleSelectionInGroup}
                  toggleSelection={toggleSelection}
                  isSelected={isSelected}
                  isGhosting={isGhosting}
                  hasOrderChanged={checkIfOrderChanged(item, index)}
                />
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderDragAndDrop;
