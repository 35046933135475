import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from '@fluentui/react';

import { TeachingBubble, Toggle } from '../../../../../../components/ui';
import { PARENT_DRILLING } from '../../../../constants';
import { ParentDrilling } from '../../../../types';
import streamsSelectors from '../../../../../Streams/selectors';

import { toggleStyles } from '../../styles';
import { styles, wrapIconStyles } from './styles';
import { ParentDrillingTypes } from './types';

interface Props {
  parentDrillingType: ParentDrillingTypes;
  handleToggleChange: (option: string, value: boolean | ParentDrilling) => void;
}

const ParentDrillingToggle = ({
  handleToggleChange,
  parentDrillingType,
}: Props) => {
  const { t } = useTranslation('streams');
  const currentDataset = useSelector(streamsSelectors.getCurrentDataset);
  const defaultDatasetOptions = useSelector(
    streamsSelectors.getDefaultDatasetOptions,
  );
  const [bubbleOpen, setBubbleOpen] = useState(false);

  const drilling = currentDataset?.options?.[parentDrillingType]
      || defaultDatasetOptions?.[parentDrillingType];

  const isChecked = PARENT_DRILLING.before === drilling;

  const handleInfoIconClick = () => {
    setBubbleOpen(true);
  };

  return (
    <>
      { bubbleOpen && (
        <TeachingBubble
          target="#parent-first-info-icon"
          headline={t('Parent First')}
          onDismiss={() => setBubbleOpen(false)}
          hasCloseButton
        >
          <Trans t={t} i18nKey="parentFirstMessage" />
        </TeachingBubble>
      )}
      <div style={wrapIconStyles}>
        <Icon
          style={styles.infoIcon}
          iconName="Info"
          id="parent-first-info-icon"
          onClick={handleInfoIconClick}
        />
      </div>
      <Toggle
        inlineLabel
        styles={toggleStyles}
        onChange={(_, checked) => handleToggleChange(
          parentDrillingType,
          checked ? PARENT_DRILLING.before : PARENT_DRILLING.after,
        )}
        checked={isChecked}
        label={t('Parent First')}
      />
    </>
  );
};

export default ParentDrillingToggle;
