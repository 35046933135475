import React from "react";
import { useTranslation } from "react-i18next";
import { IColumnDataType } from "../../types/IColumn";
import { CreatableDropdown } from "../ui";
import { customStyles } from "../ui/SelectDropdown/customization";

interface Props {
  isMulti?: boolean;
  onChange(value: string[]): void;
  defaultValue: string[];
  label?: string;
  styles?: any;
  assetDataType?: IColumnDataType;
}

const TypedValuesInput: React.FC<Props> = ({
  isMulti,
  onChange,
  defaultValue,
  label,
  styles = customStyles,
  assetDataType,
}) => {
  const { t } = useTranslation();

  // CALLBACKS

  // internally react-select library hold options as array of objects
  // {key: string, label:string}
  // when creating new object list of values is appended with such an object
  // however for our purposes we are really good with flat string[] list
  const extractLabel = React.useCallback(
    (option) => option?.label || option,
    []
  );
  const getOptionValue = extractLabel;
  const getOptionLabel = extractLabel;

  const isValidNewOption = React.useCallback(
    (val: string) => {
      // https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
      function isStringNumeric(str: any) {
        if (typeof str !== "string") return false; // we only process strings!
        return (
          !Number.isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !Number.isNaN(parseFloat(str))
        ); // ...and ensure strings of whitespace fail
      }

      // just to be very explicit
      // every react-select entry will be added as string
      // however our API throws 500 error when casting fails
      if (assetDataType === IColumnDataType.Text) {
        return true;
      }

      if (
        assetDataType === IColumnDataType.NumericDecimal ||
        assetDataType === IColumnDataType.NumericInteger
      ) {
        return isStringNumeric(val);
      }

      return true;
    },
    [assetDataType]
  );

  const handleUpdate = React.useCallback(
    (newValues: any) => {
      // similarly here
      // react select uses {key: string, label:string}
      // we want string[]
      if (!newValues) return;

      const values = isMulti ? newValues : [newValues];

      onChange(
        values?.map((val) => {
          if (typeof val === "object" && val !== null) {
            return val.label;
          }
          return val;
        }) || []
      );
    },
    [isMulti, onChange]
  );

  const handleFormatCreateLabel = React.useCallback(
    (value: string) => t("filters:newTypedValueInFilterForm", { value }),
    [t]
  );

  return (
    <CreatableDropdown
      {...{
        getOptionLabel,
        getOptionValue,
        isMulti,
        label,
        styles,
        isValidNewOption,
      }}
      cacheOptions
      defaultOptions
      menuPlacement="auto"
      onChange={handleUpdate}
      value={defaultValue}
      formatCreateLabel={handleFormatCreateLabel}
    />
  );
};

export default TypedValuesInput;
