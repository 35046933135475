import { SelectionType } from '../../components/ui/ViewControlButton';

export enum ObjectTypes {
  FREE_FORM = 'FREE_FORM',
  TABLE = 'TABLE',
  GROUP_TABLE = 'GROUP_TABLE',
  PIVOT_TABLE = 'PIVOT_TABLE',
}

export enum DefinedSortTypes {
  // the value of the enum will be used to "grab" group names
  // for example for Tags sort we will search for all tags and assign groups accordingly
  Letter = 'letter',
  Name = 'name',
  Tags = 'tags',
  Catalog = 'catalog',
  Data = 'data',
  DataType = 'dataType',
  Entity = 'parentEntityName',
  Category = 'category',
  Type = 'type',
  Role = 'role',
  Sequence = 'sequence',
  Precedence = 'precedence',
  Logic = 'logic',
  Value = 'value',
  ParentGroupName = 'parentGroupName',
  StreamAssetType = 'streamAssetType',
  EmptyLinks = 'emptyLinks',
  StreamerEntity = 'processedEntityName',
  Design = 'parentDesignName',
  Status = 'processStatus',
}

export interface AppState {
  activeObjectType: ObjectTypes;
  isOtherUserSyncing: boolean;
  selectedItemIds: string[];
  sortControlType: string | DefinedSortTypes;
  isEditingItem: boolean;
  selectionType: SelectionType;
  sortControlHistory: Record<string, DefinedSortTypes | string>;
}
