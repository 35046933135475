import React from 'react';
import useSortControl from '../../../../hooks/useSortControl';
import { DefinedSortTypes } from '../../../App/types';
import { AssetsPrecedence } from '../components/AssetsPrecedence/AssetsPrecedence';
import { AssetsSequencing } from '../components/AssetsSequencing/AssetsSequencing';

const components = {
  [DefinedSortTypes.Sequence]: <AssetsSequencing />,
  [DefinedSortTypes.Precedence]: <AssetsPrecedence />,
};

export const Layout = () => {
  const sortControlData = useSortControl();

  const { currentSort } = sortControlData;
  const componentToRender = components[currentSort];

  if (!componentToRender) return null;

  return <>{componentToRender}</>;
};
