import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EntityField from '../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../components/EntityFieldSlotSpacer';
import { GroupCollapse } from '../../../../components/ui';
import { SelectionType } from '../../../../components/ui/ViewControlButton';
import useSortControl from '../../../../hooks/useSortControl';
import { IDesignerFilter } from '../../../../types/IDesignerFilter';
import { requestSourceFieldValues } from '../../../Designer/Ingestion/api';
import { IDesignSourceEntityField, IStreamField } from '../../../Designer/types';
import { getSearchQuery } from '../../../Search/selectors';
import { matchesSearchQuery } from '../../../Search/utils';
import { filterByValueItemClicked } from '../../actions';
import { getFilterValuesBeingUpdated } from '../../selectors';

interface Props {
  relatedSourceEntityField: IDesignSourceEntityField
  isValueGroupOpen: boolean
  handleValueGroupOpen: (id:string) => void
  relatedFilter?: IDesignerFilter
  relatedStreamField: IStreamField
}

export const ByValueGroupCollapse = ({
  relatedSourceEntityField,
  handleValueGroupOpen,
  isValueGroupOpen,
  relatedFilter,
  relatedStreamField,
}: Props) => {
  // STATE
  const [values, setValues] = React.useState<string[]>([]);
  const [isLoading, setLoading] = React.useState(false);

  // HOOKS
  const { selectionType } = useSortControl();
  const searchQuery = useSelector(getSearchQuery);
  const valuesBeingUpdated = useSelector(getFilterValuesBeingUpdated);
  const dispatch = useDispatch();

  // DERIVED STATE
  const selectedValues = relatedFilter?.values || [];

  const selectionFilteredRecords = React.useMemo(() => {
    switch (selectionType) {
      case SelectionType.all:
      {
        return values;
      }
      case SelectionType.selected: {
        const filtered = values.filter((value) => selectedValues.includes(value));
        return filtered;
      }
      case SelectionType.unselected: {
        const filtered = values.filter((value) => !selectedValues.includes(value));
        return filtered;
      }
      default:
        return values;
    }
  }, [values, selectionType, selectedValues]);

  const filteredRecords = React.useMemo(
    () => (searchQuery === ''
      ? selectionFilteredRecords
      : selectionFilteredRecords.filter((value) => matchesSearchQuery(searchQuery, value))),
    [selectionFilteredRecords, searchQuery],
  );

  // CALLBACKS
  const handleGroupClick = React.useCallback(async () => {
    if (!isValueGroupOpen && values.length === 0) {
      try {
        (setLoading(true));
        const { data } = await requestSourceFieldValues(relatedSourceEntityField.id);

        // For reasons unknown atm, the server might send a empty object in the array
        // of values, so we'll need to filter those out.
        const filteredValues = data?.values
          ?.filter((val) => ['string', 'number', 'boolean'].includes(typeof val))
          ?.map((val) => String(val));

        setValues(filteredValues);
      } catch (e) {
        console.error(e);
      } finally {
        (setLoading(false));
      }
    }

    handleValueGroupOpen(relatedSourceEntityField.id);
  }, [relatedSourceEntityField, isValueGroupOpen, values]);

  const handleValueClick = React.useCallback((value: string) => {
    dispatch(filterByValueItemClicked({
      relatedFilter,
      relatedStreamField,
      value,
    }));
  }, [relatedFilter, relatedStreamField]);

  return (
    <GroupCollapse
      isOpen={isValueGroupOpen}
      key={relatedSourceEntityField.id}
      groupName={relatedSourceEntityField.name}
      groupId={relatedSourceEntityField.id}
      onClick={handleGroupClick}
      hasLeftSlotSpacer
      isSyncing={isLoading}
      isSticky={isValueGroupOpen}
    >
      {filteredRecords.map((value) => (
        <EntityField
          isSelected={selectedValues.includes(value)}
          slot1={<EntityFieldSlotSpacer />}
          slot2={<EntityFieldSlotSpacer />}
          name={value}
          key={value}
          isSelectable
          searchQuery={searchQuery}
          onClick={() => handleValueClick(value)}
          isSyncing={valuesBeingUpdated.includes(value)}
        />
      ))}
    </GroupCollapse>
  );
};
