import moment from 'moment';
import {
  mdiEqual,
  mdiNotEqualVariant,
  mdiLessThan,
  mdiLessThanOrEqual,
  mdiGreaterThan,
  mdiGreaterThanOrEqual,
  mdiContain,
  mdiContainStart,
  mdiContainEnd,
  mdiCircleOutline,
  mdiCircleOffOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxBlankOffOutline,
  mdiArrowExpandHorizontal,
  mdiArrowSplitVertical,
  mdiPlaylistCheck,
  mdiPlaylistRemove,
} from '@mdi/js';
import { IDesignerFilter } from '../types/IDesignerFilter';
import { ComparisonTypes, IStreamFilter } from '../types/IStreamFilter';
import i18n from '../config/i18n';
import { COLUMN_DATA_TYPES } from '../constants/aggregations';
import { DataType } from '../api/model/schemas/DataType';
import * as customMdiIcons from '../assets/icons/customMdiPaths';

export const formatValueByDataType = (
  value: any,
  dataType: any // TO DO: narrow types to DataType after full switch to API V4
) => {
  if (DataType.DateTime === dataType) {
    return moment(value).format('LL');
  }

  if (value === undefined) {
    return i18n.t('filters:null');
  }

  // that is how our API behaves
  if (value === '') {
    return i18n.t('filters:blank');
  }

  return String(value);
};

export const formatValues = (
  filter: Omit<IDesignerFilter, 'aggregation' | 'id'>
) => {
  const { dataType = COLUMN_DATA_TYPES.Text } = filter?.item || {};

  if (
    [
      ComparisonTypes.IsNull,
      ComparisonTypes.IsNotNull,
      ComparisonTypes.IsBlank,
      ComparisonTypes.IsNotBlank,
    ].includes(filter?.comparison)
  ) {
    return '';
  }

  if (
    [ComparisonTypes.Between, ComparisonTypes.NotBetween].includes(
      filter.comparison
    )
  ) {
    return `${formatValueByDataType(filter.lowerBoundValue, dataType)} ${i18n.t(
      'filters:and'
    )} ${formatValueByDataType(filter.upperBoundValue, dataType)}`;
  }

  if ([ComparisonTypes.In, ComparisonTypes.NotIn].includes(filter.comparison)) {
    return filter.values
      ?.map((v) => formatValueByDataType(v, dataType))
      .join(', ');
  }

  return formatValueByDataType(filter.compareValue, dataType);
};

export const getRealComparisonType = (filter: IStreamFilter) => {
  // Backend comparison types does not handle "In" case
  // We need to derive it
  if (
    filter?.comparison === ComparisonTypes.Equal &&
    Array.isArray(filter.values)
  ) {
    return ComparisonTypes.In;
  }
  if (
    filter?.comparison === ComparisonTypes.NotEqual &&
    Array.isArray(filter.values)
  ) {
    return ComparisonTypes.NotIn;
  }

  if (
    filter?.lowerBoundValue &&
    filter?.upperBoundValue &&
    filter?.comparison === ComparisonTypes.Equal
  ) {
    return ComparisonTypes.Between;
  }

  if (
    filter?.lowerBoundValue &&
    filter?.upperBoundValue &&
    filter?.comparison === ComparisonTypes.NotEqual
  ) {
    return ComparisonTypes.NotBetween;
  }

  return filter?.comparison;
};

export const comparisonToIcon: Record<ComparisonTypes, string> = {
  [ComparisonTypes.Equal]: mdiEqual,
  [ComparisonTypes.NotEqual]: mdiNotEqualVariant,
  [ComparisonTypes.LessThan]: mdiLessThan,
  [ComparisonTypes.LessThanOrEqual]: mdiLessThanOrEqual,
  [ComparisonTypes.GreaterThan]: mdiGreaterThan,
  [ComparisonTypes.GreaterThanOrEqual]: mdiGreaterThanOrEqual,
  [ComparisonTypes.Contains]: mdiContain,
  [ComparisonTypes.StartsWith]: mdiContainStart,
  [ComparisonTypes.EndsWith]: mdiContainEnd,
  [ComparisonTypes.IsNull]: mdiCircleOutline,
  [ComparisonTypes.IsNotNull]: mdiCircleOffOutline,
  [ComparisonTypes.IsBlank]: mdiCheckboxBlankOutline,
  [ComparisonTypes.IsNotBlank]: mdiCheckboxBlankOffOutline,
  [ComparisonTypes.Between]: mdiArrowExpandHorizontal,
  [ComparisonTypes.In]: mdiPlaylistCheck,
  [ComparisonTypes.NotIn]: mdiPlaylistRemove,
  [ComparisonTypes.NotBetween]: mdiArrowSplitVertical,
  // mdi icon renderer expects svg path, and those 3 icons were
  // created by Paul just for this view
  // I will suggest contributing to the icon library, this is a temporary workaround
  [ComparisonTypes.DoesNotContain]: customMdiIcons.DoesNotContain,
  [ComparisonTypes.DoesNotEndWith]: customMdiIcons.DoesNotEndWith,
  [ComparisonTypes.DoesNotStartWith]: customMdiIcons.DoesNotStartWith,
};
