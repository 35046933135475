import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { classNames } from './styles';
import WizardPage, { WizardType } from '../WizardPage';

interface Props {
  entityName: string;
  deleteDescription: string;
  goBackPage?: string;
  handleDelete: () => void;
  isSyncing: boolean
}

const DeleteConfirmation:React.FC<Props> = ({
  entityName,
  goBackPage,
  handleDelete,
  children,
  isSyncing
}) => {
  const { t } = useTranslation('designer');
  const history = useHistory();

  const handleCancelClick = () => (goBackPage ? history.push(goBackPage) : history.go(-1));

  const buttonsConfig = [
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.accept" />,
      onClick: handleDelete,
      iconProps: { iconName: 'CheckMark' },
      'data-testid': 'approve-delete-button',
    },
    {
      tooltip: <Trans t={t} i18nKey="actionBar:general.cancelAction" />,
      onClick: handleCancelClick,
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'cancel-delete-button',
    },
  ];

  return (
    <WizardPage
      wizardType={WizardType.Delete}
      entityName={entityName}
      headerBarButtonsConfig={buttonsConfig}
      pageTitle={t('wizard:deletePageTitle')}
      isSyncing={isSyncing}
    >
      <section className={classNames.contentWrapper}>
        {children}
      </section>
    </WizardPage>
  );
};

export default DeleteConfirmation;
