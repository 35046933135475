import { CSSProperties } from 'react';

export const colorIconStyles = (color: string, size: number): CSSProperties => ({
  display: 'inline-block',
  backgroundColor: color,
  width: size,
  height: size,
  borderRadius: '100%',
  zIndex: 10,
  position: 'relative',
});
