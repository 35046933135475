import { createAction } from "@reduxjs/toolkit";
import { ModuleName, Path, SectionName } from "./model";

export interface SetMostRecentPathPayload {
  module: ModuleName;
  path: Path;
  section: SectionName;
}

const SET_MOST_RECENT_PATH = 'SECTION_ROUTE_HISTORY/SET_MOST_RECENT_PATH';
export const setMostRecentPath = createAction<SetMostRecentPathPayload, typeof SET_MOST_RECENT_PATH>(SET_MOST_RECENT_PATH);
