import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import EntityField from '../../../../../components/EntityField';
import DataSourceIcon from '../../../../../components/DataSourceIcon';
import FoldableList from '../../../../../components/ui/FoldableList';
import { IFoldableListItem } from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';

import { DefinedSortTypes } from '../../../../App/types';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { IDesignSource } from '../../../types';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import useGetParsedTypeProperties from '../../../../../hooks/useGetParsedTypeProperties';
import useEntitiesSelections from '../../../../../hooks/useEntitiesSelections';

import {
  getIsUpdating,
  getSelectedSourceId,
  getDataSources,
} from '../../selectors';
import {
  fetchDataSources,
  setSelectedSourceId,
  updateEntity,
} from '../../actions';
import EditableFoldableListItem from '../../../../../components/EditableFoldableListItem';
import useEditableItems from '../../../../../hooks/useEditableItems';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { EmptyMessage } from '../../../../../components/ui';
import { getSearchQuery } from '../../../../Search/selectors';
import { matchesSearchQuery } from '../../../../Search/utils';
import { getSchemaFromTypeProperties } from '../../../../../utils/getSchemaFromTypeProperties';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';
import DataSourceCalloutTitle from '../../../Ingestion/components/DataSourceCalloutTitle';
import { numberToHexColor } from '../../../../../components/ui/ColorPicker/utils';

const CatalogData: FunctionComponent = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [editingId, setEditingId] = useState('');

  const selectedDesignId = useSelector(getSelectedDesignId);

  const onEditEndCallback = React.useCallback(
    (hasChanged: boolean) => {
      if (hasChanged) {
        dispatch(fetchDataSources(selectedDesignId));
      }
    },
    [dispatch]
  );

  const { isEditing, onEditEnd, onEditStart } = useEditableItems({
    shouldCleanupOnUmount: true,
    onEditEndCallback,
  });

  // STATE
  const selectedSourceId = useSelector(getSelectedSourceId);

  const { records: ingestedDataSources, isLoading } =
    useSelector(getDataSources);
  const isUpdating = useSelector(getIsUpdating);

  const searchQuery = useSelector(getSearchQuery);

  // DERIVED STATE
  const filteredRecords = useMemo(
    () =>
      ingestedDataSources.filter(
        (source) =>
          searchQuery === '' || matchesSearchQuery(searchQuery, source.name)
      ),
    [ingestedDataSources, searchQuery]
  );

  const selectedEntitiesIds = React.useMemo(
    () => [selectedSourceId],
    [selectedSourceId]
  );

  const sources = useEntitiesSelections({
    entities: filteredRecords,
    selectedEntitiesIds,
  });

  const { properties } = useGetParsedTypeProperties({
    designId: selectedDesignId,
    typeId: TYPE_IDS.DataSource,
  });

  const schema = getSchemaFromTypeProperties(properties, TYPE_IDS.DataSource);

  const { mappedGroups, onClickHeaderHandler, currentSort } =
    useSortedFoldableGroups({ input: sources });

  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:catalog:sources:cta:noDataAvailable':
        mappedGroups.length === 0,
    }),
    [mappedGroups.length]
  );

  // CALLBACKS
  const onClickHandler = React.useCallback(
    (id: string) => {
      dispatch(setSelectedSourceId(selectedSourceId === id ? undefined : id));
    },
    [dispatch, selectedSourceId]
  );

  const handleRename = React.useCallback(
    async (dataSourceId: string, newName: string) => {
      await dispatch(
        updateEntity({
          entity: {
            id: dataSourceId,
            name: newName,
          },
          typeId: TYPE_IDS.DataSource,
        })
      );
      dispatch(fetchDataSources(selectedDesignId));
    },
    [dispatch]
  );

  const onEntityUpdated = React.useCallback(() => {
    dispatch(fetchDataSources(selectedDesignId));
  }, [dispatch, selectedDesignId]);

  // EFFECTS
  useEffect(() => {
    dispatch(fetchDataSources(selectedDesignId));
  }, []);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  const renderSource = (source: IDesignSource) => {
    const color = numberToHexColor(source.color);
    return (
      <EntityField
        key={source.id}
        isSelectable
        isSelected={source.id === selectedSourceId}
        info={{
          data: editingId === source.id ? null : source,
          schema,
          title: <DataSourceCalloutTitle name={source.name} color={color} />,
          enableInlineEdit: true,
          onEntityUpdated,
        }}
        onClick={() => onClickHandler(source.id)}
        slot1={<DataSourceIcon color={color} />}
        data-testid={`catalog-source-${source.name}`}
      >
        <EditableFoldableListItem
          text={source.name}
          onEdit={(newVal) => handleRename(source.id, newVal)}
          isSelected={source.id === selectedSourceId}
          onEditStart={() => {
            const isSelected = source.id === selectedSourceId;
            // for consistency mark source as selected when editing
            setEditingId(source.id);
            if (!isSelected) {
              onClickHandler(source.id);
            }
            onEditStart();
          }}
          onEditEnd={(hasChanged) => {
            setEditingId('');
            onEditEnd(hasChanged);
          }}
          transparentBackground
          disableEditing={isEditing}
          searchQuery={searchQuery}
        />
      </EntityField>
    );
  };

  // RENDER
  if (isLoading || isUpdating) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isLoading && ingestedDataSources.length === 0) {
    return (
      <EmptyMessage
        message={t('wizard:noEntityDefined', {
          entityType: t('entityTypes:Source', { count: 0 }),
        })}
      />
    );
  }

  if (currentSort === DefinedSortTypes.Name) {
    const sortedSources = [...sources].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return <>{sortedSources.map((field) => renderSource(field))}</>;
  }

  const onRenderGroupItem = (item: IFoldableListItem) => {
    const source = ingestedDataSources.find((s) => s.id === item.key);

    return source ? renderSource(source) : null;
  };

  return (
    <div>
      <FoldableList
        groups={mappedGroups}
        onClickHeader={onClickHeaderHandler}
        onRenderItem={(item) => onRenderGroupItem(item)}
      />
    </div>
  );
};

export default CatalogData;
