import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import {
  getCustomTheme,
  customStyles,
  IndicatorSeparator,
  DropdownIndicator,
  ControlComponent,
  LoadingIndicator,
  Option,
  MenuListVirtualized,
  // SingleValue,
} from './customization';

type Props = Partial<React.ComponentProps<typeof CreatableSelect>>;

// eslint-disable-next-line react/display-name
const CreatableDropdownVirtualized = React.forwardRef<Props['ref'], Props>(
  (props, ref) => {
    // HOOKS
    const { t } = useTranslation();

    // RENDER
    return (
      <CreatableSelect
        ref={ref}
        // to allow virtual list working
        captureMenuScroll={false}
        components={{
          Control: ControlComponent,
          IndicatorSeparator,
          DropdownIndicator,
          LoadingIndicator,
          Option,
          MenuList: MenuListVirtualized,
        }}
        theme={getCustomTheme}
        styles={customStyles}
        menuPlacement="auto"
        placeholder={t('filters:Select')}
        maxMenuHeight={200}
        {...props}
      />
    );
  }
);

export default CreatableDropdownVirtualized;
