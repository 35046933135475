import { NormalProps } from '../OutputCard/OutputCard';

export enum ShareLinkDataType {
  Image = 'image/png',
  Plain = 'text/plain',
  Rich = 'text/html',
}

export enum ShareSolution {
  Eyko = 'Eyko',
  MicrosoftOutlook = 'MicrosoftOutlook',
  MicrosoftPowerPoint = 'MicrosoftPowerPoint',
  MicrosoftTeams = 'MicrosoftTeams',
  MicrosoftWord = 'MicrosoftWord',
  Slack = 'Slack',
  Zoom = 'Zoom',
}

export interface ShareSolutionConfig {
  color: string;
  logo: React.ElementType;
  options: ShareLinkDataType[];
}

export type OnShareCallback = (
  previews: NormalProps['selectionPreviewsRef']['current']
) => Promise<void>;
