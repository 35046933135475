import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import ProcessStreamWizard from '../../../../pageTemplates/ProcessStreamWizard';
import { getIsProcessingStream } from '../../../Builder/selectors';
import streamSelectors from '../../../Streams/selectors';

const IndexPage = () => (
  <Switch>
    <Route path={routes.process.streamFromBuilder}>
      <ProcessStreamWizard
        goBackPage={routes.builder.streams.index}
        selectorForLoading={getIsProcessingStream}
      />
    </Route>
    <Route path={routes.process.streamFromStreamer}>
      <ProcessStreamWizard
        goBackPage={routes.favorites.index}
        selectorForLoading={streamSelectors.getIsProcessingWizardInProgress}
      />
    </Route>
  </Switch>
);

export default IndexPage;
