/**
 * Returns the range address for the table that matches the given id.
 */

export const getTableAddress = (tableId: string) => Excel.run(async (ctx) => {
  const table = ctx.workbook.tables.getItem(tableId);
  const range = table.getRange().load('address');
  await ctx.sync();
  return range.address;
});
