export const isOfficeEnabled = () => process.env.WITHOUT_OFFICE === 'true' ? false : true;

export const getIsLocalDevBuild = () => process.env.NODE_ENV === 'development';

export const getBuildVersion = () => {
  const buildVersion = process.env.GIT_COMMIT_HASH;

  if (!buildVersion) return null;

  return buildVersion.substr(0, 7);
};
