import { actionCreators } from '../../modules/Streams/Streams';
import { bindThunkActionCreators } from './utils';

export const handleError = (e) => {
  const {
    setSyncing,
    setAutoLoading,
  } = bindThunkActionCreators(actionCreators);
  setSyncing(false);
  setAutoLoading(false);
  console.error(e);
};
