import React, { useState } from 'react';
import axios, { CancelToken } from 'axios';
import { ISpinnerStyles, Label, Spinner, Stack } from 'office-ui-fabric-react';
import { useFormContext } from 'react-hook-form';
import { IControlledField } from '../../../../types';
import { SelectDropdown } from '../../../../../../../components/ui';
import { XERO_WATCHED_FIELD_NAMES } from '../constants';
import {
  asyncDebounce,
  AsyncDebounceResult,
} from '../../../../../../../../shared/utils/async-debounce';
import {
  fetchXeroTenants,
  XeroTenant,
} from '../../../../../../../api/client/system';
import { Option } from '../models';

const SPINNER_STYLE: ISpinnerStyles = {
  root: {
    // [KM]: why does fluent generate the classes in such a bad way that even an orthodox software engineer like me
    // stoops so low as to use `!important`...
    marginLeft: '8px !important',
  },
};

export const XeroTenantDropdown: React.FC<IControlledField> = ({
  controller: { name, onChange, value },
  label,
}) => {
  // HOOKS
  const { getValues, watch } = useFormContext();

  // STATE
  const { accessToken, host } = watch(XERO_WATCHED_FIELD_NAMES);
  const [isPending, setIsPending] = useState(false);
  const [xeroTenants, setXeroTenants] = useState<XeroTenant[]>([]);

  // DERIVED STATE
  const options = React.useMemo(
    () =>
      xeroTenants.map<Option>((tenant) => ({
        label: tenant.tenantName,
        value: tenant.tenantId,
      })),
    [xeroTenants]
  );
  const selectedOption = React.useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  // CALLBACKS
  const onOptionChange = React.useCallback(
    (option: Option) => onChange(option.value),
    [onChange]
  );

  // EFFECTS
  // NOTE: not an ideal solution to have this loaded in a side-effect but since this effect depends on other inputs
  // in the form, and I don't believe there are interactions complex enough to handle this case, this is the best
  // I came up with – debouncing the fetch and cancelling in case of new changes appearing (user-typed input)
  const loadTenants = React.useCallback(
    async (
      fetchXeroTenantsDebounced: AsyncDebounceResult<typeof fetchXeroTenants>,
      cancelToken: CancelToken
    ) => {
      if (!accessToken || !host) return;

      try {
        setIsPending(true);

        const { data: xeroTenantsData } = await fetchXeroTenantsDebounced({
          accessToken,
          cancelToken,
          host,
        });

        setXeroTenants(xeroTenantsData);

        // set a default selection after updating the lsit if there was nothing selected already
        // or the previously selected value is no longer available
        // NOTE: using the `getValues` method to avoid placing the value on the deps list which would
        // reset this callback definition, and in turn reset the effect
        const currentValue = getValues(name);
        const shouldResetSelection =
          !currentValue ||
          xeroTenantsData.every((tenant) => tenant.tenantId !== currentValue);
        if (shouldResetSelection) onChange(xeroTenantsData[0]?.tenantId);
      } catch (error) {
        console.error(error);
      } finally {
        setIsPending(false);
      }
    },
    [accessToken, getValues, host, name, onChange]
  );
  React.useEffect(() => {
    // NOTE: debounced because the `loadTenants` method depends on `host` that changes on user input
    // and defined here and not in the callback so the debounce can be cancelled properly whenever necessary
    const fetchXeroTenantsDebounced = asyncDebounce(fetchXeroTenants, 500);
    const source = axios.CancelToken.source();

    loadTenants(fetchXeroTenantsDebounced, source.token);

    return () => {
      fetchXeroTenantsDebounced.cancel();
      source.cancel();
    };
  }, [loadTenants]);

  // PARTS
  const spinner = isPending && <Spinner styles={SPINNER_STYLE} />;

  // RENDER
  return (
    <Stack>
      <Stack horizontal>
        <Label>{label}</Label>
        {spinner}
      </Stack>
      <SelectDropdown
        {...{ options }}
        isDisabled={isPending}
        onChange={onOptionChange}
        value={selectedOption}
      />
    </Stack>
  );
};
