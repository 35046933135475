import React, { useCallback, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { CreatableDropdownVirtualized as DropdownBase } from '../../../../../components/ui';

type Props = Partial<React.ComponentProps<typeof CreatableSelect>>;
// eslint-disable-next-line react/display-name
export const GroupPickerCreatableDropdown = React.forwardRef<
  Props['ref'],
  Props
>((props, ref) => {
  const [inputValue, setInputValue] = useState('');

  const onInputChange: Props['onInputChange'] = useCallback(
    (value, meta) => {
      if (props.isMulti && meta.action === 'set-value') {
        return;
      }
      setInputValue(value);
    },
    [props.isMulti]
  );

  return <DropdownBase {...props} {...{ ref, inputValue, onInputChange }} />;
});
