import React, { useCallback, useEffect, useMemo } from 'react';
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'office-ui-fabric-react';
import cn from 'classnames';
import DataSourceDropdown from '../../../../../components/DataSourceDropdown/DataSourceDropdown';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import TypePicker from './TypePicker';
import { entitiesToOptions, sourceEntitiesToOptions } from './utils';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import {
  IDesignSource,
  IDesignSourceEntity,
  IDesignSourceEntityField,
} from '../../../types';
import { IEntityOption } from './types';
import { classNames, entityDropdownStyles } from './styles';
import {
  SelectDropdownVirtualList,
  TextField,
} from '../../../../../components/ui';
import { fetchTypedEntities } from '../../actions';
import {
  getTypedEntities,
  getIsTypedEntitiesFetchTaskRunning,
} from '../../selectors';
import { generateEntitiesWithFields } from '../LinkPicker/utils';
import { RecordCount } from './RecordCount';
import { DataType } from '../../../../../api/model/schemas/DataType';

interface Props {
  entityId: string;
  sourceId: string;
  typeId: string;
  sources: IDesignSource[];
  // When the 'entity id' is a LiteralValue, it can be a number or string
  // depending on the interaction allowed dataType
  onEntityChange: (id: string | number) => void;
  onSourceChange: (id: string) => void;
  onTypeChange: (id: string) => void;
  typesIds: string[];
  startsLoading?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  dataType?: DataType;
  parentTypeId?: string;
  excluded?: string[];
  allowedDataTypes: DataType[];
  label?: string;
  formTypeId?: string;
  formEntityId?: string;
  referenceTypeFilterProperty?: string;
  referenceTypeFilterValue?: string;
}

const SelectOneOfType: React.FC<Props> = ({
  entityId,
  sourceId,
  typeId,
  typesIds,
  sources,
  onSourceChange,
  onTypeChange,
  onEntityChange,
  isDisabled,
  isRequired,
  dataType,
  parentTypeId,
  excluded = [],
  allowedDataTypes,
  label,
  formTypeId,
  formEntityId,
  referenceTypeFilterProperty,
  referenceTypeFilterValue,
}) => {
  const { t } = useTranslation();
  const designId = useSelector(getSelectedDesignId);
  const isEntityField = typeId === TYPE_IDS.SourceEntityField;
  const isEntity = typeId === TYPE_IDS.SourceEntity;
  const isLiteralValue = typeId === TYPE_IDS.LiteralValue;
  const isFetchingEntities = useSelector(
    getIsTypedEntitiesFetchTaskRunning(typeId, sourceId)
  );
  const currentEntities = useSelector(getTypedEntities);
  const dispatch = useDispatch();

  // DERIVED STATE

  const options: IEntityOption[] = useMemo(() => {
    if (isEntityField) {
      if (!sourceId) {
        return [];
      }
      const entities = generateEntitiesWithFields(
        currentEntities.filter(
          (entity) =>
            entity.$typeId === TYPE_IDS.SourceEntity &&
            entity.parentId === sourceId
        ) as IDesignSourceEntity[],

        currentEntities.filter(
          (entity) => entity.$typeId === TYPE_IDS.SourceEntityField
        ) as IDesignSourceEntityField[]
      );

      return sourceEntitiesToOptions({
        entities,
        dataType,
        parentTypeId,
        allowedDataTypes,
        referenceTypeFilterProperty,
        referenceTypeFilterValue,
      });
    }

    const entitiesForOptions = currentEntities.filter((entity) => {
      const isSameType = entity.$typeId === typeId;
      // NOTE: in case of selecting group assets for groups, we need to filter out the group we're editing from the list of available groups
      if (formTypeId === TYPE_IDS.Group) {
        return isSameType && entity.parentId !== formEntityId;
      }
      if (isEntity) {
        return isSameType && entity.parentId === sourceId;
      }
      return isSameType;
    });

    return entitiesToOptions({
      entities: entitiesForOptions,
      dataType,
      parentTypeId,
      allowedDataTypes,
      referenceTypeFilterProperty,
      referenceTypeFilterValue,
    });
  }, [
    currentEntities,
    typeId,
    sourceId,
    isEntityField,
    dataType,
    formTypeId,
    formEntityId,
    referenceTypeFilterProperty,
    referenceTypeFilterValue,
    isEntity,
  ]);

  const noResults = options.length === 0 && !isFetchingEntities;

  const currentOption = useMemo(
    () => options.find((o) => o.value === entityId),
    [options, entityId]
  );

  const isNumber = useMemo(
    () =>
      allowedDataTypes?.includes(DataType.NumericDecimal) ||
      allowedDataTypes?.includes(DataType.NumericInteger),
    [allowedDataTypes]
  );

  const handleEntityChange = useCallback(
    (item: IEntityOption) => onEntityChange(item.value),
    [onEntityChange, entityId]
  );

  const handleDataSourceChange = useCallback(
    (id: string) => {
      onEntityChange(null);
      onSourceChange(id);
    },
    [onEntityChange, onSourceChange]
  );

  const handleTypeChange = useCallback(
    (id: string) => {
      onEntityChange(null);
      onTypeChange(id);
    },
    [onEntityChange, onTypeChange]
  );

  useEffect(() => {
    if (
      isFetchingEntities ||
      options.length ||
      (isEntityField && !sourceId) ||
      (isEntity && !sourceId) ||
      isLiteralValue
    ) {
      return;
    }
    dispatch(
      fetchTypedEntities({
        typeId,
        designId,
        sourceId,
        // TO speed up fetching fields in Hubs,Groups,Calcs
        selectedFieldsOnly: true,
      })
    );
  }, [
    typeId,
    sourceId,
    isFetchingEntities,
    options.length,
    isEntityField,
    isEntity,
    isLiteralValue,
  ]);

  const filteredOptions = useMemo(
    () => options.filter((option) => !excluded.includes(option.value)),
    [excluded, options]
  );

  return (
    <Stack>
      <Label required={isRequired}>{label}</Label>
      <Stack className={classNames.wrap} horizontal verticalAlign="stretch">
        <TypePicker
          onChange={handleTypeChange}
          typesIds={typesIds}
          selectedTypeId={typeId}
          isDisabled={isDisabled}
        />
        {(isEntityField || isEntity) && (
          <DataSourceDropdown
            activeDataSourceId={sourceId}
            onChangeDataSource={handleDataSourceChange}
            dataSources={sources}
            isDisabled={isDisabled}
          />
        )}
        {isLiteralValue ? (
          <TextField
            defaultValue={entityId}
            onChange={(_, val) => onEntityChange(isNumber ? +val : val)}
            className={classNames.literalInput}
            type={isNumber ? 'number' : 'text'}
          />
        ) : (
          <SelectDropdownVirtualList
            key={entityId}
            options={filteredOptions}
            isDisabled={isFetchingEntities || !options.length || isDisabled}
            placeholder={
              noResults ? t(`filters:noEntries`) : t(`filters:Select`)
            }
            onChange={handleEntityChange}
            value={currentOption}
            styles={entityDropdownStyles}
            className={cn('select-one-of-type-dropdown', {
              'dropdown-disabled': isDisabled || true,
            })}
          />
        )}
        {isFetchingEntities && (
          <Spinner
            className={classNames.spinner}
            size={SpinnerSize.small}
            data-testid="select-one-of-type-spinner"
          />
        )}
      </Stack>
      {currentOption?.value && (
        <RecordCount typeId={typeId} entityId={currentOption?.value} />
      )}
    </Stack>
  );
};

export default SelectOneOfType;
