import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from '../../../../constants/routes';

import Welcome from '../Welcome';

const Settings = () => (
  <Switch>
    <Route path={routes.onboarding.index} component={Welcome} />
  </Switch>
);

export default Settings;
