import * as effects from 'redux-saga/effects';

import * as excelActions from '../../../actions/excel-actions';
import streamsSelectors from '../../Streams/selectors';
import { IColumnDataType, IColumnAggregationMethods } from '../../../types/IColumn';
import { MoveItemPayload, SectionType, SingleItem } from '../types';
import { SECTION_TYPES } from '../constants';
import { generateColumnUuid } from '../../ColumnsSequencing/utils';
import { getSectionByType } from '../selectors';
import { moveItem, setItems } from '../actions';
import { removeAggregationColumn } from '../../Streams/sagas/columnAggregation';
import { isColumnNumeric } from '../../Streams/components/StreamerColumn/utils';

export type UpdatePayload = {
  item: SingleItem,
  source: MoveItemPayload,
  destination: MoveItemPayload,
};

type GetNewAggregationPayload = {
  originalSectionType: SectionType,
  destinationSectionType: SectionType,
  columnDataType: IColumnDataType
};

const getNewAggregation = ({
  originalSectionType,
  destinationSectionType,
  columnDataType,
}:GetNewAggregationPayload):IColumnAggregationMethods => {
  if (
    originalSectionType === SECTION_TYPES.values
    && (destinationSectionType === SECTION_TYPES.rows
      || destinationSectionType === SECTION_TYPES.columns)
  ) {
    // this means we are setting aggregation to
    // "Do not summarize"
    return null;
  }

  return isColumnNumeric({ dataType: columnDataType }) ? 'Sum' : 'Count';
};

export function* updateAssociatedColumnToDefaultAggregation({
  item: originalItem, source, destination,
}: UpdatePayload) {
  const associatedColumn = streamsSelectors.getCurrentDatasetColumnByColumnUuid(originalItem.columnUuid)(yield effects.select());

  const originalSectionType = source.droppableId;
  const destinationSectionType = destination.droppableId;

  const currentDataset = streamsSelectors.getCurrentDataset(
    yield effects.select(),
  );

  if ((originalSectionType === SECTION_TYPES.columns && destinationSectionType === SECTION_TYPES.rows)
  || (originalSectionType === SECTION_TYPES.rows && destinationSectionType === SECTION_TYPES.columns)) {
    return {
      item: originalItem,
      wasUpdated: false,
      shouldBreakMoving: false,
    };
  }

  const newAggregation = getNewAggregation({
    originalSectionType,
    destinationSectionType,
    columnDataType: associatedColumn.dataType,
  });

  if (associatedColumn.aggregation === newAggregation) {
    return {
      item: originalItem,
      wasUpdated: false,
      shouldBreakMoving: false,
    };
  }

  const updatedItemColumnUuid = generateColumnUuid({
    ...associatedColumn,
    aggregation: newAggregation,
  });

  const state = yield effects.select();

  const isThereAnExactItemAlreadyInTheDesign = Object.values(SECTION_TYPES)
    .filter((section) => section !== originalSectionType)
    .map((section) => getSectionByType(section, currentDataset.id)(state))
    .some((sectionItems) => sectionItems.some((currentItem) => currentItem.columnUuid === updatedItemColumnUuid));

  if (isThereAnExactItemAlreadyInTheDesign) {
    return {
      item: originalItem,
      wasUpdated: false,
      shouldBreakMoving: true,
    };
  }

  yield effects.call(excelActions.updateDataset, {
    datasetId: currentDataset.id,
    columns: [{ ...associatedColumn, aggregation: newAggregation }],
  });

  const updatedItem:SingleItem = {
    ...originalItem,
    columnUuid: updatedItemColumnUuid,
  };

  return {
    item: updatedItem,
    wasUpdated: true,
    shouldBreakMoving: false,
  };
}

export function* handleMoveItem({
  payload: { source, destination },
}: ReturnType<typeof moveItem>) {
  const { id: datasetId } = streamsSelectors.getCurrentDataset(
    yield effects.select(),
  );

  const sourceClone = Array.from(
    getSectionByType(source.droppableId, datasetId)(yield effects.select()),
  );
  const destClone = Array.from(
    getSectionByType(destination.droppableId, datasetId)(yield effects.select()),
  );

  const [removed] = sourceClone.splice(source.index, 1);

  const { item: updatedItem, wasUpdated, shouldBreakMoving } = yield effects.call(updateAssociatedColumnToDefaultAggregation, {
    destination,
    source,
    item: removed,
  });

  if (shouldBreakMoving) return;

  destClone.splice(destination.index, 0, updatedItem);

  const setSourceAction = setItems({
    sectionType: source.droppableId,
    items: sourceClone,
    datasetId,
  });

  const setDestinationAction = setItems({
    sectionType: destination.droppableId,
    items: destClone,
    datasetId,
  });

  yield effects.put(setSourceAction);

  yield effects.put(setDestinationAction);

  if (wasUpdated) {
    // because GroupTableDesign holds links to the dataset.columns
    // we need to first add new column with aggregation, change the link, and remove surplus
    const associatedColumn = streamsSelectors.getCurrentDatasetColumnByColumnUuid(removed.columnUuid)(yield effects.select());

    yield effects.call(
      removeAggregationColumn,
      associatedColumn,
      datasetId,
      associatedColumn.aggregation,
    );
  }
}

export function* rootSaga() {
  yield effects.all([effects.takeLatest(moveItem.type, handleMoveItem)]);
}

export default rootSaga;
