import * as effects from 'redux-saga/effects';
import {
  NotificationActivity,
  NotificationSubjectState,
} from '../../../types/INotification';
import { appendNotificationIntent } from '../../Notifications/actions';
import { setConfiguringDataSource } from '../Ingestion/actions';
import {
  configureMachineLearningIntent,
  configureSmartGroupsJobIntent,
} from './actions';
import { configureMachineLearning, configureSmartGroup } from './api';

function* handleConfigureSmartGroup({
  payload: { entityId, onQueued },
}: ReturnType<typeof configureSmartGroupsJobIntent>) {
  try {
    yield effects.put(setConfiguringDataSource(true));
    yield effects.call(configureSmartGroup, entityId);
    while (true) {
      const {
        payload: notification,
      }: ReturnType<typeof appendNotificationIntent> = yield effects.take(
        appendNotificationIntent
      );

      if (
        notification.subjectId === entityId &&
        notification.activity === NotificationActivity.Configure &&
        [
          NotificationSubjectState.Start,
          NotificationSubjectState.InProgress,
          NotificationSubjectState.Failed,
        ].includes(notification.state)
      ) {
        yield effects.call(onQueued);
        break;
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield effects.put(setConfiguringDataSource(false));
  }
}
function* handleConfigureMLAsset({
  payload: { entityId, onQueued, processType },
}: ReturnType<typeof configureMachineLearningIntent>) {
  try {
    yield effects.put(setConfiguringDataSource(true));
    yield effects.call(configureMachineLearning, entityId, processType);
    while (true) {
      const {
        payload: notification,
      }: ReturnType<typeof appendNotificationIntent> = yield effects.take(
        appendNotificationIntent
      );

      if (
        notification.subjectId === entityId &&
        notification.activity === NotificationActivity.Process
      ) {
        yield effects.call(onQueued);
        break;
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield effects.put(setConfiguringDataSource(false));
  }
}

export default function* rootSaga() {
  yield effects.all([
    yield effects.takeEvery(
      configureSmartGroupsJobIntent.type,
      handleConfigureSmartGroup
    ),
    yield effects.takeEvery(
      configureMachineLearningIntent.type,
      handleConfigureMLAsset
    ),
  ]);
}
