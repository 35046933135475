import {
  createNestedEntity,
  fetchNestedEntities,
  patchEntity,
  fetchEntities,
} from '../api';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';

import { IDesignTag, ApiV4ResponseWrapper, IType } from '../types';

export const fetchTags = fetchNestedEntities<
ApiV4ResponseWrapper<IDesignTag[]>
>({
  typeId: TYPE_IDS.Tag,
});

export const fetchTypes = fetchEntities<ApiV4ResponseWrapper<IType[]>>(
  TYPE_IDS.Type,
);

type CreateEditTagParams = {
  designId: string;
  tagData: Partial<IDesignTag>;
};
export const createTag = ({ designId, tagData }: CreateEditTagParams) => createNestedEntity<IDesignTag>({
  typeId: TYPE_IDS.Tag,
  parentEntityId: designId,
})(tagData);

export const editTag = (updatedTag: Partial<IDesignTag>) => patchEntity<IDesignTag>(TYPE_IDS.Tag)(updatedTag);
