import { Icon } from '@fluentui/react';
import React from 'react';
import { DataSourceEntityField } from '../../../../../api/model/schemas/DataSourceEntityField';
import { DataType } from '../../../../../api/model/schemas/DataType';
import ButtonMenu from '../../../../../components/ui/ButtonMenu';
import { baseColors } from '../../../../../theme';
import { EntityContext } from './EntityContext';
import { getAvailableDataTypes, getIsDataTypeOrange } from './utils';

interface Props {
  field: DataSourceEntityField;
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataTypeDropdown = ({ field, setUpdating }: Props) => {
  const { patchEntityField } = React.useContext(EntityContext);
  const handleChange = async (newDataType: DataType) => {
    setUpdating(true);
    await patchEntityField({
      fieldId: field.id,
      updatedValues: {
        dataType: newDataType,
      },
    });
    setUpdating(false);
  };

  if (
    field.sourceDataType === DataType.Unknown ||
    field.dataType === DataType.Unknown
  ) {
    return <Icon iconName="Help" style={{ color: baseColors.red }} />;
  }

  return (
    <ButtonMenu
      items={getAvailableDataTypes(field.sourceDataType)}
      onChangeItem={handleChange}
      activeItemKey={field.dataType}
      isOrange={getIsDataTypeOrange(field)}
    />
  );
};

export default DataTypeDropdown;
