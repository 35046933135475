import { IButtonStyles, ITheme } from '@fluentui/react';
import { baseColors } from '../../../../theme';

const BASE_STYLES = {
  width: 32,
  height: 32,
  padding: 4,
};

export const btnStyles = (_theme: ITheme, isActive = false) : IButtonStyles => ({
  root: {
    ...BASE_STYLES,
    backgroundColor: isActive ? baseColors.white : 'transparent',
  },
  rootDisabled: {
    backgroundColor: isActive ? baseColors.white : 'auto',
  },
  icon: {
    color: baseColors.iconColor,
  },
  iconDisabled: {
    color: baseColors.iconColor,
  },
  flexContainer: {
    position: 'relative',
  },
});

export const btnStylesWhenHasErrors = (_theme: ITheme, isActive = false) : IButtonStyles => ({
  root: {
    ...BASE_STYLES,
    backgroundColor: isActive ? baseColors.white : 'transparent',
  },
  rootDisabled: {
    backgroundColor: isActive ? baseColors.white : 'auto',
  },
  icon: {
    color: baseColors.red,
  },
  iconDisabled: {
    color: baseColors.red,
  },
});
