import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationState } from '../../store/types';
import { INotification } from '../../types/INotification';
import { IUser } from '../../types/IUser';
import { getCurrentUserData } from '../User/selectors';
import * as api from './api';

import { RemoveErrorByDatePayload, UIEnhancedNotification } from './types';

export const clearErrors = createAction('CLEAR_ERRORS');
export const removeErrorByDate = createAction<
  RemoveErrorByDatePayload,
  'DISMISS_ERROR'
>('DISMISS_ERROR');

export const dismissNotification = createAction<
  string,
  'NOTIFICATIONS/DISMISS_SINGLE'
>('NOTIFICATIONS/DISMISS_SINGLE');
export const removeNotificationFromRecords = createAction<
  string,
  'NOTIFICATIONS/REMOVE_SINGLE_FROM_LIST'
>('NOTIFICATIONS/REMOVE_SINGLE_FROM_LIST');
export const appendNotification = createAction<
  {
    notification: UIEnhancedNotification;
    updatedUserData?: Record<string, IUser>;
  },
  'APPEND_NOTIFICATION'
>('APPEND_NOTIFICATION');
export const appendNotificationIntent = createAction<
  INotification,
  'APPEND_NOTIFICATION_INTENT'
>('APPEND_NOTIFICATION_INTENT');
export const broadcastNotificationReceived = createAction<
  INotification,
  'BROADCAST_NOTIFICATION_RECEIVED'
>('BROADCAST_NOTIFICATION_RECEIVED');

export const fetchNotificationsIntent = createAction(
  'NOTIFICATIONS/INITIAL_FETCH_INTENT'
);
export const setNotifications = createAction<
  {
    records: UIEnhancedNotification[];
    count: number;
    usersById: Record<string, IUser>;
  },
  'NOTIFICATIONS/SET_RECORDS'
>('NOTIFICATIONS/SET_RECORDS');

export const dismissAllNotifications = createAsyncThunk(
  'NOTIFICATIONS/DISMISS_ALL',
  async (_, { getState }) => {
    const state = getState() as ApplicationState;
    const currentUser = getCurrentUserData(state);

    await api.dismissAllNotifications(currentUser.id);
  }
);
