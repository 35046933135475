import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { getSearchQuery } from '../../../Search/selectors';
import streamSelectors from '../../selectors';

import StreamerColumn from '../../components/StreamerColumn';
import { sortColumnsAlphabetically } from '../../components/StreamerColumn/utils';
import useEntitiesSelections from '../../../../hooks/useEntitiesSelections';
import useSortedFoldableGroups from '../../../../hooks/useSortedFoldableGroups';
import { DefinedSortTypes } from '../../../App/types';
import DataModeGroupCollapse from '../../components/DataModeGroupCollapse/DataModeGroupCollapse';
import useGetParsedTypeProperties from '../../../../hooks/useGetParsedTypeProperties';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { getSchemaFromTypeProperties } from '../../../../utils/getSchemaFromTypeProperties';
import { filterColumns } from '../ValuesList/utils';
import { EmptyMessage } from '../../../../components/ui';

const DataMode: FunctionComponent = () => {
  const { t } = useTranslation();
  const searchQuery = useSelector(getSearchQuery);
  const availableColumns = useSelector(streamSelectors.getAvailableColumns);
  const selectedEntitiesIds = useSelector(
    streamSelectors.getCurrentDatasetSelectedAssetIds
  );
  const stream = useSelector(streamSelectors.getCurrentStream);

  const filteredColumns = useMemo(
    () =>
      sortColumnsAlphabetically(filterColumns(availableColumns, searchQuery)),
    [availableColumns, searchQuery]
  );

  const columnsWhichIncludesSelections = useEntitiesSelections({
    entities: filteredColumns,
    selectedEntitiesIds,
  });

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: columnsWhichIncludesSelections,
    });

  const sourceEntityFieldTypeProperties = useGetParsedTypeProperties({
    designId: stream?.parentId,
    typeId: TYPE_IDS.StreamField,
  });

  const schema = getSchemaFromTypeProperties(
    sourceEntityFieldTypeProperties?.properties,
    TYPE_IDS.StreamField
  );

  const renderFoldableList = () =>
    mappedGroups.map((group) => (
      <DataModeGroupCollapse
        searchQuery={searchQuery}
        key={group.key}
        group={group}
        onIconClick={onClickHeaderHandler}
      >
        {group.items.map((item) => {
          const relatedColumn = availableColumns.find(
            (column) => column.id === item.key
          );

          return relatedColumn ? (
            <StreamerColumn
              column={relatedColumn}
              key={relatedColumn.id}
              schema={schema}
            />
          ) : null;
        })}
      </DataModeGroupCollapse>
    ));

  if (availableColumns.length === 0) {
    return <EmptyMessage message={t('streams:noColumnsInStream')} />;
  }

  return (
    <>
      {currentSort !== DefinedSortTypes.Name ? (
        renderFoldableList()
      ) : (
        <>
          {columnsWhichIncludesSelections.map((c) => (
            <StreamerColumn column={c} key={c.id} schema={schema} />
          ))}
        </>
      )}
    </>
  );
};

export default DataMode;
