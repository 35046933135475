import React, { useMemo } from 'react';
import { FontIcon } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { StreamAssetRaw, StreamerFilter } from '../../../../../../types';
import FormattedValue from '../FormattedValue';
import { classNames } from './styles';
import { ValueType } from '../FormattedValue/FormattedValue';
import { generateTotals, isAssetInFilters, isNumberFormat } from './utils';
import { getPreviouslyFetchedAssets } from '../../../../../../selectors';
import FormattedEntryKey from '../FormattedEntryKey/FormattedEntryKey';
import { CARD_KEY_PROP, getAreTableTotalsEnabled } from '../../../../selectors';

interface Props {
  assets: StreamAssetRaw[];
  filters: StreamerFilter[];
  data: { [key: string]: any }[];
}

/**
 * This component has not implemented virtualization nor infinite-scrolling, the component
 * For new implementations use OutputTable component
 * @deprecated
 */

const SwimlanesTable: React.FC<Props> = ({
  assets,
  filters,
  data: rawData,
}) => {
  const data = useMemo(
    () => rawData.map(({ [CARD_KEY_PROP]: _key, ...entry }) => entry),
    [rawData]
  );
  const areTableTotalsEnabled = useSelector(getAreTableTotalsEnabled);

  const fetched = useSelector(getPreviouslyFetchedAssets);

  const totals = useMemo(
    () =>
      generateTotals({
        values: data.map((entry) => Object.values(entry)),
        assets,
        aggregations: fetched.map((asset) => asset?.aggregation),
      }),
    [assets, fetched, data]
  );

  if (!data?.length) {
    return null;
  }

  return (
    <table className={classNames.table}>
      <thead>
        <tr>
          {Object.keys(data[0]).map((key, i) => (
            <th key={key}>
              {isAssetInFilters(assets[i], filters) && (
                <FontIcon iconName="Filter" className={classNames.filterIcon} />
              )}
              <FormattedEntryKey
                entryKey={key}
                iconClassName={classNames.tableHeaderAggIcon}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((entry, e) => (
          <tr key={e}>
            {Object.values(entry).map((value, i) => (
              <td
                key={i}
                className={
                  isNumberFormat(assets[i]) ? classNames.alignRight : undefined
                }
              >
                <FormattedValue
                  asset={assets[i]}
                  value={value}
                  valueType={ValueType.TableValue}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      {areTableTotalsEnabled && (
        <tfoot>
          <tr>
            {totals.map((value, i) => (
              <td
                key={i}
                className={
                  isNumberFormat(assets[i]) ? classNames.alignRight : undefined
                }
              >
                <FormattedValue
                  asset={assets[i]}
                  value={value}
                  valueType={ValueType.TableValue}
                />
              </td>
            ))}
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default SwimlanesTable;
