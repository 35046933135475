import { uniqBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DataSourceIcon from '../../../../components/DataSourceIcon';
import EntityField from '../../../../components/EntityField';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { baseColors } from '../../../../theme';

import streamSelectors from '../../selectors';

type LegendItem = { sourceName: string; sourceColor: string };

export const TableLegend = () => {
  const {t} = useTranslation()
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  // DERIVED STATE
  const selectedColumns = currentDataset?.columns ?? [];
  const isAnyNonFieldItemSelected = selectedColumns?.some(
    (column) => column.streamElementTypeId !== TYPE_IDS.StreamField
  );
  const fieldSourcesColorMap: LegendItem[] = React.useMemo(() => {
    const result: LegendItem[] = [];

    if (isAnyNonFieldItemSelected) {
      result.push({
        sourceName: t('tableLegend:eykoAsset'),
        sourceColor: baseColors.midOrange,
      });
    }

    const fieldSourcesColors = uniqBy(
      selectedColumns
        .filter((column) => column.streamElementTypeId === TYPE_IDS.StreamField)
        .map((column) => ({
          sourceName: column?.dataSourceName,
          sourceColor: column?.dataSourceColor,
        })),
      'sourceName'
    );

    return [...result, ...fieldSourcesColors].sort((a, b) =>
      a.sourceName.localeCompare(b.sourceName)
    );
  }, [isAnyNonFieldItemSelected, selectedColumns]);
  return (
    <>
      {fieldSourcesColorMap.map(({ sourceColor, sourceName }) => (
        <EntityField
          slot1={<DataSourceIcon color={sourceColor} />}
          name={sourceName}
        />
      ))}
    </>
  );
};
