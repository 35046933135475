export enum UserPreferenceIds {
  PAGE_SIZE = 'PAGE_SIZE',
  LANGUAGE = 'LANGUAGE',
  ONBOARDING = 'ONBOARDING',
  TOOLTIPS = 'TOOLTIPS',
}

export enum ToggleValues {
  On = 'On',
  Off = 'Off',
}

export interface IClientPreference {
  id: UserPreferenceIds;
  value: string;
}
