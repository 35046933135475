/**
 * @param callback Callback function to execute when the onSelectionChanged event is fired
 */

export const listenWorkbookSelectionChange = (callback : () => void) => {
  let eventResult : OfficeExtension.EventHandlerResult<Excel.SelectionChangedEventArgs>;

  const add = () => Excel.run(async (ctx) => {
    try {
      eventResult = ctx.workbook.onSelectionChanged.add(
        () => new Promise((resolve) => {
          resolve(callback());
        }),
      );
    } catch (e) {
      console.error(e);
    }
  });

  const cleanup = () => Excel.run(eventResult.context, async () => {
    try {
      eventResult.remove();
      eventResult = null;
    } catch (e) {
      console.error(e);
    }
  });

  return { add, cleanup };
};

export default listenWorkbookSelectionChange;