import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as SignalR from '@microsoft/signalr';

import * as signalRChannels from '../../../../constants/signalR';
import { INotification } from '../../../../types/INotification';
import { getConfiguration } from '../../api';

import {
  appendNotificationIntent,
  broadcastNotificationReceived,
  fetchNotificationsIntent,
} from '../../actions';
import { acquireTokenSilent } from '../../../Auth-v2';

const NotificationsController = () => {
  const dispatch = useDispatch();

  const handleReceiveMessage = (notification: INotification) => {
    dispatch(appendNotificationIntent(notification));
  };

  const handleReceiveBroadcast = (notification: INotification) => {
    dispatch(broadcastNotificationReceived(notification));
  };

  const setupSignalR = async () => {
    try {
      const { signalR: signalRUrl } = await getConfiguration();

      const connection = new SignalR.HubConnectionBuilder()
        .withUrl(signalRUrl, {
          accessTokenFactory: acquireTokenSilent,
        })
        .configureLogging(SignalR.LogLevel.Information)
        // will try reconnect 4 times
        .withAutomaticReconnect([0, 1000, 5000, 10000])
        .build();

      connection.on(signalRChannels.GLOBAL_CHANNEL, handleReceiveBroadcast);
      connection.on(signalRChannels.PRIVATE_CHANNEL, handleReceiveMessage);

      connection.start();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setupSignalR();
    dispatch(fetchNotificationsIntent());
  }, []);

  return <div data-test-id="notifications-root" />;
};

export default NotificationsController;
