import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../../shared/tutorial-tiles/components/animations/Sources';

export const TUTORIAL_TILE_KEY = 'designer-ingest-sources';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:ingest:sources:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:sources:items:createDataSource:description',
      icon: 'Add',
      titleI18nKey: 'tutorialTiles:designer:ingest:sources:items:createDataSource:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:sources:items:rightOnTime:description',
      icon: 'Clock',
      titleI18nKey: 'tutorialTiles:designer:ingest:sources:items:rightOnTime:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:sources:items:ingest:description',
      icon: 'Upload',
      titleI18nKey: 'tutorialTiles:designer:ingest:sources:items:ingest:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010218461-Sources-Access-Your-Data',
};
