import React from 'react';
import { baseColors } from '../../../../../../theme';

export const errorWrapper: React.CSSProperties = {
  maxHeight: '42px',
  overflowY: 'auto',
  color: baseColors.errorRed,
};

export const errorMsg: React.CSSProperties = { fontSize: '11px' };
export const button: React.CSSProperties = { width: '110px', marginTop: '8px' };
export const validText: React.CSSProperties = { marginTop: '8px' };
