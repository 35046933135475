import * as effects from 'redux-saga/effects';

import * as builderActions from '../actions';
import * as builderApi from '../api';
import * as builderSelectors from '../selectors';
import { EntitySelectableForStream, StreamAssetType } from '../types';

const FETCH_STREAM_ASSETS_ACTION_BY_TYPE = {
  [StreamAssetType.Field]: builderActions.fetchStreamFields,
  [StreamAssetType.Calculation]: builderActions.fetchStreamCalculations,
  [StreamAssetType.Group]: builderActions.fetchStreamGroupsAssets,
  [StreamAssetType.Link]: builderActions.fetchStreamLinks,
  [StreamAssetType.Hub]: builderActions.fetchStreamHubs,
  [StreamAssetType.MachineLearning]: builderActions.fetchStreamMachineLearning,
};

function* removeAsset(assetId: string, assetType: StreamAssetType) {
  switch (assetType) {
    case StreamAssetType.Field: {
      const { records } = builderSelectors.getStreamFields(
        yield effects.select()
      );
      const relatedStreamField = records.find(
        (f) => f.sourceFieldId === assetId
      );
      yield effects.call(builderApi.removeStreamAsset, {
        entityId: relatedStreamField.id,
        assetType,
      });
      break;
    }
    case StreamAssetType.Calculation: {
      const { streamCalculations } = builderSelectors.getCalculations(
        yield effects.select()
      );
      const relatedCalculation = streamCalculations.find(
        (f) => f.calculationId === assetId
      );
      yield effects.call(builderApi.removeStreamAsset, {
        entityId: relatedCalculation.id,
        assetType,
      });
      break;
    }
    case StreamAssetType.Group: {
      const { streamGroupAssets } = builderSelectors.getGroups(
        yield effects.select()
      );
      const relatedGroup = streamGroupAssets.find((f) => f.groupId === assetId);
      yield effects.call(builderApi.removeStreamAsset, {
        entityId: relatedGroup.id,
        assetType,
      });
      break;
    }
    case StreamAssetType.Link: {
      const { streamLinks } = builderSelectors.getStreamLinks(
        yield effects.select()
      );
      const relatedLink = streamLinks.find((f) => f.linkId === assetId);
      yield effects.call(builderApi.removeStreamAsset, {
        entityId: relatedLink.id,
        assetType,
      });
      break;
    }
    case StreamAssetType.Hub: {
      const { streamHubs } = builderSelectors.getHubs(yield effects.select());
      const relatedHub = streamHubs.find((f) => f.hubId === assetId);
      yield effects.call(builderApi.removeStreamAsset, {
        entityId: relatedHub.id,
        assetType,
      });
      break;
    }
    case StreamAssetType.MachineLearning: {
      const { streamMachineLearning } = builderSelectors.getMachineLearning(
        yield effects.select()
      );
      const relatedHub = streamMachineLearning.find(
        (f) => f.machineLearningId === assetId
      );
      yield effects.call(builderApi.removeStreamAsset, {
        entityId: relatedHub.id,
        assetType,
      });
      break;
    }
    default:
      break;
  }
}

function* handleSourceFieldClicked({
  payload: { entity, actionType, assetType },
}: ReturnType<typeof builderActions.assetClicked>) {
  const selectedStreamId = builderSelectors.getSelectedBuilderStreamId(
    yield effects.select()
  );

  try {
    if (actionType === builderActions.AssetClickedIntent.ADD) {
      yield effects.call(builderApi.createStreamAsset, {
        assetType,
        entityId: entity.id,
        streamId: selectedStreamId,
      });
    }

    if (actionType === builderActions.AssetClickedIntent.REMOVE) {
      yield effects.call(removeAsset, entity.id, assetType);
    }
  } catch (err) {
    console.log('source field clicked err', err);
  } finally {
    // this saga is canceled when user clicks another field,
    // so in order to avoid unnecessary requests / keep up with performance
    // we are waiting here if next field won't be clicked
    yield effects.delay(1400);
    yield effects.put(
      FETCH_STREAM_ASSETS_ACTION_BY_TYPE[assetType]({
        streamId: selectedStreamId,
      }) as any
    );
  }
}

type RemoveAllParams = {
  assets: EntitySelectableForStream[];
  assetType: StreamAssetType;
};
function* removeAllAssets({ assetType, assets }: RemoveAllParams) {
  switch (assetType) {
    case StreamAssetType.Field: {
      const { records } = builderSelectors.getStreamFields(
        yield effects.select()
      );
      const relatedSourceFieldIds = assets.map((field) => field.id);
      const streamFieldIdsToRemove = relatedSourceFieldIds.reduce(
        (accumulator, sourceFieldId) => {
          const relatedStreamField = records.find(
            (f) => f.sourceFieldId === sourceFieldId
          );

          return [...accumulator, relatedStreamField.id];
        },
        []
      );

      yield effects.put(
        builderActions.setAssetIdsBeingUpdated({
          assetIds: relatedSourceFieldIds,
          assetType,
        })
      );

      try {
        yield effects.all(
          streamFieldIdsToRemove.map((streamFieldId) =>
            effects.call(builderApi.removeStreamField, streamFieldId)
          )
        );
      } catch (error) {
        // propagate to UI Errors?
        console.log('removeStreamField error', error);
      }
      break;
    }
    case StreamAssetType.Group: {
      const { streamGroupAssets } = builderSelectors.getGroups(
        yield effects.select()
      );
      const relatedGroupAssetIds = assets.map((field) => field.id);
      const streamGroupAssetIdsToRemove = relatedGroupAssetIds.reduce(
        (accumulator, groupAssetId) => {
          const relatedGroupAsset = streamGroupAssets.find(
            (f) => f.groupId === groupAssetId
          );

          return [...accumulator, relatedGroupAsset.id];
        },
        []
      );

      yield effects.put(
        builderActions.setAssetIdsBeingUpdated({
          assetIds: relatedGroupAssetIds,
          assetType,
        })
      );

      try {
        yield effects.all(
          streamGroupAssetIdsToRemove.map((streamFieldId) =>
            effects.call(builderApi.removeStreamGroupAsset, streamFieldId)
          )
        );
      } catch (error) {
        // propagate to UI Errors?
        console.log('removeStreamGroupAsset error', error);
      }
      break;
    }
    case StreamAssetType.Calculation: {
      const { streamCalculations } = builderSelectors.getCalculations(
        yield effects.select()
      );
      const calculationIdsInGroup = assets.map((asset) => asset.id);
      const streamCalculationsIdsToRemove = calculationIdsInGroup.reduce(
        (accumulator, calculationId) => {
          const relatedStreamCalculation = streamCalculations.find(
            (streamCalc) => streamCalc.calculationId === calculationId
          );

          return [...accumulator, relatedStreamCalculation.id];
        },
        []
      );

      yield effects.put(
        builderActions.setAssetIdsBeingUpdated({
          assetIds: calculationIdsInGroup,
          assetType,
        })
      );

      try {
        yield effects.all(
          streamCalculationsIdsToRemove.map((streamCalculationId) =>
            effects.call(
              builderApi.removeStreamCalculation,
              streamCalculationId
            )
          )
        );
      } catch (error) {
        // propagate to UI Errors?
        console.log('removeStreamCalc error', error);
      }
      break;
    }
    case StreamAssetType.Hub: {
      const { streamHubs } = builderSelectors.getHubs(yield effects.select());
      const relatedHubIdsInGroup = assets.map((asset) => asset.id);
      const streamHubIdsToRemove = relatedHubIdsInGroup.reduce(
        (accumulator, hubId) => {
          const relatedStreamCalculation = streamHubs.find(
            (streamHub) => streamHub.hubId === hubId
          );

          return [...accumulator, relatedStreamCalculation.id];
        },
        []
      );

      yield effects.put(
        builderActions.setAssetIdsBeingUpdated({
          assetIds: relatedHubIdsInGroup,
          assetType,
        })
      );

      try {
        yield effects.all(
          streamHubIdsToRemove.map((streamHubId) =>
            effects.call(builderApi.removeStreamHub, streamHubId)
          )
        );
      } catch (error) {
        // propagate to UI Errors?
        console.log('removeStreamCalc error', error);
      }
      break;
    }
    default:
      break;
  }
}

export function* handleGroupClicked({
  payload: { entities, selectedEntitiesIds: selectedFieldsIds, assetType },
}: ReturnType<typeof builderActions.assetsDataGroupClicked>) {
  const selectedStreamId = builderSelectors.getSelectedBuilderStreamId(
    yield effects.select()
  );

  // all data source fields already included in stream - deselect all of them
  if (entities.every((field) => selectedFieldsIds.includes(field.id))) {
    yield effects.call(removeAllAssets, {
      assets: entities,
      assetType,
    });
  }

  let fieldIdsToAdd: string[] = [];

  // if none field is selected -> select all
  if (entities.every((field) => !selectedFieldsIds.includes(field.id))) {
    fieldIdsToAdd = entities.map((field) => field.id);
  } else {
    // at least one field is selected
    // in such case we want to include REMAINING fields
    fieldIdsToAdd = entities
      .filter((field) => !selectedFieldsIds.includes(field.id))
      .map((field) => field.id);
  }

  yield effects.put(
    builderActions.setAssetIdsBeingUpdated({
      assetIds: fieldIdsToAdd,
      assetType,
    })
  );

  try {
    yield effects.all(
      fieldIdsToAdd.map((fieldId) =>
        effects.call(builderApi.createStreamAsset, {
          streamId: selectedStreamId,
          assetType,
          entityId: fieldId,
        })
      )
    );
  } catch (error) {
    // propagate to UI Errors?
    console.log('creating stream field error', error);
  }
  yield effects.put(
    FETCH_STREAM_ASSETS_ACTION_BY_TYPE[assetType]({
      streamId: selectedStreamId,
    }) as any
  );
}

export function* rootSaga() {
  yield effects.all([
    yield effects.takeLatest(
      builderActions.assetClicked,
      handleSourceFieldClicked
    ),
    yield effects.takeLatest(
      builderActions.assetsDataGroupClicked,
      handleGroupClicked
    ),
  ]);
}

export default rootSaga;
