import { cloneDeep } from 'lodash';
import { getAggregations } from '../../constants/aggregations';
import {
  setTable, setTableData, setTableRowKeys, setTableColumnKeys, setShouldReload,
} from '../../modules/GroupTable/actions';
import { DRILLING_DIRECTIONS, MAX_CELLS_TO_RENDER_AT_ONCE, PARENT_DRILLING } from '../../modules/GroupTable/constants';
import {
  IGroupTable, IGroupedColumn, IGroupedRow, IDrillableSelection,
} from '../../modules/GroupTable/types';
import { actionCreators } from '../../modules/Streams/Streams';
import { baseColors } from '../../theme';
import { IDataset } from '../../types/IDataset';
import { colorsDrillingColumns, colorsDrillingRows, topGroupTableColors } from './constants';
import { blockUiOfOtherUsers, syncPersistedData } from '../../store/persistence/actions';
import {
  bindThunkActionCreators,
  handleGroupTableData,
  serializeObjectKeys,
  removeGroupTableKeys,
  getFormat,
  getGroupTableValuesFormat,
  fetchTotalsIntersection,
  reportError,
  nestedSerializeObjectKeys,
  columnLettersToNumber,
  getCellColumnRowPair,
} from './utils';
import { handleError } from './handleError';
import {
  findHeaderKeyCellIndex, getIsColumnLayout, getRenderableTotals, getRenderableValues, getTableHeaderLength,
} from '../../modules/GroupTable/utils';
import { checkWorkbookSelection } from '../../modules/Streams/actions';
import fetchGroupTableData from '../../modules/GroupTable/sagas/fetchGroupTableData';
import { fetchGroupTableTotal } from '../../modules/GroupTable/sagas/fetchGroupTableTotal';
import { generateColumnUuid } from '../../modules/ColumnsSequencing/utils';
import getDatasetColumnByColumnUuid from '../../utils/getDatasetColumnByColumnUuid';

export const handleGroupTableRowDrilldown = (
  {
    isDrilledDown, keys, dataset, index,
  } : IDrillableSelection,
  shouldDrillUp : boolean,
) => Excel.run(async (ctx) => {
  const { getState, dispatch } = window.sharedState.store;
  const {
    setSyncing,
  } = bindThunkActionCreators(actionCreators);

  try {
    const childrenFirst = dataset.options.rowParentDrilling === PARENT_DRILLING.after;
    setSyncing(true);

    if (!isDrilledDown && !shouldDrillUp) {
      const table = getState().groupTable[dataset.id];
      await window.sharedState.runSaga(fetchGroupTableData, {
        dataset,
        rowKeys: keys,
      }).toPromise();
      for (let i = 0; i < table.columnKeys.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        await window.sharedState.runSaga(fetchGroupTableData, {
          dataset,
          rowKeys: keys,
          columnKeys: table.columnKeys[i],
        }).toPromise();
      }
    }

    const groupTable = getState().groupTable[dataset.id];

    dispatch(blockUiOfOtherUsers());
    const parentIndex = getTableHeaderLength(groupTable) + 1;

    if (isDrilledDown || shouldDrillUp) {
      // If the action is to drill-up, but the selection is not a parent,
      // it needs to refer to the parent key, by removing the children from the array.
      // index has to be replaced with the parent index.
      if (!isDrilledDown && shouldDrillUp) {
        keys.splice(-1);
        // eslint-disable-next-line no-param-reassign
        index = findHeaderKeyCellIndex(
          groupTable,
          dataset,
          DRILLING_DIRECTIONS.rowDrilling,
          keys,
        );
      }
      const table = ctx.workbook.worksheets.getActiveWorksheet().tables.getItem(dataset.tableId);
      let { rowsTitles } = handleGroupTableData(groupTable, dataset);
      // eslint-disable-next-line prefer-const
      let data = cloneDeep(groupTable.data);
      let toRemove = data;
      keys.forEach((key) => {
        rowsTitles = rowsTitles[key];
        toRemove = toRemove.rows[key];
      });
      toRemove.rows = {};
      dispatch(setTableData({
        datasetId: dataset.id,
        data,
      }));
      const rowCount = serializeObjectKeys(rowsTitles).length;
      const keyRow = table.rows.getItemAt(index + parentIndex + (childrenFirst ? -2 : 0)).getRange();
      keyRow.getResizedRange((rowCount - 1) * (childrenFirst ? -1 : 1), 0).delete('Up');
      dispatch(setTableRowKeys({
        datasetId: dataset.id,
        keys: removeGroupTableKeys(keys, groupTable.rowKeys),
      }));
      await ctx.sync();
    }

    if (!isDrilledDown && !shouldDrillUp) {
      let { rowsTitles, columnTitles } = handleGroupTableData(groupTable, dataset, keys);
      const values = getRenderableValues({
        table: groupTable,
        dataset,
        rowKeys: keys,
      });

      keys.forEach((key) => {
        rowsTitles = rowsTitles[key];
      });

      const valuesTitles = groupTable.values
        .map((groupTableDesignItem) => dataset.columns.find((col) => generateColumnUuid(col) === groupTableDesignItem.columnUuid))
        .map((column) => `${getAggregations()[column.aggregation].label} ${column.name}`);

      const valuesHeaders = serializeObjectKeys(columnTitles, dataset.options.columnParentDrilling === PARENT_DRILLING.after).flatMap(() => valuesTitles);

      const matrix = serializeObjectKeys(rowsTitles, childrenFirst).map((title, i) => [
        title,
        ...(values[i] || []),
        ...Array(Math.max(0, valuesHeaders.length - (values[i] || []).length)).fill(''),
      ]);

      let columnTotals = await window.sharedState.runSaga(fetchGroupTableTotal, dataset, true, keys).toPromise();
      columnTotals = getRenderableTotals({
        src: columnTotals,
        isColumnsTotal: true,
        dataset,
        table: groupTable,
        keys,
      });

      if (columnTotals.length) {
        for (let r = 0; r < columnTotals.length; r++) {
          matrix[r] = [...matrix[r], ...columnTotals[r]];
        }
      }

      const addedRange = ctx.workbook.worksheets.getActiveWorksheet()
        .tables.getItem(dataset.tableId).rows.add(parentIndex + index - (childrenFirst ? 1 : 0), matrix)
        .getRange().getResizedRange(matrix.length - 1, 0);

      const numberFormat = Array(matrix.length).fill([
        getFormat(getDatasetColumnByColumnUuid({
          columnUuid: groupTable.rows[keys.length].columnUuid,
          dataset,
        })),
        ...getGroupTableValuesFormat({
          table: groupTable, dataset, withColumnTotal: true, rowDrilling: keys.length,
        }),
      ]);
      addedRange.set({
        format: {
          fill: {
            color: colorsDrillingRows[keys.length] || baseColors.white,
          },
          font: {
            color: baseColors.offBlack,
          },
        },
        numberFormat,
      });
      const titleRange = addedRange.getColumn(0);
      titleRange.format.adjustIndent(keys.length * 1);
      titleRange.format.horizontalAlignment = Excel.HorizontalAlignment.left;
      await ctx.sync();
      dispatch(setTableRowKeys({
        datasetId: dataset.id,
        keys: [...groupTable.rowKeys, keys],
      }));
    }
    dispatch(syncPersistedData());
    setSyncing(false);
    dispatch(checkWorkbookSelection());
  } catch (e) {
    console.error(e);
  }
});

export const handleGroupTableColumnDrilldown = (
  {
    isDrilledDown, keys, dataset, index,
  } : IDrillableSelection,
  shouldDrillUp : boolean,
) => Excel.run(async (ctx) => {
  const { getState, dispatch } = window.sharedState.store;
  const {
    setSyncing,
  } = bindThunkActionCreators(actionCreators);

  const childrenFirst = dataset.options.columnParentDrilling === PARENT_DRILLING.after;

  try {
    setSyncing(true);
    dispatch(blockUiOfOtherUsers());

    if (!isDrilledDown && !shouldDrillUp) {
      const table = getState().groupTable[dataset.id];
      await window.sharedState.runSaga(fetchGroupTableData, {
        dataset,
        columnKeys: keys,
      }).toPromise();

      for (let i = 0; i < table.rowKeys.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        await window.sharedState.runSaga(fetchGroupTableData, {
          dataset,
          rowKeys: table.rowKeys[i],
          columnKeys: keys,
        }).toPromise();
      }
    }

    const groupTable = getState().groupTable[dataset.id];

    if (!isDrilledDown && shouldDrillUp) {
      keys.splice(-1);
      // eslint-disable-next-line no-param-reassign
      index = findHeaderKeyCellIndex(
        groupTable,
        dataset,
        DRILLING_DIRECTIONS.columnDrilling,
        keys,
      );
    }

    let { columnTitles } = handleGroupTableData(groupTable, dataset, [], keys);
    const values = getRenderableValues({
      table: groupTable,
      dataset,
      columnKeys: keys,
    });
    const valuesCount = groupTable.values.length;

    keys.forEach((key) => {
      columnTitles = columnTitles[key];
    });

    if (isDrilledDown || shouldDrillUp) {
      const colCount = serializeObjectKeys(columnTitles).length;

      const start = (index * valuesCount) + (childrenFirst ? 0 : (1 + valuesCount));
      const length = colCount * valuesCount - (childrenFirst ? 1 : valuesCount);

      const collection = ctx.workbook.worksheets.getActiveWorksheet()
        .tables.getItem(dataset.tableId).columns.load('items');
      await ctx.sync();

      /*     const keyColumn = collection.items[start + (childrenFirst ? 1 : -1)]
        .getRange().getResizedRange(0, childrenFirst ? (valuesCount - 1) : 0);
       keyColumn.format.borders.getItem(Excel.BorderIndex.edgeLeft).style = Excel.BorderLineStyle.none;
      keyColumn.format.borders.getItem(Excel.BorderIndex.edgeRight).style = Excel.BorderLineStyle.none; */

      for (
        let c = start + (childrenFirst ? 0 : length);
        c >= start - (childrenFirst ? length : 0);
        c--
      ) {
        collection.items[c].delete();
      }

      const clone = cloneDeep(groupTable.data);

      const findAndRemove = (data) => {
        Object.keys(data).forEach((key) => {
          switch (key) {
            case 'rows':
              Object.keys(data[key]).forEach((value) => {
                findAndRemove(data[key][value]);
              });
              break;
            case 'columns': {
              let sub = data;
              for (let k = 0; k < keys.length; k++) {
                sub = sub?.columns?.[keys[k]];
              }
              if (sub?.columns) {
                sub.columns = {};
              }
            }
              break;
            default:
              break;
          }
        });
      };
      findAndRemove(clone);
      dispatch(setTableData({
        datasetId: dataset.id,
        data: clone,
      }));
      dispatch(setTableColumnKeys({
        datasetId: dataset.id,
        keys: removeGroupTableKeys(keys, groupTable.columnKeys),
      }));
    }

    if (!isDrilledDown && !shouldDrillUp) {
      const aggregations = getAggregations();

      const serializedColumnTitles = serializeObjectKeys(columnTitles, dataset.options.columnParentDrilling === PARENT_DRILLING.after);
      const valuesTitles = groupTable.values
        .map((groupTableValueSingleItem) => dataset.columns.find((c) => generateColumnUuid(c) === groupTableValueSingleItem.columnUuid))
        .map((associatedDatasetColumn) => `${aggregations[associatedDatasetColumn.aggregation].label} ${associatedDatasetColumn.name}`);
      const columnHeaders = serializedColumnTitles.flatMap((title) => [title, ...Array(Math.max(0, valuesCount - 1)).fill('')]);
      const valuesHeaders = serializedColumnTitles.flatMap(() => valuesTitles);
      let totals = await window.sharedState.runSaga(fetchGroupTableTotal, dataset, false, keys).toPromise();
      totals = getRenderableTotals({
        src: totals,
        table: groupTable,
        dataset,
        keys,
      });

      const matrix = [
        columnHeaders,
        valuesHeaders,
        ...values,
        ...(totals.length ? totals : []),
      ];

      const table = ctx.workbook.worksheets.getActiveWorksheet().tables.getItem(dataset.tableId);
      /*      const keyColumn = table.columns.getItemAt(index * valuesCount + 1).getRange().getResizedRange(0, valuesCount - 1);
       keyColumn.format.borders.getItem(Excel.BorderIndex.edgeLeft).color = baseColors.offBlack;
      keyColumn.format.borders.getItem(Excel.BorderIndex.edgeRight).color = baseColors.offBlack; */

      for (let col = 0; col < matrix[0].length; col++) {
        const toAdd = matrix.map(
          (row: any[]) => row?.slice(col, col + 1) || [''],
        );
        const addedRange = ctx.workbook.worksheets.getActiveWorksheet().tables.getItem(dataset.tableId)
          .columns.add((index * valuesCount) + 1 + col + (childrenFirst ? 0 : valuesCount), toAdd).getRange();
        const headerRange = addedRange.getRow(0);
        const numberFormat = [[
          getFormat(
            getDatasetColumnByColumnUuid({
              dataset,
              columnUuid: groupTable.columns[keys.length].columnUuid,
            }),
          ),
        ]];
        headerRange.set({
          format: {
            fill: { color: colorsDrillingColumns[keys.length] || baseColors.white },
            font: { color: keys.length > 2 ? baseColors.offBlack : baseColors.white },
          },
          numberFormat,
        });
        addedRange.untrack();
      }

      table.getRange().format.autofitColumns();

      dispatch(setTableColumnKeys({
        datasetId: dataset.id,
        keys: [...groupTable.columnKeys, keys],
      }));
    }
    await ctx.sync();
    dispatch(syncPersistedData());
    setSyncing(false);
    dispatch(checkWorkbookSelection());
  } catch (e) {
    console.error(e);
  }
});

const mapGroupTableRows = (
  ctx: Excel.RequestContext,
  table: Excel.Table,
  groupTable: IGroupTable,
  dataset: IDataset,
) => {
  let rowsAbove = getTableHeaderLength(groupTable);
  const { columnTitles } = handleGroupTableData(groupTable, dataset);
  let cellsCount = 0;
  const columnsChildrenFirst = dataset.options.columnParentDrilling === PARENT_DRILLING.after;
  const rowsChildrenFrist = dataset.options.rowParentDrilling === PARENT_DRILLING.after;

  const getValuesByColumn = (
    columns: IGroupedColumn['columns'],
    columnKeys,
    values = [],
  ) => {
    Object.keys(columnKeys).forEach((col) => {
      const colValues = columns?.[col]?.values || null;

      // TODO: depending on the dataset's Parent drilling, getValuesByColumn should be
      // executed before or after. We need to find a better way of writing this.
      if (columnsChildrenFirst && colValues && columns?.[col]?.columns) {
        getValuesByColumn(columns[col].columns, columnKeys[col], values);
      }
      const filler = Array(groupTable.values.length * (serializeObjectKeys(columnKeys[col], columnsChildrenFirst).length + 1)).fill('');
      values.push(...(colValues || filler));

      if (!columnsChildrenFirst && colValues && columns?.[col]?.columns) {
        getValuesByColumn(columns[col].columns, columnKeys[col], values);
      }
    });
    return values;
  };

  // STEP-1: Map all rows
  const getValuesByRow = async (
    rows: IGroupedRow['rows'],
    parents: string[] = [],
    drillingLevel = 0,
  ) => {
    const keys = Object.keys(rows);
    const values = keys.map((row) => {
      if (rows[row].values) {
        return [row, ...rows[row].values];
      }
      return [row, ...getValuesByColumn(rows[row].columns, columnTitles)];
    });

    let chunk = [];
    let chunkRowIndex = rowsAbove;
    for (let i = 0; i < values.length; i++) {
      chunk.push(values[i]);
      cellsCount += values[i].length;
      if (i === values.length - 1 || cellsCount >= MAX_CELLS_TO_RENDER_AT_ONCE) {
        const addedRange = table.rows.add(chunkRowIndex, chunk).getRange().getResizedRange(chunk.length - 1, 0);
        const numberFormat = Array(chunk.length).fill([
          getFormat(
            getDatasetColumnByColumnUuid({
              columnUuid: groupTable.rows[drillingLevel].columnUuid,
              dataset,
            }),
          ),
          ...getGroupTableValuesFormat({
            table: groupTable, dataset, rowDrilling: drillingLevel,
          }),
        ]);
        addedRange.set({
          format: {
            fill: {
              color: colorsDrillingRows[drillingLevel] || baseColors.white,
            },
            font: {
              color: baseColors.offBlack,
            },
          },
          numberFormat,
        });
        const titleRange = addedRange.getColumn(0);
        titleRange.format.adjustIndent(drillingLevel * 1);
        titleRange.format.horizontalAlignment = Excel.HorizontalAlignment.left;

        addedRange.untrack();
        titleRange.untrack();

        if (cellsCount >= MAX_CELLS_TO_RENDER_AT_ONCE) {
          // eslint-disable-next-line no-await-in-loop
          await ctx.sync();
          cellsCount = 0;
          chunkRowIndex += chunk.length;
        }
        chunk = [];
      }
    }

    // STEP-2: Loop through all keys

    for (let i = 0; i < keys.length; i++) {
      // STEP-2.1: If has sub rows, start STEP-1 on sub-rows.
      if (!rowsChildrenFrist) rowsAbove += 1;
      if (rows[keys[i]].rows) {
        // eslint-disable-next-line no-await-in-loop
        await getValuesByRow(rows[keys[i]].rows, [...parents, keys[i]], drillingLevel + 1);
      }
      if (rowsChildrenFrist) rowsAbove += 1;
    }
  };

  return getValuesByRow(groupTable.data.rows);
};

const formatTableColumns = (range: Excel.Range, columnTitles: any, table: IGroupTable, dataset: IDataset) => {
  const childrenFirst = dataset.options.columnParentDrilling === PARENT_DRILLING.after;
  const columnHeaders = nestedSerializeObjectKeys(columnTitles, childrenFirst);
  const valuesCount = table.values.length;
  /* const { columnDrilling } = table; */

  const startRange = range.getColumn(1).getResizedRange(0, valuesCount - 1);

  columnHeaders.forEach((keys, i) => {
    const column = startRange.getOffsetRange(0, i * valuesCount);
    const header = column.getRow(0);
    const drilling = keys.length;
    const numberFormat = [
      Array(valuesCount).fill(
        getFormat(
          getDatasetColumnByColumnUuid({
            dataset,
            columnUuid: table.columns[drilling - 1].columnUuid,
          }),
        ),
      ),
    ];
    header.set({
      format: {
        fill: { color: colorsDrillingColumns[drilling - 1] || baseColors.white },
        font: { color: drilling > 3 ? baseColors.offBlack : baseColors.white },
        horizontalAlignment: Excel.HorizontalAlignment.center,
      },
      numberFormat,
    });

    /*    if (drilling < columnDrilling) {
      column.format.borders.getItem(Excel.BorderIndex.edgeLeft).color = baseColors.offBlack;
      column.format.borders.getItem(Excel.BorderIndex.edgeRight).color = baseColors.offBlack;
    } */
    header.untrack();
    column.untrack();
  });
};

export const mapDatasetAsGroupTable = async (dataset: IDataset) => {
  const { getState, dispatch } = window.sharedState.store;
  const {
    editDataset,
  } = bindThunkActionCreators(actionCreators);
  const groupTable = getState().groupTable[dataset.id];

  const {
    columnTitles,
  } = handleGroupTableData(groupTable, dataset);

  const aggregations = getAggregations();

  const columnsChildrenFirst = dataset.options.columnParentDrilling === PARENT_DRILLING.after;

  let serializedColumnTitles = serializeObjectKeys(columnTitles, columnsChildrenFirst);
  serializedColumnTitles = serializedColumnTitles.length ? serializedColumnTitles : [''];

  const rowsHeader = groupTable.rows.map((r) => r.text).join(' | ');
  const valuesTitles = groupTable.values
    .map((groupTableValuesItem) => dataset.columns.find((c) => generateColumnUuid(c) === groupTableValuesItem.columnUuid))
    .map((associatedColumn) => `${aggregations[associatedColumn.aggregation].label} ${associatedColumn.name}`);

  const columnHeaders = serializedColumnTitles.flatMap((title) => [title, ...Array(Math.max(0, valuesTitles.length - 1)).fill('')]);
  const valuesHeaders = serializedColumnTitles.flatMap(() => valuesTitles);
  const [rowTotals, columnTotals, crossTotals] = await Promise.all([
    window.sharedState.runSaga(fetchGroupTableTotal, dataset).toPromise(),
    window.sharedState.runSaga(fetchGroupTableTotal, dataset, true).toPromise(),
    fetchTotalsIntersection(dataset),
  ]);
  const isColumnLayout = getIsColumnLayout(groupTable);

  const headersGrid = isColumnLayout ? [
    [rowsHeader, ...columnHeaders],
    ['', ...valuesHeaders],
  ] : [
    [rowsHeader, ...valuesHeaders],
  ];

  const higherGrid = [
    ...headersGrid,
  ];

  if (groupTable.totals.rows) {
    higherGrid.push(['Total:', ...rowTotals]);
  }
  return Excel.run({ delayForCellEdit: true }, async (ctx) => {
    try {
      let range: Excel.Range;
      let worksheet = ctx.workbook.worksheets.getActiveWorksheet().load('id');
      const gridSize: [number, number] = [higherGrid.length, higherGrid[0].length];
      if (dataset.tableId) {
        /**
       * If table exists, delete mapped table and data.
       */
        const table = worksheet.tables.getItem(dataset.tableId);
        range = table.getRange().getAbsoluteResizedRange(...gridSize);
        table.delete();
      } else {
        /**
         * If table doesn't exist, create it in the current sheet (if empty), otherwise, create
         * it in a new sheet.
         */
        const worksheetRange = worksheet.getRange().getUsedRangeOrNullObject();
        await ctx.sync();
        if (!worksheetRange.isNullObject) {
          worksheet = ctx.workbook.worksheets.add().load('id');
          worksheet.activate();
          range = worksheet.getRange()
            .getAbsoluteResizedRange(...gridSize);
        } else {
          range = ctx.workbook.getSelectedRange()
            .getAbsoluteResizedRange(...gridSize);
        }
      }
      range.set({
        values: higherGrid,
      });

      range.getCell(0, 0).format.set({
        ...topGroupTableColors,
        horizontalAlignment: Excel.HorizontalAlignment.center,
      });
      const table = worksheet.tables.add(range, false);

      if (rowTotals.length) {
        const totalsRange = range.getRowsBelow(-1);
        range.getRowsBelow(-1).format.set(topGroupTableColors);
        totalsRange.getResizedRange(0, -1)
          .getOffsetRange(0, 1)
          .numberFormat = Array(1)
            .fill(getGroupTableValuesFormat({ table: groupTable, dataset }));
      }

      table.showHeaders = false;
      range.getRowsAbove(1).delete(Excel.DeleteShiftDirection.up);
      table.load('id');

      await mapGroupTableRows(ctx, table, groupTable, dataset);

      if (isColumnLayout) {
        formatTableColumns(range, columnTitles, groupTable, dataset);
        // Horizontally align values headers
        range.getRow(1).format.set({
          horizontalAlignment: Excel.HorizontalAlignment.center,
        });
      } else {
        range.getRow(0).format.set({
          ...topGroupTableColors,
          horizontalAlignment: Excel.HorizontalAlignment.center,
        });
      }

      const tableRange = table.getRange().load('address');
      tableRange.format.autofitColumns();
      tableRange.format.autofitRows();
      tableRange.format.borders.getItem('InsideVertical').style = Excel.BorderLineStyle.continuous;
      tableRange.format.borders.getItem('InsideVertical').color = baseColors.white;

      if (isColumnLayout && columnTotals.length) {
        for (let c = 0; c < columnTotals[0].length; c++) {
          const matrix = [
            [c === 0 ? 'Total' : ''],
            [valuesTitles[c]],
            ...columnTotals.map((r) => r.slice(c, c + 1)),
            ...(groupTable.totals.rows ? [[crossTotals[c]]] : []),
          ];
          const columnRange = table.columns.add(null, matrix).getRange();
          columnRange.format.autofitColumns();
        }
      }

      await ctx.sync();

      if (dataset.options.freezePanes) {
        const [addressColumn, addressRow] = getCellColumnRowPair(tableRange.address);
        worksheet.freezePanes.freezeColumns(columnLettersToNumber(addressColumn));
        worksheet.freezePanes.freezeRows(
          isColumnLayout ? +addressRow + 1 : +addressRow,
        );
      }

      editDataset(dataset.id, {
        tableId: table.id,
        address: tableRange.address,
      });
      dispatch(setTable({
        datasetId: dataset.id,
        table: {
          ...groupTable,
          worksheetId: worksheet.id,
          rowKeys: [],
          columnKeys: [],
        },
      }));

      dispatch(setShouldReload({
        datasetId: dataset.id,
        shouldReload: false,
      }));

      dispatch(checkWorkbookSelection());
    } catch (e) {
      handleError(e);
      reportError(e);
    }
  });
};

export const freezePanes = (shouldFreeze: boolean, dataset: IDataset) => {
  const { getState } = window.sharedState.store;
  const groupTable = getState().groupTable[dataset.id];
  const isColumnLayout = getIsColumnLayout(groupTable);
  return Excel.run({ delayForCellEdit: true }, async (ctx) => {
    try {
      const worksheet = ctx.workbook.worksheets.getActiveWorksheet();
      if (shouldFreeze) {
        const range = ctx.workbook.tables.getItem(dataset.tableId).getRange().load('address');
        await ctx.sync();
        const [addressColumn, addressRow] = getCellColumnRowPair(range.address);
        worksheet.freezePanes.freezeColumns(columnLettersToNumber(addressColumn));
        worksheet.freezePanes.freezeRows(
          isColumnLayout ? +addressRow + 1 : +addressRow,
        );
      } else {
        worksheet.freezePanes.unfreeze();
      }
    } catch (e) {
      handleError(e);
      reportError(e);
    }
  });
};
