/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { IDropdownOption } from '@fluentui/react';
import { Droppable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import { SectionType, SingleItem as ISingleItem } from '../../types';
import * as actions from '../../actions';
import { getSectionByType, getTotalBySectionType } from '../../selectors';
import { ApplicationState } from '../../../../store/types';
import { StreamsState } from '../../../Streams/reducer';
import { selectors } from '../../../Streams/Streams';

import SingleItem from '../SingleItem';
import ItemsContainer from '../ItemsContainer';

import { dragAndDropWrapperStyles } from './styles';

interface Props {
  dropdownOptions: IDropdownOption[];
  sectionType: SectionType
}

const SingleSection: React.FC<Props> = ({ dropdownOptions, sectionType }) => {
  const dispatch = useDispatch();
  const { currentDatasetId: datasetId } = useSelector<ApplicationState, StreamsState>(selectors.getStreamsState);

  const handleUpdateItem = useCallback((item:ISingleItem, isUpdatingColumn:boolean) => {
    const payload = {
      datasetId,
      sectionType,
      item,
    };

    if (isUpdatingColumn) {
      dispatch(actions.updateItemAndTryToAssociateAggregation(payload));
    } else {
      dispatch(actions.updateItem(payload));
    }
  }, [datasetId]);

  const handleRemoveItem = useCallback((item: ISingleItem) => dispatch(
    actions.removeItem({
      datasetId,
      sectionType,
      item,
    }),
  ), [datasetId]);

  const handleChangeTotal = useCallback(() => {
    dispatch(actions.toggleGroupTableTotal({
      datasetId,
      sectionType,
    }));
  }, [datasetId]);

  const items = useSelector(getSectionByType(sectionType, datasetId));
  const hasTotals = useSelector(getTotalBySectionType(sectionType, datasetId));

  return (
    <ItemsContainer
      sectionType={sectionType}
      handleChangeTotal={handleChangeTotal}
      hasTotals={hasTotals}
    >
      <Droppable
        droppableId={sectionType}
      >
        {(provided: any) => (
          <div
            ref={provided.innerRef}
            style={dragAndDropWrapperStyles}
          >

            {items.map((item, index) => (
              <SingleItem
                sectionType={sectionType}
                index={index}
                dropdownOptions={dropdownOptions}
                currentItem={item}
                key={item.id}
                updateItem={handleUpdateItem}
                removeItem={handleRemoveItem}
              />
            ))}
          </div>
        )}
      </Droppable>
    </ItemsContainer>
  );
};

export default SingleSection;
