import { all } from 'redux-saga/effects';

import columnAggregationSaga from './columnAggregation';
import itemSelectionDeselectionSaga from './itemSelectionDeselection';
import groupTableItemSelectionDeselection from './groupTableItemSelectionDeselection';
import fetchStreamsSaga from './fetchStreams';
import checkWorkbookSelectionSaga from './checkWorkbookSelection';
import streamPlaying from './streamPlaying';
import setCurrentStreamIdSaga from './setCurrentStreamId';
import processStream from './processStream';
import { cancelFetchingProgress, updateFetchingProgress } from './updateFetchingProgress';

export function* rootSaga() {
  yield all([
    columnAggregationSaga(),
    itemSelectionDeselectionSaga(),
    groupTableItemSelectionDeselection(),
    fetchStreamsSaga(),
    checkWorkbookSelectionSaga(),
    streamPlaying(),
    setCurrentStreamIdSaga(),
    updateFetchingProgress(),
    cancelFetchingProgress(),
    processStream(),
  ]);
}

export default rootSaga;
