import { createReducer } from '@reduxjs/toolkit';
import {
  addOrRemoveSelectedFilter,
  removeSelectedStreamFilters,
  editFilter,
  removeStreamFilterById,
} from './utils';
import { IStreamFilter } from '../../types/IStreamFilter';

import * as actions from './actions';

export interface StreamFiltersState {
  streamFilters: IStreamFilter[];
  selectedStreamFilters: IStreamFilter[];
}

export const initialState: StreamFiltersState = {
  streamFilters: [],
  selectedStreamFilters: [],
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(actions.setStreamFilters, (state, { payload }) => ({
    ...state,
    streamFilters: payload,
    selectedStreamFilters: [],
  }))
  .addCase(actions.addStreamFilter, (state, { payload }) => ({
    ...state,
    streamFilters: [
      ...state.streamFilters,
      payload,
    ],
  }))
  .addCase(actions.setSelectedStreamFilters, (state, { payload }) => ({
    ...state,
    streamFilters: payload,
  }))
  .addCase(actions.selectStreamFilter, (state, { payload }) => ({
    ...state,
    selectedStreamFilters: addOrRemoveSelectedFilter(state.selectedStreamFilters, payload),
  }))
  .addCase(actions.removeSelectedFilters, (state) => ({
    ...state,
    streamFilters: removeSelectedStreamFilters(state.streamFilters, state.selectedStreamFilters),
    selectedStreamFilters: [],
  }))
  .addCase(actions.removeStreamFilter, (state, { payload }) => ({
    ...state,
    streamFilters: removeStreamFilterById(payload, state.streamFilters),
    selectedStreamFilters: removeStreamFilterById(payload, state.selectedStreamFilters),
  }))
  .addCase(actions.editStreamFilter, (state, { payload }) => ({
    ...state,
    streamFilters: editFilter(state.streamFilters, payload),
    selectedStreamFilters: editFilter(state.selectedStreamFilters, payload),
  })));

export default reducer;
