import React from 'react';
import { AggregationType } from '../../../../../api/model/schemas/AggregationType';
import { DataSourceEntityField } from '../../../../../api/model/schemas/DataSourceEntityField';
import ButtonMenu from '../../../../../components/ui/ButtonMenu';
import { aggregationButtonMenuConfig } from '../../../../../constants/aggregations';
import { getInvalidAggregationMethodsApiV4 } from '../../../../../utils/itemsAggregation';
import { EntityContext } from './EntityContext';
import { isAggregationOrange } from './utils';

interface Props {
  field: DataSourceEntityField;
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const EntityFieldAggregationDropdown = ({ field, setUpdating }: Props) => {
  const { aggregationType, id, dataType } = field;

  const { patchEntityField } = React.useContext(EntityContext);

  const handleChange = async (newAggregationType: AggregationType) => {
    setUpdating(true);
    await patchEntityField({
      fieldId: id,
      updatedValues: {
        aggregationType: newAggregationType,
      },
    });
    setUpdating(false);
  };

  // NOTE: casting because the argument typed is based on a property of an outdated type
  const invalidAggregations = getInvalidAggregationMethodsApiV4(dataType as any);

  const items = aggregationButtonMenuConfig()
    .filter((menuItem) => !invalidAggregations.includes(menuItem.key))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <ButtonMenu
      items={items}
      onChangeItem={handleChange}
      activeItemKey={aggregationType}
      isOrange={isAggregationOrange(field)}
    />
  );
};

export default EntityFieldAggregationDropdown;
