import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FileUpload from '../../../../../components/FileUpload';
import { FileTypes } from '../../../../../components/FileUpload/types/FileType';
import { IGenericFormField } from '../../types';
import useManageMultiple from '../ManageMultiple/hooks/useManageMultiple';
import Callout from '../ManageMultiple/Callout';
import { IDelimitedFile } from './types';
import useMultiEntityForm from '../ManageMultiple/hooks/useMultiEntityForm';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { FilePickerContext, FilePickerContextValue } from './FilePickerContext';
import { addFileIntent } from '../../actions';

const FilePicker: React.FC<IGenericFormField> = ({
  interaction,
  currentForm,
  currentEntity,
}) => {
  const dispatch = useDispatch();
  const [selectedItemId, setSelectedItemId] = useState<string>(null);
  const typeId = interaction.referenceType;
  const { schema } = useMultiEntityForm(typeId, currentEntity?.id);

  const { items, remove, edit } = useManageMultiple<IDelimitedFile>({
    typeId,
    currentEntityForm: currentForm,
    interaction,
    forceMin: false,
  });
  const { t } = useTranslation();
  const currentFiles = items.map((item) => item?.data);
  const selectedItem = items.find((item) => item?.id === selectedItemId);
  const selectedItemIndex = items.findIndex(
    (item) => item?.id === selectedItemId
  );
  const filePickerContextValue: FilePickerContextValue = {
    selectedItemIndex,
  };

  const { setPreventSubmission } = useFormSubmission();

  const handleRemove = (index: number) => {
    remove(items[index].id);
  };

  const handleAdd = (files: File[]) => {
    dispatch(
      addFileIntent({
        childrenTypeId: typeId,
        formTypeId: currentForm.typeId,
        files,
        interactionTypeId: interaction.referenceType,
      })
    );
  };

  const handleSubmitCallout = (res: IDelimitedFile) => {
    edit(selectedItemId, res);
    setSelectedItemId(null);
  };

  const handleCloseCallout = useCallback(() => {
    setSelectedItemId(null);
  }, []);

  const handleOpenCallout = (index: number) => {
    const id = items?.[index]?.id;
    setSelectedItemId(id);
  };

  useEffect(() => {
    setPreventSubmission(items.length < interaction.minimum);
    return () => setPreventSubmission(false);
  }, [items.length]);

  return (
    <FilePickerContext.Provider value={filePickerContextValue}>
      <FileUpload
        files={currentFiles}
        fileType={FileTypes.All}
        onAdd={handleAdd}
        onRemove={handleRemove}
        onSelect={handleOpenCallout}
      />
      <Callout
        title={t('welcome:settings')}
        isCalloutVisible={!!selectedItemId}
        schema={schema}
        onSubmit={handleSubmitCallout}
        onClose={handleCloseCallout}
        defaultValues={selectedItem?.data}
        calloutWidth={265}
        target={`[data-file-index="${selectedItemIndex}"]`}
      />
    </FilePickerContext.Provider>
  );
};

export default FilePicker;
