import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { DatePicker } from '@fluentui/react';
import { FilterFormTextInput as TextField } from '../../../ui';
import Error from '../../Error';
import { ComparisonTypes } from '../../../../types/IStreamFilter';
import { IColumnDataType } from '../../../../types/IColumn';
import { IFilterItem } from '../../../../types/IDesignerFilter';
import { LoadedValuesInput } from '../../../ValuesInput';

interface Props {
  acceptsTypedValues: boolean;
  compareValue: any;
  comparison: ComparisonTypes;
  item: IFilterItem;
  loadOptions(inputValue: string): Promise<Array<string>>;
}

const ComparisonValue: React.FC<Props> = ({
  acceptsTypedValues,
  compareValue,
  comparison,
  item,
  loadOptions,
}) => {
  const { t } = useTranslation('filters');

  const { control, errors, setValue } = useFormContext();

  useEffect(() => {
    if ([IColumnDataType.DateTime].includes(item?.dataType)) {
      if (!compareValue || !(compareValue instanceof Date)) {
        setValue('compareValue', new Date());
      }
    }
  }, [item, comparison]);

  if (
    [
      ComparisonTypes.IsNull,
      ComparisonTypes.IsNotNull,
      ComparisonTypes.Between,
      ComparisonTypes.In,
      ComparisonTypes.NotIn,
      ComparisonTypes.NotBetween,
      ComparisonTypes.IsBlank,
      ComparisonTypes.IsNotBlank,
    ].includes(comparison) ||
    !comparison
  ) {
    return null;
  }

  if ([IColumnDataType.DateTime].includes(item.dataType)) {
    return (
      <>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label={t('Value')}
              isMonthPickerVisible={false}
              onSelectDate={(date) => onChange(date)}
              value={value}
            />
          )}
          name="compareValue"
          control={control}
          rules={{ required: true }}
          defaultValue={new Date()}
        />
        <ErrorMessage
          render={({ message }) => <Error>{message}</Error>}
          errors={errors}
          name="compareValue"
          message={t('required')}
        />
      </>
    );
  }

  if (
    [IColumnDataType.NumericInteger, IColumnDataType.NumericDecimal].includes(
      item.dataType
    )
  ) {
    return (
      <>
        <Controller
          as={NumberFormat}
          label={t('Value')}
          name="compareValue"
          control={control}
          rules={{ required: true }}
          customInput={TextField}
          defaultValue=""
        />
        <ErrorMessage
          render={({ message }) => <Error>{message}</Error>}
          errors={errors}
          name="compareValue"
          message={t('required')}
        />
      </>
    );
  }

  if ([ComparisonTypes.Equal, ComparisonTypes.NotEqual].includes(comparison)) {
    return (
      <>
        <Controller
          render={({ onChange, value }) =>
            acceptsTypedValues ? (
              <TextField
                label={t('Value')}
                onChange={(_, val) => onChange(val)}
                value={value}
              />
            ) : (
              <LoadedValuesInput
                onChange={(values) => {
                  onChange(values);
                }}
                isMulti={false}
                defaultValue={[value]}
                label={t('Value')}
                loadOptions={loadOptions}
              />
            )
          }
          label={t('Value')}
          name="compareValue"
          control={control}
          rules={{ required: true }}
        />
        <ErrorMessage
          render={({ message }) => <Error>{message}</Error>}
          errors={errors}
          name="compareValue"
          message={t('required')}
        />
      </>
    );
  }

  return (
    <>
      <Controller
        as={TextField}
        label={t('Value')}
        name="compareValue"
        control={control}
        rules={{ required: true }}
      />
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="compareValue"
        message={t('required')}
      />
    </>
  );
};

export default ComparisonValue;
