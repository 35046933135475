import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import {
  addNestedEntityRoute,
  deleteEntityRoute,
  editEntityRoute,
} from '../../../../../utils/routes';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { getSelectedDesign } from '../../../ContentLibrary/selectors';
import { getSelectedSource } from '../../selectors';
import { configureDataSourceIntent, sourceDeletedSuccess } from '../../actions';
import { routes } from '../../../../../constants/routes';
import { HeaderBarButton } from '../../../../../components/ui';

const SourcesHeader = () => {
  const dispatch = useDispatch();
  const selectedDesign = useSelector(getSelectedDesign);
  const history = useHistory();
  const { t } = useTranslation();
  const selectedSource = useSelector(getSelectedSource);

  const selectedSourceId = selectedSource?.id;

  const successCallbackFactory =
    ({ reloadFields }: { reloadFields: boolean }) =>
    (newEntityId: string) => {
      dispatch(
        configureDataSourceIntent({
          historyGoBack: () => history.go(-1),
          dataSourceId: newEntityId,
          reloadFields,
        })
      );
    };

  const handleEdit = () => {
    const path = editEntityRoute(TYPE_IDS.DataSource, selectedSourceId);
    history.push(path, {
      typeName: 'Source',
      successCallback: successCallbackFactory({ reloadFields: true }),
    });
  };

  const handleDelete = () => {
    const path = deleteEntityRoute(TYPE_IDS.DataSource, selectedSourceId);
    const goBackPage = routes.designer.ingestion.sources;

    history.push(path, {
      goBackPage,
      entityName: selectedSource.name,
      deleteDescription: (
        <Trans
          i18nKey="wizard:deleteNestedEntity"
          values={{
            name: selectedSource.name,
            asset: t('entityTypes:DataSource'),
          }}
        />
      ),
      successCallback: () => {
        dispatch(sourceDeletedSuccess(selectedSource.id));
      },
    });
  };

  const handleAdd = () => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.DataSource,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesign.id,
    });

    history.push(path, {
      typeName: 'Source',
      successCallback: successCallbackFactory({ reloadFields: false }),
    });
  };

  const handleRefresh = React.useCallback(() => {
    dispatch(
      configureDataSourceIntent({
        historyGoBack: () => null,
        dataSourceId: selectedSource.id,
        reloadFields: true,
      })
    );
  }, [selectedSource]);

  return (
    <>
      <ItemActionsHeader
        isItemSelected={!!selectedSource}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
      />
      <HeaderBarButton
        tooltip={<Trans t={t} i18nKey="actionBar:general:refresh" />}
        onClick={handleRefresh}
        iconProps={{ iconName: 'Refresh' }}
        disabled={!selectedSource}
        shouldHideOnDisabled
      />
    </>
  );
};

export default SourcesHeader;
