import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

/**
 * Just as useEffect but leverages lodash IsEqual to assert a deep compartion
 * on the dependencies
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list change.
 */

export const useDeepCompareEffect : typeof useEffect = (effect, deps) => {
  const memoizedDeps = deps.map(useDeepCompareMemoize);
  useEffect(
    effect,
    memoizedDeps,
  );
};
