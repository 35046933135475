import { AxiosResponse } from 'axios';
import * as effects from 'redux-saga/effects';
import { AssetProcessStatus } from '../../../api/model/AssetProcessStatus';

import { ApiV4ResponseWrapper, IDesignStream } from '../../Designer/types';

import * as StreamsActions from '../actions';
import { fetchStreams } from '../api';
import { checkWorkbookSelection } from './checkWorkbookSelection';

export function* handleFetchStreams() {
  yield effects.put(StreamsActions.setStreamsPending());

  try {
    const {
      data: { data: streams },
    }: AxiosResponse<ApiV4ResponseWrapper<IDesignStream[]>> =
      yield effects.call(fetchStreams, {
        orderBy: 'name asc',
        filter: `processStatus ne '${AssetProcessStatus.NotProcessed}'`,
      });

    if (!streams || streams.length === 0) {
      yield effects.put(StreamsActions.setStreamsSuccess([]));

      return;
    }

    yield effects.put(StreamsActions.setStreamsSuccess(streams));
    yield effects.call(checkWorkbookSelection);
  } catch (error) {
    yield effects.put(StreamsActions.setStreamsError(error));
    console.log('saga error', error);
  }
}

export function* rootSaga() {
  yield effects.all([
    effects.takeLatest(StreamsActions.fetchAllStreams.type, handleFetchStreams),
  ]);
}

export default rootSaga;
