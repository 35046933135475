import { mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../theme';

export const classNames = mergeStyleSets({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    position: 'relative',
    flexGrow: 1,
    overflow: 'hidden',
    maxHeight: '100%',
    overflowY: 'auto',
  },
  textWrapper: {
    maxWidth: '200px',
  },
  textStyles: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerStyles: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '10px',
  },
  descriptionStyles: {
    fontSize: '12px',
    selectors: {
      strong: {
        color: baseColors.orange,
      },
    },
  },
});
