import React from 'react';
import { useSelector } from 'react-redux';
import { FontIcon } from '@fluentui/react';
import { selectors } from '../../Streams';
import { baseColors } from '../../../../theme';
import { ObjectTypes } from '../../../App/types';
import { getActiveObjectType } from '../../../App/selectors';

const ActiveObjectIcon = () => {
  const currentDataset = useSelector(selectors.getCurrentDataset);
  const activeObjectType = useSelector(getActiveObjectType);

  const isGroupTable = currentDataset
    ? currentDataset.type === ObjectTypes.GROUP_TABLE
    : activeObjectType === ObjectTypes.GROUP_TABLE;

  const isTable = currentDataset
    ? currentDataset.type === ObjectTypes.TABLE
    : activeObjectType === ObjectTypes.TABLE;

  if (!isTable && !isGroupTable) return null;

  const getIcon = () => (isTable ? 'SynergiesTable' : 'SynergiesGroupTable');

  return (
    <FontIcon
      iconName={getIcon()}
      style={{
        fontSize: '16px',
        width: '16px',
        position: 'absolute',
        bottom: '-25px',
        right: '7px',
        color: baseColors.inputBorder,
        zIndex: 2,
      }}
    />
  );
};

export default ActiveObjectIcon;
