import { fetchTypeSections } from '../modules/Designer/api';
import { ITypeSectionProperty } from '../modules/Designer/types';

interface Params {
  designId: string;
  typeId: string;
}

export default async ({ designId, typeId }:Params) => {
  const response = await fetchTypeSections(typeId)({ designId });

  const allProperties = response.data.reduce<ITypeSectionProperty[]>((acc, currentSection) => {
    const currentSectionProperties = currentSection.properties;
    acc.push(...currentSectionProperties);

    return acc;
  }, []);

  return allProperties;
};
