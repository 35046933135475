import React from 'react';
import {
  Dropdown, IDropdownProps, IDropdownStyles,
} from '@fluentui/react';
import merge from 'lodash/merge';

import { baseColors } from '../../../theme';

const baseStyles: Partial<IDropdownStyles> = {
  root: {
    flex: 1,
  },
  dropdownItem: {
    padding: '0 16px',
    minHeight: 28,
    fontSize: 12,
  },
  dropdownItemSelected: {
    padding: '0 16px',
    minHeight: 28,
    fontSize: 12,
  },
  dropdownItemHeader: {
    color: baseColors.midOrange,
    fontWeight: 'normal',
    lineHeight: 24,
    height: 24,
  },
};

const StyledDropdown = ({ styles, ...rest }: IDropdownProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dropdown {...rest} styles={merge(styles, baseStyles)} />
);

export default StyledDropdown;
