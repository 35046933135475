import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, CommandButton, Spinner } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useFetchEntities } from './useFetchEntities';
import { classNames } from './styles';
import DataSourceIcon from '../../../../components/DataSourceIcon';
import { numberToHexColor } from '../../../../components/ui/ColorPicker/utils';
import { createGetSourceData } from '../../selectors';
import { toggleValue } from '../../../../utils/toggleValue';
import { assetsDataGroupClicked } from '../../actions';
import { StreamAssetType } from '../../types';

import { MultipleIslandsResultProps } from './types';

export const MultipleIslandResults = ({
  dataSourceId,
  islands,
  normalizedStreamLinksByLinkId,
  isLastResultOpen,
  setClosedResults,
  areResultsClosed,
}: MultipleIslandsResultProps) => {
  const { isLoading: isLoadingEntities, normalizedEntities } =
    useFetchEntities(dataSourceId);
  const dispatch = useDispatch();
  const history = useHistory();
  const getSourceData = React.useMemo(createGetSourceData, []);

  // STATE
  const [includedSolutions, setIncludedSolutions] = React.useState<string[]>(
    []
  );
  const sourceData = useSelector((state) => getSourceData(state, dataSourceId));

  // DERIVED STATE
  const filteredIslands = React.useMemo(
    () => islands.filter((island) => island.selectedLinks.length > 0),
    []
  );
  const hasSelectedAnySolution = includedSolutions.length > 0;

  // CALLBACKS
  const handleClose = React.useCallback(() => {
    setClosedResults((prev) => [...prev, dataSourceId]);
    if (isLastResultOpen) {
      history.go(-1);
    }
  }, [dataSourceId, isLastResultOpen]);

  const handleAcceptSolution = React.useCallback(() => {
    const entities = filteredIslands
      .filter((_, index) => includedSolutions.includes(String(index)))
      .flatMap((island) => island.relatedFields)
      // skip links already included in Stream (as StreamLinks)
      .filter((linkField) => !normalizedStreamLinksByLinkId[linkField.parentId])
      // StreamLink requires Link id, not LinkFields id
      .map((linkFields) => ({
        id: linkFields.parentId,
      }));

    dispatch(
      assetsDataGroupClicked({
        entities,
        // enforce ADD
        selectedEntitiesIds: [],
        assetType: StreamAssetType.Link,
      })
    );

    handleClose();
  }, [normalizedStreamLinksByLinkId, includedSolutions, islands]);

  // RENDER
  if (areResultsClosed) return null;

  return (
    <div className={classNames.wrapper}>
      {isLoadingEntities ? (
        <Spinner />
      ) : (
        <>
          <div className={classNames.boxHeader}>
            <div className={classNames.sourceWrapper}>
              <DataSourceIcon color={numberToHexColor(sourceData?.color)} />
              <p className={classNames.sourceName}>{sourceData?.name}</p>
            </div>
            <div>
              {hasSelectedAnySolution && (
                <CommandButton
                  iconProps={{ iconName: 'Accept' }}
                  onClick={handleAcceptSolution}
                  className={classNames.controlButton}
                />
              )}
              <CommandButton
                iconProps={{ iconName: 'Cancel' }}
                onClick={handleClose}
                className={classNames.controlButton}
              />
            </div>
          </div>

          <article className={classNames.explanationWrapper}>
            <p className={classNames.explanationText}>
              <Trans i18nKey="builderLinksAnalysis:multipleIslandExplanation1" />
            </p>
            <p className={classNames.explanationText}>
              <Trans i18nKey="builderLinksAnalysis:multipleIslandExplanation2" />
            </p>
          </article>

          {filteredIslands.map((singleIsland, index) => (
            <div
              key={index}
              style={{
                border: '1px solid #eee',
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {singleIsland.relatedFields.map((linkField) => (
                  <div key={linkField.id}>
                    <div className={classNames.linkWrapper}>
                      <p className={classNames.linkEntities}>
                        {`${
                          normalizedEntities[linkField.from.parentId]?.name
                        } and ${
                          normalizedEntities[linkField.to.parentId]?.name
                        }`}
                      </p>
                      <p
                        className={classNames.linkFields}
                      >{`${linkField.from.name} = ${linkField.to.name}`}</p>
                    </div>
                  </div>
                ))}
              </div>
              <Checkbox
                checked={includedSolutions.includes(String(index))}
                onChange={() =>
                  setIncludedSolutions(
                    toggleValue(includedSolutions, String(index))
                  )
                }
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
