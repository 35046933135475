import React from 'react';

import { IColumnWithUuid } from './types';

export const multiSelect = (
  items: IColumnWithUuid[],
  selectedItemsIds: string[],
  newItemId: string,
): string[] | null => {
  // Nothing already selected
  if (!selectedItemsIds.length) {
    return [newItemId];
  }

  const indexOfNew = items.findIndex((item) => item.uuid === newItemId);

  const lastSelected = selectedItemsIds[selectedItemsIds.length - 1];
  const indexOfLast = items.findIndex((item) => item.uuid === lastSelected);

  // nothing to do here
  if (indexOfLast === indexOfNew) return null;

  const isSelectingForwards = indexOfNew > indexOfLast;
  const start = isSelectingForwards ? indexOfLast : indexOfNew;
  const end = isSelectingForwards ? indexOfNew : indexOfLast;

  const inBetween = items.slice(start, end + 1);
  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd = inBetween
    .filter((taskId) => {
      // if already selected: then no need to select it again
      if (selectedItemsIds.includes(taskId.uuid)) {
        return false;
      }
      return true;
    })
    .map((item) => item.uuid);

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
  const combined = [...selectedItemsIds, ...sorted];

  return combined;
};

type DraggableLocation = {
  index: number;
  droppableId: string;
};

interface ReorderArguments {
  items: IColumnWithUuid[];
  selectedItemsIds: string[];
  source: DraggableLocation;
  destination: DraggableLocation;
}

export const wasMultiSelectKeyUsed = (
  event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
) => event.shiftKey;

export const wasToggleInSelectionGroupKeyUsed = (
  event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
) => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
  return isUsingWindows ? event.ctrlKey : event.metaKey;
};

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reorderSingleDrag = ({
  items,
  source,
  destination,
}: ReorderArguments) => {
  const reordered = reorder(items, source.index, destination.index);

  return { processedItems: reordered };
};

const reorderMultiDrag = ({
  items,
  source,
  destination,
  selectedItemsIds,
}: ReorderArguments) => {
  const dragged = items[source.index];

  const selectedItemIndexes = selectedItemsIds.map((uuid) => items.findIndex((item) => item.uuid === uuid));

  // prevent reorder if droppinig within selected items
  if (selectedItemIndexes.includes(destination.index)) { return { processedItems: items }; }

  // const insertAtIndex = destination.index;

  const insertAtIndex = (() => {
    const destinationIndexOffset = selectedItemsIds.reduce(
      (accumulator, currentTaskUuid) => {
        if (currentTaskUuid === dragged.uuid) {
          return accumulator;
        }

        const currentTaskIndex = items.findIndex(
          (item) => item.uuid === currentTaskUuid,
        );

        if (currentTaskIndex >= destination.index) {
          return accumulator;
        }

        return accumulator + 1;
      },
      0,
    );

    return destination.index - destinationIndexOffset;
  })();

  const withRemovedSelectedItems = items.filter(
    (item) => !selectedItemsIds.includes(item.uuid),
  );

  const selectedItems = selectedItemsIds
    .map((uuid) => items.findIndex((item) => item.uuid === uuid))
    // we need to sort those indexes because
    // user may use cmd+click to select multiple items
    // in random order, but we want to insert them in the
    // same order as they appear on screen
    .sort((a, b) => a - b)
    .map((index) => items[index]);

  const withInserted = (() => {
    const base = [...withRemovedSelectedItems];
    base.splice(insertAtIndex, 0, ...selectedItems);
    return base;
  })();

  return { processedItems: withInserted };
};

interface ProcessedResult {
  processedItems: IColumnWithUuid[];
}

export const multiDragAwareReorder = (
  args: ReorderArguments,
): ProcessedResult => {
  if (args.selectedItemsIds.length > 1) {
    return reorderMultiDrag(args);
  }
  return reorderSingleDrag(args);
};
