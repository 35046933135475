import React from 'react';
import { useLocation } from 'react-router-dom';

export const PATHNAME_STORAGE_KEY = '@addin/pathname';

export const useStoringPathnameInLocalStorage = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.localStorage.setItem(PATHNAME_STORAGE_KEY, pathname);
  }, [pathname]);
};
