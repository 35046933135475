import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, Label, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { QueryOptions } from 'odata-query';
import {
  CustomConditionRelatedProperty,
  IControlledField,
  IGenericEntity,
  SchemaControlType,
} from '../../types';
import * as api from '../../../api';
import { ApiV4ResponseWrapper } from '../../../types';
import { categorizedEntitiesToOptions } from '../SelectOneOfType/utils';
import { labelStyles } from './styles';
import { SelectDropdown } from '../../../../../components/ui';
import { findCurrentOptionFromGroupedOptions } from '../../../../../components/ui/SelectDropdown/utils';
import { translateApiName } from '../../../../../config/i18n/utils';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { i18nNameKey } from '../../../../../api/interceptors/i18n/constants';
import { getEntityById } from '../../selectors';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { handleCalculationChange } from '../../utils';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';

const entitiesToOptions = (entities: IGenericEntity[] = []) =>
  entities.map((entity) => ({
    value: entity.id,
    data: entity,
    label:
      entity[i18nNameKey] ??
      (entity.isLocked
        ? translateApiName(entity.$typeId, entity.name)
        : entity.name) ??
      entity.id,
  }));

interface Option {
  label: string;
  value: string;
  data?: IGenericEntity;
}

interface GroupOption {
  label: string;
  options: Option[];
}

const AsyncSelectOne: React.FC<IControlledField> = ({
  controller,
  currentForm,
  entityProperty,
  currentEntity,
  interaction,
  isDisabled,
  label,
  isEditing,
}) => {
  // HOOKS
  const { t } = useTranslation();
  const { trigger } = useFormContext();
  const { setPreventSubmission, setShouldSkipFollowingSections } =
    useFormSubmission();

  // STATE
  const designId = useSelector(getSelectedDesignId);
  const [options, setOptions] = useState<(Option | GroupOption)[]>([]);
  const parentEntity = useSelector(
    getEntityById(currentForm?.parentEntityId || currentEntity?.parentId)
  );

  // DERIVED STATE
  const { name, onChange, ref, value } = controller;
  const isMulti = interaction.controlType === SchemaControlType.SelectMultiple;
  const selected = useMemo(() => {
    if (options.some((option: GroupOption) => option?.options)) {
      return isMulti
        ? (value || []).map((val) =>
            findCurrentOptionFromGroupedOptions(val, options as GroupOption[])
          )
        : findCurrentOptionFromGroupedOptions(value, options as GroupOption[]);
    }
    return isMulti
      ? (options as Option[]).filter((option) =>
          (value || []).includes(option.value)
        )
      : (options as Option[]).find((option) => option.value === value);
  }, [options, value]);

  const helpUri = !isMulti && selected?.data?.helpUri;
  const { isReadOnly, canPreSelect, isEditableAfterCreation } = entityProperty;
  const {
    referenceType,
    isRequired,
    referenceTypeFilterValueProperty,
    referenceTypeFilterProperty,
    referenceTypeFilterValue,
    conditionRelatedProperty,
  } = interaction;

  const shouldApplyReferenceTypeFilterValue =
    conditionRelatedProperty ===
      CustomConditionRelatedProperty['parent/IsWritable'] &&
    !parentEntity?.isWritable &&
    referenceTypeFilterValue;

  const filterRefProp = useWatch({
    name: referenceTypeFilterValueProperty || '',
  });

  const sortedOptions = React.useMemo(() => {
    const currentOptions = options
      .sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { numeric: true })
      )
      .map((option) => {
        if ('options' in option) {
          return {
            ...option,
            options: option.options.sort((a, b) =>
              a.label.localeCompare(b.label, undefined, { numeric: true })
            ),
          };
        }

        return option;
      });

    if (!isRequired && !interaction?.defaultValue) {
      // If the property is not required and doesnt have
      // a default value, we give the user an option
      // clear a selected value
      currentOptions.unshift({
        label: t('filters:Select'),
        value: null,
      });
    }

    return currentOptions;
  }, [options]);

  // EFFECTS
  useEffect(() => {
    if (referenceTypeFilterValueProperty && !filterRefProp) {
      return;
    }

    const getOdataParams = (): Partial<QueryOptions<unknown>> => {
      // handle https://dev.azure.com/synergiesio/OnSynergies/_boards/board/t/Engineering/Stories/?workitem=7657
      if (referenceType === TYPE_IDS.CalculationType) {
        const sqlCalcsFilter = 'daxTemplate ne null';
        return {
          filter: shouldApplyReferenceTypeFilterValue
            ? `${referenceTypeFilterValue} and ${sqlCalcsFilter}`
            : sqlCalcsFilter,
        };
      }

      if (shouldApplyReferenceTypeFilterValue) {
        return {
          filter: referenceTypeFilterValue,
        };
      }

      if (referenceTypeFilterProperty) {
        return {
          filter: {
            [referenceTypeFilterProperty]: {
              eq: { type: 'guid', value: filterRefProp },
            },
          },
        };
      }
      return undefined;
    };

    (async () => {
      try {
        let data: IGenericEntity[];
        if (referenceType === TYPE_IDS.CalculationType) {
          data = (
            await api.fetchTypes<ApiV4ResponseWrapper<IGenericEntity[]>>({
              typeId: referenceType,
              designId,
            })(getOdataParams())
          )?.data?.data;
        } else {
          data = (
            await api.fetchEntities<ApiV4ResponseWrapper<IGenericEntity[]>>(
              referenceType
            )(getOdataParams())
          )?.data?.data;
        }
        const areCategorized = data?.some((entity) => entity.category);
        setOptions(
          areCategorized
            ? categorizedEntitiesToOptions(data)
            : entitiesToOptions(data)
        );
      } catch {
        setOptions([]);
      }
    })();
  }, [filterRefProp, interaction, shouldApplyReferenceTypeFilterValue]);

  useEffect(() => {
    // On the Stream wizard, we need to trigger form validation every
    // time the design changes
    if (currentForm.typeId === TYPE_IDS.Stream) {
      trigger();
    }
    // Calculations submission btn changes its behavior based on selected
    // calculationType. If the calculation type doesn't introduce further sections
    // into the wizard, instead of the 'next page' btn, we display the 'submit form' btn

    if (referenceType === TYPE_IDS.CalculationType) {
      handleCalculationChange({
        setPreventSubmission,
        setShouldSkipFollowingSections,
        entityId: value,
        designId,
      });
    }
  }, [value]);

  // CALLBACKS

  const handleChange = (opt: Option | Option[]) => {
    if (isMulti) {
      onChange(((opt as Option[]) || []).map(({ value: val }) => val));
    } else {
      onChange((opt as Option).value);
    }
  };

  // RENDER

  return (
    <>
      <Stack horizontal verticalAlign="center">
        <Label styles={labelStyles} required={isRequired}>
          {label}
        </Label>
        {helpUri && (
          <IconButton
            // eslint-disable-next-line i18next/no-literal-string
            target="_blank"
            href={helpUri}
            iconProps={{
              iconName: 'Info',
            }}
          />
        )}
      </Stack>
      <Stack>
        <SelectDropdown
          ref={ref}
          options={sortedOptions}
          placeholder={t('filters:Select')}
          isDisabled={
            isDisabled ||
            (isReadOnly && !canPreSelect) ||
            (!isEditableAfterCreation && isEditing) ||
            !options.length
          }
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          onChange={handleChange}
          value={selected}
          menuPlacement="bottom"
          maxMenuHeight={200}
          className={`select-dropdown-${name}`}
        />
      </Stack>
    </>
  );
};

export default AsyncSelectOne;
