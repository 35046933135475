import { IButtonStyles, ITheme } from '@fluentui/react';
import { IStackStyles, IToggleStyles } from 'office-ui-fabric-react';
import { baseColors, BORDER_RADIUS_IN_PX } from '../../../../theme';

const footerStackStylesHeight = '24px';

export const footerStackStyles: IStackStyles = {
  root: {
    borderTop: `1px solid ${baseColors.outlineGray}`,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: footerStackStylesHeight,
    position: 'absolute',
    bottom: 11,
    left: 11,
    width: 'calc(100% - 22px)',
    backgroundColor: baseColors.hover,
    borderBottomLeftRadius: BORDER_RADIUS_IN_PX,
    borderBottomRightRadius: BORDER_RADIUS_IN_PX,
  },
};

export const getStyles = (theme: ITheme) => ({
  container: {
    height: '100%',
    maxHeight: '100%',
    margin: 10,
    border: `1px solid ${baseColors.outlineGray}`,
    borderRadius: BORDER_RADIUS_IN_PX,
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  sidebar: {
    position: 'relative',
    borderRight: `1px solid ${baseColors.outlineGray}`,
  },
  labelWrap: {
    flexShrink: '0!important',
  },
  label: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    color: baseColors.outlineGray,
  },
  total: {
    selectors: {
      ':hover button': {
        visibility: 'visible',
      },
      ':hover > div': {
        borderColor: theme.palette.themePrimary,
      },
    },
  },
  totalLabel: {
    border: `1px solid ${baseColors.outlineGray}`,
    flexGrow: 1,
    color: theme.palette.themePrimary,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    padding: 5,
  },
  totalIcon: {
    fontSize: 16,
  },
  removeTotal: {
    visibility: 'hidden',
  },
  sequenceWrapper: {
    height: '100vh',
    marginBottom: footerStackStylesHeight,
    maxHeight: `calc(100% - ${footerStackStylesHeight})`,
    overflowY: 'auto',
  },
  toggleStack: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    height: 16,
    width: 16,
    marginLeft: 3,
  },
});

export const addBtnStyles:IButtonStyles = ({
  root: {
    alignSelf: 'flex-end',
    padding: 0,
    width: 'auto',
  },
});

export const rightStackStyles: IStackStyles = {
  root: {
    flex: 1,
  },
};

export const toggleStyles:Partial<IToggleStyles> = {
  root: {
    margin: 0,
  },
  label: {
    order: 'initial',
    marginRight: 8,
    marginLeft: 8,
    fontSize: 12,
    fontWeight: 'normal',
    paddingTop: 2,
    paddingBottom: 2,
  },
  pill: {
    marginRight: 8,
    height: 16,
    width: 32,
    paddingLeft: 2,
    paddingRight: 2,
  },
  thumb: {
    width: 10,
    height: 10,
    fontSize: 10,
  },
};
