import { isEqual } from 'lodash';
import { IColumn, IColumnAggregationMethods } from '../../../../types/IColumn';
import { IDataset } from '../../../../types/IDataset';
import { AGGREGATION_METHODS, IAggregationMethod } from '../../../../constants/aggregations';
import { ICustomAggregation } from '../../../../types/ICustomAggregation';
import { IFilter } from '../../../../types/IFilter';

/**
 * When two columns are equal but on their aggregations, they're technically different columns.
 */

export const areColumnsEqual = (columnA:IColumn | undefined, columnB:IColumn | undefined) => (
  columnA?.id === columnB?.id
    ? columnA?.aggregation === columnB?.aggregation
    : false
);

export const getColumnFilters = (column: IColumn, dataset: IDataset) => {
  if (dataset) {
    return [...dataset.columns, ...dataset.filterColumns]
      .find((col) => areColumnsEqual(col, column))
    ?.filters || [];
  }
  return [];
};

export const sortColumnsAlphabetically = (columns : IColumn[]) => columns.sort((a, b) => {
  const aName = a.name.toUpperCase();
  const bName = b.name.toUpperCase();
  return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
});

export const getColumnAggregations = (column: IColumn, dataset: IDataset) => {
  const aggregations : IAggregationMethod[] = [];

  dataset?.columns?.forEach((col) => {
    if (areColumnsEqual(col, column)) {
      aggregations.push(col.aggregation);
    }
  });

  return aggregations;
};

type Params = {
  column: IColumn;
  dataset: IDataset
};
// eg user clicked on a column which based aggregation is Sum,
// selected aggregations are Sum, Avg, Min, Max
// we want to get all associated IColumns
export const getAllAssociatedDatasetColumns = ({
  column, dataset,
}: Params) => dataset?.columns.filter(
  (currentColumn) => currentColumn.dataEntityId === column.dataEntityId
      && currentColumn.id === column.id
      && currentColumn.parentEntityName === column.parentEntityName
      && currentColumn.dataSourceId === column.dataSourceId,
) || [];

export const isActiveAggregationMapped = (column: IColumn, dataset: IDataset, customAggregations : ICustomAggregation[]) => {
  if (!dataset) {
    return false;
  }
  const customAggregation = customAggregations.find((customAgg) => areColumnsEqual(customAgg.column, column));
  const aggregations = getColumnAggregations(column, dataset);
  if (customAggregation) {
    return aggregations.includes(customAggregation.aggregation);
  }
  return aggregations.includes(column.aggregation);
};

export const isColumnInDataset = (column: IColumn, dataset: IDataset) => {
  if (!dataset) return false;

  return !!dataset.columns.find((c) => c.id === column.id && c.dataEntityId === column.dataEntityId && c.parentEntityName === column.parentEntityName);
};

export const isAggregationMapped = (column: IColumn, dataset: IDataset, aggregation: IColumnAggregationMethods) => {
  if (!dataset) return false;

  return !!dataset.columns.find((c) => c.aggregation === aggregation && c.id === column.id);
};

export const getFilteringColumns = (columns: IColumn[]) => columns.filter((column) => column.filters.length);

export const getNonRepeatingColumns = (columns: IColumn[], compared: IColumn[]) => columns.filter((column) => !compared.some((col) => areColumnsEqual(column, col)));

export const mergeFiltersArrays = (...filters : IFilter[][]) => filters.flat().reduce((src : IFilter[], filter) => {
  if (src.some((srcFilter) => isEqual(filter, srcFilter))) {
    return src;
  }
  return [...src, filter];
}, []);

export const mergeColumnsArrays = (...columns : IColumn[][]) => columns.flat().reduce((src, column) => {
  let isRepeated = false;
  for (let i = 0; i < src.length; i++) {
    if (areColumnsEqual(src[i], column)) {
      if (column.filters.length) {
        // eslint-disable-next-line no-param-reassign
        src[i].filters = mergeFiltersArrays(src[i].filters, column.filters);
      }
      isRepeated = true;
      break;
    }
  }
  if (isRepeated) {
    return src;
  }
  return [...src, column];
}, []);

export const removeColumnsAggregation = (columns: IColumn[]) => columns.map(
  (col) => ({ ...col, aggregation: AGGREGATION_METHODS.null }),
);

export const isColumnNumeric = (c: Partial<IColumn>) => c.dataType === 'NumericDecimal' || c.dataType === 'NumericInteger';
