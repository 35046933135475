import {
  IButtonStyles,
  IStackStyles,
  ITextStyles,
  ISpinnerStyles,
} from '@fluentui/react';
import React from 'react';
import { Availability } from '../../api/model/schemas/Availability';
import {
  ENTITY_FIELD_SLOT_WIDTH,
  ENTITY_FIELD_HEIGHT_IN_PX,
} from '../../constants/design';
import { infoButtonClassname } from '../../hooks/use-information-dialog/styles';
import { baseColors } from '../../theme';
import { renameButtonClassName } from '../EditableFoldableListItem/styles';

interface FieldStyleProps {
  isSelected: boolean;
  isOrange: boolean;
  isInfoOpen: boolean;
  isSelectable: boolean;
  isDisabled: boolean;
}

const computeBgColor = ({
  isInfoOpen,
  isSelected,
}: {
  isSelected: boolean;
  isInfoOpen: boolean;
}) => (isSelected || isInfoOpen ? baseColors.selected : 'transparent');

const computeTextColor = ({
  isDisabled,
  isOrange,
}: {
  isDisabled: boolean;
  isOrange: boolean;
}) => {
  if (isDisabled) {
    return `${baseColors.iconColor}80`;
  }

  return isOrange ? baseColors.orange : baseColors.textColor;
};

export const containerStyles = ({
  isSelected,
  isOrange,
  isInfoOpen,
  isSelectable,
  isDisabled,
}: FieldStyleProps): IStackStyles => ({
  root: {
    maxWidth: '100%',
    backgroundColor: computeBgColor({
      isInfoOpen,
      isSelected,
    }),
    height: ENTITY_FIELD_HEIGHT_IN_PX,
    color: computeTextColor({
      isDisabled,
      isOrange,
    }),
    cursor: isSelectable ? 'pointer' : 'normal',
    padding: '0',
    selectors: {
      '&:hover': {
        backgroundColor:
          isSelected || isInfoOpen
            ? baseColors.selectedWithHover
            : baseColors.hover,
      },
      [`.${infoButtonClassname}`]: {
        opacity: isInfoOpen ? 1 : 0,
      },
      [`.${renameButtonClassName}`]: {
        opacity: 0,
      },
      [`&:hover .${renameButtonClassName}`]: {
        opacity: 1,
      },
      [`&:hover .${infoButtonClassname}`]: {
        opacity: 1,
      },
      // Those styles are needed to enable hover on child element
      // (ColumnAggregationButton) - when field is not yet selected
      ':focus i': {
        visibility: 'visible',
      },
      ':hover i': {
        visibility: 'visible',
      },
    },
  },
});

export const infoButtonStyles = (isInfoOpen: boolean): IButtonStyles => ({
  root: {
    fontSize: 16,
    marginLeft: 'auto !important',
    maxHeight: 24,
    width: 24,
    padding: 0,
    borderRadius: 4,
    backgroundColor: isInfoOpen ? 'white' : 'transparent',
  },
  icon: {
    color: `${baseColors.iconColor} !important`,
  },
  rootHovered: {
    backgroundColor: baseColors.selected,
  },
  rootFocused: {
    backgroundColor: baseColors.selectedWithHover,
  },
  flexContainer: {
    alignItems: 'center',
  },
});

export const slotStyles: IStackStyles = {
  root: {
    width: ENTITY_FIELD_SLOT_WIDTH,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const fieldNameStyles: IStackStyles = {
  root: {
    paddingLeft: 0,
    flex: 1,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const availabilityIconStyles = (
  availability: Availability
): React.CSSProperties => {
  switch (availability) {
    case Availability.Error:
      return {
        color: baseColors.red,
      };
    case Availability.NotAvailable:
      return {
        color: baseColors.midOrange,
      };
    default:
      return {
        display: 'none',
      };
  }
};

export const warningWrapper: React.CSSProperties = {
  position: 'absolute',
  left: '0',
  right: '0',
  bottom: '0',
  top: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export const availabilityTextStyles = (
  availability: Availability
): React.CSSProperties => {
  // Until backend bug is resolved, we are not showing this style
  switch (availability) {
    case Availability.Error:
      return null;
    // return {
    //   backgroundColor: baseColors.red,
    //   color: baseColors.white,
    //   padding: '0px 4px',
    //   borderRadius: '4px',
    // };
    case Availability.NotAvailable:
      return null;
    // return {
    //   backgroundColor: baseColors.midOrange,
    //   color: baseColors.white,
    //   padding: '2px',
    //   borderRadius: '4px',
    // };
    default:
      return null;
  }
};
export const textStyles: ITextStyles = {
  root: {
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export const spinnerStyles: ISpinnerStyles = {
  root: {
    width: '32px',
    height: '100%',
  },
  circle: {
    width: '16px',
    height: '16px',
  },
};

export const buttonStyles: IButtonStyles = {
  root: {
    maxHeight: 24,
    width: 24,
    borderRadius: 4,
    selectors: {
      '.ms-Icon': {
        margin: 0,
      },
    },
  },
  rootHovered: {
    backgroundColor: baseColors.selected,
  },
};

export const sureTextStyles: ITextStyles = {
  root: {
    backgroundColor: baseColors.midOrange,
    textTransform: 'uppercase',
    padding: '2px 4px',
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 600,
  },
};

export const deleteConfirmationStyles: IStackStyles = {
  root: {},
};
