// TODO: move into i18n/types
import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../../modules/Designer/types";
import { DataSource } from "../../../../model/schemas/DataSource";
import { TypeIds } from "../../../../model/schemas";
import { isResponseTypeIdMatching } from "../../utils";
import { i18nTypeKey, i18nCategoryKey } from "../../constants";
import { translateApiName } from "../../../../../config/i18n/utils";

export const isDataSourcesResponse = (
  response: AxiosResponse<ApiV4ResponseWrapper<unknown>>
): response is AxiosResponse<ApiV4ResponseWrapper<DataSource[]>> =>
  isResponseTypeIdMatching(response, TypeIds.DataSource);

export const mutablyParseDataSources = (response: AxiosResponse<ApiV4ResponseWrapper<DataSource[]>>) => {
  response.data.data = response.data?.data?.map(dataSource => {
    return {
      ...dataSource,
      [i18nCategoryKey]: translateApiName(dataSource.$typeId, dataSource.category),
      // the translations for each data source type are present under the connector's type id
      [i18nTypeKey]: translateApiName(dataSource.$connectorTypeId, dataSource.type),
    };
  });

  return response;
}
