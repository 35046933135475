import React from 'react';
import { useSelector } from 'react-redux';
import { getErrors } from '../../../Notifications/selectors';
import { getCurrentUserEmail } from '../../../User/selectors';
import { ErrorFormRaw } from './ErrorFormRaw';

export const ErrorForm = () => {
  const userEmail = useSelector(getCurrentUserEmail);
  const reduxErrors = useSelector(getErrors);

  return (
    <ErrorFormRaw
      prefillEmail={userEmail}
      prefilErrorDetails={JSON.stringify(reduxErrors)}
    />
  );
};
