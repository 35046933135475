import React from 'react';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';

import streamSelectors from '../../../Streams/selectors';
import { actionCreators as streamActions } from '../../../Streams/actions';

import { IColumn } from '../../../../types/IColumn';
import useActions from '../../../../utils/useActions';
import { IColumnWithUuid } from './types';
import { generateColumnUuid } from '../../utils';
import ReorderDragAndDrop from './components/ReorderDragAndDrop/ReorderDragAndDrop';

const ColumnsReorder: React.FC = () => {
  // DEPS
  const dragDropId = 'columns-reorder';
  // STATE
  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  // HOOKS
  const { setColumnsOfDataset } = useActions(streamActions);

  // DERIVED STATE
  const columns = currentDataset?.columns || [];
  const initialColumnOrder = React.useMemo(
    () =>
      currentDataset?.lastSuccessfulMapping?.columns?.reduce<
        Record<string, number>
      >(
        (prev, cur, idx) => ({
          ...prev,
          [generateColumnUuid(cur)]: idx,
        }),
        {}
      ),
    [currentDataset?.lastSuccessfulMapping?.columns]
  );

  const items = React.useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        uuid: generateColumnUuid(column),
      })),
    [columns]
  );

  // CALLBACKS

  const checkIfOrderChanged = React.useCallback(
    (item: IColumnWithUuid, index: number) =>
      initialColumnOrder[item.uuid] !== index ||
      currentDataset?.lastSuccessfulMapping?.columns[
        initialColumnOrder[item.uuid]
      ]?.isHidden !== item?.isHidden,
    [initialColumnOrder]
  );
  const updateDatasetColumns = React.useCallback(
    (newColumns: IColumnWithUuid[]) => {
      if (!currentDataset) return;

      const newColumnsWithoutUuid = newColumns.map((column) =>
        omit(column, 'uuid')
      ) as IColumn[];

      setColumnsOfDataset(currentDataset.id, newColumnsWithoutUuid);
    },
    [currentDataset?.id]
  );

  // RENDER
  return (
    <ReorderDragAndDrop
      {...{
        checkIfOrderChanged,
        dragDropId,
        updateDatasetColumns,
        items,
      }}
    />
  );
};

export default ColumnsReorder;
