import { mergeStyleSets } from "office-ui-fabric-react";
import { baseColors } from "../../../../../../theme";

export const classNames = {
  linkFields: 'link-fields',
  linkFieldsSelected: 'link-fields-selected',
  actionButtonList: 'action-button-list',
  actionButton: 'action-button',
  actionButtonIcon: 'action-button-icon',
};

export const linkFieldsStyles = mergeStyleSets({
  [classNames.linkFields]: [
    classNames.linkFields,
    {},
  ],
  [classNames.linkFieldsSelected]: [
    classNames.linkFieldsSelected,
    {},
  ],
  [classNames.actionButtonList]: [
    classNames.actionButtonList,
    {
      display: 'none',
      [`.${classNames.linkFields}:hover &, .${classNames.linkFieldsSelected} &`]: {
        display: 'block',
      },
    },
  ],
  [classNames.actionButton]: [
    classNames.actionButton,
    {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      marginRight: '4px',
      '&:hover': {
        backgroundColor: baseColors.white,
      },
    },
  ],
  [classNames.actionButtonIcon]: [
    classNames.actionButtonIcon,
    {
      color: `${baseColors.offBlack} !important`,
      [`.${classNames.actionButton}:hover &`]: {
        color: `${baseColors.offBlack} !important`,
      },
    },
  ],
});
