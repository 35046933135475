import React, { useEffect } from 'react';
import { Text, Stack } from '@fluentui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectors } from '../../../Streams/Streams';
import { classNames, emptyMessageStyles } from './styles';

import StreamListShimmer from '../../../../components/StreamListShimmer';
import StreamsList from '../../components/StreamsList';

import { fetchAllStreams } from '../../../Streams/actions';
import { useTutorialTile } from '../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { tutorialTileConfig, TUTORIAL_TILE_KEY } from './constants';

const FavoritesList = () => {
  // HOOKS
  const { t } = useTranslation(['streams', 'headers']);
  const dispatch = useDispatch();

  // STATE
  const streams = useSelector(selectors.getAvailableStreams);
  const areStreamsPending = useSelector(selectors.getAreStreamsPending);
  const isSyncing = useSelector(selectors.getIsSyncing);

  // DERIVED STATE
  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:streamer:main:cta:noStreamsAvailable':
        streams?.length === 0,
    }),
    [streams?.length]
  );

  // EFFECTS
  useEffect(() => {
    dispatch(fetchAllStreams());
  }, []);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: streams?.length === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (areStreamsPending || isSyncing) {
    return (
      <div className={classNames.container}>
        <StreamListShimmer />
      </div>
    );
  }

  if (tutorialTile) return tutorialTile;

  if (streams?.length === 0 && !areStreamsPending) {
    return (
      <Stack styles={emptyMessageStyles}>
        <Text>{t('No streams available, please check your settings')}</Text>
      </Stack>
    );
  }

  return <StreamsList streams={streams} />;
};
export default FavoritesList;
