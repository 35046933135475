import React from 'react';
import { AsyncSelectDropdown } from '../ui';

interface Props {
  isMulti: boolean,
  onChange(value: string[]): void,
  defaultValue: string[],
  label: string,
  loadOptions(inputValue: string): Promise<Array<string>>;
}

const LoadedValuesInput: React.FC<Props> = ({
  isMulti, onChange, defaultValue, label, loadOptions,
}) => {
  const getOptionValue = (option) => option;
  const getOptionLabel = (option) => option;

  return (
    <AsyncSelectDropdown
      isMulti={isMulti}
      cacheOptions
      loadOptions={loadOptions}
      onChange={onChange}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      value={defaultValue}
      label={label}
      menuPlacement="auto"
      defaultOptions
    />
  );
};

export default LoadedValuesInput;
