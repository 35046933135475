import React from 'react';
import { useSelector } from 'react-redux';
import { getSortType } from '../../../selectors';
import { DefinedSortTypes } from '../../../types';
import { AddButton, EditButton, DeleteButton } from './components';

const FilterHeader : React.FC = () => {
  const sortType = useSelector(getSortType);

  if (sortType !== DefinedSortTypes.Logic) {
    return null;
  }

  return (
    <>
      <AddButton />
      <EditButton />
      <DeleteButton />
    </>
  );
};

export default FilterHeader;
