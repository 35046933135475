import { History } from 'history';
import { StreamCalculation } from '../../api/model/schemas/StreamCalculation';
import { StreamDataRequestElement } from '../../api/model/schemas/StreamDataRequestElement';
import { StreamField } from '../../api/model/schemas/StreamField';
import { StreamGroupAsset } from '../../api/model/schemas/StreamGroupAsset';
import { StreamHub } from '../../api/model/schemas/StreamHub';
import { Stream } from '../../api/model/schemas/Stream';
import { DataRequestContinuation } from '../../api/model/schemas/DataRequestContinuation';
import { AggregationType } from '../../api/model/schemas/AggregationType';
import { ColumnFilterType } from '../../api/model/schemas/ColumnFilterType';
import { ComparisonType } from '../../api/model/schemas/ComparisonType';
import { StreamDataRequestResultColumn } from '../../api/model/schemas/StreamDataRequestsResultColumn';
import { OrderByDirection } from '../../api/model/schemas/OrderByDirection';
import { StreamLookup } from '../../api/model/schemas/StreamLookup';

export type StreamAssetRaw = {
  defaultAggregation?: null | AggregationType;
  isAggregated?: boolean;
} & (
  | StreamField
  | StreamCalculation
  | StreamGroupAsset
  | StreamHub
  | StreamLookup
);

export interface StreamerAsset {
  streamElementId: string;
  streamElementTypeId: string;
  aggregation: AggregationType;
  sortMode: OrderByDirection;
  sortPosition?: number;
}

export interface StreamDataRequestParams {
  // the data you want back, and in what sort
  // id => uuid that you'll have to match in data obj & continuation if you wish to get more
  columns: Partial<StreamDataRequestResultColumn>[];
  data: StreamDataRequestElement[];
  continuation?: DataRequestContinuation;
  filter?: any;
}

export interface StreamerState {
  streams: {
    records: Stream[];
    isLoading: boolean;
    selectedId: string;
  };
  availableAssets: {
    isLoading: boolean;
    records: StreamAssetRaw[];
  };
  selectedAssets: StreamerAsset[];
  filters: {
    selectedFilterId: string | null;
    records: StreamerFilter[];
  };
  // used to calculate diff & derive if continuation token can be used
  previouslyFetchedAssets: StreamerAsset[];
  isRetrievingOutputData: boolean;
  sendToFile: {
    hasError: boolean;
    isLoading: boolean;
    isSuccess: boolean;
  };
}

export enum StreamingStrategies {
  ISOLATED = 'ISOLATED',
  EXCEL_TABLE = 'EXCEL_TABLE',
}

type DataRequestResponse = {
  rows: string | number[][];
  rowCount: number;
  totalRowCount: number;
};

export type StrategyPayload = DataRequestResponse & {
  getNextPage(): Promise<StrategyPayload>;
};
export type CallableStrategy = (params: CallableStrategyParams) => void;

export type FetchDataResponse = {
  rows: any[][];
  totalRowCount: number;
  rowCount: number;
  getNextPage(): Promise<FetchDataResponse>;
};

export type CallableStrategyParams = FetchDataResponse & {
  metadata: PlayIntentPayload['metadata'];
};

export type FetchDataParams = {
  selectedStreamId: string;
  dataRequestParams: StreamDataRequestParams;
  continuationToken?: string;
};

export enum GroupAssetClickedActionType {
  Select = 'Select',
  Deselect = 'Deselect',
}
export type PlayIntentPayload = {
  streamingStrategy: StreamingStrategies;
  metadata: {
    history: History<any>;
  };
};

export interface StreamerFilter {
  id: string;
  aggregation: AggregationType;
  type?: ColumnFilterType;
  values?: string[];
  comparison?: StreamerFilterComparisonTypes;
  compareValue?: string;
  lowerBoundValue?: string;
  upperBoundValue?: string;
  item?: StreamAssetRaw;
}

export enum StreamerLimitedComparisons {
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull',
  IsBlank = 'IsBlank',
  IsNotBlank = 'IsNotBlank',
  Between = 'Between',
  In = 'In',
  NotIn = 'NotIn',
  NotBetween = 'NotBetween',
}

export type StreamerFilterComparisonTypes =
  | ComparisonType
  | StreamerLimitedComparisons;
