import * as effects from 'redux-saga/effects';
import { PAGE_SIZE } from '../../../actions/excel-actions/constants';
import { IColumn } from '../../../types/IColumn';
import { IInquiryData } from '../../../types/IInquiryData';
import * as actions from '../actions';
import { fetchColumnData } from './fetchColumnData';

/**
 * Fetches a complete ad-hoc inquiry, page by page.
 */

export function* fetchAllColumnDataPages(
  items: IColumn[],
  extraItems: IColumn[] = [],
) {
  let data : IInquiryData = {
    columnCount: 0,
    rowCount: 0,
    totalRowCount: 0,
    columns: [],
    rows: [],
    continuation: { pageSize: PAGE_SIZE, token: null },
  };

  let isFirst = true;

  do {
    yield effects.put(actions.incrementTotalPages(1));

    const task = yield effects.fork(
      fetchColumnData,
      items,
      data?.continuation,
      extraItems,
    );

    const [result] = yield effects.race([
      effects.join(task),
      effects.take(actions.cancelCurrentOperation.type),
    ]);

    if (result) {
      yield effects.put(actions.incrementPagesFetched(1));
      const { rows, totalRowCount, ...res } : IInquiryData = result;
      data = { ...res, totalRowCount, rows: [...data.rows, ...rows] };
      if (isFirst) {
        isFirst = false;
        const queryPageSize = Math.max(1, Math.floor(totalRowCount / PAGE_SIZE));
        if (queryPageSize > 2) {
          yield effects.put(actions.incrementTotalPages(queryPageSize - 2));
        }
      }
    } else {
      yield effects.cancel(task);
      return { ...data, cancelled: true };
    }
  }
  while (data.continuation);

  return data;
}
