import React from 'react';

import * as excelActions from '../../../../../actions/excel-actions';
import { HeaderBarButton } from '../../../../../components/ui';
import { getIsLocalDevBuild } from '../../../../../config/buildType';

const DebugIcons: React.FC = () => {
  if (!getIsLocalDevBuild()) return null;

  return (
    <>
      <HeaderBarButton
        onClick={() => {
          excelActions.debug();
        }}
        iconProps={{ iconName: 'FileCode' }}
      />
      <HeaderBarButton
        onClick={() => {
          excelActions.clearStoredSettings();
        }}
        iconProps={{ iconName: 'ClearFormatting' }}
      />
    </>
  );
};

export default DebugIcons;
