import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { chain } from 'lodash';
import { DirectionalHint } from '@fluentui/react';
import Error from '../../Error';
import streamSelectors from '../../../../../Streams/selectors';
import { SelectDropdown } from '../../../../../../components/ui';
import AggregationButton from '../../../../../../components/AggregationButton';
import { aggregationBtn, aggregationDroplistBtn } from '../../../../../Streams/components/StreamerColumn/styles';
import { getAggregations } from '../../../../../../constants/aggregations';
import { labelStyle } from '../../../../../../components/ui/SelectDropdown/customization';
import { TYPE_IDS } from '../../../../../../constants/apiV4TypeIds';

interface Props {
  isEditing?: boolean;
}

const ColumnSection: React.FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation();

  const {
    control, errors, reset,
  } = useFormContext();

  const {
    availableColumns,
  } = useSelector(streamSelectors.getStreamsState);

  const getOptionValue = (option) => option.id;
  const getOptionLabel = (option) => option.name;

  const sourceFields = availableColumns
    .filter(
      (f) => f.streamElementTypeId === TYPE_IDS.StreamField,
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  const calculations = availableColumns
    .filter(
      (f) => f.streamElementTypeId === TYPE_IDS.StreamCalculation,
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  const hubs = availableColumns
    .filter(
      (f) => f.streamElementTypeId === TYPE_IDS.StreamHub,
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  const groups = availableColumns
    .filter(
      (f) => f.streamElementTypeId === TYPE_IDS.StreamGroupAsset,
    )
    .sort((a, b) => a.name.localeCompare(b.name));
  const links = availableColumns
    .filter(
      (f) => f.streamElementTypeId === TYPE_IDS.StreamLink,
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const groupedSourceFields = chain(sourceFields)
    .groupBy('parentEntityName')
    .map((value, key) => ({ label: key, options: value }))
    .value();

  const columns = [
    ...groupedSourceFields,
    {
      label: t('entityTypes:Calculation_plural'),
      options: calculations,
    },
    {
      label: t('entityTypes:Hub_plural'),
      options: hubs,
    },
    {
      label: t('entityTypes:Link_plural'),
      options: links,
    },
    {
      label: t('entityTypes:Group_plural'),
      options: groups,
    },
  ];

  return (
    <>
      <p style={labelStyle}>
        {t('streams:Column')}
      </p>
      <div style={{ display: 'flex' }}>
        <Controller
          render={({ value, onChange }) => {
            const aggregations = getAggregations();
            const currentAggregation = value ? aggregations[value] : null;

            const icon = currentAggregation?.icon || 'SynergiesDoNotSummarize';

            return (
              <div style={{ marginRight: 8 }}>
                <AggregationButton
                  tooltipLabel={currentAggregation?.label || t('filters:Select aggregation')}
                  aggregationIconName={icon}
                  onDroplistItemClick={(_, { data }) => onChange(data)}
                  aggregationDroplistBtnStyles={aggregationDroplistBtn}
                  aggregationBtnStyles={aggregationBtn(true, false, true)}
                  isColumnAggregation
                  currentAggregations={[value]}
                  tooltipDirectionalHint={DirectionalHint.rightCenter}
                  isDisabled={isEditing}
                />
              </div>
            );
          }}
          name="aggregation"
          control={control}
        />
        <Controller
          render={({ value }) => (
            <SelectDropdown
              onChange={(v) => {
                reset({
                  comparison: null,
                  values: [],
                  compareValue: '',
                  lowerBoundValue: '',
                  upperBoundValue: '',
                  id: null,
                  column: v,
                });
              }}
              value={value}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              options={columns}
              className="filter-item-select"
              isDisabled={isEditing}
            />
          )}
          name="column"
          control={control}
          rules={{ required: true }}
        />
      </div>
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="columnId"
        message={t('filters:required')}
      />
    </>
  );
};

export default ColumnSection;
