import React from 'react';
import {
  CommandBarButton,
  SearchBox as FluentSearchBox,
  Stack,
} from '@fluentui/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import {
  containerStyles,
  searchBoxStyles,
  searchWrapStyles,
  closeButtonStyles,
  closeButtonContainerStyles,
} from './styles';
import { getSearchState } from '../../selectors';
import useActions from '../../../../utils/useActions';
import * as searchActions from '../../actions';

const SearchBar = () => {
  const { t } = useTranslation('commands');
  const { setSearchQuery, setIsSearchBarOpen } = useActions(searchActions);
  const debouncedSetSearchQuery = debounce(setSearchQuery, 400);

  const {
    searchQuery, isSearchBarOpen,
  } = useSelector(getSearchState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      debouncedSetSearchQuery(e.target.value);
    }
  };

  const handleCloseClick = () => {
    setIsSearchBarOpen(false);
    setSearchQuery('');
  };

  if (!isSearchBarOpen) return null;

  return (
    <Stack styles={containerStyles}>
      <Stack
        horizontal
        horizontalAlign="stretch"
        styles={searchWrapStyles}
      >
        <FluentSearchBox
          autoFocus
          styles={searchBoxStyles}
          placeholder={t('Search')}
          onClear={() => setSearchQuery('')}
          onChange={handleChange}
          data-testid="searchbox"
          value={searchQuery}
          spellCheck={false}
          iconProps={{
            iconName: 'Search',
          }}
          clearButtonProps={{
            iconProps: {
              iconName: 'Cancel',
            },
          }}
        />

        <Stack styles={closeButtonContainerStyles}>
          <CommandBarButton
            styles={closeButtonStyles}
            onClick={handleCloseClick}
            iconProps={{
              iconName: 'Leave',
            }}
          />
        </Stack>
      </Stack>

    </Stack>
  );
};

export default SearchBar;
