import { getTheme } from '@fluentui/react';

export const successMessageStyles = () => {
  const theme = getTheme();

  return {
    color: theme.palette.themePrimary,
    width: '100%',
    textAlign: 'center' as const,
  };
};

export const errorStyle = {
  fontSize: '12px',
  color: 'red',
  margin: 0,
};
