import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';

import { IStream } from '../../types/IStream';
import { ApplicationState } from '../../store/types';
import { ICustomAggregation } from '../../types/ICustomAggregation';
import {
  checkIfColumnIsQueuedForDeletion,
  checkIfColumnIsQueuedForMapping,
  checkIfColumnSortModeChanged,
  checkIfColumnSortPositionChanged,
  checkIfColumnWasSequenced,
  checkIfCountOfColumnAggregationsChanged,
} from './utils';
import { IDataset, TEMP_DATASET } from '../../types/IDataset';
import { generateColumnUuid } from '../ColumnsSequencing/utils';
import { AssetProcessStatus } from '../../api/model/AssetProcessStatus';

const getStreamState = (state: ApplicationState) => state.streams;

const selectors = {
  getStreamsState: ({ streams }: ApplicationState) => streams,
  getCurrentStream: ({ streams }: ApplicationState): IStream | undefined => {
    const { currentStreamId, streams: { data = undefined } = {} } =
      streams ?? {};

    return data && data.find((s) => s.id === currentStreamId);
  },
  getCurrentDatasetId: ({ streams }: ApplicationState): string | undefined =>
    streams?.currentDatasetId,
  getAvailableStreams: ({
    streams,
  }: // streams: {
  //   streams: { data },
  //   settings: { permittedStreams },
  // },
  ApplicationState): IStream[] => {
    const {
      settings: { permittedStreams = undefined } = {},
      streams: { data = [] } = {},
    } = streams ?? {};

    if (permittedStreams) {
      return data && data.filter((c) => permittedStreams.includes(c.id));
    }

    return data;
  },
  getAreStreamsPending: (state: ApplicationState) =>
    Boolean(getStreamState(state)?.streams.pending),
  getIsSyncing: (state: ApplicationState) =>
    Boolean(getStreamState(state)?.syncing),
  getIsCheckingCurrentSelection: (state: ApplicationState) =>
    Boolean(getStreamState(state)?.checkingCurrentSelection),
  getDatasetById: (id: string) => (state: ApplicationState) =>
    getStreamState(state)?.datasets.find((dataset) => dataset.id === id),
  getStreams: (state: ApplicationState) => getStreamState(state)?.streams,
  getCustomFilters: (state: ApplicationState) =>
    getStreamState(state)?.customFilters ?? [],
  getCustomAggregations: (state: ApplicationState): ICustomAggregation[] =>
    getStreamState(state)?.customAggregations ?? [],
  getIsRewinding: (state: ApplicationState): boolean =>
    Boolean(getStreamState(state)?.isRewinding),
  getDatasets: (state: ApplicationState): IDataset[] =>
    getStreamState(state)?.datasets ?? [],
  getDefaultDatasetOptions: (state: ApplicationState) =>
    getStreamState(state)?.defaultDatasetOptions,
  getFetchingProgress: (state: ApplicationState) =>
    getStreamState(state)?.fetchingProgress,
  hasDatasetFilters: (id: string) => (state: ApplicationState) =>
    getStreamState(state)?.datasets.find((dataset) => dataset.id === id)
      ?.streamFilters?.length ?? 0 > 0,
  getDrillableSelection: (state: ApplicationState) =>
    getStreamState(state)?.drillableSelection,
};

const getCurrentDataset = createSelector(
  selectors.getStreamsState,
  (streamsState) => {
    const { datasets, currentDatasetId } = streamsState ?? {};
    return (
      datasets && datasets.find((dataset) => dataset.id === currentDatasetId)
    );
  }
);

const getIfCurrentDatasetHasContinuation = (state: ApplicationState) => {
  const currentDataset = getCurrentDataset(state);

  if (!currentDataset) return false;

  return !!currentDataset?.continuation?.token;
};

const getHasCurrentDatasetFiltersChanged = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;

    // we are only interested in
    // change of the filters inside an array
    return !isEqual(
      currentDataset?.streamFilters || [],
      currentDataset?.lastSuccessfulMapping?.streamFilters || []
    );
  }
);

const getHasColumnsQueuedForDeletion = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;
    if (!currentDataset?.lastSuccessfulMapping?.columns) return false;
    if (currentDataset.lastSuccessfulMapping.columns.length === 0) return false;

    return currentDataset.lastSuccessfulMapping.columns.some((column) =>
      checkIfColumnIsQueuedForDeletion({
        column,
        currentColumns: currentDataset.columns,
        lastSuccessfullyMappedColumns:
          currentDataset.lastSuccessfulMapping.columns,
      })
    );
  }
);

const getHasColumnsQueuedForMapping = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;
    if (!currentDataset?.lastSuccessfulMapping?.columns) return false;

    return currentDataset.columns.some((column) =>
      checkIfColumnIsQueuedForMapping({
        column,
        currentColumns: currentDataset.columns,
        lastSuccessfullyMappedColumns:
          currentDataset.lastSuccessfulMapping.columns,
      })
    );
  }
);
const getHasColumnsQueuedForSequencing = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;
    if (!currentDataset?.lastSuccessfulMapping?.columns) return false;

    return currentDataset.columns.some((column) =>
      checkIfColumnWasSequenced({
        column,
        currentColumns: currentDataset.columns,
        lastSuccessfullyMappedColumns:
          currentDataset.lastSuccessfulMapping.columns,
      })
    );
  }
);

const getHasColumnsQueuedForSortModeChange = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;
    if (!currentDataset?.lastSuccessfulMapping?.columns) return false;

    return currentDataset.columns.some((column) =>
      checkIfColumnSortModeChanged({
        column,
        currentColumns: currentDataset.columns,
        lastSuccessfullyMappedColumns:
          currentDataset.lastSuccessfulMapping.columns,
      })
    );
  }
);
const getHasColumnsQueuedForSortPositionChange = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;
    if (!currentDataset?.lastSuccessfulMapping?.columns) return false;

    return currentDataset.columns.some((column) =>
      checkIfColumnSortPositionChanged({
        column,
        currentColumns: currentDataset.columns,
        lastSuccessfullyMappedColumns:
          currentDataset.lastSuccessfulMapping.columns,
      })
    );
  }
);

const getIfCountOfColumnAggregationsChanged = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;

    return currentDataset.columns.some((column) =>
      checkIfCountOfColumnAggregationsChanged({
        column,
        currentColumns: currentDataset.columns,
        lastSuccessfullyMappedColumns:
          currentDataset?.lastSuccessfulMapping?.columns ?? [],
      })
    );
  }
);

const getIsDatasetFullyMapped = createSelector(
  getCurrentDataset,
  (currentDataset) => {
    if (!currentDataset) return false;

    return currentDataset.rows?.length >= currentDataset.totalRowCount;
  }
);

const getAllStreams = (state: ApplicationState) =>
  selectors.getStreamsState(state)?.streams?.data;
const getAllMappedDatasets = (state: ApplicationState) =>
  selectors.getStreamsState(state)?.datasets;
const getRecentStreams = createSelector(
  [getAllStreams, getAllMappedDatasets],
  (allStreams, allMappedDatasets) => {
    if (!allStreams || !allMappedDatasets || allStreams.length === 0) return [];

    const recentStreams = allStreams.filter((stream) =>
      allMappedDatasets.some(
        (dataset) =>
          dataset.streamId === stream.id && dataset.id !== TEMP_DATASET
      )
    );

    return recentStreams;
  }
);

const getIfPlayButtonShouldBeVisible = (state: ApplicationState) => {
  const currentDataset = getCurrentDataset(state);
  const hasContinuationToken = getIfCurrentDatasetHasContinuation(state);
  const hasColumnsQueuedForDeletion = getHasColumnsQueuedForDeletion(state);
  const isFullyMapped = getIsDatasetFullyMapped(state);
  const hasColumnsQueuedForMapping = getHasColumnsQueuedForMapping(state);
  const hasAnyColumnChangedAggregationCount =
    getIfCountOfColumnAggregationsChanged(state);
  const hasCurrentDatasetFiltersChanged =
    getHasCurrentDatasetFiltersChanged(state);
  const hasColumnsQueuedForSortModeChange =
    getHasColumnsQueuedForSortModeChange(state);
  const hasColumnQueuedForSequencing = getHasColumnsQueuedForSequencing(state);
  const hasColumnsQueuedForSortPositionChange =
    getHasColumnsQueuedForSortPositionChange(state);

  return (
    hasContinuationToken ||
    hasColumnsQueuedForDeletion ||
    hasColumnsQueuedForMapping ||
    hasAnyColumnChangedAggregationCount ||
    hasCurrentDatasetFiltersChanged ||
    hasColumnsQueuedForSortModeChange ||
    hasColumnQueuedForSequencing ||
    hasColumnsQueuedForSortPositionChange ||
    // initial selecting of columns in table
    (currentDataset?.id &&
      !hasColumnsQueuedForDeletion &&
      !hasColumnsQueuedForMapping &&
      !isFullyMapped)
  );
};

const getIfTableRewindButtonShouldBeVisible = (state: ApplicationState) => {
  const isFullyMapped = getIsDatasetFullyMapped(state);
  const hasContinuationToken = getIfCurrentDatasetHasContinuation(state);
  const hasColumnsQueuedForMapping = getHasColumnsQueuedForMapping(state);
  const hasColumnQueuedForSequencing = getHasColumnsQueuedForSequencing(state);
  const hasColumnsQueuedForDeletion = getHasColumnsQueuedForDeletion(state);
  const hasAnyColumnChangedAggregationCount =
    getIfCountOfColumnAggregationsChanged(state);
  const hasCurrentDatasetFiltersChanged =
    getHasCurrentDatasetFiltersChanged(state);
  const hasColumnsQueuedForSortChange =
    getHasColumnsQueuedForSortModeChange(state);
  const hasColumnsQueuedForSortPositionChange =
    getHasColumnsQueuedForSortPositionChange(state);

  return (
    !hasColumnsQueuedForMapping &&
    !hasColumnsQueuedForDeletion &&
    !hasAnyColumnChangedAggregationCount &&
    !hasCurrentDatasetFiltersChanged &&
    !hasColumnQueuedForSequencing &&
    !hasColumnsQueuedForSortChange &&
    !hasColumnsQueuedForSortPositionChange &&
    (isFullyMapped || (!isFullyMapped && hasContinuationToken))
  );
};

const getCurrentDatasetColumnByColumnUuid = (columnUUid: string | null) =>
  createSelector(getCurrentDataset, (currentDataset) => {
    if (!currentDataset || !columnUUid) return null;

    return currentDataset.columns.find(
      (column) => generateColumnUuid(column) === columnUUid
    );
  });

const getCurrentDatasetColumnById = (columnId: string | null) =>
  createSelector(getCurrentDataset, (currentDataset) => {
    if (!currentDataset || !columnId) return null;

    return currentDataset.columns.find((column) => column.id === columnId);
  });

const getAvailableColumns = (state: ApplicationState) =>
  getStreamState(state)?.availableColumns;
const getStreamColumnLoading = (state: ApplicationState) =>
  Boolean(getStreamState(state)?.streamColumnLoading);

const getIsFiltersView = (state: ApplicationState) =>
  Boolean(getStreamState(state)?.isFiltersView);

const getStreamNameById = (streamId: string) =>
  createSelector(getAllStreams, (streams) =>
    streams ? streams.find((s) => s.id === streamId)?.name : null
  );

const getCurrentStreamId = (state: ApplicationState) =>
  getStreamState(state)?.currentStreamId;
const getCurrentDatasetId = (state: ApplicationState) =>
  getStreamState(state)?.currentDatasetId;
const getCurrentDatasetColumnCount = (state: ApplicationState) =>
  getCurrentDataset(state)?.columns?.length;
const getCurrentDatasetColumnUuids = (state: ApplicationState) =>
  getCurrentDataset(state)?.columns?.map((c) => generateColumnUuid(c));

const getAreAnyColumnsInCurrentDataset = createSelector(
  getCurrentDatasetColumnCount,
  (count) => count > 0
);

const getCurrentDatasetType = (state: ApplicationState) =>
  getCurrentDataset(state)?.type;

const getIsProcessingWizardInProgress = (state: ApplicationState) =>
  Boolean(selectors.getStreamsState(state).processingWizardInProgress);
const getIsFetchingAvailableColumns = (state: ApplicationState) =>
  Boolean(selectors.getStreamsState(state).isFetchingAvailableColumns);

const getIsCurrentDatasetStreamProcessing = createSelector(
  [getCurrentDataset, getAllStreams],
  (relatedDataset, allStreams) => {
    if (!relatedDataset || !allStreams) return false;

    const relatedStream = allStreams.find(
      (stream) => stream.id === relatedDataset.streamId
    );

    return relatedStream?.processStatus === AssetProcessStatus.Processing;
  }
);

const getCurrentDatasetSelectedAssetIds = createSelector(
  [getCurrentDataset],
  (relatedDataset) => {
    if (!relatedDataset || !relatedDataset.columns) return [];

    return relatedDataset.columns.map((column) => column.id);
  }
);

export default {
  ...selectors,
  getIfCurrentDatasetHasContinuation,
  getCurrentDataset,
  getIsDatasetFullyMapped,
  getIfPlayButtonShouldBeVisible,
  getIfTableRewindButtonShouldBeVisible,
  getIfCountOfColumnAggregationsChanged,
  getHasColumnsQueuedForDeletion,
  getHasColumnsQueuedForMapping,
  getAvailableColumns,
  getStreamColumnLoading,
  getIsFiltersView,
  getRecentStreams,
  getCurrentDatasetColumnByColumnUuid,
  getHasCurrentDatasetFiltersChanged,
  getStreamNameById,
  getCurrentStreamId,
  getCurrentDatasetId,
  getCurrentDatasetColumnCount,
  getCurrentDatasetColumnUuids,
  getCurrentDatasetType,
  getIsProcessingWizardInProgress,
  getCurrentDatasetColumnById,
  getHasColumnsQueuedForSequencing,
  getHasColumnsQueuedForSortModeChange,
  getIsCurrentDatasetStreamProcessing,
  getIsFetchingAvailableColumns,
  getHasColumnsQueuedForSortPositionChange,
  getCurrentDatasetSelectedAssetIds,
  getAreAnyColumnsInCurrentDataset,
};
