import * as effects from 'redux-saga/effects';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as streamActions from '../../Streams/actions';

export function* changeDrillingLevel() {
  yield effects.takeLatest(actions.initChangeDrillingLevel.type,
    function* handler({ payload } : ReturnType<typeof actions.initChangeDrillingLevel>) {
      const levelBefore = selectors
        .getGroupTable(payload.datasetId)(yield effects.select())[payload.direction];

      yield effects.put(actions.setDrillingLevel(payload));
      const [cancelled] = yield effects.race([
        effects.take(streamActions.cancelCurrentOperation.type),
        effects.take(actions.setTableData.type),
      ]);

      if (cancelled) {
        yield effects.put(actions.setDrillingLevel({
          ...payload,
          level: levelBefore,
        }));
      }
    });
}
