import {
  ITheme,
} from '@fluentui/react';
import { baseColors } from '../../../../theme';

export const buttonStyles = (theme: ITheme, isActive: boolean) => ({
  root: {
    backgroundColor: isActive ? baseColors.midGrey : theme.palette.themeLighterAlt,
    borderRadius: '24px',
  },
  icon: {
    lineHeight: '20px',
    height: '20px',
    fontSize: '20px',
    width: '20px',
  },
});

export const outerStyles = (isActive: boolean) => ({
  padding: '4px',
  borderRadius: '27px',
  border: `1px solid ${isActive ? 'black' : 'transparent'}`,
});

export const textStyles = (isActive: boolean) => ({
  root: {
    visibility: isActive ? 'visible' : 'hidden',
    fontSize: '8px',
    textTransform: 'uppercase',
    backgroundColor: baseColors.midGrey,
    borderRadius: '4px',
    padding: '2px 3px',
    marginTop: '4px',
  },
});
