import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from '../../../../Streams/Streams';
import { getIsOtherUserSyncing } from '../../../selectors';
import { createLinkedCopy } from '../../../../../actions/excel-actions/createLinkedCopy';
import { HeaderBarButton } from '../../../../../components/ui';

const LinkedCopyButton = () => {
  const { t } = useTranslation();
  const currentDataset = useSelector(selectors.getCurrentDataset);
  const isSyncing = useSelector(selectors.getIsSyncing);
  const isOtherUserBlockingUI = useSelector(getIsOtherUserSyncing);

  const handleClick = () => {
    createLinkedCopy();
  };

  const isMapped = currentDataset?.rows?.length > 0;

  const isDisabled = !isMapped
  || isOtherUserBlockingUI
  || isSyncing;

  return (
    <HeaderBarButton
      tooltip={(
        <Trans t={t} i18nKey="actionBar:general.presentation" />
      )}
      onClick={handleClick}
      iconProps={{ iconName: 'Presentation' }}
      data-testid="linked-copy-top-menu"
      disabled={isDisabled}
      shouldHideOnDisabled
    />
  );
};

export default LinkedCopyButton;
