import { IStackStyles, ISearchBoxStyles, ISearchBoxStyleProps } from '@fluentui/react';
import { IButtonStyles } from 'office-ui-fabric-react';
import { baseColors } from '../../../../theme';

export const containerStyles: IStackStyles = {
  root: {
    padding: '8px',
    borderBottom: `1px solid ${baseColors.outlineGray}`,
    borderRight: `1px solid ${baseColors.outlineGray}`,
    borderLeft: `1px solid ${baseColors.outlineGray}`,
    flexWrap: 'nowrap',
  },
};

export const searchWrapStyles: IStackStyles = {
  root: {
    position: 'relative',
    flexShrink: '0 !important',
    flexWrap: 'nowrap',
  },
};

export const searchBoxStyles = ({ theme, hasFocus }: ISearchBoxStyleProps): ISearchBoxStyles => ({
  root: {
    borderColor: hasFocus ? baseColors.midOrange: baseColors.outlineGray,
    borderRadius: 2,
    margin: '0 !important',
    flexWrap: 'nowrap',
    flex: 1,
    selectors: {
      '&:after': {
        display: 'none',
      },
      '.ms-SearchBox-iconContainer': {
        display: 'flex',
      },
      '&:active': {
        borderColor: theme.palette.themePrimary,
      },
      '&:hover': {
        borderColor: theme.palette.themePrimary,
      },
    },
  },
  field: {

  },
  icon: {
    color: baseColors.iconColor,
    opacity: '1 !important',
  },
  iconContainer: {
    width: '32px !important',
  },
  clearButton: {
    alignItems: 'center',
    justifyContent: 'center',
    selectors: {
      '.ms-Button': {
        borderRadius: '4 !important',
        minWidth: 24,
        width: 24,
        height: 24,
      },
    },
  },
});

export const closeButtonStyles: IButtonStyles = {
  root: {
    minWidth: 32,
    width: 32,
    height: 32,
    margin: '0 !important',
    borderRadius: 4,
    boxSizing: 'border-box',
  },
  icon: {
    color: baseColors.iconColor,
  },
};

export const closeButtonContainerStyles: IStackStyles = {
  root: {
    paddingLeft: 8,
    boxSizing: 'border-box',
  },
};
