const LOCAL_STORAGE_KEY_PREFIX = '@synergies';
const LOCAL_STORAGE_KEY_PATH_SEPARATOR = '/';

const combineStoragePath = (...path: string[]) =>
  [LOCAL_STORAGE_KEY_PREFIX, ...path].join(LOCAL_STORAGE_KEY_PATH_SEPARATOR);

export const storageFactory = <ModuleName extends string>(moduleName: ModuleName) => {
  const itemSerializer = <ItemType>(itemName: string) => {
    const storageItemPath = combineStoragePath(moduleName, itemName);

    return {
      save(item: ItemType) {
        localStorage.setItem(storageItemPath, JSON.stringify(item));
      },
      load(): ItemType | undefined {
        const item = localStorage.getItem(storageItemPath);

        if (typeof item === 'string') {
          return JSON.parse(item) as ItemType;
        }

        return undefined;
      },
      remove() {
        localStorage.removeItem(storageItemPath);
      },
    }
  };

  return itemSerializer;
};
