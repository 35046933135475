import { AxiosResponse } from 'axios';
import getClient from '../../api/getClient';
import { Stream } from '../../api/model/schemas/Stream';
import { StreamCalculation } from '../../api/model/schemas/StreamCalculation';
import { StreamDataRequest } from '../../api/model/schemas/StreamDataRequest';
import { StreamField } from '../../api/model/schemas/StreamField';
import { StreamGroupAsset } from '../../api/model/schemas/StreamGroupAsset';
import { StreamHub } from '../../api/model/schemas/StreamHub';
import { StreamLookup } from '../../api/model/schemas/StreamLookup';
import { childEntityPath } from '../../constants/apiPaths';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { fetchEntities, fetchNestedEntities } from '../Designer/api';
import { ApiV4ResponseWrapper } from '../Designer/types';
import { StreamDataRequestParams } from './types';

export const fetchStreams = fetchEntities<ApiV4ResponseWrapper<Stream[]>>(
  TYPE_IDS.Stream
);

export const fetchStreamCalculations = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamCalculation[]>
>({
  typeId: TYPE_IDS.StreamCalculation,
});

export const fetchStreamGroupsAssets = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamGroupAsset[]>
>({
  typeId: TYPE_IDS.StreamGroupAsset,
});

export const fetchStreamFields = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamField[]>
>({
  typeId: TYPE_IDS.StreamField,
});

export const fetchStreamHubs = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamHub[]>
>({
  typeId: TYPE_IDS.StreamHub,
});

export const fetchStreamMachineLearning = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamHub[]>
>({
  typeId: TYPE_IDS.StreamMachineLearning,
});
export const fetchStreamLookups = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamLookup[]>
>({
  typeId: TYPE_IDS.StreamLookup,
});

export const createStreamDataRequestTask = async (
  streamId: string,
  payload: StreamDataRequestParams
): Promise<AxiosResponse<StreamDataRequest>> => {
  const client = await getClient();
  const path = childEntityPath({
    parentEntityId: streamId,
    typeId: TYPE_IDS.StreamDataRequest,
  });
  return client.post(path, payload);
};
