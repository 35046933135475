import { Shimmer, ShimmerElementType } from '@fluentui/react';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { classNames } from './styles';

const OutputCardShimmer = () => (
  <div className={classNames.wrapper} style={{ height: '100%', width: '100%' }}>
    <AutoSizer>
      {({ height, width }) => {
        console.log(width, height);
        return (
          <Shimmer
            width={width}
            shimmerElements={[{ type: ShimmerElementType.line, height, width }]}
          />
        );
      }}
    </AutoSizer>
  </div>
);

export default OutputCardShimmer;
