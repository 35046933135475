import { Toggle } from '@fluentui/react';
import React from 'react';
import { IControlledField } from '../../types';

const YesNo : React.FC<IControlledField> = ({
  controller,
  isDisabled,
  label,
}) => {
  const { onChange, value } = controller;

  return (
    <Toggle
      checked={value}
      disabled={isDisabled}
      label={label}
      onChange={(_, checked) => onChange(checked)}
    />
  );
};

export default YesNo;
