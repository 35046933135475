import * as effects from 'redux-saga/effects';

import builderFiltersSaga from './filters';
import builderStreamProcessing from './processing';
import builderSelectingAssets from './selectingAssets';

export function* rootSaga() {
  yield effects.all([
    builderFiltersSaga(),
    builderStreamProcessing(),
    builderSelectingAssets(),
  ]);
}

export default rootSaga;
