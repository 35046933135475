export enum TypeIds {
  Calculation = 'bfb8d835-a301-454d-895e-98dfcd1ffb70',
  CalculationType = 'b23447c6-1867-41ae-8f73-a8a8b29f0a49',
  ColumnsToRowsTransformation = '950abe70-1e7c-4bae-98d1-f449b96bbbb9',
  ConnectorConfigurationType = '4ecf2659-275a-418a-b26f-3212e91ab186',
  DataSource = 'eb1d2cc4-a7db-4bf7-8d0d-f080b0702d7d',
  DataSourceEntity = 'dddc12aa-bc3c-4468-8a20-d98824fa5df8',
  DataSourceEntityField = '21db92d1-c009-4906-9e78-c648d76e0158',
  Design = '54b96780-dda5-4947-aeed-1a6232c39395',
  EntityType = '2f85fb2b-e5c2-4a1a-8981-1805a22af1eb',
  GenerateLinksJob = '0d2b56c9-dcb4-4a7f-86be-b958eaa0c193',
  Hub = 'e11d9494-e920-4e8f-b890-e055bdf311b9',
  HubSpotConfiguration = '5f7c3968-c57e-4ad4-a54d-7f6476103948',
  Link = '0207203f-b5d4-431b-b663-d59081cf6bc9',
  LinkFields = '9b7df4d5-48a9-4b12-8e30-70fc9f13d4ff',
  Lookup = '109c4509-fdb0-4824-9437-2f4b20fca609',
  LoadEntityFieldsRequest = 'd8a8ecd4-16bc-48ec-80b1-f927934364c4',
  MachineLearning = '29e712e0-15fd-4ca6-bed9-367903c0ea10',
  RowsToColumnsTransformation = '4285b9e7-9ba4-4e92-849e-b8cad6dcf294',
  Stream = '64ccbed4-90ae-42a5-b071-d4c6dd3e5dd0',
  StreamCalculation = '7bcb3ae8-f95b-498c-9ae8-19274debb8a7',
  StreamField = 'cb00cb56-a051-4590-89b3-6e188d5f034c',
  StreamGroupAsset = '8358520b-364d-4e8a-b5e3-0663c5ecd04e',
  StreamHub = '99dd7c63-a283-45e1-b571-236622cc8516',
  StreamMachineLearning = '3ed24c1b-051e-4916-80e2-0cf7a74a816b',
  StreamLookup = '109c4509-fdb0-4824-9437-2f4b20fca609',
  TransformConfigurationType = 'fd151fe3-7e1b-4f38-8a4c-a7056da9499b',
  TypeProperty = '1bcf1a10-d290-49aa-a55d-3e1f19347729',
  TypePropertySection = '4d0fae61-6ff4-4538-86fc-8f99e91718f9',
  User = '3d236c98-6a5c-46bf-ab48-a6530fabb2a7',
  XeroConfiguration = 'e2d478d2-04b2-49fd-928d-048de1883200',
  MachineLearningAssetValuesRequest = '37e85e8d-00bd-4a17-a4c6-0a8acdfdc700',
}

export const TYPE_ID_TO_NAME = Object.entries(TypeIds).reduce(
  (acc, [typeName, typeId]) => ({
    ...acc,
    [typeId]: typeName,
  }),
  {}
);

export const CalculationTypes = {
  'Date and Time Value': 'e480073d-4cdf-4f12-ad66-0469f06e30c0',
  'Decimal Number Value': 'd242d2b9-f964-452a-997c-07e80fead3f8',
  'Upper Case': '4eb65a75-ed7d-4cc0-a65d-0876e1900f69',
  Right: '3a5251c6-eeab-4f1a-8f38-08b0382e753b',
  CountDistinctNoBlank: 'd87e09f3-7ce0-4548-b2ca-0aeac9cbdd6a',
  Second: 'a1769933-76c5-49f1-8880-0aee265d9157',
  Search: '7106be3b-be24-4c8b-b37d-147e63861791',
  Product: '16f64e8a-56e3-4bd0-9012-159d3d706942',
  'Remove Decimals': 'c9b124b7-7d86-4d4f-9318-19db553719bf',
  Absolute: '53e9a51f-6592-4f89-b2b7-2675c93691f5',
  Convert: 'e60a8347-3b2a-41bf-bfa1-2d6524a62220',
  Prefix: 'cf280f80-20d1-4b7d-970b-3138d721daab',
  Find: 'dbbd85c6-c391-4392-85c2-32851ea37dc1',
  Week: '4a1257ed-085c-4dd9-ac3e-37e50ac13dae',
  Replace: '05f612f9-b778-4610-b279-4a56dd7970a6',
  Merge: 'c9a1bb29-ff08-4d8d-8bd7-57868cc8c45f',
  Now: '938df252-d46b-4111-8c4f-57a51ba884bb',
  CountDistinct: 'ecc962ac-0e2c-4dfa-9b63-60c1f590921b',
  'Whole Number Value': '376ae62a-e6fc-47d4-8366-65697f4cdce0',
  Quarter: '9135d85b-6b6f-45a6-9a1c-65fcce61c28d',
  Substitute: 'db9c5411-94e5-486e-b281-6689a6bfef00',
  Left: '06b31a0f-66ce-4596-8303-66c90c5a5b82',
  Sum: 'ae0de43c-be5a-4dc6-9b1e-684fa9e90e6e',
  'Julian Date (JD Edwards)': 'db5d07f5-20e5-4012-9c0b-6927c005bc18',
  'Text Value': 'fd7e0ba6-6fe3-46d4-a4a7-69d9ae3e089d',
  Average: '014b4768-8200-40c4-9450-718302e1757e',
  Mid: 'f7f211ca-9975-49c9-acfd-871c13007850',
  Maximum: 'cc78dc73-797e-42cc-b6fe-8add53853a7c',
  Today: 'f898acbe-7b8f-4c4f-ad84-8ef8b4958b5d',
  Change: '19df0bc9-f1c9-4ba9-943a-947403525cae',
  Hour: 'a3b92b06-b150-45eb-80a7-9c79bc357c62',
  Weekday: 'f2487b26-cdcd-4abc-b5e6-a4eb68ddb7d8',
  Suffix: 'b6f216b3-75cb-4cfc-9cad-b29789b67110',
  'Lower Case': '80a975c2-28be-4412-a7f9-b9a82cf56c1c',
  Month: '3db9ea23-9f7d-4d19-ad44-b9da5e217844',
  Trim: '90c0f98f-0aeb-4494-ac5d-bd69a556c27d',
  Count: '0704606a-13f0-4c52-956e-c2a2c74780ce',
  Date: '92ab303d-a432-49f9-bd33-c3d9e8279800',
  'Date Difference': '1556d186-0168-44ca-8d14-c97f395175af',
  Time: '0c3e528b-37d1-46ce-a55c-cc30c9366e6f',
  Length: '2d27a139-cdf5-4c4d-8c56-cf7fe767f6bc',
  'True or False Value': 'ce3bf44b-8fcf-4dfa-b661-d60eee5db8f6',
  Minute: '85a48309-0993-4a7d-96d4-de5d0b83fb23',
  DaxMeasure: 'ceb90308-1d8c-49a1-a34d-e350850e59d1',
  DaxCalculation: '29373565-5a41-4e98-926d-e557eefdbf5d',
  Day: 'b6789777-454b-438e-a33f-ebd8ec1d632d',
  Year: 'bd20901e-4d25-4809-b89d-f0031737493e',
  Minimum: '896d771f-6cef-4e48-9799-f30964fcd1d3',
  'Change %': '20b7fdae-18a1-4e4c-a531-fcc7ff43a898',
};
