import { Stack, Text, Link, Spinner } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyButton } from '../../../../components/ui';
import { StreamOutput as IStreamOutput } from '../../../../api/model/schemas/StreamOutput';
import { styles } from './styles';

interface Props {
  isLoading: boolean;
  streamOutputData: IStreamOutput;
}

export const StreamOutputContent: React.FC<Props> = ({
  isLoading,
  streamOutputData,
}) => {
  const { t } = useTranslation();
  return isLoading || !streamOutputData ? (
    <Spinner />
  ) : (
    <article>
      <Stack style={styles.topStack}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Text style={styles.subheader}>{t('streamOutput:Server')}</Text>
          <CopyButton textToCopy={streamOutputData.server} />
        </Stack>
        <Link
          href={streamOutputData.server}
          style={styles.outputPart}
          data-testid="stream-server-link"
        >
          {streamOutputData.server}
        </Link>
      </Stack>
      <Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Text style={styles.subheader}>{t('streamOutput:Database')}</Text>
          <CopyButton textToCopy={streamOutputData.database} />
        </Stack>
        <Text style={styles.outputPart} data-testid="stream-server-database">
          {streamOutputData.database}
        </Text>
      </Stack>
    </article>
  );
};
