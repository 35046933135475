import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton as Button } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { TextField } from '../../../../../components/ui';
import { IGenericFormField } from '../../types';
import { generateUniqueName } from '../MultiEndpointSelector/utils';
import { setEntityFormData } from '../../actions';
import { classNames, textFieldStyles } from './styles';
import { TypeIds } from '../../../../../api/model/schemas';

type Pairs = Array<[string, string]>;

const pairsToObject = (pairs: Pairs = []): { [key: string]: string } =>
  pairs.reduce((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {});

const objectToPairs = (obj: { [key: string]: string } = {}): Pairs =>
  Object.keys(obj).map((key) => [key, obj[key]]);

const KeyValuePicker: React.FC<IGenericFormField> = ({
  currentForm,
  inputName,
  currentEntity,
  interaction: { defaultValue },
}) => {
  // STATE

  const { t } = useTranslation();
  const [pairs, setPairs] = useState<Pairs>(
    objectToPairs(
      currentForm?.data?.[inputName] ||
        (currentEntity?.[inputName] as any) ||
        defaultValue
    )
  );
  const dispatch = useDispatch();
  const { typeId, parentTypeId } = currentForm;

  const isMachineLearning = parentTypeId == TypeIds.MachineLearning;


  // CALLBACKS

  const handleAdd = () => {
    setPairs((current) => {
      const keys = current.map(([key]) => key);
      const uniqueKey = isMachineLearning
        ? ''
        : generateUniqueName('Key', keys);
      return [...current, [uniqueKey, '']];
    });
  };

  const handleRemove = (index: number) => {
    setPairs((current) => [
      ...current.slice(0, index),
      ...current.slice(index + 1),
    ]);
  };

  const handleEdit = (row: number, column: number, value: string) => {
    setPairs((current) => {
      const copied = [...current];
      if (column === 0 && !isMachineLearning) {
        const keys = current.map(([key]) => key);
        keys.splice(row);
        copied[row][column] = generateUniqueName(value, keys);
      } else {
        copied[row][column] = value;
      }
      return copied;
    });
  };

  // EFFECTS

  useEffect(() => {
    dispatch(
      setEntityFormData({
        typeId,
        data: {
          [inputName]: pairsToObject(pairs),
        },
      })
    );
  }, [pairs]);

  // RENDER

  return (
    <>
      <div className={`${classNames.row} ${classNames.headers}`}>
        <div>{t('wizard:Key')}</div>
        <div>{t('wizard:Value')}</div>
        <div />
      </div>
      {pairs.map(([key, value], index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={`${classNames.row} ${classNames.item}`} key={index}>
          <TextField
            styles={textFieldStyles}
            value={key}
            onChange={(_, val) => handleEdit(index, 0, val)}
          />
          <TextField
            styles={textFieldStyles}
            value={value}
            onChange={(_, val) => handleEdit(index, 1, val)}
          />
          <div>
            <Button
              className={classNames.btn}
              iconProps={{ iconName: 'Delete' }}
              onClick={() => handleRemove(index)}
            />
          </div>
        </div>
      ))}
      <Button
        className={`${classNames.btn} ${classNames.add}`}
        iconProps={{ iconName: 'Add' }}
        onClick={handleAdd}
      />
    </>
  );
};

export default KeyValuePicker;
