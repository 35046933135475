import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from './TutorialAnimation';

export const TUTORIAL_TILE_KEY = 'notifications-activity-feed';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:notifications:activityFeed:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:notifications:activityFeed:items:notifications:description',
      icon: 'ActivityFeed',
      titleI18nKey: 'tutorialTiles:notifications:activityFeed:items:notifications:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:notifications:activityFeed:items:activityAlert:description',
      icon: 'Completed',
      titleI18nKey: 'tutorialTiles:notifications:activityFeed:items:activityAlert:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:notifications:activityFeed:items:managingNotifications:description',
      icon: 'SynergiesNumber',
      titleI18nKey: 'tutorialTiles:notifications:activityFeed:items:managingNotifications:title',
    },
  ]
};
