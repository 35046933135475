import React, { useMemo } from 'react';
import { TextField as TextFieldBase } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { IControlledField } from '../../types';
import { DataSourceLinkFormKeys, LinkAnalysisStatus } from '../../../Ingestion/types';

const Records: React.FC<IControlledField> = ({ label, controller }) => {
  const { t } = useTranslation();
  const { value } = controller;
  const analysisStatus = useWatch({ name: DataSourceLinkFormKeys.analysisStatus });

  const display = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      Number.isNaN(value)
        ? analysisStatus === LinkAnalysisStatus.Unknown
          ? t('wizard:noRecords')
          : t('wizard:unknownRecords')
        : `${value} ${t('wizard:Record', { count: value })}`,
    [value, t, analysisStatus],
  );

  // if records is undefined or null, we don't render

  return value === undefined || value === null ? null : (
    <TextFieldBase label={label} disabled readOnly value={display} />
  );
};

export default Records;
