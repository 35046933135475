import { TutorialTile } from "../../../../shared/tutorial-tiles/model";

import TutorialAnimation from './TutorialAnimation';

export const TUTORIAL_TILE_KEY = 'settings-preferences';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:settings:preferences:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:settings:preferences:items:updateYourSettings:description',
      icon: 'Contact',
      titleI18nKey: 'tutorialTiles:settings:preferences:items:updateYourSettings:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:settings:preferences:items:onboarding:description',
      icon: 'Streaming',
      titleI18nKey: 'tutorialTiles:settings:preferences:items:onboarding:title',
    },
  ]
};
