import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import {
  wasMultiSelectKeyUsed,
  wasToggleInSelectionGroupKeyUsed,
} from '../../utils';
import EntityField from '../../../../../../components/EntityField';
import { IColumn } from '../../../../../../types/IColumn';
import SingleColumnAggregationButton from './SingleColumnAggregationButton';
import { generateColumnUuid } from '../../../../utils';
import ColumnSortSettings from '../ColumnSortSettings/ColumnSortSettings';

interface Props {
  column: IColumn;
  isSelected: boolean;
  isGhosting: boolean;
  toggleSelection: (taskId: string) => void;
  toggleSelectionInGroup: (taskId: string) => void;
  multiSelectTo: (taskId: string) => void;
  index: number;
  hasOrderChanged: boolean;
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const SingleColumn = ({
  column,
  index,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  isGhosting,
  isSelected,
  hasOrderChanged,
}: Props) => {
  const performAction = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    const columnUuid = generateColumnUuid(column);

    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(columnUuid);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(columnUuid);
      return;
    }

    toggleSelection(columnUuid);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) return;
    // react only on left mouse button click
    if (event.button !== primaryButton) return;

    // we are using the event for selection
    event.preventDefault();

    performAction(event);
  };

  const handleSelect = () => {
    if (!isSelected) {
      toggleSelection(generateColumnUuid(column));
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Draggable draggableId={generateColumnUuid(column)} index={index}>
        {(provided: DraggableProvided) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <EntityField
              data-testid={`dnd-item-${column.name}`}
              isSelectable
              isSelected={isSelected}
              isOrange={hasOrderChanged}
              isDisabled={isGhosting}
              onClick={handleClick}
              data-index={index}
              name={column.name}
              isHidden={column?.isHidden}
              slot1={
                <SingleColumnAggregationButton
                  onClick={handleSelect}
                  syncedItem={column}
                />
              }
              slot2={
                <ColumnSortSettings
                  onClick={handleSelect}
                  column={{
                    ...column,
                    uuid: generateColumnUuid(column),
                  }}
                />
              }
            />
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default SingleColumn;
