import React from 'react';
import { SchemaEntry } from '../../../../../components/DataSchemaMapper/model';
import { GroupCollapse } from '../../../../../components/ui';
import {
  DataSourceConfigureStatus,
  IDesignSource,
  IDesignSourceEntity,
} from '../../../types';
import DataSourceEntityField from './DataSourceEntityField';
import { Operation, useFetchSourceFields } from './useFetchSourceFields';
import { EntityContext, EntityContextValue } from './EntityContext';
import { ProcessStatus } from '../../../../../api/model/schemas/ProcessStatus';
import { Availability } from '../../../../../api/model/schemas/Availability';

interface Props {
  entity: IDesignSourceEntity;
  entityFieldInfoSchema: SchemaEntry[];
  entityInfoSchema: SchemaEntry[];
  searchQuery?: string;
  sourceData: IDesignSource;
}

const availabilityMap: Record<IDesignSourceEntity['status'], Availability> = {
  Failed: Availability.Error,
  Warning: Availability.Error,
  Ingested: Availability.Available,
  NotIngested: Availability.Available,
};

export const DataSourceEntity = ({
  entity,
  entityFieldInfoSchema,
  entityInfoSchema,
  searchQuery,
  sourceData,
}: Props) => {
  // DEPS
  const {
    entityFields,
    isLoaded,
    isLoading,
    loadEntityFields,
    patchEntityField,
  } = useFetchSourceFields({ entity });

  // STATE
  const [isOpen, setOpen] = React.useState(false);

  // DERIVED STATE
  // NOTE: if there is a search query performed, there are "some" matching fields that have already been fetched
  // but the user hasn't expanded the entity manually (which performs a full load and ensures all fields are present)
  // we're expanding the entity automatically
  const isAutoExpandedOnSearch =
    searchQuery && entityFields.length > 0 && !isLoaded;
  const isActuallyOpen = isOpen || isAutoExpandedOnSearch;
  const isConfiguring =
    entity.configureStatus === DataSourceConfigureStatus.Configuring;

  const entityContextValue = React.useMemo<EntityContextValue>(
    () => ({
      patchEntityField,
    }),
    [patchEntityField]
  );

  const isEntitySelected =
    entityFields.length > 0 &&
    entityFields.every(
      (field) =>
        field.ingestionStatus === ProcessStatus.PendingProcess ||
        field.ingestionStatus === ProcessStatus.Processed
    );

  const selectedCount = React.useMemo(
    () =>
      entityFields.filter(
        (field) =>
          field.ingestionStatus === ProcessStatus.PendingProcess ||
          field.ingestionStatus === ProcessStatus.Processed
      ).length,
    [entityFields]
  );

  const isEntityOrange = entityFields.some(
    (field) =>
      field.ingestionStatus === ProcessStatus.PendingProcess ||
      field.ingestionStatus === ProcessStatus.PendingDelete
  );

  // CALLBACKS
  const handleIconClick = async () => {
    // we refresh the fields on each expansion, note that this will also trigger when an entity "looks expanded" after
    // search is performed and some of its fields matched the query, but the fields that do not match are still hidden
    if (!isOpen) await loadEntityFields();
    setOpen(!isOpen);
  };

  const handleTextClick = async () => {
    await loadEntityFields({
      operation: isEntitySelected ? Operation.Exclude : Operation.Include,
    });
  };

  // RENDER
  return (
    <EntityContext.Provider value={entityContextValue}>
      <GroupCollapse
        {...{
          searchQuery,
        }}
        info={{
          data: entity,
          schema: entityInfoSchema,
        }}
        groupId={entity.id}
        data-testid={`data-source-entity-group-${entity.name}`}
        groupName={entity.name}
        // NOTE: there might potentially be more results to be fetched if the group has been auto-expanded
        hasMore={isAutoExpandedOnSearch}
        isOpen={isActuallyOpen}
        isOrange={isEntityOrange}
        isRenderingOnQueryMismatch
        isSelected={isEntitySelected}
        isSyncing={isLoading || isConfiguring}
        onIconClick={handleIconClick}
        onTextClick={handleTextClick}
        isDisabled={[
          DataSourceConfigureStatus.Configuring,
          DataSourceConfigureStatus.NotConfigured,
        ].includes(entity.configureStatus)}
        selections={{
          current: selectedCount,
          total: entityFields.length,
        }}
        availability={availabilityMap[entity.status]}
      >
        {entityFields.map((field) => (
          <DataSourceEntityField
            {...{
              field,
              infoSchema: entityFieldInfoSchema,
              searchQuery,
            }}
            key={field.id}
            source={sourceData}
          />
        ))}
      </GroupCollapse>
    </EntityContext.Provider>
  );
};
