import { Label, Stack } from '@fluentui/react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataType } from '../../../../../api/model/schemas/DataType';
import { ProcessStatus } from '../../../../../api/model/schemas/ProcessStatus';
import DataSourceDropdown, {
  IDataSource,
} from '../../../../../components/DataSourceDropdown';
import { SelectDropdownVirtualList } from '../../../../../components/ui';
import { IDesignSourceEntity } from '../../../types';
import { entityDropdownStyles } from '../SelectOneOfType/styles';
import { IEntityOption } from '../SelectOneOfType/types';
import { entitiesToOptions } from '../SelectOneOfType/utils';

interface Props {
  entityId: string;
  sourceId: string;
  onSourceChange?: (id: string) => void;
  onEntityChange: (id: string) => void;
  sources: IDataSource[];
  entities: IDesignSourceEntity[];
  isRequired: boolean;
  isDisabled: boolean;
  label?: string;
  isTransformation?: boolean;
  allowedDataTypes?: DataType[];
}

const EntityPicker: React.FC<Props> = ({
  label,
  entityId,
  sourceId,
  onEntityChange,
  onSourceChange,
  sources,
  entities,
  isRequired,
  isDisabled,
  isTransformation,
  allowedDataTypes,
}) => {
  const { t } = useTranslation();

  const labelText = label || t('catalog:Entity');

  const options = useMemo(
    () =>
      entitiesToOptions({
        entities: isTransformation
          ? entities
          : entities.filter(
              (entity) => entity.ingestionStatus === ProcessStatus.Processed
            ),
        allowedDataTypes,
      }),
    [entities, isTransformation]
  );

  const currentOption = useMemo(
    () => options.find((o) => o.value === entityId),
    [options, entityId]
  );

  const handleSourceChange = useMemo(
    () =>
      onSourceChange &&
      ((id: string) => {
        onSourceChange(id);
        onEntityChange(null);
      }),
    [onEntityChange, onSourceChange]
  );

  useEffect(() => {
    if (sources?.length && !sourceId && handleSourceChange) {
      onSourceChange(sources[0].id);
    }
  }, [sources, sourceId, handleSourceChange]);

  return (
    <Stack>
      <Label required={isRequired}>{labelText}</Label>
      <Stack horizontal>
        <DataSourceDropdown
          activeDataSourceId={sourceId}
          onChangeDataSource={handleSourceChange}
          dataSources={sources}
        />
        <SelectDropdownVirtualList
          options={options}
          isDisabled={!options.length || isDisabled}
          placeholder={t('filters:Select')}
          onChange={(option: IEntityOption) => onEntityChange(option.value)}
          value={currentOption}
          styles={entityDropdownStyles}
          className={`entity-picker-${labelText}`}
        />
      </Stack>
    </Stack>
  );
};

export default EntityPicker;
