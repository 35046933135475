import { DataSourceEntity } from '../../../api/model/schemas/DataSourceEntity';
import { DataSourceEntityField } from '../../../api/model/schemas/DataSourceEntityField';
import { Link } from '../../../api/model/schemas/Link';
import { LinkFields } from '../../../api/model/schemas/LinkFields';
import { IDesignerFilter } from '../../../types/IDesignerFilter';
import {
  IDesignSource,
  IDesignSourceEntity,
  UIEnhancedDesignSourceEntityField,
} from '../types';

export enum LinkAnalysisStatus {
  'Unknown' = 'Unknown',
  'Ok' = 'Ok',
  'InvalidType' = 'InvalidType',
  'TypeToWide' = 'TypeToWide',
}

export enum LinkFilterDirection {
  'Automatic' = 'Automatic',
  'BothWays' = 'BothWays',
  'OneFiltersMany' = 'OneFiltersMany',
}

export enum LinkType {
  'OneToOne' = 'OneToOne',
  'OneToMany' = 'OneToMany',
  'ManyToOne' = 'ManyToOne',
  'ManyToMany' = 'ManyToMany',
  'Automatic' = 'Automatic',
}

export enum TaskStatus {
  'InProgress' = 'InProgress',
  'Completed' = 'Completed',
  'Failed' = 'Failed',
}

export enum DataSourceLinkFormKeys {
  records = 'records',
  flow = 'flow',
  type = 'type',
  fromId = 'fromId',
  toId = 'toId',
  analysisStatus = 'analysisStatus',
}

export type LinkAnalysisRequestFields = { fromId: string; toId: string }[];

export interface LinkAnalysisRequest {
  id: string;
  records: number;
  analyzedFlow: LinkFilterDirection;
  analyzedType: LinkType;
  status: TaskStatus;
  fields: LinkAnalysisRequestFields;
}

export interface DataSourceLink {
  id: string;
  records: number;
  flow: LinkFilterDirection;
  type: LinkType;
  requiresAnalysis: boolean;
}

export interface DataSourceLinkFields {
  id: string;
  fromId: string;
  toId: string;
}

export interface DesignerIngestionState {
  sources: IDesignSource[];
  dataSourceEntities: IDesignSourceEntity[];
  dataSourceEntitiesNextPageUrl?: string;
  dataSourceFields: UIEnhancedDesignSourceEntityField[];
  areFieldsUpdating: boolean;
  selectedSourceId: string;
  // NOTE: keeping those entity fields separately as `dataSourceFields` are used in enough places
  // to make it risky re-using that attribute for the purposes of normalizing data for links
  linkedEntities: Record<string, DataSourceEntity>;
  linkedEntityFields: Record<string, DataSourceEntityField>;
  links: Record<Link['id'], Link>;
  linksFields: Record<string, LinkFields>;
  /** modes within the "designer – ingestion" section */
  modes: {
    /** "Data" mode */
    data: {
      /** normalized entity data */
      entities: Record<DataSourceEntity['id'], DataSourceEntity>;
      /** URL of the next page of entities to be fetched */
      entitiesNextPageUrl?: string;
      /** normalized entity fields data per entity id */
      fieldsPerEntity: Record<
        DataSourceEntity['id'],
        Record<DataSourceEntityField['id'], DataSourceEntityField>
      >;
      /** URL of the next page of fields to be fetched */
      fieldsNextPageUrl?: string;
      /** indicates that a shimmer list should be displayed */
      isLoading: boolean;
    };
  };
  selectedLinkFieldsId: string | null;
  selectedEmptyLinkId: string | null;
  isLoadingLinks: boolean;
  isLoadingSources: boolean;
  isSmartAnalysisLinksInProgress: boolean;
  ingestionFilters: IDesignerFilter[];
  isLoadingFilters: boolean;
  entitiesWithValues: any;
  isLoadingDataSourceFields: boolean;
  isConfiguringDataSource: boolean;
  isIngestingDataSource: boolean;
  selectedEntitiesCountInDataSource: number;
  transforms: {
    records: IDesignSourceEntity[];
    isLoading: boolean;
    error?: boolean;
  };
}

export type DataModeState = DesignerIngestionState['modes']['data'];
