import {
  IButtonStyles, IStackStyles, ITextStyles, mergeStyles,
} from '@fluentui/react';
import { baseColors } from '../../theme';

export const renameButtonClassName = 'rename-button';

const compileIconBgColor = (isEditing, hover, isSelected) => {
  // all fields/entities that are editable, should be "selected"
  // while entering edit state.
  // Therefore on hover we want to show the most contrasting bg color
  if (isEditing && hover) {
    return baseColors.white;
  }

  if (hover && isSelected) {
    return baseColors.hover;
  }

  if (hover && !isSelected) {
    return baseColors.selectedWithHover;
  }

  return 'transparent';
};

export const iconButtonStyles = (isEditing, isSelected): IButtonStyles => ({
  root: {
    height: '24px',
    width: '24px',
    color: baseColors.textColor,
    backgroundColor: compileIconBgColor(isEditing, false, isSelected),
    opacity: isSelected ? 1 : 'inherit',
    borderRadius: 4,
  },
  rootHovered: {
    color: baseColors.textColor,
    backgroundColor: compileIconBgColor(isEditing, true, isSelected),
  },
});

export const contentEdit = mergeStyles({
  background: baseColors.white,
  border: `1px solid ${baseColors.orange}`,
  borderRadius: '2px',
  padding: '2px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '100%',
  fontSize: '12px',
  textOverflow: 'unset !important',
  selectors: {
    br: {
      display: 'none',
    },
    '*': {
      display: 'inline',
      whiteSpace: 'nowrap',
    },
  },
});

export const editIconButtonStyles = (isEditing, isSelected): IButtonStyles => ({
  root: {
    height: 24,
    width: 24,
    color: baseColors.textColor,
    backgroundColor: compileIconBgColor(isEditing, false, isSelected),
    borderRadius: 4,
  },
  rootHovered: {
    color: baseColors.textColor,
    backgroundColor: compileIconBgColor(isEditing, true, isSelected),
  },
});

export const textStyleAtRest = (isGroupHeader: boolean): ITextStyles => ({
  root: {
    fontWeight: isGroupHeader ? 600 : 400,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const textStyleWhileEditing:ITextStyles = {
  root: {
    fontSize: '12px',
  },
};

const compileBgColor = (isSelected, hover) => {
  if (isSelected && hover) {
    return baseColors.selectedWithHover;
  }

  if (isSelected) {
    return baseColors.selected;
  }

  if (hover) {
    return baseColors.hover;
  }

  return baseColors.white;
};

export const containerStyles = (
  isSelected: boolean, transparentBackground: boolean,
): IStackStyles => ({
  root: {
    fontSize: '12px',
    lineHeight: 16,
    flex: 1,
    height: '28px',
    cursor: 'default',
    paddingLeft: 0,
    backgroundColor: transparentBackground ? 'transparent' : compileBgColor(isSelected, false),
    selectors: {
      '&:hover': {
        backgroundColor: transparentBackground ? 'transparent' : compileBgColor(isSelected, true),
      },
    },
  },
});
