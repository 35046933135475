import { TutorialTile } from "../../../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from '../../../../../../../shared/tutorial-tiles/components/animations/Filters';

export const TUTORIAL_TILE_KEY = 'designer-ingest-filters';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:ingest:filters:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:filters:items:filterYourData:description',
      icon: 'Filter',
      titleI18nKey: 'tutorialTiles:designer:ingest:filters:items:filterYourData:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:filters:items:pickValues:description',
      icon: 'CheckListText',
      titleI18nKey: 'tutorialTiles:designer:ingest:filters:items:pickValues:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:ingest:filters:items:createRules:description',
      icon: 'FlowChart',
      titleI18nKey: 'tutorialTiles:designer:ingest:filters:items:createRules:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010204222-Filters-Reduce-the-clutter',
};
