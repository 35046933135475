import React, { useState, useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';
import * as mdiJs from '@mdi/js';

import { SearchBox, Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { List, AutoSizer } from 'react-virtualized';
import { classNames, searchBoxStyles } from './styles';

import { SmallImageThumbnail } from '../../ui';

interface Props {
  initialValue?: string | null;
  isDisabled?: boolean;
  onValueChange?: (newValue: string) => void;
}

const searchRegex = (searchQuery: string): RegExp =>
  new RegExp(searchQuery, 'i');

const mdiIconKeys = Object.keys(mdiJs);
const ITEM_SIZE = 48;

const IconPicker = ({
  initialValue,
  isDisabled = false,
  onValueChange,
}: Props) => {
  // DEPS
  const { t } = useTranslation('commands');

  // STATE
  const [pickedIcon, setIcon] = useState(initialValue || null);
  const [localSearchQuery, setLocalSearchQuery] = useState<string>(
    mdiIconKeys.includes(initialValue) ? initialValue : undefined
  );

  // DERIVED STATE
  const iconsToRender = useMemo(
    () =>
      mdiIconKeys.filter(
        (iconName) => iconName.search(searchRegex(localSearchQuery)) !== -1
      ),
    [localSearchQuery]
  );

  const ITEMS_COUNT = iconsToRender.length;

  // EFFECTS
  useEffect(() => {
    if (onValueChange) {
      onValueChange(pickedIcon);
    }
  }, [pickedIcon]);

  // CALLBACKS
  const debouncedSetLocalSearchQuery = debounce((input) => {
    setLocalSearchQuery(input);
  }, 850);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      debouncedSetLocalSearchQuery(e.target.value);
    }
  };

  // RENDER
  return (
    <Stack className={classNames.mainWrap}>
      <SearchBox
        autoFocus
        styles={searchBoxStyles}
        placeholder={t('Search')}
        onClear={() => setLocalSearchQuery('')}
        onChange={handleChange}
        data-testid="searchbox"
        value={localSearchQuery}
        spellCheck={false}
        iconProps={{
          iconName: 'Search',
        }}
        clearButtonProps={{
          iconProps: {
            iconName: 'Cancel',
          },
        }}
      />
      <div className={classNames.sectionWrap}>
        {/* based on // https://stackoverflow.com/questions/40478198/is-a-flex-wrap-inline-block-style-of-item-wrapping-supported-by-react-virtualize
         */}
        <AutoSizer>
          {({ height, width }) => {
            const itemsPerRow = Math.floor(width / ITEM_SIZE);
            const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);

            return (
              <List
                className="List"
                width={width}
                height={height}
                rowCount={rowCount}
                rowHeight={ITEM_SIZE}
                rowRenderer={({ index, key, style, isVisible }) => {
                  const items = [];
                  const fromIndex = index * itemsPerRow;
                  const toIndex = Math.min(
                    fromIndex + itemsPerRow,
                    ITEMS_COUNT
                  );

                  for (let i = fromIndex; i < toIndex; i++) {
                    items.push(
                      <div
                        style={{
                          width: ITEM_SIZE,
                          height: ITEM_SIZE,
                          display: 'inline-flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <SmallImageThumbnail
                          {...{
                            isDisabled,
                          }}
                          iconName={iconsToRender[i]}
                          isSelected={pickedIcon === iconsToRender[i]}
                          onClick={
                            pickedIcon === iconsToRender[i] || !isVisible
                              ? null
                              : () => setIcon(iconsToRender[i])
                          }
                        />
                      </div>
                    );
                  }

                  return (
                    <div
                      key={key}
                      style={{
                        ...style,
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      {items}
                    </div>
                  );
                }}
              />
            );
          }}
        </AutoSizer>
      </div>
    </Stack>
  );
};

export default IconPicker;
