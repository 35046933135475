import { createAction, ThunkDispatch } from '@reduxjs/toolkit';

import { DefinedSortTypes, ObjectTypes } from './types';
import { ApplicationState, AppThunkAction } from '../../store/types';

import { KnownAction as StreamsKnownAction } from '../Streams/types';
import { actionCreators } from '../Streams/actions';
import * as excelActions from '../../actions/excel-actions';
import { SelectionType } from '../../components/ui/ViewControlButton';

export const setActiveObjectType = createAction<
ObjectTypes,
'SET_ACTIVE_OBJECT_TYPE'
>('SET_ACTIVE_OBJECT_TYPE');
export const setIsOtherUserSyncing = createAction<
boolean,
'app/IS_OTHER_USER_SYNCING'
>('app/IS_OTHER_USER_SYNCING');

type KnownAction =
  | ReturnType<typeof setActiveObjectType>
  | ReturnType<typeof setActiveObjectType>;

// TODO - change existing dataset type
// TODO - if CurrentDataset and On demand activated, re-render dataset with new type

const setDatasetOrActiveObjectType = (
  dispatch: ThunkDispatch<
  ApplicationState,
  null,
  KnownAction | StreamsKnownAction
  >,
  getState: () => ApplicationState,
  type: ObjectTypes,
) => {
  const { currentDatasetId } = getState().streams;
  if (currentDatasetId) {
    dispatch(actionCreators.editDataset(currentDatasetId, { type }));
  } else {
    dispatch(setActiveObjectType(type));
  }
};

export const setFreeForm = (): AppThunkAction<
KnownAction | StreamsKnownAction
> => (dispatch, getState) => {
  setDatasetOrActiveObjectType(dispatch, getState, ObjectTypes.FREE_FORM);
};
export const setTable = (): AppThunkAction<
KnownAction | StreamsKnownAction
> => (dispatch, getState) => {
  setDatasetOrActiveObjectType(dispatch, getState, ObjectTypes.TABLE);
};
export const setPivotTable = (): AppThunkAction<
KnownAction | StreamsKnownAction
> => () => {
  // TODO: Update pivot table to datasetType system
  /* setDatasetOrActiveObjectType(dispatch, getState, ObjectTypes.PIVOT_TABLE); */
  excelActions.generatePivotTable();
};
export const setGroupTable = (): AppThunkAction<
KnownAction | StreamsKnownAction
> => (dispatch, getState) => {
  setDatasetOrActiveObjectType(dispatch, getState, ObjectTypes.GROUP_TABLE);
};

export const setSelectedItemId = createAction<string, 'ui/SET_SELECTED_ITEM_ID'>('ui/SET_SELECTED_ITEM_ID');
export const setMultipleSelectedItemIds = createAction<string[], 'ui/SET_MULTIPLE_SELECTED_ITEMS_IDS'>('ui/SET_MULTIPLE_SELECTED_ITEMS_IDS');
export const clearSelection = createAction('ui/CLEAR_SELECTION');
export const appendSelectedItemId = createAction<string, 'ui/APPEND_SELECTED_ITEM'>('ui/APPEND_SELECTED_ITEM');
export const removeSelectedItemId = createAction<string, 'ui/REMOVE_SELECTED_ITEM_ID'>('ui/REMOVE_SELECTED_ITEM_ID');
export const setSortType = createAction<{
  path: string,
  sortType: string | DefinedSortTypes
}, 'ui/SET_SORT_TYPE'>('ui/SET_SORT_TYPE');
export const setSelectionType = createAction<SelectionType, 'ui/SET_SELECTION_TYPE'>('ui/SET_SELECTION_TYPE');
export const setEditingItem = createAction<boolean, 'ui/SET_ITEM_EDITING_VALUE'>('ui/SET_ITEM_EDITING_VALUE');
