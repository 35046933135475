import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { I18N_PREFIX } from '../constants';
import { OnShareCallback, ShareLinkDataType } from '../model';
import { classNames } from '../styles';
import { generateRichLink } from '../utils';
import { ShareIndicator } from './ShareIndicator';

interface Props {
  canvas: HTMLCanvasElement;
  copyingFinished?: ShareLinkDataType;
  isError: boolean;
  onShare(
    shareButtonType: ShareLinkDataType,
    callback: OnShareCallback
  ): Promise<void>;
  selectedCardKeys: number[];
  writingToClipboard?: ShareLinkDataType;
}

export const ShareSmartObjectButton = ({
  canvas,
  copyingFinished,
  isError,
  onShare,
  selectedCardKeys,
  writingToClipboard,
}: Props): JSX.Element => {
  // DEPS
  const { t } = useTranslation();

  // DERIVED STATE
  const isCopyingFinished = copyingFinished === ShareLinkDataType.Rich;
  const isWritingToClipboard = writingToClipboard === ShareLinkDataType.Rich;

  // CALLBACKS
  const onSmartObjectShare = React.useCallback(() => {
    onShare(ShareLinkDataType.Rich, async (selectionPreviews) => {
      const richLinks = selectedCardKeys
        .map((cardKey) => {
          const cardPreview = selectionPreviews[cardKey];

          if (!cardPreview) return undefined;

          // NOTE: for now we support only single-card selection so we can reuse the "combined" canvas
          const { /* canvas, */ parentTitle, title } = cardPreview;

          const richLink = generateRichLink({
            canvas,
            title: `${parentTitle} – ${title}`,
          });

          return `<p>${richLink}</p>`;
        })
        .join('<br/>');

      const data = [
        new ClipboardItem({
          // @ts-ignore – TS types require the value of the dictionary to be a promise
          // while using `Promise.resolve()` to circumvent that causes runtime errors
          [ShareLinkDataType.Rich]: new Blob([richLinks], {
            type: ShareLinkDataType.Rich,
          }),
        }),
      ];

      await navigator.clipboard.write(data);
    });
  }, [canvas, onShare, selectedCardKeys]);

  // RENDER
  return (
    <button
      className={cn(classNames.shareButton, classNames.shareButtonExposed)}
      onClick={onSmartObjectShare}
      type="button"
    >
      <ShareIndicator
        {...{ isCopyingFinished, isError, isWritingToClipboard }}
      />
      {t(`${I18N_PREFIX}.buttons.smartObject`)}
    </button>
  );
};
