import React from 'react';

import { routes } from '../../../../../constants/routes';

import IngestionData from '../Data';
import Filters from '../Filters';
import Sources from '../Sources';
import {
  SectionRoute,
  SectionSwitch,
} from '../../../../../shared/section-route/SectionSwitch';
import { DESIGNER_MODULE_NAME } from '../../../constants';
import Transforms from '../Transforms';

const DESIGNER_INGESTION_SECTION_NAME = 'ingestion';

const INGESTION_SECTION_ROUTES: SectionRoute[] = [
  [routes.designer.ingestion.data, IngestionData],
  [routes.designer.ingestion.filters.index, Filters],
  [routes.designer.ingestion.sources, Sources],
  [routes.designer.ingestion.transforms, Transforms],
];

const IndexPage = () => (
  <SectionSwitch
    defaultPath={routes.designer.ingestion.sources}
    module={DESIGNER_MODULE_NAME}
    routes={INGESTION_SECTION_ROUTES}
    section={DESIGNER_INGESTION_SECTION_NAME}
  />
);
export default IndexPage;
