import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from '../constants/routes';
import { constant } from '../../shared/types/utils';

const ONBOARDING_ROUTES: readonly string[] = constant<string[]>()([
  routes.onboarding.index,
  routes.onboarding.streams.index,
]);

export default () => {
  const { pathname } = useLocation();

  const isOnboarding = useMemo(() => ONBOARDING_ROUTES.includes(pathname), [pathname]);

  return {
    isOnboarding,
  };
};
