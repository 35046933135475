import { HideSectionCondition } from '../../modules/App/components/Content/constants';
import { ObjectTypes } from '../../modules/App/types';
import { IDataset } from '../../types/IDataset';
import { IStream } from '../../types/IStream';

interface ShouldRemoveParams {
  condition: HideSectionCondition;
  isSyncing: boolean;
  currentStreamId: string | null;
  currentDataset: IDataset | null;
  activeObjectType: ObjectTypes;
  recentStreams: IStream[];
  selectedDesignId: string | null;
  selectedBuilderStreamId: string | null;
  selectedCatalogSourceId?: string;
  selectedBuilderDataSourceIds?: string[];
  selectedStreamSourceFieldsCount?: number;
}

type Input = {
  activeObjectType: ObjectTypes;
  currentDataset: IDataset | null;
};

const getIsItemOfType = (
  type: ObjectTypes,
  { activeObjectType, currentDataset }: Input,
) => {
  const isItemOfType = currentDataset
    ? currentDataset.type === type
    : activeObjectType === type;

  return isItemOfType;
};

export const checkShouldRemove = ({
  condition,
  isSyncing,
  currentStreamId,
  currentDataset,
  activeObjectType,
  recentStreams,
  selectedDesignId,
  selectedBuilderStreamId,
  selectedCatalogSourceId,
  selectedBuilderDataSourceIds,
  selectedStreamSourceFieldsCount,
}: ShouldRemoveParams) => {
  switch (condition) {
    case HideSectionCondition.whenNoBuilderStreamIsSelected:
      return !selectedBuilderStreamId;
    case HideSectionCondition.whenNoDesignIsSelected:
      return !selectedDesignId;
    case HideSectionCondition.whenSyncing:
      return isSyncing;
    case HideSectionCondition.whenDatasetIsSelected:
      return !!currentDataset?.id;
    case HideSectionCondition.whenStreamIsNotSelected:
      return !currentStreamId;
    case HideSectionCondition.whenNoRecentStreams:
      return !recentStreams || recentStreams.length === 0;
    case HideSectionCondition.whenTableHasNoColumnsSelected: {
      const isTable = getIsItemOfType(ObjectTypes.TABLE, {
        activeObjectType,
        currentDataset,
      });

      if (!isTable) return false;

      return (
        !currentDataset
        || (currentDataset && currentDataset.columns.length === 0)
      );
    }
    case HideSectionCondition.whenTableWasNotPlayed: {
      const isTable = getIsItemOfType(ObjectTypes.TABLE, {
        activeObjectType,
        currentDataset,
      });

      if (!isTable) return false;

      return (
        !currentDataset
        || (currentDataset && !currentDataset.tableId)
      );
    }
    case HideSectionCondition.whenGroupTableHasNoColumnsSelected: {
      const isGroupTable = getIsItemOfType(ObjectTypes.GROUP_TABLE, {
        activeObjectType,
        currentDataset,
      });

      if (!isGroupTable) return false;

      return (
        !currentDataset
        || (currentDataset && currentDataset.columns.length === 0)
      );
    }
    case HideSectionCondition.forGroupTable: {
      const isGroupTable = getIsItemOfType(ObjectTypes.GROUP_TABLE, {
        activeObjectType,
        currentDataset,
      });

      return isGroupTable;
    }
    case HideSectionCondition.whenNoCatalogSourceIsSelected:
      return !selectedCatalogSourceId;

    case HideSectionCondition.whenNoBuilderDataSourceIsSelected:
      return selectedBuilderDataSourceIds.length === 0 && selectedStreamSourceFieldsCount === 0;

    default:
      return false;
  }
};
