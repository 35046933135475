import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTypedAction } from '../../../../../../shared/utils/actions';
import getClient from '../../../../../api/getClient';
import { DataSource } from '../../../../../api/model/schemas/DataSource';
import { DataSourceEntity } from '../../../../../api/model/schemas/DataSourceEntity';
import { DataSourceEntityField } from '../../../../../api/model/schemas/DataSourceEntityField';
import { ApiV4ResponseWrapper } from '../../../types';
import { fetchEntitiesForSource, patchDataSourceEntityField } from '../../api';
import { ACTION_PREFIX } from './constants';

interface FetchEntitiesResult {
  entities: DataSourceEntity[];
  entitiesNextPageUrl?: string;
}

export interface FetchEntitiesAndFieldsResult extends FetchEntitiesResult {
  fields?: DataSourceEntityField[];
  fieldsNextPageUrl?: string;
}

interface DataModeLoadedPayload {
  dataSourceId: DataSource['id'];
}
type DataModeLoadedResult = FetchEntitiesResult;
export const dataModeLoaded = createAsyncThunk<
  DataModeLoadedResult,
  DataModeLoadedPayload
>(`${ACTION_PREFIX}/MODE_LOADED` as const, async ({ dataSourceId }) => {
  const {
    data: { data: entities, nextPage },
  } = await fetchEntitiesForSource(dataSourceId)({
    orderBy: 'name',
  });

  return {
    // NOTE: casting because `IDesignSourceEntity` is not up to date compared to `DataSourceEntity` so they mismatch
    entities: entities as any,
    entitiesNextPageUrl: nextPage,
  };
});

interface InfiniteScrollEndPayload {
  entitiesNextPageUrl: string;
  fieldsNextPageUrl?: string;
}
type InfiniteScrollEndResult = FetchEntitiesAndFieldsResult;
export const infiniteScrollEnd = createAsyncThunk<
  InfiniteScrollEndResult,
  InfiniteScrollEndPayload
>(
  `${ACTION_PREFIX}/INFINITE_SCROLL_END`,
  async ({ entitiesNextPageUrl, fieldsNextPageUrl }) => {
    const {
      data: { data: entities, nextPage: nextEntitiesPageUrl },
    } = await getClient().get<ApiV4ResponseWrapper<DataSourceEntity[]>>(
      entitiesNextPageUrl
    );

    let fields: DataSourceEntityField[] = [];
    let nextFieldsPageUrl: string;
    if (fieldsNextPageUrl) {
      const { data: nextFieldsResponseData } = await getClient().get<
        ApiV4ResponseWrapper<DataSourceEntityField[]>
      >(fieldsNextPageUrl);

      fields = nextFieldsResponseData.data;
      nextFieldsPageUrl = nextFieldsResponseData.nextPage;
    }

    return {
      entities,
      entitiesNextPageUrl: nextEntitiesPageUrl,
      fields,
      fieldsNextPageUrl: nextFieldsPageUrl,
    };
  }
);

interface SearchPerformedPayload {
  dataSourceId: DataSource['id'];
  searchQuery: string;
}
export const searchPerformed = createTypedAction<SearchPerformedPayload>()(
  `${ACTION_PREFIX}/SEARCH_PERFORMED`
);

type SearchFinishedPayload = FetchEntitiesAndFieldsResult;
export const searchFinished = createTypedAction<SearchFinishedPayload>()(
  `${ACTION_PREFIX}/SEARCH_FINISHED`
);

export const searchFailed = createTypedAction()(
  `${ACTION_PREFIX}/SEARCH_FAILED`
);

export const entityFieldsLoaded = createTypedAction<{
  fields: DataSourceEntityField[];
}>()(`${ACTION_PREFIX}/ENTITY_FIELDS_LOADED`);

export interface PatchFieldsTriggeredPayload {
  fieldId: DataSourceEntityField['id'];
  updatedValues: Partial<DataSourceEntityField>;
}
export const patchFieldsTriggered = createAsyncThunk<
  void,
  PatchFieldsTriggeredPayload
>(
  `${ACTION_PREFIX}/PATCH_FIELDS_TRIGGERED`,
  async ({ fieldId, updatedValues }) => {
    await patchDataSourceEntityField({
      ...updatedValues,
      id: fieldId,
    });
  }
);
