import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MachineLearning as MLType } from '../../../../../api/model/schemas/MachineLearning';
import { MachineLearningProcessStatus } from '../../../../../api/model/schemas/MachineLearningProcessStatus';
import EntityField from '../../../../../components/EntityField';
import EntityFieldSlotSpacer from '../../../../../components/EntityFieldSlotSpacer';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import { GroupCollapse } from '../../../../../components/ui';
import { IFoldableListItem } from '../../../../../components/ui/FoldableList/types/IFoldableListGroup';
import useItemSelections from '../../../../../hooks/useItemSelections';
import { useSearchFilteredRecords } from '../../../../../hooks/useSearchFilteredRecords';
import useSortedFoldableGroups from '../../../../../hooks/useSortedFoldableGroups';
import { normalizeById } from '../../../../../utils/normalizeEntities';
import { DefinedSortTypes } from '../../../../App/types';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { fetchMachineLearningAssets } from '../../actions';
import { getMLAssets } from '../../selectors';

export const MachineLearning = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const selectedDesignId = useSelector(getSelectedDesignId);
  const { isLoading, records } = useSelector(getMLAssets);
  const { selectedItem: selectedItemId } = useItemSelections();

  // EFFECTS
  React.useEffect(() => {
    if (selectedDesignId)
      dispatch(fetchMachineLearningAssets(selectedDesignId));
  }, [dispatch, selectedDesignId]);

  // DERIVED STATE
  const { filteredRecords } = useSearchFilteredRecords(records);
  const normalizedRecords = React.useMemo(
    () => normalizeById(records),
    [records]
  );

  const { mappedGroups, currentSort, onClickHeaderHandler } =
    useSortedFoldableGroups({
      input: filteredRecords,
      selectedItemId,
    });

  // CALLBACKS
  const { handleItemClickedOnSingleSelection, selectedItem } =
    useItemSelections();

  // PARTS
  const renderMLAsset = (asset: MLType | null) =>
    asset ? (
      <EntityField
        key={asset.id}
        slot1={<EntityFieldSlotSpacer />}
        name={asset.name}
        isSelectable
        isSelected={asset.id === selectedItem}
        onClick={() => handleItemClickedOnSingleSelection(asset.id)}
        isDisabled={
          asset.processStatus === MachineLearningProcessStatus.Processing
        }
        isSyncing={
          asset.processStatus === MachineLearningProcessStatus.Processing
        }
      />
    ) : null;

  const onRenderItem = (item: IFoldableListItem) => {
    const entry = normalizedRecords[item.key];

    return renderMLAsset(entry);
  };

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (currentSort === DefinedSortTypes.Name) {
    const sortedAssets = [...filteredRecords].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return <>{sortedAssets.map(renderMLAsset)}</>;
  }

  return (
    <>
      {mappedGroups.map((group) => (
        <GroupCollapse
          groupId={group.key}
          key={group.key}
          isOpen={group.isOpen}
          groupName={group.name}
          onClick={onClickHeaderHandler}
        >
          {group.items.map(onRenderItem)}
        </GroupCollapse>
      ))}
    </>
  );
};
