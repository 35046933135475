// TODO: move into i18n/types
import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../../modules/Designer/types";
import { isResponseTypeIdMatching } from "../../utils";
import { TypeIds } from "../../../../model/schemas";
import { translateApiName } from "../../../../../config/i18n/utils";
import { i18nDataTypeKey, i18nFormatTypeKey, i18nProcessedDataTypeKey, i18nProcessedFormatTypeKey } from "../../constants";
import { TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP } from "../../../../constants";
import { StreamHub } from "../../../../model/schemas/StreamHub";

export const isStreamHubResponse = (
  response: AxiosResponse<ApiV4ResponseWrapper<unknown>>
): response is AxiosResponse<ApiV4ResponseWrapper<StreamHub[]>> =>
  isResponseTypeIdMatching(response, TypeIds.StreamHub);

export const mutablyParseStreamHubs = (response: AxiosResponse<ApiV4ResponseWrapper<StreamHub[]>>) => {
  response.data.data = response.data.data.map(hub => {
    return {
      ...hub,
      [i18nDataTypeKey]: translateApiName(hub.$typeId, hub.dataType),
      [i18nFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[hub.dataType] ?? hub.$typeId,
        String(hub.formatType),
      ),
      [i18nProcessedDataTypeKey]: translateApiName(hub.$typeId, hub.processedDataType),
      [i18nProcessedFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[hub.processedDataType] ?? hub.$typeId,
        String(hub.processedFormatType),
      ),
    };
  });

  return response;
}
