import { mergeStyleSets } from 'office-ui-fabric-react';
import React from 'react';
import { baseColors } from '../../../../../theme';

export const tableCell: React.CSSProperties = {
  textAlign: 'left',
  padding: '4px',
};

export const tableHeaderCell: React.CSSProperties = {
  ...tableCell,
  borderBottom: `1px solid ${baseColors.inputBorder}`,
  background: baseColors.lightGrey,
  color: baseColors.midOrange,
};

export const classNames = mergeStyleSets({
  tableRow: {
    display: 'flex',
    selectors: {
      '&:hover': {
        background: baseColors.midGrey,
      },
    },
  },
  headerRow: {
    display: 'flex',
    top: '0',
    position: 'sticky',
  },
  cell: {
    flex: 1,
  },
  table: {
    marginTop: '8px',
    border: `1px solid ${baseColors.inputBorder}`,
    maxHeight: 'calc(100% - 30px)',
    overflowY: 'auto',
  },
});
