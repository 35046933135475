import React from 'react';
import { IStackStyles, ITextStyles, Stack, Text } from '@fluentui/react';
import { useGetSyncingText } from '../../hooks/useGetSyncingText';
import { useGetLastUpdatedStreamingTime } from '../../hooks/useGetLastUpdatedStreamingTime';

const textStyles: ITextStyles = {
  root: {
    fontSize: '8px',
    fontWeight: 400,
    textAlign: 'right',
  },
};

const stackStyles: IStackStyles = {
  root: {
    position: 'absolute',
    bottom: '4px',
    right: '0',
  },
};

export const HeaderMessage = () => {
  const { syncingText } = useGetSyncingText();
  const streamingTime = useGetLastUpdatedStreamingTime();

  return (
    <Stack styles={stackStyles}>
      {syncingText && (
        <Text data-testid="page-loading-text" styles={textStyles}>
          {syncingText}
        </Text>
      )}
      {streamingTime && !syncingText && (
        <Text data-testid="last-streaming-timestamp" styles={textStyles}>
          {streamingTime}
        </Text>
      )}
    </Stack>
  );
};
