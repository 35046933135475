import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WizardPage, { WizardType } from '../../../../pageTemplates/WizardPage';
import { normalizeByKey } from '../../../../utils/normalizeEntities';
import { fetchStreamLinks } from '../../api';
import {
  getLinksAnalysisState,
  getSelectedBuilderStreamId,
  getSelectedStream,
  getStreamLinks,
} from '../../selectors';
import { MultipleIslandResults } from './MultipleIslandResults';
import { SingleIslandResult } from './SingleIslandResult';
import { classNames } from './styles';

export const AssetLinksWizard = () => {
  // HOOKS
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  // STATE
  const { streamLinks, isLoading: isLoadingStreamLinks } =
    useSelector(getStreamLinks);
  const entityName = useSelector(getSelectedStream);
  const { isLoading, results } = useSelector(getLinksAnalysisState);
  const [closedDataSourceResults, setClosedResults] = React.useState<string[]>(
    []
  );
  const selectedStreamId = useSelector(getSelectedBuilderStreamId);

  // DERIVED STATE
  const filteredResults = React.useMemo(
    () =>
      results.filter(
        (result) =>
          result.selectedIslands.length > 0 &&
          result.selectedIslands.some(
            (island) => island.selectedLinks.length > 0
          )
      ),
    [results]
  );

  const normalizedStreamLinksByLinkId = React.useMemo(
    () => normalizeByKey(streamLinks, 'linkId'),
    []
  );

  const areVisibleResultsPresent = filteredResults.length > 0;
  const areAllResultsClosed =
    closedDataSourceResults.length === filteredResults.length &&
    filteredResults.length > 0;

  // EFFECTS
  React.useEffect(() => {
    // We should already have stream links, because you can only access
    // this view from <AssetLinks /> - so this is "just in case" scenario
    if (selectedStreamId && streamLinks.length === 0 && !isLoadingStreamLinks) {
      dispatch(fetchStreamLinks(selectedStreamId));
    }
  }, [selectedStreamId]);

  // PARTS
  const buttonsConfig = [
    {
      tooltip: <Trans i18nKey="actionBar:general.cancelAction" />,
      onClick: () => history.go(-1),
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'cancel-processing-button',
    },
  ];

  return (
    <WizardPage
      headerBarButtonsConfig={buttonsConfig}
      wizardType={WizardType.Process}
      pageTitle={t('builderLinksAnalysis:wizardSubtitle')}
      entityName={entityName?.name}
      isSyncing={isLoading || isLoadingStreamLinks}
    >
      {!areVisibleResultsPresent && !isLoading && (
        <div className={classNames.noSuggestionsWrapper}>
          <p>{t('builderLinksAnalysis:noSuggestions1')}</p>
          <p>{t('builderLinksAnalysis:noSuggestions2')}</p>
        </div>
      )}

      {filteredResults.map((result) => {
        const areResultsClosed = closedDataSourceResults.some(
          (id) => id === result.dataSourceId
        );
        const isLastResultOpen =
          !areAllResultsClosed &&
          results.length - closedDataSourceResults.length === 1;

        const { dataSourceId } = result;
        const isSingleSolutionIsland = result.selectedIslands.length === 1;

        return isSingleSolutionIsland ? (
          <SingleIslandResult
            {...{
              areResultsClosed,
              dataSourceId,
              isLastResultOpen,
              normalizedStreamLinksByLinkId,
              setClosedResults,
            }}
            singleSolutionIsland={result.selectedIslands[0]}
          />
        ) : (
          <MultipleIslandResults
            {...{
              areResultsClosed,
              dataSourceId,
              isLastResultOpen,
              normalizedStreamLinksByLinkId,
              setClosedResults,
            }}
            islands={result.selectedIslands}
          />
        );
      })}
    </WizardPage>
  );
};
