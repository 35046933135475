import {
  IButtonStyles,
  IProgressIndicatorStyles,
  IStackStyles,
  ITextStyles,
  mergeStyleSets,
} from '@fluentui/react';
import { baseColors } from '../../../../theme';

export const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    flex: 1,
    marginRight: 10,
  },
  itemProgress: {
    height: 8,
    borderRadius: 4,
    backgroundColor: 'transparent',
  },
  progressBar: {
    height: 8,
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  progressTrack: {
    height: 8,
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    border: '1px solid #C6C6C6',
    borderRadius: 4,
  },
};

export const percentTextStyles: ITextStyles = {
  root: {
    width: 40,
    textAlign: 'right',
    marginRight: 4,
  },
};

export const fileElementStyles = (isSelected: boolean): IStackStyles => ({
  root: {
    margin: '0 -8px',
    padding: '0 8px',
    cursor: 'pointer',
    background: isSelected ? baseColors.selected : 'transparent',
    selectors: {
      '&>button': {
        visibility: isSelected ? 'visible' : 'hidden',
      },
      '&:hover>button': {
        visibility: 'visible',
      },
      '&:hover': {
        background: isSelected
          ? baseColors.selectedWithHover
          : baseColors.hover,
      },
      '&:focus': {
        background: baseColors.selected,
      },
      '&:focus:hover': {
        background: baseColors.selectedWithHover,
      },
    },
  },
});

export const nameStyles: ITextStyles = {
  root: {
    flex: 1,
    paddingLeft: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const btnStyles = (isSelected: boolean): IButtonStyles => ({
  root: {
    width: 24,
    height: 24,
    margin: 2,
    borderRadius: 4,
    backgroundColor: isSelected ? baseColors.white : 'auto',
    selectors: {
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  icon: {
    color: baseColors.iconColor,
  },
  rootHovered: {
    backgroundColor: baseColors.white,
  },
});

export const percentStackStyles: IStackStyles = {
  root: {
    width: '40%',
  },
};

export const classNames = mergeStyleSets({
  updateText: {
    fontSize: '12px',
    fontWeight: 300,
  },
});
