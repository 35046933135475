import moment from 'moment';

const DAYS_IN_70_YEARS = 25569.0;
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const ISO_DATE_REGEX = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/;

// return date in 1900 format
// https://support.microsoft.com/en-us/office/date-systems-in-excel-e7fe7167-48a9-4b96-bb53-5612a800b487
export const formatDateToExcelDate = (date: string) => {
  if (!ISO_DATE_REGEX.test(date)) return date;
  const time = moment.utc(date, DATE_FORMAT).unix();
  const daysFrom1970 = time / 3600 / 24;
  const date1900 = daysFrom1970 + DAYS_IN_70_YEARS;

  return Number(date1900.toFixed(5));
};

export const formatExcelDateToDate = (date: string) : string => {
  const daysFrom1970 = parseFloat(date) - DAYS_IN_70_YEARS;
  const time = daysFrom1970 * 86400;
  return moment.unix(time).utc().format(DATE_FORMAT);
};
