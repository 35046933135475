import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { mdiUndo } from '@mdi/js';
import { HeaderBarButton } from '../../../../../components/ui';
import { triggerSmartFlow } from '../../actions';
import { getEntityFields, getSelectedSource } from '../../selectors';
import { SmartFlowOperations } from './constants';

export const SmartFlowButton = () => {
  // DEPS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedSource = useSelector(getSelectedSource);
  const { isLoading } = useSelector(getEntityFields);

  const shouldDisplayReset = selectedSource?.hasAppliedSmartFlows;

  const evaluateSmartFlows = useCallback(async () => {
    dispatch(
      triggerSmartFlow({
        dataSourceId: selectedSource?.id,
        operation: SmartFlowOperations.Evaluate,
      })
    );
  }, [dispatch, selectedSource]);

  const resetSmartFlows = useCallback(async () => {
    dispatch(
      triggerSmartFlow({
        dataSourceId: selectedSource?.id,
        operation: SmartFlowOperations.Reset,
      })
    );
  }, [dispatch, selectedSource]);

  return (
    <>
      <HeaderBarButton
        iconProps={{ iconName: 'EykoSmartFlow' }}
        tooltip={t('modeBar:catalog:evaluateSmartFlows')}
        disabled={!selectedSource?.id || isLoading}
        shouldHideOnDisabled
        onClick={evaluateSmartFlows}
      />
      {shouldDisplayReset && (
        <HeaderBarButton
          tooltip={t('modeBar:catalog:resetSmartFlows')}
          disabled={!selectedSource?.id || isLoading}
          shouldHideOnDisabled
          onClick={resetSmartFlows}
          mdiIcon={mdiUndo}
        />
      )}
    </>
  );
};
