import React from 'react';
import { Trans } from 'react-i18next';

type Params = {
  handleCancel: () => void;
  handleAccept: () => void;
  hasPickedAnyUsers: boolean
};

export const getHeaderBarButtonConfig = ({
  handleCancel,
  handleAccept,
  hasPickedAnyUsers,
}:Params) => {
  const baseButtons = [
    {
      tooltip: <Trans i18nKey="actionBar:general.cancelAction" />,
      onClick: handleCancel,
      iconProps: { iconName: 'Cancel' },
      'data-testid': 'cancel-share-button',
    },
  ];

  const buttonsWithApprove = [
    {
      tooltip: <Trans i18nKey="actionBar:general.accept" />,
      onClick: handleAccept,
      iconProps: { iconName: 'CheckMark' },
      'data-testid': 'approve-share-button',
    },
    ...baseButtons,
  ];

  return hasPickedAnyUsers ? buttonsWithApprove : baseButtons;
};
