import * as effects from 'redux-saga/effects';

import * as builderActions from '../actions';
import { appendNotificationIntent } from '../../Notifications/actions';
import {
  NotificationActivity,
  NotificationSubjectState,
} from '../../../types/INotification';
import * as builderApi from '../api';

function* handleProcessStream({
  payload: { historyGoBack, streamId },
}: ReturnType<typeof builderActions.processStreamIntent>) {
  try {
    yield effects.put(builderActions.setProcessingStream(true));

    yield effects.call(builderApi.processStream, {
      streamId,
      ingestBeforeProcessing: false,
    });

    while (true) {
      const {
        payload: notification,
      }: ReturnType<typeof appendNotificationIntent> = yield effects.take(
        appendNotificationIntent
      );

      if (
        notification.subjectId === streamId &&
        notification.activity === NotificationActivity.Process &&
        [
          NotificationSubjectState.Start,
          NotificationSubjectState.InProgress,
          NotificationSubjectState.Failed,
        ].includes(notification.state)
      ) {
        break;
      }
    }
  } catch (error) {
    console.log('err', error);
  } finally {
    yield effects.call(historyGoBack);
    yield effects.put(builderActions.setProcessingStream(false));
  }
}

export function* rootSaga() {
  yield effects.takeLatest(
    builderActions.processStreamIntent,
    handleProcessStream
  );
}

export default rootSaga;
