import { TutorialTile } from "../../../../../shared/tutorial-tiles/model";

import TutorialAnimation from './TutorialAnimation';

export const TUTORIAL_TILE_KEY = 'designer-main';

export const tutorialTileConfig: TutorialTile = {
  titleI18nKey: 'tutorialTiles:designer:main:title',
  image: TutorialAnimation,
  items: [
    {
      descriptionI18nKey: 'tutorialTiles:designer:main:items:createDesign:description',
      icon: 'Add',
      titleI18nKey: 'tutorialTiles:designer:main:items:createDesign:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:main:items:ingestYourData:description',
      icon: 'CloudUpload',
      titleI18nKey: 'tutorialTiles:designer:main:items:ingestYourData:title',
    },
    {
      descriptionI18nKey: 'tutorialTiles:designer:main:items:catalogYourAssets:description',
      icon: 'Album',
      titleI18nKey: 'tutorialTiles:designer:main:items:catalogYourAssets:title',
    },
  ],
  showMeUrl: 'https://eyko.zendesk.com/hc/en-us/articles/1500010191182-Designs-Welcome-to-Designer',
};
