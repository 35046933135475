import { TYPE_IDS } from '../../constants/apiV4TypeIds';
import { IUser } from '../../types/IUser';
import {
  createNestedEntity, deleteEntity, fetchEntities, fetchNestedEntities,
} from '../Designer/api';
import { ApiV4ResponseWrapper } from '../Designer/types';
import { StreamShare, DesignShare } from './types';

export const fetchUsers = () => fetchEntities<ApiV4ResponseWrapper<IUser[]>>(TYPE_IDS.User)();

export const fetchStreamShare = fetchNestedEntities<
ApiV4ResponseWrapper<StreamShare[]>
>({
  typeId: TYPE_IDS.StreamShare,
});

export const createStreamShare = (streamId: string) => createNestedEntity<StreamShare>({
  parentEntityId: streamId,
  typeId: TYPE_IDS.StreamShare,
});

export const removeStreamShare = (streamShareId: string) => deleteEntity(TYPE_IDS.StreamShare)(streamShareId);

export const fetchDesignShare = fetchNestedEntities<
ApiV4ResponseWrapper<DesignShare[]>
>({
  typeId: TYPE_IDS.DesignShare,
});

export const createDesignShare = (designId: string) => createNestedEntity<DesignShare>({
  parentEntityId: designId,
  typeId: TYPE_IDS.DesignShare,
});

export const removeDesignShare = (designShareId: string) => deleteEntity(TYPE_IDS.DesignShare)(designShareId);
