import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import streamSelectors from '../../selectors';
import GroupCollapse from '../../../../components/ui/GroupCollapse';
import useCollapseGroups from '../../../../hooks/useCollapseGroups';
import { addStreamFilter, editStreamFilter, removeStreamFilter } from '../../../StreamFilters/actions';
import { getStreamFiltersFromValues } from '../../../StreamFilters/selectors';
import { ComparisonTypes } from '../../../../types/IStreamFilter';
import ColumnValueList from '../../components/ColumnValueList/ColumnValueList';
import { selectors } from '../../Streams';
import {
  containerStyles, scrollableAreaStyles,
} from './styles';
import { areColumnsEqual } from '../../utils';
import { getSearchQuery } from '../../../Search/selectors';
import { setSearchQuery } from '../../../Search/actions';
import { filterColumns } from './utils';
import sortAscendingComparator from '../../../../utils/sortAscendingComparator';
import { IColumn } from '../../../../types/IColumn';
import { getSelectionType } from '../../../App/selectors';
import { SelectionType } from '../../../../components/ui/ViewControlButton';

const ValuesList = () => {
  const { isGroupOpen, handleOpenGroup, isAnyGroupOpen } = useCollapseGroups();
  const dispatch = useDispatch();
  const searchQuery = useSelector(getSearchQuery);
  const filtersFromValues = useSelector(getStreamFiltersFromValues);
  const availableColumns = useSelector(selectors.getAvailableColumns);
  const selectionType = useSelector(getSelectionType);

  const currentDataset = useSelector(streamSelectors.getCurrentDataset);

  // Relative complement (A \ B)
  const unselectedColumns = availableColumns
    ?.filter(
      (col) => !currentDataset?.columns.some((datasetCol) => col.id === datasetCol.id),
    )
    .sort(sortAscendingComparator<IColumn>('name'))
    ?? [];

  const selectedColumnsWithNoAggregation = currentDataset?.columns
      .filter((c) => c.aggregation === null)
      .sort(sortAscendingComparator<IColumn>('name')) || [];

  const columns = useMemo(
    () => {
      switch (selectionType) {
        case SelectionType.selected:
          return selectedColumnsWithNoAggregation;
        case SelectionType.unselected:
          return unselectedColumns;
        default:
        case SelectionType.all:
          return [
            ...selectedColumnsWithNoAggregation,
            ...unselectedColumns,
          ];
      }
    },
    [
      selectionType,
      selectedColumnsWithNoAggregation,
      unselectedColumns,
    ],
  );

  const isGroupSelected = useMemo(
    () => columns.some((c) => isGroupOpen(c.id)),
    [columns],
  );

  const filteredColumns = useMemo(
    () => (isGroupSelected ? columns : filterColumns(columns, searchQuery)),
    [isGroupSelected, searchQuery, columns],
  );

  const onClick = (columnId: string) => {
    dispatch(setSearchQuery(''));

    handleOpenGroup(columnId);
  };

  const handleSelectValue = (column, columnValue) => {
    const columnValueString = String(columnValue);
    const filterForColumn = filtersFromValues.find(
      (f) => (areColumnsEqual(f.column, column)) && f.generatedFromValue,
    );

    if (!filterForColumn) {
      dispatch(addStreamFilter({
        column,
        comparison: ComparisonTypes.In,
        values: [columnValueString],
        generatedFromValue: true,
        id: uuidv4(),
        aggregation: null,
      }));
    } else {
      const valueInList = Boolean(filterForColumn.values?.some(
        (filterValue) => filterValue === columnValueString,
      ));

      if (!valueInList) {
        dispatch(editStreamFilter({
          ...filterForColumn,
          values: [...filterForColumn.values ?? [], columnValueString],
        }));
      } else {
        const newValues = filterForColumn.values?.filter(
          (filterValue) => filterValue !== columnValueString,
        ) ?? [];

        if (newValues.length) {
          dispatch(editStreamFilter({
            ...filterForColumn,
            values: newValues,
          }));
        } else {
          if (filterForColumn.id) dispatch(removeStreamFilter(filterForColumn.id));
        }
      }
    }
  };

  return (
    <div style={containerStyles}>
      <div style={scrollableAreaStyles} id="values-scroll">
        { filteredColumns.map((column) => {
          const filterForColumn = filtersFromValues.find(
            (f) => (f.column?.id === column.id) && f.generatedFromValue,
          );

          if (isAnyGroupOpen && !isGroupOpen(column.id)) {
            return null;
          }

          return (
            <GroupCollapse
              key={`group_${column.id}`}
              groupName={column.name}
              groupId={column.id}
              isOpen={isGroupOpen(column.id)}
              onClick={() => onClick(column.id)}
              isSelected={!!filterForColumn}
              isSticky={isGroupOpen(column.id)}
            >
              <ColumnValueList
                column={column}
                onSelect={handleSelectValue}
                filterForColumn={filterForColumn}
              />
            </GroupCollapse>
          );
        })}
      </div>
    </div>
  );
};

export default ValuesList;
