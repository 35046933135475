import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { removeItemById } from '../../../GroupTable/actions';
import { ObjectTypes } from '../../../App/types';
import streamSelectors from '../../../Streams/selectors';
import { getSelectedItemIds } from '../../selectors';
import { deleteColumnsOfDataSet, setSelectedItemsIds } from '../../actions';
import { HeaderBarButton } from '../../../../components/ui';

const DeleteColumnsHeader : React.FC = () => {
  const { t } = useTranslation('headers');
  const dispatch = useDispatch();
  const selectedItems = useSelector(getSelectedItemIds);
  const columnUuids = useSelector(streamSelectors.getCurrentDatasetColumnUuids);
  const columnCount = useSelector(streamSelectors.getCurrentDatasetColumnCount);
  const currentDatasetType = useSelector(streamSelectors.getCurrentDatasetType);

  const validSelectedIds = selectedItems.filter((id) => columnUuids?.includes(id));

  const isSelectionEmpty = validSelectedIds.length === 0;
  const isEverythingSelected = validSelectedIds.length === columnCount;

  const isDisabled = isSelectionEmpty || isEverythingSelected;

  const isGroupTable = currentDatasetType === ObjectTypes.GROUP_TABLE;

  const handleClick = () => {
    if (isGroupTable) {
      dispatch(removeItemById(selectedItems[0]));
      dispatch(deleteColumnsOfDataSet([selectedItems[0]]));
    } else {
      dispatch(deleteColumnsOfDataSet(validSelectedIds));
    }

    dispatch(setSelectedItemsIds([]));
  };

  return (
    <HeaderBarButton
      tooltip={(
        <Trans
          t={t}
          i18nKey="actionBar:layout.delete"
        />
      )}
      onClick={handleClick}
      iconProps={{ iconName: 'Delete' }}
      data-testid="delete-column-top-menu"
      disabled={isDisabled}
      shouldHideOnDisabled
    />
  );
};

export default DeleteColumnsHeader;
