import { IDropdownOption } from '@fluentui/react';
import { TFunction } from 'i18next';
import { StreamFileFormat } from '../../../../api/model/schemas/StreamFileFormat';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';

export const getDropdownOptions = (t: TFunction): IDropdownOption[] =>
  [
    {
      text: t(`api:types.${TYPE_IDS.SendStreamDataJob}.Csv`),
      key: StreamFileFormat.Csv,
    },
    {
      text: t(`api:types.${TYPE_IDS.SendStreamDataJob}.Tsv`),
      key: StreamFileFormat.Tsv,
    },
    {
      text: t(`api:types.${TYPE_IDS.SendStreamDataJob}.Json`),
      key: StreamFileFormat.Json,
    },
    {
      text: t(`api:types.${TYPE_IDS.SendStreamDataJob}.Xml`),
      key: StreamFileFormat.Xml,
    },
    {
      text: t(`api:types.${TYPE_IDS.SendStreamDataJob}.Xlsx`),
      key: StreamFileFormat.Xlsx,
    },
  ].sort((a, b) => a.text.localeCompare(b.text));
