import React from 'react';
import { IShare } from '../../types';

type Params = {
  pickedUserIds: string[],
  shareEntities: IShare[],
  createShareObject: (userId: any, access: any) => Promise<void>
  unShareObject: (id: string) => Promise<any>
};

export const useSharedPolicyUpdate = ({
  pickedUserIds,
  shareEntities,
  createShareObject,
  unShareObject,
}: Params) => {
  const [isUpdating, setUpdating] = React.useState(false);

  const userIdsWithAccess = shareEntities.map((entity) => entity.userId);

  const handleUpdate = async () => {
    setUpdating(true);
    const sharePayloads = pickedUserIds
      .filter((userId) => !userIdsWithAccess.includes(userId))
      .map((userId) => ({
        userId,
        access: 'ReadWrite',
      }));

    const unSharePayloads = pickedUserIds
      .filter((userId) => userIdsWithAccess.includes(userId))
      .map((userId) => shareEntities.find((shareEntity) => shareEntity.userId === userId))
      .map((shareEntity) => shareEntity.id);

    try {
      await Promise.all(sharePayloads.map(
        ({ access, userId }) => createShareObject(userId, access),
      ));

      await Promise.all(unSharePayloads.map(
        (shareEntityId) => unShareObject(shareEntityId),
      ));
    } finally {
      setUpdating(false);
    }
  };

  return { isUpdating, handleUpdate };
};
