import { StreamCalculation } from '../../../api/model/schemas/StreamCalculation';
import {
  IColumn,
  IColumnAggregationMethods,
  IColumnDataType,
  IColumnSortTypes,
} from '../../../types/IColumn';
import { EntityProcessStatus, IStreamField } from '../../Designer/types';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { StreamHub } from '../../../api/model/schemas/StreamHub';
import { StreamGroupAsset } from '../../../api/model/schemas/StreamGroupAsset';
import {
  i18nDefaultAggregationKey,
  i18nDataTypeKey,
  i18nFormatTypeKey,
} from '../../../api/interceptors/i18n/constants';
import { StreamReferenceProcessStatus } from '../../../api/model/schemas/StreamReferenceProcessStatus';
import { AggregationType } from '../../../api/model/schemas/AggregationType';
import { StreamMachineLearning } from '../../../api/model/schemas/StreamMachineLearning';
import { numberToHexColor } from '../../../components/ui/ColorPicker/utils';
import { baseColors } from '../../../theme';
import { StreamLookup } from '../../../api/model/schemas/StreamLookup';

const parseAggregation = (
  input: AggregationType
): IColumnAggregationMethods => {
  if (input === AggregationType.None || !input) return null;

  return input as IColumnAggregationMethods;
};

export const parseFieldsToStreamerColumns = (
  fields: IStreamField[]
): IColumn[] =>
  fields
    .filter((field) => field.processStatus === EntityProcessStatus.Processed)
    .map((field) => ({
      aggregation: parseAggregation(field.aggregationType),
      dataEntityId: field.id,
      // temporary hack to get some "entities" in the streaner/data view
      // we are waiting for https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/5927
      parentEntityName: field?.processedEntityName || 'Unknown',
      dataSetId: field.id,
      dataSourceId: field.id,
      // the only difference between v3 & v4 is lack of "Date" and "Time"
      dataType: field.processedDataType as IColumnDataType,
      [i18nDataTypeKey]: field[i18nDataTypeKey],
      defaultAggregation: field.processedIsAggregated
        ? null
        : parseAggregation(field.defaultAggregation),
      [i18nDefaultAggregationKey]: field[i18nDefaultAggregationKey],
      description: field.description,
      filters: [],
      // TO DO - handle later
      formatType: field.processedFormatType as any,
      [i18nFormatTypeKey]: field[i18nFormatTypeKey],
      id: field.id,
      name: field.processedName,
      tags: field.tags,
      streamElementTypeId: TYPE_IDS.StreamField,
      isAggregatingValuesAllowed: !field.processedIsAggregated,
      dataSourceColor: numberToHexColor(
        field?.sourceField?.parent?.parent?.color ?? 0
      ),
      dataSourceName: field?.sourceField?.parent?.parent?.name,
      // Until https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6406
      // is resolved we have to set all sort explicitly as ascending to make
      // this feature work as expected
      sortConfig: {
        sortMode: IColumnSortTypes.Ascending,
      },
      availability: field.availability,
    }));

export const parseCalculationsToStreamerColumns = (
  calculations: StreamCalculation[]
): IColumn[] =>
  calculations
    .filter(
      (calculation) =>
        calculation.processStatus === StreamReferenceProcessStatus.Processed
    )
    .map<IColumn>((calculation) => ({
      aggregation: null,
      dataEntityId: calculation.id,
      parentEntityName: calculation.processedEntityName,
      dataSetId: calculation.id,
      dataSourceId: calculation.id,
      // the only difference between v3 & v4 is lack of "Date" and "Time"
      // TODO: maybe somehow add a typeguard for this assertion? or a generic that returns `never` if it fails?
      dataType: calculation.processedDataType as string as IColumnDataType,
      [i18nDataTypeKey]: calculation[i18nDataTypeKey],
      defaultAggregation: null,
      description: '',
      filters: [],
      // TO DO - handle later
      formatType: calculation.processedFormatType as any,
      [i18nFormatTypeKey]: calculation[i18nFormatTypeKey],
      id: calculation.id,
      name: calculation.processedName,
      tags: calculation.tags,
      streamElementTypeId: TYPE_IDS.StreamCalculation,
      isAggregatingValuesAllowed: !calculation.isAggregated,
      dataSourceColor: baseColors.midOrange,
      // Until https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6406
      // is resolved we have to set all sort explicitly as ascending to make
      // this feature work as expected
      sortConfig: {
        sortMode: IColumnSortTypes.Ascending,
      },
      availability: calculation.availability,
      isAggregated: calculation.isAggregated,
    }));

export const parseHubsToStreamerColumns = (hubs: StreamHub[]): IColumn[] =>
  hubs
    .filter(
      (hub) => hub.processStatus === StreamReferenceProcessStatus.Processed
    )
    .map((hub) => ({
      aggregation: null,
      dataEntityId: hub.id,
      parentEntityName: hub.processedEntityName,
      dataSetId: hub.id,
      dataSourceId: hub.id,
      // the only difference between v3 & v4 is lack of "Date" and "Time"
      dataType: hub.processedDataType as string as IColumnDataType,
      [i18nDataTypeKey]: hub[i18nDataTypeKey],
      defaultAggregation: null,
      description: '',
      filters: [],
      // TO DO - handle later
      formatType: hub.processedFormatType as any,
      [i18nFormatTypeKey]: hub[i18nFormatTypeKey],
      id: hub.id,
      name: hub.processedName,
      tags: hub.tags,
      streamElementTypeId: TYPE_IDS.StreamHub,
      isAggregatingValuesAllowed: false,
      dataSourceColor: baseColors.midOrange,
      // Until https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6406
      // is resolved we have to set all sort explicitly as ascending to make
      // this feature work as expected
      sortConfig: {
        sortMode: IColumnSortTypes.Ascending,
      },
      availability: hub.availability,
    }));

export const parseGroupAssetsToStreamerColumn = (
  groupAssets: StreamGroupAsset[]
): IColumn[] =>
  groupAssets
    .filter(
      (group) => group.processStatus === StreamReferenceProcessStatus.Processed
    )
    .map((groupAsset) => ({
      aggregation: null,
      dataEntityId: groupAsset.id,
      parentEntityName: groupAsset.processedEntityName,
      dataSetId: groupAsset.id,
      dataSourceId: groupAsset.id,
      // the only difference between v3 & v4 is lack of "Date" and "Time"
      dataType: groupAsset.processedDataType as string as IColumnDataType,
      [i18nDataTypeKey]: groupAsset[i18nDataTypeKey],
      defaultAggregation: null,
      description: '',
      filters: [],
      // TO DO - handle later
      formatType: groupAsset.processedFormatType as any,
      [i18nFormatTypeKey]: groupAsset[i18nFormatTypeKey],
      id: groupAsset.id,
      name: groupAsset.processedName,
      tags: groupAsset.tags,
      streamElementTypeId: TYPE_IDS.StreamGroupAsset,
      dataSourceColor: baseColors.midOrange,
      isAggregatingValuesAllowed: !groupAsset.isAggregated,
      // Until https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6406
      // is resolved we have to set all sort explicitly as ascending to make
      // this feature work as expected
      sortConfig: {
        sortMode: IColumnSortTypes.Ascending,
      },
      availability: groupAsset.availability,
      groupAssetId: groupAsset.groupId,
    }));
export const parseMLAssetTOStreamerColumn = (
  mlAssets: StreamMachineLearning[]
): IColumn[] =>
  mlAssets
    .filter(
      (mlAsset) =>
        mlAsset.processStatus === StreamReferenceProcessStatus.Processed
    )
    .map((mlAsset) => ({
      aggregation: null,
      dataEntityId: mlAsset.id,
      parentEntityName: mlAsset.processedEntityName,
      dataSetId: mlAsset.id,
      dataSourceId: mlAsset.id,
      // the only difference between v3 & v4 is lack of "Date" and "Time"
      dataType: mlAsset.processedDataType as string as IColumnDataType,
      [i18nDataTypeKey]: mlAsset[i18nDataTypeKey],
      defaultAggregation: null,
      description: '',
      filters: [],
      // TO DO - handle later
      formatType: mlAsset.processedFormatType as any,
      [i18nFormatTypeKey]: mlAsset[i18nFormatTypeKey],
      id: mlAsset.id,
      name: mlAsset.processedName,
      tags: mlAsset.tags,
      streamElementTypeId: TYPE_IDS.StreamGroupAsset,
      dataSourceColor: baseColors.midOrange,
      isAggregatingValuesAllowed: true,
      // Until https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6406
      // is resolved we have to set all sort explicitly as ascending to make
      // this feature work as expected
      sortConfig: {
        sortMode: IColumnSortTypes.Ascending,
      },
      availability: mlAsset.availability,
    }));

export const parseLookupToStreamerColumn = (
  lookups: StreamLookup[]
): IColumn[] =>
  lookups
    .filter(
      (lookup) =>
        lookup.processStatus === StreamReferenceProcessStatus.Processed
    )
    .map((lookup) => ({
      aggregation: null,
      dataEntityId: lookup.id,
      parentEntityName: lookup.processedEntityName,
      dataSetId: lookup.id,
      dataSourceId: lookup.id,
      // the only difference between v3 & v4 is lack of "Date" and "Time"
      dataType: lookup.processedDataType as string as IColumnDataType,
      [i18nDataTypeKey]: lookup[i18nDataTypeKey],
      defaultAggregation: null,
      description: '',
      filters: [],
      // TO DO - handle later
      formatType: lookup.processedFormatType as any,
      [i18nFormatTypeKey]: lookup[i18nFormatTypeKey],
      id: lookup.id,
      name: lookup.processedName,
      tags: lookup.tags,
      streamElementTypeId: TYPE_IDS.StreamHub,
      isAggregatingValuesAllowed: false,
      dataSourceColor: baseColors.midOrange,
      // Until https://dev.azure.com/synergiesio/OnSynergies/_workitems/edit/6406
      // is resolved we have to set all sort explicitly as ascending to make
      // this feature work as expected
      sortConfig: {
        sortMode: IColumnSortTypes.Ascending,
      },
      availability: lookup.availability,
    }));
