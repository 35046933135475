import { IIconProps } from '@fluentui/react';
import React from 'react';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HeaderBarButton from '../../../../../components/ui/HeaderBarButton';
import { routes } from '../../../../../constants/routes';
import { Strategy } from '../../../components/StreamToDatabase/useStreamToDatabase';
import { getHasAnythingSelected } from '../../../selectors';
// import { getHasAnythingSelected, getSendToFileState } from '../../../selectors';
// import streamSelectors from '../../../../Streams/selectors';

const IMPORT_ICON_PROPS: IIconProps = {
  iconName: 'Import',
};

// const enabledSelectors = {
//   [Strategy.ISOLATED]: getHasAnythingSelected,
//   [Strategy.OLD_STREAMER]: streamSelectors.getAreAnyColumnsInCurrentDataset,
// };

interface Props {
  strategy: Strategy;
  designId: string;
}

const ImportButton: React.FC<Props> = ({ strategy, designId }) => {
  const history = useHistory();
  const hasAnythingSelected = useSelector(getHasAnythingSelected);

  const onClick = React.useCallback(() => {
    history.push(routes.streamer.import.index, {
      strategy,
      designId,
    });
  }, [history]);

  return (
    <HeaderBarButton
      iconProps={IMPORT_ICON_PROPS}
      onClick={onClick}
      shouldHideOnDisabled
      disabled={hasAnythingSelected}
    />
  );
};

export default ImportButton;
