import { mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../../../../theme';

const bubbleSize = '20px';

export const classNames = mergeStyleSets({
  selectionCount: {
    right: '10px',
    top: '-8px',
    position: 'absolute',
    borderRadius: '50%',
    height: bubbleSize,
    width: bubbleSize,
    lineHeight: bubbleSize,
    textAlign: 'center',
    background: baseColors.orange,
    color: baseColors.white,
  },
  text: {
    marginLeft: '32px',
    fontSize: '12px',
  },
});
