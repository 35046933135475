import { IIconProps } from 'office-ui-fabric-react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MachineLearningProcessStatus } from '../../../../../api/model/schemas/MachineLearningProcessStatus';
import { ProcessMachineLearningType } from '../../../../../api/model/schemas/ProcessMachineLearningType';
import { HeaderBarButton } from '../../../../../components/ui';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { routes } from '../../../../../constants/routes';
import useItemSelections from '../../../../../hooks/useItemSelections';
import ItemActionsHeader from '../../../../../pageTemplates/ItemActionsHeader';
import {
  addNestedEntityRoute,
  deleteEntityRoute,
  editEntityRoute,
  machineLearningPreviewRoute,
} from '../../../../../utils/routes';
import { getSelectedItemsIds } from '../../../../App/selectors';
import { getSelectedDesignId } from '../../../ContentLibrary/selectors';
import { configureMachineLearningIntent } from '../../actions';
import { getMLAssetById } from '../../selectors';

const PREVIEW_ICON_PROPS: IIconProps = {
  iconName: 'ReviewRequestSolid',
};

export const noop = () => null;

export const MLHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedItems = useSelector(getSelectedItemsIds);
  const history = useHistory();
  const { selectedItem: selectedMLAssetId, clear } = useItemSelections();
  const selectedMLAsset = useSelector(getMLAssetById(selectedMLAssetId));

  const selectedDesignID = useSelector(getSelectedDesignId);

  const isSelectedMLAssetProcessed =
    selectedMLAsset?.processStatus === MachineLearningProcessStatus.Processed;

  const successCallback = (newEntityId: string) => {
    dispatch(
      configureMachineLearningIntent({
        entityId: newEntityId,
        onQueued: () => history.go(-1),
        processType: ProcessMachineLearningType.ProcessAll,
      })
    );
  };

  const handleAdd = React.useCallback(() => {
    const path = addNestedEntityRoute({
      schemaId: TYPE_IDS.MachineLearning,
      parentSchemaId: TYPE_IDS.Design,
      parentEntityId: selectedDesignID,
    });

    history.push(path, {
      typeName: 'Machine Learning',
      successCallback,
    });
  }, [history, selectedDesignID]);

  const handleEdit = React.useCallback(() => {
    const path = editEntityRoute(TYPE_IDS.MachineLearning, selectedMLAssetId);
    history.push(path, {
      initialValues: {},
      successCallback: () => successCallback(selectedMLAssetId),
      typeName: 'Machine Learning',
    });
  }, [history, selectedMLAssetId]);

  const handleDelete = React.useCallback(() => {
    const path = deleteEntityRoute(TYPE_IDS.MachineLearning, selectedMLAssetId);

    const goBackPage = routes.designer.catalog.machineLearning;

    history.push(path, {
      goBackPage,
      entityName: selectedMLAsset?.name,
      successCallback: clear,
      deleteDescription: (
        <Trans
          i18nKey="wizard:deleteNestedEntity"
          values={{
            name: selectedMLAsset?.name,
            asset: t('entityTypes:MachineLearning'),
          }}
        />
      ),
    });
  }, [history, selectedMLAssetId]);

  const handlePreview = () => {
    const path = machineLearningPreviewRoute(selectedMLAssetId);
    history.push(path);
  };

  const handleRefresh = React.useCallback(() => {
    dispatch(
      configureMachineLearningIntent({
        entityId: selectedMLAssetId,
        onQueued: noop,
        processType: ProcessMachineLearningType.ProcessNew,
      })
    );
  }, [selectedMLAssetId]);

  return (
    <>
      <ItemActionsHeader
        isItemSelected={!!selectedItems?.[0]}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
      />
      <HeaderBarButton
        tooltip={
          <strong>
            <Trans t={t} i18nKey="actionBar:general:preview" />
          </strong>
        }
        iconProps={PREVIEW_ICON_PROPS}
        onClick={handlePreview}
        disabled={!isSelectedMLAssetProcessed}
        shouldHideOnDisabled
        data-testid="export-wizard-btn"
      />
      <HeaderBarButton
        tooltip={<Trans t={t} i18nKey="actionBar:general:refresh" />}
        onClick={handleRefresh}
        iconProps={{ iconName: 'Refresh' }}
        disabled={!isSelectedMLAssetProcessed}
        shouldHideOnDisabled
      />
    </>
  );
};
