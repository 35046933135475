import { createAction } from '@reduxjs/toolkit';
import { IStreamFilter } from '../../types/IStreamFilter';

export const setStreamFilters = createAction<IStreamFilter[]>('SET_STREAM_FILTERS');

export const setSelectedStreamFilters = createAction<IStreamFilter[]>('SET_SELECTED_STREAM_FILTERS');

export const addStreamFilter = createAction<IStreamFilter>('ADD_STREAM_FILTERS');

export const removeStreamFilter = createAction<string, 'REMOVE_STREAM_FILTERS'>('REMOVE_STREAM_FILTERS');

export const selectStreamFilter = createAction<IStreamFilter>('SELECT_STREAM_FILTERS');

export const removeSelectedFilters = createAction('REMOVE_SELECTED_STREAM_FILTERS');

export const editStreamFilter = createAction<IStreamFilter>('EDIT_STREAM_FILTER');
