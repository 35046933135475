import { ApplicationState } from '../../store/types';

const getAppState = (state: ApplicationState) => state.app;

export const getActiveObjectType = (state: ApplicationState) => getAppState(state).activeObjectType;

export const getIsOtherUserSyncing = (state: ApplicationState) => getAppState(state).isOtherUserSyncing;

export const getSelectedItemsIds = (state: ApplicationState) => getAppState(state).selectedItemIds;

export const getSortType = (state:ApplicationState) => getAppState(state).sortControlType;
export const getSortControlHistory = (state:ApplicationState) => getAppState(state).sortControlHistory;
export const getSelectionType = (state:ApplicationState) => getAppState(state).selectionType;

export const getIsEditingItem = (state:ApplicationState) => getAppState(state).isEditingItem;
