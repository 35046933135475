import { CancelToken } from 'axios';
import qs from 'query-string';
import {
  systemConnectorConfigurationPropertyEntriesPath,
  systemOAuthConnectorAccessPath,
  systemOauthConnectorPath,
  systemOAuthConnectorsPath,
  systemOAuthXeroTenantsPath,
} from '../../constants/apiPaths';
import { getClient } from '../getClient';

// OAUTH CONFIGURATION (DEPRECATED)
type ConfigurationTypeId = string;
type AuthorizationURL = string;
type OAuthConfiguration = Record<ConfigurationTypeId, AuthorizationURL>;
export const fetchOAuthConfiguration = async () => {
  const client = await getClient(); // ;(

  return client.get<OAuthConfiguration>(systemOAuthConnectorsPath);
};

// CREATE A CONNECTOR OAUTH URL
interface CreateOAuthConnectionUrlArgs {
  connectorId: string;
  selectedScopeIds: string[];
}
type CreateOAuthConnectionUrlResponse = string;
export const createOAuthConnectionUrl = async ({
  connectorId,
  selectedScopeIds,
}: CreateOAuthConnectionUrlArgs) => {
  const client = await getClient(); // ;(

  return client.post<CreateOAuthConnectionUrlResponse>(
    systemOauthConnectorPath(connectorId),
    selectedScopeIds
  );
};

// OAUTH ACCESS TOKEN
interface CreateOAuthAccessTokenArgs {
  authorizationCode: string;
  connectorId: string;
}
interface CreateOAuthAccessTokenResponse {
  refreshToken: string;
  accessToken: string;
}
export const createOAuthAccessToken = async ({
  authorizationCode,
  connectorId,
}: CreateOAuthAccessTokenArgs) => {
  const client = await getClient(); // ;(

  return client.post<CreateOAuthAccessTokenResponse>(
    qs.stringifyUrl({
      url: systemOAuthConnectorAccessPath(connectorId),
      query: {
        authorizationCode,
      },
    })
  );
};

// XERO - SPECIAL CASE
interface FetchXeroTenantsArgs {
  accessToken: string;
  cancelToken?: CancelToken;
  host: string;
}
export interface XeroTenant {
  tenantId: string;
  tenantName: string;
}
export const fetchXeroTenants = async ({
  accessToken,
  cancelToken,
  host,
}: FetchXeroTenantsArgs) => {
  const client = await getClient(); // ;(

  return client.get<XeroTenant[]>(
    qs.stringifyUrl({
      url: systemOAuthXeroTenantsPath,
      query: {
        accessToken,
        host,
      },
    }),
    { cancelToken }
  );
};

interface FetchConnectorConfigurationPropertyEntries {
  args: {
    cancelToken?: CancelToken;
    configuration: Record<string, unknown>;
    propertyName: string;
    typeId: string;
  };
}
export const fetchConnectorConfigurationPropertyEntries = async ({
  cancelToken,
  configuration,
  propertyName,
  typeId,
}: FetchConnectorConfigurationPropertyEntries['args']) => {
  const client = await getClient();

  return client.post(
    qs.stringifyUrl({
      url: systemConnectorConfigurationPropertyEntriesPath,
      query: {
        propertyName,
        typeId,
      },
    }),
    configuration,
    { cancelToken }
  );
};
