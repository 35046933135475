import React, { FunctionComponent } from 'react';

import DeleteColumnsHeader from '../DeleteColumnsHeader';
import MoveColumnsHeader from '../MoveColumnsHeader';

const ColumnsOrderHeader: FunctionComponent = () => (
  <>
    <DeleteColumnsHeader />
    <MoveColumnsHeader />
  </>
);

export default ColumnsOrderHeader;
