import { CommandBar, Stack } from '@fluentui/react';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import {
  activeItemClassName,
  buttonItemStyles,
  commandBarStyles,
  iconItemStyles,
} from '../../../../components/ContextualMenu/styles';
import { routes } from '../../../../constants/routes';
import OutputHeader from '../../strategies/isolated/screens/OutputPreview/components/OutputHeader';
import { Output } from '../../strategies/isolated/screens/OutputPreview/Output';
import { classNames, stackStyles } from './styles';

export const OutputPanel = () => {
  // HOOKS
  const { pathname } = useLocation();

  // DERIVED STATE
  const isInNewStreamer = !!matchPath(pathname, [routes.streamer.index]);

  // RENDER
  if (!isInNewStreamer) return null;

  return (
    <div className={classNames.container}>
      <Stack styles={stackStyles}>
        <Stack
          className={classNames.sectionTitleWrapper}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          {/* title is explicitly empty, because we have it on the left panel */}
          <OutputHeader title="" isPanelView />
        </Stack>
        <div className={classNames.commandBarStyles}>
          <CommandBar
            styles={commandBarStyles}
            items={[
              {
                key: 'EykoOutput',
                className: activeItemClassName,
                buttonStyles: buttonItemStyles,
                iconProps: {
                  iconName: 'EykoOutput',
                  styles: iconItemStyles,
                },
              },
            ]}
          />
        </div>
        <div
          className={classNames.contentWrapper}
          data-testid="container-box"
          id="container-box"
        >
          <Output />
        </div>
      </Stack>
    </div>
  );
};
