import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { DirectionalHint } from '@fluentui/react';
import Error from '../../Error';
import { AsyncSelectDropdown } from '../../../ui';
import AggregationButton from '../AggregationButton';
import { aggregationBtn, aggregationDroplistBtn } from '../../../../modules/Streams/components/StreamerColumn/styles';
import { getAggregations } from '../../../../constants/aggregations';
import { labelStyle } from '../../../ui/SelectDropdown/customization';

export interface Option {
  id: string;
  name: string;
  [key: string]: any;
}

export interface OptionGroup {
  label: string;
  options: Array<Option>
}

interface Props {
  isEditing?: boolean;
  loadOptions(input?: string): Promise<Array<OptionGroup>> | Promise<Array<Option>>;
  itemOptionsDefaultValues?: Array<OptionGroup> | Array<Option>;
  hideAggregationPicker?: boolean
}

const ItemSelect: React.FC<Props> = ({
  isEditing, loadOptions, itemOptionsDefaultValues, hideAggregationPicker,
}) => {
  const { t } = useTranslation('filters');
  const {
    control, errors, reset,
  } = useFormContext();

  const getOptionValue = (option) => option.id;
  const getOptionLabel = (option) => option.name;

  return (
    <>
      <p style={labelStyle}>
        {t('Field')}
      </p>
      <div style={{ display: 'flex' }}>
        {!hideAggregationPicker && (
        <Controller
          render={({ value, onChange }) => {
            const aggregations = getAggregations();
            const currentAggregation = value ? aggregations[value] : null;

            const icon = currentAggregation?.icon || 'SynergiesDoNotSummarize';

            return (
              <AggregationButton
                tooltipLabel={currentAggregation?.label || t('Select aggregation')}
                aggregationIconName={icon}
                onDroplistItemClick={(_, { data }) => onChange(data)}
                aggregationDroplistBtnStyles={aggregationDroplistBtn}
                aggregationBtnStyles={aggregationBtn(true, false, true)}
                isColumnAggregation
                currentAggregations={[value]}
                tooltipDirectionalHint={DirectionalHint.rightCenter}
                isDisabled={isEditing}
              />
            );
          }}
          name="aggregation"
          control={control}
        />
        )}
        <Controller
          render={({ value }) => (
            <AsyncSelectDropdown
              onChange={(v) => {
                reset({
                  comparison: null,
                  values: [],
                  compareValue: '',
                  lowerBoundValue: '',
                  upperBoundValue: '',
                  id: null,
                  item: v,
                });
              }}
              value={value}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              loadOptions={loadOptions}
              className="filter-item-select"
              isDisabled={isEditing}
              // https://react-select.com/async#defaultoptions
              defaultOptions={itemOptionsDefaultValues || true}
              cacheOptions
            />
          )}
          name="item"
          control={control}
          rules={{ required: true }}
        />
      </div>
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="columnId"
        message={t('required')}
      />
    </>
  );
};

export default ItemSelect;
