import React, { useMemo } from 'react';
import { Stack, Text, IStackStyles, mergeStyleSets } from '@fluentui/react';
import { baseColors } from '../../theme';
import { useGetSubtitleText } from '../../hooks/useSubtitleText';

const wrapStyle: IStackStyles = {
  root: {
    padding: '0 0px 10px 0px',
    position: 'relative',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: '42px',
  },
};
const fullWidthStackStyles: IStackStyles = {
  root: {
    width: '100%',
  },
};

const getStyles = () => ({
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
    color: baseColors.textColor,
    fontSize: '14px',
  },
  subtitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
    color: baseColors.midOrange,
    fontSize: '10px',
    fontWeight: 600,
  },
});

type IProps = {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  fullWidth?: boolean;
};

const SectionTitle: React.FC<IProps> = ({
  title,
  children,
  fullWidth = true,
}: IProps) => {
  const subtitle = useGetSubtitleText();

  const classNames = useMemo(() => {
    const styles = getStyles();
    return mergeStyleSets(styles);
  }, []);

  return (
    <Stack horizontal styles={wrapStyle}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={fullWidth ? fullWidthStackStyles : undefined}
      >
        <Stack>
          <Text className={classNames.title} as="h2" data-testid="page-title">
            {title}
          </Text>
          {title && (
            <Text
              className={classNames.subtitle}
              as="h4"
              data-testid="page-subtitle"
            >
              {subtitle}
            </Text>
          )}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default SectionTitle;
