/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import cn from 'classnames';
import { FontIcon, PrimaryButton, Spinner } from '@fluentui/react';
import { Icon } from '@mdi/react';
import { useTranslation } from 'react-i18next';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
import { classNames, showMoreStyles } from './styles';
import { StreamAssetRaw, StreamerFilter } from '../../../../../../types';
import FormattedValue from '../FormattedValue';
import {
  comparisonToIcon,
  formatValues,
  getRealComparisonType,
} from '../../../../../../../../utils/formatFilterValues';
import { streamerFilterToDesignerFilter } from '../../../../../../Filters/components/utils';
import { Entry } from '../../model';
import { KEYS_SHOWN_ON_MOUNT } from './constants';
import { CARD_KEY_PROP, getAreCardsForceOpen } from '../../../../selectors';
import { useSetSafeTimeout } from '../../../../../../../../../shared/hooks/useSetSafeTimeout';
import { ValueType } from '../FormattedValue/FormattedValue';
import FormattedEntryKey from '../FormattedEntryKey/FormattedEntryKey';

const getPreviewId = (cardKey: number) => `hidden-card-preview-${cardKey}`;

type CardKey = number;

interface SelectedCardPreview {
  // canvas: HTMLCanvasElement;
  parentTitle: string;
  title: string;
}

export interface BaseProps {
  assets: StreamAssetRaw[];
  entry: Entry;
  filters: StreamerFilter[];
  parentTitle: string;
  style?: any;
}

export interface NormalProps extends BaseProps {
  isCardSelected: boolean;
  isPreview?: false;
  selectionPreviewsRef: React.MutableRefObject<
    Record<CardKey, SelectedCardPreview>
  >;
  toggleCard(key: number): void;
  isLocalOpen: boolean;
  toggleIsLocalOpen: () => void;
}

export interface PreviewProps extends BaseProps {
  isPreview: true;
  onRef?: React.Dispatch<React.SetStateAction<HTMLDivElement>>;
}

export type ExtendedProps = Pick<
  NormalProps,
  'selectionPreviewsRef' | 'toggleCard'
>;

export type Props = NormalProps | PreviewProps;

const OutputCard = (props: Props) => {
  // DEPS
  const {
    assets,
    entry,
    filters,
    isPreview = false,
    parentTitle,
    style,
  } = props;

  // HOOKS
  const { t } = useTranslation();
  const setSafeTimeout = useSetSafeTimeout();

  // STATE
  const [previewDiv, setPreviewDiv] = React.useState<HTMLDivElement>();
  const [isLoading, setIsLoading] = React.useState(false);
  const areCardsForceOpen = useSelector(getAreCardsForceOpen);

  // DERIVED STATE
  const cardKey = entry[CARD_KEY_PROP];
  const entries = useMemo(
    () => Object.entries(omit(entry || {}, [CARD_KEY_PROP])),
    [entry]
  );
  const title = entries?.[0]?.[1] || '-';
  const emphasized = entries?.[1];
  const shouldAllowOpening =
    !isPreview &&
    Object.keys(entry).length - 1 > KEYS_SHOWN_ON_MOUNT &&
    !areCardsForceOpen;

  const { toggleCard, toggleIsLocalOpen, isLocalOpen } = props as NormalProps;

  const isOpen = isLocalOpen || areCardsForceOpen || isPreview;

  // CALLBACKS

  const onCardClick = React.useCallback(
    // NOTE: can't destructure discriminated union props
    // eslint-disable-next-line react/destructuring-assignment
    props.isPreview
      ? undefined
      : () => {
          // NOTE: unfortunatelly, probably due to the lack of `strictNullChecks`,
          // TS is unable to infer here that `props` is of the type `NormalProps`
          toggleCard(cardKey);
        },
    [cardKey, isPreview, toggleCard]
  );

  const onKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLDivElement>
  >(
    (event) => {
      if (event.key === 'Enter') {
        onCardClick?.();
        event.preventDefault();
      }
    },
    [onCardClick]
  );

  // EFFECTS
  // NOTE: ideally we'd like to perform this right when `onCardClick` is triggered, but we need to make sure
  // the div is available in DOM in order to perform this operation
  const onPreviewChange = React.useCallback(() => {
    if (!previewDiv) return;

    // NOTE: the ref should always be available for non-preview cards but just to be sure...
    const hasRequiredRefs = 'selectionPreviewsRef' in props;
    if (!hasRequiredRefs) return;

    const selectionPreviews = props.selectionPreviewsRef.current;

    // we don't generate the preview again if it already exists
    if (selectionPreviews?.[cardKey]) return;

    setIsLoading(true);

    // unblock the UI to apply any "loading" states, etc. (although it might not always work)
    setSafeTimeout(async () => {
      try {
        // NOTE: for now preview canvas pre-generation is disabled as it is UI blocking and has bad performance
        // so for now only the preview generated inside the dialog will be used – leaving it in for posterity
        // const canvas = await html2canvas(previewDiv, {
        //   onclone(clonedDoc) {
        //     // show the hidden preview in the in-memory doc clone for the purpose of rendering it to canvas
        //     // eslint-disable-next-line no-param-reassign
        //     clonedDoc.getElementById(getPreviewId(cardKey)).style.display =
        //       'block';
        //   },
        // });

        selectionPreviews[cardKey] = {
          // canvas,
          parentTitle,
          title,
        };
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
      // `0` seems not to be enough to queue this method after the UI update so choosing an arbitrary value :|
    }, 30);

    // NOTE: it would be nice to have a cleanup function but it's hard to figure out
    // when exactly is the preview no longer useful to delete it at that point
  }, [cardKey, parentTitle, previewDiv, title, setSafeTimeout]);
  React.useEffect(onPreviewChange, [onPreviewChange]);

  // PARTS
  const accessibilityProps = React.useMemo<
    React.HTMLAttributes<HTMLDivElement>
  >(
    () =>
      isPreview
        ? undefined
        : {
            onClick: onCardClick,
            onKeyDown,
            role: 'button',
            tabIndex: 0,
          },
    [isPreview]
  );

  // RENDER
  return (
    <>
      <div style={style} className={classNames.wrapper}>
        <div
          className={cn(classNames.card, {
            [classNames.previewCard]: isPreview,
            [classNames.selectedCard]: (props as NormalProps).isCardSelected,
          })}
          // NOTE: the accessibility props are not generated for the preview cards
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...accessibilityProps}
        >
          <div className={classNames.header}>
            <FontIcon iconName="EykoOutput" className={classNames.icon} />
            <div className={classNames.titleRow}>
              <div className={classNames.title}>
                <FormattedValue
                  asset={assets[0]}
                  value={title}
                  valueType={ValueType.CardTitle}
                />
              </div>
              <div className={classNames.subtitle}>{parentTitle}</div>
            </div>
            {isLoading && <Spinner className={classNames.cardSpinner} />}
          </div>
          <div className={classNames.body}>
            <div className={filters?.length ? cn(classNames.row) : undefined}>
              <div className={classNames.emphasized}>
                <div>
                  {emphasized?.[0] ? (
                    <FormattedEntryKey
                      entryKey={emphasized?.[0]}
                      iconClassName={classNames.cardTitleAggregationIcon}
                    />
                  ) : (
                    '-'
                  )}
                </div>
                <div>
                  <FormattedValue
                    asset={assets[1]}
                    value={emphasized?.[1]}
                    valueType={ValueType.CardMainValue}
                  />
                </div>
              </div>
              {filters?.length ? (
                <div className={classNames.filters}>
                  <FontIcon
                    iconName="Filter"
                    className={classNames.filterIcon}
                  />
                  <div>
                    {filters
                      .slice(0, isOpen ? undefined : KEYS_SHOWN_ON_MOUNT)
                      .map((src) => {
                        const filter = streamerFilterToDesignerFilter(src);
                        const comparison = getRealComparisonType(filter);
                        return (
                          <p key={filter.id}>
                            <span>{filter.item.name}</span>
                            <Icon
                              className={classNames.filterComparisonIcon}
                              path={comparisonToIcon[comparison]}
                            />
                            <span>
                              {formatValues({ ...filter, comparison })}
                            </span>
                          </p>
                        );
                      })}
                    {filters?.length > KEYS_SHOWN_ON_MOUNT && !isOpen && (
                      <p>...</p>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            {entries
              .slice(2, isOpen ? undefined : KEYS_SHOWN_ON_MOUNT)
              .map(([key, value], i) => (
                <p
                  key={`${key}-${value}`}
                  className={classNames.keyValueWrapper}
                >
                  <span className={classNames.key}>
                    <FormattedEntryKey
                      entryKey={key}
                      iconClassName={classNames.cardKeyAggregationIcon}
                    />
                    <span className={classNames.keyValueSeparator}>:</span>
                  </span>
                  <span className={classNames.value}>
                    <FormattedValue
                      asset={assets[i + 2]}
                      value={value}
                      valueType={ValueType.CardRegularValue}
                    />
                  </span>
                </p>
              ))}
          </div>
          <div className={classNames.footer}>
            {shouldAllowOpening && (
              <PrimaryButton
                styles={showMoreStyles}
                onClick={toggleIsLocalOpen}
              >
                {isLocalOpen ? t('Less Details') : t('More Details')}
              </PrimaryButton>
            )}
            {isPreview && (
              <img
                alt={t('Powered by Eyko')}
                src="assets/PoweredByEyko.svg"
                className={classNames.poweredByEyko}
              />
            )}
          </div>
        </div>
      </div>
      {!isPreview && (props as NormalProps).isCardSelected && (
        <div
          className={classNames.hiddenPreview}
          id={getPreviewId(cardKey)}
          ref={setPreviewDiv}
        >
          {/* NOTE: this is basically a preview copy of the component so we can spread the props */}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <OutputCard {...props} isPreview />
        </div>
      )}
    </>
  );
};

export default OutputCard;
