// By using immer's writable draft, we can safely mutate params
// in the reducer's addCase callback
/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { MachineLearning } from '../../../api/model/schemas/MachineLearning';
import { SelectionType } from '../../../components/ui/ViewControlButton';
import {
  designSuccessfullyDeleted,
  setSelectedDesignId,
} from '../ContentLibrary/actions';
import {
  ICalculation,
  IDesignSource,
  IDesignSourceEntity,
  IGroup,
  IHub,
  UIEnhancedDesignSourceEntityField,
} from '../types';

import {
  fetchDataSources,
  fetchDataSourceFields,
  setSelectedSourceId,
  setCurrentSelectionType,
  setCurrentFilter,
  fetchCalculations,
  fetchHubs,
  fetchGroups,
  setSelectedEntityId,
  updateEntity,
  setFieldEntities,
  setCurrentPage,
  triggerSmartCatalog,
  fetchMachineLearningAssets,
  triggerSmartFlow,
} from './actions';
import { SmartFlowOperations } from './components/DataTopHeader/constants';

export interface DesignerCatalogState {
  dataSources: {
    isLoading: boolean;
    records: IDesignSource[];
    error?: boolean;
  };

  entityFields: {
    isLoading: boolean;
    fields: UIEnhancedDesignSourceEntityField[];
    entities: IDesignSourceEntity[];
    error?: boolean;
  };

  calculations: {
    isLoading: boolean;
    records: ICalculation[];
    error?: boolean;
  };

  hubs: {
    isLoading: boolean;
    records: IHub[];
    error?: boolean;
  };

  groups: {
    isLoading: boolean;
    records: IGroup[];
    error?: boolean;
  };

  machineLearning: {
    isLoading: boolean;
    records: MachineLearning[];
    error?: boolean;
  };

  selectedSourceId?: string;

  currentFilter?: string;
  currentSelectionType?: SelectionType;

  isUpdating: boolean;

  selectedEntityId?: string;

  currentPage?: string;
}

export const initialState: DesignerCatalogState = {
  dataSources: {
    isLoading: false,
    records: [],
  },

  entityFields: {
    isLoading: false,
    fields: [],
    entities: [],
  },

  calculations: {
    isLoading: false,
    records: [],
  },

  hubs: {
    isLoading: false,
    records: [],
  },

  groups: {
    isLoading: false,
    records: [],
  },

  machineLearning: {
    records: [],
    isLoading: false,
  },

  isUpdating: false,
};

const reducer = createReducer<DesignerCatalogState>(initialState, (builder) =>
  builder
    .addCase(setSelectedDesignId, (state) => ({
      ...state,
      dataSources: {
        ...state.dataSources,
        records: [],
      },
      selectedSourceId: null,
    }))
    .addCase(fetchDataSources.pending, (state) => ({
      ...state,
      dataSources: {
        ...state.dataSources,
        isLoading: true,
      },
    }))
    .addCase(fetchDataSources.rejected, (state) => ({
      ...state,
      dataSources: {
        ...state.dataSources,
        isLoading: false,
        error: true,
      },
    }))
    .addCase(fetchDataSources.fulfilled, (state, action) => ({
      ...state,
      dataSources: {
        isLoading: false,
        records: action.payload,
        error: false,
      },
    }))
    .addCase(fetchCalculations.pending, (state) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: true,
      },
    }))
    .addCase(fetchCalculations.rejected, (state) => ({
      ...state,
      calculations: {
        ...state.calculations,
        isLoading: false,
        error: true,
      },
    }))
    .addCase(fetchCalculations.fulfilled, (state, action) => ({
      ...state,
      calculations: {
        isLoading: false,
        records: action.payload,
        error: false,
      },
    }))
    .addCase(fetchMachineLearningAssets.pending, (state) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoading: true,
      },
    }))
    .addCase(fetchMachineLearningAssets.rejected, (state) => ({
      ...state,
      machineLearning: {
        ...state.machineLearning,
        isLoading: false,
        error: true,
      },
    }))
    .addCase(fetchMachineLearningAssets.fulfilled, (state, action) => ({
      ...state,
      machineLearning: {
        isLoading: false,
        records: action.payload,
        error: false,
      },
    }))
    .addCase(fetchGroups.pending, (state) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: true,
      },
    }))
    .addCase(fetchGroups.rejected, (state) => ({
      ...state,
      groups: {
        ...state.groups,
        isLoading: false,
        error: true,
      },
    }))
    .addCase(fetchGroups.fulfilled, (state, action) => ({
      ...state,
      groups: {
        isLoading: false,
        records: action.payload,
        error: false,
      },
    }))

    .addCase(fetchHubs.pending, (state) => ({
      ...state,
      hubs: {
        ...state.hubs,
        isLoading: true,
      },
    }))
    .addCase(fetchHubs.rejected, (state) => ({
      ...state,
      hubs: {
        ...state.hubs,
        isLoading: false,
        error: true,
      },
    }))
    .addCase(fetchHubs.fulfilled, (state, action) => ({
      ...state,
      hubs: {
        isLoading: false,
        records: action.payload,
        error: false,
      },
    }))

    .addCase(triggerSmartCatalog.pending, (state) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        isLoading: true,
      },
    }))
    .addCase(triggerSmartCatalog.rejected, (state) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        isLoading: false,
      },
    }))
    // there is no loading state removal fulfilled case, because this action
    // then calls fetch source fields action and we
    // want to avoid screen blink
    .addCase(triggerSmartCatalog.fulfilled, (state, { payload }) => ({
      ...state,
      dataSources: {
        ...state.dataSources,
        records: state.dataSources.records.map((source) => {
          if (source?.id === payload.dataSourceId) {
            return {
              ...source,
              isBlitsed: payload.operation === 'On',
            };
          }
          return source;
        }),
      },
    }))
    .addCase(triggerSmartFlow.pending, (state) => {
      state.entityFields.isLoading = true;
    })
    .addCase(triggerSmartFlow.rejected, (state) => {
      state.entityFields.isLoading = false;
    })
    .addCase(
      triggerSmartFlow.fulfilled,
      (state, { payload: { dataSourceId, operation } }) => {
        const source = state.dataSources.records?.find(
          ({ id }) => id === dataSourceId
        );

        source.hasAppliedSmartFlows =
          operation === SmartFlowOperations.Evaluate;
      }
    )
    .addCase(fetchDataSourceFields.pending, (state) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        isLoading: true,
        entities: [],
        fields: [],
      },
    }))
    .addCase(fetchDataSourceFields.rejected, (state) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        isLoading: false,
        error: true,
      },
    }))
    .addCase(fetchDataSourceFields.fulfilled, (state, action) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        isLoading: false,
        fields: action.payload,
        error: false,
      },
    }))
    .addCase(setSelectedSourceId, (state, action) => ({
      ...state,
      selectedSourceId: action.payload,
      entityFields: {
        ...state.entityFields,
        fields: [],
      },
    }))
    .addCase(setSelectedEntityId, (state, action) => ({
      ...state,
      selectedEntityId: action.payload,
    }))
    .addCase(setCurrentSelectionType, (state, action) => ({
      ...state,
      currentSelectionType: action.payload,
    }))
    .addCase(setCurrentFilter, (state, action) => ({
      ...state,
      currentFilter: action.payload,
    }))
    .addCase(setFieldEntities, (state, action) => ({
      ...state,
      entityFields: {
        ...state.entityFields,
        entities: action.payload,
      },
    }))
    .addCase(designSuccessfullyDeleted, (state) => ({
      ...state,
      currentPage: null,
      selectedSourceId: null,
      entityFields: {
        ...state.entityFields,
        fields: [],
      },
    }))
    .addCase(setCurrentPage, (state, action) => ({
      ...state,
      currentPage: action.payload,
    }))
    .addCase(updateEntity.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    .addCase(updateEntity.rejected, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(updateEntity.fulfilled, (state) => ({
      ...state,
      isLoading: false,
    }))
);

export default reducer;
