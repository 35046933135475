import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Stack, getTheme } from '@fluentui/react';

import { stackStyles } from './styles';
import { routes } from '../../../../constants/routes';
import { getBuildVersion } from '../../../../config/buildType';

import VersionInfo from './VersionInfo';
import StreamsHeader from './StreamsHeader';
import FilterHeader from './FilterHeader';
import ColumnsOrderHeader from '../../../ColumnsSequencing/components/ColumnsOrderHeader';
import DeleteColumnsHeader from '../../../ColumnsSequencing/components/DeleteColumnsHeader';
import SearchIconButton from '../../../Search/components/SearchIconButton';
import DebugIcons from './DebugIcons';
import useIsOnboarding from '../../../../hooks/useIsOnboarding';
import UserFlowControls from './UserFlowControls';
import DesignerHeader from '../../../Designer/components/DesignerHeader';
import BuilderHeader from '../../../Builder/components/BuilderHeader';
import { UserHeader } from '../../../User/components/UserHeader/UserHeader';
import { NotificationsHeader } from '../../../Notifications/components/NotificationsHeader/NotificationsHeader';
import { Header as StreamerHeader } from '../../../Streamer/components/Header/Header';

const Header: React.FC = () => {
  const theme = getTheme();
  const buildVersion = getBuildVersion();

  const { isOnboarding } = useIsOnboarding();

  if (isOnboarding) {
    return null;
  }

  return (
    <>
      <Stack
        disableShrink
        styles={stackStyles}
        style={{ backgroundColor: theme.palette.themeLighter }}
        horizontal
        horizontalAlign="start"
        verticalAlign="center"
      >
        <Route path={routes.visuals.index}>
          <UserFlowControls />
        </Route>
        <SearchIconButton />
        <Route
          path={[routes.streams.index, routes.groupTable.design]}
          component={StreamsHeader}
        />
        <Switch>
          <Route path={routes.streams.filters.index} component={FilterHeader} />
          <Route
            exact
            path={routes.streams.design.index}
            component={ColumnsOrderHeader}
          />
          <Route
            exact
            path={routes.groupTable.design}
            component={DeleteColumnsHeader}
          />
          <Route path={routes.designer.index} component={DesignerHeader} />
          <Route path={routes.builder.index} component={BuilderHeader} />
          <Route path={routes.settings.profile.index} component={UserHeader} />
          <Route
            path={routes.notifications.index}
            component={NotificationsHeader}
          />
          <Route
            path={routes.streamer.dataMode.index}
            component={StreamerHeader}
          />
        </Switch>
        <DebugIcons />
        <VersionInfo buildVersion={buildVersion || 'local-build'} />
      </Stack>
    </>
  );
};
export default Header;
