import { useDispatch, InferThunkActionCreatorType } from 'react-redux';
import { useMemo } from 'react';
import { bindActionCreators, ActionCreatorsMapObject } from 'redux';

export type MappedThunkActions<T extends ActionCreatorsMapObject> = {
  [K in keyof T]: InferThunkActionCreatorType<T[K]>
};

function useActions<T extends ActionCreatorsMapObject>(actions: T[], deps?: any[]) : MappedThunkActions<T>[];
function useActions<T extends ActionCreatorsMapObject>(actions: T, deps?: any[]) : MappedThunkActions<T>;
function useActions<T extends ActionCreatorsMapObject>(actions: T | T[], deps?: any) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators<
        T,
        MappedThunkActions<T>
        >(a, dispatch));
      }
      return bindActionCreators<
      T,
      MappedThunkActions<T>
      >(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch],
  );
}

export default useActions;
