import { createSelector } from 'reselect';
import { ApplicationState } from '../../store/types';

export const getColumnSequencingState = (state: ApplicationState) => state.columnsSequencing;

export const getSelectedItemIds = (
  state: ApplicationState,
) => getColumnSequencingState(state).selectedItemsIds;

export const isColumnSelected = (columnId: string) => createSelector(
  getSelectedItemIds,
  (selectedIds) => selectedIds.includes(columnId),
);
