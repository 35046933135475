import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

import { ApplicationState } from '../../store/types';
import { getEnvironment, Environment } from '../../utils/getEnvironment';
import { getBuildVersion } from '../buildType';
import { PathnameFromLocalStorage } from './sendPathFromLocalstorage';

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state:ApplicationState) => {
    // Transform the state to remove sensitive information
    const transformedState:ApplicationState = {
      ...state,
      streams: {
        ...state.streams,
        // strip datasets - as it may contain customer data
        datasets: [],
      },
    };

    return transformedState;
  },
});

export const initializeSentry = () => {
  const environment = getEnvironment();

  // do not init sentry locally
  if (environment === Environment.Local) return;

  Sentry.init({
    dsn: 'https://e3e3ac281b674a2390706e5eb927a3ae@o923585.ingest.sentry.io/5871049',
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
      new PathnameFromLocalStorage(),
    ],
    environment,
    normalizeDepth: 5,
    release: getBuildVersion(),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};
