import React from 'react';
import { Trans } from 'react-i18next';

export const streamsTeachingBubbles = [{
  target: '#stream-item-bubble',
  headline: <Trans i18nKey="teachingBubbles:streams.streams" />,
  children: (
    <Trans i18nKey="teachingBubbles:streams.streamsMessage" />
  ),
}, {
  target: '[data-icon-name="People"]',
  headline: <Trans i18nKey="teachingBubbles:streams.sharedWithYou" />,
  children: <Trans i18nKey="teachingBubbles:streams.sharedWithYouMessage" />,
}];

export const objectsTeachingBubbles = [{
  target: '#objects-teaching-bubble',
  headline: <Trans i18nKey="teachingBubbles:streams.objects" />,
  children: (
    <Trans i18nKey="teachingBubbles:streams.objectsMessage" />
  ),
}, {
  target: '[data-icon-name="Back"]',
  headline: <Trans i18nKey="teachingBubbles:streams.back" />,
  children: <Trans i18nKey="teachingBubbles:streams.backMessage" />,
}];

export const itemsTeachingBubbles = [{
  target: '#items-teaching-bubble',
  headline: <Trans i18nKey="teachingBubbles:streams.items" />,
  children: (
    <Trans i18nKey="teachingBubbles:streams.itemsMessage" />
  ),
}, {
  target: '[data-testid="stream-entity"]',
  headline: <Trans i18nKey="teachingBubbles:streams.folders" />,
  children: <Trans i18nKey="teachingBubbles:streams.foldersMessage" />,
}];

export const playTeachingBubbles = [{
  target: '[data-icon-name="Play"]',
  headline: <Trans i18nKey="teachingBubbles:streams.readyToStream" />,
  children: (
    <Trans i18nKey="teachingBubbles:streams.readyToStreamMessage" />
  ),
}, {
  target: '[data-icon-name="Preview"]',
  headline: <Trans i18nKey="teachingBubbles:streams.preview" />,
  children: <Trans i18nKey="teachingBubbles:streams.previewMessage" />,
}];

export const finalTeachingBubbles = [{
  target: '[data-icon-name="Previous"]',
  headline: <Trans i18nKey="teachingBubbles:streams.congratulations" />,
  children: (
    <Trans i18nKey="teachingBubbles:streams.congratulationsMessage" />
  ),
}, {
  target: '[data-icon-name="Play"]',
  headline: <Trans i18nKey="teachingBubbles:streams.congratulations" />,
  children: <Trans i18nKey="teachingBubbles:streams.congratulationsMessage2" />,
}];
