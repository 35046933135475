import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from '@fluentui/react';

import { TeachingBubble, Toggle } from '../../../../../../components/ui';
import { SECTION_TYPES } from '../../../../constants';
import { ParentDrilling, SectionType } from '../../../../types';
import streamsSelectors from '../../../../../Streams/selectors';
import { actionCreators } from '../../../../../Streams/actions';

import { toggleStyles } from '../../styles';
import { styles, wrapIconStyles } from './styles';
import useActions from '../../../../../../utils/useActions';
import { freezePanes } from '../../../../../../actions/excel-actions/groupTable';
import { TEMP_DATASET } from '../../../../../../types/IDataset';
import ParentDrillingToggle from './ParentDrillingToggle';
import { ParentDrillingTypes } from './types';

interface Props {
  sectionType: SectionType;
  toggleStackClassName: string;
  hasTotals: boolean;
  handleChangeTotal: (checked: boolean) => void;
}

const ToggleStack = ({
  toggleStackClassName,
  hasTotals,
  handleChangeTotal,
  sectionType,
}: Props) => {
  const { t } = useTranslation('streams');
  const { editDataset, setDefaultDatasetOptions } = useActions({
    editDataset: actionCreators.editDataset,
    setDefaultDatasetOptions: actionCreators.setDefaultDatasetOptions,
  });
  const currentDataset = useSelector(streamsSelectors.getCurrentDataset);
  const defaultDatasetOptions = useSelector(
    streamsSelectors.getDefaultDatasetOptions,
  );
  const [bubbleOpen, setBubbleOpen] = useState(false);

  const handleToggleChange = (
    option: string,
    value: boolean | ParentDrilling,
  ) => {
    if (currentDataset) {
      editDataset(currentDataset.id, {
        options: { ...currentDataset.options, [option]: value },
      });
      if (
        option === 'freezePanes'
        && typeof value === 'boolean'
        && currentDataset.id !== TEMP_DATASET
      ) {
        // To actually freeze panes, we need dataset to be painted
        // in the Excel Grid
        freezePanes(value, currentDataset);
      }
    }

    setDefaultDatasetOptions({ ...defaultDatasetOptions, [option]: value });
  };

  const onChangeTotal = (_event: any, checked: boolean) => {
    handleChangeTotal(checked);
  };

  const handleInfoIconClick = () => {
    setBubbleOpen(true);
  };

  const renderOptions = () => {
    switch (sectionType) {
      case SECTION_TYPES.rows: {
        return (
          <ParentDrillingToggle
            handleToggleChange={handleToggleChange}
            parentDrillingType={ParentDrillingTypes.Row}
          />
        );
      }
      case SECTION_TYPES.columns: {
        return (
          <ParentDrillingToggle
            handleToggleChange={handleToggleChange}
            parentDrillingType={ParentDrillingTypes.Column}
          />
        );
      }
      case SECTION_TYPES.values: {
        return (
          <>
            { bubbleOpen && (
              <TeachingBubble
                target="#freeze-panes-info-icon"
                headline={t('freezePanes')}
                onDismiss={() => setBubbleOpen(false)}
                hasCloseButton
              >
                <Trans t={t} i18nKey="freezePanesMessage" />
              </TeachingBubble>
            )}
            <div style={wrapIconStyles}>
              <Icon
                style={styles.infoIcon}
                iconName="Info"
                id="freeze-panes-info-icon"
                onClick={handleInfoIconClick}
              />
            </div>
            <Toggle
              inlineLabel
              styles={toggleStyles}
              onChange={(_, checked) => handleToggleChange('freezePanes', checked)}
              checked={
                currentDataset
                  ? currentDataset.options.freezePanes
                  : defaultDatasetOptions.freezePanes
              }
              label={t('freezePanes')}
            />
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={toggleStackClassName}>
      {renderOptions()}
      <Toggle
        onChange={onChangeTotal}
        styles={toggleStyles}
        label={t('Total')}
        defaultChecked={hasTotals}
        inlineLabel
      />
    </div>
  );
};

export default ToggleStack;
