import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../constants/routes';
import useSortControl from '../../../../hooks/useSortControl';
import ItemActionsHeader from '../../../../pageTemplates/ItemActionsHeader';
import { DefinedSortTypes } from '../../../App/types';

const FiltersHeader = () => {
  // HOOKS
  const history = useHistory();
  const { currentSort } = useSortControl();

  // DERIVED STATE
  const isLogicFilterView = currentSort === DefinedSortTypes.Logic;

  // CALLBACKS
  const handleAdd = React.useCallback(() => history.push(routes.builder.assets.filters.add), [history]);

  return (
    <ItemActionsHeader
      isItemSelected={false}
      handleAdd={isLogicFilterView ? handleAdd : null}
    />
  );
};

export default FiltersHeader;
