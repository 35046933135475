import { createReducer } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { GroupAssetClickedActionType, StreamerState } from './types';

import * as actions from './actions';
import * as filtersActions from './Filters/actions';

export const initialState: StreamerState = {
  streams: {
    isLoading: false,
    records: [],
    selectedId: null,
  },
  availableAssets: {
    isLoading: false,
    records: [],
  },
  filters: {
    records: [],
    selectedFilterId: null,
  },
  sendToFile: {
    hasError: false,
    isLoading: false,
    isSuccess: false,
  },
  selectedAssets: [],
  // used to calculate diff
  // &derive if continuation token can be used
  previouslyFetchedAssets: [],
  isRetrievingOutputData: false,
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(filtersActions.setFilters, (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        records: payload,
      },
    }))
    .addCase(filtersActions.addFilter, (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        records: [...state.filters.records, payload],
      },
    }))
    .addCase(filtersActions.deleteFilter, (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        records: state.filters.records.filter((f) => f.id !== payload.id),
      },
    }))
    .addCase(filtersActions.editFilter, (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        records: state.filters.records.map((f) =>
          f.id === payload.id ? payload : f
        ),
      },
    }))
    .addCase(actions.performSendToFile.pending, (state) => ({
      ...state,
      sendToFile: {
        ...state.sendToFile,
        isSuccess: false,
        hasError: false,
        isLoading: true,
      },
    }))
    .addCase(actions.performSendToFile.rejected, (state) => ({
      ...state,
      sendToFile: {
        ...state.sendToFile,
        hasError: true,
        isLoading: false,
      },
    }))
    .addCase(actions.performSendToFile.fulfilled, (state) => ({
      ...state,
      sendToFile: {
        ...state.sendToFile,
        isSuccess: true,
        isLoading: false,
      },
    }))
    .addCase(actions.sendToFileModalClosed, (state) => ({
      ...state,
      sendToFile: {
        ...initialState.sendToFile,
      },
    }))
    .addCase(actions.fetchStreamsAction.pending, (state) => ({
      ...state,
      streams: {
        ...state.streams,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamsAction.rejected, (state) => ({
      ...state,
      streams: {
        ...state.streams,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamsAction.fulfilled, (state, { payload }) => ({
      ...state,
      streams: {
        ...state.streams,
        isLoading: false,
        records: payload,
      },
    }))
    .addCase(actions.fetchStreamAssets.pending, (state) => ({
      ...state,
      availableAssets: {
        ...state.availableAssets,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamAssets.rejected, (state) => ({
      ...state,
      availableAssets: {
        ...state.availableAssets,
        isLoading: true,
      },
    }))
    .addCase(actions.fetchStreamAssets.fulfilled, (state, { payload }) => ({
      ...state,
      availableAssets: {
        ...state.availableAssets,
        isLoading: false,
        records: payload,
      },
    }))
    .addCase(actions.selectStreamId, (state, { payload }) => ({
      ...state,
      streams: {
        ...state.streams,
        selectedId: payload,
      },
      availableAssets: {
        ...state.availableAssets,
        records: [],
      },
      filters: {
        ...initialState.filters,
      },
      selectedAssets: [],
      previouslyFetchedAssets: [],
    }))
    .addCase(actions.storeSelectedItems, (state) => ({
      ...state,
      previouslyFetchedAssets: state.selectedAssets,
    }))
    .addCase(actions.setSelectedAssets, (state, { payload }) => ({
      ...state,
      selectedAssets: payload,
    }))
    .addCase(actions.updateStreamerAsset, (state, { payload }) => ({
      ...state,
      selectedAssets: state.selectedAssets.map((asset) =>
        isEqual(asset, payload.currentAsset) ? payload.updatedAsset : asset
      ),
    }))
    .addCase(
      actions.groupAssetsClicked,
      (state, { payload: { actionType, assets } }) => {
        if (actionType === GroupAssetClickedActionType.Select) {
          const itemsToAdd = assets.filter(
            (asset) =>
              !state.selectedAssets.find(
                (selectedAsset) =>
                  selectedAsset.streamElementId === asset.streamElementId
              )
          );

          return {
            ...state,
            selectedAssets: [...state.selectedAssets, ...itemsToAdd],
          };
        }

        const receivedAssetsIds = assets.map((asset) => asset.streamElementId);

        return {
          ...state,
          selectedAssets: state.selectedAssets.filter(
            (selectedAsset) =>
              !receivedAssetsIds.includes(selectedAsset.streamElementId)
          ),
        };
      }
    )
    .addCase(actions.assetClicked, (state, { payload }) => {
      const shouldRemove = state.selectedAssets.some(
        (asset) => asset.streamElementId === payload.streamElementId
      );

      return {
        ...state,
        selectedAssets: shouldRemove
          ? state.selectedAssets.filter(
              (asset) => asset.streamElementId !== payload.streamElementId
            )
          : [...state.selectedAssets, payload],
      };
    })
    .addCase(actions.assetAggregationClicked, (state, { payload }) => {
      const shouldRemoveIndex = state.selectedAssets.findIndex(
        (asset) =>
          asset.streamElementId === payload.streamElementId &&
          asset.aggregation === payload.aggregation
      );

      const result = [...state.selectedAssets];
      result.splice(shouldRemoveIndex, 1);

      if (shouldRemoveIndex !== -1) {
        return {
          ...state,
          selectedAssets: result,
        };
      }

      return {
        ...state,
        selectedAssets: [...state.selectedAssets, payload],
      };
    })
);

export default reducer;
