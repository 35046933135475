import * as effects from 'redux-saga/effects';
import moment from 'moment';

import streamsSelectors from '../../Streams/selectors';
import * as groupTableSelectors from '../selectors';
import * as excelActions from '../../../actions/excel-actions';
import { groupTablePlayIntent } from '../actions';
import { syncPersistedData } from '../../../store/persistence/actions';

export function* handleGroupTablePlay() {
  const currentDataset = streamsSelectors.getCurrentDataset(
    yield effects.select(),
  );

  yield effects.call(excelActions.updateDataset, {
    datasetId: currentDataset.id,
    forceRender: true,
  });

  const { columns, id, streamFilters } = streamsSelectors.getCurrentDataset(
    yield effects.select(),
  );

  const groupTableDesign = groupTableSelectors.getGroupTable(id)(yield effects.select());

  yield effects.put({
    type: 'EDIT_DATASET',
    id,
    properties: {
      lastSuccessfulMapping: {
        columns,
        timestamp: moment().format('h:mm A M/D/YY'),
        groupTableDesign: {
          columns: groupTableDesign.columns,
          rows: groupTableDesign.rows,
          values: groupTableDesign.values,
          streamFilters,
        },
      },
    },
  });

  yield effects.put(syncPersistedData());
}

export function* rootSaga() {
  yield effects.all([
    effects.takeLatest(groupTablePlayIntent.type, handleGroupTablePlay),
  ]);
}

export default rootSaga;
