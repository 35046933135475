import React from 'react';
import {
  Text,
  getTheme,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { debug } from '../../../../../actions/excel-actions';

import { getTextStyles } from './styles';

interface Props {
  buildVersion?: string
}

const VersionInfo: React.FC<Props> = ({ buildVersion }) => {
  const { t } = useTranslation('welcome');
  const theme = getTheme();

  const printDebugInfo = () => {
    // eslint-disable-next-line no-console
    console.log(debug());
  };

  if (!buildVersion) return null;

  return (
    <div onDoubleClick={printDebugInfo}>
      <Text styles={getTextStyles(theme)} as="p" variant="xSmall">
        {t('buildVersion', { buildVersion })}
      </Text>
    </div>
  );
};

export default VersionInfo;
