// TODO: move into i18n/types
import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../../modules/Designer/types";
import { Calculation } from "../../../../model/schemas/Calculation";
import { TypeIds } from "../../../../model/schemas";
import { isResponseTypeIdMatching } from "../../utils";
import { translateApiName } from "../../../../../config/i18n/utils";
import { i18nCalculationTypeIdKey, i18nDataTypeKey, i18nFormatTypeKey } from "../../constants";
import { CALCULATION_TYPE_TYPE_ID_MAP, TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP } from "../../../../constants";

export const isCalculationsResponse = (
  response: AxiosResponse<ApiV4ResponseWrapper<unknown>>
): response is AxiosResponse<ApiV4ResponseWrapper<Calculation[]>> =>
  isResponseTypeIdMatching(response, TypeIds.Calculation);

export const mutablyParseCalculations = (response: AxiosResponse<ApiV4ResponseWrapper<Calculation[]>>) => {
  response.data.data = response.data.data.map(calculation => {
    return {
      ...calculation,
      [i18nCalculationTypeIdKey]: translateApiName(
        calculation.calculationTypeId,
        CALCULATION_TYPE_TYPE_ID_MAP[calculation.calculationTypeId],
      ),
      [i18nDataTypeKey]: translateApiName(calculation.$typeId, calculation.dataType),
      [i18nFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[calculation.dataType] ?? calculation.$typeId,
        String(calculation.formatType),
      ),
    };
  });

  return response;
}
