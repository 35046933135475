import { ApplicationState } from '../../store/types';

const getUserState = (state: ApplicationState) => state.user;

export const getIsUserDataAvailable = (state: ApplicationState) =>
  !!getUserState(state)?.data?.email;

export const getCurrentUserData = (state: ApplicationState) =>
  getUserState(state)?.data;
export const getCurrentUserEmail = (state: ApplicationState) =>
  getCurrentUserData(state)?.email;
export const getIsLoading = (state: ApplicationState) =>
  getUserState(state)?.isLoading;

export const getUserRole = (state: ApplicationState) =>
  getUserState(state).selectedUserRole || getCurrentUserData(state)?.role;
