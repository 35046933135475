import * as effects from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { getAllAssociatedDatasetColumns, isColumnInDataset, isColumnNumeric } from '../components/StreamerColumn/utils';
import { IColumn } from '../../../types/IColumn';
import { TEMP_DATASET } from '../../../types/IDataset';
import { SECTION_TYPES } from '../../GroupTable/constants';
import * as excelActions from '../../../actions/excel-actions';
import { groupTableItemClicked } from '../actions';
import streamSelectors from '../selectors';
import {
  addGroupTableItemIntent,
  addItem,
  setItems,
} from '../../GroupTable/actions';
import { getSectionByType } from '../../GroupTable/selectors';
import { removeColumnWithAllAggregationsFromDataSet } from './itemSelectionDeselection';
import { SingleItem } from '../../GroupTable/types';
import { generateColumnUuid } from '../../ColumnsSequencing/utils';
import { NUMERIC_AGGREGATION_METHODS } from '../../../constants/aggregations';

export function* handleRemoveGroupTableItemOnColumnClick(
  column: IColumn,
  removeAllAggregations: boolean = true,
) {
  const currentDataset = streamSelectors.getCurrentDataset(
    yield effects.select(),
  );
  const appState = yield effects.select();

  const datasetId = currentDataset?.id;

  if (!datasetId) return;

  const checkCondition = (item: SingleItem) => {
    if (removeAllAggregations) {
      const columnAssociatedWithItem = currentDataset.columns.find((c) => generateColumnUuid(c) === item.columnUuid);

      return (
        columnAssociatedWithItem.dataEntityId === column.dataEntityId
        && columnAssociatedWithItem.id === column.id
        && columnAssociatedWithItem.dataSourceId === column.dataSourceId
      );
    }

    return item.columnUuid === generateColumnUuid(column);
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const sectionType of Object.values(SECTION_TYPES)) {
    const currentSection = Array.from(
      getSectionByType(sectionType, datasetId)(appState),
    );

    const filtered = currentSection.filter((item) => !checkCondition(item));

    yield effects.put(
      setItems({
        sectionType,
        items: filtered,
        datasetId,
      }),
    );
  }
}

export function* handleAddGroupTableItemOnColumnClick(column: IColumn) {
  const isAggregationNumeric = (c: IColumn) => NUMERIC_AGGREGATION_METHODS.includes(c.aggregation);

  const shouldBePlacedInValues = isColumnNumeric(column) || isAggregationNumeric(column);

  const currentDataset = streamSelectors.getCurrentDataset(
    yield effects.select(),
  );
  const datasetId = currentDataset?.id || TEMP_DATASET;

  const newItem = {
    datasetId,
    sectionType: shouldBePlacedInValues
      ? SECTION_TYPES.values
      : SECTION_TYPES.rows,
    item: {
      key: column.id,
      text: column.name,
      id: uuidv4(),
      columnUuid: generateColumnUuid(column),
    },
  };

  yield effects.put(addItem(newItem));
}

function* handleAddGroupTableItemOnColumnClickIntent({
  payload: column,
}: ReturnType<typeof addGroupTableItemIntent>) {
  yield effects.call(handleAddGroupTableItemOnColumnClick, column);
}

export function* handleItemSelectionDeselection({
  payload: column,
}: ReturnType<typeof groupTableItemClicked>) {
  try {
    // check if we can remove column at all
    // we forbid to remove column (and all associated aggregations)
    // when this would result in having empty array of values section

    const currentDataset = streamSelectors.getCurrentDataset(
      yield effects.select(),
    );
    const shouldRemoveColumn = !!currentDataset?.id && isColumnInDataset(column, currentDataset);

    if (shouldRemoveColumn) {
      const allAggregationsRelatedToClickedColumn = getAllAssociatedDatasetColumns({
        column,
        dataset: currentDataset,
      });

      const groupTableValuesSection = getSectionByType(
        SECTION_TYPES.values,
        currentDataset.id,
      )(yield effects.select());

      const associatedAggregationInValuesCount = allAggregationsRelatedToClickedColumn.reduce(
        (accumulator, columnWithAggregation) => {
          const isInValues = groupTableValuesSection.some(
            (item) => item.columnUuid === generateColumnUuid(columnWithAggregation),
          );

          return accumulator + (isInValues ? 1 : 0);
        },
        0,
      );

      if (
        groupTableValuesSection.length === associatedAggregationInValuesCount
      ) {
        return;
      }

      yield effects.call(handleRemoveGroupTableItemOnColumnClick, column);
      yield effects.call(removeColumnWithAllAggregationsFromDataSet, column);
      yield effects.call(excelActions.updateDataset, {
        datasetId: currentDataset?.id,
      });
    } else {
      const columnWithDefaultAggregation:IColumn = {
        ...column,
        aggregation: column.defaultAggregation,
      };
      yield effects.call(handleAddGroupTableItemOnColumnClick, columnWithDefaultAggregation);
      yield effects.call(excelActions.updateDataset, {
        datasetId: currentDataset?.id,
        columns: [columnWithDefaultAggregation],
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export function* rootSaga() {
  yield effects.all([
    effects.takeLatest(
      groupTableItemClicked.type,
      handleItemSelectionDeselection,
    ),
    effects.takeLatest(
      addGroupTableItemIntent.type,
      handleAddGroupTableItemOnColumnClickIntent,
    ),
  ]);
}

export default rootSaga;
