import { mergeStyleSets } from '@fluentui/react';

export const classNames = mergeStyleSets({
  scrollableAreaStyles: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 240px)',
    overflowY: 'auto',
  },
  singleValueGroupWrapper: {
    height: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  noValuesMsg: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    fontSize: '12px',
    marginLeft: '28px',
  },
});
