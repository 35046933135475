import { IconButton, Stack } from '@fluentui/react';
import React, { useEffect, useMemo } from 'react';
import { TYPE_IDS } from '../../../../../constants/apiV4TypeIds';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import { IGenericFormField } from '../../types';
import LinkPicker from '../LinkPicker';
import { LinkFields } from '../LinkPicker/types';
import useManageMultiple from '../ManageMultiple/hooks/useManageMultiple';
import { btnStyles, classNames } from './styles';

const MultiLinkPicker: React.FC<IGenericFormField> = ({
  interaction,
  currentForm,
}) => {
  const { setPreventSubmission } = useFormSubmission();
  const {
    items = [],
    edit,
    append,
    remove,
  } = useManageMultiple<LinkFields>({
    typeId: TYPE_IDS.LinkFields,
    currentEntityForm: currentForm,
    interaction,
    forceMin: true,
    defaultValues: { fromId: undefined, toId: undefined },
  });

  const areItemsValid = useMemo(
    () => items.every(({ data: { fromId, toId } }) => !!fromId && !!toId),
    [items]
  );

  const fromFields = useMemo(
    () => items.map(({ data: { fromId } }) => fromId),
    [items]
  );
  const toFields = useMemo(
    () => items.map(({ data: { toId } }) => toId),
    [items]
  );

  const handleChange = (id: string, { fromId, toId }: LinkFields) =>
    edit(id, { fromId, toId });
  const handleAdd = () => append();

  useEffect(() => {
    setPreventSubmission(!areItemsValid);
    return () => {
      setPreventSubmission(false);
    };
  }, [areItemsValid]);

  return (
    <>
      <div>
        {items.map(({ data, id }, index) => (
          <Stack
            horizontal
            key={id}
            className={classNames.wrap}
            verticalAlign="center"
          >
            <LinkPicker
              excludedFields={{
                from: fromFields.slice(0, index),
                to: toFields.slice(0, index),
              }}
              linkFields={data}
              onLinkFieldsChange={(updated) => handleChange(id, updated)}
            />
            {index > 0 && (
              <IconButton
                iconProps={{ iconName: 'Delete' }}
                onMouseDown={() => remove(id)}
                data-testid="delete-link-btn"
                styles={btnStyles}
              />
            )}
          </Stack>
        ))}
      </div>
      {areItemsValid && (
        <IconButton
          className={classNames.add}
          iconProps={{ iconName: 'Add' }}
          onMouseDown={() => handleAdd()}
          data-testid="add-link-btn"
          styles={btnStyles}
        />
      )}
    </>
  );
};

export default MultiLinkPicker;
