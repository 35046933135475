import { createSelector } from 'reselect';
import { ApplicationState } from '../../store/types';
import { ClientPreferencesState, initialState } from './reducer';

export const getClientPreferencesState = (
  state: ApplicationState,
): ClientPreferencesState => state.clientPreferences || initialState;

export const getClientPreferencesList = (
  state: ApplicationState,
) => getClientPreferencesState(state).records;

export const getClientPreferencesIsCreating = (
  state: ApplicationState,
) => getClientPreferencesState(state).isCreating;

export const getClientPreferencesListAsObject = createSelector(
  [getClientPreferencesList],
  (preferencesList) => preferencesList.reduce((obj, preference) => {
    const { id, value } = preference;

    return Object.assign(obj, { [id]: value });
  }, {}),
);
