// NOTE: at the time of writing this message the file is inside config, which might not be
import startCase from 'lodash/startCase';

import i18n from '.';

const parseKeyForTranslation = (key: string = '') => startCase(key);

export const i18nApiPrefix = 'api:types';

// the best place for utilities but at least it's close to the translations it's used with
export const translateApiName = (typeId: string, key: string, fallback: string = parseKeyForTranslation(key)) => {
  const fullKey = `${i18nApiPrefix}:${typeId}:${key}`;
  return (i18n.exists(fullKey) && i18n.t(fullKey)) || fallback;
};
