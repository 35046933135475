import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { setSelectedDesignId } from '../ContentLibrary/actions';
import * as dataModeActions from './screens/Data/actions';
import { DataModeState, DesignerIngestionState } from './types';
import { setDataModeEntitiesAndFields } from './utils';
import { setSelectedSourceId as setCatalogSelectedSourceId } from '../Catalog/actions';

export const initialState: DesignerIngestionState = {
  areFieldsUpdating: false,
  dataSourceEntities: [],
  dataSourceFields: [],
  entitiesWithValues: [],
  ingestionFilters: [],
  isConfiguringDataSource: false,
  isIngestingDataSource: false,
  isLoadingDataSourceFields: false,
  isLoadingFilters: false,
  isLoadingLinks: false,
  isLoadingSources: false,
  linkedEntities: {},
  linkedEntityFields: {},
  links: {},
  linksFields: {},
  isSmartAnalysisLinksInProgress: false,
  modes: {
    data: {
      entities: {},
      fieldsPerEntity: {},
      isLoading: false,
    },
  },
  selectedEmptyLinkId: null,
  selectedEntitiesCountInDataSource: null,
  selectedLinkFieldsId: null,
  selectedSourceId: null,
  sources: [],
  transforms: {
    isLoading: false,
    records: [],
    error: false,
  },
};

const resetDataModeState = (dataModeState: DataModeState) => {
  dataModeState.entities = initialState.modes.data.entities;
  dataModeState.fieldsPerEntity = initialState.modes.data.fieldsPerEntity;
  dataModeState.entitiesNextPageUrl =
    initialState.modes.data.entitiesNextPageUrl;
  dataModeState.fieldsNextPageUrl = initialState.modes.data.fieldsNextPageUrl;
};

const reducer = createReducer<DesignerIngestionState>(initialState, (builder) =>
  builder
    .addCase(actions.setIsIngestingDataSource, (state, { payload }) => ({
      ...state,
      isIngestingDataSource: payload,
    }))
    .addCase(actions.setIsLoadingFilters, (state, { payload }) => ({
      ...state,
      isLoadingFilters: payload,
    }))
    .addCase(actions.setConfiguringDataSource, (state, { payload }) => ({
      ...state,
      isConfiguringDataSource: payload,
    }))
    .addCase(actions.sourceDeletedSuccess, (state, { payload }) => ({
      ...state,
      selectedSourceId: null,
      // we have to go with optimistic UI to solve "autoselect source"  bug
      sources: state.sources.filter((source) => source.id !== payload),
    }))
    .addCase(actions.setSelectedSourceId, (state, { payload }) => ({
      ...state,
      selectedSourceId: payload,
      /**
       * When the first dataset selected is changed, we need to reset the entities and their fields (if) previously loaded,
       * because they won't longer belong to the new selected source.
       */
      dataSourceEntities: [],
      dataSourceFields: [],
    }))
    .addCase(setCatalogSelectedSourceId, (state) => ({
      ...state,
      /**
       * - links are now placed in the catalog, so we have to react to catalog source change
       */
      dataSourceEntities: [],
      dataSourceFields: [],
    }))
    .addCase(setSelectedDesignId, (state) => ({
      ...state,
      selectedSourceId: null,
      dataSourceEntities: [],
      dataSourceFields: [],
      sources: [],
    }))
    .addCase(actions.setLinksDiscoveryInProgress, (state, { payload }) => ({
      ...state,
      isSmartAnalysisLinksInProgress: payload,
    }))
    .addCase(actions.fetchDesignDataSources.pending, (state) => ({
      ...state,
      isLoadingSources: true,
    }))
    .addCase(actions.fetchDesignDataSources.rejected, (state) => ({
      ...state,
      isLoadingSources: false,
    }))
    .addCase(actions.setLoadingSources, (state, { payload }) => ({
      ...state,
      isLoadingSources: payload,
    }))
    .addCase(actions.fetchDesignDataSources.fulfilled, (state, action) => ({
      ...state,
      isLoadingSources: false,
      sources: action.payload,
    }))
    .addCase(actions.fetchDesignLinks.pending, (state) => ({
      ...state,
      isLoadingLinks: true,
    }))
    .addCase(actions.fetchDesignLinks.rejected, (state) => ({
      ...state,
      isLoadingLinks: false,
    }))
    .addCase(actions.linkUpdated, (state, action) => {
      const updatedLink = action.payload;

      state.links[updatedLink.id] = updatedLink;
    })
    .addCase(actions.fetchDesignLinks.fulfilled, (state, action) => ({
      ...state,
      isLoadingLinks: false,
      linkedEntities:
        action.payload.parsedEntities ?? initialState.linkedEntities,
      linkedEntityFields:
        action.payload.parsedEntityFields ?? initialState.linkedEntityFields,
      links: action.payload.parsedLinks
        ? action.payload.parsedLinks
        : initialState.links,
      linksFields: action.payload.parsedLinksFields ?? initialState.linksFields,
    }))
    .addCase(actions.setSelectedLinkFieldsId, (state, action) => ({
      ...state,
      selectedLinkFieldsId: action.payload,
    }))
    .addCase(actions.setSelectedEmptyLinkId, (state, action) => ({
      ...state,
      selectedEmptyLinkId: action.payload,
    }))
    .addCase(actions.fetchAllFiltersByLogic.pending, (state) => ({
      ...state,
      isLoadingFilters: true,
    }))
    .addCase(actions.fetchAllFiltersByLogic.rejected, (state) => ({
      ...state,
      isLoadingFilters: false,
    }))
    .addCase(actions.fetchAllFiltersByLogic.fulfilled, (state, action) => ({
      ...state,
      isLoadingFilters: false,
      ingestionFilters: action.payload,
    }))
    .addCase(
      dataModeActions.dataModeLoaded.pending,
      ({ modes: { data: dataModeState } }) => {
        dataModeState.isLoading = true;
        resetDataModeState(dataModeState);
      }
    )
    .addCase(
      dataModeActions.dataModeLoaded.rejected,
      ({ modes: { data: dataModeState } }) => {
        dataModeState.isLoading = false;
      }
    )
    .addCase(
      dataModeActions.dataModeLoaded.fulfilled,
      ({ modes: { data: dataModeState } }, { payload }) => {
        setDataModeEntitiesAndFields({
          dataModeState,
          ...payload,
        });
        dataModeState.isLoading = false;
      }
    )
    .addCase(
      dataModeActions.infiniteScrollEnd.fulfilled,
      ({ modes: { data: dataModeState } }, { payload }) => {
        setDataModeEntitiesAndFields({
          dataModeState,
          ...payload,
        });
      }
    )
    .addCase(
      dataModeActions.searchPerformed.type,
      ({ modes: { data: dataModeState } }) => {
        dataModeState.isLoading = true;
        resetDataModeState(dataModeState);
      }
    )
    .addCase(
      dataModeActions.searchFailed,
      ({ modes: { data: dataModeState } }) => {
        dataModeState.isLoading = false;
      }
    )
    .addCase(
      dataModeActions.searchFinished,
      ({ modes: { data: dataModeState } }, { payload }) => {
        setDataModeEntitiesAndFields({
          dataModeState,
          ...payload,
        });
        dataModeState.isLoading = false;
      }
    )
    .addCase(
      dataModeActions.entityFieldsLoaded,
      ({ modes: { data: dataModeState } }, { payload }) => {
        setDataModeEntitiesAndFields({
          dataModeState,
          // NOTE: since we're reusing a utility we have to explicitly reuse the old state (as `undefined` clears it)
          // otherwise we'd have to rewrite the util and all it's use-cases so that the only time we assume
          // the "next page" state is supposed to be cleared is when an explicit `null` value is passed
          entitiesNextPageUrl: dataModeState.entitiesNextPageUrl,
          fieldsNextPageUrl: dataModeState.fieldsNextPageUrl,
          ...payload,
        });
      }
    )
    .addCase(
      actions.fetchIngestedOrPendingDataSourceFields.pending,
      (state) => ({
        ...state,
        isLoadingDataSourceFields: true,
      })
    )
    .addCase(
      actions.fetchIngestedOrPendingDataSourceFields.rejected,
      (state) => ({
        ...state,
        isLoadingDataSourceFields: false,
        dataSourceFieldsIdsBeingUpdated: [],
      })
    )
    .addCase(
      actions.fetchIngestedOrPendingDataSourceFields.fulfilled,
      (state, action) => ({
        ...state,
        isLoadingDataSourceFields: false,
        dataSourceEntities: action.payload.entities,
        dataSourceFields: action.payload.entityFields,
        dataSourceFieldsIdsBeingUpdated: [],
      })
    )
    .addCase(actions.fetchSelectedEntitiesCount.pending, (state) => ({
      ...state,
      selectedEntitiesCountInDataSource: null,
      isLoadingDataSourceFields: true,
    }))
    .addCase(actions.fetchSelectedEntitiesCount.rejected, (state) => ({
      ...state,
      isLoadingDataSourceFields: false,
    }))
    .addCase(actions.fetchSelectedEntitiesCount.fulfilled, (state, action) => ({
      ...state,
      selectedEntitiesCountInDataSource: action.payload,
      isLoadingDataSourceFields: false,
    }))
    .addCase(actions.fetchDataSourceTransforms.pending, (state) => ({
      ...state,
      transforms: {
        records: [],
        isLoading: true,
        error: false,
      },
    }))
    .addCase(actions.fetchDataSourceTransforms.fulfilled, (state, action) => ({
      ...state,
      transforms: {
        records: action.payload,
        isLoading: false,
        error: false,
      },
    }))
    .addCase(actions.fetchDataSourceTransforms.rejected, (state) => ({
      ...state,
      transforms: {
        records: [],
        isLoading: false,
        error: true,
      },
    }))
);

export default reducer;
