import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useLocation, matchPath, useHistory } from 'react-router-dom';
import { NormalProps as NormalCardProps } from './components/OutputCard/OutputCard';
import ItemsListShimmer from '../../../../../../components/ItemsListShimmer';
import {
  getIsFetchingStreamerOutput,
  getCardsNormalizedOutput,
  getSwimlanesAssetId,
  getColumnsAssetId,
  getClosedSwimlanes,
  getClosedColumns,
  getColumns,
  getSwimlanes,
  getOutputViewType,
  getIsSharePreviewOpen,
  getHasFetchedData,
} from '../../selectors';
import { classNames } from './styles';
import {
  getFetchedAssetsAsStreamEntities,
  getSelectedStream,
} from '../../../../selectors';
import { getAllStreamerFilters } from '../../../../Filters/selectors';
import Swimlane from './components/Swimlane';
import { SharePreview } from './components/SharePreview/SharePreview';
import { ViewGroupType } from './model';
import Column from './components/Column';
import {
  setClosedColumns,
  setClosedSwimlanes,
  clearCardSelection,
  toggleCardSelection,
} from '../../actions';
import OutputTable from './components/OutputTable';
import { OutputViewType } from '../../types';
import { useIsOutputInPanelBreakpointActive } from '../../../../screens/OutputPanel/useIsOutputInPanelBreakpointActive';
import { routes } from '../../../../../../constants/routes';
import CardListOuput from './components/CardListOuput';

export const Output = () => {
  // HOOKS
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  // REFS
  // NOTE: we're using a ref because the in-memory previews that are pre-generated don't have to trigger UI changes
  // those are just used when we're actually performing the share action (`onClick`)
  const selectionPreviewsRef: NormalCardProps['selectionPreviewsRef'] =
    React.useRef({});

  // STATE
  const isRetrievingOutputData = useSelector(getIsFetchingStreamerOutput);
  const hasFetchedData = useSelector(getHasFetchedData);
  const data = useSelector(getCardsNormalizedOutput);
  const stream = useSelector(getSelectedStream);
  const assets = useSelector(getFetchedAssetsAsStreamEntities);
  const filters = useSelector(getAllStreamerFilters);
  const swimlanesId = useSelector(getSwimlanesAssetId);
  const columnsId = useSelector(getColumnsAssetId);
  const closedSwimlanes = useSelector(getClosedSwimlanes);
  const closedColumns = useSelector(getClosedColumns);
  const columns = useSelector(getColumns);
  const swimlanes = useSelector(getSwimlanes);
  const viewType = useSelector(getOutputViewType);
  const isSharePreviewOpen = useSelector(getIsSharePreviewOpen);
  const isPanelBreakpointActive = useIsOutputInPanelBreakpointActive();
  const [openedCards, setOpenedCards] = useState<boolean[]>([]);

  // DERIVED STATE
  const columnsHeaders = useMemo(() => Object.keys(columns), [columns]);

  // CALLBACKS
  const toggleGroup = (key: any, group: ViewGroupType) => {
    const action = {
      [ViewGroupType.swimlane]: setClosedSwimlanes,
      [ViewGroupType.column]: setClosedColumns,
    };

    const current = {
      [ViewGroupType.swimlane]: closedSwimlanes,
      [ViewGroupType.column]: closedColumns,
    };

    const closed = new Set(current[group]);
    if (closed.has(key)) {
      closed.delete(key);
    } else {
      closed.add(key);
    }

    dispatch(action[group](Array.from(closed)));
  };

  const toggleCard = React.useCallback(
    (key: number) => {
      dispatch(toggleCardSelection(key));
    },
    [dispatch]
  );

  const toggleIsCardOpen = useCallback(
    (key: number) =>
      setOpenedCards((current) =>
        Object.assign(current.slice(), { [key]: !current[key] })
      ),
    []
  );

  // EFFECTS
  const onUnmount = React.useCallback(() => {
    dispatch(clearCardSelection());
  }, [dispatch]);
  React.useEffect(() => onUnmount, [onUnmount]);
  React.useEffect(() => {
    if (
      isPanelBreakpointActive &&
      !!matchPath(pathname, routes.streamer.dataMode.output)
    ) {
      history.push(routes.streamer.dataMode.selectAssets);
    }
  }, [isPanelBreakpointActive]);

  // PARTS
  const preview = isSharePreviewOpen && (
    <SharePreview
      {...{
        selectionPreviewsRef,
      }}
    />
  );

  useEffect(() => {
    setOpenedCards([]);
  }, [data]);

  // RENDER
  if (isRetrievingOutputData && !hasFetchedData) return <ItemsListShimmer />;
  if (!data) return null;

  if (swimlanesId) {
    return (
      <section className={cn([classNames.column, classNames.wrapper])}>
        {preview}
        {Object.keys(swimlanes).map((key) => (
          <Swimlane
            {...{
              assets,
              closedColumns,
              columnsHeaders,
              filters,
              selectionPreviewsRef,
              stream,
              toggleCard,
              openedCards,
              toggleIsCardOpen,
            }}
            assetId={swimlanesId}
            data={swimlanes[key]}
            isOpen={!new Set(closedSwimlanes).has(key)}
            key={key}
            label={key}
            onColumnHeaderClick={(current) =>
              toggleGroup(current, ViewGroupType.column)
            }
            onHeaderClick={(current) =>
              toggleGroup(current, ViewGroupType.swimlane)
            }
          />
        ))}
      </section>
    );
  }

  if (viewType === OutputViewType.table) {
    return data?.length ? (
      <OutputTable
        {...{
          data,
          assets,
          filters,
        }}
      />
    ) : null;
  }

  if (columnsId) {
    return (
      <section className={classNames.wrapper}>
        {preview}
        {Object.keys(columns).map((key) => (
          <Column
            {...{
              assets,
              filters,
              selectionPreviewsRef,
              toggleCard,
              toggleIsCardOpen,
              openedCards,
            }}
            assetId={columnsId}
            data={columns[key]}
            isOpen={!new Set(closedColumns).has(key)}
            key={key}
            label={key}
            onHeaderClick={(current) =>
              toggleGroup(current, ViewGroupType.column)
            }
            stream={stream}
          />
        ))}
      </section>
    );
  }

  return (
    <CardListOuput
      {...{
        openedCards,
        selectionPreviewsRef,
        toggleCard,
        toggleIsCardOpen,
        preview,
      }}
    />
  );
};
