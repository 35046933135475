import { IButtonStyles } from '@fluentui/react';

import { baseColors } from '../../theme';

export const infoButtonClassname = 'info-button';

export const infoButtonStyles = (isSelected: boolean): IButtonStyles => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    marginLeft: 'auto !important',
    maxHeight: 24,
    width: 24,
    padding: 0,
    margin: '0 2px',
    borderRadius: 2,
    backgroundColor: baseColors.hover,
  },
  icon: {
    color: `${baseColors.iconColor} !important`,
    marginTop: 2,
  },
  rootHovered: {
    backgroundColor: isSelected
      ? baseColors.hover
      : baseColors.selectedWithHover,
  },
  rootFocused: {
    backgroundColor: baseColors.selectedWithHover,
  },
  flexContainer: {
    alignItems: 'center',
  },
});
