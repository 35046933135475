import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import omit from 'lodash/omit';
import { uniqBy } from 'lodash';
import * as api from './api';
import {
  ApiV4ResponseWrapper,
  UIEnhancedDesignSourceEntityField,
  IDesignSourceEntityField,
  EntityIngestionStatus,
  IDesignSourceEntity,
  DataSourceEntityType,
} from '../types';
import { fetchEntities, fetchNestedEntities } from '../api';
import { IDesignerFilter } from '../../../types/IDesignerFilter';
import { DataSourceEntityField } from '../../../api/model/schemas/DataSourceEntityField';
import { LinkFields as LinkFieldsApi } from '../../../api/model/schemas/LinkFields';
import { Link as LinkSchema } from '../../../api/model/schemas/Link';
import { DataSourceEntity } from '../../../api/model/schemas/DataSourceEntity';
import { GenerateLinksJob } from '../../../api/model/schemas/GenerateLinksJob';
import { normalizeById } from '../../../utils/normalizeEntities';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { IColumnDataType } from '../../../types/IColumn';
import { createTypedAction } from '../../../../shared/utils/actions';
import { JobStatus } from '../../../api/model/schemas/JobStatus';
import { ApplicationState } from '../../../store/types';
import { getCurrentUserData } from '../../User/selectors';

export const fetchDesignDataSources = createAsyncThunk(
  'DESIGNER/INGESTION/FETCH_PARENT_SOURCES',
  async (parentId: string) => {
    const { data: responseData } = await api.fetchDataSources(parentId)();

    return responseData.data;
  }
);

export const linkUpdated = createTypedAction<LinkSchema>()(
  'DESIGNER/INGESTION/LINK_UPDATED'
);

type ExpandedLinkFields = LinkFieldsApi & {
  from: DataSourceEntityField & {
    parent: DataSourceEntity;
  };
  to: DataSourceEntityField & {
    parent: DataSourceEntity;
  };
};

type EntityFieldsAccumulator = Map<string, DataSourceEntityField>;
type EntitiesAccumulator = Map<string, DataSourceEntity>;

type FetchDesignLinksParams = {
  dataSourceId: string;
  fetchLinksRequestODataQuery?: string;
};

export const fetchDesignLinks = createAsyncThunk(
  'DESIGNER/INGESTION/FETCH_LINKS',
  async ({
    dataSourceId,
    fetchLinksRequestODataQuery,
  }: FetchDesignLinksParams) => {
    try {
      const {
        data: { data: links },
      } = await api.fetchLinks(dataSourceId)(
        fetchLinksRequestODataQuery
          ? { filter: fetchLinksRequestODataQuery }
          : undefined
      );

      const {
        data: { data: expandedLinkFields },
      } = await fetchEntities<ApiV4ResponseWrapper<ExpandedLinkFields[]>>(
        TYPE_IDS.LinkFields
      )({
        filter: `to/parent/parentId eq ${dataSourceId} and from/parent/parentId eq ${dataSourceId}`,
        expand: 'to($expand=Parent),from($expand=Parent),parent',
      });

      const plainLinkFields = expandedLinkFields.map((linkField) =>
        omit(linkField, ['from', 'to'])
      );
      const parsedLinksFields = normalizeById(
        plainLinkFields as any as LinkFieldsApi[]
      );
      const parsedLinks = normalizeById(links);

      const uniqueEntityFields =
        expandedLinkFields.reduce<EntityFieldsAccumulator>(
          (accumulator, { from, to }) => {
            const plainFromField = omit(from, 'parent');
            const plainToField = omit(to, 'parent');
            accumulator.set(plainFromField.id, plainFromField);
            accumulator.set(plainToField.id, plainToField);

            return accumulator;
          },
          new Map()
        );

      const uniqueEntities = expandedLinkFields.reduce<EntitiesAccumulator>(
        (
          accumulator,
          { from: { parent: fromParent }, to: { parent: toParent } }
        ) => {
          accumulator.set(fromParent.id, fromParent);
          accumulator.set(toParent.id, toParent);

          return accumulator;
        },
        new Map()
      );

      const parsedEntityFields: Record<string, DataSourceEntityField> =
        Object.fromEntries(uniqueEntityFields);
      const parsedEntities: Record<string, DataSourceEntity> =
        Object.fromEntries(uniqueEntities);

      return {
        parsedEntities,
        parsedEntityFields,
        parsedLinks,
        parsedLinksFields,
      };
    } catch (e) {
      console.error(e);
    }

    return {
      parsedEntities: {},
      parsedEntityFields: {},
      parsedLinks: {},
      parsedLinksFields: {},
    };
  }
);

type ExpandedFieldFilter = IDesignerFilter & {
  parent: IDesignSourceEntityField & {
    parent: IDesignSourceEntity;
  };
};

export const fetchAllFiltersByLogic = createAsyncThunk(
  'DESIGNER/INGESTION/FETCH_LOGIC_FILTERS',
  async (dataSourceId: string) => {
    const {
      data: { data: expandedFilters },
    } = await fetchEntities<ApiV4ResponseWrapper<ExpandedFieldFilter[]>>(
      TYPE_IDS.SourceEntityFieldFilter
    )({
      filter: `parent/parent/parentId eq ${dataSourceId}`,
      expand: 'parent($expand=parent)',
    });

    const output: IDesignerFilter[] = expandedFilters.map((filter) => ({
      ...filter,
      item: {
        parentEntityName: filter.parent.parent.name,
        dataType: filter.parent.dataType as any as IColumnDataType,
        ...omit(filter.parent, ['parent', 'dataType']),
      },
    }));

    return output;
  }
);

export const setLoadingSources = createAction<
  boolean,
  'DESIGNER/INGESTION/SET_LOADING_SOURCES'
>('DESIGNER/INGESTION/SET_LOADING_SOURCES');
export const setSelectedSourceId = createAction<
  string | null,
  'DESIGNER/INGESTION/SET_SELECTED_SOURCE_ID'
>('DESIGNER/INGESTION/SET_SELECTED_SOURCE_ID');
export const sourceDeletedSuccess = createAction<
  string,
  'DESIGNER/INGESTION/SOURCE_DELETED_SUCCESS'
>('DESIGNER/INGESTION/SOURCE_DELETED_SUCCESS');

type ExpandedFields = IDesignSourceEntityField & {
  parent: IDesignSourceEntity;
};
export const fetchIngestedOrPendingDataSourceFields = createAsyncThunk(
  'DESIGNER/INGESTION/FETCH_SOURCE_ENTITY_FIELDS',
  async (dataSourceId: string) => {
    const {
      data: { data: expandedFields },
    } = await fetchEntities<ApiV4ResponseWrapper<ExpandedFields[]>>(
      TYPE_IDS.SourceEntityField
    )({
      filter: `parent/parentId eq ${dataSourceId} and ingestionStatus in ('${EntityIngestionStatus.PendingProcess}', '${EntityIngestionStatus.Processed}')`,
      expand: 'parent',
    });

    const entities = uniqBy(
      expandedFields.map((field) => field.parent),
      'id'
    );
    const entityFields: UIEnhancedDesignSourceEntityField[] =
      expandedFields.map((field) => ({
        ...omit(field, 'parent'),
        parentEntityName: field.parent.name,
      }));

    return { entities, entityFields };
  }
);

export const fetchSelectedEntitiesCount = createAsyncThunk(
  'DESIGNER/INGESTION/FETCH_SOURCE_ENTITY_FIELDS_IN_LINKS_VIEW',
  async (dataSourceId: string) => {
    const {
      data: { data: expandedFields },
    } = await fetchEntities<ApiV4ResponseWrapper<ExpandedFields[]>>(
      TYPE_IDS.SourceEntityField
    )({
      filter: `parent/parentId eq ${dataSourceId} and (ingestionStatus eq '${EntityIngestionStatus.Processed}' or ingestionStatus eq '${EntityIngestionStatus.PendingProcess}')`,
      expand: 'parent',
    });

    const entities = uniqBy(
      expandedFields.map((field) => field.parent),
      'id'
    );

    return entities?.length || 0;
  }
);

export const linksDiscoveryIntent = createAction<
  { dataSourceId: string },
  'DESIGNER/LINKS_DISCOVERY_INTENT'
>('DESIGNER/LINKS_DISCOVERY_INTENT');

export const setLinksDiscoveryInProgress = createAction<
  boolean,
  'DESIGNER/SET_LINKS_DISCOVERY_INPROGRESS'
>('DESIGNER/SET_LINKS_DISCOVERY_INPROGRESS');

export const checkIfThereIsLinkAnalysisInProgress = createAsyncThunk(
  'DESIGNER/INGESTION/CHECK_IF_THERE_IS_ACTIVE_LINKS_DISCOVERY',
  async (sourceId: string, { dispatch, getState }) => {
    const state = getState() as ApplicationState;
    const user = getCurrentUserData(state);

    const {
      data: { data },
    } = await fetchNestedEntities<ApiV4ResponseWrapper<GenerateLinksJob[]>>({
      typeId: TYPE_IDS.GenerateLinksJob,
    })(sourceId)({
      top: 1,
      orderBy: 'lastChangedWhen desc',
    });

    const latestJob = data[0];

    if (!latestJob) return;
    if (latestJob.lastChangedById !== user.id) return;

    const isInProgress = [JobStatus.InProgress, JobStatus.Queued].includes(
      latestJob.status
    );

    if (isInProgress) {
      dispatch(setLinksDiscoveryInProgress(isInProgress));
    }
  }
);

export const fetchDataSourceTransforms = createAsyncThunk(
  'DESIGNER/INGESTION/FETCH_DATA_SOURCE_TRANSFORMS',
  async (sourceId: string) => {
    const {
      data: { data: responseData },
    } = await api.fetchEntitiesForSource(sourceId)({
      filter: `type in ('${DataSourceEntityType.Transformation}', '${DataSourceEntityType.StreamOutput}')`,
    });

    return responseData;
  }
);

export const dataSourceEntityFieldClicked = createAction<
  { field: IDesignSourceEntityField; reloadFields: () => Promise<void> },
  'DESIGNER/INGESTION/DATA_SOURCE_FIELD_CLICKED'
>('DESIGNER/INGESTION/DATA_SOURCE_FIELD_CLICKED');
export const setConfiguringDataSource = createAction<
  boolean,
  'DESIGNER/INGESTION/SET_CONFIGURING_DATA_SOURCE'
>('DESIGNER/INGESTION/SET_CONFIGURING_DATA_SOURCE');
export const setIsIngestingDataSource = createAction<
  boolean,
  'DESIGNER/INGESTION/SET_IS_INGESTING_DATA_SOURCE'
>('DESIGNER/INGESTION/SET_IS_INGESTING_DATA_SOURCE');
export const triggerIngestionJob = createAction<
  {
    successCallback: () => void;
  },
  'DESIGNER/INGESTION/RUN_INGESTION_JOB'
>('DESIGNER/INGESTION/RUN_INGESTION_JOB');
export const configureDataSourceIntent = createAction<
  {
    dataSourceId: string;
    historyGoBack: () => void;
    reloadFields: boolean;
  },
  'DESIGNER/INGESTION/CONFIGURE_DATASOURCE'
>('DESIGNER/INGESTION/CONFIGURE_DATASOURCE');

export const configureSourceEntityIntent = createAction<
  {
    entityId: string;
    onQueued: () => void;
    onEnd: () => void;
    onStart: () => void;
  },
  'DESIGNER/INGESTION/CONFIGURE_DATASOURCE_ENTITY'
>('DESIGNER/INGESTION/CONFIGURE_DATASOURCE_ENTITY');

export const setIsLoadingFilters = createAction<
  boolean,
  'DESIGNER/INGESTION/SET_IS_LOADING_FILTERS'
>('DESIGNER/INGESTION/SET_IS_LOADING_FILTERS');
export const createEntityFieldIngestionFilterIntent = createAction<
  {
    fieldId: string;
    newFilterPayload: any;
  },
  'DESIGNER/INGESTION/CREATE_ENTITY_FIELD_FILTER'
>('DESIGNER/INGESTION/CREATE_ENTITY_FIELD_FILTER');

export const setSelectedLinkFieldsId = createAction<string | null>(
  'DESIGNER/INGESTION/SET_SELECTED_LINK_FIELDS_ID'
);
export const setSelectedEmptyLinkId = createAction<string | null>(
  'DESIGNER/INGESTION/SET_SELECTED_EMPTY_LINK_ID'
);
export const editEntityFieldIngestionFilterIntent = createAction<
  {
    fieldId: string;
    editedFilter: Partial<IDesignerFilter>;
  },
  'DESIGNER/INGESTION/EDIT_ENTITY_FIELD_FILTER'
>('DESIGNER/INGESTION/EDIT_ENTITY_FIELD_FILTER');
