// TODO: move into i18n/types
import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../../modules/Designer/types";
import { isResponseTypeIdMatching } from "../../utils";
import { TypeIds } from "../../../../model/schemas";
import { translateApiName } from "../../../../../config/i18n/utils";
import { i18nDataTypeKey, i18nFormatTypeKey, i18nProcessedDataTypeKey, i18nProcessedFormatTypeKey, i18nDefaultAggregationKey } from "../../constants";
import { TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP } from "../../../../constants";
import { StreamField } from "../../../../model/schemas/StreamField";

export const isStreamFieldResponse = (
  response: AxiosResponse<ApiV4ResponseWrapper<unknown>>
): response is AxiosResponse<ApiV4ResponseWrapper<StreamField[]>> =>
  isResponseTypeIdMatching(response, TypeIds.StreamField);

export const mutablyParseStreamFields = (response: AxiosResponse<ApiV4ResponseWrapper<StreamField[]>>) => {
  response.data.data = response.data.data.map(field => {
    return {
      ...field,
      [i18nDataTypeKey]: translateApiName(field.$typeId, field.dataType),
      [i18nFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[field.dataType] ?? field.$typeId,
        String(field.formatType),
      ),
      [i18nProcessedDataTypeKey]: translateApiName(field.$typeId, field.processedDataType),
      [i18nProcessedFormatTypeKey]: translateApiName(
        TYPE_PROPERTY_PROPERTY_INTERACTION_TYPE_ID_MAP[field.processedDataType] ?? field.$typeId,
        String(field.processedFormatType),
      ),
      [i18nDefaultAggregationKey]: translateApiName(field.$typeId, field.defaultAggregation),
    };
  });

  return response;
}
