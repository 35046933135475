import { AxiosResponse } from "axios";
import { ApiV4ResponseWrapper } from "../../../../modules/Designer/types";
import { isDataSourcesResponse, mutablyParseDataSources } from "./types/DataSource";
import { isCalculationsResponse, mutablyParseCalculations } from "./types/Calculation";
import { isHubsResponse, mutablyParseHubs } from "./types/Hub";
import { isStreamCalculationsResponse, mutablyParseStreamCalculations } from "./types/StreamCalculation";
import { isStreamFieldResponse, mutablyParseStreamFields } from "./types/StreamField";
import { isStreamGroupAssetResponse, mutablyParseStreamGroupAssets } from "./types/StreamGroupAsset";
import { isStreamHubResponse, mutablyParseStreamHubs } from "./types/StreamHub";
import { isDataSourceEntityFieldsResponse, parseDataSourceEntityField } from "../types/DataSourceEntityField";
import { mutablyParseResponseDataArray } from "../utils";

export const mutablyParseChildrenResponse = (response: AxiosResponse<ApiV4ResponseWrapper<unknown>>) => {
  if (isCalculationsResponse(response)) return mutablyParseCalculations(response);
  if (isDataSourceEntityFieldsResponse(response)) return mutablyParseResponseDataArray(response, parseDataSourceEntityField);
  if (isDataSourcesResponse(response)) return mutablyParseDataSources(response);
  if (isHubsResponse(response)) return mutablyParseHubs(response);
  if (isStreamCalculationsResponse(response)) return mutablyParseStreamCalculations(response);
  if (isStreamFieldResponse(response)) return mutablyParseStreamFields(response);
  if (isStreamGroupAssetResponse(response)) return mutablyParseStreamGroupAssets(response);
  if (isStreamHubResponse(response)) return mutablyParseStreamHubs(response);

  return response;
}
