/* eslint-disable default-case */
import {
  createNestedEntity,
  deleteEntity,
  fetchEntities as fetchApiEntities,
  fetchNestedEntities,
  patchEntity,
} from '../Designer/api';
import { TYPE_IDS } from '../../constants/apiV4TypeIds';

import {
  ApiV4ResponseWrapper,
  ICalculation,
  IDesignSource,
  IDesignSourceEntity,
  IDesignSourceEntityField,
  IHub,
  IStream,
  IStreamField,
} from '../Designer/types';
import { IDesignerFilter } from '../../types/IDesignerFilter';
import { StreamCalculation } from '../../api/model/schemas/StreamCalculation';
import { StreamAssetType } from './types';
import { Group } from '../../api/model/schemas/Group';
import { GroupAsset } from '../../api/model/schemas/GroupAsset';
import { StreamGroupAsset } from '../../api/model/schemas/StreamGroupAsset';
import { StreamLink } from '../../api/model/schemas/StreamLink';
import { StreamHub } from '../../api/model/schemas/StreamHub';
import { MachineLearning } from '../../api/model/schemas/MachineLearning';
import { StreamMachineLearning } from '../../api/model/schemas/StreamMachineLearning';

export const fetchStreams = fetchApiEntities<ApiV4ResponseWrapper<IStream[]>>(
  TYPE_IDS.Stream
);

export const fetchCalculations = fetchNestedEntities<
  ApiV4ResponseWrapper<ICalculation[]>
>({
  typeId: TYPE_IDS.Calculation,
});

export const fetchStreamCalculations = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamCalculation[]>
>({
  typeId: TYPE_IDS.StreamCalculation,
});

export const fetchHubs = fetchNestedEntities<ApiV4ResponseWrapper<IHub[]>>({
  typeId: TYPE_IDS.Hub,
});

export const fetchGroups = fetchNestedEntities<ApiV4ResponseWrapper<Group[]>>({
  typeId: TYPE_IDS.Group,
});
export const fetchGroupAssets = fetchNestedEntities<
  ApiV4ResponseWrapper<GroupAsset[]>
>({
  typeId: TYPE_IDS.Group,
});
export const fetchMachineLearning = fetchNestedEntities<
  ApiV4ResponseWrapper<MachineLearning[]>
>({
  typeId: TYPE_IDS.MachineLearning,
});
export const fetchStreamMachineLearning = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamMachineLearning[]>
>({
  typeId: TYPE_IDS.StreamMachineLearning,
});
export const fetchStreamLookups = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamMachineLearning[]>
>({
  typeId: TYPE_IDS.StreamLookup,
});

export const fetchStreamGroupsAssets = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamGroupAsset[]>
>({
  typeId: TYPE_IDS.StreamGroupAsset,
});
export const fetchStreamHubs = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamHub[]>
>({
  typeId: TYPE_IDS.StreamHub,
});

export const fetchStreamFields = fetchNestedEntities<
  ApiV4ResponseWrapper<IStreamField[]>
>({
  typeId: TYPE_IDS.StreamField,
});

export const fetchStreamLinks = fetchNestedEntities<
  ApiV4ResponseWrapper<StreamLink[]>
>({
  typeId: TYPE_IDS.StreamLink,
});

export const fetchDataSources = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSource[]>
>({
  typeId: TYPE_IDS.DataSource,
});

export const fetchEntities = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSourceEntity[]>
>({
  typeId: TYPE_IDS.SourceEntity,
});

export const fetchML = fetchNestedEntities<
  ApiV4ResponseWrapper<MachineLearning[]>
>({
  typeId: TYPE_IDS.MachineLearning,
});

export const fetchEntityFields = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignSourceEntityField[]>
>({
  typeId: TYPE_IDS.SourceEntityField,
});

export const fetchStreamFieldsFilters = fetchNestedEntities<
  ApiV4ResponseWrapper<IDesignerFilter[]>
>({
  typeId: TYPE_IDS.StreamFieldFilter,
});

type ProcessStreamArgs = {
  streamId: string;
  ingestBeforeProcessing: boolean;
};
export const processStream = ({
  streamId,
  ingestBeforeProcessing,
}: ProcessStreamArgs) =>
  createNestedEntity<any>({
    typeId: TYPE_IDS.ProcessStreamJob,
    parentEntityId: streamId,
  })(
    ingestBeforeProcessing
      ? {
          dataSourceOperation: 'Update',
          notAvailableHandling: 'Fail',
        }
      : { notAvailableHandling: 'Fail' }
  );

export const removeStreamField = (streamFieldId: string) =>
  deleteEntity(TYPE_IDS.StreamField)(streamFieldId);

export const removeStreamGroupAsset = (streamGroupId: string) =>
  deleteEntity(TYPE_IDS.StreamGroupAsset)(streamGroupId);

export const removeStreamHub = (streamHubId: string) =>
  deleteEntity(TYPE_IDS.StreamHub)(streamHubId);

export const removeStreamMachineLearning = (streamMlId: string) =>
  deleteEntity(TYPE_IDS.StreamMachineLearning)(streamMlId);

export const createStreamField = ({
  streamId,
  entity,
}: {
  streamId: string;
  entity: {
    sourceFieldId: string;
    $sourceFieldTypeId: string;
  };
}) =>
  createNestedEntity({
    typeId: TYPE_IDS.StreamField,
    parentEntityId: streamId,
  })(entity);

export const createStreamGroup = ({
  streamId,
  entity,
}: {
  streamId: string;
  entity: {
    groupId: string;
  };
}) =>
  createNestedEntity({
    typeId: TYPE_IDS.StreamGroupAsset,
    parentEntityId: streamId,
  })(entity);
export const createStreamHub = ({
  streamId,
  entity,
}: {
  streamId: string;
  entity: {
    hubId: string;
  };
}) =>
  createNestedEntity({
    typeId: TYPE_IDS.StreamHub,
    parentEntityId: streamId,
  })(entity);

export const createStreamCalculation = ({
  streamId,
  entity,
}: {
  streamId: string;
  entity: {
    calculationId: string;
  };
}) =>
  createNestedEntity({
    typeId: TYPE_IDS.StreamCalculation,
    parentEntityId: streamId,
  })(entity);

export const createStreamMachineLearning = ({
  streamId,
  entity,
}: {
  streamId: string;
  entity: {
    // TO DO Verify
    machineLearningId: string;
  };
}) =>
  createNestedEntity({
    typeId: TYPE_IDS.StreamMachineLearning,
    parentEntityId: streamId,
  })(entity);

export const removeStreamCalculation = (streamCalculationId: string) =>
  deleteEntity(TYPE_IDS.StreamCalculation)(streamCalculationId);

export const createStreamLink = ({
  streamId,
  entity,
}: {
  streamId: string;
  entity: {
    linkId: string;
  };
}) =>
  createNestedEntity({
    typeId: TYPE_IDS.StreamLink,
    parentEntityId: streamId,
  })(entity);

export const removeStreamLink = (streamLinkId: string) =>
  deleteEntity(TYPE_IDS.StreamLink)(streamLinkId);

export const createStreamAsset = ({
  assetType,
  entityId,
  streamId,
}: {
  assetType: StreamAssetType;
  streamId: string;
  entityId: string;
}) => {
  switch (assetType) {
    case StreamAssetType.Calculation:
      return createStreamCalculation({
        streamId,
        entity: {
          calculationId: entityId,
        },
      });
    case StreamAssetType.Field:
      return createStreamField({
        streamId,
        entity: {
          $sourceFieldTypeId: TYPE_IDS.StreamField,
          sourceFieldId: entityId,
        },
      });
    case StreamAssetType.Group:
      return createStreamGroup({
        streamId,
        entity: {
          groupId: entityId,
        },
      });
    case StreamAssetType.Link:
      return createStreamLink({
        streamId,
        entity: {
          linkId: entityId,
        },
      });
    case StreamAssetType.Hub:
      return createStreamHub({
        streamId,
        entity: {
          hubId: entityId,
        },
      });
    case StreamAssetType.MachineLearning:
      return createStreamMachineLearning({
        streamId,
        entity: {
          machineLearningId: entityId,
        },
      });
  }
};

export const removeStreamAsset = ({
  assetType,
  entityId,
}: {
  assetType: StreamAssetType;
  entityId: string;
}) => {
  switch (assetType) {
    case StreamAssetType.Calculation:
      return removeStreamCalculation(entityId);
    case StreamAssetType.Field:
      return removeStreamField(entityId);
    case StreamAssetType.Group:
      return removeStreamGroupAsset(entityId);
    case StreamAssetType.Link:
      return removeStreamLink(entityId);
    case StreamAssetType.Hub:
      return removeStreamHub(entityId);
    case StreamAssetType.MachineLearning:
      return removeStreamMachineLearning(entityId);
  }
};

export const createStreamFieldFilter = ({
  streamFieldId,
  filter,
}: {
  streamFieldId: string;
  filter: Partial<IDesignerFilter>;
}) =>
  createNestedEntity({
    parentEntityId: streamFieldId,
    typeId: TYPE_IDS.StreamFieldFilter,
  })(filter);

export const editStreamFieldFilter = (
  updatedFilter: Partial<IDesignerFilter>
) => patchEntity<IDesignerFilter>(TYPE_IDS.StreamFieldFilter)(updatedFilter);
