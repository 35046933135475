import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pickBy from 'lodash/pickBy';
import ItemsListShimmer from '../../../../../components/ItemsListShimmer';
import {
  checkIfThereIsLinkAnalysisInProgress,
  fetchDesignLinks,
  fetchSelectedEntitiesCount,
} from '../../actions';
import {
  getIsLoadingLinks,
  getLinkedEntities,
  getLinksFields,
  getLinkedEntityFields,
  getIsLoadingDataSourceFields,
  getSelectedEntityCount,
  getAllLinks,
  getIsSmartAnalysisLinksInProgress,
} from '../../selectors';
import { calculateGroupId } from '../../components/LinkGroup/LinkGroup';
import {
  FILTER_TO_STATUS,
  LinksViewControlFilter,
  tutorialTileConfig,
  TUTORIAL_TILE_KEY,
} from './constants';
import { useTutorialTile } from '../../../../../shared/tutorial-tiles/hooks/useTutorialTile';
import { getSearchQuery } from '../../../../Search/selectors';
import useSortControl from '../../../../../hooks/useSortControl';
import { DefinedSortTypes } from '../../../../App/types';
import { EmptyLinks } from './EmptyLinks';
import { getSelectedSource } from '../../../Catalog/selectors';
import {
  groupLinkFieldsByEntities,
  LinkFieldsGroupedByEntities,
  SortedGroupedLinkFields,
  sortGroupedLinkFields,
} from './utils';
import { FromToGroup } from './components/FromToGroup';
import { getSelectionType } from '../../../../App/selectors';
import { defaultSelection, selectionItems } from './components/SortControl';

const Links = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const linkedEntities = useSelector(getLinkedEntities);
  const linkedEntityFields = useSelector(getLinkedEntityFields);
  const links = useSelector(getAllLinks);
  const linksFields = useSelector(getLinksFields);
  const isLoadingLinks = useSelector(getIsLoadingLinks);
  const isLoadingFields = useSelector(getIsLoadingDataSourceFields);
  const sourceData = useSelector(getSelectedSource);
  const selectedEntityCount = useSelector(getSelectedEntityCount);
  const searchQuery = useSelector(getSearchQuery);
  const { currentSort } = useSortControl();
  const isSmartAnalysisLinksInProgress = useSelector(
    getIsSmartAnalysisLinksInProgress
  );
  // NOTE: doing type assertion cast because the selection type in the store is not generic
  // but we're modifying it with values from `LinksViewControlFilter` when in "designer – catalog – links"
  const selectionType = useSelector(
    getSelectionType
  ) as unknown as LinksViewControlFilter;

  const [openGroupId, setOpenGroupId] = React.useState<string>(null);

  // DERIVED STATE
  const linksFieldsCount = Object.keys(linksFields).length;
  const sourceId = sourceData?.id;
  const tutorialTileRequirements = React.useMemo(
    () => ({
      'tutorialTiles:designer:ingest:links:cta:noLinksRequired':
        selectedEntityCount < 2,
    }),
    [selectedEntityCount]
  );

  const isLoading =
    isLoadingLinks || isLoadingFields || isSmartAnalysisLinksInProgress;

  // NOTE: since by default the app starts with `SelectionType.all` we need to find the true selection first
  const parsedSelection = React.useMemo(
    () =>
      (
        selectionItems.find((item) => item.key === selectionType) ??
        defaultSelection
      ).key,
    [selectionType]
  );

  const filteredLinkFields = React.useMemo(
    () =>
      pickBy(linksFields, (linkFields) => {
        const link = links[linkFields.parentId];
        const filterByStatus = FILTER_TO_STATUS[parsedSelection];

        return filterByStatus.includes(link?.status);
      }),
    [links, linksFields, parsedSelection]
  );

  // iterates over all linked fields and prepares a nested map of ids used to identify the assets:
  // fromEntityId -> toEntityId -> [linkFieldsId, ...]
  const linkFieldsGroupedByEntities: LinkFieldsGroupedByEntities =
    React.useMemo(
      () =>
        groupLinkFieldsByEntities({
          linkedEntityFields,
          linksFields: filteredLinkFields,
        }),
      [filteredLinkFields, linkedEntityFields]
    );

  // a "ready for iteration" nested arrays of grouped link fields properly ordered for rendering
  const sortedLinkFieldsGroups: SortedGroupedLinkFields = React.useMemo(
    () =>
      sortGroupedLinkFields({
        groupedLinkFields: linkFieldsGroupedByEntities,
        linkedEntities,
        linkedEntityFields,
        linksFields,
      }),
    [
      linkedEntities,
      linkedEntityFields,
      linkFieldsGroupedByEntities,
      linksFields,
    ]
  );

  // EFFECTS
  const fetchLinks = React.useCallback(() => {
    if (sourceId) dispatch(fetchDesignLinks({ dataSourceId: sourceId }));
  }, [dispatch, sourceId]);
  React.useEffect(fetchLinks, [fetchLinks]);

  const checkAnalisisStatus = React.useCallback(() => {
    if (sourceId) dispatch(checkIfThereIsLinkAnalysisInProgress(sourceId));
  }, [dispatch, sourceId]);
  React.useEffect(checkAnalisisStatus, [checkAnalisisStatus]);

  const fetchSelectedEntityCount = React.useCallback(() => {
    if (sourceId) dispatch(fetchSelectedEntitiesCount(sourceId));
  }, [dispatch, sourceId]);
  React.useEffect(fetchSelectedEntityCount, [fetchSelectedEntityCount]);

  // PARTS
  const tutorialTile = useTutorialTile({
    ...tutorialTileConfig,
    isDisplayForced: linksFieldsCount === 0,
    key: TUTORIAL_TILE_KEY,
    name: TUTORIAL_TILE_KEY,
    startButtonStates: tutorialTileRequirements,
  });

  // RENDER
  if (isLoading) {
    return <ItemsListShimmer />;
  }

  if (tutorialTile) return tutorialTile;

  if (!isLoading && linksFieldsCount === 0) {
    return null;
  }

  if (currentSort === DefinedSortTypes.EmptyLinks) {
    // only to handle edge-cases - a quick solution
    return <EmptyLinks sourceData={sourceData} />;
  }

  // NOTE: since generating groups feels a bit computationally expensive, we're breaking the usual flow for PARTS/RENDER
  // and calculating them here
  const groups = sortedLinkFieldsGroups.flatMap(
    ([fromEntityId, targetEntityEntries]) =>
      targetEntityEntries.map(([toEntityId, linkFieldsIds]) => (
        <FromToGroup
          {...{
            linkFieldsIds,
            linksFields,
            openGroupId,
            searchQuery,
            setOpenGroupId,
            sourceData,
          }}
          from={linkedEntities[fromEntityId]}
          key={calculateGroupId(fromEntityId, toEntityId)}
          to={linkedEntities[toEntityId]}
        />
      ))
  );

  return <section>{groups}</section>;
};

export default Links;
