import { createReducer } from '@reduxjs/toolkit';
import { IDesignTag, ITypeSectionProperty } from '../types';
import { CatalogEntry } from './types';

import {
  fetchDesignTags, setNewTagInputOpen, setAreTagsLoading, fetchCatalogDefinition, toggleCatalogEntrySelection, toggleCatalogPropertySelection,
} from './actions';

export interface DesignerMetadataState {
  tags: IDesignTag[]
  catalogDefinition: CatalogEntry[]
  catalogEntrySelection: CatalogEntry['id'] | null,
  catalogPropertySelection: ITypeSectionProperty['id'] | null,
  isLoading: boolean,
  isNewTagInputOpen: boolean,
}

export const initialState:DesignerMetadataState = {
  tags: [],
  catalogDefinition: [],
  catalogEntrySelection: null,
  catalogPropertySelection: null,
  isLoading: false,
  isNewTagInputOpen: false,
};

const reducer = createReducer(initialState, (builder) => builder
  .addCase(setAreTagsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  }))
  .addCase(setNewTagInputOpen, (state, { payload }) => ({
    ...state,
    isNewTagInputOpen: payload,
  }))
  .addCase(fetchDesignTags.pending, (state) => ({
    ...state,
    isLoading: true,
  }))
  .addCase(fetchDesignTags.rejected, (state) => ({
    ...state,
    isLoading: false,
  }))
  .addCase(fetchDesignTags.fulfilled, (state, action) => ({
    ...state,
    isLoading: false,
    tags: action.payload || [],
  }))
  .addCase(fetchCatalogDefinition.pending, (state) => ({
    ...state,
    isLoading: true,
  }))
  .addCase(fetchCatalogDefinition.rejected, (state) => ({
    ...state,
    isLoading: false,
  }))
  .addCase(fetchCatalogDefinition.fulfilled, (state, { payload }) => ({
    ...state,
    isLoading: false,
    catalogDefinition: payload,
  }))
  .addCase(toggleCatalogEntrySelection, (state, { payload }) => ({
    ...state,
    catalogEntrySelection: state.catalogEntrySelection === payload ? null : payload,
  }))
  .addCase(toggleCatalogPropertySelection, (state, { payload }) => ({
    ...state,
    catalogPropertySelection: state.catalogPropertySelection === payload ? null : payload,
  }))
);

export default reducer;
