import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { HeaderBarButton } from '../../../../../../components/ui';
import { routes } from '../../../../../../constants/routes';
import { toggleForceOpenAllCards } from '../../actions';
import {
  getAreCardsForceOpen,
  getHasAnyOutputData,
  getOutputViewType,
} from '../../selectors';
import { OutputViewType } from '../../types';

const CardHeaderControls = () => {
  // HOOKS
  const dispatch = useDispatch();

  // STATE
  const viewType = useSelector(getOutputViewType);
  const areCardsForceOpen = useSelector(getAreCardsForceOpen);
  const hasAnyOutputData = useSelector(getHasAnyOutputData);

  // CALLBACKS
  const handleToggleCard = React.useCallback(() => {
    dispatch(toggleForceOpenAllCards());
  }, []);

  // RENDER
  if (viewType !== OutputViewType.kanban) return null;

  return (
    <Route path={routes.streamer.dataMode.output}>
      <HeaderBarButton
        disabled={!hasAnyOutputData}
        iconProps={{
          iconName: areCardsForceOpen ? 'CloseAllCards' : 'OpenAllCards',
        }}
        onClick={handleToggleCard}
        shouldHideOnDisabled
      />
    </Route>
  );
};

export default CardHeaderControls;
