export enum ComparisonType {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  Contains = 'Contains',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  DoesNotContain = 'DoesNotContain',
  DoesNotStartWith = 'DoesNotStartWith',
  DoesNotEndWith = 'DoesNotEndWith',
}
