/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Text,
  CommandButton,
  Icon,
  getTheme,
  Link,
  Stack,
} from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { Card, CardItem, CardSection } from '@uifabric/react-cards';
import moment from 'moment';
import {
  NotificationActivity,
  NotificationSubjectState,
} from '../../../../types/INotification';
import * as styles from './styles';

import { dismissNotification } from '../../actions';
import { TYPE_IDS_TO_NAMES } from '../../../../constants/apiV4TypeIds';
import { UIEnhancedNotification } from '../../types';
import { CopyButton } from '../../../../components/ui';

export interface NotificationCardProps {
  notification: UIEnhancedNotification;
  isPopup?: boolean;
}

const NotificationCardUI: React.FC<NotificationCardProps> = ({
  notification: {
    timeStamp,
    id,
    $subjectTypeId,
    subjectTypeId,
    subjectName,
    subjectId,
    activity,
    state,
    relatedUserName,
    details,
  },
  isPopup,
}) => {
  const { t } = useTranslation('notifications');
  const theme = getTheme();
  const dispatch = useDispatch();

  const [isDetailExpanded, setDetailExpanded] = React.useState(false);

  const handleDismissNotification = async () => {
    dispatch(dismissNotification(id));
  };

  const getNotificationTitle = () => {
    // API changed - but old notification might contain them
    const entityTypeName =
      TYPE_IDS_TO_NAMES[$subjectTypeId] || TYPE_IDS_TO_NAMES[subjectTypeId];

    if (!entityTypeName && activity !== NotificationActivity.Import) {
      // to log it on sentry
      console.error('could find notification text for', {
        subjectTypeId,
        activity,
        state,
      });
      return null;
    }

    return t(`notifications:titles.${activity}-${state}`, {
      entityTypeName: t(`entityTypes:${entityTypeName}`),
    });
  };
  const getNotificationMessage = () => {
    if (
      [
        NotificationActivity.Configure,
        NotificationActivity.Process,
        NotificationActivity.Export,
        NotificationActivity.Import,
        NotificationActivity.Initializing,
      ].includes(activity)
    ) {
      const entityTypeName =
        TYPE_IDS_TO_NAMES[$subjectTypeId] || TYPE_IDS_TO_NAMES[subjectTypeId];

      return t(`notifications:messages.${activity}.${state}`, {
        // support old notifications for a while to not break UI
        assetName: subjectName || subjectId,
        userName: relatedUserName,
        entityTypeName: t(`entityTypes:${entityTypeName}`).toLowerCase(),
      });
    }

    return `${activity} ${state}`;
  };

  const getNotificationIcon = () => {
    if (state === NotificationSubjectState.Complete) {
      return 'Completed';
    }
    if (state === NotificationSubjectState.Failed) {
      return 'Error';
    }

    return 'ProgressRingDots';
  };

  return (
    <Card styles={styles.getCardStyles} horizontal>
      <CardItem fill>
        <Icon iconName={getNotificationIcon()} styles={styles.getIconStyles} />
      </CardItem>

      <CardSection
        styles={styles.cardSectionStyles}
        tokens={{ childrenGap: 8 }}
      >
        <Text styles={styles.titleStyles}>{getNotificationTitle()}</Text>

        <Text styles={styles.messageWrapperStyles(theme)}>
          <p
            style={styles.messageStyles}
            dangerouslySetInnerHTML={{
              // eslint-disable-line react/no-danger
              __html: getNotificationMessage(),
            }}
          />
          {details && !details.snapshotId && (
            <Link
              style={styles.showMoreStyles}
              onClick={() => setDetailExpanded(!isDetailExpanded)}
            >
              {t(isDetailExpanded ? 'errors:showLess' : 'errors:showMore')}
            </Link>
          )}
        </Text>
        {isDetailExpanded && (
          <Text styles={styles.detailsStyles}>
            {JSON.stringify(details, null, 2)}
          </Text>
        )}

        {details && details.snapshotId && (
          <Stack>
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
            >
              <Text styles={styles.messageWrapperStyles(theme)}>
                {t('designImportExport:designSnapshotId')}
              </Text>
              <CopyButton textToCopy={details.snapshotId} />
            </Stack>
            <Text styles={styles.messageWrapperStyles(theme)}>
              <strong>{details.snapshotId}</strong>
            </Text>
          </Stack>
        )}

        {!isPopup && (
          <CommandButton
            text={t('dismiss')}
            styles={styles.dismissButtonStyles(theme)}
            onClick={handleDismissNotification}
          />
        )}

        <Text styles={styles.dateStyles}>
          <Icon iconName="DateTime" style={{ fontSize: '16px' }} />
          {moment.utc(timeStamp).local().fromNow()}
        </Text>
      </CardSection>
    </Card>
  );
};

export default NotificationCardUI;
