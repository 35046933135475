import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderBarButton } from '../../../../../components/ui';
import { triggerSmartCatalog } from '../../actions';
import { getEntityFields, getSelectedSource } from '../../selectors';

export const SmartCatalogButton = () => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(getEntityFields);

  // STATE
  const selectedSource = useSelector(getSelectedSource);

  // DERIVED STATE
  const shouldDisplayUndo = selectedSource?.isBlitsed;

  // CALLBACKS
  const runBlits = React.useCallback(() => {
    dispatch(
      triggerSmartCatalog({
        dataSourceId: selectedSource?.id,
        operation: 'On',
      })
    );
  }, [dispatch, selectedSource?.id]);

  const runUndo = React.useCallback(() => {
    dispatch(
      triggerSmartCatalog({
        dataSourceId: selectedSource?.id,
        operation: 'Reset',
      })
    );
  }, [dispatch, selectedSource?.id]);

  // RENDER
  return (
    <>
      <HeaderBarButton
        tooltip={t('modeBar:catalog:evaluateSmartNames')}
        iconProps={{ iconName: 'SmartCatalogMagicWand' }}
        onClick={runBlits}
        disabled={!selectedSource?.id || isLoading}
        shouldHideOnDisabled
      />
      {shouldDisplayUndo && (
        <HeaderBarButton
          tooltip={t('modeBar:catalog:resetSmartNames')}
          iconProps={{ iconName: 'Undo' }}
          onClick={runUndo}
          disabled={!selectedSource?.id || isLoading}
          shouldHideOnDisabled
        />
      )}
    </>
  );
};
