import { createAction } from '@reduxjs/toolkit';
import { SingleNestedEntityPathParams } from './types';

interface TopLevelEntityDeleteParams {
  schemaId: string;
  entityId: string
  callback: () => void
}

export const deleteTopLevelEntity = createAction<TopLevelEntityDeleteParams, 'DESIGNER/DELETE_TOP_LEVEL_ENTITY'>('DESIGNER/DELETE_TOP_LEVEL_ENTITY');
export const deleteNestedEntity = createAction<SingleNestedEntityPathParams, 'DESIGNER/DELETE_NESTED_ENTITY'>('DESIGNER/DELETE_NESTED_ENTITY');
