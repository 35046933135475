import { IStackStyles } from '@fluentui/react';

export const containerStyles: IStackStyles = {
  root: {
    fontSize: 12,
    selectors: {
      '*': {
        fontSize: 12,
      },
    },
  },
};
