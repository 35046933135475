import React from 'react';

export const dragAndDropWrapperStyles: React.CSSProperties = {
  height: 'auto',
  maxHeight: '100%',
  overflowY: 'auto',
  flex: '1',
  flexDirection: 'column',
  overflow: 'auto',
};
