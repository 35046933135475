import {
  EntityIngestionStatus,
  IDesignSourceEntity,
  IDesignSourceEntityField,
} from '../../../types';
import { IEntity } from './types';

export const getCurrentEntity = (entities: IEntity[], fieldId: string) =>
  entities?.find((entity) =>
    entity.fields.some((field) => field.id === fieldId)
  );

export const filterSelectedEntity = (entities: IEntity[], fieldId: string) => {
  const current = getCurrentEntity(entities, fieldId);
  return entities.filter((entity) => entity?.id !== current?.id);
};

export const generateEntitiesWithFields = (
  entities: IDesignSourceEntity[],
  fields: IDesignSourceEntityField[],
  onlySelectedEntities: boolean = true
): IEntity[] =>
  entities
    .map((entity) => ({
      ...entity,
      fields: fields
        // Filter out not valid fields
        .filter((field) => {
          if (!onlySelectedEntities) {
            return field.parentId === entity.id;
          }

          return (
            field.parentId === entity.id &&
            (field.ingestionStatus === EntityIngestionStatus.Processed ||
              field.ingestionStatus === EntityIngestionStatus.PendingProcess)
          );
        }),
    }))
    // Filter out entities without valid fields
    .filter((entity) => !!entity.fields.length);

export const getDataType = (
  fieldId: string,
  fields: IDesignSourceEntityField[]
) => fields.find((field) => fieldId === field.id)?.dataType;

export const getValidEntitiesAndFields = (
  entities: IEntity[],
  enforcedEntity: string,
  excludedFields: string[]
): IEntity[] => {
  if (!enforcedEntity) return entities;
  const entity = entities.find((e) => e.id === enforcedEntity);
  return [
    {
      ...entity,
      fields: entity.fields.filter(
        (field) => !excludedFields.includes(field.id)
      ),
    },
  ];
};
