import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import FilterCondition from '../../../../components/FilterCondition';
import FiltersForm, {
  formFilterDefault,
} from '../../../../components/FiltersForm';
import { GroupCollapse } from '../../../../components/ui';
import { TYPE_IDS } from '../../../../constants/apiV4TypeIds';
import { ENTITY_FIELD_SLOT_WIDTH_IN_PX } from '../../../../constants/design';
import { routes } from '../../../../constants/routes';
import useCollapseGroups from '../../../../hooks/useCollapseGroups';
import { IDesignerFilter } from '../../../../types/IDesignerFilter';
import { formatValues } from '../../../../utils/formatFilterValues';
import { deleteEntityRoute } from '../../../../utils/routes';
import { compileStreamFiltersToRequest } from '../../../StreamFilters/utils';
import {
  createStreamFieldFilterIntent,
  editStreamFieldFilterIntent,
} from '../../actions';
import { getItemOptions, parseFiltersIntoRenderableGroups } from './utils';
import { adjustComparisonType } from '../../../../utils/adjustFilterComparisonType';
import { getSearchQuery } from '../../../Search/selectors';
import { matchesSearchQuery } from '../../../Search/utils';
import {
  IDesignSourceEntity,
  IDesignSourceEntityField,
  IStreamField,
} from '../../../Designer/types';
import { requestSourceFieldValues } from '../../../Designer/Ingestion/api';

interface Props {
  streamFields: IStreamField[];
  entityFields: IDesignSourceEntityField[];
  entities: IDesignSourceEntity[];
  filters: IDesignerFilter[];
}

// TODO: implement the tutorial tile using the values from './constants'
export const LogicFilters: React.FC<Props> = ({
  entities,
  entityFields,
  streamFields,
  filters,
}) => {
  // DEPS
  const newFilterFormTargetId = 'filter-form-target';

  // STATE
  const [selectedFilterForEdit, setSelectedFilterForEdit] =
    React.useState<IDesignerFilter>(null);
  const searchQuery = useSelector(getSearchQuery);

  // HOOKS
  const { t } = useTranslation();

  const formMethods = useForm<IDesignerFilter>({
    defaultValues: formFilterDefault,
    mode: 'onChange',
  });
  const history = useHistory();

  // HOOKS - REDUX
  const dispatch = useDispatch();

  // DERIVED STATE
  const isEditing = selectedFilterForEdit !== null;
  const calloutTarget = isEditing
    ? `filter-${[selectedFilterForEdit.id]}`
    : newFilterFormTargetId;
  // @ts-ignore
  const calloutWidth = document.querySelector(`#${calloutTarget}`)?.offsetWidth;

  const filteredFilters = React.useMemo(
    () => filters.filter((f) => matchesSearchQuery(searchQuery, f?.item?.name)),
    [filters, searchQuery]
  );

  const groupToRender = React.useMemo(
    () =>
      parseFiltersIntoRenderableGroups({
        entities,
        entityFields,
        filters: filteredFilters,
      }),
    [entities, entityFields, filteredFilters]
  );

  const { isGroupOpen: isEntityOpen, handleOpenGroup: handleOpenEntity } =
    useCollapseGroups({
      scrollOnOpen: false,
      initiallyOpenFirstGroupId:
        groupToRender.length > 0 ? groupToRender[0].entityName : null,
    });

  React.useEffect(() => {
    if (selectedFilterForEdit) {
      formMethods.reset(adjustComparisonType(selectedFilterForEdit));
      history.push(routes.builder.assets.filters.edit);
    }
  }, [selectedFilterForEdit]);

  // CALLBACKS
  const loadItemOptions = React.useCallback(
    async (textInput: string) => {
      const options = getItemOptions({
        streamFields,
        entities,
        entityFields,
        textInput,
      });

      return Promise.resolve(options);
    },
    [streamFields, entities, entityFields]
  );

  // We are never asking for values, always typing them
  // This is a "cheat" to not change underlying component, which has quite complex logic
  const loadValuesOptions = async (
    streamField: IStreamField
  ): Promise<Array<string>> => {
    const { data } = await requestSourceFieldValues(streamField.sourceFieldId);

    const filteredValues = data?.values
      ?.filter((val) => ['string', 'number', 'boolean'].includes(typeof val))
      ?.map((val) => String(val));

    return filteredValues;
  };

  const handleSubmit = React.useCallback(
    (value: IDesignerFilter) => {
      const { item, ...rest } = value;

      if (isEditing) {
        dispatch(
          editStreamFieldFilterIntent({
            relatedStreamField: {
              ...item,
              dataType: item.dataType as any,
            },
            filter: {
              ...compileStreamFiltersToRequest(rest),
              id: selectedFilterForEdit.id,
            },
          })
        );
        setSelectedFilterForEdit(null);
      } else {
        dispatch(
          createStreamFieldFilterIntent({
            relatedStreamField: {
              ...item,
              dataType: item.dataType as any,
            },
            filter: {
              ...compileStreamFiltersToRequest(rest),
              id: undefined,
            },
          })
        );
      }

      history.push(routes.builder.assets.filters.index);
    },
    [history, dispatch, isEditing, selectedFilterForEdit]
  );

  const handleDeleteClick = React.useCallback((filter: IDesignerFilter) => {
    history.push(deleteEntityRoute(TYPE_IDS.StreamFieldFilter, filter.id), {
      goBackPage: routes.builder.assets.filters.index,
      deleteDescription: (
        <Trans
          i18nKey="wizard:deleteNestedEntity"
          values={{
            name: `${filter.item?.name} ${t(
              `filters:${filter.comparison}`
            )} ${formatValues(filter)}`,
            asset: t('entityTypes:Filter'),
          }}
        />
      ),
    });
  }, []);

  const handleCloseForm = React.useCallback(() => {
    formMethods.reset(formFilterDefault);
    setSelectedFilterForEdit(null);
    history.push(routes.builder.assets.filters.index);
  }, [formMethods, history]);

  // RENDER
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...formMethods}>
      <div>
        <Route path={routes.builder.assets.filters.add}>
          <div
            id={newFilterFormTargetId}
            style={{
              width: 'calc(100% - 20px)',
              height: '10px',
              margin: 'auto',
            }}
          />
        </Route>
        {groupToRender.map((group) => (
          <GroupCollapse
            key={`entity_${group.entityName}`}
            groupName={group.entityName}
            groupId={group.entityName}
            onClick={(id) => handleOpenEntity(id)}
            isOpen={isEntityOpen(group.entityName)}
            isSelected={false}
          >
            {group.filters.map((filter) => (
              <FilterCondition
                key={filter.id}
                filter={filter}
                onEdit={() => setSelectedFilterForEdit(filter)}
                onDelete={handleDeleteClick}
                spacing={ENTITY_FIELD_SLOT_WIDTH_IN_PX}
                id={`filter-${filter.id}`}
                isEditing={selectedFilterForEdit?.id === filter.id}
                isSelected={selectedFilterForEdit?.id === filter.id}
                searchQuery={searchQuery}
              />
            ))}
          </GroupCollapse>
        ))}
        <Route
          path={[
            routes.builder.assets.filters.add,
            routes.builder.assets.filters.edit,
          ]}
        >
          <FiltersForm
            hideAggregationPicker
            title={t('filters:Filter')}
            loadValuesOptions={loadValuesOptions}
            loadItemOptions={loadItemOptions}
            onClose={handleCloseForm}
            onSubmit={handleSubmit}
            target={`#${calloutTarget}`}
            calloutWidth={calloutWidth}
          />
        </Route>
      </div>
    </FormProvider>
  );
};
