import {
  IProgressIndicatorStyles,
  IStackStyles,
  mergeStyles,
} from '@fluentui/react';

import { baseColors, BORDER_RADIUS_IN_PX } from '../../theme';

export const stackStyles: IStackStyles = {
  root: {
    padding: '8px',
    maxHeight: '100%',
    flex: 1,
    maxWidth: '100%',
  },
};

export const containerClassname = mergeStyles({
  flex: 1,
  flexBasis: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  borderLeft: `1px solid ${baseColors.inputBorder}`,
  borderRight: `1px solid ${baseColors.inputBorder}`,
  borderBottom: `1px solid ${baseColors.inputBorder}`,
  position: 'relative',
  borderBottomRightRadius: BORDER_RADIUS_IN_PX,
  borderBottomLeftRadius: BORDER_RADIUS_IN_PX,
});

export const streamsContainerClassname = mergeStyles({
  padding: '0',
});

export const designerContainerClassName = mergeStyles({
  borderBottomLeftRadius: '0 !important',
});

export const sectionTitleWrapperClassname = mergeStyles({
  minHeight: '36px',
  position: 'relative',
});

export const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
  root: {
    position: 'relative',
    zIndex: 1,
    left: '0',
    top: 0,
    width: '100%',
    selectors: {
      '&>div': {
        padding: 0,
      },
    },
  },
};
