import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import {
  wasMultiSelectKeyUsed,
  wasToggleInSelectionGroupKeyUsed,
} from './utils';

import EntityField from '../../../../../components/EntityField';
import { createGetStreamerAssetAsRawAsset } from '../../../selectors';
import { StreamerAssetWithUiid } from './types';
import { DraggableAssetSortSettings } from './DraggableAssetSortSettings';
import DraggableAssetAggregationDropdown from './DraggableAssetAggregationDropdown';

interface Props {
  asset: StreamerAssetWithUiid;
  isSelected: boolean;
  isGhosting: boolean;
  toggleSelection: (taskId: string) => void;
  toggleSelectionInGroup: (taskId: string) => void;
  multiSelectTo: (taskId: string) => void;
  index: number;
  hasOrderChanged: boolean;
}

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const primaryButton = 0;

const DraggableAsset = ({
  asset,
  index,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  isGhosting,
  isSelected,
  hasOrderChanged,
}: Props) => {
  // STATE
  const relatedRawAsset = useSelector(createGetStreamerAssetAsRawAsset(asset));

  // CALLBACKS
  const performAction = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(asset.uuid);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(asset.uuid);
      return;
    }

    toggleSelection(asset.uuid);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.defaultPrevented) return;
    // react only on left mouse button click
    if (event.button !== primaryButton) return;

    // we are using the event for selection
    event.preventDefault();

    performAction(event);
  };

  // RENDER
  return (
    <div style={{ position: 'relative' }}>
      <Draggable draggableId={asset.uuid} index={index}>
        {(provided: DraggableProvided) => (
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <EntityField
              data-testid={`dnd-item-${relatedRawAsset.name}`}
              isSelectable
              isSelected={isSelected}
              isOrange={hasOrderChanged}
              isDisabled={isGhosting}
              onClick={handleClick}
              data-index={index}
              name={relatedRawAsset.name}
              slot1={<DraggableAssetAggregationDropdown asset={asset} />}
              slot2={<DraggableAssetSortSettings asset={asset} />}
            />
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default DraggableAsset;
