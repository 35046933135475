import { IColumnAggregationMethods, IColumnDataType } from '../types/IColumn';
import i18n from '../config/i18n';
import { AggregationType } from '../api/model/schemas/AggregationType';

export type IAggregationMethod = IColumnAggregationMethods | 'null';

export const AGGREGATION_METHODS: Record<
  IAggregationMethod,
  IColumnAggregationMethods
> = {
  CountDistinctExcludingNull: 'CountDistinctExcludingNull',
  CountDistinctIncludingNull: 'CountDistinctIncludingNull',
  Count: 'Count',
  Sum: 'Sum',
  Average: 'Average',
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  null: null,
};

export const NUMERIC_AGGREGATION_METHODS = [
  AGGREGATION_METHODS.CountDistinctExcludingNull,
  AGGREGATION_METHODS.CountDistinctIncludingNull,
  AGGREGATION_METHODS.Count,
  AGGREGATION_METHODS.Sum,
  AGGREGATION_METHODS.Minimum,
  AGGREGATION_METHODS.Maximum,
  AGGREGATION_METHODS.Average,
];

export const COLUMN_DATA_TYPES = IColumnDataType;

// this must be a function, to be able to react to the
// language change
export const getAggregations = () => ({
  [AGGREGATION_METHODS.Sum]: {
    label: i18n.t('streams:Sum'),
    method: AGGREGATION_METHODS.Sum,
    icon: 'SynergiesSum',
  },
  [AGGREGATION_METHODS.CountDistinctExcludingNull]: {
    label: i18n.t('streams:Distinct Count (No Blanks)'),
    method: AGGREGATION_METHODS.CountDistinctExcludingNull,
    icon: 'SynergiesDistinctCountNoBlanks',
  },
  [AGGREGATION_METHODS.null]: {
    label: i18n.t('streams:Do Not Summarize'),
    method: AGGREGATION_METHODS.null,
    icon: 'SynergiesDoNotSummarize',
  },
  [AGGREGATION_METHODS.CountDistinctIncludingNull]: {
    label: i18n.t('streams:Distinct Count (With Blanks)'),
    method: AGGREGATION_METHODS.CountDistinctIncludingNull,
    icon: 'SynergiesDistinctCountWithBlanks',
  },
  [AGGREGATION_METHODS.Count]: {
    label: i18n.t('streams:Count'),
    method: AGGREGATION_METHODS.Count,
    icon: 'SynergiesCount',
  },
  [AGGREGATION_METHODS.Average]: {
    label: i18n.t('streams:Average'),
    method: AGGREGATION_METHODS.Average,
    icon: 'SynergiesAverage',
  },
  [AGGREGATION_METHODS.Minimum]: {
    label: i18n.t('streams:Minimum'),
    method: AGGREGATION_METHODS.Minimum,
    icon: 'SynergiesMinimum',
  },
  [AGGREGATION_METHODS.Maximum]: {
    label: i18n.t('streams:Maximum'),
    method: AGGREGATION_METHODS.Maximum,
    icon: 'SynergiesMaximum',
  },
});

// V4 API
export const aggregationButtonMenuConfig = () => [
  {
    name: i18n.t('streams:Average'),
    key: AggregationType.Average,
    iconProps: {
      iconName: 'SynergiesAverage',
    },
  },
  {
    name: i18n.t('streams:Distinct Count (No Blanks)'),
    key: AggregationType.CountDistinctExcludingNull,
    iconProps: {
      iconName: 'SynergiesDistinctCountNoBlanks',
    },
  },
  {
    name: i18n.t('streams:Count'),
    key: AggregationType.Count,
    iconProps: {
      iconName: 'SynergiesCount',
    },
  },
  {
    name: i18n.t('streams:Distinct Count (With Blanks)'),
    key: AggregationType.CountDistinctIncludingNull,
    iconProps: {
      iconName: 'SynergiesDistinctCountWithBlanks',
    },
  },
  {
    name: i18n.t('streams:Do Not Summarize'),
    key: AggregationType.None,
    iconProps: {
      iconName: 'SynergiesDoNotSummarize',
    },
  },
  {
    name: i18n.t('streams:Minimum'),
    key: AggregationType.Minimum,
    iconProps: {
      iconName: 'SynergiesMinimum',
    },
  },
  {
    name: i18n.t('streams:Maximum'),
    key: AggregationType.Maximum,
    iconProps: {
      iconName: 'SynergiesMaximum',
    },
  },
  {
    name: i18n.t('streams:Sum'),
    key: AggregationType.Sum,
    iconProps: {
      iconName: 'SynergiesSum',
    },
  },
];

export const AGGREGATIONS_TO_ICONS: Record<AggregationType, string> =
  aggregationButtonMenuConfig().reduce(
    (accumulator, currentVal) => ({
      ...accumulator,
      [currentVal.key]: currentVal.iconProps.iconName,
    }),
    {} as any
  );

export const getAggregationsToTranslatedName = (): Record<
  AggregationType,
  string
> =>
  aggregationButtonMenuConfig().reduce(
    (accumulator, currentVal) => ({
      ...accumulator,
      [currentVal.key]: currentVal.name,
    }),
    {} as any
  );
