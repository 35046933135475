import { TFunction } from 'react-i18next';
import { GroupTypeBase } from 'react-select';
import { FeatureStatus } from '../../../api/model/schemas/FeatureStatus';
import { TYPE_IDS } from '../../../constants/apiV4TypeIds';
import { Destination, DestinationOption } from './types';

// We mimic the definition ConnectorConfigurationType so we can get adventage
// of the existing behavior in \src\taskpane\components\ui\SelectDropdown\OptionStatus.tsx

export const getDestinationsOptions = (
  t: TFunction
): GroupTypeBase<DestinationOption>[] =>
  [
    {
      label: 'Data Visualization',
      options: [
        {
          label: t(`exportWizard:${Destination['Microsoft Power BI']}`),
          value: Destination['Microsoft Power BI'],
          data: {
            status: FeatureStatus.Stable,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
        {
          label: t(`exportWizard:${Destination.Tableau}`),
          value: Destination.Tableau,
          data: {
            status: FeatureStatus.Stable,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
      ],
    },
    {
      label: 'Files and Databases',
      options: [
        {
          label: t(`exportWizard:${Destination.Database}`),
          value: Destination.Database,
          data: {
            status: FeatureStatus.New,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
        {
          label: t(`exportWizard:${Destination.File}`),
          value: Destination.File,
          data: {
            status: FeatureStatus.New,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
      ],
    },
    {
      label: 'Enterprise Resource Planning',
      options: [
        {
          label: t(`exportWizard:${Destination.FastClose}`),
          value: Destination.FastClose,
          isDisabled: true,
          data: {
            status: FeatureStatus.ComingSoon,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },

        {
          label: t(`exportWizard:${Destination.NextWorld}`),
          value: Destination.NextWorld,
          data: {
            status: FeatureStatus.Preview,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
      ],
    },
    {
      label: 'Planning Solutions',
      options: [
        {
          label: t(`exportWizard:${Destination.Anaplan}`),
          value: Destination.Anaplan,
          data: {
            status: FeatureStatus.Preview,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
        {
          label: t(`exportWizard:${Destination.Salesforce}`),
          value: Destination.Salesforce,
          data: {
            status: FeatureStatus.Preview,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
        {
          label: t(`exportWizard:${Destination.Bizview}`),
          value: Destination.Bizview,
          isDisabled: true,
          data: {
            status: FeatureStatus.ComingSoon,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
        {
          label: t(`exportWizard:${Destination['Workday Adaptive Planning']}`),
          value: Destination['Workday Adaptive Planning'],
          isDisabled: true,
          data: {
            status: FeatureStatus.ComingSoon,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
      ],
    },
    {
      label: 'Streaming Services',
      options: [
        {
          label: t(`exportWizard:${Destination['Eyko Streaming Service']}`),
          value: Destination['Eyko Streaming Service'],
          data: {
            status: FeatureStatus.Stable,
            $typeId: TYPE_IDS.ConnectorConfigurationType,
          },
        },
      ],
    },
  ].sort((a, b) => a.label.localeCompare(b.label));
