import React from 'react';

interface BaseParams {
  url: string;
  onLoad?: () => void;
}
export const useScript = ({ url, onLoad }: BaseParams) => {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    if (onLoad) {
      script.onload = onLoad;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
interface ConditionallyLoadedParams extends BaseParams {
  shouldLoad: boolean;
}
export const useScriptConditionally = ({
  url,
  onLoad,
  shouldLoad,
}: ConditionallyLoadedParams) => {
  React.useEffect(() => {
    if (!shouldLoad) return () => null;

    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    if (onLoad) {
      script.onload = onLoad;
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, shouldLoad]);
};
