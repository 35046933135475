import { TypeIds } from "."
import { DataType } from "./DataType"
import { FormatType } from "./FormatType"
import { Availability } from "./Availability"

/** A calculation. */
export interface Calculation {
  /** The type id. */
  readonly $typeId: TypeIds.Calculation; // string($uuid)

  /** The entity id. */
  readonly id: string; // ($uuid)

  /** The Calculation name. */
  name: string | null;

  /** The Calculation description. */
  description: string | null;

  dataType: DataType;

  formatType: FormatType;

  /** The type id. */
  readonly $calculationTypeTypeId: TypeIds.CalculationType; // string($uuid)

  /** The type id. */
  calculationTypeId: string; // ($uuid)

  /** The element availability. */
  availability: Availability;

  /** The user type id. */
  readonly $lastChangedByTypeId: string | null; // ($uuid)

  /** The user id who made the last changes. */
  readonly lastChangedById: string | null; // ($uuid)

  /** The timestamp of the last changes. */
  readonly lastChangedWhen: string | null; // ($date-time)

  /** The parent type type id. */
  readonly $parentTypeId: TypeIds.Design; // string($uuid)

  /** The parent id. */
  readonly parentId: string; // ($uuid)

  tags: string[] | null;
}

export const CalculationKeys: { [key in keyof Calculation]: key } = {
  $calculationTypeTypeId: '$calculationTypeTypeId',
  $lastChangedByTypeId: '$lastChangedByTypeId',
  $parentTypeId: '$parentTypeId',
  $typeId: '$typeId',
  availability: 'availability',
  calculationTypeId: 'calculationTypeId',
  dataType: 'dataType',
  description: 'description',
  formatType: 'formatType',
  id: 'id',
  lastChangedById: 'lastChangedById',
  lastChangedWhen: 'lastChangedWhen',
  name: 'name',
  parentId: 'parentId',
  tags: 'tags',
};