import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { DirectionalHint } from 'office-ui-fabric-react';

import { useHistory } from 'react-router-dom';
import IconButton from '../../../../components/IconButton';
import { getErrorsCount, getNotificationsCount } from '../../selectors';

import { btnStyles, btnStylesWhenHasErrors } from './styles';

interface NotificationsSidebarButtonProps {
  isActive: boolean;
  onClick(): void;
  disabled: boolean
  isInWizard: boolean
}

const NotificationsSidebarButton: React.FC<NotificationsSidebarButtonProps> = ({
  isActive, onClick, disabled, isInWizard
}) => {
  const history = useHistory()
  const errorsCount = useSelector(getErrorsCount);
  const notificationsCount = useSelector(getNotificationsCount);
  const { t } = useTranslation();

  const count = Number(errorsCount || 0) + Number(notificationsCount || 0);

  const hasErrors = errorsCount > 0;

  if (isInWizard && !hasErrors) return null;

  return (
    <IconButton
      count={count > 99 ? '*' : count}
      onClick={isInWizard ? () => history.go(-1) : onClick}
      iconProps={{ iconName: 'Ringer' }}
      tooltip={<Trans t={t} i18nKey="leftPanel:NotificationsTooltip" />}
      directionalHint={DirectionalHint.rightCenter}
      styles={hasErrors ? btnStylesWhenHasErrors : btnStyles}
      active={isActive}
      disabled={isActive || disabled}
    />
  );
};

export default NotificationsSidebarButton;
