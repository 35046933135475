import React from 'react';
import { Stack, Text, IStackStyles } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import upperFirst from 'lodash/upperFirst';

import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { ITypeSectionPropertyControlType } from '../../modules/Designer/types';

import { getSelectedDesignId } from '../../modules/Designer/ContentLibrary/selectors';

import TextEdit from './components/TextEdit';
import MultiSelectEdit from './components/MultiSelectEdit';

import { SchemaEntry } from './model';
import i18n from '../../config/i18n';
import { i18nApiPrefix, translateApiName } from '../../config/i18n/utils';
import TooltipHost from '../ui/TooltipHost';

// eslint-disable-next-line @typescript-eslint/comma-dangle
const printValue = <T,>(
  value: T,
  prepareOutput: () => T | string = () => value,
  t: TFunction = i18n.t
) => {
  if (typeof value === 'boolean') return t(`${i18nApiPrefix}:boolean:${value}`);
  return prepareOutput();
};

// TODO: make `SchemaEntry` and `item` type generics that depend on each other?
interface Props {
  schema: Array<SchemaEntry>;
  item: any;

  enableInlineEdit?: boolean;
  onChangeData?(attribute: string, value: any): void;
}

const titleStyles: IStackStyles = {
  root: {
    width: '30%',
  },
};
const valueStyles: IStackStyles = {
  root: {
    width: 'calc(70% - 14px)',
    overflowWrap: 'anywhere',
  },
};

const titleTextStyles: React.CSSProperties = {
  fontWeight: 600,
  fontSize: '12px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const valueTextStyles: React.CSSProperties = {
  fontSize: '12px',
};

const DataSchemaMapper: React.FC<Props> = ({
  schema,
  item,
  enableInlineEdit = false,
  onChangeData,
}) => {
  const { t } = useTranslation();
  const desingId = useSelector(getSelectedDesignId);

  const renderFieldText = (schemaItem: SchemaEntry) => {
    const value = item[schemaItem.key];
    const i18nValue = item[`i18n${upperFirst(schemaItem.key)}`];

    if (!enableInlineEdit) {
      // NOTE: for some reason this one works WITH `isLocked` (otherwise will try to translate non-system props)
      // needs further investigation and probably more compositional approach
      // OR just the rework of the translation system
      const text =
        i18nValue ??
        printValue(
          value,
          // eslint-disable-next-line @typescript-eslint/dot-notation
          () =>
            schemaItem?.key !== 'name' &&
            item?.isLocked &&
            'translationPrefix' in schemaItem &&
            value
              ? translateApiName(
                  schemaItem.valueTranslationPrefix ??
                    schemaItem.translationPrefix,
                  value
                )
              : value,
          t
        );

      return (
        <Text style={valueTextStyles}>
          {Array.isArray(text) ? text.join(', ') : text}
        </Text>
      );
    }

    switch (schemaItem?.interaction?.controlType) {
      case ITypeSectionPropertyControlType.SingleLineEdit:
      case ITypeSectionPropertyControlType.MultiLineEdit:
        return (
          <TextEdit
            value={value}
            onChange={(newValue) => onChangeData(schemaItem.key, newValue)}
          />
        );

      case ITypeSectionPropertyControlType.SelectMultiple:
        return (
          <MultiSelectEdit
            schemaItem={schemaItem}
            onChange={(newItems) => onChangeData(schemaItem.key, newItems)}
            selectedItems={value}
            designId={desingId}
          />
        );

      default:
        // NOTE: for some reason this one works WITHOUT `isLocked` (otherwise will not translate system values)
        // needs further investigation and probably more compositional approach
        // OR just the rework of the translation system
        // eslint-disable-next-line no-case-declarations
        const text =
          i18nValue ??
          printValue(
            value,
            // eslint-disable-next-line no-confusing-arrow
            () =>
              schemaItem.key !== 'name' &&
              'translationPrefix' in schemaItem &&
              value
                ? translateApiName(schemaItem.translationPrefix, value)
                : value,
            t
          );

        return (
          <Text style={valueTextStyles}>
            {Array.isArray(text) ? text.join(', ') : text}
          </Text>
        );
    }
  };

  return (
    <>
      {schema.map((schemaItem) => {
        // eslint-disable-next-line no-nested-ternary
        const itemName =
          'name' in schemaItem
            ? schemaItem.nameI18nKey
              ? t(schemaItem.nameI18nKey)
              : schemaItem.name
            : // NOTE: [KM] in hindsight, I can see that using `translationPrefix` as a prefix only for API names
              // was not the way to go; this could be simplified when we finally have all translation interceptors
              translateApiName(schemaItem.translationPrefix, schemaItem.key);

        return (
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginBottom: '8px' }}
            key={schemaItem.key}
          >
            <Stack styles={titleStyles}>
              <Text style={titleTextStyles}>
                <TooltipHost content={itemName}>{itemName}</TooltipHost>
              </Text>
            </Stack>
            <Stack styles={valueStyles}>{renderFieldText(schemaItem)}</Stack>
          </Stack>
        );
      })}
    </>
  );
};

export default DataSchemaMapper;
