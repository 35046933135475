import { History } from "history";
import React from "react";
import { useHistory } from "react-router";

let memoryHistory: History;

export const memoryHistoryService = {
  setHistory(newHistory: History) {
    memoryHistory = newHistory;
  },
  getHistory() {
    return memoryHistory;
  },
};

export const HistoryService = (): JSX.Element => {
  // HOOKS
  const history = useHistory();

  // EFFECTS
  const updateSharedHistory = React.useCallback(
    () => {
      memoryHistoryService.setHistory(history);
    },
    [history],
  );
  React.useEffect(updateSharedHistory, [updateSharedHistory]);

  // RENDER
  return null;
};
