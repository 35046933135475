import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HeaderBarButton } from '../../../../components/ui';
import { routes } from '../../../../constants/routes';
import { processStreamIntent, setSelectedStreamId } from '../../actions';
import { getSelectedStream } from '../../selectors';

export const BuilderProcessStreamButton = () => {
  // STATE
  const selectedStream = useSelector(getSelectedStream);

  // DERIVED STATE
  const isProcessingEnabled = !!selectedStream?.id;

  // HOOKS
  const history = useHistory();
  const dispatch = useDispatch();

  const handleProcess = () => {
    history.push(routes.process.streamFromBuilder, {
      selectedStream,
      processStreamActionCreator: processStreamIntent,
      deselectStreamAction: () => dispatch(setSelectedStreamId(null)),
    });
  };

  return (
    <HeaderBarButton
      iconProps={{ iconName: 'Processing' }}
      onClick={handleProcess}
      disabled={!isProcessingEnabled}
      shouldHideOnDisabled
      data-testid="builder-process-stream-btn"
      tooltip={<Trans i18nKey="wizard:ProcessTooltip" />}
    />
  );
};
