import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@fluentui/react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { TextField } from '../../../../../../components/ui';
import Error from '../../Error';
import { ComparisonTypes } from '../../../../../../types/IStreamFilter';
import { IColumn, IColumnDataType } from '../../../../../../types/IColumn';

interface Props {
  comparison: ComparisonTypes;
  column: IColumn;
}

const RangeInputSection: React.FC<Props> = ({ comparison, column }) => {
  const { t } = useTranslation('filters');

  const { control, errors, getValues } = useFormContext();

  if (
    ![ComparisonTypes.Between, ComparisonTypes.NotBetween].includes(comparison)
  ) {
    return null;
  }

  const validateUpperbound = () =>
    Number(getValues('lowerBoundValue')) <=
    Number(getValues('upperBoundValue'));

  if ([IColumnDataType.DateTime].includes(column.dataType)) {
    return (
      <>
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label={t('Value')}
              isMonthPickerVisible={false}
              onSelectDate={(date) => onChange(date)}
              value={value}
            />
          )}
          name="lowerBoundValue"
          defaultValue={new Date()}
          control={control}
          rules={{ required: true }}
        />
        <ErrorMessage
          render={({ message }) => <Error>{message}</Error>}
          errors={errors}
          name="lowerBoundValue"
          message={t('required')}
        />
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label={t('Value')}
              isMonthPickerVisible={false}
              onSelectDate={(date) => onChange(date)}
              value={value}
            />
          )}
          label={t('Higher value')}
          defaultValue={new Date()}
          name="upperBoundValue"
          control={control}
          rules={{
            required: true,
            validate: () =>
              getValues('lowerBoundValue') <= getValues('upperBoundValue'),
          }}
        />
        {errors?.upperBoundValue?.type === 'required' && (
          <Error>{t('required')}</Error>
        )}
        {errors?.upperBoundValue?.type === 'validate' && (
          <Error>{t('Upper bound has to be bigger than lower bound')}</Error>
        )}
      </>
    );
  }

  return (
    <>
      <Controller
        as={NumberFormat}
        label={t('Value')}
        name="lowerBoundValue"
        control={control}
        rules={{ required: true }}
        customInput={TextField}
      />
      <ErrorMessage
        render={({ message }) => <Error>{message}</Error>}
        errors={errors}
        name="lowerBoundValue"
        message={t('required')}
      />
      <Controller
        as={NumberFormat}
        label={t('Higher value')}
        name="upperBoundValue"
        control={control}
        customInput={TextField}
        rules={{
          required: true,
          validate: validateUpperbound,
        }}
      />
      {errors?.upperBoundValue?.type === 'required' && (
        <Error>{t('required')}</Error>
      )}
      {errors?.upperBoundValue?.type === 'validate' && (
        <Error>{t('Upper bound has to be bigger than lower bound')}</Error>
      )}
    </>
  );
};

export default RangeInputSection;
